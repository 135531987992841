import { handleImageUpload } from 'src/utils';

export const createImage = url =>
   new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
   });

export function getRadianAngle(degreeValue) {
   return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
   const rotRad = getRadianAngle(rotation);

   return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
   };
}

// export default async function getCroppedImg(
//   imageSrc: any,
//   pixelCrop,
//   rotation = 0,
//   flip = { horizontal: false, vertical: false },
// ) {
//   const image: any = await createImage(imageSrc);
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');

//   if (!ctx) {
//     return null;
//   }

//   const rotRad = getRadianAngle(rotation);

//   const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

//   canvas.width = bBoxWidth;
//   canvas.height = bBoxHeight;

//   ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
//   ctx.rotate(rotRad);
//   ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
//   ctx.translate(-image.width / 2, -image.height / 2);

//   ctx.drawImage(image, 0, 0);

//   const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

//   canvas.width = pixelCrop.width;
//   canvas.height = pixelCrop.height;

//   ctx.putImageData(data, 0, 0);

//   return new Promise((resolve, reject) => {
//     canvas.toBlob((file: any) => {
//       file.name = 'cropped.jpeg';
//       resolve({ file: file, url: URL.createObjectURL(file) });
//     }, 'image/jpeg');
//   });
// }

export default async function getCroppedImg(
   imageSrc: any,
   pixelCrop,
   rotation = 0,
   flip = { horizontal: false, vertical: false },
   setImageUrl: (url: string) => void
) {
   const image: any = await createImage(imageSrc);
   const canvas = document.createElement('canvas');
   const ctx = canvas.getContext('2d');

   if (!ctx) {
      return null;
   }

   const rotRad = getRadianAngle(rotation);

   const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

   canvas.width = bBoxWidth;
   canvas.height = bBoxHeight;

   ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
   ctx.rotate(rotRad);
   ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
   ctx.translate(-image.width / 2, -image.height / 2);

   ctx.drawImage(image, 0, 0);

   const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

   canvas.width = pixelCrop.width;
   canvas.height = pixelCrop.height;

   ctx.putImageData(data, 0, 0);

   return new Promise((resolve, reject) => {
      canvas.toBlob((file: any) => {
         file.name = 'cropped.jpeg';

         handleImageUpload([file], setImageUrl);

         resolve({ file: file, url: URL.createObjectURL(file) });
      }, 'image/jpeg');
   });
}
