export const wards = [
  {
    name: "Phường Phúc Xá",
    code: 1,
    division_type: "phường",
    codename: "phuong_phuc_xa",
    district_code: 1,
  },
  {
    name: "Phường Trúc Bạch",
    code: 4,
    division_type: "phường",
    codename: "phuong_truc_bach",
    district_code: 1,
  },
  {
    name: "Phường Vĩnh Phúc",
    code: 6,
    division_type: "phường",
    codename: "phuong_vinh_phuc",
    district_code: 1,
  },
  {
    name: "Phường Cống Vị",
    code: 7,
    division_type: "phường",
    codename: "phuong_cong_vi",
    district_code: 1,
  },
  {
    name: "Phường Liễu Giai",
    code: 8,
    division_type: "phường",
    codename: "phuong_lieu_giai",
    district_code: 1,
  },
  {
    name: "Phường Nguyễn Trung Trực",
    code: 10,
    division_type: "phường",
    codename: "phuong_nguyen_trung_truc",
    district_code: 1,
  },
  {
    name: "Phường Quán Thánh",
    code: 13,
    division_type: "phường",
    codename: "phuong_quan_thanh",
    district_code: 1,
  },
  {
    name: "Phường Ngọc Hà",
    code: 16,
    division_type: "phường",
    codename: "phuong_ngoc_ha",
    district_code: 1,
  },
  {
    name: "Phường Điện Biên",
    code: 19,
    division_type: "phường",
    codename: "phuong_dien_bien",
    district_code: 1,
  },
  {
    name: "Phường Đội Cấn",
    code: 22,
    division_type: "phường",
    codename: "phuong_doi_can",
    district_code: 1,
  },
  {
    name: "Phường Ngọc Khánh",
    code: 25,
    division_type: "phường",
    codename: "phuong_ngoc_khanh",
    district_code: 1,
  },
  {
    name: "Phường Kim Mã",
    code: 28,
    division_type: "phường",
    codename: "phuong_kim_ma",
    district_code: 1,
  },
  {
    name: "Phường Giảng Võ",
    code: 31,
    division_type: "phường",
    codename: "phuong_giang_vo",
    district_code: 1,
  },
  {
    name: "Phường Thành Công",
    code: 34,
    division_type: "phường",
    codename: "phuong_thanh_cong",
    district_code: 1,
  },
  {
    name: "Phường Phúc Tân",
    code: 37,
    division_type: "phường",
    codename: "phuong_phuc_tan",
    district_code: 2,
  },
  {
    name: "Phường Đồng Xuân",
    code: 40,
    division_type: "phường",
    codename: "phuong_dong_xuan",
    district_code: 2,
  },
  {
    name: "Phường Hàng Mã",
    code: 43,
    division_type: "phường",
    codename: "phuong_hang_ma",
    district_code: 2,
  },
  {
    name: "Phường Hàng Buồm",
    code: 46,
    division_type: "phường",
    codename: "phuong_hang_buom",
    district_code: 2,
  },
  {
    name: "Phường Hàng Đào",
    code: 49,
    division_type: "phường",
    codename: "phuong_hang_dao",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bồ",
    code: 52,
    division_type: "phường",
    codename: "phuong_hang_bo",
    district_code: 2,
  },
  {
    name: "Phường Cửa Đông",
    code: 55,
    division_type: "phường",
    codename: "phuong_cua_dong",
    district_code: 2,
  },
  {
    name: "Phường Lý Thái Tổ",
    code: 58,
    division_type: "phường",
    codename: "phuong_ly_thai_to",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bạc",
    code: 61,
    division_type: "phường",
    codename: "phuong_hang_bac",
    district_code: 2,
  },
  {
    name: "Phường Hàng Gai",
    code: 64,
    division_type: "phường",
    codename: "phuong_hang_gai",
    district_code: 2,
  },
  {
    name: "Phường Chương Dương",
    code: 67,
    division_type: "phường",
    codename: "phuong_chuong_duong",
    district_code: 2,
  },
  {
    name: "Phường Hàng Trống",
    code: 70,
    division_type: "phường",
    codename: "phuong_hang_trong",
    district_code: 2,
  },
  {
    name: "Phường Cửa Nam",
    code: 73,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bông",
    code: 76,
    division_type: "phường",
    codename: "phuong_hang_bong",
    district_code: 2,
  },
  {
    name: "Phường Tràng Tiền",
    code: 79,
    division_type: "phường",
    codename: "phuong_trang_tien",
    district_code: 2,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 82,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 2,
  },
  {
    name: "Phường Phan Chu Trinh",
    code: 85,
    division_type: "phường",
    codename: "phuong_phan_chu_trinh",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bài",
    code: 88,
    division_type: "phường",
    codename: "phuong_hang_bai",
    district_code: 2,
  },
  {
    name: "Phường Phú Thượng",
    code: 91,
    division_type: "phường",
    codename: "phuong_phu_thuong",
    district_code: 3,
  },
  {
    name: "Phường Nhật Tân",
    code: 94,
    division_type: "phường",
    codename: "phuong_nhat_tan",
    district_code: 3,
  },
  {
    name: "Phường Tứ Liên",
    code: 97,
    division_type: "phường",
    codename: "phuong_tu_lien",
    district_code: 3,
  },
  {
    name: "Phường Quảng An",
    code: 100,
    division_type: "phường",
    codename: "phuong_quang_an",
    district_code: 3,
  },
  {
    name: "Phường Xuân La",
    code: 103,
    division_type: "phường",
    codename: "phuong_xuan_la",
    district_code: 3,
  },
  {
    name: "Phường Yên Phụ",
    code: 106,
    division_type: "phường",
    codename: "phuong_yen_phu",
    district_code: 3,
  },
  {
    name: "Phường Bưởi",
    code: 109,
    division_type: "phường",
    codename: "phuong_buoi",
    district_code: 3,
  },
  {
    name: "Phường Thụy Khuê",
    code: 112,
    division_type: "phường",
    codename: "phuong_thuy_khue",
    district_code: 3,
  },
  {
    name: "Phường Thượng Thanh",
    code: 115,
    division_type: "phường",
    codename: "phuong_thuong_thanh",
    district_code: 4,
  },
  {
    name: "Phường Ngọc Thụy",
    code: 118,
    division_type: "phường",
    codename: "phuong_ngoc_thuy",
    district_code: 4,
  },
  {
    name: "Phường Giang Biên",
    code: 121,
    division_type: "phường",
    codename: "phuong_giang_bien",
    district_code: 4,
  },
  {
    name: "Phường Đức Giang",
    code: 124,
    division_type: "phường",
    codename: "phuong_duc_giang",
    district_code: 4,
  },
  {
    name: "Phường Việt Hưng",
    code: 127,
    division_type: "phường",
    codename: "phuong_viet_hung",
    district_code: 4,
  },
  {
    name: "Phường Gia Thụy",
    code: 130,
    division_type: "phường",
    codename: "phuong_gia_thuy",
    district_code: 4,
  },
  {
    name: "Phường Ngọc Lâm",
    code: 133,
    division_type: "phường",
    codename: "phuong_ngoc_lam",
    district_code: 4,
  },
  {
    name: "Phường Phúc Lợi",
    code: 136,
    division_type: "phường",
    codename: "phuong_phuc_loi",
    district_code: 4,
  },
  {
    name: "Phường Bồ Đề",
    code: 139,
    division_type: "phường",
    codename: "phuong_bo_de",
    district_code: 4,
  },
  {
    name: "Phường Sài Đồng",
    code: 142,
    division_type: "phường",
    codename: "phuong_sai_dong",
    district_code: 4,
  },
  {
    name: "Phường Long Biên",
    code: 145,
    division_type: "phường",
    codename: "phuong_long_bien",
    district_code: 4,
  },
  {
    name: "Phường Thạch Bàn",
    code: 148,
    division_type: "phường",
    codename: "phuong_thach_ban",
    district_code: 4,
  },
  {
    name: "Phường Phúc Đồng",
    code: 151,
    division_type: "phường",
    codename: "phuong_phuc_dong",
    district_code: 4,
  },
  {
    name: "Phường Cự Khối",
    code: 154,
    division_type: "phường",
    codename: "phuong_cu_khoi",
    district_code: 4,
  },
  {
    name: "Phường Nghĩa Đô",
    code: 157,
    division_type: "phường",
    codename: "phuong_nghia_do",
    district_code: 5,
  },
  {
    name: "Phường Nghĩa Tân",
    code: 160,
    division_type: "phường",
    codename: "phuong_nghia_tan",
    district_code: 5,
  },
  {
    name: "Phường Mai Dịch",
    code: 163,
    division_type: "phường",
    codename: "phuong_mai_dich",
    district_code: 5,
  },
  {
    name: "Phường Dịch Vọng",
    code: 166,
    division_type: "phường",
    codename: "phuong_dich_vong",
    district_code: 5,
  },
  {
    name: "Phường Dịch Vọng Hậu",
    code: 167,
    division_type: "phường",
    codename: "phuong_dich_vong_hau",
    district_code: 5,
  },
  {
    name: "Phường Quan Hoa",
    code: 169,
    division_type: "phường",
    codename: "phuong_quan_hoa",
    district_code: 5,
  },
  {
    name: "Phường Yên Hoà",
    code: 172,
    division_type: "phường",
    codename: "phuong_yen_hoa",
    district_code: 5,
  },
  {
    name: "Phường Trung Hoà",
    code: 175,
    division_type: "phường",
    codename: "phuong_trung_hoa",
    district_code: 5,
  },
  {
    name: "Phường Cát Linh",
    code: 178,
    division_type: "phường",
    codename: "phuong_cat_linh",
    district_code: 6,
  },
  {
    name: "Phường Văn Miếu",
    code: 181,
    division_type: "phường",
    codename: "phuong_van_mieu",
    district_code: 6,
  },
  {
    name: "Phường Quốc Tử Giám",
    code: 184,
    division_type: "phường",
    codename: "phuong_quoc_tu_giam",
    district_code: 6,
  },
  {
    name: "Phường Láng Thượng",
    code: 187,
    division_type: "phường",
    codename: "phuong_lang_thuong",
    district_code: 6,
  },
  {
    name: "Phường Ô Chợ Dừa",
    code: 190,
    division_type: "phường",
    codename: "phuong_o_cho_dua",
    district_code: 6,
  },
  {
    name: "Phường Văn Chương",
    code: 193,
    division_type: "phường",
    codename: "phuong_van_chuong",
    district_code: 6,
  },
  {
    name: "Phường Hàng Bột",
    code: 196,
    division_type: "phường",
    codename: "phuong_hang_bot",
    district_code: 6,
  },
  {
    name: "Phường Láng Hạ",
    code: 199,
    division_type: "phường",
    codename: "phuong_lang_ha",
    district_code: 6,
  },
  {
    name: "Phường Khâm Thiên",
    code: 202,
    division_type: "phường",
    codename: "phuong_kham_thien",
    district_code: 6,
  },
  {
    name: "Phường Thổ Quan",
    code: 205,
    division_type: "phường",
    codename: "phuong_tho_quan",
    district_code: 6,
  },
  {
    name: "Phường Nam Đồng",
    code: 208,
    division_type: "phường",
    codename: "phuong_nam_dong",
    district_code: 6,
  },
  {
    name: "Phường Trung Phụng",
    code: 211,
    division_type: "phường",
    codename: "phuong_trung_phung",
    district_code: 6,
  },
  {
    name: "Phường Quang Trung",
    code: 214,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 6,
  },
  {
    name: "Phường Trung Liệt",
    code: 217,
    division_type: "phường",
    codename: "phuong_trung_liet",
    district_code: 6,
  },
  {
    name: "Phường Phương Liên",
    code: 220,
    division_type: "phường",
    codename: "phuong_phuong_lien",
    district_code: 6,
  },
  {
    name: "Phường Thịnh Quang",
    code: 223,
    division_type: "phường",
    codename: "phuong_thinh_quang",
    district_code: 6,
  },
  {
    name: "Phường Trung Tự",
    code: 226,
    division_type: "phường",
    codename: "phuong_trung_tu",
    district_code: 6,
  },
  {
    name: "Phường Kim Liên",
    code: 229,
    division_type: "phường",
    codename: "phuong_kim_lien",
    district_code: 6,
  },
  {
    name: "Phường Phương Mai",
    code: 232,
    division_type: "phường",
    codename: "phuong_phuong_mai",
    district_code: 6,
  },
  {
    name: "Phường Ngã Tư Sở",
    code: 235,
    division_type: "phường",
    codename: "phuong_nga_tu_so",
    district_code: 6,
  },
  {
    name: "Phường Khương Thượng",
    code: 238,
    division_type: "phường",
    codename: "phuong_khuong_thuong",
    district_code: 6,
  },
  {
    name: "Phường Nguyễn Du",
    code: 241,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 7,
  },
  {
    name: "Phường Bạch Đằng",
    code: 244,
    division_type: "phường",
    codename: "phuong_bach_dang",
    district_code: 7,
  },
  {
    name: "Phường Phạm Đình Hổ",
    code: 247,
    division_type: "phường",
    codename: "phuong_pham_dinh_ho",
    district_code: 7,
  },
  {
    name: "Phường Lê Đại Hành",
    code: 256,
    division_type: "phường",
    codename: "phuong_le_dai_hanh",
    district_code: 7,
  },
  {
    name: "Phường Đồng Nhân",
    code: 259,
    division_type: "phường",
    codename: "phuong_dong_nhan",
    district_code: 7,
  },
  {
    name: "Phường Phố Huế",
    code: 262,
    division_type: "phường",
    codename: "phuong_pho_hue",
    district_code: 7,
  },
  {
    name: "Phường Đống Mác",
    code: 265,
    division_type: "phường",
    codename: "phuong_dong_mac",
    district_code: 7,
  },
  {
    name: "Phường Thanh Lương",
    code: 268,
    division_type: "phường",
    codename: "phuong_thanh_luong",
    district_code: 7,
  },
  {
    name: "Phường Thanh Nhàn",
    code: 271,
    division_type: "phường",
    codename: "phuong_thanh_nhan",
    district_code: 7,
  },
  {
    name: "Phường Cầu Dền",
    code: 274,
    division_type: "phường",
    codename: "phuong_cau_den",
    district_code: 7,
  },
  {
    name: "Phường Bách Khoa",
    code: 277,
    division_type: "phường",
    codename: "phuong_bach_khoa",
    district_code: 7,
  },
  {
    name: "Phường Đồng Tâm",
    code: 280,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 7,
  },
  {
    name: "Phường Vĩnh Tuy",
    code: 283,
    division_type: "phường",
    codename: "phuong_vinh_tuy",
    district_code: 7,
  },
  {
    name: "Phường Bạch Mai",
    code: 286,
    division_type: "phường",
    codename: "phuong_bach_mai",
    district_code: 7,
  },
  {
    name: "Phường Quỳnh Mai",
    code: 289,
    division_type: "phường",
    codename: "phuong_quynh_mai",
    district_code: 7,
  },
  {
    name: "Phường Quỳnh Lôi",
    code: 292,
    division_type: "phường",
    codename: "phuong_quynh_loi",
    district_code: 7,
  },
  {
    name: "Phường Minh Khai",
    code: 295,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 7,
  },
  {
    name: "Phường Trương Định",
    code: 298,
    division_type: "phường",
    codename: "phuong_truong_dinh",
    district_code: 7,
  },
  {
    name: "Phường Thanh Trì",
    code: 301,
    division_type: "phường",
    codename: "phuong_thanh_tri",
    district_code: 8,
  },
  {
    name: "Phường Vĩnh Hưng",
    code: 304,
    division_type: "phường",
    codename: "phuong_vinh_hung",
    district_code: 8,
  },
  {
    name: "Phường Định Công",
    code: 307,
    division_type: "phường",
    codename: "phuong_dinh_cong",
    district_code: 8,
  },
  {
    name: "Phường Mai Động",
    code: 310,
    division_type: "phường",
    codename: "phuong_mai_dong",
    district_code: 8,
  },
  {
    name: "Phường Tương Mai",
    code: 313,
    division_type: "phường",
    codename: "phuong_tuong_mai",
    district_code: 8,
  },
  {
    name: "Phường Đại Kim",
    code: 316,
    division_type: "phường",
    codename: "phuong_dai_kim",
    district_code: 8,
  },
  {
    name: "Phường Tân Mai",
    code: 319,
    division_type: "phường",
    codename: "phuong_tan_mai",
    district_code: 8,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 322,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 8,
  },
  {
    name: "Phường Giáp Bát",
    code: 325,
    division_type: "phường",
    codename: "phuong_giap_bat",
    district_code: 8,
  },
  {
    name: "Phường Lĩnh Nam",
    code: 328,
    division_type: "phường",
    codename: "phuong_linh_nam",
    district_code: 8,
  },
  {
    name: "Phường Thịnh Liệt",
    code: 331,
    division_type: "phường",
    codename: "phuong_thinh_liet",
    district_code: 8,
  },
  {
    name: "Phường Trần Phú",
    code: 334,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 8,
  },
  {
    name: "Phường Hoàng Liệt",
    code: 337,
    division_type: "phường",
    codename: "phuong_hoang_liet",
    district_code: 8,
  },
  {
    name: "Phường Yên Sở",
    code: 340,
    division_type: "phường",
    codename: "phuong_yen_so",
    district_code: 8,
  },
  {
    name: "Phường Nhân Chính",
    code: 343,
    division_type: "phường",
    codename: "phuong_nhan_chinh",
    district_code: 9,
  },
  {
    name: "Phường Thượng Đình",
    code: 346,
    division_type: "phường",
    codename: "phuong_thuong_dinh",
    district_code: 9,
  },
  {
    name: "Phường Khương Trung",
    code: 349,
    division_type: "phường",
    codename: "phuong_khuong_trung",
    district_code: 9,
  },
  {
    name: "Phường Khương Mai",
    code: 352,
    division_type: "phường",
    codename: "phuong_khuong_mai",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Trung",
    code: 355,
    division_type: "phường",
    codename: "phuong_thanh_xuan_trung",
    district_code: 9,
  },
  {
    name: "Phường Phương Liệt",
    code: 358,
    division_type: "phường",
    codename: "phuong_phuong_liet",
    district_code: 9,
  },
  {
    name: "Phường Hạ Đình",
    code: 361,
    division_type: "phường",
    codename: "phuong_ha_dinh",
    district_code: 9,
  },
  {
    name: "Phường Khương Đình",
    code: 364,
    division_type: "phường",
    codename: "phuong_khuong_dinh",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Bắc",
    code: 367,
    division_type: "phường",
    codename: "phuong_thanh_xuan_bac",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Nam",
    code: 370,
    division_type: "phường",
    codename: "phuong_thanh_xuan_nam",
    district_code: 9,
  },
  {
    name: "Phường Kim Giang",
    code: 373,
    division_type: "phường",
    codename: "phuong_kim_giang",
    district_code: 9,
  },
  {
    name: "Thị trấn Sóc Sơn",
    code: 376,
    division_type: "thị trấn",
    codename: "thi_tran_soc_son",
    district_code: 16,
  },
  {
    name: "Xã Bắc Sơn",
    code: 379,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 16,
  },
  {
    name: "Xã Minh Trí",
    code: 382,
    division_type: "xã",
    codename: "xa_minh_tri",
    district_code: 16,
  },
  {
    name: "Xã Hồng Kỳ",
    code: 385,
    division_type: "xã",
    codename: "xa_hong_ky",
    district_code: 16,
  },
  {
    name: "Xã Nam Sơn",
    code: 388,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 16,
  },
  {
    name: "Xã Trung Giã",
    code: 391,
    division_type: "xã",
    codename: "xa_trung_gia",
    district_code: 16,
  },
  {
    name: "Xã Tân Hưng",
    code: 394,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 16,
  },
  {
    name: "Xã Minh Phú",
    code: 397,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 16,
  },
  {
    name: "Xã Phù Linh",
    code: 400,
    division_type: "xã",
    codename: "xa_phu_linh",
    district_code: 16,
  },
  {
    name: "Xã Bắc Phú",
    code: 403,
    division_type: "xã",
    codename: "xa_bac_phu",
    district_code: 16,
  },
  {
    name: "Xã Tân Minh",
    code: 406,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 16,
  },
  {
    name: "Xã Quang Tiến",
    code: 409,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 16,
  },
  {
    name: "Xã Hiền Ninh",
    code: 412,
    division_type: "xã",
    codename: "xa_hien_ninh",
    district_code: 16,
  },
  {
    name: "Xã Tân Dân",
    code: 415,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 16,
  },
  {
    name: "Xã Tiên Dược",
    code: 418,
    division_type: "xã",
    codename: "xa_tien_duoc",
    district_code: 16,
  },
  {
    name: "Xã Việt Long",
    code: 421,
    division_type: "xã",
    codename: "xa_viet_long",
    district_code: 16,
  },
  {
    name: "Xã Xuân Giang",
    code: 424,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 16,
  },
  {
    name: "Xã Mai Đình",
    code: 427,
    division_type: "xã",
    codename: "xa_mai_dinh",
    district_code: 16,
  },
  {
    name: "Xã Đức Hoà",
    code: 430,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 16,
  },
  {
    name: "Xã Thanh Xuân",
    code: 433,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 16,
  },
  {
    name: "Xã Đông Xuân",
    code: 436,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 16,
  },
  {
    name: "Xã Kim Lũ",
    code: 439,
    division_type: "xã",
    codename: "xa_kim_lu",
    district_code: 16,
  },
  {
    name: "Xã Phú Cường",
    code: 442,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 16,
  },
  {
    name: "Xã Phú Minh",
    code: 445,
    division_type: "xã",
    codename: "xa_phu_minh",
    district_code: 16,
  },
  {
    name: "Xã Phù Lỗ",
    code: 448,
    division_type: "xã",
    codename: "xa_phu_lo",
    district_code: 16,
  },
  {
    name: "Xã Xuân Thu",
    code: 451,
    division_type: "xã",
    codename: "xa_xuan_thu",
    district_code: 16,
  },
  {
    name: "Thị trấn Đông Anh",
    code: 454,
    division_type: "thị trấn",
    codename: "thi_tran_dong_anh",
    district_code: 17,
  },
  {
    name: "Xã Xuân Nộn",
    code: 457,
    division_type: "xã",
    codename: "xa_xuan_non",
    district_code: 17,
  },
  {
    name: "Xã Thuỵ Lâm",
    code: 460,
    division_type: "xã",
    codename: "xa_thuy_lam",
    district_code: 17,
  },
  {
    name: "Xã Bắc Hồng",
    code: 463,
    division_type: "xã",
    codename: "xa_bac_hong",
    district_code: 17,
  },
  {
    name: "Xã Nguyên Khê",
    code: 466,
    division_type: "xã",
    codename: "xa_nguyen_khe",
    district_code: 17,
  },
  {
    name: "Xã Nam Hồng",
    code: 469,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 17,
  },
  {
    name: "Xã Tiên Dương",
    code: 472,
    division_type: "xã",
    codename: "xa_tien_duong",
    district_code: 17,
  },
  {
    name: "Xã Vân Hà",
    code: 475,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 17,
  },
  {
    name: "Xã Uy Nỗ",
    code: 478,
    division_type: "xã",
    codename: "xa_uy_no",
    district_code: 17,
  },
  {
    name: "Xã Vân Nội",
    code: 481,
    division_type: "xã",
    codename: "xa_van_noi",
    district_code: 17,
  },
  {
    name: "Xã Liên Hà",
    code: 484,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 17,
  },
  {
    name: "Xã Việt Hùng",
    code: 487,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 17,
  },
  {
    name: "Xã Kim Nỗ",
    code: 490,
    division_type: "xã",
    codename: "xa_kim_no",
    district_code: 17,
  },
  {
    name: "Xã Kim Chung",
    code: 493,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 17,
  },
  {
    name: "Xã Dục Tú",
    code: 496,
    division_type: "xã",
    codename: "xa_duc_tu",
    district_code: 17,
  },
  {
    name: "Xã Đại Mạch",
    code: 499,
    division_type: "xã",
    codename: "xa_dai_mach",
    district_code: 17,
  },
  {
    name: "Xã Vĩnh Ngọc",
    code: 502,
    division_type: "xã",
    codename: "xa_vinh_ngoc",
    district_code: 17,
  },
  {
    name: "Xã Cổ Loa",
    code: 505,
    division_type: "xã",
    codename: "xa_co_loa",
    district_code: 17,
  },
  {
    name: "Xã Hải Bối",
    code: 508,
    division_type: "xã",
    codename: "xa_hai_boi",
    district_code: 17,
  },
  {
    name: "Xã Xuân Canh",
    code: 511,
    division_type: "xã",
    codename: "xa_xuan_canh",
    district_code: 17,
  },
  {
    name: "Xã Võng La",
    code: 514,
    division_type: "xã",
    codename: "xa_vong_la",
    district_code: 17,
  },
  {
    name: "Xã Tàm Xá",
    code: 517,
    division_type: "xã",
    codename: "xa_tam_xa",
    district_code: 17,
  },
  {
    name: "Xã Mai Lâm",
    code: 520,
    division_type: "xã",
    codename: "xa_mai_lam",
    district_code: 17,
  },
  {
    name: "Xã Đông Hội",
    code: 523,
    division_type: "xã",
    codename: "xa_dong_hoi",
    district_code: 17,
  },
  {
    name: "Thị trấn Yên Viên",
    code: 526,
    division_type: "thị trấn",
    codename: "thi_tran_yen_vien",
    district_code: 18,
  },
  {
    name: "Xã Yên Thường",
    code: 529,
    division_type: "xã",
    codename: "xa_yen_thuong",
    district_code: 18,
  },
  {
    name: "Xã Yên Viên",
    code: 532,
    division_type: "xã",
    codename: "xa_yen_vien",
    district_code: 18,
  },
  {
    name: "Xã Ninh Hiệp",
    code: 535,
    division_type: "xã",
    codename: "xa_ninh_hiep",
    district_code: 18,
  },
  {
    name: "Xã Đình Xuyên",
    code: 538,
    division_type: "xã",
    codename: "xa_dinh_xuyen",
    district_code: 18,
  },
  {
    name: "Xã Dương Hà",
    code: 541,
    division_type: "xã",
    codename: "xa_duong_ha",
    district_code: 18,
  },
  {
    name: "Xã Phù Đổng",
    code: 544,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 18,
  },
  {
    name: "Xã Trung Mầu",
    code: 547,
    division_type: "xã",
    codename: "xa_trung_mau",
    district_code: 18,
  },
  {
    name: "Xã Lệ Chi",
    code: 550,
    division_type: "xã",
    codename: "xa_le_chi",
    district_code: 18,
  },
  {
    name: "Xã Cổ Bi",
    code: 553,
    division_type: "xã",
    codename: "xa_co_bi",
    district_code: 18,
  },
  {
    name: "Xã Đặng Xá",
    code: 556,
    division_type: "xã",
    codename: "xa_dang_xa",
    district_code: 18,
  },
  {
    name: "Xã Phú Thị",
    code: 559,
    division_type: "xã",
    codename: "xa_phu_thi",
    district_code: 18,
  },
  {
    name: "Xã Kim Sơn",
    code: 562,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 18,
  },
  {
    name: "Thị trấn Trâu Quỳ",
    code: 565,
    division_type: "thị trấn",
    codename: "thi_tran_trau_quy",
    district_code: 18,
  },
  {
    name: "Xã Dương Quang",
    code: 568,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 18,
  },
  {
    name: "Xã Dương Xá",
    code: 571,
    division_type: "xã",
    codename: "xa_duong_xa",
    district_code: 18,
  },
  {
    name: "Xã Đông Dư",
    code: 574,
    division_type: "xã",
    codename: "xa_dong_du",
    district_code: 18,
  },
  {
    name: "Xã Đa Tốn",
    code: 577,
    division_type: "xã",
    codename: "xa_da_ton",
    district_code: 18,
  },
  {
    name: "Xã Kiêu Kỵ",
    code: 580,
    division_type: "xã",
    codename: "xa_kieu_ky",
    district_code: 18,
  },
  {
    name: "Xã Bát Tràng",
    code: 583,
    division_type: "xã",
    codename: "xa_bat_trang",
    district_code: 18,
  },
  {
    name: "Xã Kim Lan",
    code: 586,
    division_type: "xã",
    codename: "xa_kim_lan",
    district_code: 18,
  },
  {
    name: "Xã Văn Đức",
    code: 589,
    division_type: "xã",
    codename: "xa_van_duc",
    district_code: 18,
  },
  {
    name: "Phường Cầu Diễn",
    code: 592,
    division_type: "phường",
    codename: "phuong_cau_dien",
    district_code: 19,
  },
  {
    name: "Phường Xuân Phương",
    code: 622,
    division_type: "phường",
    codename: "phuong_xuan_phuong",
    district_code: 19,
  },
  {
    name: "Phường Phương Canh",
    code: 623,
    division_type: "phường",
    codename: "phuong_phuong_canh",
    district_code: 19,
  },
  {
    name: "Phường Mỹ Đình 1",
    code: 625,
    division_type: "phường",
    codename: "phuong_my_dinh_1",
    district_code: 19,
  },
  {
    name: "Phường Mỹ Đình 2",
    code: 626,
    division_type: "phường",
    codename: "phuong_my_dinh_2",
    district_code: 19,
  },
  {
    name: "Phường Tây Mỗ",
    code: 628,
    division_type: "phường",
    codename: "phuong_tay_mo",
    district_code: 19,
  },
  {
    name: "Phường Mễ Trì",
    code: 631,
    division_type: "phường",
    codename: "phuong_me_tri",
    district_code: 19,
  },
  {
    name: "Phường Phú Đô",
    code: 632,
    division_type: "phường",
    codename: "phuong_phu_do",
    district_code: 19,
  },
  {
    name: "Phường Đại Mỗ",
    code: 634,
    division_type: "phường",
    codename: "phuong_dai_mo",
    district_code: 19,
  },
  {
    name: "Phường Trung Văn",
    code: 637,
    division_type: "phường",
    codename: "phuong_trung_van",
    district_code: 19,
  },
  {
    name: "Thị trấn Văn Điển",
    code: 640,
    division_type: "thị trấn",
    codename: "thi_tran_van_dien",
    district_code: 20,
  },
  {
    name: "Xã Tân Triều",
    code: 643,
    division_type: "xã",
    codename: "xa_tan_trieu",
    district_code: 20,
  },
  {
    name: "Xã Thanh Liệt",
    code: 646,
    division_type: "xã",
    codename: "xa_thanh_liet",
    district_code: 20,
  },
  {
    name: "Xã Tả Thanh Oai",
    code: 649,
    division_type: "xã",
    codename: "xa_ta_thanh_oai",
    district_code: 20,
  },
  {
    name: "Xã Hữu Hoà",
    code: 652,
    division_type: "xã",
    codename: "xa_huu_hoa",
    district_code: 20,
  },
  {
    name: "Xã Tam Hiệp",
    code: 655,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 20,
  },
  {
    name: "Xã Tứ Hiệp",
    code: 658,
    division_type: "xã",
    codename: "xa_tu_hiep",
    district_code: 20,
  },
  {
    name: "Xã Yên Mỹ",
    code: 661,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 20,
  },
  {
    name: "Xã Vĩnh Quỳnh",
    code: 664,
    division_type: "xã",
    codename: "xa_vinh_quynh",
    district_code: 20,
  },
  {
    name: "Xã Ngũ Hiệp",
    code: 667,
    division_type: "xã",
    codename: "xa_ngu_hiep",
    district_code: 20,
  },
  {
    name: "Xã Duyên Hà",
    code: 670,
    division_type: "xã",
    codename: "xa_duyen_ha",
    district_code: 20,
  },
  {
    name: "Xã Ngọc Hồi",
    code: 673,
    division_type: "xã",
    codename: "xa_ngoc_hoi",
    district_code: 20,
  },
  {
    name: "Xã Vạn Phúc",
    code: 676,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 20,
  },
  {
    name: "Xã Đại áng",
    code: 679,
    division_type: "xã",
    codename: "xa_dai_ang",
    district_code: 20,
  },
  {
    name: "Xã Liên Ninh",
    code: 682,
    division_type: "xã",
    codename: "xa_lien_ninh",
    district_code: 20,
  },
  {
    name: "Xã Đông Mỹ",
    code: 685,
    division_type: "xã",
    codename: "xa_dong_my",
    district_code: 20,
  },
  {
    name: "Phường Thượng Cát",
    code: 595,
    division_type: "phường",
    codename: "phuong_thuong_cat",
    district_code: 21,
  },
  {
    name: "Phường Liên Mạc",
    code: 598,
    division_type: "phường",
    codename: "phuong_lien_mac",
    district_code: 21,
  },
  {
    name: "Phường Đông Ngạc",
    code: 601,
    division_type: "phường",
    codename: "phuong_dong_ngac",
    district_code: 21,
  },
  {
    name: "Phường Đức Thắng",
    code: 602,
    division_type: "phường",
    codename: "phuong_duc_thang",
    district_code: 21,
  },
  {
    name: "Phường Thụy Phương",
    code: 604,
    division_type: "phường",
    codename: "phuong_thuy_phuong",
    district_code: 21,
  },
  {
    name: "Phường Tây Tựu",
    code: 607,
    division_type: "phường",
    codename: "phuong_tay_tuu",
    district_code: 21,
  },
  {
    name: "Phường Xuân Đỉnh",
    code: 610,
    division_type: "phường",
    codename: "phuong_xuan_dinh",
    district_code: 21,
  },
  {
    name: "Phường Xuân Tảo",
    code: 611,
    division_type: "phường",
    codename: "phuong_xuan_tao",
    district_code: 21,
  },
  {
    name: "Phường Minh Khai",
    code: 613,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 21,
  },
  {
    name: "Phường Cổ Nhuế 1",
    code: 616,
    division_type: "phường",
    codename: "phuong_co_nhue_1",
    district_code: 21,
  },
  {
    name: "Phường Cổ Nhuế 2",
    code: 617,
    division_type: "phường",
    codename: "phuong_co_nhue_2",
    district_code: 21,
  },
  {
    name: "Phường Phú Diễn",
    code: 619,
    division_type: "phường",
    codename: "phuong_phu_dien",
    district_code: 21,
  },
  {
    name: "Phường Phúc Diễn",
    code: 620,
    division_type: "phường",
    codename: "phuong_phuc_dien",
    district_code: 21,
  },
  {
    name: "Thị trấn Chi Đông",
    code: 8973,
    division_type: "thị trấn",
    codename: "thi_tran_chi_dong",
    district_code: 250,
  },
  {
    name: "Xã Đại Thịnh",
    code: 8974,
    division_type: "xã",
    codename: "xa_dai_thinh",
    district_code: 250,
  },
  {
    name: "Xã Kim Hoa",
    code: 8977,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 250,
  },
  {
    name: "Xã Thạch Đà",
    code: 8980,
    division_type: "xã",
    codename: "xa_thach_da",
    district_code: 250,
  },
  {
    name: "Xã Tiến Thắng",
    code: 8983,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 250,
  },
  {
    name: "Xã Tự Lập",
    code: 8986,
    division_type: "xã",
    codename: "xa_tu_lap",
    district_code: 250,
  },
  {
    name: "Thị trấn Quang Minh",
    code: 8989,
    division_type: "thị trấn",
    codename: "thi_tran_quang_minh",
    district_code: 250,
  },
  {
    name: "Xã Thanh Lâm",
    code: 8992,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 250,
  },
  {
    name: "Xã Tam Đồng",
    code: 8995,
    division_type: "xã",
    codename: "xa_tam_dong",
    district_code: 250,
  },
  {
    name: "Xã Liên Mạc",
    code: 8998,
    division_type: "xã",
    codename: "xa_lien_mac",
    district_code: 250,
  },
  {
    name: "Xã Vạn Yên",
    code: 9001,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 250,
  },
  {
    name: "Xã Chu Phan",
    code: 9004,
    division_type: "xã",
    codename: "xa_chu_phan",
    district_code: 250,
  },
  {
    name: "Xã Tiến Thịnh",
    code: 9007,
    division_type: "xã",
    codename: "xa_tien_thinh",
    district_code: 250,
  },
  {
    name: "Xã Mê Linh",
    code: 9010,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 250,
  },
  {
    name: "Xã Văn Khê",
    code: 9013,
    division_type: "xã",
    codename: "xa_van_khe",
    district_code: 250,
  },
  {
    name: "Xã Hoàng Kim",
    code: 9016,
    division_type: "xã",
    codename: "xa_hoang_kim",
    district_code: 250,
  },
  {
    name: "Xã Tiền Phong",
    code: 9019,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 250,
  },
  {
    name: "Xã Tráng Việt",
    code: 9022,
    division_type: "xã",
    codename: "xa_trang_viet",
    district_code: 250,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 9538,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 268,
  },
  {
    name: "Phường Mộ Lao",
    code: 9541,
    division_type: "phường",
    codename: "phuong_mo_lao",
    district_code: 268,
  },
  {
    name: "Phường Văn Quán",
    code: 9542,
    division_type: "phường",
    codename: "phuong_van_quan",
    district_code: 268,
  },
  {
    name: "Phường Vạn Phúc",
    code: 9544,
    division_type: "phường",
    codename: "phuong_van_phuc",
    district_code: 268,
  },
  {
    name: "Phường Yết Kiêu",
    code: 9547,
    division_type: "phường",
    codename: "phuong_yet_kieu",
    district_code: 268,
  },
  {
    name: "Phường Quang Trung",
    code: 9550,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 268,
  },
  {
    name: "Phường La Khê",
    code: 9551,
    division_type: "phường",
    codename: "phuong_la_khe",
    district_code: 268,
  },
  {
    name: "Phường Phú La",
    code: 9552,
    division_type: "phường",
    codename: "phuong_phu_la",
    district_code: 268,
  },
  {
    name: "Phường Phúc La",
    code: 9553,
    division_type: "phường",
    codename: "phuong_phuc_la",
    district_code: 268,
  },
  {
    name: "Phường Hà Cầu",
    code: 9556,
    division_type: "phường",
    codename: "phuong_ha_cau",
    district_code: 268,
  },
  {
    name: "Phường Yên Nghĩa",
    code: 9562,
    division_type: "phường",
    codename: "phuong_yen_nghia",
    district_code: 268,
  },
  {
    name: "Phường Kiến Hưng",
    code: 9565,
    division_type: "phường",
    codename: "phuong_kien_hung",
    district_code: 268,
  },
  {
    name: "Phường Phú Lãm",
    code: 9568,
    division_type: "phường",
    codename: "phuong_phu_lam",
    district_code: 268,
  },
  {
    name: "Phường Phú Lương",
    code: 9571,
    division_type: "phường",
    codename: "phuong_phu_luong",
    district_code: 268,
  },
  {
    name: "Phường Dương Nội",
    code: 9886,
    division_type: "phường",
    codename: "phuong_duong_noi",
    district_code: 268,
  },
  {
    name: "Phường Đồng Mai",
    code: 10117,
    division_type: "phường",
    codename: "phuong_dong_mai",
    district_code: 268,
  },
  {
    name: "Phường Biên Giang",
    code: 10123,
    division_type: "phường",
    codename: "phuong_bien_giang",
    district_code: 268,
  },
  {
    name: "Phường Lê Lợi",
    code: 9574,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 269,
  },
  {
    name: "Phường Phú Thịnh",
    code: 9577,
    division_type: "phường",
    codename: "phuong_phu_thinh",
    district_code: 269,
  },
  {
    name: "Phường Ngô Quyền",
    code: 9580,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 269,
  },
  {
    name: "Phường Quang Trung",
    code: 9583,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 269,
  },
  {
    name: "Phường Sơn Lộc",
    code: 9586,
    division_type: "phường",
    codename: "phuong_son_loc",
    district_code: 269,
  },
  {
    name: "Phường Xuân Khanh",
    code: 9589,
    division_type: "phường",
    codename: "phuong_xuan_khanh",
    district_code: 269,
  },
  {
    name: "Xã Đường Lâm",
    code: 9592,
    division_type: "xã",
    codename: "xa_duong_lam",
    district_code: 269,
  },
  {
    name: "Phường Viên Sơn",
    code: 9595,
    division_type: "phường",
    codename: "phuong_vien_son",
    district_code: 269,
  },
  {
    name: "Xã Xuân Sơn",
    code: 9598,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 269,
  },
  {
    name: "Phường Trung Hưng",
    code: 9601,
    division_type: "phường",
    codename: "phuong_trung_hung",
    district_code: 269,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 9604,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 269,
  },
  {
    name: "Phường Trung Sơn Trầm",
    code: 9607,
    division_type: "phường",
    codename: "phuong_trung_son_tram",
    district_code: 269,
  },
  {
    name: "Xã Kim Sơn",
    code: 9610,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 269,
  },
  {
    name: "Xã Sơn Đông",
    code: 9613,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 269,
  },
  {
    name: "Xã Cổ Đông",
    code: 9616,
    division_type: "xã",
    codename: "xa_co_dong",
    district_code: 269,
  },
  {
    name: "Thị trấn Tây Đằng",
    code: 9619,
    division_type: "thị trấn",
    codename: "thi_tran_tay_dang",
    district_code: 271,
  },
  {
    name: "Xã Phú Cường",
    code: 9625,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 271,
  },
  {
    name: "Xã Cổ Đô",
    code: 9628,
    division_type: "xã",
    codename: "xa_co_do",
    district_code: 271,
  },
  {
    name: "Xã Tản Hồng",
    code: 9631,
    division_type: "xã",
    codename: "xa_tan_hong",
    district_code: 271,
  },
  {
    name: "Xã Vạn Thắng",
    code: 9634,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 271,
  },
  {
    name: "Xã Châu Sơn",
    code: 9637,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 271,
  },
  {
    name: "Xã Phong Vân",
    code: 9640,
    division_type: "xã",
    codename: "xa_phong_van",
    district_code: 271,
  },
  {
    name: "Xã Phú Đông",
    code: 9643,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 271,
  },
  {
    name: "Xã Phú Phương",
    code: 9646,
    division_type: "xã",
    codename: "xa_phu_phuong",
    district_code: 271,
  },
  {
    name: "Xã Phú Châu",
    code: 9649,
    division_type: "xã",
    codename: "xa_phu_chau",
    district_code: 271,
  },
  {
    name: "Xã Thái Hòa",
    code: 9652,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 271,
  },
  {
    name: "Xã Đồng Thái",
    code: 9655,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 271,
  },
  {
    name: "Xã Phú Sơn",
    code: 9658,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 271,
  },
  {
    name: "Xã Minh Châu",
    code: 9661,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 271,
  },
  {
    name: "Xã Vật Lại",
    code: 9664,
    division_type: "xã",
    codename: "xa_vat_lai",
    district_code: 271,
  },
  {
    name: "Xã Chu Minh",
    code: 9667,
    division_type: "xã",
    codename: "xa_chu_minh",
    district_code: 271,
  },
  {
    name: "Xã Tòng Bạt",
    code: 9670,
    division_type: "xã",
    codename: "xa_tong_bat",
    district_code: 271,
  },
  {
    name: "Xã Cẩm Lĩnh",
    code: 9673,
    division_type: "xã",
    codename: "xa_cam_linh",
    district_code: 271,
  },
  {
    name: "Xã Sơn Đà",
    code: 9676,
    division_type: "xã",
    codename: "xa_son_da",
    district_code: 271,
  },
  {
    name: "Xã Đông Quang",
    code: 9679,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 271,
  },
  {
    name: "Xã Tiên Phong",
    code: 9682,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 271,
  },
  {
    name: "Xã Thụy An",
    code: 9685,
    division_type: "xã",
    codename: "xa_thuy_an",
    district_code: 271,
  },
  {
    name: "Xã Cam Thượng",
    code: 9688,
    division_type: "xã",
    codename: "xa_cam_thuong",
    district_code: 271,
  },
  {
    name: "Xã Thuần Mỹ",
    code: 9691,
    division_type: "xã",
    codename: "xa_thuan_my",
    district_code: 271,
  },
  {
    name: "Xã Tản Lĩnh",
    code: 9694,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 271,
  },
  {
    name: "Xã Ba Trại",
    code: 9697,
    division_type: "xã",
    codename: "xa_ba_trai",
    district_code: 271,
  },
  {
    name: "Xã Minh Quang",
    code: 9700,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 271,
  },
  {
    name: "Xã Ba Vì",
    code: 9703,
    division_type: "xã",
    codename: "xa_ba_vi",
    district_code: 271,
  },
  {
    name: "Xã Vân Hòa",
    code: 9706,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 271,
  },
  {
    name: "Xã Yên Bài",
    code: 9709,
    division_type: "xã",
    codename: "xa_yen_bai",
    district_code: 271,
  },
  {
    name: "Xã Khánh Thượng",
    code: 9712,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 271,
  },
  {
    name: "Thị trấn Phúc Thọ",
    code: 9715,
    division_type: "thị trấn",
    codename: "thi_tran_phuc_tho",
    district_code: 272,
  },
  {
    name: "Xã Vân Hà",
    code: 9718,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 272,
  },
  {
    name: "Xã Vân Phúc",
    code: 9721,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 272,
  },
  {
    name: "Xã Vân Nam",
    code: 9724,
    division_type: "xã",
    codename: "xa_van_nam",
    district_code: 272,
  },
  {
    name: "Xã Xuân Đình",
    code: 9727,
    division_type: "xã",
    codename: "xa_xuan_dinh",
    district_code: 272,
  },
  {
    name: "Xã Sen Phương",
    code: 9733,
    division_type: "xã",
    codename: "xa_sen_phuong",
    district_code: 272,
  },
  {
    name: "Xã Võng Xuyên",
    code: 9739,
    division_type: "xã",
    codename: "xa_vong_xuyen",
    district_code: 272,
  },
  {
    name: "Xã Thọ Lộc",
    code: 9742,
    division_type: "xã",
    codename: "xa_tho_loc",
    district_code: 272,
  },
  {
    name: "Xã Long Xuyên",
    code: 9745,
    division_type: "xã",
    codename: "xa_long_xuyen",
    district_code: 272,
  },
  {
    name: "Xã Thượng Cốc",
    code: 9748,
    division_type: "xã",
    codename: "xa_thuong_coc",
    district_code: 272,
  },
  {
    name: "Xã Hát Môn",
    code: 9751,
    division_type: "xã",
    codename: "xa_hat_mon",
    district_code: 272,
  },
  {
    name: "Xã Tích Giang",
    code: 9754,
    division_type: "xã",
    codename: "xa_tich_giang",
    district_code: 272,
  },
  {
    name: "Xã Thanh Đa",
    code: 9757,
    division_type: "xã",
    codename: "xa_thanh_da",
    district_code: 272,
  },
  {
    name: "Xã Trạch Mỹ Lộc",
    code: 9760,
    division_type: "xã",
    codename: "xa_trach_my_loc",
    district_code: 272,
  },
  {
    name: "Xã Phúc Hòa",
    code: 9763,
    division_type: "xã",
    codename: "xa_phuc_hoa",
    district_code: 272,
  },
  {
    name: "Xã Ngọc Tảo",
    code: 9766,
    division_type: "xã",
    codename: "xa_ngoc_tao",
    district_code: 272,
  },
  {
    name: "Xã Phụng Thượng",
    code: 9769,
    division_type: "xã",
    codename: "xa_phung_thuong",
    district_code: 272,
  },
  {
    name: "Xã Tam Thuấn",
    code: 9772,
    division_type: "xã",
    codename: "xa_tam_thuan",
    district_code: 272,
  },
  {
    name: "Xã Tam Hiệp",
    code: 9775,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 272,
  },
  {
    name: "Xã Hiệp Thuận",
    code: 9778,
    division_type: "xã",
    codename: "xa_hiep_thuan",
    district_code: 272,
  },
  {
    name: "Xã Liên Hiệp",
    code: 9781,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 272,
  },
  {
    name: "Thị trấn Phùng",
    code: 9784,
    division_type: "thị trấn",
    codename: "thi_tran_phung",
    district_code: 273,
  },
  {
    name: "Xã Trung Châu",
    code: 9787,
    division_type: "xã",
    codename: "xa_trung_chau",
    district_code: 273,
  },
  {
    name: "Xã Thọ An",
    code: 9790,
    division_type: "xã",
    codename: "xa_tho_an",
    district_code: 273,
  },
  {
    name: "Xã Thọ Xuân",
    code: 9793,
    division_type: "xã",
    codename: "xa_tho_xuan",
    district_code: 273,
  },
  {
    name: "Xã Hồng Hà",
    code: 9796,
    division_type: "xã",
    codename: "xa_hong_ha",
    district_code: 273,
  },
  {
    name: "Xã Liên Hồng",
    code: 9799,
    division_type: "xã",
    codename: "xa_lien_hong",
    district_code: 273,
  },
  {
    name: "Xã Liên Hà",
    code: 9802,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 273,
  },
  {
    name: "Xã Hạ Mỗ",
    code: 9805,
    division_type: "xã",
    codename: "xa_ha_mo",
    district_code: 273,
  },
  {
    name: "Xã Liên Trung",
    code: 9808,
    division_type: "xã",
    codename: "xa_lien_trung",
    district_code: 273,
  },
  {
    name: "Xã Phương Đình",
    code: 9811,
    division_type: "xã",
    codename: "xa_phuong_dinh",
    district_code: 273,
  },
  {
    name: "Xã Thượng Mỗ",
    code: 9814,
    division_type: "xã",
    codename: "xa_thuong_mo",
    district_code: 273,
  },
  {
    name: "Xã Tân Hội",
    code: 9817,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 273,
  },
  {
    name: "Xã Tân Lập",
    code: 9820,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 273,
  },
  {
    name: "Xã Đan Phượng",
    code: 9823,
    division_type: "xã",
    codename: "xa_dan_phuong",
    district_code: 273,
  },
  {
    name: "Xã Đồng Tháp",
    code: 9826,
    division_type: "xã",
    codename: "xa_dong_thap",
    district_code: 273,
  },
  {
    name: "Xã Song Phượng",
    code: 9829,
    division_type: "xã",
    codename: "xa_song_phuong",
    district_code: 273,
  },
  {
    name: "Thị trấn Trạm Trôi",
    code: 9832,
    division_type: "thị trấn",
    codename: "thi_tran_tram_troi",
    district_code: 274,
  },
  {
    name: "Xã Đức Thượng",
    code: 9835,
    division_type: "xã",
    codename: "xa_duc_thuong",
    district_code: 274,
  },
  {
    name: "Xã Minh Khai",
    code: 9838,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 274,
  },
  {
    name: "Xã Dương Liễu",
    code: 9841,
    division_type: "xã",
    codename: "xa_duong_lieu",
    district_code: 274,
  },
  {
    name: "Xã Di Trạch",
    code: 9844,
    division_type: "xã",
    codename: "xa_di_trach",
    district_code: 274,
  },
  {
    name: "Xã Đức Giang",
    code: 9847,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 274,
  },
  {
    name: "Xã Cát Quế",
    code: 9850,
    division_type: "xã",
    codename: "xa_cat_que",
    district_code: 274,
  },
  {
    name: "Xã Kim Chung",
    code: 9853,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 274,
  },
  {
    name: "Xã Yên Sở",
    code: 9856,
    division_type: "xã",
    codename: "xa_yen_so",
    district_code: 274,
  },
  {
    name: "Xã Sơn Đồng",
    code: 9859,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 274,
  },
  {
    name: "Xã Vân Canh",
    code: 9862,
    division_type: "xã",
    codename: "xa_van_canh",
    district_code: 274,
  },
  {
    name: "Xã Đắc Sở",
    code: 9865,
    division_type: "xã",
    codename: "xa_dac_so",
    district_code: 274,
  },
  {
    name: "Xã Lại Yên",
    code: 9868,
    division_type: "xã",
    codename: "xa_lai_yen",
    district_code: 274,
  },
  {
    name: "Xã Tiền Yên",
    code: 9871,
    division_type: "xã",
    codename: "xa_tien_yen",
    district_code: 274,
  },
  {
    name: "Xã Song Phương",
    code: 9874,
    division_type: "xã",
    codename: "xa_song_phuong",
    district_code: 274,
  },
  {
    name: "Xã An Khánh",
    code: 9877,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 274,
  },
  {
    name: "Xã An Thượng",
    code: 9880,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 274,
  },
  {
    name: "Xã Vân Côn",
    code: 9883,
    division_type: "xã",
    codename: "xa_van_con",
    district_code: 274,
  },
  {
    name: "Xã La Phù",
    code: 9889,
    division_type: "xã",
    codename: "xa_la_phu",
    district_code: 274,
  },
  {
    name: "Xã Đông La",
    code: 9892,
    division_type: "xã",
    codename: "xa_dong_la",
    district_code: 274,
  },
  {
    name: "Xã Đông Xuân",
    code: 4939,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 275,
  },
  {
    name: "Thị trấn Quốc Oai",
    code: 9895,
    division_type: "thị trấn",
    codename: "thi_tran_quoc_oai",
    district_code: 275,
  },
  {
    name: "Xã Sài Sơn",
    code: 9898,
    division_type: "xã",
    codename: "xa_sai_son",
    district_code: 275,
  },
  {
    name: "Xã Phượng Cách",
    code: 9901,
    division_type: "xã",
    codename: "xa_phuong_cach",
    district_code: 275,
  },
  {
    name: "Xã Yên Sơn",
    code: 9904,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 275,
  },
  {
    name: "Xã Ngọc Liệp",
    code: 9907,
    division_type: "xã",
    codename: "xa_ngoc_liep",
    district_code: 275,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 9910,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 275,
  },
  {
    name: "Xã Liệp Tuyết",
    code: 9913,
    division_type: "xã",
    codename: "xa_liep_tuyet",
    district_code: 275,
  },
  {
    name: "Xã Thạch Thán",
    code: 9916,
    division_type: "xã",
    codename: "xa_thach_than",
    district_code: 275,
  },
  {
    name: "Xã Đồng Quang",
    code: 9919,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 275,
  },
  {
    name: "Xã Phú Cát",
    code: 9922,
    division_type: "xã",
    codename: "xa_phu_cat",
    district_code: 275,
  },
  {
    name: "Xã Tuyết Nghĩa",
    code: 9925,
    division_type: "xã",
    codename: "xa_tuyet_nghia",
    district_code: 275,
  },
  {
    name: "Xã Nghĩa Hương",
    code: 9928,
    division_type: "xã",
    codename: "xa_nghia_huong",
    district_code: 275,
  },
  {
    name: "Xã Cộng Hòa",
    code: 9931,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 275,
  },
  {
    name: "Xã Tân Phú",
    code: 9934,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 275,
  },
  {
    name: "Xã Đại Thành",
    code: 9937,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 275,
  },
  {
    name: "Xã Phú Mãn",
    code: 9940,
    division_type: "xã",
    codename: "xa_phu_man",
    district_code: 275,
  },
  {
    name: "Xã Cấn Hữu",
    code: 9943,
    division_type: "xã",
    codename: "xa_can_huu",
    district_code: 275,
  },
  {
    name: "Xã Tân Hòa",
    code: 9946,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 275,
  },
  {
    name: "Xã Hòa Thạch",
    code: 9949,
    division_type: "xã",
    codename: "xa_hoa_thach",
    district_code: 275,
  },
  {
    name: "Xã Đông Yên",
    code: 9952,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 275,
  },
  {
    name: "Xã Yên Trung",
    code: 4927,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 276,
  },
  {
    name: "Xã Yên Bình",
    code: 4930,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 276,
  },
  {
    name: "Xã Tiến Xuân",
    code: 4936,
    division_type: "xã",
    codename: "xa_tien_xuan",
    district_code: 276,
  },
  {
    name: "Thị trấn Liên Quan",
    code: 9955,
    division_type: "thị trấn",
    codename: "thi_tran_lien_quan",
    district_code: 276,
  },
  {
    name: "Xã Đại Đồng",
    code: 9958,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 276,
  },
  {
    name: "Xã Cẩm Yên",
    code: 9961,
    division_type: "xã",
    codename: "xa_cam_yen",
    district_code: 276,
  },
  {
    name: "Xã Lại Thượng",
    code: 9964,
    division_type: "xã",
    codename: "xa_lai_thuong",
    district_code: 276,
  },
  {
    name: "Xã Phú Kim",
    code: 9967,
    division_type: "xã",
    codename: "xa_phu_kim",
    district_code: 276,
  },
  {
    name: "Xã Hương Ngải",
    code: 9970,
    division_type: "xã",
    codename: "xa_huong_ngai",
    district_code: 276,
  },
  {
    name: "Xã Canh Nậu",
    code: 9973,
    division_type: "xã",
    codename: "xa_canh_nau",
    district_code: 276,
  },
  {
    name: "Xã Kim Quan",
    code: 9976,
    division_type: "xã",
    codename: "xa_kim_quan",
    district_code: 276,
  },
  {
    name: "Xã Dị Nậu",
    code: 9979,
    division_type: "xã",
    codename: "xa_di_nau",
    district_code: 276,
  },
  {
    name: "Xã Bình Yên",
    code: 9982,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 276,
  },
  {
    name: "Xã Chàng Sơn",
    code: 9985,
    division_type: "xã",
    codename: "xa_chang_son",
    district_code: 276,
  },
  {
    name: "Xã Thạch Hoà",
    code: 9988,
    division_type: "xã",
    codename: "xa_thach_hoa",
    district_code: 276,
  },
  {
    name: "Xã Cần Kiệm",
    code: 9991,
    division_type: "xã",
    codename: "xa_can_kiem",
    district_code: 276,
  },
  {
    name: "Xã Hữu Bằng",
    code: 9994,
    division_type: "xã",
    codename: "xa_huu_bang",
    district_code: 276,
  },
  {
    name: "Xã Phùng Xá",
    code: 9997,
    division_type: "xã",
    codename: "xa_phung_xa",
    district_code: 276,
  },
  {
    name: "Xã Tân Xã",
    code: 10000,
    division_type: "xã",
    codename: "xa_tan_xa",
    district_code: 276,
  },
  {
    name: "Xã Thạch Xá",
    code: 10003,
    division_type: "xã",
    codename: "xa_thach_xa",
    district_code: 276,
  },
  {
    name: "Xã Bình Phú",
    code: 10006,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 276,
  },
  {
    name: "Xã Hạ Bằng",
    code: 10009,
    division_type: "xã",
    codename: "xa_ha_bang",
    district_code: 276,
  },
  {
    name: "Xã Đồng Trúc",
    code: 10012,
    division_type: "xã",
    codename: "xa_dong_truc",
    district_code: 276,
  },
  {
    name: "Thị trấn Chúc Sơn",
    code: 10015,
    division_type: "thị trấn",
    codename: "thi_tran_chuc_son",
    district_code: 277,
  },
  {
    name: "Thị trấn Xuân Mai",
    code: 10018,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_mai",
    district_code: 277,
  },
  {
    name: "Xã Phụng Châu",
    code: 10021,
    division_type: "xã",
    codename: "xa_phung_chau",
    district_code: 277,
  },
  {
    name: "Xã Tiên Phương",
    code: 10024,
    division_type: "xã",
    codename: "xa_tien_phuong",
    district_code: 277,
  },
  {
    name: "Xã Đông Sơn",
    code: 10027,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 277,
  },
  {
    name: "Xã Đông Phương Yên",
    code: 10030,
    division_type: "xã",
    codename: "xa_dong_phuong_yen",
    district_code: 277,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 10033,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 277,
  },
  {
    name: "Xã Trường Yên",
    code: 10039,
    division_type: "xã",
    codename: "xa_truong_yen",
    district_code: 277,
  },
  {
    name: "Xã Ngọc Hòa",
    code: 10042,
    division_type: "xã",
    codename: "xa_ngoc_hoa",
    district_code: 277,
  },
  {
    name: "Xã Thủy Xuân Tiên",
    code: 10045,
    division_type: "xã",
    codename: "xa_thuy_xuan_tien",
    district_code: 277,
  },
  {
    name: "Xã Thanh Bình",
    code: 10048,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 277,
  },
  {
    name: "Xã Trung Hòa",
    code: 10051,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 277,
  },
  {
    name: "Xã Đại Yên",
    code: 10054,
    division_type: "xã",
    codename: "xa_dai_yen",
    district_code: 277,
  },
  {
    name: "Xã Thụy Hương",
    code: 10057,
    division_type: "xã",
    codename: "xa_thuy_huong",
    district_code: 277,
  },
  {
    name: "Xã Tốt Động",
    code: 10060,
    division_type: "xã",
    codename: "xa_tot_dong",
    district_code: 277,
  },
  {
    name: "Xã Lam Điền",
    code: 10063,
    division_type: "xã",
    codename: "xa_lam_dien",
    district_code: 277,
  },
  {
    name: "Xã Tân Tiến",
    code: 10066,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 277,
  },
  {
    name: "Xã Nam Phương Tiến",
    code: 10069,
    division_type: "xã",
    codename: "xa_nam_phuong_tien",
    district_code: 277,
  },
  {
    name: "Xã Hợp Đồng",
    code: 10072,
    division_type: "xã",
    codename: "xa_hop_dong",
    district_code: 277,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 10075,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 277,
  },
  {
    name: "Xã Hoàng Diệu",
    code: 10078,
    division_type: "xã",
    codename: "xa_hoang_dieu",
    district_code: 277,
  },
  {
    name: "Xã Hữu Văn",
    code: 10081,
    division_type: "xã",
    codename: "xa_huu_van",
    district_code: 277,
  },
  {
    name: "Xã Quảng Bị",
    code: 10084,
    division_type: "xã",
    codename: "xa_quang_bi",
    district_code: 277,
  },
  {
    name: "Xã Mỹ Lương",
    code: 10087,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 277,
  },
  {
    name: "Xã Thượng Vực",
    code: 10090,
    division_type: "xã",
    codename: "xa_thuong_vuc",
    district_code: 277,
  },
  {
    name: "Xã Hồng Phong",
    code: 10093,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 277,
  },
  {
    name: "Xã Đồng Phú",
    code: 10096,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 277,
  },
  {
    name: "Xã Trần Phú",
    code: 10099,
    division_type: "xã",
    codename: "xa_tran_phu",
    district_code: 277,
  },
  {
    name: "Xã Văn Võ",
    code: 10102,
    division_type: "xã",
    codename: "xa_van_vo",
    district_code: 277,
  },
  {
    name: "Xã Đồng Lạc",
    code: 10105,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 277,
  },
  {
    name: "Xã Hòa Chính",
    code: 10108,
    division_type: "xã",
    codename: "xa_hoa_chinh",
    district_code: 277,
  },
  {
    name: "Xã Phú Nam An",
    code: 10111,
    division_type: "xã",
    codename: "xa_phu_nam_an",
    district_code: 277,
  },
  {
    name: "Thị trấn Kim Bài",
    code: 10114,
    division_type: "thị trấn",
    codename: "thi_tran_kim_bai",
    district_code: 278,
  },
  {
    name: "Xã Cự Khê",
    code: 10120,
    division_type: "xã",
    codename: "xa_cu_khe",
    district_code: 278,
  },
  {
    name: "Xã Bích Hòa",
    code: 10126,
    division_type: "xã",
    codename: "xa_bich_hoa",
    district_code: 278,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 10129,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 278,
  },
  {
    name: "Xã Cao Viên",
    code: 10132,
    division_type: "xã",
    codename: "xa_cao_vien",
    district_code: 278,
  },
  {
    name: "Xã Bình Minh",
    code: 10135,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 278,
  },
  {
    name: "Xã Tam Hưng",
    code: 10138,
    division_type: "xã",
    codename: "xa_tam_hung",
    district_code: 278,
  },
  {
    name: "Xã Thanh Cao",
    code: 10141,
    division_type: "xã",
    codename: "xa_thanh_cao",
    district_code: 278,
  },
  {
    name: "Xã Thanh Thùy",
    code: 10144,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 278,
  },
  {
    name: "Xã Thanh Mai",
    code: 10147,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 278,
  },
  {
    name: "Xã Thanh Văn",
    code: 10150,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 278,
  },
  {
    name: "Xã Đỗ Động",
    code: 10153,
    division_type: "xã",
    codename: "xa_do_dong",
    district_code: 278,
  },
  {
    name: "Xã Kim An",
    code: 10156,
    division_type: "xã",
    codename: "xa_kim_an",
    district_code: 278,
  },
  {
    name: "Xã Kim Thư",
    code: 10159,
    division_type: "xã",
    codename: "xa_kim_thu",
    district_code: 278,
  },
  {
    name: "Xã Phương Trung",
    code: 10162,
    division_type: "xã",
    codename: "xa_phuong_trung",
    district_code: 278,
  },
  {
    name: "Xã Tân Ước",
    code: 10165,
    division_type: "xã",
    codename: "xa_tan_uoc",
    district_code: 278,
  },
  {
    name: "Xã Dân Hòa",
    code: 10168,
    division_type: "xã",
    codename: "xa_dan_hoa",
    district_code: 278,
  },
  {
    name: "Xã Liên Châu",
    code: 10171,
    division_type: "xã",
    codename: "xa_lien_chau",
    district_code: 278,
  },
  {
    name: "Xã Cao Dương",
    code: 10174,
    division_type: "xã",
    codename: "xa_cao_duong",
    district_code: 278,
  },
  {
    name: "Xã Xuân Dương",
    code: 10177,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 278,
  },
  {
    name: "Xã Hồng Dương",
    code: 10180,
    division_type: "xã",
    codename: "xa_hong_duong",
    district_code: 278,
  },
  {
    name: "Thị trấn Thường Tín",
    code: 10183,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_tin",
    district_code: 279,
  },
  {
    name: "Xã Ninh Sở",
    code: 10186,
    division_type: "xã",
    codename: "xa_ninh_so",
    district_code: 279,
  },
  {
    name: "Xã Nhị Khê",
    code: 10189,
    division_type: "xã",
    codename: "xa_nhi_khe",
    district_code: 279,
  },
  {
    name: "Xã Duyên Thái",
    code: 10192,
    division_type: "xã",
    codename: "xa_duyen_thai",
    district_code: 279,
  },
  {
    name: "Xã Khánh Hà",
    code: 10195,
    division_type: "xã",
    codename: "xa_khanh_ha",
    district_code: 279,
  },
  {
    name: "Xã Hòa Bình",
    code: 10198,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 279,
  },
  {
    name: "Xã Văn Bình",
    code: 10201,
    division_type: "xã",
    codename: "xa_van_binh",
    district_code: 279,
  },
  {
    name: "Xã Hiền Giang",
    code: 10204,
    division_type: "xã",
    codename: "xa_hien_giang",
    district_code: 279,
  },
  {
    name: "Xã Hồng Vân",
    code: 10207,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 279,
  },
  {
    name: "Xã Vân Tảo",
    code: 10210,
    division_type: "xã",
    codename: "xa_van_tao",
    district_code: 279,
  },
  {
    name: "Xã Liên Phương",
    code: 10213,
    division_type: "xã",
    codename: "xa_lien_phuong",
    district_code: 279,
  },
  {
    name: "Xã Văn Phú",
    code: 10216,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 279,
  },
  {
    name: "Xã Tự Nhiên",
    code: 10219,
    division_type: "xã",
    codename: "xa_tu_nhien",
    district_code: 279,
  },
  {
    name: "Xã Tiền Phong",
    code: 10222,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 279,
  },
  {
    name: "Xã Hà Hồi",
    code: 10225,
    division_type: "xã",
    codename: "xa_ha_hoi",
    district_code: 279,
  },
  {
    name: "Xã Thư Phú",
    code: 10228,
    division_type: "xã",
    codename: "xa_thu_phu",
    district_code: 279,
  },
  {
    name: "Xã Nguyễn Trãi",
    code: 10231,
    division_type: "xã",
    codename: "xa_nguyen_trai",
    district_code: 279,
  },
  {
    name: "Xã Quất Động",
    code: 10234,
    division_type: "xã",
    codename: "xa_quat_dong",
    district_code: 279,
  },
  {
    name: "Xã Chương Dương",
    code: 10237,
    division_type: "xã",
    codename: "xa_chuong_duong",
    district_code: 279,
  },
  {
    name: "Xã Tân Minh",
    code: 10240,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 279,
  },
  {
    name: "Xã Lê Lợi",
    code: 10243,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 279,
  },
  {
    name: "Xã Thắng Lợi",
    code: 10246,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 279,
  },
  {
    name: "Xã Dũng Tiến",
    code: 10249,
    division_type: "xã",
    codename: "xa_dung_tien",
    district_code: 279,
  },
  {
    name: "Xã Thống Nhất",
    code: 10252,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 279,
  },
  {
    name: "Xã Nghiêm Xuyên",
    code: 10255,
    division_type: "xã",
    codename: "xa_nghiem_xuyen",
    district_code: 279,
  },
  {
    name: "Xã Tô Hiệu",
    code: 10258,
    division_type: "xã",
    codename: "xa_to_hieu",
    district_code: 279,
  },
  {
    name: "Xã Văn Tự",
    code: 10261,
    division_type: "xã",
    codename: "xa_van_tu",
    district_code: 279,
  },
  {
    name: "Xã Vạn Điểm",
    code: 10264,
    division_type: "xã",
    codename: "xa_van_diem",
    district_code: 279,
  },
  {
    name: "Xã Minh Cường",
    code: 10267,
    division_type: "xã",
    codename: "xa_minh_cuong",
    district_code: 279,
  },
  {
    name: "Thị trấn Phú Minh",
    code: 10270,
    division_type: "thị trấn",
    codename: "thi_tran_phu_minh",
    district_code: 280,
  },
  {
    name: "Thị trấn Phú Xuyên",
    code: 10273,
    division_type: "thị trấn",
    codename: "thi_tran_phu_xuyen",
    district_code: 280,
  },
  {
    name: "Xã Hồng Minh",
    code: 10276,
    division_type: "xã",
    codename: "xa_hong_minh",
    district_code: 280,
  },
  {
    name: "Xã Phượng Dực",
    code: 10279,
    division_type: "xã",
    codename: "xa_phuong_duc",
    district_code: 280,
  },
  {
    name: "Xã Nam Tiến",
    code: 10282,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 280,
  },
  {
    name: "Xã Tri Trung",
    code: 10288,
    division_type: "xã",
    codename: "xa_tri_trung",
    district_code: 280,
  },
  {
    name: "Xã Đại Thắng",
    code: 10291,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 280,
  },
  {
    name: "Xã Phú Túc",
    code: 10294,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 280,
  },
  {
    name: "Xã Văn Hoàng",
    code: 10297,
    division_type: "xã",
    codename: "xa_van_hoang",
    district_code: 280,
  },
  {
    name: "Xã Hồng Thái",
    code: 10300,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 280,
  },
  {
    name: "Xã Hoàng Long",
    code: 10303,
    division_type: "xã",
    codename: "xa_hoang_long",
    district_code: 280,
  },
  {
    name: "Xã Quang Trung",
    code: 10306,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 280,
  },
  {
    name: "Xã Nam Phong",
    code: 10309,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 280,
  },
  {
    name: "Xã Nam Triều",
    code: 10312,
    division_type: "xã",
    codename: "xa_nam_trieu",
    district_code: 280,
  },
  {
    name: "Xã Tân Dân",
    code: 10315,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 280,
  },
  {
    name: "Xã Sơn Hà",
    code: 10318,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 280,
  },
  {
    name: "Xã Chuyên Mỹ",
    code: 10321,
    division_type: "xã",
    codename: "xa_chuyen_my",
    district_code: 280,
  },
  {
    name: "Xã Khai Thái",
    code: 10324,
    division_type: "xã",
    codename: "xa_khai_thai",
    district_code: 280,
  },
  {
    name: "Xã Phúc Tiến",
    code: 10327,
    division_type: "xã",
    codename: "xa_phuc_tien",
    district_code: 280,
  },
  {
    name: "Xã Vân Từ",
    code: 10330,
    division_type: "xã",
    codename: "xa_van_tu",
    district_code: 280,
  },
  {
    name: "Xã Tri Thủy",
    code: 10333,
    division_type: "xã",
    codename: "xa_tri_thuy",
    district_code: 280,
  },
  {
    name: "Xã Đại Xuyên",
    code: 10336,
    division_type: "xã",
    codename: "xa_dai_xuyen",
    district_code: 280,
  },
  {
    name: "Xã Phú Yên",
    code: 10339,
    division_type: "xã",
    codename: "xa_phu_yen",
    district_code: 280,
  },
  {
    name: "Xã Bạch Hạ",
    code: 10342,
    division_type: "xã",
    codename: "xa_bach_ha",
    district_code: 280,
  },
  {
    name: "Xã Quang Lãng",
    code: 10345,
    division_type: "xã",
    codename: "xa_quang_lang",
    district_code: 280,
  },
  {
    name: "Xã Châu Can",
    code: 10348,
    division_type: "xã",
    codename: "xa_chau_can",
    district_code: 280,
  },
  {
    name: "Xã Minh Tân",
    code: 10351,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 280,
  },
  {
    name: "Thị trấn Vân Đình",
    code: 10354,
    division_type: "thị trấn",
    codename: "thi_tran_van_dinh",
    district_code: 281,
  },
  {
    name: "Xã Viên An",
    code: 10357,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 281,
  },
  {
    name: "Xã Viên Nội",
    code: 10360,
    division_type: "xã",
    codename: "xa_vien_noi",
    district_code: 281,
  },
  {
    name: "Xã Hoa Sơn",
    code: 10363,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 281,
  },
  {
    name: "Xã Quảng Phú Cầu",
    code: 10366,
    division_type: "xã",
    codename: "xa_quang_phu_cau",
    district_code: 281,
  },
  {
    name: "Xã Trường Thịnh",
    code: 10369,
    division_type: "xã",
    codename: "xa_truong_thinh",
    district_code: 281,
  },
  {
    name: "Xã Cao Thành",
    code: 10372,
    division_type: "xã",
    codename: "xa_cao_thanh",
    district_code: 281,
  },
  {
    name: "Xã Liên Bạt",
    code: 10375,
    division_type: "xã",
    codename: "xa_lien_bat",
    district_code: 281,
  },
  {
    name: "Xã Sơn Công",
    code: 10378,
    division_type: "xã",
    codename: "xa_son_cong",
    district_code: 281,
  },
  {
    name: "Xã Đồng Tiến",
    code: 10381,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 281,
  },
  {
    name: "Xã Phương Tú",
    code: 10384,
    division_type: "xã",
    codename: "xa_phuong_tu",
    district_code: 281,
  },
  {
    name: "Xã Trung Tú",
    code: 10387,
    division_type: "xã",
    codename: "xa_trung_tu",
    district_code: 281,
  },
  {
    name: "Xã Đồng Tân",
    code: 10390,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 281,
  },
  {
    name: "Xã Tảo Dương Văn",
    code: 10393,
    division_type: "xã",
    codename: "xa_tao_duong_van",
    district_code: 281,
  },
  {
    name: "Xã Vạn Thái",
    code: 10396,
    division_type: "xã",
    codename: "xa_van_thai",
    district_code: 281,
  },
  {
    name: "Xã Minh Đức",
    code: 10399,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 281,
  },
  {
    name: "Xã Hòa Lâm",
    code: 10402,
    division_type: "xã",
    codename: "xa_hoa_lam",
    district_code: 281,
  },
  {
    name: "Xã Hòa Xá",
    code: 10405,
    division_type: "xã",
    codename: "xa_hoa_xa",
    district_code: 281,
  },
  {
    name: "Xã Trầm Lộng",
    code: 10408,
    division_type: "xã",
    codename: "xa_tram_long",
    district_code: 281,
  },
  {
    name: "Xã Kim Đường",
    code: 10411,
    division_type: "xã",
    codename: "xa_kim_duong",
    district_code: 281,
  },
  {
    name: "Xã Hòa Nam",
    code: 10414,
    division_type: "xã",
    codename: "xa_hoa_nam",
    district_code: 281,
  },
  {
    name: "Xã Hòa Phú",
    code: 10417,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 281,
  },
  {
    name: "Xã Đội Bình",
    code: 10420,
    division_type: "xã",
    codename: "xa_doi_binh",
    district_code: 281,
  },
  {
    name: "Xã Đại Hùng",
    code: 10423,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 281,
  },
  {
    name: "Xã Đông Lỗ",
    code: 10426,
    division_type: "xã",
    codename: "xa_dong_lo",
    district_code: 281,
  },
  {
    name: "Xã Phù Lưu",
    code: 10429,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 281,
  },
  {
    name: "Xã Đại Cường",
    code: 10432,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 281,
  },
  {
    name: "Xã Lưu Hoàng",
    code: 10435,
    division_type: "xã",
    codename: "xa_luu_hoang",
    district_code: 281,
  },
  {
    name: "Xã Hồng Quang",
    code: 10438,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 281,
  },
  {
    name: "Thị trấn Đại Nghĩa",
    code: 10441,
    division_type: "thị trấn",
    codename: "thi_tran_dai_nghia",
    district_code: 282,
  },
  {
    name: "Xã Đồng Tâm",
    code: 10444,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 282,
  },
  {
    name: "Xã Thượng Lâm",
    code: 10447,
    division_type: "xã",
    codename: "xa_thuong_lam",
    district_code: 282,
  },
  {
    name: "Xã Tuy Lai",
    code: 10450,
    division_type: "xã",
    codename: "xa_tuy_lai",
    district_code: 282,
  },
  {
    name: "Xã Phúc Lâm",
    code: 10453,
    division_type: "xã",
    codename: "xa_phuc_lam",
    district_code: 282,
  },
  {
    name: "Xã Mỹ Thành",
    code: 10456,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 282,
  },
  {
    name: "Xã Bột Xuyên",
    code: 10459,
    division_type: "xã",
    codename: "xa_bot_xuyen",
    district_code: 282,
  },
  {
    name: "Xã An Mỹ",
    code: 10462,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 282,
  },
  {
    name: "Xã Hồng Sơn",
    code: 10465,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 282,
  },
  {
    name: "Xã Lê Thanh",
    code: 10468,
    division_type: "xã",
    codename: "xa_le_thanh",
    district_code: 282,
  },
  {
    name: "Xã Xuy Xá",
    code: 10471,
    division_type: "xã",
    codename: "xa_xuy_xa",
    district_code: 282,
  },
  {
    name: "Xã Phùng Xá",
    code: 10474,
    division_type: "xã",
    codename: "xa_phung_xa",
    district_code: 282,
  },
  {
    name: "Xã Phù Lưu Tế",
    code: 10477,
    division_type: "xã",
    codename: "xa_phu_luu_te",
    district_code: 282,
  },
  {
    name: "Xã Đại Hưng",
    code: 10480,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 282,
  },
  {
    name: "Xã Vạn Kim",
    code: 10483,
    division_type: "xã",
    codename: "xa_van_kim",
    district_code: 282,
  },
  {
    name: "Xã Đốc Tín",
    code: 10486,
    division_type: "xã",
    codename: "xa_doc_tin",
    district_code: 282,
  },
  {
    name: "Xã Hương Sơn",
    code: 10489,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 282,
  },
  {
    name: "Xã Hùng Tiến",
    code: 10492,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 282,
  },
  {
    name: "Xã An Tiến",
    code: 10495,
    division_type: "xã",
    codename: "xa_an_tien",
    district_code: 282,
  },
  {
    name: "Xã Hợp Tiến",
    code: 10498,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 282,
  },
  {
    name: "Xã Hợp Thanh",
    code: 10501,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 282,
  },
  {
    name: "Xã An Phú",
    code: 10504,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 282,
  },
  {
    name: "Phường Quang Trung",
    code: 688,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 24,
  },
  {
    name: "Phường Trần Phú",
    code: 691,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 24,
  },
  {
    name: "Phường Ngọc Hà",
    code: 692,
    division_type: "phường",
    codename: "phuong_ngoc_ha",
    district_code: 24,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 694,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 24,
  },
  {
    name: "Phường Minh Khai",
    code: 697,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 24,
  },
  {
    name: "Xã Ngọc Đường",
    code: 700,
    division_type: "xã",
    codename: "xa_ngoc_duong",
    district_code: 24,
  },
  {
    name: "Xã Phương Độ",
    code: 946,
    division_type: "xã",
    codename: "xa_phuong_do",
    district_code: 24,
  },
  {
    name: "Xã Phương Thiện",
    code: 949,
    division_type: "xã",
    codename: "xa_phuong_thien",
    district_code: 24,
  },
  {
    name: "Thị trấn Phó Bảng",
    code: 712,
    division_type: "thị trấn",
    codename: "thi_tran_pho_bang",
    district_code: 26,
  },
  {
    name: "Xã Lũng Cú",
    code: 715,
    division_type: "xã",
    codename: "xa_lung_cu",
    district_code: 26,
  },
  {
    name: "Xã Má Lé",
    code: 718,
    division_type: "xã",
    codename: "xa_ma_le",
    district_code: 26,
  },
  {
    name: "Thị trấn Đồng Văn",
    code: 721,
    division_type: "thị trấn",
    codename: "thi_tran_dong_van",
    district_code: 26,
  },
  {
    name: "Xã Lũng Táo",
    code: 724,
    division_type: "xã",
    codename: "xa_lung_tao",
    district_code: 26,
  },
  {
    name: "Xã Phố Là",
    code: 727,
    division_type: "xã",
    codename: "xa_pho_la",
    district_code: 26,
  },
  {
    name: "Xã Thài Phìn Tủng",
    code: 730,
    division_type: "xã",
    codename: "xa_thai_phin_tung",
    district_code: 26,
  },
  {
    name: "Xã Sủng Là",
    code: 733,
    division_type: "xã",
    codename: "xa_sung_la",
    district_code: 26,
  },
  {
    name: "Xã Xà Phìn",
    code: 736,
    division_type: "xã",
    codename: "xa_xa_phin",
    district_code: 26,
  },
  {
    name: "Xã Tả Phìn",
    code: 739,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 26,
  },
  {
    name: "Xã Tả Lủng",
    code: 742,
    division_type: "xã",
    codename: "xa_ta_lung",
    district_code: 26,
  },
  {
    name: "Xã Phố Cáo",
    code: 745,
    division_type: "xã",
    codename: "xa_pho_cao",
    district_code: 26,
  },
  {
    name: "Xã Sính Lủng",
    code: 748,
    division_type: "xã",
    codename: "xa_sinh_lung",
    district_code: 26,
  },
  {
    name: "Xã Sảng Tủng",
    code: 751,
    division_type: "xã",
    codename: "xa_sang_tung",
    district_code: 26,
  },
  {
    name: "Xã Lũng Thầu",
    code: 754,
    division_type: "xã",
    codename: "xa_lung_thau",
    district_code: 26,
  },
  {
    name: "Xã Hố Quáng Phìn",
    code: 757,
    division_type: "xã",
    codename: "xa_ho_quang_phin",
    district_code: 26,
  },
  {
    name: "Xã Vần Chải",
    code: 760,
    division_type: "xã",
    codename: "xa_van_chai",
    district_code: 26,
  },
  {
    name: "Xã Lũng Phìn",
    code: 763,
    division_type: "xã",
    codename: "xa_lung_phin",
    district_code: 26,
  },
  {
    name: "Xã Sủng Trái",
    code: 766,
    division_type: "xã",
    codename: "xa_sung_trai",
    district_code: 26,
  },
  {
    name: "Thị trấn Mèo Vạc",
    code: 769,
    division_type: "thị trấn",
    codename: "thi_tran_meo_vac",
    district_code: 27,
  },
  {
    name: "Xã Thượng Phùng",
    code: 772,
    division_type: "xã",
    codename: "xa_thuong_phung",
    district_code: 27,
  },
  {
    name: "Xã Pải Lủng",
    code: 775,
    division_type: "xã",
    codename: "xa_pai_lung",
    district_code: 27,
  },
  {
    name: "Xã Xín Cái",
    code: 778,
    division_type: "xã",
    codename: "xa_xin_cai",
    district_code: 27,
  },
  {
    name: "Xã Pả Vi",
    code: 781,
    division_type: "xã",
    codename: "xa_pa_vi",
    district_code: 27,
  },
  {
    name: "Xã Giàng Chu Phìn",
    code: 784,
    division_type: "xã",
    codename: "xa_giang_chu_phin",
    district_code: 27,
  },
  {
    name: "Xã Sủng Trà",
    code: 787,
    division_type: "xã",
    codename: "xa_sung_tra",
    district_code: 27,
  },
  {
    name: "Xã Sủng Máng",
    code: 790,
    division_type: "xã",
    codename: "xa_sung_mang",
    district_code: 27,
  },
  {
    name: "Xã Sơn Vĩ",
    code: 793,
    division_type: "xã",
    codename: "xa_son_vi",
    district_code: 27,
  },
  {
    name: "Xã Tả Lủng",
    code: 796,
    division_type: "xã",
    codename: "xa_ta_lung",
    district_code: 27,
  },
  {
    name: "Xã Cán Chu Phìn",
    code: 799,
    division_type: "xã",
    codename: "xa_can_chu_phin",
    district_code: 27,
  },
  {
    name: "Xã Lũng Pù",
    code: 802,
    division_type: "xã",
    codename: "xa_lung_pu",
    district_code: 27,
  },
  {
    name: "Xã Lũng Chinh",
    code: 805,
    division_type: "xã",
    codename: "xa_lung_chinh",
    district_code: 27,
  },
  {
    name: "Xã Tát Ngà",
    code: 808,
    division_type: "xã",
    codename: "xa_tat_nga",
    district_code: 27,
  },
  {
    name: "Xã Nậm Ban",
    code: 811,
    division_type: "xã",
    codename: "xa_nam_ban",
    district_code: 27,
  },
  {
    name: "Xã Khâu Vai",
    code: 814,
    division_type: "xã",
    codename: "xa_khau_vai",
    district_code: 27,
  },
  {
    name: "Xã Niêm Tòng",
    code: 815,
    division_type: "xã",
    codename: "xa_niem_tong",
    district_code: 27,
  },
  {
    name: "Xã Niêm Sơn",
    code: 817,
    division_type: "xã",
    codename: "xa_niem_son",
    district_code: 27,
  },
  {
    name: "Thị trấn Yên Minh",
    code: 820,
    division_type: "thị trấn",
    codename: "thi_tran_yen_minh",
    district_code: 28,
  },
  {
    name: "Xã Thắng Mố",
    code: 823,
    division_type: "xã",
    codename: "xa_thang_mo",
    district_code: 28,
  },
  {
    name: "Xã Phú Lũng",
    code: 826,
    division_type: "xã",
    codename: "xa_phu_lung",
    district_code: 28,
  },
  {
    name: "Xã Sủng Tráng",
    code: 829,
    division_type: "xã",
    codename: "xa_sung_trang",
    district_code: 28,
  },
  {
    name: "Xã Bạch Đích",
    code: 832,
    division_type: "xã",
    codename: "xa_bach_dich",
    district_code: 28,
  },
  {
    name: "Xã Na Khê",
    code: 835,
    division_type: "xã",
    codename: "xa_na_khe",
    district_code: 28,
  },
  {
    name: "Xã Sủng Thài",
    code: 838,
    division_type: "xã",
    codename: "xa_sung_thai",
    district_code: 28,
  },
  {
    name: "Xã Hữu Vinh",
    code: 841,
    division_type: "xã",
    codename: "xa_huu_vinh",
    district_code: 28,
  },
  {
    name: "Xã Lao Và Chải",
    code: 844,
    division_type: "xã",
    codename: "xa_lao_va_chai",
    district_code: 28,
  },
  {
    name: "Xã Mậu Duệ",
    code: 847,
    division_type: "xã",
    codename: "xa_mau_due",
    district_code: 28,
  },
  {
    name: "Xã Đông Minh",
    code: 850,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 28,
  },
  {
    name: "Xã Mậu Long",
    code: 853,
    division_type: "xã",
    codename: "xa_mau_long",
    district_code: 28,
  },
  {
    name: "Xã Ngam La",
    code: 856,
    division_type: "xã",
    codename: "xa_ngam_la",
    district_code: 28,
  },
  {
    name: "Xã Ngọc Long",
    code: 859,
    division_type: "xã",
    codename: "xa_ngoc_long",
    district_code: 28,
  },
  {
    name: "Xã Đường Thượng",
    code: 862,
    division_type: "xã",
    codename: "xa_duong_thuong",
    district_code: 28,
  },
  {
    name: "Xã Lũng Hồ",
    code: 865,
    division_type: "xã",
    codename: "xa_lung_ho",
    district_code: 28,
  },
  {
    name: "Xã Du Tiến",
    code: 868,
    division_type: "xã",
    codename: "xa_du_tien",
    district_code: 28,
  },
  {
    name: "Xã Du Già",
    code: 871,
    division_type: "xã",
    codename: "xa_du_gia",
    district_code: 28,
  },
  {
    name: "Thị trấn Tam Sơn",
    code: 874,
    division_type: "thị trấn",
    codename: "thi_tran_tam_son",
    district_code: 29,
  },
  {
    name: "Xã Bát Đại Sơn",
    code: 877,
    division_type: "xã",
    codename: "xa_bat_dai_son",
    district_code: 29,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 880,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 29,
  },
  {
    name: "Xã Cán Tỷ",
    code: 883,
    division_type: "xã",
    codename: "xa_can_ty",
    district_code: 29,
  },
  {
    name: "Xã Cao Mã Pờ",
    code: 886,
    division_type: "xã",
    codename: "xa_cao_ma_po",
    district_code: 29,
  },
  {
    name: "Xã Thanh Vân",
    code: 889,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 29,
  },
  {
    name: "Xã Tùng Vài",
    code: 892,
    division_type: "xã",
    codename: "xa_tung_vai",
    district_code: 29,
  },
  {
    name: "Xã Đông Hà",
    code: 895,
    division_type: "xã",
    codename: "xa_dong_ha",
    district_code: 29,
  },
  {
    name: "Xã Quản Bạ",
    code: 898,
    division_type: "xã",
    codename: "xa_quan_ba",
    district_code: 29,
  },
  {
    name: "Xã Lùng Tám",
    code: 901,
    division_type: "xã",
    codename: "xa_lung_tam",
    district_code: 29,
  },
  {
    name: "Xã Quyết Tiến",
    code: 904,
    division_type: "xã",
    codename: "xa_quyet_tien",
    district_code: 29,
  },
  {
    name: "Xã Tả Ván",
    code: 907,
    division_type: "xã",
    codename: "xa_ta_van",
    district_code: 29,
  },
  {
    name: "Xã Thái An",
    code: 910,
    division_type: "xã",
    codename: "xa_thai_an",
    district_code: 29,
  },
  {
    name: "Xã Kim Thạch",
    code: 703,
    division_type: "xã",
    codename: "xa_kim_thach",
    district_code: 30,
  },
  {
    name: "Xã Phú Linh",
    code: 706,
    division_type: "xã",
    codename: "xa_phu_linh",
    district_code: 30,
  },
  {
    name: "Xã Kim Linh",
    code: 709,
    division_type: "xã",
    codename: "xa_kim_linh",
    district_code: 30,
  },
  {
    name: "Thị trấn Vị Xuyên",
    code: 913,
    division_type: "thị trấn",
    codename: "thi_tran_vi_xuyen",
    district_code: 30,
  },
  {
    name: "Thị trấn Nông Trường Việt Lâm",
    code: 916,
    division_type: "thị trấn",
    codename: "thi_tran_nong_truong_viet_lam",
    district_code: 30,
  },
  {
    name: "Xã Minh Tân",
    code: 919,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 30,
  },
  {
    name: "Xã Thuận Hoà",
    code: 922,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 30,
  },
  {
    name: "Xã Tùng Bá",
    code: 925,
    division_type: "xã",
    codename: "xa_tung_ba",
    district_code: 30,
  },
  {
    name: "Xã Thanh Thủy",
    code: 928,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 30,
  },
  {
    name: "Xã Thanh Đức",
    code: 931,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 30,
  },
  {
    name: "Xã Phong Quang",
    code: 934,
    division_type: "xã",
    codename: "xa_phong_quang",
    district_code: 30,
  },
  {
    name: "Xã Xín Chải",
    code: 937,
    division_type: "xã",
    codename: "xa_xin_chai",
    district_code: 30,
  },
  {
    name: "Xã Phương Tiến",
    code: 940,
    division_type: "xã",
    codename: "xa_phuong_tien",
    district_code: 30,
  },
  {
    name: "Xã Lao Chải",
    code: 943,
    division_type: "xã",
    codename: "xa_lao_chai",
    district_code: 30,
  },
  {
    name: "Xã Cao Bồ",
    code: 952,
    division_type: "xã",
    codename: "xa_cao_bo",
    district_code: 30,
  },
  {
    name: "Xã Đạo Đức",
    code: 955,
    division_type: "xã",
    codename: "xa_dao_duc",
    district_code: 30,
  },
  {
    name: "Xã Thượng Sơn",
    code: 958,
    division_type: "xã",
    codename: "xa_thuong_son",
    district_code: 30,
  },
  {
    name: "Xã Linh Hồ",
    code: 961,
    division_type: "xã",
    codename: "xa_linh_ho",
    district_code: 30,
  },
  {
    name: "Xã Quảng Ngần",
    code: 964,
    division_type: "xã",
    codename: "xa_quang_ngan",
    district_code: 30,
  },
  {
    name: "Xã Việt Lâm",
    code: 967,
    division_type: "xã",
    codename: "xa_viet_lam",
    district_code: 30,
  },
  {
    name: "Xã Ngọc Linh",
    code: 970,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 30,
  },
  {
    name: "Xã Ngọc Minh",
    code: 973,
    division_type: "xã",
    codename: "xa_ngoc_minh",
    district_code: 30,
  },
  {
    name: "Xã Bạch Ngọc",
    code: 976,
    division_type: "xã",
    codename: "xa_bach_ngoc",
    district_code: 30,
  },
  {
    name: "Xã Trung Thành",
    code: 979,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 30,
  },
  {
    name: "Xã Minh Sơn",
    code: 982,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 31,
  },
  {
    name: "Xã Giáp Trung",
    code: 985,
    division_type: "xã",
    codename: "xa_giap_trung",
    district_code: 31,
  },
  {
    name: "Xã Yên Định",
    code: 988,
    division_type: "xã",
    codename: "xa_yen_dinh",
    district_code: 31,
  },
  {
    name: "Thị trấn Yên Phú",
    code: 991,
    division_type: "thị trấn",
    codename: "thi_tran_yen_phu",
    district_code: 31,
  },
  {
    name: "Xã Minh Ngọc",
    code: 994,
    division_type: "xã",
    codename: "xa_minh_ngoc",
    district_code: 31,
  },
  {
    name: "Xã Yên Phong",
    code: 997,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 31,
  },
  {
    name: "Xã Lạc Nông",
    code: 1000,
    division_type: "xã",
    codename: "xa_lac_nong",
    district_code: 31,
  },
  {
    name: "Xã Phú Nam",
    code: 1003,
    division_type: "xã",
    codename: "xa_phu_nam",
    district_code: 31,
  },
  {
    name: "Xã Yên Cường",
    code: 1006,
    division_type: "xã",
    codename: "xa_yen_cuong",
    district_code: 31,
  },
  {
    name: "Xã Thượng Tân",
    code: 1009,
    division_type: "xã",
    codename: "xa_thuong_tan",
    district_code: 31,
  },
  {
    name: "Xã Đường Âm",
    code: 1012,
    division_type: "xã",
    codename: "xa_duong_am",
    district_code: 31,
  },
  {
    name: "Xã Đường Hồng",
    code: 1015,
    division_type: "xã",
    codename: "xa_duong_hong",
    district_code: 31,
  },
  {
    name: "Xã Phiêng Luông",
    code: 1018,
    division_type: "xã",
    codename: "xa_phieng_luong",
    district_code: 31,
  },
  {
    name: "Thị trấn Vinh Quang",
    code: 1021,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_quang",
    district_code: 32,
  },
  {
    name: "Xã Bản Máy",
    code: 1024,
    division_type: "xã",
    codename: "xa_ban_may",
    district_code: 32,
  },
  {
    name: "Xã Thàng Tín",
    code: 1027,
    division_type: "xã",
    codename: "xa_thang_tin",
    district_code: 32,
  },
  {
    name: "Xã Thèn Chu Phìn",
    code: 1030,
    division_type: "xã",
    codename: "xa_then_chu_phin",
    district_code: 32,
  },
  {
    name: "Xã Pố Lồ",
    code: 1033,
    division_type: "xã",
    codename: "xa_po_lo",
    district_code: 32,
  },
  {
    name: "Xã Bản Phùng",
    code: 1036,
    division_type: "xã",
    codename: "xa_ban_phung",
    district_code: 32,
  },
  {
    name: "Xã Túng Sán",
    code: 1039,
    division_type: "xã",
    codename: "xa_tung_san",
    district_code: 32,
  },
  {
    name: "Xã Chiến Phố",
    code: 1042,
    division_type: "xã",
    codename: "xa_chien_pho",
    district_code: 32,
  },
  {
    name: "Xã Đản Ván",
    code: 1045,
    division_type: "xã",
    codename: "xa_dan_van",
    district_code: 32,
  },
  {
    name: "Xã Tụ Nhân",
    code: 1048,
    division_type: "xã",
    codename: "xa_tu_nhan",
    district_code: 32,
  },
  {
    name: "Xã Tân Tiến",
    code: 1051,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 32,
  },
  {
    name: "Xã Nàng Đôn",
    code: 1054,
    division_type: "xã",
    codename: "xa_nang_don",
    district_code: 32,
  },
  {
    name: "Xã Pờ Ly Ngài",
    code: 1057,
    division_type: "xã",
    codename: "xa_po_ly_ngai",
    district_code: 32,
  },
  {
    name: "Xã Sán Xả Hồ",
    code: 1060,
    division_type: "xã",
    codename: "xa_san_xa_ho",
    district_code: 32,
  },
  {
    name: "Xã Bản Luốc",
    code: 1063,
    division_type: "xã",
    codename: "xa_ban_luoc",
    district_code: 32,
  },
  {
    name: "Xã Ngàm Đăng Vài",
    code: 1066,
    division_type: "xã",
    codename: "xa_ngam_dang_vai",
    district_code: 32,
  },
  {
    name: "Xã Bản Nhùng",
    code: 1069,
    division_type: "xã",
    codename: "xa_ban_nhung",
    district_code: 32,
  },
  {
    name: "Xã Tả Sử Choóng",
    code: 1072,
    division_type: "xã",
    codename: "xa_ta_su_choong",
    district_code: 32,
  },
  {
    name: "Xã Nậm Dịch",
    code: 1075,
    division_type: "xã",
    codename: "xa_nam_dich",
    district_code: 32,
  },
  {
    name: "Xã Hồ Thầu",
    code: 1081,
    division_type: "xã",
    codename: "xa_ho_thau",
    district_code: 32,
  },
  {
    name: "Xã Nam Sơn",
    code: 1084,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 32,
  },
  {
    name: "Xã Nậm Tỵ",
    code: 1087,
    division_type: "xã",
    codename: "xa_nam_ty",
    district_code: 32,
  },
  {
    name: "Xã Thông Nguyên",
    code: 1090,
    division_type: "xã",
    codename: "xa_thong_nguyen",
    district_code: 32,
  },
  {
    name: "Xã Nậm Khòa",
    code: 1093,
    division_type: "xã",
    codename: "xa_nam_khoa",
    district_code: 32,
  },
  {
    name: "Thị trấn Cốc Pài",
    code: 1096,
    division_type: "thị trấn",
    codename: "thi_tran_coc_pai",
    district_code: 33,
  },
  {
    name: "Xã Nàn Xỉn",
    code: 1099,
    division_type: "xã",
    codename: "xa_nan_xin",
    district_code: 33,
  },
  {
    name: "Xã Bản Díu",
    code: 1102,
    division_type: "xã",
    codename: "xa_ban_diu",
    district_code: 33,
  },
  {
    name: "Xã Chí Cà",
    code: 1105,
    division_type: "xã",
    codename: "xa_chi_ca",
    district_code: 33,
  },
  {
    name: "Xã Xín Mần",
    code: 1108,
    division_type: "xã",
    codename: "xa_xin_man",
    district_code: 33,
  },
  {
    name: "Xã Thèn Phàng",
    code: 1114,
    division_type: "xã",
    codename: "xa_then_phang",
    district_code: 33,
  },
  {
    name: "Xã Trung Thịnh",
    code: 1117,
    division_type: "xã",
    codename: "xa_trung_thinh",
    district_code: 33,
  },
  {
    name: "Xã Pà Vầy Sủ",
    code: 1120,
    division_type: "xã",
    codename: "xa_pa_vay_su",
    district_code: 33,
  },
  {
    name: "Xã Cốc Rế",
    code: 1123,
    division_type: "xã",
    codename: "xa_coc_re",
    district_code: 33,
  },
  {
    name: "Xã Thu Tà",
    code: 1126,
    division_type: "xã",
    codename: "xa_thu_ta",
    district_code: 33,
  },
  {
    name: "Xã Nàn Ma",
    code: 1129,
    division_type: "xã",
    codename: "xa_nan_ma",
    district_code: 33,
  },
  {
    name: "Xã Tả Nhìu",
    code: 1132,
    division_type: "xã",
    codename: "xa_ta_nhiu",
    district_code: 33,
  },
  {
    name: "Xã Bản Ngò",
    code: 1135,
    division_type: "xã",
    codename: "xa_ban_ngo",
    district_code: 33,
  },
  {
    name: "Xã Chế Là",
    code: 1138,
    division_type: "xã",
    codename: "xa_che_la",
    district_code: 33,
  },
  {
    name: "Xã Nấm Dẩn",
    code: 1141,
    division_type: "xã",
    codename: "xa_nam_dan",
    district_code: 33,
  },
  {
    name: "Xã Quảng Nguyên",
    code: 1144,
    division_type: "xã",
    codename: "xa_quang_nguyen",
    district_code: 33,
  },
  {
    name: "Xã Nà Chì",
    code: 1147,
    division_type: "xã",
    codename: "xa_na_chi",
    district_code: 33,
  },
  {
    name: "Xã Khuôn Lùng",
    code: 1150,
    division_type: "xã",
    codename: "xa_khuon_lung",
    district_code: 33,
  },
  {
    name: "Thị trấn Việt Quang",
    code: 1153,
    division_type: "thị trấn",
    codename: "thi_tran_viet_quang",
    district_code: 34,
  },
  {
    name: "Thị trấn Vĩnh Tuy",
    code: 1156,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tuy",
    district_code: 34,
  },
  {
    name: "Xã Tân Lập",
    code: 1159,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 34,
  },
  {
    name: "Xã Tân Thành",
    code: 1162,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 34,
  },
  {
    name: "Xã Đồng Tiến",
    code: 1165,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 34,
  },
  {
    name: "Xã Đồng Tâm",
    code: 1168,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 34,
  },
  {
    name: "Xã Tân Quang",
    code: 1171,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 34,
  },
  {
    name: "Xã Thượng Bình",
    code: 1174,
    division_type: "xã",
    codename: "xa_thuong_binh",
    district_code: 34,
  },
  {
    name: "Xã Hữu Sản",
    code: 1177,
    division_type: "xã",
    codename: "xa_huu_san",
    district_code: 34,
  },
  {
    name: "Xã Kim Ngọc",
    code: 1180,
    division_type: "xã",
    codename: "xa_kim_ngoc",
    district_code: 34,
  },
  {
    name: "Xã Việt Vinh",
    code: 1183,
    division_type: "xã",
    codename: "xa_viet_vinh",
    district_code: 34,
  },
  {
    name: "Xã Bằng Hành",
    code: 1186,
    division_type: "xã",
    codename: "xa_bang_hanh",
    district_code: 34,
  },
  {
    name: "Xã Quang Minh",
    code: 1189,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 34,
  },
  {
    name: "Xã Liên Hiệp",
    code: 1192,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 34,
  },
  {
    name: "Xã Vô Điếm",
    code: 1195,
    division_type: "xã",
    codename: "xa_vo_diem",
    district_code: 34,
  },
  {
    name: "Xã Việt Hồng",
    code: 1198,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 34,
  },
  {
    name: "Xã Hùng An",
    code: 1201,
    division_type: "xã",
    codename: "xa_hung_an",
    district_code: 34,
  },
  {
    name: "Xã Đức Xuân",
    code: 1204,
    division_type: "xã",
    codename: "xa_duc_xuan",
    district_code: 34,
  },
  {
    name: "Xã Tiên Kiều",
    code: 1207,
    division_type: "xã",
    codename: "xa_tien_kieu",
    district_code: 34,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 1210,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 34,
  },
  {
    name: "Xã Vĩnh Phúc",
    code: 1213,
    division_type: "xã",
    codename: "xa_vinh_phuc",
    district_code: 34,
  },
  {
    name: "Xã Đồng Yên",
    code: 1216,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 34,
  },
  {
    name: "Xã Đông Thành",
    code: 1219,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 34,
  },
  {
    name: "Xã Xuân Minh",
    code: 1222,
    division_type: "xã",
    codename: "xa_xuan_minh",
    district_code: 35,
  },
  {
    name: "Xã Tiên Nguyên",
    code: 1225,
    division_type: "xã",
    codename: "xa_tien_nguyen",
    district_code: 35,
  },
  {
    name: "Xã Tân Nam",
    code: 1228,
    division_type: "xã",
    codename: "xa_tan_nam",
    district_code: 35,
  },
  {
    name: "Xã Bản Rịa",
    code: 1231,
    division_type: "xã",
    codename: "xa_ban_ria",
    district_code: 35,
  },
  {
    name: "Xã Yên Thành",
    code: 1234,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 35,
  },
  {
    name: "Thị trấn Yên Bình",
    code: 1237,
    division_type: "thị trấn",
    codename: "thi_tran_yen_binh",
    district_code: 35,
  },
  {
    name: "Xã Tân Trịnh",
    code: 1240,
    division_type: "xã",
    codename: "xa_tan_trinh",
    district_code: 35,
  },
  {
    name: "Xã Tân Bắc",
    code: 1243,
    division_type: "xã",
    codename: "xa_tan_bac",
    district_code: 35,
  },
  {
    name: "Xã Bằng Lang",
    code: 1246,
    division_type: "xã",
    codename: "xa_bang_lang",
    district_code: 35,
  },
  {
    name: "Xã Yên Hà",
    code: 1249,
    division_type: "xã",
    codename: "xa_yen_ha",
    district_code: 35,
  },
  {
    name: "Xã Hương Sơn",
    code: 1252,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 35,
  },
  {
    name: "Xã Xuân Giang",
    code: 1255,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 35,
  },
  {
    name: "Xã Nà Khương",
    code: 1258,
    division_type: "xã",
    codename: "xa_na_khuong",
    district_code: 35,
  },
  {
    name: "Xã Tiên Yên",
    code: 1261,
    division_type: "xã",
    codename: "xa_tien_yen",
    district_code: 35,
  },
  {
    name: "Xã Vĩ Thượng",
    code: 1264,
    division_type: "xã",
    codename: "xa_vi_thuong",
    district_code: 35,
  },
  {
    name: "Phường Sông Hiến",
    code: 1267,
    division_type: "phường",
    codename: "phuong_song_hien",
    district_code: 40,
  },
  {
    name: "Phường Sông Bằng",
    code: 1270,
    division_type: "phường",
    codename: "phuong_song_bang",
    district_code: 40,
  },
  {
    name: "Phường Hợp Giang",
    code: 1273,
    division_type: "phường",
    codename: "phuong_hop_giang",
    district_code: 40,
  },
  {
    name: "Phường Tân Giang",
    code: 1276,
    division_type: "phường",
    codename: "phuong_tan_giang",
    district_code: 40,
  },
  {
    name: "Phường Ngọc Xuân",
    code: 1279,
    division_type: "phường",
    codename: "phuong_ngoc_xuan",
    district_code: 40,
  },
  {
    name: "Phường Đề Thám",
    code: 1282,
    division_type: "phường",
    codename: "phuong_de_tham",
    district_code: 40,
  },
  {
    name: "Phường Hoà Chung",
    code: 1285,
    division_type: "phường",
    codename: "phuong_hoa_chung",
    district_code: 40,
  },
  {
    name: "Phường Duyệt Trung",
    code: 1288,
    division_type: "phường",
    codename: "phuong_duyet_trung",
    district_code: 40,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 1693,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 40,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1705,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 40,
  },
  {
    name: "Xã Chu Trinh",
    code: 1720,
    division_type: "xã",
    codename: "xa_chu_trinh",
    district_code: 40,
  },
  {
    name: "Thị trấn Pác Miầu",
    code: 1290,
    division_type: "thị trấn",
    codename: "thi_tran_pac_miau",
    district_code: 42,
  },
  {
    name: "Xã Đức Hạnh",
    code: 1291,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 42,
  },
  {
    name: "Xã Lý Bôn",
    code: 1294,
    division_type: "xã",
    codename: "xa_ly_bon",
    district_code: 42,
  },
  {
    name: "Xã Nam Cao",
    code: 1296,
    division_type: "xã",
    codename: "xa_nam_cao",
    district_code: 42,
  },
  {
    name: "Xã Nam Quang",
    code: 1297,
    division_type: "xã",
    codename: "xa_nam_quang",
    district_code: 42,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 1300,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 42,
  },
  {
    name: "Xã Quảng Lâm",
    code: 1303,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 42,
  },
  {
    name: "Xã Thạch Lâm",
    code: 1304,
    division_type: "xã",
    codename: "xa_thach_lam",
    district_code: 42,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 1309,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 42,
  },
  {
    name: "Xã Mông Ân",
    code: 1312,
    division_type: "xã",
    codename: "xa_mong_an",
    district_code: 42,
  },
  {
    name: "Xã Thái Học",
    code: 1315,
    division_type: "xã",
    codename: "xa_thai_hoc",
    district_code: 42,
  },
  {
    name: "Xã Thái Sơn",
    code: 1316,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 42,
  },
  {
    name: "Xã Yên Thổ",
    code: 1318,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 42,
  },
  {
    name: "Thị trấn Bảo Lạc",
    code: 1321,
    division_type: "thị trấn",
    codename: "thi_tran_bao_lac",
    district_code: 43,
  },
  {
    name: "Xã Cốc Pàng",
    code: 1324,
    division_type: "xã",
    codename: "xa_coc_pang",
    district_code: 43,
  },
  {
    name: "Xã Thượng Hà",
    code: 1327,
    division_type: "xã",
    codename: "xa_thuong_ha",
    district_code: 43,
  },
  {
    name: "Xã Cô Ba",
    code: 1330,
    division_type: "xã",
    codename: "xa_co_ba",
    district_code: 43,
  },
  {
    name: "Xã Bảo Toàn",
    code: 1333,
    division_type: "xã",
    codename: "xa_bao_toan",
    district_code: 43,
  },
  {
    name: "Xã Khánh Xuân",
    code: 1336,
    division_type: "xã",
    codename: "xa_khanh_xuan",
    district_code: 43,
  },
  {
    name: "Xã Xuân Trường",
    code: 1339,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 43,
  },
  {
    name: "Xã Hồng Trị",
    code: 1342,
    division_type: "xã",
    codename: "xa_hong_tri",
    district_code: 43,
  },
  {
    name: "Xã Kim Cúc",
    code: 1343,
    division_type: "xã",
    codename: "xa_kim_cuc",
    district_code: 43,
  },
  {
    name: "Xã Phan Thanh",
    code: 1345,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 43,
  },
  {
    name: "Xã Hồng An",
    code: 1348,
    division_type: "xã",
    codename: "xa_hong_an",
    district_code: 43,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1351,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 43,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 1352,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 43,
  },
  {
    name: "Xã Huy Giáp",
    code: 1354,
    division_type: "xã",
    codename: "xa_huy_giap",
    district_code: 43,
  },
  {
    name: "Xã Đình Phùng",
    code: 1357,
    division_type: "xã",
    codename: "xa_dinh_phung",
    district_code: 43,
  },
  {
    name: "Xã Sơn Lập",
    code: 1359,
    division_type: "xã",
    codename: "xa_son_lap",
    district_code: 43,
  },
  {
    name: "Xã Sơn Lộ",
    code: 1360,
    division_type: "xã",
    codename: "xa_son_lo",
    district_code: 43,
  },
  {
    name: "Thị trấn Thông Nông",
    code: 1363,
    division_type: "thị trấn",
    codename: "thi_tran_thong_nong",
    district_code: 45,
  },
  {
    name: "Xã Cần Yên",
    code: 1366,
    division_type: "xã",
    codename: "xa_can_yen",
    district_code: 45,
  },
  {
    name: "Xã Cần Nông",
    code: 1367,
    division_type: "xã",
    codename: "xa_can_nong",
    district_code: 45,
  },
  {
    name: "Xã Lương Thông",
    code: 1372,
    division_type: "xã",
    codename: "xa_luong_thong",
    district_code: 45,
  },
  {
    name: "Xã Đa Thông",
    code: 1375,
    division_type: "xã",
    codename: "xa_da_thong",
    district_code: 45,
  },
  {
    name: "Xã Ngọc Động",
    code: 1378,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 45,
  },
  {
    name: "Xã Yên Sơn",
    code: 1381,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 45,
  },
  {
    name: "Xã Lương Can",
    code: 1384,
    division_type: "xã",
    codename: "xa_luong_can",
    district_code: 45,
  },
  {
    name: "Xã Thanh Long",
    code: 1387,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 45,
  },
  {
    name: "Thị trấn Xuân Hòa",
    code: 1392,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_hoa",
    district_code: 45,
  },
  {
    name: "Xã Lũng Nặm",
    code: 1393,
    division_type: "xã",
    codename: "xa_lung_nam",
    district_code: 45,
  },
  {
    name: "Xã Trường Hà",
    code: 1399,
    division_type: "xã",
    codename: "xa_truong_ha",
    district_code: 45,
  },
  {
    name: "Xã Cải Viên",
    code: 1402,
    division_type: "xã",
    codename: "xa_cai_vien",
    district_code: 45,
  },
  {
    name: "Xã Nội Thôn",
    code: 1411,
    division_type: "xã",
    codename: "xa_noi_thon",
    district_code: 45,
  },
  {
    name: "Xã Tổng Cọt",
    code: 1414,
    division_type: "xã",
    codename: "xa_tong_cot",
    district_code: 45,
  },
  {
    name: "Xã Sóc Hà",
    code: 1417,
    division_type: "xã",
    codename: "xa_soc_ha",
    district_code: 45,
  },
  {
    name: "Xã Thượng Thôn",
    code: 1420,
    division_type: "xã",
    codename: "xa_thuong_thon",
    district_code: 45,
  },
  {
    name: "Xã Hồng Sỹ",
    code: 1429,
    division_type: "xã",
    codename: "xa_hong_sy",
    district_code: 45,
  },
  {
    name: "Xã Quý Quân",
    code: 1432,
    division_type: "xã",
    codename: "xa_quy_quan",
    district_code: 45,
  },
  {
    name: "Xã Mã Ba",
    code: 1435,
    division_type: "xã",
    codename: "xa_ma_ba",
    district_code: 45,
  },
  {
    name: "Xã Ngọc Đào",
    code: 1438,
    division_type: "xã",
    codename: "xa_ngoc_dao",
    district_code: 45,
  },
  {
    name: "Thị trấn Trà Lĩnh",
    code: 1447,
    division_type: "thị trấn",
    codename: "thi_tran_tra_linh",
    district_code: 47,
  },
  {
    name: "Xã Tri Phương",
    code: 1453,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 47,
  },
  {
    name: "Xã Quang Hán",
    code: 1456,
    division_type: "xã",
    codename: "xa_quang_han",
    district_code: 47,
  },
  {
    name: "Xã Xuân Nội",
    code: 1462,
    division_type: "xã",
    codename: "xa_xuan_noi",
    district_code: 47,
  },
  {
    name: "Xã Quang Trung",
    code: 1465,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 47,
  },
  {
    name: "Xã Quang Vinh",
    code: 1468,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 47,
  },
  {
    name: "Xã Cao Chương",
    code: 1471,
    division_type: "xã",
    codename: "xa_cao_chuong",
    district_code: 47,
  },
  {
    name: "Thị trấn Trùng Khánh",
    code: 1477,
    division_type: "thị trấn",
    codename: "thi_tran_trung_khanh",
    district_code: 47,
  },
  {
    name: "Xã Ngọc Khê",
    code: 1480,
    division_type: "xã",
    codename: "xa_ngoc_khe",
    district_code: 47,
  },
  {
    name: "Xã Ngọc Côn",
    code: 1481,
    division_type: "xã",
    codename: "xa_ngoc_con",
    district_code: 47,
  },
  {
    name: "Xã Phong Nậm",
    code: 1483,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 47,
  },
  {
    name: "Xã Đình Phong",
    code: 1489,
    division_type: "xã",
    codename: "xa_dinh_phong",
    district_code: 47,
  },
  {
    name: "Xã Đàm Thuỷ",
    code: 1495,
    division_type: "xã",
    codename: "xa_dam_thuy",
    district_code: 47,
  },
  {
    name: "Xã Khâm Thành",
    code: 1498,
    division_type: "xã",
    codename: "xa_kham_thanh",
    district_code: 47,
  },
  {
    name: "Xã Chí Viễn",
    code: 1501,
    division_type: "xã",
    codename: "xa_chi_vien",
    district_code: 47,
  },
  {
    name: "Xã Lăng Hiếu",
    code: 1504,
    division_type: "xã",
    codename: "xa_lang_hieu",
    district_code: 47,
  },
  {
    name: "Xã Phong Châu",
    code: 1507,
    division_type: "xã",
    codename: "xa_phong_chau",
    district_code: 47,
  },
  {
    name: "Xã Trung Phúc",
    code: 1516,
    division_type: "xã",
    codename: "xa_trung_phuc",
    district_code: 47,
  },
  {
    name: "Xã Cao Thăng",
    code: 1519,
    division_type: "xã",
    codename: "xa_cao_thang",
    district_code: 47,
  },
  {
    name: "Xã Đức Hồng",
    code: 1522,
    division_type: "xã",
    codename: "xa_duc_hong",
    district_code: 47,
  },
  {
    name: "Xã Đoài Dương",
    code: 1525,
    division_type: "xã",
    codename: "xa_doai_duong",
    district_code: 47,
  },
  {
    name: "Xã Minh Long",
    code: 1534,
    division_type: "xã",
    codename: "xa_minh_long",
    district_code: 48,
  },
  {
    name: "Xã Lý Quốc",
    code: 1537,
    division_type: "xã",
    codename: "xa_ly_quoc",
    district_code: 48,
  },
  {
    name: "Xã Thắng Lợi",
    code: 1540,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 48,
  },
  {
    name: "Xã Đồng Loan",
    code: 1543,
    division_type: "xã",
    codename: "xa_dong_loan",
    district_code: 48,
  },
  {
    name: "Xã Đức Quang",
    code: 1546,
    division_type: "xã",
    codename: "xa_duc_quang",
    district_code: 48,
  },
  {
    name: "Xã Kim Loan",
    code: 1549,
    division_type: "xã",
    codename: "xa_kim_loan",
    district_code: 48,
  },
  {
    name: "Xã Quang Long",
    code: 1552,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 48,
  },
  {
    name: "Xã An Lạc",
    code: 1555,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 48,
  },
  {
    name: "Thị trấn Thanh Nhật",
    code: 1558,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_nhat",
    district_code: 48,
  },
  {
    name: "Xã Vinh Quý",
    code: 1561,
    division_type: "xã",
    codename: "xa_vinh_quy",
    district_code: 48,
  },
  {
    name: "Xã Thống Nhất",
    code: 1564,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 48,
  },
  {
    name: "Xã Cô Ngân",
    code: 1567,
    division_type: "xã",
    codename: "xa_co_ngan",
    district_code: 48,
  },
  {
    name: "Xã Thị Hoa",
    code: 1573,
    division_type: "xã",
    codename: "xa_thi_hoa",
    district_code: 48,
  },
  {
    name: "Xã Quốc Toản",
    code: 1474,
    division_type: "xã",
    codename: "xa_quoc_toan",
    district_code: 49,
  },
  {
    name: "Thị trấn Quảng Uyên",
    code: 1576,
    division_type: "thị trấn",
    codename: "thi_tran_quang_uyen",
    district_code: 49,
  },
  {
    name: "Xã Phi Hải",
    code: 1579,
    division_type: "xã",
    codename: "xa_phi_hai",
    district_code: 49,
  },
  {
    name: "Xã Quảng Hưng",
    code: 1582,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 49,
  },
  {
    name: "Xã Độc Lập",
    code: 1594,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 49,
  },
  {
    name: "Xã Cai Bộ",
    code: 1597,
    division_type: "xã",
    codename: "xa_cai_bo",
    district_code: 49,
  },
  {
    name: "Xã Phúc Sen",
    code: 1603,
    division_type: "xã",
    codename: "xa_phuc_sen",
    district_code: 49,
  },
  {
    name: "Xã Chí Thảo",
    code: 1606,
    division_type: "xã",
    codename: "xa_chi_thao",
    district_code: 49,
  },
  {
    name: "Xã Tự Do",
    code: 1609,
    division_type: "xã",
    codename: "xa_tu_do",
    district_code: 49,
  },
  {
    name: "Xã Hồng Quang",
    code: 1615,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 49,
  },
  {
    name: "Xã Ngọc Động",
    code: 1618,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 49,
  },
  {
    name: "Xã Hạnh Phúc",
    code: 1624,
    division_type: "xã",
    codename: "xa_hanh_phuc",
    district_code: 49,
  },
  {
    name: "Thị trấn Tà Lùng",
    code: 1627,
    division_type: "thị trấn",
    codename: "thi_tran_ta_lung",
    district_code: 49,
  },
  {
    name: "Xã Bế Văn Đàn",
    code: 1630,
    division_type: "xã",
    codename: "xa_be_van_dan",
    district_code: 49,
  },
  {
    name: "Xã Cách Linh",
    code: 1636,
    division_type: "xã",
    codename: "xa_cach_linh",
    district_code: 49,
  },
  {
    name: "Xã Đại Sơn",
    code: 1639,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 49,
  },
  {
    name: "Xã Tiên Thành",
    code: 1645,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 49,
  },
  {
    name: "Thị trấn Hoà Thuận",
    code: 1648,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_thuan",
    district_code: 49,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 1651,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 49,
  },
  {
    name: "Thị trấn Nước Hai",
    code: 1654,
    division_type: "thị trấn",
    codename: "thi_tran_nuoc_hai",
    district_code: 51,
  },
  {
    name: "Xã Dân Chủ",
    code: 1657,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 51,
  },
  {
    name: "Xã Nam Tuấn",
    code: 1660,
    division_type: "xã",
    codename: "xa_nam_tuan",
    district_code: 51,
  },
  {
    name: "Xã Đại Tiến",
    code: 1666,
    division_type: "xã",
    codename: "xa_dai_tien",
    district_code: 51,
  },
  {
    name: "Xã Đức Long",
    code: 1669,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 51,
  },
  {
    name: "Xã Ngũ Lão",
    code: 1672,
    division_type: "xã",
    codename: "xa_ngu_lao",
    district_code: 51,
  },
  {
    name: "Xã Trương Lương",
    code: 1675,
    division_type: "xã",
    codename: "xa_truong_luong",
    district_code: 51,
  },
  {
    name: "Xã Hồng Việt",
    code: 1687,
    division_type: "xã",
    codename: "xa_hong_viet",
    district_code: 51,
  },
  {
    name: "Xã Hoàng Tung",
    code: 1696,
    division_type: "xã",
    codename: "xa_hoang_tung",
    district_code: 51,
  },
  {
    name: "Xã Nguyễn Huệ",
    code: 1699,
    division_type: "xã",
    codename: "xa_nguyen_hue",
    district_code: 51,
  },
  {
    name: "Xã Quang Trung",
    code: 1702,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 51,
  },
  {
    name: "Xã Bạch Đằng",
    code: 1708,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 51,
  },
  {
    name: "Xã Bình Dương",
    code: 1711,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 51,
  },
  {
    name: "Xã Lê Chung",
    code: 1714,
    division_type: "xã",
    codename: "xa_le_chung",
    district_code: 51,
  },
  {
    name: "Xã Hồng Nam",
    code: 1723,
    division_type: "xã",
    codename: "xa_hong_nam",
    district_code: 51,
  },
  {
    name: "Thị trấn Nguyên Bình",
    code: 1726,
    division_type: "thị trấn",
    codename: "thi_tran_nguyen_binh",
    district_code: 52,
  },
  {
    name: "Thị trấn Tĩnh Túc",
    code: 1729,
    division_type: "thị trấn",
    codename: "thi_tran_tinh_tuc",
    district_code: 52,
  },
  {
    name: "Xã Yên Lạc",
    code: 1732,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 52,
  },
  {
    name: "Xã Triệu Nguyên",
    code: 1735,
    division_type: "xã",
    codename: "xa_trieu_nguyen",
    district_code: 52,
  },
  {
    name: "Xã Ca Thành",
    code: 1738,
    division_type: "xã",
    codename: "xa_ca_thanh",
    district_code: 52,
  },
  {
    name: "Xã Vũ Nông",
    code: 1744,
    division_type: "xã",
    codename: "xa_vu_nong",
    district_code: 52,
  },
  {
    name: "Xã Minh Tâm",
    code: 1747,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 52,
  },
  {
    name: "Xã Thể Dục",
    code: 1750,
    division_type: "xã",
    codename: "xa_the_duc",
    district_code: 52,
  },
  {
    name: "Xã Mai Long",
    code: 1756,
    division_type: "xã",
    codename: "xa_mai_long",
    district_code: 52,
  },
  {
    name: "Xã Vũ Minh",
    code: 1762,
    division_type: "xã",
    codename: "xa_vu_minh",
    district_code: 52,
  },
  {
    name: "Xã Hoa Thám",
    code: 1765,
    division_type: "xã",
    codename: "xa_hoa_tham",
    district_code: 52,
  },
  {
    name: "Xã Phan Thanh",
    code: 1768,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 52,
  },
  {
    name: "Xã Quang Thành",
    code: 1771,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 52,
  },
  {
    name: "Xã Tam Kim",
    code: 1774,
    division_type: "xã",
    codename: "xa_tam_kim",
    district_code: 52,
  },
  {
    name: "Xã Thành Công",
    code: 1777,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 52,
  },
  {
    name: "Xã Thịnh Vượng",
    code: 1780,
    division_type: "xã",
    codename: "xa_thinh_vuong",
    district_code: 52,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1783,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 52,
  },
  {
    name: "Thị trấn Đông Khê",
    code: 1786,
    division_type: "thị trấn",
    codename: "thi_tran_dong_khe",
    district_code: 53,
  },
  {
    name: "Xã Canh Tân",
    code: 1789,
    division_type: "xã",
    codename: "xa_canh_tan",
    district_code: 53,
  },
  {
    name: "Xã Kim Đồng",
    code: 1792,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 53,
  },
  {
    name: "Xã Minh Khai",
    code: 1795,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 53,
  },
  {
    name: "Xã Đức Thông",
    code: 1801,
    division_type: "xã",
    codename: "xa_duc_thong",
    district_code: 53,
  },
  {
    name: "Xã Thái Cường",
    code: 1804,
    division_type: "xã",
    codename: "xa_thai_cuong",
    district_code: 53,
  },
  {
    name: "Xã Vân Trình",
    code: 1807,
    division_type: "xã",
    codename: "xa_van_trinh",
    district_code: 53,
  },
  {
    name: "Xã Thụy Hùng",
    code: 1810,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 53,
  },
  {
    name: "Xã Quang Trọng",
    code: 1813,
    division_type: "xã",
    codename: "xa_quang_trong",
    district_code: 53,
  },
  {
    name: "Xã Trọng Con",
    code: 1816,
    division_type: "xã",
    codename: "xa_trong_con",
    district_code: 53,
  },
  {
    name: "Xã Lê Lai",
    code: 1819,
    division_type: "xã",
    codename: "xa_le_lai",
    district_code: 53,
  },
  {
    name: "Xã Đức Long",
    code: 1822,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 53,
  },
  {
    name: "Xã Lê Lợi",
    code: 1828,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 53,
  },
  {
    name: "Xã Đức Xuân",
    code: 1831,
    division_type: "xã",
    codename: "xa_duc_xuan",
    district_code: 53,
  },
  {
    name: "Phường Nguyễn Thị Minh Khai",
    code: 1834,
    division_type: "phường",
    codename: "phuong_nguyen_thi_minh_khai",
    district_code: 58,
  },
  {
    name: "Phường Sông Cầu",
    code: 1837,
    division_type: "phường",
    codename: "phuong_song_cau",
    district_code: 58,
  },
  {
    name: "Phường Đức Xuân",
    code: 1840,
    division_type: "phường",
    codename: "phuong_duc_xuan",
    district_code: 58,
  },
  {
    name: "Phường Phùng Chí Kiên",
    code: 1843,
    division_type: "phường",
    codename: "phuong_phung_chi_kien",
    district_code: 58,
  },
  {
    name: "Phường Huyền Tụng",
    code: 1846,
    division_type: "phường",
    codename: "phuong_huyen_tung",
    district_code: 58,
  },
  {
    name: "Xã Dương Quang",
    code: 1849,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 58,
  },
  {
    name: "Xã Nông Thượng",
    code: 1852,
    division_type: "xã",
    codename: "xa_nong_thuong",
    district_code: 58,
  },
  {
    name: "Phường Xuất Hóa",
    code: 1855,
    division_type: "phường",
    codename: "phuong_xuat_hoa",
    district_code: 58,
  },
  {
    name: "Xã Bằng Thành",
    code: 1858,
    division_type: "xã",
    codename: "xa_bang_thanh",
    district_code: 60,
  },
  {
    name: "Xã Nhạn Môn",
    code: 1861,
    division_type: "xã",
    codename: "xa_nhan_mon",
    district_code: 60,
  },
  {
    name: "Xã Bộc Bố",
    code: 1864,
    division_type: "xã",
    codename: "xa_boc_bo",
    district_code: 60,
  },
  {
    name: "Xã Công Bằng",
    code: 1867,
    division_type: "xã",
    codename: "xa_cong_bang",
    district_code: 60,
  },
  {
    name: "Xã Giáo Hiệu",
    code: 1870,
    division_type: "xã",
    codename: "xa_giao_hieu",
    district_code: 60,
  },
  {
    name: "Xã Xuân La",
    code: 1873,
    division_type: "xã",
    codename: "xa_xuan_la",
    district_code: 60,
  },
  {
    name: "Xã An Thắng",
    code: 1876,
    division_type: "xã",
    codename: "xa_an_thang",
    district_code: 60,
  },
  {
    name: "Xã Cổ Linh",
    code: 1879,
    division_type: "xã",
    codename: "xa_co_linh",
    district_code: 60,
  },
  {
    name: "Xã Nghiên Loan",
    code: 1882,
    division_type: "xã",
    codename: "xa_nghien_loan",
    district_code: 60,
  },
  {
    name: "Xã Cao Tân",
    code: 1885,
    division_type: "xã",
    codename: "xa_cao_tan",
    district_code: 60,
  },
  {
    name: "Thị trấn Chợ Rã",
    code: 1888,
    division_type: "thị trấn",
    codename: "thi_tran_cho_ra",
    district_code: 61,
  },
  {
    name: "Xã Bành Trạch",
    code: 1891,
    division_type: "xã",
    codename: "xa_banh_trach",
    district_code: 61,
  },
  {
    name: "Xã Phúc Lộc",
    code: 1894,
    division_type: "xã",
    codename: "xa_phuc_loc",
    district_code: 61,
  },
  {
    name: "Xã Hà Hiệu",
    code: 1897,
    division_type: "xã",
    codename: "xa_ha_hieu",
    district_code: 61,
  },
  {
    name: "Xã Cao Thượng",
    code: 1900,
    division_type: "xã",
    codename: "xa_cao_thuong",
    district_code: 61,
  },
  {
    name: "Xã Khang Ninh",
    code: 1906,
    division_type: "xã",
    codename: "xa_khang_ninh",
    district_code: 61,
  },
  {
    name: "Xã Nam Mẫu",
    code: 1909,
    division_type: "xã",
    codename: "xa_nam_mau",
    district_code: 61,
  },
  {
    name: "Xã Thượng Giáo",
    code: 1912,
    division_type: "xã",
    codename: "xa_thuong_giao",
    district_code: 61,
  },
  {
    name: "Xã Địa Linh",
    code: 1915,
    division_type: "xã",
    codename: "xa_dia_linh",
    district_code: 61,
  },
  {
    name: "Xã Yến Dương",
    code: 1918,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 61,
  },
  {
    name: "Xã Chu Hương",
    code: 1921,
    division_type: "xã",
    codename: "xa_chu_huong",
    district_code: 61,
  },
  {
    name: "Xã Quảng Khê",
    code: 1924,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 61,
  },
  {
    name: "Xã Mỹ Phương",
    code: 1927,
    division_type: "xã",
    codename: "xa_my_phuong",
    district_code: 61,
  },
  {
    name: "Xã Hoàng Trĩ",
    code: 1930,
    division_type: "xã",
    codename: "xa_hoang_tri",
    district_code: 61,
  },
  {
    name: "Xã Đồng Phúc",
    code: 1933,
    division_type: "xã",
    codename: "xa_dong_phuc",
    district_code: 61,
  },
  {
    name: "Thị trấn Nà Phặc",
    code: 1936,
    division_type: "thị trấn",
    codename: "thi_tran_na_phac",
    district_code: 62,
  },
  {
    name: "Xã Thượng Ân",
    code: 1939,
    division_type: "xã",
    codename: "xa_thuong_an",
    district_code: 62,
  },
  {
    name: "Xã Bằng Vân",
    code: 1942,
    division_type: "xã",
    codename: "xa_bang_van",
    district_code: 62,
  },
  {
    name: "Xã Cốc Đán",
    code: 1945,
    division_type: "xã",
    codename: "xa_coc_dan",
    district_code: 62,
  },
  {
    name: "Xã Trung Hoà",
    code: 1948,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 62,
  },
  {
    name: "Xã Đức Vân",
    code: 1951,
    division_type: "xã",
    codename: "xa_duc_van",
    district_code: 62,
  },
  {
    name: "Xã Vân Tùng",
    code: 1954,
    division_type: "xã",
    codename: "xa_van_tung",
    district_code: 62,
  },
  {
    name: "Xã Thượng Quan",
    code: 1957,
    division_type: "xã",
    codename: "xa_thuong_quan",
    district_code: 62,
  },
  {
    name: "Xã Hiệp Lực",
    code: 1960,
    division_type: "xã",
    codename: "xa_hiep_luc",
    district_code: 62,
  },
  {
    name: "Xã Thuần Mang",
    code: 1963,
    division_type: "xã",
    codename: "xa_thuan_mang",
    district_code: 62,
  },
  {
    name: "Thị trấn Phủ Thông",
    code: 1969,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thong",
    district_code: 63,
  },
  {
    name: "Xã Vi Hương",
    code: 1975,
    division_type: "xã",
    codename: "xa_vi_huong",
    district_code: 63,
  },
  {
    name: "Xã Sĩ Bình",
    code: 1978,
    division_type: "xã",
    codename: "xa_si_binh",
    district_code: 63,
  },
  {
    name: "Xã Vũ Muộn",
    code: 1981,
    division_type: "xã",
    codename: "xa_vu_muon",
    district_code: 63,
  },
  {
    name: "Xã Đôn Phong",
    code: 1984,
    division_type: "xã",
    codename: "xa_don_phong",
    district_code: 63,
  },
  {
    name: "Xã Lục Bình",
    code: 1990,
    division_type: "xã",
    codename: "xa_luc_binh",
    district_code: 63,
  },
  {
    name: "Xã Tân Tú",
    code: 1993,
    division_type: "xã",
    codename: "xa_tan_tu",
    district_code: 63,
  },
  {
    name: "Xã Nguyên Phúc",
    code: 1999,
    division_type: "xã",
    codename: "xa_nguyen_phuc",
    district_code: 63,
  },
  {
    name: "Xã Cao Sơn",
    code: 2002,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 63,
  },
  {
    name: "Xã Quân Hà",
    code: 2005,
    division_type: "xã",
    codename: "xa_quan_ha",
    district_code: 63,
  },
  {
    name: "Xã Cẩm Giàng",
    code: 2008,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 63,
  },
  {
    name: "Xã Mỹ Thanh",
    code: 2011,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 63,
  },
  {
    name: "Xã Dương Phong",
    code: 2014,
    division_type: "xã",
    codename: "xa_duong_phong",
    district_code: 63,
  },
  {
    name: "Xã Quang Thuận",
    code: 2017,
    division_type: "xã",
    codename: "xa_quang_thuan",
    district_code: 63,
  },
  {
    name: "Thị trấn Bằng Lũng",
    code: 2020,
    division_type: "thị trấn",
    codename: "thi_tran_bang_lung",
    district_code: 64,
  },
  {
    name: "Xã Xuân Lạc",
    code: 2023,
    division_type: "xã",
    codename: "xa_xuan_lac",
    district_code: 64,
  },
  {
    name: "Xã Nam Cường",
    code: 2026,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 64,
  },
  {
    name: "Xã Đồng Lạc",
    code: 2029,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 64,
  },
  {
    name: "Xã Tân Lập",
    code: 2032,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 64,
  },
  {
    name: "Xã Bản Thi",
    code: 2035,
    division_type: "xã",
    codename: "xa_ban_thi",
    district_code: 64,
  },
  {
    name: "Xã Quảng Bạch",
    code: 2038,
    division_type: "xã",
    codename: "xa_quang_bach",
    district_code: 64,
  },
  {
    name: "Xã Bằng Phúc",
    code: 2041,
    division_type: "xã",
    codename: "xa_bang_phuc",
    district_code: 64,
  },
  {
    name: "Xã Yên Thịnh",
    code: 2044,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 64,
  },
  {
    name: "Xã Yên Thượng",
    code: 2047,
    division_type: "xã",
    codename: "xa_yen_thuong",
    district_code: 64,
  },
  {
    name: "Xã Phương Viên",
    code: 2050,
    division_type: "xã",
    codename: "xa_phuong_vien",
    district_code: 64,
  },
  {
    name: "Xã Ngọc Phái",
    code: 2053,
    division_type: "xã",
    codename: "xa_ngoc_phai",
    district_code: 64,
  },
  {
    name: "Xã Đồng Thắng",
    code: 2059,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 64,
  },
  {
    name: "Xã Lương Bằng",
    code: 2062,
    division_type: "xã",
    codename: "xa_luong_bang",
    district_code: 64,
  },
  {
    name: "Xã Bằng Lãng",
    code: 2065,
    division_type: "xã",
    codename: "xa_bang_lang",
    district_code: 64,
  },
  {
    name: "Xã Đại Sảo",
    code: 2068,
    division_type: "xã",
    codename: "xa_dai_sao",
    district_code: 64,
  },
  {
    name: "Xã Nghĩa Tá",
    code: 2071,
    division_type: "xã",
    codename: "xa_nghia_ta",
    district_code: 64,
  },
  {
    name: "Xã Yên Mỹ",
    code: 2077,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 64,
  },
  {
    name: "Xã Bình Trung",
    code: 2080,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 64,
  },
  {
    name: "Xã Yên Phong",
    code: 2083,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 64,
  },
  {
    name: "Thị trấn Đồng Tâm",
    code: 2086,
    division_type: "thị trấn",
    codename: "thi_tran_dong_tam",
    district_code: 65,
  },
  {
    name: "Xã Tân Sơn",
    code: 2089,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 65,
  },
  {
    name: "Xã Thanh Vận",
    code: 2092,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 65,
  },
  {
    name: "Xã Mai Lạp",
    code: 2095,
    division_type: "xã",
    codename: "xa_mai_lap",
    district_code: 65,
  },
  {
    name: "Xã Hoà Mục",
    code: 2098,
    division_type: "xã",
    codename: "xa_hoa_muc",
    district_code: 65,
  },
  {
    name: "Xã Thanh Mai",
    code: 2101,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 65,
  },
  {
    name: "Xã Cao Kỳ",
    code: 2104,
    division_type: "xã",
    codename: "xa_cao_ky",
    district_code: 65,
  },
  {
    name: "Xã Nông Hạ",
    code: 2107,
    division_type: "xã",
    codename: "xa_nong_ha",
    district_code: 65,
  },
  {
    name: "Xã Yên Cư",
    code: 2110,
    division_type: "xã",
    codename: "xa_yen_cu",
    district_code: 65,
  },
  {
    name: "Xã Thanh Thịnh",
    code: 2113,
    division_type: "xã",
    codename: "xa_thanh_thinh",
    district_code: 65,
  },
  {
    name: "Xã Yên Hân",
    code: 2116,
    division_type: "xã",
    codename: "xa_yen_han",
    district_code: 65,
  },
  {
    name: "Xã Như Cố",
    code: 2122,
    division_type: "xã",
    codename: "xa_nhu_co",
    district_code: 65,
  },
  {
    name: "Xã Bình Văn",
    code: 2125,
    division_type: "xã",
    codename: "xa_binh_van",
    district_code: 65,
  },
  {
    name: "Xã Quảng Chu",
    code: 2131,
    division_type: "xã",
    codename: "xa_quang_chu",
    district_code: 65,
  },
  {
    name: "Xã Văn Vũ",
    code: 2137,
    division_type: "xã",
    codename: "xa_van_vu",
    district_code: 66,
  },
  {
    name: "Xã Văn Lang",
    code: 2140,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 66,
  },
  {
    name: "Xã Lương Thượng",
    code: 2143,
    division_type: "xã",
    codename: "xa_luong_thuong",
    district_code: 66,
  },
  {
    name: "Xã Kim Hỷ",
    code: 2146,
    division_type: "xã",
    codename: "xa_kim_hy",
    district_code: 66,
  },
  {
    name: "Xã Cường Lợi",
    code: 2152,
    division_type: "xã",
    codename: "xa_cuong_loi",
    district_code: 66,
  },
  {
    name: "Thị trấn Yến Lạc",
    code: 2155,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lac",
    district_code: 66,
  },
  {
    name: "Xã Kim Lư",
    code: 2158,
    division_type: "xã",
    codename: "xa_kim_lu",
    district_code: 66,
  },
  {
    name: "Xã Sơn Thành",
    code: 2161,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 66,
  },
  {
    name: "Xã Văn Minh",
    code: 2170,
    division_type: "xã",
    codename: "xa_van_minh",
    district_code: 66,
  },
  {
    name: "Xã Côn Minh",
    code: 2173,
    division_type: "xã",
    codename: "xa_con_minh",
    district_code: 66,
  },
  {
    name: "Xã Cư Lễ",
    code: 2176,
    division_type: "xã",
    codename: "xa_cu_le",
    district_code: 66,
  },
  {
    name: "Xã Trần Phú",
    code: 2179,
    division_type: "xã",
    codename: "xa_tran_phu",
    district_code: 66,
  },
  {
    name: "Xã Quang Phong",
    code: 2185,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 66,
  },
  {
    name: "Xã Dương Sơn",
    code: 2188,
    division_type: "xã",
    codename: "xa_duong_son",
    district_code: 66,
  },
  {
    name: "Xã Xuân Dương",
    code: 2191,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 66,
  },
  {
    name: "Xã Đổng Xá",
    code: 2194,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 66,
  },
  {
    name: "Xã Liêm Thuỷ",
    code: 2197,
    division_type: "xã",
    codename: "xa_liem_thuy",
    district_code: 66,
  },
  {
    name: "Phường Phan Thiết",
    code: 2200,
    division_type: "phường",
    codename: "phuong_phan_thiet",
    district_code: 70,
  },
  {
    name: "Phường Minh Xuân",
    code: 2203,
    division_type: "phường",
    codename: "phuong_minh_xuan",
    district_code: 70,
  },
  {
    name: "Phường Tân Quang",
    code: 2206,
    division_type: "phường",
    codename: "phuong_tan_quang",
    district_code: 70,
  },
  {
    name: "Xã Tràng Đà",
    code: 2209,
    division_type: "xã",
    codename: "xa_trang_da",
    district_code: 70,
  },
  {
    name: "Phường Nông Tiến",
    code: 2212,
    division_type: "phường",
    codename: "phuong_nong_tien",
    district_code: 70,
  },
  {
    name: "Phường Ỷ La",
    code: 2215,
    division_type: "phường",
    codename: "phuong_y_la",
    district_code: 70,
  },
  {
    name: "Phường Tân Hà",
    code: 2216,
    division_type: "phường",
    codename: "phuong_tan_ha",
    district_code: 70,
  },
  {
    name: "Phường Hưng Thành",
    code: 2218,
    division_type: "phường",
    codename: "phuong_hung_thanh",
    district_code: 70,
  },
  {
    name: "Xã Kim Phú",
    code: 2497,
    division_type: "xã",
    codename: "xa_kim_phu",
    district_code: 70,
  },
  {
    name: "Xã An Khang",
    code: 2503,
    division_type: "xã",
    codename: "xa_an_khang",
    district_code: 70,
  },
  {
    name: "Phường Mỹ Lâm",
    code: 2509,
    division_type: "phường",
    codename: "phuong_my_lam",
    district_code: 70,
  },
  {
    name: "Phường An Tường",
    code: 2512,
    division_type: "phường",
    codename: "phuong_an_tuong",
    district_code: 70,
  },
  {
    name: "Xã Lưỡng Vượng",
    code: 2515,
    division_type: "xã",
    codename: "xa_luong_vuong",
    district_code: 70,
  },
  {
    name: "Xã Thái Long",
    code: 2521,
    division_type: "xã",
    codename: "xa_thai_long",
    district_code: 70,
  },
  {
    name: "Phường Đội Cấn",
    code: 2524,
    division_type: "phường",
    codename: "phuong_doi_can",
    district_code: 70,
  },
  {
    name: "Xã Phúc Yên",
    code: 2233,
    division_type: "xã",
    codename: "xa_phuc_yen",
    district_code: 71,
  },
  {
    name: "Xã Xuân Lập",
    code: 2242,
    division_type: "xã",
    codename: "xa_xuan_lap",
    district_code: 71,
  },
  {
    name: "Xã Khuôn Hà",
    code: 2251,
    division_type: "xã",
    codename: "xa_khuon_ha",
    district_code: 71,
  },
  {
    name: "Xã Lăng Can",
    code: 2266,
    division_type: "xã",
    codename: "xa_lang_can",
    district_code: 71,
  },
  {
    name: "Xã Thượng Lâm",
    code: 2269,
    division_type: "xã",
    codename: "xa_thuong_lam",
    district_code: 71,
  },
  {
    name: "Xã Bình An",
    code: 2290,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 71,
  },
  {
    name: "Xã Hồng Quang",
    code: 2293,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 71,
  },
  {
    name: "Xã Thổ Bình",
    code: 2296,
    division_type: "xã",
    codename: "xa_tho_binh",
    district_code: 71,
  },
  {
    name: "Thị trấn Na Hang",
    code: 2221,
    division_type: "thị trấn",
    codename: "thi_tran_na_hang",
    district_code: 72,
  },
  {
    name: "Xã Sinh Long",
    code: 2227,
    division_type: "xã",
    codename: "xa_sinh_long",
    district_code: 72,
  },
  {
    name: "Xã Thượng Giáp",
    code: 2230,
    division_type: "xã",
    codename: "xa_thuong_giap",
    district_code: 72,
  },
  {
    name: "Xã Thượng Nông",
    code: 2239,
    division_type: "xã",
    codename: "xa_thuong_nong",
    district_code: 72,
  },
  {
    name: "Xã Côn Lôn",
    code: 2245,
    division_type: "xã",
    codename: "xa_con_lon",
    district_code: 72,
  },
  {
    name: "Xã Yên Hoa",
    code: 2248,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 72,
  },
  {
    name: "Xã Hồng Thái",
    code: 2254,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 72,
  },
  {
    name: "Xã Đà Vị",
    code: 2260,
    division_type: "xã",
    codename: "xa_da_vi",
    district_code: 72,
  },
  {
    name: "Xã Khau Tinh",
    code: 2263,
    division_type: "xã",
    codename: "xa_khau_tinh",
    district_code: 72,
  },
  {
    name: "Xã Sơn Phú",
    code: 2275,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 72,
  },
  {
    name: "Xã Năng Khả",
    code: 2281,
    division_type: "xã",
    codename: "xa_nang_kha",
    district_code: 72,
  },
  {
    name: "Xã Thanh Tương",
    code: 2284,
    division_type: "xã",
    codename: "xa_thanh_tuong",
    district_code: 72,
  },
  {
    name: "Thị trấn Vĩnh Lộc",
    code: 2287,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_loc",
    district_code: 73,
  },
  {
    name: "Xã Phúc Sơn",
    code: 2299,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 73,
  },
  {
    name: "Xã Minh Quang",
    code: 2302,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 73,
  },
  {
    name: "Xã Trung Hà",
    code: 2305,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 73,
  },
  {
    name: "Xã Tân Mỹ",
    code: 2308,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 73,
  },
  {
    name: "Xã Hà Lang",
    code: 2311,
    division_type: "xã",
    codename: "xa_ha_lang",
    district_code: 73,
  },
  {
    name: "Xã Hùng Mỹ",
    code: 2314,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 73,
  },
  {
    name: "Xã Yên Lập",
    code: 2317,
    division_type: "xã",
    codename: "xa_yen_lap",
    district_code: 73,
  },
  {
    name: "Xã Tân An",
    code: 2320,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 73,
  },
  {
    name: "Xã Bình Phú",
    code: 2323,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 73,
  },
  {
    name: "Xã Xuân Quang",
    code: 2326,
    division_type: "xã",
    codename: "xa_xuan_quang",
    district_code: 73,
  },
  {
    name: "Xã Ngọc Hội",
    code: 2329,
    division_type: "xã",
    codename: "xa_ngoc_hoi",
    district_code: 73,
  },
  {
    name: "Xã Phú Bình",
    code: 2332,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 73,
  },
  {
    name: "Xã Hòa Phú",
    code: 2335,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 73,
  },
  {
    name: "Xã Phúc Thịnh",
    code: 2338,
    division_type: "xã",
    codename: "xa_phuc_thinh",
    district_code: 73,
  },
  {
    name: "Xã Kiên Đài",
    code: 2341,
    division_type: "xã",
    codename: "xa_kien_dai",
    district_code: 73,
  },
  {
    name: "Xã Tân Thịnh",
    code: 2344,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 73,
  },
  {
    name: "Xã Trung Hòa",
    code: 2347,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 73,
  },
  {
    name: "Xã Kim Bình",
    code: 2350,
    division_type: "xã",
    codename: "xa_kim_binh",
    district_code: 73,
  },
  {
    name: "Xã Hòa An",
    code: 2353,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 73,
  },
  {
    name: "Xã Vinh Quang",
    code: 2356,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 73,
  },
  {
    name: "Xã Tri Phú",
    code: 2359,
    division_type: "xã",
    codename: "xa_tri_phu",
    district_code: 73,
  },
  {
    name: "Xã Nhân Lý",
    code: 2362,
    division_type: "xã",
    codename: "xa_nhan_ly",
    district_code: 73,
  },
  {
    name: "Xã Yên Nguyên",
    code: 2365,
    division_type: "xã",
    codename: "xa_yen_nguyen",
    district_code: 73,
  },
  {
    name: "Xã Linh Phú",
    code: 2368,
    division_type: "xã",
    codename: "xa_linh_phu",
    district_code: 73,
  },
  {
    name: "Xã Bình Nhân",
    code: 2371,
    division_type: "xã",
    codename: "xa_binh_nhan",
    district_code: 73,
  },
  {
    name: "Thị trấn Tân Yên",
    code: 2374,
    division_type: "thị trấn",
    codename: "thi_tran_tan_yen",
    district_code: 74,
  },
  {
    name: "Xã Yên Thuận",
    code: 2377,
    division_type: "xã",
    codename: "xa_yen_thuan",
    district_code: 74,
  },
  {
    name: "Xã Bạch Xa",
    code: 2380,
    division_type: "xã",
    codename: "xa_bach_xa",
    district_code: 74,
  },
  {
    name: "Xã Minh Khương",
    code: 2383,
    division_type: "xã",
    codename: "xa_minh_khuong",
    district_code: 74,
  },
  {
    name: "Xã Yên Lâm",
    code: 2386,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 74,
  },
  {
    name: "Xã Minh Dân",
    code: 2389,
    division_type: "xã",
    codename: "xa_minh_dan",
    district_code: 74,
  },
  {
    name: "Xã Phù Lưu",
    code: 2392,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 74,
  },
  {
    name: "Xã Minh Hương",
    code: 2395,
    division_type: "xã",
    codename: "xa_minh_huong",
    district_code: 74,
  },
  {
    name: "Xã Yên Phú",
    code: 2398,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 74,
  },
  {
    name: "Xã Tân Thành",
    code: 2401,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 74,
  },
  {
    name: "Xã Bình Xa",
    code: 2404,
    division_type: "xã",
    codename: "xa_binh_xa",
    district_code: 74,
  },
  {
    name: "Xã Thái Sơn",
    code: 2407,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 74,
  },
  {
    name: "Xã Nhân Mục",
    code: 2410,
    division_type: "xã",
    codename: "xa_nhan_muc",
    district_code: 74,
  },
  {
    name: "Xã Thành Long",
    code: 2413,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 74,
  },
  {
    name: "Xã Bằng Cốc",
    code: 2416,
    division_type: "xã",
    codename: "xa_bang_coc",
    district_code: 74,
  },
  {
    name: "Xã Thái Hòa",
    code: 2419,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 74,
  },
  {
    name: "Xã Đức Ninh",
    code: 2422,
    division_type: "xã",
    codename: "xa_duc_ninh",
    district_code: 74,
  },
  {
    name: "Xã Hùng Đức",
    code: 2425,
    division_type: "xã",
    codename: "xa_hung_duc",
    district_code: 74,
  },
  {
    name: "Xã Quí Quân",
    code: 2431,
    division_type: "xã",
    codename: "xa_qui_quan",
    district_code: 75,
  },
  {
    name: "Xã Lực Hành",
    code: 2434,
    division_type: "xã",
    codename: "xa_luc_hanh",
    district_code: 75,
  },
  {
    name: "Xã Kiến Thiết",
    code: 2437,
    division_type: "xã",
    codename: "xa_kien_thiet",
    district_code: 75,
  },
  {
    name: "Xã Trung Minh",
    code: 2440,
    division_type: "xã",
    codename: "xa_trung_minh",
    district_code: 75,
  },
  {
    name: "Xã Chiêu Yên",
    code: 2443,
    division_type: "xã",
    codename: "xa_chieu_yen",
    district_code: 75,
  },
  {
    name: "Xã Trung Trực",
    code: 2446,
    division_type: "xã",
    codename: "xa_trung_truc",
    district_code: 75,
  },
  {
    name: "Xã Xuân Vân",
    code: 2449,
    division_type: "xã",
    codename: "xa_xuan_van",
    district_code: 75,
  },
  {
    name: "Xã Phúc Ninh",
    code: 2452,
    division_type: "xã",
    codename: "xa_phuc_ninh",
    district_code: 75,
  },
  {
    name: "Xã Hùng Lợi",
    code: 2455,
    division_type: "xã",
    codename: "xa_hung_loi",
    district_code: 75,
  },
  {
    name: "Xã Trung Sơn",
    code: 2458,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 75,
  },
  {
    name: "Xã Tân Tiến",
    code: 2461,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 75,
  },
  {
    name: "Xã Tứ Quận",
    code: 2464,
    division_type: "xã",
    codename: "xa_tu_quan",
    district_code: 75,
  },
  {
    name: "Xã Đạo Viện",
    code: 2467,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 75,
  },
  {
    name: "Xã Tân Long",
    code: 2470,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 75,
  },
  {
    name: "Xã Thắng Quân",
    code: 2473,
    division_type: "xã",
    codename: "xa_thang_quan",
    district_code: 75,
  },
  {
    name: "Xã Kim Quan",
    code: 2476,
    division_type: "xã",
    codename: "xa_kim_quan",
    district_code: 75,
  },
  {
    name: "Xã Lang Quán",
    code: 2479,
    division_type: "xã",
    codename: "xa_lang_quan",
    district_code: 75,
  },
  {
    name: "Xã Phú Thịnh",
    code: 2482,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 75,
  },
  {
    name: "Xã Công Đa",
    code: 2485,
    division_type: "xã",
    codename: "xa_cong_da",
    district_code: 75,
  },
  {
    name: "Xã Trung Môn",
    code: 2488,
    division_type: "xã",
    codename: "xa_trung_mon",
    district_code: 75,
  },
  {
    name: "Xã Chân Sơn",
    code: 2491,
    division_type: "xã",
    codename: "xa_chan_son",
    district_code: 75,
  },
  {
    name: "Xã Thái Bình",
    code: 2494,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 75,
  },
  {
    name: "Xã Tiến Bộ",
    code: 2500,
    division_type: "xã",
    codename: "xa_tien_bo",
    district_code: 75,
  },
  {
    name: "Xã Mỹ Bằng",
    code: 2506,
    division_type: "xã",
    codename: "xa_my_bang",
    district_code: 75,
  },
  {
    name: "Xã Hoàng Khai",
    code: 2518,
    division_type: "xã",
    codename: "xa_hoang_khai",
    district_code: 75,
  },
  {
    name: "Xã Nhữ Hán",
    code: 2527,
    division_type: "xã",
    codename: "xa_nhu_han",
    district_code: 75,
  },
  {
    name: "Xã Nhữ Khê",
    code: 2530,
    division_type: "xã",
    codename: "xa_nhu_khe",
    district_code: 75,
  },
  {
    name: "Xã Đội Bình",
    code: 2533,
    division_type: "xã",
    codename: "xa_doi_binh",
    district_code: 75,
  },
  {
    name: "Thị trấn Sơn Dương",
    code: 2536,
    division_type: "thị trấn",
    codename: "thi_tran_son_duong",
    district_code: 76,
  },
  {
    name: "Xã Trung Yên",
    code: 2539,
    division_type: "xã",
    codename: "xa_trung_yen",
    district_code: 76,
  },
  {
    name: "Xã Minh Thanh",
    code: 2542,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 76,
  },
  {
    name: "Xã Tân Trào",
    code: 2545,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 76,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 2548,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 76,
  },
  {
    name: "Xã Thượng Ấm",
    code: 2551,
    division_type: "xã",
    codename: "xa_thuong_am",
    district_code: 76,
  },
  {
    name: "Xã Bình Yên",
    code: 2554,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 76,
  },
  {
    name: "Xã Lương Thiện",
    code: 2557,
    division_type: "xã",
    codename: "xa_luong_thien",
    district_code: 76,
  },
  {
    name: "Xã Tú Thịnh",
    code: 2560,
    division_type: "xã",
    codename: "xa_tu_thinh",
    district_code: 76,
  },
  {
    name: "Xã Cấp Tiến",
    code: 2563,
    division_type: "xã",
    codename: "xa_cap_tien",
    district_code: 76,
  },
  {
    name: "Xã Hợp Thành",
    code: 2566,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 76,
  },
  {
    name: "Xã Phúc Ứng",
    code: 2569,
    division_type: "xã",
    codename: "xa_phuc_ung",
    district_code: 76,
  },
  {
    name: "Xã Đông Thọ",
    code: 2572,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 76,
  },
  {
    name: "Xã Kháng Nhật",
    code: 2575,
    division_type: "xã",
    codename: "xa_khang_nhat",
    district_code: 76,
  },
  {
    name: "Xã Hợp Hòa",
    code: 2578,
    division_type: "xã",
    codename: "xa_hop_hoa",
    district_code: 76,
  },
  {
    name: "Xã Quyết Thắng",
    code: 2584,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 76,
  },
  {
    name: "Xã Đồng Quý",
    code: 2587,
    division_type: "xã",
    codename: "xa_dong_quy",
    district_code: 76,
  },
  {
    name: "Xã Tân Thanh",
    code: 2590,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 76,
  },
  {
    name: "Xã Vân Sơn",
    code: 2593,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 76,
  },
  {
    name: "Xã Văn Phú",
    code: 2596,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 76,
  },
  {
    name: "Xã Chi Thiết",
    code: 2599,
    division_type: "xã",
    codename: "xa_chi_thiet",
    district_code: 76,
  },
  {
    name: "Xã Đông Lợi",
    code: 2602,
    division_type: "xã",
    codename: "xa_dong_loi",
    district_code: 76,
  },
  {
    name: "Xã Thiện Kế",
    code: 2605,
    division_type: "xã",
    codename: "xa_thien_ke",
    district_code: 76,
  },
  {
    name: "Xã Hồng Lạc",
    code: 2608,
    division_type: "xã",
    codename: "xa_hong_lac",
    district_code: 76,
  },
  {
    name: "Xã Phú Lương",
    code: 2611,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 76,
  },
  {
    name: "Xã Ninh Lai",
    code: 2614,
    division_type: "xã",
    codename: "xa_ninh_lai",
    district_code: 76,
  },
  {
    name: "Xã Đại Phú",
    code: 2617,
    division_type: "xã",
    codename: "xa_dai_phu",
    district_code: 76,
  },
  {
    name: "Xã Sơn Nam",
    code: 2620,
    division_type: "xã",
    codename: "xa_son_nam",
    district_code: 76,
  },
  {
    name: "Xã Hào Phú",
    code: 2623,
    division_type: "xã",
    codename: "xa_hao_phu",
    district_code: 76,
  },
  {
    name: "Xã Tam Đa",
    code: 2626,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 76,
  },
  {
    name: "Xã Trường Sinh",
    code: 2632,
    division_type: "xã",
    codename: "xa_truong_sinh",
    district_code: 76,
  },
  {
    name: "Phường Duyên Hải",
    code: 2635,
    division_type: "phường",
    codename: "phuong_duyen_hai",
    district_code: 80,
  },
  {
    name: "Phường Lào Cai",
    code: 2641,
    division_type: "phường",
    codename: "phuong_lao_cai",
    district_code: 80,
  },
  {
    name: "Phường Cốc Lếu",
    code: 2644,
    division_type: "phường",
    codename: "phuong_coc_leu",
    district_code: 80,
  },
  {
    name: "Phường Kim Tân",
    code: 2647,
    division_type: "phường",
    codename: "phuong_kim_tan",
    district_code: 80,
  },
  {
    name: "Phường Bắc Lệnh",
    code: 2650,
    division_type: "phường",
    codename: "phuong_bac_lenh",
    district_code: 80,
  },
  {
    name: "Phường Pom Hán",
    code: 2653,
    division_type: "phường",
    codename: "phuong_pom_han",
    district_code: 80,
  },
  {
    name: "Phường Xuân Tăng",
    code: 2656,
    division_type: "phường",
    codename: "phuong_xuan_tang",
    district_code: 80,
  },
  {
    name: "Phường Bình Minh",
    code: 2658,
    division_type: "phường",
    codename: "phuong_binh_minh",
    district_code: 80,
  },
  {
    name: "Xã Thống Nhất",
    code: 2659,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 80,
  },
  {
    name: "Xã Đồng Tuyển",
    code: 2662,
    division_type: "xã",
    codename: "xa_dong_tuyen",
    district_code: 80,
  },
  {
    name: "Xã Vạn Hoà",
    code: 2665,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 80,
  },
  {
    name: "Phường Bắc Cường",
    code: 2668,
    division_type: "phường",
    codename: "phuong_bac_cuong",
    district_code: 80,
  },
  {
    name: "Phường Nam Cường",
    code: 2671,
    division_type: "phường",
    codename: "phuong_nam_cuong",
    district_code: 80,
  },
  {
    name: "Xã Cam Đường",
    code: 2674,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 80,
  },
  {
    name: "Xã Tả Phời",
    code: 2677,
    division_type: "xã",
    codename: "xa_ta_phoi",
    district_code: 80,
  },
  {
    name: "Xã Hợp Thành",
    code: 2680,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 80,
  },
  {
    name: "Xã Cốc San",
    code: 2746,
    division_type: "xã",
    codename: "xa_coc_san",
    district_code: 80,
  },
  {
    name: "Thị trấn Bát Xát",
    code: 2683,
    division_type: "thị trấn",
    codename: "thi_tran_bat_xat",
    district_code: 82,
  },
  {
    name: "Xã A Mú Sung",
    code: 2686,
    division_type: "xã",
    codename: "xa_a_mu_sung",
    district_code: 82,
  },
  {
    name: "Xã Nậm Chạc",
    code: 2689,
    division_type: "xã",
    codename: "xa_nam_chac",
    district_code: 82,
  },
  {
    name: "Xã A Lù",
    code: 2692,
    division_type: "xã",
    codename: "xa_a_lu",
    district_code: 82,
  },
  {
    name: "Xã Trịnh Tường",
    code: 2695,
    division_type: "xã",
    codename: "xa_trinh_tuong",
    district_code: 82,
  },
  {
    name: "Xã Y Tý",
    code: 2701,
    division_type: "xã",
    codename: "xa_y_ty",
    district_code: 82,
  },
  {
    name: "Xã Cốc Mỳ",
    code: 2704,
    division_type: "xã",
    codename: "xa_coc_my",
    district_code: 82,
  },
  {
    name: "Xã Dền Sáng",
    code: 2707,
    division_type: "xã",
    codename: "xa_den_sang",
    district_code: 82,
  },
  {
    name: "Xã Bản Vược",
    code: 2710,
    division_type: "xã",
    codename: "xa_ban_vuoc",
    district_code: 82,
  },
  {
    name: "Xã Sàng Ma Sáo",
    code: 2713,
    division_type: "xã",
    codename: "xa_sang_ma_sao",
    district_code: 82,
  },
  {
    name: "Xã Bản Qua",
    code: 2716,
    division_type: "xã",
    codename: "xa_ban_qua",
    district_code: 82,
  },
  {
    name: "Xã Mường Vi",
    code: 2719,
    division_type: "xã",
    codename: "xa_muong_vi",
    district_code: 82,
  },
  {
    name: "Xã Dền Thàng",
    code: 2722,
    division_type: "xã",
    codename: "xa_den_thang",
    district_code: 82,
  },
  {
    name: "Xã Bản Xèo",
    code: 2725,
    division_type: "xã",
    codename: "xa_ban_xeo",
    district_code: 82,
  },
  {
    name: "Xã Mường Hum",
    code: 2728,
    division_type: "xã",
    codename: "xa_muong_hum",
    district_code: 82,
  },
  {
    name: "Xã Trung Lèng Hồ",
    code: 2731,
    division_type: "xã",
    codename: "xa_trung_leng_ho",
    district_code: 82,
  },
  {
    name: "Xã Quang Kim",
    code: 2734,
    division_type: "xã",
    codename: "xa_quang_kim",
    district_code: 82,
  },
  {
    name: "Xã Pa Cheo",
    code: 2737,
    division_type: "xã",
    codename: "xa_pa_cheo",
    district_code: 82,
  },
  {
    name: "Xã Nậm Pung",
    code: 2740,
    division_type: "xã",
    codename: "xa_nam_pung",
    district_code: 82,
  },
  {
    name: "Xã Phìn Ngan",
    code: 2743,
    division_type: "xã",
    codename: "xa_phin_ngan",
    district_code: 82,
  },
  {
    name: "Xã Tòng Sành",
    code: 2749,
    division_type: "xã",
    codename: "xa_tong_sanh",
    district_code: 82,
  },
  {
    name: "Xã Pha Long",
    code: 2752,
    division_type: "xã",
    codename: "xa_pha_long",
    district_code: 83,
  },
  {
    name: "Xã Tả Ngải Chồ",
    code: 2755,
    division_type: "xã",
    codename: "xa_ta_ngai_cho",
    district_code: 83,
  },
  {
    name: "Xã Tung Chung Phố",
    code: 2758,
    division_type: "xã",
    codename: "xa_tung_chung_pho",
    district_code: 83,
  },
  {
    name: "Thị trấn Mường Khương",
    code: 2761,
    division_type: "thị trấn",
    codename: "thi_tran_muong_khuong",
    district_code: 83,
  },
  {
    name: "Xã Dìn Chin",
    code: 2764,
    division_type: "xã",
    codename: "xa_din_chin",
    district_code: 83,
  },
  {
    name: "Xã Tả Gia Khâu",
    code: 2767,
    division_type: "xã",
    codename: "xa_ta_gia_khau",
    district_code: 83,
  },
  {
    name: "Xã Nậm Chảy",
    code: 2770,
    division_type: "xã",
    codename: "xa_nam_chay",
    district_code: 83,
  },
  {
    name: "Xã Nấm Lư",
    code: 2773,
    division_type: "xã",
    codename: "xa_nam_lu",
    district_code: 83,
  },
  {
    name: "Xã Lùng Khấu Nhin",
    code: 2776,
    division_type: "xã",
    codename: "xa_lung_khau_nhin",
    district_code: 83,
  },
  {
    name: "Xã Thanh Bình",
    code: 2779,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 83,
  },
  {
    name: "Xã Cao Sơn",
    code: 2782,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 83,
  },
  {
    name: "Xã Lùng Vai",
    code: 2785,
    division_type: "xã",
    codename: "xa_lung_vai",
    district_code: 83,
  },
  {
    name: "Xã Bản Lầu",
    code: 2788,
    division_type: "xã",
    codename: "xa_ban_lau",
    district_code: 83,
  },
  {
    name: "Xã La Pan Tẩn",
    code: 2791,
    division_type: "xã",
    codename: "xa_la_pan_tan",
    district_code: 83,
  },
  {
    name: "Xã Tả Thàng",
    code: 2794,
    division_type: "xã",
    codename: "xa_ta_thang",
    district_code: 83,
  },
  {
    name: "Xã Bản Sen",
    code: 2797,
    division_type: "xã",
    codename: "xa_ban_sen",
    district_code: 83,
  },
  {
    name: "Xã Nàn Sán",
    code: 2800,
    division_type: "xã",
    codename: "xa_nan_san",
    district_code: 84,
  },
  {
    name: "Xã Thào Chư Phìn",
    code: 2803,
    division_type: "xã",
    codename: "xa_thao_chu_phin",
    district_code: 84,
  },
  {
    name: "Xã Bản Mế",
    code: 2806,
    division_type: "xã",
    codename: "xa_ban_me",
    district_code: 84,
  },
  {
    name: "Thị trấn Si Ma Cai",
    code: 2809,
    division_type: "thị trấn",
    codename: "thi_tran_si_ma_cai",
    district_code: 84,
  },
  {
    name: "Xã Sán Chải",
    code: 2812,
    division_type: "xã",
    codename: "xa_san_chai",
    district_code: 84,
  },
  {
    name: "Xã Lùng Thẩn",
    code: 2818,
    division_type: "xã",
    codename: "xa_lung_than",
    district_code: 84,
  },
  {
    name: "Xã Cán Cấu",
    code: 2821,
    division_type: "xã",
    codename: "xa_can_cau",
    district_code: 84,
  },
  {
    name: "Xã Sín Chéng",
    code: 2824,
    division_type: "xã",
    codename: "xa_sin_cheng",
    district_code: 84,
  },
  {
    name: "Xã Quan Hồ Thẩn",
    code: 2827,
    division_type: "xã",
    codename: "xa_quan_ho_than",
    district_code: 84,
  },
  {
    name: "Xã Nàn Xín",
    code: 2836,
    division_type: "xã",
    codename: "xa_nan_xin",
    district_code: 84,
  },
  {
    name: "Thị trấn Bắc Hà",
    code: 2839,
    division_type: "thị trấn",
    codename: "thi_tran_bac_ha",
    district_code: 85,
  },
  {
    name: "Xã Lùng Cải",
    code: 2842,
    division_type: "xã",
    codename: "xa_lung_cai",
    district_code: 85,
  },
  {
    name: "Xã Lùng Phình",
    code: 2848,
    division_type: "xã",
    codename: "xa_lung_phinh",
    district_code: 85,
  },
  {
    name: "Xã Tả Van Chư",
    code: 2851,
    division_type: "xã",
    codename: "xa_ta_van_chu",
    district_code: 85,
  },
  {
    name: "Xã Tả Củ Tỷ",
    code: 2854,
    division_type: "xã",
    codename: "xa_ta_cu_ty",
    district_code: 85,
  },
  {
    name: "Xã Thải Giàng Phố",
    code: 2857,
    division_type: "xã",
    codename: "xa_thai_giang_pho",
    district_code: 85,
  },
  {
    name: "Xã Hoàng Thu Phố",
    code: 2863,
    division_type: "xã",
    codename: "xa_hoang_thu_pho",
    district_code: 85,
  },
  {
    name: "Xã Bản Phố",
    code: 2866,
    division_type: "xã",
    codename: "xa_ban_pho",
    district_code: 85,
  },
  {
    name: "Xã Bản Liền",
    code: 2869,
    division_type: "xã",
    codename: "xa_ban_lien",
    district_code: 85,
  },
  {
    name: "Xã Tà Chải",
    code: 2872,
    division_type: "xã",
    codename: "xa_ta_chai",
    district_code: 85,
  },
  {
    name: "Xã Na Hối",
    code: 2875,
    division_type: "xã",
    codename: "xa_na_hoi",
    district_code: 85,
  },
  {
    name: "Xã Cốc Ly",
    code: 2878,
    division_type: "xã",
    codename: "xa_coc_ly",
    district_code: 85,
  },
  {
    name: "Xã Nậm Mòn",
    code: 2881,
    division_type: "xã",
    codename: "xa_nam_mon",
    district_code: 85,
  },
  {
    name: "Xã Nậm Đét",
    code: 2884,
    division_type: "xã",
    codename: "xa_nam_det",
    district_code: 85,
  },
  {
    name: "Xã Nậm Khánh",
    code: 2887,
    division_type: "xã",
    codename: "xa_nam_khanh",
    district_code: 85,
  },
  {
    name: "Xã Bảo Nhai",
    code: 2890,
    division_type: "xã",
    codename: "xa_bao_nhai",
    district_code: 85,
  },
  {
    name: "Xã Nậm Lúc",
    code: 2893,
    division_type: "xã",
    codename: "xa_nam_luc",
    district_code: 85,
  },
  {
    name: "Xã Cốc Lầu",
    code: 2896,
    division_type: "xã",
    codename: "xa_coc_lau",
    district_code: 85,
  },
  {
    name: "Xã Bản Cái",
    code: 2899,
    division_type: "xã",
    codename: "xa_ban_cai",
    district_code: 85,
  },
  {
    name: "Thị trấn N.T Phong Hải",
    code: 2902,
    division_type: "thị trấn",
    codename: "thi_tran_n_t_phong_hai",
    district_code: 86,
  },
  {
    name: "Thị trấn Phố Lu",
    code: 2905,
    division_type: "thị trấn",
    codename: "thi_tran_pho_lu",
    district_code: 86,
  },
  {
    name: "Thị trấn Tằng Loỏng",
    code: 2908,
    division_type: "thị trấn",
    codename: "thi_tran_tang_loong",
    district_code: 86,
  },
  {
    name: "Xã Bản Phiệt",
    code: 2911,
    division_type: "xã",
    codename: "xa_ban_phiet",
    district_code: 86,
  },
  {
    name: "Xã Bản Cầm",
    code: 2914,
    division_type: "xã",
    codename: "xa_ban_cam",
    district_code: 86,
  },
  {
    name: "Xã Thái Niên",
    code: 2917,
    division_type: "xã",
    codename: "xa_thai_nien",
    district_code: 86,
  },
  {
    name: "Xã Phong Niên",
    code: 2920,
    division_type: "xã",
    codename: "xa_phong_nien",
    district_code: 86,
  },
  {
    name: "Xã Gia Phú",
    code: 2923,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 86,
  },
  {
    name: "Xã Xuân Quang",
    code: 2926,
    division_type: "xã",
    codename: "xa_xuan_quang",
    district_code: 86,
  },
  {
    name: "Xã Sơn Hải",
    code: 2929,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 86,
  },
  {
    name: "Xã Xuân Giao",
    code: 2932,
    division_type: "xã",
    codename: "xa_xuan_giao",
    district_code: 86,
  },
  {
    name: "Xã Trì Quang",
    code: 2935,
    division_type: "xã",
    codename: "xa_tri_quang",
    district_code: 86,
  },
  {
    name: "Xã Sơn Hà",
    code: 2938,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 86,
  },
  {
    name: "Xã Phú Nhuận",
    code: 2944,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 86,
  },
  {
    name: "Thị trấn Phố Ràng",
    code: 2947,
    division_type: "thị trấn",
    codename: "thi_tran_pho_rang",
    district_code: 87,
  },
  {
    name: "Xã Tân Tiến",
    code: 2950,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 87,
  },
  {
    name: "Xã Nghĩa Đô",
    code: 2953,
    division_type: "xã",
    codename: "xa_nghia_do",
    district_code: 87,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 2956,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 87,
  },
  {
    name: "Xã Điện Quan",
    code: 2959,
    division_type: "xã",
    codename: "xa_dien_quan",
    district_code: 87,
  },
  {
    name: "Xã Xuân Hoà",
    code: 2962,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 87,
  },
  {
    name: "Xã Tân Dương",
    code: 2965,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 87,
  },
  {
    name: "Xã Thượng Hà",
    code: 2968,
    division_type: "xã",
    codename: "xa_thuong_ha",
    district_code: 87,
  },
  {
    name: "Xã Kim Sơn",
    code: 2971,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 87,
  },
  {
    name: "Xã Cam Cọn",
    code: 2974,
    division_type: "xã",
    codename: "xa_cam_con",
    district_code: 87,
  },
  {
    name: "Xã Minh Tân",
    code: 2977,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 87,
  },
  {
    name: "Xã Xuân Thượng",
    code: 2980,
    division_type: "xã",
    codename: "xa_xuan_thuong",
    district_code: 87,
  },
  {
    name: "Xã Việt Tiến",
    code: 2983,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 87,
  },
  {
    name: "Xã Yên Sơn",
    code: 2986,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 87,
  },
  {
    name: "Xã Bảo Hà",
    code: 2989,
    division_type: "xã",
    codename: "xa_bao_ha",
    district_code: 87,
  },
  {
    name: "Xã Lương Sơn",
    code: 2992,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 87,
  },
  {
    name: "Xã Phúc Khánh",
    code: 2998,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 87,
  },
  {
    name: "Phường Sa Pa",
    code: 3001,
    division_type: "phường",
    codename: "phuong_sa_pa",
    district_code: 88,
  },
  {
    name: "Phường Sa Pả",
    code: 3002,
    division_type: "phường",
    codename: "phuong_sa_pa",
    district_code: 88,
  },
  {
    name: "Phường Ô Quý Hồ",
    code: 3003,
    division_type: "phường",
    codename: "phuong_o_quy_ho",
    district_code: 88,
  },
  {
    name: "Xã Ngũ Chỉ Sơn",
    code: 3004,
    division_type: "xã",
    codename: "xa_ngu_chi_son",
    district_code: 88,
  },
  {
    name: "Phường Phan Si Păng",
    code: 3006,
    division_type: "phường",
    codename: "phuong_phan_si_pang",
    district_code: 88,
  },
  {
    name: "Xã Trung Chải",
    code: 3010,
    division_type: "xã",
    codename: "xa_trung_chai",
    district_code: 88,
  },
  {
    name: "Xã Tả Phìn",
    code: 3013,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 88,
  },
  {
    name: "Phường Hàm Rồng",
    code: 3016,
    division_type: "phường",
    codename: "phuong_ham_rong",
    district_code: 88,
  },
  {
    name: "Xã Hoàng Liên",
    code: 3019,
    division_type: "xã",
    codename: "xa_hoang_lien",
    district_code: 88,
  },
  {
    name: "Xã Thanh Bình",
    code: 3022,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 88,
  },
  {
    name: "Phường Cầu Mây",
    code: 3028,
    division_type: "phường",
    codename: "phuong_cau_may",
    district_code: 88,
  },
  {
    name: "Xã Mường Hoa",
    code: 3037,
    division_type: "xã",
    codename: "xa_muong_hoa",
    district_code: 88,
  },
  {
    name: "Xã Tả Van",
    code: 3040,
    division_type: "xã",
    codename: "xa_ta_van",
    district_code: 88,
  },
  {
    name: "Xã Mường Bo",
    code: 3043,
    division_type: "xã",
    codename: "xa_muong_bo",
    district_code: 88,
  },
  {
    name: "Xã Bản Hồ",
    code: 3046,
    division_type: "xã",
    codename: "xa_ban_ho",
    district_code: 88,
  },
  {
    name: "Xã Liên Minh",
    code: 3052,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 88,
  },
  {
    name: "Thị trấn Khánh Yên",
    code: 3055,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_yen",
    district_code: 89,
  },
  {
    name: "Xã Võ Lao",
    code: 3061,
    division_type: "xã",
    codename: "xa_vo_lao",
    district_code: 89,
  },
  {
    name: "Xã Sơn Thuỷ",
    code: 3064,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 89,
  },
  {
    name: "Xã Nậm Mả",
    code: 3067,
    division_type: "xã",
    codename: "xa_nam_ma",
    district_code: 89,
  },
  {
    name: "Xã Tân Thượng",
    code: 3070,
    division_type: "xã",
    codename: "xa_tan_thuong",
    district_code: 89,
  },
  {
    name: "Xã Nậm Rạng",
    code: 3073,
    division_type: "xã",
    codename: "xa_nam_rang",
    district_code: 89,
  },
  {
    name: "Xã Nậm Chầy",
    code: 3076,
    division_type: "xã",
    codename: "xa_nam_chay",
    district_code: 89,
  },
  {
    name: "Xã Tân An",
    code: 3079,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Thượng",
    code: 3082,
    division_type: "xã",
    codename: "xa_khanh_yen_thuong",
    district_code: 89,
  },
  {
    name: "Xã Nậm Xé",
    code: 3085,
    division_type: "xã",
    codename: "xa_nam_xe",
    district_code: 89,
  },
  {
    name: "Xã Dần Thàng",
    code: 3088,
    division_type: "xã",
    codename: "xa_dan_thang",
    district_code: 89,
  },
  {
    name: "Xã Chiềng Ken",
    code: 3091,
    division_type: "xã",
    codename: "xa_chieng_ken",
    district_code: 89,
  },
  {
    name: "Xã Làng Giàng",
    code: 3094,
    division_type: "xã",
    codename: "xa_lang_giang",
    district_code: 89,
  },
  {
    name: "Xã Hoà Mạc",
    code: 3097,
    division_type: "xã",
    codename: "xa_hoa_mac",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Trung",
    code: 3100,
    division_type: "xã",
    codename: "xa_khanh_yen_trung",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Hạ",
    code: 3103,
    division_type: "xã",
    codename: "xa_khanh_yen_ha",
    district_code: 89,
  },
  {
    name: "Xã Dương Quỳ",
    code: 3106,
    division_type: "xã",
    codename: "xa_duong_quy",
    district_code: 89,
  },
  {
    name: "Xã Nậm Tha",
    code: 3109,
    division_type: "xã",
    codename: "xa_nam_tha",
    district_code: 89,
  },
  {
    name: "Xã Minh Lương",
    code: 3112,
    division_type: "xã",
    codename: "xa_minh_luong",
    district_code: 89,
  },
  {
    name: "Xã Thẩm Dương",
    code: 3115,
    division_type: "xã",
    codename: "xa_tham_duong",
    district_code: 89,
  },
  {
    name: "Xã Liêm Phú",
    code: 3118,
    division_type: "xã",
    codename: "xa_liem_phu",
    district_code: 89,
  },
  {
    name: "Xã Nậm Xây",
    code: 3121,
    division_type: "xã",
    codename: "xa_nam_xay",
    district_code: 89,
  },
  {
    name: "Phường Noong Bua",
    code: 3124,
    division_type: "phường",
    codename: "phuong_noong_bua",
    district_code: 94,
  },
  {
    name: "Phường Him Lam",
    code: 3127,
    division_type: "phường",
    codename: "phuong_him_lam",
    district_code: 94,
  },
  {
    name: "Phường Thanh Bình",
    code: 3130,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 94,
  },
  {
    name: "Phường Tân Thanh",
    code: 3133,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 94,
  },
  {
    name: "Phường Mường Thanh",
    code: 3136,
    division_type: "phường",
    codename: "phuong_muong_thanh",
    district_code: 94,
  },
  {
    name: "Phường Nam Thanh",
    code: 3139,
    division_type: "phường",
    codename: "phuong_nam_thanh",
    district_code: 94,
  },
  {
    name: "Phường Thanh Trường",
    code: 3142,
    division_type: "phường",
    codename: "phuong_thanh_truong",
    district_code: 94,
  },
  {
    name: "Xã Thanh Minh",
    code: 3145,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 94,
  },
  {
    name: "Xã Nà Tấu",
    code: 3316,
    division_type: "xã",
    codename: "xa_na_tau",
    district_code: 94,
  },
  {
    name: "Xã Nà Nhạn",
    code: 3317,
    division_type: "xã",
    codename: "xa_na_nhan",
    district_code: 94,
  },
  {
    name: "Xã Mường Phăng",
    code: 3325,
    division_type: "xã",
    codename: "xa_muong_phang",
    district_code: 94,
  },
  {
    name: "Xã Pá Khoang",
    code: 3326,
    division_type: "xã",
    codename: "xa_pa_khoang",
    district_code: 94,
  },
  {
    name: "Phường Sông Đà",
    code: 3148,
    division_type: "phường",
    codename: "phuong_song_da",
    district_code: 95,
  },
  {
    name: "Phường Na Lay",
    code: 3151,
    division_type: "phường",
    codename: "phuong_na_lay",
    district_code: 95,
  },
  {
    name: "Xã Lay Nưa",
    code: 3184,
    division_type: "xã",
    codename: "xa_lay_nua",
    district_code: 95,
  },
  {
    name: "Xã Sín Thầu",
    code: 3154,
    division_type: "xã",
    codename: "xa_sin_thau",
    district_code: 96,
  },
  {
    name: "Xã Sen Thượng",
    code: 3155,
    division_type: "xã",
    codename: "xa_sen_thuong",
    district_code: 96,
  },
  {
    name: "Xã Chung Chải",
    code: 3157,
    division_type: "xã",
    codename: "xa_chung_chai",
    district_code: 96,
  },
  {
    name: "Xã Leng Su Sìn",
    code: 3158,
    division_type: "xã",
    codename: "xa_leng_su_sin",
    district_code: 96,
  },
  {
    name: "Xã Pá Mỳ",
    code: 3159,
    division_type: "xã",
    codename: "xa_pa_my",
    district_code: 96,
  },
  {
    name: "Xã Mường Nhé",
    code: 3160,
    division_type: "xã",
    codename: "xa_muong_nhe",
    district_code: 96,
  },
  {
    name: "Xã Nậm Vì",
    code: 3161,
    division_type: "xã",
    codename: "xa_nam_vi",
    district_code: 96,
  },
  {
    name: "Xã Nậm Kè",
    code: 3162,
    division_type: "xã",
    codename: "xa_nam_ke",
    district_code: 96,
  },
  {
    name: "Xã Mường Toong",
    code: 3163,
    division_type: "xã",
    codename: "xa_muong_toong",
    district_code: 96,
  },
  {
    name: "Xã Quảng Lâm",
    code: 3164,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 96,
  },
  {
    name: "Xã Huổi Lếnh",
    code: 3177,
    division_type: "xã",
    codename: "xa_huoi_lenh",
    district_code: 96,
  },
  {
    name: "Thị trấn Mường Chà",
    code: 3172,
    division_type: "thị trấn",
    codename: "thi_tran_muong_cha",
    district_code: 97,
  },
  {
    name: "Xã Xá Tổng",
    code: 3178,
    division_type: "xã",
    codename: "xa_xa_tong",
    district_code: 97,
  },
  {
    name: "Xã Mường Tùng",
    code: 3181,
    division_type: "xã",
    codename: "xa_muong_tung",
    district_code: 97,
  },
  {
    name: "Xã Hừa Ngài",
    code: 3190,
    division_type: "xã",
    codename: "xa_hua_ngai",
    district_code: 97,
  },
  {
    name: "Xã Huổi Mí",
    code: 3191,
    division_type: "xã",
    codename: "xa_huoi_mi",
    district_code: 97,
  },
  {
    name: "Xã Pa Ham",
    code: 3193,
    division_type: "xã",
    codename: "xa_pa_ham",
    district_code: 97,
  },
  {
    name: "Xã Nậm Nèn",
    code: 3194,
    division_type: "xã",
    codename: "xa_nam_nen",
    district_code: 97,
  },
  {
    name: "Xã Huổi Lèng",
    code: 3196,
    division_type: "xã",
    codename: "xa_huoi_leng",
    district_code: 97,
  },
  {
    name: "Xã Sa Lông",
    code: 3197,
    division_type: "xã",
    codename: "xa_sa_long",
    district_code: 97,
  },
  {
    name: "Xã Ma Thì Hồ",
    code: 3200,
    division_type: "xã",
    codename: "xa_ma_thi_ho",
    district_code: 97,
  },
  {
    name: "Xã Na Sang",
    code: 3201,
    division_type: "xã",
    codename: "xa_na_sang",
    district_code: 97,
  },
  {
    name: "Xã Mường Mươn",
    code: 3202,
    division_type: "xã",
    codename: "xa_muong_muon",
    district_code: 97,
  },
  {
    name: "Thị trấn Tủa Chùa",
    code: 3217,
    division_type: "thị trấn",
    codename: "thi_tran_tua_chua",
    district_code: 98,
  },
  {
    name: "Xã Huổi Só",
    code: 3220,
    division_type: "xã",
    codename: "xa_huoi_so",
    district_code: 98,
  },
  {
    name: "Xã Xín Chải",
    code: 3223,
    division_type: "xã",
    codename: "xa_xin_chai",
    district_code: 98,
  },
  {
    name: "Xã Tả Sìn Thàng",
    code: 3226,
    division_type: "xã",
    codename: "xa_ta_sin_thang",
    district_code: 98,
  },
  {
    name: "Xã Lao Xả Phình",
    code: 3229,
    division_type: "xã",
    codename: "xa_lao_xa_phinh",
    district_code: 98,
  },
  {
    name: "Xã Tả Phìn",
    code: 3232,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 98,
  },
  {
    name: "Xã Tủa Thàng",
    code: 3235,
    division_type: "xã",
    codename: "xa_tua_thang",
    district_code: 98,
  },
  {
    name: "Xã Trung Thu",
    code: 3238,
    division_type: "xã",
    codename: "xa_trung_thu",
    district_code: 98,
  },
  {
    name: "Xã Sính Phình",
    code: 3241,
    division_type: "xã",
    codename: "xa_sinh_phinh",
    district_code: 98,
  },
  {
    name: "Xã Sáng Nhè",
    code: 3244,
    division_type: "xã",
    codename: "xa_sang_nhe",
    district_code: 98,
  },
  {
    name: "Xã Mường Đun",
    code: 3247,
    division_type: "xã",
    codename: "xa_muong_dun",
    district_code: 98,
  },
  {
    name: "Xã Mường Báng",
    code: 3250,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 98,
  },
  {
    name: "Thị trấn Tuần Giáo",
    code: 3253,
    division_type: "thị trấn",
    codename: "thi_tran_tuan_giao",
    district_code: 99,
  },
  {
    name: "Xã Phình Sáng",
    code: 3259,
    division_type: "xã",
    codename: "xa_phinh_sang",
    district_code: 99,
  },
  {
    name: "Xã Rạng Đông",
    code: 3260,
    division_type: "xã",
    codename: "xa_rang_dong",
    district_code: 99,
  },
  {
    name: "Xã Mùn Chung",
    code: 3262,
    division_type: "xã",
    codename: "xa_mun_chung",
    district_code: 99,
  },
  {
    name: "Xã Nà Tòng",
    code: 3263,
    division_type: "xã",
    codename: "xa_na_tong",
    district_code: 99,
  },
  {
    name: "Xã Ta Ma",
    code: 3265,
    division_type: "xã",
    codename: "xa_ta_ma",
    district_code: 99,
  },
  {
    name: "Xã Mường Mùn",
    code: 3268,
    division_type: "xã",
    codename: "xa_muong_mun",
    district_code: 99,
  },
  {
    name: "Xã Pú Xi",
    code: 3269,
    division_type: "xã",
    codename: "xa_pu_xi",
    district_code: 99,
  },
  {
    name: "Xã Pú Nhung",
    code: 3271,
    division_type: "xã",
    codename: "xa_pu_nhung",
    district_code: 99,
  },
  {
    name: "Xã Quài Nưa",
    code: 3274,
    division_type: "xã",
    codename: "xa_quai_nua",
    district_code: 99,
  },
  {
    name: "Xã Mường Thín",
    code: 3277,
    division_type: "xã",
    codename: "xa_muong_thin",
    district_code: 99,
  },
  {
    name: "Xã Tỏa Tình",
    code: 3280,
    division_type: "xã",
    codename: "xa_toa_tinh",
    district_code: 99,
  },
  {
    name: "Xã Nà Sáy",
    code: 3283,
    division_type: "xã",
    codename: "xa_na_say",
    district_code: 99,
  },
  {
    name: "Xã Mường Khong",
    code: 3284,
    division_type: "xã",
    codename: "xa_muong_khong",
    district_code: 99,
  },
  {
    name: "Xã Quài Cang",
    code: 3289,
    division_type: "xã",
    codename: "xa_quai_cang",
    district_code: 99,
  },
  {
    name: "Xã Quài Tở",
    code: 3295,
    division_type: "xã",
    codename: "xa_quai_to",
    district_code: 99,
  },
  {
    name: "Xã Chiềng Sinh",
    code: 3298,
    division_type: "xã",
    codename: "xa_chieng_sinh",
    district_code: 99,
  },
  {
    name: "Xã Chiềng Đông",
    code: 3299,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 99,
  },
  {
    name: "Xã Tênh Phông",
    code: 3304,
    division_type: "xã",
    codename: "xa_tenh_phong",
    district_code: 99,
  },
  {
    name: "Xã Mường Pồn",
    code: 3319,
    division_type: "xã",
    codename: "xa_muong_pon",
    district_code: 100,
  },
  {
    name: "Xã Thanh Nưa",
    code: 3322,
    division_type: "xã",
    codename: "xa_thanh_nua",
    district_code: 100,
  },
  {
    name: "Xã Hua Thanh",
    code: 3323,
    division_type: "xã",
    codename: "xa_hua_thanh",
    district_code: 100,
  },
  {
    name: "Xã Thanh Luông",
    code: 3328,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 100,
  },
  {
    name: "Xã Thanh Hưng",
    code: 3331,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 100,
  },
  {
    name: "Xã Thanh Xương",
    code: 3334,
    division_type: "xã",
    codename: "xa_thanh_xuong",
    district_code: 100,
  },
  {
    name: "Xã Thanh Chăn",
    code: 3337,
    division_type: "xã",
    codename: "xa_thanh_chan",
    district_code: 100,
  },
  {
    name: "Xã Pa Thơm",
    code: 3340,
    division_type: "xã",
    codename: "xa_pa_thom",
    district_code: 100,
  },
  {
    name: "Xã Thanh An",
    code: 3343,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 100,
  },
  {
    name: "Xã Thanh Yên",
    code: 3346,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 100,
  },
  {
    name: "Xã Noong Luống",
    code: 3349,
    division_type: "xã",
    codename: "xa_noong_luong",
    district_code: 100,
  },
  {
    name: "Xã Noọng Hẹt",
    code: 3352,
    division_type: "xã",
    codename: "xa_noong_het",
    district_code: 100,
  },
  {
    name: "Xã Sam Mứn",
    code: 3355,
    division_type: "xã",
    codename: "xa_sam_mun",
    district_code: 100,
  },
  {
    name: "Xã Pom Lót",
    code: 3356,
    division_type: "xã",
    codename: "xa_pom_lot",
    district_code: 100,
  },
  {
    name: "Xã Núa Ngam",
    code: 3358,
    division_type: "xã",
    codename: "xa_nua_ngam",
    district_code: 100,
  },
  {
    name: "Xã Hẹ Muông",
    code: 3359,
    division_type: "xã",
    codename: "xa_he_muong",
    district_code: 100,
  },
  {
    name: "Xã Na Ư",
    code: 3361,
    division_type: "xã",
    codename: "xa_na_u",
    district_code: 100,
  },
  {
    name: "Xã Mường Nhà",
    code: 3364,
    division_type: "xã",
    codename: "xa_muong_nha",
    district_code: 100,
  },
  {
    name: "Xã Na Tông",
    code: 3365,
    division_type: "xã",
    codename: "xa_na_tong",
    district_code: 100,
  },
  {
    name: "Xã Mường Lói",
    code: 3367,
    division_type: "xã",
    codename: "xa_muong_loi",
    district_code: 100,
  },
  {
    name: "Xã Phu Luông",
    code: 3368,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 100,
  },
  {
    name: "Thị trấn Điện Biên Đông",
    code: 3203,
    division_type: "thị trấn",
    codename: "thi_tran_dien_bien_dong",
    district_code: 101,
  },
  {
    name: "Xã Na Son",
    code: 3205,
    division_type: "xã",
    codename: "xa_na_son",
    district_code: 101,
  },
  {
    name: "Xã Phì Nhừ",
    code: 3208,
    division_type: "xã",
    codename: "xa_phi_nhu",
    district_code: 101,
  },
  {
    name: "Xã Chiềng Sơ",
    code: 3211,
    division_type: "xã",
    codename: "xa_chieng_so",
    district_code: 101,
  },
  {
    name: "Xã Mường Luân",
    code: 3214,
    division_type: "xã",
    codename: "xa_muong_luan",
    district_code: 101,
  },
  {
    name: "Xã Pú Nhi",
    code: 3370,
    division_type: "xã",
    codename: "xa_pu_nhi",
    district_code: 101,
  },
  {
    name: "Xã Nong U",
    code: 3371,
    division_type: "xã",
    codename: "xa_nong_u",
    district_code: 101,
  },
  {
    name: "Xã Xa Dung",
    code: 3373,
    division_type: "xã",
    codename: "xa_xa_dung",
    district_code: 101,
  },
  {
    name: "Xã Keo Lôm",
    code: 3376,
    division_type: "xã",
    codename: "xa_keo_lom",
    district_code: 101,
  },
  {
    name: "Xã Luân Giới",
    code: 3379,
    division_type: "xã",
    codename: "xa_luan_gioi",
    district_code: 101,
  },
  {
    name: "Xã Phình Giàng",
    code: 3382,
    division_type: "xã",
    codename: "xa_phinh_giang",
    district_code: 101,
  },
  {
    name: "Xã Pú Hồng",
    code: 3383,
    division_type: "xã",
    codename: "xa_pu_hong",
    district_code: 101,
  },
  {
    name: "Xã Tìa Dình",
    code: 3384,
    division_type: "xã",
    codename: "xa_tia_dinh",
    district_code: 101,
  },
  {
    name: "Xã Háng Lìa",
    code: 3385,
    division_type: "xã",
    codename: "xa_hang_lia",
    district_code: 101,
  },
  {
    name: "Thị trấn Mường Ảng",
    code: 3256,
    division_type: "thị trấn",
    codename: "thi_tran_muong_ang",
    district_code: 102,
  },
  {
    name: "Xã Mường Đăng",
    code: 3286,
    division_type: "xã",
    codename: "xa_muong_dang",
    district_code: 102,
  },
  {
    name: "Xã Ngối Cáy",
    code: 3287,
    division_type: "xã",
    codename: "xa_ngoi_cay",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Tở",
    code: 3292,
    division_type: "xã",
    codename: "xa_ang_to",
    district_code: 102,
  },
  {
    name: "Xã Búng Lao",
    code: 3301,
    division_type: "xã",
    codename: "xa_bung_lao",
    district_code: 102,
  },
  {
    name: "Xã Xuân Lao",
    code: 3302,
    division_type: "xã",
    codename: "xa_xuan_lao",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Nưa",
    code: 3307,
    division_type: "xã",
    codename: "xa_ang_nua",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Cang",
    code: 3310,
    division_type: "xã",
    codename: "xa_ang_cang",
    district_code: 102,
  },
  {
    name: "Xã Nặm Lịch",
    code: 3312,
    division_type: "xã",
    codename: "xa_nam_lich",
    district_code: 102,
  },
  {
    name: "Xã Mường Lạn",
    code: 3313,
    division_type: "xã",
    codename: "xa_muong_lan",
    district_code: 102,
  },
  {
    name: "Xã Nậm Tin",
    code: 3156,
    division_type: "xã",
    codename: "xa_nam_tin",
    district_code: 103,
  },
  {
    name: "Xã Pa Tần",
    code: 3165,
    division_type: "xã",
    codename: "xa_pa_tan",
    district_code: 103,
  },
  {
    name: "Xã Chà Cang",
    code: 3166,
    division_type: "xã",
    codename: "xa_cha_cang",
    district_code: 103,
  },
  {
    name: "Xã Na Cô Sa",
    code: 3167,
    division_type: "xã",
    codename: "xa_na_co_sa",
    district_code: 103,
  },
  {
    name: "Xã Nà Khoa",
    code: 3168,
    division_type: "xã",
    codename: "xa_na_khoa",
    district_code: 103,
  },
  {
    name: "Xã Nà Hỳ",
    code: 3169,
    division_type: "xã",
    codename: "xa_na_hy",
    district_code: 103,
  },
  {
    name: "Xã Nà Bủng",
    code: 3170,
    division_type: "xã",
    codename: "xa_na_bung",
    district_code: 103,
  },
  {
    name: "Xã Nậm Nhừ",
    code: 3171,
    division_type: "xã",
    codename: "xa_nam_nhu",
    district_code: 103,
  },
  {
    name: "Xã Nậm Chua",
    code: 3173,
    division_type: "xã",
    codename: "xa_nam_chua",
    district_code: 103,
  },
  {
    name: "Xã Nậm Khăn",
    code: 3174,
    division_type: "xã",
    codename: "xa_nam_khan",
    district_code: 103,
  },
  {
    name: "Xã Chà Tở",
    code: 3175,
    division_type: "xã",
    codename: "xa_cha_to",
    district_code: 103,
  },
  {
    name: "Xã Vàng Đán",
    code: 3176,
    division_type: "xã",
    codename: "xa_vang_dan",
    district_code: 103,
  },
  {
    name: "Xã Chà Nưa",
    code: 3187,
    division_type: "xã",
    codename: "xa_cha_nua",
    district_code: 103,
  },
  {
    name: "Xã Phìn Hồ",
    code: 3198,
    division_type: "xã",
    codename: "xa_phin_ho",
    district_code: 103,
  },
  {
    name: "Xã Si Pa Phìn",
    code: 3199,
    division_type: "xã",
    codename: "xa_si_pa_phin",
    district_code: 103,
  },
  {
    name: "Phường Quyết Thắng",
    code: 3386,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 105,
  },
  {
    name: "Phường Tân Phong",
    code: 3387,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 105,
  },
  {
    name: "Phường Quyết Tiến",
    code: 3388,
    division_type: "phường",
    codename: "phuong_quyet_tien",
    district_code: 105,
  },
  {
    name: "Phường Đoàn Kết",
    code: 3389,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 105,
  },
  {
    name: "Xã Sùng Phài",
    code: 3403,
    division_type: "xã",
    codename: "xa_sung_phai",
    district_code: 105,
  },
  {
    name: "Phường Đông Phong",
    code: 3408,
    division_type: "phường",
    codename: "phuong_dong_phong",
    district_code: 105,
  },
  {
    name: "Xã San Thàng",
    code: 3409,
    division_type: "xã",
    codename: "xa_san_thang",
    district_code: 105,
  },
  {
    name: "Thị trấn Tam Đường",
    code: 3390,
    division_type: "thị trấn",
    codename: "thi_tran_tam_duong",
    district_code: 106,
  },
  {
    name: "Xã Thèn Sin",
    code: 3394,
    division_type: "xã",
    codename: "xa_then_sin",
    district_code: 106,
  },
  {
    name: "Xã Tả Lèng",
    code: 3400,
    division_type: "xã",
    codename: "xa_ta_leng",
    district_code: 106,
  },
  {
    name: "Xã Giang Ma",
    code: 3405,
    division_type: "xã",
    codename: "xa_giang_ma",
    district_code: 106,
  },
  {
    name: "Xã Hồ Thầu",
    code: 3406,
    division_type: "xã",
    codename: "xa_ho_thau",
    district_code: 106,
  },
  {
    name: "Xã Bình Lư",
    code: 3412,
    division_type: "xã",
    codename: "xa_binh_lu",
    district_code: 106,
  },
  {
    name: "Xã Sơn Bình",
    code: 3413,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 106,
  },
  {
    name: "Xã Nùng Nàng",
    code: 3415,
    division_type: "xã",
    codename: "xa_nung_nang",
    district_code: 106,
  },
  {
    name: "Xã Bản Giang",
    code: 3418,
    division_type: "xã",
    codename: "xa_ban_giang",
    district_code: 106,
  },
  {
    name: "Xã Bản Hon",
    code: 3421,
    division_type: "xã",
    codename: "xa_ban_hon",
    district_code: 106,
  },
  {
    name: "Xã Bản Bo",
    code: 3424,
    division_type: "xã",
    codename: "xa_ban_bo",
    district_code: 106,
  },
  {
    name: "Xã Nà Tăm",
    code: 3427,
    division_type: "xã",
    codename: "xa_na_tam",
    district_code: 106,
  },
  {
    name: "Xã Khun Há",
    code: 3430,
    division_type: "xã",
    codename: "xa_khun_ha",
    district_code: 106,
  },
  {
    name: "Thị trấn Mường Tè",
    code: 3433,
    division_type: "thị trấn",
    codename: "thi_tran_muong_te",
    district_code: 107,
  },
  {
    name: "Xã Thu Lũm",
    code: 3436,
    division_type: "xã",
    codename: "xa_thu_lum",
    district_code: 107,
  },
  {
    name: "Xã Ka Lăng",
    code: 3439,
    division_type: "xã",
    codename: "xa_ka_lang",
    district_code: 107,
  },
  {
    name: "Xã Tá Bạ",
    code: 3440,
    division_type: "xã",
    codename: "xa_ta_ba",
    district_code: 107,
  },
  {
    name: "Xã Pa ủ",
    code: 3442,
    division_type: "xã",
    codename: "xa_pa_u",
    district_code: 107,
  },
  {
    name: "Xã Mường Tè",
    code: 3445,
    division_type: "xã",
    codename: "xa_muong_te",
    district_code: 107,
  },
  {
    name: "Xã Pa Vệ Sử",
    code: 3448,
    division_type: "xã",
    codename: "xa_pa_ve_su",
    district_code: 107,
  },
  {
    name: "Xã Mù Cả",
    code: 3451,
    division_type: "xã",
    codename: "xa_mu_ca",
    district_code: 107,
  },
  {
    name: "Xã Bum Tở",
    code: 3454,
    division_type: "xã",
    codename: "xa_bum_to",
    district_code: 107,
  },
  {
    name: "Xã Nậm Khao",
    code: 3457,
    division_type: "xã",
    codename: "xa_nam_khao",
    district_code: 107,
  },
  {
    name: "Xã Tà Tổng",
    code: 3463,
    division_type: "xã",
    codename: "xa_ta_tong",
    district_code: 107,
  },
  {
    name: "Xã Bum Nưa",
    code: 3466,
    division_type: "xã",
    codename: "xa_bum_nua",
    district_code: 107,
  },
  {
    name: "Xã Vàng San",
    code: 3467,
    division_type: "xã",
    codename: "xa_vang_san",
    district_code: 107,
  },
  {
    name: "Xã Kan Hồ",
    code: 3469,
    division_type: "xã",
    codename: "xa_kan_ho",
    district_code: 107,
  },
  {
    name: "Thị trấn Sìn Hồ",
    code: 3478,
    division_type: "thị trấn",
    codename: "thi_tran_sin_ho",
    district_code: 108,
  },
  {
    name: "Xã Chăn Nưa",
    code: 3487,
    division_type: "xã",
    codename: "xa_chan_nua",
    district_code: 108,
  },
  {
    name: "Xã Pa Tần",
    code: 3493,
    division_type: "xã",
    codename: "xa_pa_tan",
    district_code: 108,
  },
  {
    name: "Xã Phìn Hồ",
    code: 3496,
    division_type: "xã",
    codename: "xa_phin_ho",
    district_code: 108,
  },
  {
    name: "Xã Hồng Thu",
    code: 3499,
    division_type: "xã",
    codename: "xa_hong_thu",
    district_code: 108,
  },
  {
    name: "Xã Phăng Sô Lin",
    code: 3505,
    division_type: "xã",
    codename: "xa_phang_so_lin",
    district_code: 108,
  },
  {
    name: "Xã Ma Quai",
    code: 3508,
    division_type: "xã",
    codename: "xa_ma_quai",
    district_code: 108,
  },
  {
    name: "Xã Lùng Thàng",
    code: 3509,
    division_type: "xã",
    codename: "xa_lung_thang",
    district_code: 108,
  },
  {
    name: "Xã Tả Phìn",
    code: 3511,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 108,
  },
  {
    name: "Xã Sà Dề Phìn",
    code: 3514,
    division_type: "xã",
    codename: "xa_sa_de_phin",
    district_code: 108,
  },
  {
    name: "Xã Nậm Tăm",
    code: 3517,
    division_type: "xã",
    codename: "xa_nam_tam",
    district_code: 108,
  },
  {
    name: "Xã Tả Ngảo",
    code: 3520,
    division_type: "xã",
    codename: "xa_ta_ngao",
    district_code: 108,
  },
  {
    name: "Xã Pu Sam Cáp",
    code: 3523,
    division_type: "xã",
    codename: "xa_pu_sam_cap",
    district_code: 108,
  },
  {
    name: "Xã Nậm Cha",
    code: 3526,
    division_type: "xã",
    codename: "xa_nam_cha",
    district_code: 108,
  },
  {
    name: "Xã Pa Khoá",
    code: 3527,
    division_type: "xã",
    codename: "xa_pa_khoa",
    district_code: 108,
  },
  {
    name: "Xã Làng Mô",
    code: 3529,
    division_type: "xã",
    codename: "xa_lang_mo",
    district_code: 108,
  },
  {
    name: "Xã Noong Hẻo",
    code: 3532,
    division_type: "xã",
    codename: "xa_noong_heo",
    district_code: 108,
  },
  {
    name: "Xã Nậm Mạ",
    code: 3535,
    division_type: "xã",
    codename: "xa_nam_ma",
    district_code: 108,
  },
  {
    name: "Xã Căn Co",
    code: 3538,
    division_type: "xã",
    codename: "xa_can_co",
    district_code: 108,
  },
  {
    name: "Xã Tủa Sín Chải",
    code: 3541,
    division_type: "xã",
    codename: "xa_tua_sin_chai",
    district_code: 108,
  },
  {
    name: "Xã Nậm Cuổi",
    code: 3544,
    division_type: "xã",
    codename: "xa_nam_cuoi",
    district_code: 108,
  },
  {
    name: "Xã Nậm Hăn",
    code: 3547,
    division_type: "xã",
    codename: "xa_nam_han",
    district_code: 108,
  },
  {
    name: "Xã Lả Nhì Thàng",
    code: 3391,
    division_type: "xã",
    codename: "xa_la_nhi_thang",
    district_code: 109,
  },
  {
    name: "Xã Huổi Luông",
    code: 3490,
    division_type: "xã",
    codename: "xa_huoi_luong",
    district_code: 109,
  },
  {
    name: "Thị trấn Phong Thổ",
    code: 3549,
    division_type: "thị trấn",
    codename: "thi_tran_phong_tho",
    district_code: 109,
  },
  {
    name: "Xã Sì Lở Lầu",
    code: 3550,
    division_type: "xã",
    codename: "xa_si_lo_lau",
    district_code: 109,
  },
  {
    name: "Xã Mồ Sì San",
    code: 3553,
    division_type: "xã",
    codename: "xa_mo_si_san",
    district_code: 109,
  },
  {
    name: "Xã Pa Vây Sử",
    code: 3559,
    division_type: "xã",
    codename: "xa_pa_vay_su",
    district_code: 109,
  },
  {
    name: "Xã Vàng Ma Chải",
    code: 3562,
    division_type: "xã",
    codename: "xa_vang_ma_chai",
    district_code: 109,
  },
  {
    name: "Xã Tông Qua Lìn",
    code: 3565,
    division_type: "xã",
    codename: "xa_tong_qua_lin",
    district_code: 109,
  },
  {
    name: "Xã Mù Sang",
    code: 3568,
    division_type: "xã",
    codename: "xa_mu_sang",
    district_code: 109,
  },
  {
    name: "Xã Dào San",
    code: 3571,
    division_type: "xã",
    codename: "xa_dao_san",
    district_code: 109,
  },
  {
    name: "Xã Ma Ly Pho",
    code: 3574,
    division_type: "xã",
    codename: "xa_ma_ly_pho",
    district_code: 109,
  },
  {
    name: "Xã Bản Lang",
    code: 3577,
    division_type: "xã",
    codename: "xa_ban_lang",
    district_code: 109,
  },
  {
    name: "Xã Hoang Thèn",
    code: 3580,
    division_type: "xã",
    codename: "xa_hoang_then",
    district_code: 109,
  },
  {
    name: "Xã Khổng Lào",
    code: 3583,
    division_type: "xã",
    codename: "xa_khong_lao",
    district_code: 109,
  },
  {
    name: "Xã Nậm Xe",
    code: 3586,
    division_type: "xã",
    codename: "xa_nam_xe",
    district_code: 109,
  },
  {
    name: "Xã Mường So",
    code: 3589,
    division_type: "xã",
    codename: "xa_muong_so",
    district_code: 109,
  },
  {
    name: "Xã Sin Suối Hồ",
    code: 3592,
    division_type: "xã",
    codename: "xa_sin_suoi_ho",
    district_code: 109,
  },
  {
    name: "Thị trấn Than Uyên",
    code: 3595,
    division_type: "thị trấn",
    codename: "thi_tran_than_uyen",
    district_code: 110,
  },
  {
    name: "Xã Phúc Than",
    code: 3618,
    division_type: "xã",
    codename: "xa_phuc_than",
    district_code: 110,
  },
  {
    name: "Xã Mường Than",
    code: 3619,
    division_type: "xã",
    codename: "xa_muong_than",
    district_code: 110,
  },
  {
    name: "Xã Mường Mít",
    code: 3625,
    division_type: "xã",
    codename: "xa_muong_mit",
    district_code: 110,
  },
  {
    name: "Xã Pha Mu",
    code: 3628,
    division_type: "xã",
    codename: "xa_pha_mu",
    district_code: 110,
  },
  {
    name: "Xã Mường Cang",
    code: 3631,
    division_type: "xã",
    codename: "xa_muong_cang",
    district_code: 110,
  },
  {
    name: "Xã Hua Nà",
    code: 3632,
    division_type: "xã",
    codename: "xa_hua_na",
    district_code: 110,
  },
  {
    name: "Xã Tà Hừa",
    code: 3634,
    division_type: "xã",
    codename: "xa_ta_hua",
    district_code: 110,
  },
  {
    name: "Xã Mường Kim",
    code: 3637,
    division_type: "xã",
    codename: "xa_muong_kim",
    district_code: 110,
  },
  {
    name: "Xã Tà Mung",
    code: 3638,
    division_type: "xã",
    codename: "xa_ta_mung",
    district_code: 110,
  },
  {
    name: "Xã Tà Gia",
    code: 3640,
    division_type: "xã",
    codename: "xa_ta_gia",
    district_code: 110,
  },
  {
    name: "Xã Khoen On",
    code: 3643,
    division_type: "xã",
    codename: "xa_khoen_on",
    district_code: 110,
  },
  {
    name: "Thị trấn Tân Uyên",
    code: 3598,
    division_type: "thị trấn",
    codename: "thi_tran_tan_uyen",
    district_code: 111,
  },
  {
    name: "Xã Mường Khoa",
    code: 3601,
    division_type: "xã",
    codename: "xa_muong_khoa",
    district_code: 111,
  },
  {
    name: "Xã Phúc Khoa",
    code: 3602,
    division_type: "xã",
    codename: "xa_phuc_khoa",
    district_code: 111,
  },
  {
    name: "Xã Thân Thuộc",
    code: 3604,
    division_type: "xã",
    codename: "xa_than_thuoc",
    district_code: 111,
  },
  {
    name: "Xã Trung Đồng",
    code: 3605,
    division_type: "xã",
    codename: "xa_trung_dong",
    district_code: 111,
  },
  {
    name: "Xã Hố Mít",
    code: 3607,
    division_type: "xã",
    codename: "xa_ho_mit",
    district_code: 111,
  },
  {
    name: "Xã Nậm Cần",
    code: 3610,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 111,
  },
  {
    name: "Xã Nậm Sỏ",
    code: 3613,
    division_type: "xã",
    codename: "xa_nam_so",
    district_code: 111,
  },
  {
    name: "Xã Pắc Ta",
    code: 3616,
    division_type: "xã",
    codename: "xa_pac_ta",
    district_code: 111,
  },
  {
    name: "Xã Tà Mít",
    code: 3622,
    division_type: "xã",
    codename: "xa_ta_mit",
    district_code: 111,
  },
  {
    name: "Thị trấn Nậm Nhùn",
    code: 3434,
    division_type: "thị trấn",
    codename: "thi_tran_nam_nhun",
    district_code: 112,
  },
  {
    name: "Xã Hua Bun",
    code: 3460,
    division_type: "xã",
    codename: "xa_hua_bun",
    district_code: 112,
  },
  {
    name: "Xã Mường Mô",
    code: 3472,
    division_type: "xã",
    codename: "xa_muong_mo",
    district_code: 112,
  },
  {
    name: "Xã Nậm Chà",
    code: 3473,
    division_type: "xã",
    codename: "xa_nam_cha",
    district_code: 112,
  },
  {
    name: "Xã Nậm Manh",
    code: 3474,
    division_type: "xã",
    codename: "xa_nam_manh",
    district_code: 112,
  },
  {
    name: "Xã Nậm Hàng",
    code: 3475,
    division_type: "xã",
    codename: "xa_nam_hang",
    district_code: 112,
  },
  {
    name: "Xã Lê Lợi",
    code: 3481,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 112,
  },
  {
    name: "Xã Pú Đao",
    code: 3484,
    division_type: "xã",
    codename: "xa_pu_dao",
    district_code: 112,
  },
  {
    name: "Xã Nậm Pì",
    code: 3488,
    division_type: "xã",
    codename: "xa_nam_pi",
    district_code: 112,
  },
  {
    name: "Xã Nậm Ban",
    code: 3502,
    division_type: "xã",
    codename: "xa_nam_ban",
    district_code: 112,
  },
  {
    name: "Xã Trung Chải",
    code: 3503,
    division_type: "xã",
    codename: "xa_trung_chai",
    district_code: 112,
  },
  {
    name: "Phường Chiềng Lề",
    code: 3646,
    division_type: "phường",
    codename: "phuong_chieng_le",
    district_code: 116,
  },
  {
    name: "Phường Tô Hiệu",
    code: 3649,
    division_type: "phường",
    codename: "phuong_to_hieu",
    district_code: 116,
  },
  {
    name: "Phường Quyết Thắng",
    code: 3652,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 116,
  },
  {
    name: "Phường Quyết Tâm",
    code: 3655,
    division_type: "phường",
    codename: "phuong_quyet_tam",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Cọ",
    code: 3658,
    division_type: "xã",
    codename: "xa_chieng_co",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Đen",
    code: 3661,
    division_type: "xã",
    codename: "xa_chieng_den",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Xôm",
    code: 3664,
    division_type: "xã",
    codename: "xa_chieng_xom",
    district_code: 116,
  },
  {
    name: "Phường Chiềng An",
    code: 3667,
    division_type: "phường",
    codename: "phuong_chieng_an",
    district_code: 116,
  },
  {
    name: "Phường Chiềng Cơi",
    code: 3670,
    division_type: "phường",
    codename: "phuong_chieng_coi",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Ngần",
    code: 3673,
    division_type: "xã",
    codename: "xa_chieng_ngan",
    district_code: 116,
  },
  {
    name: "Xã Hua La",
    code: 3676,
    division_type: "xã",
    codename: "xa_hua_la",
    district_code: 116,
  },
  {
    name: "Phường Chiềng Sinh",
    code: 3679,
    division_type: "phường",
    codename: "phuong_chieng_sinh",
    district_code: 116,
  },
  {
    name: "Xã Mường Chiên",
    code: 3682,
    division_type: "xã",
    codename: "xa_muong_chien",
    district_code: 118,
  },
  {
    name: "Xã Cà Nàng",
    code: 3685,
    division_type: "xã",
    codename: "xa_ca_nang",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Khay",
    code: 3688,
    division_type: "xã",
    codename: "xa_chieng_khay",
    district_code: 118,
  },
  {
    name: "Xã Mường Giôn",
    code: 3694,
    division_type: "xã",
    codename: "xa_muong_gion",
    district_code: 118,
  },
  {
    name: "Xã Pá Ma Pha Khinh",
    code: 3697,
    division_type: "xã",
    codename: "xa_pa_ma_pha_khinh",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Ơn",
    code: 3700,
    division_type: "xã",
    codename: "xa_chieng_on",
    district_code: 118,
  },
  {
    name: "Xã Mường Giàng",
    code: 3703,
    division_type: "xã",
    codename: "xa_muong_giang",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Bằng",
    code: 3706,
    division_type: "xã",
    codename: "xa_chieng_bang",
    district_code: 118,
  },
  {
    name: "Xã Mường Sại",
    code: 3709,
    division_type: "xã",
    codename: "xa_muong_sai",
    district_code: 118,
  },
  {
    name: "Xã Nậm ét",
    code: 3712,
    division_type: "xã",
    codename: "xa_nam_et",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Khoang",
    code: 3718,
    division_type: "xã",
    codename: "xa_chieng_khoang",
    district_code: 118,
  },
  {
    name: "Thị trấn Thuận Châu",
    code: 3721,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_chau",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lái",
    code: 3724,
    division_type: "xã",
    codename: "xa_phong_lai",
    district_code: 119,
  },
  {
    name: "Xã Mường é",
    code: 3727,
    division_type: "xã",
    codename: "xa_muong_e",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Pha",
    code: 3730,
    division_type: "xã",
    codename: "xa_chieng_pha",
    district_code: 119,
  },
  {
    name: "Xã Chiềng La",
    code: 3733,
    division_type: "xã",
    codename: "xa_chieng_la",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Ngàm",
    code: 3736,
    division_type: "xã",
    codename: "xa_chieng_ngam",
    district_code: 119,
  },
  {
    name: "Xã Liệp Tè",
    code: 3739,
    division_type: "xã",
    codename: "xa_liep_te",
    district_code: 119,
  },
  {
    name: "Xã é Tòng",
    code: 3742,
    division_type: "xã",
    codename: "xa_e_tong",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lập",
    code: 3745,
    division_type: "xã",
    codename: "xa_phong_lap",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lăng",
    code: 3748,
    division_type: "xã",
    codename: "xa_phong_lang",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Ly",
    code: 3751,
    division_type: "xã",
    codename: "xa_chieng_ly",
    district_code: 119,
  },
  {
    name: "Xã Noong Lay",
    code: 3754,
    division_type: "xã",
    codename: "xa_noong_lay",
    district_code: 119,
  },
  {
    name: "Xã Mường Khiêng",
    code: 3757,
    division_type: "xã",
    codename: "xa_muong_khieng",
    district_code: 119,
  },
  {
    name: "Xã Mường Bám",
    code: 3760,
    division_type: "xã",
    codename: "xa_muong_bam",
    district_code: 119,
  },
  {
    name: "Xã Long Hẹ",
    code: 3763,
    division_type: "xã",
    codename: "xa_long_he",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Bôm",
    code: 3766,
    division_type: "xã",
    codename: "xa_chieng_bom",
    district_code: 119,
  },
  {
    name: "Xã Thôm Mòn",
    code: 3769,
    division_type: "xã",
    codename: "xa_thom_mon",
    district_code: 119,
  },
  {
    name: "Xã Tông Lạnh",
    code: 3772,
    division_type: "xã",
    codename: "xa_tong_lanh",
    district_code: 119,
  },
  {
    name: "Xã Tông Cọ",
    code: 3775,
    division_type: "xã",
    codename: "xa_tong_co",
    district_code: 119,
  },
  {
    name: "Xã Bó Mười",
    code: 3778,
    division_type: "xã",
    codename: "xa_bo_muoi",
    district_code: 119,
  },
  {
    name: "Xã Co Mạ",
    code: 3781,
    division_type: "xã",
    codename: "xa_co_ma",
    district_code: 119,
  },
  {
    name: "Xã Púng Tra",
    code: 3784,
    division_type: "xã",
    codename: "xa_pung_tra",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Pấc",
    code: 3787,
    division_type: "xã",
    codename: "xa_chieng_pac",
    district_code: 119,
  },
  {
    name: "Xã Nậm Lầu",
    code: 3790,
    division_type: "xã",
    codename: "xa_nam_lau",
    district_code: 119,
  },
  {
    name: "Xã Bon Phặng",
    code: 3793,
    division_type: "xã",
    codename: "xa_bon_phang",
    district_code: 119,
  },
  {
    name: "Xã Co Tòng",
    code: 3796,
    division_type: "xã",
    codename: "xa_co_tong",
    district_code: 119,
  },
  {
    name: "Xã Muổi Nọi",
    code: 3799,
    division_type: "xã",
    codename: "xa_muoi_noi",
    district_code: 119,
  },
  {
    name: "Xã Pá Lông",
    code: 3802,
    division_type: "xã",
    codename: "xa_pa_long",
    district_code: 119,
  },
  {
    name: "Xã Bản Lầm",
    code: 3805,
    division_type: "xã",
    codename: "xa_ban_lam",
    district_code: 119,
  },
  {
    name: "Thị trấn Ít Ong",
    code: 3808,
    division_type: "thị trấn",
    codename: "thi_tran_it_ong",
    district_code: 120,
  },
  {
    name: "Xã Nậm Giôn",
    code: 3811,
    division_type: "xã",
    codename: "xa_nam_gion",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Lao",
    code: 3814,
    division_type: "xã",
    codename: "xa_chieng_lao",
    district_code: 120,
  },
  {
    name: "Xã Hua Trai",
    code: 3817,
    division_type: "xã",
    codename: "xa_hua_trai",
    district_code: 120,
  },
  {
    name: "Xã Ngọc Chiến",
    code: 3820,
    division_type: "xã",
    codename: "xa_ngoc_chien",
    district_code: 120,
  },
  {
    name: "Xã Mường Trai",
    code: 3823,
    division_type: "xã",
    codename: "xa_muong_trai",
    district_code: 120,
  },
  {
    name: "Xã Nậm Păm",
    code: 3826,
    division_type: "xã",
    codename: "xa_nam_pam",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Muôn",
    code: 3829,
    division_type: "xã",
    codename: "xa_chieng_muon",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Ân",
    code: 3832,
    division_type: "xã",
    codename: "xa_chieng_an",
    district_code: 120,
  },
  {
    name: "Xã Pi Toong",
    code: 3835,
    division_type: "xã",
    codename: "xa_pi_toong",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Công",
    code: 3838,
    division_type: "xã",
    codename: "xa_chieng_cong",
    district_code: 120,
  },
  {
    name: "Xã Tạ Bú",
    code: 3841,
    division_type: "xã",
    codename: "xa_ta_bu",
    district_code: 120,
  },
  {
    name: "Xã Chiềng San",
    code: 3844,
    division_type: "xã",
    codename: "xa_chieng_san",
    district_code: 120,
  },
  {
    name: "Xã Mường Bú",
    code: 3847,
    division_type: "xã",
    codename: "xa_muong_bu",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Hoa",
    code: 3850,
    division_type: "xã",
    codename: "xa_chieng_hoa",
    district_code: 120,
  },
  {
    name: "Xã Mường Chùm",
    code: 3853,
    division_type: "xã",
    codename: "xa_muong_chum",
    district_code: 120,
  },
  {
    name: "Thị trấn Bắc Yên",
    code: 3856,
    division_type: "thị trấn",
    codename: "thi_tran_bac_yen",
    district_code: 121,
  },
  {
    name: "Xã Phiêng Ban",
    code: 3859,
    division_type: "xã",
    codename: "xa_phieng_ban",
    district_code: 121,
  },
  {
    name: "Xã Hang Chú",
    code: 3862,
    division_type: "xã",
    codename: "xa_hang_chu",
    district_code: 121,
  },
  {
    name: "Xã Xím Vàng",
    code: 3865,
    division_type: "xã",
    codename: "xa_xim_vang",
    district_code: 121,
  },
  {
    name: "Xã Tà Xùa",
    code: 3868,
    division_type: "xã",
    codename: "xa_ta_xua",
    district_code: 121,
  },
  {
    name: "Xã Háng Đồng",
    code: 3869,
    division_type: "xã",
    codename: "xa_hang_dong",
    district_code: 121,
  },
  {
    name: "Xã Pắc Ngà",
    code: 3871,
    division_type: "xã",
    codename: "xa_pac_nga",
    district_code: 121,
  },
  {
    name: "Xã Làng Chếu",
    code: 3874,
    division_type: "xã",
    codename: "xa_lang_cheu",
    district_code: 121,
  },
  {
    name: "Xã Chim Vàn",
    code: 3877,
    division_type: "xã",
    codename: "xa_chim_van",
    district_code: 121,
  },
  {
    name: "Xã Mường Khoa",
    code: 3880,
    division_type: "xã",
    codename: "xa_muong_khoa",
    district_code: 121,
  },
  {
    name: "Xã Song Pe",
    code: 3883,
    division_type: "xã",
    codename: "xa_song_pe",
    district_code: 121,
  },
  {
    name: "Xã Hồng Ngài",
    code: 3886,
    division_type: "xã",
    codename: "xa_hong_ngai",
    district_code: 121,
  },
  {
    name: "Xã Tạ Khoa",
    code: 3889,
    division_type: "xã",
    codename: "xa_ta_khoa",
    district_code: 121,
  },
  {
    name: "Xã Hua Nhàn",
    code: 3890,
    division_type: "xã",
    codename: "xa_hua_nhan",
    district_code: 121,
  },
  {
    name: "Xã Phiêng Côn",
    code: 3892,
    division_type: "xã",
    codename: "xa_phieng_con",
    district_code: 121,
  },
  {
    name: "Xã Chiềng Sại",
    code: 3895,
    division_type: "xã",
    codename: "xa_chieng_sai",
    district_code: 121,
  },
  {
    name: "Thị trấn Phù Yên",
    code: 3898,
    division_type: "thị trấn",
    codename: "thi_tran_phu_yen",
    district_code: 122,
  },
  {
    name: "Xã Suối Tọ",
    code: 3901,
    division_type: "xã",
    codename: "xa_suoi_to",
    district_code: 122,
  },
  {
    name: "Xã Mường Thải",
    code: 3904,
    division_type: "xã",
    codename: "xa_muong_thai",
    district_code: 122,
  },
  {
    name: "Xã Mường Cơi",
    code: 3907,
    division_type: "xã",
    codename: "xa_muong_coi",
    district_code: 122,
  },
  {
    name: "Xã Quang Huy",
    code: 3910,
    division_type: "xã",
    codename: "xa_quang_huy",
    district_code: 122,
  },
  {
    name: "Xã Huy Bắc",
    code: 3913,
    division_type: "xã",
    codename: "xa_huy_bac",
    district_code: 122,
  },
  {
    name: "Xã Huy Thượng",
    code: 3916,
    division_type: "xã",
    codename: "xa_huy_thuong",
    district_code: 122,
  },
  {
    name: "Xã Tân Lang",
    code: 3919,
    division_type: "xã",
    codename: "xa_tan_lang",
    district_code: 122,
  },
  {
    name: "Xã Gia Phù",
    code: 3922,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 122,
  },
  {
    name: "Xã Tường Phù",
    code: 3925,
    division_type: "xã",
    codename: "xa_tuong_phu",
    district_code: 122,
  },
  {
    name: "Xã Huy Hạ",
    code: 3928,
    division_type: "xã",
    codename: "xa_huy_ha",
    district_code: 122,
  },
  {
    name: "Xã Huy Tân",
    code: 3931,
    division_type: "xã",
    codename: "xa_huy_tan",
    district_code: 122,
  },
  {
    name: "Xã Mường Lang",
    code: 3934,
    division_type: "xã",
    codename: "xa_muong_lang",
    district_code: 122,
  },
  {
    name: "Xã Suối Bau",
    code: 3937,
    division_type: "xã",
    codename: "xa_suoi_bau",
    district_code: 122,
  },
  {
    name: "Xã Huy Tường",
    code: 3940,
    division_type: "xã",
    codename: "xa_huy_tuong",
    district_code: 122,
  },
  {
    name: "Xã Mường Do",
    code: 3943,
    division_type: "xã",
    codename: "xa_muong_do",
    district_code: 122,
  },
  {
    name: "Xã Sập Xa",
    code: 3946,
    division_type: "xã",
    codename: "xa_sap_xa",
    district_code: 122,
  },
  {
    name: "Xã Tường Thượng",
    code: 3949,
    division_type: "xã",
    codename: "xa_tuong_thuong",
    district_code: 122,
  },
  {
    name: "Xã Tường Tiến",
    code: 3952,
    division_type: "xã",
    codename: "xa_tuong_tien",
    district_code: 122,
  },
  {
    name: "Xã Tường Phong",
    code: 3955,
    division_type: "xã",
    codename: "xa_tuong_phong",
    district_code: 122,
  },
  {
    name: "Xã Tường Hạ",
    code: 3958,
    division_type: "xã",
    codename: "xa_tuong_ha",
    district_code: 122,
  },
  {
    name: "Xã Kim Bon",
    code: 3961,
    division_type: "xã",
    codename: "xa_kim_bon",
    district_code: 122,
  },
  {
    name: "Xã Mường Bang",
    code: 3964,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 122,
  },
  {
    name: "Xã Đá Đỏ",
    code: 3967,
    division_type: "xã",
    codename: "xa_da_do",
    district_code: 122,
  },
  {
    name: "Xã Tân Phong",
    code: 3970,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 122,
  },
  {
    name: "Xã Nam Phong",
    code: 3973,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 122,
  },
  {
    name: "Xã Bắc Phong",
    code: 3976,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 122,
  },
  {
    name: "Thị trấn Mộc Châu",
    code: 3979,
    division_type: "thị trấn",
    codename: "thi_tran_moc_chau",
    district_code: 123,
  },
  {
    name: "Thị trấn NT Mộc Châu",
    code: 3982,
    division_type: "thị trấn",
    codename: "thi_tran_nt_moc_chau",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Sơn",
    code: 3985,
    division_type: "xã",
    codename: "xa_chieng_son",
    district_code: 123,
  },
  {
    name: "Xã Tân Hợp",
    code: 3988,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 123,
  },
  {
    name: "Xã Qui Hướng",
    code: 3991,
    division_type: "xã",
    codename: "xa_qui_huong",
    district_code: 123,
  },
  {
    name: "Xã Tân Lập",
    code: 3997,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 123,
  },
  {
    name: "Xã Nà Mường",
    code: 4000,
    division_type: "xã",
    codename: "xa_na_muong",
    district_code: 123,
  },
  {
    name: "Xã Tà Lai",
    code: 4003,
    division_type: "xã",
    codename: "xa_ta_lai",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Hắc",
    code: 4012,
    division_type: "xã",
    codename: "xa_chieng_hac",
    district_code: 123,
  },
  {
    name: "Xã Hua Păng",
    code: 4015,
    division_type: "xã",
    codename: "xa_hua_pang",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Khừa",
    code: 4024,
    division_type: "xã",
    codename: "xa_chieng_khua",
    district_code: 123,
  },
  {
    name: "Xã Mường Sang",
    code: 4027,
    division_type: "xã",
    codename: "xa_muong_sang",
    district_code: 123,
  },
  {
    name: "Xã Đông Sang",
    code: 4030,
    division_type: "xã",
    codename: "xa_dong_sang",
    district_code: 123,
  },
  {
    name: "Xã Phiêng Luông",
    code: 4033,
    division_type: "xã",
    codename: "xa_phieng_luong",
    district_code: 123,
  },
  {
    name: "Xã Lóng Sập",
    code: 4045,
    division_type: "xã",
    codename: "xa_long_sap",
    district_code: 123,
  },
  {
    name: "Thị trấn Yên Châu",
    code: 4060,
    division_type: "thị trấn",
    codename: "thi_tran_yen_chau",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Đông",
    code: 4063,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 124,
  },
  {
    name: "Xã Sập Vạt",
    code: 4066,
    division_type: "xã",
    codename: "xa_sap_vat",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Sàng",
    code: 4069,
    division_type: "xã",
    codename: "xa_chieng_sang",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Pằn",
    code: 4072,
    division_type: "xã",
    codename: "xa_chieng_pan",
    district_code: 124,
  },
  {
    name: "Xã Viêng Lán",
    code: 4075,
    division_type: "xã",
    codename: "xa_vieng_lan",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Hặc",
    code: 4078,
    division_type: "xã",
    codename: "xa_chieng_hac",
    district_code: 124,
  },
  {
    name: "Xã Mường Lựm",
    code: 4081,
    division_type: "xã",
    codename: "xa_muong_lum",
    district_code: 124,
  },
  {
    name: "Xã Chiềng On",
    code: 4084,
    division_type: "xã",
    codename: "xa_chieng_on",
    district_code: 124,
  },
  {
    name: "Xã Yên Sơn",
    code: 4087,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Khoi",
    code: 4090,
    division_type: "xã",
    codename: "xa_chieng_khoi",
    district_code: 124,
  },
  {
    name: "Xã Tú Nang",
    code: 4093,
    division_type: "xã",
    codename: "xa_tu_nang",
    district_code: 124,
  },
  {
    name: "Xã Lóng Phiêng",
    code: 4096,
    division_type: "xã",
    codename: "xa_long_phieng",
    district_code: 124,
  },
  {
    name: "Xã Phiêng Khoài",
    code: 4099,
    division_type: "xã",
    codename: "xa_phieng_khoai",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Tương",
    code: 4102,
    division_type: "xã",
    codename: "xa_chieng_tuong",
    district_code: 124,
  },
  {
    name: "Thị trấn Hát Lót",
    code: 4105,
    division_type: "thị trấn",
    codename: "thi_tran_hat_lot",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Sung",
    code: 4108,
    division_type: "xã",
    codename: "xa_chieng_sung",
    district_code: 125,
  },
  {
    name: "Xã Mường Bằng",
    code: 4111,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Chăn",
    code: 4114,
    division_type: "xã",
    codename: "xa_chieng_chan",
    district_code: 125,
  },
  {
    name: "Xã Mương Chanh",
    code: 4117,
    division_type: "xã",
    codename: "xa_muong_chanh",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Ban",
    code: 4120,
    division_type: "xã",
    codename: "xa_chieng_ban",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Mung",
    code: 4123,
    division_type: "xã",
    codename: "xa_chieng_mung",
    district_code: 125,
  },
  {
    name: "Xã Mường Bon",
    code: 4126,
    division_type: "xã",
    codename: "xa_muong_bon",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Chung",
    code: 4129,
    division_type: "xã",
    codename: "xa_chieng_chung",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Mai",
    code: 4132,
    division_type: "xã",
    codename: "xa_chieng_mai",
    district_code: 125,
  },
  {
    name: "Xã Hát Lót",
    code: 4135,
    division_type: "xã",
    codename: "xa_hat_lot",
    district_code: 125,
  },
  {
    name: "Xã Nà Pó",
    code: 4136,
    division_type: "xã",
    codename: "xa_na_po",
    district_code: 125,
  },
  {
    name: "Xã Cò Nòi",
    code: 4138,
    division_type: "xã",
    codename: "xa_co_noi",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Nơi",
    code: 4141,
    division_type: "xã",
    codename: "xa_chieng_noi",
    district_code: 125,
  },
  {
    name: "Xã Phiêng Cằm",
    code: 4144,
    division_type: "xã",
    codename: "xa_phieng_cam",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Dong",
    code: 4147,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Kheo",
    code: 4150,
    division_type: "xã",
    codename: "xa_chieng_kheo",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Ve",
    code: 4153,
    division_type: "xã",
    codename: "xa_chieng_ve",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Lương",
    code: 4156,
    division_type: "xã",
    codename: "xa_chieng_luong",
    district_code: 125,
  },
  {
    name: "Xã Phiêng Pằn",
    code: 4159,
    division_type: "xã",
    codename: "xa_phieng_pan",
    district_code: 125,
  },
  {
    name: "Xã Nà Ơt",
    code: 4162,
    division_type: "xã",
    codename: "xa_na_ot",
    district_code: 125,
  },
  {
    name: "Xã Tà Hộc",
    code: 4165,
    division_type: "xã",
    codename: "xa_ta_hoc",
    district_code: 125,
  },
  {
    name: "Thị trấn Sông Mã",
    code: 4168,
    division_type: "thị trấn",
    codename: "thi_tran_song_ma",
    district_code: 126,
  },
  {
    name: "Xã Bó Sinh",
    code: 4171,
    division_type: "xã",
    codename: "xa_bo_sinh",
    district_code: 126,
  },
  {
    name: "Xã Pú Pẩu",
    code: 4174,
    division_type: "xã",
    codename: "xa_pu_pau",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Phung",
    code: 4177,
    division_type: "xã",
    codename: "xa_chieng_phung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng En",
    code: 4180,
    division_type: "xã",
    codename: "xa_chieng_en",
    district_code: 126,
  },
  {
    name: "Xã Mường Lầm",
    code: 4183,
    division_type: "xã",
    codename: "xa_muong_lam",
    district_code: 126,
  },
  {
    name: "Xã Nậm Ty",
    code: 4186,
    division_type: "xã",
    codename: "xa_nam_ty",
    district_code: 126,
  },
  {
    name: "Xã Đứa Mòn",
    code: 4189,
    division_type: "xã",
    codename: "xa_dua_mon",
    district_code: 126,
  },
  {
    name: "Xã Yên Hưng",
    code: 4192,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Sơ",
    code: 4195,
    division_type: "xã",
    codename: "xa_chieng_so",
    district_code: 126,
  },
  {
    name: "Xã Nà Nghịu",
    code: 4198,
    division_type: "xã",
    codename: "xa_na_nghiu",
    district_code: 126,
  },
  {
    name: "Xã Nậm Mằn",
    code: 4201,
    division_type: "xã",
    codename: "xa_nam_man",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Khoong",
    code: 4204,
    division_type: "xã",
    codename: "xa_chieng_khoong",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Cang",
    code: 4207,
    division_type: "xã",
    codename: "xa_chieng_cang",
    district_code: 126,
  },
  {
    name: "Xã Huổi Một",
    code: 4210,
    division_type: "xã",
    codename: "xa_huoi_mot",
    district_code: 126,
  },
  {
    name: "Xã Mường Sai",
    code: 4213,
    division_type: "xã",
    codename: "xa_muong_sai",
    district_code: 126,
  },
  {
    name: "Xã Mường Cai",
    code: 4216,
    division_type: "xã",
    codename: "xa_muong_cai",
    district_code: 126,
  },
  {
    name: "Xã Mường Hung",
    code: 4219,
    division_type: "xã",
    codename: "xa_muong_hung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Khương",
    code: 4222,
    division_type: "xã",
    codename: "xa_chieng_khuong",
    district_code: 126,
  },
  {
    name: "Xã Sam Kha",
    code: 4225,
    division_type: "xã",
    codename: "xa_sam_kha",
    district_code: 127,
  },
  {
    name: "Xã Púng Bánh",
    code: 4228,
    division_type: "xã",
    codename: "xa_pung_banh",
    district_code: 127,
  },
  {
    name: "Xã Sốp Cộp",
    code: 4231,
    division_type: "xã",
    codename: "xa_sop_cop",
    district_code: 127,
  },
  {
    name: "Xã Dồm Cang",
    code: 4234,
    division_type: "xã",
    codename: "xa_dom_cang",
    district_code: 127,
  },
  {
    name: "Xã Nậm Lạnh",
    code: 4237,
    division_type: "xã",
    codename: "xa_nam_lanh",
    district_code: 127,
  },
  {
    name: "Xã Mường Lèo",
    code: 4240,
    division_type: "xã",
    codename: "xa_muong_leo",
    district_code: 127,
  },
  {
    name: "Xã Mường Và",
    code: 4243,
    division_type: "xã",
    codename: "xa_muong_va",
    district_code: 127,
  },
  {
    name: "Xã Mường Lạn",
    code: 4246,
    division_type: "xã",
    codename: "xa_muong_lan",
    district_code: 127,
  },
  {
    name: "Xã Suối Bàng",
    code: 3994,
    division_type: "xã",
    codename: "xa_suoi_bang",
    district_code: 128,
  },
  {
    name: "Xã Song Khủa",
    code: 4006,
    division_type: "xã",
    codename: "xa_song_khua",
    district_code: 128,
  },
  {
    name: "Xã Liên Hoà",
    code: 4009,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 128,
  },
  {
    name: "Xã Tô Múa",
    code: 4018,
    division_type: "xã",
    codename: "xa_to_mua",
    district_code: 128,
  },
  {
    name: "Xã Mường Tè",
    code: 4021,
    division_type: "xã",
    codename: "xa_muong_te",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Khoa",
    code: 4036,
    division_type: "xã",
    codename: "xa_chieng_khoa",
    district_code: 128,
  },
  {
    name: "Xã Mường Men",
    code: 4039,
    division_type: "xã",
    codename: "xa_muong_men",
    district_code: 128,
  },
  {
    name: "Xã Quang Minh",
    code: 4042,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 128,
  },
  {
    name: "Xã Vân Hồ",
    code: 4048,
    division_type: "xã",
    codename: "xa_van_ho",
    district_code: 128,
  },
  {
    name: "Xã Lóng Luông",
    code: 4051,
    division_type: "xã",
    codename: "xa_long_luong",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Yên",
    code: 4054,
    division_type: "xã",
    codename: "xa_chieng_yen",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Xuân",
    code: 4056,
    division_type: "xã",
    codename: "xa_chieng_xuan",
    district_code: 128,
  },
  {
    name: "Xã Xuân Nha",
    code: 4057,
    division_type: "xã",
    codename: "xa_xuan_nha",
    district_code: 128,
  },
  {
    name: "Xã Tân Xuân",
    code: 4058,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 128,
  },
  {
    name: "Phường Yên Thịnh",
    code: 4249,
    division_type: "phường",
    codename: "phuong_yen_thinh",
    district_code: 132,
  },
  {
    name: "Phường Yên Ninh",
    code: 4252,
    division_type: "phường",
    codename: "phuong_yen_ninh",
    district_code: 132,
  },
  {
    name: "Phường Minh Tân",
    code: 4255,
    division_type: "phường",
    codename: "phuong_minh_tan",
    district_code: 132,
  },
  {
    name: "Phường Nguyễn Thái Học",
    code: 4258,
    division_type: "phường",
    codename: "phuong_nguyen_thai_hoc",
    district_code: 132,
  },
  {
    name: "Phường Đồng Tâm",
    code: 4261,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 132,
  },
  {
    name: "Phường Nguyễn Phúc",
    code: 4264,
    division_type: "phường",
    codename: "phuong_nguyen_phuc",
    district_code: 132,
  },
  {
    name: "Phường Hồng Hà",
    code: 4267,
    division_type: "phường",
    codename: "phuong_hong_ha",
    district_code: 132,
  },
  {
    name: "Xã Minh Bảo",
    code: 4270,
    division_type: "xã",
    codename: "xa_minh_bao",
    district_code: 132,
  },
  {
    name: "Phường Nam Cường",
    code: 4273,
    division_type: "phường",
    codename: "phuong_nam_cuong",
    district_code: 132,
  },
  {
    name: "Xã Tuy Lộc",
    code: 4276,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 132,
  },
  {
    name: "Xã Tân Thịnh",
    code: 4279,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 132,
  },
  {
    name: "Xã Âu Lâu",
    code: 4540,
    division_type: "xã",
    codename: "xa_au_lau",
    district_code: 132,
  },
  {
    name: "Xã Giới Phiên",
    code: 4543,
    division_type: "xã",
    codename: "xa_gioi_phien",
    district_code: 132,
  },
  {
    name: "Phường Hợp Minh",
    code: 4546,
    division_type: "phường",
    codename: "phuong_hop_minh",
    district_code: 132,
  },
  {
    name: "Xã Văn Phú",
    code: 4558,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 132,
  },
  {
    name: "Phường Pú Trạng",
    code: 4282,
    division_type: "phường",
    codename: "phuong_pu_trang",
    district_code: 133,
  },
  {
    name: "Phường Trung Tâm",
    code: 4285,
    division_type: "phường",
    codename: "phuong_trung_tam",
    district_code: 133,
  },
  {
    name: "Phường Tân An",
    code: 4288,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 133,
  },
  {
    name: "Phường Cầu Thia",
    code: 4291,
    division_type: "phường",
    codename: "phuong_cau_thia",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 4294,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Phúc",
    code: 4297,
    division_type: "xã",
    codename: "xa_nghia_phuc",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa An",
    code: 4300,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Lộ",
    code: 4624,
    division_type: "xã",
    codename: "xa_nghia_lo",
    district_code: 133,
  },
  {
    name: "Xã Sơn A",
    code: 4660,
    division_type: "xã",
    codename: "xa_son_a",
    district_code: 133,
  },
  {
    name: "Xã Phù Nham",
    code: 4663,
    division_type: "xã",
    codename: "xa_phu_nham",
    district_code: 133,
  },
  {
    name: "Xã Thanh Lương",
    code: 4675,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 133,
  },
  {
    name: "Xã Hạnh Sơn",
    code: 4678,
    division_type: "xã",
    codename: "xa_hanh_son",
    district_code: 133,
  },
  {
    name: "Xã Phúc Sơn",
    code: 4681,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 133,
  },
  {
    name: "Xã Thạch Lương",
    code: 4684,
    division_type: "xã",
    codename: "xa_thach_luong",
    district_code: 133,
  },
  {
    name: "Thị trấn Yên Thế",
    code: 4303,
    division_type: "thị trấn",
    codename: "thi_tran_yen_the",
    district_code: 135,
  },
  {
    name: "Xã Tân Phượng",
    code: 4306,
    division_type: "xã",
    codename: "xa_tan_phuong",
    district_code: 135,
  },
  {
    name: "Xã Lâm Thượng",
    code: 4309,
    division_type: "xã",
    codename: "xa_lam_thuong",
    district_code: 135,
  },
  {
    name: "Xã Khánh Thiện",
    code: 4312,
    division_type: "xã",
    codename: "xa_khanh_thien",
    district_code: 135,
  },
  {
    name: "Xã Minh Chuẩn",
    code: 4315,
    division_type: "xã",
    codename: "xa_minh_chuan",
    district_code: 135,
  },
  {
    name: "Xã Mai Sơn",
    code: 4318,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 135,
  },
  {
    name: "Xã Khai Trung",
    code: 4321,
    division_type: "xã",
    codename: "xa_khai_trung",
    district_code: 135,
  },
  {
    name: "Xã Mường Lai",
    code: 4324,
    division_type: "xã",
    codename: "xa_muong_lai",
    district_code: 135,
  },
  {
    name: "Xã An Lạc",
    code: 4327,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 135,
  },
  {
    name: "Xã Minh Xuân",
    code: 4330,
    division_type: "xã",
    codename: "xa_minh_xuan",
    district_code: 135,
  },
  {
    name: "Xã Tô Mậu",
    code: 4333,
    division_type: "xã",
    codename: "xa_to_mau",
    district_code: 135,
  },
  {
    name: "Xã Tân Lĩnh",
    code: 4336,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 135,
  },
  {
    name: "Xã Yên Thắng",
    code: 4339,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 135,
  },
  {
    name: "Xã Khánh Hoà",
    code: 4342,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 135,
  },
  {
    name: "Xã Vĩnh Lạc",
    code: 4345,
    division_type: "xã",
    codename: "xa_vinh_lac",
    district_code: 135,
  },
  {
    name: "Xã Liễu Đô",
    code: 4348,
    division_type: "xã",
    codename: "xa_lieu_do",
    district_code: 135,
  },
  {
    name: "Xã Động Quan",
    code: 4351,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 135,
  },
  {
    name: "Xã Tân Lập",
    code: 4354,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 135,
  },
  {
    name: "Xã Minh Tiến",
    code: 4357,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 135,
  },
  {
    name: "Xã Trúc Lâu",
    code: 4360,
    division_type: "xã",
    codename: "xa_truc_lau",
    district_code: 135,
  },
  {
    name: "Xã Phúc Lợi",
    code: 4363,
    division_type: "xã",
    codename: "xa_phuc_loi",
    district_code: 135,
  },
  {
    name: "Xã Phan Thanh",
    code: 4366,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 135,
  },
  {
    name: "Xã An Phú",
    code: 4369,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 135,
  },
  {
    name: "Xã Trung Tâm",
    code: 4372,
    division_type: "xã",
    codename: "xa_trung_tam",
    district_code: 135,
  },
  {
    name: "Thị trấn Mậu A",
    code: 4375,
    division_type: "thị trấn",
    codename: "thi_tran_mau_a",
    district_code: 136,
  },
  {
    name: "Xã Lang Thíp",
    code: 4378,
    division_type: "xã",
    codename: "xa_lang_thip",
    district_code: 136,
  },
  {
    name: "Xã Lâm Giang",
    code: 4381,
    division_type: "xã",
    codename: "xa_lam_giang",
    district_code: 136,
  },
  {
    name: "Xã Châu Quế Thượng",
    code: 4384,
    division_type: "xã",
    codename: "xa_chau_que_thuong",
    district_code: 136,
  },
  {
    name: "Xã Châu Quế Hạ",
    code: 4387,
    division_type: "xã",
    codename: "xa_chau_que_ha",
    district_code: 136,
  },
  {
    name: "Xã An Bình",
    code: 4390,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 136,
  },
  {
    name: "Xã Quang Minh",
    code: 4393,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 136,
  },
  {
    name: "Xã Đông An",
    code: 4396,
    division_type: "xã",
    codename: "xa_dong_an",
    district_code: 136,
  },
  {
    name: "Xã Đông Cuông",
    code: 4399,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 136,
  },
  {
    name: "Xã Phong Dụ Hạ",
    code: 4402,
    division_type: "xã",
    codename: "xa_phong_du_ha",
    district_code: 136,
  },
  {
    name: "Xã Mậu Đông",
    code: 4405,
    division_type: "xã",
    codename: "xa_mau_dong",
    district_code: 136,
  },
  {
    name: "Xã Ngòi A",
    code: 4408,
    division_type: "xã",
    codename: "xa_ngoi_a",
    district_code: 136,
  },
  {
    name: "Xã Xuân Tầm",
    code: 4411,
    division_type: "xã",
    codename: "xa_xuan_tam",
    district_code: 136,
  },
  {
    name: "Xã Tân Hợp",
    code: 4414,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 136,
  },
  {
    name: "Xã An Thịnh",
    code: 4417,
    division_type: "xã",
    codename: "xa_an_thinh",
    district_code: 136,
  },
  {
    name: "Xã Yên Thái",
    code: 4420,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 136,
  },
  {
    name: "Xã Phong Dụ Thượng",
    code: 4423,
    division_type: "xã",
    codename: "xa_phong_du_thuong",
    district_code: 136,
  },
  {
    name: "Xã Yên Hợp",
    code: 4426,
    division_type: "xã",
    codename: "xa_yen_hop",
    district_code: 136,
  },
  {
    name: "Xã Đại Sơn",
    code: 4429,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 136,
  },
  {
    name: "Xã Đại Phác",
    code: 4435,
    division_type: "xã",
    codename: "xa_dai_phac",
    district_code: 136,
  },
  {
    name: "Xã Yên Phú",
    code: 4438,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 136,
  },
  {
    name: "Xã Xuân Ái",
    code: 4441,
    division_type: "xã",
    codename: "xa_xuan_ai",
    district_code: 136,
  },
  {
    name: "Xã Viễn Sơn",
    code: 4447,
    division_type: "xã",
    codename: "xa_vien_son",
    district_code: 136,
  },
  {
    name: "Xã Mỏ Vàng",
    code: 4450,
    division_type: "xã",
    codename: "xa_mo_vang",
    district_code: 136,
  },
  {
    name: "Xã Nà Hẩu",
    code: 4453,
    division_type: "xã",
    codename: "xa_na_hau",
    district_code: 136,
  },
  {
    name: "Thị trấn Mù Căng Chải",
    code: 4456,
    division_type: "thị trấn",
    codename: "thi_tran_mu_cang_chai",
    district_code: 137,
  },
  {
    name: "Xã Hồ Bốn",
    code: 4459,
    division_type: "xã",
    codename: "xa_ho_bon",
    district_code: 137,
  },
  {
    name: "Xã Nậm Có",
    code: 4462,
    division_type: "xã",
    codename: "xa_nam_co",
    district_code: 137,
  },
  {
    name: "Xã Khao Mang",
    code: 4465,
    division_type: "xã",
    codename: "xa_khao_mang",
    district_code: 137,
  },
  {
    name: "Xã Mồ Dề",
    code: 4468,
    division_type: "xã",
    codename: "xa_mo_de",
    district_code: 137,
  },
  {
    name: "Xã Chế Cu Nha",
    code: 4471,
    division_type: "xã",
    codename: "xa_che_cu_nha",
    district_code: 137,
  },
  {
    name: "Xã Lao Chải",
    code: 4474,
    division_type: "xã",
    codename: "xa_lao_chai",
    district_code: 137,
  },
  {
    name: "Xã Kim Nọi",
    code: 4477,
    division_type: "xã",
    codename: "xa_kim_noi",
    district_code: 137,
  },
  {
    name: "Xã Cao Phạ",
    code: 4480,
    division_type: "xã",
    codename: "xa_cao_pha",
    district_code: 137,
  },
  {
    name: "Xã La Pán Tẩn",
    code: 4483,
    division_type: "xã",
    codename: "xa_la_pan_tan",
    district_code: 137,
  },
  {
    name: "Xã Dế Su Phình",
    code: 4486,
    division_type: "xã",
    codename: "xa_de_su_phinh",
    district_code: 137,
  },
  {
    name: "Xã Chế Tạo",
    code: 4489,
    division_type: "xã",
    codename: "xa_che_tao",
    district_code: 137,
  },
  {
    name: "Xã Púng Luông",
    code: 4492,
    division_type: "xã",
    codename: "xa_pung_luong",
    district_code: 137,
  },
  {
    name: "Xã Nậm Khắt",
    code: 4495,
    division_type: "xã",
    codename: "xa_nam_khat",
    district_code: 137,
  },
  {
    name: "Thị trấn Cổ Phúc",
    code: 4498,
    division_type: "thị trấn",
    codename: "thi_tran_co_phuc",
    district_code: 138,
  },
  {
    name: "Xã Tân Đồng",
    code: 4501,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 138,
  },
  {
    name: "Xã Báo Đáp",
    code: 4504,
    division_type: "xã",
    codename: "xa_bao_dap",
    district_code: 138,
  },
  {
    name: "Xã Đào Thịnh",
    code: 4507,
    division_type: "xã",
    codename: "xa_dao_thinh",
    district_code: 138,
  },
  {
    name: "Xã Việt Thành",
    code: 4510,
    division_type: "xã",
    codename: "xa_viet_thanh",
    district_code: 138,
  },
  {
    name: "Xã Hòa Cuông",
    code: 4513,
    division_type: "xã",
    codename: "xa_hoa_cuong",
    district_code: 138,
  },
  {
    name: "Xã Minh Quán",
    code: 4516,
    division_type: "xã",
    codename: "xa_minh_quan",
    district_code: 138,
  },
  {
    name: "Xã Quy Mông",
    code: 4519,
    division_type: "xã",
    codename: "xa_quy_mong",
    district_code: 138,
  },
  {
    name: "Xã Cường Thịnh",
    code: 4522,
    division_type: "xã",
    codename: "xa_cuong_thinh",
    district_code: 138,
  },
  {
    name: "Xã Kiên Thành",
    code: 4525,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 138,
  },
  {
    name: "Xã Nga Quán",
    code: 4528,
    division_type: "xã",
    codename: "xa_nga_quan",
    district_code: 138,
  },
  {
    name: "Xã Y Can",
    code: 4531,
    division_type: "xã",
    codename: "xa_y_can",
    district_code: 138,
  },
  {
    name: "Xã Lương Thịnh",
    code: 4537,
    division_type: "xã",
    codename: "xa_luong_thinh",
    district_code: 138,
  },
  {
    name: "Xã Bảo Hưng",
    code: 4561,
    division_type: "xã",
    codename: "xa_bao_hung",
    district_code: 138,
  },
  {
    name: "Xã Việt Cường",
    code: 4564,
    division_type: "xã",
    codename: "xa_viet_cuong",
    district_code: 138,
  },
  {
    name: "Xã Minh Quân",
    code: 4567,
    division_type: "xã",
    codename: "xa_minh_quan",
    district_code: 138,
  },
  {
    name: "Xã Hồng Ca",
    code: 4570,
    division_type: "xã",
    codename: "xa_hong_ca",
    district_code: 138,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 4573,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 138,
  },
  {
    name: "Xã Hưng Khánh",
    code: 4576,
    division_type: "xã",
    codename: "xa_hung_khanh",
    district_code: 138,
  },
  {
    name: "Xã Việt Hồng",
    code: 4579,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 138,
  },
  {
    name: "Xã Vân Hội",
    code: 4582,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 138,
  },
  {
    name: "Thị trấn Trạm Tấu",
    code: 4585,
    division_type: "thị trấn",
    codename: "thi_tran_tram_tau",
    district_code: 139,
  },
  {
    name: "Xã Túc Đán",
    code: 4588,
    division_type: "xã",
    codename: "xa_tuc_dan",
    district_code: 139,
  },
  {
    name: "Xã Pá Lau",
    code: 4591,
    division_type: "xã",
    codename: "xa_pa_lau",
    district_code: 139,
  },
  {
    name: "Xã Xà Hồ",
    code: 4594,
    division_type: "xã",
    codename: "xa_xa_ho",
    district_code: 139,
  },
  {
    name: "Xã Phình Hồ",
    code: 4597,
    division_type: "xã",
    codename: "xa_phinh_ho",
    district_code: 139,
  },
  {
    name: "Xã Trạm Tấu",
    code: 4600,
    division_type: "xã",
    codename: "xa_tram_tau",
    district_code: 139,
  },
  {
    name: "Xã Tà Si Láng",
    code: 4603,
    division_type: "xã",
    codename: "xa_ta_si_lang",
    district_code: 139,
  },
  {
    name: "Xã Pá Hu",
    code: 4606,
    division_type: "xã",
    codename: "xa_pa_hu",
    district_code: 139,
  },
  {
    name: "Xã Làng Nhì",
    code: 4609,
    division_type: "xã",
    codename: "xa_lang_nhi",
    district_code: 139,
  },
  {
    name: "Xã Bản Công",
    code: 4612,
    division_type: "xã",
    codename: "xa_ban_cong",
    district_code: 139,
  },
  {
    name: "Xã Bản Mù",
    code: 4615,
    division_type: "xã",
    codename: "xa_ban_mu",
    district_code: 139,
  },
  {
    name: "Xã Hát Lìu",
    code: 4618,
    division_type: "xã",
    codename: "xa_hat_liu",
    district_code: 139,
  },
  {
    name: "Thị trấn NT Liên Sơn",
    code: 4621,
    division_type: "thị trấn",
    codename: "thi_tran_nt_lien_son",
    district_code: 140,
  },
  {
    name: "Thị trấn NT Trần Phú",
    code: 4627,
    division_type: "thị trấn",
    codename: "thi_tran_nt_tran_phu",
    district_code: 140,
  },
  {
    name: "Xã Tú Lệ",
    code: 4630,
    division_type: "xã",
    codename: "xa_tu_le",
    district_code: 140,
  },
  {
    name: "Xã Nậm Búng",
    code: 4633,
    division_type: "xã",
    codename: "xa_nam_bung",
    district_code: 140,
  },
  {
    name: "Xã Gia Hội",
    code: 4636,
    division_type: "xã",
    codename: "xa_gia_hoi",
    district_code: 140,
  },
  {
    name: "Xã Sùng Đô",
    code: 4639,
    division_type: "xã",
    codename: "xa_sung_do",
    district_code: 140,
  },
  {
    name: "Xã Nậm Mười",
    code: 4642,
    division_type: "xã",
    codename: "xa_nam_muoi",
    district_code: 140,
  },
  {
    name: "Xã An Lương",
    code: 4645,
    division_type: "xã",
    codename: "xa_an_luong",
    district_code: 140,
  },
  {
    name: "Xã Nậm Lành",
    code: 4648,
    division_type: "xã",
    codename: "xa_nam_lanh",
    district_code: 140,
  },
  {
    name: "Xã Sơn Lương",
    code: 4651,
    division_type: "xã",
    codename: "xa_son_luong",
    district_code: 140,
  },
  {
    name: "Xã Suối Quyền",
    code: 4654,
    division_type: "xã",
    codename: "xa_suoi_quyen",
    district_code: 140,
  },
  {
    name: "Xã Suối Giàng",
    code: 4657,
    division_type: "xã",
    codename: "xa_suoi_giang",
    district_code: 140,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 4666,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 140,
  },
  {
    name: "Xã Suối Bu",
    code: 4669,
    division_type: "xã",
    codename: "xa_suoi_bu",
    district_code: 140,
  },
  {
    name: "Thị trấn Sơn Thịnh",
    code: 4672,
    division_type: "thị trấn",
    codename: "thi_tran_son_thinh",
    district_code: 140,
  },
  {
    name: "Xã Đại Lịch",
    code: 4687,
    division_type: "xã",
    codename: "xa_dai_lich",
    district_code: 140,
  },
  {
    name: "Xã Đồng Khê",
    code: 4690,
    division_type: "xã",
    codename: "xa_dong_khe",
    district_code: 140,
  },
  {
    name: "Xã Cát Thịnh",
    code: 4693,
    division_type: "xã",
    codename: "xa_cat_thinh",
    district_code: 140,
  },
  {
    name: "Xã Tân Thịnh",
    code: 4696,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 140,
  },
  {
    name: "Xã Chấn Thịnh",
    code: 4699,
    division_type: "xã",
    codename: "xa_chan_thinh",
    district_code: 140,
  },
  {
    name: "Xã Bình Thuận",
    code: 4702,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 140,
  },
  {
    name: "Xã Thượng Bằng La",
    code: 4705,
    division_type: "xã",
    codename: "xa_thuong_bang_la",
    district_code: 140,
  },
  {
    name: "Xã Minh An",
    code: 4708,
    division_type: "xã",
    codename: "xa_minh_an",
    district_code: 140,
  },
  {
    name: "Xã Nghĩa Tâm",
    code: 4711,
    division_type: "xã",
    codename: "xa_nghia_tam",
    district_code: 140,
  },
  {
    name: "Thị trấn Yên Bình",
    code: 4714,
    division_type: "thị trấn",
    codename: "thi_tran_yen_binh",
    district_code: 141,
  },
  {
    name: "Thị trấn Thác Bà",
    code: 4717,
    division_type: "thị trấn",
    codename: "thi_tran_thac_ba",
    district_code: 141,
  },
  {
    name: "Xã Xuân Long",
    code: 4720,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 141,
  },
  {
    name: "Xã Cảm Nhân",
    code: 4726,
    division_type: "xã",
    codename: "xa_cam_nhan",
    district_code: 141,
  },
  {
    name: "Xã Ngọc Chấn",
    code: 4729,
    division_type: "xã",
    codename: "xa_ngoc_chan",
    district_code: 141,
  },
  {
    name: "Xã Tân Nguyên",
    code: 4732,
    division_type: "xã",
    codename: "xa_tan_nguyen",
    district_code: 141,
  },
  {
    name: "Xã Phúc Ninh",
    code: 4735,
    division_type: "xã",
    codename: "xa_phuc_ninh",
    district_code: 141,
  },
  {
    name: "Xã Bảo Ái",
    code: 4738,
    division_type: "xã",
    codename: "xa_bao_ai",
    district_code: 141,
  },
  {
    name: "Xã Mỹ Gia",
    code: 4741,
    division_type: "xã",
    codename: "xa_my_gia",
    district_code: 141,
  },
  {
    name: "Xã Xuân Lai",
    code: 4744,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 141,
  },
  {
    name: "Xã Mông Sơn",
    code: 4747,
    division_type: "xã",
    codename: "xa_mong_son",
    district_code: 141,
  },
  {
    name: "Xã Cảm Ân",
    code: 4750,
    division_type: "xã",
    codename: "xa_cam_an",
    district_code: 141,
  },
  {
    name: "Xã Yên Thành",
    code: 4753,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 141,
  },
  {
    name: "Xã Tân Hương",
    code: 4756,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 141,
  },
  {
    name: "Xã Phúc An",
    code: 4759,
    division_type: "xã",
    codename: "xa_phuc_an",
    district_code: 141,
  },
  {
    name: "Xã Bạch Hà",
    code: 4762,
    division_type: "xã",
    codename: "xa_bach_ha",
    district_code: 141,
  },
  {
    name: "Xã Vũ Linh",
    code: 4765,
    division_type: "xã",
    codename: "xa_vu_linh",
    district_code: 141,
  },
  {
    name: "Xã Đại Đồng",
    code: 4768,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 141,
  },
  {
    name: "Xã Vĩnh Kiên",
    code: 4771,
    division_type: "xã",
    codename: "xa_vinh_kien",
    district_code: 141,
  },
  {
    name: "Xã Yên Bình",
    code: 4774,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 141,
  },
  {
    name: "Xã Thịnh Hưng",
    code: 4777,
    division_type: "xã",
    codename: "xa_thinh_hung",
    district_code: 141,
  },
  {
    name: "Xã Hán Đà",
    code: 4780,
    division_type: "xã",
    codename: "xa_han_da",
    district_code: 141,
  },
  {
    name: "Xã Phú Thịnh",
    code: 4783,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 141,
  },
  {
    name: "Xã Đại Minh",
    code: 4786,
    division_type: "xã",
    codename: "xa_dai_minh",
    district_code: 141,
  },
  {
    name: "Phường Thái Bình",
    code: 4789,
    division_type: "phường",
    codename: "phuong_thai_binh",
    district_code: 148,
  },
  {
    name: "Phường Tân Hòa",
    code: 4792,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 148,
  },
  {
    name: "Phường Thịnh Lang",
    code: 4795,
    division_type: "phường",
    codename: "phuong_thinh_lang",
    district_code: 148,
  },
  {
    name: "Phường Hữu Nghị",
    code: 4798,
    division_type: "phường",
    codename: "phuong_huu_nghi",
    district_code: 148,
  },
  {
    name: "Phường Tân Thịnh",
    code: 4801,
    division_type: "phường",
    codename: "phuong_tan_thinh",
    district_code: 148,
  },
  {
    name: "Phường Đồng Tiến",
    code: 4804,
    division_type: "phường",
    codename: "phuong_dong_tien",
    district_code: 148,
  },
  {
    name: "Phường Phương Lâm",
    code: 4807,
    division_type: "phường",
    codename: "phuong_phuong_lam",
    district_code: 148,
  },
  {
    name: "Xã Yên Mông",
    code: 4813,
    division_type: "xã",
    codename: "xa_yen_mong",
    district_code: 148,
  },
  {
    name: "Phường Quỳnh Lâm",
    code: 4816,
    division_type: "phường",
    codename: "phuong_quynh_lam",
    district_code: 148,
  },
  {
    name: "Phường Dân Chủ",
    code: 4819,
    division_type: "phường",
    codename: "phuong_dan_chu",
    district_code: 148,
  },
  {
    name: "Xã Hòa Bình",
    code: 4825,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 148,
  },
  {
    name: "Phường Thống Nhất",
    code: 4828,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 148,
  },
  {
    name: "Phường Kỳ Sơn",
    code: 4894,
    division_type: "phường",
    codename: "phuong_ky_son",
    district_code: 148,
  },
  {
    name: "Xã Thịnh Minh",
    code: 4897,
    division_type: "xã",
    codename: "xa_thinh_minh",
    district_code: 148,
  },
  {
    name: "Xã Hợp Thành",
    code: 4903,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 148,
  },
  {
    name: "Xã Quang Tiến",
    code: 4906,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 148,
  },
  {
    name: "Xã Mông Hóa",
    code: 4912,
    division_type: "xã",
    codename: "xa_mong_hoa",
    district_code: 148,
  },
  {
    name: "Phường Trung Minh",
    code: 4918,
    division_type: "phường",
    codename: "phuong_trung_minh",
    district_code: 148,
  },
  {
    name: "Xã Độc Lập",
    code: 4921,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 148,
  },
  {
    name: "Thị trấn Đà Bắc",
    code: 4831,
    division_type: "thị trấn",
    codename: "thi_tran_da_bac",
    district_code: 150,
  },
  {
    name: "Xã Nánh Nghê",
    code: 4834,
    division_type: "xã",
    codename: "xa_nanh_nghe",
    district_code: 150,
  },
  {
    name: "Xã Giáp Đắt",
    code: 4840,
    division_type: "xã",
    codename: "xa_giap_dat",
    district_code: 150,
  },
  {
    name: "Xã Mường Chiềng",
    code: 4846,
    division_type: "xã",
    codename: "xa_muong_chieng",
    district_code: 150,
  },
  {
    name: "Xã Tân Pheo",
    code: 4849,
    division_type: "xã",
    codename: "xa_tan_pheo",
    district_code: 150,
  },
  {
    name: "Xã Đồng Chum",
    code: 4852,
    division_type: "xã",
    codename: "xa_dong_chum",
    district_code: 150,
  },
  {
    name: "Xã Tân Minh",
    code: 4855,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 150,
  },
  {
    name: "Xã Đoàn Kết",
    code: 4858,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 150,
  },
  {
    name: "Xã Đồng Ruộng",
    code: 4861,
    division_type: "xã",
    codename: "xa_dong_ruong",
    district_code: 150,
  },
  {
    name: "Xã Tú Lý",
    code: 4867,
    division_type: "xã",
    codename: "xa_tu_ly",
    district_code: 150,
  },
  {
    name: "Xã Trung Thành",
    code: 4870,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 150,
  },
  {
    name: "Xã Yên Hòa",
    code: 4873,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 150,
  },
  {
    name: "Xã Cao Sơn",
    code: 4876,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 150,
  },
  {
    name: "Xã Toàn Sơn",
    code: 4879,
    division_type: "xã",
    codename: "xa_toan_son",
    district_code: 150,
  },
  {
    name: "Xã Hiền Lương",
    code: 4885,
    division_type: "xã",
    codename: "xa_hien_luong",
    district_code: 150,
  },
  {
    name: "Xã Tiền Phong",
    code: 4888,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 150,
  },
  {
    name: "Xã Vầy Nưa",
    code: 4891,
    division_type: "xã",
    codename: "xa_vay_nua",
    district_code: 150,
  },
  {
    name: "Thị trấn Lương Sơn",
    code: 4924,
    division_type: "thị trấn",
    codename: "thi_tran_luong_son",
    district_code: 152,
  },
  {
    name: "Xã Lâm Sơn",
    code: 4942,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 152,
  },
  {
    name: "Xã Hòa Sơn",
    code: 4945,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 152,
  },
  {
    name: "Xã Tân Vinh",
    code: 4951,
    division_type: "xã",
    codename: "xa_tan_vinh",
    district_code: 152,
  },
  {
    name: "Xã Nhuận Trạch",
    code: 4954,
    division_type: "xã",
    codename: "xa_nhuan_trach",
    district_code: 152,
  },
  {
    name: "Xã Cao Sơn",
    code: 4957,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 152,
  },
  {
    name: "Xã Cư Yên",
    code: 4960,
    division_type: "xã",
    codename: "xa_cu_yen",
    district_code: 152,
  },
  {
    name: "Xã Liên Sơn",
    code: 4969,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 152,
  },
  {
    name: "Xã Cao Dương",
    code: 5008,
    division_type: "xã",
    codename: "xa_cao_duong",
    district_code: 152,
  },
  {
    name: "Xã Thanh Sơn",
    code: 5041,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 152,
  },
  {
    name: "Xã Thanh Cao",
    code: 5047,
    division_type: "xã",
    codename: "xa_thanh_cao",
    district_code: 152,
  },
  {
    name: "Thị trấn Bo",
    code: 4978,
    division_type: "thị trấn",
    codename: "thi_tran_bo",
    district_code: 153,
  },
  {
    name: "Xã Đú Sáng",
    code: 4984,
    division_type: "xã",
    codename: "xa_du_sang",
    district_code: 153,
  },
  {
    name: "Xã Hùng Sơn",
    code: 4987,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 153,
  },
  {
    name: "Xã Bình Sơn",
    code: 4990,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 153,
  },
  {
    name: "Xã Tú Sơn",
    code: 4999,
    division_type: "xã",
    codename: "xa_tu_son",
    district_code: 153,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 5005,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 153,
  },
  {
    name: "Xã Đông Bắc",
    code: 5014,
    division_type: "xã",
    codename: "xa_dong_bac",
    district_code: 153,
  },
  {
    name: "Xã Xuân Thủy",
    code: 5017,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 153,
  },
  {
    name: "Xã Vĩnh Đồng",
    code: 5026,
    division_type: "xã",
    codename: "xa_vinh_dong",
    district_code: 153,
  },
  {
    name: "Xã Kim Lập",
    code: 5035,
    division_type: "xã",
    codename: "xa_kim_lap",
    district_code: 153,
  },
  {
    name: "Xã Hợp Tiến",
    code: 5038,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 153,
  },
  {
    name: "Xã Kim Bôi",
    code: 5065,
    division_type: "xã",
    codename: "xa_kim_boi",
    district_code: 153,
  },
  {
    name: "Xã Nam Thượng",
    code: 5068,
    division_type: "xã",
    codename: "xa_nam_thuong",
    district_code: 153,
  },
  {
    name: "Xã Cuối Hạ",
    code: 5077,
    division_type: "xã",
    codename: "xa_cuoi_ha",
    district_code: 153,
  },
  {
    name: "Xã Sào Báy",
    code: 5080,
    division_type: "xã",
    codename: "xa_sao_bay",
    district_code: 153,
  },
  {
    name: "Xã Mi Hòa",
    code: 5083,
    division_type: "xã",
    codename: "xa_mi_hoa",
    district_code: 153,
  },
  {
    name: "Xã Nuông Dăm",
    code: 5086,
    division_type: "xã",
    codename: "xa_nuong_dam",
    district_code: 153,
  },
  {
    name: "Thị trấn Cao Phong",
    code: 5089,
    division_type: "thị trấn",
    codename: "thi_tran_cao_phong",
    district_code: 154,
  },
  {
    name: "Xã Bình Thanh",
    code: 5092,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 154,
  },
  {
    name: "Xã Thung Nai",
    code: 5095,
    division_type: "xã",
    codename: "xa_thung_nai",
    district_code: 154,
  },
  {
    name: "Xã Bắc Phong",
    code: 5098,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 154,
  },
  {
    name: "Xã Thu Phong",
    code: 5101,
    division_type: "xã",
    codename: "xa_thu_phong",
    district_code: 154,
  },
  {
    name: "Xã Hợp Phong",
    code: 5104,
    division_type: "xã",
    codename: "xa_hop_phong",
    district_code: 154,
  },
  {
    name: "Xã Tây Phong",
    code: 5110,
    division_type: "xã",
    codename: "xa_tay_phong",
    district_code: 154,
  },
  {
    name: "Xã Dũng Phong",
    code: 5116,
    division_type: "xã",
    codename: "xa_dung_phong",
    district_code: 154,
  },
  {
    name: "Xã Nam Phong",
    code: 5119,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 154,
  },
  {
    name: "Xã Thạch Yên",
    code: 5125,
    division_type: "xã",
    codename: "xa_thach_yen",
    district_code: 154,
  },
  {
    name: "Thị trấn Mãn Đức",
    code: 5128,
    division_type: "thị trấn",
    codename: "thi_tran_man_duc",
    district_code: 155,
  },
  {
    name: "Xã Suối Hoa",
    code: 5134,
    division_type: "xã",
    codename: "xa_suoi_hoa",
    district_code: 155,
  },
  {
    name: "Xã Phú Vinh",
    code: 5137,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 155,
  },
  {
    name: "Xã Phú Cường",
    code: 5140,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 155,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 5143,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 155,
  },
  {
    name: "Xã Quyết Chiến",
    code: 5152,
    division_type: "xã",
    codename: "xa_quyet_chien",
    district_code: 155,
  },
  {
    name: "Xã Phong Phú",
    code: 5158,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 155,
  },
  {
    name: "Xã Tử Nê",
    code: 5164,
    division_type: "xã",
    codename: "xa_tu_ne",
    district_code: 155,
  },
  {
    name: "Xã Thanh Hối",
    code: 5167,
    division_type: "xã",
    codename: "xa_thanh_hoi",
    district_code: 155,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 5170,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 155,
  },
  {
    name: "Xã Đông Lai",
    code: 5173,
    division_type: "xã",
    codename: "xa_dong_lai",
    district_code: 155,
  },
  {
    name: "Xã Vân Sơn",
    code: 5176,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 155,
  },
  {
    name: "Xã Nhân Mỹ",
    code: 5182,
    division_type: "xã",
    codename: "xa_nhan_my",
    district_code: 155,
  },
  {
    name: "Xã Lỗ Sơn",
    code: 5191,
    division_type: "xã",
    codename: "xa_lo_son",
    district_code: 155,
  },
  {
    name: "Xã Ngổ Luông",
    code: 5194,
    division_type: "xã",
    codename: "xa_ngo_luong",
    district_code: 155,
  },
  {
    name: "Xã Gia Mô",
    code: 5197,
    division_type: "xã",
    codename: "xa_gia_mo",
    district_code: 155,
  },
  {
    name: "Xã Tân Thành",
    code: 4882,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 156,
  },
  {
    name: "Thị trấn Mai Châu",
    code: 5200,
    division_type: "thị trấn",
    codename: "thi_tran_mai_chau",
    district_code: 156,
  },
  {
    name: "Xã Sơn Thủy",
    code: 5206,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 156,
  },
  {
    name: "Xã Pà Cò",
    code: 5209,
    division_type: "xã",
    codename: "xa_pa_co",
    district_code: 156,
  },
  {
    name: "Xã Hang Kia",
    code: 5212,
    division_type: "xã",
    codename: "xa_hang_kia",
    district_code: 156,
  },
  {
    name: "Xã Đồng Tân",
    code: 5221,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 156,
  },
  {
    name: "Xã Cun Pheo",
    code: 5224,
    division_type: "xã",
    codename: "xa_cun_pheo",
    district_code: 156,
  },
  {
    name: "Xã Bao La",
    code: 5227,
    division_type: "xã",
    codename: "xa_bao_la",
    district_code: 156,
  },
  {
    name: "Xã Tòng Đậu",
    code: 5233,
    division_type: "xã",
    codename: "xa_tong_dau",
    district_code: 156,
  },
  {
    name: "Xã Nà Phòn",
    code: 5242,
    division_type: "xã",
    codename: "xa_na_phon",
    district_code: 156,
  },
  {
    name: "Xã Săm Khóe",
    code: 5245,
    division_type: "xã",
    codename: "xa_sam_khoe",
    district_code: 156,
  },
  {
    name: "Xã Chiềng Châu",
    code: 5248,
    division_type: "xã",
    codename: "xa_chieng_chau",
    district_code: 156,
  },
  {
    name: "Xã Mai Hạ",
    code: 5251,
    division_type: "xã",
    codename: "xa_mai_ha",
    district_code: 156,
  },
  {
    name: "Xã Thành Sơn",
    code: 5254,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 156,
  },
  {
    name: "Xã Mai Hịch",
    code: 5257,
    division_type: "xã",
    codename: "xa_mai_hich",
    district_code: 156,
  },
  {
    name: "Xã Vạn Mai",
    code: 5263,
    division_type: "xã",
    codename: "xa_van_mai",
    district_code: 156,
  },
  {
    name: "Thị trấn Vụ Bản",
    code: 5266,
    division_type: "thị trấn",
    codename: "thi_tran_vu_ban",
    district_code: 157,
  },
  {
    name: "Xã Quý Hòa",
    code: 5269,
    division_type: "xã",
    codename: "xa_quy_hoa",
    district_code: 157,
  },
  {
    name: "Xã Miền Đồi",
    code: 5272,
    division_type: "xã",
    codename: "xa_mien_doi",
    district_code: 157,
  },
  {
    name: "Xã Mỹ Thành",
    code: 5275,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 157,
  },
  {
    name: "Xã Tuân Đạo",
    code: 5278,
    division_type: "xã",
    codename: "xa_tuan_dao",
    district_code: 157,
  },
  {
    name: "Xã Văn Nghĩa",
    code: 5281,
    division_type: "xã",
    codename: "xa_van_nghia",
    district_code: 157,
  },
  {
    name: "Xã Văn Sơn",
    code: 5284,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 157,
  },
  {
    name: "Xã Tân Lập",
    code: 5287,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 157,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 5290,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 157,
  },
  {
    name: "Xã Thượng Cốc",
    code: 5293,
    division_type: "xã",
    codename: "xa_thuong_coc",
    district_code: 157,
  },
  {
    name: "Xã Quyết Thắng",
    code: 5299,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 157,
  },
  {
    name: "Xã Xuất Hóa",
    code: 5302,
    division_type: "xã",
    codename: "xa_xuat_hoa",
    district_code: 157,
  },
  {
    name: "Xã Yên Phú",
    code: 5305,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 157,
  },
  {
    name: "Xã Bình Hẻm",
    code: 5308,
    division_type: "xã",
    codename: "xa_binh_hem",
    district_code: 157,
  },
  {
    name: "Xã Định Cư",
    code: 5320,
    division_type: "xã",
    codename: "xa_dinh_cu",
    district_code: 157,
  },
  {
    name: "Xã Chí Đạo",
    code: 5323,
    division_type: "xã",
    codename: "xa_chi_dao",
    district_code: 157,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 5329,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 157,
  },
  {
    name: "Xã Hương Nhượng",
    code: 5332,
    division_type: "xã",
    codename: "xa_huong_nhuong",
    district_code: 157,
  },
  {
    name: "Xã Vũ Bình",
    code: 5335,
    division_type: "xã",
    codename: "xa_vu_binh",
    district_code: 157,
  },
  {
    name: "Xã Tự Do",
    code: 5338,
    division_type: "xã",
    codename: "xa_tu_do",
    district_code: 157,
  },
  {
    name: "Xã Yên Nghiệp",
    code: 5341,
    division_type: "xã",
    codename: "xa_yen_nghiep",
    district_code: 157,
  },
  {
    name: "Xã Tân Mỹ",
    code: 5344,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 157,
  },
  {
    name: "Xã Ân Nghĩa",
    code: 5347,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 157,
  },
  {
    name: "Xã Ngọc Lâu",
    code: 5350,
    division_type: "xã",
    codename: "xa_ngoc_lau",
    district_code: 157,
  },
  {
    name: "Thị trấn Hàng Trạm",
    code: 5353,
    division_type: "thị trấn",
    codename: "thi_tran_hang_tram",
    district_code: 158,
  },
  {
    name: "Xã Lạc Sỹ",
    code: 5356,
    division_type: "xã",
    codename: "xa_lac_sy",
    district_code: 158,
  },
  {
    name: "Xã Lạc Lương",
    code: 5362,
    division_type: "xã",
    codename: "xa_lac_luong",
    district_code: 158,
  },
  {
    name: "Xã Bảo Hiệu",
    code: 5365,
    division_type: "xã",
    codename: "xa_bao_hieu",
    district_code: 158,
  },
  {
    name: "Xã Đa Phúc",
    code: 5368,
    division_type: "xã",
    codename: "xa_da_phuc",
    district_code: 158,
  },
  {
    name: "Xã Hữu Lợi",
    code: 5371,
    division_type: "xã",
    codename: "xa_huu_loi",
    district_code: 158,
  },
  {
    name: "Xã Lạc Thịnh",
    code: 5374,
    division_type: "xã",
    codename: "xa_lac_thinh",
    district_code: 158,
  },
  {
    name: "Xã Đoàn Kết",
    code: 5380,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 158,
  },
  {
    name: "Xã Phú Lai",
    code: 5383,
    division_type: "xã",
    codename: "xa_phu_lai",
    district_code: 158,
  },
  {
    name: "Xã Yên Trị",
    code: 5386,
    division_type: "xã",
    codename: "xa_yen_tri",
    district_code: 158,
  },
  {
    name: "Xã Ngọc Lương",
    code: 5389,
    division_type: "xã",
    codename: "xa_ngoc_luong",
    district_code: 158,
  },
  {
    name: "Thị trấn Ba Hàng Đồi",
    code: 4981,
    division_type: "thị trấn",
    codename: "thi_tran_ba_hang_doi",
    district_code: 159,
  },
  {
    name: "Thị trấn Chi Nê",
    code: 5392,
    division_type: "thị trấn",
    codename: "thi_tran_chi_ne",
    district_code: 159,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 5395,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 159,
  },
  {
    name: "Xã Phú Thành",
    code: 5398,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 159,
  },
  {
    name: "Xã Hưng Thi",
    code: 5404,
    division_type: "xã",
    codename: "xa_hung_thi",
    district_code: 159,
  },
  {
    name: "Xã Khoan Dụ",
    code: 5413,
    division_type: "xã",
    codename: "xa_khoan_du",
    district_code: 159,
  },
  {
    name: "Xã Đồng Tâm",
    code: 5419,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 159,
  },
  {
    name: "Xã Yên Bồng",
    code: 5422,
    division_type: "xã",
    codename: "xa_yen_bong",
    district_code: 159,
  },
  {
    name: "Xã Thống Nhất",
    code: 5425,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 159,
  },
  {
    name: "Xã An Bình",
    code: 5428,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 159,
  },
  {
    name: "Phường Quán Triều",
    code: 5431,
    division_type: "phường",
    codename: "phuong_quan_trieu",
    district_code: 164,
  },
  {
    name: "Phường Quang Vinh",
    code: 5434,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 164,
  },
  {
    name: "Phường Túc Duyên",
    code: 5437,
    division_type: "phường",
    codename: "phuong_tuc_duyen",
    district_code: 164,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 5440,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 164,
  },
  {
    name: "Phường Trưng Vương",
    code: 5443,
    division_type: "phường",
    codename: "phuong_trung_vuong",
    district_code: 164,
  },
  {
    name: "Phường Quang Trung",
    code: 5446,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 164,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 5449,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 164,
  },
  {
    name: "Phường Tân Thịnh",
    code: 5452,
    division_type: "phường",
    codename: "phuong_tan_thinh",
    district_code: 164,
  },
  {
    name: "Phường Thịnh Đán",
    code: 5455,
    division_type: "phường",
    codename: "phuong_thinh_dan",
    district_code: 164,
  },
  {
    name: "Phường Đồng Quang",
    code: 5458,
    division_type: "phường",
    codename: "phuong_dong_quang",
    district_code: 164,
  },
  {
    name: "Phường Gia Sàng",
    code: 5461,
    division_type: "phường",
    codename: "phuong_gia_sang",
    district_code: 164,
  },
  {
    name: "Phường Tân Lập",
    code: 5464,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 164,
  },
  {
    name: "Phường Cam Giá",
    code: 5467,
    division_type: "phường",
    codename: "phuong_cam_gia",
    district_code: 164,
  },
  {
    name: "Phường Phú Xá",
    code: 5470,
    division_type: "phường",
    codename: "phuong_phu_xa",
    district_code: 164,
  },
  {
    name: "Phường Hương Sơn",
    code: 5473,
    division_type: "phường",
    codename: "phuong_huong_son",
    district_code: 164,
  },
  {
    name: "Phường Trung Thành",
    code: 5476,
    division_type: "phường",
    codename: "phuong_trung_thanh",
    district_code: 164,
  },
  {
    name: "Phường Tân Thành",
    code: 5479,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 164,
  },
  {
    name: "Phường Tân Long",
    code: 5482,
    division_type: "phường",
    codename: "phuong_tan_long",
    district_code: 164,
  },
  {
    name: "Xã Phúc Hà",
    code: 5485,
    division_type: "xã",
    codename: "xa_phuc_ha",
    district_code: 164,
  },
  {
    name: "Xã Phúc Xuân",
    code: 5488,
    division_type: "xã",
    codename: "xa_phuc_xuan",
    district_code: 164,
  },
  {
    name: "Xã Quyết Thắng",
    code: 5491,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 164,
  },
  {
    name: "Xã Phúc Trìu",
    code: 5494,
    division_type: "xã",
    codename: "xa_phuc_triu",
    district_code: 164,
  },
  {
    name: "Xã Thịnh Đức",
    code: 5497,
    division_type: "xã",
    codename: "xa_thinh_duc",
    district_code: 164,
  },
  {
    name: "Phường Tích Lương",
    code: 5500,
    division_type: "phường",
    codename: "phuong_tich_luong",
    district_code: 164,
  },
  {
    name: "Xã Tân Cương",
    code: 5503,
    division_type: "xã",
    codename: "xa_tan_cuong",
    district_code: 164,
  },
  {
    name: "Xã Sơn Cẩm",
    code: 5653,
    division_type: "xã",
    codename: "xa_son_cam",
    district_code: 164,
  },
  {
    name: "Phường Chùa Hang",
    code: 5659,
    division_type: "phường",
    codename: "phuong_chua_hang",
    district_code: 164,
  },
  {
    name: "Xã Cao Ngạn",
    code: 5695,
    division_type: "xã",
    codename: "xa_cao_ngan",
    district_code: 164,
  },
  {
    name: "Xã Linh Sơn",
    code: 5701,
    division_type: "xã",
    codename: "xa_linh_son",
    district_code: 164,
  },
  {
    name: "Phường Đồng Bẩm",
    code: 5710,
    division_type: "phường",
    codename: "phuong_dong_bam",
    district_code: 164,
  },
  {
    name: "Xã Huống Thượng",
    code: 5713,
    division_type: "xã",
    codename: "xa_huong_thuong",
    district_code: 164,
  },
  {
    name: "Xã Đồng Liên",
    code: 5914,
    division_type: "xã",
    codename: "xa_dong_lien",
    district_code: 164,
  },
  {
    name: "Phường Lương Sơn",
    code: 5506,
    division_type: "phường",
    codename: "phuong_luong_son",
    district_code: 165,
  },
  {
    name: "Phường Châu Sơn",
    code: 5509,
    division_type: "phường",
    codename: "phuong_chau_son",
    district_code: 165,
  },
  {
    name: "Phường Mỏ Chè",
    code: 5512,
    division_type: "phường",
    codename: "phuong_mo_che",
    district_code: 165,
  },
  {
    name: "Phường Cải Đan",
    code: 5515,
    division_type: "phường",
    codename: "phuong_cai_dan",
    district_code: 165,
  },
  {
    name: "Phường Thắng Lợi",
    code: 5518,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 165,
  },
  {
    name: "Phường Phố Cò",
    code: 5521,
    division_type: "phường",
    codename: "phuong_pho_co",
    district_code: 165,
  },
  {
    name: "Xã Tân Quang",
    code: 5527,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 165,
  },
  {
    name: "Phường Bách Quang",
    code: 5528,
    division_type: "phường",
    codename: "phuong_bach_quang",
    district_code: 165,
  },
  {
    name: "Xã Bình Sơn",
    code: 5530,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 165,
  },
  {
    name: "Xã Bá Xuyên",
    code: 5533,
    division_type: "xã",
    codename: "xa_ba_xuyen",
    district_code: 165,
  },
  {
    name: "Thị trấn Chợ Chu",
    code: 5536,
    division_type: "thị trấn",
    codename: "thi_tran_cho_chu",
    district_code: 167,
  },
  {
    name: "Xã Linh Thông",
    code: 5539,
    division_type: "xã",
    codename: "xa_linh_thong",
    district_code: 167,
  },
  {
    name: "Xã Lam Vỹ",
    code: 5542,
    division_type: "xã",
    codename: "xa_lam_vy",
    district_code: 167,
  },
  {
    name: "Xã Quy Kỳ",
    code: 5545,
    division_type: "xã",
    codename: "xa_quy_ky",
    district_code: 167,
  },
  {
    name: "Xã Tân Thịnh",
    code: 5548,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 167,
  },
  {
    name: "Xã Kim Phượng",
    code: 5551,
    division_type: "xã",
    codename: "xa_kim_phuong",
    district_code: 167,
  },
  {
    name: "Xã Bảo Linh",
    code: 5554,
    division_type: "xã",
    codename: "xa_bao_linh",
    district_code: 167,
  },
  {
    name: "Xã Phúc Chu",
    code: 5560,
    division_type: "xã",
    codename: "xa_phuc_chu",
    district_code: 167,
  },
  {
    name: "Xã Tân Dương",
    code: 5563,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 167,
  },
  {
    name: "Xã Phượng Tiến",
    code: 5566,
    division_type: "xã",
    codename: "xa_phuong_tien",
    district_code: 167,
  },
  {
    name: "Xã Bảo Cường",
    code: 5569,
    division_type: "xã",
    codename: "xa_bao_cuong",
    district_code: 167,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 5572,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 167,
  },
  {
    name: "Xã Định Biên",
    code: 5575,
    division_type: "xã",
    codename: "xa_dinh_bien",
    district_code: 167,
  },
  {
    name: "Xã Thanh Định",
    code: 5578,
    division_type: "xã",
    codename: "xa_thanh_dinh",
    district_code: 167,
  },
  {
    name: "Xã Trung Hội",
    code: 5581,
    division_type: "xã",
    codename: "xa_trung_hoi",
    district_code: 167,
  },
  {
    name: "Xã Trung Lương",
    code: 5584,
    division_type: "xã",
    codename: "xa_trung_luong",
    district_code: 167,
  },
  {
    name: "Xã Bình Yên",
    code: 5587,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 167,
  },
  {
    name: "Xã Điềm Mặc",
    code: 5590,
    division_type: "xã",
    codename: "xa_diem_mac",
    district_code: 167,
  },
  {
    name: "Xã Phú Tiến",
    code: 5593,
    division_type: "xã",
    codename: "xa_phu_tien",
    district_code: 167,
  },
  {
    name: "Xã Bộc Nhiêu",
    code: 5596,
    division_type: "xã",
    codename: "xa_boc_nhieu",
    district_code: 167,
  },
  {
    name: "Xã Sơn Phú",
    code: 5599,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 167,
  },
  {
    name: "Xã Phú Đình",
    code: 5602,
    division_type: "xã",
    codename: "xa_phu_dinh",
    district_code: 167,
  },
  {
    name: "Xã Bình Thành",
    code: 5605,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 167,
  },
  {
    name: "Thị trấn Giang Tiên",
    code: 5608,
    division_type: "thị trấn",
    codename: "thi_tran_giang_tien",
    district_code: 168,
  },
  {
    name: "Thị trấn Đu",
    code: 5611,
    division_type: "thị trấn",
    codename: "thi_tran_du",
    district_code: 168,
  },
  {
    name: "Xã Yên Ninh",
    code: 5614,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 168,
  },
  {
    name: "Xã Yên Trạch",
    code: 5617,
    division_type: "xã",
    codename: "xa_yen_trach",
    district_code: 168,
  },
  {
    name: "Xã Yên Đổ",
    code: 5620,
    division_type: "xã",
    codename: "xa_yen_do",
    district_code: 168,
  },
  {
    name: "Xã Yên Lạc",
    code: 5623,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 168,
  },
  {
    name: "Xã Ôn Lương",
    code: 5626,
    division_type: "xã",
    codename: "xa_on_luong",
    district_code: 168,
  },
  {
    name: "Xã Động Đạt",
    code: 5629,
    division_type: "xã",
    codename: "xa_dong_dat",
    district_code: 168,
  },
  {
    name: "Xã Phủ Lý",
    code: 5632,
    division_type: "xã",
    codename: "xa_phu_ly",
    district_code: 168,
  },
  {
    name: "Xã Phú Đô",
    code: 5635,
    division_type: "xã",
    codename: "xa_phu_do",
    district_code: 168,
  },
  {
    name: "Xã Hợp Thành",
    code: 5638,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 168,
  },
  {
    name: "Xã Tức Tranh",
    code: 5641,
    division_type: "xã",
    codename: "xa_tuc_tranh",
    district_code: 168,
  },
  {
    name: "Xã Phấn Mễ",
    code: 5644,
    division_type: "xã",
    codename: "xa_phan_me",
    district_code: 168,
  },
  {
    name: "Xã Vô Tranh",
    code: 5647,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 168,
  },
  {
    name: "Xã Cổ Lũng",
    code: 5650,
    division_type: "xã",
    codename: "xa_co_lung",
    district_code: 168,
  },
  {
    name: "Thị trấn Sông Cầu",
    code: 5656,
    division_type: "thị trấn",
    codename: "thi_tran_song_cau",
    district_code: 169,
  },
  {
    name: "Thị trấn Trại Cau",
    code: 5662,
    division_type: "thị trấn",
    codename: "thi_tran_trai_cau",
    district_code: 169,
  },
  {
    name: "Xã Văn Lăng",
    code: 5665,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 169,
  },
  {
    name: "Xã Tân Long",
    code: 5668,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 169,
  },
  {
    name: "Xã Hòa Bình",
    code: 5671,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 169,
  },
  {
    name: "Xã Quang Sơn",
    code: 5674,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 169,
  },
  {
    name: "Xã Minh Lập",
    code: 5677,
    division_type: "xã",
    codename: "xa_minh_lap",
    district_code: 169,
  },
  {
    name: "Xã Văn Hán",
    code: 5680,
    division_type: "xã",
    codename: "xa_van_han",
    district_code: 169,
  },
  {
    name: "Xã Hóa Trung",
    code: 5683,
    division_type: "xã",
    codename: "xa_hoa_trung",
    district_code: 169,
  },
  {
    name: "Xã Khe Mo",
    code: 5686,
    division_type: "xã",
    codename: "xa_khe_mo",
    district_code: 169,
  },
  {
    name: "Xã Cây Thị",
    code: 5689,
    division_type: "xã",
    codename: "xa_cay_thi",
    district_code: 169,
  },
  {
    name: "Xã Hóa Thượng",
    code: 5692,
    division_type: "xã",
    codename: "xa_hoa_thuong",
    district_code: 169,
  },
  {
    name: "Xã Hợp Tiến",
    code: 5698,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 169,
  },
  {
    name: "Xã Tân Lợi",
    code: 5704,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 169,
  },
  {
    name: "Xã Nam Hòa",
    code: 5707,
    division_type: "xã",
    codename: "xa_nam_hoa",
    district_code: 169,
  },
  {
    name: "Thị trấn Đình Cả",
    code: 5716,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_ca",
    district_code: 170,
  },
  {
    name: "Xã Sảng Mộc",
    code: 5719,
    division_type: "xã",
    codename: "xa_sang_moc",
    district_code: 170,
  },
  {
    name: "Xã Nghinh Tường",
    code: 5722,
    division_type: "xã",
    codename: "xa_nghinh_tuong",
    district_code: 170,
  },
  {
    name: "Xã Thần Xa",
    code: 5725,
    division_type: "xã",
    codename: "xa_than_xa",
    district_code: 170,
  },
  {
    name: "Xã Vũ Chấn",
    code: 5728,
    division_type: "xã",
    codename: "xa_vu_chan",
    district_code: 170,
  },
  {
    name: "Xã Thượng Nung",
    code: 5731,
    division_type: "xã",
    codename: "xa_thuong_nung",
    district_code: 170,
  },
  {
    name: "Xã Phú Thượng",
    code: 5734,
    division_type: "xã",
    codename: "xa_phu_thuong",
    district_code: 170,
  },
  {
    name: "Xã Cúc Đường",
    code: 5737,
    division_type: "xã",
    codename: "xa_cuc_duong",
    district_code: 170,
  },
  {
    name: "Xã La Hiên",
    code: 5740,
    division_type: "xã",
    codename: "xa_la_hien",
    district_code: 170,
  },
  {
    name: "Xã Lâu Thượng",
    code: 5743,
    division_type: "xã",
    codename: "xa_lau_thuong",
    district_code: 170,
  },
  {
    name: "Xã Tràng Xá",
    code: 5746,
    division_type: "xã",
    codename: "xa_trang_xa",
    district_code: 170,
  },
  {
    name: "Xã Phương Giao",
    code: 5749,
    division_type: "xã",
    codename: "xa_phuong_giao",
    district_code: 170,
  },
  {
    name: "Xã Liên Minh",
    code: 5752,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 170,
  },
  {
    name: "Xã Dân Tiến",
    code: 5755,
    division_type: "xã",
    codename: "xa_dan_tien",
    district_code: 170,
  },
  {
    name: "Xã Bình Long",
    code: 5758,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 170,
  },
  {
    name: "Thị trấn Hùng Sơn",
    code: 5761,
    division_type: "thị trấn",
    codename: "thi_tran_hung_son",
    district_code: 171,
  },
  {
    name: "Thị trấn Quân Chu",
    code: 5764,
    division_type: "thị trấn",
    codename: "thi_tran_quan_chu",
    district_code: 171,
  },
  {
    name: "Xã Phúc Lương",
    code: 5767,
    division_type: "xã",
    codename: "xa_phuc_luong",
    district_code: 171,
  },
  {
    name: "Xã Minh Tiến",
    code: 5770,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 171,
  },
  {
    name: "Xã Yên Lãng",
    code: 5773,
    division_type: "xã",
    codename: "xa_yen_lang",
    district_code: 171,
  },
  {
    name: "Xã Đức Lương",
    code: 5776,
    division_type: "xã",
    codename: "xa_duc_luong",
    district_code: 171,
  },
  {
    name: "Xã Phú Cường",
    code: 5779,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 171,
  },
  {
    name: "Xã Na Mao",
    code: 5782,
    division_type: "xã",
    codename: "xa_na_mao",
    district_code: 171,
  },
  {
    name: "Xã Phú Lạc",
    code: 5785,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 171,
  },
  {
    name: "Xã Tân Linh",
    code: 5788,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 171,
  },
  {
    name: "Xã Phú Thịnh",
    code: 5791,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 171,
  },
  {
    name: "Xã Phục Linh",
    code: 5794,
    division_type: "xã",
    codename: "xa_phuc_linh",
    district_code: 171,
  },
  {
    name: "Xã Phú Xuyên",
    code: 5797,
    division_type: "xã",
    codename: "xa_phu_xuyen",
    district_code: 171,
  },
  {
    name: "Xã Bản Ngoại",
    code: 5800,
    division_type: "xã",
    codename: "xa_ban_ngoai",
    district_code: 171,
  },
  {
    name: "Xã Tiên Hội",
    code: 5803,
    division_type: "xã",
    codename: "xa_tien_hoi",
    district_code: 171,
  },
  {
    name: "Xã Cù Vân",
    code: 5809,
    division_type: "xã",
    codename: "xa_cu_van",
    district_code: 171,
  },
  {
    name: "Xã Hà Thượng",
    code: 5812,
    division_type: "xã",
    codename: "xa_ha_thuong",
    district_code: 171,
  },
  {
    name: "Xã La Bằng",
    code: 5815,
    division_type: "xã",
    codename: "xa_la_bang",
    district_code: 171,
  },
  {
    name: "Xã Hoàng Nông",
    code: 5818,
    division_type: "xã",
    codename: "xa_hoang_nong",
    district_code: 171,
  },
  {
    name: "Xã Khôi Kỳ",
    code: 5821,
    division_type: "xã",
    codename: "xa_khoi_ky",
    district_code: 171,
  },
  {
    name: "Xã An Khánh",
    code: 5824,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 171,
  },
  {
    name: "Xã Tân Thái",
    code: 5827,
    division_type: "xã",
    codename: "xa_tan_thai",
    district_code: 171,
  },
  {
    name: "Xã Bình Thuận",
    code: 5830,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 171,
  },
  {
    name: "Xã Lục Ba",
    code: 5833,
    division_type: "xã",
    codename: "xa_luc_ba",
    district_code: 171,
  },
  {
    name: "Xã Mỹ Yên",
    code: 5836,
    division_type: "xã",
    codename: "xa_my_yen",
    district_code: 171,
  },
  {
    name: "Xã Vạn Thọ",
    code: 5839,
    division_type: "xã",
    codename: "xa_van_tho",
    district_code: 171,
  },
  {
    name: "Xã Văn Yên",
    code: 5842,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 171,
  },
  {
    name: "Xã Ký Phú",
    code: 5845,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 171,
  },
  {
    name: "Xã Cát Nê",
    code: 5848,
    division_type: "xã",
    codename: "xa_cat_ne",
    district_code: 171,
  },
  {
    name: "Xã Quân Chu",
    code: 5851,
    division_type: "xã",
    codename: "xa_quan_chu",
    district_code: 171,
  },
  {
    name: "Phường Bãi Bông",
    code: 5854,
    division_type: "phường",
    codename: "phuong_bai_bong",
    district_code: 172,
  },
  {
    name: "Phường Bắc Sơn",
    code: 5857,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 172,
  },
  {
    name: "Phường Ba Hàng",
    code: 5860,
    division_type: "phường",
    codename: "phuong_ba_hang",
    district_code: 172,
  },
  {
    name: "Xã Phúc Tân",
    code: 5863,
    division_type: "xã",
    codename: "xa_phuc_tan",
    district_code: 172,
  },
  {
    name: "Xã Phúc Thuận",
    code: 5866,
    division_type: "xã",
    codename: "xa_phuc_thuan",
    district_code: 172,
  },
  {
    name: "Xã Hồng Tiến",
    code: 5869,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 172,
  },
  {
    name: "Xã Minh Đức",
    code: 5872,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 172,
  },
  {
    name: "Xã Đắc Sơn",
    code: 5875,
    division_type: "xã",
    codename: "xa_dac_son",
    district_code: 172,
  },
  {
    name: "Phường Đồng Tiến",
    code: 5878,
    division_type: "phường",
    codename: "phuong_dong_tien",
    district_code: 172,
  },
  {
    name: "Xã Thành Công",
    code: 5881,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 172,
  },
  {
    name: "Xã Tiên Phong",
    code: 5884,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 172,
  },
  {
    name: "Xã Vạn Phái",
    code: 5887,
    division_type: "xã",
    codename: "xa_van_phai",
    district_code: 172,
  },
  {
    name: "Xã Nam Tiến",
    code: 5890,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 172,
  },
  {
    name: "Xã Tân Hương",
    code: 5893,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 172,
  },
  {
    name: "Xã Đông Cao",
    code: 5896,
    division_type: "xã",
    codename: "xa_dong_cao",
    district_code: 172,
  },
  {
    name: "Xã Trung Thành",
    code: 5899,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 172,
  },
  {
    name: "Xã Tân Phú",
    code: 5902,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 172,
  },
  {
    name: "Xã Thuận Thành",
    code: 5905,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 172,
  },
  {
    name: "Thị trấn Hương Sơn",
    code: 5908,
    division_type: "thị trấn",
    codename: "thi_tran_huong_son",
    district_code: 173,
  },
  {
    name: "Xã Bàn Đạt",
    code: 5911,
    division_type: "xã",
    codename: "xa_ban_dat",
    district_code: 173,
  },
  {
    name: "Xã Tân Khánh",
    code: 5917,
    division_type: "xã",
    codename: "xa_tan_khanh",
    district_code: 173,
  },
  {
    name: "Xã Tân Kim",
    code: 5920,
    division_type: "xã",
    codename: "xa_tan_kim",
    district_code: 173,
  },
  {
    name: "Xã Tân Thành",
    code: 5923,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 173,
  },
  {
    name: "Xã Đào Xá",
    code: 5926,
    division_type: "xã",
    codename: "xa_dao_xa",
    district_code: 173,
  },
  {
    name: "Xã Bảo Lý",
    code: 5929,
    division_type: "xã",
    codename: "xa_bao_ly",
    district_code: 173,
  },
  {
    name: "Xã Thượng Đình",
    code: 5932,
    division_type: "xã",
    codename: "xa_thuong_dinh",
    district_code: 173,
  },
  {
    name: "Xã Tân Hòa",
    code: 5935,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 173,
  },
  {
    name: "Xã Nhã Lộng",
    code: 5938,
    division_type: "xã",
    codename: "xa_nha_long",
    district_code: 173,
  },
  {
    name: "Xã Điềm Thụy",
    code: 5941,
    division_type: "xã",
    codename: "xa_diem_thuy",
    district_code: 173,
  },
  {
    name: "Xã Xuân Phương",
    code: 5944,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 173,
  },
  {
    name: "Xã Tân Đức",
    code: 5947,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 173,
  },
  {
    name: "Xã Úc Kỳ",
    code: 5950,
    division_type: "xã",
    codename: "xa_uc_ky",
    district_code: 173,
  },
  {
    name: "Xã Lương Phú",
    code: 5953,
    division_type: "xã",
    codename: "xa_luong_phu",
    district_code: 173,
  },
  {
    name: "Xã Nga My",
    code: 5956,
    division_type: "xã",
    codename: "xa_nga_my",
    district_code: 173,
  },
  {
    name: "Xã Kha Sơn",
    code: 5959,
    division_type: "xã",
    codename: "xa_kha_son",
    district_code: 173,
  },
  {
    name: "Xã Thanh Ninh",
    code: 5962,
    division_type: "xã",
    codename: "xa_thanh_ninh",
    district_code: 173,
  },
  {
    name: "Xã Dương Thành",
    code: 5965,
    division_type: "xã",
    codename: "xa_duong_thanh",
    district_code: 173,
  },
  {
    name: "Xã Hà Châu",
    code: 5968,
    division_type: "xã",
    codename: "xa_ha_chau",
    district_code: 173,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 5971,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 178,
  },
  {
    name: "Phường Tam Thanh",
    code: 5974,
    division_type: "phường",
    codename: "phuong_tam_thanh",
    district_code: 178,
  },
  {
    name: "Phường Vĩnh Trại",
    code: 5977,
    division_type: "phường",
    codename: "phuong_vinh_trai",
    district_code: 178,
  },
  {
    name: "Phường Đông Kinh",
    code: 5980,
    division_type: "phường",
    codename: "phuong_dong_kinh",
    district_code: 178,
  },
  {
    name: "Phường Chi Lăng",
    code: 5983,
    division_type: "phường",
    codename: "phuong_chi_lang",
    district_code: 178,
  },
  {
    name: "Xã Hoàng Đồng",
    code: 5986,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 178,
  },
  {
    name: "Xã Quảng Lạc",
    code: 5989,
    division_type: "xã",
    codename: "xa_quang_lac",
    district_code: 178,
  },
  {
    name: "Xã Mai Pha",
    code: 5992,
    division_type: "xã",
    codename: "xa_mai_pha",
    district_code: 178,
  },
  {
    name: "Thị trấn Thất Khê",
    code: 5995,
    division_type: "thị trấn",
    codename: "thi_tran_that_khe",
    district_code: 180,
  },
  {
    name: "Xã Khánh Long",
    code: 5998,
    division_type: "xã",
    codename: "xa_khanh_long",
    district_code: 180,
  },
  {
    name: "Xã Đoàn Kết",
    code: 6001,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 180,
  },
  {
    name: "Xã Quốc Khánh",
    code: 6004,
    division_type: "xã",
    codename: "xa_quoc_khanh",
    district_code: 180,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 6007,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 180,
  },
  {
    name: "Xã Cao Minh",
    code: 6010,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 180,
  },
  {
    name: "Xã Chí Minh",
    code: 6013,
    division_type: "xã",
    codename: "xa_chi_minh",
    district_code: 180,
  },
  {
    name: "Xã Tri Phương",
    code: 6016,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 180,
  },
  {
    name: "Xã Tân Tiến",
    code: 6019,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 180,
  },
  {
    name: "Xã Tân Yên",
    code: 6022,
    division_type: "xã",
    codename: "xa_tan_yen",
    district_code: 180,
  },
  {
    name: "Xã Đội Cấn",
    code: 6025,
    division_type: "xã",
    codename: "xa_doi_can",
    district_code: 180,
  },
  {
    name: "Xã Tân Minh",
    code: 6028,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 180,
  },
  {
    name: "Xã Kim Đồng",
    code: 6031,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 180,
  },
  {
    name: "Xã Chi Lăng",
    code: 6034,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 180,
  },
  {
    name: "Xã Trung Thành",
    code: 6037,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 180,
  },
  {
    name: "Xã Đại Đồng",
    code: 6040,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 180,
  },
  {
    name: "Xã Đào Viên",
    code: 6043,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 180,
  },
  {
    name: "Xã Đề Thám",
    code: 6046,
    division_type: "xã",
    codename: "xa_de_tham",
    district_code: 180,
  },
  {
    name: "Xã Kháng Chiến",
    code: 6049,
    division_type: "xã",
    codename: "xa_khang_chien",
    district_code: 180,
  },
  {
    name: "Xã Hùng Sơn",
    code: 6055,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 180,
  },
  {
    name: "Xã Quốc Việt",
    code: 6058,
    division_type: "xã",
    codename: "xa_quoc_viet",
    district_code: 180,
  },
  {
    name: "Xã Hùng Việt",
    code: 6061,
    division_type: "xã",
    codename: "xa_hung_viet",
    district_code: 180,
  },
  {
    name: "Xã Hưng Đạo",
    code: 6067,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 181,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 6070,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 181,
  },
  {
    name: "Xã Hoa Thám",
    code: 6073,
    division_type: "xã",
    codename: "xa_hoa_tham",
    district_code: 181,
  },
  {
    name: "Xã Quý Hòa",
    code: 6076,
    division_type: "xã",
    codename: "xa_quy_hoa",
    district_code: 181,
  },
  {
    name: "Xã Hồng Phong",
    code: 6079,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 181,
  },
  {
    name: "Xã Yên Lỗ",
    code: 6082,
    division_type: "xã",
    codename: "xa_yen_lo",
    district_code: 181,
  },
  {
    name: "Xã Thiện Hòa",
    code: 6085,
    division_type: "xã",
    codename: "xa_thien_hoa",
    district_code: 181,
  },
  {
    name: "Xã Quang Trung",
    code: 6088,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 181,
  },
  {
    name: "Xã Thiện Thuật",
    code: 6091,
    division_type: "xã",
    codename: "xa_thien_thuat",
    district_code: 181,
  },
  {
    name: "Xã Minh Khai",
    code: 6094,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 181,
  },
  {
    name: "Xã Thiện Long",
    code: 6097,
    division_type: "xã",
    codename: "xa_thien_long",
    district_code: 181,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 6100,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 181,
  },
  {
    name: "Xã Hòa Bình",
    code: 6103,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 181,
  },
  {
    name: "Xã Mông Ân",
    code: 6106,
    division_type: "xã",
    codename: "xa_mong_an",
    district_code: 181,
  },
  {
    name: "Xã Tân Hòa",
    code: 6109,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 181,
  },
  {
    name: "Thị trấn Bình Gia",
    code: 6112,
    division_type: "thị trấn",
    codename: "thi_tran_binh_gia",
    district_code: 181,
  },
  {
    name: "Xã Hồng Thái",
    code: 6115,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 181,
  },
  {
    name: "Xã Bình La",
    code: 6118,
    division_type: "xã",
    codename: "xa_binh_la",
    district_code: 181,
  },
  {
    name: "Xã Tân Văn",
    code: 6121,
    division_type: "xã",
    codename: "xa_tan_van",
    district_code: 181,
  },
  {
    name: "Thị trấn Na Sầm",
    code: 6124,
    division_type: "thị trấn",
    codename: "thi_tran_na_sam",
    district_code: 182,
  },
  {
    name: "Xã Trùng Khánh",
    code: 6127,
    division_type: "xã",
    codename: "xa_trung_khanh",
    district_code: 182,
  },
  {
    name: "Xã Bắc La",
    code: 6133,
    division_type: "xã",
    codename: "xa_bac_la",
    district_code: 182,
  },
  {
    name: "Xã Thụy Hùng",
    code: 6136,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 182,
  },
  {
    name: "Xã Bắc Hùng",
    code: 6139,
    division_type: "xã",
    codename: "xa_bac_hung",
    district_code: 182,
  },
  {
    name: "Xã Tân Tác",
    code: 6142,
    division_type: "xã",
    codename: "xa_tan_tac",
    district_code: 182,
  },
  {
    name: "Xã Thanh Long",
    code: 6148,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 182,
  },
  {
    name: "Xã Hội Hoan",
    code: 6151,
    division_type: "xã",
    codename: "xa_hoi_hoan",
    district_code: 182,
  },
  {
    name: "Xã Bắc Việt",
    code: 6154,
    division_type: "xã",
    codename: "xa_bac_viet",
    district_code: 182,
  },
  {
    name: "Xã Hoàng Việt",
    code: 6157,
    division_type: "xã",
    codename: "xa_hoang_viet",
    district_code: 182,
  },
  {
    name: "Xã Gia Miễn",
    code: 6160,
    division_type: "xã",
    codename: "xa_gia_mien",
    district_code: 182,
  },
  {
    name: "Xã Thành Hòa",
    code: 6163,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 182,
  },
  {
    name: "Xã Tân Thanh",
    code: 6166,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 182,
  },
  {
    name: "Xã Tân Mỹ",
    code: 6172,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 182,
  },
  {
    name: "Xã Hồng Thái",
    code: 6175,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 182,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 6178,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 182,
  },
  {
    name: "Xã Nhạc Kỳ",
    code: 6181,
    division_type: "xã",
    codename: "xa_nhac_ky",
    district_code: 182,
  },
  {
    name: "Thị trấn Đồng Đăng",
    code: 6184,
    division_type: "thị trấn",
    codename: "thi_tran_dong_dang",
    district_code: 183,
  },
  {
    name: "Thị trấn Cao Lộc",
    code: 6187,
    division_type: "thị trấn",
    codename: "thi_tran_cao_loc",
    district_code: 183,
  },
  {
    name: "Xã Bảo Lâm",
    code: 6190,
    division_type: "xã",
    codename: "xa_bao_lam",
    district_code: 183,
  },
  {
    name: "Xã Thanh Lòa",
    code: 6193,
    division_type: "xã",
    codename: "xa_thanh_loa",
    district_code: 183,
  },
  {
    name: "Xã Cao Lâu",
    code: 6196,
    division_type: "xã",
    codename: "xa_cao_lau",
    district_code: 183,
  },
  {
    name: "Xã Thạch Đạn",
    code: 6199,
    division_type: "xã",
    codename: "xa_thach_dan",
    district_code: 183,
  },
  {
    name: "Xã Xuất Lễ",
    code: 6202,
    division_type: "xã",
    codename: "xa_xuat_le",
    district_code: 183,
  },
  {
    name: "Xã Hồng Phong",
    code: 6205,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 183,
  },
  {
    name: "Xã Thụy Hùng",
    code: 6208,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 183,
  },
  {
    name: "Xã Lộc Yên",
    code: 6211,
    division_type: "xã",
    codename: "xa_loc_yen",
    district_code: 183,
  },
  {
    name: "Xã Phú Xá",
    code: 6214,
    division_type: "xã",
    codename: "xa_phu_xa",
    district_code: 183,
  },
  {
    name: "Xã Bình Trung",
    code: 6217,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 183,
  },
  {
    name: "Xã Hải Yến",
    code: 6220,
    division_type: "xã",
    codename: "xa_hai_yen",
    district_code: 183,
  },
  {
    name: "Xã Hòa Cư",
    code: 6223,
    division_type: "xã",
    codename: "xa_hoa_cu",
    district_code: 183,
  },
  {
    name: "Xã Hợp Thành",
    code: 6226,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 183,
  },
  {
    name: "Xã Công Sơn",
    code: 6232,
    division_type: "xã",
    codename: "xa_cong_son",
    district_code: 183,
  },
  {
    name: "Xã Gia Cát",
    code: 6235,
    division_type: "xã",
    codename: "xa_gia_cat",
    district_code: 183,
  },
  {
    name: "Xã Mẫu Sơn",
    code: 6238,
    division_type: "xã",
    codename: "xa_mau_son",
    district_code: 183,
  },
  {
    name: "Xã Xuân Long",
    code: 6241,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 183,
  },
  {
    name: "Xã Tân Liên",
    code: 6244,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 183,
  },
  {
    name: "Xã Yên Trạch",
    code: 6247,
    division_type: "xã",
    codename: "xa_yen_trach",
    district_code: 183,
  },
  {
    name: "Xã Tân Thành",
    code: 6250,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 183,
  },
  {
    name: "Thị trấn Văn Quan",
    code: 6253,
    division_type: "thị trấn",
    codename: "thi_tran_van_quan",
    district_code: 184,
  },
  {
    name: "Xã Trấn Ninh",
    code: 6256,
    division_type: "xã",
    codename: "xa_tran_ninh",
    district_code: 184,
  },
  {
    name: "Xã Liên Hội",
    code: 6268,
    division_type: "xã",
    codename: "xa_lien_hoi",
    district_code: 184,
  },
  {
    name: "Xã Hòa Bình",
    code: 6274,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 184,
  },
  {
    name: "Xã Tú Xuyên",
    code: 6277,
    division_type: "xã",
    codename: "xa_tu_xuyen",
    district_code: 184,
  },
  {
    name: "Xã Điềm He",
    code: 6280,
    division_type: "xã",
    codename: "xa_diem_he",
    district_code: 184,
  },
  {
    name: "Xã An Sơn",
    code: 6283,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 184,
  },
  {
    name: "Xã Khánh Khê",
    code: 6286,
    division_type: "xã",
    codename: "xa_khanh_khe",
    district_code: 184,
  },
  {
    name: "Xã Lương Năng",
    code: 6292,
    division_type: "xã",
    codename: "xa_luong_nang",
    district_code: 184,
  },
  {
    name: "Xã Đồng Giáp",
    code: 6295,
    division_type: "xã",
    codename: "xa_dong_giap",
    district_code: 184,
  },
  {
    name: "Xã Bình Phúc",
    code: 6298,
    division_type: "xã",
    codename: "xa_binh_phuc",
    district_code: 184,
  },
  {
    name: "Xã Tràng Các",
    code: 6301,
    division_type: "xã",
    codename: "xa_trang_cac",
    district_code: 184,
  },
  {
    name: "Xã Tân Đoàn",
    code: 6307,
    division_type: "xã",
    codename: "xa_tan_doan",
    district_code: 184,
  },
  {
    name: "Xã Tri Lễ",
    code: 6313,
    division_type: "xã",
    codename: "xa_tri_le",
    district_code: 184,
  },
  {
    name: "Xã Tràng Phái",
    code: 6316,
    division_type: "xã",
    codename: "xa_trang_phai",
    district_code: 184,
  },
  {
    name: "Xã Yên Phúc",
    code: 6319,
    division_type: "xã",
    codename: "xa_yen_phuc",
    district_code: 184,
  },
  {
    name: "Xã Hữu Lễ",
    code: 6322,
    division_type: "xã",
    codename: "xa_huu_le",
    district_code: 184,
  },
  {
    name: "Thị trấn Bắc Sơn",
    code: 6325,
    division_type: "thị trấn",
    codename: "thi_tran_bac_son",
    district_code: 185,
  },
  {
    name: "Xã Long Đống",
    code: 6328,
    division_type: "xã",
    codename: "xa_long_dong",
    district_code: 185,
  },
  {
    name: "Xã Vạn Thủy",
    code: 6331,
    division_type: "xã",
    codename: "xa_van_thuy",
    district_code: 185,
  },
  {
    name: "Xã Đồng ý",
    code: 6337,
    division_type: "xã",
    codename: "xa_dong_y",
    district_code: 185,
  },
  {
    name: "Xã Tân Tri",
    code: 6340,
    division_type: "xã",
    codename: "xa_tan_tri",
    district_code: 185,
  },
  {
    name: "Xã Bắc Quỳnh",
    code: 6343,
    division_type: "xã",
    codename: "xa_bac_quynh",
    district_code: 185,
  },
  {
    name: "Xã Hưng Vũ",
    code: 6349,
    division_type: "xã",
    codename: "xa_hung_vu",
    district_code: 185,
  },
  {
    name: "Xã Tân Lập",
    code: 6352,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 185,
  },
  {
    name: "Xã Vũ Sơn",
    code: 6355,
    division_type: "xã",
    codename: "xa_vu_son",
    district_code: 185,
  },
  {
    name: "Xã Chiêu Vũ",
    code: 6358,
    division_type: "xã",
    codename: "xa_chieu_vu",
    district_code: 185,
  },
  {
    name: "Xã Tân Hương",
    code: 6361,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 185,
  },
  {
    name: "Xã Chiến Thắng",
    code: 6364,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 185,
  },
  {
    name: "Xã Vũ Lăng",
    code: 6367,
    division_type: "xã",
    codename: "xa_vu_lang",
    district_code: 185,
  },
  {
    name: "Xã Trấn Yên",
    code: 6370,
    division_type: "xã",
    codename: "xa_tran_yen",
    district_code: 185,
  },
  {
    name: "Xã Vũ Lễ",
    code: 6373,
    division_type: "xã",
    codename: "xa_vu_le",
    district_code: 185,
  },
  {
    name: "Xã Nhất Hòa",
    code: 6376,
    division_type: "xã",
    codename: "xa_nhat_hoa",
    district_code: 185,
  },
  {
    name: "Xã Tân Thành",
    code: 6379,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 185,
  },
  {
    name: "Xã Nhất Tiến",
    code: 6382,
    division_type: "xã",
    codename: "xa_nhat_tien",
    district_code: 185,
  },
  {
    name: "Thị trấn Hữu Lũng",
    code: 6385,
    division_type: "thị trấn",
    codename: "thi_tran_huu_lung",
    district_code: 186,
  },
  {
    name: "Xã Hữu Liên",
    code: 6388,
    division_type: "xã",
    codename: "xa_huu_lien",
    district_code: 186,
  },
  {
    name: "Xã Yên Bình",
    code: 6391,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 186,
  },
  {
    name: "Xã Quyết Thắng",
    code: 6394,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 186,
  },
  {
    name: "Xã Hòa Bình",
    code: 6397,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 186,
  },
  {
    name: "Xã Yên Thịnh",
    code: 6400,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 186,
  },
  {
    name: "Xã Yên Sơn",
    code: 6403,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 186,
  },
  {
    name: "Xã Thiện Tân",
    code: 6406,
    division_type: "xã",
    codename: "xa_thien_tan",
    district_code: 186,
  },
  {
    name: "Xã Yên Vượng",
    code: 6412,
    division_type: "xã",
    codename: "xa_yen_vuong",
    district_code: 186,
  },
  {
    name: "Xã Minh Tiến",
    code: 6415,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 186,
  },
  {
    name: "Xã Nhật Tiến",
    code: 6418,
    division_type: "xã",
    codename: "xa_nhat_tien",
    district_code: 186,
  },
  {
    name: "Xã Thanh Sơn",
    code: 6421,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 186,
  },
  {
    name: "Xã Đồng Tân",
    code: 6424,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 186,
  },
  {
    name: "Xã Cai Kinh",
    code: 6427,
    division_type: "xã",
    codename: "xa_cai_kinh",
    district_code: 186,
  },
  {
    name: "Xã Hòa Lạc",
    code: 6430,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 186,
  },
  {
    name: "Xã Vân Nham",
    code: 6433,
    division_type: "xã",
    codename: "xa_van_nham",
    district_code: 186,
  },
  {
    name: "Xã Đồng Tiến",
    code: 6436,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 186,
  },
  {
    name: "Xã Tân Thành",
    code: 6442,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 186,
  },
  {
    name: "Xã Hòa Sơn",
    code: 6445,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 186,
  },
  {
    name: "Xã Minh Sơn",
    code: 6448,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 186,
  },
  {
    name: "Xã Hồ Sơn",
    code: 6451,
    division_type: "xã",
    codename: "xa_ho_son",
    district_code: 186,
  },
  {
    name: "Xã Sơn Hà",
    code: 6454,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 186,
  },
  {
    name: "Xã Minh Hòa",
    code: 6457,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 186,
  },
  {
    name: "Xã Hòa Thắng",
    code: 6460,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 186,
  },
  {
    name: "Thị trấn Đồng Mỏ",
    code: 6463,
    division_type: "thị trấn",
    codename: "thi_tran_dong_mo",
    district_code: 187,
  },
  {
    name: "Thị trấn Chi Lăng",
    code: 6466,
    division_type: "thị trấn",
    codename: "thi_tran_chi_lang",
    district_code: 187,
  },
  {
    name: "Xã Vân An",
    code: 6469,
    division_type: "xã",
    codename: "xa_van_an",
    district_code: 187,
  },
  {
    name: "Xã Vân Thủy",
    code: 6472,
    division_type: "xã",
    codename: "xa_van_thuy",
    district_code: 187,
  },
  {
    name: "Xã Gia Lộc",
    code: 6475,
    division_type: "xã",
    codename: "xa_gia_loc",
    district_code: 187,
  },
  {
    name: "Xã Bắc Thủy",
    code: 6478,
    division_type: "xã",
    codename: "xa_bac_thuy",
    district_code: 187,
  },
  {
    name: "Xã Chiến Thắng",
    code: 6481,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 187,
  },
  {
    name: "Xã Mai Sao",
    code: 6484,
    division_type: "xã",
    codename: "xa_mai_sao",
    district_code: 187,
  },
  {
    name: "Xã Bằng Hữu",
    code: 6487,
    division_type: "xã",
    codename: "xa_bang_huu",
    district_code: 187,
  },
  {
    name: "Xã Thượng Cường",
    code: 6490,
    division_type: "xã",
    codename: "xa_thuong_cuong",
    district_code: 187,
  },
  {
    name: "Xã Bằng Mạc",
    code: 6493,
    division_type: "xã",
    codename: "xa_bang_mac",
    district_code: 187,
  },
  {
    name: "Xã Nhân Lý",
    code: 6496,
    division_type: "xã",
    codename: "xa_nhan_ly",
    district_code: 187,
  },
  {
    name: "Xã Lâm Sơn",
    code: 6499,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 187,
  },
  {
    name: "Xã Liên Sơn",
    code: 6502,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 187,
  },
  {
    name: "Xã Vạn Linh",
    code: 6505,
    division_type: "xã",
    codename: "xa_van_linh",
    district_code: 187,
  },
  {
    name: "Xã Hòa Bình",
    code: 6508,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 187,
  },
  {
    name: "Xã Hữu Kiên",
    code: 6514,
    division_type: "xã",
    codename: "xa_huu_kien",
    district_code: 187,
  },
  {
    name: "Xã Quan Sơn",
    code: 6517,
    division_type: "xã",
    codename: "xa_quan_son",
    district_code: 187,
  },
  {
    name: "Xã Y Tịch",
    code: 6520,
    division_type: "xã",
    codename: "xa_y_tich",
    district_code: 187,
  },
  {
    name: "Xã Chi Lăng",
    code: 6523,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 187,
  },
  {
    name: "Thị trấn Na Dương",
    code: 6526,
    division_type: "thị trấn",
    codename: "thi_tran_na_duong",
    district_code: 188,
  },
  {
    name: "Thị trấn Lộc Bình",
    code: 6529,
    division_type: "thị trấn",
    codename: "thi_tran_loc_binh",
    district_code: 188,
  },
  {
    name: "Xã Mẫu Sơn",
    code: 6532,
    division_type: "xã",
    codename: "xa_mau_son",
    district_code: 188,
  },
  {
    name: "Xã Yên Khoái",
    code: 6541,
    division_type: "xã",
    codename: "xa_yen_khoai",
    district_code: 188,
  },
  {
    name: "Xã Khánh Xuân",
    code: 6544,
    division_type: "xã",
    codename: "xa_khanh_xuan",
    district_code: 188,
  },
  {
    name: "Xã Tú Mịch",
    code: 6547,
    division_type: "xã",
    codename: "xa_tu_mich",
    district_code: 188,
  },
  {
    name: "Xã Hữu Khánh",
    code: 6550,
    division_type: "xã",
    codename: "xa_huu_khanh",
    district_code: 188,
  },
  {
    name: "Xã Đồng Bục",
    code: 6553,
    division_type: "xã",
    codename: "xa_dong_buc",
    district_code: 188,
  },
  {
    name: "Xã Tam Gia",
    code: 6559,
    division_type: "xã",
    codename: "xa_tam_gia",
    district_code: 188,
  },
  {
    name: "Xã Tú Đoạn",
    code: 6562,
    division_type: "xã",
    codename: "xa_tu_doan",
    district_code: 188,
  },
  {
    name: "Xã Khuất Xá",
    code: 6565,
    division_type: "xã",
    codename: "xa_khuat_xa",
    district_code: 188,
  },
  {
    name: "Xã Tĩnh Bắc",
    code: 6574,
    division_type: "xã",
    codename: "xa_tinh_bac",
    district_code: 188,
  },
  {
    name: "Xã Thống Nhất",
    code: 6577,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 188,
  },
  {
    name: "Xã Sàn Viên",
    code: 6589,
    division_type: "xã",
    codename: "xa_san_vien",
    district_code: 188,
  },
  {
    name: "Xã Đông Quan",
    code: 6592,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 188,
  },
  {
    name: "Xã Minh Hiệp",
    code: 6595,
    division_type: "xã",
    codename: "xa_minh_hiep",
    district_code: 188,
  },
  {
    name: "Xã Hữu Lân",
    code: 6598,
    division_type: "xã",
    codename: "xa_huu_lan",
    district_code: 188,
  },
  {
    name: "Xã Lợi Bác",
    code: 6601,
    division_type: "xã",
    codename: "xa_loi_bac",
    district_code: 188,
  },
  {
    name: "Xã Nam Quan",
    code: 6604,
    division_type: "xã",
    codename: "xa_nam_quan",
    district_code: 188,
  },
  {
    name: "Xã Xuân Dương",
    code: 6607,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 188,
  },
  {
    name: "Xã Ái Quốc",
    code: 6610,
    division_type: "xã",
    codename: "xa_ai_quoc",
    district_code: 188,
  },
  {
    name: "Thị trấn Đình Lập",
    code: 6613,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_lap",
    district_code: 189,
  },
  {
    name: "Thị trấn NT Thái Bình",
    code: 6616,
    division_type: "thị trấn",
    codename: "thi_tran_nt_thai_binh",
    district_code: 189,
  },
  {
    name: "Xã Bắc Xa",
    code: 6619,
    division_type: "xã",
    codename: "xa_bac_xa",
    district_code: 189,
  },
  {
    name: "Xã Bính Xá",
    code: 6622,
    division_type: "xã",
    codename: "xa_binh_xa",
    district_code: 189,
  },
  {
    name: "Xã Kiên Mộc",
    code: 6625,
    division_type: "xã",
    codename: "xa_kien_moc",
    district_code: 189,
  },
  {
    name: "Xã Đình Lập",
    code: 6628,
    division_type: "xã",
    codename: "xa_dinh_lap",
    district_code: 189,
  },
  {
    name: "Xã Thái Bình",
    code: 6631,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 189,
  },
  {
    name: "Xã Cường Lợi",
    code: 6634,
    division_type: "xã",
    codename: "xa_cuong_loi",
    district_code: 189,
  },
  {
    name: "Xã Châu Sơn",
    code: 6637,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 189,
  },
  {
    name: "Xã Lâm Ca",
    code: 6640,
    division_type: "xã",
    codename: "xa_lam_ca",
    district_code: 189,
  },
  {
    name: "Xã Đồng Thắng",
    code: 6643,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 189,
  },
  {
    name: "Xã Bắc Lãng",
    code: 6646,
    division_type: "xã",
    codename: "xa_bac_lang",
    district_code: 189,
  },
  {
    name: "Phường Hà Khánh",
    code: 6649,
    division_type: "phường",
    codename: "phuong_ha_khanh",
    district_code: 193,
  },
  {
    name: "Phường Hà Phong",
    code: 6652,
    division_type: "phường",
    codename: "phuong_ha_phong",
    district_code: 193,
  },
  {
    name: "Phường Hà Khẩu",
    code: 6655,
    division_type: "phường",
    codename: "phuong_ha_khau",
    district_code: 193,
  },
  {
    name: "Phường Cao Xanh",
    code: 6658,
    division_type: "phường",
    codename: "phuong_cao_xanh",
    district_code: 193,
  },
  {
    name: "Phường Giếng Đáy",
    code: 6661,
    division_type: "phường",
    codename: "phuong_gieng_day",
    district_code: 193,
  },
  {
    name: "Phường Hà Tu",
    code: 6664,
    division_type: "phường",
    codename: "phuong_ha_tu",
    district_code: 193,
  },
  {
    name: "Phường Hà Trung",
    code: 6667,
    division_type: "phường",
    codename: "phuong_ha_trung",
    district_code: 193,
  },
  {
    name: "Phường Hà Lầm",
    code: 6670,
    division_type: "phường",
    codename: "phuong_ha_lam",
    district_code: 193,
  },
  {
    name: "Phường Bãi Cháy",
    code: 6673,
    division_type: "phường",
    codename: "phuong_bai_chay",
    district_code: 193,
  },
  {
    name: "Phường Cao Thắng",
    code: 6676,
    division_type: "phường",
    codename: "phuong_cao_thang",
    district_code: 193,
  },
  {
    name: "Phường Hùng Thắng",
    code: 6679,
    division_type: "phường",
    codename: "phuong_hung_thang",
    district_code: 193,
  },
  {
    name: "Phường Yết Kiêu",
    code: 6682,
    division_type: "phường",
    codename: "phuong_yet_kieu",
    district_code: 193,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 6685,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 193,
  },
  {
    name: "Phường Hồng Hải",
    code: 6688,
    division_type: "phường",
    codename: "phuong_hong_hai",
    district_code: 193,
  },
  {
    name: "Phường Hồng Gai",
    code: 6691,
    division_type: "phường",
    codename: "phuong_hong_gai",
    district_code: 193,
  },
  {
    name: "Phường Bạch Đằng",
    code: 6694,
    division_type: "phường",
    codename: "phuong_bach_dang",
    district_code: 193,
  },
  {
    name: "Phường Hồng Hà",
    code: 6697,
    division_type: "phường",
    codename: "phuong_hong_ha",
    district_code: 193,
  },
  {
    name: "Phường Tuần Châu",
    code: 6700,
    division_type: "phường",
    codename: "phuong_tuan_chau",
    district_code: 193,
  },
  {
    name: "Phường Việt Hưng",
    code: 6703,
    division_type: "phường",
    codename: "phuong_viet_hung",
    district_code: 193,
  },
  {
    name: "Phường Đại Yên",
    code: 6706,
    division_type: "phường",
    codename: "phuong_dai_yen",
    district_code: 193,
  },
  {
    name: "Phường Hoành Bồ",
    code: 7030,
    division_type: "phường",
    codename: "phuong_hoanh_bo",
    district_code: 193,
  },
  {
    name: "Xã Kỳ Thượng",
    code: 7033,
    division_type: "xã",
    codename: "xa_ky_thuong",
    district_code: 193,
  },
  {
    name: "Xã Đồng Sơn",
    code: 7036,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 193,
  },
  {
    name: "Xã Tân Dân",
    code: 7039,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 193,
  },
  {
    name: "Xã Đồng Lâm",
    code: 7042,
    division_type: "xã",
    codename: "xa_dong_lam",
    district_code: 193,
  },
  {
    name: "Xã Hòa Bình",
    code: 7045,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 193,
  },
  {
    name: "Xã Vũ Oai",
    code: 7048,
    division_type: "xã",
    codename: "xa_vu_oai",
    district_code: 193,
  },
  {
    name: "Xã Dân Chủ",
    code: 7051,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 193,
  },
  {
    name: "Xã Quảng La",
    code: 7054,
    division_type: "xã",
    codename: "xa_quang_la",
    district_code: 193,
  },
  {
    name: "Xã Bằng Cả",
    code: 7057,
    division_type: "xã",
    codename: "xa_bang_ca",
    district_code: 193,
  },
  {
    name: "Xã Thống Nhất",
    code: 7060,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 193,
  },
  {
    name: "Xã Sơn Dương",
    code: 7063,
    division_type: "xã",
    codename: "xa_son_duong",
    district_code: 193,
  },
  {
    name: "Xã Lê Lợi",
    code: 7066,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 193,
  },
  {
    name: "Phường Ka Long",
    code: 6709,
    division_type: "phường",
    codename: "phuong_ka_long",
    district_code: 194,
  },
  {
    name: "Phường Trần Phú",
    code: 6712,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 194,
  },
  {
    name: "Phường Ninh Dương",
    code: 6715,
    division_type: "phường",
    codename: "phuong_ninh_duong",
    district_code: 194,
  },
  {
    name: "Phường Hoà Lạc",
    code: 6718,
    division_type: "phường",
    codename: "phuong_hoa_lac",
    district_code: 194,
  },
  {
    name: "Phường Trà Cổ",
    code: 6721,
    division_type: "phường",
    codename: "phuong_tra_co",
    district_code: 194,
  },
  {
    name: "Xã Hải Sơn",
    code: 6724,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 194,
  },
  {
    name: "Xã Bắc Sơn",
    code: 6727,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 194,
  },
  {
    name: "Xã Hải Đông",
    code: 6730,
    division_type: "xã",
    codename: "xa_hai_dong",
    district_code: 194,
  },
  {
    name: "Xã Hải Tiến",
    code: 6733,
    division_type: "xã",
    codename: "xa_hai_tien",
    district_code: 194,
  },
  {
    name: "Phường Hải Yên",
    code: 6736,
    division_type: "phường",
    codename: "phuong_hai_yen",
    district_code: 194,
  },
  {
    name: "Xã Quảng Nghĩa",
    code: 6739,
    division_type: "xã",
    codename: "xa_quang_nghia",
    district_code: 194,
  },
  {
    name: "Phường Hải Hoà",
    code: 6742,
    division_type: "phường",
    codename: "phuong_hai_hoa",
    district_code: 194,
  },
  {
    name: "Xã Hải Xuân",
    code: 6745,
    division_type: "xã",
    codename: "xa_hai_xuan",
    district_code: 194,
  },
  {
    name: "Xã Vạn Ninh",
    code: 6748,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 194,
  },
  {
    name: "Phường Bình Ngọc",
    code: 6751,
    division_type: "phường",
    codename: "phuong_binh_ngoc",
    district_code: 194,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 6754,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 194,
  },
  {
    name: "Xã Vĩnh Thực",
    code: 6757,
    division_type: "xã",
    codename: "xa_vinh_thuc",
    district_code: 194,
  },
  {
    name: "Phường Mông Dương",
    code: 6760,
    division_type: "phường",
    codename: "phuong_mong_duong",
    district_code: 195,
  },
  {
    name: "Phường Cửa Ông",
    code: 6763,
    division_type: "phường",
    codename: "phuong_cua_ong",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Sơn",
    code: 6766,
    division_type: "phường",
    codename: "phuong_cam_son",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Đông",
    code: 6769,
    division_type: "phường",
    codename: "phuong_cam_dong",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Phú",
    code: 6772,
    division_type: "phường",
    codename: "phuong_cam_phu",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Tây",
    code: 6775,
    division_type: "phường",
    codename: "phuong_cam_tay",
    district_code: 195,
  },
  {
    name: "Phường Quang Hanh",
    code: 6778,
    division_type: "phường",
    codename: "phuong_quang_hanh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thịnh",
    code: 6781,
    division_type: "phường",
    codename: "phuong_cam_thinh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thủy",
    code: 6784,
    division_type: "phường",
    codename: "phuong_cam_thuy",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thạch",
    code: 6787,
    division_type: "phường",
    codename: "phuong_cam_thach",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thành",
    code: 6790,
    division_type: "phường",
    codename: "phuong_cam_thanh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Trung",
    code: 6793,
    division_type: "phường",
    codename: "phuong_cam_trung",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Bình",
    code: 6796,
    division_type: "phường",
    codename: "phuong_cam_binh",
    district_code: 195,
  },
  {
    name: "Xã Cộng Hòa",
    code: 6799,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 195,
  },
  {
    name: "Xã Cẩm Hải",
    code: 6802,
    division_type: "xã",
    codename: "xa_cam_hai",
    district_code: 195,
  },
  {
    name: "Xã Dương Huy",
    code: 6805,
    division_type: "xã",
    codename: "xa_duong_huy",
    district_code: 195,
  },
  {
    name: "Phường Vàng Danh",
    code: 6808,
    division_type: "phường",
    codename: "phuong_vang_danh",
    district_code: 196,
  },
  {
    name: "Phường Thanh Sơn",
    code: 6811,
    division_type: "phường",
    codename: "phuong_thanh_son",
    district_code: 196,
  },
  {
    name: "Phường Bắc Sơn",
    code: 6814,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 196,
  },
  {
    name: "Phường Quang Trung",
    code: 6817,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 196,
  },
  {
    name: "Phường Trưng Vương",
    code: 6820,
    division_type: "phường",
    codename: "phuong_trung_vuong",
    district_code: 196,
  },
  {
    name: "Phường Nam Khê",
    code: 6823,
    division_type: "phường",
    codename: "phuong_nam_khe",
    district_code: 196,
  },
  {
    name: "Phường Yên Thanh",
    code: 6826,
    division_type: "phường",
    codename: "phuong_yen_thanh",
    district_code: 196,
  },
  {
    name: "Xã Thượng Yên Công",
    code: 6829,
    division_type: "xã",
    codename: "xa_thuong_yen_cong",
    district_code: 196,
  },
  {
    name: "Phường Phương Đông",
    code: 6832,
    division_type: "phường",
    codename: "phuong_phuong_dong",
    district_code: 196,
  },
  {
    name: "Phường Phương Nam",
    code: 6835,
    division_type: "phường",
    codename: "phuong_phuong_nam",
    district_code: 196,
  },
  {
    name: "Thị trấn Bình Liêu",
    code: 6838,
    division_type: "thị trấn",
    codename: "thi_tran_binh_lieu",
    district_code: 198,
  },
  {
    name: "Xã Hoành Mô",
    code: 6841,
    division_type: "xã",
    codename: "xa_hoanh_mo",
    district_code: 198,
  },
  {
    name: "Xã Đồng Tâm",
    code: 6844,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 198,
  },
  {
    name: "Xã Đồng Văn",
    code: 6847,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 198,
  },
  {
    name: "Xã Vô Ngại",
    code: 6853,
    division_type: "xã",
    codename: "xa_vo_ngai",
    district_code: 198,
  },
  {
    name: "Xã Lục Hồn",
    code: 6856,
    division_type: "xã",
    codename: "xa_luc_hon",
    district_code: 198,
  },
  {
    name: "Xã Húc Động",
    code: 6859,
    division_type: "xã",
    codename: "xa_huc_dong",
    district_code: 198,
  },
  {
    name: "Thị trấn Tiên Yên",
    code: 6862,
    division_type: "thị trấn",
    codename: "thi_tran_tien_yen",
    district_code: 199,
  },
  {
    name: "Xã Hà Lâu",
    code: 6865,
    division_type: "xã",
    codename: "xa_ha_lau",
    district_code: 199,
  },
  {
    name: "Xã Đại Dực",
    code: 6868,
    division_type: "xã",
    codename: "xa_dai_duc",
    district_code: 199,
  },
  {
    name: "Xã Phong Dụ",
    code: 6871,
    division_type: "xã",
    codename: "xa_phong_du",
    district_code: 199,
  },
  {
    name: "Xã Điền Xá",
    code: 6874,
    division_type: "xã",
    codename: "xa_dien_xa",
    district_code: 199,
  },
  {
    name: "Xã Đông Ngũ",
    code: 6877,
    division_type: "xã",
    codename: "xa_dong_ngu",
    district_code: 199,
  },
  {
    name: "Xã Yên Than",
    code: 6880,
    division_type: "xã",
    codename: "xa_yen_than",
    district_code: 199,
  },
  {
    name: "Xã Đông Hải",
    code: 6883,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 199,
  },
  {
    name: "Xã Hải Lạng",
    code: 6886,
    division_type: "xã",
    codename: "xa_hai_lang",
    district_code: 199,
  },
  {
    name: "Xã Tiên Lãng",
    code: 6889,
    division_type: "xã",
    codename: "xa_tien_lang",
    district_code: 199,
  },
  {
    name: "Xã Đồng Rui",
    code: 6892,
    division_type: "xã",
    codename: "xa_dong_rui",
    district_code: 199,
  },
  {
    name: "Thị trấn Đầm Hà",
    code: 6895,
    division_type: "thị trấn",
    codename: "thi_tran_dam_ha",
    district_code: 200,
  },
  {
    name: "Xã Quảng Lâm",
    code: 6898,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 200,
  },
  {
    name: "Xã Quảng An",
    code: 6901,
    division_type: "xã",
    codename: "xa_quang_an",
    district_code: 200,
  },
  {
    name: "Xã Tân Bình",
    code: 6904,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 200,
  },
  {
    name: "Xã Dực Yên",
    code: 6910,
    division_type: "xã",
    codename: "xa_duc_yen",
    district_code: 200,
  },
  {
    name: "Xã Quảng Tân",
    code: 6913,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 200,
  },
  {
    name: "Xã Đầm Hà",
    code: 6916,
    division_type: "xã",
    codename: "xa_dam_ha",
    district_code: 200,
  },
  {
    name: "Xã Tân Lập",
    code: 6917,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 200,
  },
  {
    name: "Xã Đại Bình",
    code: 6919,
    division_type: "xã",
    codename: "xa_dai_binh",
    district_code: 200,
  },
  {
    name: "Thị trấn Quảng Hà",
    code: 6922,
    division_type: "thị trấn",
    codename: "thi_tran_quang_ha",
    district_code: 201,
  },
  {
    name: "Xã Quảng Đức",
    code: 6925,
    division_type: "xã",
    codename: "xa_quang_duc",
    district_code: 201,
  },
  {
    name: "Xã Quảng Sơn",
    code: 6928,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 201,
  },
  {
    name: "Xã Quảng Thành",
    code: 6931,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Thịnh",
    code: 6937,
    division_type: "xã",
    codename: "xa_quang_thinh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Minh",
    code: 6940,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Chính",
    code: 6943,
    division_type: "xã",
    codename: "xa_quang_chinh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Long",
    code: 6946,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 201,
  },
  {
    name: "Xã Đường Hoa",
    code: 6949,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 201,
  },
  {
    name: "Xã Quảng Phong",
    code: 6952,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 201,
  },
  {
    name: "Xã Cái Chiên",
    code: 6967,
    division_type: "xã",
    codename: "xa_cai_chien",
    district_code: 201,
  },
  {
    name: "Thị trấn Ba Chẽ",
    code: 6970,
    division_type: "thị trấn",
    codename: "thi_tran_ba_che",
    district_code: 202,
  },
  {
    name: "Xã Thanh Sơn",
    code: 6973,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 202,
  },
  {
    name: "Xã Thanh Lâm",
    code: 6976,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 202,
  },
  {
    name: "Xã Đạp Thanh",
    code: 6979,
    division_type: "xã",
    codename: "xa_dap_thanh",
    district_code: 202,
  },
  {
    name: "Xã Nam Sơn",
    code: 6982,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 202,
  },
  {
    name: "Xã Lương Mông",
    code: 6985,
    division_type: "xã",
    codename: "xa_luong_mong",
    district_code: 202,
  },
  {
    name: "Xã Đồn Đạc",
    code: 6988,
    division_type: "xã",
    codename: "xa_don_dac",
    district_code: 202,
  },
  {
    name: "Xã Minh Cầm",
    code: 6991,
    division_type: "xã",
    codename: "xa_minh_cam",
    district_code: 202,
  },
  {
    name: "Thị trấn Cái Rồng",
    code: 6994,
    division_type: "thị trấn",
    codename: "thi_tran_cai_rong",
    district_code: 203,
  },
  {
    name: "Xã Đài Xuyên",
    code: 6997,
    division_type: "xã",
    codename: "xa_dai_xuyen",
    district_code: 203,
  },
  {
    name: "Xã Bình Dân",
    code: 7000,
    division_type: "xã",
    codename: "xa_binh_dan",
    district_code: 203,
  },
  {
    name: "Xã Vạn Yên",
    code: 7003,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 203,
  },
  {
    name: "Xã Minh Châu",
    code: 7006,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 203,
  },
  {
    name: "Xã Đoàn Kết",
    code: 7009,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 203,
  },
  {
    name: "Xã Hạ Long",
    code: 7012,
    division_type: "xã",
    codename: "xa_ha_long",
    district_code: 203,
  },
  {
    name: "Xã Đông Xá",
    code: 7015,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 203,
  },
  {
    name: "Xã Bản Sen",
    code: 7018,
    division_type: "xã",
    codename: "xa_ban_sen",
    district_code: 203,
  },
  {
    name: "Xã Thắng Lợi",
    code: 7021,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 203,
  },
  {
    name: "Xã Quan Lạn",
    code: 7024,
    division_type: "xã",
    codename: "xa_quan_lan",
    district_code: 203,
  },
  {
    name: "Xã Ngọc Vừng",
    code: 7027,
    division_type: "xã",
    codename: "xa_ngoc_vung",
    district_code: 203,
  },
  {
    name: "Phường Mạo Khê",
    code: 7069,
    division_type: "phường",
    codename: "phuong_mao_khe",
    district_code: 205,
  },
  {
    name: "Phường Đông Triều",
    code: 7072,
    division_type: "phường",
    codename: "phuong_dong_trieu",
    district_code: 205,
  },
  {
    name: "Xã An Sinh",
    code: 7075,
    division_type: "xã",
    codename: "xa_an_sinh",
    district_code: 205,
  },
  {
    name: "Xã Tràng Lương",
    code: 7078,
    division_type: "xã",
    codename: "xa_trang_luong",
    district_code: 205,
  },
  {
    name: "Xã Bình Khê",
    code: 7081,
    division_type: "xã",
    codename: "xa_binh_khe",
    district_code: 205,
  },
  {
    name: "Xã Việt Dân",
    code: 7084,
    division_type: "xã",
    codename: "xa_viet_dan",
    district_code: 205,
  },
  {
    name: "Xã Tân Việt",
    code: 7087,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 205,
  },
  {
    name: "Xã Bình Dương",
    code: 7090,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 205,
  },
  {
    name: "Phường Đức Chính",
    code: 7093,
    division_type: "phường",
    codename: "phuong_duc_chinh",
    district_code: 205,
  },
  {
    name: "Phường Tràng An",
    code: 7096,
    division_type: "phường",
    codename: "phuong_trang_an",
    district_code: 205,
  },
  {
    name: "Xã Nguyễn Huệ",
    code: 7099,
    division_type: "xã",
    codename: "xa_nguyen_hue",
    district_code: 205,
  },
  {
    name: "Xã Thủy An",
    code: 7102,
    division_type: "xã",
    codename: "xa_thuy_an",
    district_code: 205,
  },
  {
    name: "Phường Xuân Sơn",
    code: 7105,
    division_type: "phường",
    codename: "phuong_xuan_son",
    district_code: 205,
  },
  {
    name: "Xã Hồng Thái Tây",
    code: 7108,
    division_type: "xã",
    codename: "xa_hong_thai_tay",
    district_code: 205,
  },
  {
    name: "Xã Hồng Thái Đông",
    code: 7111,
    division_type: "xã",
    codename: "xa_hong_thai_dong",
    district_code: 205,
  },
  {
    name: "Phường Hoàng Quế",
    code: 7114,
    division_type: "phường",
    codename: "phuong_hoang_que",
    district_code: 205,
  },
  {
    name: "Phường Yên Thọ",
    code: 7117,
    division_type: "phường",
    codename: "phuong_yen_tho",
    district_code: 205,
  },
  {
    name: "Phường Hồng Phong",
    code: 7120,
    division_type: "phường",
    codename: "phuong_hong_phong",
    district_code: 205,
  },
  {
    name: "Phường Kim Sơn",
    code: 7123,
    division_type: "phường",
    codename: "phuong_kim_son",
    district_code: 205,
  },
  {
    name: "Phường Hưng Đạo",
    code: 7126,
    division_type: "phường",
    codename: "phuong_hung_dao",
    district_code: 205,
  },
  {
    name: "Xã Yên Đức",
    code: 7129,
    division_type: "xã",
    codename: "xa_yen_duc",
    district_code: 205,
  },
  {
    name: "Phường Quảng Yên",
    code: 7132,
    division_type: "phường",
    codename: "phuong_quang_yen",
    district_code: 206,
  },
  {
    name: "Phường Đông Mai",
    code: 7135,
    division_type: "phường",
    codename: "phuong_dong_mai",
    district_code: 206,
  },
  {
    name: "Phường Minh Thành",
    code: 7138,
    division_type: "phường",
    codename: "phuong_minh_thanh",
    district_code: 206,
  },
  {
    name: "Xã Sông Khoai",
    code: 7144,
    division_type: "xã",
    codename: "xa_song_khoai",
    district_code: 206,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 7147,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 206,
  },
  {
    name: "Phường Cộng Hòa",
    code: 7150,
    division_type: "phường",
    codename: "phuong_cong_hoa",
    district_code: 206,
  },
  {
    name: "Xã Tiền An",
    code: 7153,
    division_type: "xã",
    codename: "xa_tien_an",
    district_code: 206,
  },
  {
    name: "Xã Hoàng Tân",
    code: 7156,
    division_type: "xã",
    codename: "xa_hoang_tan",
    district_code: 206,
  },
  {
    name: "Phường Tân An",
    code: 7159,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 206,
  },
  {
    name: "Phường Yên Giang",
    code: 7162,
    division_type: "phường",
    codename: "phuong_yen_giang",
    district_code: 206,
  },
  {
    name: "Phường Nam Hoà",
    code: 7165,
    division_type: "phường",
    codename: "phuong_nam_hoa",
    district_code: 206,
  },
  {
    name: "Phường Hà An",
    code: 7168,
    division_type: "phường",
    codename: "phuong_ha_an",
    district_code: 206,
  },
  {
    name: "Xã Cẩm La",
    code: 7171,
    division_type: "xã",
    codename: "xa_cam_la",
    district_code: 206,
  },
  {
    name: "Phường Phong Hải",
    code: 7174,
    division_type: "phường",
    codename: "phuong_phong_hai",
    district_code: 206,
  },
  {
    name: "Phường Yên Hải",
    code: 7177,
    division_type: "phường",
    codename: "phuong_yen_hai",
    district_code: 206,
  },
  {
    name: "Xã Liên Hòa",
    code: 7180,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 206,
  },
  {
    name: "Phường Phong Cốc",
    code: 7183,
    division_type: "phường",
    codename: "phuong_phong_coc",
    district_code: 206,
  },
  {
    name: "Xã Liên Vị",
    code: 7186,
    division_type: "xã",
    codename: "xa_lien_vi",
    district_code: 206,
  },
  {
    name: "Xã Tiền Phong",
    code: 7189,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 206,
  },
  {
    name: "Thị trấn Cô Tô",
    code: 7192,
    division_type: "thị trấn",
    codename: "thi_tran_co_to",
    district_code: 207,
  },
  {
    name: "Xã Đồng Tiến",
    code: 7195,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 207,
  },
  {
    name: "Xã Thanh Lân",
    code: 7198,
    division_type: "xã",
    codename: "xa_thanh_lan",
    district_code: 207,
  },
  {
    name: "Phường Thọ Xương",
    code: 7201,
    division_type: "phường",
    codename: "phuong_tho_xuong",
    district_code: 213,
  },
  {
    name: "Phường Trần Nguyên Hãn",
    code: 7204,
    division_type: "phường",
    codename: "phuong_tran_nguyen_han",
    district_code: 213,
  },
  {
    name: "Phường Ngô Quyền",
    code: 7207,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 213,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 7210,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 213,
  },
  {
    name: "Phường Trần Phú",
    code: 7213,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 213,
  },
  {
    name: "Phường Mỹ Độ",
    code: 7216,
    division_type: "phường",
    codename: "phuong_my_do",
    district_code: 213,
  },
  {
    name: "Phường Lê Lợi",
    code: 7219,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 213,
  },
  {
    name: "Xã Song Mai",
    code: 7222,
    division_type: "xã",
    codename: "xa_song_mai",
    district_code: 213,
  },
  {
    name: "Phường Xương Giang",
    code: 7225,
    division_type: "phường",
    codename: "phuong_xuong_giang",
    district_code: 213,
  },
  {
    name: "Phường Đa Mai",
    code: 7228,
    division_type: "phường",
    codename: "phuong_da_mai",
    district_code: 213,
  },
  {
    name: "Phường Dĩnh Kế",
    code: 7231,
    division_type: "phường",
    codename: "phuong_dinh_ke",
    district_code: 213,
  },
  {
    name: "Xã Dĩnh Trì",
    code: 7441,
    division_type: "xã",
    codename: "xa_dinh_tri",
    district_code: 213,
  },
  {
    name: "Xã Tân Mỹ",
    code: 7687,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 213,
  },
  {
    name: "Xã Đồng Sơn",
    code: 7696,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 213,
  },
  {
    name: "Xã Tân Tiến",
    code: 7699,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 213,
  },
  {
    name: "Xã Song Khê",
    code: 7705,
    division_type: "xã",
    codename: "xa_song_khe",
    district_code: 213,
  },
  {
    name: "Xã Đồng Tiến",
    code: 7243,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 215,
  },
  {
    name: "Xã Canh Nậu",
    code: 7246,
    division_type: "xã",
    codename: "xa_canh_nau",
    district_code: 215,
  },
  {
    name: "Xã Xuân Lương",
    code: 7249,
    division_type: "xã",
    codename: "xa_xuan_luong",
    district_code: 215,
  },
  {
    name: "Xã Tam Tiến",
    code: 7252,
    division_type: "xã",
    codename: "xa_tam_tien",
    district_code: 215,
  },
  {
    name: "Xã Đồng Vương",
    code: 7255,
    division_type: "xã",
    codename: "xa_dong_vuong",
    district_code: 215,
  },
  {
    name: "Xã Đồng Hưu",
    code: 7258,
    division_type: "xã",
    codename: "xa_dong_huu",
    district_code: 215,
  },
  {
    name: "Xã Đồng Tâm",
    code: 7260,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 215,
  },
  {
    name: "Xã Tam Hiệp",
    code: 7261,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 215,
  },
  {
    name: "Xã Tiến Thắng",
    code: 7264,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 215,
  },
  {
    name: "Xã Hồng Kỳ",
    code: 7267,
    division_type: "xã",
    codename: "xa_hong_ky",
    district_code: 215,
  },
  {
    name: "Xã Đồng Lạc",
    code: 7270,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 215,
  },
  {
    name: "Xã Đông Sơn",
    code: 7273,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 215,
  },
  {
    name: "Xã Tân Hiệp",
    code: 7276,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 215,
  },
  {
    name: "Xã Hương Vĩ",
    code: 7279,
    division_type: "xã",
    codename: "xa_huong_vi",
    district_code: 215,
  },
  {
    name: "Xã Đồng Kỳ",
    code: 7282,
    division_type: "xã",
    codename: "xa_dong_ky",
    district_code: 215,
  },
  {
    name: "Xã An Thượng",
    code: 7285,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 215,
  },
  {
    name: "Thị trấn Phồn Xương",
    code: 7288,
    division_type: "thị trấn",
    codename: "thi_tran_phon_xuong",
    district_code: 215,
  },
  {
    name: "Xã Tân Sỏi",
    code: 7291,
    division_type: "xã",
    codename: "xa_tan_soi",
    district_code: 215,
  },
  {
    name: "Thị trấn Bố Hạ",
    code: 7294,
    division_type: "thị trấn",
    codename: "thi_tran_bo_ha",
    district_code: 215,
  },
  {
    name: "Xã Lan Giới",
    code: 7303,
    division_type: "xã",
    codename: "xa_lan_gioi",
    district_code: 216,
  },
  {
    name: "Thị trấn Nhã Nam",
    code: 7306,
    division_type: "thị trấn",
    codename: "thi_tran_nha_nam",
    district_code: 216,
  },
  {
    name: "Xã Tân Trung",
    code: 7309,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 216,
  },
  {
    name: "Xã Đại Hóa",
    code: 7312,
    division_type: "xã",
    codename: "xa_dai_hoa",
    district_code: 216,
  },
  {
    name: "Xã Quang Tiến",
    code: 7315,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 216,
  },
  {
    name: "Xã Phúc Sơn",
    code: 7318,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 216,
  },
  {
    name: "Xã An Dương",
    code: 7321,
    division_type: "xã",
    codename: "xa_an_duong",
    district_code: 216,
  },
  {
    name: "Xã Phúc Hòa",
    code: 7324,
    division_type: "xã",
    codename: "xa_phuc_hoa",
    district_code: 216,
  },
  {
    name: "Xã Liên Sơn",
    code: 7327,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 216,
  },
  {
    name: "Xã Hợp Đức",
    code: 7330,
    division_type: "xã",
    codename: "xa_hop_duc",
    district_code: 216,
  },
  {
    name: "Xã Lam Cốt",
    code: 7333,
    division_type: "xã",
    codename: "xa_lam_cot",
    district_code: 216,
  },
  {
    name: "Xã Cao Xá",
    code: 7336,
    division_type: "xã",
    codename: "xa_cao_xa",
    district_code: 216,
  },
  {
    name: "Thị trấn Cao Thượng",
    code: 7339,
    division_type: "thị trấn",
    codename: "thi_tran_cao_thuong",
    district_code: 216,
  },
  {
    name: "Xã Việt Ngọc",
    code: 7342,
    division_type: "xã",
    codename: "xa_viet_ngoc",
    district_code: 216,
  },
  {
    name: "Xã Song Vân",
    code: 7345,
    division_type: "xã",
    codename: "xa_song_van",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Châu",
    code: 7348,
    division_type: "xã",
    codename: "xa_ngoc_chau",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Vân",
    code: 7351,
    division_type: "xã",
    codename: "xa_ngoc_van",
    district_code: 216,
  },
  {
    name: "Xã Việt Lập",
    code: 7354,
    division_type: "xã",
    codename: "xa_viet_lap",
    district_code: 216,
  },
  {
    name: "Xã Liên Chung",
    code: 7357,
    division_type: "xã",
    codename: "xa_lien_chung",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Thiện",
    code: 7360,
    division_type: "xã",
    codename: "xa_ngoc_thien",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Lý",
    code: 7363,
    division_type: "xã",
    codename: "xa_ngoc_ly",
    district_code: 216,
  },
  {
    name: "Xã Quế Nham",
    code: 7366,
    division_type: "xã",
    codename: "xa_que_nham",
    district_code: 216,
  },
  {
    name: "Thị trấn Vôi",
    code: 7375,
    division_type: "thị trấn",
    codename: "thi_tran_voi",
    district_code: 217,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 7378,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 217,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 7381,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 217,
  },
  {
    name: "Xã Quang Thịnh",
    code: 7384,
    division_type: "xã",
    codename: "xa_quang_thinh",
    district_code: 217,
  },
  {
    name: "Xã Hương Sơn",
    code: 7387,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 217,
  },
  {
    name: "Xã Đào Mỹ",
    code: 7390,
    division_type: "xã",
    codename: "xa_dao_my",
    district_code: 217,
  },
  {
    name: "Xã Tiên Lục",
    code: 7393,
    division_type: "xã",
    codename: "xa_tien_luc",
    district_code: 217,
  },
  {
    name: "Xã An Hà",
    code: 7396,
    division_type: "xã",
    codename: "xa_an_ha",
    district_code: 217,
  },
  {
    name: "Thị trấn Kép",
    code: 7399,
    division_type: "thị trấn",
    codename: "thi_tran_kep",
    district_code: 217,
  },
  {
    name: "Xã Mỹ Hà",
    code: 7402,
    division_type: "xã",
    codename: "xa_my_ha",
    district_code: 217,
  },
  {
    name: "Xã Hương Lạc",
    code: 7405,
    division_type: "xã",
    codename: "xa_huong_lac",
    district_code: 217,
  },
  {
    name: "Xã Dương Đức",
    code: 7408,
    division_type: "xã",
    codename: "xa_duong_duc",
    district_code: 217,
  },
  {
    name: "Xã Tân Thanh",
    code: 7411,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 217,
  },
  {
    name: "Xã Yên Mỹ",
    code: 7414,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 217,
  },
  {
    name: "Xã Tân Hưng",
    code: 7417,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 217,
  },
  {
    name: "Xã Mỹ Thái",
    code: 7420,
    division_type: "xã",
    codename: "xa_my_thai",
    district_code: 217,
  },
  {
    name: "Xã Xương Lâm",
    code: 7426,
    division_type: "xã",
    codename: "xa_xuong_lam",
    district_code: 217,
  },
  {
    name: "Xã Xuân Hương",
    code: 7429,
    division_type: "xã",
    codename: "xa_xuan_huong",
    district_code: 217,
  },
  {
    name: "Xã Tân Dĩnh",
    code: 7432,
    division_type: "xã",
    codename: "xa_tan_dinh",
    district_code: 217,
  },
  {
    name: "Xã Đại Lâm",
    code: 7435,
    division_type: "xã",
    codename: "xa_dai_lam",
    district_code: 217,
  },
  {
    name: "Xã Thái Đào",
    code: 7438,
    division_type: "xã",
    codename: "xa_thai_dao",
    district_code: 217,
  },
  {
    name: "Thị trấn Đồi Ngô",
    code: 7444,
    division_type: "thị trấn",
    codename: "thi_tran_doi_ngo",
    district_code: 218,
  },
  {
    name: "Xã Đông Hưng",
    code: 7450,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 218,
  },
  {
    name: "Xã Đông Phú",
    code: 7453,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 218,
  },
  {
    name: "Xã Tam Dị",
    code: 7456,
    division_type: "xã",
    codename: "xa_tam_di",
    district_code: 218,
  },
  {
    name: "Xã Bảo Sơn",
    code: 7459,
    division_type: "xã",
    codename: "xa_bao_son",
    district_code: 218,
  },
  {
    name: "Xã Bảo Đài",
    code: 7462,
    division_type: "xã",
    codename: "xa_bao_dai",
    district_code: 218,
  },
  {
    name: "Xã Thanh Lâm",
    code: 7465,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 218,
  },
  {
    name: "Xã Tiên Nha",
    code: 7468,
    division_type: "xã",
    codename: "xa_tien_nha",
    district_code: 218,
  },
  {
    name: "Xã Trường Giang",
    code: 7471,
    division_type: "xã",
    codename: "xa_truong_giang",
    district_code: 218,
  },
  {
    name: "Xã Phương Sơn",
    code: 7477,
    division_type: "xã",
    codename: "xa_phuong_son",
    district_code: 218,
  },
  {
    name: "Xã Chu Điện",
    code: 7480,
    division_type: "xã",
    codename: "xa_chu_dien",
    district_code: 218,
  },
  {
    name: "Xã Cương Sơn",
    code: 7483,
    division_type: "xã",
    codename: "xa_cuong_son",
    district_code: 218,
  },
  {
    name: "Xã Nghĩa Phương",
    code: 7486,
    division_type: "xã",
    codename: "xa_nghia_phuong",
    district_code: 218,
  },
  {
    name: "Xã Vô Tranh",
    code: 7489,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 218,
  },
  {
    name: "Xã Bình Sơn",
    code: 7492,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 218,
  },
  {
    name: "Xã Lan Mẫu",
    code: 7495,
    division_type: "xã",
    codename: "xa_lan_mau",
    district_code: 218,
  },
  {
    name: "Xã Yên Sơn",
    code: 7498,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 218,
  },
  {
    name: "Xã Khám Lạng",
    code: 7501,
    division_type: "xã",
    codename: "xa_kham_lang",
    district_code: 218,
  },
  {
    name: "Xã Huyền Sơn",
    code: 7504,
    division_type: "xã",
    codename: "xa_huyen_son",
    district_code: 218,
  },
  {
    name: "Xã Trường Sơn",
    code: 7507,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 218,
  },
  {
    name: "Xã Lục Sơn",
    code: 7510,
    division_type: "xã",
    codename: "xa_luc_son",
    district_code: 218,
  },
  {
    name: "Xã Bắc Lũng",
    code: 7513,
    division_type: "xã",
    codename: "xa_bac_lung",
    district_code: 218,
  },
  {
    name: "Xã Vũ Xá",
    code: 7516,
    division_type: "xã",
    codename: "xa_vu_xa",
    district_code: 218,
  },
  {
    name: "Xã Cẩm Lý",
    code: 7519,
    division_type: "xã",
    codename: "xa_cam_ly",
    district_code: 218,
  },
  {
    name: "Xã Đan Hội",
    code: 7522,
    division_type: "xã",
    codename: "xa_dan_hoi",
    district_code: 218,
  },
  {
    name: "Thị trấn Chũ",
    code: 7525,
    division_type: "thị trấn",
    codename: "thi_tran_chu",
    district_code: 219,
  },
  {
    name: "Xã Cấm Sơn",
    code: 7528,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 219,
  },
  {
    name: "Xã Tân Sơn",
    code: 7531,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 219,
  },
  {
    name: "Xã Phong Minh",
    code: 7534,
    division_type: "xã",
    codename: "xa_phong_minh",
    district_code: 219,
  },
  {
    name: "Xã Phong Vân",
    code: 7537,
    division_type: "xã",
    codename: "xa_phong_van",
    district_code: 219,
  },
  {
    name: "Xã Xa Lý",
    code: 7540,
    division_type: "xã",
    codename: "xa_xa_ly",
    district_code: 219,
  },
  {
    name: "Xã Hộ Đáp",
    code: 7543,
    division_type: "xã",
    codename: "xa_ho_dap",
    district_code: 219,
  },
  {
    name: "Xã Sơn Hải",
    code: 7546,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 219,
  },
  {
    name: "Xã Thanh Hải",
    code: 7549,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 219,
  },
  {
    name: "Xã Kiên Lao",
    code: 7552,
    division_type: "xã",
    codename: "xa_kien_lao",
    district_code: 219,
  },
  {
    name: "Xã Biên Sơn",
    code: 7555,
    division_type: "xã",
    codename: "xa_bien_son",
    district_code: 219,
  },
  {
    name: "Xã Kiên Thành",
    code: 7558,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 219,
  },
  {
    name: "Xã Hồng Giang",
    code: 7561,
    division_type: "xã",
    codename: "xa_hong_giang",
    district_code: 219,
  },
  {
    name: "Xã Kim Sơn",
    code: 7564,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 219,
  },
  {
    name: "Xã Tân Hoa",
    code: 7567,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 219,
  },
  {
    name: "Xã Giáp Sơn",
    code: 7570,
    division_type: "xã",
    codename: "xa_giap_son",
    district_code: 219,
  },
  {
    name: "Xã Biển Động",
    code: 7573,
    division_type: "xã",
    codename: "xa_bien_dong",
    district_code: 219,
  },
  {
    name: "Xã Quý Sơn",
    code: 7576,
    division_type: "xã",
    codename: "xa_quy_son",
    district_code: 219,
  },
  {
    name: "Xã Trù Hựu",
    code: 7579,
    division_type: "xã",
    codename: "xa_tru_huu",
    district_code: 219,
  },
  {
    name: "Xã Phì Điền",
    code: 7582,
    division_type: "xã",
    codename: "xa_phi_dien",
    district_code: 219,
  },
  {
    name: "Xã Tân Quang",
    code: 7588,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 219,
  },
  {
    name: "Xã Đồng Cốc",
    code: 7591,
    division_type: "xã",
    codename: "xa_dong_coc",
    district_code: 219,
  },
  {
    name: "Xã Tân Lập",
    code: 7594,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 219,
  },
  {
    name: "Xã Phú Nhuận",
    code: 7597,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 219,
  },
  {
    name: "Xã Mỹ An",
    code: 7600,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 219,
  },
  {
    name: "Xã Nam Dương",
    code: 7603,
    division_type: "xã",
    codename: "xa_nam_duong",
    district_code: 219,
  },
  {
    name: "Xã Tân Mộc",
    code: 7606,
    division_type: "xã",
    codename: "xa_tan_moc",
    district_code: 219,
  },
  {
    name: "Xã Đèo Gia",
    code: 7609,
    division_type: "xã",
    codename: "xa_deo_gia",
    district_code: 219,
  },
  {
    name: "Xã Phượng Sơn",
    code: 7612,
    division_type: "xã",
    codename: "xa_phuong_son",
    district_code: 219,
  },
  {
    name: "Thị trấn An Châu",
    code: 7615,
    division_type: "thị trấn",
    codename: "thi_tran_an_chau",
    district_code: 220,
  },
  {
    name: "Thị trấn Tây Yên Tử",
    code: 7616,
    division_type: "thị trấn",
    codename: "thi_tran_tay_yen_tu",
    district_code: 220,
  },
  {
    name: "Xã Vân Sơn",
    code: 7621,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 220,
  },
  {
    name: "Xã Hữu Sản",
    code: 7624,
    division_type: "xã",
    codename: "xa_huu_san",
    district_code: 220,
  },
  {
    name: "Xã Đại Sơn",
    code: 7627,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 220,
  },
  {
    name: "Xã Phúc Sơn",
    code: 7630,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 220,
  },
  {
    name: "Xã Giáo Liêm",
    code: 7636,
    division_type: "xã",
    codename: "xa_giao_liem",
    district_code: 220,
  },
  {
    name: "Xã Cẩm Đàn",
    code: 7642,
    division_type: "xã",
    codename: "xa_cam_dan",
    district_code: 220,
  },
  {
    name: "Xã An Lạc",
    code: 7645,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 220,
  },
  {
    name: "Xã Vĩnh An",
    code: 7648,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 220,
  },
  {
    name: "Xã Yên Định",
    code: 7651,
    division_type: "xã",
    codename: "xa_yen_dinh",
    district_code: 220,
  },
  {
    name: "Xã Lệ Viễn",
    code: 7654,
    division_type: "xã",
    codename: "xa_le_vien",
    district_code: 220,
  },
  {
    name: "Xã An Bá",
    code: 7660,
    division_type: "xã",
    codename: "xa_an_ba",
    district_code: 220,
  },
  {
    name: "Xã Tuấn Đạo",
    code: 7663,
    division_type: "xã",
    codename: "xa_tuan_dao",
    district_code: 220,
  },
  {
    name: "Xã Dương Hưu",
    code: 7666,
    division_type: "xã",
    codename: "xa_duong_huu",
    district_code: 220,
  },
  {
    name: "Xã Long Sơn",
    code: 7672,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 220,
  },
  {
    name: "Xã Thanh Luận",
    code: 7678,
    division_type: "xã",
    codename: "xa_thanh_luan",
    district_code: 220,
  },
  {
    name: "Thị trấn Nham Biền",
    code: 7681,
    division_type: "thị trấn",
    codename: "thi_tran_nham_bien",
    district_code: 221,
  },
  {
    name: "Thị trấn Tân An",
    code: 7682,
    division_type: "thị trấn",
    codename: "thi_tran_tan_an",
    district_code: 221,
  },
  {
    name: "Xã Lão Hộ",
    code: 7684,
    division_type: "xã",
    codename: "xa_lao_ho",
    district_code: 221,
  },
  {
    name: "Xã Hương Gián",
    code: 7690,
    division_type: "xã",
    codename: "xa_huong_gian",
    district_code: 221,
  },
  {
    name: "Xã Quỳnh Sơn",
    code: 7702,
    division_type: "xã",
    codename: "xa_quynh_son",
    district_code: 221,
  },
  {
    name: "Xã Nội Hoàng",
    code: 7708,
    division_type: "xã",
    codename: "xa_noi_hoang",
    district_code: 221,
  },
  {
    name: "Xã Tiền Phong",
    code: 7711,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 221,
  },
  {
    name: "Xã Xuân Phú",
    code: 7714,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 221,
  },
  {
    name: "Xã Tân Liễu",
    code: 7717,
    division_type: "xã",
    codename: "xa_tan_lieu",
    district_code: 221,
  },
  {
    name: "Xã Trí Yên",
    code: 7720,
    division_type: "xã",
    codename: "xa_tri_yen",
    district_code: 221,
  },
  {
    name: "Xã Lãng Sơn",
    code: 7723,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 221,
  },
  {
    name: "Xã Yên Lư",
    code: 7726,
    division_type: "xã",
    codename: "xa_yen_lu",
    district_code: 221,
  },
  {
    name: "Xã Tiến Dũng",
    code: 7729,
    division_type: "xã",
    codename: "xa_tien_dung",
    district_code: 221,
  },
  {
    name: "Xã Đức Giang",
    code: 7735,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 221,
  },
  {
    name: "Xã Cảnh Thụy",
    code: 7738,
    division_type: "xã",
    codename: "xa_canh_thuy",
    district_code: 221,
  },
  {
    name: "Xã Tư Mại",
    code: 7741,
    division_type: "xã",
    codename: "xa_tu_mai",
    district_code: 221,
  },
  {
    name: "Xã Đồng Việt",
    code: 7747,
    division_type: "xã",
    codename: "xa_dong_viet",
    district_code: 221,
  },
  {
    name: "Xã Đồng Phúc",
    code: 7750,
    division_type: "xã",
    codename: "xa_dong_phuc",
    district_code: 221,
  },
  {
    name: "Xã Thượng Lan",
    code: 7759,
    division_type: "xã",
    codename: "xa_thuong_lan",
    district_code: 222,
  },
  {
    name: "Xã Việt Tiến",
    code: 7762,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 222,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 7765,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 222,
  },
  {
    name: "Xã Minh Đức",
    code: 7768,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 222,
  },
  {
    name: "Xã Hương Mai",
    code: 7771,
    division_type: "xã",
    codename: "xa_huong_mai",
    district_code: 222,
  },
  {
    name: "Xã Tự Lạn",
    code: 7774,
    division_type: "xã",
    codename: "xa_tu_lan",
    district_code: 222,
  },
  {
    name: "Thị trấn Bích Động",
    code: 7777,
    division_type: "thị trấn",
    codename: "thi_tran_bich_dong",
    district_code: 222,
  },
  {
    name: "Xã Trung Sơn",
    code: 7780,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 222,
  },
  {
    name: "Xã Hồng Thái",
    code: 7783,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 222,
  },
  {
    name: "Xã Tiên Sơn",
    code: 7786,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 222,
  },
  {
    name: "Xã Tăng Tiến",
    code: 7789,
    division_type: "xã",
    codename: "xa_tang_tien",
    district_code: 222,
  },
  {
    name: "Xã Quảng Minh",
    code: 7792,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 222,
  },
  {
    name: "Thị trấn Nếnh",
    code: 7795,
    division_type: "thị trấn",
    codename: "thi_tran_nenh",
    district_code: 222,
  },
  {
    name: "Xã Ninh Sơn",
    code: 7798,
    division_type: "xã",
    codename: "xa_ninh_son",
    district_code: 222,
  },
  {
    name: "Xã Vân Trung",
    code: 7801,
    division_type: "xã",
    codename: "xa_van_trung",
    district_code: 222,
  },
  {
    name: "Xã Vân Hà",
    code: 7804,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 222,
  },
  {
    name: "Xã Quang Châu",
    code: 7807,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 222,
  },
  {
    name: "Xã Đồng Tân",
    code: 7813,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 223,
  },
  {
    name: "Xã Thanh Vân",
    code: 7816,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Lương",
    code: 7819,
    division_type: "xã",
    codename: "xa_hoang_luong",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Vân",
    code: 7822,
    division_type: "xã",
    codename: "xa_hoang_van",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Thanh",
    code: 7825,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 223,
  },
  {
    name: "Xã Hoàng An",
    code: 7828,
    division_type: "xã",
    codename: "xa_hoang_an",
    district_code: 223,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 7831,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 223,
  },
  {
    name: "Xã Thái Sơn",
    code: 7834,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 223,
  },
  {
    name: "Xã Hòa Sơn",
    code: 7837,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 223,
  },
  {
    name: "Thị trấn Thắng",
    code: 7840,
    division_type: "thị trấn",
    codename: "thi_tran_thang",
    district_code: 223,
  },
  {
    name: "Xã Quang Minh",
    code: 7843,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 223,
  },
  {
    name: "Xã Lương Phong",
    code: 7846,
    division_type: "xã",
    codename: "xa_luong_phong",
    district_code: 223,
  },
  {
    name: "Xã Hùng Sơn",
    code: 7849,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 223,
  },
  {
    name: "Xã Đại Thành",
    code: 7852,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 223,
  },
  {
    name: "Xã Thường Thắng",
    code: 7855,
    division_type: "xã",
    codename: "xa_thuong_thang",
    district_code: 223,
  },
  {
    name: "Xã Hợp Thịnh",
    code: 7858,
    division_type: "xã",
    codename: "xa_hop_thinh",
    district_code: 223,
  },
  {
    name: "Xã Danh Thắng",
    code: 7861,
    division_type: "xã",
    codename: "xa_danh_thang",
    district_code: 223,
  },
  {
    name: "Xã Mai Trung",
    code: 7864,
    division_type: "xã",
    codename: "xa_mai_trung",
    district_code: 223,
  },
  {
    name: "Xã Đoan Bái",
    code: 7867,
    division_type: "xã",
    codename: "xa_doan_bai",
    district_code: 223,
  },
  {
    name: "Xã Bắc Lý",
    code: 7870,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 223,
  },
  {
    name: "Xã Xuân Cẩm",
    code: 7873,
    division_type: "xã",
    codename: "xa_xuan_cam",
    district_code: 223,
  },
  {
    name: "Xã Hương Lâm",
    code: 7876,
    division_type: "xã",
    codename: "xa_huong_lam",
    district_code: 223,
  },
  {
    name: "Xã Đông Lỗ",
    code: 7879,
    division_type: "xã",
    codename: "xa_dong_lo",
    district_code: 223,
  },
  {
    name: "Xã Châu Minh",
    code: 7882,
    division_type: "xã",
    codename: "xa_chau_minh",
    district_code: 223,
  },
  {
    name: "Xã Mai Đình",
    code: 7885,
    division_type: "xã",
    codename: "xa_mai_dinh",
    district_code: 223,
  },
  {
    name: "Phường Dữu Lâu",
    code: 7888,
    division_type: "phường",
    codename: "phuong_duu_lau",
    district_code: 227,
  },
  {
    name: "Phường Vân Cơ",
    code: 7891,
    division_type: "phường",
    codename: "phuong_van_co",
    district_code: 227,
  },
  {
    name: "Phường Nông Trang",
    code: 7894,
    division_type: "phường",
    codename: "phuong_nong_trang",
    district_code: 227,
  },
  {
    name: "Phường Tân Dân",
    code: 7897,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 227,
  },
  {
    name: "Phường Gia Cẩm",
    code: 7900,
    division_type: "phường",
    codename: "phuong_gia_cam",
    district_code: 227,
  },
  {
    name: "Phường Tiên Cát",
    code: 7903,
    division_type: "phường",
    codename: "phuong_tien_cat",
    district_code: 227,
  },
  {
    name: "Phường Thọ Sơn",
    code: 7906,
    division_type: "phường",
    codename: "phuong_tho_son",
    district_code: 227,
  },
  {
    name: "Phường Thanh Miếu",
    code: 7909,
    division_type: "phường",
    codename: "phuong_thanh_mieu",
    district_code: 227,
  },
  {
    name: "Phường Bạch Hạc",
    code: 7912,
    division_type: "phường",
    codename: "phuong_bach_hac",
    district_code: 227,
  },
  {
    name: "Phường Bến Gót",
    code: 7915,
    division_type: "phường",
    codename: "phuong_ben_got",
    district_code: 227,
  },
  {
    name: "Phường Vân Phú",
    code: 7918,
    division_type: "phường",
    codename: "phuong_van_phu",
    district_code: 227,
  },
  {
    name: "Xã Phượng Lâu",
    code: 7921,
    division_type: "xã",
    codename: "xa_phuong_lau",
    district_code: 227,
  },
  {
    name: "Xã Thụy Vân",
    code: 7924,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 227,
  },
  {
    name: "Phường Minh Phương",
    code: 7927,
    division_type: "phường",
    codename: "phuong_minh_phuong",
    district_code: 227,
  },
  {
    name: "Xã Trưng Vương",
    code: 7930,
    division_type: "xã",
    codename: "xa_trung_vuong",
    district_code: 227,
  },
  {
    name: "Phường Minh Nông",
    code: 7933,
    division_type: "phường",
    codename: "phuong_minh_nong",
    district_code: 227,
  },
  {
    name: "Xã Sông Lô",
    code: 7936,
    division_type: "xã",
    codename: "xa_song_lo",
    district_code: 227,
  },
  {
    name: "Xã Kim Đức",
    code: 8281,
    division_type: "xã",
    codename: "xa_kim_duc",
    district_code: 227,
  },
  {
    name: "Xã Hùng Lô",
    code: 8287,
    division_type: "xã",
    codename: "xa_hung_lo",
    district_code: 227,
  },
  {
    name: "Xã Hy Cương",
    code: 8503,
    division_type: "xã",
    codename: "xa_hy_cuong",
    district_code: 227,
  },
  {
    name: "Xã Chu Hóa",
    code: 8506,
    division_type: "xã",
    codename: "xa_chu_hoa",
    district_code: 227,
  },
  {
    name: "Xã Thanh Đình",
    code: 8515,
    division_type: "xã",
    codename: "xa_thanh_dinh",
    district_code: 227,
  },
  {
    name: "Phường Hùng Vương",
    code: 7942,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 228,
  },
  {
    name: "Phường Phong Châu",
    code: 7945,
    division_type: "phường",
    codename: "phuong_phong_chau",
    district_code: 228,
  },
  {
    name: "Phường Âu Cơ",
    code: 7948,
    division_type: "phường",
    codename: "phuong_au_co",
    district_code: 228,
  },
  {
    name: "Xã Hà Lộc",
    code: 7951,
    division_type: "xã",
    codename: "xa_ha_loc",
    district_code: 228,
  },
  {
    name: "Xã Phú Hộ",
    code: 7954,
    division_type: "xã",
    codename: "xa_phu_ho",
    district_code: 228,
  },
  {
    name: "Xã Văn Lung",
    code: 7957,
    division_type: "xã",
    codename: "xa_van_lung",
    district_code: 228,
  },
  {
    name: "Xã Thanh Minh",
    code: 7960,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 228,
  },
  {
    name: "Xã Hà Thạch",
    code: 7963,
    division_type: "xã",
    codename: "xa_ha_thach",
    district_code: 228,
  },
  {
    name: "Phường Thanh Vinh",
    code: 7966,
    division_type: "phường",
    codename: "phuong_thanh_vinh",
    district_code: 228,
  },
  {
    name: "Thị trấn Đoan Hùng",
    code: 7969,
    division_type: "thị trấn",
    codename: "thi_tran_doan_hung",
    district_code: 230,
  },
  {
    name: "Xã Hùng Xuyên",
    code: 7975,
    division_type: "xã",
    codename: "xa_hung_xuyen",
    district_code: 230,
  },
  {
    name: "Xã Bằng Luân",
    code: 7981,
    division_type: "xã",
    codename: "xa_bang_luan",
    district_code: 230,
  },
  {
    name: "Xã Vân Du",
    code: 7984,
    division_type: "xã",
    codename: "xa_van_du",
    district_code: 230,
  },
  {
    name: "Xã Phú Lâm",
    code: 7987,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 230,
  },
  {
    name: "Xã Minh Lương",
    code: 7993,
    division_type: "xã",
    codename: "xa_minh_luong",
    district_code: 230,
  },
  {
    name: "Xã Bằng Doãn",
    code: 7996,
    division_type: "xã",
    codename: "xa_bang_doan",
    district_code: 230,
  },
  {
    name: "Xã Chí Đám",
    code: 7999,
    division_type: "xã",
    codename: "xa_chi_dam",
    district_code: 230,
  },
  {
    name: "Xã Phúc Lai",
    code: 8005,
    division_type: "xã",
    codename: "xa_phuc_lai",
    district_code: 230,
  },
  {
    name: "Xã Ngọc Quan",
    code: 8008,
    division_type: "xã",
    codename: "xa_ngoc_quan",
    district_code: 230,
  },
  {
    name: "Xã Hợp Nhất",
    code: 8014,
    division_type: "xã",
    codename: "xa_hop_nhat",
    district_code: 230,
  },
  {
    name: "Xã Sóc Đăng",
    code: 8017,
    division_type: "xã",
    codename: "xa_soc_dang",
    district_code: 230,
  },
  {
    name: "Xã Tây Cốc",
    code: 8023,
    division_type: "xã",
    codename: "xa_tay_coc",
    district_code: 230,
  },
  {
    name: "Xã Yên Kiện",
    code: 8026,
    division_type: "xã",
    codename: "xa_yen_kien",
    district_code: 230,
  },
  {
    name: "Xã Hùng Long",
    code: 8029,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 230,
  },
  {
    name: "Xã Vụ Quang",
    code: 8032,
    division_type: "xã",
    codename: "xa_vu_quang",
    district_code: 230,
  },
  {
    name: "Xã Vân Đồn",
    code: 8035,
    division_type: "xã",
    codename: "xa_van_don",
    district_code: 230,
  },
  {
    name: "Xã Tiêu Sơn",
    code: 8038,
    division_type: "xã",
    codename: "xa_tieu_son",
    district_code: 230,
  },
  {
    name: "Xã Minh Tiến",
    code: 8041,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 230,
  },
  {
    name: "Xã Minh Phú",
    code: 8044,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 230,
  },
  {
    name: "Xã Chân Mộng",
    code: 8047,
    division_type: "xã",
    codename: "xa_chan_mong",
    district_code: 230,
  },
  {
    name: "Xã Ca Đình",
    code: 8050,
    division_type: "xã",
    codename: "xa_ca_dinh",
    district_code: 230,
  },
  {
    name: "Thị trấn Hạ Hoà",
    code: 8053,
    division_type: "thị trấn",
    codename: "thi_tran_ha_hoa",
    district_code: 231,
  },
  {
    name: "Xã Đại Phạm",
    code: 8056,
    division_type: "xã",
    codename: "xa_dai_pham",
    district_code: 231,
  },
  {
    name: "Xã Đan Thượng",
    code: 8062,
    division_type: "xã",
    codename: "xa_dan_thuong",
    district_code: 231,
  },
  {
    name: "Xã Hà Lương",
    code: 8065,
    division_type: "xã",
    codename: "xa_ha_luong",
    district_code: 231,
  },
  {
    name: "Xã Tứ Hiệp",
    code: 8071,
    division_type: "xã",
    codename: "xa_tu_hiep",
    district_code: 231,
  },
  {
    name: "Xã Hiền Lương",
    code: 8080,
    division_type: "xã",
    codename: "xa_hien_luong",
    district_code: 231,
  },
  {
    name: "Xã Phương Viên",
    code: 8089,
    division_type: "xã",
    codename: "xa_phuong_vien",
    district_code: 231,
  },
  {
    name: "Xã Gia Điền",
    code: 8092,
    division_type: "xã",
    codename: "xa_gia_dien",
    district_code: 231,
  },
  {
    name: "Xã Ấm Hạ",
    code: 8095,
    division_type: "xã",
    codename: "xa_am_ha",
    district_code: 231,
  },
  {
    name: "Xã Hương Xạ",
    code: 8104,
    division_type: "xã",
    codename: "xa_huong_xa",
    district_code: 231,
  },
  {
    name: "Xã Xuân Áng",
    code: 8110,
    division_type: "xã",
    codename: "xa_xuan_ang",
    district_code: 231,
  },
  {
    name: "Xã Yên Kỳ",
    code: 8113,
    division_type: "xã",
    codename: "xa_yen_ky",
    district_code: 231,
  },
  {
    name: "Xã Minh Hạc",
    code: 8119,
    division_type: "xã",
    codename: "xa_minh_hac",
    district_code: 231,
  },
  {
    name: "Xã Lang Sơn",
    code: 8122,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 231,
  },
  {
    name: "Xã Bằng Giã",
    code: 8125,
    division_type: "xã",
    codename: "xa_bang_gia",
    district_code: 231,
  },
  {
    name: "Xã Yên Luật",
    code: 8128,
    division_type: "xã",
    codename: "xa_yen_luat",
    district_code: 231,
  },
  {
    name: "Xã Vô Tranh",
    code: 8131,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 231,
  },
  {
    name: "Xã Văn Lang",
    code: 8134,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 231,
  },
  {
    name: "Xã Minh Côi",
    code: 8140,
    division_type: "xã",
    codename: "xa_minh_coi",
    district_code: 231,
  },
  {
    name: "Xã Vĩnh Chân",
    code: 8143,
    division_type: "xã",
    codename: "xa_vinh_chan",
    district_code: 231,
  },
  {
    name: "Thị trấn Thanh Ba",
    code: 8152,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_ba",
    district_code: 232,
  },
  {
    name: "Xã Vân Lĩnh",
    code: 8156,
    division_type: "xã",
    codename: "xa_van_linh",
    district_code: 232,
  },
  {
    name: "Xã Đông Lĩnh",
    code: 8158,
    division_type: "xã",
    codename: "xa_dong_linh",
    district_code: 232,
  },
  {
    name: "Xã Đại An",
    code: 8161,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 232,
  },
  {
    name: "Xã Hanh Cù",
    code: 8164,
    division_type: "xã",
    codename: "xa_hanh_cu",
    district_code: 232,
  },
  {
    name: "Xã Đồng Xuân",
    code: 8170,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 232,
  },
  {
    name: "Xã Quảng Yên",
    code: 8173,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 232,
  },
  {
    name: "Xã Ninh Dân",
    code: 8179,
    division_type: "xã",
    codename: "xa_ninh_dan",
    district_code: 232,
  },
  {
    name: "Xã Võ Lao",
    code: 8194,
    division_type: "xã",
    codename: "xa_vo_lao",
    district_code: 232,
  },
  {
    name: "Xã Khải Xuân",
    code: 8197,
    division_type: "xã",
    codename: "xa_khai_xuan",
    district_code: 232,
  },
  {
    name: "Xã Mạn Lạn",
    code: 8200,
    division_type: "xã",
    codename: "xa_man_lan",
    district_code: 232,
  },
  {
    name: "Xã Hoàng Cương",
    code: 8203,
    division_type: "xã",
    codename: "xa_hoang_cuong",
    district_code: 232,
  },
  {
    name: "Xã Chí Tiên",
    code: 8206,
    division_type: "xã",
    codename: "xa_chi_tien",
    district_code: 232,
  },
  {
    name: "Xã Đông Thành",
    code: 8209,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 232,
  },
  {
    name: "Xã Sơn Cương",
    code: 8215,
    division_type: "xã",
    codename: "xa_son_cuong",
    district_code: 232,
  },
  {
    name: "Xã Thanh Hà",
    code: 8218,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 232,
  },
  {
    name: "Xã Đỗ Sơn",
    code: 8221,
    division_type: "xã",
    codename: "xa_do_son",
    district_code: 232,
  },
  {
    name: "Xã Đỗ Xuyên",
    code: 8224,
    division_type: "xã",
    codename: "xa_do_xuyen",
    district_code: 232,
  },
  {
    name: "Xã Lương Lỗ",
    code: 8227,
    division_type: "xã",
    codename: "xa_luong_lo",
    district_code: 232,
  },
  {
    name: "Thị trấn Phong Châu",
    code: 8230,
    division_type: "thị trấn",
    codename: "thi_tran_phong_chau",
    district_code: 233,
  },
  {
    name: "Xã Phú Mỹ",
    code: 8233,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 233,
  },
  {
    name: "Xã Lệ Mỹ",
    code: 8234,
    division_type: "xã",
    codename: "xa_le_my",
    district_code: 233,
  },
  {
    name: "Xã Liên Hoa",
    code: 8236,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 233,
  },
  {
    name: "Xã Trạm Thản",
    code: 8239,
    division_type: "xã",
    codename: "xa_tram_than",
    district_code: 233,
  },
  {
    name: "Xã Trị Quận",
    code: 8242,
    division_type: "xã",
    codename: "xa_tri_quan",
    district_code: 233,
  },
  {
    name: "Xã Trung Giáp",
    code: 8245,
    division_type: "xã",
    codename: "xa_trung_giap",
    district_code: 233,
  },
  {
    name: "Xã Tiên Phú",
    code: 8248,
    division_type: "xã",
    codename: "xa_tien_phu",
    district_code: 233,
  },
  {
    name: "Xã Hạ Giáp",
    code: 8251,
    division_type: "xã",
    codename: "xa_ha_giap",
    district_code: 233,
  },
  {
    name: "Xã Bảo Thanh",
    code: 8254,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 233,
  },
  {
    name: "Xã Phú Lộc",
    code: 8257,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 233,
  },
  {
    name: "Xã Gia Thanh",
    code: 8260,
    division_type: "xã",
    codename: "xa_gia_thanh",
    district_code: 233,
  },
  {
    name: "Xã Tiên Du",
    code: 8263,
    division_type: "xã",
    codename: "xa_tien_du",
    district_code: 233,
  },
  {
    name: "Xã Phú Nham",
    code: 8266,
    division_type: "xã",
    codename: "xa_phu_nham",
    district_code: 233,
  },
  {
    name: "Xã An Đạo",
    code: 8272,
    division_type: "xã",
    codename: "xa_an_dao",
    district_code: 233,
  },
  {
    name: "Xã Bình Phú",
    code: 8275,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 233,
  },
  {
    name: "Xã Phù Ninh",
    code: 8278,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 233,
  },
  {
    name: "Thị trấn Yên Lập",
    code: 8290,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lap",
    district_code: 234,
  },
  {
    name: "Xã Mỹ Lung",
    code: 8293,
    division_type: "xã",
    codename: "xa_my_lung",
    district_code: 234,
  },
  {
    name: "Xã Mỹ Lương",
    code: 8296,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 234,
  },
  {
    name: "Xã Lương Sơn",
    code: 8299,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 234,
  },
  {
    name: "Xã Xuân An",
    code: 8302,
    division_type: "xã",
    codename: "xa_xuan_an",
    district_code: 234,
  },
  {
    name: "Xã Xuân Viên",
    code: 8305,
    division_type: "xã",
    codename: "xa_xuan_vien",
    district_code: 234,
  },
  {
    name: "Xã Xuân Thủy",
    code: 8308,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 234,
  },
  {
    name: "Xã Trung Sơn",
    code: 8311,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 234,
  },
  {
    name: "Xã Hưng Long",
    code: 8314,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 234,
  },
  {
    name: "Xã Nga Hoàng",
    code: 8317,
    division_type: "xã",
    codename: "xa_nga_hoang",
    district_code: 234,
  },
  {
    name: "Xã Đồng Lạc",
    code: 8320,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 234,
  },
  {
    name: "Xã Thượng Long",
    code: 8323,
    division_type: "xã",
    codename: "xa_thuong_long",
    district_code: 234,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 8326,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 234,
  },
  {
    name: "Xã Phúc Khánh",
    code: 8329,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 234,
  },
  {
    name: "Xã Minh Hòa",
    code: 8332,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 234,
  },
  {
    name: "Xã Ngọc Lập",
    code: 8335,
    division_type: "xã",
    codename: "xa_ngoc_lap",
    district_code: 234,
  },
  {
    name: "Xã Ngọc Đồng",
    code: 8338,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 234,
  },
  {
    name: "Thị trấn Cẩm Khê",
    code: 8341,
    division_type: "thị trấn",
    codename: "thi_tran_cam_khe",
    district_code: 235,
  },
  {
    name: "Xã Tiên Lương",
    code: 8344,
    division_type: "xã",
    codename: "xa_tien_luong",
    district_code: 235,
  },
  {
    name: "Xã Tuy Lộc",
    code: 8347,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 235,
  },
  {
    name: "Xã Ngô Xá",
    code: 8350,
    division_type: "xã",
    codename: "xa_ngo_xa",
    district_code: 235,
  },
  {
    name: "Xã Minh Tân",
    code: 8353,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 235,
  },
  {
    name: "Xã Phượng Vĩ",
    code: 8356,
    division_type: "xã",
    codename: "xa_phuong_vi",
    district_code: 235,
  },
  {
    name: "Xã Thụy Liễu",
    code: 8362,
    division_type: "xã",
    codename: "xa_thuy_lieu",
    district_code: 235,
  },
  {
    name: "Xã Tùng Khê",
    code: 8374,
    division_type: "xã",
    codename: "xa_tung_khe",
    district_code: 235,
  },
  {
    name: "Xã Tam Sơn",
    code: 8377,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 235,
  },
  {
    name: "Xã Văn Bán",
    code: 8380,
    division_type: "xã",
    codename: "xa_van_ban",
    district_code: 235,
  },
  {
    name: "Xã Cấp Dẫn",
    code: 8383,
    division_type: "xã",
    codename: "xa_cap_dan",
    district_code: 235,
  },
  {
    name: "Xã Xương Thịnh",
    code: 8389,
    division_type: "xã",
    codename: "xa_xuong_thinh",
    district_code: 235,
  },
  {
    name: "Xã Phú Khê",
    code: 8392,
    division_type: "xã",
    codename: "xa_phu_khe",
    district_code: 235,
  },
  {
    name: "Xã Sơn Tình",
    code: 8395,
    division_type: "xã",
    codename: "xa_son_tinh",
    district_code: 235,
  },
  {
    name: "Xã Yên Tập",
    code: 8398,
    division_type: "xã",
    codename: "xa_yen_tap",
    district_code: 235,
  },
  {
    name: "Xã Hương Lung",
    code: 8401,
    division_type: "xã",
    codename: "xa_huong_lung",
    district_code: 235,
  },
  {
    name: "Xã Tạ Xá",
    code: 8404,
    division_type: "xã",
    codename: "xa_ta_xa",
    district_code: 235,
  },
  {
    name: "Xã Phú Lạc",
    code: 8407,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 235,
  },
  {
    name: "Xã Chương Xá",
    code: 8413,
    division_type: "xã",
    codename: "xa_chuong_xa",
    district_code: 235,
  },
  {
    name: "Xã Hùng Việt",
    code: 8416,
    division_type: "xã",
    codename: "xa_hung_viet",
    district_code: 235,
  },
  {
    name: "Xã Văn Khúc",
    code: 8419,
    division_type: "xã",
    codename: "xa_van_khuc",
    district_code: 235,
  },
  {
    name: "Xã Yên Dưỡng",
    code: 8422,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 235,
  },
  {
    name: "Xã Điêu Lương",
    code: 8428,
    division_type: "xã",
    codename: "xa_dieu_luong",
    district_code: 235,
  },
  {
    name: "Xã Đồng Lương",
    code: 8431,
    division_type: "xã",
    codename: "xa_dong_luong",
    district_code: 235,
  },
  {
    name: "Thị trấn Hưng Hoá",
    code: 8434,
    division_type: "thị trấn",
    codename: "thi_tran_hung_hoa",
    district_code: 236,
  },
  {
    name: "Xã Hiền Quan",
    code: 8440,
    division_type: "xã",
    codename: "xa_hien_quan",
    district_code: 236,
  },
  {
    name: "Xã Bắc Sơn",
    code: 8443,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 236,
  },
  {
    name: "Xã Thanh Uyên",
    code: 8446,
    division_type: "xã",
    codename: "xa_thanh_uyen",
    district_code: 236,
  },
  {
    name: "Xã Lam Sơn",
    code: 8461,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 236,
  },
  {
    name: "Xã Vạn Xuân",
    code: 8467,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 236,
  },
  {
    name: "Xã Quang Húc",
    code: 8470,
    division_type: "xã",
    codename: "xa_quang_huc",
    district_code: 236,
  },
  {
    name: "Xã Hương Nộn",
    code: 8473,
    division_type: "xã",
    codename: "xa_huong_non",
    district_code: 236,
  },
  {
    name: "Xã Tề Lễ",
    code: 8476,
    division_type: "xã",
    codename: "xa_te_le",
    district_code: 236,
  },
  {
    name: "Xã Thọ Văn",
    code: 8479,
    division_type: "xã",
    codename: "xa_tho_van",
    district_code: 236,
  },
  {
    name: "Xã Dị Nậu",
    code: 8482,
    division_type: "xã",
    codename: "xa_di_nau",
    district_code: 236,
  },
  {
    name: "Xã Dân Quyền",
    code: 8491,
    division_type: "xã",
    codename: "xa_dan_quyen",
    district_code: 236,
  },
  {
    name: "Thị trấn Lâm Thao",
    code: 8494,
    division_type: "thị trấn",
    codename: "thi_tran_lam_thao",
    district_code: 237,
  },
  {
    name: "Xã Tiên Kiên",
    code: 8497,
    division_type: "xã",
    codename: "xa_tien_kien",
    district_code: 237,
  },
  {
    name: "Thị trấn Hùng Sơn",
    code: 8498,
    division_type: "thị trấn",
    codename: "thi_tran_hung_son",
    district_code: 237,
  },
  {
    name: "Xã Xuân Lũng",
    code: 8500,
    division_type: "xã",
    codename: "xa_xuan_lung",
    district_code: 237,
  },
  {
    name: "Xã Xuân Huy",
    code: 8509,
    division_type: "xã",
    codename: "xa_xuan_huy",
    district_code: 237,
  },
  {
    name: "Xã Thạch Sơn",
    code: 8512,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 237,
  },
  {
    name: "Xã Sơn Vi",
    code: 8518,
    division_type: "xã",
    codename: "xa_son_vi",
    district_code: 237,
  },
  {
    name: "Xã Phùng Nguyên",
    code: 8521,
    division_type: "xã",
    codename: "xa_phung_nguyen",
    district_code: 237,
  },
  {
    name: "Xã Cao Xá",
    code: 8527,
    division_type: "xã",
    codename: "xa_cao_xa",
    district_code: 237,
  },
  {
    name: "Xã Vĩnh Lại",
    code: 8533,
    division_type: "xã",
    codename: "xa_vinh_lai",
    district_code: 237,
  },
  {
    name: "Xã Tứ Xã",
    code: 8536,
    division_type: "xã",
    codename: "xa_tu_xa",
    district_code: 237,
  },
  {
    name: "Xã Bản Nguyên",
    code: 8539,
    division_type: "xã",
    codename: "xa_ban_nguyen",
    district_code: 237,
  },
  {
    name: "Thị trấn Thanh Sơn",
    code: 8542,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_son",
    district_code: 238,
  },
  {
    name: "Xã Sơn Hùng",
    code: 8563,
    division_type: "xã",
    codename: "xa_son_hung",
    district_code: 238,
  },
  {
    name: "Xã Địch Quả",
    code: 8572,
    division_type: "xã",
    codename: "xa_dich_qua",
    district_code: 238,
  },
  {
    name: "Xã Giáp Lai",
    code: 8575,
    division_type: "xã",
    codename: "xa_giap_lai",
    district_code: 238,
  },
  {
    name: "Xã Thục Luyện",
    code: 8581,
    division_type: "xã",
    codename: "xa_thuc_luyen",
    district_code: 238,
  },
  {
    name: "Xã Võ Miếu",
    code: 8584,
    division_type: "xã",
    codename: "xa_vo_mieu",
    district_code: 238,
  },
  {
    name: "Xã Thạch Khoán",
    code: 8587,
    division_type: "xã",
    codename: "xa_thach_khoan",
    district_code: 238,
  },
  {
    name: "Xã Cự Thắng",
    code: 8602,
    division_type: "xã",
    codename: "xa_cu_thang",
    district_code: 238,
  },
  {
    name: "Xã Tất Thắng",
    code: 8605,
    division_type: "xã",
    codename: "xa_tat_thang",
    district_code: 238,
  },
  {
    name: "Xã Văn Miếu",
    code: 8611,
    division_type: "xã",
    codename: "xa_van_mieu",
    district_code: 238,
  },
  {
    name: "Xã Cự Đồng",
    code: 8614,
    division_type: "xã",
    codename: "xa_cu_dong",
    district_code: 238,
  },
  {
    name: "Xã Thắng Sơn",
    code: 8623,
    division_type: "xã",
    codename: "xa_thang_son",
    district_code: 238,
  },
  {
    name: "Xã Tân Minh",
    code: 8629,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 238,
  },
  {
    name: "Xã Hương Cần",
    code: 8632,
    division_type: "xã",
    codename: "xa_huong_can",
    district_code: 238,
  },
  {
    name: "Xã Khả Cửu",
    code: 8635,
    division_type: "xã",
    codename: "xa_kha_cuu",
    district_code: 238,
  },
  {
    name: "Xã Đông Cửu",
    code: 8638,
    division_type: "xã",
    codename: "xa_dong_cuu",
    district_code: 238,
  },
  {
    name: "Xã Tân Lập",
    code: 8641,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 238,
  },
  {
    name: "Xã Yên Lãng",
    code: 8644,
    division_type: "xã",
    codename: "xa_yen_lang",
    district_code: 238,
  },
  {
    name: "Xã Yên Lương",
    code: 8647,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 238,
  },
  {
    name: "Xã Thượng Cửu",
    code: 8650,
    division_type: "xã",
    codename: "xa_thuong_cuu",
    district_code: 238,
  },
  {
    name: "Xã Lương Nha",
    code: 8653,
    division_type: "xã",
    codename: "xa_luong_nha",
    district_code: 238,
  },
  {
    name: "Xã Yên Sơn",
    code: 8656,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 238,
  },
  {
    name: "Xã Tinh Nhuệ",
    code: 8659,
    division_type: "xã",
    codename: "xa_tinh_nhue",
    district_code: 238,
  },
  {
    name: "Xã Đào Xá",
    code: 8662,
    division_type: "xã",
    codename: "xa_dao_xa",
    district_code: 239,
  },
  {
    name: "Xã Thạch Đồng",
    code: 8665,
    division_type: "xã",
    codename: "xa_thach_dong",
    district_code: 239,
  },
  {
    name: "Xã Xuân Lộc",
    code: 8668,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 239,
  },
  {
    name: "Xã Tân Phương",
    code: 8671,
    division_type: "xã",
    codename: "xa_tan_phuong",
    district_code: 239,
  },
  {
    name: "Thị trấn Thanh Thủy",
    code: 8674,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_thuy",
    district_code: 239,
  },
  {
    name: "Xã Sơn Thủy",
    code: 8677,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 239,
  },
  {
    name: "Xã Bảo Yên",
    code: 8680,
    division_type: "xã",
    codename: "xa_bao_yen",
    district_code: 239,
  },
  {
    name: "Xã Đoan Hạ",
    code: 8683,
    division_type: "xã",
    codename: "xa_doan_ha",
    district_code: 239,
  },
  {
    name: "Xã Đồng Trung",
    code: 8686,
    division_type: "xã",
    codename: "xa_dong_trung",
    district_code: 239,
  },
  {
    name: "Xã Hoàng Xá",
    code: 8689,
    division_type: "xã",
    codename: "xa_hoang_xa",
    district_code: 239,
  },
  {
    name: "Xã Tu Vũ",
    code: 8701,
    division_type: "xã",
    codename: "xa_tu_vu",
    district_code: 239,
  },
  {
    name: "Xã Thu Cúc",
    code: 8545,
    division_type: "xã",
    codename: "xa_thu_cuc",
    district_code: 240,
  },
  {
    name: "Xã Thạch Kiệt",
    code: 8548,
    division_type: "xã",
    codename: "xa_thach_kiet",
    district_code: 240,
  },
  {
    name: "Xã Thu Ngạc",
    code: 8551,
    division_type: "xã",
    codename: "xa_thu_ngac",
    district_code: 240,
  },
  {
    name: "Xã Kiệt Sơn",
    code: 8554,
    division_type: "xã",
    codename: "xa_kiet_son",
    district_code: 240,
  },
  {
    name: "Xã Đồng Sơn",
    code: 8557,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 240,
  },
  {
    name: "Xã Lai Đồng",
    code: 8560,
    division_type: "xã",
    codename: "xa_lai_dong",
    district_code: 240,
  },
  {
    name: "Xã Tân Phú",
    code: 8566,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 240,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 8569,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 240,
  },
  {
    name: "Xã Tân Sơn",
    code: 8578,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 240,
  },
  {
    name: "Xã Xuân Đài",
    code: 8590,
    division_type: "xã",
    codename: "xa_xuan_dai",
    district_code: 240,
  },
  {
    name: "Xã Minh Đài",
    code: 8593,
    division_type: "xã",
    codename: "xa_minh_dai",
    district_code: 240,
  },
  {
    name: "Xã Văn Luông",
    code: 8596,
    division_type: "xã",
    codename: "xa_van_luong",
    district_code: 240,
  },
  {
    name: "Xã Xuân Sơn",
    code: 8599,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 240,
  },
  {
    name: "Xã Long Cốc",
    code: 8608,
    division_type: "xã",
    codename: "xa_long_coc",
    district_code: 240,
  },
  {
    name: "Xã Kim Thượng",
    code: 8617,
    division_type: "xã",
    codename: "xa_kim_thuong",
    district_code: 240,
  },
  {
    name: "Xã Tam Thanh",
    code: 8620,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 240,
  },
  {
    name: "Xã Vinh Tiền",
    code: 8626,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 240,
  },
  {
    name: "Phường Tích Sơn",
    code: 8707,
    division_type: "phường",
    codename: "phuong_tich_son",
    district_code: 243,
  },
  {
    name: "Phường Liên Bảo",
    code: 8710,
    division_type: "phường",
    codename: "phuong_lien_bao",
    district_code: 243,
  },
  {
    name: "Phường Hội Hợp",
    code: 8713,
    division_type: "phường",
    codename: "phuong_hoi_hop",
    district_code: 243,
  },
  {
    name: "Phường Đống Đa",
    code: 8716,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 243,
  },
  {
    name: "Phường Ngô Quyền",
    code: 8719,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 243,
  },
  {
    name: "Phường Đồng Tâm",
    code: 8722,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 243,
  },
  {
    name: "Xã Định Trung",
    code: 8725,
    division_type: "xã",
    codename: "xa_dinh_trung",
    district_code: 243,
  },
  {
    name: "Phường Khai Quang",
    code: 8728,
    division_type: "phường",
    codename: "phuong_khai_quang",
    district_code: 243,
  },
  {
    name: "Xã Thanh Trù",
    code: 8731,
    division_type: "xã",
    codename: "xa_thanh_tru",
    district_code: 243,
  },
  {
    name: "Phường Trưng Trắc",
    code: 8734,
    division_type: "phường",
    codename: "phuong_trung_trac",
    district_code: 244,
  },
  {
    name: "Phường Hùng Vương",
    code: 8737,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 244,
  },
  {
    name: "Phường Trưng Nhị",
    code: 8740,
    division_type: "phường",
    codename: "phuong_trung_nhi",
    district_code: 244,
  },
  {
    name: "Phường Phúc Thắng",
    code: 8743,
    division_type: "phường",
    codename: "phuong_phuc_thang",
    district_code: 244,
  },
  {
    name: "Phường Xuân Hoà",
    code: 8746,
    division_type: "phường",
    codename: "phuong_xuan_hoa",
    district_code: 244,
  },
  {
    name: "Phường Đồng Xuân",
    code: 8747,
    division_type: "phường",
    codename: "phuong_dong_xuan",
    district_code: 244,
  },
  {
    name: "Xã Ngọc Thanh",
    code: 8749,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 244,
  },
  {
    name: "Xã Cao Minh",
    code: 8752,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 244,
  },
  {
    name: "Phường Nam Viêm",
    code: 8755,
    division_type: "phường",
    codename: "phuong_nam_viem",
    district_code: 244,
  },
  {
    name: "Phường Tiền Châu",
    code: 8758,
    division_type: "phường",
    codename: "phuong_tien_chau",
    district_code: 244,
  },
  {
    name: "Thị trấn Lập Thạch",
    code: 8761,
    division_type: "thị trấn",
    codename: "thi_tran_lap_thach",
    district_code: 246,
  },
  {
    name: "Xã Quang Sơn",
    code: 8764,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 246,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 8767,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 246,
  },
  {
    name: "Xã Hợp Lý",
    code: 8770,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 246,
  },
  {
    name: "Xã Bắc Bình",
    code: 8785,
    division_type: "xã",
    codename: "xa_bac_binh",
    district_code: 246,
  },
  {
    name: "Xã Thái Hòa",
    code: 8788,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 246,
  },
  {
    name: "Thị trấn Hoa Sơn",
    code: 8789,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_son",
    district_code: 246,
  },
  {
    name: "Xã Liễn Sơn",
    code: 8791,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 246,
  },
  {
    name: "Xã Xuân Hòa",
    code: 8794,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 246,
  },
  {
    name: "Xã Vân Trục",
    code: 8797,
    division_type: "xã",
    codename: "xa_van_truc",
    district_code: 246,
  },
  {
    name: "Xã Liên Hòa",
    code: 8812,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 246,
  },
  {
    name: "Xã Tử Du",
    code: 8815,
    division_type: "xã",
    codename: "xa_tu_du",
    district_code: 246,
  },
  {
    name: "Xã Bàn Giản",
    code: 8833,
    division_type: "xã",
    codename: "xa_ban_gian",
    district_code: 246,
  },
  {
    name: "Xã Xuân Lôi",
    code: 8836,
    division_type: "xã",
    codename: "xa_xuan_loi",
    district_code: 246,
  },
  {
    name: "Xã Đồng Ích",
    code: 8839,
    division_type: "xã",
    codename: "xa_dong_ich",
    district_code: 246,
  },
  {
    name: "Xã Tiên Lữ",
    code: 8842,
    division_type: "xã",
    codename: "xa_tien_lu",
    district_code: 246,
  },
  {
    name: "Xã Văn Quán",
    code: 8845,
    division_type: "xã",
    codename: "xa_van_quan",
    district_code: 246,
  },
  {
    name: "Xã Đình Chu",
    code: 8857,
    division_type: "xã",
    codename: "xa_dinh_chu",
    district_code: 246,
  },
  {
    name: "Xã Triệu Đề",
    code: 8863,
    division_type: "xã",
    codename: "xa_trieu_de",
    district_code: 246,
  },
  {
    name: "Xã Sơn Đông",
    code: 8866,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 246,
  },
  {
    name: "Thị trấn Hợp Hòa",
    code: 8869,
    division_type: "thị trấn",
    codename: "thi_tran_hop_hoa",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Hoa",
    code: 8872,
    division_type: "xã",
    codename: "xa_hoang_hoa",
    district_code: 247,
  },
  {
    name: "Xã Đồng Tĩnh",
    code: 8875,
    division_type: "xã",
    codename: "xa_dong_tinh",
    district_code: 247,
  },
  {
    name: "Xã Kim Long",
    code: 8878,
    division_type: "xã",
    codename: "xa_kim_long",
    district_code: 247,
  },
  {
    name: "Xã Hướng Đạo",
    code: 8881,
    division_type: "xã",
    codename: "xa_huong_dao",
    district_code: 247,
  },
  {
    name: "Xã Đạo Tú",
    code: 8884,
    division_type: "xã",
    codename: "xa_dao_tu",
    district_code: 247,
  },
  {
    name: "Xã An Hòa",
    code: 8887,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 247,
  },
  {
    name: "Xã Thanh Vân",
    code: 8890,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 247,
  },
  {
    name: "Xã Duy Phiên",
    code: 8893,
    division_type: "xã",
    codename: "xa_duy_phien",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Đan",
    code: 8896,
    division_type: "xã",
    codename: "xa_hoang_dan",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Lâu",
    code: 8899,
    division_type: "xã",
    codename: "xa_hoang_lau",
    district_code: 247,
  },
  {
    name: "Xã Vân Hội",
    code: 8902,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 247,
  },
  {
    name: "Xã Hợp Thịnh",
    code: 8905,
    division_type: "xã",
    codename: "xa_hop_thinh",
    district_code: 247,
  },
  {
    name: "Thị trấn Tam Đảo",
    code: 8908,
    division_type: "thị trấn",
    codename: "thi_tran_tam_dao",
    district_code: 248,
  },
  {
    name: "Thị trấn Hợp Châu",
    code: 8911,
    division_type: "thị trấn",
    codename: "thi_tran_hop_chau",
    district_code: 248,
  },
  {
    name: "Xã Đạo Trù",
    code: 8914,
    division_type: "xã",
    codename: "xa_dao_tru",
    district_code: 248,
  },
  {
    name: "Xã Yên Dương",
    code: 8917,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 248,
  },
  {
    name: "Xã Bồ Lý",
    code: 8920,
    division_type: "xã",
    codename: "xa_bo_ly",
    district_code: 248,
  },
  {
    name: "Thị trấn Đại Đình",
    code: 8923,
    division_type: "thị trấn",
    codename: "thi_tran_dai_dinh",
    district_code: 248,
  },
  {
    name: "Xã Tam Quan",
    code: 8926,
    division_type: "xã",
    codename: "xa_tam_quan",
    district_code: 248,
  },
  {
    name: "Xã Hồ Sơn",
    code: 8929,
    division_type: "xã",
    codename: "xa_ho_son",
    district_code: 248,
  },
  {
    name: "Xã Minh Quang",
    code: 8932,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 248,
  },
  {
    name: "Thị trấn Hương Canh",
    code: 8935,
    division_type: "thị trấn",
    codename: "thi_tran_huong_canh",
    district_code: 249,
  },
  {
    name: "Thị trấn Gia Khánh",
    code: 8936,
    division_type: "thị trấn",
    codename: "thi_tran_gia_khanh",
    district_code: 249,
  },
  {
    name: "Xã Trung Mỹ",
    code: 8938,
    division_type: "xã",
    codename: "xa_trung_my",
    district_code: 249,
  },
  {
    name: "Thị trấn Bá Hiến",
    code: 8944,
    division_type: "thị trấn",
    codename: "thi_tran_ba_hien",
    district_code: 249,
  },
  {
    name: "Xã Thiện Kế",
    code: 8947,
    division_type: "xã",
    codename: "xa_thien_ke",
    district_code: 249,
  },
  {
    name: "Xã Hương Sơn",
    code: 8950,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 249,
  },
  {
    name: "Xã Tam Hợp",
    code: 8953,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 249,
  },
  {
    name: "Xã Quất Lưu",
    code: 8956,
    division_type: "xã",
    codename: "xa_quat_luu",
    district_code: 249,
  },
  {
    name: "Xã Sơn Lôi",
    code: 8959,
    division_type: "xã",
    codename: "xa_son_loi",
    district_code: 249,
  },
  {
    name: "Thị trấn Đạo Đức",
    code: 8962,
    division_type: "thị trấn",
    codename: "thi_tran_dao_duc",
    district_code: 249,
  },
  {
    name: "Xã Tân Phong",
    code: 8965,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 249,
  },
  {
    name: "Thị trấn Thanh Lãng",
    code: 8968,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_lang",
    district_code: 249,
  },
  {
    name: "Xã Phú Xuân",
    code: 8971,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 249,
  },
  {
    name: "Thị trấn Yên Lạc",
    code: 9025,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lac",
    district_code: 251,
  },
  {
    name: "Xã Đồng Cương",
    code: 9028,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 251,
  },
  {
    name: "Xã Đồng Văn",
    code: 9031,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 251,
  },
  {
    name: "Xã Bình Định",
    code: 9034,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 251,
  },
  {
    name: "Xã Trung Nguyên",
    code: 9037,
    division_type: "xã",
    codename: "xa_trung_nguyen",
    district_code: 251,
  },
  {
    name: "Xã Tề Lỗ",
    code: 9040,
    division_type: "xã",
    codename: "xa_te_lo",
    district_code: 251,
  },
  {
    name: "Xã Tam Hồng",
    code: 9043,
    division_type: "xã",
    codename: "xa_tam_hong",
    district_code: 251,
  },
  {
    name: "Xã Yên Đồng",
    code: 9046,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 251,
  },
  {
    name: "Xã Văn Tiến",
    code: 9049,
    division_type: "xã",
    codename: "xa_van_tien",
    district_code: 251,
  },
  {
    name: "Xã Nguyệt Đức",
    code: 9052,
    division_type: "xã",
    codename: "xa_nguyet_duc",
    district_code: 251,
  },
  {
    name: "Xã Yên Phương",
    code: 9055,
    division_type: "xã",
    codename: "xa_yen_phuong",
    district_code: 251,
  },
  {
    name: "Xã Hồng Phương",
    code: 9058,
    division_type: "xã",
    codename: "xa_hong_phuong",
    district_code: 251,
  },
  {
    name: "Xã Trung Kiên",
    code: 9061,
    division_type: "xã",
    codename: "xa_trung_kien",
    district_code: 251,
  },
  {
    name: "Xã Liên Châu",
    code: 9064,
    division_type: "xã",
    codename: "xa_lien_chau",
    district_code: 251,
  },
  {
    name: "Xã Đại Tự",
    code: 9067,
    division_type: "xã",
    codename: "xa_dai_tu",
    district_code: 251,
  },
  {
    name: "Xã Hồng Châu",
    code: 9070,
    division_type: "xã",
    codename: "xa_hong_chau",
    district_code: 251,
  },
  {
    name: "Xã Trung Hà",
    code: 9073,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 251,
  },
  {
    name: "Thị trấn Vĩnh Tường",
    code: 9076,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tuong",
    district_code: 252,
  },
  {
    name: "Xã Kim Xá",
    code: 9079,
    division_type: "xã",
    codename: "xa_kim_xa",
    district_code: 252,
  },
  {
    name: "Xã Yên Bình",
    code: 9082,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 252,
  },
  {
    name: "Xã Chấn Hưng",
    code: 9085,
    division_type: "xã",
    codename: "xa_chan_hung",
    district_code: 252,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 9088,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 252,
  },
  {
    name: "Xã Yên Lập",
    code: 9091,
    division_type: "xã",
    codename: "xa_yen_lap",
    district_code: 252,
  },
  {
    name: "Xã Việt Xuân",
    code: 9094,
    division_type: "xã",
    codename: "xa_viet_xuan",
    district_code: 252,
  },
  {
    name: "Xã Bồ Sao",
    code: 9097,
    division_type: "xã",
    codename: "xa_bo_sao",
    district_code: 252,
  },
  {
    name: "Xã Đại Đồng",
    code: 9100,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 252,
  },
  {
    name: "Xã Tân Tiến",
    code: 9103,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 252,
  },
  {
    name: "Xã Lũng Hoà",
    code: 9106,
    division_type: "xã",
    codename: "xa_lung_hoa",
    district_code: 252,
  },
  {
    name: "Xã Cao Đại",
    code: 9109,
    division_type: "xã",
    codename: "xa_cao_dai",
    district_code: 252,
  },
  {
    name: "Thị trấn Thổ Tang",
    code: 9112,
    division_type: "thị trấn",
    codename: "thi_tran_tho_tang",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 9115,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 252,
  },
  {
    name: "Xã Bình Dương",
    code: 9118,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 252,
  },
  {
    name: "Xã Tân Phú",
    code: 9124,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 252,
  },
  {
    name: "Xã Thượng Trưng",
    code: 9127,
    division_type: "xã",
    codename: "xa_thuong_trung",
    district_code: 252,
  },
  {
    name: "Xã Vũ Di",
    code: 9130,
    division_type: "xã",
    codename: "xa_vu_di",
    district_code: 252,
  },
  {
    name: "Xã Lý Nhân",
    code: 9133,
    division_type: "xã",
    codename: "xa_ly_nhan",
    district_code: 252,
  },
  {
    name: "Xã Tuân Chính",
    code: 9136,
    division_type: "xã",
    codename: "xa_tuan_chinh",
    district_code: 252,
  },
  {
    name: "Xã Vân Xuân",
    code: 9139,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 252,
  },
  {
    name: "Xã Tam Phúc",
    code: 9142,
    division_type: "xã",
    codename: "xa_tam_phuc",
    district_code: 252,
  },
  {
    name: "Thị trấn Tứ Trưng",
    code: 9145,
    division_type: "thị trấn",
    codename: "thi_tran_tu_trung",
    district_code: 252,
  },
  {
    name: "Xã Ngũ Kiên",
    code: 9148,
    division_type: "xã",
    codename: "xa_ngu_kien",
    district_code: 252,
  },
  {
    name: "Xã An Tường",
    code: 9151,
    division_type: "xã",
    codename: "xa_an_tuong",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 9154,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 252,
  },
  {
    name: "Xã Phú Đa",
    code: 9157,
    division_type: "xã",
    codename: "xa_phu_da",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Ninh",
    code: 9160,
    division_type: "xã",
    codename: "xa_vinh_ninh",
    district_code: 252,
  },
  {
    name: "Xã Lãng Công",
    code: 8773,
    division_type: "xã",
    codename: "xa_lang_cong",
    district_code: 253,
  },
  {
    name: "Xã Quang Yên",
    code: 8776,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 253,
  },
  {
    name: "Xã Bạch Lưu",
    code: 8779,
    division_type: "xã",
    codename: "xa_bach_luu",
    district_code: 253,
  },
  {
    name: "Xã Hải Lựu",
    code: 8782,
    division_type: "xã",
    codename: "xa_hai_luu",
    district_code: 253,
  },
  {
    name: "Xã Đồng Quế",
    code: 8800,
    division_type: "xã",
    codename: "xa_dong_que",
    district_code: 253,
  },
  {
    name: "Xã Nhân Đạo",
    code: 8803,
    division_type: "xã",
    codename: "xa_nhan_dao",
    district_code: 253,
  },
  {
    name: "Xã Đôn Nhân",
    code: 8806,
    division_type: "xã",
    codename: "xa_don_nhan",
    district_code: 253,
  },
  {
    name: "Xã Phương Khoan",
    code: 8809,
    division_type: "xã",
    codename: "xa_phuong_khoan",
    district_code: 253,
  },
  {
    name: "Xã Tân Lập",
    code: 8818,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 253,
  },
  {
    name: "Xã Nhạo Sơn",
    code: 8821,
    division_type: "xã",
    codename: "xa_nhao_son",
    district_code: 253,
  },
  {
    name: "Thị trấn Tam Sơn",
    code: 8824,
    division_type: "thị trấn",
    codename: "thi_tran_tam_son",
    district_code: 253,
  },
  {
    name: "Xã Như Thụy",
    code: 8827,
    division_type: "xã",
    codename: "xa_nhu_thuy",
    district_code: 253,
  },
  {
    name: "Xã Yên Thạch",
    code: 8830,
    division_type: "xã",
    codename: "xa_yen_thach",
    district_code: 253,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 8848,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 253,
  },
  {
    name: "Xã Tứ Yên",
    code: 8851,
    division_type: "xã",
    codename: "xa_tu_yen",
    district_code: 253,
  },
  {
    name: "Xã Đức Bác",
    code: 8854,
    division_type: "xã",
    codename: "xa_duc_bac",
    district_code: 253,
  },
  {
    name: "Xã Cao Phong",
    code: 8860,
    division_type: "xã",
    codename: "xa_cao_phong",
    district_code: 253,
  },
  {
    name: "Phường Vũ Ninh",
    code: 9163,
    division_type: "phường",
    codename: "phuong_vu_ninh",
    district_code: 256,
  },
  {
    name: "Phường Đáp Cầu",
    code: 9166,
    division_type: "phường",
    codename: "phuong_dap_cau",
    district_code: 256,
  },
  {
    name: "Phường Thị Cầu",
    code: 9169,
    division_type: "phường",
    codename: "phuong_thi_cau",
    district_code: 256,
  },
  {
    name: "Phường Kinh Bắc",
    code: 9172,
    division_type: "phường",
    codename: "phuong_kinh_bac",
    district_code: 256,
  },
  {
    name: "Phường Vệ An",
    code: 9175,
    division_type: "phường",
    codename: "phuong_ve_an",
    district_code: 256,
  },
  {
    name: "Phường Tiền An",
    code: 9178,
    division_type: "phường",
    codename: "phuong_tien_an",
    district_code: 256,
  },
  {
    name: "Phường Đại Phúc",
    code: 9181,
    division_type: "phường",
    codename: "phuong_dai_phuc",
    district_code: 256,
  },
  {
    name: "Phường Ninh Xá",
    code: 9184,
    division_type: "phường",
    codename: "phuong_ninh_xa",
    district_code: 256,
  },
  {
    name: "Phường Suối Hoa",
    code: 9187,
    division_type: "phường",
    codename: "phuong_suoi_hoa",
    district_code: 256,
  },
  {
    name: "Phường Võ Cường",
    code: 9190,
    division_type: "phường",
    codename: "phuong_vo_cuong",
    district_code: 256,
  },
  {
    name: "Phường Hòa Long",
    code: 9214,
    division_type: "phường",
    codename: "phuong_hoa_long",
    district_code: 256,
  },
  {
    name: "Phường Vạn An",
    code: 9226,
    division_type: "phường",
    codename: "phuong_van_an",
    district_code: 256,
  },
  {
    name: "Phường Khúc Xuyên",
    code: 9235,
    division_type: "phường",
    codename: "phuong_khuc_xuyen",
    district_code: 256,
  },
  {
    name: "Phường Phong Khê",
    code: 9244,
    division_type: "phường",
    codename: "phuong_phong_khe",
    district_code: 256,
  },
  {
    name: "Phường Kim Chân",
    code: 9256,
    division_type: "phường",
    codename: "phuong_kim_chan",
    district_code: 256,
  },
  {
    name: "Phường Vân Dương",
    code: 9271,
    division_type: "phường",
    codename: "phuong_van_duong",
    district_code: 256,
  },
  {
    name: "Phường Nam Sơn",
    code: 9286,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 256,
  },
  {
    name: "Phường Khắc Niệm",
    code: 9325,
    division_type: "phường",
    codename: "phuong_khac_niem",
    district_code: 256,
  },
  {
    name: "Phường Hạp Lĩnh",
    code: 9331,
    division_type: "phường",
    codename: "phuong_hap_linh",
    district_code: 256,
  },
  {
    name: "Thị trấn Chờ",
    code: 9193,
    division_type: "thị trấn",
    codename: "thi_tran_cho",
    district_code: 258,
  },
  {
    name: "Xã Dũng Liệt",
    code: 9196,
    division_type: "xã",
    codename: "xa_dung_liet",
    district_code: 258,
  },
  {
    name: "Xã Tam Đa",
    code: 9199,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 258,
  },
  {
    name: "Xã Tam Giang",
    code: 9202,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 258,
  },
  {
    name: "Xã Yên Trung",
    code: 9205,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 258,
  },
  {
    name: "Xã Thụy Hòa",
    code: 9208,
    division_type: "xã",
    codename: "xa_thuy_hoa",
    district_code: 258,
  },
  {
    name: "Xã Hòa Tiến",
    code: 9211,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 258,
  },
  {
    name: "Xã Đông Tiến",
    code: 9217,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 258,
  },
  {
    name: "Xã Yên Phụ",
    code: 9220,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 258,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 9223,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 258,
  },
  {
    name: "Xã Đông Phong",
    code: 9229,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 258,
  },
  {
    name: "Xã Long Châu",
    code: 9232,
    division_type: "xã",
    codename: "xa_long_chau",
    district_code: 258,
  },
  {
    name: "Xã Văn Môn",
    code: 9238,
    division_type: "xã",
    codename: "xa_van_mon",
    district_code: 258,
  },
  {
    name: "Xã Đông Thọ",
    code: 9241,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 258,
  },
  {
    name: "Thị trấn Phố Mới",
    code: 9247,
    division_type: "thị trấn",
    codename: "thi_tran_pho_moi",
    district_code: 259,
  },
  {
    name: "Xã Việt Thống",
    code: 9250,
    division_type: "xã",
    codename: "xa_viet_thong",
    district_code: 259,
  },
  {
    name: "Xã Đại Xuân",
    code: 9253,
    division_type: "xã",
    codename: "xa_dai_xuan",
    district_code: 259,
  },
  {
    name: "Xã Nhân Hòa",
    code: 9259,
    division_type: "xã",
    codename: "xa_nhan_hoa",
    district_code: 259,
  },
  {
    name: "Xã Bằng An",
    code: 9262,
    division_type: "xã",
    codename: "xa_bang_an",
    district_code: 259,
  },
  {
    name: "Xã Phương Liễu",
    code: 9265,
    division_type: "xã",
    codename: "xa_phuong_lieu",
    district_code: 259,
  },
  {
    name: "Xã Quế Tân",
    code: 9268,
    division_type: "xã",
    codename: "xa_que_tan",
    district_code: 259,
  },
  {
    name: "Xã Phù Lương",
    code: 9274,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 259,
  },
  {
    name: "Xã Phù Lãng",
    code: 9277,
    division_type: "xã",
    codename: "xa_phu_lang",
    district_code: 259,
  },
  {
    name: "Xã Phượng Mao",
    code: 9280,
    division_type: "xã",
    codename: "xa_phuong_mao",
    district_code: 259,
  },
  {
    name: "Xã Việt Hùng",
    code: 9283,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 259,
  },
  {
    name: "Xã Ngọc Xá",
    code: 9289,
    division_type: "xã",
    codename: "xa_ngoc_xa",
    district_code: 259,
  },
  {
    name: "Xã Châu Phong",
    code: 9292,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 259,
  },
  {
    name: "Xã Bồng Lai",
    code: 9295,
    division_type: "xã",
    codename: "xa_bong_lai",
    district_code: 259,
  },
  {
    name: "Xã Cách Bi",
    code: 9298,
    division_type: "xã",
    codename: "xa_cach_bi",
    district_code: 259,
  },
  {
    name: "Xã Đào Viên",
    code: 9301,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 259,
  },
  {
    name: "Xã Yên Giả",
    code: 9304,
    division_type: "xã",
    codename: "xa_yen_gia",
    district_code: 259,
  },
  {
    name: "Xã Mộ Đạo",
    code: 9307,
    division_type: "xã",
    codename: "xa_mo_dao",
    district_code: 259,
  },
  {
    name: "Xã Đức Long",
    code: 9310,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 259,
  },
  {
    name: "Xã Chi Lăng",
    code: 9313,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 259,
  },
  {
    name: "Xã Hán Quảng",
    code: 9316,
    division_type: "xã",
    codename: "xa_han_quang",
    district_code: 259,
  },
  {
    name: "Thị trấn Lim",
    code: 9319,
    division_type: "thị trấn",
    codename: "thi_tran_lim",
    district_code: 260,
  },
  {
    name: "Xã Phú Lâm",
    code: 9322,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 260,
  },
  {
    name: "Xã Nội Duệ",
    code: 9328,
    division_type: "xã",
    codename: "xa_noi_due",
    district_code: 260,
  },
  {
    name: "Xã Liên Bão",
    code: 9334,
    division_type: "xã",
    codename: "xa_lien_bao",
    district_code: 260,
  },
  {
    name: "Xã Hiên Vân",
    code: 9337,
    division_type: "xã",
    codename: "xa_hien_van",
    district_code: 260,
  },
  {
    name: "Xã Hoàn Sơn",
    code: 9340,
    division_type: "xã",
    codename: "xa_hoan_son",
    district_code: 260,
  },
  {
    name: "Xã Lạc Vệ",
    code: 9343,
    division_type: "xã",
    codename: "xa_lac_ve",
    district_code: 260,
  },
  {
    name: "Xã Việt Đoàn",
    code: 9346,
    division_type: "xã",
    codename: "xa_viet_doan",
    district_code: 260,
  },
  {
    name: "Xã Phật Tích",
    code: 9349,
    division_type: "xã",
    codename: "xa_phat_tich",
    district_code: 260,
  },
  {
    name: "Xã Tân Chi",
    code: 9352,
    division_type: "xã",
    codename: "xa_tan_chi",
    district_code: 260,
  },
  {
    name: "Xã Đại Đồng",
    code: 9355,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 260,
  },
  {
    name: "Xã Tri Phương",
    code: 9358,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 260,
  },
  {
    name: "Xã Minh Đạo",
    code: 9361,
    division_type: "xã",
    codename: "xa_minh_dao",
    district_code: 260,
  },
  {
    name: "Xã Cảnh Hưng",
    code: 9364,
    division_type: "xã",
    codename: "xa_canh_hung",
    district_code: 260,
  },
  {
    name: "Phường Đông Ngàn",
    code: 9367,
    division_type: "phường",
    codename: "phuong_dong_ngan",
    district_code: 261,
  },
  {
    name: "Phường Tam Sơn",
    code: 9370,
    division_type: "phường",
    codename: "phuong_tam_son",
    district_code: 261,
  },
  {
    name: "Phường Hương Mạc",
    code: 9373,
    division_type: "phường",
    codename: "phuong_huong_mac",
    district_code: 261,
  },
  {
    name: "Phường Tương Giang",
    code: 9376,
    division_type: "phường",
    codename: "phuong_tuong_giang",
    district_code: 261,
  },
  {
    name: "Phường Phù Khê",
    code: 9379,
    division_type: "phường",
    codename: "phuong_phu_khe",
    district_code: 261,
  },
  {
    name: "Phường Đồng Kỵ",
    code: 9382,
    division_type: "phường",
    codename: "phuong_dong_ky",
    district_code: 261,
  },
  {
    name: "Phường Trang Hạ",
    code: 9383,
    division_type: "phường",
    codename: "phuong_trang_ha",
    district_code: 261,
  },
  {
    name: "Phường Đồng Nguyên",
    code: 9385,
    division_type: "phường",
    codename: "phuong_dong_nguyen",
    district_code: 261,
  },
  {
    name: "Phường Châu Khê",
    code: 9388,
    division_type: "phường",
    codename: "phuong_chau_khe",
    district_code: 261,
  },
  {
    name: "Phường Tân Hồng",
    code: 9391,
    division_type: "phường",
    codename: "phuong_tan_hong",
    district_code: 261,
  },
  {
    name: "Phường Đình Bảng",
    code: 9394,
    division_type: "phường",
    codename: "phuong_dinh_bang",
    district_code: 261,
  },
  {
    name: "Phường Phù Chẩn",
    code: 9397,
    division_type: "phường",
    codename: "phuong_phu_chan",
    district_code: 261,
  },
  {
    name: "Thị trấn Hồ",
    code: 9400,
    division_type: "thị trấn",
    codename: "thi_tran_ho",
    district_code: 262,
  },
  {
    name: "Xã Hoài Thượng",
    code: 9403,
    division_type: "xã",
    codename: "xa_hoai_thuong",
    district_code: 262,
  },
  {
    name: "Xã Đại Đồng Thành",
    code: 9406,
    division_type: "xã",
    codename: "xa_dai_dong_thanh",
    district_code: 262,
  },
  {
    name: "Xã Mão Điền",
    code: 9409,
    division_type: "xã",
    codename: "xa_mao_dien",
    district_code: 262,
  },
  {
    name: "Xã Song Hồ",
    code: 9412,
    division_type: "xã",
    codename: "xa_song_ho",
    district_code: 262,
  },
  {
    name: "Xã Đình Tổ",
    code: 9415,
    division_type: "xã",
    codename: "xa_dinh_to",
    district_code: 262,
  },
  {
    name: "Xã An Bình",
    code: 9418,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 262,
  },
  {
    name: "Xã Trí Quả",
    code: 9421,
    division_type: "xã",
    codename: "xa_tri_qua",
    district_code: 262,
  },
  {
    name: "Xã Gia Đông",
    code: 9424,
    division_type: "xã",
    codename: "xa_gia_dong",
    district_code: 262,
  },
  {
    name: "Xã Thanh Khương",
    code: 9427,
    division_type: "xã",
    codename: "xa_thanh_khuong",
    district_code: 262,
  },
  {
    name: "Xã Trạm Lộ",
    code: 9430,
    division_type: "xã",
    codename: "xa_tram_lo",
    district_code: 262,
  },
  {
    name: "Xã Xuân Lâm",
    code: 9433,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 262,
  },
  {
    name: "Xã Hà Mãn",
    code: 9436,
    division_type: "xã",
    codename: "xa_ha_man",
    district_code: 262,
  },
  {
    name: "Xã Ngũ Thái",
    code: 9439,
    division_type: "xã",
    codename: "xa_ngu_thai",
    district_code: 262,
  },
  {
    name: "Xã Nguyệt Đức",
    code: 9442,
    division_type: "xã",
    codename: "xa_nguyet_duc",
    district_code: 262,
  },
  {
    name: "Xã Ninh Xá",
    code: 9445,
    division_type: "xã",
    codename: "xa_ninh_xa",
    district_code: 262,
  },
  {
    name: "Xã Nghĩa Đạo",
    code: 9448,
    division_type: "xã",
    codename: "xa_nghia_dao",
    district_code: 262,
  },
  {
    name: "Xã Song Liễu",
    code: 9451,
    division_type: "xã",
    codename: "xa_song_lieu",
    district_code: 262,
  },
  {
    name: "Thị trấn Gia Bình",
    code: 9454,
    division_type: "thị trấn",
    codename: "thi_tran_gia_binh",
    district_code: 263,
  },
  {
    name: "Xã Vạn Ninh",
    code: 9457,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 263,
  },
  {
    name: "Xã Thái Bảo",
    code: 9460,
    division_type: "xã",
    codename: "xa_thai_bao",
    district_code: 263,
  },
  {
    name: "Xã Giang Sơn",
    code: 9463,
    division_type: "xã",
    codename: "xa_giang_son",
    district_code: 263,
  },
  {
    name: "Xã Cao Đức",
    code: 9466,
    division_type: "xã",
    codename: "xa_cao_duc",
    district_code: 263,
  },
  {
    name: "Xã Đại Lai",
    code: 9469,
    division_type: "xã",
    codename: "xa_dai_lai",
    district_code: 263,
  },
  {
    name: "Xã Song Giang",
    code: 9472,
    division_type: "xã",
    codename: "xa_song_giang",
    district_code: 263,
  },
  {
    name: "Xã Bình Dương",
    code: 9475,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 263,
  },
  {
    name: "Xã Lãng Ngâm",
    code: 9478,
    division_type: "xã",
    codename: "xa_lang_ngam",
    district_code: 263,
  },
  {
    name: "Xã Nhân Thắng",
    code: 9481,
    division_type: "xã",
    codename: "xa_nhan_thang",
    district_code: 263,
  },
  {
    name: "Xã Xuân Lai",
    code: 9484,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 263,
  },
  {
    name: "Xã Đông Cứu",
    code: 9487,
    division_type: "xã",
    codename: "xa_dong_cuu",
    district_code: 263,
  },
  {
    name: "Xã Đại Bái",
    code: 9490,
    division_type: "xã",
    codename: "xa_dai_bai",
    district_code: 263,
  },
  {
    name: "Xã Quỳnh Phú",
    code: 9493,
    division_type: "xã",
    codename: "xa_quynh_phu",
    district_code: 263,
  },
  {
    name: "Thị trấn Thứa",
    code: 9496,
    division_type: "thị trấn",
    codename: "thi_tran_thua",
    district_code: 264,
  },
  {
    name: "Xã An Thịnh",
    code: 9499,
    division_type: "xã",
    codename: "xa_an_thinh",
    district_code: 264,
  },
  {
    name: "Xã Trung Kênh",
    code: 9502,
    division_type: "xã",
    codename: "xa_trung_kenh",
    district_code: 264,
  },
  {
    name: "Xã Phú Hòa",
    code: 9505,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 264,
  },
  {
    name: "Xã Mỹ Hương",
    code: 9508,
    division_type: "xã",
    codename: "xa_my_huong",
    district_code: 264,
  },
  {
    name: "Xã Tân Lãng",
    code: 9511,
    division_type: "xã",
    codename: "xa_tan_lang",
    district_code: 264,
  },
  {
    name: "Xã Quảng Phú",
    code: 9514,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 264,
  },
  {
    name: "Xã Trừng Xá",
    code: 9517,
    division_type: "xã",
    codename: "xa_trung_xa",
    district_code: 264,
  },
  {
    name: "Xã Lai Hạ",
    code: 9520,
    division_type: "xã",
    codename: "xa_lai_ha",
    district_code: 264,
  },
  {
    name: "Xã Trung Chính",
    code: 9523,
    division_type: "xã",
    codename: "xa_trung_chinh",
    district_code: 264,
  },
  {
    name: "Xã Minh Tân",
    code: 9526,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 264,
  },
  {
    name: "Xã Bình Định",
    code: 9529,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 264,
  },
  {
    name: "Xã Phú Lương",
    code: 9532,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 264,
  },
  {
    name: "Xã Lâm Thao",
    code: 9535,
    division_type: "xã",
    codename: "xa_lam_thao",
    district_code: 264,
  },
  {
    name: "Phường Cẩm Thượng",
    code: 10507,
    division_type: "phường",
    codename: "phuong_cam_thuong",
    district_code: 288,
  },
  {
    name: "Phường Bình Hàn",
    code: 10510,
    division_type: "phường",
    codename: "phuong_binh_han",
    district_code: 288,
  },
  {
    name: "Phường Ngọc Châu",
    code: 10513,
    division_type: "phường",
    codename: "phuong_ngoc_chau",
    district_code: 288,
  },
  {
    name: "Phường Nhị Châu",
    code: 10514,
    division_type: "phường",
    codename: "phuong_nhi_chau",
    district_code: 288,
  },
  {
    name: "Phường Quang Trung",
    code: 10516,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 288,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 10519,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 288,
  },
  {
    name: "Phường Phạm Ngũ Lão",
    code: 10522,
    division_type: "phường",
    codename: "phuong_pham_ngu_lao",
    district_code: 288,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 10525,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 288,
  },
  {
    name: "Phường Trần Phú",
    code: 10528,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 288,
  },
  {
    name: "Phường Thanh Bình",
    code: 10531,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 288,
  },
  {
    name: "Phường Tân Bình",
    code: 10532,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 288,
  },
  {
    name: "Phường Lê Thanh Nghị",
    code: 10534,
    division_type: "phường",
    codename: "phuong_le_thanh_nghi",
    district_code: 288,
  },
  {
    name: "Phường Hải Tân",
    code: 10537,
    division_type: "phường",
    codename: "phuong_hai_tan",
    district_code: 288,
  },
  {
    name: "Phường Tứ Minh",
    code: 10540,
    division_type: "phường",
    codename: "phuong_tu_minh",
    district_code: 288,
  },
  {
    name: "Phường Việt Hoà",
    code: 10543,
    division_type: "phường",
    codename: "phuong_viet_hoa",
    district_code: 288,
  },
  {
    name: "Phường Ái Quốc",
    code: 10660,
    division_type: "phường",
    codename: "phuong_ai_quoc",
    district_code: 288,
  },
  {
    name: "Xã An Thượng",
    code: 10663,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 288,
  },
  {
    name: "Phường Nam Đồng",
    code: 10672,
    division_type: "phường",
    codename: "phuong_nam_dong",
    district_code: 288,
  },
  {
    name: "Xã Quyết Thắng",
    code: 10822,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 288,
  },
  {
    name: "Xã Tiền Tiến",
    code: 10837,
    division_type: "xã",
    codename: "xa_tien_tien",
    district_code: 288,
  },
  {
    name: "Phường Thạch Khôi",
    code: 11002,
    division_type: "phường",
    codename: "phuong_thach_khoi",
    district_code: 288,
  },
  {
    name: "Xã Liên Hồng",
    code: 11005,
    division_type: "xã",
    codename: "xa_lien_hong",
    district_code: 288,
  },
  {
    name: "Phường Tân Hưng",
    code: 11011,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 288,
  },
  {
    name: "Xã Gia Xuyên",
    code: 11017,
    division_type: "xã",
    codename: "xa_gia_xuyen",
    district_code: 288,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 11077,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 288,
  },
  {
    name: "Phường Phả Lại",
    code: 10546,
    division_type: "phường",
    codename: "phuong_pha_lai",
    district_code: 290,
  },
  {
    name: "Phường Sao Đỏ",
    code: 10549,
    division_type: "phường",
    codename: "phuong_sao_do",
    district_code: 290,
  },
  {
    name: "Phường Bến Tắm",
    code: 10552,
    division_type: "phường",
    codename: "phuong_ben_tam",
    district_code: 290,
  },
  {
    name: "Xã Hoàng Hoa Thám",
    code: 10555,
    division_type: "xã",
    codename: "xa_hoang_hoa_tham",
    district_code: 290,
  },
  {
    name: "Xã Bắc An",
    code: 10558,
    division_type: "xã",
    codename: "xa_bac_an",
    district_code: 290,
  },
  {
    name: "Xã Hưng Đạo",
    code: 10561,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 290,
  },
  {
    name: "Xã Lê Lợi",
    code: 10564,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 290,
  },
  {
    name: "Phường Hoàng Tiến",
    code: 10567,
    division_type: "phường",
    codename: "phuong_hoang_tien",
    district_code: 290,
  },
  {
    name: "Phường Cộng Hoà",
    code: 10570,
    division_type: "phường",
    codename: "phuong_cong_hoa",
    district_code: 290,
  },
  {
    name: "Phường Hoàng Tân",
    code: 10573,
    division_type: "phường",
    codename: "phuong_hoang_tan",
    district_code: 290,
  },
  {
    name: "Phường Cổ Thành",
    code: 10576,
    division_type: "phường",
    codename: "phuong_co_thanh",
    district_code: 290,
  },
  {
    name: "Phường Văn An",
    code: 10579,
    division_type: "phường",
    codename: "phuong_van_an",
    district_code: 290,
  },
  {
    name: "Phường Chí Minh",
    code: 10582,
    division_type: "phường",
    codename: "phuong_chi_minh",
    district_code: 290,
  },
  {
    name: "Phường Văn Đức",
    code: 10585,
    division_type: "phường",
    codename: "phuong_van_duc",
    district_code: 290,
  },
  {
    name: "Phường Thái Học",
    code: 10588,
    division_type: "phường",
    codename: "phuong_thai_hoc",
    district_code: 290,
  },
  {
    name: "Xã Nhân Huệ",
    code: 10591,
    division_type: "xã",
    codename: "xa_nhan_hue",
    district_code: 290,
  },
  {
    name: "Phường An Lạc",
    code: 10594,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 290,
  },
  {
    name: "Phường Đồng Lạc",
    code: 10600,
    division_type: "phường",
    codename: "phuong_dong_lac",
    district_code: 290,
  },
  {
    name: "Phường Tân Dân",
    code: 10603,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 290,
  },
  {
    name: "Thị trấn Nam Sách",
    code: 10606,
    division_type: "thị trấn",
    codename: "thi_tran_nam_sach",
    district_code: 291,
  },
  {
    name: "Xã Nam Hưng",
    code: 10609,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 291,
  },
  {
    name: "Xã Nam Tân",
    code: 10612,
    division_type: "xã",
    codename: "xa_nam_tan",
    district_code: 291,
  },
  {
    name: "Xã Hợp Tiến",
    code: 10615,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 291,
  },
  {
    name: "Xã Hiệp Cát",
    code: 10618,
    division_type: "xã",
    codename: "xa_hiep_cat",
    district_code: 291,
  },
  {
    name: "Xã Thanh Quang",
    code: 10621,
    division_type: "xã",
    codename: "xa_thanh_quang",
    district_code: 291,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 10624,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 291,
  },
  {
    name: "Xã Nam Chính",
    code: 10627,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 291,
  },
  {
    name: "Xã An Bình",
    code: 10630,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 291,
  },
  {
    name: "Xã Nam Trung",
    code: 10633,
    division_type: "xã",
    codename: "xa_nam_trung",
    district_code: 291,
  },
  {
    name: "Xã An Sơn",
    code: 10636,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 291,
  },
  {
    name: "Xã Cộng Hòa",
    code: 10639,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 291,
  },
  {
    name: "Xã Thái Tân",
    code: 10642,
    division_type: "xã",
    codename: "xa_thai_tan",
    district_code: 291,
  },
  {
    name: "Xã An Lâm",
    code: 10645,
    division_type: "xã",
    codename: "xa_an_lam",
    district_code: 291,
  },
  {
    name: "Xã Phú Điền",
    code: 10648,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 291,
  },
  {
    name: "Xã Nam Hồng",
    code: 10651,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 291,
  },
  {
    name: "Xã Hồng Phong",
    code: 10654,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 291,
  },
  {
    name: "Xã Đồng Lạc",
    code: 10657,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 291,
  },
  {
    name: "Xã Minh Tân",
    code: 10666,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 291,
  },
  {
    name: "Phường An Lưu",
    code: 10675,
    division_type: "phường",
    codename: "phuong_an_luu",
    district_code: 292,
  },
  {
    name: "Xã Bạch Đằng",
    code: 10678,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 292,
  },
  {
    name: "Phường Thất Hùng",
    code: 10681,
    division_type: "phường",
    codename: "phuong_that_hung",
    district_code: 292,
  },
  {
    name: "Xã Lê Ninh",
    code: 10684,
    division_type: "xã",
    codename: "xa_le_ninh",
    district_code: 292,
  },
  {
    name: "Xã Hoành Sơn",
    code: 10687,
    division_type: "xã",
    codename: "xa_hoanh_son",
    district_code: 292,
  },
  {
    name: "Phường Phạm Thái",
    code: 10693,
    division_type: "phường",
    codename: "phuong_pham_thai",
    district_code: 292,
  },
  {
    name: "Phường Duy Tân",
    code: 10696,
    division_type: "phường",
    codename: "phuong_duy_tan",
    district_code: 292,
  },
  {
    name: "Phường Tân Dân",
    code: 10699,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 292,
  },
  {
    name: "Phường Minh Tân",
    code: 10702,
    division_type: "phường",
    codename: "phuong_minh_tan",
    district_code: 292,
  },
  {
    name: "Xã Quang Thành",
    code: 10705,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 292,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 10708,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 292,
  },
  {
    name: "Phường Phú Thứ",
    code: 10714,
    division_type: "phường",
    codename: "phuong_phu_thu",
    district_code: 292,
  },
  {
    name: "Xã Thăng Long",
    code: 10717,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 292,
  },
  {
    name: "Xã Lạc Long",
    code: 10720,
    division_type: "xã",
    codename: "xa_lac_long",
    district_code: 292,
  },
  {
    name: "Phường An Sinh",
    code: 10723,
    division_type: "phường",
    codename: "phuong_an_sinh",
    district_code: 292,
  },
  {
    name: "Phường Hiệp Sơn",
    code: 10726,
    division_type: "phường",
    codename: "phuong_hiep_son",
    district_code: 292,
  },
  {
    name: "Xã Thượng Quận",
    code: 10729,
    division_type: "xã",
    codename: "xa_thuong_quan",
    district_code: 292,
  },
  {
    name: "Phường An Phụ",
    code: 10732,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 292,
  },
  {
    name: "Phường Hiệp An",
    code: 10735,
    division_type: "phường",
    codename: "phuong_hiep_an",
    district_code: 292,
  },
  {
    name: "Phường Long Xuyên",
    code: 10738,
    division_type: "phường",
    codename: "phuong_long_xuyen",
    district_code: 292,
  },
  {
    name: "Phường Thái Thịnh",
    code: 10741,
    division_type: "phường",
    codename: "phuong_thai_thinh",
    district_code: 292,
  },
  {
    name: "Phường Hiến Thành",
    code: 10744,
    division_type: "phường",
    codename: "phuong_hien_thanh",
    district_code: 292,
  },
  {
    name: "Xã Minh Hòa",
    code: 10747,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 292,
  },
  {
    name: "Thị trấn Phú Thái",
    code: 10750,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thai",
    district_code: 293,
  },
  {
    name: "Xã Lai Vu",
    code: 10753,
    division_type: "xã",
    codename: "xa_lai_vu",
    district_code: 293,
  },
  {
    name: "Xã Cộng Hòa",
    code: 10756,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 293,
  },
  {
    name: "Xã Thượng Vũ",
    code: 10759,
    division_type: "xã",
    codename: "xa_thuong_vu",
    district_code: 293,
  },
  {
    name: "Xã Cổ Dũng",
    code: 10762,
    division_type: "xã",
    codename: "xa_co_dung",
    district_code: 293,
  },
  {
    name: "Xã Tuấn Việt",
    code: 10768,
    division_type: "xã",
    codename: "xa_tuan_viet",
    district_code: 293,
  },
  {
    name: "Xã Kim Xuyên",
    code: 10771,
    division_type: "xã",
    codename: "xa_kim_xuyen",
    district_code: 293,
  },
  {
    name: "Xã Phúc Thành A",
    code: 10774,
    division_type: "xã",
    codename: "xa_phuc_thanh_a",
    district_code: 293,
  },
  {
    name: "Xã Ngũ Phúc",
    code: 10777,
    division_type: "xã",
    codename: "xa_ngu_phuc",
    district_code: 293,
  },
  {
    name: "Xã Kim Anh",
    code: 10780,
    division_type: "xã",
    codename: "xa_kim_anh",
    district_code: 293,
  },
  {
    name: "Xã Kim Liên",
    code: 10783,
    division_type: "xã",
    codename: "xa_kim_lien",
    district_code: 293,
  },
  {
    name: "Xã Kim Tân",
    code: 10786,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 293,
  },
  {
    name: "Xã Kim Đính",
    code: 10792,
    division_type: "xã",
    codename: "xa_kim_dinh",
    district_code: 293,
  },
  {
    name: "Xã Bình Dân",
    code: 10798,
    division_type: "xã",
    codename: "xa_binh_dan",
    district_code: 293,
  },
  {
    name: "Xã Tam Kỳ",
    code: 10801,
    division_type: "xã",
    codename: "xa_tam_ky",
    district_code: 293,
  },
  {
    name: "Xã Đồng Cẩm",
    code: 10804,
    division_type: "xã",
    codename: "xa_dong_cam",
    district_code: 293,
  },
  {
    name: "Xã Liên Hòa",
    code: 10807,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 293,
  },
  {
    name: "Xã Đại Đức",
    code: 10810,
    division_type: "xã",
    codename: "xa_dai_duc",
    district_code: 293,
  },
  {
    name: "Thị trấn Thanh Hà",
    code: 10813,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_ha",
    district_code: 294,
  },
  {
    name: "Xã Hồng Lạc",
    code: 10816,
    division_type: "xã",
    codename: "xa_hong_lac",
    district_code: 294,
  },
  {
    name: "Xã Việt Hồng",
    code: 10819,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 294,
  },
  {
    name: "Xã Tân Việt",
    code: 10825,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 294,
  },
  {
    name: "Xã Cẩm Chế",
    code: 10828,
    division_type: "xã",
    codename: "xa_cam_che",
    district_code: 294,
  },
  {
    name: "Xã Thanh An",
    code: 10831,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 294,
  },
  {
    name: "Xã Thanh Lang",
    code: 10834,
    division_type: "xã",
    codename: "xa_thanh_lang",
    district_code: 294,
  },
  {
    name: "Xã Tân An",
    code: 10840,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 294,
  },
  {
    name: "Xã Liên Mạc",
    code: 10843,
    division_type: "xã",
    codename: "xa_lien_mac",
    district_code: 294,
  },
  {
    name: "Xã Thanh Hải",
    code: 10846,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 294,
  },
  {
    name: "Xã Thanh Khê",
    code: 10849,
    division_type: "xã",
    codename: "xa_thanh_khe",
    district_code: 294,
  },
  {
    name: "Xã Thanh Xá",
    code: 10852,
    division_type: "xã",
    codename: "xa_thanh_xa",
    district_code: 294,
  },
  {
    name: "Xã Thanh Xuân",
    code: 10855,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 294,
  },
  {
    name: "Xã Thanh Thủy",
    code: 10861,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 294,
  },
  {
    name: "Xã An Phượng",
    code: 10864,
    division_type: "xã",
    codename: "xa_an_phuong",
    district_code: 294,
  },
  {
    name: "Xã Thanh Sơn",
    code: 10867,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 294,
  },
  {
    name: "Xã Thanh Quang",
    code: 10876,
    division_type: "xã",
    codename: "xa_thanh_quang",
    district_code: 294,
  },
  {
    name: "Xã Thanh Hồng",
    code: 10879,
    division_type: "xã",
    codename: "xa_thanh_hong",
    district_code: 294,
  },
  {
    name: "Xã Thanh Cường",
    code: 10882,
    division_type: "xã",
    codename: "xa_thanh_cuong",
    district_code: 294,
  },
  {
    name: "Xã Vĩnh Lập",
    code: 10885,
    division_type: "xã",
    codename: "xa_vinh_lap",
    district_code: 294,
  },
  {
    name: "Thị trấn Cẩm Giang",
    code: 10888,
    division_type: "thị trấn",
    codename: "thi_tran_cam_giang",
    district_code: 295,
  },
  {
    name: "Thị trấn Lai Cách",
    code: 10891,
    division_type: "thị trấn",
    codename: "thi_tran_lai_cach",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Hưng",
    code: 10894,
    division_type: "xã",
    codename: "xa_cam_hung",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Hoàng",
    code: 10897,
    division_type: "xã",
    codename: "xa_cam_hoang",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Văn",
    code: 10900,
    division_type: "xã",
    codename: "xa_cam_van",
    district_code: 295,
  },
  {
    name: "Xã Ngọc Liên",
    code: 10903,
    division_type: "xã",
    codename: "xa_ngoc_lien",
    district_code: 295,
  },
  {
    name: "Xã Thạch Lỗi",
    code: 10906,
    division_type: "xã",
    codename: "xa_thach_loi",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Vũ",
    code: 10909,
    division_type: "xã",
    codename: "xa_cam_vu",
    district_code: 295,
  },
  {
    name: "Xã Đức Chính",
    code: 10912,
    division_type: "xã",
    codename: "xa_duc_chinh",
    district_code: 295,
  },
  {
    name: "Xã Định Sơn",
    code: 10918,
    division_type: "xã",
    codename: "xa_dinh_son",
    district_code: 295,
  },
  {
    name: "Xã Lương Điền",
    code: 10924,
    division_type: "xã",
    codename: "xa_luong_dien",
    district_code: 295,
  },
  {
    name: "Xã Cao An",
    code: 10927,
    division_type: "xã",
    codename: "xa_cao_an",
    district_code: 295,
  },
  {
    name: "Xã Tân Trường",
    code: 10930,
    division_type: "xã",
    codename: "xa_tan_truong",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Phúc",
    code: 10933,
    division_type: "xã",
    codename: "xa_cam_phuc",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Điền",
    code: 10936,
    division_type: "xã",
    codename: "xa_cam_dien",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Đông",
    code: 10939,
    division_type: "xã",
    codename: "xa_cam_dong",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Đoài",
    code: 10942,
    division_type: "xã",
    codename: "xa_cam_doai",
    district_code: 295,
  },
  {
    name: "Thị trấn Kẻ Sặt",
    code: 10945,
    division_type: "thị trấn",
    codename: "thi_tran_ke_sat",
    district_code: 296,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 10951,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 296,
  },
  {
    name: "Xã Hùng Thắng",
    code: 10954,
    division_type: "xã",
    codename: "xa_hung_thang",
    district_code: 296,
  },
  {
    name: "Xã Vĩnh Hồng",
    code: 10960,
    division_type: "xã",
    codename: "xa_vinh_hong",
    district_code: 296,
  },
  {
    name: "Xã Long Xuyên",
    code: 10963,
    division_type: "xã",
    codename: "xa_long_xuyen",
    district_code: 296,
  },
  {
    name: "Xã Tân Việt",
    code: 10966,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 296,
  },
  {
    name: "Xã Thúc Kháng",
    code: 10969,
    division_type: "xã",
    codename: "xa_thuc_khang",
    district_code: 296,
  },
  {
    name: "Xã Tân Hồng",
    code: 10972,
    division_type: "xã",
    codename: "xa_tan_hong",
    district_code: 296,
  },
  {
    name: "Xã Bình Minh",
    code: 10975,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 296,
  },
  {
    name: "Xã Hồng Khê",
    code: 10978,
    division_type: "xã",
    codename: "xa_hong_khe",
    district_code: 296,
  },
  {
    name: "Xã Thái Học",
    code: 10981,
    division_type: "xã",
    codename: "xa_thai_hoc",
    district_code: 296,
  },
  {
    name: "Xã Cổ Bì",
    code: 10984,
    division_type: "xã",
    codename: "xa_co_bi",
    district_code: 296,
  },
  {
    name: "Xã Nhân Quyền",
    code: 10987,
    division_type: "xã",
    codename: "xa_nhan_quyen",
    district_code: 296,
  },
  {
    name: "Xã Thái Dương",
    code: 10990,
    division_type: "xã",
    codename: "xa_thai_duong",
    district_code: 296,
  },
  {
    name: "Xã Thái Hòa",
    code: 10993,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 296,
  },
  {
    name: "Xã Bình Xuyên",
    code: 10996,
    division_type: "xã",
    codename: "xa_binh_xuyen",
    district_code: 296,
  },
  {
    name: "Thị trấn Gia Lộc",
    code: 10999,
    division_type: "thị trấn",
    codename: "thi_tran_gia_loc",
    district_code: 297,
  },
  {
    name: "Xã Thống Nhất",
    code: 11008,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 297,
  },
  {
    name: "Xã Yết Kiêu",
    code: 11020,
    division_type: "xã",
    codename: "xa_yet_kieu",
    district_code: 297,
  },
  {
    name: "Xã Gia Tân",
    code: 11029,
    division_type: "xã",
    codename: "xa_gia_tan",
    district_code: 297,
  },
  {
    name: "Xã Tân Tiến",
    code: 11032,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 297,
  },
  {
    name: "Xã Gia Khánh",
    code: 11035,
    division_type: "xã",
    codename: "xa_gia_khanh",
    district_code: 297,
  },
  {
    name: "Xã Gia Lương",
    code: 11038,
    division_type: "xã",
    codename: "xa_gia_luong",
    district_code: 297,
  },
  {
    name: "Xã Lê Lợi",
    code: 11041,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 297,
  },
  {
    name: "Xã Toàn Thắng",
    code: 11044,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 297,
  },
  {
    name: "Xã Hoàng Diệu",
    code: 11047,
    division_type: "xã",
    codename: "xa_hoang_dieu",
    district_code: 297,
  },
  {
    name: "Xã Hồng Hưng",
    code: 11050,
    division_type: "xã",
    codename: "xa_hong_hung",
    district_code: 297,
  },
  {
    name: "Xã Phạm Trấn",
    code: 11053,
    division_type: "xã",
    codename: "xa_pham_tran",
    district_code: 297,
  },
  {
    name: "Xã Đoàn Thượng",
    code: 11056,
    division_type: "xã",
    codename: "xa_doan_thuong",
    district_code: 297,
  },
  {
    name: "Xã Thống Kênh",
    code: 11059,
    division_type: "xã",
    codename: "xa_thong_kenh",
    district_code: 297,
  },
  {
    name: "Xã Quang Minh",
    code: 11062,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 297,
  },
  {
    name: "Xã Đồng Quang",
    code: 11065,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 297,
  },
  {
    name: "Xã Nhật Tân",
    code: 11068,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 297,
  },
  {
    name: "Xã Đức Xương",
    code: 11071,
    division_type: "xã",
    codename: "xa_duc_xuong",
    district_code: 297,
  },
  {
    name: "Thị trấn Tứ Kỳ",
    code: 11074,
    division_type: "thị trấn",
    codename: "thi_tran_tu_ky",
    district_code: 298,
  },
  {
    name: "Xã Đại Sơn",
    code: 11083,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 298,
  },
  {
    name: "Xã Hưng Đạo",
    code: 11086,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 298,
  },
  {
    name: "Xã Ngọc Kỳ",
    code: 11089,
    division_type: "xã",
    codename: "xa_ngoc_ky",
    district_code: 298,
  },
  {
    name: "Xã Bình Lăng",
    code: 11092,
    division_type: "xã",
    codename: "xa_binh_lang",
    district_code: 298,
  },
  {
    name: "Xã Chí Minh",
    code: 11095,
    division_type: "xã",
    codename: "xa_chi_minh",
    district_code: 298,
  },
  {
    name: "Xã Tái Sơn",
    code: 11098,
    division_type: "xã",
    codename: "xa_tai_son",
    district_code: 298,
  },
  {
    name: "Xã Quang Phục",
    code: 11101,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 298,
  },
  {
    name: "Xã Dân Chủ",
    code: 11110,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 298,
  },
  {
    name: "Xã Tân Kỳ",
    code: 11113,
    division_type: "xã",
    codename: "xa_tan_ky",
    district_code: 298,
  },
  {
    name: "Xã Quang Khải",
    code: 11116,
    division_type: "xã",
    codename: "xa_quang_khai",
    district_code: 298,
  },
  {
    name: "Xã Đại Hợp",
    code: 11119,
    division_type: "xã",
    codename: "xa_dai_hop",
    district_code: 298,
  },
  {
    name: "Xã Quảng Nghiệp",
    code: 11122,
    division_type: "xã",
    codename: "xa_quang_nghiep",
    district_code: 298,
  },
  {
    name: "Xã An Thanh",
    code: 11125,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 298,
  },
  {
    name: "Xã Minh Đức",
    code: 11128,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 298,
  },
  {
    name: "Xã Văn Tố",
    code: 11131,
    division_type: "xã",
    codename: "xa_van_to",
    district_code: 298,
  },
  {
    name: "Xã Quang Trung",
    code: 11134,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 298,
  },
  {
    name: "Xã Phượng Kỳ",
    code: 11137,
    division_type: "xã",
    codename: "xa_phuong_ky",
    district_code: 298,
  },
  {
    name: "Xã Cộng Lạc",
    code: 11140,
    division_type: "xã",
    codename: "xa_cong_lac",
    district_code: 298,
  },
  {
    name: "Xã Tiên Động",
    code: 11143,
    division_type: "xã",
    codename: "xa_tien_dong",
    district_code: 298,
  },
  {
    name: "Xã Nguyên Giáp",
    code: 11146,
    division_type: "xã",
    codename: "xa_nguyen_giap",
    district_code: 298,
  },
  {
    name: "Xã Hà Kỳ",
    code: 11149,
    division_type: "xã",
    codename: "xa_ha_ky",
    district_code: 298,
  },
  {
    name: "Xã Hà Thanh",
    code: 11152,
    division_type: "xã",
    codename: "xa_ha_thanh",
    district_code: 298,
  },
  {
    name: "Thị trấn Ninh Giang",
    code: 11155,
    division_type: "thị trấn",
    codename: "thi_tran_ninh_giang",
    district_code: 299,
  },
  {
    name: "Xã Ứng Hoè",
    code: 11161,
    division_type: "xã",
    codename: "xa_ung_hoe",
    district_code: 299,
  },
  {
    name: "Xã Nghĩa An",
    code: 11164,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 299,
  },
  {
    name: "Xã Hồng Đức",
    code: 11167,
    division_type: "xã",
    codename: "xa_hong_duc",
    district_code: 299,
  },
  {
    name: "Xã An Đức",
    code: 11173,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 299,
  },
  {
    name: "Xã Vạn Phúc",
    code: 11176,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 299,
  },
  {
    name: "Xã Tân Hương",
    code: 11179,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 299,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 11185,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 299,
  },
  {
    name: "Xã Đông Xuyên",
    code: 11188,
    division_type: "xã",
    codename: "xa_dong_xuyen",
    district_code: 299,
  },
  {
    name: "Xã Tân Phong",
    code: 11197,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 299,
  },
  {
    name: "Xã Ninh Hải",
    code: 11200,
    division_type: "xã",
    codename: "xa_ninh_hai",
    district_code: 299,
  },
  {
    name: "Xã Đồng Tâm",
    code: 11203,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 299,
  },
  {
    name: "Xã Tân Quang",
    code: 11206,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 299,
  },
  {
    name: "Xã Kiến Quốc",
    code: 11209,
    division_type: "xã",
    codename: "xa_kien_quoc",
    district_code: 299,
  },
  {
    name: "Xã Hồng Dụ",
    code: 11215,
    division_type: "xã",
    codename: "xa_hong_du",
    district_code: 299,
  },
  {
    name: "Xã Văn Hội",
    code: 11218,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 299,
  },
  {
    name: "Xã Hồng Phong",
    code: 11224,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 299,
  },
  {
    name: "Xã Hiệp Lực",
    code: 11227,
    division_type: "xã",
    codename: "xa_hiep_luc",
    district_code: 299,
  },
  {
    name: "Xã Hồng Phúc",
    code: 11230,
    division_type: "xã",
    codename: "xa_hong_phuc",
    district_code: 299,
  },
  {
    name: "Xã Hưng Long",
    code: 11233,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 299,
  },
  {
    name: "Thị trấn Thanh Miện",
    code: 11239,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_mien",
    district_code: 300,
  },
  {
    name: "Xã Thanh Tùng",
    code: 11242,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 300,
  },
  {
    name: "Xã Phạm Kha",
    code: 11245,
    division_type: "xã",
    codename: "xa_pham_kha",
    district_code: 300,
  },
  {
    name: "Xã Ngô Quyền",
    code: 11248,
    division_type: "xã",
    codename: "xa_ngo_quyen",
    district_code: 300,
  },
  {
    name: "Xã Đoàn Tùng",
    code: 11251,
    division_type: "xã",
    codename: "xa_doan_tung",
    district_code: 300,
  },
  {
    name: "Xã Hồng Quang",
    code: 11254,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 300,
  },
  {
    name: "Xã Tân Trào",
    code: 11257,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 300,
  },
  {
    name: "Xã Lam Sơn",
    code: 11260,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 300,
  },
  {
    name: "Xã Đoàn Kết",
    code: 11263,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 300,
  },
  {
    name: "Xã Lê Hồng",
    code: 11266,
    division_type: "xã",
    codename: "xa_le_hong",
    district_code: 300,
  },
  {
    name: "Xã Tứ Cường",
    code: 11269,
    division_type: "xã",
    codename: "xa_tu_cuong",
    district_code: 300,
  },
  {
    name: "Xã Ngũ Hùng",
    code: 11275,
    division_type: "xã",
    codename: "xa_ngu_hung",
    district_code: 300,
  },
  {
    name: "Xã Cao Thắng",
    code: 11278,
    division_type: "xã",
    codename: "xa_cao_thang",
    district_code: 300,
  },
  {
    name: "Xã Chi Lăng Bắc",
    code: 11281,
    division_type: "xã",
    codename: "xa_chi_lang_bac",
    district_code: 300,
  },
  {
    name: "Xã Chi Lăng Nam",
    code: 11284,
    division_type: "xã",
    codename: "xa_chi_lang_nam",
    district_code: 300,
  },
  {
    name: "Xã Thanh Giang",
    code: 11287,
    division_type: "xã",
    codename: "xa_thanh_giang",
    district_code: 300,
  },
  {
    name: "Xã Hồng Phong",
    code: 11293,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 300,
  },
  {
    name: "Phường Quán Toan",
    code: 11296,
    division_type: "phường",
    codename: "phuong_quan_toan",
    district_code: 303,
  },
  {
    name: "Phường Hùng Vương",
    code: 11299,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 303,
  },
  {
    name: "Phường Sở Dầu",
    code: 11302,
    division_type: "phường",
    codename: "phuong_so_dau",
    district_code: 303,
  },
  {
    name: "Phường Thượng Lý",
    code: 11305,
    division_type: "phường",
    codename: "phuong_thuong_ly",
    district_code: 303,
  },
  {
    name: "Phường Hạ Lý",
    code: 11308,
    division_type: "phường",
    codename: "phuong_ha_ly",
    district_code: 303,
  },
  {
    name: "Phường Minh Khai",
    code: 11311,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 303,
  },
  {
    name: "Phường Trại Chuối",
    code: 11314,
    division_type: "phường",
    codename: "phuong_trai_chuoi",
    district_code: 303,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 11320,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 303,
  },
  {
    name: "Phường Phan Bội Châu",
    code: 11323,
    division_type: "phường",
    codename: "phuong_phan_boi_chau",
    district_code: 303,
  },
  {
    name: "Phường Máy Chai",
    code: 11329,
    division_type: "phường",
    codename: "phuong_may_chai",
    district_code: 304,
  },
  {
    name: "Phường Máy Tơ",
    code: 11332,
    division_type: "phường",
    codename: "phuong_may_to",
    district_code: 304,
  },
  {
    name: "Phường Vạn Mỹ",
    code: 11335,
    division_type: "phường",
    codename: "phuong_van_my",
    district_code: 304,
  },
  {
    name: "Phường Cầu Tre",
    code: 11338,
    division_type: "phường",
    codename: "phuong_cau_tre",
    district_code: 304,
  },
  {
    name: "Phường Lạc Viên",
    code: 11341,
    division_type: "phường",
    codename: "phuong_lac_vien",
    district_code: 304,
  },
  {
    name: "Phường Gia Viên",
    code: 11347,
    division_type: "phường",
    codename: "phuong_gia_vien",
    district_code: 304,
  },
  {
    name: "Phường Đông Khê",
    code: 11350,
    division_type: "phường",
    codename: "phuong_dong_khe",
    district_code: 304,
  },
  {
    name: "Phường Cầu Đất",
    code: 11353,
    division_type: "phường",
    codename: "phuong_cau_dat",
    district_code: 304,
  },
  {
    name: "Phường Lê Lợi",
    code: 11356,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 304,
  },
  {
    name: "Phường Đằng Giang",
    code: 11359,
    division_type: "phường",
    codename: "phuong_dang_giang",
    district_code: 304,
  },
  {
    name: "Phường Lạch Tray",
    code: 11362,
    division_type: "phường",
    codename: "phuong_lach_tray",
    district_code: 304,
  },
  {
    name: "Phường Đổng Quốc Bình",
    code: 11365,
    division_type: "phường",
    codename: "phuong_dong_quoc_binh",
    district_code: 304,
  },
  {
    name: "Phường Cát Dài",
    code: 11368,
    division_type: "phường",
    codename: "phuong_cat_dai",
    district_code: 305,
  },
  {
    name: "Phường An Biên",
    code: 11371,
    division_type: "phường",
    codename: "phuong_an_bien",
    district_code: 305,
  },
  {
    name: "Phường Lam Sơn",
    code: 11374,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 305,
  },
  {
    name: "Phường An Dương",
    code: 11377,
    division_type: "phường",
    codename: "phuong_an_duong",
    district_code: 305,
  },
  {
    name: "Phường Trần Nguyên Hãn",
    code: 11380,
    division_type: "phường",
    codename: "phuong_tran_nguyen_han",
    district_code: 305,
  },
  {
    name: "Phường Hồ Nam",
    code: 11383,
    division_type: "phường",
    codename: "phuong_ho_nam",
    district_code: 305,
  },
  {
    name: "Phường Trại Cau",
    code: 11386,
    division_type: "phường",
    codename: "phuong_trai_cau",
    district_code: 305,
  },
  {
    name: "Phường Dư Hàng",
    code: 11389,
    division_type: "phường",
    codename: "phuong_du_hang",
    district_code: 305,
  },
  {
    name: "Phường Hàng Kênh",
    code: 11392,
    division_type: "phường",
    codename: "phuong_hang_kenh",
    district_code: 305,
  },
  {
    name: "Phường Đông Hải",
    code: 11395,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 305,
  },
  {
    name: "Phường Niệm Nghĩa",
    code: 11398,
    division_type: "phường",
    codename: "phuong_niem_nghia",
    district_code: 305,
  },
  {
    name: "Phường Nghĩa Xá",
    code: 11401,
    division_type: "phường",
    codename: "phuong_nghia_xa",
    district_code: 305,
  },
  {
    name: "Phường Dư Hàng Kênh",
    code: 11404,
    division_type: "phường",
    codename: "phuong_du_hang_kenh",
    district_code: 305,
  },
  {
    name: "Phường Kênh Dương",
    code: 11405,
    division_type: "phường",
    codename: "phuong_kenh_duong",
    district_code: 305,
  },
  {
    name: "Phường Vĩnh Niệm",
    code: 11407,
    division_type: "phường",
    codename: "phuong_vinh_niem",
    district_code: 305,
  },
  {
    name: "Phường Đông Hải 1",
    code: 11410,
    division_type: "phường",
    codename: "phuong_dong_hai_1",
    district_code: 306,
  },
  {
    name: "Phường Đông Hải 2",
    code: 11411,
    division_type: "phường",
    codename: "phuong_dong_hai_2",
    district_code: 306,
  },
  {
    name: "Phường Đằng Lâm",
    code: 11413,
    division_type: "phường",
    codename: "phuong_dang_lam",
    district_code: 306,
  },
  {
    name: "Phường Thành Tô",
    code: 11414,
    division_type: "phường",
    codename: "phuong_thanh_to",
    district_code: 306,
  },
  {
    name: "Phường Đằng Hải",
    code: 11416,
    division_type: "phường",
    codename: "phuong_dang_hai",
    district_code: 306,
  },
  {
    name: "Phường Nam Hải",
    code: 11419,
    division_type: "phường",
    codename: "phuong_nam_hai",
    district_code: 306,
  },
  {
    name: "Phường Cát Bi",
    code: 11422,
    division_type: "phường",
    codename: "phuong_cat_bi",
    district_code: 306,
  },
  {
    name: "Phường Tràng Cát",
    code: 11425,
    division_type: "phường",
    codename: "phuong_trang_cat",
    district_code: 306,
  },
  {
    name: "Phường Quán Trữ",
    code: 11428,
    division_type: "phường",
    codename: "phuong_quan_tru",
    district_code: 307,
  },
  {
    name: "Phường Lãm Hà",
    code: 11429,
    division_type: "phường",
    codename: "phuong_lam_ha",
    district_code: 307,
  },
  {
    name: "Phường Đồng Hoà",
    code: 11431,
    division_type: "phường",
    codename: "phuong_dong_hoa",
    district_code: 307,
  },
  {
    name: "Phường Bắc Sơn",
    code: 11434,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 307,
  },
  {
    name: "Phường Nam Sơn",
    code: 11437,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 307,
  },
  {
    name: "Phường Ngọc Sơn",
    code: 11440,
    division_type: "phường",
    codename: "phuong_ngoc_son",
    district_code: 307,
  },
  {
    name: "Phường Trần Thành Ngọ",
    code: 11443,
    division_type: "phường",
    codename: "phuong_tran_thanh_ngo",
    district_code: 307,
  },
  {
    name: "Phường Văn Đẩu",
    code: 11446,
    division_type: "phường",
    codename: "phuong_van_dau",
    district_code: 307,
  },
  {
    name: "Phường Phù Liễn",
    code: 11449,
    division_type: "phường",
    codename: "phuong_phu_lien",
    district_code: 307,
  },
  {
    name: "Phường Tràng Minh",
    code: 11452,
    division_type: "phường",
    codename: "phuong_trang_minh",
    district_code: 307,
  },
  {
    name: "Phường Ngọc Xuyên",
    code: 11455,
    division_type: "phường",
    codename: "phuong_ngoc_xuyen",
    district_code: 308,
  },
  {
    name: "Phường Hải Sơn",
    code: 11458,
    division_type: "phường",
    codename: "phuong_hai_son",
    district_code: 308,
  },
  {
    name: "Phường Vạn Hương",
    code: 11461,
    division_type: "phường",
    codename: "phuong_van_huong",
    district_code: 308,
  },
  {
    name: "Phường Minh Đức",
    code: 11465,
    division_type: "phường",
    codename: "phuong_minh_duc",
    district_code: 308,
  },
  {
    name: "Phường Bàng La",
    code: 11467,
    division_type: "phường",
    codename: "phuong_bang_la",
    district_code: 308,
  },
  {
    name: "Phường Hợp Đức",
    code: 11737,
    division_type: "phường",
    codename: "phuong_hop_duc",
    district_code: 308,
  },
  {
    name: "Phường Đa Phúc",
    code: 11683,
    division_type: "phường",
    codename: "phuong_da_phuc",
    district_code: 309,
  },
  {
    name: "Phường Hưng Đạo",
    code: 11686,
    division_type: "phường",
    codename: "phuong_hung_dao",
    district_code: 309,
  },
  {
    name: "Phường Anh Dũng",
    code: 11689,
    division_type: "phường",
    codename: "phuong_anh_dung",
    district_code: 309,
  },
  {
    name: "Phường Hải Thành",
    code: 11692,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 309,
  },
  {
    name: "Phường Hoà Nghĩa",
    code: 11707,
    division_type: "phường",
    codename: "phuong_hoa_nghia",
    district_code: 309,
  },
  {
    name: "Phường Tân Thành",
    code: 11740,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 309,
  },
  {
    name: "Thị trấn Núi Đèo",
    code: 11470,
    division_type: "thị trấn",
    codename: "thi_tran_nui_deo",
    district_code: 311,
  },
  {
    name: "Thị trấn Minh Đức",
    code: 11473,
    division_type: "thị trấn",
    codename: "thi_tran_minh_duc",
    district_code: 311,
  },
  {
    name: "Xã Lại Xuân",
    code: 11476,
    division_type: "xã",
    codename: "xa_lai_xuan",
    district_code: 311,
  },
  {
    name: "Xã An Sơn",
    code: 11479,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 311,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 11482,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 311,
  },
  {
    name: "Xã Liên Khê",
    code: 11485,
    division_type: "xã",
    codename: "xa_lien_khe",
    district_code: 311,
  },
  {
    name: "Xã Lưu Kiếm",
    code: 11488,
    division_type: "xã",
    codename: "xa_luu_kiem",
    district_code: 311,
  },
  {
    name: "Xã Lưu Kỳ",
    code: 11491,
    division_type: "xã",
    codename: "xa_luu_ky",
    district_code: 311,
  },
  {
    name: "Xã Gia Minh",
    code: 11494,
    division_type: "xã",
    codename: "xa_gia_minh",
    district_code: 311,
  },
  {
    name: "Xã Gia Đức",
    code: 11497,
    division_type: "xã",
    codename: "xa_gia_duc",
    district_code: 311,
  },
  {
    name: "Xã Minh Tân",
    code: 11500,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 311,
  },
  {
    name: "Xã Phù Ninh",
    code: 11503,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 311,
  },
  {
    name: "Xã Quảng Thanh",
    code: 11506,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 311,
  },
  {
    name: "Xã Chính Mỹ",
    code: 11509,
    division_type: "xã",
    codename: "xa_chinh_my",
    district_code: 311,
  },
  {
    name: "Xã Kênh Giang",
    code: 11512,
    division_type: "xã",
    codename: "xa_kenh_giang",
    district_code: 311,
  },
  {
    name: "Xã Hợp Thành",
    code: 11515,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 311,
  },
  {
    name: "Xã Cao Nhân",
    code: 11518,
    division_type: "xã",
    codename: "xa_cao_nhan",
    district_code: 311,
  },
  {
    name: "Xã Mỹ Đồng",
    code: 11521,
    division_type: "xã",
    codename: "xa_my_dong",
    district_code: 311,
  },
  {
    name: "Xã Đông Sơn",
    code: 11524,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 311,
  },
  {
    name: "Xã Hoà Bình",
    code: 11527,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 311,
  },
  {
    name: "Xã Trung Hà",
    code: 11530,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 311,
  },
  {
    name: "Xã An Lư",
    code: 11533,
    division_type: "xã",
    codename: "xa_an_lu",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Triều",
    code: 11536,
    division_type: "xã",
    codename: "xa_thuy_trieu",
    district_code: 311,
  },
  {
    name: "Xã Ngũ Lão",
    code: 11539,
    division_type: "xã",
    codename: "xa_ngu_lao",
    district_code: 311,
  },
  {
    name: "Xã Phục Lễ",
    code: 11542,
    division_type: "xã",
    codename: "xa_phuc_le",
    district_code: 311,
  },
  {
    name: "Xã Tam Hưng",
    code: 11545,
    division_type: "xã",
    codename: "xa_tam_hung",
    district_code: 311,
  },
  {
    name: "Xã Phả Lễ",
    code: 11548,
    division_type: "xã",
    codename: "xa_pha_le",
    district_code: 311,
  },
  {
    name: "Xã Lập Lễ",
    code: 11551,
    division_type: "xã",
    codename: "xa_lap_le",
    district_code: 311,
  },
  {
    name: "Xã Kiền Bái",
    code: 11554,
    division_type: "xã",
    codename: "xa_kien_bai",
    district_code: 311,
  },
  {
    name: "Xã Thiên Hương",
    code: 11557,
    division_type: "xã",
    codename: "xa_thien_huong",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Sơn",
    code: 11560,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Đường",
    code: 11563,
    division_type: "xã",
    codename: "xa_thuy_duong",
    district_code: 311,
  },
  {
    name: "Xã Hoàng Động",
    code: 11566,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 311,
  },
  {
    name: "Xã Lâm Động",
    code: 11569,
    division_type: "xã",
    codename: "xa_lam_dong",
    district_code: 311,
  },
  {
    name: "Xã Hoa Động",
    code: 11572,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 311,
  },
  {
    name: "Xã Tân Dương",
    code: 11575,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 311,
  },
  {
    name: "Xã Dương Quan",
    code: 11578,
    division_type: "xã",
    codename: "xa_duong_quan",
    district_code: 311,
  },
  {
    name: "Thị trấn An Dương",
    code: 11581,
    division_type: "thị trấn",
    codename: "thi_tran_an_duong",
    district_code: 312,
  },
  {
    name: "Xã Lê Thiện",
    code: 11584,
    division_type: "xã",
    codename: "xa_le_thien",
    district_code: 312,
  },
  {
    name: "Xã Đại Bản",
    code: 11587,
    division_type: "xã",
    codename: "xa_dai_ban",
    district_code: 312,
  },
  {
    name: "Xã An Hoà",
    code: 11590,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 312,
  },
  {
    name: "Xã Hồng Phong",
    code: 11593,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 312,
  },
  {
    name: "Xã Tân Tiến",
    code: 11596,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 312,
  },
  {
    name: "Xã An Hưng",
    code: 11599,
    division_type: "xã",
    codename: "xa_an_hung",
    district_code: 312,
  },
  {
    name: "Xã An Hồng",
    code: 11602,
    division_type: "xã",
    codename: "xa_an_hong",
    district_code: 312,
  },
  {
    name: "Xã Bắc Sơn",
    code: 11605,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 312,
  },
  {
    name: "Xã Nam Sơn",
    code: 11608,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 312,
  },
  {
    name: "Xã Lê Lợi",
    code: 11611,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 312,
  },
  {
    name: "Xã Đặng Cương",
    code: 11614,
    division_type: "xã",
    codename: "xa_dang_cuong",
    district_code: 312,
  },
  {
    name: "Xã Đồng Thái",
    code: 11617,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 312,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 11620,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 312,
  },
  {
    name: "Xã An Đồng",
    code: 11623,
    division_type: "xã",
    codename: "xa_an_dong",
    district_code: 312,
  },
  {
    name: "Xã Hồng Thái",
    code: 11626,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 312,
  },
  {
    name: "Thị trấn An Lão",
    code: 11629,
    division_type: "thị trấn",
    codename: "thi_tran_an_lao",
    district_code: 313,
  },
  {
    name: "Xã Bát Trang",
    code: 11632,
    division_type: "xã",
    codename: "xa_bat_trang",
    district_code: 313,
  },
  {
    name: "Xã Trường Thọ",
    code: 11635,
    division_type: "xã",
    codename: "xa_truong_tho",
    district_code: 313,
  },
  {
    name: "Xã Trường Thành",
    code: 11638,
    division_type: "xã",
    codename: "xa_truong_thanh",
    district_code: 313,
  },
  {
    name: "Xã An Tiến",
    code: 11641,
    division_type: "xã",
    codename: "xa_an_tien",
    district_code: 313,
  },
  {
    name: "Xã Quang Hưng",
    code: 11644,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 313,
  },
  {
    name: "Xã Quang Trung",
    code: 11647,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 313,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 11650,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 313,
  },
  {
    name: "Xã An Thắng",
    code: 11653,
    division_type: "xã",
    codename: "xa_an_thang",
    district_code: 313,
  },
  {
    name: "Thị trấn Trường Sơn",
    code: 11656,
    division_type: "thị trấn",
    codename: "thi_tran_truong_son",
    district_code: 313,
  },
  {
    name: "Xã Tân Dân",
    code: 11659,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 313,
  },
  {
    name: "Xã Thái Sơn",
    code: 11662,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 313,
  },
  {
    name: "Xã Tân Viên",
    code: 11665,
    division_type: "xã",
    codename: "xa_tan_vien",
    district_code: 313,
  },
  {
    name: "Xã Mỹ Đức",
    code: 11668,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 313,
  },
  {
    name: "Xã Chiến Thắng",
    code: 11671,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 313,
  },
  {
    name: "Xã An Thọ",
    code: 11674,
    division_type: "xã",
    codename: "xa_an_tho",
    district_code: 313,
  },
  {
    name: "Xã An Thái",
    code: 11677,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 313,
  },
  {
    name: "Thị trấn Núi Đối",
    code: 11680,
    division_type: "thị trấn",
    codename: "thi_tran_nui_doi",
    district_code: 314,
  },
  {
    name: "Xã Đông Phương",
    code: 11695,
    division_type: "xã",
    codename: "xa_dong_phuong",
    district_code: 314,
  },
  {
    name: "Xã Thuận Thiên",
    code: 11698,
    division_type: "xã",
    codename: "xa_thuan_thien",
    district_code: 314,
  },
  {
    name: "Xã Hữu Bằng",
    code: 11701,
    division_type: "xã",
    codename: "xa_huu_bang",
    district_code: 314,
  },
  {
    name: "Xã Đại Đồng",
    code: 11704,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 314,
  },
  {
    name: "Xã Ngũ Phúc",
    code: 11710,
    division_type: "xã",
    codename: "xa_ngu_phuc",
    district_code: 314,
  },
  {
    name: "Xã Kiến Quốc",
    code: 11713,
    division_type: "xã",
    codename: "xa_kien_quoc",
    district_code: 314,
  },
  {
    name: "Xã Du Lễ",
    code: 11716,
    division_type: "xã",
    codename: "xa_du_le",
    district_code: 314,
  },
  {
    name: "Xã Thuỵ Hương",
    code: 11719,
    division_type: "xã",
    codename: "xa_thuy_huong",
    district_code: 314,
  },
  {
    name: "Xã Thanh Sơn",
    code: 11722,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 314,
  },
  {
    name: "Xã Minh Tân",
    code: 11725,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 314,
  },
  {
    name: "Xã Đại Hà",
    code: 11728,
    division_type: "xã",
    codename: "xa_dai_ha",
    district_code: 314,
  },
  {
    name: "Xã Ngũ Đoan",
    code: 11731,
    division_type: "xã",
    codename: "xa_ngu_doan",
    district_code: 314,
  },
  {
    name: "Xã Tân Phong",
    code: 11734,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 314,
  },
  {
    name: "Xã Tân Trào",
    code: 11743,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 314,
  },
  {
    name: "Xã Đoàn Xá",
    code: 11746,
    division_type: "xã",
    codename: "xa_doan_xa",
    district_code: 314,
  },
  {
    name: "Xã Tú Sơn",
    code: 11749,
    division_type: "xã",
    codename: "xa_tu_son",
    district_code: 314,
  },
  {
    name: "Xã Đại Hợp",
    code: 11752,
    division_type: "xã",
    codename: "xa_dai_hop",
    district_code: 314,
  },
  {
    name: "Thị trấn Tiên Lãng",
    code: 11755,
    division_type: "thị trấn",
    codename: "thi_tran_tien_lang",
    district_code: 315,
  },
  {
    name: "Xã Đại Thắng",
    code: 11758,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Cường",
    code: 11761,
    division_type: "xã",
    codename: "xa_tien_cuong",
    district_code: 315,
  },
  {
    name: "Xã Tự Cường",
    code: 11764,
    division_type: "xã",
    codename: "xa_tu_cuong",
    district_code: 315,
  },
  {
    name: "Xã Quyết Tiến",
    code: 11770,
    division_type: "xã",
    codename: "xa_quyet_tien",
    district_code: 315,
  },
  {
    name: "Xã Khởi Nghĩa",
    code: 11773,
    division_type: "xã",
    codename: "xa_khoi_nghia",
    district_code: 315,
  },
  {
    name: "Xã Tiên Thanh",
    code: 11776,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 315,
  },
  {
    name: "Xã Cấp Tiến",
    code: 11779,
    division_type: "xã",
    codename: "xa_cap_tien",
    district_code: 315,
  },
  {
    name: "Xã Kiến Thiết",
    code: 11782,
    division_type: "xã",
    codename: "xa_kien_thiet",
    district_code: 315,
  },
  {
    name: "Xã Đoàn Lập",
    code: 11785,
    division_type: "xã",
    codename: "xa_doan_lap",
    district_code: 315,
  },
  {
    name: "Xã Bạch Đằng",
    code: 11788,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 315,
  },
  {
    name: "Xã Quang Phục",
    code: 11791,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 315,
  },
  {
    name: "Xã Toàn Thắng",
    code: 11794,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Thắng",
    code: 11797,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Minh",
    code: 11800,
    division_type: "xã",
    codename: "xa_tien_minh",
    district_code: 315,
  },
  {
    name: "Xã Bắc Hưng",
    code: 11803,
    division_type: "xã",
    codename: "xa_bac_hung",
    district_code: 315,
  },
  {
    name: "Xã Nam Hưng",
    code: 11806,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 315,
  },
  {
    name: "Xã Hùng Thắng",
    code: 11809,
    division_type: "xã",
    codename: "xa_hung_thang",
    district_code: 315,
  },
  {
    name: "Xã Tây Hưng",
    code: 11812,
    division_type: "xã",
    codename: "xa_tay_hung",
    district_code: 315,
  },
  {
    name: "Xã Đông Hưng",
    code: 11815,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 315,
  },
  {
    name: "Xã Vinh Quang",
    code: 11821,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 315,
  },
  {
    name: "Thị trấn Vĩnh Bảo",
    code: 11824,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_bao",
    district_code: 316,
  },
  {
    name: "Xã Dũng Tiến",
    code: 11827,
    division_type: "xã",
    codename: "xa_dung_tien",
    district_code: 316,
  },
  {
    name: "Xã Giang Biên",
    code: 11830,
    division_type: "xã",
    codename: "xa_giang_bien",
    district_code: 316,
  },
  {
    name: "Xã Thắng Thuỷ",
    code: 11833,
    division_type: "xã",
    codename: "xa_thang_thuy",
    district_code: 316,
  },
  {
    name: "Xã Trung Lập",
    code: 11836,
    division_type: "xã",
    codename: "xa_trung_lap",
    district_code: 316,
  },
  {
    name: "Xã Việt Tiến",
    code: 11839,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh An",
    code: 11842,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Long",
    code: 11845,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 316,
  },
  {
    name: "Xã Hiệp Hoà",
    code: 11848,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 316,
  },
  {
    name: "Xã Hùng Tiến",
    code: 11851,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 316,
  },
  {
    name: "Xã An Hoà",
    code: 11854,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 316,
  },
  {
    name: "Xã Tân Hưng",
    code: 11857,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 316,
  },
  {
    name: "Xã Tân Liên",
    code: 11860,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 316,
  },
  {
    name: "Xã Nhân Hoà",
    code: 11863,
    division_type: "xã",
    codename: "xa_nhan_hoa",
    district_code: 316,
  },
  {
    name: "Xã Tam Đa",
    code: 11866,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 316,
  },
  {
    name: "Xã Hưng Nhân",
    code: 11869,
    division_type: "xã",
    codename: "xa_hung_nhan",
    district_code: 316,
  },
  {
    name: "Xã Vinh Quang",
    code: 11872,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 316,
  },
  {
    name: "Xã Đồng Minh",
    code: 11875,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 316,
  },
  {
    name: "Xã Thanh Lương",
    code: 11878,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 316,
  },
  {
    name: "Xã Liên Am",
    code: 11881,
    division_type: "xã",
    codename: "xa_lien_am",
    district_code: 316,
  },
  {
    name: "Xã Lý Học",
    code: 11884,
    division_type: "xã",
    codename: "xa_ly_hoc",
    district_code: 316,
  },
  {
    name: "Xã Tam Cường",
    code: 11887,
    division_type: "xã",
    codename: "xa_tam_cuong",
    district_code: 316,
  },
  {
    name: "Xã Hoà Bình",
    code: 11890,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 316,
  },
  {
    name: "Xã Tiền Phong",
    code: 11893,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 11896,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 316,
  },
  {
    name: "Xã Cộng Hiền",
    code: 11899,
    division_type: "xã",
    codename: "xa_cong_hien",
    district_code: 316,
  },
  {
    name: "Xã Cao Minh",
    code: 11902,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 316,
  },
  {
    name: "Xã Cổ Am",
    code: 11905,
    division_type: "xã",
    codename: "xa_co_am",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 11908,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 316,
  },
  {
    name: "Xã Trấn Dương",
    code: 11911,
    division_type: "xã",
    codename: "xa_tran_duong",
    district_code: 316,
  },
  {
    name: "Thị trấn Cát Bà",
    code: 11914,
    division_type: "thị trấn",
    codename: "thi_tran_cat_ba",
    district_code: 317,
  },
  {
    name: "Thị trấn Cát Hải",
    code: 11917,
    division_type: "thị trấn",
    codename: "thi_tran_cat_hai",
    district_code: 317,
  },
  {
    name: "Xã Nghĩa Lộ",
    code: 11920,
    division_type: "xã",
    codename: "xa_nghia_lo",
    district_code: 317,
  },
  {
    name: "Xã Đồng Bài",
    code: 11923,
    division_type: "xã",
    codename: "xa_dong_bai",
    district_code: 317,
  },
  {
    name: "Xã Hoàng Châu",
    code: 11926,
    division_type: "xã",
    codename: "xa_hoang_chau",
    district_code: 317,
  },
  {
    name: "Xã Văn Phong",
    code: 11929,
    division_type: "xã",
    codename: "xa_van_phong",
    district_code: 317,
  },
  {
    name: "Xã Phù Long",
    code: 11932,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 317,
  },
  {
    name: "Xã Gia Luận",
    code: 11935,
    division_type: "xã",
    codename: "xa_gia_luan",
    district_code: 317,
  },
  {
    name: "Xã Hiền Hào",
    code: 11938,
    division_type: "xã",
    codename: "xa_hien_hao",
    district_code: 317,
  },
  {
    name: "Xã Trân Châu",
    code: 11941,
    division_type: "xã",
    codename: "xa_tran_chau",
    district_code: 317,
  },
  {
    name: "Xã Việt Hải",
    code: 11944,
    division_type: "xã",
    codename: "xa_viet_hai",
    district_code: 317,
  },
  {
    name: "Xã Xuân Đám",
    code: 11947,
    division_type: "xã",
    codename: "xa_xuan_dam",
    district_code: 317,
  },
  {
    name: "Phường Lam Sơn",
    code: 11950,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 323,
  },
  {
    name: "Phường Hiến Nam",
    code: 11953,
    division_type: "phường",
    codename: "phuong_hien_nam",
    district_code: 323,
  },
  {
    name: "Phường An Tảo",
    code: 11956,
    division_type: "phường",
    codename: "phuong_an_tao",
    district_code: 323,
  },
  {
    name: "Phường Lê Lợi",
    code: 11959,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 323,
  },
  {
    name: "Phường Minh Khai",
    code: 11962,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 323,
  },
  {
    name: "Phường Quang Trung",
    code: 11965,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 323,
  },
  {
    name: "Phường Hồng Châu",
    code: 11968,
    division_type: "phường",
    codename: "phuong_hong_chau",
    district_code: 323,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 11971,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 323,
  },
  {
    name: "Xã Liên Phương",
    code: 11974,
    division_type: "xã",
    codename: "xa_lien_phuong",
    district_code: 323,
  },
  {
    name: "Xã Hồng Nam",
    code: 11977,
    division_type: "xã",
    codename: "xa_hong_nam",
    district_code: 323,
  },
  {
    name: "Xã Quảng Châu",
    code: 11980,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 323,
  },
  {
    name: "Xã Bảo Khê",
    code: 11983,
    division_type: "xã",
    codename: "xa_bao_khe",
    district_code: 323,
  },
  {
    name: "Xã Phú Cường",
    code: 12331,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 323,
  },
  {
    name: "Xã Hùng Cường",
    code: 12334,
    division_type: "xã",
    codename: "xa_hung_cuong",
    district_code: 323,
  },
  {
    name: "Xã Phương Chiểu",
    code: 12382,
    division_type: "xã",
    codename: "xa_phuong_chieu",
    district_code: 323,
  },
  {
    name: "Xã Tân Hưng",
    code: 12385,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 323,
  },
  {
    name: "Xã Hoàng Hanh",
    code: 12388,
    division_type: "xã",
    codename: "xa_hoang_hanh",
    district_code: 323,
  },
  {
    name: "Thị trấn Như Quỳnh",
    code: 11986,
    division_type: "thị trấn",
    codename: "thi_tran_nhu_quynh",
    district_code: 325,
  },
  {
    name: "Xã Lạc Đạo",
    code: 11989,
    division_type: "xã",
    codename: "xa_lac_dao",
    district_code: 325,
  },
  {
    name: "Xã Chỉ Đạo",
    code: 11992,
    division_type: "xã",
    codename: "xa_chi_dao",
    district_code: 325,
  },
  {
    name: "Xã Đại Đồng",
    code: 11995,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 325,
  },
  {
    name: "Xã Việt Hưng",
    code: 11998,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 325,
  },
  {
    name: "Xã Tân Quang",
    code: 12001,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 325,
  },
  {
    name: "Xã Đình Dù",
    code: 12004,
    division_type: "xã",
    codename: "xa_dinh_du",
    district_code: 325,
  },
  {
    name: "Xã Minh Hải",
    code: 12007,
    division_type: "xã",
    codename: "xa_minh_hai",
    district_code: 325,
  },
  {
    name: "Xã Lương Tài",
    code: 12010,
    division_type: "xã",
    codename: "xa_luong_tai",
    district_code: 325,
  },
  {
    name: "Xã Trưng Trắc",
    code: 12013,
    division_type: "xã",
    codename: "xa_trung_trac",
    district_code: 325,
  },
  {
    name: "Xã Lạc Hồng",
    code: 12016,
    division_type: "xã",
    codename: "xa_lac_hong",
    district_code: 325,
  },
  {
    name: "Thị trấn Văn Giang",
    code: 12019,
    division_type: "thị trấn",
    codename: "thi_tran_van_giang",
    district_code: 326,
  },
  {
    name: "Xã Xuân Quan",
    code: 12022,
    division_type: "xã",
    codename: "xa_xuan_quan",
    district_code: 326,
  },
  {
    name: "Xã Cửu Cao",
    code: 12025,
    division_type: "xã",
    codename: "xa_cuu_cao",
    district_code: 326,
  },
  {
    name: "Xã Phụng Công",
    code: 12028,
    division_type: "xã",
    codename: "xa_phung_cong",
    district_code: 326,
  },
  {
    name: "Xã Nghĩa Trụ",
    code: 12031,
    division_type: "xã",
    codename: "xa_nghia_tru",
    district_code: 326,
  },
  {
    name: "Xã Long Hưng",
    code: 12034,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 326,
  },
  {
    name: "Xã Vĩnh Khúc",
    code: 12037,
    division_type: "xã",
    codename: "xa_vinh_khuc",
    district_code: 326,
  },
  {
    name: "Xã Liên Nghĩa",
    code: 12040,
    division_type: "xã",
    codename: "xa_lien_nghia",
    district_code: 326,
  },
  {
    name: "Xã Tân Tiến",
    code: 12043,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 326,
  },
  {
    name: "Xã Thắng Lợi",
    code: 12046,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 326,
  },
  {
    name: "Xã Mễ Sở",
    code: 12049,
    division_type: "xã",
    codename: "xa_me_so",
    district_code: 326,
  },
  {
    name: "Thị trấn Yên Mỹ",
    code: 12052,
    division_type: "thị trấn",
    codename: "thi_tran_yen_my",
    district_code: 327,
  },
  {
    name: "Xã Giai Phạm",
    code: 12055,
    division_type: "xã",
    codename: "xa_giai_pham",
    district_code: 327,
  },
  {
    name: "Xã Nghĩa Hiệp",
    code: 12058,
    division_type: "xã",
    codename: "xa_nghia_hiep",
    district_code: 327,
  },
  {
    name: "Xã Đồng Than",
    code: 12061,
    division_type: "xã",
    codename: "xa_dong_than",
    district_code: 327,
  },
  {
    name: "Xã Ngọc Long",
    code: 12064,
    division_type: "xã",
    codename: "xa_ngoc_long",
    district_code: 327,
  },
  {
    name: "Xã Liêu Xá",
    code: 12067,
    division_type: "xã",
    codename: "xa_lieu_xa",
    district_code: 327,
  },
  {
    name: "Xã Hoàn Long",
    code: 12070,
    division_type: "xã",
    codename: "xa_hoan_long",
    district_code: 327,
  },
  {
    name: "Xã Tân Lập",
    code: 12073,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 327,
  },
  {
    name: "Xã Thanh Long",
    code: 12076,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 327,
  },
  {
    name: "Xã Yên Phú",
    code: 12079,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 327,
  },
  {
    name: "Xã Việt Cường",
    code: 12082,
    division_type: "xã",
    codename: "xa_viet_cuong",
    district_code: 327,
  },
  {
    name: "Xã Trung Hòa",
    code: 12085,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 327,
  },
  {
    name: "Xã Yên Hòa",
    code: 12088,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 327,
  },
  {
    name: "Xã Minh Châu",
    code: 12091,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 327,
  },
  {
    name: "Xã Trung Hưng",
    code: 12094,
    division_type: "xã",
    codename: "xa_trung_hung",
    district_code: 327,
  },
  {
    name: "Xã Lý Thường Kiệt",
    code: 12097,
    division_type: "xã",
    codename: "xa_ly_thuong_kiet",
    district_code: 327,
  },
  {
    name: "Xã Tân Việt",
    code: 12100,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 327,
  },
  {
    name: "Phường Bần Yên Nhân",
    code: 12103,
    division_type: "phường",
    codename: "phuong_ban_yen_nhan",
    district_code: 328,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 12106,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 328,
  },
  {
    name: "Xã Cẩm Xá",
    code: 12109,
    division_type: "xã",
    codename: "xa_cam_xa",
    district_code: 328,
  },
  {
    name: "Xã Dương Quang",
    code: 12112,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 328,
  },
  {
    name: "Xã Hòa Phong",
    code: 12115,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 328,
  },
  {
    name: "Phường Nhân Hòa",
    code: 12118,
    division_type: "phường",
    codename: "phuong_nhan_hoa",
    district_code: 328,
  },
  {
    name: "Phường Dị Sử",
    code: 12121,
    division_type: "phường",
    codename: "phuong_di_su",
    district_code: 328,
  },
  {
    name: "Phường Bạch Sam",
    code: 12124,
    division_type: "phường",
    codename: "phuong_bach_sam",
    district_code: 328,
  },
  {
    name: "Phường Minh Đức",
    code: 12127,
    division_type: "phường",
    codename: "phuong_minh_duc",
    district_code: 328,
  },
  {
    name: "Phường Phùng Chí Kiên",
    code: 12130,
    division_type: "phường",
    codename: "phuong_phung_chi_kien",
    district_code: 328,
  },
  {
    name: "Xã Xuân Dục",
    code: 12133,
    division_type: "xã",
    codename: "xa_xuan_duc",
    district_code: 328,
  },
  {
    name: "Xã Ngọc Lâm",
    code: 12136,
    division_type: "xã",
    codename: "xa_ngoc_lam",
    district_code: 328,
  },
  {
    name: "Xã Hưng Long",
    code: 12139,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 328,
  },
  {
    name: "Thị trấn Ân Thi",
    code: 12142,
    division_type: "thị trấn",
    codename: "thi_tran_an_thi",
    district_code: 329,
  },
  {
    name: "Xã Phù Ủng",
    code: 12145,
    division_type: "xã",
    codename: "xa_phu_ung",
    district_code: 329,
  },
  {
    name: "Xã Bắc Sơn",
    code: 12148,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 329,
  },
  {
    name: "Xã Bãi Sậy",
    code: 12151,
    division_type: "xã",
    codename: "xa_bai_say",
    district_code: 329,
  },
  {
    name: "Xã Đào Dương",
    code: 12154,
    division_type: "xã",
    codename: "xa_dao_duong",
    district_code: 329,
  },
  {
    name: "Xã Tân Phúc",
    code: 12157,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 329,
  },
  {
    name: "Xã Vân Du",
    code: 12160,
    division_type: "xã",
    codename: "xa_van_du",
    district_code: 329,
  },
  {
    name: "Xã Quang Vinh",
    code: 12163,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 329,
  },
  {
    name: "Xã Xuân Trúc",
    code: 12166,
    division_type: "xã",
    codename: "xa_xuan_truc",
    district_code: 329,
  },
  {
    name: "Xã Hoàng Hoa Thám",
    code: 12169,
    division_type: "xã",
    codename: "xa_hoang_hoa_tham",
    district_code: 329,
  },
  {
    name: "Xã Quảng Lãng",
    code: 12172,
    division_type: "xã",
    codename: "xa_quang_lang",
    district_code: 329,
  },
  {
    name: "Xã Văn Nhuệ",
    code: 12175,
    division_type: "xã",
    codename: "xa_van_nhue",
    district_code: 329,
  },
  {
    name: "Xã Đặng Lễ",
    code: 12178,
    division_type: "xã",
    codename: "xa_dang_le",
    district_code: 329,
  },
  {
    name: "Xã Cẩm Ninh",
    code: 12181,
    division_type: "xã",
    codename: "xa_cam_ninh",
    district_code: 329,
  },
  {
    name: "Xã Nguyễn Trãi",
    code: 12184,
    division_type: "xã",
    codename: "xa_nguyen_trai",
    district_code: 329,
  },
  {
    name: "Xã Đa Lộc",
    code: 12187,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 329,
  },
  {
    name: "Xã Hồ Tùng Mậu",
    code: 12190,
    division_type: "xã",
    codename: "xa_ho_tung_mau",
    district_code: 329,
  },
  {
    name: "Xã Tiền Phong",
    code: 12193,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 329,
  },
  {
    name: "Xã Hồng Vân",
    code: 12196,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 329,
  },
  {
    name: "Xã Hồng Quang",
    code: 12199,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 329,
  },
  {
    name: "Xã Hạ Lễ",
    code: 12202,
    division_type: "xã",
    codename: "xa_ha_le",
    district_code: 329,
  },
  {
    name: "Thị trấn Khoái Châu",
    code: 12205,
    division_type: "thị trấn",
    codename: "thi_tran_khoai_chau",
    district_code: 330,
  },
  {
    name: "Xã Đông Tảo",
    code: 12208,
    division_type: "xã",
    codename: "xa_dong_tao",
    district_code: 330,
  },
  {
    name: "Xã Bình Minh",
    code: 12211,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 330,
  },
  {
    name: "Xã Dạ Trạch",
    code: 12214,
    division_type: "xã",
    codename: "xa_da_trach",
    district_code: 330,
  },
  {
    name: "Xã Hàm Tử",
    code: 12217,
    division_type: "xã",
    codename: "xa_ham_tu",
    district_code: 330,
  },
  {
    name: "Xã Ông Đình",
    code: 12220,
    division_type: "xã",
    codename: "xa_ong_dinh",
    district_code: 330,
  },
  {
    name: "Xã Tân Dân",
    code: 12223,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 330,
  },
  {
    name: "Xã Tứ Dân",
    code: 12226,
    division_type: "xã",
    codename: "xa_tu_dan",
    district_code: 330,
  },
  {
    name: "Xã An Vĩ",
    code: 12229,
    division_type: "xã",
    codename: "xa_an_vi",
    district_code: 330,
  },
  {
    name: "Xã Đông Kết",
    code: 12232,
    division_type: "xã",
    codename: "xa_dong_ket",
    district_code: 330,
  },
  {
    name: "Xã Bình Kiều",
    code: 12235,
    division_type: "xã",
    codename: "xa_binh_kieu",
    district_code: 330,
  },
  {
    name: "Xã Dân Tiến",
    code: 12238,
    division_type: "xã",
    codename: "xa_dan_tien",
    district_code: 330,
  },
  {
    name: "Xã Đồng Tiến",
    code: 12241,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 330,
  },
  {
    name: "Xã Hồng Tiến",
    code: 12244,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 330,
  },
  {
    name: "Xã Tân Châu",
    code: 12247,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 330,
  },
  {
    name: "Xã Liên Khê",
    code: 12250,
    division_type: "xã",
    codename: "xa_lien_khe",
    district_code: 330,
  },
  {
    name: "Xã Phùng Hưng",
    code: 12253,
    division_type: "xã",
    codename: "xa_phung_hung",
    district_code: 330,
  },
  {
    name: "Xã Việt Hòa",
    code: 12256,
    division_type: "xã",
    codename: "xa_viet_hoa",
    district_code: 330,
  },
  {
    name: "Xã Đông Ninh",
    code: 12259,
    division_type: "xã",
    codename: "xa_dong_ninh",
    district_code: 330,
  },
  {
    name: "Xã Đại Tập",
    code: 12262,
    division_type: "xã",
    codename: "xa_dai_tap",
    district_code: 330,
  },
  {
    name: "Xã Chí Tân",
    code: 12265,
    division_type: "xã",
    codename: "xa_chi_tan",
    district_code: 330,
  },
  {
    name: "Xã Đại Hưng",
    code: 12268,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 330,
  },
  {
    name: "Xã Thuần Hưng",
    code: 12271,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 330,
  },
  {
    name: "Xã Thành Công",
    code: 12274,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 330,
  },
  {
    name: "Xã Nhuế Dương",
    code: 12277,
    division_type: "xã",
    codename: "xa_nhue_duong",
    district_code: 330,
  },
  {
    name: "Thị trấn Lương Bằng",
    code: 12280,
    division_type: "thị trấn",
    codename: "thi_tran_luong_bang",
    district_code: 331,
  },
  {
    name: "Xã Nghĩa Dân",
    code: 12283,
    division_type: "xã",
    codename: "xa_nghia_dan",
    district_code: 331,
  },
  {
    name: "Xã Toàn Thắng",
    code: 12286,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 331,
  },
  {
    name: "Xã Vĩnh Xá",
    code: 12289,
    division_type: "xã",
    codename: "xa_vinh_xa",
    district_code: 331,
  },
  {
    name: "Xã Phạm Ngũ Lão",
    code: 12292,
    division_type: "xã",
    codename: "xa_pham_ngu_lao",
    district_code: 331,
  },
  {
    name: "Xã Thọ Vinh",
    code: 12295,
    division_type: "xã",
    codename: "xa_tho_vinh",
    district_code: 331,
  },
  {
    name: "Xã Đồng Thanh",
    code: 12298,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 331,
  },
  {
    name: "Xã Song Mai",
    code: 12301,
    division_type: "xã",
    codename: "xa_song_mai",
    district_code: 331,
  },
  {
    name: "Xã Chính Nghĩa",
    code: 12304,
    division_type: "xã",
    codename: "xa_chinh_nghia",
    district_code: 331,
  },
  {
    name: "Xã Nhân La",
    code: 12307,
    division_type: "xã",
    codename: "xa_nhan_la",
    district_code: 331,
  },
  {
    name: "Xã Phú Thịnh",
    code: 12310,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 331,
  },
  {
    name: "Xã Mai Động",
    code: 12313,
    division_type: "xã",
    codename: "xa_mai_dong",
    district_code: 331,
  },
  {
    name: "Xã Đức Hợp",
    code: 12316,
    division_type: "xã",
    codename: "xa_duc_hop",
    district_code: 331,
  },
  {
    name: "Xã Hùng An",
    code: 12319,
    division_type: "xã",
    codename: "xa_hung_an",
    district_code: 331,
  },
  {
    name: "Xã Ngọc Thanh",
    code: 12322,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 331,
  },
  {
    name: "Xã Vũ Xá",
    code: 12325,
    division_type: "xã",
    codename: "xa_vu_xa",
    district_code: 331,
  },
  {
    name: "Xã Hiệp Cường",
    code: 12328,
    division_type: "xã",
    codename: "xa_hiep_cuong",
    district_code: 331,
  },
  {
    name: "Thị trấn Vương",
    code: 12337,
    division_type: "thị trấn",
    codename: "thi_tran_vuong",
    district_code: 332,
  },
  {
    name: "Xã Hưng Đạo",
    code: 12340,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 332,
  },
  {
    name: "Xã Ngô Quyền",
    code: 12343,
    division_type: "xã",
    codename: "xa_ngo_quyen",
    district_code: 332,
  },
  {
    name: "Xã Nhật Tân",
    code: 12346,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 332,
  },
  {
    name: "Xã Dị Chế",
    code: 12349,
    division_type: "xã",
    codename: "xa_di_che",
    district_code: 332,
  },
  {
    name: "Xã Lệ Xá",
    code: 12352,
    division_type: "xã",
    codename: "xa_le_xa",
    district_code: 332,
  },
  {
    name: "Xã An Viên",
    code: 12355,
    division_type: "xã",
    codename: "xa_an_vien",
    district_code: 332,
  },
  {
    name: "Xã Đức Thắng",
    code: 12358,
    division_type: "xã",
    codename: "xa_duc_thang",
    district_code: 332,
  },
  {
    name: "Xã Trung Dũng",
    code: 12361,
    division_type: "xã",
    codename: "xa_trung_dung",
    district_code: 332,
  },
  {
    name: "Xã Hải Triều",
    code: 12364,
    division_type: "xã",
    codename: "xa_hai_trieu",
    district_code: 332,
  },
  {
    name: "Xã Thủ Sỹ",
    code: 12367,
    division_type: "xã",
    codename: "xa_thu_sy",
    district_code: 332,
  },
  {
    name: "Xã Thiện Phiến",
    code: 12370,
    division_type: "xã",
    codename: "xa_thien_phien",
    district_code: 332,
  },
  {
    name: "Xã Thụy Lôi",
    code: 12373,
    division_type: "xã",
    codename: "xa_thuy_loi",
    district_code: 332,
  },
  {
    name: "Xã Cương Chính",
    code: 12376,
    division_type: "xã",
    codename: "xa_cuong_chinh",
    district_code: 332,
  },
  {
    name: "Xã Minh Phượng",
    code: 12379,
    division_type: "xã",
    codename: "xa_minh_phuong",
    district_code: 332,
  },
  {
    name: "Thị trấn Trần Cao",
    code: 12391,
    division_type: "thị trấn",
    codename: "thi_tran_tran_cao",
    district_code: 333,
  },
  {
    name: "Xã Minh Tân",
    code: 12394,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 333,
  },
  {
    name: "Xã Phan Sào Nam",
    code: 12397,
    division_type: "xã",
    codename: "xa_phan_sao_nam",
    district_code: 333,
  },
  {
    name: "Xã Quang Hưng",
    code: 12400,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 333,
  },
  {
    name: "Xã Minh Hoàng",
    code: 12403,
    division_type: "xã",
    codename: "xa_minh_hoang",
    district_code: 333,
  },
  {
    name: "Xã Đoàn Đào",
    code: 12406,
    division_type: "xã",
    codename: "xa_doan_dao",
    district_code: 333,
  },
  {
    name: "Xã Tống Phan",
    code: 12409,
    division_type: "xã",
    codename: "xa_tong_phan",
    district_code: 333,
  },
  {
    name: "Xã Đình Cao",
    code: 12412,
    division_type: "xã",
    codename: "xa_dinh_cao",
    district_code: 333,
  },
  {
    name: "Xã Nhật Quang",
    code: 12415,
    division_type: "xã",
    codename: "xa_nhat_quang",
    district_code: 333,
  },
  {
    name: "Xã Tiền Tiến",
    code: 12418,
    division_type: "xã",
    codename: "xa_tien_tien",
    district_code: 333,
  },
  {
    name: "Xã Tam Đa",
    code: 12421,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 333,
  },
  {
    name: "Xã Minh Tiến",
    code: 12424,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 333,
  },
  {
    name: "Xã Nguyên Hòa",
    code: 12427,
    division_type: "xã",
    codename: "xa_nguyen_hoa",
    district_code: 333,
  },
  {
    name: "Xã Tống Trân",
    code: 12430,
    division_type: "xã",
    codename: "xa_tong_tran",
    district_code: 333,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 12433,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 336,
  },
  {
    name: "Phường Bồ Xuyên",
    code: 12436,
    division_type: "phường",
    codename: "phuong_bo_xuyen",
    district_code: 336,
  },
  {
    name: "Phường Đề Thám",
    code: 12439,
    division_type: "phường",
    codename: "phuong_de_tham",
    district_code: 336,
  },
  {
    name: "Phường Kỳ Bá",
    code: 12442,
    division_type: "phường",
    codename: "phuong_ky_ba",
    district_code: 336,
  },
  {
    name: "Phường Quang Trung",
    code: 12445,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 336,
  },
  {
    name: "Phường Phú Khánh",
    code: 12448,
    division_type: "phường",
    codename: "phuong_phu_khanh",
    district_code: 336,
  },
  {
    name: "Phường Tiền Phong",
    code: 12451,
    division_type: "phường",
    codename: "phuong_tien_phong",
    district_code: 336,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 12452,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 336,
  },
  {
    name: "Phường Trần Lãm",
    code: 12454,
    division_type: "phường",
    codename: "phuong_tran_lam",
    district_code: 336,
  },
  {
    name: "Xã Đông Hòa",
    code: 12457,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 336,
  },
  {
    name: "Phường Hoàng Diệu",
    code: 12460,
    division_type: "phường",
    codename: "phuong_hoang_dieu",
    district_code: 336,
  },
  {
    name: "Xã Phú Xuân",
    code: 12463,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 336,
  },
  {
    name: "Xã Vũ Phúc",
    code: 12466,
    division_type: "xã",
    codename: "xa_vu_phuc",
    district_code: 336,
  },
  {
    name: "Xã Vũ Chính",
    code: 12469,
    division_type: "xã",
    codename: "xa_vu_chinh",
    district_code: 336,
  },
  {
    name: "Xã Đông Mỹ",
    code: 12817,
    division_type: "xã",
    codename: "xa_dong_my",
    district_code: 336,
  },
  {
    name: "Xã Đông Thọ",
    code: 12820,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 336,
  },
  {
    name: "Xã Vũ Đông",
    code: 13084,
    division_type: "xã",
    codename: "xa_vu_dong",
    district_code: 336,
  },
  {
    name: "Xã Vũ Lạc",
    code: 13108,
    division_type: "xã",
    codename: "xa_vu_lac",
    district_code: 336,
  },
  {
    name: "Xã Tân Bình",
    code: 13225,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 336,
  },
  {
    name: "Thị trấn Quỳnh Côi",
    code: 12472,
    division_type: "thị trấn",
    codename: "thi_tran_quynh_coi",
    district_code: 338,
  },
  {
    name: "Xã An Khê",
    code: 12475,
    division_type: "xã",
    codename: "xa_an_khe",
    district_code: 338,
  },
  {
    name: "Xã An Đồng",
    code: 12478,
    division_type: "xã",
    codename: "xa_an_dong",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hoa",
    code: 12481,
    division_type: "xã",
    codename: "xa_quynh_hoa",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Lâm",
    code: 12484,
    division_type: "xã",
    codename: "xa_quynh_lam",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Thọ",
    code: 12487,
    division_type: "xã",
    codename: "xa_quynh_tho",
    district_code: 338,
  },
  {
    name: "Xã An Hiệp",
    code: 12490,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hoàng",
    code: 12493,
    division_type: "xã",
    codename: "xa_quynh_hoang",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Giao",
    code: 12496,
    division_type: "xã",
    codename: "xa_quynh_giao",
    district_code: 338,
  },
  {
    name: "Xã An Thái",
    code: 12499,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 338,
  },
  {
    name: "Xã An Cầu",
    code: 12502,
    division_type: "xã",
    codename: "xa_an_cau",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hồng",
    code: 12505,
    division_type: "xã",
    codename: "xa_quynh_hong",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Khê",
    code: 12508,
    division_type: "xã",
    codename: "xa_quynh_khe",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Minh",
    code: 12511,
    division_type: "xã",
    codename: "xa_quynh_minh",
    district_code: 338,
  },
  {
    name: "Xã An Ninh",
    code: 12514,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Ngọc",
    code: 12517,
    division_type: "xã",
    codename: "xa_quynh_ngoc",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hải",
    code: 12520,
    division_type: "xã",
    codename: "xa_quynh_hai",
    district_code: 338,
  },
  {
    name: "Thị trấn An Bài",
    code: 12523,
    division_type: "thị trấn",
    codename: "thi_tran_an_bai",
    district_code: 338,
  },
  {
    name: "Xã An Ấp",
    code: 12526,
    division_type: "xã",
    codename: "xa_an_ap",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hội",
    code: 12529,
    division_type: "xã",
    codename: "xa_quynh_hoi",
    district_code: 338,
  },
  {
    name: "Xã Châu Sơn",
    code: 12532,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Mỹ",
    code: 12535,
    division_type: "xã",
    codename: "xa_quynh_my",
    district_code: 338,
  },
  {
    name: "Xã An Quí",
    code: 12538,
    division_type: "xã",
    codename: "xa_an_qui",
    district_code: 338,
  },
  {
    name: "Xã An Thanh",
    code: 12541,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 338,
  },
  {
    name: "Xã An Vũ",
    code: 12547,
    division_type: "xã",
    codename: "xa_an_vu",
    district_code: 338,
  },
  {
    name: "Xã An Lễ",
    code: 12550,
    division_type: "xã",
    codename: "xa_an_le",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hưng",
    code: 12553,
    division_type: "xã",
    codename: "xa_quynh_hung",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Bảo",
    code: 12556,
    division_type: "xã",
    codename: "xa_quynh_bao",
    district_code: 338,
  },
  {
    name: "Xã An Mỹ",
    code: 12559,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Nguyên",
    code: 12562,
    division_type: "xã",
    codename: "xa_quynh_nguyen",
    district_code: 338,
  },
  {
    name: "Xã An Vinh",
    code: 12565,
    division_type: "xã",
    codename: "xa_an_vinh",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Xá",
    code: 12568,
    division_type: "xã",
    codename: "xa_quynh_xa",
    district_code: 338,
  },
  {
    name: "Xã An Dục",
    code: 12571,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 338,
  },
  {
    name: "Xã Đông Hải",
    code: 12574,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Trang",
    code: 12577,
    division_type: "xã",
    codename: "xa_quynh_trang",
    district_code: 338,
  },
  {
    name: "Xã An Tràng",
    code: 12580,
    division_type: "xã",
    codename: "xa_an_trang",
    district_code: 338,
  },
  {
    name: "Xã Đồng Tiến",
    code: 12583,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 338,
  },
  {
    name: "Thị trấn Hưng Hà",
    code: 12586,
    division_type: "thị trấn",
    codename: "thi_tran_hung_ha",
    district_code: 339,
  },
  {
    name: "Xã Điệp Nông",
    code: 12589,
    division_type: "xã",
    codename: "xa_diep_nong",
    district_code: 339,
  },
  {
    name: "Xã Tân Lễ",
    code: 12592,
    division_type: "xã",
    codename: "xa_tan_le",
    district_code: 339,
  },
  {
    name: "Xã Cộng Hòa",
    code: 12595,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 339,
  },
  {
    name: "Xã Dân Chủ",
    code: 12598,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 339,
  },
  {
    name: "Xã Canh Tân",
    code: 12601,
    division_type: "xã",
    codename: "xa_canh_tan",
    district_code: 339,
  },
  {
    name: "Xã Hòa Tiến",
    code: 12604,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 339,
  },
  {
    name: "Xã Hùng Dũng",
    code: 12607,
    division_type: "xã",
    codename: "xa_hung_dung",
    district_code: 339,
  },
  {
    name: "Xã Tân Tiến",
    code: 12610,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 339,
  },
  {
    name: "Thị trấn Hưng Nhân",
    code: 12613,
    division_type: "thị trấn",
    codename: "thi_tran_hung_nhan",
    district_code: 339,
  },
  {
    name: "Xã Đoan Hùng",
    code: 12616,
    division_type: "xã",
    codename: "xa_doan_hung",
    district_code: 339,
  },
  {
    name: "Xã Duyên Hải",
    code: 12619,
    division_type: "xã",
    codename: "xa_duyen_hai",
    district_code: 339,
  },
  {
    name: "Xã Tân Hòa",
    code: 12622,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 339,
  },
  {
    name: "Xã Văn Cẩm",
    code: 12625,
    division_type: "xã",
    codename: "xa_van_cam",
    district_code: 339,
  },
  {
    name: "Xã Bắc Sơn",
    code: 12628,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 339,
  },
  {
    name: "Xã Đông Đô",
    code: 12631,
    division_type: "xã",
    codename: "xa_dong_do",
    district_code: 339,
  },
  {
    name: "Xã Phúc Khánh",
    code: 12634,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 339,
  },
  {
    name: "Xã Liên Hiệp",
    code: 12637,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 339,
  },
  {
    name: "Xã Tây Đô",
    code: 12640,
    division_type: "xã",
    codename: "xa_tay_do",
    district_code: 339,
  },
  {
    name: "Xã Thống Nhất",
    code: 12643,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 339,
  },
  {
    name: "Xã Tiến Đức",
    code: 12646,
    division_type: "xã",
    codename: "xa_tien_duc",
    district_code: 339,
  },
  {
    name: "Xã Thái Hưng",
    code: 12649,
    division_type: "xã",
    codename: "xa_thai_hung",
    district_code: 339,
  },
  {
    name: "Xã Thái Phương",
    code: 12652,
    division_type: "xã",
    codename: "xa_thai_phuong",
    district_code: 339,
  },
  {
    name: "Xã Hòa Bình",
    code: 12655,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 339,
  },
  {
    name: "Xã Chi Lăng",
    code: 12656,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 339,
  },
  {
    name: "Xã Minh Khai",
    code: 12658,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 339,
  },
  {
    name: "Xã Hồng An",
    code: 12661,
    division_type: "xã",
    codename: "xa_hong_an",
    district_code: 339,
  },
  {
    name: "Xã Kim Chung",
    code: 12664,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 339,
  },
  {
    name: "Xã Hồng Lĩnh",
    code: 12667,
    division_type: "xã",
    codename: "xa_hong_linh",
    district_code: 339,
  },
  {
    name: "Xã Minh Tân",
    code: 12670,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 339,
  },
  {
    name: "Xã Văn Lang",
    code: 12673,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 339,
  },
  {
    name: "Xã Độc Lập",
    code: 12676,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 339,
  },
  {
    name: "Xã Chí Hòa",
    code: 12679,
    division_type: "xã",
    codename: "xa_chi_hoa",
    district_code: 339,
  },
  {
    name: "Xã Minh Hòa",
    code: 12682,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 339,
  },
  {
    name: "Xã Hồng Minh",
    code: 12685,
    division_type: "xã",
    codename: "xa_hong_minh",
    district_code: 339,
  },
  {
    name: "Thị trấn Đông Hưng",
    code: 12688,
    division_type: "thị trấn",
    codename: "thi_tran_dong_hung",
    district_code: 340,
  },
  {
    name: "Xã Đô Lương",
    code: 12691,
    division_type: "xã",
    codename: "xa_do_luong",
    district_code: 340,
  },
  {
    name: "Xã Đông Phương",
    code: 12694,
    division_type: "xã",
    codename: "xa_dong_phuong",
    district_code: 340,
  },
  {
    name: "Xã Liên Giang",
    code: 12697,
    division_type: "xã",
    codename: "xa_lien_giang",
    district_code: 340,
  },
  {
    name: "Xã An Châu",
    code: 12700,
    division_type: "xã",
    codename: "xa_an_chau",
    district_code: 340,
  },
  {
    name: "Xã Đông Sơn",
    code: 12703,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 340,
  },
  {
    name: "Xã Đông Cường",
    code: 12706,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 340,
  },
  {
    name: "Xã Phú Lương",
    code: 12709,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 340,
  },
  {
    name: "Xã Mê Linh",
    code: 12712,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 340,
  },
  {
    name: "Xã Lô Giang",
    code: 12715,
    division_type: "xã",
    codename: "xa_lo_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông La",
    code: 12718,
    division_type: "xã",
    codename: "xa_dong_la",
    district_code: 340,
  },
  {
    name: "Xã Minh Tân",
    code: 12721,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 340,
  },
  {
    name: "Xã Đông Xá",
    code: 12724,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 340,
  },
  {
    name: "Xã Chương Dương",
    code: 12727,
    division_type: "xã",
    codename: "xa_chuong_duong",
    district_code: 340,
  },
  {
    name: "Xã Nguyên Xá",
    code: 12730,
    division_type: "xã",
    codename: "xa_nguyen_xa",
    district_code: 340,
  },
  {
    name: "Xã Phong Châu",
    code: 12733,
    division_type: "xã",
    codename: "xa_phong_chau",
    district_code: 340,
  },
  {
    name: "Xã Hợp Tiến",
    code: 12736,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 340,
  },
  {
    name: "Xã Hồng Việt",
    code: 12739,
    division_type: "xã",
    codename: "xa_hong_viet",
    district_code: 340,
  },
  {
    name: "Xã Hà Giang",
    code: 12745,
    division_type: "xã",
    codename: "xa_ha_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông Kinh",
    code: 12748,
    division_type: "xã",
    codename: "xa_dong_kinh",
    district_code: 340,
  },
  {
    name: "Xã Đông Hợp",
    code: 12751,
    division_type: "xã",
    codename: "xa_dong_hop",
    district_code: 340,
  },
  {
    name: "Xã Thăng Long",
    code: 12754,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 340,
  },
  {
    name: "Xã Đông Các",
    code: 12757,
    division_type: "xã",
    codename: "xa_dong_cac",
    district_code: 340,
  },
  {
    name: "Xã Phú Châu",
    code: 12760,
    division_type: "xã",
    codename: "xa_phu_chau",
    district_code: 340,
  },
  {
    name: "Xã Liên Hoa",
    code: 12763,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 340,
  },
  {
    name: "Xã Đông Tân",
    code: 12769,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 340,
  },
  {
    name: "Xã Đông Vinh",
    code: 12772,
    division_type: "xã",
    codename: "xa_dong_vinh",
    district_code: 340,
  },
  {
    name: "Xã Đông Động",
    code: 12775,
    division_type: "xã",
    codename: "xa_dong_dong",
    district_code: 340,
  },
  {
    name: "Xã Hồng Bạch",
    code: 12778,
    division_type: "xã",
    codename: "xa_hong_bach",
    district_code: 340,
  },
  {
    name: "Xã Trọng Quan",
    code: 12784,
    division_type: "xã",
    codename: "xa_trong_quan",
    district_code: 340,
  },
  {
    name: "Xã Hồng Giang",
    code: 12790,
    division_type: "xã",
    codename: "xa_hong_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông Quan",
    code: 12793,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 340,
  },
  {
    name: "Xã Đông Quang",
    code: 12796,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 340,
  },
  {
    name: "Xã Đông Xuân",
    code: 12799,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 340,
  },
  {
    name: "Xã Đông Á",
    code: 12802,
    division_type: "xã",
    codename: "xa_dong_a",
    district_code: 340,
  },
  {
    name: "Xã Đông Hoàng",
    code: 12808,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 340,
  },
  {
    name: "Xã Đông Dương",
    code: 12811,
    division_type: "xã",
    codename: "xa_dong_duong",
    district_code: 340,
  },
  {
    name: "Xã Minh Phú",
    code: 12823,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 340,
  },
  {
    name: "Thị trấn Diêm Điền",
    code: 12826,
    division_type: "thị trấn",
    codename: "thi_tran_diem_dien",
    district_code: 341,
  },
  {
    name: "Xã Thụy Trường",
    code: 12832,
    division_type: "xã",
    codename: "xa_thuy_truong",
    district_code: 341,
  },
  {
    name: "Xã Hồng Dũng",
    code: 12841,
    division_type: "xã",
    codename: "xa_hong_dung",
    district_code: 341,
  },
  {
    name: "Xã Thụy Quỳnh",
    code: 12844,
    division_type: "xã",
    codename: "xa_thuy_quynh",
    district_code: 341,
  },
  {
    name: "Xã An Tân",
    code: 12847,
    division_type: "xã",
    codename: "xa_an_tan",
    district_code: 341,
  },
  {
    name: "Xã Thụy Ninh",
    code: 12850,
    division_type: "xã",
    codename: "xa_thuy_ninh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Hưng",
    code: 12853,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 341,
  },
  {
    name: "Xã Thụy Việt",
    code: 12856,
    division_type: "xã",
    codename: "xa_thuy_viet",
    district_code: 341,
  },
  {
    name: "Xã Thụy Văn",
    code: 12859,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 341,
  },
  {
    name: "Xã Thụy Xuân",
    code: 12862,
    division_type: "xã",
    codename: "xa_thuy_xuan",
    district_code: 341,
  },
  {
    name: "Xã Dương Phúc",
    code: 12865,
    division_type: "xã",
    codename: "xa_duong_phuc",
    district_code: 341,
  },
  {
    name: "Xã Thụy Trình",
    code: 12868,
    division_type: "xã",
    codename: "xa_thuy_trinh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Bình",
    code: 12871,
    division_type: "xã",
    codename: "xa_thuy_binh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Chính",
    code: 12874,
    division_type: "xã",
    codename: "xa_thuy_chinh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Dân",
    code: 12877,
    division_type: "xã",
    codename: "xa_thuy_dan",
    district_code: 341,
  },
  {
    name: "Xã Thụy Hải",
    code: 12880,
    division_type: "xã",
    codename: "xa_thuy_hai",
    district_code: 341,
  },
  {
    name: "Xã Thụy Liên",
    code: 12889,
    division_type: "xã",
    codename: "xa_thuy_lien",
    district_code: 341,
  },
  {
    name: "Xã Thụy Duyên",
    code: 12892,
    division_type: "xã",
    codename: "xa_thuy_duyen",
    district_code: 341,
  },
  {
    name: "Xã Thụy Thanh",
    code: 12898,
    division_type: "xã",
    codename: "xa_thuy_thanh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Sơn",
    code: 12901,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 341,
  },
  {
    name: "Xã Thụy Phong",
    code: 12904,
    division_type: "xã",
    codename: "xa_thuy_phong",
    district_code: 341,
  },
  {
    name: "Xã Thái Thượng",
    code: 12907,
    division_type: "xã",
    codename: "xa_thai_thuong",
    district_code: 341,
  },
  {
    name: "Xã Thái Nguyên",
    code: 12910,
    division_type: "xã",
    codename: "xa_thai_nguyen",
    district_code: 341,
  },
  {
    name: "Xã Dương Hồng Thủy",
    code: 12916,
    division_type: "xã",
    codename: "xa_duong_hong_thuy",
    district_code: 341,
  },
  {
    name: "Xã Thái Giang",
    code: 12919,
    division_type: "xã",
    codename: "xa_thai_giang",
    district_code: 341,
  },
  {
    name: "Xã Hòa An",
    code: 12922,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 341,
  },
  {
    name: "Xã Sơn Hà",
    code: 12925,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 341,
  },
  {
    name: "Xã Thái Phúc",
    code: 12934,
    division_type: "xã",
    codename: "xa_thai_phuc",
    district_code: 341,
  },
  {
    name: "Xã Thái Hưng",
    code: 12937,
    division_type: "xã",
    codename: "xa_thai_hung",
    district_code: 341,
  },
  {
    name: "Xã Thái Đô",
    code: 12940,
    division_type: "xã",
    codename: "xa_thai_do",
    district_code: 341,
  },
  {
    name: "Xã Thái Xuyên",
    code: 12943,
    division_type: "xã",
    codename: "xa_thai_xuyen",
    district_code: 341,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 12949,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 341,
  },
  {
    name: "Xã Tân Học",
    code: 12958,
    division_type: "xã",
    codename: "xa_tan_hoc",
    district_code: 341,
  },
  {
    name: "Xã Thái Thịnh",
    code: 12961,
    division_type: "xã",
    codename: "xa_thai_thinh",
    district_code: 341,
  },
  {
    name: "Xã Thuần Thành",
    code: 12964,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 341,
  },
  {
    name: "Xã Thái Thọ",
    code: 12967,
    division_type: "xã",
    codename: "xa_thai_tho",
    district_code: 341,
  },
  {
    name: "Thị trấn Tiền Hải",
    code: 12970,
    division_type: "thị trấn",
    codename: "thi_tran_tien_hai",
    district_code: 342,
  },
  {
    name: "Xã Đông Trà",
    code: 12976,
    division_type: "xã",
    codename: "xa_dong_tra",
    district_code: 342,
  },
  {
    name: "Xã Đông Long",
    code: 12979,
    division_type: "xã",
    codename: "xa_dong_long",
    district_code: 342,
  },
  {
    name: "Xã Đông Quí",
    code: 12982,
    division_type: "xã",
    codename: "xa_dong_qui",
    district_code: 342,
  },
  {
    name: "Xã Vũ Lăng",
    code: 12985,
    division_type: "xã",
    codename: "xa_vu_lang",
    district_code: 342,
  },
  {
    name: "Xã Đông Xuyên",
    code: 12988,
    division_type: "xã",
    codename: "xa_dong_xuyen",
    district_code: 342,
  },
  {
    name: "Xã Tây Lương",
    code: 12991,
    division_type: "xã",
    codename: "xa_tay_luong",
    district_code: 342,
  },
  {
    name: "Xã Tây Ninh",
    code: 12994,
    division_type: "xã",
    codename: "xa_tay_ninh",
    district_code: 342,
  },
  {
    name: "Xã Đông Trung",
    code: 12997,
    division_type: "xã",
    codename: "xa_dong_trung",
    district_code: 342,
  },
  {
    name: "Xã Đông Hoàng",
    code: 13000,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 342,
  },
  {
    name: "Xã Đông Minh",
    code: 13003,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 342,
  },
  {
    name: "Xã Đông Phong",
    code: 13009,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 342,
  },
  {
    name: "Xã An Ninh",
    code: 13012,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 342,
  },
  {
    name: "Xã Đông Cơ",
    code: 13018,
    division_type: "xã",
    codename: "xa_dong_co",
    district_code: 342,
  },
  {
    name: "Xã Tây Giang",
    code: 13021,
    division_type: "xã",
    codename: "xa_tay_giang",
    district_code: 342,
  },
  {
    name: "Xã Đông Lâm",
    code: 13024,
    division_type: "xã",
    codename: "xa_dong_lam",
    district_code: 342,
  },
  {
    name: "Xã Phương Công",
    code: 13027,
    division_type: "xã",
    codename: "xa_phuong_cong",
    district_code: 342,
  },
  {
    name: "Xã Tây Phong",
    code: 13030,
    division_type: "xã",
    codename: "xa_tay_phong",
    district_code: 342,
  },
  {
    name: "Xã Tây Tiến",
    code: 13033,
    division_type: "xã",
    codename: "xa_tay_tien",
    district_code: 342,
  },
  {
    name: "Xã Nam Cường",
    code: 13036,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 342,
  },
  {
    name: "Xã Vân Trường",
    code: 13039,
    division_type: "xã",
    codename: "xa_van_truong",
    district_code: 342,
  },
  {
    name: "Xã Nam Thắng",
    code: 13042,
    division_type: "xã",
    codename: "xa_nam_thang",
    district_code: 342,
  },
  {
    name: "Xã Nam Chính",
    code: 13045,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 342,
  },
  {
    name: "Xã Bắc Hải",
    code: 13048,
    division_type: "xã",
    codename: "xa_bac_hai",
    district_code: 342,
  },
  {
    name: "Xã Nam Thịnh",
    code: 13051,
    division_type: "xã",
    codename: "xa_nam_thinh",
    district_code: 342,
  },
  {
    name: "Xã Nam Hà",
    code: 13054,
    division_type: "xã",
    codename: "xa_nam_ha",
    district_code: 342,
  },
  {
    name: "Xã Nam Thanh",
    code: 13057,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 342,
  },
  {
    name: "Xã Nam Trung",
    code: 13060,
    division_type: "xã",
    codename: "xa_nam_trung",
    district_code: 342,
  },
  {
    name: "Xã Nam Hồng",
    code: 13063,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 342,
  },
  {
    name: "Xã Nam Hưng",
    code: 13066,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 342,
  },
  {
    name: "Xã Nam Hải",
    code: 13069,
    division_type: "xã",
    codename: "xa_nam_hai",
    district_code: 342,
  },
  {
    name: "Xã Nam Phú",
    code: 13072,
    division_type: "xã",
    codename: "xa_nam_phu",
    district_code: 342,
  },
  {
    name: "Thị trấn Kiến Xương",
    code: 13075,
    division_type: "thị trấn",
    codename: "thi_tran_kien_xuong",
    district_code: 343,
  },
  {
    name: "Xã Trà Giang",
    code: 13078,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 343,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 13081,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 343,
  },
  {
    name: "Xã An Bình",
    code: 13087,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 343,
  },
  {
    name: "Xã Tây Sơn",
    code: 13090,
    division_type: "xã",
    codename: "xa_tay_son",
    district_code: 343,
  },
  {
    name: "Xã Hồng Thái",
    code: 13093,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 343,
  },
  {
    name: "Xã Bình Nguyên",
    code: 13096,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 343,
  },
  {
    name: "Xã Lê Lợi",
    code: 13102,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 343,
  },
  {
    name: "Xã Vũ Lễ",
    code: 13111,
    division_type: "xã",
    codename: "xa_vu_le",
    district_code: 343,
  },
  {
    name: "Xã Thanh Tân",
    code: 13114,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 343,
  },
  {
    name: "Xã Thượng Hiền",
    code: 13117,
    division_type: "xã",
    codename: "xa_thuong_hien",
    district_code: 343,
  },
  {
    name: "Xã Nam Cao",
    code: 13120,
    division_type: "xã",
    codename: "xa_nam_cao",
    district_code: 343,
  },
  {
    name: "Xã Đình Phùng",
    code: 13123,
    division_type: "xã",
    codename: "xa_dinh_phung",
    district_code: 343,
  },
  {
    name: "Xã Vũ Ninh",
    code: 13126,
    division_type: "xã",
    codename: "xa_vu_ninh",
    district_code: 343,
  },
  {
    name: "Xã Vũ An",
    code: 13129,
    division_type: "xã",
    codename: "xa_vu_an",
    district_code: 343,
  },
  {
    name: "Xã Quang Lịch",
    code: 13132,
    division_type: "xã",
    codename: "xa_quang_lich",
    district_code: 343,
  },
  {
    name: "Xã Hòa Bình",
    code: 13135,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 343,
  },
  {
    name: "Xã Bình Minh",
    code: 13138,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 343,
  },
  {
    name: "Xã Vũ Quí",
    code: 13141,
    division_type: "xã",
    codename: "xa_vu_qui",
    district_code: 343,
  },
  {
    name: "Xã Quang Bình",
    code: 13144,
    division_type: "xã",
    codename: "xa_quang_binh",
    district_code: 343,
  },
  {
    name: "Xã Vũ Trung",
    code: 13150,
    division_type: "xã",
    codename: "xa_vu_trung",
    district_code: 343,
  },
  {
    name: "Xã Vũ Thắng",
    code: 13153,
    division_type: "xã",
    codename: "xa_vu_thang",
    district_code: 343,
  },
  {
    name: "Xã Vũ Công",
    code: 13156,
    division_type: "xã",
    codename: "xa_vu_cong",
    district_code: 343,
  },
  {
    name: "Xã Vũ Hòa",
    code: 13159,
    division_type: "xã",
    codename: "xa_vu_hoa",
    district_code: 343,
  },
  {
    name: "Xã Quang Minh",
    code: 13162,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 343,
  },
  {
    name: "Xã Quang Trung",
    code: 13165,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 343,
  },
  {
    name: "Xã Minh Quang",
    code: 13171,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 343,
  },
  {
    name: "Xã Vũ Bình",
    code: 13174,
    division_type: "xã",
    codename: "xa_vu_binh",
    district_code: 343,
  },
  {
    name: "Xã Minh Tân",
    code: 13177,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 343,
  },
  {
    name: "Xã Nam Bình",
    code: 13180,
    division_type: "xã",
    codename: "xa_nam_binh",
    district_code: 343,
  },
  {
    name: "Xã Bình Thanh",
    code: 13183,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 343,
  },
  {
    name: "Xã Bình Định",
    code: 13186,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 343,
  },
  {
    name: "Xã Hồng Tiến",
    code: 13189,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 343,
  },
  {
    name: "Thị trấn Vũ Thư",
    code: 13192,
    division_type: "thị trấn",
    codename: "thi_tran_vu_thu",
    district_code: 344,
  },
  {
    name: "Xã Hồng Lý",
    code: 13195,
    division_type: "xã",
    codename: "xa_hong_ly",
    district_code: 344,
  },
  {
    name: "Xã Đồng Thanh",
    code: 13198,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 344,
  },
  {
    name: "Xã Xuân Hòa",
    code: 13201,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 344,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 13204,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 344,
  },
  {
    name: "Xã Phúc Thành",
    code: 13207,
    division_type: "xã",
    codename: "xa_phuc_thanh",
    district_code: 344,
  },
  {
    name: "Xã Tân Phong",
    code: 13210,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 344,
  },
  {
    name: "Xã Song Lãng",
    code: 13213,
    division_type: "xã",
    codename: "xa_song_lang",
    district_code: 344,
  },
  {
    name: "Xã Tân Hòa",
    code: 13216,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 344,
  },
  {
    name: "Xã Việt Hùng",
    code: 13219,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 344,
  },
  {
    name: "Xã Minh Lãng",
    code: 13222,
    division_type: "xã",
    codename: "xa_minh_lang",
    district_code: 344,
  },
  {
    name: "Xã Minh Khai",
    code: 13228,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 344,
  },
  {
    name: "Xã Dũng Nghĩa",
    code: 13231,
    division_type: "xã",
    codename: "xa_dung_nghia",
    district_code: 344,
  },
  {
    name: "Xã Minh Quang",
    code: 13234,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 344,
  },
  {
    name: "Xã Tam Quang",
    code: 13237,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 344,
  },
  {
    name: "Xã Tân Lập",
    code: 13240,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 344,
  },
  {
    name: "Xã Bách Thuận",
    code: 13243,
    division_type: "xã",
    codename: "xa_bach_thuan",
    district_code: 344,
  },
  {
    name: "Xã Tự Tân",
    code: 13246,
    division_type: "xã",
    codename: "xa_tu_tan",
    district_code: 344,
  },
  {
    name: "Xã Song An",
    code: 13249,
    division_type: "xã",
    codename: "xa_song_an",
    district_code: 344,
  },
  {
    name: "Xã Trung An",
    code: 13252,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 344,
  },
  {
    name: "Xã Vũ Hội",
    code: 13255,
    division_type: "xã",
    codename: "xa_vu_hoi",
    district_code: 344,
  },
  {
    name: "Xã Hòa Bình",
    code: 13258,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 344,
  },
  {
    name: "Xã Nguyên Xá",
    code: 13261,
    division_type: "xã",
    codename: "xa_nguyen_xa",
    district_code: 344,
  },
  {
    name: "Xã Việt Thuận",
    code: 13264,
    division_type: "xã",
    codename: "xa_viet_thuan",
    district_code: 344,
  },
  {
    name: "Xã Vũ Vinh",
    code: 13267,
    division_type: "xã",
    codename: "xa_vu_vinh",
    district_code: 344,
  },
  {
    name: "Xã Vũ Đoài",
    code: 13270,
    division_type: "xã",
    codename: "xa_vu_doai",
    district_code: 344,
  },
  {
    name: "Xã Vũ Tiến",
    code: 13273,
    division_type: "xã",
    codename: "xa_vu_tien",
    district_code: 344,
  },
  {
    name: "Xã Vũ Vân",
    code: 13276,
    division_type: "xã",
    codename: "xa_vu_van",
    district_code: 344,
  },
  {
    name: "Xã Duy Nhất",
    code: 13279,
    division_type: "xã",
    codename: "xa_duy_nhat",
    district_code: 344,
  },
  {
    name: "Xã Hồng Phong",
    code: 13282,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 344,
  },
  {
    name: "Phường Quang Trung",
    code: 13285,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 347,
  },
  {
    name: "Phường Lương Khánh Thiện",
    code: 13288,
    division_type: "phường",
    codename: "phuong_luong_khanh_thien",
    district_code: 347,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 13291,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 347,
  },
  {
    name: "Phường Minh Khai",
    code: 13294,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 347,
  },
  {
    name: "Phường Hai Bà Trưng",
    code: 13297,
    division_type: "phường",
    codename: "phuong_hai_ba_trung",
    district_code: 347,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 13300,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 347,
  },
  {
    name: "Phường Lam Hạ",
    code: 13303,
    division_type: "phường",
    codename: "phuong_lam_ha",
    district_code: 347,
  },
  {
    name: "Xã Phù Vân",
    code: 13306,
    division_type: "xã",
    codename: "xa_phu_van",
    district_code: 347,
  },
  {
    name: "Phường Liêm Chính",
    code: 13309,
    division_type: "phường",
    codename: "phuong_liem_chinh",
    district_code: 347,
  },
  {
    name: "Xã Liêm Chung",
    code: 13312,
    division_type: "xã",
    codename: "xa_liem_chung",
    district_code: 347,
  },
  {
    name: "Phường Thanh Châu",
    code: 13315,
    division_type: "phường",
    codename: "phuong_thanh_chau",
    district_code: 347,
  },
  {
    name: "Phường Châu Sơn",
    code: 13318,
    division_type: "phường",
    codename: "phuong_chau_son",
    district_code: 347,
  },
  {
    name: "Xã Tiên Tân",
    code: 13366,
    division_type: "xã",
    codename: "xa_tien_tan",
    district_code: 347,
  },
  {
    name: "Xã Tiên Hiệp",
    code: 13372,
    division_type: "xã",
    codename: "xa_tien_hiep",
    district_code: 347,
  },
  {
    name: "Xã Tiên Hải",
    code: 13381,
    division_type: "xã",
    codename: "xa_tien_hai",
    district_code: 347,
  },
  {
    name: "Xã Kim Bình",
    code: 13426,
    division_type: "xã",
    codename: "xa_kim_binh",
    district_code: 347,
  },
  {
    name: "Xã Liêm Tuyền",
    code: 13444,
    division_type: "xã",
    codename: "xa_liem_tuyen",
    district_code: 347,
  },
  {
    name: "Xã Liêm Tiết",
    code: 13447,
    division_type: "xã",
    codename: "xa_liem_tiet",
    district_code: 347,
  },
  {
    name: "Phường Thanh Tuyền",
    code: 13459,
    division_type: "phường",
    codename: "phuong_thanh_tuyen",
    district_code: 347,
  },
  {
    name: "Xã Đinh Xá",
    code: 13507,
    division_type: "xã",
    codename: "xa_dinh_xa",
    district_code: 347,
  },
  {
    name: "Xã Trịnh Xá",
    code: 13513,
    division_type: "xã",
    codename: "xa_trinh_xa",
    district_code: 347,
  },
  {
    name: "Phường Đồng Văn",
    code: 13321,
    division_type: "phường",
    codename: "phuong_dong_van",
    district_code: 349,
  },
  {
    name: "Phường Hòa Mạc",
    code: 13324,
    division_type: "phường",
    codename: "phuong_hoa_mac",
    district_code: 349,
  },
  {
    name: "Xã Mộc Bắc",
    code: 13327,
    division_type: "xã",
    codename: "xa_moc_bac",
    district_code: 349,
  },
  {
    name: "Phường Châu Giang",
    code: 13330,
    division_type: "phường",
    codename: "phuong_chau_giang",
    district_code: 349,
  },
  {
    name: "Phường Bạch Thượng",
    code: 13333,
    division_type: "phường",
    codename: "phuong_bach_thuong",
    district_code: 349,
  },
  {
    name: "Phường Duy Minh",
    code: 13336,
    division_type: "phường",
    codename: "phuong_duy_minh",
    district_code: 349,
  },
  {
    name: "Xã Mộc Nam",
    code: 13339,
    division_type: "xã",
    codename: "xa_moc_nam",
    district_code: 349,
  },
  {
    name: "Phường Duy Hải",
    code: 13342,
    division_type: "phường",
    codename: "phuong_duy_hai",
    district_code: 349,
  },
  {
    name: "Xã Chuyên Ngoại",
    code: 13345,
    division_type: "xã",
    codename: "xa_chuyen_ngoai",
    district_code: 349,
  },
  {
    name: "Phường Yên Bắc",
    code: 13348,
    division_type: "phường",
    codename: "phuong_yen_bac",
    district_code: 349,
  },
  {
    name: "Xã Trác Văn",
    code: 13351,
    division_type: "xã",
    codename: "xa_trac_van",
    district_code: 349,
  },
  {
    name: "Phường Tiên Nội",
    code: 13354,
    division_type: "phường",
    codename: "phuong_tien_noi",
    district_code: 349,
  },
  {
    name: "Phường Hoàng Đông",
    code: 13357,
    division_type: "phường",
    codename: "phuong_hoang_dong",
    district_code: 349,
  },
  {
    name: "Xã Yên Nam",
    code: 13360,
    division_type: "xã",
    codename: "xa_yen_nam",
    district_code: 349,
  },
  {
    name: "Xã Tiên Ngoại",
    code: 13363,
    division_type: "xã",
    codename: "xa_tien_ngoai",
    district_code: 349,
  },
  {
    name: "Xã Tiên Sơn",
    code: 13369,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 349,
  },
  {
    name: "Thị trấn Quế",
    code: 13384,
    division_type: "thị trấn",
    codename: "thi_tran_que",
    district_code: 350,
  },
  {
    name: "Xã Nguyễn Úy",
    code: 13387,
    division_type: "xã",
    codename: "xa_nguyen_uy",
    district_code: 350,
  },
  {
    name: "Xã Đại Cương",
    code: 13390,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 350,
  },
  {
    name: "Xã Lê Hồ",
    code: 13393,
    division_type: "xã",
    codename: "xa_le_ho",
    district_code: 350,
  },
  {
    name: "Xã Tượng Lĩnh",
    code: 13396,
    division_type: "xã",
    codename: "xa_tuong_linh",
    district_code: 350,
  },
  {
    name: "Xã Nhật Tựu",
    code: 13399,
    division_type: "xã",
    codename: "xa_nhat_tuu",
    district_code: 350,
  },
  {
    name: "Xã Nhật Tân",
    code: 13402,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 350,
  },
  {
    name: "Xã Đồng Hóa",
    code: 13405,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 350,
  },
  {
    name: "Xã Hoàng Tây",
    code: 13408,
    division_type: "xã",
    codename: "xa_hoang_tay",
    district_code: 350,
  },
  {
    name: "Xã Tân Sơn",
    code: 13411,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 350,
  },
  {
    name: "Xã Thụy Lôi",
    code: 13414,
    division_type: "xã",
    codename: "xa_thuy_loi",
    district_code: 350,
  },
  {
    name: "Xã Văn Xá",
    code: 13417,
    division_type: "xã",
    codename: "xa_van_xa",
    district_code: 350,
  },
  {
    name: "Xã Khả Phong",
    code: 13420,
    division_type: "xã",
    codename: "xa_kha_phong",
    district_code: 350,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 13423,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 350,
  },
  {
    name: "Thị trấn Ba Sao",
    code: 13429,
    division_type: "thị trấn",
    codename: "thi_tran_ba_sao",
    district_code: 350,
  },
  {
    name: "Xã Liên Sơn",
    code: 13432,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 350,
  },
  {
    name: "Xã Thi Sơn",
    code: 13435,
    division_type: "xã",
    codename: "xa_thi_son",
    district_code: 350,
  },
  {
    name: "Xã Thanh Sơn",
    code: 13438,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 350,
  },
  {
    name: "Thị trấn Kiện Khê",
    code: 13441,
    division_type: "thị trấn",
    codename: "thi_tran_kien_khe",
    district_code: 351,
  },
  {
    name: "Xã Liêm Phong",
    code: 13450,
    division_type: "xã",
    codename: "xa_liem_phong",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hà",
    code: 13453,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 351,
  },
  {
    name: "Xã Liêm Cần",
    code: 13456,
    division_type: "xã",
    codename: "xa_liem_can",
    district_code: 351,
  },
  {
    name: "Xã Liêm Thuận",
    code: 13465,
    division_type: "xã",
    codename: "xa_liem_thuan",
    district_code: 351,
  },
  {
    name: "Xã Thanh Thủy",
    code: 13468,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 351,
  },
  {
    name: "Xã Thanh Phong",
    code: 13471,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 351,
  },
  {
    name: "Thị trấn Tân Thanh",
    code: 13474,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 351,
  },
  {
    name: "Xã Thanh Tân",
    code: 13477,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 351,
  },
  {
    name: "Xã Liêm Túc",
    code: 13480,
    division_type: "xã",
    codename: "xa_liem_tuc",
    district_code: 351,
  },
  {
    name: "Xã Liêm Sơn",
    code: 13483,
    division_type: "xã",
    codename: "xa_liem_son",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hương",
    code: 13486,
    division_type: "xã",
    codename: "xa_thanh_huong",
    district_code: 351,
  },
  {
    name: "Xã Thanh Nghị",
    code: 13489,
    division_type: "xã",
    codename: "xa_thanh_nghi",
    district_code: 351,
  },
  {
    name: "Xã Thanh Tâm",
    code: 13492,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 351,
  },
  {
    name: "Xã Thanh Nguyên",
    code: 13495,
    division_type: "xã",
    codename: "xa_thanh_nguyen",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hải",
    code: 13498,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 351,
  },
  {
    name: "Thị trấn Bình Mỹ",
    code: 13501,
    division_type: "thị trấn",
    codename: "thi_tran_binh_my",
    district_code: 352,
  },
  {
    name: "Xã Bình Nghĩa",
    code: 13504,
    division_type: "xã",
    codename: "xa_binh_nghia",
    district_code: 352,
  },
  {
    name: "Xã Tràng An",
    code: 13510,
    division_type: "xã",
    codename: "xa_trang_an",
    district_code: 352,
  },
  {
    name: "Xã Đồng Du",
    code: 13516,
    division_type: "xã",
    codename: "xa_dong_du",
    district_code: 352,
  },
  {
    name: "Xã Ngọc Lũ",
    code: 13519,
    division_type: "xã",
    codename: "xa_ngoc_lu",
    district_code: 352,
  },
  {
    name: "Xã Hưng Công",
    code: 13522,
    division_type: "xã",
    codename: "xa_hung_cong",
    district_code: 352,
  },
  {
    name: "Xã Đồn Xá",
    code: 13525,
    division_type: "xã",
    codename: "xa_don_xa",
    district_code: 352,
  },
  {
    name: "Xã An Ninh",
    code: 13528,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 352,
  },
  {
    name: "Xã Bồ Đề",
    code: 13531,
    division_type: "xã",
    codename: "xa_bo_de",
    district_code: 352,
  },
  {
    name: "Xã Bối Cầu",
    code: 13534,
    division_type: "xã",
    codename: "xa_boi_cau",
    district_code: 352,
  },
  {
    name: "Xã An Nội",
    code: 13540,
    division_type: "xã",
    codename: "xa_an_noi",
    district_code: 352,
  },
  {
    name: "Xã Vũ Bản",
    code: 13543,
    division_type: "xã",
    codename: "xa_vu_ban",
    district_code: 352,
  },
  {
    name: "Xã Trung Lương",
    code: 13546,
    division_type: "xã",
    codename: "xa_trung_luong",
    district_code: 352,
  },
  {
    name: "Xã An Đổ",
    code: 13552,
    division_type: "xã",
    codename: "xa_an_do",
    district_code: 352,
  },
  {
    name: "Xã La Sơn",
    code: 13555,
    division_type: "xã",
    codename: "xa_la_son",
    district_code: 352,
  },
  {
    name: "Xã Tiêu Động",
    code: 13558,
    division_type: "xã",
    codename: "xa_tieu_dong",
    district_code: 352,
  },
  {
    name: "Xã An Lão",
    code: 13561,
    division_type: "xã",
    codename: "xa_an_lao",
    district_code: 352,
  },
  {
    name: "Xã Hợp Lý",
    code: 13567,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 353,
  },
  {
    name: "Xã Nguyên Lý",
    code: 13570,
    division_type: "xã",
    codename: "xa_nguyen_ly",
    district_code: 353,
  },
  {
    name: "Xã Chính Lý",
    code: 13573,
    division_type: "xã",
    codename: "xa_chinh_ly",
    district_code: 353,
  },
  {
    name: "Xã Chân Lý",
    code: 13576,
    division_type: "xã",
    codename: "xa_chan_ly",
    district_code: 353,
  },
  {
    name: "Xã Đạo Lý",
    code: 13579,
    division_type: "xã",
    codename: "xa_dao_ly",
    district_code: 353,
  },
  {
    name: "Xã Công Lý",
    code: 13582,
    division_type: "xã",
    codename: "xa_cong_ly",
    district_code: 353,
  },
  {
    name: "Xã Văn Lý",
    code: 13585,
    division_type: "xã",
    codename: "xa_van_ly",
    district_code: 353,
  },
  {
    name: "Xã Bắc Lý",
    code: 13588,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 353,
  },
  {
    name: "Xã Đức Lý",
    code: 13591,
    division_type: "xã",
    codename: "xa_duc_ly",
    district_code: 353,
  },
  {
    name: "Xã Trần Hưng Đạo",
    code: 13594,
    division_type: "xã",
    codename: "xa_tran_hung_dao",
    district_code: 353,
  },
  {
    name: "Thị trấn Vĩnh Trụ",
    code: 13597,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tru",
    district_code: 353,
  },
  {
    name: "Xã Nhân Thịnh",
    code: 13600,
    division_type: "xã",
    codename: "xa_nhan_thinh",
    district_code: 353,
  },
  {
    name: "Xã Nhân Khang",
    code: 13606,
    division_type: "xã",
    codename: "xa_nhan_khang",
    district_code: 353,
  },
  {
    name: "Xã Nhân Mỹ",
    code: 13609,
    division_type: "xã",
    codename: "xa_nhan_my",
    district_code: 353,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 13612,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 353,
  },
  {
    name: "Xã Nhân Chính",
    code: 13615,
    division_type: "xã",
    codename: "xa_nhan_chinh",
    district_code: 353,
  },
  {
    name: "Xã Nhân Bình",
    code: 13618,
    division_type: "xã",
    codename: "xa_nhan_binh",
    district_code: 353,
  },
  {
    name: "Xã Phú Phúc",
    code: 13621,
    division_type: "xã",
    codename: "xa_phu_phuc",
    district_code: 353,
  },
  {
    name: "Xã Xuân Khê",
    code: 13624,
    division_type: "xã",
    codename: "xa_xuan_khe",
    district_code: 353,
  },
  {
    name: "Xã Tiến Thắng",
    code: 13627,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 353,
  },
  {
    name: "Xã Hòa Hậu",
    code: 13630,
    division_type: "xã",
    codename: "xa_hoa_hau",
    district_code: 353,
  },
  {
    name: "Phường Hạ Long",
    code: 13633,
    division_type: "phường",
    codename: "phuong_ha_long",
    district_code: 356,
  },
  {
    name: "Phường Trần Tế Xương",
    code: 13636,
    division_type: "phường",
    codename: "phuong_tran_te_xuong",
    district_code: 356,
  },
  {
    name: "Phường Vị Hoàng",
    code: 13639,
    division_type: "phường",
    codename: "phuong_vi_hoang",
    district_code: 356,
  },
  {
    name: "Phường Vị Xuyên",
    code: 13642,
    division_type: "phường",
    codename: "phuong_vi_xuyen",
    district_code: 356,
  },
  {
    name: "Phường Quang Trung",
    code: 13645,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 356,
  },
  {
    name: "Phường Cửa Bắc",
    code: 13648,
    division_type: "phường",
    codename: "phuong_cua_bac",
    district_code: 356,
  },
  {
    name: "Phường Nguyễn Du",
    code: 13651,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 356,
  },
  {
    name: "Phường Bà Triệu",
    code: 13654,
    division_type: "phường",
    codename: "phuong_ba_trieu",
    district_code: 356,
  },
  {
    name: "Phường Trường Thi",
    code: 13657,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 356,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 13660,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 356,
  },
  {
    name: "Phường Ngô Quyền",
    code: 13663,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 356,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 13666,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 356,
  },
  {
    name: "Phường Trần Đăng Ninh",
    code: 13669,
    division_type: "phường",
    codename: "phuong_tran_dang_ninh",
    district_code: 356,
  },
  {
    name: "Phường Năng Tĩnh",
    code: 13672,
    division_type: "phường",
    codename: "phuong_nang_tinh",
    district_code: 356,
  },
  {
    name: "Phường Văn Miếu",
    code: 13675,
    division_type: "phường",
    codename: "phuong_van_mieu",
    district_code: 356,
  },
  {
    name: "Phường Trần Quang Khải",
    code: 13678,
    division_type: "phường",
    codename: "phuong_tran_quang_khai",
    district_code: 356,
  },
  {
    name: "Phường Thống Nhất",
    code: 13681,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 356,
  },
  {
    name: "Phường Lộc Hạ",
    code: 13684,
    division_type: "phường",
    codename: "phuong_loc_ha",
    district_code: 356,
  },
  {
    name: "Phường Lộc Vượng",
    code: 13687,
    division_type: "phường",
    codename: "phuong_loc_vuong",
    district_code: 356,
  },
  {
    name: "Phường Cửa Nam",
    code: 13690,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 356,
  },
  {
    name: "Phường Lộc Hòa",
    code: 13693,
    division_type: "phường",
    codename: "phuong_loc_hoa",
    district_code: 356,
  },
  {
    name: "Xã Nam Phong",
    code: 13696,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 356,
  },
  {
    name: "Phường Mỹ Xá",
    code: 13699,
    division_type: "phường",
    codename: "phuong_my_xa",
    district_code: 356,
  },
  {
    name: "Xã Lộc An",
    code: 13702,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 356,
  },
  {
    name: "Xã Nam Vân",
    code: 13705,
    division_type: "xã",
    codename: "xa_nam_van",
    district_code: 356,
  },
  {
    name: "Thị trấn Mỹ Lộc",
    code: 13708,
    division_type: "thị trấn",
    codename: "thi_tran_my_loc",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Hà",
    code: 13711,
    division_type: "xã",
    codename: "xa_my_ha",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Tiến",
    code: 13714,
    division_type: "xã",
    codename: "xa_my_tien",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thắng",
    code: 13717,
    division_type: "xã",
    codename: "xa_my_thang",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Trung",
    code: 13720,
    division_type: "xã",
    codename: "xa_my_trung",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Tân",
    code: 13723,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Phúc",
    code: 13726,
    division_type: "xã",
    codename: "xa_my_phuc",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 13729,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 13732,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thịnh",
    code: 13735,
    division_type: "xã",
    codename: "xa_my_thinh",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thành",
    code: 13738,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 358,
  },
  {
    name: "Thị trấn Gôi",
    code: 13741,
    division_type: "thị trấn",
    codename: "thi_tran_goi",
    district_code: 359,
  },
  {
    name: "Xã Minh Thuận",
    code: 13744,
    division_type: "xã",
    codename: "xa_minh_thuan",
    district_code: 359,
  },
  {
    name: "Xã Hiển Khánh",
    code: 13747,
    division_type: "xã",
    codename: "xa_hien_khanh",
    district_code: 359,
  },
  {
    name: "Xã Tân Khánh",
    code: 13750,
    division_type: "xã",
    codename: "xa_tan_khanh",
    district_code: 359,
  },
  {
    name: "Xã Hợp Hưng",
    code: 13753,
    division_type: "xã",
    codename: "xa_hop_hung",
    district_code: 359,
  },
  {
    name: "Xã Đại An",
    code: 13756,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 359,
  },
  {
    name: "Xã Tân Thành",
    code: 13759,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 359,
  },
  {
    name: "Xã Cộng Hòa",
    code: 13762,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 359,
  },
  {
    name: "Xã Trung Thành",
    code: 13765,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 359,
  },
  {
    name: "Xã Quang Trung",
    code: 13768,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 359,
  },
  {
    name: "Xã Minh Tân",
    code: 13771,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 359,
  },
  {
    name: "Xã Liên Bảo",
    code: 13774,
    division_type: "xã",
    codename: "xa_lien_bao",
    district_code: 359,
  },
  {
    name: "Xã Thành Lợi",
    code: 13777,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 359,
  },
  {
    name: "Xã Kim Thái",
    code: 13780,
    division_type: "xã",
    codename: "xa_kim_thai",
    district_code: 359,
  },
  {
    name: "Xã Liên Minh",
    code: 13783,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 359,
  },
  {
    name: "Xã Đại Thắng",
    code: 13786,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 359,
  },
  {
    name: "Xã Tam Thanh",
    code: 13789,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 359,
  },
  {
    name: "Xã Vĩnh Hào",
    code: 13792,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 359,
  },
  {
    name: "Thị trấn Lâm",
    code: 13795,
    division_type: "thị trấn",
    codename: "thi_tran_lam",
    district_code: 360,
  },
  {
    name: "Xã Yên Trung",
    code: 13798,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 360,
  },
  {
    name: "Xã Yên Thành",
    code: 13801,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 360,
  },
  {
    name: "Xã Yên Tân",
    code: 13804,
    division_type: "xã",
    codename: "xa_yen_tan",
    district_code: 360,
  },
  {
    name: "Xã Yên Lợi",
    code: 13807,
    division_type: "xã",
    codename: "xa_yen_loi",
    district_code: 360,
  },
  {
    name: "Xã Yên Thọ",
    code: 13810,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 360,
  },
  {
    name: "Xã Yên Nghĩa",
    code: 13813,
    division_type: "xã",
    codename: "xa_yen_nghia",
    district_code: 360,
  },
  {
    name: "Xã Yên Minh",
    code: 13816,
    division_type: "xã",
    codename: "xa_yen_minh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phương",
    code: 13819,
    division_type: "xã",
    codename: "xa_yen_phuong",
    district_code: 360,
  },
  {
    name: "Xã Yên Chính",
    code: 13822,
    division_type: "xã",
    codename: "xa_yen_chinh",
    district_code: 360,
  },
  {
    name: "Xã Yên Bình",
    code: 13825,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phú",
    code: 13828,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 360,
  },
  {
    name: "Xã Yên Mỹ",
    code: 13831,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 360,
  },
  {
    name: "Xã Yên Dương",
    code: 13834,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 360,
  },
  {
    name: "Xã Yên Hưng",
    code: 13840,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 360,
  },
  {
    name: "Xã Yên Khánh",
    code: 13843,
    division_type: "xã",
    codename: "xa_yen_khanh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phong",
    code: 13846,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 360,
  },
  {
    name: "Xã Yên Ninh",
    code: 13849,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 360,
  },
  {
    name: "Xã Yên Lương",
    code: 13852,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 360,
  },
  {
    name: "Xã Yên Hồng",
    code: 13855,
    division_type: "xã",
    codename: "xa_yen_hong",
    district_code: 360,
  },
  {
    name: "Xã Yên Quang",
    code: 13858,
    division_type: "xã",
    codename: "xa_yen_quang",
    district_code: 360,
  },
  {
    name: "Xã Yên Tiến",
    code: 13861,
    division_type: "xã",
    codename: "xa_yen_tien",
    district_code: 360,
  },
  {
    name: "Xã Yên Thắng",
    code: 13864,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 360,
  },
  {
    name: "Xã Yên Phúc",
    code: 13867,
    division_type: "xã",
    codename: "xa_yen_phuc",
    district_code: 360,
  },
  {
    name: "Xã Yên Cường",
    code: 13870,
    division_type: "xã",
    codename: "xa_yen_cuong",
    district_code: 360,
  },
  {
    name: "Xã Yên Lộc",
    code: 13873,
    division_type: "xã",
    codename: "xa_yen_loc",
    district_code: 360,
  },
  {
    name: "Xã Yên Bằng",
    code: 13876,
    division_type: "xã",
    codename: "xa_yen_bang",
    district_code: 360,
  },
  {
    name: "Xã Yên Đồng",
    code: 13879,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 360,
  },
  {
    name: "Xã Yên Khang",
    code: 13882,
    division_type: "xã",
    codename: "xa_yen_khang",
    district_code: 360,
  },
  {
    name: "Xã Yên Nhân",
    code: 13885,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 360,
  },
  {
    name: "Xã Yên Trị",
    code: 13888,
    division_type: "xã",
    codename: "xa_yen_tri",
    district_code: 360,
  },
  {
    name: "Thị trấn Liễu Đề",
    code: 13891,
    division_type: "thị trấn",
    codename: "thi_tran_lieu_de",
    district_code: 361,
  },
  {
    name: "Thị trấn Rạng Đông",
    code: 13894,
    division_type: "thị trấn",
    codename: "thi_tran_rang_dong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Đồng",
    code: 13897,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thịnh",
    code: 13900,
    division_type: "xã",
    codename: "xa_nghia_thinh",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Minh",
    code: 13903,
    division_type: "xã",
    codename: "xa_nghia_minh",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thái",
    code: 13906,
    division_type: "xã",
    codename: "xa_nghia_thai",
    district_code: 361,
  },
  {
    name: "Xã Hoàng Nam",
    code: 13909,
    division_type: "xã",
    codename: "xa_hoang_nam",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Châu",
    code: 13912,
    division_type: "xã",
    codename: "xa_nghia_chau",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 13915,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 13918,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lạc",
    code: 13921,
    division_type: "xã",
    codename: "xa_nghia_lac",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hồng",
    code: 13924,
    division_type: "xã",
    codename: "xa_nghia_hong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Phong",
    code: 13927,
    division_type: "xã",
    codename: "xa_nghia_phong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 13930,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 13933,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 361,
  },
  {
    name: "Thị trấn Quỹ Nhất",
    code: 13936,
    division_type: "thị trấn",
    codename: "thi_tran_quy_nhat",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Tân",
    code: 13939,
    division_type: "xã",
    codename: "xa_nghia_tan",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hùng",
    code: 13942,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 13945,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 13948,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 361,
  },
  {
    name: "Xã Phúc Thắng",
    code: 13951,
    division_type: "xã",
    codename: "xa_phuc_thang",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 13954,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hải",
    code: 13957,
    division_type: "xã",
    codename: "xa_nghia_hai",
    district_code: 361,
  },
  {
    name: "Xã Nam Điền",
    code: 13963,
    division_type: "xã",
    codename: "xa_nam_dien",
    district_code: 361,
  },
  {
    name: "Thị trấn Nam Giang",
    code: 13966,
    division_type: "thị trấn",
    codename: "thi_tran_nam_giang",
    district_code: 362,
  },
  {
    name: "Xã Nam Mỹ",
    code: 13969,
    division_type: "xã",
    codename: "xa_nam_my",
    district_code: 362,
  },
  {
    name: "Xã Điền Xá",
    code: 13972,
    division_type: "xã",
    codename: "xa_dien_xa",
    district_code: 362,
  },
  {
    name: "Xã Nghĩa An",
    code: 13975,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 362,
  },
  {
    name: "Xã Nam Thắng",
    code: 13978,
    division_type: "xã",
    codename: "xa_nam_thang",
    district_code: 362,
  },
  {
    name: "Xã Nam Toàn",
    code: 13981,
    division_type: "xã",
    codename: "xa_nam_toan",
    district_code: 362,
  },
  {
    name: "Xã Hồng Quang",
    code: 13984,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 362,
  },
  {
    name: "Xã Tân Thịnh",
    code: 13987,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 362,
  },
  {
    name: "Xã Nam Cường",
    code: 13990,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 362,
  },
  {
    name: "Xã Nam Hồng",
    code: 13993,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 362,
  },
  {
    name: "Xã Nam Hùng",
    code: 13996,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 362,
  },
  {
    name: "Xã Nam Hoa",
    code: 13999,
    division_type: "xã",
    codename: "xa_nam_hoa",
    district_code: 362,
  },
  {
    name: "Xã Nam Dương",
    code: 14002,
    division_type: "xã",
    codename: "xa_nam_duong",
    district_code: 362,
  },
  {
    name: "Xã Nam Thanh",
    code: 14005,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 362,
  },
  {
    name: "Xã Nam Lợi",
    code: 14008,
    division_type: "xã",
    codename: "xa_nam_loi",
    district_code: 362,
  },
  {
    name: "Xã Bình Minh",
    code: 14011,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 362,
  },
  {
    name: "Xã Đồng Sơn",
    code: 14014,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 362,
  },
  {
    name: "Xã Nam Tiến",
    code: 14017,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 362,
  },
  {
    name: "Xã Nam Hải",
    code: 14020,
    division_type: "xã",
    codename: "xa_nam_hai",
    district_code: 362,
  },
  {
    name: "Xã Nam Thái",
    code: 14023,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 362,
  },
  {
    name: "Thị trấn Cổ Lễ",
    code: 14026,
    division_type: "thị trấn",
    codename: "thi_tran_co_le",
    district_code: 363,
  },
  {
    name: "Xã Phương Định",
    code: 14029,
    division_type: "xã",
    codename: "xa_phuong_dinh",
    district_code: 363,
  },
  {
    name: "Xã Trực Chính",
    code: 14032,
    division_type: "xã",
    codename: "xa_truc_chinh",
    district_code: 363,
  },
  {
    name: "Xã Trung Đông",
    code: 14035,
    division_type: "xã",
    codename: "xa_trung_dong",
    district_code: 363,
  },
  {
    name: "Xã Liêm Hải",
    code: 14038,
    division_type: "xã",
    codename: "xa_liem_hai",
    district_code: 363,
  },
  {
    name: "Xã Trực Tuấn",
    code: 14041,
    division_type: "xã",
    codename: "xa_truc_tuan",
    district_code: 363,
  },
  {
    name: "Xã Việt Hùng",
    code: 14044,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Đạo",
    code: 14047,
    division_type: "xã",
    codename: "xa_truc_dao",
    district_code: 363,
  },
  {
    name: "Xã Trực Hưng",
    code: 14050,
    division_type: "xã",
    codename: "xa_truc_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Nội",
    code: 14053,
    division_type: "xã",
    codename: "xa_truc_noi",
    district_code: 363,
  },
  {
    name: "Thị trấn Cát Thành",
    code: 14056,
    division_type: "thị trấn",
    codename: "thi_tran_cat_thanh",
    district_code: 363,
  },
  {
    name: "Xã Trực Thanh",
    code: 14059,
    division_type: "xã",
    codename: "xa_truc_thanh",
    district_code: 363,
  },
  {
    name: "Xã Trực Khang",
    code: 14062,
    division_type: "xã",
    codename: "xa_truc_khang",
    district_code: 363,
  },
  {
    name: "Xã Trực Thuận",
    code: 14065,
    division_type: "xã",
    codename: "xa_truc_thuan",
    district_code: 363,
  },
  {
    name: "Xã Trực Mỹ",
    code: 14068,
    division_type: "xã",
    codename: "xa_truc_my",
    district_code: 363,
  },
  {
    name: "Xã Trực Đại",
    code: 14071,
    division_type: "xã",
    codename: "xa_truc_dai",
    district_code: 363,
  },
  {
    name: "Xã Trực Cường",
    code: 14074,
    division_type: "xã",
    codename: "xa_truc_cuong",
    district_code: 363,
  },
  {
    name: "Thị trấn Ninh Cường",
    code: 14077,
    division_type: "thị trấn",
    codename: "thi_tran_ninh_cuong",
    district_code: 363,
  },
  {
    name: "Xã Trực Thái",
    code: 14080,
    division_type: "xã",
    codename: "xa_truc_thai",
    district_code: 363,
  },
  {
    name: "Xã Trực Hùng",
    code: 14083,
    division_type: "xã",
    codename: "xa_truc_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Thắng",
    code: 14086,
    division_type: "xã",
    codename: "xa_truc_thang",
    district_code: 363,
  },
  {
    name: "Thị trấn Xuân Trường",
    code: 14089,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_truong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Châu",
    code: 14092,
    division_type: "xã",
    codename: "xa_xuan_chau",
    district_code: 364,
  },
  {
    name: "Xã Xuân Hồng",
    code: 14095,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thành",
    code: 14098,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thượng",
    code: 14101,
    division_type: "xã",
    codename: "xa_xuan_thuong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phong",
    code: 14104,
    division_type: "xã",
    codename: "xa_xuan_phong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Đài",
    code: 14107,
    division_type: "xã",
    codename: "xa_xuan_dai",
    district_code: 364,
  },
  {
    name: "Xã Xuân Tân",
    code: 14110,
    division_type: "xã",
    codename: "xa_xuan_tan",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thủy",
    code: 14113,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 364,
  },
  {
    name: "Xã Xuân Ngọc",
    code: 14116,
    division_type: "xã",
    codename: "xa_xuan_ngoc",
    district_code: 364,
  },
  {
    name: "Xã Xuân Bắc",
    code: 14119,
    division_type: "xã",
    codename: "xa_xuan_bac",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phương",
    code: 14122,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 364,
  },
  {
    name: "Xã Thọ Nghiệp",
    code: 14125,
    division_type: "xã",
    codename: "xa_tho_nghiep",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phú",
    code: 14128,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 364,
  },
  {
    name: "Xã Xuân Trung",
    code: 14131,
    division_type: "xã",
    codename: "xa_xuan_trung",
    district_code: 364,
  },
  {
    name: "Xã Xuân Vinh",
    code: 14134,
    division_type: "xã",
    codename: "xa_xuan_vinh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Kiên",
    code: 14137,
    division_type: "xã",
    codename: "xa_xuan_kien",
    district_code: 364,
  },
  {
    name: "Xã Xuân Tiến",
    code: 14140,
    division_type: "xã",
    codename: "xa_xuan_tien",
    district_code: 364,
  },
  {
    name: "Xã Xuân Ninh",
    code: 14143,
    division_type: "xã",
    codename: "xa_xuan_ninh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Hòa",
    code: 14146,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 364,
  },
  {
    name: "Thị trấn Ngô Đồng",
    code: 14149,
    division_type: "thị trấn",
    codename: "thi_tran_ngo_dong",
    district_code: 365,
  },
  {
    name: "Thị trấn Quất Lâm",
    code: 14152,
    division_type: "thị trấn",
    codename: "thi_tran_quat_lam",
    district_code: 365,
  },
  {
    name: "Xã Giao Hương",
    code: 14155,
    division_type: "xã",
    codename: "xa_giao_huong",
    district_code: 365,
  },
  {
    name: "Xã Hồng Thuận",
    code: 14158,
    division_type: "xã",
    codename: "xa_hong_thuan",
    district_code: 365,
  },
  {
    name: "Xã Giao Thiện",
    code: 14161,
    division_type: "xã",
    codename: "xa_giao_thien",
    district_code: 365,
  },
  {
    name: "Xã Giao Thanh",
    code: 14164,
    division_type: "xã",
    codename: "xa_giao_thanh",
    district_code: 365,
  },
  {
    name: "Xã Hoành Sơn",
    code: 14167,
    division_type: "xã",
    codename: "xa_hoanh_son",
    district_code: 365,
  },
  {
    name: "Xã Bình Hòa",
    code: 14170,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 365,
  },
  {
    name: "Xã Giao Tiến",
    code: 14173,
    division_type: "xã",
    codename: "xa_giao_tien",
    district_code: 365,
  },
  {
    name: "Xã Giao Hà",
    code: 14176,
    division_type: "xã",
    codename: "xa_giao_ha",
    district_code: 365,
  },
  {
    name: "Xã Giao Nhân",
    code: 14179,
    division_type: "xã",
    codename: "xa_giao_nhan",
    district_code: 365,
  },
  {
    name: "Xã Giao An",
    code: 14182,
    division_type: "xã",
    codename: "xa_giao_an",
    district_code: 365,
  },
  {
    name: "Xã Giao Lạc",
    code: 14185,
    division_type: "xã",
    codename: "xa_giao_lac",
    district_code: 365,
  },
  {
    name: "Xã Giao Châu",
    code: 14188,
    division_type: "xã",
    codename: "xa_giao_chau",
    district_code: 365,
  },
  {
    name: "Xã Giao Tân",
    code: 14191,
    division_type: "xã",
    codename: "xa_giao_tan",
    district_code: 365,
  },
  {
    name: "Xã Giao Yến",
    code: 14194,
    division_type: "xã",
    codename: "xa_giao_yen",
    district_code: 365,
  },
  {
    name: "Xã Giao Xuân",
    code: 14197,
    division_type: "xã",
    codename: "xa_giao_xuan",
    district_code: 365,
  },
  {
    name: "Xã Giao Thịnh",
    code: 14200,
    division_type: "xã",
    codename: "xa_giao_thinh",
    district_code: 365,
  },
  {
    name: "Xã Giao Hải",
    code: 14203,
    division_type: "xã",
    codename: "xa_giao_hai",
    district_code: 365,
  },
  {
    name: "Xã Bạch Long",
    code: 14206,
    division_type: "xã",
    codename: "xa_bach_long",
    district_code: 365,
  },
  {
    name: "Xã Giao Long",
    code: 14209,
    division_type: "xã",
    codename: "xa_giao_long",
    district_code: 365,
  },
  {
    name: "Xã Giao Phong",
    code: 14212,
    division_type: "xã",
    codename: "xa_giao_phong",
    district_code: 365,
  },
  {
    name: "Thị trấn Yên Định",
    code: 14215,
    division_type: "thị trấn",
    codename: "thi_tran_yen_dinh",
    district_code: 366,
  },
  {
    name: "Thị trấn Cồn",
    code: 14218,
    division_type: "thị trấn",
    codename: "thi_tran_con",
    district_code: 366,
  },
  {
    name: "Thị trấn Thịnh Long",
    code: 14221,
    division_type: "thị trấn",
    codename: "thi_tran_thinh_long",
    district_code: 366,
  },
  {
    name: "Xã Hải Nam",
    code: 14224,
    division_type: "xã",
    codename: "xa_hai_nam",
    district_code: 366,
  },
  {
    name: "Xã Hải Trung",
    code: 14227,
    division_type: "xã",
    codename: "xa_hai_trung",
    district_code: 366,
  },
  {
    name: "Xã Hải Vân",
    code: 14230,
    division_type: "xã",
    codename: "xa_hai_van",
    district_code: 366,
  },
  {
    name: "Xã Hải Minh",
    code: 14233,
    division_type: "xã",
    codename: "xa_hai_minh",
    district_code: 366,
  },
  {
    name: "Xã Hải Anh",
    code: 14236,
    division_type: "xã",
    codename: "xa_hai_anh",
    district_code: 366,
  },
  {
    name: "Xã Hải Hưng",
    code: 14239,
    division_type: "xã",
    codename: "xa_hai_hung",
    district_code: 366,
  },
  {
    name: "Xã Hải Bắc",
    code: 14242,
    division_type: "xã",
    codename: "xa_hai_bac",
    district_code: 366,
  },
  {
    name: "Xã Hải Phúc",
    code: 14245,
    division_type: "xã",
    codename: "xa_hai_phuc",
    district_code: 366,
  },
  {
    name: "Xã Hải Thanh",
    code: 14248,
    division_type: "xã",
    codename: "xa_hai_thanh",
    district_code: 366,
  },
  {
    name: "Xã Hải Hà",
    code: 14251,
    division_type: "xã",
    codename: "xa_hai_ha",
    district_code: 366,
  },
  {
    name: "Xã Hải Long",
    code: 14254,
    division_type: "xã",
    codename: "xa_hai_long",
    district_code: 366,
  },
  {
    name: "Xã Hải Phương",
    code: 14257,
    division_type: "xã",
    codename: "xa_hai_phuong",
    district_code: 366,
  },
  {
    name: "Xã Hải Đường",
    code: 14260,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 366,
  },
  {
    name: "Xã Hải Lộc",
    code: 14263,
    division_type: "xã",
    codename: "xa_hai_loc",
    district_code: 366,
  },
  {
    name: "Xã Hải Quang",
    code: 14266,
    division_type: "xã",
    codename: "xa_hai_quang",
    district_code: 366,
  },
  {
    name: "Xã Hải Đông",
    code: 14269,
    division_type: "xã",
    codename: "xa_hai_dong",
    district_code: 366,
  },
  {
    name: "Xã Hải Sơn",
    code: 14272,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 366,
  },
  {
    name: "Xã Hải Tân",
    code: 14275,
    division_type: "xã",
    codename: "xa_hai_tan",
    district_code: 366,
  },
  {
    name: "Xã Hải Phong",
    code: 14281,
    division_type: "xã",
    codename: "xa_hai_phong",
    district_code: 366,
  },
  {
    name: "Xã Hải An",
    code: 14284,
    division_type: "xã",
    codename: "xa_hai_an",
    district_code: 366,
  },
  {
    name: "Xã Hải Tây",
    code: 14287,
    division_type: "xã",
    codename: "xa_hai_tay",
    district_code: 366,
  },
  {
    name: "Xã Hải Lý",
    code: 14290,
    division_type: "xã",
    codename: "xa_hai_ly",
    district_code: 366,
  },
  {
    name: "Xã Hải Phú",
    code: 14293,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 366,
  },
  {
    name: "Xã Hải Giang",
    code: 14296,
    division_type: "xã",
    codename: "xa_hai_giang",
    district_code: 366,
  },
  {
    name: "Xã Hải Cường",
    code: 14299,
    division_type: "xã",
    codename: "xa_hai_cuong",
    district_code: 366,
  },
  {
    name: "Xã Hải Ninh",
    code: 14302,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 366,
  },
  {
    name: "Xã Hải Chính",
    code: 14305,
    division_type: "xã",
    codename: "xa_hai_chinh",
    district_code: 366,
  },
  {
    name: "Xã Hải Xuân",
    code: 14308,
    division_type: "xã",
    codename: "xa_hai_xuan",
    district_code: 366,
  },
  {
    name: "Xã Hải Châu",
    code: 14311,
    division_type: "xã",
    codename: "xa_hai_chau",
    district_code: 366,
  },
  {
    name: "Xã Hải Triều",
    code: 14314,
    division_type: "xã",
    codename: "xa_hai_trieu",
    district_code: 366,
  },
  {
    name: "Xã Hải Hòa",
    code: 14317,
    division_type: "xã",
    codename: "xa_hai_hoa",
    district_code: 366,
  },
  {
    name: "Phường Đông Thành",
    code: 14320,
    division_type: "phường",
    codename: "phuong_dong_thanh",
    district_code: 369,
  },
  {
    name: "Phường Tân Thành",
    code: 14323,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 369,
  },
  {
    name: "Phường Thanh Bình",
    code: 14326,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 369,
  },
  {
    name: "Phường Vân Giang",
    code: 14329,
    division_type: "phường",
    codename: "phuong_van_giang",
    district_code: 369,
  },
  {
    name: "Phường Bích Đào",
    code: 14332,
    division_type: "phường",
    codename: "phuong_bich_dao",
    district_code: 369,
  },
  {
    name: "Phường Phúc Thành",
    code: 14335,
    division_type: "phường",
    codename: "phuong_phuc_thanh",
    district_code: 369,
  },
  {
    name: "Phường Nam Bình",
    code: 14338,
    division_type: "phường",
    codename: "phuong_nam_binh",
    district_code: 369,
  },
  {
    name: "Phường Nam Thành",
    code: 14341,
    division_type: "phường",
    codename: "phuong_nam_thanh",
    district_code: 369,
  },
  {
    name: "Phường Ninh Khánh",
    code: 14344,
    division_type: "phường",
    codename: "phuong_ninh_khanh",
    district_code: 369,
  },
  {
    name: "Xã Ninh Nhất",
    code: 14347,
    division_type: "xã",
    codename: "xa_ninh_nhat",
    district_code: 369,
  },
  {
    name: "Xã Ninh Tiến",
    code: 14350,
    division_type: "xã",
    codename: "xa_ninh_tien",
    district_code: 369,
  },
  {
    name: "Xã Ninh Phúc",
    code: 14353,
    division_type: "xã",
    codename: "xa_ninh_phuc",
    district_code: 369,
  },
  {
    name: "Phường Ninh Sơn",
    code: 14356,
    division_type: "phường",
    codename: "phuong_ninh_son",
    district_code: 369,
  },
  {
    name: "Phường Ninh Phong",
    code: 14359,
    division_type: "phường",
    codename: "phuong_ninh_phong",
    district_code: 369,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14362,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 370,
  },
  {
    name: "Phường Trung Sơn",
    code: 14365,
    division_type: "phường",
    codename: "phuong_trung_son",
    district_code: 370,
  },
  {
    name: "Phường Nam Sơn",
    code: 14368,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 370,
  },
  {
    name: "Phường Tây Sơn",
    code: 14369,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 370,
  },
  {
    name: "Xã Yên Sơn",
    code: 14371,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 370,
  },
  {
    name: "Phường Yên Bình",
    code: 14374,
    division_type: "phường",
    codename: "phuong_yen_binh",
    district_code: 370,
  },
  {
    name: "Phường Tân Bình",
    code: 14375,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 370,
  },
  {
    name: "Xã Quang Sơn",
    code: 14377,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 370,
  },
  {
    name: "Xã Đông Sơn",
    code: 14380,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 370,
  },
  {
    name: "Thị trấn Nho Quan",
    code: 14383,
    division_type: "thị trấn",
    codename: "thi_tran_nho_quan",
    district_code: 372,
  },
  {
    name: "Xã Xích Thổ",
    code: 14386,
    division_type: "xã",
    codename: "xa_xich_tho",
    district_code: 372,
  },
  {
    name: "Xã Gia Lâm",
    code: 14389,
    division_type: "xã",
    codename: "xa_gia_lam",
    district_code: 372,
  },
  {
    name: "Xã Gia Sơn",
    code: 14392,
    division_type: "xã",
    codename: "xa_gia_son",
    district_code: 372,
  },
  {
    name: "Xã Thạch Bình",
    code: 14395,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 372,
  },
  {
    name: "Xã Gia Thủy",
    code: 14398,
    division_type: "xã",
    codename: "xa_gia_thuy",
    district_code: 372,
  },
  {
    name: "Xã Gia Tường",
    code: 14401,
    division_type: "xã",
    codename: "xa_gia_tuong",
    district_code: 372,
  },
  {
    name: "Xã Cúc Phương",
    code: 14404,
    division_type: "xã",
    codename: "xa_cuc_phuong",
    district_code: 372,
  },
  {
    name: "Xã Phú Sơn",
    code: 14407,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 372,
  },
  {
    name: "Xã Đức Long",
    code: 14410,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 372,
  },
  {
    name: "Xã Lạc Vân",
    code: 14413,
    division_type: "xã",
    codename: "xa_lac_van",
    district_code: 372,
  },
  {
    name: "Xã Đồng Phong",
    code: 14416,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 372,
  },
  {
    name: "Xã Yên Quang",
    code: 14419,
    division_type: "xã",
    codename: "xa_yen_quang",
    district_code: 372,
  },
  {
    name: "Xã Lạng Phong",
    code: 14422,
    division_type: "xã",
    codename: "xa_lang_phong",
    district_code: 372,
  },
  {
    name: "Xã Thượng Hòa",
    code: 14425,
    division_type: "xã",
    codename: "xa_thuong_hoa",
    district_code: 372,
  },
  {
    name: "Xã Văn Phong",
    code: 14428,
    division_type: "xã",
    codename: "xa_van_phong",
    district_code: 372,
  },
  {
    name: "Xã Văn Phương",
    code: 14431,
    division_type: "xã",
    codename: "xa_van_phuong",
    district_code: 372,
  },
  {
    name: "Xã Thanh Lạc",
    code: 14434,
    division_type: "xã",
    codename: "xa_thanh_lac",
    district_code: 372,
  },
  {
    name: "Xã Sơn Lai",
    code: 14437,
    division_type: "xã",
    codename: "xa_son_lai",
    district_code: 372,
  },
  {
    name: "Xã Sơn Thành",
    code: 14440,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 372,
  },
  {
    name: "Xã Văn Phú",
    code: 14443,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 372,
  },
  {
    name: "Xã Phú Lộc",
    code: 14446,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 372,
  },
  {
    name: "Xã Kỳ Phú",
    code: 14449,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 372,
  },
  {
    name: "Xã Quỳnh Lưu",
    code: 14452,
    division_type: "xã",
    codename: "xa_quynh_luu",
    district_code: 372,
  },
  {
    name: "Xã Sơn Hà",
    code: 14455,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 372,
  },
  {
    name: "Xã Phú Long",
    code: 14458,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 372,
  },
  {
    name: "Xã Quảng Lạc",
    code: 14461,
    division_type: "xã",
    codename: "xa_quang_lac",
    district_code: 372,
  },
  {
    name: "Thị trấn Me",
    code: 14464,
    division_type: "thị trấn",
    codename: "thi_tran_me",
    district_code: 373,
  },
  {
    name: "Xã Gia Hòa",
    code: 14467,
    division_type: "xã",
    codename: "xa_gia_hoa",
    district_code: 373,
  },
  {
    name: "Xã Gia Hưng",
    code: 14470,
    division_type: "xã",
    codename: "xa_gia_hung",
    district_code: 373,
  },
  {
    name: "Xã Liên Sơn",
    code: 14473,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 373,
  },
  {
    name: "Xã Gia Thanh",
    code: 14476,
    division_type: "xã",
    codename: "xa_gia_thanh",
    district_code: 373,
  },
  {
    name: "Xã Gia Vân",
    code: 14479,
    division_type: "xã",
    codename: "xa_gia_van",
    district_code: 373,
  },
  {
    name: "Xã Gia Phú",
    code: 14482,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 373,
  },
  {
    name: "Xã Gia Xuân",
    code: 14485,
    division_type: "xã",
    codename: "xa_gia_xuan",
    district_code: 373,
  },
  {
    name: "Xã Gia Lập",
    code: 14488,
    division_type: "xã",
    codename: "xa_gia_lap",
    district_code: 373,
  },
  {
    name: "Xã Gia Vượng",
    code: 14491,
    division_type: "xã",
    codename: "xa_gia_vuong",
    district_code: 373,
  },
  {
    name: "Xã Gia Trấn",
    code: 14494,
    division_type: "xã",
    codename: "xa_gia_tran",
    district_code: 373,
  },
  {
    name: "Xã Gia Thịnh",
    code: 14497,
    division_type: "xã",
    codename: "xa_gia_thinh",
    district_code: 373,
  },
  {
    name: "Xã Gia Phương",
    code: 14500,
    division_type: "xã",
    codename: "xa_gia_phuong",
    district_code: 373,
  },
  {
    name: "Xã Gia Tân",
    code: 14503,
    division_type: "xã",
    codename: "xa_gia_tan",
    district_code: 373,
  },
  {
    name: "Xã Gia Thắng",
    code: 14506,
    division_type: "xã",
    codename: "xa_gia_thang",
    district_code: 373,
  },
  {
    name: "Xã Gia Trung",
    code: 14509,
    division_type: "xã",
    codename: "xa_gia_trung",
    district_code: 373,
  },
  {
    name: "Xã Gia Minh",
    code: 14512,
    division_type: "xã",
    codename: "xa_gia_minh",
    district_code: 373,
  },
  {
    name: "Xã Gia Lạc",
    code: 14515,
    division_type: "xã",
    codename: "xa_gia_lac",
    district_code: 373,
  },
  {
    name: "Xã Gia Tiến",
    code: 14518,
    division_type: "xã",
    codename: "xa_gia_tien",
    district_code: 373,
  },
  {
    name: "Xã Gia Sinh",
    code: 14521,
    division_type: "xã",
    codename: "xa_gia_sinh",
    district_code: 373,
  },
  {
    name: "Xã Gia Phong",
    code: 14524,
    division_type: "xã",
    codename: "xa_gia_phong",
    district_code: 373,
  },
  {
    name: "Thị trấn Thiên Tôn",
    code: 14527,
    division_type: "thị trấn",
    codename: "thi_tran_thien_ton",
    district_code: 374,
  },
  {
    name: "Xã Ninh Giang",
    code: 14530,
    division_type: "xã",
    codename: "xa_ninh_giang",
    district_code: 374,
  },
  {
    name: "Xã Trường Yên",
    code: 14533,
    division_type: "xã",
    codename: "xa_truong_yen",
    district_code: 374,
  },
  {
    name: "Xã Ninh Khang",
    code: 14536,
    division_type: "xã",
    codename: "xa_ninh_khang",
    district_code: 374,
  },
  {
    name: "Xã Ninh Mỹ",
    code: 14539,
    division_type: "xã",
    codename: "xa_ninh_my",
    district_code: 374,
  },
  {
    name: "Xã Ninh Hòa",
    code: 14542,
    division_type: "xã",
    codename: "xa_ninh_hoa",
    district_code: 374,
  },
  {
    name: "Xã Ninh Xuân",
    code: 14545,
    division_type: "xã",
    codename: "xa_ninh_xuan",
    district_code: 374,
  },
  {
    name: "Xã Ninh Hải",
    code: 14548,
    division_type: "xã",
    codename: "xa_ninh_hai",
    district_code: 374,
  },
  {
    name: "Xã Ninh Thắng",
    code: 14551,
    division_type: "xã",
    codename: "xa_ninh_thang",
    district_code: 374,
  },
  {
    name: "Xã Ninh Vân",
    code: 14554,
    division_type: "xã",
    codename: "xa_ninh_van",
    district_code: 374,
  },
  {
    name: "Xã Ninh An",
    code: 14557,
    division_type: "xã",
    codename: "xa_ninh_an",
    district_code: 374,
  },
  {
    name: "Thị trấn Yên Ninh",
    code: 14560,
    division_type: "thị trấn",
    codename: "thi_tran_yen_ninh",
    district_code: 375,
  },
  {
    name: "Xã Khánh Tiên",
    code: 14563,
    division_type: "xã",
    codename: "xa_khanh_tien",
    district_code: 375,
  },
  {
    name: "Xã Khánh Phú",
    code: 14566,
    division_type: "xã",
    codename: "xa_khanh_phu",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hòa",
    code: 14569,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 375,
  },
  {
    name: "Xã Khánh Lợi",
    code: 14572,
    division_type: "xã",
    codename: "xa_khanh_loi",
    district_code: 375,
  },
  {
    name: "Xã Khánh An",
    code: 14575,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 375,
  },
  {
    name: "Xã Khánh Cường",
    code: 14578,
    division_type: "xã",
    codename: "xa_khanh_cuong",
    district_code: 375,
  },
  {
    name: "Xã Khánh Cư",
    code: 14581,
    division_type: "xã",
    codename: "xa_khanh_cu",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thiện",
    code: 14584,
    division_type: "xã",
    codename: "xa_khanh_thien",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hải",
    code: 14587,
    division_type: "xã",
    codename: "xa_khanh_hai",
    district_code: 375,
  },
  {
    name: "Xã Khánh Trung",
    code: 14590,
    division_type: "xã",
    codename: "xa_khanh_trung",
    district_code: 375,
  },
  {
    name: "Xã Khánh Mậu",
    code: 14593,
    division_type: "xã",
    codename: "xa_khanh_mau",
    district_code: 375,
  },
  {
    name: "Xã Khánh Vân",
    code: 14596,
    division_type: "xã",
    codename: "xa_khanh_van",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hội",
    code: 14599,
    division_type: "xã",
    codename: "xa_khanh_hoi",
    district_code: 375,
  },
  {
    name: "Xã Khánh Công",
    code: 14602,
    division_type: "xã",
    codename: "xa_khanh_cong",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thành",
    code: 14608,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 375,
  },
  {
    name: "Xã Khánh Nhạc",
    code: 14611,
    division_type: "xã",
    codename: "xa_khanh_nhac",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thủy",
    code: 14614,
    division_type: "xã",
    codename: "xa_khanh_thuy",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hồng",
    code: 14617,
    division_type: "xã",
    codename: "xa_khanh_hong",
    district_code: 375,
  },
  {
    name: "Thị trấn Phát Diệm",
    code: 14620,
    division_type: "thị trấn",
    codename: "thi_tran_phat_diem",
    district_code: 376,
  },
  {
    name: "Thị trấn Bình Minh",
    code: 14623,
    division_type: "thị trấn",
    codename: "thi_tran_binh_minh",
    district_code: 376,
  },
  {
    name: "Xã Hồi Ninh",
    code: 14629,
    division_type: "xã",
    codename: "xa_hoi_ninh",
    district_code: 376,
  },
  {
    name: "Xã Xuân Chính",
    code: 14632,
    division_type: "xã",
    codename: "xa_xuan_chinh",
    district_code: 376,
  },
  {
    name: "Xã Kim Định",
    code: 14635,
    division_type: "xã",
    codename: "xa_kim_dinh",
    district_code: 376,
  },
  {
    name: "Xã Ân Hòa",
    code: 14638,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 376,
  },
  {
    name: "Xã Hùng Tiến",
    code: 14641,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 376,
  },
  {
    name: "Xã Quang Thiện",
    code: 14647,
    division_type: "xã",
    codename: "xa_quang_thien",
    district_code: 376,
  },
  {
    name: "Xã Như Hòa",
    code: 14650,
    division_type: "xã",
    codename: "xa_nhu_hoa",
    district_code: 376,
  },
  {
    name: "Xã Chất Bình",
    code: 14653,
    division_type: "xã",
    codename: "xa_chat_binh",
    district_code: 376,
  },
  {
    name: "Xã Đồng Hướng",
    code: 14656,
    division_type: "xã",
    codename: "xa_dong_huong",
    district_code: 376,
  },
  {
    name: "Xã Kim Chính",
    code: 14659,
    division_type: "xã",
    codename: "xa_kim_chinh",
    district_code: 376,
  },
  {
    name: "Xã Thượng Kiệm",
    code: 14662,
    division_type: "xã",
    codename: "xa_thuong_kiem",
    district_code: 376,
  },
  {
    name: "Xã Lưu Phương",
    code: 14665,
    division_type: "xã",
    codename: "xa_luu_phuong",
    district_code: 376,
  },
  {
    name: "Xã Tân Thành",
    code: 14668,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 376,
  },
  {
    name: "Xã Yên Lộc",
    code: 14671,
    division_type: "xã",
    codename: "xa_yen_loc",
    district_code: 376,
  },
  {
    name: "Xã Lai Thành",
    code: 14674,
    division_type: "xã",
    codename: "xa_lai_thanh",
    district_code: 376,
  },
  {
    name: "Xã Định Hóa",
    code: 14677,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 376,
  },
  {
    name: "Xã Văn Hải",
    code: 14680,
    division_type: "xã",
    codename: "xa_van_hai",
    district_code: 376,
  },
  {
    name: "Xã Kim Tân",
    code: 14683,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 376,
  },
  {
    name: "Xã Kim Mỹ",
    code: 14686,
    division_type: "xã",
    codename: "xa_kim_my",
    district_code: 376,
  },
  {
    name: "Xã Cồn Thoi",
    code: 14689,
    division_type: "xã",
    codename: "xa_con_thoi",
    district_code: 376,
  },
  {
    name: "Xã Kim Hải",
    code: 14692,
    division_type: "xã",
    codename: "xa_kim_hai",
    district_code: 376,
  },
  {
    name: "Xã Kim Trung",
    code: 14695,
    division_type: "xã",
    codename: "xa_kim_trung",
    district_code: 376,
  },
  {
    name: "Xã Kim Đông",
    code: 14698,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 376,
  },
  {
    name: "Thị trấn Yên Thịnh",
    code: 14701,
    division_type: "thị trấn",
    codename: "thi_tran_yen_thinh",
    district_code: 377,
  },
  {
    name: "Xã Khánh Thượng",
    code: 14704,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 377,
  },
  {
    name: "Xã Khánh Dương",
    code: 14707,
    division_type: "xã",
    codename: "xa_khanh_duong",
    district_code: 377,
  },
  {
    name: "Xã Mai Sơn",
    code: 14710,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 377,
  },
  {
    name: "Xã Khánh Thịnh",
    code: 14713,
    division_type: "xã",
    codename: "xa_khanh_thinh",
    district_code: 377,
  },
  {
    name: "Xã Yên Phong",
    code: 14719,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 377,
  },
  {
    name: "Xã Yên Hòa",
    code: 14722,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 377,
  },
  {
    name: "Xã Yên Thắng",
    code: 14725,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 377,
  },
  {
    name: "Xã Yên Từ",
    code: 14728,
    division_type: "xã",
    codename: "xa_yen_tu",
    district_code: 377,
  },
  {
    name: "Xã Yên Hưng",
    code: 14731,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 377,
  },
  {
    name: "Xã Yên Thành",
    code: 14734,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 377,
  },
  {
    name: "Xã Yên Nhân",
    code: 14737,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 377,
  },
  {
    name: "Xã Yên Mỹ",
    code: 14740,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 377,
  },
  {
    name: "Xã Yên Mạc",
    code: 14743,
    division_type: "xã",
    codename: "xa_yen_mac",
    district_code: 377,
  },
  {
    name: "Xã Yên Đồng",
    code: 14746,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 377,
  },
  {
    name: "Xã Yên Thái",
    code: 14749,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 377,
  },
  {
    name: "Xã Yên Lâm",
    code: 14752,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 377,
  },
  {
    name: "Phường Hàm Rồng",
    code: 14755,
    division_type: "phường",
    codename: "phuong_ham_rong",
    district_code: 380,
  },
  {
    name: "Phường Đông Thọ",
    code: 14758,
    division_type: "phường",
    codename: "phuong_dong_tho",
    district_code: 380,
  },
  {
    name: "Phường Nam Ngạn",
    code: 14761,
    division_type: "phường",
    codename: "phuong_nam_ngan",
    district_code: 380,
  },
  {
    name: "Phường Trường Thi",
    code: 14764,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 380,
  },
  {
    name: "Phường Điện Biên",
    code: 14767,
    division_type: "phường",
    codename: "phuong_dien_bien",
    district_code: 380,
  },
  {
    name: "Phường Phú Sơn",
    code: 14770,
    division_type: "phường",
    codename: "phuong_phu_son",
    district_code: 380,
  },
  {
    name: "Phường Lam Sơn",
    code: 14773,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 380,
  },
  {
    name: "Phường Ba Đình",
    code: 14776,
    division_type: "phường",
    codename: "phuong_ba_dinh",
    district_code: 380,
  },
  {
    name: "Phường Ngọc Trạo",
    code: 14779,
    division_type: "phường",
    codename: "phuong_ngoc_trao",
    district_code: 380,
  },
  {
    name: "Phường Đông Vệ",
    code: 14782,
    division_type: "phường",
    codename: "phuong_dong_ve",
    district_code: 380,
  },
  {
    name: "Phường Đông Sơn",
    code: 14785,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 380,
  },
  {
    name: "Phường Tân Sơn",
    code: 14788,
    division_type: "phường",
    codename: "phuong_tan_son",
    district_code: 380,
  },
  {
    name: "Phường Đông Cương",
    code: 14791,
    division_type: "phường",
    codename: "phuong_dong_cuong",
    district_code: 380,
  },
  {
    name: "Phường Đông Hương",
    code: 14794,
    division_type: "phường",
    codename: "phuong_dong_huong",
    district_code: 380,
  },
  {
    name: "Phường Đông Hải",
    code: 14797,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 380,
  },
  {
    name: "Phường Quảng Hưng",
    code: 14800,
    division_type: "phường",
    codename: "phuong_quang_hung",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thắng",
    code: 14803,
    division_type: "phường",
    codename: "phuong_quang_thang",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thành",
    code: 14806,
    division_type: "phường",
    codename: "phuong_quang_thanh",
    district_code: 380,
  },
  {
    name: "Xã Thiệu Vân",
    code: 15850,
    division_type: "xã",
    codename: "xa_thieu_van",
    district_code: 380,
  },
  {
    name: "Phường Thiệu Khánh",
    code: 15856,
    division_type: "phường",
    codename: "phuong_thieu_khanh",
    district_code: 380,
  },
  {
    name: "Phường Thiệu Dương",
    code: 15859,
    division_type: "phường",
    codename: "phuong_thieu_duong",
    district_code: 380,
  },
  {
    name: "Phường Tào Xuyên",
    code: 15913,
    division_type: "phường",
    codename: "phuong_tao_xuyen",
    district_code: 380,
  },
  {
    name: "Phường Long Anh",
    code: 15922,
    division_type: "phường",
    codename: "phuong_long_anh",
    district_code: 380,
  },
  {
    name: "Xã Hoằng Quang",
    code: 15925,
    division_type: "xã",
    codename: "xa_hoang_quang",
    district_code: 380,
  },
  {
    name: "Xã Hoằng Đại",
    code: 15970,
    division_type: "xã",
    codename: "xa_hoang_dai",
    district_code: 380,
  },
  {
    name: "Phường Đông Lĩnh",
    code: 16396,
    division_type: "phường",
    codename: "phuong_dong_linh",
    district_code: 380,
  },
  {
    name: "Xã Đông Vinh",
    code: 16429,
    division_type: "xã",
    codename: "xa_dong_vinh",
    district_code: 380,
  },
  {
    name: "Phường Đông Tân",
    code: 16432,
    division_type: "phường",
    codename: "phuong_dong_tan",
    district_code: 380,
  },
  {
    name: "Phường An Hưng",
    code: 16435,
    division_type: "phường",
    codename: "phuong_an_hung",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thịnh",
    code: 16441,
    division_type: "phường",
    codename: "phuong_quang_thinh",
    district_code: 380,
  },
  {
    name: "Phường Quảng Đông",
    code: 16459,
    division_type: "phường",
    codename: "phuong_quang_dong",
    district_code: 380,
  },
  {
    name: "Phường Quảng Cát",
    code: 16507,
    division_type: "phường",
    codename: "phuong_quang_cat",
    district_code: 380,
  },
  {
    name: "Phường Quảng Phú",
    code: 16522,
    division_type: "phường",
    codename: "phuong_quang_phu",
    district_code: 380,
  },
  {
    name: "Phường Quảng Tâm",
    code: 16525,
    division_type: "phường",
    codename: "phuong_quang_tam",
    district_code: 380,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14809,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 381,
  },
  {
    name: "Phường Ba Đình",
    code: 14812,
    division_type: "phường",
    codename: "phuong_ba_dinh",
    district_code: 381,
  },
  {
    name: "Phường Lam Sơn",
    code: 14815,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 381,
  },
  {
    name: "Phường Ngọc Trạo",
    code: 14818,
    division_type: "phường",
    codename: "phuong_ngoc_trao",
    district_code: 381,
  },
  {
    name: "Phường Đông Sơn",
    code: 14821,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 381,
  },
  {
    name: "Phường Phú Sơn",
    code: 14823,
    division_type: "phường",
    codename: "phuong_phu_son",
    district_code: 381,
  },
  {
    name: "Xã Quang Trung",
    code: 14824,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 381,
  },
  {
    name: "Phường Trung Sơn",
    code: 14830,
    division_type: "phường",
    codename: "phuong_trung_son",
    district_code: 382,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14833,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 382,
  },
  {
    name: "Phường Trường Sơn",
    code: 14836,
    division_type: "phường",
    codename: "phuong_truong_son",
    district_code: 382,
  },
  {
    name: "Phường Quảng Cư",
    code: 14839,
    division_type: "phường",
    codename: "phuong_quang_cu",
    district_code: 382,
  },
  {
    name: "Phường Quảng Tiến",
    code: 14842,
    division_type: "phường",
    codename: "phuong_quang_tien",
    district_code: 382,
  },
  {
    name: "Xã Quảng Minh",
    code: 16513,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 382,
  },
  {
    name: "Xã Quảng Hùng",
    code: 16516,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 382,
  },
  {
    name: "Phường Quảng Thọ",
    code: 16528,
    division_type: "phường",
    codename: "phuong_quang_tho",
    district_code: 382,
  },
  {
    name: "Phường Quảng Châu",
    code: 16531,
    division_type: "phường",
    codename: "phuong_quang_chau",
    district_code: 382,
  },
  {
    name: "Phường Quảng Vinh",
    code: 16534,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 382,
  },
  {
    name: "Xã Quảng Đại",
    code: 16537,
    division_type: "xã",
    codename: "xa_quang_dai",
    district_code: 382,
  },
  {
    name: "Thị trấn Mường Lát",
    code: 14845,
    division_type: "thị trấn",
    codename: "thi_tran_muong_lat",
    district_code: 384,
  },
  {
    name: "Xã Tam Chung",
    code: 14848,
    division_type: "xã",
    codename: "xa_tam_chung",
    district_code: 384,
  },
  {
    name: "Xã Mường Lý",
    code: 14854,
    division_type: "xã",
    codename: "xa_muong_ly",
    district_code: 384,
  },
  {
    name: "Xã Trung Lý",
    code: 14857,
    division_type: "xã",
    codename: "xa_trung_ly",
    district_code: 384,
  },
  {
    name: "Xã Quang Chiểu",
    code: 14860,
    division_type: "xã",
    codename: "xa_quang_chieu",
    district_code: 384,
  },
  {
    name: "Xã Pù Nhi",
    code: 14863,
    division_type: "xã",
    codename: "xa_pu_nhi",
    district_code: 384,
  },
  {
    name: "Xã Nhi Sơn",
    code: 14864,
    division_type: "xã",
    codename: "xa_nhi_son",
    district_code: 384,
  },
  {
    name: "Xã Mường Chanh",
    code: 14866,
    division_type: "xã",
    codename: "xa_muong_chanh",
    district_code: 384,
  },
  {
    name: "Thị trấn Hồi Xuân",
    code: 14869,
    division_type: "thị trấn",
    codename: "thi_tran_hoi_xuan",
    district_code: 385,
  },
  {
    name: "Xã Thành Sơn",
    code: 14872,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 385,
  },
  {
    name: "Xã Trung Sơn",
    code: 14875,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 385,
  },
  {
    name: "Xã Phú Thanh",
    code: 14878,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 385,
  },
  {
    name: "Xã Trung Thành",
    code: 14881,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 385,
  },
  {
    name: "Xã Phú Lệ",
    code: 14884,
    division_type: "xã",
    codename: "xa_phu_le",
    district_code: 385,
  },
  {
    name: "Xã Phú Sơn",
    code: 14887,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 385,
  },
  {
    name: "Xã Phú Xuân",
    code: 14890,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 385,
  },
  {
    name: "Xã Hiền Chung",
    code: 14896,
    division_type: "xã",
    codename: "xa_hien_chung",
    district_code: 385,
  },
  {
    name: "Xã Hiền Kiệt",
    code: 14899,
    division_type: "xã",
    codename: "xa_hien_kiet",
    district_code: 385,
  },
  {
    name: "Xã Nam Tiến",
    code: 14902,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 385,
  },
  {
    name: "Xã Thiên Phủ",
    code: 14908,
    division_type: "xã",
    codename: "xa_thien_phu",
    district_code: 385,
  },
  {
    name: "Xã Phú Nghiêm",
    code: 14911,
    division_type: "xã",
    codename: "xa_phu_nghiem",
    district_code: 385,
  },
  {
    name: "Xã Nam Xuân",
    code: 14914,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 385,
  },
  {
    name: "Xã Nam Động",
    code: 14917,
    division_type: "xã",
    codename: "xa_nam_dong",
    district_code: 385,
  },
  {
    name: "Thị trấn Cành Nàng",
    code: 14923,
    division_type: "thị trấn",
    codename: "thi_tran_canh_nang",
    district_code: 386,
  },
  {
    name: "Xã Điền Thượng",
    code: 14926,
    division_type: "xã",
    codename: "xa_dien_thuong",
    district_code: 386,
  },
  {
    name: "Xã Điền Hạ",
    code: 14929,
    division_type: "xã",
    codename: "xa_dien_ha",
    district_code: 386,
  },
  {
    name: "Xã Điền Quang",
    code: 14932,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 386,
  },
  {
    name: "Xã Điền Trung",
    code: 14935,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 386,
  },
  {
    name: "Xã Thành Sơn",
    code: 14938,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 386,
  },
  {
    name: "Xã Lương Ngoại",
    code: 14941,
    division_type: "xã",
    codename: "xa_luong_ngoai",
    district_code: 386,
  },
  {
    name: "Xã Ái Thượng",
    code: 14944,
    division_type: "xã",
    codename: "xa_ai_thuong",
    district_code: 386,
  },
  {
    name: "Xã Lương Nội",
    code: 14947,
    division_type: "xã",
    codename: "xa_luong_noi",
    district_code: 386,
  },
  {
    name: "Xã Điền Lư",
    code: 14950,
    division_type: "xã",
    codename: "xa_dien_lu",
    district_code: 386,
  },
  {
    name: "Xã Lương Trung",
    code: 14953,
    division_type: "xã",
    codename: "xa_luong_trung",
    district_code: 386,
  },
  {
    name: "Xã Lũng Niêm",
    code: 14956,
    division_type: "xã",
    codename: "xa_lung_niem",
    district_code: 386,
  },
  {
    name: "Xã Lũng Cao",
    code: 14959,
    division_type: "xã",
    codename: "xa_lung_cao",
    district_code: 386,
  },
  {
    name: "Xã Hạ Trung",
    code: 14962,
    division_type: "xã",
    codename: "xa_ha_trung",
    district_code: 386,
  },
  {
    name: "Xã Cổ Lũng",
    code: 14965,
    division_type: "xã",
    codename: "xa_co_lung",
    district_code: 386,
  },
  {
    name: "Xã Thành Lâm",
    code: 14968,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 386,
  },
  {
    name: "Xã Ban Công",
    code: 14971,
    division_type: "xã",
    codename: "xa_ban_cong",
    district_code: 386,
  },
  {
    name: "Xã Kỳ Tân",
    code: 14974,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 386,
  },
  {
    name: "Xã Văn Nho",
    code: 14977,
    division_type: "xã",
    codename: "xa_van_nho",
    district_code: 386,
  },
  {
    name: "Xã Thiết Ống",
    code: 14980,
    division_type: "xã",
    codename: "xa_thiet_ong",
    district_code: 386,
  },
  {
    name: "Xã Thiết Kế",
    code: 14986,
    division_type: "xã",
    codename: "xa_thiet_ke",
    district_code: 386,
  },
  {
    name: "Xã Trung Xuân",
    code: 14995,
    division_type: "xã",
    codename: "xa_trung_xuan",
    district_code: 387,
  },
  {
    name: "Xã Trung Thượng",
    code: 14998,
    division_type: "xã",
    codename: "xa_trung_thuong",
    district_code: 387,
  },
  {
    name: "Xã Trung Tiến",
    code: 14999,
    division_type: "xã",
    codename: "xa_trung_tien",
    district_code: 387,
  },
  {
    name: "Xã Trung Hạ",
    code: 15001,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 387,
  },
  {
    name: "Xã Sơn Hà",
    code: 15004,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 387,
  },
  {
    name: "Xã Tam Thanh",
    code: 15007,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 387,
  },
  {
    name: "Xã Sơn Thủy",
    code: 15010,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 387,
  },
  {
    name: "Xã Na Mèo",
    code: 15013,
    division_type: "xã",
    codename: "xa_na_meo",
    district_code: 387,
  },
  {
    name: "Thị trấn Sơn Lư",
    code: 15016,
    division_type: "thị trấn",
    codename: "thi_tran_son_lu",
    district_code: 387,
  },
  {
    name: "Xã Tam Lư",
    code: 15019,
    division_type: "xã",
    codename: "xa_tam_lu",
    district_code: 387,
  },
  {
    name: "Xã Sơn Điện",
    code: 15022,
    division_type: "xã",
    codename: "xa_son_dien",
    district_code: 387,
  },
  {
    name: "Xã Mường Mìn",
    code: 15025,
    division_type: "xã",
    codename: "xa_muong_min",
    district_code: 387,
  },
  {
    name: "Xã Yên Khương",
    code: 15031,
    division_type: "xã",
    codename: "xa_yen_khuong",
    district_code: 388,
  },
  {
    name: "Xã Yên Thắng",
    code: 15034,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 388,
  },
  {
    name: "Xã Trí Nang",
    code: 15037,
    division_type: "xã",
    codename: "xa_tri_nang",
    district_code: 388,
  },
  {
    name: "Xã Giao An",
    code: 15040,
    division_type: "xã",
    codename: "xa_giao_an",
    district_code: 388,
  },
  {
    name: "Xã Giao Thiện",
    code: 15043,
    division_type: "xã",
    codename: "xa_giao_thien",
    district_code: 388,
  },
  {
    name: "Xã Tân Phúc",
    code: 15046,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 388,
  },
  {
    name: "Xã Tam Văn",
    code: 15049,
    division_type: "xã",
    codename: "xa_tam_van",
    district_code: 388,
  },
  {
    name: "Xã Lâm Phú",
    code: 15052,
    division_type: "xã",
    codename: "xa_lam_phu",
    district_code: 388,
  },
  {
    name: "Thị trấn Lang Chánh",
    code: 15055,
    division_type: "thị trấn",
    codename: "thi_tran_lang_chanh",
    district_code: 388,
  },
  {
    name: "Xã Đồng Lương",
    code: 15058,
    division_type: "xã",
    codename: "xa_dong_luong",
    district_code: 388,
  },
  {
    name: "Thị trấn Ngọc Lặc",
    code: 15061,
    division_type: "thị trấn",
    codename: "thi_tran_ngoc_lac",
    district_code: 389,
  },
  {
    name: "Xã Lam Sơn",
    code: 15064,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 389,
  },
  {
    name: "Xã Mỹ Tân",
    code: 15067,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 389,
  },
  {
    name: "Xã Thúy Sơn",
    code: 15070,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 389,
  },
  {
    name: "Xã Thạch Lập",
    code: 15073,
    division_type: "xã",
    codename: "xa_thach_lap",
    district_code: 389,
  },
  {
    name: "Xã Vân Âm",
    code: 15076,
    division_type: "xã",
    codename: "xa_van_am",
    district_code: 389,
  },
  {
    name: "Xã Cao Ngọc",
    code: 15079,
    division_type: "xã",
    codename: "xa_cao_ngoc",
    district_code: 389,
  },
  {
    name: "Xã Quang Trung",
    code: 15085,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 389,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 15088,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Liên",
    code: 15091,
    division_type: "xã",
    codename: "xa_ngoc_lien",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 15094,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 389,
  },
  {
    name: "Xã Lộc Thịnh",
    code: 15097,
    division_type: "xã",
    codename: "xa_loc_thinh",
    district_code: 389,
  },
  {
    name: "Xã Cao Thịnh",
    code: 15100,
    division_type: "xã",
    codename: "xa_cao_thinh",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Trung",
    code: 15103,
    division_type: "xã",
    codename: "xa_ngoc_trung",
    district_code: 389,
  },
  {
    name: "Xã Phùng Giáo",
    code: 15106,
    division_type: "xã",
    codename: "xa_phung_giao",
    district_code: 389,
  },
  {
    name: "Xã Phùng Minh",
    code: 15109,
    division_type: "xã",
    codename: "xa_phung_minh",
    district_code: 389,
  },
  {
    name: "Xã Phúc Thịnh",
    code: 15112,
    division_type: "xã",
    codename: "xa_phuc_thinh",
    district_code: 389,
  },
  {
    name: "Xã Nguyệt Ấn",
    code: 15115,
    division_type: "xã",
    codename: "xa_nguyet_an",
    district_code: 389,
  },
  {
    name: "Xã Kiên Thọ",
    code: 15118,
    division_type: "xã",
    codename: "xa_kien_tho",
    district_code: 389,
  },
  {
    name: "Xã Minh Tiến",
    code: 15121,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 389,
  },
  {
    name: "Xã Minh Sơn",
    code: 15124,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 389,
  },
  {
    name: "Thị trấn Phong Sơn",
    code: 15127,
    division_type: "thị trấn",
    codename: "thi_tran_phong_son",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Thành",
    code: 15133,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Quý",
    code: 15136,
    division_type: "xã",
    codename: "xa_cam_quy",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Lương",
    code: 15139,
    division_type: "xã",
    codename: "xa_cam_luong",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Thạch",
    code: 15142,
    division_type: "xã",
    codename: "xa_cam_thach",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Liên",
    code: 15145,
    division_type: "xã",
    codename: "xa_cam_lien",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Giang",
    code: 15148,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Bình",
    code: 15151,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tú",
    code: 15154,
    division_type: "xã",
    codename: "xa_cam_tu",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Châu",
    code: 15160,
    division_type: "xã",
    codename: "xa_cam_chau",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tâm",
    code: 15163,
    division_type: "xã",
    codename: "xa_cam_tam",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Ngọc",
    code: 15169,
    division_type: "xã",
    codename: "xa_cam_ngoc",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Long",
    code: 15172,
    division_type: "xã",
    codename: "xa_cam_long",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Yên",
    code: 15175,
    division_type: "xã",
    codename: "xa_cam_yen",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tân",
    code: 15178,
    division_type: "xã",
    codename: "xa_cam_tan",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Phú",
    code: 15181,
    division_type: "xã",
    codename: "xa_cam_phu",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Vân",
    code: 15184,
    division_type: "xã",
    codename: "xa_cam_van",
    district_code: 390,
  },
  {
    name: "Thị trấn Kim Tân",
    code: 15187,
    division_type: "thị trấn",
    codename: "thi_tran_kim_tan",
    district_code: 391,
  },
  {
    name: "Thị trấn Vân Du",
    code: 15190,
    division_type: "thị trấn",
    codename: "thi_tran_van_du",
    district_code: 391,
  },
  {
    name: "Xã Thạch Lâm",
    code: 15196,
    division_type: "xã",
    codename: "xa_thach_lam",
    district_code: 391,
  },
  {
    name: "Xã Thạch Quảng",
    code: 15199,
    division_type: "xã",
    codename: "xa_thach_quang",
    district_code: 391,
  },
  {
    name: "Xã Thạch Tượng",
    code: 15202,
    division_type: "xã",
    codename: "xa_thach_tuong",
    district_code: 391,
  },
  {
    name: "Xã Thạch Cẩm",
    code: 15205,
    division_type: "xã",
    codename: "xa_thach_cam",
    district_code: 391,
  },
  {
    name: "Xã Thạch Sơn",
    code: 15208,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 391,
  },
  {
    name: "Xã Thạch Bình",
    code: 15211,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 391,
  },
  {
    name: "Xã Thạch Định",
    code: 15214,
    division_type: "xã",
    codename: "xa_thach_dinh",
    district_code: 391,
  },
  {
    name: "Xã Thạch Đồng",
    code: 15217,
    division_type: "xã",
    codename: "xa_thach_dong",
    district_code: 391,
  },
  {
    name: "Xã Thạch Long",
    code: 15220,
    division_type: "xã",
    codename: "xa_thach_long",
    district_code: 391,
  },
  {
    name: "Xã Thành Mỹ",
    code: 15223,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 391,
  },
  {
    name: "Xã Thành Yên",
    code: 15226,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 391,
  },
  {
    name: "Xã Thành Vinh",
    code: 15229,
    division_type: "xã",
    codename: "xa_thanh_vinh",
    district_code: 391,
  },
  {
    name: "Xã Thành Minh",
    code: 15232,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 391,
  },
  {
    name: "Xã Thành Công",
    code: 15235,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 391,
  },
  {
    name: "Xã Thành Tân",
    code: 15238,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 391,
  },
  {
    name: "Xã Thành Trực",
    code: 15241,
    division_type: "xã",
    codename: "xa_thanh_truc",
    district_code: 391,
  },
  {
    name: "Xã Thành Tâm",
    code: 15247,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 391,
  },
  {
    name: "Xã Thành An",
    code: 15250,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 391,
  },
  {
    name: "Xã Thành Thọ",
    code: 15253,
    division_type: "xã",
    codename: "xa_thanh_tho",
    district_code: 391,
  },
  {
    name: "Xã Thành Tiến",
    code: 15256,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 391,
  },
  {
    name: "Xã Thành Long",
    code: 15259,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 391,
  },
  {
    name: "Xã Thành Hưng",
    code: 15265,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 391,
  },
  {
    name: "Xã Ngọc Trạo",
    code: 15268,
    division_type: "xã",
    codename: "xa_ngoc_trao",
    district_code: 391,
  },
  {
    name: "Thị trấn Hà Trung",
    code: 15271,
    division_type: "thị trấn",
    codename: "thi_tran_ha_trung",
    district_code: 392,
  },
  {
    name: "Xã Hà Long",
    code: 15274,
    division_type: "xã",
    codename: "xa_ha_long",
    district_code: 392,
  },
  {
    name: "Xã Hà Vinh",
    code: 15277,
    division_type: "xã",
    codename: "xa_ha_vinh",
    district_code: 392,
  },
  {
    name: "Xã Hà Bắc",
    code: 15280,
    division_type: "xã",
    codename: "xa_ha_bac",
    district_code: 392,
  },
  {
    name: "Xã Hoạt Giang",
    code: 15283,
    division_type: "xã",
    codename: "xa_hoat_giang",
    district_code: 392,
  },
  {
    name: "Xã Yên Dương",
    code: 15286,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 392,
  },
  {
    name: "Xã Hà Giang",
    code: 15292,
    division_type: "xã",
    codename: "xa_ha_giang",
    district_code: 392,
  },
  {
    name: "Xã Lĩnh Toại",
    code: 15298,
    division_type: "xã",
    codename: "xa_linh_toai",
    district_code: 392,
  },
  {
    name: "Xã Hà Ngọc",
    code: 15304,
    division_type: "xã",
    codename: "xa_ha_ngoc",
    district_code: 392,
  },
  {
    name: "Xã Yến Sơn",
    code: 15307,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 392,
  },
  {
    name: "Xã Hà Sơn",
    code: 15313,
    division_type: "xã",
    codename: "xa_ha_son",
    district_code: 392,
  },
  {
    name: "Xã Hà Lĩnh",
    code: 15316,
    division_type: "xã",
    codename: "xa_ha_linh",
    district_code: 392,
  },
  {
    name: "Xã Hà Đông",
    code: 15319,
    division_type: "xã",
    codename: "xa_ha_dong",
    district_code: 392,
  },
  {
    name: "Xã Hà Tân",
    code: 15322,
    division_type: "xã",
    codename: "xa_ha_tan",
    district_code: 392,
  },
  {
    name: "Xã Hà Tiến",
    code: 15325,
    division_type: "xã",
    codename: "xa_ha_tien",
    district_code: 392,
  },
  {
    name: "Xã Hà Bình",
    code: 15328,
    division_type: "xã",
    codename: "xa_ha_binh",
    district_code: 392,
  },
  {
    name: "Xã Hà Lai",
    code: 15331,
    division_type: "xã",
    codename: "xa_ha_lai",
    district_code: 392,
  },
  {
    name: "Xã Hà Châu",
    code: 15334,
    division_type: "xã",
    codename: "xa_ha_chau",
    district_code: 392,
  },
  {
    name: "Xã Hà Thái",
    code: 15340,
    division_type: "xã",
    codename: "xa_ha_thai",
    district_code: 392,
  },
  {
    name: "Xã Hà Hải",
    code: 15343,
    division_type: "xã",
    codename: "xa_ha_hai",
    district_code: 392,
  },
  {
    name: "Thị trấn Vĩnh Lộc",
    code: 15349,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_loc",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 15352,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 15355,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 15358,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Long",
    code: 15361,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Phúc",
    code: 15364,
    division_type: "xã",
    codename: "xa_vinh_phuc",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 15367,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 15376,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hùng",
    code: 15379,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 393,
  },
  {
    name: "Xã Minh Tân",
    code: 15382,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 393,
  },
  {
    name: "Xã Ninh Khang",
    code: 15385,
    division_type: "xã",
    codename: "xa_ninh_khang",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 15388,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh An",
    code: 15391,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 393,
  },
  {
    name: "Thị trấn Thống Nhất",
    code: 15397,
    division_type: "thị trấn",
    codename: "thi_tran_thong_nhat",
    district_code: 394,
  },
  {
    name: "Xã Yên Lâm",
    code: 15403,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 394,
  },
  {
    name: "Xã Yên Tâm",
    code: 15406,
    division_type: "xã",
    codename: "xa_yen_tam",
    district_code: 394,
  },
  {
    name: "Xã Yên Phú",
    code: 15409,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 394,
  },
  {
    name: "Xã Quí Lộc",
    code: 15412,
    division_type: "xã",
    codename: "xa_qui_loc",
    district_code: 394,
  },
  {
    name: "Xã Yên Thọ",
    code: 15415,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 394,
  },
  {
    name: "Xã Yên Trung",
    code: 15418,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 394,
  },
  {
    name: "Xã Yên Trường",
    code: 15421,
    division_type: "xã",
    codename: "xa_yen_truong",
    district_code: 394,
  },
  {
    name: "Xã Yên Phong",
    code: 15427,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 394,
  },
  {
    name: "Xã Yên Thái",
    code: 15430,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 394,
  },
  {
    name: "Xã Yên Hùng",
    code: 15433,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 394,
  },
  {
    name: "Xã Yên Thịnh",
    code: 15436,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 394,
  },
  {
    name: "Xã Yên Ninh",
    code: 15439,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 394,
  },
  {
    name: "Xã Yên Lạc",
    code: 15442,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 394,
  },
  {
    name: "Xã Định Tăng",
    code: 15445,
    division_type: "xã",
    codename: "xa_dinh_tang",
    district_code: 394,
  },
  {
    name: "Xã Định Hòa",
    code: 15448,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 394,
  },
  {
    name: "Xã Định Thành",
    code: 15451,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 394,
  },
  {
    name: "Xã Định Công",
    code: 15454,
    division_type: "xã",
    codename: "xa_dinh_cong",
    district_code: 394,
  },
  {
    name: "Xã Định Tân",
    code: 15457,
    division_type: "xã",
    codename: "xa_dinh_tan",
    district_code: 394,
  },
  {
    name: "Xã Định Tiến",
    code: 15460,
    division_type: "xã",
    codename: "xa_dinh_tien",
    district_code: 394,
  },
  {
    name: "Xã Định Long",
    code: 15463,
    division_type: "xã",
    codename: "xa_dinh_long",
    district_code: 394,
  },
  {
    name: "Xã Định Liên",
    code: 15466,
    division_type: "xã",
    codename: "xa_dinh_lien",
    district_code: 394,
  },
  {
    name: "Thị trấn Quán Lào",
    code: 15469,
    division_type: "thị trấn",
    codename: "thi_tran_quan_lao",
    district_code: 394,
  },
  {
    name: "Xã Định Hưng",
    code: 15472,
    division_type: "xã",
    codename: "xa_dinh_hung",
    district_code: 394,
  },
  {
    name: "Xã Định Hải",
    code: 15475,
    division_type: "xã",
    codename: "xa_dinh_hai",
    district_code: 394,
  },
  {
    name: "Xã Định Bình",
    code: 15478,
    division_type: "xã",
    codename: "xa_dinh_binh",
    district_code: 394,
  },
  {
    name: "Xã Xuân Hồng",
    code: 15493,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 395,
  },
  {
    name: "Thị trấn Thọ Xuân",
    code: 15499,
    division_type: "thị trấn",
    codename: "thi_tran_tho_xuan",
    district_code: 395,
  },
  {
    name: "Xã Bắc Lương",
    code: 15502,
    division_type: "xã",
    codename: "xa_bac_luong",
    district_code: 395,
  },
  {
    name: "Xã Nam Giang",
    code: 15505,
    division_type: "xã",
    codename: "xa_nam_giang",
    district_code: 395,
  },
  {
    name: "Xã Xuân Phong",
    code: 15508,
    division_type: "xã",
    codename: "xa_xuan_phong",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lộc",
    code: 15511,
    division_type: "xã",
    codename: "xa_tho_loc",
    district_code: 395,
  },
  {
    name: "Xã Xuân Trường",
    code: 15514,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 395,
  },
  {
    name: "Xã Xuân Hòa",
    code: 15517,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 395,
  },
  {
    name: "Xã Thọ Hải",
    code: 15520,
    division_type: "xã",
    codename: "xa_tho_hai",
    district_code: 395,
  },
  {
    name: "Xã Tây Hồ",
    code: 15523,
    division_type: "xã",
    codename: "xa_tay_ho",
    district_code: 395,
  },
  {
    name: "Xã Xuân Giang",
    code: 15526,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 395,
  },
  {
    name: "Xã Xuân Sinh",
    code: 15532,
    division_type: "xã",
    codename: "xa_xuan_sinh",
    district_code: 395,
  },
  {
    name: "Xã Xuân Hưng",
    code: 15535,
    division_type: "xã",
    codename: "xa_xuan_hung",
    district_code: 395,
  },
  {
    name: "Xã Thọ Diên",
    code: 15538,
    division_type: "xã",
    codename: "xa_tho_dien",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lâm",
    code: 15541,
    division_type: "xã",
    codename: "xa_tho_lam",
    district_code: 395,
  },
  {
    name: "Xã Thọ Xương",
    code: 15544,
    division_type: "xã",
    codename: "xa_tho_xuong",
    district_code: 395,
  },
  {
    name: "Xã Xuân Bái",
    code: 15547,
    division_type: "xã",
    codename: "xa_xuan_bai",
    district_code: 395,
  },
  {
    name: "Xã Xuân Phú",
    code: 15550,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 395,
  },
  {
    name: "Thị trấn Sao Vàng",
    code: 15553,
    division_type: "thị trấn",
    codename: "thi_tran_sao_vang",
    district_code: 395,
  },
  {
    name: "Thị trấn Lam Sơn",
    code: 15556,
    division_type: "thị trấn",
    codename: "thi_tran_lam_son",
    district_code: 395,
  },
  {
    name: "Xã Xuân Thiên",
    code: 15559,
    division_type: "xã",
    codename: "xa_xuan_thien",
    district_code: 395,
  },
  {
    name: "Xã Thuận Minh",
    code: 15565,
    division_type: "xã",
    codename: "xa_thuan_minh",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lập",
    code: 15568,
    division_type: "xã",
    codename: "xa_tho_lap",
    district_code: 395,
  },
  {
    name: "Xã Quảng Phú",
    code: 15571,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 395,
  },
  {
    name: "Xã Xuân Tín",
    code: 15574,
    division_type: "xã",
    codename: "xa_xuan_tin",
    district_code: 395,
  },
  {
    name: "Xã Phú Xuân",
    code: 15577,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 395,
  },
  {
    name: "Xã Xuân Lai",
    code: 15583,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 395,
  },
  {
    name: "Xã Xuân Lập",
    code: 15586,
    division_type: "xã",
    codename: "xa_xuan_lap",
    district_code: 395,
  },
  {
    name: "Xã Xuân Minh",
    code: 15592,
    division_type: "xã",
    codename: "xa_xuan_minh",
    district_code: 395,
  },
  {
    name: "Xã Trường Xuân",
    code: 15598,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 395,
  },
  {
    name: "Xã Bát Mọt",
    code: 15607,
    division_type: "xã",
    codename: "xa_bat_mot",
    district_code: 396,
  },
  {
    name: "Xã Yên Nhân",
    code: 15610,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 396,
  },
  {
    name: "Xã Xuân Lẹ",
    code: 15619,
    division_type: "xã",
    codename: "xa_xuan_le",
    district_code: 396,
  },
  {
    name: "Xã Vạn Xuân",
    code: 15622,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 396,
  },
  {
    name: "Xã Lương Sơn",
    code: 15628,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 396,
  },
  {
    name: "Xã Xuân Cao",
    code: 15631,
    division_type: "xã",
    codename: "xa_xuan_cao",
    district_code: 396,
  },
  {
    name: "Xã Luận Thành",
    code: 15634,
    division_type: "xã",
    codename: "xa_luan_thanh",
    district_code: 396,
  },
  {
    name: "Xã Luận Khê",
    code: 15637,
    division_type: "xã",
    codename: "xa_luan_khe",
    district_code: 396,
  },
  {
    name: "Xã Xuân Thắng",
    code: 15640,
    division_type: "xã",
    codename: "xa_xuan_thang",
    district_code: 396,
  },
  {
    name: "Xã Xuân Lộc",
    code: 15643,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 396,
  },
  {
    name: "Thị trấn Thường Xuân",
    code: 15646,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_xuan",
    district_code: 396,
  },
  {
    name: "Xã Xuân Dương",
    code: 15649,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 396,
  },
  {
    name: "Xã Thọ Thanh",
    code: 15652,
    division_type: "xã",
    codename: "xa_tho_thanh",
    district_code: 396,
  },
  {
    name: "Xã Ngọc Phụng",
    code: 15655,
    division_type: "xã",
    codename: "xa_ngoc_phung",
    district_code: 396,
  },
  {
    name: "Xã Xuân Chinh",
    code: 15658,
    division_type: "xã",
    codename: "xa_xuan_chinh",
    district_code: 396,
  },
  {
    name: "Xã Tân Thành",
    code: 15661,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 396,
  },
  {
    name: "Thị trấn Triệu Sơn",
    code: 15664,
    division_type: "thị trấn",
    codename: "thi_tran_trieu_son",
    district_code: 397,
  },
  {
    name: "Xã Thọ Sơn",
    code: 15667,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 397,
  },
  {
    name: "Xã Thọ Bình",
    code: 15670,
    division_type: "xã",
    codename: "xa_tho_binh",
    district_code: 397,
  },
  {
    name: "Xã Thọ Tiến",
    code: 15673,
    division_type: "xã",
    codename: "xa_tho_tien",
    district_code: 397,
  },
  {
    name: "Xã Hợp Lý",
    code: 15676,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 397,
  },
  {
    name: "Xã Hợp Tiến",
    code: 15679,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 397,
  },
  {
    name: "Xã Hợp Thành",
    code: 15682,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 397,
  },
  {
    name: "Xã Triệu Thành",
    code: 15685,
    division_type: "xã",
    codename: "xa_trieu_thanh",
    district_code: 397,
  },
  {
    name: "Xã Hợp Thắng",
    code: 15688,
    division_type: "xã",
    codename: "xa_hop_thang",
    district_code: 397,
  },
  {
    name: "Xã Minh Sơn",
    code: 15691,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 397,
  },
  {
    name: "Xã Dân Lực",
    code: 15700,
    division_type: "xã",
    codename: "xa_dan_luc",
    district_code: 397,
  },
  {
    name: "Xã Dân Lý",
    code: 15703,
    division_type: "xã",
    codename: "xa_dan_ly",
    district_code: 397,
  },
  {
    name: "Xã Dân Quyền",
    code: 15706,
    division_type: "xã",
    codename: "xa_dan_quyen",
    district_code: 397,
  },
  {
    name: "Xã An Nông",
    code: 15709,
    division_type: "xã",
    codename: "xa_an_nong",
    district_code: 397,
  },
  {
    name: "Xã Văn Sơn",
    code: 15712,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 397,
  },
  {
    name: "Xã Thái Hòa",
    code: 15715,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 397,
  },
  {
    name: "Thị trấn Nưa",
    code: 15718,
    division_type: "thị trấn",
    codename: "thi_tran_nua",
    district_code: 397,
  },
  {
    name: "Xã Đồng Lợi",
    code: 15721,
    division_type: "xã",
    codename: "xa_dong_loi",
    district_code: 397,
  },
  {
    name: "Xã Đồng Tiến",
    code: 15724,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 397,
  },
  {
    name: "Xã Đồng Thắng",
    code: 15727,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 397,
  },
  {
    name: "Xã Tiến Nông",
    code: 15730,
    division_type: "xã",
    codename: "xa_tien_nong",
    district_code: 397,
  },
  {
    name: "Xã Khuyến Nông",
    code: 15733,
    division_type: "xã",
    codename: "xa_khuyen_nong",
    district_code: 397,
  },
  {
    name: "Xã Xuân Thịnh",
    code: 15736,
    division_type: "xã",
    codename: "xa_xuan_thinh",
    district_code: 397,
  },
  {
    name: "Xã Xuân Lộc",
    code: 15739,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Dân",
    code: 15742,
    division_type: "xã",
    codename: "xa_tho_dan",
    district_code: 397,
  },
  {
    name: "Xã Xuân Thọ",
    code: 15745,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 397,
  },
  {
    name: "Xã Thọ Tân",
    code: 15748,
    division_type: "xã",
    codename: "xa_tho_tan",
    district_code: 397,
  },
  {
    name: "Xã Thọ Ngọc",
    code: 15751,
    division_type: "xã",
    codename: "xa_tho_ngoc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Cường",
    code: 15754,
    division_type: "xã",
    codename: "xa_tho_cuong",
    district_code: 397,
  },
  {
    name: "Xã Thọ Phú",
    code: 15757,
    division_type: "xã",
    codename: "xa_tho_phu",
    district_code: 397,
  },
  {
    name: "Xã Thọ Vực",
    code: 15760,
    division_type: "xã",
    codename: "xa_tho_vuc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Thế",
    code: 15763,
    division_type: "xã",
    codename: "xa_tho_the",
    district_code: 397,
  },
  {
    name: "Xã Nông Trường",
    code: 15766,
    division_type: "xã",
    codename: "xa_nong_truong",
    district_code: 397,
  },
  {
    name: "Xã Bình Sơn",
    code: 15769,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 397,
  },
  {
    name: "Thị trấn Thiệu Hóa",
    code: 15772,
    division_type: "thị trấn",
    codename: "thi_tran_thieu_hoa",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Ngọc",
    code: 15775,
    division_type: "xã",
    codename: "xa_thieu_ngoc",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Vũ",
    code: 15778,
    division_type: "xã",
    codename: "xa_thieu_vu",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Phúc",
    code: 15781,
    division_type: "xã",
    codename: "xa_thieu_phuc",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Tiến",
    code: 15784,
    division_type: "xã",
    codename: "xa_thieu_tien",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Công",
    code: 15787,
    division_type: "xã",
    codename: "xa_thieu_cong",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Phú",
    code: 15790,
    division_type: "xã",
    codename: "xa_thieu_phu",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Long",
    code: 15793,
    division_type: "xã",
    codename: "xa_thieu_long",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Giang",
    code: 15796,
    division_type: "xã",
    codename: "xa_thieu_giang",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Duy",
    code: 15799,
    division_type: "xã",
    codename: "xa_thieu_duy",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Nguyên",
    code: 15802,
    division_type: "xã",
    codename: "xa_thieu_nguyen",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Hợp",
    code: 15805,
    division_type: "xã",
    codename: "xa_thieu_hop",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Thịnh",
    code: 15808,
    division_type: "xã",
    codename: "xa_thieu_thinh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Quang",
    code: 15811,
    division_type: "xã",
    codename: "xa_thieu_quang",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Thành",
    code: 15814,
    division_type: "xã",
    codename: "xa_thieu_thanh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Toán",
    code: 15817,
    division_type: "xã",
    codename: "xa_thieu_toan",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Chính",
    code: 15820,
    division_type: "xã",
    codename: "xa_thieu_chinh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Hòa",
    code: 15823,
    division_type: "xã",
    codename: "xa_thieu_hoa",
    district_code: 398,
  },
  {
    name: "Xã Minh Tâm",
    code: 15829,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Viên",
    code: 15832,
    division_type: "xã",
    codename: "xa_thieu_vien",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Lý",
    code: 15835,
    division_type: "xã",
    codename: "xa_thieu_ly",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Vận",
    code: 15838,
    division_type: "xã",
    codename: "xa_thieu_van",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Trung",
    code: 15841,
    division_type: "xã",
    codename: "xa_thieu_trung",
    district_code: 398,
  },
  {
    name: "Xã Tân Châu",
    code: 15847,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Giao",
    code: 15853,
    division_type: "xã",
    codename: "xa_thieu_giao",
    district_code: 398,
  },
  {
    name: "Thị trấn Bút Sơn",
    code: 15865,
    division_type: "thị trấn",
    codename: "thi_tran_but_son",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Giang",
    code: 15871,
    division_type: "xã",
    codename: "xa_hoang_giang",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Xuân",
    code: 15877,
    division_type: "xã",
    codename: "xa_hoang_xuan",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phượng",
    code: 15880,
    division_type: "xã",
    codename: "xa_hoang_phuong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phú",
    code: 15883,
    division_type: "xã",
    codename: "xa_hoang_phu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Quỳ",
    code: 15886,
    division_type: "xã",
    codename: "xa_hoang_quy",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Kim",
    code: 15889,
    division_type: "xã",
    codename: "xa_hoang_kim",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trung",
    code: 15892,
    division_type: "xã",
    codename: "xa_hoang_trung",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trinh",
    code: 15895,
    division_type: "xã",
    codename: "xa_hoang_trinh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Sơn",
    code: 15901,
    division_type: "xã",
    codename: "xa_hoang_son",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Cát",
    code: 15907,
    division_type: "xã",
    codename: "xa_hoang_cat",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Xuyên",
    code: 15910,
    division_type: "xã",
    codename: "xa_hoang_xuyen",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Quý",
    code: 15916,
    division_type: "xã",
    codename: "xa_hoang_quy",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hợp",
    code: 15919,
    division_type: "xã",
    codename: "xa_hoang_hop",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đức",
    code: 15928,
    division_type: "xã",
    codename: "xa_hoang_duc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hà",
    code: 15937,
    division_type: "xã",
    codename: "xa_hoang_ha",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đạt",
    code: 15940,
    division_type: "xã",
    codename: "xa_hoang_dat",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đạo",
    code: 15946,
    division_type: "xã",
    codename: "xa_hoang_dao",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thắng",
    code: 15949,
    division_type: "xã",
    codename: "xa_hoang_thang",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đồng",
    code: 15952,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thái",
    code: 15955,
    division_type: "xã",
    codename: "xa_hoang_thai",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thịnh",
    code: 15958,
    division_type: "xã",
    codename: "xa_hoang_thinh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thành",
    code: 15961,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Lộc",
    code: 15964,
    division_type: "xã",
    codename: "xa_hoang_loc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trạch",
    code: 15967,
    division_type: "xã",
    codename: "xa_hoang_trach",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phong",
    code: 15973,
    division_type: "xã",
    codename: "xa_hoang_phong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Lưu",
    code: 15976,
    division_type: "xã",
    codename: "xa_hoang_luu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Châu",
    code: 15979,
    division_type: "xã",
    codename: "xa_hoang_chau",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Tân",
    code: 15982,
    division_type: "xã",
    codename: "xa_hoang_tan",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Yến",
    code: 15985,
    division_type: "xã",
    codename: "xa_hoang_yen",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Tiến",
    code: 15988,
    division_type: "xã",
    codename: "xa_hoang_tien",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hải",
    code: 15991,
    division_type: "xã",
    codename: "xa_hoang_hai",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Ngọc",
    code: 15994,
    division_type: "xã",
    codename: "xa_hoang_ngoc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đông",
    code: 15997,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thanh",
    code: 16000,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phụ",
    code: 16003,
    division_type: "xã",
    codename: "xa_hoang_phu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trường",
    code: 16006,
    division_type: "xã",
    codename: "xa_hoang_truong",
    district_code: 399,
  },
  {
    name: "Thị trấn Hậu Lộc",
    code: 16012,
    division_type: "thị trấn",
    codename: "thi_tran_hau_loc",
    district_code: 400,
  },
  {
    name: "Xã Đồng Lộc",
    code: 16015,
    division_type: "xã",
    codename: "xa_dong_loc",
    district_code: 400,
  },
  {
    name: "Xã Đại Lộc",
    code: 16018,
    division_type: "xã",
    codename: "xa_dai_loc",
    district_code: 400,
  },
  {
    name: "Xã Triệu Lộc",
    code: 16021,
    division_type: "xã",
    codename: "xa_trieu_loc",
    district_code: 400,
  },
  {
    name: "Xã Tiến Lộc",
    code: 16027,
    division_type: "xã",
    codename: "xa_tien_loc",
    district_code: 400,
  },
  {
    name: "Xã Lộc Sơn",
    code: 16030,
    division_type: "xã",
    codename: "xa_loc_son",
    district_code: 400,
  },
  {
    name: "Xã Cầu Lộc",
    code: 16033,
    division_type: "xã",
    codename: "xa_cau_loc",
    district_code: 400,
  },
  {
    name: "Xã Thành Lộc",
    code: 16036,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 400,
  },
  {
    name: "Xã Tuy Lộc",
    code: 16039,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 400,
  },
  {
    name: "Xã Phong Lộc",
    code: 16042,
    division_type: "xã",
    codename: "xa_phong_loc",
    district_code: 400,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 16045,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 400,
  },
  {
    name: "Xã Thuần Lộc",
    code: 16048,
    division_type: "xã",
    codename: "xa_thuan_loc",
    district_code: 400,
  },
  {
    name: "Xã Xuân Lộc",
    code: 16057,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 400,
  },
  {
    name: "Xã Hoa Lộc",
    code: 16063,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 400,
  },
  {
    name: "Xã Liên Lộc",
    code: 16066,
    division_type: "xã",
    codename: "xa_lien_loc",
    district_code: 400,
  },
  {
    name: "Xã Quang Lộc",
    code: 16069,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 400,
  },
  {
    name: "Xã Phú Lộc",
    code: 16072,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 400,
  },
  {
    name: "Xã Hòa Lộc",
    code: 16075,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 400,
  },
  {
    name: "Xã Minh Lộc",
    code: 16078,
    division_type: "xã",
    codename: "xa_minh_loc",
    district_code: 400,
  },
  {
    name: "Xã Hưng Lộc",
    code: 16081,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 400,
  },
  {
    name: "Xã Hải Lộc",
    code: 16084,
    division_type: "xã",
    codename: "xa_hai_loc",
    district_code: 400,
  },
  {
    name: "Xã Đa Lộc",
    code: 16087,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 400,
  },
  {
    name: "Xã Ngư Lộc",
    code: 16090,
    division_type: "xã",
    codename: "xa_ngu_loc",
    district_code: 400,
  },
  {
    name: "Thị trấn Nga Sơn",
    code: 16093,
    division_type: "thị trấn",
    codename: "thi_tran_nga_son",
    district_code: 401,
  },
  {
    name: "Xã Ba Đình",
    code: 16096,
    division_type: "xã",
    codename: "xa_ba_dinh",
    district_code: 401,
  },
  {
    name: "Xã Nga Vịnh",
    code: 16099,
    division_type: "xã",
    codename: "xa_nga_vinh",
    district_code: 401,
  },
  {
    name: "Xã Nga Văn",
    code: 16102,
    division_type: "xã",
    codename: "xa_nga_van",
    district_code: 401,
  },
  {
    name: "Xã Nga Thiện",
    code: 16105,
    division_type: "xã",
    codename: "xa_nga_thien",
    district_code: 401,
  },
  {
    name: "Xã Nga Tiến",
    code: 16108,
    division_type: "xã",
    codename: "xa_nga_tien",
    district_code: 401,
  },
  {
    name: "Xã Nga Phượng",
    code: 16114,
    division_type: "xã",
    codename: "xa_nga_phuong",
    district_code: 401,
  },
  {
    name: "Xã Nga Trung",
    code: 16117,
    division_type: "xã",
    codename: "xa_nga_trung",
    district_code: 401,
  },
  {
    name: "Xã Nga Bạch",
    code: 16120,
    division_type: "xã",
    codename: "xa_nga_bach",
    district_code: 401,
  },
  {
    name: "Xã Nga Thanh",
    code: 16123,
    division_type: "xã",
    codename: "xa_nga_thanh",
    district_code: 401,
  },
  {
    name: "Xã Nga Yên",
    code: 16132,
    division_type: "xã",
    codename: "xa_nga_yen",
    district_code: 401,
  },
  {
    name: "Xã Nga Giáp",
    code: 16135,
    division_type: "xã",
    codename: "xa_nga_giap",
    district_code: 401,
  },
  {
    name: "Xã Nga Hải",
    code: 16138,
    division_type: "xã",
    codename: "xa_nga_hai",
    district_code: 401,
  },
  {
    name: "Xã Nga Thành",
    code: 16141,
    division_type: "xã",
    codename: "xa_nga_thanh",
    district_code: 401,
  },
  {
    name: "Xã Nga An",
    code: 16144,
    division_type: "xã",
    codename: "xa_nga_an",
    district_code: 401,
  },
  {
    name: "Xã Nga Phú",
    code: 16147,
    division_type: "xã",
    codename: "xa_nga_phu",
    district_code: 401,
  },
  {
    name: "Xã Nga Điền",
    code: 16150,
    division_type: "xã",
    codename: "xa_nga_dien",
    district_code: 401,
  },
  {
    name: "Xã Nga Tân",
    code: 16153,
    division_type: "xã",
    codename: "xa_nga_tan",
    district_code: 401,
  },
  {
    name: "Xã Nga Thủy",
    code: 16156,
    division_type: "xã",
    codename: "xa_nga_thuy",
    district_code: 401,
  },
  {
    name: "Xã Nga Liên",
    code: 16159,
    division_type: "xã",
    codename: "xa_nga_lien",
    district_code: 401,
  },
  {
    name: "Xã Nga Thái",
    code: 16162,
    division_type: "xã",
    codename: "xa_nga_thai",
    district_code: 401,
  },
  {
    name: "Xã Nga Thạch",
    code: 16165,
    division_type: "xã",
    codename: "xa_nga_thach",
    district_code: 401,
  },
  {
    name: "Xã Nga Thắng",
    code: 16168,
    division_type: "xã",
    codename: "xa_nga_thang",
    district_code: 401,
  },
  {
    name: "Xã Nga Trường",
    code: 16171,
    division_type: "xã",
    codename: "xa_nga_truong",
    district_code: 401,
  },
  {
    name: "Thị trấn Yên Cát",
    code: 16174,
    division_type: "thị trấn",
    codename: "thi_tran_yen_cat",
    district_code: 402,
  },
  {
    name: "Xã Bãi Trành",
    code: 16177,
    division_type: "xã",
    codename: "xa_bai_tranh",
    district_code: 402,
  },
  {
    name: "Xã Xuân Hòa",
    code: 16180,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 402,
  },
  {
    name: "Xã Xuân Bình",
    code: 16183,
    division_type: "xã",
    codename: "xa_xuan_binh",
    district_code: 402,
  },
  {
    name: "Xã Hóa Quỳ",
    code: 16186,
    division_type: "xã",
    codename: "xa_hoa_quy",
    district_code: 402,
  },
  {
    name: "Xã Cát Vân",
    code: 16195,
    division_type: "xã",
    codename: "xa_cat_van",
    district_code: 402,
  },
  {
    name: "Xã Cát Tân",
    code: 16198,
    division_type: "xã",
    codename: "xa_cat_tan",
    district_code: 402,
  },
  {
    name: "Xã Tân Bình",
    code: 16201,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 402,
  },
  {
    name: "Xã Bình Lương",
    code: 16204,
    division_type: "xã",
    codename: "xa_binh_luong",
    district_code: 402,
  },
  {
    name: "Xã Thanh Quân",
    code: 16207,
    division_type: "xã",
    codename: "xa_thanh_quan",
    district_code: 402,
  },
  {
    name: "Xã Thanh Xuân",
    code: 16210,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 402,
  },
  {
    name: "Xã Thanh Hòa",
    code: 16213,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 402,
  },
  {
    name: "Xã Thanh Phong",
    code: 16216,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 402,
  },
  {
    name: "Xã Thanh Lâm",
    code: 16219,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 402,
  },
  {
    name: "Xã Thanh Sơn",
    code: 16222,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 402,
  },
  {
    name: "Xã Thượng Ninh",
    code: 16225,
    division_type: "xã",
    codename: "xa_thuong_ninh",
    district_code: 402,
  },
  {
    name: "Thị trấn Bến Sung",
    code: 16228,
    division_type: "thị trấn",
    codename: "thi_tran_ben_sung",
    district_code: 403,
  },
  {
    name: "Xã Cán Khê",
    code: 16231,
    division_type: "xã",
    codename: "xa_can_khe",
    district_code: 403,
  },
  {
    name: "Xã Xuân Du",
    code: 16234,
    division_type: "xã",
    codename: "xa_xuan_du",
    district_code: 403,
  },
  {
    name: "Xã Phượng Nghi",
    code: 16240,
    division_type: "xã",
    codename: "xa_phuong_nghi",
    district_code: 403,
  },
  {
    name: "Xã Mậu Lâm",
    code: 16243,
    division_type: "xã",
    codename: "xa_mau_lam",
    district_code: 403,
  },
  {
    name: "Xã Xuân Khang",
    code: 16246,
    division_type: "xã",
    codename: "xa_xuan_khang",
    district_code: 403,
  },
  {
    name: "Xã Phú Nhuận",
    code: 16249,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 403,
  },
  {
    name: "Xã Hải Long",
    code: 16252,
    division_type: "xã",
    codename: "xa_hai_long",
    district_code: 403,
  },
  {
    name: "Xã Xuân Thái",
    code: 16258,
    division_type: "xã",
    codename: "xa_xuan_thai",
    district_code: 403,
  },
  {
    name: "Xã Xuân Phúc",
    code: 16261,
    division_type: "xã",
    codename: "xa_xuan_phuc",
    district_code: 403,
  },
  {
    name: "Xã Yên Thọ",
    code: 16264,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 403,
  },
  {
    name: "Xã Yên Lạc",
    code: 16267,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 403,
  },
  {
    name: "Xã Thanh Tân",
    code: 16273,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 403,
  },
  {
    name: "Xã Thanh Kỳ",
    code: 16276,
    division_type: "xã",
    codename: "xa_thanh_ky",
    district_code: 403,
  },
  {
    name: "Thị trấn Nông Cống",
    code: 16279,
    division_type: "thị trấn",
    codename: "thi_tran_nong_cong",
    district_code: 404,
  },
  {
    name: "Xã Tân Phúc",
    code: 16282,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 404,
  },
  {
    name: "Xã Tân Thọ",
    code: 16285,
    division_type: "xã",
    codename: "xa_tan_tho",
    district_code: 404,
  },
  {
    name: "Xã Hoàng Sơn",
    code: 16288,
    division_type: "xã",
    codename: "xa_hoang_son",
    district_code: 404,
  },
  {
    name: "Xã Tân Khang",
    code: 16291,
    division_type: "xã",
    codename: "xa_tan_khang",
    district_code: 404,
  },
  {
    name: "Xã Hoàng Giang",
    code: 16294,
    division_type: "xã",
    codename: "xa_hoang_giang",
    district_code: 404,
  },
  {
    name: "Xã Trung Chính",
    code: 16297,
    division_type: "xã",
    codename: "xa_trung_chinh",
    district_code: 404,
  },
  {
    name: "Xã Trung Thành",
    code: 16303,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 404,
  },
  {
    name: "Xã Tế Thắng",
    code: 16309,
    division_type: "xã",
    codename: "xa_te_thang",
    district_code: 404,
  },
  {
    name: "Xã Tế Lợi",
    code: 16315,
    division_type: "xã",
    codename: "xa_te_loi",
    district_code: 404,
  },
  {
    name: "Xã Tế Nông",
    code: 16318,
    division_type: "xã",
    codename: "xa_te_nong",
    district_code: 404,
  },
  {
    name: "Xã Minh Nghĩa",
    code: 16321,
    division_type: "xã",
    codename: "xa_minh_nghia",
    district_code: 404,
  },
  {
    name: "Xã Minh Khôi",
    code: 16324,
    division_type: "xã",
    codename: "xa_minh_khoi",
    district_code: 404,
  },
  {
    name: "Xã Vạn Hòa",
    code: 16327,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 404,
  },
  {
    name: "Xã Trường Trung",
    code: 16330,
    division_type: "xã",
    codename: "xa_truong_trung",
    district_code: 404,
  },
  {
    name: "Xã Vạn Thắng",
    code: 16333,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 404,
  },
  {
    name: "Xã Trường Giang",
    code: 16336,
    division_type: "xã",
    codename: "xa_truong_giang",
    district_code: 404,
  },
  {
    name: "Xã Vạn Thiện",
    code: 16339,
    division_type: "xã",
    codename: "xa_van_thien",
    district_code: 404,
  },
  {
    name: "Xã Thăng Long",
    code: 16342,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 404,
  },
  {
    name: "Xã Trường Minh",
    code: 16345,
    division_type: "xã",
    codename: "xa_truong_minh",
    district_code: 404,
  },
  {
    name: "Xã Trường Sơn",
    code: 16348,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 404,
  },
  {
    name: "Xã Thăng Bình",
    code: 16351,
    division_type: "xã",
    codename: "xa_thang_binh",
    district_code: 404,
  },
  {
    name: "Xã Công Liêm",
    code: 16354,
    division_type: "xã",
    codename: "xa_cong_liem",
    district_code: 404,
  },
  {
    name: "Xã Tượng Văn",
    code: 16357,
    division_type: "xã",
    codename: "xa_tuong_van",
    district_code: 404,
  },
  {
    name: "Xã Thăng Thọ",
    code: 16360,
    division_type: "xã",
    codename: "xa_thang_tho",
    district_code: 404,
  },
  {
    name: "Xã Tượng Lĩnh",
    code: 16363,
    division_type: "xã",
    codename: "xa_tuong_linh",
    district_code: 404,
  },
  {
    name: "Xã Tượng Sơn",
    code: 16366,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 404,
  },
  {
    name: "Xã Công Chính",
    code: 16369,
    division_type: "xã",
    codename: "xa_cong_chinh",
    district_code: 404,
  },
  {
    name: "Xã Yên Mỹ",
    code: 16375,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 404,
  },
  {
    name: "Thị trấn Rừng Thông",
    code: 16378,
    division_type: "thị trấn",
    codename: "thi_tran_rung_thong",
    district_code: 405,
  },
  {
    name: "Xã Đông Hoàng",
    code: 16381,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 405,
  },
  {
    name: "Xã Đông Ninh",
    code: 16384,
    division_type: "xã",
    codename: "xa_dong_ninh",
    district_code: 405,
  },
  {
    name: "Xã Đông Hòa",
    code: 16390,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 405,
  },
  {
    name: "Xã Đông Yên",
    code: 16393,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 405,
  },
  {
    name: "Xã Đông Minh",
    code: 16399,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 405,
  },
  {
    name: "Xã Đông Thanh",
    code: 16402,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 405,
  },
  {
    name: "Xã Đông Tiến",
    code: 16405,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 405,
  },
  {
    name: "Xã Đông Khê",
    code: 16408,
    division_type: "xã",
    codename: "xa_dong_khe",
    district_code: 405,
  },
  {
    name: "Xã Đông Thịnh",
    code: 16414,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 405,
  },
  {
    name: "Xã Đông Văn",
    code: 16417,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 405,
  },
  {
    name: "Xã Đông Phú",
    code: 16420,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 405,
  },
  {
    name: "Xã Đông Nam",
    code: 16423,
    division_type: "xã",
    codename: "xa_dong_nam",
    district_code: 405,
  },
  {
    name: "Xã Đông Quang",
    code: 16426,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 405,
  },
  {
    name: "Thị trấn Tân Phong",
    code: 16438,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phong",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trạch",
    code: 16447,
    division_type: "xã",
    codename: "xa_quang_trach",
    district_code: 406,
  },
  {
    name: "Xã Quảng Đức",
    code: 16453,
    division_type: "xã",
    codename: "xa_quang_duc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Định",
    code: 16456,
    division_type: "xã",
    codename: "xa_quang_dinh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Nhân",
    code: 16462,
    division_type: "xã",
    codename: "xa_quang_nhan",
    district_code: 406,
  },
  {
    name: "Xã Quảng Ninh",
    code: 16465,
    division_type: "xã",
    codename: "xa_quang_ninh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Bình",
    code: 16468,
    division_type: "xã",
    codename: "xa_quang_binh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hợp",
    code: 16471,
    division_type: "xã",
    codename: "xa_quang_hop",
    district_code: 406,
  },
  {
    name: "Xã Quảng Văn",
    code: 16474,
    division_type: "xã",
    codename: "xa_quang_van",
    district_code: 406,
  },
  {
    name: "Xã Quảng Long",
    code: 16477,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 406,
  },
  {
    name: "Xã Quảng Yên",
    code: 16480,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hòa",
    code: 16483,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 406,
  },
  {
    name: "Xã Quảng Khê",
    code: 16489,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trung",
    code: 16492,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 406,
  },
  {
    name: "Xã Quảng Chính",
    code: 16495,
    division_type: "xã",
    codename: "xa_quang_chinh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Ngọc",
    code: 16498,
    division_type: "xã",
    codename: "xa_quang_ngoc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trường",
    code: 16501,
    division_type: "xã",
    codename: "xa_quang_truong",
    district_code: 406,
  },
  {
    name: "Xã Quảng Phúc",
    code: 16510,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Giao",
    code: 16519,
    division_type: "xã",
    codename: "xa_quang_giao",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hải",
    code: 16540,
    division_type: "xã",
    codename: "xa_quang_hai",
    district_code: 406,
  },
  {
    name: "Xã Quảng Lưu",
    code: 16543,
    division_type: "xã",
    codename: "xa_quang_luu",
    district_code: 406,
  },
  {
    name: "Xã Quảng Lộc",
    code: 16546,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 406,
  },
  {
    name: "Xã Tiên Trang",
    code: 16549,
    division_type: "xã",
    codename: "xa_tien_trang",
    district_code: 406,
  },
  {
    name: "Xã Quảng Nham",
    code: 16552,
    division_type: "xã",
    codename: "xa_quang_nham",
    district_code: 406,
  },
  {
    name: "Xã Quảng Thạch",
    code: 16555,
    division_type: "xã",
    codename: "xa_quang_thach",
    district_code: 406,
  },
  {
    name: "Xã Quảng Thái",
    code: 16558,
    division_type: "xã",
    codename: "xa_quang_thai",
    district_code: 406,
  },
  {
    name: "Phường Hải Hòa",
    code: 16561,
    division_type: "phường",
    codename: "phuong_hai_hoa",
    district_code: 407,
  },
  {
    name: "Phường Hải Châu",
    code: 16564,
    division_type: "phường",
    codename: "phuong_hai_chau",
    district_code: 407,
  },
  {
    name: "Xã Thanh Thủy",
    code: 16567,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 407,
  },
  {
    name: "Xã Thanh Sơn",
    code: 16570,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 407,
  },
  {
    name: "Phường Hải Ninh",
    code: 16576,
    division_type: "phường",
    codename: "phuong_hai_ninh",
    district_code: 407,
  },
  {
    name: "Xã Anh Sơn",
    code: 16579,
    division_type: "xã",
    codename: "xa_anh_son",
    district_code: 407,
  },
  {
    name: "Xã Ngọc Lĩnh",
    code: 16582,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 407,
  },
  {
    name: "Phường Hải An",
    code: 16585,
    division_type: "phường",
    codename: "phuong_hai_an",
    district_code: 407,
  },
  {
    name: "Xã Các Sơn",
    code: 16591,
    division_type: "xã",
    codename: "xa_cac_son",
    district_code: 407,
  },
  {
    name: "Phường Tân Dân",
    code: 16594,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 407,
  },
  {
    name: "Phường Hải Lĩnh",
    code: 16597,
    division_type: "phường",
    codename: "phuong_hai_linh",
    district_code: 407,
  },
  {
    name: "Xã Định Hải",
    code: 16600,
    division_type: "xã",
    codename: "xa_dinh_hai",
    district_code: 407,
  },
  {
    name: "Xã Phú Sơn",
    code: 16603,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 407,
  },
  {
    name: "Phường Ninh Hải",
    code: 16606,
    division_type: "phường",
    codename: "phuong_ninh_hai",
    district_code: 407,
  },
  {
    name: "Phường Nguyên Bình",
    code: 16609,
    division_type: "phường",
    codename: "phuong_nguyen_binh",
    district_code: 407,
  },
  {
    name: "Xã Hải Nhân",
    code: 16612,
    division_type: "xã",
    codename: "xa_hai_nhan",
    district_code: 407,
  },
  {
    name: "Phường Bình Minh",
    code: 16618,
    division_type: "phường",
    codename: "phuong_binh_minh",
    district_code: 407,
  },
  {
    name: "Phường Hải Thanh",
    code: 16621,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 407,
  },
  {
    name: "Xã Phú Lâm",
    code: 16624,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 407,
  },
  {
    name: "Phường Xuân Lâm",
    code: 16627,
    division_type: "phường",
    codename: "phuong_xuan_lam",
    district_code: 407,
  },
  {
    name: "Phường Trúc Lâm",
    code: 16630,
    division_type: "phường",
    codename: "phuong_truc_lam",
    district_code: 407,
  },
  {
    name: "Phường Hải Bình",
    code: 16633,
    division_type: "phường",
    codename: "phuong_hai_binh",
    district_code: 407,
  },
  {
    name: "Xã Tân Trường",
    code: 16636,
    division_type: "xã",
    codename: "xa_tan_truong",
    district_code: 407,
  },
  {
    name: "Xã Tùng Lâm",
    code: 16639,
    division_type: "xã",
    codename: "xa_tung_lam",
    district_code: 407,
  },
  {
    name: "Phường Tĩnh Hải",
    code: 16642,
    division_type: "phường",
    codename: "phuong_tinh_hai",
    district_code: 407,
  },
  {
    name: "Phường Mai Lâm",
    code: 16645,
    division_type: "phường",
    codename: "phuong_mai_lam",
    district_code: 407,
  },
  {
    name: "Xã Trường Lâm",
    code: 16648,
    division_type: "xã",
    codename: "xa_truong_lam",
    district_code: 407,
  },
  {
    name: "Xã Hải Yến",
    code: 16651,
    division_type: "xã",
    codename: "xa_hai_yen",
    district_code: 407,
  },
  {
    name: "Phường Hải Thượng",
    code: 16654,
    division_type: "phường",
    codename: "phuong_hai_thuong",
    district_code: 407,
  },
  {
    name: "Xã Nghi Sơn",
    code: 16657,
    division_type: "xã",
    codename: "xa_nghi_son",
    district_code: 407,
  },
  {
    name: "Xã Hải Hà",
    code: 16660,
    division_type: "xã",
    codename: "xa_hai_ha",
    district_code: 407,
  },
  {
    name: "Phường Đông Vĩnh",
    code: 16663,
    division_type: "phường",
    codename: "phuong_dong_vinh",
    district_code: 412,
  },
  {
    name: "Phường Hà Huy Tập",
    code: 16666,
    division_type: "phường",
    codename: "phuong_ha_huy_tap",
    district_code: 412,
  },
  {
    name: "Phường Lê Lợi",
    code: 16669,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 412,
  },
  {
    name: "Phường Quán Bàu",
    code: 16670,
    division_type: "phường",
    codename: "phuong_quan_bau",
    district_code: 412,
  },
  {
    name: "Phường Hưng Bình",
    code: 16672,
    division_type: "phường",
    codename: "phuong_hung_binh",
    district_code: 412,
  },
  {
    name: "Phường Hưng Phúc",
    code: 16673,
    division_type: "phường",
    codename: "phuong_hung_phuc",
    district_code: 412,
  },
  {
    name: "Phường Hưng Dũng",
    code: 16675,
    division_type: "phường",
    codename: "phuong_hung_dung",
    district_code: 412,
  },
  {
    name: "Phường Cửa Nam",
    code: 16678,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 412,
  },
  {
    name: "Phường Quang Trung",
    code: 16681,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 412,
  },
  {
    name: "Phường Đội Cung",
    code: 16684,
    division_type: "phường",
    codename: "phuong_doi_cung",
    district_code: 412,
  },
  {
    name: "Phường Lê Mao",
    code: 16687,
    division_type: "phường",
    codename: "phuong_le_mao",
    district_code: 412,
  },
  {
    name: "Phường Trường Thi",
    code: 16690,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 412,
  },
  {
    name: "Phường Bến Thủy",
    code: 16693,
    division_type: "phường",
    codename: "phuong_ben_thuy",
    district_code: 412,
  },
  {
    name: "Phường Hồng Sơn",
    code: 16696,
    division_type: "phường",
    codename: "phuong_hong_son",
    district_code: 412,
  },
  {
    name: "Phường Trung Đô",
    code: 16699,
    division_type: "phường",
    codename: "phuong_trung_do",
    district_code: 412,
  },
  {
    name: "Xã Nghi Phú",
    code: 16702,
    division_type: "xã",
    codename: "xa_nghi_phu",
    district_code: 412,
  },
  {
    name: "Xã Hưng Đông",
    code: 16705,
    division_type: "xã",
    codename: "xa_hung_dong",
    district_code: 412,
  },
  {
    name: "Xã Hưng Lộc",
    code: 16708,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 412,
  },
  {
    name: "Xã Hưng Hòa",
    code: 16711,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 412,
  },
  {
    name: "Phường Vinh Tân",
    code: 16714,
    division_type: "phường",
    codename: "phuong_vinh_tan",
    district_code: 412,
  },
  {
    name: "Xã Nghi Liên",
    code: 17908,
    division_type: "xã",
    codename: "xa_nghi_lien",
    district_code: 412,
  },
  {
    name: "Xã Nghi Ân",
    code: 17914,
    division_type: "xã",
    codename: "xa_nghi_an",
    district_code: 412,
  },
  {
    name: "Xã Nghi Kim",
    code: 17920,
    division_type: "xã",
    codename: "xa_nghi_kim",
    district_code: 412,
  },
  {
    name: "Xã Nghi Đức",
    code: 17923,
    division_type: "xã",
    codename: "xa_nghi_duc",
    district_code: 412,
  },
  {
    name: "Xã Hưng Chính",
    code: 18013,
    division_type: "xã",
    codename: "xa_hung_chinh",
    district_code: 412,
  },
  {
    name: "Phường Nghi Thuỷ",
    code: 16717,
    division_type: "phường",
    codename: "phuong_nghi_thuy",
    district_code: 413,
  },
  {
    name: "Phường Nghi Tân",
    code: 16720,
    division_type: "phường",
    codename: "phuong_nghi_tan",
    district_code: 413,
  },
  {
    name: "Phường Thu Thuỷ",
    code: 16723,
    division_type: "phường",
    codename: "phuong_thu_thuy",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hòa",
    code: 16726,
    division_type: "phường",
    codename: "phuong_nghi_hoa",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hải",
    code: 16729,
    division_type: "phường",
    codename: "phuong_nghi_hai",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hương",
    code: 16732,
    division_type: "phường",
    codename: "phuong_nghi_huong",
    district_code: 413,
  },
  {
    name: "Phường Nghi Thu",
    code: 16735,
    division_type: "phường",
    codename: "phuong_nghi_thu",
    district_code: 413,
  },
  {
    name: "Phường Hoà Hiếu",
    code: 16939,
    division_type: "phường",
    codename: "phuong_hoa_hieu",
    district_code: 414,
  },
  {
    name: "Phường Quang Phong",
    code: 16993,
    division_type: "phường",
    codename: "phuong_quang_phong",
    district_code: 414,
  },
  {
    name: "Phường Quang Tiến",
    code: 16994,
    division_type: "phường",
    codename: "phuong_quang_tien",
    district_code: 414,
  },
  {
    name: "Phường Long Sơn",
    code: 17003,
    division_type: "phường",
    codename: "phuong_long_son",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Tiến",
    code: 17005,
    division_type: "xã",
    codename: "xa_nghia_tien",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Mỹ",
    code: 17008,
    division_type: "xã",
    codename: "xa_nghia_my",
    district_code: 414,
  },
  {
    name: "Xã Tây Hiếu",
    code: 17011,
    division_type: "xã",
    codename: "xa_tay_hieu",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 17014,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 414,
  },
  {
    name: "Xã Đông Hiếu",
    code: 17017,
    division_type: "xã",
    codename: "xa_dong_hieu",
    district_code: 414,
  },
  {
    name: "Thị trấn Kim Sơn",
    code: 16738,
    division_type: "thị trấn",
    codename: "thi_tran_kim_son",
    district_code: 415,
  },
  {
    name: "Xã Thông Thụ",
    code: 16741,
    division_type: "xã",
    codename: "xa_thong_thu",
    district_code: 415,
  },
  {
    name: "Xã Đồng Văn",
    code: 16744,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 415,
  },
  {
    name: "Xã Hạnh Dịch",
    code: 16747,
    division_type: "xã",
    codename: "xa_hanh_dich",
    district_code: 415,
  },
  {
    name: "Xã Tiền Phong",
    code: 16750,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 415,
  },
  {
    name: "Xã Nậm Giải",
    code: 16753,
    division_type: "xã",
    codename: "xa_nam_giai",
    district_code: 415,
  },
  {
    name: "Xã Tri Lễ",
    code: 16756,
    division_type: "xã",
    codename: "xa_tri_le",
    district_code: 415,
  },
  {
    name: "Xã Châu Kim",
    code: 16759,
    division_type: "xã",
    codename: "xa_chau_kim",
    district_code: 415,
  },
  {
    name: "Xã Mường Nọc",
    code: 16763,
    division_type: "xã",
    codename: "xa_muong_noc",
    district_code: 415,
  },
  {
    name: "Xã Châu Thôn",
    code: 16765,
    division_type: "xã",
    codename: "xa_chau_thon",
    district_code: 415,
  },
  {
    name: "Xã Nậm Nhoóng",
    code: 16768,
    division_type: "xã",
    codename: "xa_nam_nhoong",
    district_code: 415,
  },
  {
    name: "Xã Quang Phong",
    code: 16771,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 415,
  },
  {
    name: "Xã Căm Muộn",
    code: 16774,
    division_type: "xã",
    codename: "xa_cam_muon",
    district_code: 415,
  },
  {
    name: "Thị trấn Tân Lạc",
    code: 16777,
    division_type: "thị trấn",
    codename: "thi_tran_tan_lac",
    district_code: 416,
  },
  {
    name: "Xã Châu Bính",
    code: 16780,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 416,
  },
  {
    name: "Xã Châu Thuận",
    code: 16783,
    division_type: "xã",
    codename: "xa_chau_thuan",
    district_code: 416,
  },
  {
    name: "Xã Châu Hội",
    code: 16786,
    division_type: "xã",
    codename: "xa_chau_hoi",
    district_code: 416,
  },
  {
    name: "Xã Châu Nga",
    code: 16789,
    division_type: "xã",
    codename: "xa_chau_nga",
    district_code: 416,
  },
  {
    name: "Xã Châu Tiến",
    code: 16792,
    division_type: "xã",
    codename: "xa_chau_tien",
    district_code: 416,
  },
  {
    name: "Xã Châu Hạnh",
    code: 16795,
    division_type: "xã",
    codename: "xa_chau_hanh",
    district_code: 416,
  },
  {
    name: "Xã Châu Thắng",
    code: 16798,
    division_type: "xã",
    codename: "xa_chau_thang",
    district_code: 416,
  },
  {
    name: "Xã Châu Phong",
    code: 16801,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 416,
  },
  {
    name: "Xã Châu Bình",
    code: 16804,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 416,
  },
  {
    name: "Xã Châu Hoàn",
    code: 16807,
    division_type: "xã",
    codename: "xa_chau_hoan",
    district_code: 416,
  },
  {
    name: "Xã Diên Lãm",
    code: 16810,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 416,
  },
  {
    name: "Thị trấn Mường Xén",
    code: 16813,
    division_type: "thị trấn",
    codename: "thi_tran_muong_xen",
    district_code: 417,
  },
  {
    name: "Xã Mỹ Lý",
    code: 16816,
    division_type: "xã",
    codename: "xa_my_ly",
    district_code: 417,
  },
  {
    name: "Xã Bắc Lý",
    code: 16819,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 417,
  },
  {
    name: "Xã Keng Đu",
    code: 16822,
    division_type: "xã",
    codename: "xa_keng_du",
    district_code: 417,
  },
  {
    name: "Xã Đoọc Mạy",
    code: 16825,
    division_type: "xã",
    codename: "xa_dooc_may",
    district_code: 417,
  },
  {
    name: "Xã Huồi Tụ",
    code: 16828,
    division_type: "xã",
    codename: "xa_huoi_tu",
    district_code: 417,
  },
  {
    name: "Xã Mường Lống",
    code: 16831,
    division_type: "xã",
    codename: "xa_muong_long",
    district_code: 417,
  },
  {
    name: "Xã Na Loi",
    code: 16834,
    division_type: "xã",
    codename: "xa_na_loi",
    district_code: 417,
  },
  {
    name: "Xã Nậm Cắn",
    code: 16837,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 417,
  },
  {
    name: "Xã Bảo Nam",
    code: 16840,
    division_type: "xã",
    codename: "xa_bao_nam",
    district_code: 417,
  },
  {
    name: "Xã Phà Đánh",
    code: 16843,
    division_type: "xã",
    codename: "xa_pha_danh",
    district_code: 417,
  },
  {
    name: "Xã Bảo Thắng",
    code: 16846,
    division_type: "xã",
    codename: "xa_bao_thang",
    district_code: 417,
  },
  {
    name: "Xã Hữu Lập",
    code: 16849,
    division_type: "xã",
    codename: "xa_huu_lap",
    district_code: 417,
  },
  {
    name: "Xã Tà Cạ",
    code: 16852,
    division_type: "xã",
    codename: "xa_ta_ca",
    district_code: 417,
  },
  {
    name: "Xã Chiêu Lưu",
    code: 16855,
    division_type: "xã",
    codename: "xa_chieu_luu",
    district_code: 417,
  },
  {
    name: "Xã Mường Típ",
    code: 16858,
    division_type: "xã",
    codename: "xa_muong_tip",
    district_code: 417,
  },
  {
    name: "Xã Hữu Kiệm",
    code: 16861,
    division_type: "xã",
    codename: "xa_huu_kiem",
    district_code: 417,
  },
  {
    name: "Xã Tây Sơn",
    code: 16864,
    division_type: "xã",
    codename: "xa_tay_son",
    district_code: 417,
  },
  {
    name: "Xã Mường Ải",
    code: 16867,
    division_type: "xã",
    codename: "xa_muong_ai",
    district_code: 417,
  },
  {
    name: "Xã Na Ngoi",
    code: 16870,
    division_type: "xã",
    codename: "xa_na_ngoi",
    district_code: 417,
  },
  {
    name: "Xã Nậm Càn",
    code: 16873,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 417,
  },
  {
    name: "Thị trấn Thạch Giám",
    code: 16876,
    division_type: "thị trấn",
    codename: "thi_tran_thach_giam",
    district_code: 418,
  },
  {
    name: "Xã Mai Sơn",
    code: 16879,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 418,
  },
  {
    name: "Xã Nhôn Mai",
    code: 16882,
    division_type: "xã",
    codename: "xa_nhon_mai",
    district_code: 418,
  },
  {
    name: "Xã Hữu Khuông",
    code: 16885,
    division_type: "xã",
    codename: "xa_huu_khuong",
    district_code: 418,
  },
  {
    name: "Xã Yên Tĩnh",
    code: 16900,
    division_type: "xã",
    codename: "xa_yen_tinh",
    district_code: 418,
  },
  {
    name: "Xã Nga My",
    code: 16903,
    division_type: "xã",
    codename: "xa_nga_my",
    district_code: 418,
  },
  {
    name: "Xã Xiêng My",
    code: 16904,
    division_type: "xã",
    codename: "xa_xieng_my",
    district_code: 418,
  },
  {
    name: "Xã Lưỡng Minh",
    code: 16906,
    division_type: "xã",
    codename: "xa_luong_minh",
    district_code: 418,
  },
  {
    name: "Xã Yên Hòa",
    code: 16909,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 418,
  },
  {
    name: "Xã Yên Na",
    code: 16912,
    division_type: "xã",
    codename: "xa_yen_na",
    district_code: 418,
  },
  {
    name: "Xã Lưu Kiền",
    code: 16915,
    division_type: "xã",
    codename: "xa_luu_kien",
    district_code: 418,
  },
  {
    name: "Xã Xá Lượng",
    code: 16921,
    division_type: "xã",
    codename: "xa_xa_luong",
    district_code: 418,
  },
  {
    name: "Xã Tam Thái",
    code: 16924,
    division_type: "xã",
    codename: "xa_tam_thai",
    district_code: 418,
  },
  {
    name: "Xã Tam Đình",
    code: 16927,
    division_type: "xã",
    codename: "xa_tam_dinh",
    district_code: 418,
  },
  {
    name: "Xã Yên Thắng",
    code: 16930,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 418,
  },
  {
    name: "Xã Tam Quang",
    code: 16933,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 418,
  },
  {
    name: "Xã Tam Hợp",
    code: 16936,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 418,
  },
  {
    name: "Thị trấn Nghĩa Đàn",
    code: 16941,
    division_type: "thị trấn",
    codename: "thi_tran_nghia_dan",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Mai",
    code: 16942,
    division_type: "xã",
    codename: "xa_nghia_mai",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Yên",
    code: 16945,
    division_type: "xã",
    codename: "xa_nghia_yen",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lạc",
    code: 16948,
    division_type: "xã",
    codename: "xa_nghia_lac",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 16951,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 16954,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 16957,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 16960,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thọ",
    code: 16963,
    division_type: "xã",
    codename: "xa_nghia_tho",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Minh",
    code: 16966,
    division_type: "xã",
    codename: "xa_nghia_minh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 16969,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 16972,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hồng",
    code: 16975,
    division_type: "xã",
    codename: "xa_nghia_hong",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thịnh",
    code: 16978,
    division_type: "xã",
    codename: "xa_nghia_thinh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 16981,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hội",
    code: 16984,
    division_type: "xã",
    codename: "xa_nghia_hoi",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 16987,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hiếu",
    code: 16996,
    division_type: "xã",
    codename: "xa_nghia_hieu",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Đức",
    code: 17020,
    division_type: "xã",
    codename: "xa_nghia_duc",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa An",
    code: 17023,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Long",
    code: 17026,
    division_type: "xã",
    codename: "xa_nghia_long",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lộc",
    code: 17029,
    division_type: "xã",
    codename: "xa_nghia_loc",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Khánh",
    code: 17032,
    division_type: "xã",
    codename: "xa_nghia_khanh",
    district_code: 419,
  },
  {
    name: "Thị trấn Quỳ Hợp",
    code: 17035,
    division_type: "thị trấn",
    codename: "thi_tran_quy_hop",
    district_code: 420,
  },
  {
    name: "Xã Yên Hợp",
    code: 17038,
    division_type: "xã",
    codename: "xa_yen_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Tiến",
    code: 17041,
    division_type: "xã",
    codename: "xa_chau_tien",
    district_code: 420,
  },
  {
    name: "Xã Châu Hồng",
    code: 17044,
    division_type: "xã",
    codename: "xa_chau_hong",
    district_code: 420,
  },
  {
    name: "Xã Đồng Hợp",
    code: 17047,
    division_type: "xã",
    codename: "xa_dong_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Thành",
    code: 17050,
    division_type: "xã",
    codename: "xa_chau_thanh",
    district_code: 420,
  },
  {
    name: "Xã Liên Hợp",
    code: 17053,
    division_type: "xã",
    codename: "xa_lien_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Lộc",
    code: 17056,
    division_type: "xã",
    codename: "xa_chau_loc",
    district_code: 420,
  },
  {
    name: "Xã Tam Hợp",
    code: 17059,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Cường",
    code: 17062,
    division_type: "xã",
    codename: "xa_chau_cuong",
    district_code: 420,
  },
  {
    name: "Xã Châu Quang",
    code: 17065,
    division_type: "xã",
    codename: "xa_chau_quang",
    district_code: 420,
  },
  {
    name: "Xã Thọ Hợp",
    code: 17068,
    division_type: "xã",
    codename: "xa_tho_hop",
    district_code: 420,
  },
  {
    name: "Xã Minh Hợp",
    code: 17071,
    division_type: "xã",
    codename: "xa_minh_hop",
    district_code: 420,
  },
  {
    name: "Xã Nghĩa Xuân",
    code: 17074,
    division_type: "xã",
    codename: "xa_nghia_xuan",
    district_code: 420,
  },
  {
    name: "Xã Châu Thái",
    code: 17077,
    division_type: "xã",
    codename: "xa_chau_thai",
    district_code: 420,
  },
  {
    name: "Xã Châu Đình",
    code: 17080,
    division_type: "xã",
    codename: "xa_chau_dinh",
    district_code: 420,
  },
  {
    name: "Xã Văn Lợi",
    code: 17083,
    division_type: "xã",
    codename: "xa_van_loi",
    district_code: 420,
  },
  {
    name: "Xã Nam Sơn",
    code: 17086,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 420,
  },
  {
    name: "Xã Châu Lý",
    code: 17089,
    division_type: "xã",
    codename: "xa_chau_ly",
    district_code: 420,
  },
  {
    name: "Xã Hạ Sơn",
    code: 17092,
    division_type: "xã",
    codename: "xa_ha_son",
    district_code: 420,
  },
  {
    name: "Xã Bắc Sơn",
    code: 17095,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 420,
  },
  {
    name: "Thị trấn Cầu Giát",
    code: 17098,
    division_type: "thị trấn",
    codename: "thi_tran_cau_giat",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thắng",
    code: 17101,
    division_type: "xã",
    codename: "xa_quynh_thang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Tân",
    code: 17119,
    division_type: "xã",
    codename: "xa_quynh_tan",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Châu",
    code: 17122,
    division_type: "xã",
    codename: "xa_quynh_chau",
    district_code: 421,
  },
  {
    name: "Xã Tân Sơn",
    code: 17140,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Văn",
    code: 17143,
    division_type: "xã",
    codename: "xa_quynh_van",
    district_code: 421,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17146,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Tam",
    code: 17149,
    division_type: "xã",
    codename: "xa_quynh_tam",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hoa",
    code: 17152,
    division_type: "xã",
    codename: "xa_quynh_hoa",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thạch",
    code: 17155,
    division_type: "xã",
    codename: "xa_quynh_thach",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Bảng",
    code: 17158,
    division_type: "xã",
    codename: "xa_quynh_bang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Mỹ",
    code: 17161,
    division_type: "xã",
    codename: "xa_quynh_my",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thanh",
    code: 17164,
    division_type: "xã",
    codename: "xa_quynh_thanh",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hậu",
    code: 17167,
    division_type: "xã",
    codename: "xa_quynh_hau",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Lâm",
    code: 17170,
    division_type: "xã",
    codename: "xa_quynh_lam",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Đôi",
    code: 17173,
    division_type: "xã",
    codename: "xa_quynh_doi",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Lương",
    code: 17176,
    division_type: "xã",
    codename: "xa_quynh_luong",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hồng",
    code: 17179,
    division_type: "xã",
    codename: "xa_quynh_hong",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Yên",
    code: 17182,
    division_type: "xã",
    codename: "xa_quynh_yen",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Bá",
    code: 17185,
    division_type: "xã",
    codename: "xa_quynh_ba",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Minh",
    code: 17188,
    division_type: "xã",
    codename: "xa_quynh_minh",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Diễn",
    code: 17191,
    division_type: "xã",
    codename: "xa_quynh_dien",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hưng",
    code: 17194,
    division_type: "xã",
    codename: "xa_quynh_hung",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Giang",
    code: 17197,
    division_type: "xã",
    codename: "xa_quynh_giang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Ngọc",
    code: 17200,
    division_type: "xã",
    codename: "xa_quynh_ngoc",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Nghĩa",
    code: 17203,
    division_type: "xã",
    codename: "xa_quynh_nghia",
    district_code: 421,
  },
  {
    name: "Xã An Hòa",
    code: 17206,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 421,
  },
  {
    name: "Xã Tiến Thủy",
    code: 17209,
    division_type: "xã",
    codename: "xa_tien_thuy",
    district_code: 421,
  },
  {
    name: "Xã Sơn Hải",
    code: 17212,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thọ",
    code: 17215,
    division_type: "xã",
    codename: "xa_quynh_tho",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thuận",
    code: 17218,
    division_type: "xã",
    codename: "xa_quynh_thuan",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Long",
    code: 17221,
    division_type: "xã",
    codename: "xa_quynh_long",
    district_code: 421,
  },
  {
    name: "Xã Tân Thắng",
    code: 17224,
    division_type: "xã",
    codename: "xa_tan_thang",
    district_code: 421,
  },
  {
    name: "Thị trấn Con Cuông",
    code: 17227,
    division_type: "thị trấn",
    codename: "thi_tran_con_cuong",
    district_code: 422,
  },
  {
    name: "Xã Bình Chuẩn",
    code: 17230,
    division_type: "xã",
    codename: "xa_binh_chuan",
    district_code: 422,
  },
  {
    name: "Xã Lạng Khê",
    code: 17233,
    division_type: "xã",
    codename: "xa_lang_khe",
    district_code: 422,
  },
  {
    name: "Xã Cam Lâm",
    code: 17236,
    division_type: "xã",
    codename: "xa_cam_lam",
    district_code: 422,
  },
  {
    name: "Xã Thạch Ngàn",
    code: 17239,
    division_type: "xã",
    codename: "xa_thach_ngan",
    district_code: 422,
  },
  {
    name: "Xã Đôn Phục",
    code: 17242,
    division_type: "xã",
    codename: "xa_don_phuc",
    district_code: 422,
  },
  {
    name: "Xã Mậu Đức",
    code: 17245,
    division_type: "xã",
    codename: "xa_mau_duc",
    district_code: 422,
  },
  {
    name: "Xã Châu Khê",
    code: 17248,
    division_type: "xã",
    codename: "xa_chau_khe",
    district_code: 422,
  },
  {
    name: "Xã Chi Khê",
    code: 17251,
    division_type: "xã",
    codename: "xa_chi_khe",
    district_code: 422,
  },
  {
    name: "Xã Bồng Khê",
    code: 17254,
    division_type: "xã",
    codename: "xa_bong_khe",
    district_code: 422,
  },
  {
    name: "Xã Yên Khê",
    code: 17257,
    division_type: "xã",
    codename: "xa_yen_khe",
    district_code: 422,
  },
  {
    name: "Xã Lục Dạ",
    code: 17260,
    division_type: "xã",
    codename: "xa_luc_da",
    district_code: 422,
  },
  {
    name: "Xã Môn Sơn",
    code: 17263,
    division_type: "xã",
    codename: "xa_mon_son",
    district_code: 422,
  },
  {
    name: "Thị trấn Tân Kỳ",
    code: 17266,
    division_type: "thị trấn",
    codename: "thi_tran_tan_ky",
    district_code: 423,
  },
  {
    name: "Xã Tân Hợp",
    code: 17269,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 423,
  },
  {
    name: "Xã Tân Phú",
    code: 17272,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 423,
  },
  {
    name: "Xã Tân Xuân",
    code: 17275,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 423,
  },
  {
    name: "Xã Giai Xuân",
    code: 17278,
    division_type: "xã",
    codename: "xa_giai_xuan",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 17281,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Đồng",
    code: 17284,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 423,
  },
  {
    name: "Xã Đồng Văn",
    code: 17287,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Thái",
    code: 17290,
    division_type: "xã",
    codename: "xa_nghia_thai",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hợp",
    code: 17293,
    division_type: "xã",
    codename: "xa_nghia_hop",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hoàn",
    code: 17296,
    division_type: "xã",
    codename: "xa_nghia_hoan",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Phúc",
    code: 17299,
    division_type: "xã",
    codename: "xa_nghia_phuc",
    district_code: 423,
  },
  {
    name: "Xã Tiên Kỳ",
    code: 17302,
    division_type: "xã",
    codename: "xa_tien_ky",
    district_code: 423,
  },
  {
    name: "Xã Tân An",
    code: 17305,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Dũng",
    code: 17308,
    division_type: "xã",
    codename: "xa_nghia_dung",
    district_code: 423,
  },
  {
    name: "Xã Tân Long",
    code: 17311,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 423,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 17314,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 423,
  },
  {
    name: "Xã Hương Sơn",
    code: 17317,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 423,
  },
  {
    name: "Xã Kỳ Tân",
    code: 17320,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 423,
  },
  {
    name: "Xã Phú Sơn",
    code: 17323,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 423,
  },
  {
    name: "Xã Tân Hương",
    code: 17325,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hành",
    code: 17326,
    division_type: "xã",
    codename: "xa_nghia_hanh",
    district_code: 423,
  },
  {
    name: "Thị trấn Anh Sơn",
    code: 17329,
    division_type: "thị trấn",
    codename: "thi_tran_anh_son",
    district_code: 424,
  },
  {
    name: "Xã Thọ Sơn",
    code: 17332,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 424,
  },
  {
    name: "Xã Thành Sơn",
    code: 17335,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 424,
  },
  {
    name: "Xã Bình Sơn",
    code: 17338,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 424,
  },
  {
    name: "Xã Tam Sơn",
    code: 17341,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 424,
  },
  {
    name: "Xã Đỉnh Sơn",
    code: 17344,
    division_type: "xã",
    codename: "xa_dinh_son",
    district_code: 424,
  },
  {
    name: "Xã Hùng Sơn",
    code: 17347,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 424,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 17350,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 424,
  },
  {
    name: "Xã Đức Sơn",
    code: 17353,
    division_type: "xã",
    codename: "xa_duc_son",
    district_code: 424,
  },
  {
    name: "Xã Tường Sơn",
    code: 17356,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 424,
  },
  {
    name: "Xã Hoa Sơn",
    code: 17357,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 424,
  },
  {
    name: "Xã Tào Sơn",
    code: 17359,
    division_type: "xã",
    codename: "xa_tao_son",
    district_code: 424,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 17362,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 424,
  },
  {
    name: "Xã Lạng Sơn",
    code: 17365,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 424,
  },
  {
    name: "Xã Hội Sơn",
    code: 17368,
    division_type: "xã",
    codename: "xa_hoi_son",
    district_code: 424,
  },
  {
    name: "Xã Thạch Sơn",
    code: 17371,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 424,
  },
  {
    name: "Xã Phúc Sơn",
    code: 17374,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 424,
  },
  {
    name: "Xã Long Sơn",
    code: 17377,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 424,
  },
  {
    name: "Xã Khai Sơn",
    code: 17380,
    division_type: "xã",
    codename: "xa_khai_son",
    district_code: 424,
  },
  {
    name: "Xã Lĩnh Sơn",
    code: 17383,
    division_type: "xã",
    codename: "xa_linh_son",
    district_code: 424,
  },
  {
    name: "Xã Cao Sơn",
    code: 17386,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 424,
  },
  {
    name: "Thị trấn Diễn Châu",
    code: 17389,
    division_type: "thị trấn",
    codename: "thi_tran_dien_chau",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lâm",
    code: 17392,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 425,
  },
  {
    name: "Xã Diễn Đoài",
    code: 17395,
    division_type: "xã",
    codename: "xa_dien_doai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Trường",
    code: 17398,
    division_type: "xã",
    codename: "xa_dien_truong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Yên",
    code: 17401,
    division_type: "xã",
    codename: "xa_dien_yen",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hoàng",
    code: 17404,
    division_type: "xã",
    codename: "xa_dien_hoang",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hùng",
    code: 17407,
    division_type: "xã",
    codename: "xa_dien_hung",
    district_code: 425,
  },
  {
    name: "Xã Diễn Mỹ",
    code: 17410,
    division_type: "xã",
    codename: "xa_dien_my",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hồng",
    code: 17413,
    division_type: "xã",
    codename: "xa_dien_hong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phong",
    code: 17416,
    division_type: "xã",
    codename: "xa_dien_phong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hải",
    code: 17419,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Tháp",
    code: 17422,
    division_type: "xã",
    codename: "xa_dien_thap",
    district_code: 425,
  },
  {
    name: "Xã Diễn Liên",
    code: 17425,
    division_type: "xã",
    codename: "xa_dien_lien",
    district_code: 425,
  },
  {
    name: "Xã Diễn Vạn",
    code: 17428,
    division_type: "xã",
    codename: "xa_dien_van",
    district_code: 425,
  },
  {
    name: "Xã Diễn Kim",
    code: 17431,
    division_type: "xã",
    codename: "xa_dien_kim",
    district_code: 425,
  },
  {
    name: "Xã Diễn Kỷ",
    code: 17434,
    division_type: "xã",
    codename: "xa_dien_ky",
    district_code: 425,
  },
  {
    name: "Xã Diễn Xuân",
    code: 17437,
    division_type: "xã",
    codename: "xa_dien_xuan",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thái",
    code: 17440,
    division_type: "xã",
    codename: "xa_dien_thai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Đồng",
    code: 17443,
    division_type: "xã",
    codename: "xa_dien_dong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Bích",
    code: 17446,
    division_type: "xã",
    codename: "xa_dien_bich",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hạnh",
    code: 17449,
    division_type: "xã",
    codename: "xa_dien_hanh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Ngọc",
    code: 17452,
    division_type: "xã",
    codename: "xa_dien_ngoc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Quảng",
    code: 17455,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 425,
  },
  {
    name: "Xã Diễn Nguyên",
    code: 17458,
    division_type: "xã",
    codename: "xa_dien_nguyen",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hoa",
    code: 17461,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thành",
    code: 17464,
    division_type: "xã",
    codename: "xa_dien_thanh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phúc",
    code: 17467,
    division_type: "xã",
    codename: "xa_dien_phuc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Cát",
    code: 17476,
    division_type: "xã",
    codename: "xa_dien_cat",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thịnh",
    code: 17479,
    division_type: "xã",
    codename: "xa_dien_thinh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Tân",
    code: 17482,
    division_type: "xã",
    codename: "xa_dien_tan",
    district_code: 425,
  },
  {
    name: "Xã Minh Châu",
    code: 17485,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thọ",
    code: 17488,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lợi",
    code: 17491,
    division_type: "xã",
    codename: "xa_dien_loi",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lộc",
    code: 17494,
    division_type: "xã",
    codename: "xa_dien_loc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Trung",
    code: 17497,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 425,
  },
  {
    name: "Xã Diễn An",
    code: 17500,
    division_type: "xã",
    codename: "xa_dien_an",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phú",
    code: 17503,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 425,
  },
  {
    name: "Thị trấn Yên Thành",
    code: 17506,
    division_type: "thị trấn",
    codename: "thi_tran_yen_thanh",
    district_code: 426,
  },
  {
    name: "Xã Mã Thành",
    code: 17509,
    division_type: "xã",
    codename: "xa_ma_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tiến Thành",
    code: 17510,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Lăng Thành",
    code: 17512,
    division_type: "xã",
    codename: "xa_lang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tân Thành",
    code: 17515,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đức Thành",
    code: 17518,
    division_type: "xã",
    codename: "xa_duc_thanh",
    district_code: 426,
  },
  {
    name: "Xã Kim Thành",
    code: 17521,
    division_type: "xã",
    codename: "xa_kim_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hậu Thành",
    code: 17524,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hùng Thành",
    code: 17525,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đô Thành",
    code: 17527,
    division_type: "xã",
    codename: "xa_do_thanh",
    district_code: 426,
  },
  {
    name: "Xã Thọ Thành",
    code: 17530,
    division_type: "xã",
    codename: "xa_tho_thanh",
    district_code: 426,
  },
  {
    name: "Xã Quang Thành",
    code: 17533,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tây Thành",
    code: 17536,
    division_type: "xã",
    codename: "xa_tay_thanh",
    district_code: 426,
  },
  {
    name: "Xã Phúc Thành",
    code: 17539,
    division_type: "xã",
    codename: "xa_phuc_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hồng Thành",
    code: 17542,
    division_type: "xã",
    codename: "xa_hong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đồng Thành",
    code: 17545,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Phú Thành",
    code: 17548,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hoa Thành",
    code: 17551,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tăng Thành",
    code: 17554,
    division_type: "xã",
    codename: "xa_tang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Văn Thành",
    code: 17557,
    division_type: "xã",
    codename: "xa_van_thanh",
    district_code: 426,
  },
  {
    name: "Xã Thịnh Thành",
    code: 17560,
    division_type: "xã",
    codename: "xa_thinh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hợp Thành",
    code: 17563,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 426,
  },
  {
    name: "Xã Xuân Thành",
    code: 17566,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Bắc Thành",
    code: 17569,
    division_type: "xã",
    codename: "xa_bac_thanh",
    district_code: 426,
  },
  {
    name: "Xã Nhân Thành",
    code: 17572,
    division_type: "xã",
    codename: "xa_nhan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Trung Thành",
    code: 17575,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 426,
  },
  {
    name: "Xã Long Thành",
    code: 17578,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 426,
  },
  {
    name: "Xã Minh Thành",
    code: 17581,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Nam Thành",
    code: 17584,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 426,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 17587,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Lý Thành",
    code: 17590,
    division_type: "xã",
    codename: "xa_ly_thanh",
    district_code: 426,
  },
  {
    name: "Xã Khánh Thành",
    code: 17593,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Viên Thành",
    code: 17596,
    division_type: "xã",
    codename: "xa_vien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đại Thành",
    code: 17599,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 426,
  },
  {
    name: "Xã Liên Thành",
    code: 17602,
    division_type: "xã",
    codename: "xa_lien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Bảo Thành",
    code: 17605,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 426,
  },
  {
    name: "Xã Mỹ Thành",
    code: 17608,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 426,
  },
  {
    name: "Xã Công Thành",
    code: 17611,
    division_type: "xã",
    codename: "xa_cong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Sơn Thành",
    code: 17614,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 426,
  },
  {
    name: "Thị trấn Đô Lương",
    code: 17617,
    division_type: "thị trấn",
    codename: "thi_tran_do_luong",
    district_code: 427,
  },
  {
    name: "Xã Giang Sơn Đông",
    code: 17619,
    division_type: "xã",
    codename: "xa_giang_son_dong",
    district_code: 427,
  },
  {
    name: "Xã Giang Sơn Tây",
    code: 17620,
    division_type: "xã",
    codename: "xa_giang_son_tay",
    district_code: 427,
  },
  {
    name: "Xã Lam Sơn",
    code: 17623,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 427,
  },
  {
    name: "Xã Bồi Sơn",
    code: 17626,
    division_type: "xã",
    codename: "xa_boi_son",
    district_code: 427,
  },
  {
    name: "Xã Hồng Sơn",
    code: 17629,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 427,
  },
  {
    name: "Xã Bài Sơn",
    code: 17632,
    division_type: "xã",
    codename: "xa_bai_son",
    district_code: 427,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17635,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 427,
  },
  {
    name: "Xã Bắc Sơn",
    code: 17638,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 427,
  },
  {
    name: "Xã Tràng Sơn",
    code: 17641,
    division_type: "xã",
    codename: "xa_trang_son",
    district_code: 427,
  },
  {
    name: "Xã Thượng Sơn",
    code: 17644,
    division_type: "xã",
    codename: "xa_thuong_son",
    district_code: 427,
  },
  {
    name: "Xã Hòa Sơn",
    code: 17647,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 427,
  },
  {
    name: "Xã Đặng Sơn",
    code: 17650,
    division_type: "xã",
    codename: "xa_dang_son",
    district_code: 427,
  },
  {
    name: "Xã Đông Sơn",
    code: 17653,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 427,
  },
  {
    name: "Xã Nam Sơn",
    code: 17656,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 427,
  },
  {
    name: "Xã Lưu Sơn",
    code: 17659,
    division_type: "xã",
    codename: "xa_luu_son",
    district_code: 427,
  },
  {
    name: "Xã Yên Sơn",
    code: 17662,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 427,
  },
  {
    name: "Xã Văn Sơn",
    code: 17665,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 427,
  },
  {
    name: "Xã Đà Sơn",
    code: 17668,
    division_type: "xã",
    codename: "xa_da_son",
    district_code: 427,
  },
  {
    name: "Xã Lạc Sơn",
    code: 17671,
    division_type: "xã",
    codename: "xa_lac_son",
    district_code: 427,
  },
  {
    name: "Xã Tân Sơn",
    code: 17674,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 427,
  },
  {
    name: "Xã Thái Sơn",
    code: 17677,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 427,
  },
  {
    name: "Xã Quang Sơn",
    code: 17680,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 427,
  },
  {
    name: "Xã Thịnh Sơn",
    code: 17683,
    division_type: "xã",
    codename: "xa_thinh_son",
    district_code: 427,
  },
  {
    name: "Xã Trung Sơn",
    code: 17686,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 427,
  },
  {
    name: "Xã Xuân Sơn",
    code: 17689,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 427,
  },
  {
    name: "Xã Minh Sơn",
    code: 17692,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 427,
  },
  {
    name: "Xã Thuận Sơn",
    code: 17695,
    division_type: "xã",
    codename: "xa_thuan_son",
    district_code: 427,
  },
  {
    name: "Xã Nhân Sơn",
    code: 17698,
    division_type: "xã",
    codename: "xa_nhan_son",
    district_code: 427,
  },
  {
    name: "Xã Hiến Sơn",
    code: 17701,
    division_type: "xã",
    codename: "xa_hien_son",
    district_code: 427,
  },
  {
    name: "Xã Mỹ Sơn",
    code: 17704,
    division_type: "xã",
    codename: "xa_my_son",
    district_code: 427,
  },
  {
    name: "Xã Trù Sơn",
    code: 17707,
    division_type: "xã",
    codename: "xa_tru_son",
    district_code: 427,
  },
  {
    name: "Xã Đại Sơn",
    code: 17710,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 427,
  },
  {
    name: "Thị trấn Thanh Chương",
    code: 17713,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_chuong",
    district_code: 428,
  },
  {
    name: "Xã Cát Văn",
    code: 17716,
    division_type: "xã",
    codename: "xa_cat_van",
    district_code: 428,
  },
  {
    name: "Xã Thanh Nho",
    code: 17719,
    division_type: "xã",
    codename: "xa_thanh_nho",
    district_code: 428,
  },
  {
    name: "Xã Hạnh Lâm",
    code: 17722,
    division_type: "xã",
    codename: "xa_hanh_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Sơn",
    code: 17723,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hòa",
    code: 17725,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 428,
  },
  {
    name: "Xã Phong Thịnh",
    code: 17728,
    division_type: "xã",
    codename: "xa_phong_thinh",
    district_code: 428,
  },
  {
    name: "Xã Thanh Phong",
    code: 17731,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 17734,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 428,
  },
  {
    name: "Xã Thanh Tiên",
    code: 17737,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 428,
  },
  {
    name: "Xã Thanh Liên",
    code: 17743,
    division_type: "xã",
    codename: "xa_thanh_lien",
    district_code: 428,
  },
  {
    name: "Xã Đại Đồng",
    code: 17749,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Đồng",
    code: 17752,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Ngọc",
    code: 17755,
    division_type: "xã",
    codename: "xa_thanh_ngoc",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hương",
    code: 17758,
    division_type: "xã",
    codename: "xa_thanh_huong",
    district_code: 428,
  },
  {
    name: "Xã Ngọc Lâm",
    code: 17759,
    division_type: "xã",
    codename: "xa_ngoc_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lĩnh",
    code: 17761,
    division_type: "xã",
    codename: "xa_thanh_linh",
    district_code: 428,
  },
  {
    name: "Xã Đồng Văn",
    code: 17764,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 428,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17767,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 428,
  },
  {
    name: "Xã Thanh Thịnh",
    code: 17770,
    division_type: "xã",
    codename: "xa_thanh_thinh",
    district_code: 428,
  },
  {
    name: "Xã Thanh An",
    code: 17773,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 428,
  },
  {
    name: "Xã Thanh Chi",
    code: 17776,
    division_type: "xã",
    codename: "xa_thanh_chi",
    district_code: 428,
  },
  {
    name: "Xã Xuân Tường",
    code: 17779,
    division_type: "xã",
    codename: "xa_xuan_tuong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Dương",
    code: 17782,
    division_type: "xã",
    codename: "xa_thanh_duong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lương",
    code: 17785,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Khê",
    code: 17788,
    division_type: "xã",
    codename: "xa_thanh_khe",
    district_code: 428,
  },
  {
    name: "Xã Võ Liệt",
    code: 17791,
    division_type: "xã",
    codename: "xa_vo_liet",
    district_code: 428,
  },
  {
    name: "Xã Thanh Long",
    code: 17794,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 428,
  },
  {
    name: "Xã Thanh Thủy",
    code: 17797,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 428,
  },
  {
    name: "Xã Thanh Khai",
    code: 17800,
    division_type: "xã",
    codename: "xa_thanh_khai",
    district_code: 428,
  },
  {
    name: "Xã Thanh Yên",
    code: 17803,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hà",
    code: 17806,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 428,
  },
  {
    name: "Xã Thanh Giang",
    code: 17809,
    division_type: "xã",
    codename: "xa_thanh_giang",
    district_code: 428,
  },
  {
    name: "Xã Thanh Tùng",
    code: 17812,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lâm",
    code: 17815,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Mai",
    code: 17818,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 428,
  },
  {
    name: "Xã Thanh Xuân",
    code: 17821,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 428,
  },
  {
    name: "Xã Thanh Đức",
    code: 17824,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 428,
  },
  {
    name: "Thị trấn Quán Hành",
    code: 17827,
    division_type: "thị trấn",
    codename: "thi_tran_quan_hanh",
    district_code: 429,
  },
  {
    name: "Xã Nghi Văn",
    code: 17830,
    division_type: "xã",
    codename: "xa_nghi_van",
    district_code: 429,
  },
  {
    name: "Xã Nghi Yên",
    code: 17833,
    division_type: "xã",
    codename: "xa_nghi_yen",
    district_code: 429,
  },
  {
    name: "Xã Nghi Tiến",
    code: 17836,
    division_type: "xã",
    codename: "xa_nghi_tien",
    district_code: 429,
  },
  {
    name: "Xã Nghi Hưng",
    code: 17839,
    division_type: "xã",
    codename: "xa_nghi_hung",
    district_code: 429,
  },
  {
    name: "Xã Nghi Đồng",
    code: 17842,
    division_type: "xã",
    codename: "xa_nghi_dong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thiết",
    code: 17845,
    division_type: "xã",
    codename: "xa_nghi_thiet",
    district_code: 429,
  },
  {
    name: "Xã Nghi Lâm",
    code: 17848,
    division_type: "xã",
    codename: "xa_nghi_lam",
    district_code: 429,
  },
  {
    name: "Xã Nghi Quang",
    code: 17851,
    division_type: "xã",
    codename: "xa_nghi_quang",
    district_code: 429,
  },
  {
    name: "Xã Nghi Kiều",
    code: 17854,
    division_type: "xã",
    codename: "xa_nghi_kieu",
    district_code: 429,
  },
  {
    name: "Xã Nghi Mỹ",
    code: 17857,
    division_type: "xã",
    codename: "xa_nghi_my",
    district_code: 429,
  },
  {
    name: "Xã Nghi Phương",
    code: 17860,
    division_type: "xã",
    codename: "xa_nghi_phuong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thuận",
    code: 17863,
    division_type: "xã",
    codename: "xa_nghi_thuan",
    district_code: 429,
  },
  {
    name: "Xã Nghi Long",
    code: 17866,
    division_type: "xã",
    codename: "xa_nghi_long",
    district_code: 429,
  },
  {
    name: "Xã Nghi Xá",
    code: 17869,
    division_type: "xã",
    codename: "xa_nghi_xa",
    district_code: 429,
  },
  {
    name: "Xã Nghi Hoa",
    code: 17875,
    division_type: "xã",
    codename: "xa_nghi_hoa",
    district_code: 429,
  },
  {
    name: "Xã Khánh Hợp",
    code: 17878,
    division_type: "xã",
    codename: "xa_khanh_hop",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thịnh",
    code: 17881,
    division_type: "xã",
    codename: "xa_nghi_thinh",
    district_code: 429,
  },
  {
    name: "Xã Nghi Công Bắc",
    code: 17884,
    division_type: "xã",
    codename: "xa_nghi_cong_bac",
    district_code: 429,
  },
  {
    name: "Xã Nghi Công Nam",
    code: 17887,
    division_type: "xã",
    codename: "xa_nghi_cong_nam",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thạch",
    code: 17890,
    division_type: "xã",
    codename: "xa_nghi_thach",
    district_code: 429,
  },
  {
    name: "Xã Nghi Trung",
    code: 17893,
    division_type: "xã",
    codename: "xa_nghi_trung",
    district_code: 429,
  },
  {
    name: "Xã Nghi Trường",
    code: 17896,
    division_type: "xã",
    codename: "xa_nghi_truong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Diên",
    code: 17899,
    division_type: "xã",
    codename: "xa_nghi_dien",
    district_code: 429,
  },
  {
    name: "Xã Nghi Phong",
    code: 17902,
    division_type: "xã",
    codename: "xa_nghi_phong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Xuân",
    code: 17905,
    division_type: "xã",
    codename: "xa_nghi_xuan",
    district_code: 429,
  },
  {
    name: "Xã Nghi Vạn",
    code: 17911,
    division_type: "xã",
    codename: "xa_nghi_van",
    district_code: 429,
  },
  {
    name: "Xã Phúc Thọ",
    code: 17917,
    division_type: "xã",
    codename: "xa_phuc_tho",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thái",
    code: 17926,
    division_type: "xã",
    codename: "xa_nghi_thai",
    district_code: 429,
  },
  {
    name: "Xã Nam Hưng",
    code: 17932,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 430,
  },
  {
    name: "Xã Nam Nghĩa",
    code: 17935,
    division_type: "xã",
    codename: "xa_nam_nghia",
    district_code: 430,
  },
  {
    name: "Xã Nam Thanh",
    code: 17938,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 430,
  },
  {
    name: "Xã Nam Anh",
    code: 17941,
    division_type: "xã",
    codename: "xa_nam_anh",
    district_code: 430,
  },
  {
    name: "Xã Nam Xuân",
    code: 17944,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 430,
  },
  {
    name: "Xã Nam Thái",
    code: 17947,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 430,
  },
  {
    name: "Thị trấn Nam Đàn",
    code: 17950,
    division_type: "thị trấn",
    codename: "thi_tran_nam_dan",
    district_code: 430,
  },
  {
    name: "Xã Nam Lĩnh",
    code: 17953,
    division_type: "xã",
    codename: "xa_nam_linh",
    district_code: 430,
  },
  {
    name: "Xã Nam Giang",
    code: 17956,
    division_type: "xã",
    codename: "xa_nam_giang",
    district_code: 430,
  },
  {
    name: "Xã Xuân Hòa",
    code: 17959,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 430,
  },
  {
    name: "Xã Hùng Tiến",
    code: 17962,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 430,
  },
  {
    name: "Xã Thượng Tân Lộc",
    code: 17968,
    division_type: "xã",
    codename: "xa_thuong_tan_loc",
    district_code: 430,
  },
  {
    name: "Xã Kim Liên",
    code: 17971,
    division_type: "xã",
    codename: "xa_kim_lien",
    district_code: 430,
  },
  {
    name: "Xã Hồng Long",
    code: 17977,
    division_type: "xã",
    codename: "xa_hong_long",
    district_code: 430,
  },
  {
    name: "Xã Xuân Lâm",
    code: 17980,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 430,
  },
  {
    name: "Xã Nam Cát",
    code: 17983,
    division_type: "xã",
    codename: "xa_nam_cat",
    district_code: 430,
  },
  {
    name: "Xã Khánh Sơn",
    code: 17986,
    division_type: "xã",
    codename: "xa_khanh_son",
    district_code: 430,
  },
  {
    name: "Xã Trung Phúc Cường",
    code: 17989,
    division_type: "xã",
    codename: "xa_trung_phuc_cuong",
    district_code: 430,
  },
  {
    name: "Xã Nam Kim",
    code: 17998,
    division_type: "xã",
    codename: "xa_nam_kim",
    district_code: 430,
  },
  {
    name: "Thị trấn Hưng Nguyên",
    code: 18001,
    division_type: "thị trấn",
    codename: "thi_tran_hung_nguyen",
    district_code: 431,
  },
  {
    name: "Xã Hưng Trung",
    code: 18004,
    division_type: "xã",
    codename: "xa_hung_trung",
    district_code: 431,
  },
  {
    name: "Xã Hưng Yên",
    code: 18007,
    division_type: "xã",
    codename: "xa_hung_yen",
    district_code: 431,
  },
  {
    name: "Xã Hưng Yên Bắc",
    code: 18008,
    division_type: "xã",
    codename: "xa_hung_yen_bac",
    district_code: 431,
  },
  {
    name: "Xã Hưng Tây",
    code: 18010,
    division_type: "xã",
    codename: "xa_hung_tay",
    district_code: 431,
  },
  {
    name: "Xã Hưng Đạo",
    code: 18016,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 431,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 18019,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 18022,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 431,
  },
  {
    name: "Xã Hưng Lĩnh",
    code: 18025,
    division_type: "xã",
    codename: "xa_hung_linh",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thông",
    code: 18028,
    division_type: "xã",
    codename: "xa_hung_thong",
    district_code: 431,
  },
  {
    name: "Xã Hưng Tân",
    code: 18031,
    division_type: "xã",
    codename: "xa_hung_tan",
    district_code: 431,
  },
  {
    name: "Xã Hưng Lợi",
    code: 18034,
    division_type: "xã",
    codename: "xa_hung_loi",
    district_code: 431,
  },
  {
    name: "Xã Hưng Nghĩa",
    code: 18037,
    division_type: "xã",
    codename: "xa_hung_nghia",
    district_code: 431,
  },
  {
    name: "Xã Hưng Phúc",
    code: 18040,
    division_type: "xã",
    codename: "xa_hung_phuc",
    district_code: 431,
  },
  {
    name: "Xã Long Xá",
    code: 18043,
    division_type: "xã",
    codename: "xa_long_xa",
    district_code: 431,
  },
  {
    name: "Xã Châu Nhân",
    code: 18052,
    division_type: "xã",
    codename: "xa_chau_nhan",
    district_code: 431,
  },
  {
    name: "Xã Xuân Lam",
    code: 18055,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thành",
    code: 18064,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 431,
  },
  {
    name: "Xã Quỳnh Vinh",
    code: 17104,
    division_type: "xã",
    codename: "xa_quynh_vinh",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Lộc",
    code: 17107,
    division_type: "xã",
    codename: "xa_quynh_loc",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Thiện",
    code: 17110,
    division_type: "phường",
    codename: "phuong_quynh_thien",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Lập",
    code: 17113,
    division_type: "xã",
    codename: "xa_quynh_lap",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Trang",
    code: 17116,
    division_type: "xã",
    codename: "xa_quynh_trang",
    district_code: 432,
  },
  {
    name: "Phường Mai Hùng",
    code: 17125,
    division_type: "phường",
    codename: "phuong_mai_hung",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Dị",
    code: 17128,
    division_type: "phường",
    codename: "phuong_quynh_di",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Xuân",
    code: 17131,
    division_type: "phường",
    codename: "phuong_quynh_xuan",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Phương",
    code: 17134,
    division_type: "phường",
    codename: "phuong_quynh_phuong",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Liên",
    code: 17137,
    division_type: "xã",
    codename: "xa_quynh_lien",
    district_code: 432,
  },
  {
    name: "Phường Trần Phú",
    code: 18070,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 436,
  },
  {
    name: "Phường Nam Hà",
    code: 18073,
    division_type: "phường",
    codename: "phuong_nam_ha",
    district_code: 436,
  },
  {
    name: "Phường Bắc Hà",
    code: 18076,
    division_type: "phường",
    codename: "phuong_bac_ha",
    district_code: 436,
  },
  {
    name: "Phường Nguyễn Du",
    code: 18077,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 436,
  },
  {
    name: "Phường Tân Giang",
    code: 18079,
    division_type: "phường",
    codename: "phuong_tan_giang",
    district_code: 436,
  },
  {
    name: "Phường Đại Nài",
    code: 18082,
    division_type: "phường",
    codename: "phuong_dai_nai",
    district_code: 436,
  },
  {
    name: "Phường Hà Huy Tập",
    code: 18085,
    division_type: "phường",
    codename: "phuong_ha_huy_tap",
    district_code: 436,
  },
  {
    name: "Xã Thạch Trung",
    code: 18088,
    division_type: "xã",
    codename: "xa_thach_trung",
    district_code: 436,
  },
  {
    name: "Phường Thạch Quý",
    code: 18091,
    division_type: "phường",
    codename: "phuong_thach_quy",
    district_code: 436,
  },
  {
    name: "Phường Thạch Linh",
    code: 18094,
    division_type: "phường",
    codename: "phuong_thach_linh",
    district_code: 436,
  },
  {
    name: "Phường Văn Yên",
    code: 18097,
    division_type: "phường",
    codename: "phuong_van_yen",
    district_code: 436,
  },
  {
    name: "Xã Thạch Hạ",
    code: 18100,
    division_type: "xã",
    codename: "xa_thach_ha",
    district_code: 436,
  },
  {
    name: "Xã Đồng Môn",
    code: 18103,
    division_type: "xã",
    codename: "xa_dong_mon",
    district_code: 436,
  },
  {
    name: "Xã Thạch Hưng",
    code: 18109,
    division_type: "xã",
    codename: "xa_thach_hung",
    district_code: 436,
  },
  {
    name: "Xã Thạch Bình",
    code: 18112,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 436,
  },
  {
    name: "Phường Bắc Hồng",
    code: 18115,
    division_type: "phường",
    codename: "phuong_bac_hong",
    district_code: 437,
  },
  {
    name: "Phường Nam Hồng",
    code: 18118,
    division_type: "phường",
    codename: "phuong_nam_hong",
    district_code: 437,
  },
  {
    name: "Phường Trung Lương",
    code: 18121,
    division_type: "phường",
    codename: "phuong_trung_luong",
    district_code: 437,
  },
  {
    name: "Phường Đức Thuận",
    code: 18124,
    division_type: "phường",
    codename: "phuong_duc_thuan",
    district_code: 437,
  },
  {
    name: "Phường Đậu Liêu",
    code: 18127,
    division_type: "phường",
    codename: "phuong_dau_lieu",
    district_code: 437,
  },
  {
    name: "Xã Thuận Lộc",
    code: 18130,
    division_type: "xã",
    codename: "xa_thuan_loc",
    district_code: 437,
  },
  {
    name: "Thị trấn Phố Châu",
    code: 18133,
    division_type: "thị trấn",
    codename: "thi_tran_pho_chau",
    district_code: 439,
  },
  {
    name: "Thị trấn Tây Sơn",
    code: 18136,
    division_type: "thị trấn",
    codename: "thi_tran_tay_son",
    district_code: 439,
  },
  {
    name: "Xã Sơn Hồng",
    code: 18139,
    division_type: "xã",
    codename: "xa_son_hong",
    district_code: 439,
  },
  {
    name: "Xã Sơn Tiến",
    code: 18142,
    division_type: "xã",
    codename: "xa_son_tien",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lâm",
    code: 18145,
    division_type: "xã",
    codename: "xa_son_lam",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lễ",
    code: 18148,
    division_type: "xã",
    codename: "xa_son_le",
    district_code: 439,
  },
  {
    name: "Xã Sơn Giang",
    code: 18157,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lĩnh",
    code: 18160,
    division_type: "xã",
    codename: "xa_son_linh",
    district_code: 439,
  },
  {
    name: "Xã An Hòa Thịnh",
    code: 18163,
    division_type: "xã",
    codename: "xa_an_hoa_thinh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Tây",
    code: 18172,
    division_type: "xã",
    codename: "xa_son_tay",
    district_code: 439,
  },
  {
    name: "Xã Sơn Ninh",
    code: 18175,
    division_type: "xã",
    codename: "xa_son_ninh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Châu",
    code: 18178,
    division_type: "xã",
    codename: "xa_son_chau",
    district_code: 439,
  },
  {
    name: "Xã Tân Mỹ Hà",
    code: 18181,
    division_type: "xã",
    codename: "xa_tan_my_ha",
    district_code: 439,
  },
  {
    name: "Xã Quang Diệm",
    code: 18184,
    division_type: "xã",
    codename: "xa_quang_diem",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trung",
    code: 18187,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 439,
  },
  {
    name: "Xã Sơn Bằng",
    code: 18190,
    division_type: "xã",
    codename: "xa_son_bang",
    district_code: 439,
  },
  {
    name: "Xã Sơn Bình",
    code: 18193,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Kim 1",
    code: 18196,
    division_type: "xã",
    codename: "xa_son_kim_1",
    district_code: 439,
  },
  {
    name: "Xã Sơn Kim 2",
    code: 18199,
    division_type: "xã",
    codename: "xa_son_kim_2",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trà",
    code: 18202,
    division_type: "xã",
    codename: "xa_son_tra",
    district_code: 439,
  },
  {
    name: "Xã Sơn Long",
    code: 18205,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 439,
  },
  {
    name: "Xã Kim Hoa",
    code: 18211,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 439,
  },
  {
    name: "Xã Sơn Hàm",
    code: 18214,
    division_type: "xã",
    codename: "xa_son_ham",
    district_code: 439,
  },
  {
    name: "Xã Sơn Phú",
    code: 18217,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trường",
    code: 18223,
    division_type: "xã",
    codename: "xa_son_truong",
    district_code: 439,
  },
  {
    name: "Thị trấn Đức Thọ",
    code: 18229,
    division_type: "thị trấn",
    codename: "thi_tran_duc_tho",
    district_code: 440,
  },
  {
    name: "Xã Quang Vĩnh",
    code: 18235,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 440,
  },
  {
    name: "Xã Tùng Châu",
    code: 18241,
    division_type: "xã",
    codename: "xa_tung_chau",
    district_code: 440,
  },
  {
    name: "Xã Trường Sơn",
    code: 18244,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 440,
  },
  {
    name: "Xã Liên Minh",
    code: 18247,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 440,
  },
  {
    name: "Xã Yên Hồ",
    code: 18253,
    division_type: "xã",
    codename: "xa_yen_ho",
    district_code: 440,
  },
  {
    name: "Xã Tùng Ảnh",
    code: 18259,
    division_type: "xã",
    codename: "xa_tung_anh",
    district_code: 440,
  },
  {
    name: "Xã Bùi La Nhân",
    code: 18262,
    division_type: "xã",
    codename: "xa_bui_la_nhan",
    district_code: 440,
  },
  {
    name: "Xã Thanh Bình Thịnh",
    code: 18274,
    division_type: "xã",
    codename: "xa_thanh_binh_thinh",
    district_code: 440,
  },
  {
    name: "Xã Lâm Trung Thủy",
    code: 18277,
    division_type: "xã",
    codename: "xa_lam_trung_thuy",
    district_code: 440,
  },
  {
    name: "Xã Hòa Lạc",
    code: 18280,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 440,
  },
  {
    name: "Xã Tân Dân",
    code: 18283,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 440,
  },
  {
    name: "Xã An Dũng",
    code: 18298,
    division_type: "xã",
    codename: "xa_an_dung",
    district_code: 440,
  },
  {
    name: "Xã Đức Đồng",
    code: 18304,
    division_type: "xã",
    codename: "xa_duc_dong",
    district_code: 440,
  },
  {
    name: "Xã Đức Lạng",
    code: 18307,
    division_type: "xã",
    codename: "xa_duc_lang",
    district_code: 440,
  },
  {
    name: "Xã Tân Hương",
    code: 18310,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 440,
  },
  {
    name: "Thị trấn Vũ Quang",
    code: 18313,
    division_type: "thị trấn",
    codename: "thi_tran_vu_quang",
    district_code: 441,
  },
  {
    name: "Xã Ân Phú",
    code: 18316,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 441,
  },
  {
    name: "Xã Đức Giang",
    code: 18319,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 441,
  },
  {
    name: "Xã Đức Lĩnh",
    code: 18322,
    division_type: "xã",
    codename: "xa_duc_linh",
    district_code: 441,
  },
  {
    name: "Xã Thọ Điền",
    code: 18325,
    division_type: "xã",
    codename: "xa_tho_dien",
    district_code: 441,
  },
  {
    name: "Xã Đức Hương",
    code: 18328,
    division_type: "xã",
    codename: "xa_duc_huong",
    district_code: 441,
  },
  {
    name: "Xã Đức Bồng",
    code: 18331,
    division_type: "xã",
    codename: "xa_duc_bong",
    district_code: 441,
  },
  {
    name: "Xã Đức Liên",
    code: 18334,
    division_type: "xã",
    codename: "xa_duc_lien",
    district_code: 441,
  },
  {
    name: "Xã Hương Minh",
    code: 18340,
    division_type: "xã",
    codename: "xa_huong_minh",
    district_code: 441,
  },
  {
    name: "Xã Quang Thọ",
    code: 18343,
    division_type: "xã",
    codename: "xa_quang_tho",
    district_code: 441,
  },
  {
    name: "Thị trấn Xuân An",
    code: 18352,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_an",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hội",
    code: 18355,
    division_type: "xã",
    codename: "xa_xuan_hoi",
    district_code: 442,
  },
  {
    name: "Xã Đan Trường",
    code: 18358,
    division_type: "xã",
    codename: "xa_dan_truong",
    district_code: 442,
  },
  {
    name: "Xã Xuân Phổ",
    code: 18364,
    division_type: "xã",
    codename: "xa_xuan_pho",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hải",
    code: 18367,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 442,
  },
  {
    name: "Xã Xuân Giang",
    code: 18370,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 442,
  },
  {
    name: "Thị trấn Tiên Điền",
    code: 18373,
    division_type: "thị trấn",
    codename: "thi_tran_tien_dien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Yên",
    code: 18376,
    division_type: "xã",
    codename: "xa_xuan_yen",
    district_code: 442,
  },
  {
    name: "Xã Xuân Mỹ",
    code: 18379,
    division_type: "xã",
    codename: "xa_xuan_my",
    district_code: 442,
  },
  {
    name: "Xã Xuân Thành",
    code: 18382,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 442,
  },
  {
    name: "Xã Xuân Viên",
    code: 18385,
    division_type: "xã",
    codename: "xa_xuan_vien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hồng",
    code: 18388,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 442,
  },
  {
    name: "Xã Cỗ Đạm",
    code: 18391,
    division_type: "xã",
    codename: "xa_co_dam",
    district_code: 442,
  },
  {
    name: "Xã Xuân Liên",
    code: 18394,
    division_type: "xã",
    codename: "xa_xuan_lien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Lĩnh",
    code: 18397,
    division_type: "xã",
    codename: "xa_xuan_linh",
    district_code: 442,
  },
  {
    name: "Xã Xuân Lam",
    code: 18400,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 442,
  },
  {
    name: "Xã Cương Gián",
    code: 18403,
    division_type: "xã",
    codename: "xa_cuong_gian",
    district_code: 442,
  },
  {
    name: "Thị trấn Nghèn",
    code: 18406,
    division_type: "thị trấn",
    codename: "thi_tran_nghen",
    district_code: 443,
  },
  {
    name: "Xã Thiên Lộc",
    code: 18415,
    division_type: "xã",
    codename: "xa_thien_loc",
    district_code: 443,
  },
  {
    name: "Xã Thuần Thiện",
    code: 18418,
    division_type: "xã",
    codename: "xa_thuan_thien",
    district_code: 443,
  },
  {
    name: "Xã Vượng Lộc",
    code: 18427,
    division_type: "xã",
    codename: "xa_vuong_loc",
    district_code: 443,
  },
  {
    name: "Xã Thanh Lộc",
    code: 18433,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 443,
  },
  {
    name: "Xã Kim Song Trường",
    code: 18436,
    division_type: "xã",
    codename: "xa_kim_song_truong",
    district_code: 443,
  },
  {
    name: "Xã Thường Nga",
    code: 18439,
    division_type: "xã",
    codename: "xa_thuong_nga",
    district_code: 443,
  },
  {
    name: "Xã Tùng Lộc",
    code: 18445,
    division_type: "xã",
    codename: "xa_tung_loc",
    district_code: 443,
  },
  {
    name: "Xã Phú Lộc",
    code: 18454,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 443,
  },
  {
    name: "Xã Gia Hanh",
    code: 18463,
    division_type: "xã",
    codename: "xa_gia_hanh",
    district_code: 443,
  },
  {
    name: "Xã Khánh Vĩnh Yên",
    code: 18466,
    division_type: "xã",
    codename: "xa_khanh_vinh_yen",
    district_code: 443,
  },
  {
    name: "Xã Trung Lộc",
    code: 18472,
    division_type: "xã",
    codename: "xa_trung_loc",
    district_code: 443,
  },
  {
    name: "Xã Xuân Lộc",
    code: 18475,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 443,
  },
  {
    name: "Xã Thượng Lộc",
    code: 18478,
    division_type: "xã",
    codename: "xa_thuong_loc",
    district_code: 443,
  },
  {
    name: "Xã Quang Lộc",
    code: 18481,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 443,
  },
  {
    name: "Thị trấn Đồng Lộc",
    code: 18484,
    division_type: "thị trấn",
    codename: "thi_tran_dong_loc",
    district_code: 443,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 18487,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 443,
  },
  {
    name: "Xã Sơn Lộc",
    code: 18490,
    division_type: "xã",
    codename: "xa_son_loc",
    district_code: 443,
  },
  {
    name: "Thị trấn Hương Khê",
    code: 18496,
    division_type: "thị trấn",
    codename: "thi_tran_huong_khe",
    district_code: 444,
  },
  {
    name: "Xã Điền Mỹ",
    code: 18499,
    division_type: "xã",
    codename: "xa_dien_my",
    district_code: 444,
  },
  {
    name: "Xã Hà Linh",
    code: 18502,
    division_type: "xã",
    codename: "xa_ha_linh",
    district_code: 444,
  },
  {
    name: "Xã Hương Thủy",
    code: 18505,
    division_type: "xã",
    codename: "xa_huong_thuy",
    district_code: 444,
  },
  {
    name: "Xã Hòa Hải",
    code: 18508,
    division_type: "xã",
    codename: "xa_hoa_hai",
    district_code: 444,
  },
  {
    name: "Xã Phúc Đồng",
    code: 18514,
    division_type: "xã",
    codename: "xa_phuc_dong",
    district_code: 444,
  },
  {
    name: "Xã Hương Giang",
    code: 18517,
    division_type: "xã",
    codename: "xa_huong_giang",
    district_code: 444,
  },
  {
    name: "Xã Lộc Yên",
    code: 18520,
    division_type: "xã",
    codename: "xa_loc_yen",
    district_code: 444,
  },
  {
    name: "Xã Hương Bình",
    code: 18523,
    division_type: "xã",
    codename: "xa_huong_binh",
    district_code: 444,
  },
  {
    name: "Xã Hương Long",
    code: 18526,
    division_type: "xã",
    codename: "xa_huong_long",
    district_code: 444,
  },
  {
    name: "Xã Phú Gia",
    code: 18529,
    division_type: "xã",
    codename: "xa_phu_gia",
    district_code: 444,
  },
  {
    name: "Xã Gia Phố",
    code: 18532,
    division_type: "xã",
    codename: "xa_gia_pho",
    district_code: 444,
  },
  {
    name: "Xã Phú Phong",
    code: 18535,
    division_type: "xã",
    codename: "xa_phu_phong",
    district_code: 444,
  },
  {
    name: "Xã Hương Đô",
    code: 18538,
    division_type: "xã",
    codename: "xa_huong_do",
    district_code: 444,
  },
  {
    name: "Xã Hương Vĩnh",
    code: 18541,
    division_type: "xã",
    codename: "xa_huong_vinh",
    district_code: 444,
  },
  {
    name: "Xã Hương Xuân",
    code: 18544,
    division_type: "xã",
    codename: "xa_huong_xuan",
    district_code: 444,
  },
  {
    name: "Xã Phúc Trạch",
    code: 18547,
    division_type: "xã",
    codename: "xa_phuc_trach",
    district_code: 444,
  },
  {
    name: "Xã Hương Trà",
    code: 18550,
    division_type: "xã",
    codename: "xa_huong_tra",
    district_code: 444,
  },
  {
    name: "Xã Hương Trạch",
    code: 18553,
    division_type: "xã",
    codename: "xa_huong_trach",
    district_code: 444,
  },
  {
    name: "Xã Hương Lâm",
    code: 18556,
    division_type: "xã",
    codename: "xa_huong_lam",
    district_code: 444,
  },
  {
    name: "Xã Hương Liên",
    code: 18559,
    division_type: "xã",
    codename: "xa_huong_lien",
    district_code: 444,
  },
  {
    name: "Thị trấn Thạch Hà",
    code: 18562,
    division_type: "thị trấn",
    codename: "thi_tran_thach_ha",
    district_code: 445,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 18565,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Hải",
    code: 18571,
    division_type: "xã",
    codename: "xa_thach_hai",
    district_code: 445,
  },
  {
    name: "Xã Thạch Kênh",
    code: 18586,
    division_type: "xã",
    codename: "xa_thach_kenh",
    district_code: 445,
  },
  {
    name: "Xã Thạch Sơn",
    code: 18589,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Liên",
    code: 18592,
    division_type: "xã",
    codename: "xa_thach_lien",
    district_code: 445,
  },
  {
    name: "Xã Đỉnh Bàn",
    code: 18595,
    division_type: "xã",
    codename: "xa_dinh_ban",
    district_code: 445,
  },
  {
    name: "Xã Việt Tiến",
    code: 18601,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 445,
  },
  {
    name: "Xã Thạch Khê",
    code: 18604,
    division_type: "xã",
    codename: "xa_thach_khe",
    district_code: 445,
  },
  {
    name: "Xã Thạch Long",
    code: 18607,
    division_type: "xã",
    codename: "xa_thach_long",
    district_code: 445,
  },
  {
    name: "Xã Thạch Trị",
    code: 18619,
    division_type: "xã",
    codename: "xa_thach_tri",
    district_code: 445,
  },
  {
    name: "Xã Thạch Lạc",
    code: 18622,
    division_type: "xã",
    codename: "xa_thach_lac",
    district_code: 445,
  },
  {
    name: "Xã Thạch Ngọc",
    code: 18625,
    division_type: "xã",
    codename: "xa_thach_ngoc",
    district_code: 445,
  },
  {
    name: "Xã Tượng Sơn",
    code: 18628,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Văn",
    code: 18631,
    division_type: "xã",
    codename: "xa_thach_van",
    district_code: 445,
  },
  {
    name: "Xã Lưu Vĩnh Sơn",
    code: 18634,
    division_type: "xã",
    codename: "xa_luu_vinh_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Thắng",
    code: 18637,
    division_type: "xã",
    codename: "xa_thach_thang",
    district_code: 445,
  },
  {
    name: "Xã Thạch Đài",
    code: 18643,
    division_type: "xã",
    codename: "xa_thach_dai",
    district_code: 445,
  },
  {
    name: "Xã Thạch Hội",
    code: 18649,
    division_type: "xã",
    codename: "xa_thach_hoi",
    district_code: 445,
  },
  {
    name: "Xã Tân Lâm Hương",
    code: 18652,
    division_type: "xã",
    codename: "xa_tan_lam_huong",
    district_code: 445,
  },
  {
    name: "Xã Thạch Xuân",
    code: 18658,
    division_type: "xã",
    codename: "xa_thach_xuan",
    district_code: 445,
  },
  {
    name: "Xã Nam Điền",
    code: 18667,
    division_type: "xã",
    codename: "xa_nam_dien",
    district_code: 445,
  },
  {
    name: "Thị trấn Cẩm Xuyên",
    code: 18673,
    division_type: "thị trấn",
    codename: "thi_tran_cam_xuyen",
    district_code: 446,
  },
  {
    name: "Thị trấn Thiên Cầm",
    code: 18676,
    division_type: "thị trấn",
    codename: "thi_tran_thien_cam",
    district_code: 446,
  },
  {
    name: "Xã Yên Hòa",
    code: 18679,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Dương",
    code: 18682,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Bình",
    code: 18685,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Vĩnh",
    code: 18691,
    division_type: "xã",
    codename: "xa_cam_vinh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thành",
    code: 18694,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Quang",
    code: 18697,
    division_type: "xã",
    codename: "xa_cam_quang",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thạch",
    code: 18706,
    division_type: "xã",
    codename: "xa_cam_thach",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Nhượng",
    code: 18709,
    division_type: "xã",
    codename: "xa_cam_nhuong",
    district_code: 446,
  },
  {
    name: "Xã Nam Phúc Thăng",
    code: 18712,
    division_type: "xã",
    codename: "xa_nam_phuc_thang",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Duệ",
    code: 18715,
    division_type: "xã",
    codename: "xa_cam_due",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lĩnh",
    code: 18721,
    division_type: "xã",
    codename: "xa_cam_linh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Quan",
    code: 18724,
    division_type: "xã",
    codename: "xa_cam_quan",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Hà",
    code: 18727,
    division_type: "xã",
    codename: "xa_cam_ha",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lộc",
    code: 18730,
    division_type: "xã",
    codename: "xa_cam_loc",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Hưng",
    code: 18733,
    division_type: "xã",
    codename: "xa_cam_hung",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thịnh",
    code: 18736,
    division_type: "xã",
    codename: "xa_cam_thinh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Mỹ",
    code: 18739,
    division_type: "xã",
    codename: "xa_cam_my",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Trung",
    code: 18742,
    division_type: "xã",
    codename: "xa_cam_trung",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 18745,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lạc",
    code: 18748,
    division_type: "xã",
    codename: "xa_cam_lac",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Minh",
    code: 18751,
    division_type: "xã",
    codename: "xa_cam_minh",
    district_code: 446,
  },
  {
    name: "Xã Kỳ Xuân",
    code: 18757,
    division_type: "xã",
    codename: "xa_ky_xuan",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Bắc",
    code: 18760,
    division_type: "xã",
    codename: "xa_ky_bac",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Phú",
    code: 18763,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Phong",
    code: 18766,
    division_type: "xã",
    codename: "xa_ky_phong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tiến",
    code: 18769,
    division_type: "xã",
    codename: "xa_ky_tien",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Giang",
    code: 18772,
    division_type: "xã",
    codename: "xa_ky_giang",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Đồng",
    code: 18775,
    division_type: "xã",
    codename: "xa_ky_dong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Khang",
    code: 18778,
    division_type: "xã",
    codename: "xa_ky_khang",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Văn",
    code: 18784,
    division_type: "xã",
    codename: "xa_ky_van",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Trung",
    code: 18787,
    division_type: "xã",
    codename: "xa_ky_trung",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thọ",
    code: 18790,
    division_type: "xã",
    codename: "xa_ky_tho",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tây",
    code: 18793,
    division_type: "xã",
    codename: "xa_ky_tay",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thượng",
    code: 18799,
    division_type: "xã",
    codename: "xa_ky_thuong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Hải",
    code: 18802,
    division_type: "xã",
    codename: "xa_ky_hai",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thư",
    code: 18805,
    division_type: "xã",
    codename: "xa_ky_thu",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Châu",
    code: 18811,
    division_type: "xã",
    codename: "xa_ky_chau",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tân",
    code: 18814,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 447,
  },
  {
    name: "Xã Lâm Hợp",
    code: 18838,
    division_type: "xã",
    codename: "xa_lam_hop",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 18844,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Lạc",
    code: 18850,
    division_type: "xã",
    codename: "xa_ky_lac",
    district_code: 447,
  },
  {
    name: "Xã Tân Lộc",
    code: 18409,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 448,
  },
  {
    name: "Xã Hồng Lộc",
    code: 18412,
    division_type: "xã",
    codename: "xa_hong_loc",
    district_code: 448,
  },
  {
    name: "Xã Thịnh Lộc",
    code: 18421,
    division_type: "xã",
    codename: "xa_thinh_loc",
    district_code: 448,
  },
  {
    name: "Xã Bình An",
    code: 18430,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 448,
  },
  {
    name: "Xã Ích Hậu",
    code: 18457,
    division_type: "xã",
    codename: "xa_ich_hau",
    district_code: 448,
  },
  {
    name: "Xã Phù Lưu",
    code: 18493,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 448,
  },
  {
    name: "Thị trấn Lộc Hà",
    code: 18568,
    division_type: "thị trấn",
    codename: "thi_tran_loc_ha",
    district_code: 448,
  },
  {
    name: "Xã Thạch Mỹ",
    code: 18577,
    division_type: "xã",
    codename: "xa_thach_my",
    district_code: 448,
  },
  {
    name: "Xã Thạch Kim",
    code: 18580,
    division_type: "xã",
    codename: "xa_thach_kim",
    district_code: 448,
  },
  {
    name: "Xã Thạch Châu",
    code: 18583,
    division_type: "xã",
    codename: "xa_thach_chau",
    district_code: 448,
  },
  {
    name: "Xã Hộ Độ",
    code: 18598,
    division_type: "xã",
    codename: "xa_ho_do",
    district_code: 448,
  },
  {
    name: "Xã Mai Phụ",
    code: 18670,
    division_type: "xã",
    codename: "xa_mai_phu",
    district_code: 448,
  },
  {
    name: "Phường Hưng Trí",
    code: 18754,
    division_type: "phường",
    codename: "phuong_hung_tri",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Ninh",
    code: 18781,
    division_type: "xã",
    codename: "xa_ky_ninh",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Lợi",
    code: 18796,
    division_type: "xã",
    codename: "xa_ky_loi",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Hà",
    code: 18808,
    division_type: "xã",
    codename: "xa_ky_ha",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Trinh",
    code: 18820,
    division_type: "phường",
    codename: "phuong_ky_trinh",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Thịnh",
    code: 18823,
    division_type: "phường",
    codename: "phuong_ky_thinh",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Hoa",
    code: 18829,
    division_type: "xã",
    codename: "xa_ky_hoa",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Phương",
    code: 18832,
    division_type: "phường",
    codename: "phuong_ky_phuong",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Long",
    code: 18835,
    division_type: "phường",
    codename: "phuong_ky_long",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Liên",
    code: 18841,
    division_type: "phường",
    codename: "phuong_ky_lien",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Nam",
    code: 18847,
    division_type: "xã",
    codename: "xa_ky_nam",
    district_code: 449,
  },
  {
    name: "Phường Hải Thành",
    code: 18853,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 450,
  },
  {
    name: "Phường Đồng Phú",
    code: 18856,
    division_type: "phường",
    codename: "phuong_dong_phu",
    district_code: 450,
  },
  {
    name: "Phường Bắc Lý",
    code: 18859,
    division_type: "phường",
    codename: "phuong_bac_ly",
    district_code: 450,
  },
  {
    name: "Phường Nam Lý",
    code: 18865,
    division_type: "phường",
    codename: "phuong_nam_ly",
    district_code: 450,
  },
  {
    name: "Phường Đồng Hải",
    code: 18868,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 450,
  },
  {
    name: "Phường Đồng Sơn",
    code: 18871,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 450,
  },
  {
    name: "Phường Phú Hải",
    code: 18874,
    division_type: "phường",
    codename: "phuong_phu_hai",
    district_code: 450,
  },
  {
    name: "Phường Bắc Nghĩa",
    code: 18877,
    division_type: "phường",
    codename: "phuong_bac_nghia",
    district_code: 450,
  },
  {
    name: "Phường Đức Ninh Đông",
    code: 18880,
    division_type: "phường",
    codename: "phuong_duc_ninh_dong",
    district_code: 450,
  },
  {
    name: "Xã Quang Phú",
    code: 18883,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 450,
  },
  {
    name: "Xã Lộc Ninh",
    code: 18886,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 450,
  },
  {
    name: "Xã Bảo Ninh",
    code: 18889,
    division_type: "xã",
    codename: "xa_bao_ninh",
    district_code: 450,
  },
  {
    name: "Xã Nghĩa Ninh",
    code: 18892,
    division_type: "xã",
    codename: "xa_nghia_ninh",
    district_code: 450,
  },
  {
    name: "Xã Thuận Đức",
    code: 18895,
    division_type: "xã",
    codename: "xa_thuan_duc",
    district_code: 450,
  },
  {
    name: "Xã Đức Ninh",
    code: 18898,
    division_type: "xã",
    codename: "xa_duc_ninh",
    district_code: 450,
  },
  {
    name: "Thị trấn Quy Đạt",
    code: 18901,
    division_type: "thị trấn",
    codename: "thi_tran_quy_dat",
    district_code: 452,
  },
  {
    name: "Xã Dân Hóa",
    code: 18904,
    division_type: "xã",
    codename: "xa_dan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Trọng Hóa",
    code: 18907,
    division_type: "xã",
    codename: "xa_trong_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Phúc",
    code: 18910,
    division_type: "xã",
    codename: "xa_hoa_phuc",
    district_code: 452,
  },
  {
    name: "Xã Hồng Hóa",
    code: 18913,
    division_type: "xã",
    codename: "xa_hong_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Thanh",
    code: 18916,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 452,
  },
  {
    name: "Xã Hóa Tiến",
    code: 18919,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 452,
  },
  {
    name: "Xã Hóa Hợp",
    code: 18922,
    division_type: "xã",
    codename: "xa_hoa_hop",
    district_code: 452,
  },
  {
    name: "Xã Xuân Hóa",
    code: 18925,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Yên Hóa",
    code: 18928,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 452,
  },
  {
    name: "Xã Minh Hóa",
    code: 18931,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 452,
  },
  {
    name: "Xã Tân Hóa",
    code: 18934,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Sơn",
    code: 18937,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 452,
  },
  {
    name: "Xã Trung Hóa",
    code: 18943,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 452,
  },
  {
    name: "Xã Thượng Hóa",
    code: 18946,
    division_type: "xã",
    codename: "xa_thuong_hoa",
    district_code: 452,
  },
  {
    name: "Thị trấn Đồng Lê",
    code: 18949,
    division_type: "thị trấn",
    codename: "thi_tran_dong_le",
    district_code: 453,
  },
  {
    name: "Xã Hương Hóa",
    code: 18952,
    division_type: "xã",
    codename: "xa_huong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Kim Hóa",
    code: 18955,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thanh Hóa",
    code: 18958,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thanh Thạch",
    code: 18961,
    division_type: "xã",
    codename: "xa_thanh_thach",
    district_code: 453,
  },
  {
    name: "Xã Thuận Hóa",
    code: 18964,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 453,
  },
  {
    name: "Xã Lâm Hóa",
    code: 18967,
    division_type: "xã",
    codename: "xa_lam_hoa",
    district_code: 453,
  },
  {
    name: "Xã Lê Hóa",
    code: 18970,
    division_type: "xã",
    codename: "xa_le_hoa",
    district_code: 453,
  },
  {
    name: "Xã Sơn Hóa",
    code: 18973,
    division_type: "xã",
    codename: "xa_son_hoa",
    district_code: 453,
  },
  {
    name: "Xã Đồng Hóa",
    code: 18976,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Ngư Hóa",
    code: 18979,
    division_type: "xã",
    codename: "xa_ngu_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thạch Hóa",
    code: 18985,
    division_type: "xã",
    codename: "xa_thach_hoa",
    district_code: 453,
  },
  {
    name: "Xã Đức Hóa",
    code: 18988,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 453,
  },
  {
    name: "Xã Phong Hóa",
    code: 18991,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Mai Hóa",
    code: 18994,
    division_type: "xã",
    codename: "xa_mai_hoa",
    district_code: 453,
  },
  {
    name: "Xã Tiến Hóa",
    code: 18997,
    division_type: "xã",
    codename: "xa_tien_hoa",
    district_code: 453,
  },
  {
    name: "Xã Châu Hóa",
    code: 19000,
    division_type: "xã",
    codename: "xa_chau_hoa",
    district_code: 453,
  },
  {
    name: "Xã Cao Quảng",
    code: 19003,
    division_type: "xã",
    codename: "xa_cao_quang",
    district_code: 453,
  },
  {
    name: "Xã Văn Hóa",
    code: 19006,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 453,
  },
  {
    name: "Xã Quảng Hợp",
    code: 19012,
    division_type: "xã",
    codename: "xa_quang_hop",
    district_code: 454,
  },
  {
    name: "Xã Quảng Kim",
    code: 19015,
    division_type: "xã",
    codename: "xa_quang_kim",
    district_code: 454,
  },
  {
    name: "Xã Quảng Đông",
    code: 19018,
    division_type: "xã",
    codename: "xa_quang_dong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Phú",
    code: 19021,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 454,
  },
  {
    name: "Xã Quảng Châu",
    code: 19024,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 454,
  },
  {
    name: "Xã Quảng Thạch",
    code: 19027,
    division_type: "xã",
    codename: "xa_quang_thach",
    district_code: 454,
  },
  {
    name: "Xã Quảng Lưu",
    code: 19030,
    division_type: "xã",
    codename: "xa_quang_luu",
    district_code: 454,
  },
  {
    name: "Xã Quảng Tùng",
    code: 19033,
    division_type: "xã",
    codename: "xa_quang_tung",
    district_code: 454,
  },
  {
    name: "Xã Cảnh Dương",
    code: 19036,
    division_type: "xã",
    codename: "xa_canh_duong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Tiến",
    code: 19039,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 454,
  },
  {
    name: "Xã Quảng Hưng",
    code: 19042,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 454,
  },
  {
    name: "Xã Quảng Xuân",
    code: 19045,
    division_type: "xã",
    codename: "xa_quang_xuan",
    district_code: 454,
  },
  {
    name: "Xã Cảnh Hóa",
    code: 19048,
    division_type: "xã",
    codename: "xa_canh_hoa",
    district_code: 454,
  },
  {
    name: "Xã Liên Trường",
    code: 19051,
    division_type: "xã",
    codename: "xa_lien_truong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Phương",
    code: 19057,
    division_type: "xã",
    codename: "xa_quang_phuong",
    district_code: 454,
  },
  {
    name: "Xã Phù Hóa",
    code: 19063,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 454,
  },
  {
    name: "Xã Quảng Thanh",
    code: 19072,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 454,
  },
  {
    name: "Thị trấn Hoàn Lão",
    code: 19111,
    division_type: "thị trấn",
    codename: "thi_tran_hoan_lao",
    district_code: 455,
  },
  {
    name: "Thị trấn NT Việt Trung",
    code: 19114,
    division_type: "thị trấn",
    codename: "thi_tran_nt_viet_trung",
    district_code: 455,
  },
  {
    name: "Xã Xuân Trạch",
    code: 19117,
    division_type: "xã",
    codename: "xa_xuan_trach",
    district_code: 455,
  },
  {
    name: "Xã Mỹ Trạch",
    code: 19120,
    division_type: "xã",
    codename: "xa_my_trach",
    district_code: 455,
  },
  {
    name: "Xã Hạ Trạch",
    code: 19123,
    division_type: "xã",
    codename: "xa_ha_trach",
    district_code: 455,
  },
  {
    name: "Xã Bắc Trạch",
    code: 19126,
    division_type: "xã",
    codename: "xa_bac_trach",
    district_code: 455,
  },
  {
    name: "Xã Lâm Trạch",
    code: 19129,
    division_type: "xã",
    codename: "xa_lam_trach",
    district_code: 455,
  },
  {
    name: "Xã Thanh Trạch",
    code: 19132,
    division_type: "xã",
    codename: "xa_thanh_trach",
    district_code: 455,
  },
  {
    name: "Xã Liên Trạch",
    code: 19135,
    division_type: "xã",
    codename: "xa_lien_trach",
    district_code: 455,
  },
  {
    name: "Xã Phúc Trạch",
    code: 19138,
    division_type: "xã",
    codename: "xa_phuc_trach",
    district_code: 455,
  },
  {
    name: "Xã Cự Nẫm",
    code: 19141,
    division_type: "xã",
    codename: "xa_cu_nam",
    district_code: 455,
  },
  {
    name: "Xã Hải Phú",
    code: 19144,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 455,
  },
  {
    name: "Xã Thượng Trạch",
    code: 19147,
    division_type: "xã",
    codename: "xa_thuong_trach",
    district_code: 455,
  },
  {
    name: "Xã Sơn Lộc",
    code: 19150,
    division_type: "xã",
    codename: "xa_son_loc",
    district_code: 455,
  },
  {
    name: "Xã Hưng Trạch",
    code: 19156,
    division_type: "xã",
    codename: "xa_hung_trach",
    district_code: 455,
  },
  {
    name: "Xã Đồng Trạch",
    code: 19159,
    division_type: "xã",
    codename: "xa_dong_trach",
    district_code: 455,
  },
  {
    name: "Xã Đức Trạch",
    code: 19162,
    division_type: "xã",
    codename: "xa_duc_trach",
    district_code: 455,
  },
  {
    name: "Thị trấn Phong Nha",
    code: 19165,
    division_type: "thị trấn",
    codename: "thi_tran_phong_nha",
    district_code: 455,
  },
  {
    name: "Xã Vạn Trạch",
    code: 19168,
    division_type: "xã",
    codename: "xa_van_trach",
    district_code: 455,
  },
  {
    name: "Xã Phú Định",
    code: 19174,
    division_type: "xã",
    codename: "xa_phu_dinh",
    district_code: 455,
  },
  {
    name: "Xã Trung Trạch",
    code: 19177,
    division_type: "xã",
    codename: "xa_trung_trach",
    district_code: 455,
  },
  {
    name: "Xã Tây Trạch",
    code: 19180,
    division_type: "xã",
    codename: "xa_tay_trach",
    district_code: 455,
  },
  {
    name: "Xã Hòa Trạch",
    code: 19183,
    division_type: "xã",
    codename: "xa_hoa_trach",
    district_code: 455,
  },
  {
    name: "Xã Đại Trạch",
    code: 19186,
    division_type: "xã",
    codename: "xa_dai_trach",
    district_code: 455,
  },
  {
    name: "Xã Nhân Trạch",
    code: 19189,
    division_type: "xã",
    codename: "xa_nhan_trach",
    district_code: 455,
  },
  {
    name: "Xã Tân Trạch",
    code: 19192,
    division_type: "xã",
    codename: "xa_tan_trach",
    district_code: 455,
  },
  {
    name: "Xã Nam Trạch",
    code: 19195,
    division_type: "xã",
    codename: "xa_nam_trach",
    district_code: 455,
  },
  {
    name: "Xã Lý Trạch",
    code: 19198,
    division_type: "xã",
    codename: "xa_ly_trach",
    district_code: 455,
  },
  {
    name: "Thị trấn Quán Hàu",
    code: 19201,
    division_type: "thị trấn",
    codename: "thi_tran_quan_hau",
    district_code: 456,
  },
  {
    name: "Xã Trường Sơn",
    code: 19204,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 456,
  },
  {
    name: "Xã Lương Ninh",
    code: 19207,
    division_type: "xã",
    codename: "xa_luong_ninh",
    district_code: 456,
  },
  {
    name: "Xã Vĩnh Ninh",
    code: 19210,
    division_type: "xã",
    codename: "xa_vinh_ninh",
    district_code: 456,
  },
  {
    name: "Xã Võ Ninh",
    code: 19213,
    division_type: "xã",
    codename: "xa_vo_ninh",
    district_code: 456,
  },
  {
    name: "Xã Hải Ninh",
    code: 19216,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 456,
  },
  {
    name: "Xã Hàm Ninh",
    code: 19219,
    division_type: "xã",
    codename: "xa_ham_ninh",
    district_code: 456,
  },
  {
    name: "Xã Duy Ninh",
    code: 19222,
    division_type: "xã",
    codename: "xa_duy_ninh",
    district_code: 456,
  },
  {
    name: "Xã Gia Ninh",
    code: 19225,
    division_type: "xã",
    codename: "xa_gia_ninh",
    district_code: 456,
  },
  {
    name: "Xã Trường Xuân",
    code: 19228,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 456,
  },
  {
    name: "Xã Hiền Ninh",
    code: 19231,
    division_type: "xã",
    codename: "xa_hien_ninh",
    district_code: 456,
  },
  {
    name: "Xã Tân Ninh",
    code: 19234,
    division_type: "xã",
    codename: "xa_tan_ninh",
    district_code: 456,
  },
  {
    name: "Xã Xuân Ninh",
    code: 19237,
    division_type: "xã",
    codename: "xa_xuan_ninh",
    district_code: 456,
  },
  {
    name: "Xã An Ninh",
    code: 19240,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 456,
  },
  {
    name: "Xã Vạn Ninh",
    code: 19243,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 456,
  },
  {
    name: "Thị trấn NT Lệ Ninh",
    code: 19246,
    division_type: "thị trấn",
    codename: "thi_tran_nt_le_ninh",
    district_code: 457,
  },
  {
    name: "Thị trấn Kiến Giang",
    code: 19249,
    division_type: "thị trấn",
    codename: "thi_tran_kien_giang",
    district_code: 457,
  },
  {
    name: "Xã Hồng Thủy",
    code: 19252,
    division_type: "xã",
    codename: "xa_hong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngư Thủy Bắc",
    code: 19255,
    division_type: "xã",
    codename: "xa_ngu_thuy_bac",
    district_code: 457,
  },
  {
    name: "Xã Hoa Thủy",
    code: 19258,
    division_type: "xã",
    codename: "xa_hoa_thuy",
    district_code: 457,
  },
  {
    name: "Xã Thanh Thủy",
    code: 19261,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 457,
  },
  {
    name: "Xã An Thủy",
    code: 19264,
    division_type: "xã",
    codename: "xa_an_thuy",
    district_code: 457,
  },
  {
    name: "Xã Phong Thủy",
    code: 19267,
    division_type: "xã",
    codename: "xa_phong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Cam Thủy",
    code: 19270,
    division_type: "xã",
    codename: "xa_cam_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngân Thủy",
    code: 19273,
    division_type: "xã",
    codename: "xa_ngan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Sơn Thủy",
    code: 19276,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 457,
  },
  {
    name: "Xã Lộc Thủy",
    code: 19279,
    division_type: "xã",
    codename: "xa_loc_thuy",
    district_code: 457,
  },
  {
    name: "Xã Liên Thủy",
    code: 19285,
    division_type: "xã",
    codename: "xa_lien_thuy",
    district_code: 457,
  },
  {
    name: "Xã Hưng Thủy",
    code: 19288,
    division_type: "xã",
    codename: "xa_hung_thuy",
    district_code: 457,
  },
  {
    name: "Xã Dương Thủy",
    code: 19291,
    division_type: "xã",
    codename: "xa_duong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Tân Thủy",
    code: 19294,
    division_type: "xã",
    codename: "xa_tan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Phú Thủy",
    code: 19297,
    division_type: "xã",
    codename: "xa_phu_thuy",
    district_code: 457,
  },
  {
    name: "Xã Xuân Thủy",
    code: 19300,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Mỹ Thủy",
    code: 19303,
    division_type: "xã",
    codename: "xa_my_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngư Thủy",
    code: 19306,
    division_type: "xã",
    codename: "xa_ngu_thuy",
    district_code: 457,
  },
  {
    name: "Xã Mai Thủy",
    code: 19309,
    division_type: "xã",
    codename: "xa_mai_thuy",
    district_code: 457,
  },
  {
    name: "Xã Sen Thủy",
    code: 19312,
    division_type: "xã",
    codename: "xa_sen_thuy",
    district_code: 457,
  },
  {
    name: "Xã Thái Thủy",
    code: 19315,
    division_type: "xã",
    codename: "xa_thai_thuy",
    district_code: 457,
  },
  {
    name: "Xã Kim Thủy",
    code: 19318,
    division_type: "xã",
    codename: "xa_kim_thuy",
    district_code: 457,
  },
  {
    name: "Xã Trường Thủy",
    code: 19321,
    division_type: "xã",
    codename: "xa_truong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Lâm Thủy",
    code: 19327,
    division_type: "xã",
    codename: "xa_lam_thuy",
    district_code: 457,
  },
  {
    name: "Phường Ba Đồn",
    code: 19009,
    division_type: "phường",
    codename: "phuong_ba_don",
    district_code: 458,
  },
  {
    name: "Phường Quảng Long",
    code: 19060,
    division_type: "phường",
    codename: "phuong_quang_long",
    district_code: 458,
  },
  {
    name: "Phường Quảng Thọ",
    code: 19066,
    division_type: "phường",
    codename: "phuong_quang_tho",
    district_code: 458,
  },
  {
    name: "Xã Quảng Tiên",
    code: 19069,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 458,
  },
  {
    name: "Xã Quảng Trung",
    code: 19075,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 458,
  },
  {
    name: "Phường Quảng Phong",
    code: 19078,
    division_type: "phường",
    codename: "phuong_quang_phong",
    district_code: 458,
  },
  {
    name: "Phường Quảng Thuận",
    code: 19081,
    division_type: "phường",
    codename: "phuong_quang_thuan",
    district_code: 458,
  },
  {
    name: "Xã Quảng Tân",
    code: 19084,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 458,
  },
  {
    name: "Xã Quảng Hải",
    code: 19087,
    division_type: "xã",
    codename: "xa_quang_hai",
    district_code: 458,
  },
  {
    name: "Xã Quảng Sơn",
    code: 19090,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 458,
  },
  {
    name: "Xã Quảng Lộc",
    code: 19093,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 458,
  },
  {
    name: "Xã Quảng Thủy",
    code: 19096,
    division_type: "xã",
    codename: "xa_quang_thuy",
    district_code: 458,
  },
  {
    name: "Xã Quảng Văn",
    code: 19099,
    division_type: "xã",
    codename: "xa_quang_van",
    district_code: 458,
  },
  {
    name: "Phường Quảng Phúc",
    code: 19102,
    division_type: "phường",
    codename: "phuong_quang_phuc",
    district_code: 458,
  },
  {
    name: "Xã Quảng Hòa",
    code: 19105,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 458,
  },
  {
    name: "Xã Quảng Minh",
    code: 19108,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 458,
  },
  {
    name: "Phường Đông Giang",
    code: 19330,
    division_type: "phường",
    codename: "phuong_dong_giang",
    district_code: 461,
  },
  {
    name: "Phường 1",
    code: 19333,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 461,
  },
  {
    name: "Phường Đông Lễ",
    code: 19336,
    division_type: "phường",
    codename: "phuong_dong_le",
    district_code: 461,
  },
  {
    name: "Phường Đông Thanh",
    code: 19339,
    division_type: "phường",
    codename: "phuong_dong_thanh",
    district_code: 461,
  },
  {
    name: "Phường 2",
    code: 19342,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 461,
  },
  {
    name: "Phường 4",
    code: 19345,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 461,
  },
  {
    name: "Phường 5",
    code: 19348,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 461,
  },
  {
    name: "Phường Đông Lương",
    code: 19351,
    division_type: "phường",
    codename: "phuong_dong_luong",
    district_code: 461,
  },
  {
    name: "Phường 3",
    code: 19354,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 461,
  },
  {
    name: "Phường 1",
    code: 19357,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 462,
  },
  {
    name: "Phường An Đôn",
    code: 19358,
    division_type: "phường",
    codename: "phuong_an_don",
    district_code: 462,
  },
  {
    name: "Phường 2",
    code: 19360,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 462,
  },
  {
    name: "Phường 3",
    code: 19361,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 462,
  },
  {
    name: "Xã Hải Lệ",
    code: 19705,
    division_type: "xã",
    codename: "xa_hai_le",
    district_code: 462,
  },
  {
    name: "Thị trấn Hồ Xá",
    code: 19363,
    division_type: "thị trấn",
    codename: "thi_tran_ho_xa",
    district_code: 464,
  },
  {
    name: "Thị trấn Bến Quan",
    code: 19366,
    division_type: "thị trấn",
    codename: "thi_tran_ben_quan",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Thái",
    code: 19369,
    division_type: "xã",
    codename: "xa_vinh_thai",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Tú",
    code: 19372,
    division_type: "xã",
    codename: "xa_vinh_tu",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Chấp",
    code: 19375,
    division_type: "xã",
    codename: "xa_vinh_chap",
    district_code: 464,
  },
  {
    name: "Xã Trung Nam",
    code: 19378,
    division_type: "xã",
    codename: "xa_trung_nam",
    district_code: 464,
  },
  {
    name: "Xã Kim Thạch",
    code: 19384,
    division_type: "xã",
    codename: "xa_kim_thach",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Long",
    code: 19387,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Khê",
    code: 19393,
    division_type: "xã",
    codename: "xa_vinh_khe",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 19396,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Thủy",
    code: 19402,
    division_type: "xã",
    codename: "xa_vinh_thuy",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Lâm",
    code: 19405,
    division_type: "xã",
    codename: "xa_vinh_lam",
    district_code: 464,
  },
  {
    name: "Xã Hiền Thành",
    code: 19408,
    division_type: "xã",
    codename: "xa_hien_thanh",
    district_code: 464,
  },
  {
    name: "Thị trấn Cửa Tùng",
    code: 19414,
    division_type: "thị trấn",
    codename: "thi_tran_cua_tung",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Hà",
    code: 19417,
    division_type: "xã",
    codename: "xa_vinh_ha",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 19420,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Giang",
    code: 19423,
    division_type: "xã",
    codename: "xa_vinh_giang",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Ô",
    code: 19426,
    division_type: "xã",
    codename: "xa_vinh_o",
    district_code: 464,
  },
  {
    name: "Thị trấn Khe Sanh",
    code: 19429,
    division_type: "thị trấn",
    codename: "thi_tran_khe_sanh",
    district_code: 465,
  },
  {
    name: "Thị trấn Lao Bảo",
    code: 19432,
    division_type: "thị trấn",
    codename: "thi_tran_lao_bao",
    district_code: 465,
  },
  {
    name: "Xã Hướng Lập",
    code: 19435,
    division_type: "xã",
    codename: "xa_huong_lap",
    district_code: 465,
  },
  {
    name: "Xã Hướng Việt",
    code: 19438,
    division_type: "xã",
    codename: "xa_huong_viet",
    district_code: 465,
  },
  {
    name: "Xã Hướng Phùng",
    code: 19441,
    division_type: "xã",
    codename: "xa_huong_phung",
    district_code: 465,
  },
  {
    name: "Xã Hướng Sơn",
    code: 19444,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 465,
  },
  {
    name: "Xã Hướng Linh",
    code: 19447,
    division_type: "xã",
    codename: "xa_huong_linh",
    district_code: 465,
  },
  {
    name: "Xã Tân Hợp",
    code: 19450,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 465,
  },
  {
    name: "Xã Hướng Tân",
    code: 19453,
    division_type: "xã",
    codename: "xa_huong_tan",
    district_code: 465,
  },
  {
    name: "Xã Tân Thành",
    code: 19456,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 465,
  },
  {
    name: "Xã Tân Long",
    code: 19459,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 465,
  },
  {
    name: "Xã Tân Lập",
    code: 19462,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 465,
  },
  {
    name: "Xã Tân Liên",
    code: 19465,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 465,
  },
  {
    name: "Xã Húc",
    code: 19468,
    division_type: "xã",
    codename: "xa_huc",
    district_code: 465,
  },
  {
    name: "Xã Thuận",
    code: 19471,
    division_type: "xã",
    codename: "xa_thuan",
    district_code: 465,
  },
  {
    name: "Xã Hướng Lộc",
    code: 19474,
    division_type: "xã",
    codename: "xa_huong_loc",
    district_code: 465,
  },
  {
    name: "Xã Ba Tầng",
    code: 19477,
    division_type: "xã",
    codename: "xa_ba_tang",
    district_code: 465,
  },
  {
    name: "Xã Thanh",
    code: 19480,
    division_type: "xã",
    codename: "xa_thanh",
    district_code: 465,
  },
  {
    name: "Xã A Dơi",
    code: 19483,
    division_type: "xã",
    codename: "xa_a_doi",
    district_code: 465,
  },
  {
    name: "Xã Lìa",
    code: 19489,
    division_type: "xã",
    codename: "xa_lia",
    district_code: 465,
  },
  {
    name: "Xã Xy",
    code: 19492,
    division_type: "xã",
    codename: "xa_xy",
    district_code: 465,
  },
  {
    name: "Thị trấn Gio Linh",
    code: 19495,
    division_type: "thị trấn",
    codename: "thi_tran_gio_linh",
    district_code: 466,
  },
  {
    name: "Thị trấn Cửa Việt",
    code: 19496,
    division_type: "thị trấn",
    codename: "thi_tran_cua_viet",
    district_code: 466,
  },
  {
    name: "Xã Trung Giang",
    code: 19498,
    division_type: "xã",
    codename: "xa_trung_giang",
    district_code: 466,
  },
  {
    name: "Xã Trung Hải",
    code: 19501,
    division_type: "xã",
    codename: "xa_trung_hai",
    district_code: 466,
  },
  {
    name: "Xã Trung Sơn",
    code: 19504,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 466,
  },
  {
    name: "Xã Phong Bình",
    code: 19507,
    division_type: "xã",
    codename: "xa_phong_binh",
    district_code: 466,
  },
  {
    name: "Xã Gio Mỹ",
    code: 19510,
    division_type: "xã",
    codename: "xa_gio_my",
    district_code: 466,
  },
  {
    name: "Xã Gio Hải",
    code: 19519,
    division_type: "xã",
    codename: "xa_gio_hai",
    district_code: 466,
  },
  {
    name: "Xã Gio An",
    code: 19522,
    division_type: "xã",
    codename: "xa_gio_an",
    district_code: 466,
  },
  {
    name: "Xã Gio Châu",
    code: 19525,
    division_type: "xã",
    codename: "xa_gio_chau",
    district_code: 466,
  },
  {
    name: "Xã Gio Việt",
    code: 19531,
    division_type: "xã",
    codename: "xa_gio_viet",
    district_code: 466,
  },
  {
    name: "Xã Linh Trường",
    code: 19534,
    division_type: "xã",
    codename: "xa_linh_truong",
    district_code: 466,
  },
  {
    name: "Xã Gio Sơn",
    code: 19537,
    division_type: "xã",
    codename: "xa_gio_son",
    district_code: 466,
  },
  {
    name: "Xã Gio Mai",
    code: 19543,
    division_type: "xã",
    codename: "xa_gio_mai",
    district_code: 466,
  },
  {
    name: "Xã Hải Thái",
    code: 19546,
    division_type: "xã",
    codename: "xa_hai_thai",
    district_code: 466,
  },
  {
    name: "Xã Linh Hải",
    code: 19549,
    division_type: "xã",
    codename: "xa_linh_hai",
    district_code: 466,
  },
  {
    name: "Xã Gio Quang",
    code: 19552,
    division_type: "xã",
    codename: "xa_gio_quang",
    district_code: 466,
  },
  {
    name: "Thị trấn Krông Klang",
    code: 19555,
    division_type: "thị trấn",
    codename: "thi_tran_krong_klang",
    district_code: 467,
  },
  {
    name: "Xã Mò Ó",
    code: 19558,
    division_type: "xã",
    codename: "xa_mo_o",
    district_code: 467,
  },
  {
    name: "Xã Hướng Hiệp",
    code: 19561,
    division_type: "xã",
    codename: "xa_huong_hiep",
    district_code: 467,
  },
  {
    name: "Xã Đa Krông",
    code: 19564,
    division_type: "xã",
    codename: "xa_da_krong",
    district_code: 467,
  },
  {
    name: "Xã Triệu Nguyên",
    code: 19567,
    division_type: "xã",
    codename: "xa_trieu_nguyen",
    district_code: 467,
  },
  {
    name: "Xã Ba Lòng",
    code: 19570,
    division_type: "xã",
    codename: "xa_ba_long",
    district_code: 467,
  },
  {
    name: "Xã Ba Nang",
    code: 19576,
    division_type: "xã",
    codename: "xa_ba_nang",
    district_code: 467,
  },
  {
    name: "Xã Tà Long",
    code: 19579,
    division_type: "xã",
    codename: "xa_ta_long",
    district_code: 467,
  },
  {
    name: "Xã Húc Nghì",
    code: 19582,
    division_type: "xã",
    codename: "xa_huc_nghi",
    district_code: 467,
  },
  {
    name: "Xã A Vao",
    code: 19585,
    division_type: "xã",
    codename: "xa_a_vao",
    district_code: 467,
  },
  {
    name: "Xã Tà Rụt",
    code: 19588,
    division_type: "xã",
    codename: "xa_ta_rut",
    district_code: 467,
  },
  {
    name: "Xã A Bung",
    code: 19591,
    division_type: "xã",
    codename: "xa_a_bung",
    district_code: 467,
  },
  {
    name: "Xã A Ngo",
    code: 19594,
    division_type: "xã",
    codename: "xa_a_ngo",
    district_code: 467,
  },
  {
    name: "Thị trấn Cam Lộ",
    code: 19597,
    division_type: "thị trấn",
    codename: "thi_tran_cam_lo",
    district_code: 468,
  },
  {
    name: "Xã Cam Tuyền",
    code: 19600,
    division_type: "xã",
    codename: "xa_cam_tuyen",
    district_code: 468,
  },
  {
    name: "Xã Thanh An",
    code: 19603,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 468,
  },
  {
    name: "Xã Cam Thủy",
    code: 19606,
    division_type: "xã",
    codename: "xa_cam_thuy",
    district_code: 468,
  },
  {
    name: "Xã Cam Thành",
    code: 19612,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 468,
  },
  {
    name: "Xã Cam Hiếu",
    code: 19615,
    division_type: "xã",
    codename: "xa_cam_hieu",
    district_code: 468,
  },
  {
    name: "Xã Cam Chính",
    code: 19618,
    division_type: "xã",
    codename: "xa_cam_chinh",
    district_code: 468,
  },
  {
    name: "Xã Cam Nghĩa",
    code: 19621,
    division_type: "xã",
    codename: "xa_cam_nghia",
    district_code: 468,
  },
  {
    name: "Thị trấn Ái Tử",
    code: 19624,
    division_type: "thị trấn",
    codename: "thi_tran_ai_tu",
    district_code: 469,
  },
  {
    name: "Xã Triệu An",
    code: 19627,
    division_type: "xã",
    codename: "xa_trieu_an",
    district_code: 469,
  },
  {
    name: "Xã Triệu Vân",
    code: 19630,
    division_type: "xã",
    codename: "xa_trieu_van",
    district_code: 469,
  },
  {
    name: "Xã Triệu Phước",
    code: 19633,
    division_type: "xã",
    codename: "xa_trieu_phuoc",
    district_code: 469,
  },
  {
    name: "Xã Triệu Độ",
    code: 19636,
    division_type: "xã",
    codename: "xa_trieu_do",
    district_code: 469,
  },
  {
    name: "Xã Triệu Trạch",
    code: 19639,
    division_type: "xã",
    codename: "xa_trieu_trach",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thuận",
    code: 19642,
    division_type: "xã",
    codename: "xa_trieu_thuan",
    district_code: 469,
  },
  {
    name: "Xã Triệu Đại",
    code: 19645,
    division_type: "xã",
    codename: "xa_trieu_dai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Hòa",
    code: 19648,
    division_type: "xã",
    codename: "xa_trieu_hoa",
    district_code: 469,
  },
  {
    name: "Xã Triệu Lăng",
    code: 19651,
    division_type: "xã",
    codename: "xa_trieu_lang",
    district_code: 469,
  },
  {
    name: "Xã Triệu Sơn",
    code: 19654,
    division_type: "xã",
    codename: "xa_trieu_son",
    district_code: 469,
  },
  {
    name: "Xã Triệu Long",
    code: 19657,
    division_type: "xã",
    codename: "xa_trieu_long",
    district_code: 469,
  },
  {
    name: "Xã Triệu Tài",
    code: 19660,
    division_type: "xã",
    codename: "xa_trieu_tai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Trung",
    code: 19666,
    division_type: "xã",
    codename: "xa_trieu_trung",
    district_code: 469,
  },
  {
    name: "Xã Triệu Ái",
    code: 19669,
    division_type: "xã",
    codename: "xa_trieu_ai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thượng",
    code: 19672,
    division_type: "xã",
    codename: "xa_trieu_thuong",
    district_code: 469,
  },
  {
    name: "Xã Triệu Giang",
    code: 19675,
    division_type: "xã",
    codename: "xa_trieu_giang",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thành",
    code: 19678,
    division_type: "xã",
    codename: "xa_trieu_thanh",
    district_code: 469,
  },
  {
    name: "Thị trấn Diên Sanh",
    code: 19681,
    division_type: "thị trấn",
    codename: "thi_tran_dien_sanh",
    district_code: 470,
  },
  {
    name: "Xã Hải An",
    code: 19684,
    division_type: "xã",
    codename: "xa_hai_an",
    district_code: 470,
  },
  {
    name: "Xã Hải Ba",
    code: 19687,
    division_type: "xã",
    codename: "xa_hai_ba",
    district_code: 470,
  },
  {
    name: "Xã Hải Quy",
    code: 19693,
    division_type: "xã",
    codename: "xa_hai_quy",
    district_code: 470,
  },
  {
    name: "Xã Hải Quế",
    code: 19696,
    division_type: "xã",
    codename: "xa_hai_que",
    district_code: 470,
  },
  {
    name: "Xã Hải Hưng",
    code: 19699,
    division_type: "xã",
    codename: "xa_hai_hung",
    district_code: 470,
  },
  {
    name: "Xã Hải Phú",
    code: 19702,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 470,
  },
  {
    name: "Xã Hải Thượng",
    code: 19708,
    division_type: "xã",
    codename: "xa_hai_thuong",
    district_code: 470,
  },
  {
    name: "Xã Hải Dương",
    code: 19711,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 470,
  },
  {
    name: "Xã Hải Định",
    code: 19714,
    division_type: "xã",
    codename: "xa_hai_dinh",
    district_code: 470,
  },
  {
    name: "Xã Hải Lâm",
    code: 19717,
    division_type: "xã",
    codename: "xa_hai_lam",
    district_code: 470,
  },
  {
    name: "Xã Hải Phong",
    code: 19726,
    division_type: "xã",
    codename: "xa_hai_phong",
    district_code: 470,
  },
  {
    name: "Xã Hải Trường",
    code: 19729,
    division_type: "xã",
    codename: "xa_hai_truong",
    district_code: 470,
  },
  {
    name: "Xã Hải Sơn",
    code: 19735,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 470,
  },
  {
    name: "Xã Hải Chánh",
    code: 19738,
    division_type: "xã",
    codename: "xa_hai_chanh",
    district_code: 470,
  },
  {
    name: "Xã Hải Khê",
    code: 19741,
    division_type: "xã",
    codename: "xa_hai_khe",
    district_code: 470,
  },
  {
    name: "Phường Phú Thuận",
    code: 19744,
    division_type: "phường",
    codename: "phuong_phu_thuan",
    district_code: 474,
  },
  {
    name: "Phường Phú Bình",
    code: 19747,
    division_type: "phường",
    codename: "phuong_phu_binh",
    district_code: 474,
  },
  {
    name: "Phường Tây Lộc",
    code: 19750,
    division_type: "phường",
    codename: "phuong_tay_loc",
    district_code: 474,
  },
  {
    name: "Phường Thuận Lộc",
    code: 19753,
    division_type: "phường",
    codename: "phuong_thuan_loc",
    district_code: 474,
  },
  {
    name: "Phường Phú Hiệp",
    code: 19756,
    division_type: "phường",
    codename: "phuong_phu_hiep",
    district_code: 474,
  },
  {
    name: "Phường Phú Hậu",
    code: 19759,
    division_type: "phường",
    codename: "phuong_phu_hau",
    district_code: 474,
  },
  {
    name: "Phường Thuận Hòa",
    code: 19762,
    division_type: "phường",
    codename: "phuong_thuan_hoa",
    district_code: 474,
  },
  {
    name: "Phường Thuận Thành",
    code: 19765,
    division_type: "phường",
    codename: "phuong_thuan_thanh",
    district_code: 474,
  },
  {
    name: "Phường Phú Hòa",
    code: 19768,
    division_type: "phường",
    codename: "phuong_phu_hoa",
    district_code: 474,
  },
  {
    name: "Phường Phú Cát",
    code: 19771,
    division_type: "phường",
    codename: "phuong_phu_cat",
    district_code: 474,
  },
  {
    name: "Phường Kim Long",
    code: 19774,
    division_type: "phường",
    codename: "phuong_kim_long",
    district_code: 474,
  },
  {
    name: "Phường Vỹ Dạ",
    code: 19777,
    division_type: "phường",
    codename: "phuong_vy_da",
    district_code: 474,
  },
  {
    name: "Phường Phường Đúc",
    code: 19780,
    division_type: "phường",
    codename: "phuong_phuong_duc",
    district_code: 474,
  },
  {
    name: "Phường Vĩnh Ninh",
    code: 19783,
    division_type: "phường",
    codename: "phuong_vinh_ninh",
    district_code: 474,
  },
  {
    name: "Phường Phú Hội",
    code: 19786,
    division_type: "phường",
    codename: "phuong_phu_hoi",
    district_code: 474,
  },
  {
    name: "Phường Phú Nhuận",
    code: 19789,
    division_type: "phường",
    codename: "phuong_phu_nhuan",
    district_code: 474,
  },
  {
    name: "Phường Xuân Phú",
    code: 19792,
    division_type: "phường",
    codename: "phuong_xuan_phu",
    district_code: 474,
  },
  {
    name: "Phường Trường An",
    code: 19795,
    division_type: "phường",
    codename: "phuong_truong_an",
    district_code: 474,
  },
  {
    name: "Phường Phước Vĩnh",
    code: 19798,
    division_type: "phường",
    codename: "phuong_phuoc_vinh",
    district_code: 474,
  },
  {
    name: "Phường An Cựu",
    code: 19801,
    division_type: "phường",
    codename: "phuong_an_cuu",
    district_code: 474,
  },
  {
    name: "Phường An Hòa",
    code: 19803,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 474,
  },
  {
    name: "Phường Hương Sơ",
    code: 19804,
    division_type: "phường",
    codename: "phuong_huong_so",
    district_code: 474,
  },
  {
    name: "Phường Thuỷ Biều",
    code: 19807,
    division_type: "phường",
    codename: "phuong_thuy_bieu",
    district_code: 474,
  },
  {
    name: "Phường Hương Long",
    code: 19810,
    division_type: "phường",
    codename: "phuong_huong_long",
    district_code: 474,
  },
  {
    name: "Phường Thuỷ Xuân",
    code: 19813,
    division_type: "phường",
    codename: "phuong_thuy_xuan",
    district_code: 474,
  },
  {
    name: "Phường An Đông",
    code: 19815,
    division_type: "phường",
    codename: "phuong_an_dong",
    district_code: 474,
  },
  {
    name: "Phường An Tây",
    code: 19816,
    division_type: "phường",
    codename: "phuong_an_tay",
    district_code: 474,
  },
  {
    name: "Thị trấn Phong Điền",
    code: 19819,
    division_type: "thị trấn",
    codename: "thi_tran_phong_dien",
    district_code: 476,
  },
  {
    name: "Xã Điền Hương",
    code: 19822,
    division_type: "xã",
    codename: "xa_dien_huong",
    district_code: 476,
  },
  {
    name: "Xã Điền Môn",
    code: 19825,
    division_type: "xã",
    codename: "xa_dien_mon",
    district_code: 476,
  },
  {
    name: "Xã Điền Lộc",
    code: 19828,
    division_type: "xã",
    codename: "xa_dien_loc",
    district_code: 476,
  },
  {
    name: "Xã Phong Bình",
    code: 19831,
    division_type: "xã",
    codename: "xa_phong_binh",
    district_code: 476,
  },
  {
    name: "Xã Điền Hòa",
    code: 19834,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 476,
  },
  {
    name: "Xã Phong Chương",
    code: 19837,
    division_type: "xã",
    codename: "xa_phong_chuong",
    district_code: 476,
  },
  {
    name: "Xã Phong Hải",
    code: 19840,
    division_type: "xã",
    codename: "xa_phong_hai",
    district_code: 476,
  },
  {
    name: "Xã Điền Hải",
    code: 19843,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 476,
  },
  {
    name: "Xã Phong Hòa",
    code: 19846,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 476,
  },
  {
    name: "Xã Phong Thu",
    code: 19849,
    division_type: "xã",
    codename: "xa_phong_thu",
    district_code: 476,
  },
  {
    name: "Xã Phong Hiền",
    code: 19852,
    division_type: "xã",
    codename: "xa_phong_hien",
    district_code: 476,
  },
  {
    name: "Xã Phong Mỹ",
    code: 19855,
    division_type: "xã",
    codename: "xa_phong_my",
    district_code: 476,
  },
  {
    name: "Xã Phong An",
    code: 19858,
    division_type: "xã",
    codename: "xa_phong_an",
    district_code: 476,
  },
  {
    name: "Xã Phong Xuân",
    code: 19861,
    division_type: "xã",
    codename: "xa_phong_xuan",
    district_code: 476,
  },
  {
    name: "Xã Phong Sơn",
    code: 19864,
    division_type: "xã",
    codename: "xa_phong_son",
    district_code: 476,
  },
  {
    name: "Thị trấn Sịa",
    code: 19867,
    division_type: "thị trấn",
    codename: "thi_tran_sia",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thái",
    code: 19870,
    division_type: "xã",
    codename: "xa_quang_thai",
    district_code: 477,
  },
  {
    name: "Xã Quảng Ngạn",
    code: 19873,
    division_type: "xã",
    codename: "xa_quang_ngan",
    district_code: 477,
  },
  {
    name: "Xã Quảng Lợi",
    code: 19876,
    division_type: "xã",
    codename: "xa_quang_loi",
    district_code: 477,
  },
  {
    name: "Xã Quảng Công",
    code: 19879,
    division_type: "xã",
    codename: "xa_quang_cong",
    district_code: 477,
  },
  {
    name: "Xã Quảng Phước",
    code: 19882,
    division_type: "xã",
    codename: "xa_quang_phuoc",
    district_code: 477,
  },
  {
    name: "Xã Quảng Vinh",
    code: 19885,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 477,
  },
  {
    name: "Xã Quảng An",
    code: 19888,
    division_type: "xã",
    codename: "xa_quang_an",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thành",
    code: 19891,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thọ",
    code: 19894,
    division_type: "xã",
    codename: "xa_quang_tho",
    district_code: 477,
  },
  {
    name: "Xã Quảng Phú",
    code: 19897,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 477,
  },
  {
    name: "Thị trấn Thuận An",
    code: 19900,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Thuận",
    code: 19903,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Dương",
    code: 19906,
    division_type: "xã",
    codename: "xa_phu_duong",
    district_code: 478,
  },
  {
    name: "Xã Phú Mậu",
    code: 19909,
    division_type: "xã",
    codename: "xa_phu_mau",
    district_code: 478,
  },
  {
    name: "Xã Phú An",
    code: 19912,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Hải",
    code: 19915,
    division_type: "xã",
    codename: "xa_phu_hai",
    district_code: 478,
  },
  {
    name: "Xã Phú Xuân",
    code: 19918,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Diên",
    code: 19921,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 478,
  },
  {
    name: "Xã Phú Thanh",
    code: 19924,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 478,
  },
  {
    name: "Xã Phú Mỹ",
    code: 19927,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 478,
  },
  {
    name: "Xã Phú Thượng",
    code: 19930,
    division_type: "xã",
    codename: "xa_phu_thuong",
    district_code: 478,
  },
  {
    name: "Xã Phú Hồ",
    code: 19933,
    division_type: "xã",
    codename: "xa_phu_ho",
    district_code: 478,
  },
  {
    name: "Xã Vinh Xuân",
    code: 19936,
    division_type: "xã",
    codename: "xa_vinh_xuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Lương",
    code: 19939,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 478,
  },
  {
    name: "Thị trấn Phú Đa",
    code: 19942,
    division_type: "thị trấn",
    codename: "thi_tran_phu_da",
    district_code: 478,
  },
  {
    name: "Xã Vinh Thanh",
    code: 19945,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 478,
  },
  {
    name: "Xã Vinh An",
    code: 19948,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Gia",
    code: 19954,
    division_type: "xã",
    codename: "xa_phu_gia",
    district_code: 478,
  },
  {
    name: "Xã Vinh Hà",
    code: 19957,
    division_type: "xã",
    codename: "xa_vinh_ha",
    district_code: 478,
  },
  {
    name: "Phường Phú Bài",
    code: 19960,
    division_type: "phường",
    codename: "phuong_phu_bai",
    district_code: 479,
  },
  {
    name: "Xã Thủy Vân",
    code: 19963,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 479,
  },
  {
    name: "Xã Thủy Thanh",
    code: 19966,
    division_type: "xã",
    codename: "xa_thuy_thanh",
    district_code: 479,
  },
  {
    name: "Phường Thủy Dương",
    code: 19969,
    division_type: "phường",
    codename: "phuong_thuy_duong",
    district_code: 479,
  },
  {
    name: "Phường Thủy Phương",
    code: 19972,
    division_type: "phường",
    codename: "phuong_thuy_phuong",
    district_code: 479,
  },
  {
    name: "Phường Thủy Châu",
    code: 19975,
    division_type: "phường",
    codename: "phuong_thuy_chau",
    district_code: 479,
  },
  {
    name: "Phường Thủy Lương",
    code: 19978,
    division_type: "phường",
    codename: "phuong_thuy_luong",
    district_code: 479,
  },
  {
    name: "Xã Thủy Bằng",
    code: 19981,
    division_type: "xã",
    codename: "xa_thuy_bang",
    district_code: 479,
  },
  {
    name: "Xã Thủy Tân",
    code: 19984,
    division_type: "xã",
    codename: "xa_thuy_tan",
    district_code: 479,
  },
  {
    name: "Xã Thủy Phù",
    code: 19987,
    division_type: "xã",
    codename: "xa_thuy_phu",
    district_code: 479,
  },
  {
    name: "Xã Phú Sơn",
    code: 19990,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 479,
  },
  {
    name: "Xã Dương Hòa",
    code: 19993,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 479,
  },
  {
    name: "Phường Tứ Hạ",
    code: 19996,
    division_type: "phường",
    codename: "phuong_tu_ha",
    district_code: 480,
  },
  {
    name: "Xã Hải Dương",
    code: 19999,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 480,
  },
  {
    name: "Xã Hương Phong",
    code: 20002,
    division_type: "xã",
    codename: "xa_huong_phong",
    district_code: 480,
  },
  {
    name: "Xã Hương Toàn",
    code: 20005,
    division_type: "xã",
    codename: "xa_huong_toan",
    district_code: 480,
  },
  {
    name: "Phường Hương Vân",
    code: 20008,
    division_type: "phường",
    codename: "phuong_huong_van",
    district_code: 480,
  },
  {
    name: "Phường Hương Văn",
    code: 20011,
    division_type: "phường",
    codename: "phuong_huong_van",
    district_code: 480,
  },
  {
    name: "Xã Hương Vinh",
    code: 20014,
    division_type: "xã",
    codename: "xa_huong_vinh",
    district_code: 480,
  },
  {
    name: "Phường Hương Xuân",
    code: 20017,
    division_type: "phường",
    codename: "phuong_huong_xuan",
    district_code: 480,
  },
  {
    name: "Phường Hương Chữ",
    code: 20020,
    division_type: "phường",
    codename: "phuong_huong_chu",
    district_code: 480,
  },
  {
    name: "Phường Hương An",
    code: 20023,
    division_type: "phường",
    codename: "phuong_huong_an",
    district_code: 480,
  },
  {
    name: "Xã Hương Bình",
    code: 20026,
    division_type: "xã",
    codename: "xa_huong_binh",
    district_code: 480,
  },
  {
    name: "Phường Hương Hồ",
    code: 20029,
    division_type: "phường",
    codename: "phuong_huong_ho",
    district_code: 480,
  },
  {
    name: "Xã Hương Thọ",
    code: 20032,
    division_type: "xã",
    codename: "xa_huong_tho",
    district_code: 480,
  },
  {
    name: "Xã Bình Tiến",
    code: 20035,
    division_type: "xã",
    codename: "xa_binh_tien",
    district_code: 480,
  },
  {
    name: "Xã Bình Thành",
    code: 20041,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 480,
  },
  {
    name: "Thị trấn A Lưới",
    code: 20044,
    division_type: "thị trấn",
    codename: "thi_tran_a_luoi",
    district_code: 481,
  },
  {
    name: "Xã Hồng Vân",
    code: 20047,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 481,
  },
  {
    name: "Xã Hồng Hạ",
    code: 20050,
    division_type: "xã",
    codename: "xa_hong_ha",
    district_code: 481,
  },
  {
    name: "Xã Hồng Kim",
    code: 20053,
    division_type: "xã",
    codename: "xa_hong_kim",
    district_code: 481,
  },
  {
    name: "Xã Trung Sơn",
    code: 20056,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 481,
  },
  {
    name: "Xã Hương Nguyên",
    code: 20059,
    division_type: "xã",
    codename: "xa_huong_nguyen",
    district_code: 481,
  },
  {
    name: "Xã Hồng Bắc",
    code: 20065,
    division_type: "xã",
    codename: "xa_hong_bac",
    district_code: 481,
  },
  {
    name: "Xã A Ngo",
    code: 20068,
    division_type: "xã",
    codename: "xa_a_ngo",
    district_code: 481,
  },
  {
    name: "Xã Sơn Thủy",
    code: 20071,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 481,
  },
  {
    name: "Xã Phú Vinh",
    code: 20074,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 481,
  },
  {
    name: "Xã Hương Phong",
    code: 20080,
    division_type: "xã",
    codename: "xa_huong_phong",
    district_code: 481,
  },
  {
    name: "Xã Quảng Nhâm",
    code: 20083,
    division_type: "xã",
    codename: "xa_quang_nham",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thượng",
    code: 20086,
    division_type: "xã",
    codename: "xa_hong_thuong",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thái",
    code: 20089,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 481,
  },
  {
    name: "Xã A Roàng",
    code: 20095,
    division_type: "xã",
    codename: "xa_a_roang",
    district_code: 481,
  },
  {
    name: "Xã Đông Sơn",
    code: 20098,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 481,
  },
  {
    name: "Xã Lâm Đớt",
    code: 20101,
    division_type: "xã",
    codename: "xa_lam_dot",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thủy",
    code: 20104,
    division_type: "xã",
    codename: "xa_hong_thuy",
    district_code: 481,
  },
  {
    name: "Thị trấn Phú Lộc",
    code: 20107,
    division_type: "thị trấn",
    codename: "thi_tran_phu_loc",
    district_code: 482,
  },
  {
    name: "Thị trấn Lăng Cô",
    code: 20110,
    division_type: "thị trấn",
    codename: "thi_tran_lang_co",
    district_code: 482,
  },
  {
    name: "Xã Vinh Mỹ",
    code: 20113,
    division_type: "xã",
    codename: "xa_vinh_my",
    district_code: 482,
  },
  {
    name: "Xã Vinh Hưng",
    code: 20116,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 482,
  },
  {
    name: "Xã Giang Hải",
    code: 20122,
    division_type: "xã",
    codename: "xa_giang_hai",
    district_code: 482,
  },
  {
    name: "Xã Vinh Hiền",
    code: 20125,
    division_type: "xã",
    codename: "xa_vinh_hien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Bổn",
    code: 20128,
    division_type: "xã",
    codename: "xa_loc_bon",
    district_code: 482,
  },
  {
    name: "Xã Lộc Sơn",
    code: 20131,
    division_type: "xã",
    codename: "xa_loc_son",
    district_code: 482,
  },
  {
    name: "Xã Lộc Bình",
    code: 20134,
    division_type: "xã",
    codename: "xa_loc_binh",
    district_code: 482,
  },
  {
    name: "Xã Lộc Vĩnh",
    code: 20137,
    division_type: "xã",
    codename: "xa_loc_vinh",
    district_code: 482,
  },
  {
    name: "Xã Lộc An",
    code: 20140,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 482,
  },
  {
    name: "Xã Lộc Điền",
    code: 20143,
    division_type: "xã",
    codename: "xa_loc_dien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Thủy",
    code: 20146,
    division_type: "xã",
    codename: "xa_loc_thuy",
    district_code: 482,
  },
  {
    name: "Xã Lộc Trì",
    code: 20149,
    division_type: "xã",
    codename: "xa_loc_tri",
    district_code: 482,
  },
  {
    name: "Xã Lộc Tiến",
    code: 20152,
    division_type: "xã",
    codename: "xa_loc_tien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Hòa",
    code: 20155,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 482,
  },
  {
    name: "Xã Xuân Lộc",
    code: 20158,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 482,
  },
  {
    name: "Thị trấn Khe Tre",
    code: 20161,
    division_type: "thị trấn",
    codename: "thi_tran_khe_tre",
    district_code: 483,
  },
  {
    name: "Xã Hương Phú",
    code: 20164,
    division_type: "xã",
    codename: "xa_huong_phu",
    district_code: 483,
  },
  {
    name: "Xã Hương Sơn",
    code: 20167,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 483,
  },
  {
    name: "Xã Hương Lộc",
    code: 20170,
    division_type: "xã",
    codename: "xa_huong_loc",
    district_code: 483,
  },
  {
    name: "Xã Thượng Quảng",
    code: 20173,
    division_type: "xã",
    codename: "xa_thuong_quang",
    district_code: 483,
  },
  {
    name: "Xã Hương Xuân",
    code: 20179,
    division_type: "xã",
    codename: "xa_huong_xuan",
    district_code: 483,
  },
  {
    name: "Xã Hương Hữu",
    code: 20182,
    division_type: "xã",
    codename: "xa_huong_huu",
    district_code: 483,
  },
  {
    name: "Xã Thượng Lộ",
    code: 20185,
    division_type: "xã",
    codename: "xa_thuong_lo",
    district_code: 483,
  },
  {
    name: "Xã Thượng Long",
    code: 20188,
    division_type: "xã",
    codename: "xa_thuong_long",
    district_code: 483,
  },
  {
    name: "Xã Thượng Nhật",
    code: 20191,
    division_type: "xã",
    codename: "xa_thuong_nhat",
    district_code: 483,
  },
  {
    name: "Phường Hòa Hiệp Bắc",
    code: 20194,
    division_type: "phường",
    codename: "phuong_hoa_hiep_bac",
    district_code: 490,
  },
  {
    name: "Phường Hòa Hiệp Nam",
    code: 20195,
    division_type: "phường",
    codename: "phuong_hoa_hiep_nam",
    district_code: 490,
  },
  {
    name: "Phường Hòa Khánh Bắc",
    code: 20197,
    division_type: "phường",
    codename: "phuong_hoa_khanh_bac",
    district_code: 490,
  },
  {
    name: "Phường Hòa Khánh Nam",
    code: 20198,
    division_type: "phường",
    codename: "phuong_hoa_khanh_nam",
    district_code: 490,
  },
  {
    name: "Phường Hòa Minh",
    code: 20200,
    division_type: "phường",
    codename: "phuong_hoa_minh",
    district_code: 490,
  },
  {
    name: "Phường Tam Thuận",
    code: 20203,
    division_type: "phường",
    codename: "phuong_tam_thuan",
    district_code: 491,
  },
  {
    name: "Phường Thanh Khê Tây",
    code: 20206,
    division_type: "phường",
    codename: "phuong_thanh_khe_tay",
    district_code: 491,
  },
  {
    name: "Phường Thanh Khê Đông",
    code: 20207,
    division_type: "phường",
    codename: "phuong_thanh_khe_dong",
    district_code: 491,
  },
  {
    name: "Phường Xuân Hà",
    code: 20209,
    division_type: "phường",
    codename: "phuong_xuan_ha",
    district_code: 491,
  },
  {
    name: "Phường Tân Chính",
    code: 20212,
    division_type: "phường",
    codename: "phuong_tan_chinh",
    district_code: 491,
  },
  {
    name: "Phường Chính Gián",
    code: 20215,
    division_type: "phường",
    codename: "phuong_chinh_gian",
    district_code: 491,
  },
  {
    name: "Phường Vĩnh Trung",
    code: 20218,
    division_type: "phường",
    codename: "phuong_vinh_trung",
    district_code: 491,
  },
  {
    name: "Phường Thạc Gián",
    code: 20221,
    division_type: "phường",
    codename: "phuong_thac_gian",
    district_code: 491,
  },
  {
    name: "Phường An Khê",
    code: 20224,
    division_type: "phường",
    codename: "phuong_an_khe",
    district_code: 491,
  },
  {
    name: "Phường Hòa Khê",
    code: 20225,
    division_type: "phường",
    codename: "phuong_hoa_khe",
    district_code: 491,
  },
  {
    name: "Phường Thanh Bình",
    code: 20227,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 492,
  },
  {
    name: "Phường Thuận Phước",
    code: 20230,
    division_type: "phường",
    codename: "phuong_thuan_phuoc",
    district_code: 492,
  },
  {
    name: "Phường Thạch Thang",
    code: 20233,
    division_type: "phường",
    codename: "phuong_thach_thang",
    district_code: 492,
  },
  {
    name: "Phường Hải Châu I",
    code: 20236,
    division_type: "phường",
    codename: "phuong_hai_chau_i",
    district_code: 492,
  },
  {
    name: "Phường Hải Châu II",
    code: 20239,
    division_type: "phường",
    codename: "phuong_hai_chau_ii",
    district_code: 492,
  },
  {
    name: "Phường Phước Ninh",
    code: 20242,
    division_type: "phường",
    codename: "phuong_phuoc_ninh",
    district_code: 492,
  },
  {
    name: "Phường Hòa Thuận Tây",
    code: 20245,
    division_type: "phường",
    codename: "phuong_hoa_thuan_tay",
    district_code: 492,
  },
  {
    name: "Phường Hòa Thuận Đông",
    code: 20246,
    division_type: "phường",
    codename: "phuong_hoa_thuan_dong",
    district_code: 492,
  },
  {
    name: "Phường Nam Dương",
    code: 20248,
    division_type: "phường",
    codename: "phuong_nam_duong",
    district_code: 492,
  },
  {
    name: "Phường Bình Hiên",
    code: 20251,
    division_type: "phường",
    codename: "phuong_binh_hien",
    district_code: 492,
  },
  {
    name: "Phường Bình Thuận",
    code: 20254,
    division_type: "phường",
    codename: "phuong_binh_thuan",
    district_code: 492,
  },
  {
    name: "Phường Hòa Cường Bắc",
    code: 20257,
    division_type: "phường",
    codename: "phuong_hoa_cuong_bac",
    district_code: 492,
  },
  {
    name: "Phường Hòa Cường Nam",
    code: 20258,
    division_type: "phường",
    codename: "phuong_hoa_cuong_nam",
    district_code: 492,
  },
  {
    name: "Phường Thọ Quang",
    code: 20263,
    division_type: "phường",
    codename: "phuong_tho_quang",
    district_code: 493,
  },
  {
    name: "Phường Nại Hiên Đông",
    code: 20266,
    division_type: "phường",
    codename: "phuong_nai_hien_dong",
    district_code: 493,
  },
  {
    name: "Phường Mân Thái",
    code: 20269,
    division_type: "phường",
    codename: "phuong_man_thai",
    district_code: 493,
  },
  {
    name: "Phường An Hải Bắc",
    code: 20272,
    division_type: "phường",
    codename: "phuong_an_hai_bac",
    district_code: 493,
  },
  {
    name: "Phường Phước Mỹ",
    code: 20275,
    division_type: "phường",
    codename: "phuong_phuoc_my",
    district_code: 493,
  },
  {
    name: "Phường An Hải Tây",
    code: 20278,
    division_type: "phường",
    codename: "phuong_an_hai_tay",
    district_code: 493,
  },
  {
    name: "Phường An Hải Đông",
    code: 20281,
    division_type: "phường",
    codename: "phuong_an_hai_dong",
    district_code: 493,
  },
  {
    name: "Phường Mỹ An",
    code: 20284,
    division_type: "phường",
    codename: "phuong_my_an",
    district_code: 494,
  },
  {
    name: "Phường Khuê Mỹ",
    code: 20285,
    division_type: "phường",
    codename: "phuong_khue_my",
    district_code: 494,
  },
  {
    name: "Phường Hoà Quý",
    code: 20287,
    division_type: "phường",
    codename: "phuong_hoa_quy",
    district_code: 494,
  },
  {
    name: "Phường Hoà Hải",
    code: 20290,
    division_type: "phường",
    codename: "phuong_hoa_hai",
    district_code: 494,
  },
  {
    name: "Phường Khuê Trung",
    code: 20260,
    division_type: "phường",
    codename: "phuong_khue_trung",
    district_code: 495,
  },
  {
    name: "Phường Hòa Phát",
    code: 20305,
    division_type: "phường",
    codename: "phuong_hoa_phat",
    district_code: 495,
  },
  {
    name: "Phường Hòa An",
    code: 20306,
    division_type: "phường",
    codename: "phuong_hoa_an",
    district_code: 495,
  },
  {
    name: "Phường Hòa Thọ Tây",
    code: 20311,
    division_type: "phường",
    codename: "phuong_hoa_tho_tay",
    district_code: 495,
  },
  {
    name: "Phường Hòa Thọ Đông",
    code: 20312,
    division_type: "phường",
    codename: "phuong_hoa_tho_dong",
    district_code: 495,
  },
  {
    name: "Phường Hòa Xuân",
    code: 20314,
    division_type: "phường",
    codename: "phuong_hoa_xuan",
    district_code: 495,
  },
  {
    name: "Xã Hòa Bắc",
    code: 20293,
    division_type: "xã",
    codename: "xa_hoa_bac",
    district_code: 497,
  },
  {
    name: "Xã Hòa Liên",
    code: 20296,
    division_type: "xã",
    codename: "xa_hoa_lien",
    district_code: 497,
  },
  {
    name: "Xã Hòa Ninh",
    code: 20299,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 497,
  },
  {
    name: "Xã Hòa Sơn",
    code: 20302,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 497,
  },
  {
    name: "Xã Hòa Nhơn",
    code: 20308,
    division_type: "xã",
    codename: "xa_hoa_nhon",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phú",
    code: 20317,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phong",
    code: 20320,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 497,
  },
  {
    name: "Xã Hòa Châu",
    code: 20323,
    division_type: "xã",
    codename: "xa_hoa_chau",
    district_code: 497,
  },
  {
    name: "Xã Hòa Tiến",
    code: 20326,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phước",
    code: 20329,
    division_type: "xã",
    codename: "xa_hoa_phuoc",
    district_code: 497,
  },
  {
    name: "Xã Hòa Khương",
    code: 20332,
    division_type: "xã",
    codename: "xa_hoa_khuong",
    district_code: 497,
  },
  {
    name: "Phường Tân Thạnh",
    code: 20335,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 502,
  },
  {
    name: "Phường Phước Hòa",
    code: 20338,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 502,
  },
  {
    name: "Phường An Mỹ",
    code: 20341,
    division_type: "phường",
    codename: "phuong_an_my",
    district_code: 502,
  },
  {
    name: "Phường Hòa Hương",
    code: 20344,
    division_type: "phường",
    codename: "phuong_hoa_huong",
    district_code: 502,
  },
  {
    name: "Phường An Xuân",
    code: 20347,
    division_type: "phường",
    codename: "phuong_an_xuan",
    district_code: 502,
  },
  {
    name: "Phường An Sơn",
    code: 20350,
    division_type: "phường",
    codename: "phuong_an_son",
    district_code: 502,
  },
  {
    name: "Phường Trường Xuân",
    code: 20353,
    division_type: "phường",
    codename: "phuong_truong_xuan",
    district_code: 502,
  },
  {
    name: "Phường An Phú",
    code: 20356,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 502,
  },
  {
    name: "Xã Tam Thanh",
    code: 20359,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 502,
  },
  {
    name: "Xã Tam Thăng",
    code: 20362,
    division_type: "xã",
    codename: "xa_tam_thang",
    district_code: 502,
  },
  {
    name: "Xã Tam Phú",
    code: 20371,
    division_type: "xã",
    codename: "xa_tam_phu",
    district_code: 502,
  },
  {
    name: "Phường Hoà Thuận",
    code: 20375,
    division_type: "phường",
    codename: "phuong_hoa_thuan",
    district_code: 502,
  },
  {
    name: "Xã Tam Ngọc",
    code: 20389,
    division_type: "xã",
    codename: "xa_tam_ngoc",
    district_code: 502,
  },
  {
    name: "Phường Minh An",
    code: 20398,
    division_type: "phường",
    codename: "phuong_minh_an",
    district_code: 503,
  },
  {
    name: "Phường Tân An",
    code: 20401,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Phô",
    code: 20404,
    division_type: "phường",
    codename: "phuong_cam_pho",
    district_code: 503,
  },
  {
    name: "Phường Thanh Hà",
    code: 20407,
    division_type: "phường",
    codename: "phuong_thanh_ha",
    district_code: 503,
  },
  {
    name: "Phường Sơn Phong",
    code: 20410,
    division_type: "phường",
    codename: "phuong_son_phong",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Châu",
    code: 20413,
    division_type: "phường",
    codename: "phuong_cam_chau",
    district_code: 503,
  },
  {
    name: "Phường Cửa Đại",
    code: 20416,
    division_type: "phường",
    codename: "phuong_cua_dai",
    district_code: 503,
  },
  {
    name: "Phường Cẩm An",
    code: 20419,
    division_type: "phường",
    codename: "phuong_cam_an",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Hà",
    code: 20422,
    division_type: "xã",
    codename: "xa_cam_ha",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Kim",
    code: 20425,
    division_type: "xã",
    codename: "xa_cam_kim",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Nam",
    code: 20428,
    division_type: "phường",
    codename: "phuong_cam_nam",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Thanh",
    code: 20431,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 503,
  },
  {
    name: "Xã Tân Hiệp",
    code: 20434,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 503,
  },
  {
    name: "Xã Ch'ơm",
    code: 20437,
    division_type: "xã",
    codename: "xa_chom",
    district_code: 504,
  },
  {
    name: "Xã Ga Ri",
    code: 20440,
    division_type: "xã",
    codename: "xa_ga_ri",
    district_code: 504,
  },
  {
    name: "Xã A Xan",
    code: 20443,
    division_type: "xã",
    codename: "xa_a_xan",
    district_code: 504,
  },
  {
    name: "Xã Tr'Hy",
    code: 20446,
    division_type: "xã",
    codename: "xa_trhy",
    district_code: 504,
  },
  {
    name: "Xã Lăng",
    code: 20449,
    division_type: "xã",
    codename: "xa_lang",
    district_code: 504,
  },
  {
    name: "Xã A Nông",
    code: 20452,
    division_type: "xã",
    codename: "xa_a_nong",
    district_code: 504,
  },
  {
    name: "Xã A Tiêng",
    code: 20455,
    division_type: "xã",
    codename: "xa_a_tieng",
    district_code: 504,
  },
  {
    name: "Xã Bha Lê",
    code: 20458,
    division_type: "xã",
    codename: "xa_bha_le",
    district_code: 504,
  },
  {
    name: "Xã A Vương",
    code: 20461,
    division_type: "xã",
    codename: "xa_a_vuong",
    district_code: 504,
  },
  {
    name: "Xã Dang",
    code: 20464,
    division_type: "xã",
    codename: "xa_dang",
    district_code: 504,
  },
  {
    name: "Thị trấn P Rao",
    code: 20467,
    division_type: "thị trấn",
    codename: "thi_tran_p_rao",
    district_code: 505,
  },
  {
    name: "Xã Tà Lu",
    code: 20470,
    division_type: "xã",
    codename: "xa_ta_lu",
    district_code: 505,
  },
  {
    name: "Xã Sông Kôn",
    code: 20473,
    division_type: "xã",
    codename: "xa_song_kon",
    district_code: 505,
  },
  {
    name: "Xã Jơ Ngây",
    code: 20476,
    division_type: "xã",
    codename: "xa_jo_ngay",
    district_code: 505,
  },
  {
    name: "Xã A Ting",
    code: 20479,
    division_type: "xã",
    codename: "xa_a_ting",
    district_code: 505,
  },
  {
    name: "Xã Tư",
    code: 20482,
    division_type: "xã",
    codename: "xa_tu",
    district_code: 505,
  },
  {
    name: "Xã Ba",
    code: 20485,
    division_type: "xã",
    codename: "xa_ba",
    district_code: 505,
  },
  {
    name: "Xã A Rooi",
    code: 20488,
    division_type: "xã",
    codename: "xa_a_rooi",
    district_code: 505,
  },
  {
    name: "Xã Za Hung",
    code: 20491,
    division_type: "xã",
    codename: "xa_za_hung",
    district_code: 505,
  },
  {
    name: "Xã Mà Cooi",
    code: 20494,
    division_type: "xã",
    codename: "xa_ma_cooi",
    district_code: 505,
  },
  {
    name: "Xã Ka Dăng",
    code: 20497,
    division_type: "xã",
    codename: "xa_ka_dang",
    district_code: 505,
  },
  {
    name: "Thị trấn Ái Nghĩa",
    code: 20500,
    division_type: "thị trấn",
    codename: "thi_tran_ai_nghia",
    district_code: 506,
  },
  {
    name: "Xã Đại Sơn",
    code: 20503,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 506,
  },
  {
    name: "Xã Đại Lãnh",
    code: 20506,
    division_type: "xã",
    codename: "xa_dai_lanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Hưng",
    code: 20509,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 506,
  },
  {
    name: "Xã Đại Hồng",
    code: 20512,
    division_type: "xã",
    codename: "xa_dai_hong",
    district_code: 506,
  },
  {
    name: "Xã Đại Đồng",
    code: 20515,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 506,
  },
  {
    name: "Xã Đại Quang",
    code: 20518,
    division_type: "xã",
    codename: "xa_dai_quang",
    district_code: 506,
  },
  {
    name: "Xã Đại Nghĩa",
    code: 20521,
    division_type: "xã",
    codename: "xa_dai_nghia",
    district_code: 506,
  },
  {
    name: "Xã Đại Hiệp",
    code: 20524,
    division_type: "xã",
    codename: "xa_dai_hiep",
    district_code: 506,
  },
  {
    name: "Xã Đại Thạnh",
    code: 20527,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Chánh",
    code: 20530,
    division_type: "xã",
    codename: "xa_dai_chanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Tân",
    code: 20533,
    division_type: "xã",
    codename: "xa_dai_tan",
    district_code: 506,
  },
  {
    name: "Xã Đại Phong",
    code: 20536,
    division_type: "xã",
    codename: "xa_dai_phong",
    district_code: 506,
  },
  {
    name: "Xã Đại Minh",
    code: 20539,
    division_type: "xã",
    codename: "xa_dai_minh",
    district_code: 506,
  },
  {
    name: "Xã Đại Thắng",
    code: 20542,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 506,
  },
  {
    name: "Xã Đại Cường",
    code: 20545,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 506,
  },
  {
    name: "Xã Đại An",
    code: 20547,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 506,
  },
  {
    name: "Xã Đại Hòa",
    code: 20548,
    division_type: "xã",
    codename: "xa_dai_hoa",
    district_code: 506,
  },
  {
    name: "Phường Vĩnh Điện",
    code: 20551,
    division_type: "phường",
    codename: "phuong_vinh_dien",
    district_code: 507,
  },
  {
    name: "Xã Điện Tiến",
    code: 20554,
    division_type: "xã",
    codename: "xa_dien_tien",
    district_code: 507,
  },
  {
    name: "Xã Điện Hòa",
    code: 20557,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Bắc",
    code: 20560,
    division_type: "xã",
    codename: "xa_dien_thang_bac",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Trung",
    code: 20561,
    division_type: "xã",
    codename: "xa_dien_thang_trung",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Nam",
    code: 20562,
    division_type: "xã",
    codename: "xa_dien_thang_nam",
    district_code: 507,
  },
  {
    name: "Phường Điện Ngọc",
    code: 20563,
    division_type: "phường",
    codename: "phuong_dien_ngoc",
    district_code: 507,
  },
  {
    name: "Xã Điện Hồng",
    code: 20566,
    division_type: "xã",
    codename: "xa_dien_hong",
    district_code: 507,
  },
  {
    name: "Xã Điện Thọ",
    code: 20569,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 507,
  },
  {
    name: "Xã Điện Phước",
    code: 20572,
    division_type: "xã",
    codename: "xa_dien_phuoc",
    district_code: 507,
  },
  {
    name: "Phường Điện An",
    code: 20575,
    division_type: "phường",
    codename: "phuong_dien_an",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Bắc",
    code: 20578,
    division_type: "phường",
    codename: "phuong_dien_nam_bac",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Trung",
    code: 20579,
    division_type: "phường",
    codename: "phuong_dien_nam_trung",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Đông",
    code: 20580,
    division_type: "phường",
    codename: "phuong_dien_nam_dong",
    district_code: 507,
  },
  {
    name: "Phường Điện Dương",
    code: 20581,
    division_type: "phường",
    codename: "phuong_dien_duong",
    district_code: 507,
  },
  {
    name: "Xã Điện Quang",
    code: 20584,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 507,
  },
  {
    name: "Xã Điện Trung",
    code: 20587,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 507,
  },
  {
    name: "Xã Điện Phong",
    code: 20590,
    division_type: "xã",
    codename: "xa_dien_phong",
    district_code: 507,
  },
  {
    name: "Xã Điện Minh",
    code: 20593,
    division_type: "xã",
    codename: "xa_dien_minh",
    district_code: 507,
  },
  {
    name: "Xã Điện Phương",
    code: 20596,
    division_type: "xã",
    codename: "xa_dien_phuong",
    district_code: 507,
  },
  {
    name: "Thị trấn Nam Phước",
    code: 20599,
    division_type: "thị trấn",
    codename: "thi_tran_nam_phuoc",
    district_code: 508,
  },
  {
    name: "Xã Duy Thu",
    code: 20602,
    division_type: "xã",
    codename: "xa_duy_thu",
    district_code: 508,
  },
  {
    name: "Xã Duy Phú",
    code: 20605,
    division_type: "xã",
    codename: "xa_duy_phu",
    district_code: 508,
  },
  {
    name: "Xã Duy Tân",
    code: 20608,
    division_type: "xã",
    codename: "xa_duy_tan",
    district_code: 508,
  },
  {
    name: "Xã Duy Hòa",
    code: 20611,
    division_type: "xã",
    codename: "xa_duy_hoa",
    district_code: 508,
  },
  {
    name: "Xã Duy Châu",
    code: 20614,
    division_type: "xã",
    codename: "xa_duy_chau",
    district_code: 508,
  },
  {
    name: "Xã Duy Trinh",
    code: 20617,
    division_type: "xã",
    codename: "xa_duy_trinh",
    district_code: 508,
  },
  {
    name: "Xã Duy Sơn",
    code: 20620,
    division_type: "xã",
    codename: "xa_duy_son",
    district_code: 508,
  },
  {
    name: "Xã Duy Trung",
    code: 20623,
    division_type: "xã",
    codename: "xa_duy_trung",
    district_code: 508,
  },
  {
    name: "Xã Duy Phước",
    code: 20626,
    division_type: "xã",
    codename: "xa_duy_phuoc",
    district_code: 508,
  },
  {
    name: "Xã Duy Thành",
    code: 20629,
    division_type: "xã",
    codename: "xa_duy_thanh",
    district_code: 508,
  },
  {
    name: "Xã Duy Vinh",
    code: 20632,
    division_type: "xã",
    codename: "xa_duy_vinh",
    district_code: 508,
  },
  {
    name: "Xã Duy Nghĩa",
    code: 20635,
    division_type: "xã",
    codename: "xa_duy_nghia",
    district_code: 508,
  },
  {
    name: "Xã Duy Hải",
    code: 20638,
    division_type: "xã",
    codename: "xa_duy_hai",
    district_code: 508,
  },
  {
    name: "Thị trấn Đông Phú",
    code: 20641,
    division_type: "thị trấn",
    codename: "thi_tran_dong_phu",
    district_code: 509,
  },
  {
    name: "Xã Quế Xuân 1",
    code: 20644,
    division_type: "xã",
    codename: "xa_que_xuan_1",
    district_code: 509,
  },
  {
    name: "Xã Quế Xuân 2",
    code: 20647,
    division_type: "xã",
    codename: "xa_que_xuan_2",
    district_code: 509,
  },
  {
    name: "Xã Quế Phú",
    code: 20650,
    division_type: "xã",
    codename: "xa_que_phu",
    district_code: 509,
  },
  {
    name: "Thị trấn Hương An",
    code: 20651,
    division_type: "thị trấn",
    codename: "thi_tran_huong_an",
    district_code: 509,
  },
  {
    name: "Xã Quế Hiệp",
    code: 20659,
    division_type: "xã",
    codename: "xa_que_hiep",
    district_code: 509,
  },
  {
    name: "Xã Quế Thuận",
    code: 20662,
    division_type: "xã",
    codename: "xa_que_thuan",
    district_code: 509,
  },
  {
    name: "Xã Quế Mỹ",
    code: 20665,
    division_type: "xã",
    codename: "xa_que_my",
    district_code: 509,
  },
  {
    name: "Xã Quế Long",
    code: 20677,
    division_type: "xã",
    codename: "xa_que_long",
    district_code: 509,
  },
  {
    name: "Xã Quế Châu",
    code: 20680,
    division_type: "xã",
    codename: "xa_que_chau",
    district_code: 509,
  },
  {
    name: "Xã Quế Phong",
    code: 20683,
    division_type: "xã",
    codename: "xa_que_phong",
    district_code: 509,
  },
  {
    name: "Xã Quế An",
    code: 20686,
    division_type: "xã",
    codename: "xa_que_an",
    district_code: 509,
  },
  {
    name: "Xã Quế Minh",
    code: 20689,
    division_type: "xã",
    codename: "xa_que_minh",
    district_code: 509,
  },
  {
    name: "Thị trấn Thạnh Mỹ",
    code: 20695,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_my",
    district_code: 510,
  },
  {
    name: "Xã Laêê",
    code: 20698,
    division_type: "xã",
    codename: "xa_laee",
    district_code: 510,
  },
  {
    name: "Xã Chơ Chun",
    code: 20699,
    division_type: "xã",
    codename: "xa_cho_chun",
    district_code: 510,
  },
  {
    name: "Xã Zuôich",
    code: 20701,
    division_type: "xã",
    codename: "xa_zuoich",
    district_code: 510,
  },
  {
    name: "Xã Tà Pơơ",
    code: 20702,
    division_type: "xã",
    codename: "xa_ta_poo",
    district_code: 510,
  },
  {
    name: "Xã La Dêê",
    code: 20704,
    division_type: "xã",
    codename: "xa_la_dee",
    district_code: 510,
  },
  {
    name: "Xã Đắc Tôi",
    code: 20705,
    division_type: "xã",
    codename: "xa_dac_toi",
    district_code: 510,
  },
  {
    name: "Xã Chà Vàl",
    code: 20707,
    division_type: "xã",
    codename: "xa_cha_val",
    district_code: 510,
  },
  {
    name: "Xã Tà Bhinh",
    code: 20710,
    division_type: "xã",
    codename: "xa_ta_bhinh",
    district_code: 510,
  },
  {
    name: "Xã Cà Dy",
    code: 20713,
    division_type: "xã",
    codename: "xa_ca_dy",
    district_code: 510,
  },
  {
    name: "Xã Đắc Pre",
    code: 20716,
    division_type: "xã",
    codename: "xa_dac_pre",
    district_code: 510,
  },
  {
    name: "Xã Đắc Pring",
    code: 20719,
    division_type: "xã",
    codename: "xa_dac_pring",
    district_code: 510,
  },
  {
    name: "Thị trấn Khâm Đức",
    code: 20722,
    division_type: "thị trấn",
    codename: "thi_tran_kham_duc",
    district_code: 511,
  },
  {
    name: "Xã Phước Xuân",
    code: 20725,
    division_type: "xã",
    codename: "xa_phuoc_xuan",
    district_code: 511,
  },
  {
    name: "Xã Phước Hiệp",
    code: 20728,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 511,
  },
  {
    name: "Xã Phước Hoà",
    code: 20729,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 511,
  },
  {
    name: "Xã Phước Đức",
    code: 20731,
    division_type: "xã",
    codename: "xa_phuoc_duc",
    district_code: 511,
  },
  {
    name: "Xã Phước Năng",
    code: 20734,
    division_type: "xã",
    codename: "xa_phuoc_nang",
    district_code: 511,
  },
  {
    name: "Xã Phước Mỹ",
    code: 20737,
    division_type: "xã",
    codename: "xa_phuoc_my",
    district_code: 511,
  },
  {
    name: "Xã Phước Chánh",
    code: 20740,
    division_type: "xã",
    codename: "xa_phuoc_chanh",
    district_code: 511,
  },
  {
    name: "Xã Phước Công",
    code: 20743,
    division_type: "xã",
    codename: "xa_phuoc_cong",
    district_code: 511,
  },
  {
    name: "Xã Phước Kim",
    code: 20746,
    division_type: "xã",
    codename: "xa_phuoc_kim",
    district_code: 511,
  },
  {
    name: "Xã Phước Lộc",
    code: 20749,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 511,
  },
  {
    name: "Xã Phước Thành",
    code: 20752,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 511,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 20758,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 512,
  },
  {
    name: "Xã Hiệp Thuận",
    code: 20761,
    division_type: "xã",
    codename: "xa_hiep_thuan",
    district_code: 512,
  },
  {
    name: "Xã Quế Thọ",
    code: 20764,
    division_type: "xã",
    codename: "xa_que_tho",
    district_code: 512,
  },
  {
    name: "Xã Bình Lâm",
    code: 20767,
    division_type: "xã",
    codename: "xa_binh_lam",
    district_code: 512,
  },
  {
    name: "Xã Sông Trà",
    code: 20770,
    division_type: "xã",
    codename: "xa_song_tra",
    district_code: 512,
  },
  {
    name: "Xã Phước Trà",
    code: 20773,
    division_type: "xã",
    codename: "xa_phuoc_tra",
    district_code: 512,
  },
  {
    name: "Xã Phước Gia",
    code: 20776,
    division_type: "xã",
    codename: "xa_phuoc_gia",
    district_code: 512,
  },
  {
    name: "Thị trấn Tân Bình",
    code: 20779,
    division_type: "thị trấn",
    codename: "thi_tran_tan_binh",
    district_code: 512,
  },
  {
    name: "Xã Quế Lưu",
    code: 20782,
    division_type: "xã",
    codename: "xa_que_luu",
    district_code: 512,
  },
  {
    name: "Xã Thăng Phước",
    code: 20785,
    division_type: "xã",
    codename: "xa_thang_phuoc",
    district_code: 512,
  },
  {
    name: "Xã Bình Sơn",
    code: 20788,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 512,
  },
  {
    name: "Thị trấn Hà Lam",
    code: 20791,
    division_type: "thị trấn",
    codename: "thi_tran_ha_lam",
    district_code: 513,
  },
  {
    name: "Xã Bình Dương",
    code: 20794,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 513,
  },
  {
    name: "Xã Bình Giang",
    code: 20797,
    division_type: "xã",
    codename: "xa_binh_giang",
    district_code: 513,
  },
  {
    name: "Xã Bình Nguyên",
    code: 20800,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 513,
  },
  {
    name: "Xã Bình Phục",
    code: 20803,
    division_type: "xã",
    codename: "xa_binh_phuc",
    district_code: 513,
  },
  {
    name: "Xã Bình Triều",
    code: 20806,
    division_type: "xã",
    codename: "xa_binh_trieu",
    district_code: 513,
  },
  {
    name: "Xã Bình Đào",
    code: 20809,
    division_type: "xã",
    codename: "xa_binh_dao",
    district_code: 513,
  },
  {
    name: "Xã Bình Minh",
    code: 20812,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 513,
  },
  {
    name: "Xã Bình Lãnh",
    code: 20815,
    division_type: "xã",
    codename: "xa_binh_lanh",
    district_code: 513,
  },
  {
    name: "Xã Bình Trị",
    code: 20818,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 513,
  },
  {
    name: "Xã Bình Định Bắc",
    code: 20821,
    division_type: "xã",
    codename: "xa_binh_dinh_bac",
    district_code: 513,
  },
  {
    name: "Xã Bình Định Nam",
    code: 20822,
    division_type: "xã",
    codename: "xa_binh_dinh_nam",
    district_code: 513,
  },
  {
    name: "Xã Bình Quý",
    code: 20824,
    division_type: "xã",
    codename: "xa_binh_quy",
    district_code: 513,
  },
  {
    name: "Xã Bình Phú",
    code: 20827,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 513,
  },
  {
    name: "Xã Bình Chánh",
    code: 20830,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 513,
  },
  {
    name: "Xã Bình Tú",
    code: 20833,
    division_type: "xã",
    codename: "xa_binh_tu",
    district_code: 513,
  },
  {
    name: "Xã Bình Sa",
    code: 20836,
    division_type: "xã",
    codename: "xa_binh_sa",
    district_code: 513,
  },
  {
    name: "Xã Bình Hải",
    code: 20839,
    division_type: "xã",
    codename: "xa_binh_hai",
    district_code: 513,
  },
  {
    name: "Xã Bình Quế",
    code: 20842,
    division_type: "xã",
    codename: "xa_binh_que",
    district_code: 513,
  },
  {
    name: "Xã Bình An",
    code: 20845,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 513,
  },
  {
    name: "Xã Bình Trung",
    code: 20848,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 513,
  },
  {
    name: "Xã Bình Nam",
    code: 20851,
    division_type: "xã",
    codename: "xa_binh_nam",
    district_code: 513,
  },
  {
    name: "Thị trấn Tiên Kỳ",
    code: 20854,
    division_type: "thị trấn",
    codename: "thi_tran_tien_ky",
    district_code: 514,
  },
  {
    name: "Xã Tiên Sơn",
    code: 20857,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 514,
  },
  {
    name: "Xã Tiên Hà",
    code: 20860,
    division_type: "xã",
    codename: "xa_tien_ha",
    district_code: 514,
  },
  {
    name: "Xã Tiên Cẩm",
    code: 20863,
    division_type: "xã",
    codename: "xa_tien_cam",
    district_code: 514,
  },
  {
    name: "Xã Tiên Châu",
    code: 20866,
    division_type: "xã",
    codename: "xa_tien_chau",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lãnh",
    code: 20869,
    division_type: "xã",
    codename: "xa_tien_lanh",
    district_code: 514,
  },
  {
    name: "Xã Tiên Ngọc",
    code: 20872,
    division_type: "xã",
    codename: "xa_tien_ngoc",
    district_code: 514,
  },
  {
    name: "Xã Tiên Hiệp",
    code: 20875,
    division_type: "xã",
    codename: "xa_tien_hiep",
    district_code: 514,
  },
  {
    name: "Xã Tiên Cảnh",
    code: 20878,
    division_type: "xã",
    codename: "xa_tien_canh",
    district_code: 514,
  },
  {
    name: "Xã Tiên Mỹ",
    code: 20881,
    division_type: "xã",
    codename: "xa_tien_my",
    district_code: 514,
  },
  {
    name: "Xã Tiên Phong",
    code: 20884,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 514,
  },
  {
    name: "Xã Tiên Thọ",
    code: 20887,
    division_type: "xã",
    codename: "xa_tien_tho",
    district_code: 514,
  },
  {
    name: "Xã Tiên An",
    code: 20890,
    division_type: "xã",
    codename: "xa_tien_an",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lộc",
    code: 20893,
    division_type: "xã",
    codename: "xa_tien_loc",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lập",
    code: 20896,
    division_type: "xã",
    codename: "xa_tien_lap",
    district_code: 514,
  },
  {
    name: "Thị trấn Trà My",
    code: 20899,
    division_type: "thị trấn",
    codename: "thi_tran_tra_my",
    district_code: 515,
  },
  {
    name: "Xã Trà Sơn",
    code: 20900,
    division_type: "xã",
    codename: "xa_tra_son",
    district_code: 515,
  },
  {
    name: "Xã Trà Kót",
    code: 20902,
    division_type: "xã",
    codename: "xa_tra_kot",
    district_code: 515,
  },
  {
    name: "Xã Trà Nú",
    code: 20905,
    division_type: "xã",
    codename: "xa_tra_nu",
    district_code: 515,
  },
  {
    name: "Xã Trà Đông",
    code: 20908,
    division_type: "xã",
    codename: "xa_tra_dong",
    district_code: 515,
  },
  {
    name: "Xã Trà Dương",
    code: 20911,
    division_type: "xã",
    codename: "xa_tra_duong",
    district_code: 515,
  },
  {
    name: "Xã Trà Giang",
    code: 20914,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 515,
  },
  {
    name: "Xã Trà Bui",
    code: 20917,
    division_type: "xã",
    codename: "xa_tra_bui",
    district_code: 515,
  },
  {
    name: "Xã Trà Đốc",
    code: 20920,
    division_type: "xã",
    codename: "xa_tra_doc",
    district_code: 515,
  },
  {
    name: "Xã Trà Tân",
    code: 20923,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 515,
  },
  {
    name: "Xã Trà Giác",
    code: 20926,
    division_type: "xã",
    codename: "xa_tra_giac",
    district_code: 515,
  },
  {
    name: "Xã Trà Giáp",
    code: 20929,
    division_type: "xã",
    codename: "xa_tra_giap",
    district_code: 515,
  },
  {
    name: "Xã Trà Ka",
    code: 20932,
    division_type: "xã",
    codename: "xa_tra_ka",
    district_code: 515,
  },
  {
    name: "Xã Trà Leng",
    code: 20935,
    division_type: "xã",
    codename: "xa_tra_leng",
    district_code: 516,
  },
  {
    name: "Xã Trà Dơn",
    code: 20938,
    division_type: "xã",
    codename: "xa_tra_don",
    district_code: 516,
  },
  {
    name: "Xã Trà Tập",
    code: 20941,
    division_type: "xã",
    codename: "xa_tra_tap",
    district_code: 516,
  },
  {
    name: "Xã Trà Mai",
    code: 20944,
    division_type: "xã",
    codename: "xa_tra_mai",
    district_code: 516,
  },
  {
    name: "Xã Trà Cang",
    code: 20947,
    division_type: "xã",
    codename: "xa_tra_cang",
    district_code: 516,
  },
  {
    name: "Xã Trà Linh",
    code: 20950,
    division_type: "xã",
    codename: "xa_tra_linh",
    district_code: 516,
  },
  {
    name: "Xã Trà Nam",
    code: 20953,
    division_type: "xã",
    codename: "xa_tra_nam",
    district_code: 516,
  },
  {
    name: "Xã Trà Don",
    code: 20956,
    division_type: "xã",
    codename: "xa_tra_don",
    district_code: 516,
  },
  {
    name: "Xã Trà Vân",
    code: 20959,
    division_type: "xã",
    codename: "xa_tra_van",
    district_code: 516,
  },
  {
    name: "Xã Trà Vinh",
    code: 20962,
    division_type: "xã",
    codename: "xa_tra_vinh",
    district_code: 516,
  },
  {
    name: "Thị trấn Núi Thành",
    code: 20965,
    division_type: "thị trấn",
    codename: "thi_tran_nui_thanh",
    district_code: 517,
  },
  {
    name: "Xã Tam Xuân I",
    code: 20968,
    division_type: "xã",
    codename: "xa_tam_xuan_i",
    district_code: 517,
  },
  {
    name: "Xã Tam Xuân II",
    code: 20971,
    division_type: "xã",
    codename: "xa_tam_xuan_ii",
    district_code: 517,
  },
  {
    name: "Xã Tam Tiến",
    code: 20974,
    division_type: "xã",
    codename: "xa_tam_tien",
    district_code: 517,
  },
  {
    name: "Xã Tam Sơn",
    code: 20977,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 517,
  },
  {
    name: "Xã Tam Thạnh",
    code: 20980,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 517,
  },
  {
    name: "Xã Tam Anh Bắc",
    code: 20983,
    division_type: "xã",
    codename: "xa_tam_anh_bac",
    district_code: 517,
  },
  {
    name: "Xã Tam Anh Nam",
    code: 20984,
    division_type: "xã",
    codename: "xa_tam_anh_nam",
    district_code: 517,
  },
  {
    name: "Xã Tam Hòa",
    code: 20986,
    division_type: "xã",
    codename: "xa_tam_hoa",
    district_code: 517,
  },
  {
    name: "Xã Tam Hiệp",
    code: 20989,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 517,
  },
  {
    name: "Xã Tam Hải",
    code: 20992,
    division_type: "xã",
    codename: "xa_tam_hai",
    district_code: 517,
  },
  {
    name: "Xã Tam Giang",
    code: 20995,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 517,
  },
  {
    name: "Xã Tam Quang",
    code: 20998,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 517,
  },
  {
    name: "Xã Tam Nghĩa",
    code: 21001,
    division_type: "xã",
    codename: "xa_tam_nghia",
    district_code: 517,
  },
  {
    name: "Xã Tam Mỹ Tây",
    code: 21004,
    division_type: "xã",
    codename: "xa_tam_my_tay",
    district_code: 517,
  },
  {
    name: "Xã Tam Mỹ Đông",
    code: 21005,
    division_type: "xã",
    codename: "xa_tam_my_dong",
    district_code: 517,
  },
  {
    name: "Xã Tam Trà",
    code: 21007,
    division_type: "xã",
    codename: "xa_tam_tra",
    district_code: 517,
  },
  {
    name: "Thị trấn Phú Thịnh",
    code: 20364,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thinh",
    district_code: 518,
  },
  {
    name: "Xã Tam Thành",
    code: 20365,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 518,
  },
  {
    name: "Xã Tam An",
    code: 20368,
    division_type: "xã",
    codename: "xa_tam_an",
    district_code: 518,
  },
  {
    name: "Xã Tam Đàn",
    code: 20374,
    division_type: "xã",
    codename: "xa_tam_dan",
    district_code: 518,
  },
  {
    name: "Xã Tam Lộc",
    code: 20377,
    division_type: "xã",
    codename: "xa_tam_loc",
    district_code: 518,
  },
  {
    name: "Xã Tam Phước",
    code: 20380,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 518,
  },
  {
    name: "Xã Tam Vinh",
    code: 20383,
    division_type: "xã",
    codename: "xa_tam_vinh",
    district_code: 518,
  },
  {
    name: "Xã Tam Thái",
    code: 20386,
    division_type: "xã",
    codename: "xa_tam_thai",
    district_code: 518,
  },
  {
    name: "Xã Tam Đại",
    code: 20387,
    division_type: "xã",
    codename: "xa_tam_dai",
    district_code: 518,
  },
  {
    name: "Xã Tam Dân",
    code: 20392,
    division_type: "xã",
    codename: "xa_tam_dan",
    district_code: 518,
  },
  {
    name: "Xã Tam Lãnh",
    code: 20395,
    division_type: "xã",
    codename: "xa_tam_lanh",
    district_code: 518,
  },
  {
    name: "Xã Quế Trung",
    code: 20656,
    division_type: "xã",
    codename: "xa_que_trung",
    district_code: 519,
  },
  {
    name: "Xã Ninh Phước",
    code: 20668,
    division_type: "xã",
    codename: "xa_ninh_phuoc",
    district_code: 519,
  },
  {
    name: "Xã Phước Ninh",
    code: 20669,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 519,
  },
  {
    name: "Xã Quế Lộc",
    code: 20671,
    division_type: "xã",
    codename: "xa_que_loc",
    district_code: 519,
  },
  {
    name: "Xã Sơn Viên",
    code: 20672,
    division_type: "xã",
    codename: "xa_son_vien",
    district_code: 519,
  },
  {
    name: "Xã Quế Lâm",
    code: 20692,
    division_type: "xã",
    codename: "xa_que_lam",
    district_code: 519,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 21010,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 522,
  },
  {
    name: "Phường Trần Phú",
    code: 21013,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 522,
  },
  {
    name: "Phường Quảng Phú",
    code: 21016,
    division_type: "phường",
    codename: "phuong_quang_phu",
    district_code: 522,
  },
  {
    name: "Phường Nghĩa Chánh",
    code: 21019,
    division_type: "phường",
    codename: "phuong_nghia_chanh",
    district_code: 522,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 21022,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 522,
  },
  {
    name: "Phường Nguyễn Nghiêm",
    code: 21025,
    division_type: "phường",
    codename: "phuong_nguyen_nghiem",
    district_code: 522,
  },
  {
    name: "Phường Nghĩa Lộ",
    code: 21028,
    division_type: "phường",
    codename: "phuong_nghia_lo",
    district_code: 522,
  },
  {
    name: "Phường Chánh Lộ",
    code: 21031,
    division_type: "phường",
    codename: "phuong_chanh_lo",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Dũng",
    code: 21034,
    division_type: "xã",
    codename: "xa_nghia_dung",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Dõng",
    code: 21037,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 522,
  },
  {
    name: "Phường Trương Quang Trọng",
    code: 21172,
    division_type: "phường",
    codename: "phuong_truong_quang_trong",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Hòa",
    code: 21187,
    division_type: "xã",
    codename: "xa_tinh_hoa",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Kỳ",
    code: 21190,
    division_type: "xã",
    codename: "xa_tinh_ky",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Thiện",
    code: 21199,
    division_type: "xã",
    codename: "xa_tinh_thien",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Ấn Đông",
    code: 21202,
    division_type: "xã",
    codename: "xa_tinh_an_dong",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Châu",
    code: 21208,
    division_type: "xã",
    codename: "xa_tinh_chau",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Khê",
    code: 21211,
    division_type: "xã",
    codename: "xa_tinh_khe",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Long",
    code: 21214,
    division_type: "xã",
    codename: "xa_tinh_long",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Ấn Tây",
    code: 21223,
    division_type: "xã",
    codename: "xa_tinh_an_tay",
    district_code: 522,
  },
  {
    name: "Xã Tịnh An",
    code: 21232,
    division_type: "xã",
    codename: "xa_tinh_an",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 21253,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Hà",
    code: 21256,
    division_type: "xã",
    codename: "xa_nghia_ha",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa An",
    code: 21262,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 522,
  },
  {
    name: "Thị trấn Châu Ổ",
    code: 21040,
    division_type: "thị trấn",
    codename: "thi_tran_chau_o",
    district_code: 524,
  },
  {
    name: "Xã Bình Thuận",
    code: 21043,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 524,
  },
  {
    name: "Xã Bình Thạnh",
    code: 21046,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Đông",
    code: 21049,
    division_type: "xã",
    codename: "xa_binh_dong",
    district_code: 524,
  },
  {
    name: "Xã Bình Chánh",
    code: 21052,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Nguyên",
    code: 21055,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 524,
  },
  {
    name: "Xã Bình Khương",
    code: 21058,
    division_type: "xã",
    codename: "xa_binh_khuong",
    district_code: 524,
  },
  {
    name: "Xã Bình Trị",
    code: 21061,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 524,
  },
  {
    name: "Xã Bình An",
    code: 21064,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 524,
  },
  {
    name: "Xã Bình Hải",
    code: 21067,
    division_type: "xã",
    codename: "xa_binh_hai",
    district_code: 524,
  },
  {
    name: "Xã Bình Dương",
    code: 21070,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 524,
  },
  {
    name: "Xã Bình Phước",
    code: 21073,
    division_type: "xã",
    codename: "xa_binh_phuoc",
    district_code: 524,
  },
  {
    name: "Xã Bình Hòa",
    code: 21079,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 524,
  },
  {
    name: "Xã Bình Trung",
    code: 21082,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 524,
  },
  {
    name: "Xã Bình Minh",
    code: 21085,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 524,
  },
  {
    name: "Xã Bình Long",
    code: 21088,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 524,
  },
  {
    name: "Xã Bình Thanh",
    code: 21091,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Chương",
    code: 21100,
    division_type: "xã",
    codename: "xa_binh_chuong",
    district_code: 524,
  },
  {
    name: "Xã Bình Hiệp",
    code: 21103,
    division_type: "xã",
    codename: "xa_binh_hiep",
    district_code: 524,
  },
  {
    name: "Xã Bình Mỹ",
    code: 21106,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 524,
  },
  {
    name: "Xã Bình Tân Phú",
    code: 21109,
    division_type: "xã",
    codename: "xa_binh_tan_phu",
    district_code: 524,
  },
  {
    name: "Xã Bình Châu",
    code: 21112,
    division_type: "xã",
    codename: "xa_binh_chau",
    district_code: 524,
  },
  {
    name: "Thị trấn Trà Xuân",
    code: 21115,
    division_type: "thị trấn",
    codename: "thi_tran_tra_xuan",
    district_code: 525,
  },
  {
    name: "Xã Trà Giang",
    code: 21118,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 525,
  },
  {
    name: "Xã Trà Thủy",
    code: 21121,
    division_type: "xã",
    codename: "xa_tra_thuy",
    district_code: 525,
  },
  {
    name: "Xã Trà Hiệp",
    code: 21124,
    division_type: "xã",
    codename: "xa_tra_hiep",
    district_code: 525,
  },
  {
    name: "Xã Trà Bình",
    code: 21127,
    division_type: "xã",
    codename: "xa_tra_binh",
    district_code: 525,
  },
  {
    name: "Xã Trà Phú",
    code: 21130,
    division_type: "xã",
    codename: "xa_tra_phu",
    district_code: 525,
  },
  {
    name: "Xã Trà Lâm",
    code: 21133,
    division_type: "xã",
    codename: "xa_tra_lam",
    district_code: 525,
  },
  {
    name: "Xã Trà Tân",
    code: 21136,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 525,
  },
  {
    name: "Xã Trà Sơn",
    code: 21139,
    division_type: "xã",
    codename: "xa_tra_son",
    district_code: 525,
  },
  {
    name: "Xã Trà Bùi",
    code: 21142,
    division_type: "xã",
    codename: "xa_tra_bui",
    district_code: 525,
  },
  {
    name: "Xã Trà Thanh",
    code: 21145,
    division_type: "xã",
    codename: "xa_tra_thanh",
    district_code: 525,
  },
  {
    name: "Xã Sơn Trà",
    code: 21148,
    division_type: "xã",
    codename: "xa_son_tra",
    district_code: 525,
  },
  {
    name: "Xã Trà Phong",
    code: 21154,
    division_type: "xã",
    codename: "xa_tra_phong",
    district_code: 525,
  },
  {
    name: "Xã Hương Trà",
    code: 21157,
    division_type: "xã",
    codename: "xa_huong_tra",
    district_code: 525,
  },
  {
    name: "Xã Trà Xinh",
    code: 21163,
    division_type: "xã",
    codename: "xa_tra_xinh",
    district_code: 525,
  },
  {
    name: "Xã Trà Tây",
    code: 21166,
    division_type: "xã",
    codename: "xa_tra_tay",
    district_code: 525,
  },
  {
    name: "Xã Tịnh Thọ",
    code: 21175,
    division_type: "xã",
    codename: "xa_tinh_tho",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Trà",
    code: 21178,
    division_type: "xã",
    codename: "xa_tinh_tra",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Phong",
    code: 21181,
    division_type: "xã",
    codename: "xa_tinh_phong",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Hiệp",
    code: 21184,
    division_type: "xã",
    codename: "xa_tinh_hiep",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Bình",
    code: 21193,
    division_type: "xã",
    codename: "xa_tinh_binh",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Đông",
    code: 21196,
    division_type: "xã",
    codename: "xa_tinh_dong",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Bắc",
    code: 21205,
    division_type: "xã",
    codename: "xa_tinh_bac",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Sơn",
    code: 21217,
    division_type: "xã",
    codename: "xa_tinh_son",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Hà",
    code: 21220,
    division_type: "xã",
    codename: "xa_tinh_ha",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Giang",
    code: 21226,
    division_type: "xã",
    codename: "xa_tinh_giang",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Minh",
    code: 21229,
    division_type: "xã",
    codename: "xa_tinh_minh",
    district_code: 527,
  },
  {
    name: "Thị trấn La Hà",
    code: 21235,
    division_type: "thị trấn",
    codename: "thi_tran_la_ha",
    district_code: 528,
  },
  {
    name: "Thị trấn Sông Vệ",
    code: 21238,
    division_type: "thị trấn",
    codename: "thi_tran_song_ve",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 21241,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thắng",
    code: 21244,
    division_type: "xã",
    codename: "xa_nghia_thang",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 21247,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Kỳ",
    code: 21250,
    division_type: "xã",
    codename: "xa_nghia_ky",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 21259,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 21268,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Điền",
    code: 21271,
    division_type: "xã",
    codename: "xa_nghia_dien",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thương",
    code: 21274,
    division_type: "xã",
    codename: "xa_nghia_thuong",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 21277,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Hiệp",
    code: 21280,
    division_type: "xã",
    codename: "xa_nghia_hiep",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Phương",
    code: 21283,
    division_type: "xã",
    codename: "xa_nghia_phuong",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Mỹ",
    code: 21286,
    division_type: "xã",
    codename: "xa_nghia_my",
    district_code: 528,
  },
  {
    name: "Thị trấn Di Lăng",
    code: 21289,
    division_type: "thị trấn",
    codename: "thi_tran_di_lang",
    district_code: 529,
  },
  {
    name: "Xã Sơn Hạ",
    code: 21292,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thành",
    code: 21295,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 529,
  },
  {
    name: "Xã Sơn Nham",
    code: 21298,
    division_type: "xã",
    codename: "xa_son_nham",
    district_code: 529,
  },
  {
    name: "Xã Sơn Bao",
    code: 21301,
    division_type: "xã",
    codename: "xa_son_bao",
    district_code: 529,
  },
  {
    name: "Xã Sơn Linh",
    code: 21304,
    division_type: "xã",
    codename: "xa_son_linh",
    district_code: 529,
  },
  {
    name: "Xã Sơn Giang",
    code: 21307,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 529,
  },
  {
    name: "Xã Sơn Trung",
    code: 21310,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thượng",
    code: 21313,
    division_type: "xã",
    codename: "xa_son_thuong",
    district_code: 529,
  },
  {
    name: "Xã Sơn Cao",
    code: 21316,
    division_type: "xã",
    codename: "xa_son_cao",
    district_code: 529,
  },
  {
    name: "Xã Sơn Hải",
    code: 21319,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thủy",
    code: 21322,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 529,
  },
  {
    name: "Xã Sơn Kỳ",
    code: 21325,
    division_type: "xã",
    codename: "xa_son_ky",
    district_code: 529,
  },
  {
    name: "Xã Sơn Ba",
    code: 21328,
    division_type: "xã",
    codename: "xa_son_ba",
    district_code: 529,
  },
  {
    name: "Xã Sơn Bua",
    code: 21331,
    division_type: "xã",
    codename: "xa_son_bua",
    district_code: 530,
  },
  {
    name: "Xã Sơn Mùa",
    code: 21334,
    division_type: "xã",
    codename: "xa_son_mua",
    district_code: 530,
  },
  {
    name: "Xã Sơn Liên",
    code: 21335,
    division_type: "xã",
    codename: "xa_son_lien",
    district_code: 530,
  },
  {
    name: "Xã Sơn Tân",
    code: 21337,
    division_type: "xã",
    codename: "xa_son_tan",
    district_code: 530,
  },
  {
    name: "Xã Sơn Màu",
    code: 21338,
    division_type: "xã",
    codename: "xa_son_mau",
    district_code: 530,
  },
  {
    name: "Xã Sơn Dung",
    code: 21340,
    division_type: "xã",
    codename: "xa_son_dung",
    district_code: 530,
  },
  {
    name: "Xã Sơn Long",
    code: 21341,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 530,
  },
  {
    name: "Xã Sơn Tinh",
    code: 21343,
    division_type: "xã",
    codename: "xa_son_tinh",
    district_code: 530,
  },
  {
    name: "Xã Sơn Lập",
    code: 21346,
    division_type: "xã",
    codename: "xa_son_lap",
    district_code: 530,
  },
  {
    name: "Xã Long Sơn",
    code: 21349,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 531,
  },
  {
    name: "Xã Long Mai",
    code: 21352,
    division_type: "xã",
    codename: "xa_long_mai",
    district_code: 531,
  },
  {
    name: "Xã Thanh An",
    code: 21355,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 531,
  },
  {
    name: "Xã Long Môn",
    code: 21358,
    division_type: "xã",
    codename: "xa_long_mon",
    district_code: 531,
  },
  {
    name: "Xã Long Hiệp",
    code: 21361,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 531,
  },
  {
    name: "Thị trấn Chợ Chùa",
    code: 21364,
    division_type: "thị trấn",
    codename: "thi_tran_cho_chua",
    district_code: 532,
  },
  {
    name: "Xã Hành Thuận",
    code: 21367,
    division_type: "xã",
    codename: "xa_hanh_thuan",
    district_code: 532,
  },
  {
    name: "Xã Hành Dũng",
    code: 21370,
    division_type: "xã",
    codename: "xa_hanh_dung",
    district_code: 532,
  },
  {
    name: "Xã Hành Trung",
    code: 21373,
    division_type: "xã",
    codename: "xa_hanh_trung",
    district_code: 532,
  },
  {
    name: "Xã Hành Nhân",
    code: 21376,
    division_type: "xã",
    codename: "xa_hanh_nhan",
    district_code: 532,
  },
  {
    name: "Xã Hành Đức",
    code: 21379,
    division_type: "xã",
    codename: "xa_hanh_duc",
    district_code: 532,
  },
  {
    name: "Xã Hành Minh",
    code: 21382,
    division_type: "xã",
    codename: "xa_hanh_minh",
    district_code: 532,
  },
  {
    name: "Xã Hành Phước",
    code: 21385,
    division_type: "xã",
    codename: "xa_hanh_phuoc",
    district_code: 532,
  },
  {
    name: "Xã Hành Thiện",
    code: 21388,
    division_type: "xã",
    codename: "xa_hanh_thien",
    district_code: 532,
  },
  {
    name: "Xã Hành Thịnh",
    code: 21391,
    division_type: "xã",
    codename: "xa_hanh_thinh",
    district_code: 532,
  },
  {
    name: "Xã Hành Tín Tây",
    code: 21394,
    division_type: "xã",
    codename: "xa_hanh_tin_tay",
    district_code: 532,
  },
  {
    name: "Xã Hành Tín Đông",
    code: 21397,
    division_type: "xã",
    codename: "xa_hanh_tin_dong",
    district_code: 532,
  },
  {
    name: "Thị trấn Mộ Đức",
    code: 21400,
    division_type: "thị trấn",
    codename: "thi_tran_mo_duc",
    district_code: 533,
  },
  {
    name: "Xã Đức Lợi",
    code: 21403,
    division_type: "xã",
    codename: "xa_duc_loi",
    district_code: 533,
  },
  {
    name: "Xã Đức Thắng",
    code: 21406,
    division_type: "xã",
    codename: "xa_duc_thang",
    district_code: 533,
  },
  {
    name: "Xã Đức Nhuận",
    code: 21409,
    division_type: "xã",
    codename: "xa_duc_nhuan",
    district_code: 533,
  },
  {
    name: "Xã Đức Chánh",
    code: 21412,
    division_type: "xã",
    codename: "xa_duc_chanh",
    district_code: 533,
  },
  {
    name: "Xã Đức Hiệp",
    code: 21415,
    division_type: "xã",
    codename: "xa_duc_hiep",
    district_code: 533,
  },
  {
    name: "Xã Đức Minh",
    code: 21418,
    division_type: "xã",
    codename: "xa_duc_minh",
    district_code: 533,
  },
  {
    name: "Xã Đức Thạnh",
    code: 21421,
    division_type: "xã",
    codename: "xa_duc_thanh",
    district_code: 533,
  },
  {
    name: "Xã Đức Hòa",
    code: 21424,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 533,
  },
  {
    name: "Xã Đức Tân",
    code: 21427,
    division_type: "xã",
    codename: "xa_duc_tan",
    district_code: 533,
  },
  {
    name: "Xã Đức Phú",
    code: 21430,
    division_type: "xã",
    codename: "xa_duc_phu",
    district_code: 533,
  },
  {
    name: "Xã Đức Phong",
    code: 21433,
    division_type: "xã",
    codename: "xa_duc_phong",
    district_code: 533,
  },
  {
    name: "Xã Đức Lân",
    code: 21436,
    division_type: "xã",
    codename: "xa_duc_lan",
    district_code: 533,
  },
  {
    name: "Phường Nguyễn Nghiêm",
    code: 21439,
    division_type: "phường",
    codename: "phuong_nguyen_nghiem",
    district_code: 534,
  },
  {
    name: "Xã Phổ An",
    code: 21442,
    division_type: "xã",
    codename: "xa_pho_an",
    district_code: 534,
  },
  {
    name: "Xã Phổ Phong",
    code: 21445,
    division_type: "xã",
    codename: "xa_pho_phong",
    district_code: 534,
  },
  {
    name: "Xã Phổ Thuận",
    code: 21448,
    division_type: "xã",
    codename: "xa_pho_thuan",
    district_code: 534,
  },
  {
    name: "Phường Phổ Văn",
    code: 21451,
    division_type: "phường",
    codename: "phuong_pho_van",
    district_code: 534,
  },
  {
    name: "Phường Phổ Quang",
    code: 21454,
    division_type: "phường",
    codename: "phuong_pho_quang",
    district_code: 534,
  },
  {
    name: "Xã Phổ Nhơn",
    code: 21457,
    division_type: "xã",
    codename: "xa_pho_nhon",
    district_code: 534,
  },
  {
    name: "Phường Phổ Ninh",
    code: 21460,
    division_type: "phường",
    codename: "phuong_pho_ninh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Minh",
    code: 21463,
    division_type: "phường",
    codename: "phuong_pho_minh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Vinh",
    code: 21466,
    division_type: "phường",
    codename: "phuong_pho_vinh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Hòa",
    code: 21469,
    division_type: "phường",
    codename: "phuong_pho_hoa",
    district_code: 534,
  },
  {
    name: "Xã Phổ Cường",
    code: 21472,
    division_type: "xã",
    codename: "xa_pho_cuong",
    district_code: 534,
  },
  {
    name: "Xã Phổ Khánh",
    code: 21475,
    division_type: "xã",
    codename: "xa_pho_khanh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Thạnh",
    code: 21478,
    division_type: "phường",
    codename: "phuong_pho_thanh",
    district_code: 534,
  },
  {
    name: "Xã Phổ Châu",
    code: 21481,
    division_type: "xã",
    codename: "xa_pho_chau",
    district_code: 534,
  },
  {
    name: "Thị trấn Ba Tơ",
    code: 21484,
    division_type: "thị trấn",
    codename: "thi_tran_ba_to",
    district_code: 535,
  },
  {
    name: "Xã Ba Điền",
    code: 21487,
    division_type: "xã",
    codename: "xa_ba_dien",
    district_code: 535,
  },
  {
    name: "Xã Ba Vinh",
    code: 21490,
    division_type: "xã",
    codename: "xa_ba_vinh",
    district_code: 535,
  },
  {
    name: "Xã Ba Thành",
    code: 21493,
    division_type: "xã",
    codename: "xa_ba_thanh",
    district_code: 535,
  },
  {
    name: "Xã Ba Động",
    code: 21496,
    division_type: "xã",
    codename: "xa_ba_dong",
    district_code: 535,
  },
  {
    name: "Xã Ba Dinh",
    code: 21499,
    division_type: "xã",
    codename: "xa_ba_dinh",
    district_code: 535,
  },
  {
    name: "Xã Ba Giang",
    code: 21500,
    division_type: "xã",
    codename: "xa_ba_giang",
    district_code: 535,
  },
  {
    name: "Xã Ba Liên",
    code: 21502,
    division_type: "xã",
    codename: "xa_ba_lien",
    district_code: 535,
  },
  {
    name: "Xã Ba Ngạc",
    code: 21505,
    division_type: "xã",
    codename: "xa_ba_ngac",
    district_code: 535,
  },
  {
    name: "Xã Ba Khâm",
    code: 21508,
    division_type: "xã",
    codename: "xa_ba_kham",
    district_code: 535,
  },
  {
    name: "Xã Ba Cung",
    code: 21511,
    division_type: "xã",
    codename: "xa_ba_cung",
    district_code: 535,
  },
  {
    name: "Xã Ba Tiêu",
    code: 21517,
    division_type: "xã",
    codename: "xa_ba_tieu",
    district_code: 535,
  },
  {
    name: "Xã Ba Trang",
    code: 21520,
    division_type: "xã",
    codename: "xa_ba_trang",
    district_code: 535,
  },
  {
    name: "Xã Ba Tô",
    code: 21523,
    division_type: "xã",
    codename: "xa_ba_to",
    district_code: 535,
  },
  {
    name: "Xã Ba Bích",
    code: 21526,
    division_type: "xã",
    codename: "xa_ba_bich",
    district_code: 535,
  },
  {
    name: "Xã Ba Vì",
    code: 21529,
    division_type: "xã",
    codename: "xa_ba_vi",
    district_code: 535,
  },
  {
    name: "Xã Ba Lế",
    code: 21532,
    division_type: "xã",
    codename: "xa_ba_le",
    district_code: 535,
  },
  {
    name: "Xã Ba Nam",
    code: 21535,
    division_type: "xã",
    codename: "xa_ba_nam",
    district_code: 535,
  },
  {
    name: "Xã Ba Xa",
    code: 21538,
    division_type: "xã",
    codename: "xa_ba_xa",
    district_code: 535,
  },
  {
    name: "Phường Nhơn Bình",
    code: 21550,
    division_type: "phường",
    codename: "phuong_nhon_binh",
    district_code: 540,
  },
  {
    name: "Phường Nhơn Phú",
    code: 21553,
    division_type: "phường",
    codename: "phuong_nhon_phu",
    district_code: 540,
  },
  {
    name: "Phường Đống Đa",
    code: 21556,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 540,
  },
  {
    name: "Phường Trần Quang Diệu",
    code: 21559,
    division_type: "phường",
    codename: "phuong_tran_quang_dieu",
    district_code: 540,
  },
  {
    name: "Phường Hải Cảng",
    code: 21562,
    division_type: "phường",
    codename: "phuong_hai_cang",
    district_code: 540,
  },
  {
    name: "Phường Quang Trung",
    code: 21565,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 540,
  },
  {
    name: "Phường Thị Nại",
    code: 21568,
    division_type: "phường",
    codename: "phuong_thi_nai",
    district_code: 540,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 21571,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 540,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 21574,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 540,
  },
  {
    name: "Phường Ngô Mây",
    code: 21577,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 540,
  },
  {
    name: "Phường Lý Thường Kiệt",
    code: 21580,
    division_type: "phường",
    codename: "phuong_ly_thuong_kiet",
    district_code: 540,
  },
  {
    name: "Phường Lê Lợi",
    code: 21583,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 540,
  },
  {
    name: "Phường Trần Phú",
    code: 21586,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 540,
  },
  {
    name: "Phường Bùi Thị Xuân",
    code: 21589,
    division_type: "phường",
    codename: "phuong_bui_thi_xuan",
    district_code: 540,
  },
  {
    name: "Phường Nguyễn Văn Cừ",
    code: 21592,
    division_type: "phường",
    codename: "phuong_nguyen_van_cu",
    district_code: 540,
  },
  {
    name: "Phường Ghềnh Ráng",
    code: 21595,
    division_type: "phường",
    codename: "phuong_ghenh_rang",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Lý",
    code: 21598,
    division_type: "xã",
    codename: "xa_nhon_ly",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Hội",
    code: 21601,
    division_type: "xã",
    codename: "xa_nhon_hoi",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Hải",
    code: 21604,
    division_type: "xã",
    codename: "xa_nhon_hai",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Châu",
    code: 21607,
    division_type: "xã",
    codename: "xa_nhon_chau",
    district_code: 540,
  },
  {
    name: "Xã Phước Mỹ",
    code: 21991,
    division_type: "xã",
    codename: "xa_phuoc_my",
    district_code: 540,
  },
  {
    name: "Thị trấn An Lão",
    code: 21609,
    division_type: "thị trấn",
    codename: "thi_tran_an_lao",
    district_code: 542,
  },
  {
    name: "Xã An Hưng",
    code: 21610,
    division_type: "xã",
    codename: "xa_an_hung",
    district_code: 542,
  },
  {
    name: "Xã An Trung",
    code: 21613,
    division_type: "xã",
    codename: "xa_an_trung",
    district_code: 542,
  },
  {
    name: "Xã An Dũng",
    code: 21616,
    division_type: "xã",
    codename: "xa_an_dung",
    district_code: 542,
  },
  {
    name: "Xã An Vinh",
    code: 21619,
    division_type: "xã",
    codename: "xa_an_vinh",
    district_code: 542,
  },
  {
    name: "Xã An Toàn",
    code: 21622,
    division_type: "xã",
    codename: "xa_an_toan",
    district_code: 542,
  },
  {
    name: "Xã An Tân",
    code: 21625,
    division_type: "xã",
    codename: "xa_an_tan",
    district_code: 542,
  },
  {
    name: "Xã An Hòa",
    code: 21628,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 542,
  },
  {
    name: "Xã An Quang",
    code: 21631,
    division_type: "xã",
    codename: "xa_an_quang",
    district_code: 542,
  },
  {
    name: "Xã An Nghĩa",
    code: 21634,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 542,
  },
  {
    name: "Phường Tam Quan",
    code: 21637,
    division_type: "phường",
    codename: "phuong_tam_quan",
    district_code: 543,
  },
  {
    name: "Phường Bồng Sơn",
    code: 21640,
    division_type: "phường",
    codename: "phuong_bong_son",
    district_code: 543,
  },
  {
    name: "Xã Hoài Sơn",
    code: 21643,
    division_type: "xã",
    codename: "xa_hoai_son",
    district_code: 543,
  },
  {
    name: "Xã Hoài Châu Bắc",
    code: 21646,
    division_type: "xã",
    codename: "xa_hoai_chau_bac",
    district_code: 543,
  },
  {
    name: "Xã Hoài Châu",
    code: 21649,
    division_type: "xã",
    codename: "xa_hoai_chau",
    district_code: 543,
  },
  {
    name: "Xã Hoài Phú",
    code: 21652,
    division_type: "xã",
    codename: "xa_hoai_phu",
    district_code: 543,
  },
  {
    name: "Phường Tam Quan Bắc",
    code: 21655,
    division_type: "phường",
    codename: "phuong_tam_quan_bac",
    district_code: 543,
  },
  {
    name: "Phường Tam Quan Nam",
    code: 21658,
    division_type: "phường",
    codename: "phuong_tam_quan_nam",
    district_code: 543,
  },
  {
    name: "Phường Hoài Hảo",
    code: 21661,
    division_type: "phường",
    codename: "phuong_hoai_hao",
    district_code: 543,
  },
  {
    name: "Phường Hoài Thanh Tây",
    code: 21664,
    division_type: "phường",
    codename: "phuong_hoai_thanh_tay",
    district_code: 543,
  },
  {
    name: "Phường Hoài Thanh",
    code: 21667,
    division_type: "phường",
    codename: "phuong_hoai_thanh",
    district_code: 543,
  },
  {
    name: "Phường Hoài Hương",
    code: 21670,
    division_type: "phường",
    codename: "phuong_hoai_huong",
    district_code: 543,
  },
  {
    name: "Phường Hoài Tân",
    code: 21673,
    division_type: "phường",
    codename: "phuong_hoai_tan",
    district_code: 543,
  },
  {
    name: "Xã Hoài Hải",
    code: 21676,
    division_type: "xã",
    codename: "xa_hoai_hai",
    district_code: 543,
  },
  {
    name: "Phường Hoài Xuân",
    code: 21679,
    division_type: "phường",
    codename: "phuong_hoai_xuan",
    district_code: 543,
  },
  {
    name: "Xã Hoài Mỹ",
    code: 21682,
    division_type: "xã",
    codename: "xa_hoai_my",
    district_code: 543,
  },
  {
    name: "Phường Hoài Đức",
    code: 21685,
    division_type: "phường",
    codename: "phuong_hoai_duc",
    district_code: 543,
  },
  {
    name: "Thị trấn Tăng Bạt Hổ",
    code: 21688,
    division_type: "thị trấn",
    codename: "thi_tran_tang_bat_ho",
    district_code: 544,
  },
  {
    name: "Xã Ân Hảo Tây",
    code: 21690,
    division_type: "xã",
    codename: "xa_an_hao_tay",
    district_code: 544,
  },
  {
    name: "Xã Ân Hảo Đông",
    code: 21691,
    division_type: "xã",
    codename: "xa_an_hao_dong",
    district_code: 544,
  },
  {
    name: "Xã Ân Sơn",
    code: 21694,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 544,
  },
  {
    name: "Xã Ân Mỹ",
    code: 21697,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 544,
  },
  {
    name: "Xã Đak Mang",
    code: 21700,
    division_type: "xã",
    codename: "xa_dak_mang",
    district_code: 544,
  },
  {
    name: "Xã Ân Tín",
    code: 21703,
    division_type: "xã",
    codename: "xa_an_tin",
    district_code: 544,
  },
  {
    name: "Xã Ân Thạnh",
    code: 21706,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 544,
  },
  {
    name: "Xã Ân Phong",
    code: 21709,
    division_type: "xã",
    codename: "xa_an_phong",
    district_code: 544,
  },
  {
    name: "Xã Ân Đức",
    code: 21712,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 544,
  },
  {
    name: "Xã Ân Hữu",
    code: 21715,
    division_type: "xã",
    codename: "xa_an_huu",
    district_code: 544,
  },
  {
    name: "Xã Bok Tới",
    code: 21718,
    division_type: "xã",
    codename: "xa_bok_toi",
    district_code: 544,
  },
  {
    name: "Xã Ân Tường Tây",
    code: 21721,
    division_type: "xã",
    codename: "xa_an_tuong_tay",
    district_code: 544,
  },
  {
    name: "Xã Ân Tường Đông",
    code: 21724,
    division_type: "xã",
    codename: "xa_an_tuong_dong",
    district_code: 544,
  },
  {
    name: "Xã Ân Nghĩa",
    code: 21727,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 544,
  },
  {
    name: "Thị trấn Phù Mỹ",
    code: 21730,
    division_type: "thị trấn",
    codename: "thi_tran_phu_my",
    district_code: 545,
  },
  {
    name: "Thị trấn Bình Dương",
    code: 21733,
    division_type: "thị trấn",
    codename: "thi_tran_binh_duong",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Đức",
    code: 21736,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Châu",
    code: 21739,
    division_type: "xã",
    codename: "xa_my_chau",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thắng",
    code: 21742,
    division_type: "xã",
    codename: "xa_my_thang",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 21745,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Lợi",
    code: 21748,
    division_type: "xã",
    codename: "xa_my_loi",
    district_code: 545,
  },
  {
    name: "Xã Mỹ An",
    code: 21751,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Phong",
    code: 21754,
    division_type: "xã",
    codename: "xa_my_phong",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Trinh",
    code: 21757,
    division_type: "xã",
    codename: "xa_my_trinh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thọ",
    code: 21760,
    division_type: "xã",
    codename: "xa_my_tho",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 21763,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thành",
    code: 21766,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 21769,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Quang",
    code: 21772,
    division_type: "xã",
    codename: "xa_my_quang",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 21775,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Tài",
    code: 21778,
    division_type: "xã",
    codename: "xa_my_tai",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Cát",
    code: 21781,
    division_type: "xã",
    codename: "xa_my_cat",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Chánh Tây",
    code: 21784,
    division_type: "xã",
    codename: "xa_my_chanh_tay",
    district_code: 545,
  },
  {
    name: "Thị trấn Vĩnh Thạnh",
    code: 21786,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 21787,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 21790,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 21796,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 21799,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 21801,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 21802,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 21804,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 21805,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 546,
  },
  {
    name: "Thị trấn Phú Phong",
    code: 21808,
    division_type: "thị trấn",
    codename: "thi_tran_phu_phong",
    district_code: 547,
  },
  {
    name: "Xã Bình Tân",
    code: 21811,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 547,
  },
  {
    name: "Xã Tây Thuận",
    code: 21814,
    division_type: "xã",
    codename: "xa_tay_thuan",
    district_code: 547,
  },
  {
    name: "Xã Bình Thuận",
    code: 21817,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 547,
  },
  {
    name: "Xã Tây Giang",
    code: 21820,
    division_type: "xã",
    codename: "xa_tay_giang",
    district_code: 547,
  },
  {
    name: "Xã Bình Thành",
    code: 21823,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 547,
  },
  {
    name: "Xã Tây An",
    code: 21826,
    division_type: "xã",
    codename: "xa_tay_an",
    district_code: 547,
  },
  {
    name: "Xã Bình Hòa",
    code: 21829,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 547,
  },
  {
    name: "Xã Tây Bình",
    code: 21832,
    division_type: "xã",
    codename: "xa_tay_binh",
    district_code: 547,
  },
  {
    name: "Xã Bình Tường",
    code: 21835,
    division_type: "xã",
    codename: "xa_binh_tuong",
    district_code: 547,
  },
  {
    name: "Xã Tây Vinh",
    code: 21838,
    division_type: "xã",
    codename: "xa_tay_vinh",
    district_code: 547,
  },
  {
    name: "Xã Vĩnh An",
    code: 21841,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 547,
  },
  {
    name: "Xã Tây Xuân",
    code: 21844,
    division_type: "xã",
    codename: "xa_tay_xuan",
    district_code: 547,
  },
  {
    name: "Xã Bình Nghi",
    code: 21847,
    division_type: "xã",
    codename: "xa_binh_nghi",
    district_code: 547,
  },
  {
    name: "Xã Tây Phú",
    code: 21850,
    division_type: "xã",
    codename: "xa_tay_phu",
    district_code: 547,
  },
  {
    name: "Thị trấn Ngô Mây",
    code: 21853,
    division_type: "thị trấn",
    codename: "thi_tran_ngo_may",
    district_code: 548,
  },
  {
    name: "Xã Cát Sơn",
    code: 21856,
    division_type: "xã",
    codename: "xa_cat_son",
    district_code: 548,
  },
  {
    name: "Xã Cát Minh",
    code: 21859,
    division_type: "xã",
    codename: "xa_cat_minh",
    district_code: 548,
  },
  {
    name: "Xã Cát Khánh",
    code: 21862,
    division_type: "xã",
    codename: "xa_cat_khanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Tài",
    code: 21865,
    division_type: "xã",
    codename: "xa_cat_tai",
    district_code: 548,
  },
  {
    name: "Xã Cát Lâm",
    code: 21868,
    division_type: "xã",
    codename: "xa_cat_lam",
    district_code: 548,
  },
  {
    name: "Xã Cát Hanh",
    code: 21871,
    division_type: "xã",
    codename: "xa_cat_hanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Thành",
    code: 21874,
    division_type: "xã",
    codename: "xa_cat_thanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Trinh",
    code: 21877,
    division_type: "xã",
    codename: "xa_cat_trinh",
    district_code: 548,
  },
  {
    name: "Xã Cát Hải",
    code: 21880,
    division_type: "xã",
    codename: "xa_cat_hai",
    district_code: 548,
  },
  {
    name: "Xã Cát Hiệp",
    code: 21883,
    division_type: "xã",
    codename: "xa_cat_hiep",
    district_code: 548,
  },
  {
    name: "Xã Cát Nhơn",
    code: 21886,
    division_type: "xã",
    codename: "xa_cat_nhon",
    district_code: 548,
  },
  {
    name: "Xã Cát Hưng",
    code: 21889,
    division_type: "xã",
    codename: "xa_cat_hung",
    district_code: 548,
  },
  {
    name: "Xã Cát Tường",
    code: 21892,
    division_type: "xã",
    codename: "xa_cat_tuong",
    district_code: 548,
  },
  {
    name: "Xã Cát Tân",
    code: 21895,
    division_type: "xã",
    codename: "xa_cat_tan",
    district_code: 548,
  },
  {
    name: "Thị trấn Cát Tiến",
    code: 21898,
    division_type: "thị trấn",
    codename: "thi_tran_cat_tien",
    district_code: 548,
  },
  {
    name: "Xã Cát Thắng",
    code: 21901,
    division_type: "xã",
    codename: "xa_cat_thang",
    district_code: 548,
  },
  {
    name: "Xã Cát Chánh",
    code: 21904,
    division_type: "xã",
    codename: "xa_cat_chanh",
    district_code: 548,
  },
  {
    name: "Phường Bình Định",
    code: 21907,
    division_type: "phường",
    codename: "phuong_binh_dinh",
    district_code: 549,
  },
  {
    name: "Phường Đập Đá",
    code: 21910,
    division_type: "phường",
    codename: "phuong_dap_da",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 21913,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Thành",
    code: 21916,
    division_type: "phường",
    codename: "phuong_nhon_thanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Hạnh",
    code: 21919,
    division_type: "xã",
    codename: "xa_nhon_hanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Hậu",
    code: 21922,
    division_type: "xã",
    codename: "xa_nhon_hau",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Phong",
    code: 21925,
    division_type: "xã",
    codename: "xa_nhon_phong",
    district_code: 549,
  },
  {
    name: "Xã Nhơn An",
    code: 21928,
    division_type: "xã",
    codename: "xa_nhon_an",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Phúc",
    code: 21931,
    division_type: "xã",
    codename: "xa_nhon_phuc",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Hưng",
    code: 21934,
    division_type: "phường",
    codename: "phuong_nhon_hung",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Khánh",
    code: 21937,
    division_type: "xã",
    codename: "xa_nhon_khanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Lộc",
    code: 21940,
    division_type: "xã",
    codename: "xa_nhon_loc",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Hoà",
    code: 21943,
    division_type: "phường",
    codename: "phuong_nhon_hoa",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Tân",
    code: 21946,
    division_type: "xã",
    codename: "xa_nhon_tan",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Thọ",
    code: 21949,
    division_type: "xã",
    codename: "xa_nhon_tho",
    district_code: 549,
  },
  {
    name: "Thị trấn Tuy Phước",
    code: 21952,
    division_type: "thị trấn",
    codename: "thi_tran_tuy_phuoc",
    district_code: 550,
  },
  {
    name: "Thị trấn Diêu Trì",
    code: 21955,
    division_type: "thị trấn",
    codename: "thi_tran_dieu_tri",
    district_code: 550,
  },
  {
    name: "Xã Phước Thắng",
    code: 21958,
    division_type: "xã",
    codename: "xa_phuoc_thang",
    district_code: 550,
  },
  {
    name: "Xã Phước Hưng",
    code: 21961,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 550,
  },
  {
    name: "Xã Phước Quang",
    code: 21964,
    division_type: "xã",
    codename: "xa_phuoc_quang",
    district_code: 550,
  },
  {
    name: "Xã Phước Hòa",
    code: 21967,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 550,
  },
  {
    name: "Xã Phước Sơn",
    code: 21970,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 550,
  },
  {
    name: "Xã Phước Hiệp",
    code: 21973,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 550,
  },
  {
    name: "Xã Phước Lộc",
    code: 21976,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 550,
  },
  {
    name: "Xã Phước Nghĩa",
    code: 21979,
    division_type: "xã",
    codename: "xa_phuoc_nghia",
    district_code: 550,
  },
  {
    name: "Xã Phước Thuận",
    code: 21982,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 550,
  },
  {
    name: "Xã Phước An",
    code: 21985,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 550,
  },
  {
    name: "Xã Phước Thành",
    code: 21988,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 550,
  },
  {
    name: "Thị trấn Vân Canh",
    code: 21994,
    division_type: "thị trấn",
    codename: "thi_tran_van_canh",
    district_code: 551,
  },
  {
    name: "Xã Canh Liên",
    code: 21997,
    division_type: "xã",
    codename: "xa_canh_lien",
    district_code: 551,
  },
  {
    name: "Xã Canh Hiệp",
    code: 22000,
    division_type: "xã",
    codename: "xa_canh_hiep",
    district_code: 551,
  },
  {
    name: "Xã Canh Vinh",
    code: 22003,
    division_type: "xã",
    codename: "xa_canh_vinh",
    district_code: 551,
  },
  {
    name: "Xã Canh Hiển",
    code: 22006,
    division_type: "xã",
    codename: "xa_canh_hien",
    district_code: 551,
  },
  {
    name: "Xã Canh Thuận",
    code: 22009,
    division_type: "xã",
    codename: "xa_canh_thuan",
    district_code: 551,
  },
  {
    name: "Xã Canh Hòa",
    code: 22012,
    division_type: "xã",
    codename: "xa_canh_hoa",
    district_code: 551,
  },
  {
    name: "Phường 1",
    code: 22015,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 555,
  },
  {
    name: "Phường 8",
    code: 22018,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 555,
  },
  {
    name: "Phường 2",
    code: 22021,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 555,
  },
  {
    name: "Phường 9",
    code: 22024,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 555,
  },
  {
    name: "Phường 3",
    code: 22027,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 555,
  },
  {
    name: "Phường 4",
    code: 22030,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 555,
  },
  {
    name: "Phường 5",
    code: 22033,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 555,
  },
  {
    name: "Phường 7",
    code: 22036,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 555,
  },
  {
    name: "Phường 6",
    code: 22039,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 555,
  },
  {
    name: "Phường Phú Thạnh",
    code: 22040,
    division_type: "phường",
    codename: "phuong_phu_thanh",
    district_code: 555,
  },
  {
    name: "Phường Phú Đông",
    code: 22041,
    division_type: "phường",
    codename: "phuong_phu_dong",
    district_code: 555,
  },
  {
    name: "Xã Hòa Kiến",
    code: 22042,
    division_type: "xã",
    codename: "xa_hoa_kien",
    district_code: 555,
  },
  {
    name: "Xã Bình Kiến",
    code: 22045,
    division_type: "xã",
    codename: "xa_binh_kien",
    district_code: 555,
  },
  {
    name: "Xã Bình Ngọc",
    code: 22048,
    division_type: "xã",
    codename: "xa_binh_ngoc",
    district_code: 555,
  },
  {
    name: "Xã An Phú",
    code: 22162,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 555,
  },
  {
    name: "Phường Phú Lâm",
    code: 22240,
    division_type: "phường",
    codename: "phuong_phu_lam",
    district_code: 555,
  },
  {
    name: "Phường Xuân Phú",
    code: 22051,
    division_type: "phường",
    codename: "phuong_xuan_phu",
    district_code: 557,
  },
  {
    name: "Xã Xuân Lâm",
    code: 22052,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 557,
  },
  {
    name: "Phường Xuân Thành",
    code: 22053,
    division_type: "phường",
    codename: "phuong_xuan_thanh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Hải",
    code: 22054,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 557,
  },
  {
    name: "Xã Xuân Lộc",
    code: 22057,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 557,
  },
  {
    name: "Xã Xuân Bình",
    code: 22060,
    division_type: "xã",
    codename: "xa_xuan_binh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Cảnh",
    code: 22066,
    division_type: "xã",
    codename: "xa_xuan_canh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thịnh",
    code: 22069,
    division_type: "xã",
    codename: "xa_xuan_thinh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Phương",
    code: 22072,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 557,
  },
  {
    name: "Phường Xuân Yên",
    code: 22073,
    division_type: "phường",
    codename: "phuong_xuan_yen",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thọ 1",
    code: 22075,
    division_type: "xã",
    codename: "xa_xuan_tho_1",
    district_code: 557,
  },
  {
    name: "Phường Xuân Đài",
    code: 22076,
    division_type: "phường",
    codename: "phuong_xuan_dai",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thọ 2",
    code: 22078,
    division_type: "xã",
    codename: "xa_xuan_tho_2",
    district_code: 557,
  },
  {
    name: "Thị trấn La Hai",
    code: 22081,
    division_type: "thị trấn",
    codename: "thi_tran_la_hai",
    district_code: 558,
  },
  {
    name: "Xã Đa Lộc",
    code: 22084,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 558,
  },
  {
    name: "Xã Phú Mỡ",
    code: 22087,
    division_type: "xã",
    codename: "xa_phu_mo",
    district_code: 558,
  },
  {
    name: "Xã Xuân Lãnh",
    code: 22090,
    division_type: "xã",
    codename: "xa_xuan_lanh",
    district_code: 558,
  },
  {
    name: "Xã Xuân Long",
    code: 22093,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 1",
    code: 22096,
    division_type: "xã",
    codename: "xa_xuan_quang_1",
    district_code: 558,
  },
  {
    name: "Xã Xuân Sơn Bắc",
    code: 22099,
    division_type: "xã",
    codename: "xa_xuan_son_bac",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 2",
    code: 22102,
    division_type: "xã",
    codename: "xa_xuan_quang_2",
    district_code: 558,
  },
  {
    name: "Xã Xuân Sơn Nam",
    code: 22105,
    division_type: "xã",
    codename: "xa_xuan_son_nam",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 3",
    code: 22108,
    division_type: "xã",
    codename: "xa_xuan_quang_3",
    district_code: 558,
  },
  {
    name: "Xã Xuân Phước",
    code: 22111,
    division_type: "xã",
    codename: "xa_xuan_phuoc",
    district_code: 558,
  },
  {
    name: "Thị trấn Chí Thạnh",
    code: 22114,
    division_type: "thị trấn",
    codename: "thi_tran_chi_thanh",
    district_code: 559,
  },
  {
    name: "Xã An Dân",
    code: 22117,
    division_type: "xã",
    codename: "xa_an_dan",
    district_code: 559,
  },
  {
    name: "Xã An Ninh Tây",
    code: 22120,
    division_type: "xã",
    codename: "xa_an_ninh_tay",
    district_code: 559,
  },
  {
    name: "Xã An Ninh Đông",
    code: 22123,
    division_type: "xã",
    codename: "xa_an_ninh_dong",
    district_code: 559,
  },
  {
    name: "Xã An Thạch",
    code: 22126,
    division_type: "xã",
    codename: "xa_an_thach",
    district_code: 559,
  },
  {
    name: "Xã An Định",
    code: 22129,
    division_type: "xã",
    codename: "xa_an_dinh",
    district_code: 559,
  },
  {
    name: "Xã An Nghiệp",
    code: 22132,
    division_type: "xã",
    codename: "xa_an_nghiep",
    district_code: 559,
  },
  {
    name: "Xã An Cư",
    code: 22138,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 559,
  },
  {
    name: "Xã An Xuân",
    code: 22141,
    division_type: "xã",
    codename: "xa_an_xuan",
    district_code: 559,
  },
  {
    name: "Xã An Lĩnh",
    code: 22144,
    division_type: "xã",
    codename: "xa_an_linh",
    district_code: 559,
  },
  {
    name: "Xã An Hòa Hải",
    code: 22147,
    division_type: "xã",
    codename: "xa_an_hoa_hai",
    district_code: 559,
  },
  {
    name: "Xã An Hiệp",
    code: 22150,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 559,
  },
  {
    name: "Xã An Mỹ",
    code: 22153,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 559,
  },
  {
    name: "Xã An Chấn",
    code: 22156,
    division_type: "xã",
    codename: "xa_an_chan",
    district_code: 559,
  },
  {
    name: "Xã An Thọ",
    code: 22159,
    division_type: "xã",
    codename: "xa_an_tho",
    district_code: 559,
  },
  {
    name: "Thị trấn Củng Sơn",
    code: 22165,
    division_type: "thị trấn",
    codename: "thi_tran_cung_son",
    district_code: 560,
  },
  {
    name: "Xã Phước Tân",
    code: 22168,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 560,
  },
  {
    name: "Xã Sơn Hội",
    code: 22171,
    division_type: "xã",
    codename: "xa_son_hoi",
    district_code: 560,
  },
  {
    name: "Xã Sơn Định",
    code: 22174,
    division_type: "xã",
    codename: "xa_son_dinh",
    district_code: 560,
  },
  {
    name: "Xã Sơn Long",
    code: 22177,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 560,
  },
  {
    name: "Xã Cà Lúi",
    code: 22180,
    division_type: "xã",
    codename: "xa_ca_lui",
    district_code: 560,
  },
  {
    name: "Xã Sơn Phước",
    code: 22183,
    division_type: "xã",
    codename: "xa_son_phuoc",
    district_code: 560,
  },
  {
    name: "Xã Sơn Xuân",
    code: 22186,
    division_type: "xã",
    codename: "xa_son_xuan",
    district_code: 560,
  },
  {
    name: "Xã Sơn Nguyên",
    code: 22189,
    division_type: "xã",
    codename: "xa_son_nguyen",
    district_code: 560,
  },
  {
    name: "Xã Eachà Rang",
    code: 22192,
    division_type: "xã",
    codename: "xa_eacha_rang",
    district_code: 560,
  },
  {
    name: "Xã Krông Pa",
    code: 22195,
    division_type: "xã",
    codename: "xa_krong_pa",
    district_code: 560,
  },
  {
    name: "Xã Suối Bạc",
    code: 22198,
    division_type: "xã",
    codename: "xa_suoi_bac",
    district_code: 560,
  },
  {
    name: "Xã Sơn Hà",
    code: 22201,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 560,
  },
  {
    name: "Xã Suối Trai",
    code: 22204,
    division_type: "xã",
    codename: "xa_suoi_trai",
    district_code: 560,
  },
  {
    name: "Thị trấn Hai Riêng",
    code: 22207,
    division_type: "thị trấn",
    codename: "thi_tran_hai_rieng",
    district_code: 561,
  },
  {
    name: "Xã Ea Lâm",
    code: 22210,
    division_type: "xã",
    codename: "xa_ea_lam",
    district_code: 561,
  },
  {
    name: "Xã Đức Bình Tây",
    code: 22213,
    division_type: "xã",
    codename: "xa_duc_binh_tay",
    district_code: 561,
  },
  {
    name: "Xã Ea Bá",
    code: 22216,
    division_type: "xã",
    codename: "xa_ea_ba",
    district_code: 561,
  },
  {
    name: "Xã Sơn Giang",
    code: 22219,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 561,
  },
  {
    name: "Xã Đức Bình Đông",
    code: 22222,
    division_type: "xã",
    codename: "xa_duc_binh_dong",
    district_code: 561,
  },
  {
    name: "Xã EaBar",
    code: 22225,
    division_type: "xã",
    codename: "xa_eabar",
    district_code: 561,
  },
  {
    name: "Xã EaBia",
    code: 22228,
    division_type: "xã",
    codename: "xa_eabia",
    district_code: 561,
  },
  {
    name: "Xã EaTrol",
    code: 22231,
    division_type: "xã",
    codename: "xa_eatrol",
    district_code: 561,
  },
  {
    name: "Xã Sông Hinh",
    code: 22234,
    division_type: "xã",
    codename: "xa_song_hinh",
    district_code: 561,
  },
  {
    name: "Xã Ealy",
    code: 22237,
    division_type: "xã",
    codename: "xa_ealy",
    district_code: 561,
  },
  {
    name: "Xã Sơn Thành Tây",
    code: 22249,
    division_type: "xã",
    codename: "xa_son_thanh_tay",
    district_code: 562,
  },
  {
    name: "Xã Sơn Thành Đông",
    code: 22250,
    division_type: "xã",
    codename: "xa_son_thanh_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Bình 1",
    code: 22252,
    division_type: "xã",
    codename: "xa_hoa_binh_1",
    district_code: 562,
  },
  {
    name: "Thị trấn Phú Thứ",
    code: 22255,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thu",
    district_code: 562,
  },
  {
    name: "Xã Hòa Phong",
    code: 22264,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Phú",
    code: 22270,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 562,
  },
  {
    name: "Xã Hòa Tân Tây",
    code: 22273,
    division_type: "xã",
    codename: "xa_hoa_tan_tay",
    district_code: 562,
  },
  {
    name: "Xã Hòa Đồng",
    code: 22276,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Mỹ Đông",
    code: 22285,
    division_type: "xã",
    codename: "xa_hoa_my_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Mỹ Tây",
    code: 22288,
    division_type: "xã",
    codename: "xa_hoa_my_tay",
    district_code: 562,
  },
  {
    name: "Xã Hòa Thịnh",
    code: 22294,
    division_type: "xã",
    codename: "xa_hoa_thinh",
    district_code: 562,
  },
  {
    name: "Xã Hòa Quang Bắc",
    code: 22303,
    division_type: "xã",
    codename: "xa_hoa_quang_bac",
    district_code: 563,
  },
  {
    name: "Xã Hòa Quang Nam",
    code: 22306,
    division_type: "xã",
    codename: "xa_hoa_quang_nam",
    district_code: 563,
  },
  {
    name: "Xã Hòa Hội",
    code: 22309,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 563,
  },
  {
    name: "Xã Hòa Trị",
    code: 22312,
    division_type: "xã",
    codename: "xa_hoa_tri",
    district_code: 563,
  },
  {
    name: "Xã Hòa An",
    code: 22315,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 563,
  },
  {
    name: "Xã Hòa Định Đông",
    code: 22318,
    division_type: "xã",
    codename: "xa_hoa_dinh_dong",
    district_code: 563,
  },
  {
    name: "Thị trấn Phú Hoà",
    code: 22319,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 563,
  },
  {
    name: "Xã Hòa Định Tây",
    code: 22321,
    division_type: "xã",
    codename: "xa_hoa_dinh_tay",
    district_code: 563,
  },
  {
    name: "Xã Hòa Thắng",
    code: 22324,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 563,
  },
  {
    name: "Xã Hòa Thành",
    code: 22243,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 564,
  },
  {
    name: "Phường Hòa Hiệp Bắc",
    code: 22246,
    division_type: "phường",
    codename: "phuong_hoa_hiep_bac",
    district_code: 564,
  },
  {
    name: "Phường Hoà Vinh",
    code: 22258,
    division_type: "phường",
    codename: "phuong_hoa_vinh",
    district_code: 564,
  },
  {
    name: "Phường Hoà Hiệp Trung",
    code: 22261,
    division_type: "phường",
    codename: "phuong_hoa_hiep_trung",
    district_code: 564,
  },
  {
    name: "Xã Hòa Tân Đông",
    code: 22267,
    division_type: "xã",
    codename: "xa_hoa_tan_dong",
    district_code: 564,
  },
  {
    name: "Phường Hòa Xuân Tây",
    code: 22279,
    division_type: "phường",
    codename: "phuong_hoa_xuan_tay",
    district_code: 564,
  },
  {
    name: "Phường Hòa Hiệp Nam",
    code: 22282,
    division_type: "phường",
    codename: "phuong_hoa_hiep_nam",
    district_code: 564,
  },
  {
    name: "Xã Hòa Xuân Đông",
    code: 22291,
    division_type: "xã",
    codename: "xa_hoa_xuan_dong",
    district_code: 564,
  },
  {
    name: "Xã Hòa Tâm",
    code: 22297,
    division_type: "xã",
    codename: "xa_hoa_tam",
    district_code: 564,
  },
  {
    name: "Xã Hòa Xuân Nam",
    code: 22300,
    division_type: "xã",
    codename: "xa_hoa_xuan_nam",
    district_code: 564,
  },
  {
    name: "Phường Vĩnh Hòa",
    code: 22327,
    division_type: "phường",
    codename: "phuong_vinh_hoa",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Hải",
    code: 22330,
    division_type: "phường",
    codename: "phuong_vinh_hai",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Phước",
    code: 22333,
    division_type: "phường",
    codename: "phuong_vinh_phuoc",
    district_code: 568,
  },
  {
    name: "Phường Ngọc Hiệp",
    code: 22336,
    division_type: "phường",
    codename: "phuong_ngoc_hiep",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Thọ",
    code: 22339,
    division_type: "phường",
    codename: "phuong_vinh_tho",
    district_code: 568,
  },
  {
    name: "Phường Xương Huân",
    code: 22342,
    division_type: "phường",
    codename: "phuong_xuong_huan",
    district_code: 568,
  },
  {
    name: "Phường Vạn Thắng",
    code: 22345,
    division_type: "phường",
    codename: "phuong_van_thang",
    district_code: 568,
  },
  {
    name: "Phường Vạn Thạnh",
    code: 22348,
    division_type: "phường",
    codename: "phuong_van_thanh",
    district_code: 568,
  },
  {
    name: "Phường Phương Sài",
    code: 22351,
    division_type: "phường",
    codename: "phuong_phuong_sai",
    district_code: 568,
  },
  {
    name: "Phường Phương Sơn",
    code: 22354,
    division_type: "phường",
    codename: "phuong_phuong_son",
    district_code: 568,
  },
  {
    name: "Phường Phước Hải",
    code: 22357,
    division_type: "phường",
    codename: "phuong_phuoc_hai",
    district_code: 568,
  },
  {
    name: "Phường Phước Tân",
    code: 22360,
    division_type: "phường",
    codename: "phuong_phuoc_tan",
    district_code: 568,
  },
  {
    name: "Phường Lộc Thọ",
    code: 22363,
    division_type: "phường",
    codename: "phuong_loc_tho",
    district_code: 568,
  },
  {
    name: "Phường Phước Tiến",
    code: 22366,
    division_type: "phường",
    codename: "phuong_phuoc_tien",
    district_code: 568,
  },
  {
    name: "Phường Tân Lập",
    code: 22369,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 568,
  },
  {
    name: "Phường Phước Hòa",
    code: 22372,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Nguyên",
    code: 22375,
    division_type: "phường",
    codename: "phuong_vinh_nguyen",
    district_code: 568,
  },
  {
    name: "Phường Phước Long",
    code: 22378,
    division_type: "phường",
    codename: "phuong_phuoc_long",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Trường",
    code: 22381,
    division_type: "phường",
    codename: "phuong_vinh_truong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Lương",
    code: 22384,
    division_type: "xã",
    codename: "xa_vinh_luong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Phương",
    code: 22387,
    division_type: "xã",
    codename: "xa_vinh_phuong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Ngọc",
    code: 22390,
    division_type: "xã",
    codename: "xa_vinh_ngoc",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 22393,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 22396,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 22399,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Thái",
    code: 22402,
    division_type: "xã",
    codename: "xa_vinh_thai",
    district_code: 568,
  },
  {
    name: "Xã Phước Đồng",
    code: 22405,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 568,
  },
  {
    name: "Phường Cam Nghĩa",
    code: 22408,
    division_type: "phường",
    codename: "phuong_cam_nghia",
    district_code: 569,
  },
  {
    name: "Phường Cam Phúc Bắc",
    code: 22411,
    division_type: "phường",
    codename: "phuong_cam_phuc_bac",
    district_code: 569,
  },
  {
    name: "Phường Cam Phúc Nam",
    code: 22414,
    division_type: "phường",
    codename: "phuong_cam_phuc_nam",
    district_code: 569,
  },
  {
    name: "Phường Cam Lộc",
    code: 22417,
    division_type: "phường",
    codename: "phuong_cam_loc",
    district_code: 569,
  },
  {
    name: "Phường Cam Phú",
    code: 22420,
    division_type: "phường",
    codename: "phuong_cam_phu",
    district_code: 569,
  },
  {
    name: "Phường Ba Ngòi",
    code: 22423,
    division_type: "phường",
    codename: "phuong_ba_ngoi",
    district_code: 569,
  },
  {
    name: "Phường Cam Thuận",
    code: 22426,
    division_type: "phường",
    codename: "phuong_cam_thuan",
    district_code: 569,
  },
  {
    name: "Phường Cam Lợi",
    code: 22429,
    division_type: "phường",
    codename: "phuong_cam_loi",
    district_code: 569,
  },
  {
    name: "Phường Cam Linh",
    code: 22432,
    division_type: "phường",
    codename: "phuong_cam_linh",
    district_code: 569,
  },
  {
    name: "Xã Cam Thành Nam",
    code: 22468,
    division_type: "xã",
    codename: "xa_cam_thanh_nam",
    district_code: 569,
  },
  {
    name: "Xã Cam Phước Đông",
    code: 22474,
    division_type: "xã",
    codename: "xa_cam_phuoc_dong",
    district_code: 569,
  },
  {
    name: "Xã Cam Thịnh Tây",
    code: 22477,
    division_type: "xã",
    codename: "xa_cam_thinh_tay",
    district_code: 569,
  },
  {
    name: "Xã Cam Thịnh Đông",
    code: 22480,
    division_type: "xã",
    codename: "xa_cam_thinh_dong",
    district_code: 569,
  },
  {
    name: "Xã Cam Lập",
    code: 22483,
    division_type: "xã",
    codename: "xa_cam_lap",
    district_code: 569,
  },
  {
    name: "Xã Cam Bình",
    code: 22486,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 569,
  },
  {
    name: "Xã Cam Tân",
    code: 22435,
    division_type: "xã",
    codename: "xa_cam_tan",
    district_code: 570,
  },
  {
    name: "Xã Cam Hòa",
    code: 22438,
    division_type: "xã",
    codename: "xa_cam_hoa",
    district_code: 570,
  },
  {
    name: "Xã Cam Hải Đông",
    code: 22441,
    division_type: "xã",
    codename: "xa_cam_hai_dong",
    district_code: 570,
  },
  {
    name: "Xã Cam Hải Tây",
    code: 22444,
    division_type: "xã",
    codename: "xa_cam_hai_tay",
    district_code: 570,
  },
  {
    name: "Xã Sơn Tân",
    code: 22447,
    division_type: "xã",
    codename: "xa_son_tan",
    district_code: 570,
  },
  {
    name: "Xã Cam Hiệp Bắc",
    code: 22450,
    division_type: "xã",
    codename: "xa_cam_hiep_bac",
    district_code: 570,
  },
  {
    name: "Thị trấn Cam Đức",
    code: 22453,
    division_type: "thị trấn",
    codename: "thi_tran_cam_duc",
    district_code: 570,
  },
  {
    name: "Xã Cam Hiệp Nam",
    code: 22456,
    division_type: "xã",
    codename: "xa_cam_hiep_nam",
    district_code: 570,
  },
  {
    name: "Xã Cam Phước Tây",
    code: 22459,
    division_type: "xã",
    codename: "xa_cam_phuoc_tay",
    district_code: 570,
  },
  {
    name: "Xã Cam Thành Bắc",
    code: 22462,
    division_type: "xã",
    codename: "xa_cam_thanh_bac",
    district_code: 570,
  },
  {
    name: "Xã Cam An Bắc",
    code: 22465,
    division_type: "xã",
    codename: "xa_cam_an_bac",
    district_code: 570,
  },
  {
    name: "Xã Cam An Nam",
    code: 22471,
    division_type: "xã",
    codename: "xa_cam_an_nam",
    district_code: 570,
  },
  {
    name: "Xã Suối Cát",
    code: 22708,
    division_type: "xã",
    codename: "xa_suoi_cat",
    district_code: 570,
  },
  {
    name: "Xã Suối Tân",
    code: 22711,
    division_type: "xã",
    codename: "xa_suoi_tan",
    district_code: 570,
  },
  {
    name: "Thị trấn Vạn Giã",
    code: 22489,
    division_type: "thị trấn",
    codename: "thi_tran_van_gia",
    district_code: 571,
  },
  {
    name: "Xã Đại Lãnh",
    code: 22492,
    division_type: "xã",
    codename: "xa_dai_lanh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Phước",
    code: 22495,
    division_type: "xã",
    codename: "xa_van_phuoc",
    district_code: 571,
  },
  {
    name: "Xã Vạn Long",
    code: 22498,
    division_type: "xã",
    codename: "xa_van_long",
    district_code: 571,
  },
  {
    name: "Xã Vạn Bình",
    code: 22501,
    division_type: "xã",
    codename: "xa_van_binh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thọ",
    code: 22504,
    division_type: "xã",
    codename: "xa_van_tho",
    district_code: 571,
  },
  {
    name: "Xã Vạn Khánh",
    code: 22507,
    division_type: "xã",
    codename: "xa_van_khanh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Phú",
    code: 22510,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 571,
  },
  {
    name: "Xã Vạn Lương",
    code: 22513,
    division_type: "xã",
    codename: "xa_van_luong",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thắng",
    code: 22516,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thạnh",
    code: 22519,
    division_type: "xã",
    codename: "xa_van_thanh",
    district_code: 571,
  },
  {
    name: "Xã Xuân Sơn",
    code: 22522,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 571,
  },
  {
    name: "Xã Vạn Hưng",
    code: 22525,
    division_type: "xã",
    codename: "xa_van_hung",
    district_code: 571,
  },
  {
    name: "Phường Ninh Hiệp",
    code: 22528,
    division_type: "phường",
    codename: "phuong_ninh_hiep",
    district_code: 572,
  },
  {
    name: "Xã Ninh Sơn",
    code: 22531,
    division_type: "xã",
    codename: "xa_ninh_son",
    district_code: 572,
  },
  {
    name: "Xã Ninh Tây",
    code: 22534,
    division_type: "xã",
    codename: "xa_ninh_tay",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thượng",
    code: 22537,
    division_type: "xã",
    codename: "xa_ninh_thuong",
    district_code: 572,
  },
  {
    name: "Xã Ninh An",
    code: 22540,
    division_type: "xã",
    codename: "xa_ninh_an",
    district_code: 572,
  },
  {
    name: "Phường Ninh Hải",
    code: 22543,
    division_type: "phường",
    codename: "phuong_ninh_hai",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thọ",
    code: 22546,
    division_type: "xã",
    codename: "xa_ninh_tho",
    district_code: 572,
  },
  {
    name: "Xã Ninh Trung",
    code: 22549,
    division_type: "xã",
    codename: "xa_ninh_trung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Sim",
    code: 22552,
    division_type: "xã",
    codename: "xa_ninh_sim",
    district_code: 572,
  },
  {
    name: "Xã Ninh Xuân",
    code: 22555,
    division_type: "xã",
    codename: "xa_ninh_xuan",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thân",
    code: 22558,
    division_type: "xã",
    codename: "xa_ninh_than",
    district_code: 572,
  },
  {
    name: "Phường Ninh Diêm",
    code: 22561,
    division_type: "phường",
    codename: "phuong_ninh_diem",
    district_code: 572,
  },
  {
    name: "Xã Ninh Đông",
    code: 22564,
    division_type: "xã",
    codename: "xa_ninh_dong",
    district_code: 572,
  },
  {
    name: "Phường Ninh Thủy",
    code: 22567,
    division_type: "phường",
    codename: "phuong_ninh_thuy",
    district_code: 572,
  },
  {
    name: "Phường Ninh Đa",
    code: 22570,
    division_type: "phường",
    codename: "phuong_ninh_da",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phụng",
    code: 22573,
    division_type: "xã",
    codename: "xa_ninh_phung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Bình",
    code: 22576,
    division_type: "xã",
    codename: "xa_ninh_binh",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phước",
    code: 22579,
    division_type: "xã",
    codename: "xa_ninh_phuoc",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phú",
    code: 22582,
    division_type: "xã",
    codename: "xa_ninh_phu",
    district_code: 572,
  },
  {
    name: "Xã Ninh Tân",
    code: 22585,
    division_type: "xã",
    codename: "xa_ninh_tan",
    district_code: 572,
  },
  {
    name: "Xã Ninh Quang",
    code: 22588,
    division_type: "xã",
    codename: "xa_ninh_quang",
    district_code: 572,
  },
  {
    name: "Phường Ninh Giang",
    code: 22591,
    division_type: "phường",
    codename: "phuong_ninh_giang",
    district_code: 572,
  },
  {
    name: "Phường Ninh Hà",
    code: 22594,
    division_type: "phường",
    codename: "phuong_ninh_ha",
    district_code: 572,
  },
  {
    name: "Xã Ninh Hưng",
    code: 22597,
    division_type: "xã",
    codename: "xa_ninh_hung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Lộc",
    code: 22600,
    division_type: "xã",
    codename: "xa_ninh_loc",
    district_code: 572,
  },
  {
    name: "Xã Ninh Ích",
    code: 22603,
    division_type: "xã",
    codename: "xa_ninh_ich",
    district_code: 572,
  },
  {
    name: "Xã Ninh Vân",
    code: 22606,
    division_type: "xã",
    codename: "xa_ninh_van",
    district_code: 572,
  },
  {
    name: "Thị trấn Khánh Vĩnh",
    code: 22609,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_vinh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Hiệp",
    code: 22612,
    division_type: "xã",
    codename: "xa_khanh_hiep",
    district_code: 573,
  },
  {
    name: "Xã Khánh Bình",
    code: 22615,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Trung",
    code: 22618,
    division_type: "xã",
    codename: "xa_khanh_trung",
    district_code: 573,
  },
  {
    name: "Xã Khánh Đông",
    code: 22621,
    division_type: "xã",
    codename: "xa_khanh_dong",
    district_code: 573,
  },
  {
    name: "Xã Khánh Thượng",
    code: 22624,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 573,
  },
  {
    name: "Xã Khánh Nam",
    code: 22627,
    division_type: "xã",
    codename: "xa_khanh_nam",
    district_code: 573,
  },
  {
    name: "Xã Sông Cầu",
    code: 22630,
    division_type: "xã",
    codename: "xa_song_cau",
    district_code: 573,
  },
  {
    name: "Xã Giang Ly",
    code: 22633,
    division_type: "xã",
    codename: "xa_giang_ly",
    district_code: 573,
  },
  {
    name: "Xã Cầu Bà",
    code: 22636,
    division_type: "xã",
    codename: "xa_cau_ba",
    district_code: 573,
  },
  {
    name: "Xã Liên Sang",
    code: 22639,
    division_type: "xã",
    codename: "xa_lien_sang",
    district_code: 573,
  },
  {
    name: "Xã Khánh Thành",
    code: 22642,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Phú",
    code: 22645,
    division_type: "xã",
    codename: "xa_khanh_phu",
    district_code: 573,
  },
  {
    name: "Xã Sơn Thái",
    code: 22648,
    division_type: "xã",
    codename: "xa_son_thai",
    district_code: 573,
  },
  {
    name: "Thị trấn Diên Khánh",
    code: 22651,
    division_type: "thị trấn",
    codename: "thi_tran_dien_khanh",
    district_code: 574,
  },
  {
    name: "Xã Diên Lâm",
    code: 22654,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 574,
  },
  {
    name: "Xã Diên Điền",
    code: 22657,
    division_type: "xã",
    codename: "xa_dien_dien",
    district_code: 574,
  },
  {
    name: "Xã Diên Xuân",
    code: 22660,
    division_type: "xã",
    codename: "xa_dien_xuan",
    district_code: 574,
  },
  {
    name: "Xã Diên Sơn",
    code: 22663,
    division_type: "xã",
    codename: "xa_dien_son",
    district_code: 574,
  },
  {
    name: "Xã Diên Đồng",
    code: 22666,
    division_type: "xã",
    codename: "xa_dien_dong",
    district_code: 574,
  },
  {
    name: "Xã Diên Phú",
    code: 22669,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 574,
  },
  {
    name: "Xã Diên Thọ",
    code: 22672,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 574,
  },
  {
    name: "Xã Diên Phước",
    code: 22675,
    division_type: "xã",
    codename: "xa_dien_phuoc",
    district_code: 574,
  },
  {
    name: "Xã Diên Lạc",
    code: 22678,
    division_type: "xã",
    codename: "xa_dien_lac",
    district_code: 574,
  },
  {
    name: "Xã Diên Tân",
    code: 22681,
    division_type: "xã",
    codename: "xa_dien_tan",
    district_code: 574,
  },
  {
    name: "Xã Diên Hòa",
    code: 22684,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 574,
  },
  {
    name: "Xã Diên Thạnh",
    code: 22687,
    division_type: "xã",
    codename: "xa_dien_thanh",
    district_code: 574,
  },
  {
    name: "Xã Diên Toàn",
    code: 22690,
    division_type: "xã",
    codename: "xa_dien_toan",
    district_code: 574,
  },
  {
    name: "Xã Diên An",
    code: 22693,
    division_type: "xã",
    codename: "xa_dien_an",
    district_code: 574,
  },
  {
    name: "Xã Bình Lộc",
    code: 22696,
    division_type: "xã",
    codename: "xa_binh_loc",
    district_code: 574,
  },
  {
    name: "Xã Suối Hiệp",
    code: 22702,
    division_type: "xã",
    codename: "xa_suoi_hiep",
    district_code: 574,
  },
  {
    name: "Xã Suối Tiên",
    code: 22705,
    division_type: "xã",
    codename: "xa_suoi_tien",
    district_code: 574,
  },
  {
    name: "Thị trấn Tô Hạp",
    code: 22714,
    division_type: "thị trấn",
    codename: "thi_tran_to_hap",
    district_code: 575,
  },
  {
    name: "Xã Thành Sơn",
    code: 22717,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 575,
  },
  {
    name: "Xã Sơn Lâm",
    code: 22720,
    division_type: "xã",
    codename: "xa_son_lam",
    district_code: 575,
  },
  {
    name: "Xã Sơn Hiệp",
    code: 22723,
    division_type: "xã",
    codename: "xa_son_hiep",
    district_code: 575,
  },
  {
    name: "Xã Sơn Bình",
    code: 22726,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 575,
  },
  {
    name: "Xã Sơn Trung",
    code: 22729,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 575,
  },
  {
    name: "Xã Ba Cụm Bắc",
    code: 22732,
    division_type: "xã",
    codename: "xa_ba_cum_bac",
    district_code: 575,
  },
  {
    name: "Xã Ba Cụm Nam",
    code: 22735,
    division_type: "xã",
    codename: "xa_ba_cum_nam",
    district_code: 575,
  },
  {
    name: "Thị trấn Trường Sa",
    code: 22736,
    division_type: "thị trấn",
    codename: "thi_tran_truong_sa",
    district_code: 576,
  },
  {
    name: "Xã Song Tử Tây",
    code: 22737,
    division_type: "xã",
    codename: "xa_song_tu_tay",
    district_code: 576,
  },
  {
    name: "Xã Sinh Tồn",
    code: 22739,
    division_type: "xã",
    codename: "xa_sinh_ton",
    district_code: 576,
  },
  {
    name: "Phường Đô Vinh",
    code: 22738,
    division_type: "phường",
    codename: "phuong_do_vinh",
    district_code: 582,
  },
  {
    name: "Phường Phước Mỹ",
    code: 22741,
    division_type: "phường",
    codename: "phuong_phuoc_my",
    district_code: 582,
  },
  {
    name: "Phường Bảo An",
    code: 22744,
    division_type: "phường",
    codename: "phuong_bao_an",
    district_code: 582,
  },
  {
    name: "Phường Phủ Hà",
    code: 22747,
    division_type: "phường",
    codename: "phuong_phu_ha",
    district_code: 582,
  },
  {
    name: "Phường Thanh Sơn",
    code: 22750,
    division_type: "phường",
    codename: "phuong_thanh_son",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Hương",
    code: 22753,
    division_type: "phường",
    codename: "phuong_my_huong",
    district_code: 582,
  },
  {
    name: "Phường Tấn Tài",
    code: 22756,
    division_type: "phường",
    codename: "phuong_tan_tai",
    district_code: 582,
  },
  {
    name: "Phường Kinh Dinh",
    code: 22759,
    division_type: "phường",
    codename: "phuong_kinh_dinh",
    district_code: 582,
  },
  {
    name: "Phường Đạo Long",
    code: 22762,
    division_type: "phường",
    codename: "phuong_dao_long",
    district_code: 582,
  },
  {
    name: "Phường Đài Sơn",
    code: 22765,
    division_type: "phường",
    codename: "phuong_dai_son",
    district_code: 582,
  },
  {
    name: "Phường Đông Hải",
    code: 22768,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Đông",
    code: 22771,
    division_type: "phường",
    codename: "phuong_my_dong",
    district_code: 582,
  },
  {
    name: "Xã Thành Hải",
    code: 22774,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 582,
  },
  {
    name: "Phường Văn Hải",
    code: 22777,
    division_type: "phường",
    codename: "phuong_van_hai",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Bình",
    code: 22779,
    division_type: "phường",
    codename: "phuong_my_binh",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Hải",
    code: 22780,
    division_type: "phường",
    codename: "phuong_my_hai",
    district_code: 582,
  },
  {
    name: "Xã Phước Bình",
    code: 22783,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 584,
  },
  {
    name: "Xã Phước Hòa",
    code: 22786,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 584,
  },
  {
    name: "Xã Phước Tân",
    code: 22789,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 584,
  },
  {
    name: "Xã Phước Tiến",
    code: 22792,
    division_type: "xã",
    codename: "xa_phuoc_tien",
    district_code: 584,
  },
  {
    name: "Xã Phước Thắng",
    code: 22795,
    division_type: "xã",
    codename: "xa_phuoc_thang",
    district_code: 584,
  },
  {
    name: "Xã Phước Thành",
    code: 22798,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 584,
  },
  {
    name: "Xã Phước Đại",
    code: 22801,
    division_type: "xã",
    codename: "xa_phuoc_dai",
    district_code: 584,
  },
  {
    name: "Xã Phước Chính",
    code: 22804,
    division_type: "xã",
    codename: "xa_phuoc_chinh",
    district_code: 584,
  },
  {
    name: "Xã Phước Trung",
    code: 22807,
    division_type: "xã",
    codename: "xa_phuoc_trung",
    district_code: 584,
  },
  {
    name: "Thị trấn Tân Sơn",
    code: 22810,
    division_type: "thị trấn",
    codename: "thi_tran_tan_son",
    district_code: 585,
  },
  {
    name: "Xã Lâm Sơn",
    code: 22813,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 585,
  },
  {
    name: "Xã Lương Sơn",
    code: 22816,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 585,
  },
  {
    name: "Xã Quảng Sơn",
    code: 22819,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 585,
  },
  {
    name: "Xã Mỹ Sơn",
    code: 22822,
    division_type: "xã",
    codename: "xa_my_son",
    district_code: 585,
  },
  {
    name: "Xã Hòa Sơn",
    code: 22825,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 585,
  },
  {
    name: "Xã Ma Nới",
    code: 22828,
    division_type: "xã",
    codename: "xa_ma_noi",
    district_code: 585,
  },
  {
    name: "Xã Nhơn Sơn",
    code: 22831,
    division_type: "xã",
    codename: "xa_nhon_son",
    district_code: 585,
  },
  {
    name: "Thị trấn Khánh Hải",
    code: 22834,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_hai",
    district_code: 586,
  },
  {
    name: "Xã Vĩnh Hải",
    code: 22846,
    division_type: "xã",
    codename: "xa_vinh_hai",
    district_code: 586,
  },
  {
    name: "Xã Phương Hải",
    code: 22852,
    division_type: "xã",
    codename: "xa_phuong_hai",
    district_code: 586,
  },
  {
    name: "Xã Tân Hải",
    code: 22855,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 586,
  },
  {
    name: "Xã Xuân Hải",
    code: 22858,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 586,
  },
  {
    name: "Xã Hộ Hải",
    code: 22861,
    division_type: "xã",
    codename: "xa_ho_hai",
    district_code: 586,
  },
  {
    name: "Xã Tri Hải",
    code: 22864,
    division_type: "xã",
    codename: "xa_tri_hai",
    district_code: 586,
  },
  {
    name: "Xã Nhơn Hải",
    code: 22867,
    division_type: "xã",
    codename: "xa_nhon_hai",
    district_code: 586,
  },
  {
    name: "Xã Thanh Hải",
    code: 22868,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 586,
  },
  {
    name: "Thị trấn Phước Dân",
    code: 22870,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_dan",
    district_code: 587,
  },
  {
    name: "Xã Phước Sơn",
    code: 22873,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 587,
  },
  {
    name: "Xã Phước Thái",
    code: 22876,
    division_type: "xã",
    codename: "xa_phuoc_thai",
    district_code: 587,
  },
  {
    name: "Xã Phước Hậu",
    code: 22879,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 587,
  },
  {
    name: "Xã Phước Thuận",
    code: 22882,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 587,
  },
  {
    name: "Xã An Hải",
    code: 22888,
    division_type: "xã",
    codename: "xa_an_hai",
    district_code: 587,
  },
  {
    name: "Xã Phước Hữu",
    code: 22891,
    division_type: "xã",
    codename: "xa_phuoc_huu",
    district_code: 587,
  },
  {
    name: "Xã Phước Hải",
    code: 22894,
    division_type: "xã",
    codename: "xa_phuoc_hai",
    district_code: 587,
  },
  {
    name: "Xã Phước Vinh",
    code: 22912,
    division_type: "xã",
    codename: "xa_phuoc_vinh",
    district_code: 587,
  },
  {
    name: "Xã Phước Chiến",
    code: 22837,
    division_type: "xã",
    codename: "xa_phuoc_chien",
    district_code: 588,
  },
  {
    name: "Xã Công Hải",
    code: 22840,
    division_type: "xã",
    codename: "xa_cong_hai",
    district_code: 588,
  },
  {
    name: "Xã Phước Kháng",
    code: 22843,
    division_type: "xã",
    codename: "xa_phuoc_khang",
    district_code: 588,
  },
  {
    name: "Xã Lợi Hải",
    code: 22849,
    division_type: "xã",
    codename: "xa_loi_hai",
    district_code: 588,
  },
  {
    name: "Xã Bắc Sơn",
    code: 22853,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 588,
  },
  {
    name: "Xã Bắc Phong",
    code: 22856,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 588,
  },
  {
    name: "Xã Phước Hà",
    code: 22885,
    division_type: "xã",
    codename: "xa_phuoc_ha",
    district_code: 589,
  },
  {
    name: "Xã Phước Nam",
    code: 22897,
    division_type: "xã",
    codename: "xa_phuoc_nam",
    district_code: 589,
  },
  {
    name: "Xã Phước Ninh",
    code: 22898,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 589,
  },
  {
    name: "Xã Nhị Hà",
    code: 22900,
    division_type: "xã",
    codename: "xa_nhi_ha",
    district_code: 589,
  },
  {
    name: "Xã Phước Dinh",
    code: 22903,
    division_type: "xã",
    codename: "xa_phuoc_dinh",
    district_code: 589,
  },
  {
    name: "Xã Phước Minh",
    code: 22906,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 589,
  },
  {
    name: "Xã Phước Diêm",
    code: 22909,
    division_type: "xã",
    codename: "xa_phuoc_diem",
    district_code: 589,
  },
  {
    name: "Xã Cà Ná",
    code: 22910,
    division_type: "xã",
    codename: "xa_ca_na",
    district_code: 589,
  },
  {
    name: "Phường Mũi Né",
    code: 22915,
    division_type: "phường",
    codename: "phuong_mui_ne",
    district_code: 593,
  },
  {
    name: "Phường Hàm Tiến",
    code: 22918,
    division_type: "phường",
    codename: "phuong_ham_tien",
    district_code: 593,
  },
  {
    name: "Phường Phú Hài",
    code: 22921,
    division_type: "phường",
    codename: "phuong_phu_hai",
    district_code: 593,
  },
  {
    name: "Phường Phú Thủy",
    code: 22924,
    division_type: "phường",
    codename: "phuong_phu_thuy",
    district_code: 593,
  },
  {
    name: "Phường Phú Tài",
    code: 22927,
    division_type: "phường",
    codename: "phuong_phu_tai",
    district_code: 593,
  },
  {
    name: "Phường Phú Trinh",
    code: 22930,
    division_type: "phường",
    codename: "phuong_phu_trinh",
    district_code: 593,
  },
  {
    name: "Phường Xuân An",
    code: 22933,
    division_type: "phường",
    codename: "phuong_xuan_an",
    district_code: 593,
  },
  {
    name: "Phường Thanh Hải",
    code: 22936,
    division_type: "phường",
    codename: "phuong_thanh_hai",
    district_code: 593,
  },
  {
    name: "Phường Bình Hưng",
    code: 22939,
    division_type: "phường",
    codename: "phuong_binh_hung",
    district_code: 593,
  },
  {
    name: "Phường Đức Nghĩa",
    code: 22942,
    division_type: "phường",
    codename: "phuong_duc_nghia",
    district_code: 593,
  },
  {
    name: "Phường Lạc Đạo",
    code: 22945,
    division_type: "phường",
    codename: "phuong_lac_dao",
    district_code: 593,
  },
  {
    name: "Phường Đức Thắng",
    code: 22948,
    division_type: "phường",
    codename: "phuong_duc_thang",
    district_code: 593,
  },
  {
    name: "Phường Hưng Long",
    code: 22951,
    division_type: "phường",
    codename: "phuong_hung_long",
    district_code: 593,
  },
  {
    name: "Phường Đức Long",
    code: 22954,
    division_type: "phường",
    codename: "phuong_duc_long",
    district_code: 593,
  },
  {
    name: "Xã Thiện Nghiệp",
    code: 22957,
    division_type: "xã",
    codename: "xa_thien_nghiep",
    district_code: 593,
  },
  {
    name: "Xã Phong Nẫm",
    code: 22960,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 593,
  },
  {
    name: "Xã Tiến Lợi",
    code: 22963,
    division_type: "xã",
    codename: "xa_tien_loi",
    district_code: 593,
  },
  {
    name: "Xã Tiến Thành",
    code: 22966,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 593,
  },
  {
    name: "Phường Phước Hội",
    code: 23231,
    division_type: "phường",
    codename: "phuong_phuoc_hoi",
    district_code: 594,
  },
  {
    name: "Phường Phước Lộc",
    code: 23232,
    division_type: "phường",
    codename: "phuong_phuoc_loc",
    district_code: 594,
  },
  {
    name: "Phường Tân Thiện",
    code: 23234,
    division_type: "phường",
    codename: "phuong_tan_thien",
    district_code: 594,
  },
  {
    name: "Phường Tân An",
    code: 23235,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 594,
  },
  {
    name: "Phường Bình Tân",
    code: 23237,
    division_type: "phường",
    codename: "phuong_binh_tan",
    district_code: 594,
  },
  {
    name: "Xã Tân Hải",
    code: 23245,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 594,
  },
  {
    name: "Xã Tân Tiến",
    code: 23246,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 594,
  },
  {
    name: "Xã Tân Bình",
    code: 23248,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 594,
  },
  {
    name: "Xã Tân Phước",
    code: 23268,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 594,
  },
  {
    name: "Thị trấn Liên Hương",
    code: 22969,
    division_type: "thị trấn",
    codename: "thi_tran_lien_huong",
    district_code: 595,
  },
  {
    name: "Thị trấn Phan Rí Cửa",
    code: 22972,
    division_type: "thị trấn",
    codename: "thi_tran_phan_ri_cua",
    district_code: 595,
  },
  {
    name: "Xã Phan Dũng",
    code: 22975,
    division_type: "xã",
    codename: "xa_phan_dung",
    district_code: 595,
  },
  {
    name: "Xã Phong Phú",
    code: 22978,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 595,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 22981,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 595,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 22984,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 595,
  },
  {
    name: "Xã Phú Lạc",
    code: 22987,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 595,
  },
  {
    name: "Xã Phước Thể",
    code: 22990,
    division_type: "xã",
    codename: "xa_phuoc_the",
    district_code: 595,
  },
  {
    name: "Xã Hòa Minh",
    code: 22993,
    division_type: "xã",
    codename: "xa_hoa_minh",
    district_code: 595,
  },
  {
    name: "Xã Chí Công",
    code: 22996,
    division_type: "xã",
    codename: "xa_chi_cong",
    district_code: 595,
  },
  {
    name: "Xã Bình Thạnh",
    code: 22999,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 595,
  },
  {
    name: "Thị trấn Chợ Lầu",
    code: 23005,
    division_type: "thị trấn",
    codename: "thi_tran_cho_lau",
    district_code: 596,
  },
  {
    name: "Xã Phan Sơn",
    code: 23008,
    division_type: "xã",
    codename: "xa_phan_son",
    district_code: 596,
  },
  {
    name: "Xã Phan Lâm",
    code: 23011,
    division_type: "xã",
    codename: "xa_phan_lam",
    district_code: 596,
  },
  {
    name: "Xã Bình An",
    code: 23014,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 596,
  },
  {
    name: "Xã Phan Điền",
    code: 23017,
    division_type: "xã",
    codename: "xa_phan_dien",
    district_code: 596,
  },
  {
    name: "Xã Hải Ninh",
    code: 23020,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 596,
  },
  {
    name: "Xã Sông Lũy",
    code: 23023,
    division_type: "xã",
    codename: "xa_song_luy",
    district_code: 596,
  },
  {
    name: "Xã Phan Tiến",
    code: 23026,
    division_type: "xã",
    codename: "xa_phan_tien",
    district_code: 596,
  },
  {
    name: "Xã Sông Bình",
    code: 23029,
    division_type: "xã",
    codename: "xa_song_binh",
    district_code: 596,
  },
  {
    name: "Thị trấn Lương Sơn",
    code: 23032,
    division_type: "thị trấn",
    codename: "thi_tran_luong_son",
    district_code: 596,
  },
  {
    name: "Xã Phan Hòa",
    code: 23035,
    division_type: "xã",
    codename: "xa_phan_hoa",
    district_code: 596,
  },
  {
    name: "Xã Phan Thanh",
    code: 23038,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 596,
  },
  {
    name: "Xã Hồng Thái",
    code: 23041,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 596,
  },
  {
    name: "Xã Phan Hiệp",
    code: 23044,
    division_type: "xã",
    codename: "xa_phan_hiep",
    district_code: 596,
  },
  {
    name: "Xã Bình Tân",
    code: 23047,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 596,
  },
  {
    name: "Xã Phan Rí Thành",
    code: 23050,
    division_type: "xã",
    codename: "xa_phan_ri_thanh",
    district_code: 596,
  },
  {
    name: "Xã Hòa Thắng",
    code: 23053,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 596,
  },
  {
    name: "Xã Hồng Phong",
    code: 23056,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 596,
  },
  {
    name: "Thị trấn Ma Lâm",
    code: 23059,
    division_type: "thị trấn",
    codename: "thi_tran_ma_lam",
    district_code: 597,
  },
  {
    name: "Thị trấn Phú Long",
    code: 23062,
    division_type: "thị trấn",
    codename: "thi_tran_phu_long",
    district_code: 597,
  },
  {
    name: "Xã La Dạ",
    code: 23065,
    division_type: "xã",
    codename: "xa_la_da",
    district_code: 597,
  },
  {
    name: "Xã Đông Tiến",
    code: 23068,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 597,
  },
  {
    name: "Xã Thuận Hòa",
    code: 23071,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 597,
  },
  {
    name: "Xã Đông Giang",
    code: 23074,
    division_type: "xã",
    codename: "xa_dong_giang",
    district_code: 597,
  },
  {
    name: "Xã Hàm Phú",
    code: 23077,
    division_type: "xã",
    codename: "xa_ham_phu",
    district_code: 597,
  },
  {
    name: "Xã Hồng Liêm",
    code: 23080,
    division_type: "xã",
    codename: "xa_hong_liem",
    district_code: 597,
  },
  {
    name: "Xã Thuận Minh",
    code: 23083,
    division_type: "xã",
    codename: "xa_thuan_minh",
    district_code: 597,
  },
  {
    name: "Xã Hồng Sơn",
    code: 23086,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 597,
  },
  {
    name: "Xã Hàm Trí",
    code: 23089,
    division_type: "xã",
    codename: "xa_ham_tri",
    district_code: 597,
  },
  {
    name: "Xã Hàm Đức",
    code: 23092,
    division_type: "xã",
    codename: "xa_ham_duc",
    district_code: 597,
  },
  {
    name: "Xã Hàm Liêm",
    code: 23095,
    division_type: "xã",
    codename: "xa_ham_liem",
    district_code: 597,
  },
  {
    name: "Xã Hàm Chính",
    code: 23098,
    division_type: "xã",
    codename: "xa_ham_chinh",
    district_code: 597,
  },
  {
    name: "Xã Hàm Hiệp",
    code: 23101,
    division_type: "xã",
    codename: "xa_ham_hiep",
    district_code: 597,
  },
  {
    name: "Xã Hàm Thắng",
    code: 23104,
    division_type: "xã",
    codename: "xa_ham_thang",
    district_code: 597,
  },
  {
    name: "Xã Đa Mi",
    code: 23107,
    division_type: "xã",
    codename: "xa_da_mi",
    district_code: 597,
  },
  {
    name: "Thị trấn Thuận Nam",
    code: 23110,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_nam",
    district_code: 598,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 23113,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 598,
  },
  {
    name: "Xã Hàm Cần",
    code: 23116,
    division_type: "xã",
    codename: "xa_ham_can",
    district_code: 598,
  },
  {
    name: "Xã Mương Mán",
    code: 23119,
    division_type: "xã",
    codename: "xa_muong_man",
    district_code: 598,
  },
  {
    name: "Xã Hàm Thạnh",
    code: 23122,
    division_type: "xã",
    codename: "xa_ham_thanh",
    district_code: 598,
  },
  {
    name: "Xã Hàm Kiệm",
    code: 23125,
    division_type: "xã",
    codename: "xa_ham_kiem",
    district_code: 598,
  },
  {
    name: "Xã Hàm Cường",
    code: 23128,
    division_type: "xã",
    codename: "xa_ham_cuong",
    district_code: 598,
  },
  {
    name: "Xã Hàm Mỹ",
    code: 23131,
    division_type: "xã",
    codename: "xa_ham_my",
    district_code: 598,
  },
  {
    name: "Xã Tân Lập",
    code: 23134,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 598,
  },
  {
    name: "Xã Hàm Minh",
    code: 23137,
    division_type: "xã",
    codename: "xa_ham_minh",
    district_code: 598,
  },
  {
    name: "Xã Thuận Quí",
    code: 23140,
    division_type: "xã",
    codename: "xa_thuan_qui",
    district_code: 598,
  },
  {
    name: "Xã Tân Thuận",
    code: 23143,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 598,
  },
  {
    name: "Xã Tân Thành",
    code: 23146,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 598,
  },
  {
    name: "Thị trấn Lạc Tánh",
    code: 23149,
    division_type: "thị trấn",
    codename: "thi_tran_lac_tanh",
    district_code: 599,
  },
  {
    name: "Xã Bắc Ruộng",
    code: 23152,
    division_type: "xã",
    codename: "xa_bac_ruong",
    district_code: 599,
  },
  {
    name: "Xã Nghị Đức",
    code: 23158,
    division_type: "xã",
    codename: "xa_nghi_duc",
    district_code: 599,
  },
  {
    name: "Xã La Ngâu",
    code: 23161,
    division_type: "xã",
    codename: "xa_la_ngau",
    district_code: 599,
  },
  {
    name: "Xã Huy Khiêm",
    code: 23164,
    division_type: "xã",
    codename: "xa_huy_khiem",
    district_code: 599,
  },
  {
    name: "Xã Măng Tố",
    code: 23167,
    division_type: "xã",
    codename: "xa_mang_to",
    district_code: 599,
  },
  {
    name: "Xã Đức Phú",
    code: 23170,
    division_type: "xã",
    codename: "xa_duc_phu",
    district_code: 599,
  },
  {
    name: "Xã Đồng Kho",
    code: 23173,
    division_type: "xã",
    codename: "xa_dong_kho",
    district_code: 599,
  },
  {
    name: "Xã Gia An",
    code: 23176,
    division_type: "xã",
    codename: "xa_gia_an",
    district_code: 599,
  },
  {
    name: "Xã Đức Bình",
    code: 23179,
    division_type: "xã",
    codename: "xa_duc_binh",
    district_code: 599,
  },
  {
    name: "Xã Gia Huynh",
    code: 23182,
    division_type: "xã",
    codename: "xa_gia_huynh",
    district_code: 599,
  },
  {
    name: "Xã Đức Thuận",
    code: 23185,
    division_type: "xã",
    codename: "xa_duc_thuan",
    district_code: 599,
  },
  {
    name: "Xã Suối Kiết",
    code: 23188,
    division_type: "xã",
    codename: "xa_suoi_kiet",
    district_code: 599,
  },
  {
    name: "Thị trấn Võ Xu",
    code: 23191,
    division_type: "thị trấn",
    codename: "thi_tran_vo_xu",
    district_code: 600,
  },
  {
    name: "Thị trấn Đức Tài",
    code: 23194,
    division_type: "thị trấn",
    codename: "thi_tran_duc_tai",
    district_code: 600,
  },
  {
    name: "Xã Đa Kai",
    code: 23197,
    division_type: "xã",
    codename: "xa_da_kai",
    district_code: 600,
  },
  {
    name: "Xã Sùng Nhơn",
    code: 23200,
    division_type: "xã",
    codename: "xa_sung_nhon",
    district_code: 600,
  },
  {
    name: "Xã Mê Pu",
    code: 23203,
    division_type: "xã",
    codename: "xa_me_pu",
    district_code: 600,
  },
  {
    name: "Xã Nam Chính",
    code: 23206,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 600,
  },
  {
    name: "Xã Đức Hạnh",
    code: 23212,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 600,
  },
  {
    name: "Xã Đức Tín",
    code: 23215,
    division_type: "xã",
    codename: "xa_duc_tin",
    district_code: 600,
  },
  {
    name: "Xã Vũ Hoà",
    code: 23218,
    division_type: "xã",
    codename: "xa_vu_hoa",
    district_code: 600,
  },
  {
    name: "Xã Tân Hà",
    code: 23221,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 600,
  },
  {
    name: "Xã Đông Hà",
    code: 23224,
    division_type: "xã",
    codename: "xa_dong_ha",
    district_code: 600,
  },
  {
    name: "Xã Trà Tân",
    code: 23227,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 600,
  },
  {
    name: "Thị trấn Tân Minh",
    code: 23230,
    division_type: "thị trấn",
    codename: "thi_tran_tan_minh",
    district_code: 601,
  },
  {
    name: "Thị trấn Tân Nghĩa",
    code: 23236,
    division_type: "thị trấn",
    codename: "thi_tran_tan_nghia",
    district_code: 601,
  },
  {
    name: "Xã Sông Phan",
    code: 23239,
    division_type: "xã",
    codename: "xa_song_phan",
    district_code: 601,
  },
  {
    name: "Xã Tân Phúc",
    code: 23242,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 601,
  },
  {
    name: "Xã Tân Đức",
    code: 23251,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 601,
  },
  {
    name: "Xã Tân Thắng",
    code: 23254,
    division_type: "xã",
    codename: "xa_tan_thang",
    district_code: 601,
  },
  {
    name: "Xã Thắng Hải",
    code: 23255,
    division_type: "xã",
    codename: "xa_thang_hai",
    district_code: 601,
  },
  {
    name: "Xã Tân Hà",
    code: 23257,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 601,
  },
  {
    name: "Xã Tân Xuân",
    code: 23260,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 601,
  },
  {
    name: "Xã Sơn Mỹ",
    code: 23266,
    division_type: "xã",
    codename: "xa_son_my",
    district_code: 601,
  },
  {
    name: "Xã Ngũ Phụng",
    code: 23272,
    division_type: "xã",
    codename: "xa_ngu_phung",
    district_code: 602,
  },
  {
    name: "Xã Long Hải",
    code: 23275,
    division_type: "xã",
    codename: "xa_long_hai",
    district_code: 602,
  },
  {
    name: "Xã Tam Thanh",
    code: 23278,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 602,
  },
  {
    name: "Phường Quang Trung",
    code: 23281,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 608,
  },
  {
    name: "Phường Duy Tân",
    code: 23284,
    division_type: "phường",
    codename: "phuong_duy_tan",
    district_code: 608,
  },
  {
    name: "Phường Quyết Thắng",
    code: 23287,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 608,
  },
  {
    name: "Phường Trường Chinh",
    code: 23290,
    division_type: "phường",
    codename: "phuong_truong_chinh",
    district_code: 608,
  },
  {
    name: "Phường Thắng Lợi",
    code: 23293,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 608,
  },
  {
    name: "Phường Ngô Mây",
    code: 23296,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 608,
  },
  {
    name: "Phường Thống Nhất",
    code: 23299,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 608,
  },
  {
    name: "Phường Lê Lợi",
    code: 23302,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 608,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 23305,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 608,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 23308,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 608,
  },
  {
    name: "Xã Đắk Cấm",
    code: 23311,
    division_type: "xã",
    codename: "xa_dak_cam",
    district_code: 608,
  },
  {
    name: "Xã Kroong",
    code: 23314,
    division_type: "xã",
    codename: "xa_kroong",
    district_code: 608,
  },
  {
    name: "Xã Ngọk Bay",
    code: 23317,
    division_type: "xã",
    codename: "xa_ngok_bay",
    district_code: 608,
  },
  {
    name: "Xã Vinh Quang",
    code: 23320,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 608,
  },
  {
    name: "Xã Đắk Blà",
    code: 23323,
    division_type: "xã",
    codename: "xa_dak_bla",
    district_code: 608,
  },
  {
    name: "Xã Ia Chim",
    code: 23326,
    division_type: "xã",
    codename: "xa_ia_chim",
    district_code: 608,
  },
  {
    name: "Xã Đăk Năng",
    code: 23327,
    division_type: "xã",
    codename: "xa_dak_nang",
    district_code: 608,
  },
  {
    name: "Xã Đoàn Kết",
    code: 23329,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 608,
  },
  {
    name: "Xã Chư Hreng",
    code: 23332,
    division_type: "xã",
    codename: "xa_chu_hreng",
    district_code: 608,
  },
  {
    name: "Xã Đắk Rơ Wa",
    code: 23335,
    division_type: "xã",
    codename: "xa_dak_ro_wa",
    district_code: 608,
  },
  {
    name: "Xã Hòa Bình",
    code: 23338,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 608,
  },
  {
    name: "Thị trấn Đắk Glei",
    code: 23341,
    division_type: "thị trấn",
    codename: "thi_tran_dak_glei",
    district_code: 610,
  },
  {
    name: "Xã Đắk Blô",
    code: 23344,
    division_type: "xã",
    codename: "xa_dak_blo",
    district_code: 610,
  },
  {
    name: "Xã Đắk Man",
    code: 23347,
    division_type: "xã",
    codename: "xa_dak_man",
    district_code: 610,
  },
  {
    name: "Xã Đắk Nhoong",
    code: 23350,
    division_type: "xã",
    codename: "xa_dak_nhoong",
    district_code: 610,
  },
  {
    name: "Xã Đắk Pék",
    code: 23353,
    division_type: "xã",
    codename: "xa_dak_pek",
    district_code: 610,
  },
  {
    name: "Xã Đắk Choong",
    code: 23356,
    division_type: "xã",
    codename: "xa_dak_choong",
    district_code: 610,
  },
  {
    name: "Xã Xốp",
    code: 23359,
    division_type: "xã",
    codename: "xa_xop",
    district_code: 610,
  },
  {
    name: "Xã Mường Hoong",
    code: 23362,
    division_type: "xã",
    codename: "xa_muong_hoong",
    district_code: 610,
  },
  {
    name: "Xã Ngọc Linh",
    code: 23365,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 610,
  },
  {
    name: "Xã Đắk Long",
    code: 23368,
    division_type: "xã",
    codename: "xa_dak_long",
    district_code: 610,
  },
  {
    name: "Xã Đắk KRoong",
    code: 23371,
    division_type: "xã",
    codename: "xa_dak_kroong",
    district_code: 610,
  },
  {
    name: "Xã Đắk Môn",
    code: 23374,
    division_type: "xã",
    codename: "xa_dak_mon",
    district_code: 610,
  },
  {
    name: "Thị trấn Plei Kần",
    code: 23377,
    division_type: "thị trấn",
    codename: "thi_tran_plei_kan",
    district_code: 611,
  },
  {
    name: "Xã Đắk Ang",
    code: 23380,
    division_type: "xã",
    codename: "xa_dak_ang",
    district_code: 611,
  },
  {
    name: "Xã Đắk Dục",
    code: 23383,
    division_type: "xã",
    codename: "xa_dak_duc",
    district_code: 611,
  },
  {
    name: "Xã Đắk Nông",
    code: 23386,
    division_type: "xã",
    codename: "xa_dak_nong",
    district_code: 611,
  },
  {
    name: "Xã Đắk Xú",
    code: 23389,
    division_type: "xã",
    codename: "xa_dak_xu",
    district_code: 611,
  },
  {
    name: "Xã Đắk Kan",
    code: 23392,
    division_type: "xã",
    codename: "xa_dak_kan",
    district_code: 611,
  },
  {
    name: "Xã Bờ Y",
    code: 23395,
    division_type: "xã",
    codename: "xa_bo_y",
    district_code: 611,
  },
  {
    name: "Xã Sa Loong",
    code: 23398,
    division_type: "xã",
    codename: "xa_sa_loong",
    district_code: 611,
  },
  {
    name: "Thị trấn Đắk Tô",
    code: 23401,
    division_type: "thị trấn",
    codename: "thi_tran_dak_to",
    district_code: 612,
  },
  {
    name: "Xã Đắk Rơ Nga",
    code: 23427,
    division_type: "xã",
    codename: "xa_dak_ro_nga",
    district_code: 612,
  },
  {
    name: "Xã Ngọk Tụ",
    code: 23428,
    division_type: "xã",
    codename: "xa_ngok_tu",
    district_code: 612,
  },
  {
    name: "Xã Đắk Trăm",
    code: 23430,
    division_type: "xã",
    codename: "xa_dak_tram",
    district_code: 612,
  },
  {
    name: "Xã Văn Lem",
    code: 23431,
    division_type: "xã",
    codename: "xa_van_lem",
    district_code: 612,
  },
  {
    name: "Xã Kon Đào",
    code: 23434,
    division_type: "xã",
    codename: "xa_kon_dao",
    district_code: 612,
  },
  {
    name: "Xã Tân Cảnh",
    code: 23437,
    division_type: "xã",
    codename: "xa_tan_canh",
    district_code: 612,
  },
  {
    name: "Xã Diên Bình",
    code: 23440,
    division_type: "xã",
    codename: "xa_dien_binh",
    district_code: 612,
  },
  {
    name: "Xã Pô Kô",
    code: 23443,
    division_type: "xã",
    codename: "xa_po_ko",
    district_code: 612,
  },
  {
    name: "Xã Đắk Nên",
    code: 23452,
    division_type: "xã",
    codename: "xa_dak_nen",
    district_code: 613,
  },
  {
    name: "Xã Đắk Ring",
    code: 23455,
    division_type: "xã",
    codename: "xa_dak_ring",
    district_code: 613,
  },
  {
    name: "Xã Măng Buk",
    code: 23458,
    division_type: "xã",
    codename: "xa_mang_buk",
    district_code: 613,
  },
  {
    name: "Xã Đắk Tăng",
    code: 23461,
    division_type: "xã",
    codename: "xa_dak_tang",
    district_code: 613,
  },
  {
    name: "Xã Ngok Tem",
    code: 23464,
    division_type: "xã",
    codename: "xa_ngok_tem",
    district_code: 613,
  },
  {
    name: "Xã Pờ Ê",
    code: 23467,
    division_type: "xã",
    codename: "xa_po_e",
    district_code: 613,
  },
  {
    name: "Xã Măng Cành",
    code: 23470,
    division_type: "xã",
    codename: "xa_mang_canh",
    district_code: 613,
  },
  {
    name: "Thị trấn Măng Đen",
    code: 23473,
    division_type: "thị trấn",
    codename: "thi_tran_mang_den",
    district_code: 613,
  },
  {
    name: "Xã Hiếu",
    code: 23476,
    division_type: "xã",
    codename: "xa_hieu",
    district_code: 613,
  },
  {
    name: "Thị trấn Đắk Rve",
    code: 23479,
    division_type: "thị trấn",
    codename: "thi_tran_dak_rve",
    district_code: 614,
  },
  {
    name: "Xã Đắk Kôi",
    code: 23482,
    division_type: "xã",
    codename: "xa_dak_koi",
    district_code: 614,
  },
  {
    name: "Xã Đắk Tơ Lung",
    code: 23485,
    division_type: "xã",
    codename: "xa_dak_to_lung",
    district_code: 614,
  },
  {
    name: "Xã Đắk Ruồng",
    code: 23488,
    division_type: "xã",
    codename: "xa_dak_ruong",
    district_code: 614,
  },
  {
    name: "Xã Đắk Pne",
    code: 23491,
    division_type: "xã",
    codename: "xa_dak_pne",
    district_code: 614,
  },
  {
    name: "Xã Đắk Tờ Re",
    code: 23494,
    division_type: "xã",
    codename: "xa_dak_to_re",
    district_code: 614,
  },
  {
    name: "Xã Tân Lập",
    code: 23497,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 614,
  },
  {
    name: "Thị trấn Đắk Hà",
    code: 23500,
    division_type: "thị trấn",
    codename: "thi_tran_dak_ha",
    district_code: 615,
  },
  {
    name: "Xã Đắk PXi",
    code: 23503,
    division_type: "xã",
    codename: "xa_dak_pxi",
    district_code: 615,
  },
  {
    name: "Xã Đăk Long",
    code: 23504,
    division_type: "xã",
    codename: "xa_dak_long",
    district_code: 615,
  },
  {
    name: "Xã Đắk HRing",
    code: 23506,
    division_type: "xã",
    codename: "xa_dak_hring",
    district_code: 615,
  },
  {
    name: "Xã Đắk Ui",
    code: 23509,
    division_type: "xã",
    codename: "xa_dak_ui",
    district_code: 615,
  },
  {
    name: "Xã Đăk Ngọk",
    code: 23510,
    division_type: "xã",
    codename: "xa_dak_ngok",
    district_code: 615,
  },
  {
    name: "Xã Đắk Mar",
    code: 23512,
    division_type: "xã",
    codename: "xa_dak_mar",
    district_code: 615,
  },
  {
    name: "Xã Ngok Wang",
    code: 23515,
    division_type: "xã",
    codename: "xa_ngok_wang",
    district_code: 615,
  },
  {
    name: "Xã Ngok Réo",
    code: 23518,
    division_type: "xã",
    codename: "xa_ngok_reo",
    district_code: 615,
  },
  {
    name: "Xã Hà Mòn",
    code: 23521,
    division_type: "xã",
    codename: "xa_ha_mon",
    district_code: 615,
  },
  {
    name: "Xã Đắk La",
    code: 23524,
    division_type: "xã",
    codename: "xa_dak_la",
    district_code: 615,
  },
  {
    name: "Thị trấn Sa Thầy",
    code: 23527,
    division_type: "thị trấn",
    codename: "thi_tran_sa_thay",
    district_code: 616,
  },
  {
    name: "Xã Rơ Kơi",
    code: 23530,
    division_type: "xã",
    codename: "xa_ro_koi",
    district_code: 616,
  },
  {
    name: "Xã Sa Nhơn",
    code: 23533,
    division_type: "xã",
    codename: "xa_sa_nhon",
    district_code: 616,
  },
  {
    name: "Xã Hơ Moong",
    code: 23534,
    division_type: "xã",
    codename: "xa_ho_moong",
    district_code: 616,
  },
  {
    name: "Xã Mô Rai",
    code: 23536,
    division_type: "xã",
    codename: "xa_mo_rai",
    district_code: 616,
  },
  {
    name: "Xã Sa Sơn",
    code: 23539,
    division_type: "xã",
    codename: "xa_sa_son",
    district_code: 616,
  },
  {
    name: "Xã Sa Nghĩa",
    code: 23542,
    division_type: "xã",
    codename: "xa_sa_nghia",
    district_code: 616,
  },
  {
    name: "Xã Sa Bình",
    code: 23545,
    division_type: "xã",
    codename: "xa_sa_binh",
    district_code: 616,
  },
  {
    name: "Xã Ya Xiêr",
    code: 23548,
    division_type: "xã",
    codename: "xa_ya_xier",
    district_code: 616,
  },
  {
    name: "Xã Ya Tăng",
    code: 23551,
    division_type: "xã",
    codename: "xa_ya_tang",
    district_code: 616,
  },
  {
    name: "Xã Ya ly",
    code: 23554,
    division_type: "xã",
    codename: "xa_ya_ly",
    district_code: 616,
  },
  {
    name: "Xã Ngọc Lây",
    code: 23404,
    division_type: "xã",
    codename: "xa_ngoc_lay",
    district_code: 617,
  },
  {
    name: "Xã Đắk Na",
    code: 23407,
    division_type: "xã",
    codename: "xa_dak_na",
    district_code: 617,
  },
  {
    name: "Xã Măng Ri",
    code: 23410,
    division_type: "xã",
    codename: "xa_mang_ri",
    district_code: 617,
  },
  {
    name: "Xã Ngọc Yêu",
    code: 23413,
    division_type: "xã",
    codename: "xa_ngoc_yeu",
    district_code: 617,
  },
  {
    name: "Xã Đắk Sao",
    code: 23416,
    division_type: "xã",
    codename: "xa_dak_sao",
    district_code: 617,
  },
  {
    name: "Xã Đắk Rơ Ông",
    code: 23417,
    division_type: "xã",
    codename: "xa_dak_ro_ong",
    district_code: 617,
  },
  {
    name: "Xã Đắk Tờ Kan",
    code: 23419,
    division_type: "xã",
    codename: "xa_dak_to_kan",
    district_code: 617,
  },
  {
    name: "Xã Tu Mơ Rông",
    code: 23422,
    division_type: "xã",
    codename: "xa_tu_mo_rong",
    district_code: 617,
  },
  {
    name: "Xã Đắk Hà",
    code: 23425,
    division_type: "xã",
    codename: "xa_dak_ha",
    district_code: 617,
  },
  {
    name: "Xã Tê Xăng",
    code: 23446,
    division_type: "xã",
    codename: "xa_te_xang",
    district_code: 617,
  },
  {
    name: "Xã Văn Xuôi",
    code: 23449,
    division_type: "xã",
    codename: "xa_van_xuoi",
    district_code: 617,
  },
  {
    name: "Xã Ia Đal",
    code: 23535,
    division_type: "xã",
    codename: "xa_ia_dal",
    district_code: 618,
  },
  {
    name: "Xã Ia Dom",
    code: 23537,
    division_type: "xã",
    codename: "xa_ia_dom",
    district_code: 618,
  },
  {
    name: "Xã Ia Tơi",
    code: 23538,
    division_type: "xã",
    codename: "xa_ia_toi",
    district_code: 618,
  },
  {
    name: "Phường Yên Đỗ",
    code: 23557,
    division_type: "phường",
    codename: "phuong_yen_do",
    district_code: 622,
  },
  {
    name: "Phường Diên Hồng",
    code: 23560,
    division_type: "phường",
    codename: "phuong_dien_hong",
    district_code: 622,
  },
  {
    name: "Phường Ia Kring",
    code: 23563,
    division_type: "phường",
    codename: "phuong_ia_kring",
    district_code: 622,
  },
  {
    name: "Phường Hội Thương",
    code: 23566,
    division_type: "phường",
    codename: "phuong_hoi_thuong",
    district_code: 622,
  },
  {
    name: "Phường Hội Phú",
    code: 23569,
    division_type: "phường",
    codename: "phuong_hoi_phu",
    district_code: 622,
  },
  {
    name: "Phường Phù Đổng",
    code: 23570,
    division_type: "phường",
    codename: "phuong_phu_dong",
    district_code: 622,
  },
  {
    name: "Phường Hoa Lư",
    code: 23572,
    division_type: "phường",
    codename: "phuong_hoa_lu",
    district_code: 622,
  },
  {
    name: "Phường Tây Sơn",
    code: 23575,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 622,
  },
  {
    name: "Phường Thống Nhất",
    code: 23578,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 622,
  },
  {
    name: "Phường Đống Đa",
    code: 23579,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 622,
  },
  {
    name: "Phường Trà Bá",
    code: 23581,
    division_type: "phường",
    codename: "phuong_tra_ba",
    district_code: 622,
  },
  {
    name: "Phường Thắng Lợi",
    code: 23582,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 622,
  },
  {
    name: "Phường Yên Thế",
    code: 23584,
    division_type: "phường",
    codename: "phuong_yen_the",
    district_code: 622,
  },
  {
    name: "Phường Chi Lăng",
    code: 23586,
    division_type: "phường",
    codename: "phuong_chi_lang",
    district_code: 622,
  },
  {
    name: "Xã Biển Hồ",
    code: 23590,
    division_type: "xã",
    codename: "xa_bien_ho",
    district_code: 622,
  },
  {
    name: "Xã Tân Sơn",
    code: 23593,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 622,
  },
  {
    name: "Xã Trà Đa",
    code: 23596,
    division_type: "xã",
    codename: "xa_tra_da",
    district_code: 622,
  },
  {
    name: "Xã Chư Á",
    code: 23599,
    division_type: "xã",
    codename: "xa_chu_a",
    district_code: 622,
  },
  {
    name: "Xã An Phú",
    code: 23602,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 622,
  },
  {
    name: "Xã Diên Phú",
    code: 23605,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 622,
  },
  {
    name: "Xã Ia Kênh",
    code: 23608,
    division_type: "xã",
    codename: "xa_ia_kenh",
    district_code: 622,
  },
  {
    name: "Xã Gào",
    code: 23611,
    division_type: "xã",
    codename: "xa_gao",
    district_code: 622,
  },
  {
    name: "Phường An Bình",
    code: 23614,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 623,
  },
  {
    name: "Phường Tây Sơn",
    code: 23617,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 623,
  },
  {
    name: "Phường An Phú",
    code: 23620,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 623,
  },
  {
    name: "Phường An Tân",
    code: 23623,
    division_type: "phường",
    codename: "phuong_an_tan",
    district_code: 623,
  },
  {
    name: "Xã Tú An",
    code: 23626,
    division_type: "xã",
    codename: "xa_tu_an",
    district_code: 623,
  },
  {
    name: "Xã Xuân An",
    code: 23627,
    division_type: "xã",
    codename: "xa_xuan_an",
    district_code: 623,
  },
  {
    name: "Xã Cửu An",
    code: 23629,
    division_type: "xã",
    codename: "xa_cuu_an",
    district_code: 623,
  },
  {
    name: "Phường An Phước",
    code: 23630,
    division_type: "phường",
    codename: "phuong_an_phuoc",
    district_code: 623,
  },
  {
    name: "Xã Song An",
    code: 23632,
    division_type: "xã",
    codename: "xa_song_an",
    district_code: 623,
  },
  {
    name: "Phường Ngô Mây",
    code: 23633,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 623,
  },
  {
    name: "Xã Thành An",
    code: 23635,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 623,
  },
  {
    name: "Phường Cheo Reo",
    code: 24041,
    division_type: "phường",
    codename: "phuong_cheo_reo",
    district_code: 624,
  },
  {
    name: "Phường Hòa Bình",
    code: 24042,
    division_type: "phường",
    codename: "phuong_hoa_binh",
    district_code: 624,
  },
  {
    name: "Phường Đoàn Kết",
    code: 24044,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 624,
  },
  {
    name: "Phường Sông Bờ",
    code: 24045,
    division_type: "phường",
    codename: "phuong_song_bo",
    district_code: 624,
  },
  {
    name: "Xã Ia RBol",
    code: 24064,
    division_type: "xã",
    codename: "xa_ia_rbol",
    district_code: 624,
  },
  {
    name: "Xã Chư Băh",
    code: 24065,
    division_type: "xã",
    codename: "xa_chu_bah",
    district_code: 624,
  },
  {
    name: "Xã Ia RTô",
    code: 24070,
    division_type: "xã",
    codename: "xa_ia_rto",
    district_code: 624,
  },
  {
    name: "Xã Ia Sao",
    code: 24073,
    division_type: "xã",
    codename: "xa_ia_sao",
    district_code: 624,
  },
  {
    name: "Thị trấn KBang",
    code: 23638,
    division_type: "thị trấn",
    codename: "thi_tran_kbang",
    district_code: 625,
  },
  {
    name: "Xã Kon Pne",
    code: 23641,
    division_type: "xã",
    codename: "xa_kon_pne",
    district_code: 625,
  },
  {
    name: "Xã Đăk Roong",
    code: 23644,
    division_type: "xã",
    codename: "xa_dak_roong",
    district_code: 625,
  },
  {
    name: "Xã Sơn Lang",
    code: 23647,
    division_type: "xã",
    codename: "xa_son_lang",
    district_code: 625,
  },
  {
    name: "Xã KRong",
    code: 23650,
    division_type: "xã",
    codename: "xa_krong",
    district_code: 625,
  },
  {
    name: "Xã Sơ Pai",
    code: 23653,
    division_type: "xã",
    codename: "xa_so_pai",
    district_code: 625,
  },
  {
    name: "Xã Lơ Ku",
    code: 23656,
    division_type: "xã",
    codename: "xa_lo_ku",
    district_code: 625,
  },
  {
    name: "Xã Đông",
    code: 23659,
    division_type: "xã",
    codename: "xa_dong",
    district_code: 625,
  },
  {
    name: "Xã Đak SMar",
    code: 23660,
    division_type: "xã",
    codename: "xa_dak_smar",
    district_code: 625,
  },
  {
    name: "Xã Nghĩa An",
    code: 23662,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 625,
  },
  {
    name: "Xã Tơ Tung",
    code: 23665,
    division_type: "xã",
    codename: "xa_to_tung",
    district_code: 625,
  },
  {
    name: "Xã Kông Lơng Khơng",
    code: 23668,
    division_type: "xã",
    codename: "xa_kong_long_khong",
    district_code: 625,
  },
  {
    name: "Xã Kông Pla",
    code: 23671,
    division_type: "xã",
    codename: "xa_kong_pla",
    district_code: 625,
  },
  {
    name: "Xã Đăk HLơ",
    code: 23674,
    division_type: "xã",
    codename: "xa_dak_hlo",
    district_code: 625,
  },
  {
    name: "Thị trấn Đăk Đoa",
    code: 23677,
    division_type: "thị trấn",
    codename: "thi_tran_dak_doa",
    district_code: 626,
  },
  {
    name: "Xã Hà Đông",
    code: 23680,
    division_type: "xã",
    codename: "xa_ha_dong",
    district_code: 626,
  },
  {
    name: "Xã Đăk Sơmei",
    code: 23683,
    division_type: "xã",
    codename: "xa_dak_somei",
    district_code: 626,
  },
  {
    name: "Xã Đăk Krong",
    code: 23684,
    division_type: "xã",
    codename: "xa_dak_krong",
    district_code: 626,
  },
  {
    name: "Xã Hải Yang",
    code: 23686,
    division_type: "xã",
    codename: "xa_hai_yang",
    district_code: 626,
  },
  {
    name: "Xã Kon Gang",
    code: 23689,
    division_type: "xã",
    codename: "xa_kon_gang",
    district_code: 626,
  },
  {
    name: "Xã Hà Bầu",
    code: 23692,
    division_type: "xã",
    codename: "xa_ha_bau",
    district_code: 626,
  },
  {
    name: "Xã Nam Yang",
    code: 23695,
    division_type: "xã",
    codename: "xa_nam_yang",
    district_code: 626,
  },
  {
    name: "Xã K' Dang",
    code: 23698,
    division_type: "xã",
    codename: "xa_k_dang",
    district_code: 626,
  },
  {
    name: "Xã H' Neng",
    code: 23701,
    division_type: "xã",
    codename: "xa_h_neng",
    district_code: 626,
  },
  {
    name: "Xã Tân Bình",
    code: 23704,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 626,
  },
  {
    name: "Xã Glar",
    code: 23707,
    division_type: "xã",
    codename: "xa_glar",
    district_code: 626,
  },
  {
    name: "Xã A Dơk",
    code: 23710,
    division_type: "xã",
    codename: "xa_a_dok",
    district_code: 626,
  },
  {
    name: "Xã Trang",
    code: 23713,
    division_type: "xã",
    codename: "xa_trang",
    district_code: 626,
  },
  {
    name: "Xã HNol",
    code: 23714,
    division_type: "xã",
    codename: "xa_hnol",
    district_code: 626,
  },
  {
    name: "Xã Ia Pết",
    code: 23716,
    division_type: "xã",
    codename: "xa_ia_pet",
    district_code: 626,
  },
  {
    name: "Xã Ia Băng",
    code: 23719,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 626,
  },
  {
    name: "Thị trấn Phú Hòa",
    code: 23722,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 627,
  },
  {
    name: "Xã Hà Tây",
    code: 23725,
    division_type: "xã",
    codename: "xa_ha_tay",
    district_code: 627,
  },
  {
    name: "Xã Ia Khươl",
    code: 23728,
    division_type: "xã",
    codename: "xa_ia_khuol",
    district_code: 627,
  },
  {
    name: "Xã Ia Phí",
    code: 23731,
    division_type: "xã",
    codename: "xa_ia_phi",
    district_code: 627,
  },
  {
    name: "Thị trấn Ia Ly",
    code: 23734,
    division_type: "thị trấn",
    codename: "thi_tran_ia_ly",
    district_code: 627,
  },
  {
    name: "Xã Ia Mơ Nông",
    code: 23737,
    division_type: "xã",
    codename: "xa_ia_mo_nong",
    district_code: 627,
  },
  {
    name: "Xã Ia Kreng",
    code: 23738,
    division_type: "xã",
    codename: "xa_ia_kreng",
    district_code: 627,
  },
  {
    name: "Xã Đăk Tơ Ver",
    code: 23740,
    division_type: "xã",
    codename: "xa_dak_to_ver",
    district_code: 627,
  },
  {
    name: "Xã Hòa Phú",
    code: 23743,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 627,
  },
  {
    name: "Xã Chư Đăng Ya",
    code: 23746,
    division_type: "xã",
    codename: "xa_chu_dang_ya",
    district_code: 627,
  },
  {
    name: "Xã Ia Ka",
    code: 23749,
    division_type: "xã",
    codename: "xa_ia_ka",
    district_code: 627,
  },
  {
    name: "Xã Ia Nhin",
    code: 23752,
    division_type: "xã",
    codename: "xa_ia_nhin",
    district_code: 627,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 23755,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 627,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 23761,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 627,
  },
  {
    name: "Thị trấn Ia Kha",
    code: 23764,
    division_type: "thị trấn",
    codename: "thi_tran_ia_kha",
    district_code: 628,
  },
  {
    name: "Xã Ia Sao",
    code: 23767,
    division_type: "xã",
    codename: "xa_ia_sao",
    district_code: 628,
  },
  {
    name: "Xã Ia Yok",
    code: 23768,
    division_type: "xã",
    codename: "xa_ia_yok",
    district_code: 628,
  },
  {
    name: "Xã Ia Hrung",
    code: 23770,
    division_type: "xã",
    codename: "xa_ia_hrung",
    district_code: 628,
  },
  {
    name: "Xã Ia Bă",
    code: 23771,
    division_type: "xã",
    codename: "xa_ia_ba",
    district_code: 628,
  },
  {
    name: "Xã Ia Khai",
    code: 23773,
    division_type: "xã",
    codename: "xa_ia_khai",
    district_code: 628,
  },
  {
    name: "Xã Ia KRai",
    code: 23776,
    division_type: "xã",
    codename: "xa_ia_krai",
    district_code: 628,
  },
  {
    name: "Xã Ia Grăng",
    code: 23778,
    division_type: "xã",
    codename: "xa_ia_grang",
    district_code: 628,
  },
  {
    name: "Xã Ia Tô",
    code: 23779,
    division_type: "xã",
    codename: "xa_ia_to",
    district_code: 628,
  },
  {
    name: "Xã Ia O",
    code: 23782,
    division_type: "xã",
    codename: "xa_ia_o",
    district_code: 628,
  },
  {
    name: "Xã Ia Dêr",
    code: 23785,
    division_type: "xã",
    codename: "xa_ia_der",
    district_code: 628,
  },
  {
    name: "Xã Ia Chia",
    code: 23788,
    division_type: "xã",
    codename: "xa_ia_chia",
    district_code: 628,
  },
  {
    name: "Xã Ia Pếch",
    code: 23791,
    division_type: "xã",
    codename: "xa_ia_pech",
    district_code: 628,
  },
  {
    name: "Thị trấn Kon Dơng",
    code: 23794,
    division_type: "thị trấn",
    codename: "thi_tran_kon_dong",
    district_code: 629,
  },
  {
    name: "Xã Ayun",
    code: 23797,
    division_type: "xã",
    codename: "xa_ayun",
    district_code: 629,
  },
  {
    name: "Xã Đak Jơ Ta",
    code: 23798,
    division_type: "xã",
    codename: "xa_dak_jo_ta",
    district_code: 629,
  },
  {
    name: "Xã Đak Ta Ley",
    code: 23799,
    division_type: "xã",
    codename: "xa_dak_ta_ley",
    district_code: 629,
  },
  {
    name: "Xã Hra",
    code: 23800,
    division_type: "xã",
    codename: "xa_hra",
    district_code: 629,
  },
  {
    name: "Xã Đăk Yă",
    code: 23803,
    division_type: "xã",
    codename: "xa_dak_ya",
    district_code: 629,
  },
  {
    name: "Xã Đăk Djrăng",
    code: 23806,
    division_type: "xã",
    codename: "xa_dak_djrang",
    district_code: 629,
  },
  {
    name: "Xã Lơ Pang",
    code: 23809,
    division_type: "xã",
    codename: "xa_lo_pang",
    district_code: 629,
  },
  {
    name: "Xã Kon Thụp",
    code: 23812,
    division_type: "xã",
    codename: "xa_kon_thup",
    district_code: 629,
  },
  {
    name: "Xã Đê Ar",
    code: 23815,
    division_type: "xã",
    codename: "xa_de_ar",
    district_code: 629,
  },
  {
    name: "Xã Kon Chiêng",
    code: 23818,
    division_type: "xã",
    codename: "xa_kon_chieng",
    district_code: 629,
  },
  {
    name: "Xã Đăk Trôi",
    code: 23821,
    division_type: "xã",
    codename: "xa_dak_troi",
    district_code: 629,
  },
  {
    name: "Thị trấn Kông Chro",
    code: 23824,
    division_type: "thị trấn",
    codename: "thi_tran_kong_chro",
    district_code: 630,
  },
  {
    name: "Xã Chư Krêy",
    code: 23827,
    division_type: "xã",
    codename: "xa_chu_krey",
    district_code: 630,
  },
  {
    name: "Xã An Trung",
    code: 23830,
    division_type: "xã",
    codename: "xa_an_trung",
    district_code: 630,
  },
  {
    name: "Xã Kông Yang",
    code: 23833,
    division_type: "xã",
    codename: "xa_kong_yang",
    district_code: 630,
  },
  {
    name: "Xã Đăk Tơ Pang",
    code: 23836,
    division_type: "xã",
    codename: "xa_dak_to_pang",
    district_code: 630,
  },
  {
    name: "Xã SRó",
    code: 23839,
    division_type: "xã",
    codename: "xa_sro",
    district_code: 630,
  },
  {
    name: "Xã Đắk Kơ Ning",
    code: 23840,
    division_type: "xã",
    codename: "xa_dak_ko_ning",
    district_code: 630,
  },
  {
    name: "Xã Đăk Song",
    code: 23842,
    division_type: "xã",
    codename: "xa_dak_song",
    district_code: 630,
  },
  {
    name: "Xã Đăk Pling",
    code: 23843,
    division_type: "xã",
    codename: "xa_dak_pling",
    district_code: 630,
  },
  {
    name: "Xã Yang Trung",
    code: 23845,
    division_type: "xã",
    codename: "xa_yang_trung",
    district_code: 630,
  },
  {
    name: "Xã Đăk Pơ Pho",
    code: 23846,
    division_type: "xã",
    codename: "xa_dak_po_pho",
    district_code: 630,
  },
  {
    name: "Xã Ya Ma",
    code: 23848,
    division_type: "xã",
    codename: "xa_ya_ma",
    district_code: 630,
  },
  {
    name: "Xã Chơ Long",
    code: 23851,
    division_type: "xã",
    codename: "xa_cho_long",
    district_code: 630,
  },
  {
    name: "Xã Yang Nam",
    code: 23854,
    division_type: "xã",
    codename: "xa_yang_nam",
    district_code: 630,
  },
  {
    name: "Thị trấn Chư Ty",
    code: 23857,
    division_type: "thị trấn",
    codename: "thi_tran_chu_ty",
    district_code: 631,
  },
  {
    name: "Xã Ia Dơk",
    code: 23860,
    division_type: "xã",
    codename: "xa_ia_dok",
    district_code: 631,
  },
  {
    name: "Xã Ia Krêl",
    code: 23863,
    division_type: "xã",
    codename: "xa_ia_krel",
    district_code: 631,
  },
  {
    name: "Xã Ia Din",
    code: 23866,
    division_type: "xã",
    codename: "xa_ia_din",
    district_code: 631,
  },
  {
    name: "Xã Ia Kla",
    code: 23869,
    division_type: "xã",
    codename: "xa_ia_kla",
    district_code: 631,
  },
  {
    name: "Xã Ia Dom",
    code: 23872,
    division_type: "xã",
    codename: "xa_ia_dom",
    district_code: 631,
  },
  {
    name: "Xã Ia Lang",
    code: 23875,
    division_type: "xã",
    codename: "xa_ia_lang",
    district_code: 631,
  },
  {
    name: "Xã Ia Kriêng",
    code: 23878,
    division_type: "xã",
    codename: "xa_ia_krieng",
    district_code: 631,
  },
  {
    name: "Xã Ia Pnôn",
    code: 23881,
    division_type: "xã",
    codename: "xa_ia_pnon",
    district_code: 631,
  },
  {
    name: "Xã Ia Nan",
    code: 23884,
    division_type: "xã",
    codename: "xa_ia_nan",
    district_code: 631,
  },
  {
    name: "Thị trấn Chư Prông",
    code: 23887,
    division_type: "thị trấn",
    codename: "thi_tran_chu_prong",
    district_code: 632,
  },
  {
    name: "Xã Ia Kly",
    code: 23888,
    division_type: "xã",
    codename: "xa_ia_kly",
    district_code: 632,
  },
  {
    name: "Xã Bình Giáo",
    code: 23890,
    division_type: "xã",
    codename: "xa_binh_giao",
    district_code: 632,
  },
  {
    name: "Xã Ia Drăng",
    code: 23893,
    division_type: "xã",
    codename: "xa_ia_drang",
    district_code: 632,
  },
  {
    name: "Xã Thăng Hưng",
    code: 23896,
    division_type: "xã",
    codename: "xa_thang_hung",
    district_code: 632,
  },
  {
    name: "Xã Bàu Cạn",
    code: 23899,
    division_type: "xã",
    codename: "xa_bau_can",
    district_code: 632,
  },
  {
    name: "Xã Ia Phìn",
    code: 23902,
    division_type: "xã",
    codename: "xa_ia_phin",
    district_code: 632,
  },
  {
    name: "Xã Ia Băng",
    code: 23905,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 632,
  },
  {
    name: "Xã Ia Tôr",
    code: 23908,
    division_type: "xã",
    codename: "xa_ia_tor",
    district_code: 632,
  },
  {
    name: "Xã Ia Boòng",
    code: 23911,
    division_type: "xã",
    codename: "xa_ia_boong",
    district_code: 632,
  },
  {
    name: "Xã Ia O",
    code: 23914,
    division_type: "xã",
    codename: "xa_ia_o",
    district_code: 632,
  },
  {
    name: "Xã Ia Púch",
    code: 23917,
    division_type: "xã",
    codename: "xa_ia_puch",
    district_code: 632,
  },
  {
    name: "Xã Ia Me",
    code: 23920,
    division_type: "xã",
    codename: "xa_ia_me",
    district_code: 632,
  },
  {
    name: "Xã Ia Vê",
    code: 23923,
    division_type: "xã",
    codename: "xa_ia_ve",
    district_code: 632,
  },
  {
    name: "Xã Ia Bang",
    code: 23924,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 632,
  },
  {
    name: "Xã Ia Pia",
    code: 23926,
    division_type: "xã",
    codename: "xa_ia_pia",
    district_code: 632,
  },
  {
    name: "Xã Ia Ga",
    code: 23929,
    division_type: "xã",
    codename: "xa_ia_ga",
    district_code: 632,
  },
  {
    name: "Xã Ia Lâu",
    code: 23932,
    division_type: "xã",
    codename: "xa_ia_lau",
    district_code: 632,
  },
  {
    name: "Xã Ia Piơr",
    code: 23935,
    division_type: "xã",
    codename: "xa_ia_pior",
    district_code: 632,
  },
  {
    name: "Xã Ia Mơ",
    code: 23938,
    division_type: "xã",
    codename: "xa_ia_mo",
    district_code: 632,
  },
  {
    name: "Thị trấn Chư Sê",
    code: 23941,
    division_type: "thị trấn",
    codename: "thi_tran_chu_se",
    district_code: 633,
  },
  {
    name: "Xã Ia Tiêm",
    code: 23944,
    division_type: "xã",
    codename: "xa_ia_tiem",
    district_code: 633,
  },
  {
    name: "Xã Chư Pơng",
    code: 23945,
    division_type: "xã",
    codename: "xa_chu_pong",
    district_code: 633,
  },
  {
    name: "Xã Bar Măih",
    code: 23946,
    division_type: "xã",
    codename: "xa_bar_maih",
    district_code: 633,
  },
  {
    name: "Xã Bờ Ngoong",
    code: 23947,
    division_type: "xã",
    codename: "xa_bo_ngoong",
    district_code: 633,
  },
  {
    name: "Xã Ia Glai",
    code: 23950,
    division_type: "xã",
    codename: "xa_ia_glai",
    district_code: 633,
  },
  {
    name: "Xã AL Bá",
    code: 23953,
    division_type: "xã",
    codename: "xa_al_ba",
    district_code: 633,
  },
  {
    name: "Xã Kông HTok",
    code: 23954,
    division_type: "xã",
    codename: "xa_kong_htok",
    district_code: 633,
  },
  {
    name: "Xã AYun",
    code: 23956,
    division_type: "xã",
    codename: "xa_ayun",
    district_code: 633,
  },
  {
    name: "Xã Ia HLốp",
    code: 23959,
    division_type: "xã",
    codename: "xa_ia_hlop",
    district_code: 633,
  },
  {
    name: "Xã Ia Blang",
    code: 23962,
    division_type: "xã",
    codename: "xa_ia_blang",
    district_code: 633,
  },
  {
    name: "Xã Dun",
    code: 23965,
    division_type: "xã",
    codename: "xa_dun",
    district_code: 633,
  },
  {
    name: "Xã Ia Pal",
    code: 23966,
    division_type: "xã",
    codename: "xa_ia_pal",
    district_code: 633,
  },
  {
    name: "Xã H Bông",
    code: 23968,
    division_type: "xã",
    codename: "xa_h_bong",
    district_code: 633,
  },
  {
    name: "Xã Ia Ko",
    code: 23977,
    division_type: "xã",
    codename: "xa_ia_ko",
    district_code: 633,
  },
  {
    name: "Xã Hà Tam",
    code: 23989,
    division_type: "xã",
    codename: "xa_ha_tam",
    district_code: 634,
  },
  {
    name: "Xã An Thành",
    code: 23992,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 634,
  },
  {
    name: "Thị trấn Đak Pơ",
    code: 23995,
    division_type: "thị trấn",
    codename: "thi_tran_dak_po",
    district_code: 634,
  },
  {
    name: "Xã Yang Bắc",
    code: 23998,
    division_type: "xã",
    codename: "xa_yang_bac",
    district_code: 634,
  },
  {
    name: "Xã Cư An",
    code: 24001,
    division_type: "xã",
    codename: "xa_cu_an",
    district_code: 634,
  },
  {
    name: "Xã Tân An",
    code: 24004,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 634,
  },
  {
    name: "Xã Phú An",
    code: 24007,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 634,
  },
  {
    name: "Xã Ya Hội",
    code: 24010,
    division_type: "xã",
    codename: "xa_ya_hoi",
    district_code: 634,
  },
  {
    name: "Xã Pờ Tó",
    code: 24013,
    division_type: "xã",
    codename: "xa_po_to",
    district_code: 635,
  },
  {
    name: "Xã Chư Răng",
    code: 24016,
    division_type: "xã",
    codename: "xa_chu_rang",
    district_code: 635,
  },
  {
    name: "Xã Ia KDăm",
    code: 24019,
    division_type: "xã",
    codename: "xa_ia_kdam",
    district_code: 635,
  },
  {
    name: "Xã Kim Tân",
    code: 24022,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 635,
  },
  {
    name: "Xã Chư Mố",
    code: 24025,
    division_type: "xã",
    codename: "xa_chu_mo",
    district_code: 635,
  },
  {
    name: "Xã Ia Tul",
    code: 24028,
    division_type: "xã",
    codename: "xa_ia_tul",
    district_code: 635,
  },
  {
    name: "Xã Ia Ma Rơn",
    code: 24031,
    division_type: "xã",
    codename: "xa_ia_ma_ron",
    district_code: 635,
  },
  {
    name: "Xã Ia Broăi",
    code: 24034,
    division_type: "xã",
    codename: "xa_ia_broai",
    district_code: 635,
  },
  {
    name: "Xã Ia Trok",
    code: 24037,
    division_type: "xã",
    codename: "xa_ia_trok",
    district_code: 635,
  },
  {
    name: "Thị trấn Phú Túc",
    code: 24076,
    division_type: "thị trấn",
    codename: "thi_tran_phu_tuc",
    district_code: 637,
  },
  {
    name: "Xã Ia RSai",
    code: 24079,
    division_type: "xã",
    codename: "xa_ia_rsai",
    district_code: 637,
  },
  {
    name: "Xã Ia RSươm",
    code: 24082,
    division_type: "xã",
    codename: "xa_ia_rsuom",
    district_code: 637,
  },
  {
    name: "Xã Chư Gu",
    code: 24085,
    division_type: "xã",
    codename: "xa_chu_gu",
    district_code: 637,
  },
  {
    name: "Xã Đất Bằng",
    code: 24088,
    division_type: "xã",
    codename: "xa_dat_bang",
    district_code: 637,
  },
  {
    name: "Xã Ia Mláh",
    code: 24091,
    division_type: "xã",
    codename: "xa_ia_mlah",
    district_code: 637,
  },
  {
    name: "Xã Chư Drăng",
    code: 24094,
    division_type: "xã",
    codename: "xa_chu_drang",
    district_code: 637,
  },
  {
    name: "Xã Phú Cần",
    code: 24097,
    division_type: "xã",
    codename: "xa_phu_can",
    district_code: 637,
  },
  {
    name: "Xã Ia HDreh",
    code: 24100,
    division_type: "xã",
    codename: "xa_ia_hdreh",
    district_code: 637,
  },
  {
    name: "Xã Ia RMok",
    code: 24103,
    division_type: "xã",
    codename: "xa_ia_rmok",
    district_code: 637,
  },
  {
    name: "Xã Chư Ngọc",
    code: 24106,
    division_type: "xã",
    codename: "xa_chu_ngoc",
    district_code: 637,
  },
  {
    name: "Xã Uar",
    code: 24109,
    division_type: "xã",
    codename: "xa_uar",
    district_code: 637,
  },
  {
    name: "Xã Chư Rcăm",
    code: 24112,
    division_type: "xã",
    codename: "xa_chu_rcam",
    district_code: 637,
  },
  {
    name: "Xã Krông Năng",
    code: 24115,
    division_type: "xã",
    codename: "xa_krong_nang",
    district_code: 637,
  },
  {
    name: "Thị trấn Phú Thiện",
    code: 24043,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thien",
    district_code: 638,
  },
  {
    name: "Xã Chư A Thai",
    code: 24046,
    division_type: "xã",
    codename: "xa_chu_a_thai",
    district_code: 638,
  },
  {
    name: "Xã Ayun Hạ",
    code: 24048,
    division_type: "xã",
    codename: "xa_ayun_ha",
    district_code: 638,
  },
  {
    name: "Xã Ia Ake",
    code: 24049,
    division_type: "xã",
    codename: "xa_ia_ake",
    district_code: 638,
  },
  {
    name: "Xã Ia Sol",
    code: 24052,
    division_type: "xã",
    codename: "xa_ia_sol",
    district_code: 638,
  },
  {
    name: "Xã Ia Piar",
    code: 24055,
    division_type: "xã",
    codename: "xa_ia_piar",
    district_code: 638,
  },
  {
    name: "Xã Ia Peng",
    code: 24058,
    division_type: "xã",
    codename: "xa_ia_peng",
    district_code: 638,
  },
  {
    name: "Xã Chrôh Pơnan",
    code: 24060,
    division_type: "xã",
    codename: "xa_chroh_ponan",
    district_code: 638,
  },
  {
    name: "Xã Ia Hiao",
    code: 24061,
    division_type: "xã",
    codename: "xa_ia_hiao",
    district_code: 638,
  },
  {
    name: "Xã Ia Yeng",
    code: 24067,
    division_type: "xã",
    codename: "xa_ia_yeng",
    district_code: 638,
  },
  {
    name: "Thị trấn Nhơn Hoà",
    code: 23942,
    division_type: "thị trấn",
    codename: "thi_tran_nhon_hoa",
    district_code: 639,
  },
  {
    name: "Xã Ia Hrú",
    code: 23971,
    division_type: "xã",
    codename: "xa_ia_hru",
    district_code: 639,
  },
  {
    name: "Xã Ia Rong",
    code: 23972,
    division_type: "xã",
    codename: "xa_ia_rong",
    district_code: 639,
  },
  {
    name: "Xã Ia Dreng",
    code: 23974,
    division_type: "xã",
    codename: "xa_ia_dreng",
    district_code: 639,
  },
  {
    name: "Xã Ia Hla",
    code: 23978,
    division_type: "xã",
    codename: "xa_ia_hla",
    district_code: 639,
  },
  {
    name: "Xã Chư Don",
    code: 23980,
    division_type: "xã",
    codename: "xa_chu_don",
    district_code: 639,
  },
  {
    name: "Xã Ia Phang",
    code: 23983,
    division_type: "xã",
    codename: "xa_ia_phang",
    district_code: 639,
  },
  {
    name: "Xã Ia Le",
    code: 23986,
    division_type: "xã",
    codename: "xa_ia_le",
    district_code: 639,
  },
  {
    name: "Xã Ia BLứ",
    code: 23987,
    division_type: "xã",
    codename: "xa_ia_blu",
    district_code: 639,
  },
  {
    name: "Phường Tân Lập",
    code: 24118,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 643,
  },
  {
    name: "Phường Tân Hòa",
    code: 24121,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 643,
  },
  {
    name: "Phường Tân An",
    code: 24124,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 643,
  },
  {
    name: "Phường Thống Nhất",
    code: 24127,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 643,
  },
  {
    name: "Phường Thành Nhất",
    code: 24130,
    division_type: "phường",
    codename: "phuong_thanh_nhat",
    district_code: 643,
  },
  {
    name: "Phường Thắng Lợi",
    code: 24133,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 643,
  },
  {
    name: "Phường Tân Lợi",
    code: 24136,
    division_type: "phường",
    codename: "phuong_tan_loi",
    district_code: 643,
  },
  {
    name: "Phường Thành Công",
    code: 24139,
    division_type: "phường",
    codename: "phuong_thanh_cong",
    district_code: 643,
  },
  {
    name: "Phường Tân Thành",
    code: 24142,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 643,
  },
  {
    name: "Phường Tân Tiến",
    code: 24145,
    division_type: "phường",
    codename: "phuong_tan_tien",
    district_code: 643,
  },
  {
    name: "Phường Tự An",
    code: 24148,
    division_type: "phường",
    codename: "phuong_tu_an",
    district_code: 643,
  },
  {
    name: "Phường Ea Tam",
    code: 24151,
    division_type: "phường",
    codename: "phuong_ea_tam",
    district_code: 643,
  },
  {
    name: "Phường Khánh Xuân",
    code: 24154,
    division_type: "phường",
    codename: "phuong_khanh_xuan",
    district_code: 643,
  },
  {
    name: "Xã Hòa Thuận",
    code: 24157,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 643,
  },
  {
    name: "Xã Cư ÊBur",
    code: 24160,
    division_type: "xã",
    codename: "xa_cu_ebur",
    district_code: 643,
  },
  {
    name: "Xã Ea Tu",
    code: 24163,
    division_type: "xã",
    codename: "xa_ea_tu",
    district_code: 643,
  },
  {
    name: "Xã Hòa Thắng",
    code: 24166,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 643,
  },
  {
    name: "Xã Ea Kao",
    code: 24169,
    division_type: "xã",
    codename: "xa_ea_kao",
    district_code: 643,
  },
  {
    name: "Xã Hòa Phú",
    code: 24172,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 643,
  },
  {
    name: "Xã Hòa Khánh",
    code: 24175,
    division_type: "xã",
    codename: "xa_hoa_khanh",
    district_code: 643,
  },
  {
    name: "Xã Hòa Xuân",
    code: 24178,
    division_type: "xã",
    codename: "xa_hoa_xuan",
    district_code: 643,
  },
  {
    name: "Phường An Lạc",
    code: 24305,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 644,
  },
  {
    name: "Phường An Bình",
    code: 24308,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 644,
  },
  {
    name: "Phường Thiện An",
    code: 24311,
    division_type: "phường",
    codename: "phuong_thien_an",
    district_code: 644,
  },
  {
    name: "Phường Đạt Hiếu",
    code: 24318,
    division_type: "phường",
    codename: "phuong_dat_hieu",
    district_code: 644,
  },
  {
    name: "Phường Đoàn Kết",
    code: 24322,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 644,
  },
  {
    name: "Xã Ea Blang",
    code: 24325,
    division_type: "xã",
    codename: "xa_ea_blang",
    district_code: 644,
  },
  {
    name: "Xã Ea Drông",
    code: 24328,
    division_type: "xã",
    codename: "xa_ea_drong",
    district_code: 644,
  },
  {
    name: "Phường Thống Nhất",
    code: 24331,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 644,
  },
  {
    name: "Phường Bình Tân",
    code: 24332,
    division_type: "phường",
    codename: "phuong_binh_tan",
    district_code: 644,
  },
  {
    name: "Xã Ea Siên",
    code: 24334,
    division_type: "xã",
    codename: "xa_ea_sien",
    district_code: 644,
  },
  {
    name: "Xã Bình Thuận",
    code: 24337,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 644,
  },
  {
    name: "Xã Cư Bao",
    code: 24340,
    division_type: "xã",
    codename: "xa_cu_bao",
    district_code: 644,
  },
  {
    name: "Thị trấn Ea Drăng",
    code: 24181,
    division_type: "thị trấn",
    codename: "thi_tran_ea_drang",
    district_code: 645,
  },
  {
    name: "Xã Ea H'leo",
    code: 24184,
    division_type: "xã",
    codename: "xa_ea_hleo",
    district_code: 645,
  },
  {
    name: "Xã Ea Sol",
    code: 24187,
    division_type: "xã",
    codename: "xa_ea_sol",
    district_code: 645,
  },
  {
    name: "Xã Ea Ral",
    code: 24190,
    division_type: "xã",
    codename: "xa_ea_ral",
    district_code: 645,
  },
  {
    name: "Xã Ea Wy",
    code: 24193,
    division_type: "xã",
    codename: "xa_ea_wy",
    district_code: 645,
  },
  {
    name: "Xã Cư A Mung",
    code: 24194,
    division_type: "xã",
    codename: "xa_cu_a_mung",
    district_code: 645,
  },
  {
    name: "Xã Cư Mốt",
    code: 24196,
    division_type: "xã",
    codename: "xa_cu_mot",
    district_code: 645,
  },
  {
    name: "Xã Ea Hiao",
    code: 24199,
    division_type: "xã",
    codename: "xa_ea_hiao",
    district_code: 645,
  },
  {
    name: "Xã Ea Khal",
    code: 24202,
    division_type: "xã",
    codename: "xa_ea_khal",
    district_code: 645,
  },
  {
    name: "Xã Dliê Yang",
    code: 24205,
    division_type: "xã",
    codename: "xa_dlie_yang",
    district_code: 645,
  },
  {
    name: "Xã Ea Tir",
    code: 24207,
    division_type: "xã",
    codename: "xa_ea_tir",
    district_code: 645,
  },
  {
    name: "Xã Ea Nam",
    code: 24208,
    division_type: "xã",
    codename: "xa_ea_nam",
    district_code: 645,
  },
  {
    name: "Thị trấn Ea Súp",
    code: 24211,
    division_type: "thị trấn",
    codename: "thi_tran_ea_sup",
    district_code: 646,
  },
  {
    name: "Xã Ia Lốp",
    code: 24214,
    division_type: "xã",
    codename: "xa_ia_lop",
    district_code: 646,
  },
  {
    name: "Xã Ia JLơi",
    code: 24215,
    division_type: "xã",
    codename: "xa_ia_jloi",
    district_code: 646,
  },
  {
    name: "Xã Ea Rốk",
    code: 24217,
    division_type: "xã",
    codename: "xa_ea_rok",
    district_code: 646,
  },
  {
    name: "Xã Ya Tờ Mốt",
    code: 24220,
    division_type: "xã",
    codename: "xa_ya_to_mot",
    district_code: 646,
  },
  {
    name: "Xã Ia RVê",
    code: 24221,
    division_type: "xã",
    codename: "xa_ia_rve",
    district_code: 646,
  },
  {
    name: "Xã Ea Lê",
    code: 24223,
    division_type: "xã",
    codename: "xa_ea_le",
    district_code: 646,
  },
  {
    name: "Xã Cư KBang",
    code: 24226,
    division_type: "xã",
    codename: "xa_cu_kbang",
    district_code: 646,
  },
  {
    name: "Xã Ea Bung",
    code: 24229,
    division_type: "xã",
    codename: "xa_ea_bung",
    district_code: 646,
  },
  {
    name: "Xã Cư M'Lan",
    code: 24232,
    division_type: "xã",
    codename: "xa_cu_mlan",
    district_code: 646,
  },
  {
    name: "Xã Krông Na",
    code: 24235,
    division_type: "xã",
    codename: "xa_krong_na",
    district_code: 647,
  },
  {
    name: "Xã Ea Huar",
    code: 24238,
    division_type: "xã",
    codename: "xa_ea_huar",
    district_code: 647,
  },
  {
    name: "Xã Ea Wer",
    code: 24241,
    division_type: "xã",
    codename: "xa_ea_wer",
    district_code: 647,
  },
  {
    name: "Xã Tân Hoà",
    code: 24244,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 647,
  },
  {
    name: "Xã Cuôr KNia",
    code: 24247,
    division_type: "xã",
    codename: "xa_cuor_knia",
    district_code: 647,
  },
  {
    name: "Xã Ea Bar",
    code: 24250,
    division_type: "xã",
    codename: "xa_ea_bar",
    district_code: 647,
  },
  {
    name: "Xã Ea Nuôl",
    code: 24253,
    division_type: "xã",
    codename: "xa_ea_nuol",
    district_code: 647,
  },
  {
    name: "Thị trấn Ea Pốk",
    code: 24256,
    division_type: "thị trấn",
    codename: "thi_tran_ea_pok",
    district_code: 648,
  },
  {
    name: "Thị trấn Quảng Phú",
    code: 24259,
    division_type: "thị trấn",
    codename: "thi_tran_quang_phu",
    district_code: 648,
  },
  {
    name: "Xã Quảng Tiến",
    code: 24262,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 648,
  },
  {
    name: "Xã Ea Kuêh",
    code: 24264,
    division_type: "xã",
    codename: "xa_ea_kueh",
    district_code: 648,
  },
  {
    name: "Xã Ea Kiết",
    code: 24265,
    division_type: "xã",
    codename: "xa_ea_kiet",
    district_code: 648,
  },
  {
    name: "Xã Ea Tar",
    code: 24268,
    division_type: "xã",
    codename: "xa_ea_tar",
    district_code: 648,
  },
  {
    name: "Xã Cư Dliê M'nông",
    code: 24271,
    division_type: "xã",
    codename: "xa_cu_dlie_mnong",
    district_code: 648,
  },
  {
    name: "Xã Ea H'đinh",
    code: 24274,
    division_type: "xã",
    codename: "xa_ea_hdinh",
    district_code: 648,
  },
  {
    name: "Xã Ea Tul",
    code: 24277,
    division_type: "xã",
    codename: "xa_ea_tul",
    district_code: 648,
  },
  {
    name: "Xã Ea KPam",
    code: 24280,
    division_type: "xã",
    codename: "xa_ea_kpam",
    district_code: 648,
  },
  {
    name: "Xã Ea M'DRóh",
    code: 24283,
    division_type: "xã",
    codename: "xa_ea_mdroh",
    district_code: 648,
  },
  {
    name: "Xã Quảng Hiệp",
    code: 24286,
    division_type: "xã",
    codename: "xa_quang_hiep",
    district_code: 648,
  },
  {
    name: "Xã Cư M'gar",
    code: 24289,
    division_type: "xã",
    codename: "xa_cu_mgar",
    district_code: 648,
  },
  {
    name: "Xã Ea D'Rơng",
    code: 24292,
    division_type: "xã",
    codename: "xa_ea_drong",
    district_code: 648,
  },
  {
    name: "Xã Ea M'nang",
    code: 24295,
    division_type: "xã",
    codename: "xa_ea_mnang",
    district_code: 648,
  },
  {
    name: "Xã Cư Suê",
    code: 24298,
    division_type: "xã",
    codename: "xa_cu_sue",
    district_code: 648,
  },
  {
    name: "Xã Cuor Đăng",
    code: 24301,
    division_type: "xã",
    codename: "xa_cuor_dang",
    district_code: 648,
  },
  {
    name: "Xã Cư Né",
    code: 24307,
    division_type: "xã",
    codename: "xa_cu_ne",
    district_code: 649,
  },
  {
    name: "Xã Chư KBô",
    code: 24310,
    division_type: "xã",
    codename: "xa_chu_kbo",
    district_code: 649,
  },
  {
    name: "Xã Cư Pơng",
    code: 24313,
    division_type: "xã",
    codename: "xa_cu_pong",
    district_code: 649,
  },
  {
    name: "Xã Ea Sin",
    code: 24314,
    division_type: "xã",
    codename: "xa_ea_sin",
    district_code: 649,
  },
  {
    name: "Xã Pơng Drang",
    code: 24316,
    division_type: "xã",
    codename: "xa_pong_drang",
    district_code: 649,
  },
  {
    name: "Xã Tân Lập",
    code: 24317,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 649,
  },
  {
    name: "Xã Ea Ngai",
    code: 24319,
    division_type: "xã",
    codename: "xa_ea_ngai",
    district_code: 649,
  },
  {
    name: "Thị trấn Krông Năng",
    code: 24343,
    division_type: "thị trấn",
    codename: "thi_tran_krong_nang",
    district_code: 650,
  },
  {
    name: "Xã ĐLiê Ya",
    code: 24346,
    division_type: "xã",
    codename: "xa_dlie_ya",
    district_code: 650,
  },
  {
    name: "Xã Ea Tóh",
    code: 24349,
    division_type: "xã",
    codename: "xa_ea_toh",
    district_code: 650,
  },
  {
    name: "Xã Ea Tam",
    code: 24352,
    division_type: "xã",
    codename: "xa_ea_tam",
    district_code: 650,
  },
  {
    name: "Xã Phú Lộc",
    code: 24355,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 650,
  },
  {
    name: "Xã Tam Giang",
    code: 24358,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 650,
  },
  {
    name: "Xã Ea Puk",
    code: 24359,
    division_type: "xã",
    codename: "xa_ea_puk",
    district_code: 650,
  },
  {
    name: "Xã Ea Dăh",
    code: 24360,
    division_type: "xã",
    codename: "xa_ea_dah",
    district_code: 650,
  },
  {
    name: "Xã Ea Hồ",
    code: 24361,
    division_type: "xã",
    codename: "xa_ea_ho",
    district_code: 650,
  },
  {
    name: "Xã Phú Xuân",
    code: 24364,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 650,
  },
  {
    name: "Xã Cư Klông",
    code: 24367,
    division_type: "xã",
    codename: "xa_cu_klong",
    district_code: 650,
  },
  {
    name: "Xã Ea Tân",
    code: 24370,
    division_type: "xã",
    codename: "xa_ea_tan",
    district_code: 650,
  },
  {
    name: "Thị trấn Ea Kar",
    code: 24373,
    division_type: "thị trấn",
    codename: "thi_tran_ea_kar",
    district_code: 651,
  },
  {
    name: "Thị trấn Ea Knốp",
    code: 24376,
    division_type: "thị trấn",
    codename: "thi_tran_ea_knop",
    district_code: 651,
  },
  {
    name: "Xã Ea Sô",
    code: 24379,
    division_type: "xã",
    codename: "xa_ea_so",
    district_code: 651,
  },
  {
    name: "Xã Ea Sar",
    code: 24380,
    division_type: "xã",
    codename: "xa_ea_sar",
    district_code: 651,
  },
  {
    name: "Xã Xuân Phú",
    code: 24382,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 651,
  },
  {
    name: "Xã Cư Huê",
    code: 24385,
    division_type: "xã",
    codename: "xa_cu_hue",
    district_code: 651,
  },
  {
    name: "Xã Ea Tih",
    code: 24388,
    division_type: "xã",
    codename: "xa_ea_tih",
    district_code: 651,
  },
  {
    name: "Xã Ea Đar",
    code: 24391,
    division_type: "xã",
    codename: "xa_ea_dar",
    district_code: 651,
  },
  {
    name: "Xã Ea Kmút",
    code: 24394,
    division_type: "xã",
    codename: "xa_ea_kmut",
    district_code: 651,
  },
  {
    name: "Xã Cư Ni",
    code: 24397,
    division_type: "xã",
    codename: "xa_cu_ni",
    district_code: 651,
  },
  {
    name: "Xã Ea Păl",
    code: 24400,
    division_type: "xã",
    codename: "xa_ea_pal",
    district_code: 651,
  },
  {
    name: "Xã Cư Prông",
    code: 24401,
    division_type: "xã",
    codename: "xa_cu_prong",
    district_code: 651,
  },
  {
    name: "Xã Ea Ô",
    code: 24403,
    division_type: "xã",
    codename: "xa_ea_o",
    district_code: 651,
  },
  {
    name: "Xã Cư ELang",
    code: 24404,
    division_type: "xã",
    codename: "xa_cu_elang",
    district_code: 651,
  },
  {
    name: "Xã Cư Bông",
    code: 24406,
    division_type: "xã",
    codename: "xa_cu_bong",
    district_code: 651,
  },
  {
    name: "Xã Cư Jang",
    code: 24409,
    division_type: "xã",
    codename: "xa_cu_jang",
    district_code: 651,
  },
  {
    name: "Thị trấn M'Đrắk",
    code: 24412,
    division_type: "thị trấn",
    codename: "thi_tran_mdrak",
    district_code: 652,
  },
  {
    name: "Xã Cư Prao",
    code: 24415,
    division_type: "xã",
    codename: "xa_cu_prao",
    district_code: 652,
  },
  {
    name: "Xã Ea Pil",
    code: 24418,
    division_type: "xã",
    codename: "xa_ea_pil",
    district_code: 652,
  },
  {
    name: "Xã Ea Lai",
    code: 24421,
    division_type: "xã",
    codename: "xa_ea_lai",
    district_code: 652,
  },
  {
    name: "Xã Ea H'MLay",
    code: 24424,
    division_type: "xã",
    codename: "xa_ea_hmlay",
    district_code: 652,
  },
  {
    name: "Xã Krông Jing",
    code: 24427,
    division_type: "xã",
    codename: "xa_krong_jing",
    district_code: 652,
  },
  {
    name: "Xã Ea M' Doal",
    code: 24430,
    division_type: "xã",
    codename: "xa_ea_m_doal",
    district_code: 652,
  },
  {
    name: "Xã Ea Riêng",
    code: 24433,
    division_type: "xã",
    codename: "xa_ea_rieng",
    district_code: 652,
  },
  {
    name: "Xã Cư M'ta",
    code: 24436,
    division_type: "xã",
    codename: "xa_cu_mta",
    district_code: 652,
  },
  {
    name: "Xã Cư K Róa",
    code: 24439,
    division_type: "xã",
    codename: "xa_cu_k_roa",
    district_code: 652,
  },
  {
    name: "Xã Krông Á",
    code: 24442,
    division_type: "xã",
    codename: "xa_krong_a",
    district_code: 652,
  },
  {
    name: "Xã Cư San",
    code: 24444,
    division_type: "xã",
    codename: "xa_cu_san",
    district_code: 652,
  },
  {
    name: "Xã Ea Trang",
    code: 24445,
    division_type: "xã",
    codename: "xa_ea_trang",
    district_code: 652,
  },
  {
    name: "Thị trấn Krông Kmar",
    code: 24448,
    division_type: "thị trấn",
    codename: "thi_tran_krong_kmar",
    district_code: 653,
  },
  {
    name: "Xã Dang Kang",
    code: 24451,
    division_type: "xã",
    codename: "xa_dang_kang",
    district_code: 653,
  },
  {
    name: "Xã Cư KTy",
    code: 24454,
    division_type: "xã",
    codename: "xa_cu_kty",
    district_code: 653,
  },
  {
    name: "Xã Hòa Thành",
    code: 24457,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 653,
  },
  {
    name: "Xã Hòa Tân",
    code: 24460,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 653,
  },
  {
    name: "Xã Hòa Phong",
    code: 24463,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 653,
  },
  {
    name: "Xã Hòa Lễ",
    code: 24466,
    division_type: "xã",
    codename: "xa_hoa_le",
    district_code: 653,
  },
  {
    name: "Xã Yang Reh",
    code: 24469,
    division_type: "xã",
    codename: "xa_yang_reh",
    district_code: 653,
  },
  {
    name: "Xã Ea Trul",
    code: 24472,
    division_type: "xã",
    codename: "xa_ea_trul",
    district_code: 653,
  },
  {
    name: "Xã Khuê Ngọc Điền",
    code: 24475,
    division_type: "xã",
    codename: "xa_khue_ngoc_dien",
    district_code: 653,
  },
  {
    name: "Xã Cư Pui",
    code: 24478,
    division_type: "xã",
    codename: "xa_cu_pui",
    district_code: 653,
  },
  {
    name: "Xã Hòa Sơn",
    code: 24481,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 653,
  },
  {
    name: "Xã Cư Drăm",
    code: 24484,
    division_type: "xã",
    codename: "xa_cu_dram",
    district_code: 653,
  },
  {
    name: "Xã Yang Mao",
    code: 24487,
    division_type: "xã",
    codename: "xa_yang_mao",
    district_code: 653,
  },
  {
    name: "Thị trấn Phước An",
    code: 24490,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_an",
    district_code: 654,
  },
  {
    name: "Xã KRông Búk",
    code: 24493,
    division_type: "xã",
    codename: "xa_krong_buk",
    district_code: 654,
  },
  {
    name: "Xã Ea Kly",
    code: 24496,
    division_type: "xã",
    codename: "xa_ea_kly",
    district_code: 654,
  },
  {
    name: "Xã Ea Kênh",
    code: 24499,
    division_type: "xã",
    codename: "xa_ea_kenh",
    district_code: 654,
  },
  {
    name: "Xã Ea Phê",
    code: 24502,
    division_type: "xã",
    codename: "xa_ea_phe",
    district_code: 654,
  },
  {
    name: "Xã Ea KNuec",
    code: 24505,
    division_type: "xã",
    codename: "xa_ea_knuec",
    district_code: 654,
  },
  {
    name: "Xã Ea Yông",
    code: 24508,
    division_type: "xã",
    codename: "xa_ea_yong",
    district_code: 654,
  },
  {
    name: "Xã Hòa An",
    code: 24511,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 654,
  },
  {
    name: "Xã Ea Kuăng",
    code: 24514,
    division_type: "xã",
    codename: "xa_ea_kuang",
    district_code: 654,
  },
  {
    name: "Xã Hòa Đông",
    code: 24517,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 654,
  },
  {
    name: "Xã Ea Hiu",
    code: 24520,
    division_type: "xã",
    codename: "xa_ea_hiu",
    district_code: 654,
  },
  {
    name: "Xã Hòa Tiến",
    code: 24523,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 654,
  },
  {
    name: "Xã Tân Tiến",
    code: 24526,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 654,
  },
  {
    name: "Xã Vụ Bổn",
    code: 24529,
    division_type: "xã",
    codename: "xa_vu_bon",
    district_code: 654,
  },
  {
    name: "Xã Ea Uy",
    code: 24532,
    division_type: "xã",
    codename: "xa_ea_uy",
    district_code: 654,
  },
  {
    name: "Xã Ea Yiêng",
    code: 24535,
    division_type: "xã",
    codename: "xa_ea_yieng",
    district_code: 654,
  },
  {
    name: "Thị trấn Buôn Trấp",
    code: 24538,
    division_type: "thị trấn",
    codename: "thi_tran_buon_trap",
    district_code: 655,
  },
  {
    name: "Xã Dray Sáp",
    code: 24556,
    division_type: "xã",
    codename: "xa_dray_sap",
    district_code: 655,
  },
  {
    name: "Xã Ea Na",
    code: 24559,
    division_type: "xã",
    codename: "xa_ea_na",
    district_code: 655,
  },
  {
    name: "Xã Ea Bông",
    code: 24565,
    division_type: "xã",
    codename: "xa_ea_bong",
    district_code: 655,
  },
  {
    name: "Xã Băng A Drênh",
    code: 24568,
    division_type: "xã",
    codename: "xa_bang_a_drenh",
    district_code: 655,
  },
  {
    name: "Xã Dur KMăl",
    code: 24571,
    division_type: "xã",
    codename: "xa_dur_kmal",
    district_code: 655,
  },
  {
    name: "Xã Bình Hòa",
    code: 24574,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 655,
  },
  {
    name: "Xã Quảng Điền",
    code: 24577,
    division_type: "xã",
    codename: "xa_quang_dien",
    district_code: 655,
  },
  {
    name: "Thị trấn Liên Sơn",
    code: 24580,
    division_type: "thị trấn",
    codename: "thi_tran_lien_son",
    district_code: 656,
  },
  {
    name: "Xã Yang Tao",
    code: 24583,
    division_type: "xã",
    codename: "xa_yang_tao",
    district_code: 656,
  },
  {
    name: "Xã Bông Krang",
    code: 24586,
    division_type: "xã",
    codename: "xa_bong_krang",
    district_code: 656,
  },
  {
    name: "Xã Đắk Liêng",
    code: 24589,
    division_type: "xã",
    codename: "xa_dak_lieng",
    district_code: 656,
  },
  {
    name: "Xã Buôn Triết",
    code: 24592,
    division_type: "xã",
    codename: "xa_buon_triet",
    district_code: 656,
  },
  {
    name: "Xã Buôn Tría",
    code: 24595,
    division_type: "xã",
    codename: "xa_buon_tria",
    district_code: 656,
  },
  {
    name: "Xã Đắk Phơi",
    code: 24598,
    division_type: "xã",
    codename: "xa_dak_phoi",
    district_code: 656,
  },
  {
    name: "Xã Đắk Nuê",
    code: 24601,
    division_type: "xã",
    codename: "xa_dak_nue",
    district_code: 656,
  },
  {
    name: "Xã Krông Nô",
    code: 24604,
    division_type: "xã",
    codename: "xa_krong_no",
    district_code: 656,
  },
  {
    name: "Xã Nam Ka",
    code: 24607,
    division_type: "xã",
    codename: "xa_nam_ka",
    district_code: 656,
  },
  {
    name: "Xã Ea R'Bin",
    code: 24610,
    division_type: "xã",
    codename: "xa_ea_rbin",
    district_code: 656,
  },
  {
    name: "Xã Ea Ning",
    code: 24540,
    division_type: "xã",
    codename: "xa_ea_ning",
    district_code: 657,
  },
  {
    name: "Xã Cư Ê Wi",
    code: 24541,
    division_type: "xã",
    codename: "xa_cu_e_wi",
    district_code: 657,
  },
  {
    name: "Xã Ea Ktur",
    code: 24544,
    division_type: "xã",
    codename: "xa_ea_ktur",
    district_code: 657,
  },
  {
    name: "Xã Ea Tiêu",
    code: 24547,
    division_type: "xã",
    codename: "xa_ea_tieu",
    district_code: 657,
  },
  {
    name: "Xã Ea BHốk",
    code: 24550,
    division_type: "xã",
    codename: "xa_ea_bhok",
    district_code: 657,
  },
  {
    name: "Xã Ea Hu",
    code: 24553,
    division_type: "xã",
    codename: "xa_ea_hu",
    district_code: 657,
  },
  {
    name: "Xã Dray Bhăng",
    code: 24561,
    division_type: "xã",
    codename: "xa_dray_bhang",
    district_code: 657,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 24562,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 657,
  },
  {
    name: "Phường Nghĩa Đức",
    code: 24611,
    division_type: "phường",
    codename: "phuong_nghia_duc",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Thành",
    code: 24612,
    division_type: "phường",
    codename: "phuong_nghia_thanh",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Phú",
    code: 24614,
    division_type: "phường",
    codename: "phuong_nghia_phu",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Tân",
    code: 24615,
    division_type: "phường",
    codename: "phuong_nghia_tan",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Trung",
    code: 24617,
    division_type: "phường",
    codename: "phuong_nghia_trung",
    district_code: 660,
  },
  {
    name: "Xã Đăk R'Moan",
    code: 24618,
    division_type: "xã",
    codename: "xa_dak_rmoan",
    district_code: 660,
  },
  {
    name: "Phường Quảng Thành",
    code: 24619,
    division_type: "phường",
    codename: "phuong_quang_thanh",
    district_code: 660,
  },
  {
    name: "Xã Đắk Nia",
    code: 24628,
    division_type: "xã",
    codename: "xa_dak_nia",
    district_code: 660,
  },
  {
    name: "Xã Quảng Sơn",
    code: 24616,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 661,
  },
  {
    name: "Xã Quảng Hoà",
    code: 24620,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 661,
  },
  {
    name: "Xã Đắk Ha",
    code: 24622,
    division_type: "xã",
    codename: "xa_dak_ha",
    district_code: 661,
  },
  {
    name: "Xã Đắk R'Măng",
    code: 24625,
    division_type: "xã",
    codename: "xa_dak_rmang",
    district_code: 661,
  },
  {
    name: "Xã Quảng Khê",
    code: 24631,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 661,
  },
  {
    name: "Xã Đắk Plao",
    code: 24634,
    division_type: "xã",
    codename: "xa_dak_plao",
    district_code: 661,
  },
  {
    name: "Xã Đắk Som",
    code: 24637,
    division_type: "xã",
    codename: "xa_dak_som",
    district_code: 661,
  },
  {
    name: "Thị trấn Ea T'Ling",
    code: 24640,
    division_type: "thị trấn",
    codename: "thi_tran_ea_tling",
    district_code: 662,
  },
  {
    name: "Xã Đắk Wil",
    code: 24643,
    division_type: "xã",
    codename: "xa_dak_wil",
    district_code: 662,
  },
  {
    name: "Xã Ea Pô",
    code: 24646,
    division_type: "xã",
    codename: "xa_ea_po",
    district_code: 662,
  },
  {
    name: "Xã Nam Dong",
    code: 24649,
    division_type: "xã",
    codename: "xa_nam_dong",
    district_code: 662,
  },
  {
    name: "Xã Đắk DRông",
    code: 24652,
    division_type: "xã",
    codename: "xa_dak_drong",
    district_code: 662,
  },
  {
    name: "Xã Tâm Thắng",
    code: 24655,
    division_type: "xã",
    codename: "xa_tam_thang",
    district_code: 662,
  },
  {
    name: "Xã Cư Knia",
    code: 24658,
    division_type: "xã",
    codename: "xa_cu_knia",
    district_code: 662,
  },
  {
    name: "Xã Trúc Sơn",
    code: 24661,
    division_type: "xã",
    codename: "xa_truc_son",
    district_code: 662,
  },
  {
    name: "Thị trấn Đắk Mil",
    code: 24664,
    division_type: "thị trấn",
    codename: "thi_tran_dak_mil",
    district_code: 663,
  },
  {
    name: "Xã Đắk Lao",
    code: 24667,
    division_type: "xã",
    codename: "xa_dak_lao",
    district_code: 663,
  },
  {
    name: "Xã Đắk R'La",
    code: 24670,
    division_type: "xã",
    codename: "xa_dak_rla",
    district_code: 663,
  },
  {
    name: "Xã Đắk Gằn",
    code: 24673,
    division_type: "xã",
    codename: "xa_dak_gan",
    district_code: 663,
  },
  {
    name: "Xã Đức Mạnh",
    code: 24676,
    division_type: "xã",
    codename: "xa_duc_manh",
    district_code: 663,
  },
  {
    name: "Xã Đắk N'Drót",
    code: 24677,
    division_type: "xã",
    codename: "xa_dak_ndrot",
    district_code: 663,
  },
  {
    name: "Xã Long Sơn",
    code: 24678,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 663,
  },
  {
    name: "Xã Đắk Sắk",
    code: 24679,
    division_type: "xã",
    codename: "xa_dak_sak",
    district_code: 663,
  },
  {
    name: "Xã Thuận An",
    code: 24682,
    division_type: "xã",
    codename: "xa_thuan_an",
    district_code: 663,
  },
  {
    name: "Xã Đức Minh",
    code: 24685,
    division_type: "xã",
    codename: "xa_duc_minh",
    district_code: 663,
  },
  {
    name: "Thị trấn Đắk Mâm",
    code: 24688,
    division_type: "thị trấn",
    codename: "thi_tran_dak_mam",
    district_code: 664,
  },
  {
    name: "Xã Đắk Sôr",
    code: 24691,
    division_type: "xã",
    codename: "xa_dak_sor",
    district_code: 664,
  },
  {
    name: "Xã Nam Xuân",
    code: 24692,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 664,
  },
  {
    name: "Xã Buôn Choah",
    code: 24694,
    division_type: "xã",
    codename: "xa_buon_choah",
    district_code: 664,
  },
  {
    name: "Xã Nam Đà",
    code: 24697,
    division_type: "xã",
    codename: "xa_nam_da",
    district_code: 664,
  },
  {
    name: "Xã Tân Thành",
    code: 24699,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 664,
  },
  {
    name: "Xã Đắk Drô",
    code: 24700,
    division_type: "xã",
    codename: "xa_dak_dro",
    district_code: 664,
  },
  {
    name: "Xã Nâm Nung",
    code: 24703,
    division_type: "xã",
    codename: "xa_nam_nung",
    district_code: 664,
  },
  {
    name: "Xã Đức Xuyên",
    code: 24706,
    division_type: "xã",
    codename: "xa_duc_xuyen",
    district_code: 664,
  },
  {
    name: "Xã Đắk Nang",
    code: 24709,
    division_type: "xã",
    codename: "xa_dak_nang",
    district_code: 664,
  },
  {
    name: "Xã Quảng Phú",
    code: 24712,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 664,
  },
  {
    name: "Xã Nâm N'Đir",
    code: 24715,
    division_type: "xã",
    codename: "xa_nam_ndir",
    district_code: 664,
  },
  {
    name: "Thị trấn Đức An",
    code: 24717,
    division_type: "thị trấn",
    codename: "thi_tran_duc_an",
    district_code: 665,
  },
  {
    name: "Xã Đắk Môl",
    code: 24718,
    division_type: "xã",
    codename: "xa_dak_mol",
    district_code: 665,
  },
  {
    name: "Xã Đắk Hòa",
    code: 24719,
    division_type: "xã",
    codename: "xa_dak_hoa",
    district_code: 665,
  },
  {
    name: "Xã Nam Bình",
    code: 24721,
    division_type: "xã",
    codename: "xa_nam_binh",
    district_code: 665,
  },
  {
    name: "Xã Thuận Hà",
    code: 24722,
    division_type: "xã",
    codename: "xa_thuan_ha",
    district_code: 665,
  },
  {
    name: "Xã Thuận Hạnh",
    code: 24724,
    division_type: "xã",
    codename: "xa_thuan_hanh",
    district_code: 665,
  },
  {
    name: "Xã Đắk N'Dung",
    code: 24727,
    division_type: "xã",
    codename: "xa_dak_ndung",
    district_code: 665,
  },
  {
    name: "Xã Nâm N'Jang",
    code: 24728,
    division_type: "xã",
    codename: "xa_nam_njang",
    district_code: 665,
  },
  {
    name: "Xã Trường Xuân",
    code: 24730,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 665,
  },
  {
    name: "Thị trấn Kiến Đức",
    code: 24733,
    division_type: "thị trấn",
    codename: "thi_tran_kien_duc",
    district_code: 666,
  },
  {
    name: "Xã Quảng Tín",
    code: 24745,
    division_type: "xã",
    codename: "xa_quang_tin",
    district_code: 666,
  },
  {
    name: "Xã Đắk Wer",
    code: 24750,
    division_type: "xã",
    codename: "xa_dak_wer",
    district_code: 666,
  },
  {
    name: "Xã Nhân Cơ",
    code: 24751,
    division_type: "xã",
    codename: "xa_nhan_co",
    district_code: 666,
  },
  {
    name: "Xã Kiến Thành",
    code: 24754,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 666,
  },
  {
    name: "Xã Nghĩa Thắng",
    code: 24756,
    division_type: "xã",
    codename: "xa_nghia_thang",
    district_code: 666,
  },
  {
    name: "Xã Đạo Nghĩa",
    code: 24757,
    division_type: "xã",
    codename: "xa_dao_nghia",
    district_code: 666,
  },
  {
    name: "Xã Đắk Sin",
    code: 24760,
    division_type: "xã",
    codename: "xa_dak_sin",
    district_code: 666,
  },
  {
    name: "Xã Hưng Bình",
    code: 24761,
    division_type: "xã",
    codename: "xa_hung_binh",
    district_code: 666,
  },
  {
    name: "Xã Đắk Ru",
    code: 24763,
    division_type: "xã",
    codename: "xa_dak_ru",
    district_code: 666,
  },
  {
    name: "Xã Nhân Đạo",
    code: 24766,
    division_type: "xã",
    codename: "xa_nhan_dao",
    district_code: 666,
  },
  {
    name: "Xã Quảng Trực",
    code: 24736,
    division_type: "xã",
    codename: "xa_quang_truc",
    district_code: 667,
  },
  {
    name: "Xã Đắk Búk So",
    code: 24739,
    division_type: "xã",
    codename: "xa_dak_buk_so",
    district_code: 667,
  },
  {
    name: "Xã Quảng Tâm",
    code: 24740,
    division_type: "xã",
    codename: "xa_quang_tam",
    district_code: 667,
  },
  {
    name: "Xã Đắk R'Tíh",
    code: 24742,
    division_type: "xã",
    codename: "xa_dak_rtih",
    district_code: 667,
  },
  {
    name: "Xã Đắk Ngo",
    code: 24746,
    division_type: "xã",
    codename: "xa_dak_ngo",
    district_code: 667,
  },
  {
    name: "Xã Quảng Tân",
    code: 24748,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 667,
  },
  {
    name: "Phường 7",
    code: 24769,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 672,
  },
  {
    name: "Phường 8",
    code: 24772,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 672,
  },
  {
    name: "Phường 12",
    code: 24775,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 672,
  },
  {
    name: "Phường 9",
    code: 24778,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 672,
  },
  {
    name: "Phường 2",
    code: 24781,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 672,
  },
  {
    name: "Phường 1",
    code: 24784,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 672,
  },
  {
    name: "Phường 6",
    code: 24787,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 672,
  },
  {
    name: "Phường 5",
    code: 24790,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 672,
  },
  {
    name: "Phường 4",
    code: 24793,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 672,
  },
  {
    name: "Phường 10",
    code: 24796,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 672,
  },
  {
    name: "Phường 11",
    code: 24799,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 672,
  },
  {
    name: "Phường 3",
    code: 24802,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 672,
  },
  {
    name: "Xã Xuân Thọ",
    code: 24805,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 672,
  },
  {
    name: "Xã Tà Nung",
    code: 24808,
    division_type: "xã",
    codename: "xa_ta_nung",
    district_code: 672,
  },
  {
    name: "Xã Trạm Hành",
    code: 24810,
    division_type: "xã",
    codename: "xa_tram_hanh",
    district_code: 672,
  },
  {
    name: "Xã Xuân Trường",
    code: 24811,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 672,
  },
  {
    name: "Phường Lộc Phát",
    code: 24814,
    division_type: "phường",
    codename: "phuong_loc_phat",
    district_code: 673,
  },
  {
    name: "Phường Lộc Tiến",
    code: 24817,
    division_type: "phường",
    codename: "phuong_loc_tien",
    district_code: 673,
  },
  {
    name: "Phường 2",
    code: 24820,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 673,
  },
  {
    name: "Phường 1",
    code: 24823,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 673,
  },
  {
    name: "Phường B'lao",
    code: 24826,
    division_type: "phường",
    codename: "phuong_blao",
    district_code: 673,
  },
  {
    name: "Phường Lộc Sơn",
    code: 24829,
    division_type: "phường",
    codename: "phuong_loc_son",
    district_code: 673,
  },
  {
    name: "Xã Đạm Bri",
    code: 24832,
    division_type: "xã",
    codename: "xa_dam_bri",
    district_code: 673,
  },
  {
    name: "Xã Lộc Thanh",
    code: 24835,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 673,
  },
  {
    name: "Xã Lộc Nga",
    code: 24838,
    division_type: "xã",
    codename: "xa_loc_nga",
    district_code: 673,
  },
  {
    name: "Xã Lộc Châu",
    code: 24841,
    division_type: "xã",
    codename: "xa_loc_chau",
    district_code: 673,
  },
  {
    name: "Xã Đại Lào",
    code: 24844,
    division_type: "xã",
    codename: "xa_dai_lao",
    district_code: 673,
  },
  {
    name: "Xã Đạ Tông",
    code: 24853,
    division_type: "xã",
    codename: "xa_da_tong",
    district_code: 674,
  },
  {
    name: "Xã Đạ Long",
    code: 24856,
    division_type: "xã",
    codename: "xa_da_long",
    district_code: 674,
  },
  {
    name: "Xã Đạ M' Rong",
    code: 24859,
    division_type: "xã",
    codename: "xa_da_m_rong",
    district_code: 674,
  },
  {
    name: "Xã Liêng Srônh",
    code: 24874,
    division_type: "xã",
    codename: "xa_lieng_sronh",
    district_code: 674,
  },
  {
    name: "Xã Đạ Rsal",
    code: 24875,
    division_type: "xã",
    codename: "xa_da_rsal",
    district_code: 674,
  },
  {
    name: "Xã Rô Men",
    code: 24877,
    division_type: "xã",
    codename: "xa_ro_men",
    district_code: 674,
  },
  {
    name: "Xã Phi Liêng",
    code: 24886,
    division_type: "xã",
    codename: "xa_phi_lieng",
    district_code: 674,
  },
  {
    name: "Xã Đạ K' Nàng",
    code: 24889,
    division_type: "xã",
    codename: "xa_da_k_nang",
    district_code: 674,
  },
  {
    name: "Thị trấn Lạc Dương",
    code: 24846,
    division_type: "thị trấn",
    codename: "thi_tran_lac_duong",
    district_code: 675,
  },
  {
    name: "Xã Đạ Chais",
    code: 24847,
    division_type: "xã",
    codename: "xa_da_chais",
    district_code: 675,
  },
  {
    name: "Xã Đạ Nhim",
    code: 24848,
    division_type: "xã",
    codename: "xa_da_nhim",
    district_code: 675,
  },
  {
    name: "Xã Đưng KNớ",
    code: 24850,
    division_type: "xã",
    codename: "xa_dung_kno",
    district_code: 675,
  },
  {
    name: "Xã Lát",
    code: 24862,
    division_type: "xã",
    codename: "xa_lat",
    district_code: 675,
  },
  {
    name: "Xã Đạ Sar",
    code: 24865,
    division_type: "xã",
    codename: "xa_da_sar",
    district_code: 675,
  },
  {
    name: "Thị trấn Nam Ban",
    code: 24868,
    division_type: "thị trấn",
    codename: "thi_tran_nam_ban",
    district_code: 676,
  },
  {
    name: "Thị trấn Đinh Văn",
    code: 24871,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_van",
    district_code: 676,
  },
  {
    name: "Xã Phú Sơn",
    code: 24880,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 676,
  },
  {
    name: "Xã Phi Tô",
    code: 24883,
    division_type: "xã",
    codename: "xa_phi_to",
    district_code: 676,
  },
  {
    name: "Xã Mê Linh",
    code: 24892,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 676,
  },
  {
    name: "Xã Đạ Đờn",
    code: 24895,
    division_type: "xã",
    codename: "xa_da_don",
    district_code: 676,
  },
  {
    name: "Xã Phúc Thọ",
    code: 24898,
    division_type: "xã",
    codename: "xa_phuc_tho",
    district_code: 676,
  },
  {
    name: "Xã Đông Thanh",
    code: 24901,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 676,
  },
  {
    name: "Xã Gia Lâm",
    code: 24904,
    division_type: "xã",
    codename: "xa_gia_lam",
    district_code: 676,
  },
  {
    name: "Xã Tân Thanh",
    code: 24907,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 676,
  },
  {
    name: "Xã Tân Văn",
    code: 24910,
    division_type: "xã",
    codename: "xa_tan_van",
    district_code: 676,
  },
  {
    name: "Xã Hoài Đức",
    code: 24913,
    division_type: "xã",
    codename: "xa_hoai_duc",
    district_code: 676,
  },
  {
    name: "Xã Tân Hà",
    code: 24916,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 676,
  },
  {
    name: "Xã Liên Hà",
    code: 24919,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 676,
  },
  {
    name: "Xã Đan Phượng",
    code: 24922,
    division_type: "xã",
    codename: "xa_dan_phuong",
    district_code: 676,
  },
  {
    name: "Xã Nam Hà",
    code: 24925,
    division_type: "xã",
    codename: "xa_nam_ha",
    district_code: 676,
  },
  {
    name: "Thị trấn D'Ran",
    code: 24928,
    division_type: "thị trấn",
    codename: "thi_tran_dran",
    district_code: 677,
  },
  {
    name: "Thị trấn Thạnh Mỹ",
    code: 24931,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_my",
    district_code: 677,
  },
  {
    name: "Xã Lạc Xuân",
    code: 24934,
    division_type: "xã",
    codename: "xa_lac_xuan",
    district_code: 677,
  },
  {
    name: "Xã Đạ Ròn",
    code: 24937,
    division_type: "xã",
    codename: "xa_da_ron",
    district_code: 677,
  },
  {
    name: "Xã Lạc Lâm",
    code: 24940,
    division_type: "xã",
    codename: "xa_lac_lam",
    district_code: 677,
  },
  {
    name: "Xã Ka Đô",
    code: 24943,
    division_type: "xã",
    codename: "xa_ka_do",
    district_code: 677,
  },
  {
    name: "Xã Quảng Lập",
    code: 24946,
    division_type: "xã",
    codename: "xa_quang_lap",
    district_code: 677,
  },
  {
    name: "Xã Ka Đơn",
    code: 24949,
    division_type: "xã",
    codename: "xa_ka_don",
    district_code: 677,
  },
  {
    name: "Xã Tu Tra",
    code: 24952,
    division_type: "xã",
    codename: "xa_tu_tra",
    district_code: 677,
  },
  {
    name: "Xã Pró",
    code: 24955,
    division_type: "xã",
    codename: "xa_pro",
    district_code: 677,
  },
  {
    name: "Thị trấn Liên Nghĩa",
    code: 24958,
    division_type: "thị trấn",
    codename: "thi_tran_lien_nghia",
    district_code: 678,
  },
  {
    name: "Xã Hiệp An",
    code: 24961,
    division_type: "xã",
    codename: "xa_hiep_an",
    district_code: 678,
  },
  {
    name: "Xã Liên Hiệp",
    code: 24964,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 678,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 24967,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 678,
  },
  {
    name: "Xã Bình Thạnh",
    code: 24970,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 678,
  },
  {
    name: "Xã N'Thol Hạ",
    code: 24973,
    division_type: "xã",
    codename: "xa_nthol_ha",
    district_code: 678,
  },
  {
    name: "Xã Tân Hội",
    code: 24976,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 678,
  },
  {
    name: "Xã Tân Thành",
    code: 24979,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 678,
  },
  {
    name: "Xã Phú Hội",
    code: 24982,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 678,
  },
  {
    name: "Xã Ninh Gia",
    code: 24985,
    division_type: "xã",
    codename: "xa_ninh_gia",
    district_code: 678,
  },
  {
    name: "Xã Tà Năng",
    code: 24988,
    division_type: "xã",
    codename: "xa_ta_nang",
    district_code: 678,
  },
  {
    name: "Xã Đa Quyn",
    code: 24989,
    division_type: "xã",
    codename: "xa_da_quyn",
    district_code: 678,
  },
  {
    name: "Xã Tà Hine",
    code: 24991,
    division_type: "xã",
    codename: "xa_ta_hine",
    district_code: 678,
  },
  {
    name: "Xã Đà Loan",
    code: 24994,
    division_type: "xã",
    codename: "xa_da_loan",
    district_code: 678,
  },
  {
    name: "Xã Ninh Loan",
    code: 24997,
    division_type: "xã",
    codename: "xa_ninh_loan",
    district_code: 678,
  },
  {
    name: "Thị trấn Di Linh",
    code: 25000,
    division_type: "thị trấn",
    codename: "thi_tran_di_linh",
    district_code: 679,
  },
  {
    name: "Xã Đinh Trang Thượng",
    code: 25003,
    division_type: "xã",
    codename: "xa_dinh_trang_thuong",
    district_code: 679,
  },
  {
    name: "Xã Tân Thượng",
    code: 25006,
    division_type: "xã",
    codename: "xa_tan_thuong",
    district_code: 679,
  },
  {
    name: "Xã Tân Lâm",
    code: 25007,
    division_type: "xã",
    codename: "xa_tan_lam",
    district_code: 679,
  },
  {
    name: "Xã Tân Châu",
    code: 25009,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 679,
  },
  {
    name: "Xã Tân Nghĩa",
    code: 25012,
    division_type: "xã",
    codename: "xa_tan_nghia",
    district_code: 679,
  },
  {
    name: "Xã Gia Hiệp",
    code: 25015,
    division_type: "xã",
    codename: "xa_gia_hiep",
    district_code: 679,
  },
  {
    name: "Xã Đinh Lạc",
    code: 25018,
    division_type: "xã",
    codename: "xa_dinh_lac",
    district_code: 679,
  },
  {
    name: "Xã Tam Bố",
    code: 25021,
    division_type: "xã",
    codename: "xa_tam_bo",
    district_code: 679,
  },
  {
    name: "Xã Đinh Trang Hòa",
    code: 25024,
    division_type: "xã",
    codename: "xa_dinh_trang_hoa",
    district_code: 679,
  },
  {
    name: "Xã Liên Đầm",
    code: 25027,
    division_type: "xã",
    codename: "xa_lien_dam",
    district_code: 679,
  },
  {
    name: "Xã Gung Ré",
    code: 25030,
    division_type: "xã",
    codename: "xa_gung_re",
    district_code: 679,
  },
  {
    name: "Xã Bảo Thuận",
    code: 25033,
    division_type: "xã",
    codename: "xa_bao_thuan",
    district_code: 679,
  },
  {
    name: "Xã Hòa Ninh",
    code: 25036,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 679,
  },
  {
    name: "Xã Hòa Trung",
    code: 25039,
    division_type: "xã",
    codename: "xa_hoa_trung",
    district_code: 679,
  },
  {
    name: "Xã Hòa Nam",
    code: 25042,
    division_type: "xã",
    codename: "xa_hoa_nam",
    district_code: 679,
  },
  {
    name: "Xã Hòa Bắc",
    code: 25045,
    division_type: "xã",
    codename: "xa_hoa_bac",
    district_code: 679,
  },
  {
    name: "Xã Sơn Điền",
    code: 25048,
    division_type: "xã",
    codename: "xa_son_dien",
    district_code: 679,
  },
  {
    name: "Xã Gia Bắc",
    code: 25051,
    division_type: "xã",
    codename: "xa_gia_bac",
    district_code: 679,
  },
  {
    name: "Thị trấn Lộc Thắng",
    code: 25054,
    division_type: "thị trấn",
    codename: "thi_tran_loc_thang",
    district_code: 680,
  },
  {
    name: "Xã Lộc Bảo",
    code: 25057,
    division_type: "xã",
    codename: "xa_loc_bao",
    district_code: 680,
  },
  {
    name: "Xã Lộc Lâm",
    code: 25060,
    division_type: "xã",
    codename: "xa_loc_lam",
    district_code: 680,
  },
  {
    name: "Xã Lộc Phú",
    code: 25063,
    division_type: "xã",
    codename: "xa_loc_phu",
    district_code: 680,
  },
  {
    name: "Xã Lộc Bắc",
    code: 25066,
    division_type: "xã",
    codename: "xa_loc_bac",
    district_code: 680,
  },
  {
    name: "Xã B' Lá",
    code: 25069,
    division_type: "xã",
    codename: "xa_b_la",
    district_code: 680,
  },
  {
    name: "Xã Lộc Ngãi",
    code: 25072,
    division_type: "xã",
    codename: "xa_loc_ngai",
    district_code: 680,
  },
  {
    name: "Xã Lộc Quảng",
    code: 25075,
    division_type: "xã",
    codename: "xa_loc_quang",
    district_code: 680,
  },
  {
    name: "Xã Lộc Tân",
    code: 25078,
    division_type: "xã",
    codename: "xa_loc_tan",
    district_code: 680,
  },
  {
    name: "Xã Lộc Đức",
    code: 25081,
    division_type: "xã",
    codename: "xa_loc_duc",
    district_code: 680,
  },
  {
    name: "Xã Lộc An",
    code: 25084,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 680,
  },
  {
    name: "Xã Tân Lạc",
    code: 25087,
    division_type: "xã",
    codename: "xa_tan_lac",
    district_code: 680,
  },
  {
    name: "Xã Lộc Thành",
    code: 25090,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 680,
  },
  {
    name: "Xã Lộc Nam",
    code: 25093,
    division_type: "xã",
    codename: "xa_loc_nam",
    district_code: 680,
  },
  {
    name: "Thị trấn Đạ M'ri",
    code: 25096,
    division_type: "thị trấn",
    codename: "thi_tran_da_mri",
    district_code: 681,
  },
  {
    name: "Thị trấn Ma Đa Guôi",
    code: 25099,
    division_type: "thị trấn",
    codename: "thi_tran_ma_da_guoi",
    district_code: 681,
  },
  {
    name: "Xã Hà Lâm",
    code: 25105,
    division_type: "xã",
    codename: "xa_ha_lam",
    district_code: 681,
  },
  {
    name: "Xã Đạ Tồn",
    code: 25108,
    division_type: "xã",
    codename: "xa_da_ton",
    district_code: 681,
  },
  {
    name: "Xã Đạ Oai",
    code: 25111,
    division_type: "xã",
    codename: "xa_da_oai",
    district_code: 681,
  },
  {
    name: "Xã Đạ Ploa",
    code: 25114,
    division_type: "xã",
    codename: "xa_da_ploa",
    district_code: 681,
  },
  {
    name: "Xã Ma Đa Guôi",
    code: 25117,
    division_type: "xã",
    codename: "xa_ma_da_guoi",
    district_code: 681,
  },
  {
    name: "Xã Đoàn Kết",
    code: 25120,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 681,
  },
  {
    name: "Xã Phước Lộc",
    code: 25123,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 681,
  },
  {
    name: "Thị trấn Đạ Tẻh",
    code: 25126,
    division_type: "thị trấn",
    codename: "thi_tran_da_teh",
    district_code: 682,
  },
  {
    name: "Xã An Nhơn",
    code: 25129,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 682,
  },
  {
    name: "Xã Quốc Oai",
    code: 25132,
    division_type: "xã",
    codename: "xa_quoc_oai",
    district_code: 682,
  },
  {
    name: "Xã Mỹ Đức",
    code: 25135,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 682,
  },
  {
    name: "Xã Quảng Trị",
    code: 25138,
    division_type: "xã",
    codename: "xa_quang_tri",
    district_code: 682,
  },
  {
    name: "Xã Đạ Lây",
    code: 25141,
    division_type: "xã",
    codename: "xa_da_lay",
    district_code: 682,
  },
  {
    name: "Xã Triệu Hải",
    code: 25147,
    division_type: "xã",
    codename: "xa_trieu_hai",
    district_code: 682,
  },
  {
    name: "Xã Đạ Kho",
    code: 25153,
    division_type: "xã",
    codename: "xa_da_kho",
    district_code: 682,
  },
  {
    name: "Xã Đạ Pal",
    code: 25156,
    division_type: "xã",
    codename: "xa_da_pal",
    district_code: 682,
  },
  {
    name: "Thị trấn Cát Tiên",
    code: 25159,
    division_type: "thị trấn",
    codename: "thi_tran_cat_tien",
    district_code: 683,
  },
  {
    name: "Xã Tiên Hoàng",
    code: 25162,
    division_type: "xã",
    codename: "xa_tien_hoang",
    district_code: 683,
  },
  {
    name: "Xã Phước Cát 2",
    code: 25165,
    division_type: "xã",
    codename: "xa_phuoc_cat_2",
    district_code: 683,
  },
  {
    name: "Xã Gia Viễn",
    code: 25168,
    division_type: "xã",
    codename: "xa_gia_vien",
    district_code: 683,
  },
  {
    name: "Xã Nam Ninh",
    code: 25171,
    division_type: "xã",
    codename: "xa_nam_ninh",
    district_code: 683,
  },
  {
    name: "Thị trấn Phước Cát",
    code: 25180,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_cat",
    district_code: 683,
  },
  {
    name: "Xã Đức Phổ",
    code: 25183,
    division_type: "xã",
    codename: "xa_duc_pho",
    district_code: 683,
  },
  {
    name: "Xã Quảng Ngãi",
    code: 25189,
    division_type: "xã",
    codename: "xa_quang_ngai",
    district_code: 683,
  },
  {
    name: "Xã Đồng Nai Thượng",
    code: 25192,
    division_type: "xã",
    codename: "xa_dong_nai_thuong",
    district_code: 683,
  },
  {
    name: "Phường Thác Mơ",
    code: 25216,
    division_type: "phường",
    codename: "phuong_thac_mo",
    district_code: 688,
  },
  {
    name: "Phường Long Thủy",
    code: 25217,
    division_type: "phường",
    codename: "phuong_long_thuy",
    district_code: 688,
  },
  {
    name: "Phường Phước Bình",
    code: 25219,
    division_type: "phường",
    codename: "phuong_phuoc_binh",
    district_code: 688,
  },
  {
    name: "Phường Long Phước",
    code: 25220,
    division_type: "phường",
    codename: "phuong_long_phuoc",
    district_code: 688,
  },
  {
    name: "Phường Sơn Giang",
    code: 25237,
    division_type: "phường",
    codename: "phuong_son_giang",
    district_code: 688,
  },
  {
    name: "Xã Long Giang",
    code: 25245,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 688,
  },
  {
    name: "Xã Phước Tín",
    code: 25249,
    division_type: "xã",
    codename: "xa_phuoc_tin",
    district_code: 688,
  },
  {
    name: "Phường Tân Phú",
    code: 25195,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 689,
  },
  {
    name: "Phường Tân Đồng",
    code: 25198,
    division_type: "phường",
    codename: "phuong_tan_dong",
    district_code: 689,
  },
  {
    name: "Phường Tân Bình",
    code: 25201,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 689,
  },
  {
    name: "Phường Tân Xuân",
    code: 25204,
    division_type: "phường",
    codename: "phuong_tan_xuan",
    district_code: 689,
  },
  {
    name: "Phường Tân Thiện",
    code: 25205,
    division_type: "phường",
    codename: "phuong_tan_thien",
    district_code: 689,
  },
  {
    name: "Xã Tân Thành",
    code: 25207,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 689,
  },
  {
    name: "Phường Tiến Thành",
    code: 25210,
    division_type: "phường",
    codename: "phuong_tien_thanh",
    district_code: 689,
  },
  {
    name: "Xã Tiến Hưng",
    code: 25213,
    division_type: "xã",
    codename: "xa_tien_hung",
    district_code: 689,
  },
  {
    name: "Phường Hưng Chiến",
    code: 25320,
    division_type: "phường",
    codename: "phuong_hung_chien",
    district_code: 690,
  },
  {
    name: "Phường An Lộc",
    code: 25324,
    division_type: "phường",
    codename: "phuong_an_loc",
    district_code: 690,
  },
  {
    name: "Phường Phú Thịnh",
    code: 25325,
    division_type: "phường",
    codename: "phuong_phu_thinh",
    district_code: 690,
  },
  {
    name: "Phường Phú Đức",
    code: 25326,
    division_type: "phường",
    codename: "phuong_phu_duc",
    district_code: 690,
  },
  {
    name: "Xã Thanh Lương",
    code: 25333,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 690,
  },
  {
    name: "Xã Thanh Phú",
    code: 25336,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 690,
  },
  {
    name: "Xã Bù Gia Mập",
    code: 25222,
    division_type: "xã",
    codename: "xa_bu_gia_map",
    district_code: 691,
  },
  {
    name: "Xã Đak Ơ",
    code: 25225,
    division_type: "xã",
    codename: "xa_dak_o",
    district_code: 691,
  },
  {
    name: "Xã Đức Hạnh",
    code: 25228,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 691,
  },
  {
    name: "Xã Phú Văn",
    code: 25229,
    division_type: "xã",
    codename: "xa_phu_van",
    district_code: 691,
  },
  {
    name: "Xã Đa Kia",
    code: 25231,
    division_type: "xã",
    codename: "xa_da_kia",
    district_code: 691,
  },
  {
    name: "Xã Phước Minh",
    code: 25232,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 691,
  },
  {
    name: "Xã Bình Thắng",
    code: 25234,
    division_type: "xã",
    codename: "xa_binh_thang",
    district_code: 691,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 25267,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 691,
  },
  {
    name: "Thị trấn Lộc Ninh",
    code: 25270,
    division_type: "thị trấn",
    codename: "thi_tran_loc_ninh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hòa",
    code: 25273,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 692,
  },
  {
    name: "Xã Lộc An",
    code: 25276,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 692,
  },
  {
    name: "Xã Lộc Tấn",
    code: 25279,
    division_type: "xã",
    codename: "xa_loc_tan",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thạnh",
    code: 25280,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hiệp",
    code: 25282,
    division_type: "xã",
    codename: "xa_loc_hiep",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thiện",
    code: 25285,
    division_type: "xã",
    codename: "xa_loc_thien",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thuận",
    code: 25288,
    division_type: "xã",
    codename: "xa_loc_thuan",
    district_code: 692,
  },
  {
    name: "Xã Lộc Quang",
    code: 25291,
    division_type: "xã",
    codename: "xa_loc_quang",
    district_code: 692,
  },
  {
    name: "Xã Lộc Phú",
    code: 25292,
    division_type: "xã",
    codename: "xa_loc_phu",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thành",
    code: 25294,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thái",
    code: 25297,
    division_type: "xã",
    codename: "xa_loc_thai",
    district_code: 692,
  },
  {
    name: "Xã Lộc Điền",
    code: 25300,
    division_type: "xã",
    codename: "xa_loc_dien",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hưng",
    code: 25303,
    division_type: "xã",
    codename: "xa_loc_hung",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thịnh",
    code: 25305,
    division_type: "xã",
    codename: "xa_loc_thinh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Khánh",
    code: 25306,
    division_type: "xã",
    codename: "xa_loc_khanh",
    district_code: 692,
  },
  {
    name: "Thị trấn Thanh Bình",
    code: 25308,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_binh",
    district_code: 693,
  },
  {
    name: "Xã Hưng Phước",
    code: 25309,
    division_type: "xã",
    codename: "xa_hung_phuoc",
    district_code: 693,
  },
  {
    name: "Xã Phước Thiện",
    code: 25310,
    division_type: "xã",
    codename: "xa_phuoc_thien",
    district_code: 693,
  },
  {
    name: "Xã Thiện Hưng",
    code: 25312,
    division_type: "xã",
    codename: "xa_thien_hung",
    district_code: 693,
  },
  {
    name: "Xã Thanh Hòa",
    code: 25315,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 693,
  },
  {
    name: "Xã Tân Thành",
    code: 25318,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 693,
  },
  {
    name: "Xã Tân Tiến",
    code: 25321,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 693,
  },
  {
    name: "Xã Thanh An",
    code: 25327,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 694,
  },
  {
    name: "Xã An Khương",
    code: 25330,
    division_type: "xã",
    codename: "xa_an_khuong",
    district_code: 694,
  },
  {
    name: "Xã An Phú",
    code: 25339,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 694,
  },
  {
    name: "Xã Tân Lợi",
    code: 25342,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 694,
  },
  {
    name: "Xã Tân Hưng",
    code: 25345,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 694,
  },
  {
    name: "Xã Minh Đức",
    code: 25348,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 694,
  },
  {
    name: "Xã Minh Tâm",
    code: 25349,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 694,
  },
  {
    name: "Xã Phước An",
    code: 25351,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 694,
  },
  {
    name: "Xã Thanh Bình",
    code: 25354,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 694,
  },
  {
    name: "Thị trấn Tân Khai",
    code: 25357,
    division_type: "thị trấn",
    codename: "thi_tran_tan_khai",
    district_code: 694,
  },
  {
    name: "Xã Đồng Nơ",
    code: 25360,
    division_type: "xã",
    codename: "xa_dong_no",
    district_code: 694,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25361,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 694,
  },
  {
    name: "Xã Tân Quan",
    code: 25438,
    division_type: "xã",
    codename: "xa_tan_quan",
    district_code: 694,
  },
  {
    name: "Thị trấn Tân Phú",
    code: 25363,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phu",
    district_code: 695,
  },
  {
    name: "Xã Thuận Lợi",
    code: 25366,
    division_type: "xã",
    codename: "xa_thuan_loi",
    district_code: 695,
  },
  {
    name: "Xã Đồng Tâm",
    code: 25369,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 695,
  },
  {
    name: "Xã Tân Phước",
    code: 25372,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 695,
  },
  {
    name: "Xã Tân Hưng",
    code: 25375,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 695,
  },
  {
    name: "Xã Tân Lợi",
    code: 25378,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 695,
  },
  {
    name: "Xã Tân Lập",
    code: 25381,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 695,
  },
  {
    name: "Xã Tân Hòa",
    code: 25384,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 695,
  },
  {
    name: "Xã Thuận Phú",
    code: 25387,
    division_type: "xã",
    codename: "xa_thuan_phu",
    district_code: 695,
  },
  {
    name: "Xã Đồng Tiến",
    code: 25390,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 695,
  },
  {
    name: "Xã Tân Tiến",
    code: 25393,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 695,
  },
  {
    name: "Thị trấn Đức Phong",
    code: 25396,
    division_type: "thị trấn",
    codename: "thi_tran_duc_phong",
    district_code: 696,
  },
  {
    name: "Xã Đường 10",
    code: 25398,
    division_type: "xã",
    codename: "xa_duong_10",
    district_code: 696,
  },
  {
    name: "Xã Đak Nhau",
    code: 25399,
    division_type: "xã",
    codename: "xa_dak_nhau",
    district_code: 696,
  },
  {
    name: "Xã Phú Sơn",
    code: 25400,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 696,
  },
  {
    name: "Xã Thọ Sơn",
    code: 25402,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 696,
  },
  {
    name: "Xã Bình Minh",
    code: 25404,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 696,
  },
  {
    name: "Xã Bom Bo",
    code: 25405,
    division_type: "xã",
    codename: "xa_bom_bo",
    district_code: 696,
  },
  {
    name: "Xã Minh Hưng",
    code: 25408,
    division_type: "xã",
    codename: "xa_minh_hung",
    district_code: 696,
  },
  {
    name: "Xã Đoàn Kết",
    code: 25411,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 696,
  },
  {
    name: "Xã Đồng Nai",
    code: 25414,
    division_type: "xã",
    codename: "xa_dong_nai",
    district_code: 696,
  },
  {
    name: "Xã Đức Liễu",
    code: 25417,
    division_type: "xã",
    codename: "xa_duc_lieu",
    district_code: 696,
  },
  {
    name: "Xã Thống Nhất",
    code: 25420,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 696,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 25423,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 696,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 25424,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 696,
  },
  {
    name: "Xã Đăng Hà",
    code: 25426,
    division_type: "xã",
    codename: "xa_dang_ha",
    district_code: 696,
  },
  {
    name: "Xã Phước Sơn",
    code: 25429,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 696,
  },
  {
    name: "Thị trấn Chơn Thành",
    code: 25432,
    division_type: "thị trấn",
    codename: "thi_tran_chon_thanh",
    district_code: 697,
  },
  {
    name: "Xã Thành Tâm",
    code: 25433,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 697,
  },
  {
    name: "Xã Minh Lập",
    code: 25435,
    division_type: "xã",
    codename: "xa_minh_lap",
    district_code: 697,
  },
  {
    name: "Xã Quang Minh",
    code: 25439,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 697,
  },
  {
    name: "Xã Minh Hưng",
    code: 25441,
    division_type: "xã",
    codename: "xa_minh_hung",
    district_code: 697,
  },
  {
    name: "Xã Minh Long",
    code: 25444,
    division_type: "xã",
    codename: "xa_minh_long",
    district_code: 697,
  },
  {
    name: "Xã Minh Thành",
    code: 25447,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 697,
  },
  {
    name: "Xã Nha Bích",
    code: 25450,
    division_type: "xã",
    codename: "xa_nha_bich",
    district_code: 697,
  },
  {
    name: "Xã Minh Thắng",
    code: 25453,
    division_type: "xã",
    codename: "xa_minh_thang",
    district_code: 697,
  },
  {
    name: "Xã Long Bình",
    code: 25240,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 698,
  },
  {
    name: "Xã Bình Tân",
    code: 25243,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 698,
  },
  {
    name: "Xã Bình Sơn",
    code: 25244,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 698,
  },
  {
    name: "Xã Long Hưng",
    code: 25246,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 698,
  },
  {
    name: "Xã Phước Tân",
    code: 25250,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 698,
  },
  {
    name: "Xã Bù Nho",
    code: 25252,
    division_type: "xã",
    codename: "xa_bu_nho",
    district_code: 698,
  },
  {
    name: "Xã Long Hà",
    code: 25255,
    division_type: "xã",
    codename: "xa_long_ha",
    district_code: 698,
  },
  {
    name: "Xã Long Tân",
    code: 25258,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 698,
  },
  {
    name: "Xã Phú Trung",
    code: 25261,
    division_type: "xã",
    codename: "xa_phu_trung",
    district_code: 698,
  },
  {
    name: "Xã Phú Riềng",
    code: 25264,
    division_type: "xã",
    codename: "xa_phu_rieng",
    district_code: 698,
  },
  {
    name: "Phường 1",
    code: 25456,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 703,
  },
  {
    name: "Phường 3",
    code: 25459,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 703,
  },
  {
    name: "Phường 4",
    code: 25462,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 703,
  },
  {
    name: "Phường Hiệp Ninh",
    code: 25465,
    division_type: "phường",
    codename: "phuong_hiep_ninh",
    district_code: 703,
  },
  {
    name: "Phường 2",
    code: 25468,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 703,
  },
  {
    name: "Xã Thạnh Tân",
    code: 25471,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 703,
  },
  {
    name: "Xã Tân Bình",
    code: 25474,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 703,
  },
  {
    name: "Xã Bình Minh",
    code: 25477,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 703,
  },
  {
    name: "Phường Ninh Sơn",
    code: 25480,
    division_type: "phường",
    codename: "phuong_ninh_son",
    district_code: 703,
  },
  {
    name: "Phường Ninh Thạnh",
    code: 25483,
    division_type: "phường",
    codename: "phuong_ninh_thanh",
    district_code: 703,
  },
  {
    name: "Thị trấn Tân Biên",
    code: 25486,
    division_type: "thị trấn",
    codename: "thi_tran_tan_bien",
    district_code: 705,
  },
  {
    name: "Xã Tân Lập",
    code: 25489,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Bắc",
    code: 25492,
    division_type: "xã",
    codename: "xa_thanh_bac",
    district_code: 705,
  },
  {
    name: "Xã Tân Bình",
    code: 25495,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Bình",
    code: 25498,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Tây",
    code: 25501,
    division_type: "xã",
    codename: "xa_thanh_tay",
    district_code: 705,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 25504,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 705,
  },
  {
    name: "Xã Tân Phong",
    code: 25507,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 705,
  },
  {
    name: "Xã Mỏ Công",
    code: 25510,
    division_type: "xã",
    codename: "xa_mo_cong",
    district_code: 705,
  },
  {
    name: "Xã Trà Vong",
    code: 25513,
    division_type: "xã",
    codename: "xa_tra_vong",
    district_code: 705,
  },
  {
    name: "Thị trấn Tân Châu",
    code: 25516,
    division_type: "thị trấn",
    codename: "thi_tran_tan_chau",
    district_code: 706,
  },
  {
    name: "Xã Tân Hà",
    code: 25519,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 706,
  },
  {
    name: "Xã Tân Đông",
    code: 25522,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 706,
  },
  {
    name: "Xã Tân Hội",
    code: 25525,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 706,
  },
  {
    name: "Xã Tân Hòa",
    code: 25528,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 706,
  },
  {
    name: "Xã Suối Ngô",
    code: 25531,
    division_type: "xã",
    codename: "xa_suoi_ngo",
    district_code: 706,
  },
  {
    name: "Xã Suối Dây",
    code: 25534,
    division_type: "xã",
    codename: "xa_suoi_day",
    district_code: 706,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25537,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 706,
  },
  {
    name: "Xã Thạnh Đông",
    code: 25540,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 706,
  },
  {
    name: "Xã Tân Thành",
    code: 25543,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 706,
  },
  {
    name: "Xã Tân Phú",
    code: 25546,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 706,
  },
  {
    name: "Xã Tân Hưng",
    code: 25549,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 706,
  },
  {
    name: "Thị trấn Dương Minh Châu",
    code: 25552,
    division_type: "thị trấn",
    codename: "thi_tran_duong_minh_chau",
    district_code: 707,
  },
  {
    name: "Xã Suối Đá",
    code: 25555,
    division_type: "xã",
    codename: "xa_suoi_da",
    district_code: 707,
  },
  {
    name: "Xã Phan",
    code: 25558,
    division_type: "xã",
    codename: "xa_phan",
    district_code: 707,
  },
  {
    name: "Xã Phước Ninh",
    code: 25561,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 707,
  },
  {
    name: "Xã Phước Minh",
    code: 25564,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 707,
  },
  {
    name: "Xã Bàu Năng",
    code: 25567,
    division_type: "xã",
    codename: "xa_bau_nang",
    district_code: 707,
  },
  {
    name: "Xã Chà Là",
    code: 25570,
    division_type: "xã",
    codename: "xa_cha_la",
    district_code: 707,
  },
  {
    name: "Xã Cầu Khởi",
    code: 25573,
    division_type: "xã",
    codename: "xa_cau_khoi",
    district_code: 707,
  },
  {
    name: "Xã Bến Củi",
    code: 25576,
    division_type: "xã",
    codename: "xa_ben_cui",
    district_code: 707,
  },
  {
    name: "Xã Lộc Ninh",
    code: 25579,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 707,
  },
  {
    name: "Xã Truông Mít",
    code: 25582,
    division_type: "xã",
    codename: "xa_truong_mit",
    district_code: 707,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 25585,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 708,
  },
  {
    name: "Xã Hảo Đước",
    code: 25588,
    division_type: "xã",
    codename: "xa_hao_duoc",
    district_code: 708,
  },
  {
    name: "Xã Phước Vinh",
    code: 25591,
    division_type: "xã",
    codename: "xa_phuoc_vinh",
    district_code: 708,
  },
  {
    name: "Xã Đồng Khởi",
    code: 25594,
    division_type: "xã",
    codename: "xa_dong_khoi",
    district_code: 708,
  },
  {
    name: "Xã Thái Bình",
    code: 25597,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 708,
  },
  {
    name: "Xã An Cơ",
    code: 25600,
    division_type: "xã",
    codename: "xa_an_co",
    district_code: 708,
  },
  {
    name: "Xã Biên Giới",
    code: 25603,
    division_type: "xã",
    codename: "xa_bien_gioi",
    district_code: 708,
  },
  {
    name: "Xã Hòa Thạnh",
    code: 25606,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 708,
  },
  {
    name: "Xã Trí Bình",
    code: 25609,
    division_type: "xã",
    codename: "xa_tri_binh",
    district_code: 708,
  },
  {
    name: "Xã Hòa Hội",
    code: 25612,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 708,
  },
  {
    name: "Xã An Bình",
    code: 25615,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 708,
  },
  {
    name: "Xã Thanh Điền",
    code: 25618,
    division_type: "xã",
    codename: "xa_thanh_dien",
    district_code: 708,
  },
  {
    name: "Xã Thành Long",
    code: 25621,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 708,
  },
  {
    name: "Xã Ninh Điền",
    code: 25624,
    division_type: "xã",
    codename: "xa_ninh_dien",
    district_code: 708,
  },
  {
    name: "Xã Long Vĩnh",
    code: 25627,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 708,
  },
  {
    name: "Phường Long Hoa",
    code: 25630,
    division_type: "phường",
    codename: "phuong_long_hoa",
    district_code: 709,
  },
  {
    name: "Phường Hiệp Tân",
    code: 25633,
    division_type: "phường",
    codename: "phuong_hiep_tan",
    district_code: 709,
  },
  {
    name: "Phường Long Thành Bắc",
    code: 25636,
    division_type: "phường",
    codename: "phuong_long_thanh_bac",
    district_code: 709,
  },
  {
    name: "Xã Trường Hòa",
    code: 25639,
    division_type: "xã",
    codename: "xa_truong_hoa",
    district_code: 709,
  },
  {
    name: "Xã Trường Đông",
    code: 25642,
    division_type: "xã",
    codename: "xa_truong_dong",
    district_code: 709,
  },
  {
    name: "Phường Long Thành Trung",
    code: 25645,
    division_type: "phường",
    codename: "phuong_long_thanh_trung",
    district_code: 709,
  },
  {
    name: "Xã Trường Tây",
    code: 25648,
    division_type: "xã",
    codename: "xa_truong_tay",
    district_code: 709,
  },
  {
    name: "Xã Long Thành Nam",
    code: 25651,
    division_type: "xã",
    codename: "xa_long_thanh_nam",
    district_code: 709,
  },
  {
    name: "Thị trấn Gò Dầu",
    code: 25654,
    division_type: "thị trấn",
    codename: "thi_tran_go_dau",
    district_code: 710,
  },
  {
    name: "Xã Thạnh Đức",
    code: 25657,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 710,
  },
  {
    name: "Xã Cẩm Giang",
    code: 25660,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 710,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 25663,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 710,
  },
  {
    name: "Xã Bàu Đồn",
    code: 25666,
    division_type: "xã",
    codename: "xa_bau_don",
    district_code: 710,
  },
  {
    name: "Xã Phước Thạnh",
    code: 25669,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 710,
  },
  {
    name: "Xã Phước Đông",
    code: 25672,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 710,
  },
  {
    name: "Xã Phước Trạch",
    code: 25675,
    division_type: "xã",
    codename: "xa_phuoc_trach",
    district_code: 710,
  },
  {
    name: "Xã Thanh Phước",
    code: 25678,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 710,
  },
  {
    name: "Thị trấn Bến Cầu",
    code: 25681,
    division_type: "thị trấn",
    codename: "thi_tran_ben_cau",
    district_code: 711,
  },
  {
    name: "Xã Long Chữ",
    code: 25684,
    division_type: "xã",
    codename: "xa_long_chu",
    district_code: 711,
  },
  {
    name: "Xã Long Phước",
    code: 25687,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 711,
  },
  {
    name: "Xã Long Giang",
    code: 25690,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 711,
  },
  {
    name: "Xã Tiên Thuận",
    code: 25693,
    division_type: "xã",
    codename: "xa_tien_thuan",
    district_code: 711,
  },
  {
    name: "Xã Long Khánh",
    code: 25696,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 711,
  },
  {
    name: "Xã Lợi Thuận",
    code: 25699,
    division_type: "xã",
    codename: "xa_loi_thuan",
    district_code: 711,
  },
  {
    name: "Xã Long Thuận",
    code: 25702,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 711,
  },
  {
    name: "Xã An Thạnh",
    code: 25705,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 711,
  },
  {
    name: "Phường Trảng Bàng",
    code: 25708,
    division_type: "phường",
    codename: "phuong_trang_bang",
    district_code: 712,
  },
  {
    name: "Xã Đôn Thuận",
    code: 25711,
    division_type: "xã",
    codename: "xa_don_thuan",
    district_code: 712,
  },
  {
    name: "Xã Hưng Thuận",
    code: 25714,
    division_type: "xã",
    codename: "xa_hung_thuan",
    district_code: 712,
  },
  {
    name: "Phường Lộc Hưng",
    code: 25717,
    division_type: "phường",
    codename: "phuong_loc_hung",
    district_code: 712,
  },
  {
    name: "Phường Gia Lộc",
    code: 25720,
    division_type: "phường",
    codename: "phuong_gia_loc",
    district_code: 712,
  },
  {
    name: "Phường Gia Bình",
    code: 25723,
    division_type: "phường",
    codename: "phuong_gia_binh",
    district_code: 712,
  },
  {
    name: "Xã Phước Bình",
    code: 25729,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 712,
  },
  {
    name: "Phường An Tịnh",
    code: 25732,
    division_type: "phường",
    codename: "phuong_an_tinh",
    district_code: 712,
  },
  {
    name: "Phường An Hòa",
    code: 25735,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 712,
  },
  {
    name: "Xã Phước Chỉ",
    code: 25738,
    division_type: "xã",
    codename: "xa_phuoc_chi",
    district_code: 712,
  },
  {
    name: "Phường Hiệp Thành",
    code: 25741,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 718,
  },
  {
    name: "Phường Phú Lợi",
    code: 25744,
    division_type: "phường",
    codename: "phuong_phu_loi",
    district_code: 718,
  },
  {
    name: "Phường Phú Cường",
    code: 25747,
    division_type: "phường",
    codename: "phuong_phu_cuong",
    district_code: 718,
  },
  {
    name: "Phường Phú Hòa",
    code: 25750,
    division_type: "phường",
    codename: "phuong_phu_hoa",
    district_code: 718,
  },
  {
    name: "Phường Phú Thọ",
    code: 25753,
    division_type: "phường",
    codename: "phuong_phu_tho",
    district_code: 718,
  },
  {
    name: "Phường Chánh Nghĩa",
    code: 25756,
    division_type: "phường",
    codename: "phuong_chanh_nghia",
    district_code: 718,
  },
  {
    name: "Phường Định Hoà",
    code: 25759,
    division_type: "phường",
    codename: "phuong_dinh_hoa",
    district_code: 718,
  },
  {
    name: "Phường Hoà Phú",
    code: 25760,
    division_type: "phường",
    codename: "phuong_hoa_phu",
    district_code: 718,
  },
  {
    name: "Phường Phú Mỹ",
    code: 25762,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 718,
  },
  {
    name: "Phường Phú Tân",
    code: 25763,
    division_type: "phường",
    codename: "phuong_phu_tan",
    district_code: 718,
  },
  {
    name: "Phường Tân An",
    code: 25765,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 718,
  },
  {
    name: "Phường Hiệp An",
    code: 25768,
    division_type: "phường",
    codename: "phuong_hiep_an",
    district_code: 718,
  },
  {
    name: "Phường Tương Bình Hiệp",
    code: 25771,
    division_type: "phường",
    codename: "phuong_tuong_binh_hiep",
    district_code: 718,
  },
  {
    name: "Phường Chánh Mỹ",
    code: 25774,
    division_type: "phường",
    codename: "phuong_chanh_my",
    district_code: 718,
  },
  {
    name: "Xã Trừ Văn Thố",
    code: 25816,
    division_type: "xã",
    codename: "xa_tru_van_tho",
    district_code: 719,
  },
  {
    name: "Xã Cây Trường II",
    code: 25819,
    division_type: "xã",
    codename: "xa_cay_truong_ii",
    district_code: 719,
  },
  {
    name: "Thị trấn Lai Uyên",
    code: 25822,
    division_type: "thị trấn",
    codename: "thi_tran_lai_uyen",
    district_code: 719,
  },
  {
    name: "Xã Tân Hưng",
    code: 25825,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 719,
  },
  {
    name: "Xã Long Nguyên",
    code: 25828,
    division_type: "xã",
    codename: "xa_long_nguyen",
    district_code: 719,
  },
  {
    name: "Xã Hưng Hòa",
    code: 25831,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 719,
  },
  {
    name: "Xã Lai Hưng",
    code: 25834,
    division_type: "xã",
    codename: "xa_lai_hung",
    district_code: 719,
  },
  {
    name: "Thị trấn Dầu Tiếng",
    code: 25777,
    division_type: "thị trấn",
    codename: "thi_tran_dau_tieng",
    district_code: 720,
  },
  {
    name: "Xã Minh Hoà",
    code: 25780,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 720,
  },
  {
    name: "Xã Minh Thạnh",
    code: 25783,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 720,
  },
  {
    name: "Xã Minh Tân",
    code: 25786,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 720,
  },
  {
    name: "Xã Định An",
    code: 25789,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 720,
  },
  {
    name: "Xã Long Hoà",
    code: 25792,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 720,
  },
  {
    name: "Xã Định Thành",
    code: 25795,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 720,
  },
  {
    name: "Xã Định Hiệp",
    code: 25798,
    division_type: "xã",
    codename: "xa_dinh_hiep",
    district_code: 720,
  },
  {
    name: "Xã An Lập",
    code: 25801,
    division_type: "xã",
    codename: "xa_an_lap",
    district_code: 720,
  },
  {
    name: "Xã Long Tân",
    code: 25804,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 720,
  },
  {
    name: "Xã Thanh An",
    code: 25807,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 720,
  },
  {
    name: "Xã Thanh Tuyền",
    code: 25810,
    division_type: "xã",
    codename: "xa_thanh_tuyen",
    district_code: 720,
  },
  {
    name: "Phường Mỹ Phước",
    code: 25813,
    division_type: "phường",
    codename: "phuong_my_phuoc",
    district_code: 721,
  },
  {
    name: "Phường Chánh Phú Hòa",
    code: 25837,
    division_type: "phường",
    codename: "phuong_chanh_phu_hoa",
    district_code: 721,
  },
  {
    name: "Xã An Điền",
    code: 25840,
    division_type: "xã",
    codename: "xa_an_dien",
    district_code: 721,
  },
  {
    name: "Xã An Tây",
    code: 25843,
    division_type: "xã",
    codename: "xa_an_tay",
    district_code: 721,
  },
  {
    name: "Phường Thới Hòa",
    code: 25846,
    division_type: "phường",
    codename: "phuong_thoi_hoa",
    district_code: 721,
  },
  {
    name: "Phường Hòa Lợi",
    code: 25849,
    division_type: "phường",
    codename: "phuong_hoa_loi",
    district_code: 721,
  },
  {
    name: "Phường Tân Định",
    code: 25852,
    division_type: "phường",
    codename: "phuong_tan_dinh",
    district_code: 721,
  },
  {
    name: "Xã Phú An",
    code: 25855,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 721,
  },
  {
    name: "Thị trấn Phước Vĩnh",
    code: 25858,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_vinh",
    district_code: 722,
  },
  {
    name: "Xã An Linh",
    code: 25861,
    division_type: "xã",
    codename: "xa_an_linh",
    district_code: 722,
  },
  {
    name: "Xã Phước Sang",
    code: 25864,
    division_type: "xã",
    codename: "xa_phuoc_sang",
    district_code: 722,
  },
  {
    name: "Xã An Thái",
    code: 25865,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 722,
  },
  {
    name: "Xã An Long",
    code: 25867,
    division_type: "xã",
    codename: "xa_an_long",
    district_code: 722,
  },
  {
    name: "Xã An Bình",
    code: 25870,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 722,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25873,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 722,
  },
  {
    name: "Xã Tam Lập",
    code: 25876,
    division_type: "xã",
    codename: "xa_tam_lap",
    district_code: 722,
  },
  {
    name: "Xã Tân Long",
    code: 25879,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 722,
  },
  {
    name: "Xã Vĩnh Hoà",
    code: 25882,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 722,
  },
  {
    name: "Xã Phước Hoà",
    code: 25885,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 722,
  },
  {
    name: "Phường Uyên Hưng",
    code: 25888,
    division_type: "phường",
    codename: "phuong_uyen_hung",
    district_code: 723,
  },
  {
    name: "Phường Tân Phước Khánh",
    code: 25891,
    division_type: "phường",
    codename: "phuong_tan_phuoc_khanh",
    district_code: 723,
  },
  {
    name: "Phường Vĩnh Tân",
    code: 25912,
    division_type: "phường",
    codename: "phuong_vinh_tan",
    district_code: 723,
  },
  {
    name: "Phường Hội Nghĩa",
    code: 25915,
    division_type: "phường",
    codename: "phuong_hoi_nghia",
    district_code: 723,
  },
  {
    name: "Phường Tân Hiệp",
    code: 25920,
    division_type: "phường",
    codename: "phuong_tan_hiep",
    district_code: 723,
  },
  {
    name: "Phường Khánh Bình",
    code: 25921,
    division_type: "phường",
    codename: "phuong_khanh_binh",
    district_code: 723,
  },
  {
    name: "Phường Phú Chánh",
    code: 25924,
    division_type: "phường",
    codename: "phuong_phu_chanh",
    district_code: 723,
  },
  {
    name: "Xã Bạch Đằng",
    code: 25930,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 723,
  },
  {
    name: "Phường Tân Vĩnh Hiệp",
    code: 25933,
    division_type: "phường",
    codename: "phuong_tan_vinh_hiep",
    district_code: 723,
  },
  {
    name: "Phường Thạnh Phước",
    code: 25936,
    division_type: "phường",
    codename: "phuong_thanh_phuoc",
    district_code: 723,
  },
  {
    name: "Xã Thạnh Hội",
    code: 25937,
    division_type: "xã",
    codename: "xa_thanh_hoi",
    district_code: 723,
  },
  {
    name: "Phường Thái Hòa",
    code: 25939,
    division_type: "phường",
    codename: "phuong_thai_hoa",
    district_code: 723,
  },
  {
    name: "Phường Dĩ An",
    code: 25942,
    division_type: "phường",
    codename: "phuong_di_an",
    district_code: 724,
  },
  {
    name: "Phường Tân Bình",
    code: 25945,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 724,
  },
  {
    name: "Phường Tân Đông Hiệp",
    code: 25948,
    division_type: "phường",
    codename: "phuong_tan_dong_hiep",
    district_code: 724,
  },
  {
    name: "Phường Bình An",
    code: 25951,
    division_type: "phường",
    codename: "phuong_binh_an",
    district_code: 724,
  },
  {
    name: "Phường Bình Thắng",
    code: 25954,
    division_type: "phường",
    codename: "phuong_binh_thang",
    district_code: 724,
  },
  {
    name: "Phường Đông Hòa",
    code: 25957,
    division_type: "phường",
    codename: "phuong_dong_hoa",
    district_code: 724,
  },
  {
    name: "Phường An Bình",
    code: 25960,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 724,
  },
  {
    name: "Phường An Thạnh",
    code: 25963,
    division_type: "phường",
    codename: "phuong_an_thanh",
    district_code: 725,
  },
  {
    name: "Phường Lái Thiêu",
    code: 25966,
    division_type: "phường",
    codename: "phuong_lai_thieu",
    district_code: 725,
  },
  {
    name: "Phường Bình Chuẩn",
    code: 25969,
    division_type: "phường",
    codename: "phuong_binh_chuan",
    district_code: 725,
  },
  {
    name: "Phường Thuận Giao",
    code: 25972,
    division_type: "phường",
    codename: "phuong_thuan_giao",
    district_code: 725,
  },
  {
    name: "Phường An Phú",
    code: 25975,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 725,
  },
  {
    name: "Phường Hưng Định",
    code: 25978,
    division_type: "phường",
    codename: "phuong_hung_dinh",
    district_code: 725,
  },
  {
    name: "Xã An Sơn",
    code: 25981,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 725,
  },
  {
    name: "Phường Bình Nhâm",
    code: 25984,
    division_type: "phường",
    codename: "phuong_binh_nham",
    district_code: 725,
  },
  {
    name: "Phường Bình Hòa",
    code: 25987,
    division_type: "phường",
    codename: "phuong_binh_hoa",
    district_code: 725,
  },
  {
    name: "Phường Vĩnh Phú",
    code: 25990,
    division_type: "phường",
    codename: "phuong_vinh_phu",
    district_code: 725,
  },
  {
    name: "Xã Tân Định",
    code: 25894,
    division_type: "xã",
    codename: "xa_tan_dinh",
    district_code: 726,
  },
  {
    name: "Xã Bình Mỹ",
    code: 25897,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 726,
  },
  {
    name: "Thị trấn Tân Bình",
    code: 25900,
    division_type: "thị trấn",
    codename: "thi_tran_tan_binh",
    district_code: 726,
  },
  {
    name: "Xã Tân Lập",
    code: 25903,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 726,
  },
  {
    name: "Thị trấn Tân Thành",
    code: 25906,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 726,
  },
  {
    name: "Xã Đất Cuốc",
    code: 25907,
    division_type: "xã",
    codename: "xa_dat_cuoc",
    district_code: 726,
  },
  {
    name: "Xã Hiếu Liêm",
    code: 25908,
    division_type: "xã",
    codename: "xa_hieu_liem",
    district_code: 726,
  },
  {
    name: "Xã Lạc An",
    code: 25909,
    division_type: "xã",
    codename: "xa_lac_an",
    district_code: 726,
  },
  {
    name: "Xã Tân Mỹ",
    code: 25918,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 726,
  },
  {
    name: "Xã Thường Tân",
    code: 25927,
    division_type: "xã",
    codename: "xa_thuong_tan",
    district_code: 726,
  },
  {
    name: "Phường Trảng Dài",
    code: 25993,
    division_type: "phường",
    codename: "phuong_trang_dai",
    district_code: 731,
  },
  {
    name: "Phường Tân Phong",
    code: 25996,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 731,
  },
  {
    name: "Phường Tân Biên",
    code: 25999,
    division_type: "phường",
    codename: "phuong_tan_bien",
    district_code: 731,
  },
  {
    name: "Phường Hố Nai",
    code: 26002,
    division_type: "phường",
    codename: "phuong_ho_nai",
    district_code: 731,
  },
  {
    name: "Phường Tân Hòa",
    code: 26005,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 731,
  },
  {
    name: "Phường Tân Hiệp",
    code: 26008,
    division_type: "phường",
    codename: "phuong_tan_hiep",
    district_code: 731,
  },
  {
    name: "Phường Bửu Long",
    code: 26011,
    division_type: "phường",
    codename: "phuong_buu_long",
    district_code: 731,
  },
  {
    name: "Phường Tân Tiến",
    code: 26014,
    division_type: "phường",
    codename: "phuong_tan_tien",
    district_code: 731,
  },
  {
    name: "Phường Tam Hiệp",
    code: 26017,
    division_type: "phường",
    codename: "phuong_tam_hiep",
    district_code: 731,
  },
  {
    name: "Phường Long Bình",
    code: 26020,
    division_type: "phường",
    codename: "phuong_long_binh",
    district_code: 731,
  },
  {
    name: "Phường Quang Vinh",
    code: 26023,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 731,
  },
  {
    name: "Phường Tân Mai",
    code: 26026,
    division_type: "phường",
    codename: "phuong_tan_mai",
    district_code: 731,
  },
  {
    name: "Phường Thống Nhất",
    code: 26029,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 731,
  },
  {
    name: "Phường Trung Dũng",
    code: 26032,
    division_type: "phường",
    codename: "phuong_trung_dung",
    district_code: 731,
  },
  {
    name: "Phường Tam Hòa",
    code: 26035,
    division_type: "phường",
    codename: "phuong_tam_hoa",
    district_code: 731,
  },
  {
    name: "Phường Hòa Bình",
    code: 26038,
    division_type: "phường",
    codename: "phuong_hoa_binh",
    district_code: 731,
  },
  {
    name: "Phường Quyết Thắng",
    code: 26041,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 731,
  },
  {
    name: "Phường Thanh Bình",
    code: 26044,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 731,
  },
  {
    name: "Phường Bình Đa",
    code: 26047,
    division_type: "phường",
    codename: "phuong_binh_da",
    district_code: 731,
  },
  {
    name: "Phường An Bình",
    code: 26050,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 731,
  },
  {
    name: "Phường Bửu Hòa",
    code: 26053,
    division_type: "phường",
    codename: "phuong_buu_hoa",
    district_code: 731,
  },
  {
    name: "Phường Long Bình Tân",
    code: 26056,
    division_type: "phường",
    codename: "phuong_long_binh_tan",
    district_code: 731,
  },
  {
    name: "Phường Tân Vạn",
    code: 26059,
    division_type: "phường",
    codename: "phuong_tan_van",
    district_code: 731,
  },
  {
    name: "Phường Tân Hạnh",
    code: 26062,
    division_type: "phường",
    codename: "phuong_tan_hanh",
    district_code: 731,
  },
  {
    name: "Phường Hiệp Hòa",
    code: 26065,
    division_type: "phường",
    codename: "phuong_hiep_hoa",
    district_code: 731,
  },
  {
    name: "Phường Hóa An",
    code: 26068,
    division_type: "phường",
    codename: "phuong_hoa_an",
    district_code: 731,
  },
  {
    name: "Phường An Hòa",
    code: 26371,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 731,
  },
  {
    name: "Phường Tam Phước",
    code: 26374,
    division_type: "phường",
    codename: "phuong_tam_phuoc",
    district_code: 731,
  },
  {
    name: "Phường Phước Tân",
    code: 26377,
    division_type: "phường",
    codename: "phuong_phuoc_tan",
    district_code: 731,
  },
  {
    name: "Xã Long Hưng",
    code: 26380,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 731,
  },
  {
    name: "Phường Xuân Trung",
    code: 26071,
    division_type: "phường",
    codename: "phuong_xuan_trung",
    district_code: 732,
  },
  {
    name: "Phường Xuân Thanh",
    code: 26074,
    division_type: "phường",
    codename: "phuong_xuan_thanh",
    district_code: 732,
  },
  {
    name: "Phường Xuân Bình",
    code: 26077,
    division_type: "phường",
    codename: "phuong_xuan_binh",
    district_code: 732,
  },
  {
    name: "Phường Xuân An",
    code: 26080,
    division_type: "phường",
    codename: "phuong_xuan_an",
    district_code: 732,
  },
  {
    name: "Phường Xuân Hoà",
    code: 26083,
    division_type: "phường",
    codename: "phuong_xuan_hoa",
    district_code: 732,
  },
  {
    name: "Phường Phú Bình",
    code: 26086,
    division_type: "phường",
    codename: "phuong_phu_binh",
    district_code: 732,
  },
  {
    name: "Xã Bình Lộc",
    code: 26089,
    division_type: "xã",
    codename: "xa_binh_loc",
    district_code: 732,
  },
  {
    name: "Xã Bảo Quang",
    code: 26092,
    division_type: "xã",
    codename: "xa_bao_quang",
    district_code: 732,
  },
  {
    name: "Phường Suối Tre",
    code: 26095,
    division_type: "phường",
    codename: "phuong_suoi_tre",
    district_code: 732,
  },
  {
    name: "Phường Bảo Vinh",
    code: 26098,
    division_type: "phường",
    codename: "phuong_bao_vinh",
    district_code: 732,
  },
  {
    name: "Phường Xuân Lập",
    code: 26101,
    division_type: "phường",
    codename: "phuong_xuan_lap",
    district_code: 732,
  },
  {
    name: "Phường Bàu Sen",
    code: 26104,
    division_type: "phường",
    codename: "phuong_bau_sen",
    district_code: 732,
  },
  {
    name: "Xã Bàu Trâm",
    code: 26107,
    division_type: "xã",
    codename: "xa_bau_tram",
    district_code: 732,
  },
  {
    name: "Phường Xuân Tân",
    code: 26110,
    division_type: "phường",
    codename: "phuong_xuan_tan",
    district_code: 732,
  },
  {
    name: "Xã Hàng Gòn",
    code: 26113,
    division_type: "xã",
    codename: "xa_hang_gon",
    district_code: 732,
  },
  {
    name: "Thị trấn Tân Phú",
    code: 26116,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phu",
    district_code: 734,
  },
  {
    name: "Xã Dak Lua",
    code: 26119,
    division_type: "xã",
    codename: "xa_dak_lua",
    district_code: 734,
  },
  {
    name: "Xã Nam Cát Tiên",
    code: 26122,
    division_type: "xã",
    codename: "xa_nam_cat_tien",
    district_code: 734,
  },
  {
    name: "Xã Phú An",
    code: 26125,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 734,
  },
  {
    name: "Xã Núi Tượng",
    code: 26128,
    division_type: "xã",
    codename: "xa_nui_tuong",
    district_code: 734,
  },
  {
    name: "Xã Tà Lài",
    code: 26131,
    division_type: "xã",
    codename: "xa_ta_lai",
    district_code: 734,
  },
  {
    name: "Xã Phú Lập",
    code: 26134,
    division_type: "xã",
    codename: "xa_phu_lap",
    district_code: 734,
  },
  {
    name: "Xã Phú Sơn",
    code: 26137,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 734,
  },
  {
    name: "Xã Phú Thịnh",
    code: 26140,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 734,
  },
  {
    name: "Xã Thanh Sơn",
    code: 26143,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 734,
  },
  {
    name: "Xã Phú Trung",
    code: 26146,
    division_type: "xã",
    codename: "xa_phu_trung",
    district_code: 734,
  },
  {
    name: "Xã Phú Xuân",
    code: 26149,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 734,
  },
  {
    name: "Xã Phú Lộc",
    code: 26152,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 734,
  },
  {
    name: "Xã Phú Lâm",
    code: 26155,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 734,
  },
  {
    name: "Xã Phú Bình",
    code: 26158,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 734,
  },
  {
    name: "Xã Phú Thanh",
    code: 26161,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 734,
  },
  {
    name: "Xã Trà Cổ",
    code: 26164,
    division_type: "xã",
    codename: "xa_tra_co",
    district_code: 734,
  },
  {
    name: "Xã Phú Điền",
    code: 26167,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 734,
  },
  {
    name: "Thị trấn Vĩnh An",
    code: 26170,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_an",
    district_code: 735,
  },
  {
    name: "Xã Phú Lý",
    code: 26173,
    division_type: "xã",
    codename: "xa_phu_ly",
    district_code: 735,
  },
  {
    name: "Xã Trị An",
    code: 26176,
    division_type: "xã",
    codename: "xa_tri_an",
    district_code: 735,
  },
  {
    name: "Xã Tân An",
    code: 26179,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 735,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 26182,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 735,
  },
  {
    name: "Xã Bình Lợi",
    code: 26185,
    division_type: "xã",
    codename: "xa_binh_loi",
    district_code: 735,
  },
  {
    name: "Xã Thạnh Phú",
    code: 26188,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 735,
  },
  {
    name: "Xã Thiện Tân",
    code: 26191,
    division_type: "xã",
    codename: "xa_thien_tan",
    district_code: 735,
  },
  {
    name: "Xã Tân Bình",
    code: 26194,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 735,
  },
  {
    name: "Xã Bình Hòa",
    code: 26197,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 735,
  },
  {
    name: "Xã Mã Đà",
    code: 26200,
    division_type: "xã",
    codename: "xa_ma_da",
    district_code: 735,
  },
  {
    name: "Xã Hiếu Liêm",
    code: 26203,
    division_type: "xã",
    codename: "xa_hieu_liem",
    district_code: 735,
  },
  {
    name: "Thị trấn Định Quán",
    code: 26206,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_quan",
    district_code: 736,
  },
  {
    name: "Xã Thanh Sơn",
    code: 26209,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 736,
  },
  {
    name: "Xã Phú Tân",
    code: 26212,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 736,
  },
  {
    name: "Xã Phú Vinh",
    code: 26215,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 736,
  },
  {
    name: "Xã Phú Lợi",
    code: 26218,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 736,
  },
  {
    name: "Xã Phú Hòa",
    code: 26221,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 736,
  },
  {
    name: "Xã Ngọc Định",
    code: 26224,
    division_type: "xã",
    codename: "xa_ngoc_dinh",
    district_code: 736,
  },
  {
    name: "Xã La Ngà",
    code: 26227,
    division_type: "xã",
    codename: "xa_la_nga",
    district_code: 736,
  },
  {
    name: "Xã Gia Canh",
    code: 26230,
    division_type: "xã",
    codename: "xa_gia_canh",
    district_code: 736,
  },
  {
    name: "Xã Phú Ngọc",
    code: 26233,
    division_type: "xã",
    codename: "xa_phu_ngoc",
    district_code: 736,
  },
  {
    name: "Xã Phú Cường",
    code: 26236,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 736,
  },
  {
    name: "Xã Túc Trưng",
    code: 26239,
    division_type: "xã",
    codename: "xa_tuc_trung",
    district_code: 736,
  },
  {
    name: "Xã Phú Túc",
    code: 26242,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 736,
  },
  {
    name: "Xã Suối Nho",
    code: 26245,
    division_type: "xã",
    codename: "xa_suoi_nho",
    district_code: 736,
  },
  {
    name: "Thị trấn Trảng Bom",
    code: 26248,
    division_type: "thị trấn",
    codename: "thi_tran_trang_bom",
    district_code: 737,
  },
  {
    name: "Xã Thanh Bình",
    code: 26251,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 737,
  },
  {
    name: "Xã Cây Gáo",
    code: 26254,
    division_type: "xã",
    codename: "xa_cay_gao",
    district_code: 737,
  },
  {
    name: "Xã Bàu Hàm",
    code: 26257,
    division_type: "xã",
    codename: "xa_bau_ham",
    district_code: 737,
  },
  {
    name: "Xã Sông Thao",
    code: 26260,
    division_type: "xã",
    codename: "xa_song_thao",
    district_code: 737,
  },
  {
    name: "Xã Sông Trầu",
    code: 26263,
    division_type: "xã",
    codename: "xa_song_trau",
    district_code: 737,
  },
  {
    name: "Xã Đông Hoà",
    code: 26266,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 737,
  },
  {
    name: "Xã Bắc Sơn",
    code: 26269,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 737,
  },
  {
    name: "Xã Hố Nai 3",
    code: 26272,
    division_type: "xã",
    codename: "xa_ho_nai_3",
    district_code: 737,
  },
  {
    name: "Xã Tây Hoà",
    code: 26275,
    division_type: "xã",
    codename: "xa_tay_hoa",
    district_code: 737,
  },
  {
    name: "Xã Bình Minh",
    code: 26278,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 737,
  },
  {
    name: "Xã Trung Hoà",
    code: 26281,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 737,
  },
  {
    name: "Xã Đồi 61",
    code: 26284,
    division_type: "xã",
    codename: "xa_doi_61",
    district_code: 737,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 26287,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 737,
  },
  {
    name: "Xã Quảng Tiến",
    code: 26290,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 737,
  },
  {
    name: "Xã Giang Điền",
    code: 26293,
    division_type: "xã",
    codename: "xa_giang_dien",
    district_code: 737,
  },
  {
    name: "Xã An Viễn",
    code: 26296,
    division_type: "xã",
    codename: "xa_an_vien",
    district_code: 737,
  },
  {
    name: "Xã Gia Tân 1",
    code: 26299,
    division_type: "xã",
    codename: "xa_gia_tan_1",
    district_code: 738,
  },
  {
    name: "Xã Gia Tân 2",
    code: 26302,
    division_type: "xã",
    codename: "xa_gia_tan_2",
    district_code: 738,
  },
  {
    name: "Xã Gia Tân 3",
    code: 26305,
    division_type: "xã",
    codename: "xa_gia_tan_3",
    district_code: 738,
  },
  {
    name: "Xã Gia Kiệm",
    code: 26308,
    division_type: "xã",
    codename: "xa_gia_kiem",
    district_code: 738,
  },
  {
    name: "Xã Quang Trung",
    code: 26311,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 738,
  },
  {
    name: "Xã Bàu Hàm 2",
    code: 26314,
    division_type: "xã",
    codename: "xa_bau_ham_2",
    district_code: 738,
  },
  {
    name: "Xã Hưng Lộc",
    code: 26317,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 738,
  },
  {
    name: "Xã Lộ 25",
    code: 26320,
    division_type: "xã",
    codename: "xa_lo_25",
    district_code: 738,
  },
  {
    name: "Xã Xuân Thiện",
    code: 26323,
    division_type: "xã",
    codename: "xa_xuan_thien",
    district_code: 738,
  },
  {
    name: "Thị trấn Dầu Giây",
    code: 26326,
    division_type: "thị trấn",
    codename: "thi_tran_dau_giay",
    district_code: 738,
  },
  {
    name: "Xã Sông Nhạn",
    code: 26329,
    division_type: "xã",
    codename: "xa_song_nhan",
    district_code: 739,
  },
  {
    name: "Xã Xuân Quế",
    code: 26332,
    division_type: "xã",
    codename: "xa_xuan_que",
    district_code: 739,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 26335,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 739,
  },
  {
    name: "Xã Xuân Đường",
    code: 26338,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 739,
  },
  {
    name: "Xã Long Giao",
    code: 26341,
    division_type: "xã",
    codename: "xa_long_giao",
    district_code: 739,
  },
  {
    name: "Xã Xuân Mỹ",
    code: 26344,
    division_type: "xã",
    codename: "xa_xuan_my",
    district_code: 739,
  },
  {
    name: "Xã Thừa Đức",
    code: 26347,
    division_type: "xã",
    codename: "xa_thua_duc",
    district_code: 739,
  },
  {
    name: "Xã Bảo Bình",
    code: 26350,
    division_type: "xã",
    codename: "xa_bao_binh",
    district_code: 739,
  },
  {
    name: "Xã Xuân Bảo",
    code: 26353,
    division_type: "xã",
    codename: "xa_xuan_bao",
    district_code: 739,
  },
  {
    name: "Xã Xuân Tây",
    code: 26356,
    division_type: "xã",
    codename: "xa_xuan_tay",
    district_code: 739,
  },
  {
    name: "Xã Xuân Đông",
    code: 26359,
    division_type: "xã",
    codename: "xa_xuan_dong",
    district_code: 739,
  },
  {
    name: "Xã Sông Ray",
    code: 26362,
    division_type: "xã",
    codename: "xa_song_ray",
    district_code: 739,
  },
  {
    name: "Xã Lâm San",
    code: 26365,
    division_type: "xã",
    codename: "xa_lam_san",
    district_code: 739,
  },
  {
    name: "Thị trấn Long Thành",
    code: 26368,
    division_type: "thị trấn",
    codename: "thi_tran_long_thanh",
    district_code: 740,
  },
  {
    name: "Xã An Phước",
    code: 26383,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 740,
  },
  {
    name: "Xã Bình An",
    code: 26386,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 740,
  },
  {
    name: "Xã Long Đức",
    code: 26389,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 740,
  },
  {
    name: "Xã Lộc An",
    code: 26392,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 740,
  },
  {
    name: "Xã Bình Sơn",
    code: 26395,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 740,
  },
  {
    name: "Xã Tam An",
    code: 26398,
    division_type: "xã",
    codename: "xa_tam_an",
    district_code: 740,
  },
  {
    name: "Xã Cẩm Đường",
    code: 26401,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 740,
  },
  {
    name: "Xã Long An",
    code: 26404,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 740,
  },
  {
    name: "Xã Bàu Cạn",
    code: 26410,
    division_type: "xã",
    codename: "xa_bau_can",
    district_code: 740,
  },
  {
    name: "Xã Long Phước",
    code: 26413,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 740,
  },
  {
    name: "Xã Phước Bình",
    code: 26416,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 740,
  },
  {
    name: "Xã Tân Hiệp",
    code: 26419,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 740,
  },
  {
    name: "Xã Phước Thái",
    code: 26422,
    division_type: "xã",
    codename: "xa_phuoc_thai",
    district_code: 740,
  },
  {
    name: "Thị trấn Gia Ray",
    code: 26425,
    division_type: "thị trấn",
    codename: "thi_tran_gia_ray",
    district_code: 741,
  },
  {
    name: "Xã Xuân Bắc",
    code: 26428,
    division_type: "xã",
    codename: "xa_xuan_bac",
    district_code: 741,
  },
  {
    name: "Xã Suối Cao",
    code: 26431,
    division_type: "xã",
    codename: "xa_suoi_cao",
    district_code: 741,
  },
  {
    name: "Xã Xuân Thành",
    code: 26434,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 741,
  },
  {
    name: "Xã Xuân Thọ",
    code: 26437,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 741,
  },
  {
    name: "Xã Xuân Trường",
    code: 26440,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hòa",
    code: 26443,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hưng",
    code: 26446,
    division_type: "xã",
    codename: "xa_xuan_hung",
    district_code: 741,
  },
  {
    name: "Xã Xuân Tâm",
    code: 26449,
    division_type: "xã",
    codename: "xa_xuan_tam",
    district_code: 741,
  },
  {
    name: "Xã Suối Cát",
    code: 26452,
    division_type: "xã",
    codename: "xa_suoi_cat",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hiệp",
    code: 26455,
    division_type: "xã",
    codename: "xa_xuan_hiep",
    district_code: 741,
  },
  {
    name: "Xã Xuân Phú",
    code: 26458,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 741,
  },
  {
    name: "Xã Xuân Định",
    code: 26461,
    division_type: "xã",
    codename: "xa_xuan_dinh",
    district_code: 741,
  },
  {
    name: "Xã Bảo Hoà",
    code: 26464,
    division_type: "xã",
    codename: "xa_bao_hoa",
    district_code: 741,
  },
  {
    name: "Xã Lang Minh",
    code: 26467,
    division_type: "xã",
    codename: "xa_lang_minh",
    district_code: 741,
  },
  {
    name: "Xã Phước Thiền",
    code: 26470,
    division_type: "xã",
    codename: "xa_phuoc_thien",
    district_code: 742,
  },
  {
    name: "Xã Long Tân",
    code: 26473,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 742,
  },
  {
    name: "Xã Đại Phước",
    code: 26476,
    division_type: "xã",
    codename: "xa_dai_phuoc",
    district_code: 742,
  },
  {
    name: "Thị trấn Hiệp Phước",
    code: 26479,
    division_type: "thị trấn",
    codename: "thi_tran_hiep_phuoc",
    district_code: 742,
  },
  {
    name: "Xã Phú Hữu",
    code: 26482,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 742,
  },
  {
    name: "Xã Phú Hội",
    code: 26485,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 742,
  },
  {
    name: "Xã Phú Thạnh",
    code: 26488,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 742,
  },
  {
    name: "Xã Phú Đông",
    code: 26491,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 742,
  },
  {
    name: "Xã Long Thọ",
    code: 26494,
    division_type: "xã",
    codename: "xa_long_tho",
    district_code: 742,
  },
  {
    name: "Xã Vĩnh Thanh",
    code: 26497,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 742,
  },
  {
    name: "Xã Phước Khánh",
    code: 26500,
    division_type: "xã",
    codename: "xa_phuoc_khanh",
    district_code: 742,
  },
  {
    name: "Xã Phước An",
    code: 26503,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 742,
  },
  {
    name: "Phường 1",
    code: 26506,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 747,
  },
  {
    name: "Phường Thắng Tam",
    code: 26508,
    division_type: "phường",
    codename: "phuong_thang_tam",
    district_code: 747,
  },
  {
    name: "Phường 2",
    code: 26509,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 747,
  },
  {
    name: "Phường 3",
    code: 26512,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 747,
  },
  {
    name: "Phường 4",
    code: 26515,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 747,
  },
  {
    name: "Phường 5",
    code: 26518,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 747,
  },
  {
    name: "Phường Thắng Nhì",
    code: 26521,
    division_type: "phường",
    codename: "phuong_thang_nhi",
    district_code: 747,
  },
  {
    name: "Phường 7",
    code: 26524,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 747,
  },
  {
    name: "Phường Nguyễn An Ninh",
    code: 26526,
    division_type: "phường",
    codename: "phuong_nguyen_an_ninh",
    district_code: 747,
  },
  {
    name: "Phường 8",
    code: 26527,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 747,
  },
  {
    name: "Phường 9",
    code: 26530,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 747,
  },
  {
    name: "Phường Thắng Nhất",
    code: 26533,
    division_type: "phường",
    codename: "phuong_thang_nhat",
    district_code: 747,
  },
  {
    name: "Phường Rạch Dừa",
    code: 26535,
    division_type: "phường",
    codename: "phuong_rach_dua",
    district_code: 747,
  },
  {
    name: "Phường 10",
    code: 26536,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 747,
  },
  {
    name: "Phường 11",
    code: 26539,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 747,
  },
  {
    name: "Phường 12",
    code: 26542,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 747,
  },
  {
    name: "Xã Long Sơn",
    code: 26545,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 747,
  },
  {
    name: "Phường Phước Hưng",
    code: 26548,
    division_type: "phường",
    codename: "phuong_phuoc_hung",
    district_code: 748,
  },
  {
    name: "Phường Phước Hiệp",
    code: 26551,
    division_type: "phường",
    codename: "phuong_phuoc_hiep",
    district_code: 748,
  },
  {
    name: "Phường Phước Nguyên",
    code: 26554,
    division_type: "phường",
    codename: "phuong_phuoc_nguyen",
    district_code: 748,
  },
  {
    name: "Phường Long Toàn",
    code: 26557,
    division_type: "phường",
    codename: "phuong_long_toan",
    district_code: 748,
  },
  {
    name: "Phường Long Tâm",
    code: 26558,
    division_type: "phường",
    codename: "phuong_long_tam",
    district_code: 748,
  },
  {
    name: "Phường Phước Trung",
    code: 26560,
    division_type: "phường",
    codename: "phuong_phuoc_trung",
    district_code: 748,
  },
  {
    name: "Phường Long Hương",
    code: 26563,
    division_type: "phường",
    codename: "phuong_long_huong",
    district_code: 748,
  },
  {
    name: "Phường Kim Dinh",
    code: 26566,
    division_type: "phường",
    codename: "phuong_kim_dinh",
    district_code: 748,
  },
  {
    name: "Xã Tân Hưng",
    code: 26567,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 748,
  },
  {
    name: "Xã Long Phước",
    code: 26569,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 748,
  },
  {
    name: "Xã Hoà Long",
    code: 26572,
    division_type: "xã",
    codename: "xa_hoa_long",
    district_code: 748,
  },
  {
    name: "Xã Bàu Chinh",
    code: 26574,
    division_type: "xã",
    codename: "xa_bau_chinh",
    district_code: 750,
  },
  {
    name: "Thị trấn Ngãi Giao",
    code: 26575,
    division_type: "thị trấn",
    codename: "thi_tran_ngai_giao",
    district_code: 750,
  },
  {
    name: "Xã Bình Ba",
    code: 26578,
    division_type: "xã",
    codename: "xa_binh_ba",
    district_code: 750,
  },
  {
    name: "Xã Suối Nghệ",
    code: 26581,
    division_type: "xã",
    codename: "xa_suoi_nghe",
    district_code: 750,
  },
  {
    name: "Xã Xuân Sơn",
    code: 26584,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 750,
  },
  {
    name: "Xã Sơn Bình",
    code: 26587,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 750,
  },
  {
    name: "Xã Bình Giã",
    code: 26590,
    division_type: "xã",
    codename: "xa_binh_gia",
    district_code: 750,
  },
  {
    name: "Xã Bình Trung",
    code: 26593,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 750,
  },
  {
    name: "Xã Xà Bang",
    code: 26596,
    division_type: "xã",
    codename: "xa_xa_bang",
    district_code: 750,
  },
  {
    name: "Xã Cù Bị",
    code: 26599,
    division_type: "xã",
    codename: "xa_cu_bi",
    district_code: 750,
  },
  {
    name: "Xã Láng Lớn",
    code: 26602,
    division_type: "xã",
    codename: "xa_lang_lon",
    district_code: 750,
  },
  {
    name: "Xã Quảng Thành",
    code: 26605,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 750,
  },
  {
    name: "Xã Kim Long",
    code: 26608,
    division_type: "xã",
    codename: "xa_kim_long",
    district_code: 750,
  },
  {
    name: "Xã Suối Rao",
    code: 26611,
    division_type: "xã",
    codename: "xa_suoi_rao",
    district_code: 750,
  },
  {
    name: "Xã Đá Bạc",
    code: 26614,
    division_type: "xã",
    codename: "xa_da_bac",
    district_code: 750,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 26617,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 750,
  },
  {
    name: "Thị trấn Phước Bửu",
    code: 26620,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_buu",
    district_code: 751,
  },
  {
    name: "Xã Phước Thuận",
    code: 26623,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 751,
  },
  {
    name: "Xã Phước Tân",
    code: 26626,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 751,
  },
  {
    name: "Xã Xuyên Mộc",
    code: 26629,
    division_type: "xã",
    codename: "xa_xuyen_moc",
    district_code: 751,
  },
  {
    name: "Xã Bông Trang",
    code: 26632,
    division_type: "xã",
    codename: "xa_bong_trang",
    district_code: 751,
  },
  {
    name: "Xã Tân Lâm",
    code: 26635,
    division_type: "xã",
    codename: "xa_tan_lam",
    district_code: 751,
  },
  {
    name: "Xã Bàu Lâm",
    code: 26638,
    division_type: "xã",
    codename: "xa_bau_lam",
    district_code: 751,
  },
  {
    name: "Xã Hòa Bình",
    code: 26641,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hưng",
    code: 26644,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 26647,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hội",
    code: 26650,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 751,
  },
  {
    name: "Xã Bưng Riềng",
    code: 26653,
    division_type: "xã",
    codename: "xa_bung_rieng",
    district_code: 751,
  },
  {
    name: "Xã Bình Châu",
    code: 26656,
    division_type: "xã",
    codename: "xa_binh_chau",
    district_code: 751,
  },
  {
    name: "Thị trấn Long Điền",
    code: 26659,
    division_type: "thị trấn",
    codename: "thi_tran_long_dien",
    district_code: 752,
  },
  {
    name: "Thị trấn Long Hải",
    code: 26662,
    division_type: "thị trấn",
    codename: "thi_tran_long_hai",
    district_code: 752,
  },
  {
    name: "Xã An Ngãi",
    code: 26665,
    division_type: "xã",
    codename: "xa_an_ngai",
    district_code: 752,
  },
  {
    name: "Xã Tam Phước",
    code: 26668,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 752,
  },
  {
    name: "Xã An Nhứt",
    code: 26671,
    division_type: "xã",
    codename: "xa_an_nhut",
    district_code: 752,
  },
  {
    name: "Xã Phước Tỉnh",
    code: 26674,
    division_type: "xã",
    codename: "xa_phuoc_tinh",
    district_code: 752,
  },
  {
    name: "Xã Phước Hưng",
    code: 26677,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 752,
  },
  {
    name: "Thị trấn Đất Đỏ",
    code: 26680,
    division_type: "thị trấn",
    codename: "thi_tran_dat_do",
    district_code: 753,
  },
  {
    name: "Xã Phước Long Thọ",
    code: 26683,
    division_type: "xã",
    codename: "xa_phuoc_long_tho",
    district_code: 753,
  },
  {
    name: "Xã Phước Hội",
    code: 26686,
    division_type: "xã",
    codename: "xa_phuoc_hoi",
    district_code: 753,
  },
  {
    name: "Xã Long Mỹ",
    code: 26689,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 753,
  },
  {
    name: "Thị trấn Phước Hải",
    code: 26692,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_hai",
    district_code: 753,
  },
  {
    name: "Xã Long Tân",
    code: 26695,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 753,
  },
  {
    name: "Xã Láng Dài",
    code: 26698,
    division_type: "xã",
    codename: "xa_lang_dai",
    district_code: 753,
  },
  {
    name: "Xã Lộc An",
    code: 26701,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 753,
  },
  {
    name: "Phường Phú Mỹ",
    code: 26704,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 754,
  },
  {
    name: "Xã Tân Hoà",
    code: 26707,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 754,
  },
  {
    name: "Xã Tân Hải",
    code: 26710,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 754,
  },
  {
    name: "Phường Phước Hoà",
    code: 26713,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 754,
  },
  {
    name: "Phường Tân Phước",
    code: 26716,
    division_type: "phường",
    codename: "phuong_tan_phuoc",
    district_code: 754,
  },
  {
    name: "Phường Mỹ Xuân",
    code: 26719,
    division_type: "phường",
    codename: "phuong_my_xuan",
    district_code: 754,
  },
  {
    name: "Xã Sông Xoài",
    code: 26722,
    division_type: "xã",
    codename: "xa_song_xoai",
    district_code: 754,
  },
  {
    name: "Phường Hắc Dịch",
    code: 26725,
    division_type: "phường",
    codename: "phuong_hac_dich",
    district_code: 754,
  },
  {
    name: "Xã Châu Pha",
    code: 26728,
    division_type: "xã",
    codename: "xa_chau_pha",
    district_code: 754,
  },
  {
    name: "Xã Tóc Tiên",
    code: 26731,
    division_type: "xã",
    codename: "xa_toc_tien",
    district_code: 754,
  },
  {
    name: "Phường Tân Định",
    code: 26734,
    division_type: "phường",
    codename: "phuong_tan_dinh",
    district_code: 760,
  },
  {
    name: "Phường Đa Kao",
    code: 26737,
    division_type: "phường",
    codename: "phuong_da_kao",
    district_code: 760,
  },
  {
    name: "Phường Bến Nghé",
    code: 26740,
    division_type: "phường",
    codename: "phuong_ben_nghe",
    district_code: 760,
  },
  {
    name: "Phường Bến Thành",
    code: 26743,
    division_type: "phường",
    codename: "phuong_ben_thanh",
    district_code: 760,
  },
  {
    name: "Phường Nguyễn Thái Bình",
    code: 26746,
    division_type: "phường",
    codename: "phuong_nguyen_thai_binh",
    district_code: 760,
  },
  {
    name: "Phường Phạm Ngũ Lão",
    code: 26749,
    division_type: "phường",
    codename: "phuong_pham_ngu_lao",
    district_code: 760,
  },
  {
    name: "Phường Cầu Ông Lãnh",
    code: 26752,
    division_type: "phường",
    codename: "phuong_cau_ong_lanh",
    district_code: 760,
  },
  {
    name: "Phường Cô Giang",
    code: 26755,
    division_type: "phường",
    codename: "phuong_co_giang",
    district_code: 760,
  },
  {
    name: "Phường Nguyễn Cư Trinh",
    code: 26758,
    division_type: "phường",
    codename: "phuong_nguyen_cu_trinh",
    district_code: 760,
  },
  {
    name: "Phường Cầu Kho",
    code: 26761,
    division_type: "phường",
    codename: "phuong_cau_kho",
    district_code: 760,
  },
  {
    name: "Phường Thạnh Xuân",
    code: 26764,
    division_type: "phường",
    codename: "phuong_thanh_xuan",
    district_code: 761,
  },
  {
    name: "Phường Thạnh Lộc",
    code: 26767,
    division_type: "phường",
    codename: "phuong_thanh_loc",
    district_code: 761,
  },
  {
    name: "Phường Hiệp Thành",
    code: 26770,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 761,
  },
  {
    name: "Phường Thới An",
    code: 26773,
    division_type: "phường",
    codename: "phuong_thoi_an",
    district_code: 761,
  },
  {
    name: "Phường Tân Chánh Hiệp",
    code: 26776,
    division_type: "phường",
    codename: "phuong_tan_chanh_hiep",
    district_code: 761,
  },
  {
    name: "Phường An Phú Đông",
    code: 26779,
    division_type: "phường",
    codename: "phuong_an_phu_dong",
    district_code: 761,
  },
  {
    name: "Phường Tân Thới Hiệp",
    code: 26782,
    division_type: "phường",
    codename: "phuong_tan_thoi_hiep",
    district_code: 761,
  },
  {
    name: "Phường Trung Mỹ Tây",
    code: 26785,
    division_type: "phường",
    codename: "phuong_trung_my_tay",
    district_code: 761,
  },
  {
    name: "Phường Tân Hưng Thuận",
    code: 26787,
    division_type: "phường",
    codename: "phuong_tan_hung_thuan",
    district_code: 761,
  },
  {
    name: "Phường Đông Hưng Thuận",
    code: 26788,
    division_type: "phường",
    codename: "phuong_dong_hung_thuan",
    district_code: 761,
  },
  {
    name: "Phường Tân Thới Nhất",
    code: 26791,
    division_type: "phường",
    codename: "phuong_tan_thoi_nhat",
    district_code: 761,
  },
  {
    name: "Phường 15",
    code: 26869,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 764,
  },
  {
    name: "Phường 13",
    code: 26872,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 764,
  },
  {
    name: "Phường 17",
    code: 26875,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 764,
  },
  {
    name: "Phường 6",
    code: 26876,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 764,
  },
  {
    name: "Phường 16",
    code: 26878,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 764,
  },
  {
    name: "Phường 12",
    code: 26881,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 764,
  },
  {
    name: "Phường 14",
    code: 26882,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 764,
  },
  {
    name: "Phường 10",
    code: 26884,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 764,
  },
  {
    name: "Phường 05",
    code: 26887,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 764,
  },
  {
    name: "Phường 07",
    code: 26890,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 764,
  },
  {
    name: "Phường 04",
    code: 26893,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 764,
  },
  {
    name: "Phường 01",
    code: 26896,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 764,
  },
  {
    name: "Phường 9",
    code: 26897,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 764,
  },
  {
    name: "Phường 8",
    code: 26898,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 764,
  },
  {
    name: "Phường 11",
    code: 26899,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 764,
  },
  {
    name: "Phường 03",
    code: 26902,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 764,
  },
  {
    name: "Phường 13",
    code: 26905,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 765,
  },
  {
    name: "Phường 11",
    code: 26908,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 765,
  },
  {
    name: "Phường 27",
    code: 26911,
    division_type: "phường",
    codename: "phuong_27",
    district_code: 765,
  },
  {
    name: "Phường 26",
    code: 26914,
    division_type: "phường",
    codename: "phuong_26",
    district_code: 765,
  },
  {
    name: "Phường 12",
    code: 26917,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 765,
  },
  {
    name: "Phường 25",
    code: 26920,
    division_type: "phường",
    codename: "phuong_25",
    district_code: 765,
  },
  {
    name: "Phường 05",
    code: 26923,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 765,
  },
  {
    name: "Phường 07",
    code: 26926,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 765,
  },
  {
    name: "Phường 24",
    code: 26929,
    division_type: "phường",
    codename: "phuong_24",
    district_code: 765,
  },
  {
    name: "Phường 06",
    code: 26932,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 765,
  },
  {
    name: "Phường 14",
    code: 26935,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 765,
  },
  {
    name: "Phường 15",
    code: 26938,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 765,
  },
  {
    name: "Phường 02",
    code: 26941,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 765,
  },
  {
    name: "Phường 01",
    code: 26944,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 765,
  },
  {
    name: "Phường 03",
    code: 26947,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 765,
  },
  {
    name: "Phường 17",
    code: 26950,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 765,
  },
  {
    name: "Phường 21",
    code: 26953,
    division_type: "phường",
    codename: "phuong_21",
    district_code: 765,
  },
  {
    name: "Phường 22",
    code: 26956,
    division_type: "phường",
    codename: "phuong_22",
    district_code: 765,
  },
  {
    name: "Phường 19",
    code: 26959,
    division_type: "phường",
    codename: "phuong_19",
    district_code: 765,
  },
  {
    name: "Phường 28",
    code: 26962,
    division_type: "phường",
    codename: "phuong_28",
    district_code: 765,
  },
  {
    name: "Phường 02",
    code: 26965,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 766,
  },
  {
    name: "Phường 04",
    code: 26968,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 766,
  },
  {
    name: "Phường 12",
    code: 26971,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 766,
  },
  {
    name: "Phường 13",
    code: 26974,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 766,
  },
  {
    name: "Phường 01",
    code: 26977,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 766,
  },
  {
    name: "Phường 03",
    code: 26980,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 766,
  },
  {
    name: "Phường 11",
    code: 26983,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 766,
  },
  {
    name: "Phường 07",
    code: 26986,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 766,
  },
  {
    name: "Phường 05",
    code: 26989,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 766,
  },
  {
    name: "Phường 10",
    code: 26992,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 766,
  },
  {
    name: "Phường 06",
    code: 26995,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 766,
  },
  {
    name: "Phường 08",
    code: 26998,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 766,
  },
  {
    name: "Phường 09",
    code: 27001,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 766,
  },
  {
    name: "Phường 14",
    code: 27004,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 766,
  },
  {
    name: "Phường 15",
    code: 27007,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 766,
  },
  {
    name: "Phường Tân Sơn Nhì",
    code: 27010,
    division_type: "phường",
    codename: "phuong_tan_son_nhi",
    district_code: 767,
  },
  {
    name: "Phường Tây Thạnh",
    code: 27013,
    division_type: "phường",
    codename: "phuong_tay_thanh",
    district_code: 767,
  },
  {
    name: "Phường Sơn Kỳ",
    code: 27016,
    division_type: "phường",
    codename: "phuong_son_ky",
    district_code: 767,
  },
  {
    name: "Phường Tân Quý",
    code: 27019,
    division_type: "phường",
    codename: "phuong_tan_quy",
    district_code: 767,
  },
  {
    name: "Phường Tân Thành",
    code: 27022,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 767,
  },
  {
    name: "Phường Phú Thọ Hòa",
    code: 27025,
    division_type: "phường",
    codename: "phuong_phu_tho_hoa",
    district_code: 767,
  },
  {
    name: "Phường Phú Thạnh",
    code: 27028,
    division_type: "phường",
    codename: "phuong_phu_thanh",
    district_code: 767,
  },
  {
    name: "Phường Phú Trung",
    code: 27031,
    division_type: "phường",
    codename: "phuong_phu_trung",
    district_code: 767,
  },
  {
    name: "Phường Hòa Thạnh",
    code: 27034,
    division_type: "phường",
    codename: "phuong_hoa_thanh",
    district_code: 767,
  },
  {
    name: "Phường Hiệp Tân",
    code: 27037,
    division_type: "phường",
    codename: "phuong_hiep_tan",
    district_code: 767,
  },
  {
    name: "Phường Tân Thới Hòa",
    code: 27040,
    division_type: "phường",
    codename: "phuong_tan_thoi_hoa",
    district_code: 767,
  },
  {
    name: "Phường 04",
    code: 27043,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 768,
  },
  {
    name: "Phường 05",
    code: 27046,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 768,
  },
  {
    name: "Phường 09",
    code: 27049,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 768,
  },
  {
    name: "Phường 07",
    code: 27052,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 768,
  },
  {
    name: "Phường 03",
    code: 27055,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 768,
  },
  {
    name: "Phường 01",
    code: 27058,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 768,
  },
  {
    name: "Phường 02",
    code: 27061,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 768,
  },
  {
    name: "Phường 08",
    code: 27064,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 768,
  },
  {
    name: "Phường 15",
    code: 27067,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 768,
  },
  {
    name: "Phường 10",
    code: 27070,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 768,
  },
  {
    name: "Phường 11",
    code: 27073,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 768,
  },
  {
    name: "Phường 17",
    code: 27076,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 768,
  },
  {
    name: "Phường 13",
    code: 27085,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 768,
  },
  {
    name: "Phường Linh Xuân",
    code: 26794,
    division_type: "phường",
    codename: "phuong_linh_xuan",
    district_code: 769,
  },
  {
    name: "Phường Bình Chiểu",
    code: 26797,
    division_type: "phường",
    codename: "phuong_binh_chieu",
    district_code: 769,
  },
  {
    name: "Phường Linh Trung",
    code: 26800,
    division_type: "phường",
    codename: "phuong_linh_trung",
    district_code: 769,
  },
  {
    name: "Phường Tam Bình",
    code: 26803,
    division_type: "phường",
    codename: "phuong_tam_binh",
    district_code: 769,
  },
  {
    name: "Phường Tam Phú",
    code: 26806,
    division_type: "phường",
    codename: "phuong_tam_phu",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Bình Phước",
    code: 26809,
    division_type: "phường",
    codename: "phuong_hiep_binh_phuoc",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Bình Chánh",
    code: 26812,
    division_type: "phường",
    codename: "phuong_hiep_binh_chanh",
    district_code: 769,
  },
  {
    name: "Phường Linh Chiểu",
    code: 26815,
    division_type: "phường",
    codename: "phuong_linh_chieu",
    district_code: 769,
  },
  {
    name: "Phường Linh Tây",
    code: 26818,
    division_type: "phường",
    codename: "phuong_linh_tay",
    district_code: 769,
  },
  {
    name: "Phường Linh Đông",
    code: 26821,
    division_type: "phường",
    codename: "phuong_linh_dong",
    district_code: 769,
  },
  {
    name: "Phường Bình Thọ",
    code: 26824,
    division_type: "phường",
    codename: "phuong_binh_tho",
    district_code: 769,
  },
  {
    name: "Phường Trường Thọ",
    code: 26827,
    division_type: "phường",
    codename: "phuong_truong_tho",
    district_code: 769,
  },
  {
    name: "Phường Long Bình",
    code: 26830,
    division_type: "phường",
    codename: "phuong_long_binh",
    district_code: 769,
  },
  {
    name: "Phường Long Thạnh Mỹ",
    code: 26833,
    division_type: "phường",
    codename: "phuong_long_thanh_my",
    district_code: 769,
  },
  {
    name: "Phường Tân Phú",
    code: 26836,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Phú",
    code: 26839,
    division_type: "phường",
    codename: "phuong_hiep_phu",
    district_code: 769,
  },
  {
    name: "Phường Tăng Nhơn Phú A",
    code: 26842,
    division_type: "phường",
    codename: "phuong_tang_nhon_phu_a",
    district_code: 769,
  },
  {
    name: "Phường Tăng Nhơn Phú B",
    code: 26845,
    division_type: "phường",
    codename: "phuong_tang_nhon_phu_b",
    district_code: 769,
  },
  {
    name: "Phường Phước Long B",
    code: 26848,
    division_type: "phường",
    codename: "phuong_phuoc_long_b",
    district_code: 769,
  },
  {
    name: "Phường Phước Long A",
    code: 26851,
    division_type: "phường",
    codename: "phuong_phuoc_long_a",
    district_code: 769,
  },
  {
    name: "Phường Trường Thạnh",
    code: 26854,
    division_type: "phường",
    codename: "phuong_truong_thanh",
    district_code: 769,
  },
  {
    name: "Phường Long Phước",
    code: 26857,
    division_type: "phường",
    codename: "phuong_long_phuoc",
    district_code: 769,
  },
  {
    name: "Phường Long Trường",
    code: 26860,
    division_type: "phường",
    codename: "phuong_long_truong",
    district_code: 769,
  },
  {
    name: "Phường Phước Bình",
    code: 26863,
    division_type: "phường",
    codename: "phuong_phuoc_binh",
    district_code: 769,
  },
  {
    name: "Phường Phú Hữu",
    code: 26866,
    division_type: "phường",
    codename: "phuong_phu_huu",
    district_code: 769,
  },
  {
    name: "Phường Thảo Điền",
    code: 27088,
    division_type: "phường",
    codename: "phuong_thao_dien",
    district_code: 769,
  },
  {
    name: "Phường An Phú",
    code: 27091,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 769,
  },
  {
    name: "Phường An Khánh",
    code: 27094,
    division_type: "phường",
    codename: "phuong_an_khanh",
    district_code: 769,
  },
  {
    name: "Phường Bình Trưng Đông",
    code: 27097,
    division_type: "phường",
    codename: "phuong_binh_trung_dong",
    district_code: 769,
  },
  {
    name: "Phường Bình Trưng Tây",
    code: 27100,
    division_type: "phường",
    codename: "phuong_binh_trung_tay",
    district_code: 769,
  },
  {
    name: "Phường Cát Lái",
    code: 27109,
    division_type: "phường",
    codename: "phuong_cat_lai",
    district_code: 769,
  },
  {
    name: "Phường Thạnh Mỹ Lợi",
    code: 27112,
    division_type: "phường",
    codename: "phuong_thanh_my_loi",
    district_code: 769,
  },
  {
    name: "Phường An Lợi Đông",
    code: 27115,
    division_type: "phường",
    codename: "phuong_an_loi_dong",
    district_code: 769,
  },
  {
    name: "Phường Thủ Thiêm",
    code: 27118,
    division_type: "phường",
    codename: "phuong_thu_thiem",
    district_code: 769,
  },
  {
    name: "Phường 14",
    code: 27127,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 770,
  },
  {
    name: "Phường 12",
    code: 27130,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 770,
  },
  {
    name: "Phường 11",
    code: 27133,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 770,
  },
  {
    name: "Phường 13",
    code: 27136,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 770,
  },
  {
    name: "Phường Võ Thị Sáu",
    code: 27139,
    division_type: "phường",
    codename: "phuong_vo_thi_sau",
    district_code: 770,
  },
  {
    name: "Phường 09",
    code: 27142,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 770,
  },
  {
    name: "Phường 10",
    code: 27145,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 770,
  },
  {
    name: "Phường 04",
    code: 27148,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 770,
  },
  {
    name: "Phường 05",
    code: 27151,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 770,
  },
  {
    name: "Phường 03",
    code: 27154,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 770,
  },
  {
    name: "Phường 02",
    code: 27157,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 770,
  },
  {
    name: "Phường 01",
    code: 27160,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 770,
  },
  {
    name: "Phường 15",
    code: 27163,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 771,
  },
  {
    name: "Phường 13",
    code: 27166,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 771,
  },
  {
    name: "Phường 14",
    code: 27169,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 771,
  },
  {
    name: "Phường 12",
    code: 27172,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 771,
  },
  {
    name: "Phường 11",
    code: 27175,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 771,
  },
  {
    name: "Phường 10",
    code: 27178,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 771,
  },
  {
    name: "Phường 09",
    code: 27181,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 771,
  },
  {
    name: "Phường 01",
    code: 27184,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 771,
  },
  {
    name: "Phường 08",
    code: 27187,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 771,
  },
  {
    name: "Phường 02",
    code: 27190,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 771,
  },
  {
    name: "Phường 04",
    code: 27193,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 771,
  },
  {
    name: "Phường 07",
    code: 27196,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 771,
  },
  {
    name: "Phường 05",
    code: 27199,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 771,
  },
  {
    name: "Phường 06",
    code: 27202,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 771,
  },
  {
    name: "Phường 15",
    code: 27208,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 772,
  },
  {
    name: "Phường 05",
    code: 27211,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 772,
  },
  {
    name: "Phường 14",
    code: 27214,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 772,
  },
  {
    name: "Phường 11",
    code: 27217,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 772,
  },
  {
    name: "Phường 03",
    code: 27220,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 772,
  },
  {
    name: "Phường 10",
    code: 27223,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 772,
  },
  {
    name: "Phường 13",
    code: 27226,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 772,
  },
  {
    name: "Phường 08",
    code: 27229,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 772,
  },
  {
    name: "Phường 09",
    code: 27232,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 772,
  },
  {
    name: "Phường 12",
    code: 27235,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 772,
  },
  {
    name: "Phường 07",
    code: 27238,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 772,
  },
  {
    name: "Phường 06",
    code: 27241,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 772,
  },
  {
    name: "Phường 04",
    code: 27244,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 772,
  },
  {
    name: "Phường 01",
    code: 27247,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 772,
  },
  {
    name: "Phường 02",
    code: 27250,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 772,
  },
  {
    name: "Phường 16",
    code: 27253,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 772,
  },
  {
    name: "Phường 13",
    code: 27259,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 773,
  },
  {
    name: "Phường 09",
    code: 27262,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 773,
  },
  {
    name: "Phường 06",
    code: 27265,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 773,
  },
  {
    name: "Phường 08",
    code: 27268,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 773,
  },
  {
    name: "Phường 10",
    code: 27271,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 773,
  },
  {
    name: "Phường 18",
    code: 27277,
    division_type: "phường",
    codename: "phuong_18",
    district_code: 773,
  },
  {
    name: "Phường 14",
    code: 27280,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 773,
  },
  {
    name: "Phường 04",
    code: 27283,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 773,
  },
  {
    name: "Phường 03",
    code: 27286,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 773,
  },
  {
    name: "Phường 16",
    code: 27289,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 773,
  },
  {
    name: "Phường 02",
    code: 27292,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 773,
  },
  {
    name: "Phường 15",
    code: 27295,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 773,
  },
  {
    name: "Phường 01",
    code: 27298,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 773,
  },
  {
    name: "Phường 04",
    code: 27301,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 774,
  },
  {
    name: "Phường 09",
    code: 27304,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 774,
  },
  {
    name: "Phường 03",
    code: 27307,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 774,
  },
  {
    name: "Phường 12",
    code: 27310,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 774,
  },
  {
    name: "Phường 02",
    code: 27313,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 774,
  },
  {
    name: "Phường 08",
    code: 27316,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 774,
  },
  {
    name: "Phường 07",
    code: 27322,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 774,
  },
  {
    name: "Phường 01",
    code: 27325,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 774,
  },
  {
    name: "Phường 11",
    code: 27328,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 774,
  },
  {
    name: "Phường 14",
    code: 27331,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 774,
  },
  {
    name: "Phường 05",
    code: 27334,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 774,
  },
  {
    name: "Phường 06",
    code: 27337,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 774,
  },
  {
    name: "Phường 10",
    code: 27340,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 774,
  },
  {
    name: "Phường 13",
    code: 27343,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 774,
  },
  {
    name: "Phường 14",
    code: 27346,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 775,
  },
  {
    name: "Phường 13",
    code: 27349,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 775,
  },
  {
    name: "Phường 09",
    code: 27352,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 775,
  },
  {
    name: "Phường 06",
    code: 27355,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 775,
  },
  {
    name: "Phường 12",
    code: 27358,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 775,
  },
  {
    name: "Phường 05",
    code: 27361,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 775,
  },
  {
    name: "Phường 11",
    code: 27364,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 775,
  },
  {
    name: "Phường 02",
    code: 27367,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 775,
  },
  {
    name: "Phường 01",
    code: 27370,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 775,
  },
  {
    name: "Phường 04",
    code: 27373,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 775,
  },
  {
    name: "Phường 08",
    code: 27376,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 775,
  },
  {
    name: "Phường 03",
    code: 27379,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 775,
  },
  {
    name: "Phường 07",
    code: 27382,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 775,
  },
  {
    name: "Phường 10",
    code: 27385,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 775,
  },
  {
    name: "Phường 08",
    code: 27388,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 776,
  },
  {
    name: "Phường 02",
    code: 27391,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 776,
  },
  {
    name: "Phường 01",
    code: 27394,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 776,
  },
  {
    name: "Phường 03",
    code: 27397,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 776,
  },
  {
    name: "Phường 11",
    code: 27400,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 776,
  },
  {
    name: "Phường 09",
    code: 27403,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 776,
  },
  {
    name: "Phường 10",
    code: 27406,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 776,
  },
  {
    name: "Phường 04",
    code: 27409,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 776,
  },
  {
    name: "Phường 13",
    code: 27412,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 776,
  },
  {
    name: "Phường 12",
    code: 27415,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 776,
  },
  {
    name: "Phường 05",
    code: 27418,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 776,
  },
  {
    name: "Phường 14",
    code: 27421,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 776,
  },
  {
    name: "Phường 06",
    code: 27424,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 776,
  },
  {
    name: "Phường 15",
    code: 27427,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 776,
  },
  {
    name: "Phường 16",
    code: 27430,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 776,
  },
  {
    name: "Phường 07",
    code: 27433,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 776,
  },
  {
    name: "Phường Bình Hưng Hòa",
    code: 27436,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa",
    district_code: 777,
  },
  {
    name: "Phường Bình Hưng Hoà A",
    code: 27439,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa_a",
    district_code: 777,
  },
  {
    name: "Phường Bình Hưng Hoà B",
    code: 27442,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa_b",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông",
    code: 27445,
    division_type: "phường",
    codename: "phuong_binh_tri_dong",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông A",
    code: 27448,
    division_type: "phường",
    codename: "phuong_binh_tri_dong_a",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông B",
    code: 27451,
    division_type: "phường",
    codename: "phuong_binh_tri_dong_b",
    district_code: 777,
  },
  {
    name: "Phường Tân Tạo",
    code: 27454,
    division_type: "phường",
    codename: "phuong_tan_tao",
    district_code: 777,
  },
  {
    name: "Phường Tân Tạo A",
    code: 27457,
    division_type: "phường",
    codename: "phuong_tan_tao_a",
    district_code: 777,
  },
  {
    name: "Phường An Lạc",
    code: 27460,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 777,
  },
  {
    name: "Phường An Lạc A",
    code: 27463,
    division_type: "phường",
    codename: "phuong_an_lac_a",
    district_code: 777,
  },
  {
    name: "Phường Tân Thuận Đông",
    code: 27466,
    division_type: "phường",
    codename: "phuong_tan_thuan_dong",
    district_code: 778,
  },
  {
    name: "Phường Tân Thuận Tây",
    code: 27469,
    division_type: "phường",
    codename: "phuong_tan_thuan_tay",
    district_code: 778,
  },
  {
    name: "Phường Tân Kiểng",
    code: 27472,
    division_type: "phường",
    codename: "phuong_tan_kieng",
    district_code: 778,
  },
  {
    name: "Phường Tân Hưng",
    code: 27475,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 778,
  },
  {
    name: "Phường Bình Thuận",
    code: 27478,
    division_type: "phường",
    codename: "phuong_binh_thuan",
    district_code: 778,
  },
  {
    name: "Phường Tân Quy",
    code: 27481,
    division_type: "phường",
    codename: "phuong_tan_quy",
    district_code: 778,
  },
  {
    name: "Phường Phú Thuận",
    code: 27484,
    division_type: "phường",
    codename: "phuong_phu_thuan",
    district_code: 778,
  },
  {
    name: "Phường Tân Phú",
    code: 27487,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 778,
  },
  {
    name: "Phường Tân Phong",
    code: 27490,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 778,
  },
  {
    name: "Phường Phú Mỹ",
    code: 27493,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 778,
  },
  {
    name: "Thị trấn Củ Chi",
    code: 27496,
    division_type: "thị trấn",
    codename: "thi_tran_cu_chi",
    district_code: 783,
  },
  {
    name: "Xã Phú Mỹ Hưng",
    code: 27499,
    division_type: "xã",
    codename: "xa_phu_my_hung",
    district_code: 783,
  },
  {
    name: "Xã An Phú",
    code: 27502,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 783,
  },
  {
    name: "Xã Trung Lập Thượng",
    code: 27505,
    division_type: "xã",
    codename: "xa_trung_lap_thuong",
    district_code: 783,
  },
  {
    name: "Xã An Nhơn Tây",
    code: 27508,
    division_type: "xã",
    codename: "xa_an_nhon_tay",
    district_code: 783,
  },
  {
    name: "Xã Nhuận Đức",
    code: 27511,
    division_type: "xã",
    codename: "xa_nhuan_duc",
    district_code: 783,
  },
  {
    name: "Xã Phạm Văn Cội",
    code: 27514,
    division_type: "xã",
    codename: "xa_pham_van_coi",
    district_code: 783,
  },
  {
    name: "Xã Phú Hòa Đông",
    code: 27517,
    division_type: "xã",
    codename: "xa_phu_hoa_dong",
    district_code: 783,
  },
  {
    name: "Xã Trung Lập Hạ",
    code: 27520,
    division_type: "xã",
    codename: "xa_trung_lap_ha",
    district_code: 783,
  },
  {
    name: "Xã Trung An",
    code: 27523,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 783,
  },
  {
    name: "Xã Phước Thạnh",
    code: 27526,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 783,
  },
  {
    name: "Xã Phước Hiệp",
    code: 27529,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 783,
  },
  {
    name: "Xã Tân An Hội",
    code: 27532,
    division_type: "xã",
    codename: "xa_tan_an_hoi",
    district_code: 783,
  },
  {
    name: "Xã Phước Vĩnh An",
    code: 27535,
    division_type: "xã",
    codename: "xa_phuoc_vinh_an",
    district_code: 783,
  },
  {
    name: "Xã Thái Mỹ",
    code: 27538,
    division_type: "xã",
    codename: "xa_thai_my",
    district_code: 783,
  },
  {
    name: "Xã Tân Thạnh Tây",
    code: 27541,
    division_type: "xã",
    codename: "xa_tan_thanh_tay",
    district_code: 783,
  },
  {
    name: "Xã Hòa Phú",
    code: 27544,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 783,
  },
  {
    name: "Xã Tân Thạnh Đông",
    code: 27547,
    division_type: "xã",
    codename: "xa_tan_thanh_dong",
    district_code: 783,
  },
  {
    name: "Xã Bình Mỹ",
    code: 27550,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 783,
  },
  {
    name: "Xã Tân Phú Trung",
    code: 27553,
    division_type: "xã",
    codename: "xa_tan_phu_trung",
    district_code: 783,
  },
  {
    name: "Xã Tân Thông Hội",
    code: 27556,
    division_type: "xã",
    codename: "xa_tan_thong_hoi",
    district_code: 783,
  },
  {
    name: "Thị trấn Hóc Môn",
    code: 27559,
    division_type: "thị trấn",
    codename: "thi_tran_hoc_mon",
    district_code: 784,
  },
  {
    name: "Xã Tân Hiệp",
    code: 27562,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 784,
  },
  {
    name: "Xã Nhị Bình",
    code: 27565,
    division_type: "xã",
    codename: "xa_nhi_binh",
    district_code: 784,
  },
  {
    name: "Xã Đông Thạnh",
    code: 27568,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 784,
  },
  {
    name: "Xã Tân Thới Nhì",
    code: 27571,
    division_type: "xã",
    codename: "xa_tan_thoi_nhi",
    district_code: 784,
  },
  {
    name: "Xã Thới Tam Thôn",
    code: 27574,
    division_type: "xã",
    codename: "xa_thoi_tam_thon",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Sơn",
    code: 27577,
    division_type: "xã",
    codename: "xa_xuan_thoi_son",
    district_code: 784,
  },
  {
    name: "Xã Tân Xuân",
    code: 27580,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Đông",
    code: 27583,
    division_type: "xã",
    codename: "xa_xuan_thoi_dong",
    district_code: 784,
  },
  {
    name: "Xã Trung Chánh",
    code: 27586,
    division_type: "xã",
    codename: "xa_trung_chanh",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Thượng",
    code: 27589,
    division_type: "xã",
    codename: "xa_xuan_thoi_thuong",
    district_code: 784,
  },
  {
    name: "Xã Bà Điểm",
    code: 27592,
    division_type: "xã",
    codename: "xa_ba_diem",
    district_code: 784,
  },
  {
    name: "Thị trấn Tân Túc",
    code: 27595,
    division_type: "thị trấn",
    codename: "thi_tran_tan_tuc",
    district_code: 785,
  },
  {
    name: "Xã Phạm Văn Hai",
    code: 27598,
    division_type: "xã",
    codename: "xa_pham_van_hai",
    district_code: 785,
  },
  {
    name: "Xã Vĩnh Lộc A",
    code: 27601,
    division_type: "xã",
    codename: "xa_vinh_loc_a",
    district_code: 785,
  },
  {
    name: "Xã Vĩnh Lộc B",
    code: 27604,
    division_type: "xã",
    codename: "xa_vinh_loc_b",
    district_code: 785,
  },
  {
    name: "Xã Bình Lợi",
    code: 27607,
    division_type: "xã",
    codename: "xa_binh_loi",
    district_code: 785,
  },
  {
    name: "Xã Lê Minh Xuân",
    code: 27610,
    division_type: "xã",
    codename: "xa_le_minh_xuan",
    district_code: 785,
  },
  {
    name: "Xã Tân Nhựt",
    code: 27613,
    division_type: "xã",
    codename: "xa_tan_nhut",
    district_code: 785,
  },
  {
    name: "Xã Tân Kiên",
    code: 27616,
    division_type: "xã",
    codename: "xa_tan_kien",
    district_code: 785,
  },
  {
    name: "Xã Bình Hưng",
    code: 27619,
    division_type: "xã",
    codename: "xa_binh_hung",
    district_code: 785,
  },
  {
    name: "Xã Phong Phú",
    code: 27622,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 785,
  },
  {
    name: "Xã An Phú Tây",
    code: 27625,
    division_type: "xã",
    codename: "xa_an_phu_tay",
    district_code: 785,
  },
  {
    name: "Xã Hưng Long",
    code: 27628,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 785,
  },
  {
    name: "Xã Đa Phước",
    code: 27631,
    division_type: "xã",
    codename: "xa_da_phuoc",
    district_code: 785,
  },
  {
    name: "Xã Tân Quý Tây",
    code: 27634,
    division_type: "xã",
    codename: "xa_tan_quy_tay",
    district_code: 785,
  },
  {
    name: "Xã Bình Chánh",
    code: 27637,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 785,
  },
  {
    name: "Xã Quy Đức",
    code: 27640,
    division_type: "xã",
    codename: "xa_quy_duc",
    district_code: 785,
  },
  {
    name: "Thị trấn Nhà Bè",
    code: 27643,
    division_type: "thị trấn",
    codename: "thi_tran_nha_be",
    district_code: 786,
  },
  {
    name: "Xã Phước Kiển",
    code: 27646,
    division_type: "xã",
    codename: "xa_phuoc_kien",
    district_code: 786,
  },
  {
    name: "Xã Phước Lộc",
    code: 27649,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 786,
  },
  {
    name: "Xã Nhơn Đức",
    code: 27652,
    division_type: "xã",
    codename: "xa_nhon_duc",
    district_code: 786,
  },
  {
    name: "Xã Phú Xuân",
    code: 27655,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 786,
  },
  {
    name: "Xã Long Thới",
    code: 27658,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 786,
  },
  {
    name: "Xã Hiệp Phước",
    code: 27661,
    division_type: "xã",
    codename: "xa_hiep_phuoc",
    district_code: 786,
  },
  {
    name: "Thị trấn Cần Thạnh",
    code: 27664,
    division_type: "thị trấn",
    codename: "thi_tran_can_thanh",
    district_code: 787,
  },
  {
    name: "Xã Bình Khánh",
    code: 27667,
    division_type: "xã",
    codename: "xa_binh_khanh",
    district_code: 787,
  },
  {
    name: "Xã Tam Thôn Hiệp",
    code: 27670,
    division_type: "xã",
    codename: "xa_tam_thon_hiep",
    district_code: 787,
  },
  {
    name: "Xã An Thới Đông",
    code: 27673,
    division_type: "xã",
    codename: "xa_an_thoi_dong",
    district_code: 787,
  },
  {
    name: "Xã Thạnh An",
    code: 27676,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 787,
  },
  {
    name: "Xã Long Hòa",
    code: 27679,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 787,
  },
  {
    name: "Xã Lý Nhơn",
    code: 27682,
    division_type: "xã",
    codename: "xa_ly_nhon",
    district_code: 787,
  },
  {
    name: "Phường 5",
    code: 27685,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 794,
  },
  {
    name: "Phường 2",
    code: 27688,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 794,
  },
  {
    name: "Phường 4",
    code: 27691,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 794,
  },
  {
    name: "Phường Tân Khánh",
    code: 27692,
    division_type: "phường",
    codename: "phuong_tan_khanh",
    district_code: 794,
  },
  {
    name: "Phường 1",
    code: 27694,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 794,
  },
  {
    name: "Phường 3",
    code: 27697,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 794,
  },
  {
    name: "Phường 7",
    code: 27698,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 794,
  },
  {
    name: "Phường 6",
    code: 27700,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 794,
  },
  {
    name: "Xã Hướng Thọ Phú",
    code: 27703,
    division_type: "xã",
    codename: "xa_huong_tho_phu",
    district_code: 794,
  },
  {
    name: "Xã Nhơn Thạnh Trung",
    code: 27706,
    division_type: "xã",
    codename: "xa_nhon_thanh_trung",
    district_code: 794,
  },
  {
    name: "Xã Lợi Bình Nhơn",
    code: 27709,
    division_type: "xã",
    codename: "xa_loi_binh_nhon",
    district_code: 794,
  },
  {
    name: "Xã Bình Tâm",
    code: 27712,
    division_type: "xã",
    codename: "xa_binh_tam",
    district_code: 794,
  },
  {
    name: "Phường Khánh Hậu",
    code: 27715,
    division_type: "phường",
    codename: "phuong_khanh_hau",
    district_code: 794,
  },
  {
    name: "Xã An Vĩnh Ngãi",
    code: 27718,
    division_type: "xã",
    codename: "xa_an_vinh_ngai",
    district_code: 794,
  },
  {
    name: "Phường 1",
    code: 27787,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 795,
  },
  {
    name: "Phường 2",
    code: 27788,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 795,
  },
  {
    name: "Xã Thạnh Trị",
    code: 27790,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 795,
  },
  {
    name: "Xã Bình Hiệp",
    code: 27793,
    division_type: "xã",
    codename: "xa_binh_hiep",
    district_code: 795,
  },
  {
    name: "Xã Bình Tân",
    code: 27799,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 795,
  },
  {
    name: "Xã Tuyên Thạnh",
    code: 27805,
    division_type: "xã",
    codename: "xa_tuyen_thanh",
    district_code: 795,
  },
  {
    name: "Phường 3",
    code: 27806,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 795,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 27817,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 795,
  },
  {
    name: "Thị trấn Tân Hưng",
    code: 27721,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hung",
    district_code: 796,
  },
  {
    name: "Xã Hưng Hà",
    code: 27724,
    division_type: "xã",
    codename: "xa_hung_ha",
    district_code: 796,
  },
  {
    name: "Xã Hưng Điền B",
    code: 27727,
    division_type: "xã",
    codename: "xa_hung_dien_b",
    district_code: 796,
  },
  {
    name: "Xã Hưng Điền",
    code: 27730,
    division_type: "xã",
    codename: "xa_hung_dien",
    district_code: 796,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 27733,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 796,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 27736,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 27739,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Châu B",
    code: 27742,
    division_type: "xã",
    codename: "xa_vinh_chau_b",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 27745,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Đại",
    code: 27748,
    division_type: "xã",
    codename: "xa_vinh_dai",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Châu A",
    code: 27751,
    division_type: "xã",
    codename: "xa_vinh_chau_a",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Bửu",
    code: 27754,
    division_type: "xã",
    codename: "xa_vinh_buu",
    district_code: 796,
  },
  {
    name: "Thị trấn Vĩnh Hưng",
    code: 27757,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_hung",
    district_code: 797,
  },
  {
    name: "Xã Hưng Điền A",
    code: 27760,
    division_type: "xã",
    codename: "xa_hung_dien_a",
    district_code: 797,
  },
  {
    name: "Xã Khánh Hưng",
    code: 27763,
    division_type: "xã",
    codename: "xa_khanh_hung",
    district_code: 797,
  },
  {
    name: "Xã Thái Trị",
    code: 27766,
    division_type: "xã",
    codename: "xa_thai_tri",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Trị",
    code: 27769,
    division_type: "xã",
    codename: "xa_vinh_tri",
    district_code: 797,
  },
  {
    name: "Xã Thái Bình Trung",
    code: 27772,
    division_type: "xã",
    codename: "xa_thai_binh_trung",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 27775,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 27778,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 797,
  },
  {
    name: "Xã Tuyên Bình",
    code: 27781,
    division_type: "xã",
    codename: "xa_tuyen_binh",
    district_code: 797,
  },
  {
    name: "Xã Tuyên Bình Tây",
    code: 27784,
    division_type: "xã",
    codename: "xa_tuyen_binh_tay",
    district_code: 797,
  },
  {
    name: "Xã Bình Hòa Tây",
    code: 27796,
    division_type: "xã",
    codename: "xa_binh_hoa_tay",
    district_code: 798,
  },
  {
    name: "Xã Bình Thạnh",
    code: 27802,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 798,
  },
  {
    name: "Xã Bình Hòa Trung",
    code: 27808,
    division_type: "xã",
    codename: "xa_binh_hoa_trung",
    district_code: 798,
  },
  {
    name: "Xã Bình Hòa Đông",
    code: 27811,
    division_type: "xã",
    codename: "xa_binh_hoa_dong",
    district_code: 798,
  },
  {
    name: "Thị trấn Bình Phong Thạnh",
    code: 27814,
    division_type: "thị trấn",
    codename: "thi_tran_binh_phong_thanh",
    district_code: 798,
  },
  {
    name: "Xã Tân Lập",
    code: 27820,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 798,
  },
  {
    name: "Xã Tân Thành",
    code: 27823,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 798,
  },
  {
    name: "Thị trấn Tân Thạnh",
    code: 27826,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 799,
  },
  {
    name: "Xã Bắc Hòa",
    code: 27829,
    division_type: "xã",
    codename: "xa_bac_hoa",
    district_code: 799,
  },
  {
    name: "Xã Hậu Thạnh Tây",
    code: 27832,
    division_type: "xã",
    codename: "xa_hau_thanh_tay",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Hòa Lập",
    code: 27835,
    division_type: "xã",
    codename: "xa_nhon_hoa_lap",
    district_code: 799,
  },
  {
    name: "Xã Tân Lập",
    code: 27838,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 799,
  },
  {
    name: "Xã Hậu Thạnh Đông",
    code: 27841,
    division_type: "xã",
    codename: "xa_hau_thanh_dong",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Hoà",
    code: 27844,
    division_type: "xã",
    codename: "xa_nhon_hoa",
    district_code: 799,
  },
  {
    name: "Xã Kiến Bình",
    code: 27847,
    division_type: "xã",
    codename: "xa_kien_binh",
    district_code: 799,
  },
  {
    name: "Xã Tân Thành",
    code: 27850,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 799,
  },
  {
    name: "Xã Tân Bình",
    code: 27853,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 799,
  },
  {
    name: "Xã Tân Ninh",
    code: 27856,
    division_type: "xã",
    codename: "xa_tan_ninh",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Ninh",
    code: 27859,
    division_type: "xã",
    codename: "xa_nhon_ninh",
    district_code: 799,
  },
  {
    name: "Xã Tân Hòa",
    code: 27862,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 799,
  },
  {
    name: "Thị trấn Thạnh Hóa",
    code: 27865,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_hoa",
    district_code: 800,
  },
  {
    name: "Xã Tân Hiệp",
    code: 27868,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 800,
  },
  {
    name: "Xã Thuận Bình",
    code: 27871,
    division_type: "xã",
    codename: "xa_thuan_binh",
    district_code: 800,
  },
  {
    name: "Xã Thạnh Phước",
    code: 27874,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 800,
  },
  {
    name: "Xã Thạnh Phú",
    code: 27877,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 800,
  },
  {
    name: "Xã Thuận Nghĩa Hòa",
    code: 27880,
    division_type: "xã",
    codename: "xa_thuan_nghia_hoa",
    district_code: 800,
  },
  {
    name: "Xã Thủy Đông",
    code: 27883,
    division_type: "xã",
    codename: "xa_thuy_dong",
    district_code: 800,
  },
  {
    name: "Xã Thủy Tây",
    code: 27886,
    division_type: "xã",
    codename: "xa_thuy_tay",
    district_code: 800,
  },
  {
    name: "Xã Tân Tây",
    code: 27889,
    division_type: "xã",
    codename: "xa_tan_tay",
    district_code: 800,
  },
  {
    name: "Xã Tân Đông",
    code: 27892,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 800,
  },
  {
    name: "Xã Thạnh An",
    code: 27895,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 800,
  },
  {
    name: "Thị trấn Đông Thành",
    code: 27898,
    division_type: "thị trấn",
    codename: "thi_tran_dong_thanh",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Quý Đông",
    code: 27901,
    division_type: "xã",
    codename: "xa_my_quy_dong",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Bắc",
    code: 27904,
    division_type: "xã",
    codename: "xa_my_thanh_bac",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Quý Tây",
    code: 27907,
    division_type: "xã",
    codename: "xa_my_quy_tay",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Tây",
    code: 27910,
    division_type: "xã",
    codename: "xa_my_thanh_tay",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Đông",
    code: 27913,
    division_type: "xã",
    codename: "xa_my_thanh_dong",
    district_code: 801,
  },
  {
    name: "Xã Bình Thành",
    code: 27916,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Bắc",
    code: 27919,
    division_type: "xã",
    codename: "xa_binh_hoa_bac",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Hưng",
    code: 27922,
    division_type: "xã",
    codename: "xa_binh_hoa_hung",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Nam",
    code: 27925,
    division_type: "xã",
    codename: "xa_binh_hoa_nam",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Bình",
    code: 27928,
    division_type: "xã",
    codename: "xa_my_binh",
    district_code: 801,
  },
  {
    name: "Thị trấn Hậu Nghĩa",
    code: 27931,
    division_type: "thị trấn",
    codename: "thi_tran_hau_nghia",
    district_code: 802,
  },
  {
    name: "Thị trấn Hiệp Hòa",
    code: 27934,
    division_type: "thị trấn",
    codename: "thi_tran_hiep_hoa",
    district_code: 802,
  },
  {
    name: "Thị trấn Đức Hòa",
    code: 27937,
    division_type: "thị trấn",
    codename: "thi_tran_duc_hoa",
    district_code: 802,
  },
  {
    name: "Xã Lộc Giang",
    code: 27940,
    division_type: "xã",
    codename: "xa_loc_giang",
    district_code: 802,
  },
  {
    name: "Xã An Ninh Đông",
    code: 27943,
    division_type: "xã",
    codename: "xa_an_ninh_dong",
    district_code: 802,
  },
  {
    name: "Xã An Ninh Tây",
    code: 27946,
    division_type: "xã",
    codename: "xa_an_ninh_tay",
    district_code: 802,
  },
  {
    name: "Xã Tân Mỹ",
    code: 27949,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 802,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 27952,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 802,
  },
  {
    name: "Xã Đức Lập Thượng",
    code: 27955,
    division_type: "xã",
    codename: "xa_duc_lap_thuong",
    district_code: 802,
  },
  {
    name: "Xã Đức Lập Hạ",
    code: 27958,
    division_type: "xã",
    codename: "xa_duc_lap_ha",
    district_code: 802,
  },
  {
    name: "Xã Tân Phú",
    code: 27961,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 802,
  },
  {
    name: "Xã Mỹ Hạnh Bắc",
    code: 27964,
    division_type: "xã",
    codename: "xa_my_hanh_bac",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Thượng",
    code: 27967,
    division_type: "xã",
    codename: "xa_duc_hoa_thuong",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Tây",
    code: 27970,
    division_type: "xã",
    codename: "xa_hoa_khanh_tay",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Đông",
    code: 27973,
    division_type: "xã",
    codename: "xa_hoa_khanh_dong",
    district_code: 802,
  },
  {
    name: "Xã Mỹ Hạnh Nam",
    code: 27976,
    division_type: "xã",
    codename: "xa_my_hanh_nam",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Nam",
    code: 27979,
    division_type: "xã",
    codename: "xa_hoa_khanh_nam",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Đông",
    code: 27982,
    division_type: "xã",
    codename: "xa_duc_hoa_dong",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Hạ",
    code: 27985,
    division_type: "xã",
    codename: "xa_duc_hoa_ha",
    district_code: 802,
  },
  {
    name: "Xã Hựu Thạnh",
    code: 27988,
    division_type: "xã",
    codename: "xa_huu_thanh",
    district_code: 802,
  },
  {
    name: "Thị trấn Bến Lức",
    code: 27991,
    division_type: "thị trấn",
    codename: "thi_tran_ben_luc",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 27994,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 803,
  },
  {
    name: "Xã Lương Bình",
    code: 27997,
    division_type: "xã",
    codename: "xa_luong_binh",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 28000,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 803,
  },
  {
    name: "Xã Lương Hòa",
    code: 28003,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 803,
  },
  {
    name: "Xã Tân Hòa",
    code: 28006,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 803,
  },
  {
    name: "Xã Tân Bửu",
    code: 28009,
    division_type: "xã",
    codename: "xa_tan_buu",
    district_code: 803,
  },
  {
    name: "Xã An Thạnh",
    code: 28012,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 803,
  },
  {
    name: "Xã Bình Đức",
    code: 28015,
    division_type: "xã",
    codename: "xa_binh_duc",
    district_code: 803,
  },
  {
    name: "Xã Mỹ Yên",
    code: 28018,
    division_type: "xã",
    codename: "xa_my_yen",
    district_code: 803,
  },
  {
    name: "Xã Thanh Phú",
    code: 28021,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 803,
  },
  {
    name: "Xã Long Hiệp",
    code: 28024,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Đức",
    code: 28027,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 803,
  },
  {
    name: "Xã Phước Lợi",
    code: 28030,
    division_type: "xã",
    codename: "xa_phuoc_loi",
    district_code: 803,
  },
  {
    name: "Xã Nhựt Chánh",
    code: 28033,
    division_type: "xã",
    codename: "xa_nhut_chanh",
    district_code: 803,
  },
  {
    name: "Thị trấn Thủ Thừa",
    code: 28036,
    division_type: "thị trấn",
    codename: "thi_tran_thu_thua",
    district_code: 804,
  },
  {
    name: "Xã Long Thạnh",
    code: 28039,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 804,
  },
  {
    name: "Xã Tân Thành",
    code: 28042,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 804,
  },
  {
    name: "Xã Long Thuận",
    code: 28045,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Lạc",
    code: 28048,
    division_type: "xã",
    codename: "xa_my_lac",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 28051,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 804,
  },
  {
    name: "Xã Bình An",
    code: 28054,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 804,
  },
  {
    name: "Xã Nhị Thành",
    code: 28057,
    division_type: "xã",
    codename: "xa_nhi_thanh",
    district_code: 804,
  },
  {
    name: "Xã Mỹ An",
    code: 28060,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 804,
  },
  {
    name: "Xã Bình Thạnh",
    code: 28063,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Phú",
    code: 28066,
    division_type: "xã",
    codename: "xa_my_phu",
    district_code: 804,
  },
  {
    name: "Xã Tân Long",
    code: 28072,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 804,
  },
  {
    name: "Thị trấn Tân Trụ",
    code: 28075,
    division_type: "thị trấn",
    codename: "thi_tran_tan_tru",
    district_code: 805,
  },
  {
    name: "Xã Tân Bình",
    code: 28078,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 805,
  },
  {
    name: "Xã Quê Mỹ Thạnh",
    code: 28084,
    division_type: "xã",
    codename: "xa_que_my_thanh",
    district_code: 805,
  },
  {
    name: "Xã Lạc Tấn",
    code: 28087,
    division_type: "xã",
    codename: "xa_lac_tan",
    district_code: 805,
  },
  {
    name: "Xã Bình Trinh Đông",
    code: 28090,
    division_type: "xã",
    codename: "xa_binh_trinh_dong",
    district_code: 805,
  },
  {
    name: "Xã Tân Phước Tây",
    code: 28093,
    division_type: "xã",
    codename: "xa_tan_phuoc_tay",
    district_code: 805,
  },
  {
    name: "Xã Bình Lãng",
    code: 28096,
    division_type: "xã",
    codename: "xa_binh_lang",
    district_code: 805,
  },
  {
    name: "Xã Bình Tịnh",
    code: 28099,
    division_type: "xã",
    codename: "xa_binh_tinh",
    district_code: 805,
  },
  {
    name: "Xã Đức Tân",
    code: 28102,
    division_type: "xã",
    codename: "xa_duc_tan",
    district_code: 805,
  },
  {
    name: "Xã Nhựt Ninh",
    code: 28105,
    division_type: "xã",
    codename: "xa_nhut_ninh",
    district_code: 805,
  },
  {
    name: "Thị trấn Cần Đước",
    code: 28108,
    division_type: "thị trấn",
    codename: "thi_tran_can_duoc",
    district_code: 806,
  },
  {
    name: "Xã Long Trạch",
    code: 28111,
    division_type: "xã",
    codename: "xa_long_trach",
    district_code: 806,
  },
  {
    name: "Xã Long Khê",
    code: 28114,
    division_type: "xã",
    codename: "xa_long_khe",
    district_code: 806,
  },
  {
    name: "Xã Long Định",
    code: 28117,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 806,
  },
  {
    name: "Xã Phước Vân",
    code: 28120,
    division_type: "xã",
    codename: "xa_phuoc_van",
    district_code: 806,
  },
  {
    name: "Xã Long Hòa",
    code: 28123,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 806,
  },
  {
    name: "Xã Long Cang",
    code: 28126,
    division_type: "xã",
    codename: "xa_long_cang",
    district_code: 806,
  },
  {
    name: "Xã Long Sơn",
    code: 28129,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 806,
  },
  {
    name: "Xã Tân Trạch",
    code: 28132,
    division_type: "xã",
    codename: "xa_tan_trach",
    district_code: 806,
  },
  {
    name: "Xã Mỹ Lệ",
    code: 28135,
    division_type: "xã",
    codename: "xa_my_le",
    district_code: 806,
  },
  {
    name: "Xã Tân Lân",
    code: 28138,
    division_type: "xã",
    codename: "xa_tan_lan",
    district_code: 806,
  },
  {
    name: "Xã Phước Tuy",
    code: 28141,
    division_type: "xã",
    codename: "xa_phuoc_tuy",
    district_code: 806,
  },
  {
    name: "Xã Long Hựu Đông",
    code: 28144,
    division_type: "xã",
    codename: "xa_long_huu_dong",
    district_code: 806,
  },
  {
    name: "Xã Tân Ân",
    code: 28147,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 806,
  },
  {
    name: "Xã Phước Đông",
    code: 28150,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 806,
  },
  {
    name: "Xã Long Hựu Tây",
    code: 28153,
    division_type: "xã",
    codename: "xa_long_huu_tay",
    district_code: 806,
  },
  {
    name: "Xã Tân Chánh",
    code: 28156,
    division_type: "xã",
    codename: "xa_tan_chanh",
    district_code: 806,
  },
  {
    name: "Thị trấn Cần Giuộc",
    code: 28159,
    division_type: "thị trấn",
    codename: "thi_tran_can_giuoc",
    district_code: 807,
  },
  {
    name: "Xã Phước Lý",
    code: 28162,
    division_type: "xã",
    codename: "xa_phuoc_ly",
    district_code: 807,
  },
  {
    name: "Xã Long Thượng",
    code: 28165,
    division_type: "xã",
    codename: "xa_long_thuong",
    district_code: 807,
  },
  {
    name: "Xã Long Hậu",
    code: 28168,
    division_type: "xã",
    codename: "xa_long_hau",
    district_code: 807,
  },
  {
    name: "Xã Phước Hậu",
    code: 28174,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 807,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 28177,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 807,
  },
  {
    name: "Xã Phước Lại",
    code: 28180,
    division_type: "xã",
    codename: "xa_phuoc_lai",
    district_code: 807,
  },
  {
    name: "Xã Phước Lâm",
    code: 28183,
    division_type: "xã",
    codename: "xa_phuoc_lam",
    district_code: 807,
  },
  {
    name: "Xã Thuận Thành",
    code: 28189,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 807,
  },
  {
    name: "Xã Phước Vĩnh Tây",
    code: 28192,
    division_type: "xã",
    codename: "xa_phuoc_vinh_tay",
    district_code: 807,
  },
  {
    name: "Xã Phước Vĩnh Đông",
    code: 28195,
    division_type: "xã",
    codename: "xa_phuoc_vinh_dong",
    district_code: 807,
  },
  {
    name: "Xã Long An",
    code: 28198,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 807,
  },
  {
    name: "Xã Long Phụng",
    code: 28201,
    division_type: "xã",
    codename: "xa_long_phung",
    district_code: 807,
  },
  {
    name: "Xã Đông Thạnh",
    code: 28204,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 807,
  },
  {
    name: "Xã Tân Tập",
    code: 28207,
    division_type: "xã",
    codename: "xa_tan_tap",
    district_code: 807,
  },
  {
    name: "Thị trấn Tầm Vu",
    code: 28210,
    division_type: "thị trấn",
    codename: "thi_tran_tam_vu",
    district_code: 808,
  },
  {
    name: "Xã Bình Quới",
    code: 28213,
    division_type: "xã",
    codename: "xa_binh_quoi",
    district_code: 808,
  },
  {
    name: "Xã Hòa Phú",
    code: 28216,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 808,
  },
  {
    name: "Xã Phú Ngãi Trị",
    code: 28219,
    division_type: "xã",
    codename: "xa_phu_ngai_tri",
    district_code: 808,
  },
  {
    name: "Xã Vĩnh Công",
    code: 28222,
    division_type: "xã",
    codename: "xa_vinh_cong",
    district_code: 808,
  },
  {
    name: "Xã Thuận Mỹ",
    code: 28225,
    division_type: "xã",
    codename: "xa_thuan_my",
    district_code: 808,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 28228,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 808,
  },
  {
    name: "Xã Phước Tân Hưng",
    code: 28231,
    division_type: "xã",
    codename: "xa_phuoc_tan_hung",
    district_code: 808,
  },
  {
    name: "Xã Thanh Phú Long",
    code: 28234,
    division_type: "xã",
    codename: "xa_thanh_phu_long",
    district_code: 808,
  },
  {
    name: "Xã Dương Xuân Hội",
    code: 28237,
    division_type: "xã",
    codename: "xa_duong_xuan_hoi",
    district_code: 808,
  },
  {
    name: "Xã An Lục Long",
    code: 28240,
    division_type: "xã",
    codename: "xa_an_luc_long",
    district_code: 808,
  },
  {
    name: "Xã Long Trì",
    code: 28243,
    division_type: "xã",
    codename: "xa_long_tri",
    district_code: 808,
  },
  {
    name: "Xã Thanh Vĩnh Đông",
    code: 28246,
    division_type: "xã",
    codename: "xa_thanh_vinh_dong",
    district_code: 808,
  },
  {
    name: "Phường 5",
    code: 28249,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 815,
  },
  {
    name: "Phường 4",
    code: 28252,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 815,
  },
  {
    name: "Phường 7",
    code: 28255,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 815,
  },
  {
    name: "Phường 3",
    code: 28258,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 815,
  },
  {
    name: "Phường 1",
    code: 28261,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 815,
  },
  {
    name: "Phường 2",
    code: 28264,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 815,
  },
  {
    name: "Phường 8",
    code: 28267,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 815,
  },
  {
    name: "Phường 6",
    code: 28270,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 815,
  },
  {
    name: "Phường 9",
    code: 28273,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 815,
  },
  {
    name: "Phường 10",
    code: 28276,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 815,
  },
  {
    name: "Phường Tân Long",
    code: 28279,
    division_type: "phường",
    codename: "phuong_tan_long",
    district_code: 815,
  },
  {
    name: "Xã Đạo Thạnh",
    code: 28282,
    division_type: "xã",
    codename: "xa_dao_thanh",
    district_code: 815,
  },
  {
    name: "Xã Trung An",
    code: 28285,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 815,
  },
  {
    name: "Xã Mỹ Phong",
    code: 28288,
    division_type: "xã",
    codename: "xa_my_phong",
    district_code: 815,
  },
  {
    name: "Xã Tân Mỹ Chánh",
    code: 28291,
    division_type: "xã",
    codename: "xa_tan_my_chanh",
    district_code: 815,
  },
  {
    name: "Xã Phước Thạnh",
    code: 28567,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 815,
  },
  {
    name: "Xã Thới Sơn",
    code: 28591,
    division_type: "xã",
    codename: "xa_thoi_son",
    district_code: 815,
  },
  {
    name: "Phường 3",
    code: 28294,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 816,
  },
  {
    name: "Phường 2",
    code: 28297,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 816,
  },
  {
    name: "Phường 4",
    code: 28300,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 816,
  },
  {
    name: "Phường 1",
    code: 28303,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 816,
  },
  {
    name: "Phường 5",
    code: 28306,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 816,
  },
  {
    name: "Xã Long Hưng",
    code: 28309,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 816,
  },
  {
    name: "Xã Long Thuận",
    code: 28312,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 816,
  },
  {
    name: "Xã Long Chánh",
    code: 28315,
    division_type: "xã",
    codename: "xa_long_chanh",
    district_code: 816,
  },
  {
    name: "Xã Long Hòa",
    code: 28318,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 816,
  },
  {
    name: "Xã Bình Đông",
    code: 28708,
    division_type: "xã",
    codename: "xa_binh_dong",
    district_code: 816,
  },
  {
    name: "Xã Bình Xuân",
    code: 28717,
    division_type: "xã",
    codename: "xa_binh_xuan",
    district_code: 816,
  },
  {
    name: "Xã Tân Trung",
    code: 28729,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 816,
  },
  {
    name: "Phường 1",
    code: 28435,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 817,
  },
  {
    name: "Phường 2",
    code: 28436,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 817,
  },
  {
    name: "Phường 3",
    code: 28437,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 817,
  },
  {
    name: "Phường 4",
    code: 28439,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 817,
  },
  {
    name: "Phường 5",
    code: 28440,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Phước Tây",
    code: 28447,
    division_type: "xã",
    codename: "xa_my_phuoc_tay",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Hạnh Đông",
    code: 28450,
    division_type: "xã",
    codename: "xa_my_hanh_dong",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Hạnh Trung",
    code: 28453,
    division_type: "xã",
    codename: "xa_my_hanh_trung",
    district_code: 817,
  },
  {
    name: "Xã Tân Phú",
    code: 28459,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 817,
  },
  {
    name: "Xã Tân Bình",
    code: 28462,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 817,
  },
  {
    name: "Xã Tân Hội",
    code: 28468,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 817,
  },
  {
    name: "Phường Nhị Mỹ",
    code: 28474,
    division_type: "phường",
    codename: "phuong_nhi_my",
    district_code: 817,
  },
  {
    name: "Xã Nhị Quý",
    code: 28477,
    division_type: "xã",
    codename: "xa_nhi_quy",
    district_code: 817,
  },
  {
    name: "Xã Thanh Hòa",
    code: 28480,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 817,
  },
  {
    name: "Xã Phú Quý",
    code: 28483,
    division_type: "xã",
    codename: "xa_phu_quy",
    district_code: 817,
  },
  {
    name: "Xã Long Khánh",
    code: 28486,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 817,
  },
  {
    name: "Thị trấn Mỹ Phước",
    code: 28321,
    division_type: "thị trấn",
    codename: "thi_tran_my_phuoc",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Đông",
    code: 28324,
    division_type: "xã",
    codename: "xa_tan_hoa_dong",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Tân",
    code: 28327,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Mỹ",
    code: 28330,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Hoà",
    code: 28333,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 818,
  },
  {
    name: "Xã Phú Mỹ",
    code: 28336,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Thành",
    code: 28339,
    division_type: "xã",
    codename: "xa_tan_hoa_thanh",
    district_code: 818,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 28342,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 818,
  },
  {
    name: "Xã Tân Lập 1",
    code: 28345,
    division_type: "xã",
    codename: "xa_tan_lap_1",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Tây",
    code: 28348,
    division_type: "xã",
    codename: "xa_tan_hoa_tay",
    district_code: 818,
  },
  {
    name: "Xã Tân Lập 2",
    code: 28354,
    division_type: "xã",
    codename: "xa_tan_lap_2",
    district_code: 818,
  },
  {
    name: "Xã Phước Lập",
    code: 28357,
    division_type: "xã",
    codename: "xa_phuoc_lap",
    district_code: 818,
  },
  {
    name: "Thị trấn Cái Bè",
    code: 28360,
    division_type: "thị trấn",
    codename: "thi_tran_cai_be",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Bắc B",
    code: 28363,
    division_type: "xã",
    codename: "xa_hau_my_bac_b",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Bắc A",
    code: 28366,
    division_type: "xã",
    codename: "xa_hau_my_bac_a",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Trung",
    code: 28369,
    division_type: "xã",
    codename: "xa_my_trung",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Trinh",
    code: 28372,
    division_type: "xã",
    codename: "xa_hau_my_trinh",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Phú",
    code: 28375,
    division_type: "xã",
    codename: "xa_hau_my_phu",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Tân",
    code: 28378,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lợi B",
    code: 28381,
    division_type: "xã",
    codename: "xa_my_loi_b",
    district_code: 819,
  },
  {
    name: "Xã Thiện Trung",
    code: 28384,
    division_type: "xã",
    codename: "xa_thien_trung",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Hội",
    code: 28387,
    division_type: "xã",
    codename: "xa_my_hoi",
    district_code: 819,
  },
  {
    name: "Xã An Cư",
    code: 28390,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 819,
  },
  {
    name: "Xã Hậu Thành",
    code: 28393,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lợi A",
    code: 28396,
    division_type: "xã",
    codename: "xa_my_loi_a",
    district_code: 819,
  },
  {
    name: "Xã Hòa Khánh",
    code: 28399,
    division_type: "xã",
    codename: "xa_hoa_khanh",
    district_code: 819,
  },
  {
    name: "Xã Thiện Trí",
    code: 28402,
    division_type: "xã",
    codename: "xa_thien_tri",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Đức Đông",
    code: 28405,
    division_type: "xã",
    codename: "xa_my_duc_dong",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Đức Tây",
    code: 28408,
    division_type: "xã",
    codename: "xa_my_duc_tay",
    district_code: 819,
  },
  {
    name: "Xã Đông Hòa Hiệp",
    code: 28411,
    division_type: "xã",
    codename: "xa_dong_hoa_hiep",
    district_code: 819,
  },
  {
    name: "Xã An Thái Đông",
    code: 28414,
    division_type: "xã",
    codename: "xa_an_thai_dong",
    district_code: 819,
  },
  {
    name: "Xã Tân Hưng",
    code: 28417,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lương",
    code: 28420,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 819,
  },
  {
    name: "Xã Tân Thanh",
    code: 28423,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 819,
  },
  {
    name: "Xã An Thái Trung",
    code: 28426,
    division_type: "xã",
    codename: "xa_an_thai_trung",
    district_code: 819,
  },
  {
    name: "Xã An Hữu",
    code: 28429,
    division_type: "xã",
    codename: "xa_an_huu",
    district_code: 819,
  },
  {
    name: "Xã Hòa Hưng",
    code: 28432,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 819,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 28438,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Thành Bắc",
    code: 28441,
    division_type: "xã",
    codename: "xa_my_thanh_bac",
    district_code: 820,
  },
  {
    name: "Xã Phú Cường",
    code: 28444,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Thành Nam",
    code: 28456,
    division_type: "xã",
    codename: "xa_my_thanh_nam",
    district_code: 820,
  },
  {
    name: "Xã Phú Nhuận",
    code: 28465,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 820,
  },
  {
    name: "Xã Bình Phú",
    code: 28471,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 820,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 28489,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 820,
  },
  {
    name: "Xã Phú An",
    code: 28492,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Long",
    code: 28495,
    division_type: "xã",
    codename: "xa_my_long",
    district_code: 820,
  },
  {
    name: "Xã Long Tiên",
    code: 28498,
    division_type: "xã",
    codename: "xa_long_tien",
    district_code: 820,
  },
  {
    name: "Xã Hiệp Đức",
    code: 28501,
    division_type: "xã",
    codename: "xa_hiep_duc",
    district_code: 820,
  },
  {
    name: "Xã Long Trung",
    code: 28504,
    division_type: "xã",
    codename: "xa_long_trung",
    district_code: 820,
  },
  {
    name: "Xã Hội Xuân",
    code: 28507,
    division_type: "xã",
    codename: "xa_hoi_xuan",
    district_code: 820,
  },
  {
    name: "Xã Tân Phong",
    code: 28510,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 820,
  },
  {
    name: "Xã Tam Bình",
    code: 28513,
    division_type: "xã",
    codename: "xa_tam_binh",
    district_code: 820,
  },
  {
    name: "Xã Ngũ Hiệp",
    code: 28516,
    division_type: "xã",
    codename: "xa_ngu_hiep",
    district_code: 820,
  },
  {
    name: "Thị trấn Tân Hiệp",
    code: 28519,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hiep",
    district_code: 821,
  },
  {
    name: "Xã Tân Hội Đông",
    code: 28522,
    division_type: "xã",
    codename: "xa_tan_hoi_dong",
    district_code: 821,
  },
  {
    name: "Xã Tân Hương",
    code: 28525,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 821,
  },
  {
    name: "Xã Tân Lý Đông",
    code: 28528,
    division_type: "xã",
    codename: "xa_tan_ly_dong",
    district_code: 821,
  },
  {
    name: "Xã Tân Lý Tây",
    code: 28531,
    division_type: "xã",
    codename: "xa_tan_ly_tay",
    district_code: 821,
  },
  {
    name: "Xã Thân Cửu Nghĩa",
    code: 28534,
    division_type: "xã",
    codename: "xa_than_cuu_nghia",
    district_code: 821,
  },
  {
    name: "Xã Tam Hiệp",
    code: 28537,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 821,
  },
  {
    name: "Xã Điềm Hy",
    code: 28540,
    division_type: "xã",
    codename: "xa_diem_hy",
    district_code: 821,
  },
  {
    name: "Xã Nhị Bình",
    code: 28543,
    division_type: "xã",
    codename: "xa_nhi_binh",
    district_code: 821,
  },
  {
    name: "Xã Dưỡng Điềm",
    code: 28546,
    division_type: "xã",
    codename: "xa_duong_diem",
    district_code: 821,
  },
  {
    name: "Xã Đông Hòa",
    code: 28549,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 821,
  },
  {
    name: "Xã Long Định",
    code: 28552,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 821,
  },
  {
    name: "Xã Hữu Đạo",
    code: 28555,
    division_type: "xã",
    codename: "xa_huu_dao",
    district_code: 821,
  },
  {
    name: "Xã Long An",
    code: 28558,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 821,
  },
  {
    name: "Xã Long Hưng",
    code: 28561,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 821,
  },
  {
    name: "Xã Bình Trưng",
    code: 28564,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 821,
  },
  {
    name: "Xã Thạnh Phú",
    code: 28570,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 821,
  },
  {
    name: "Xã Bàn Long",
    code: 28573,
    division_type: "xã",
    codename: "xa_ban_long",
    district_code: 821,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 28576,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 821,
  },
  {
    name: "Xã Bình Đức",
    code: 28579,
    division_type: "xã",
    codename: "xa_binh_duc",
    district_code: 821,
  },
  {
    name: "Xã Song Thuận",
    code: 28582,
    division_type: "xã",
    codename: "xa_song_thuan",
    district_code: 821,
  },
  {
    name: "Xã Kim Sơn",
    code: 28585,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 821,
  },
  {
    name: "Xã Phú Phong",
    code: 28588,
    division_type: "xã",
    codename: "xa_phu_phong",
    district_code: 821,
  },
  {
    name: "Thị trấn Chợ Gạo",
    code: 28594,
    division_type: "thị trấn",
    codename: "thi_tran_cho_gao",
    district_code: 822,
  },
  {
    name: "Xã Trung Hòa",
    code: 28597,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 822,
  },
  {
    name: "Xã Hòa Tịnh",
    code: 28600,
    division_type: "xã",
    codename: "xa_hoa_tinh",
    district_code: 822,
  },
  {
    name: "Xã Mỹ Tịnh An",
    code: 28603,
    division_type: "xã",
    codename: "xa_my_tinh_an",
    district_code: 822,
  },
  {
    name: "Xã Tân Bình Thạnh",
    code: 28606,
    division_type: "xã",
    codename: "xa_tan_binh_thanh",
    district_code: 822,
  },
  {
    name: "Xã Phú Kiết",
    code: 28609,
    division_type: "xã",
    codename: "xa_phu_kiet",
    district_code: 822,
  },
  {
    name: "Xã Lương Hòa Lạc",
    code: 28612,
    division_type: "xã",
    codename: "xa_luong_hoa_lac",
    district_code: 822,
  },
  {
    name: "Xã Thanh Bình",
    code: 28615,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 822,
  },
  {
    name: "Xã Quơn Long",
    code: 28618,
    division_type: "xã",
    codename: "xa_quon_long",
    district_code: 822,
  },
  {
    name: "Xã Bình Phục Nhứt",
    code: 28621,
    division_type: "xã",
    codename: "xa_binh_phuc_nhut",
    district_code: 822,
  },
  {
    name: "Xã Đăng Hưng Phước",
    code: 28624,
    division_type: "xã",
    codename: "xa_dang_hung_phuoc",
    district_code: 822,
  },
  {
    name: "Xã Tân Thuận Bình",
    code: 28627,
    division_type: "xã",
    codename: "xa_tan_thuan_binh",
    district_code: 822,
  },
  {
    name: "Xã Song Bình",
    code: 28630,
    division_type: "xã",
    codename: "xa_song_binh",
    district_code: 822,
  },
  {
    name: "Xã Bình Phan",
    code: 28633,
    division_type: "xã",
    codename: "xa_binh_phan",
    district_code: 822,
  },
  {
    name: "Xã Long Bình Điền",
    code: 28636,
    division_type: "xã",
    codename: "xa_long_binh_dien",
    district_code: 822,
  },
  {
    name: "Xã An Thạnh Thủy",
    code: 28639,
    division_type: "xã",
    codename: "xa_an_thanh_thuy",
    district_code: 822,
  },
  {
    name: "Xã Xuân Đông",
    code: 28642,
    division_type: "xã",
    codename: "xa_xuan_dong",
    district_code: 822,
  },
  {
    name: "Xã Hòa Định",
    code: 28645,
    division_type: "xã",
    codename: "xa_hoa_dinh",
    district_code: 822,
  },
  {
    name: "Xã Bình Ninh",
    code: 28648,
    division_type: "xã",
    codename: "xa_binh_ninh",
    district_code: 822,
  },
  {
    name: "Thị trấn Vĩnh Bình",
    code: 28651,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_binh",
    district_code: 823,
  },
  {
    name: "Xã Đồng Sơn",
    code: 28654,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 823,
  },
  {
    name: "Xã Bình Phú",
    code: 28657,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 823,
  },
  {
    name: "Xã Đồng Thạnh",
    code: 28660,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 823,
  },
  {
    name: "Xã Thành Công",
    code: 28663,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 823,
  },
  {
    name: "Xã Bình Nhì",
    code: 28666,
    division_type: "xã",
    codename: "xa_binh_nhi",
    district_code: 823,
  },
  {
    name: "Xã Yên Luông",
    code: 28669,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 823,
  },
  {
    name: "Xã Thạnh Trị",
    code: 28672,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 823,
  },
  {
    name: "Xã Thạnh Nhựt",
    code: 28675,
    division_type: "xã",
    codename: "xa_thanh_nhut",
    district_code: 823,
  },
  {
    name: "Xã Long Vĩnh",
    code: 28678,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 823,
  },
  {
    name: "Xã Bình Tân",
    code: 28681,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 823,
  },
  {
    name: "Xã Vĩnh Hựu",
    code: 28684,
    division_type: "xã",
    codename: "xa_vinh_huu",
    district_code: 823,
  },
  {
    name: "Xã Long Bình",
    code: 28687,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 823,
  },
  {
    name: "Thị trấn Tân Hòa",
    code: 28702,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hoa",
    district_code: 824,
  },
  {
    name: "Xã Tăng Hoà",
    code: 28705,
    division_type: "xã",
    codename: "xa_tang_hoa",
    district_code: 824,
  },
  {
    name: "Xã Tân Phước",
    code: 28711,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 824,
  },
  {
    name: "Xã Gia Thuận",
    code: 28714,
    division_type: "xã",
    codename: "xa_gia_thuan",
    district_code: 824,
  },
  {
    name: "Thị trấn Vàm Láng",
    code: 28720,
    division_type: "thị trấn",
    codename: "thi_tran_vam_lang",
    district_code: 824,
  },
  {
    name: "Xã Tân Tây",
    code: 28723,
    division_type: "xã",
    codename: "xa_tan_tay",
    district_code: 824,
  },
  {
    name: "Xã Kiểng Phước",
    code: 28726,
    division_type: "xã",
    codename: "xa_kieng_phuoc",
    district_code: 824,
  },
  {
    name: "Xã Tân Đông",
    code: 28732,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 824,
  },
  {
    name: "Xã Bình Ân",
    code: 28735,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 824,
  },
  {
    name: "Xã Tân Điền",
    code: 28738,
    division_type: "xã",
    codename: "xa_tan_dien",
    district_code: 824,
  },
  {
    name: "Xã Bình Nghị",
    code: 28741,
    division_type: "xã",
    codename: "xa_binh_nghi",
    district_code: 824,
  },
  {
    name: "Xã Phước Trung",
    code: 28744,
    division_type: "xã",
    codename: "xa_phuoc_trung",
    district_code: 824,
  },
  {
    name: "Xã Tân Thành",
    code: 28747,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 824,
  },
  {
    name: "Xã Tân Thới",
    code: 28690,
    division_type: "xã",
    codename: "xa_tan_thoi",
    district_code: 825,
  },
  {
    name: "Xã Tân Phú",
    code: 28693,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 825,
  },
  {
    name: "Xã Phú Thạnh",
    code: 28696,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 825,
  },
  {
    name: "Xã Tân Thạnh",
    code: 28699,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 825,
  },
  {
    name: "Xã Phú Đông",
    code: 28750,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 825,
  },
  {
    name: "Xã Phú Tân",
    code: 28753,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 825,
  },
  {
    name: "Phường Phú Khương",
    code: 28756,
    division_type: "phường",
    codename: "phuong_phu_khuong",
    district_code: 829,
  },
  {
    name: "Phường Phú Tân",
    code: 28757,
    division_type: "phường",
    codename: "phuong_phu_tan",
    district_code: 829,
  },
  {
    name: "Phường 8",
    code: 28759,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 829,
  },
  {
    name: "Phường 6",
    code: 28762,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 829,
  },
  {
    name: "Phường 4",
    code: 28765,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 829,
  },
  {
    name: "Phường 5",
    code: 28768,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 829,
  },
  {
    name: "Phường An Hội",
    code: 28777,
    division_type: "phường",
    codename: "phuong_an_hoi",
    district_code: 829,
  },
  {
    name: "Phường 7",
    code: 28780,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 829,
  },
  {
    name: "Xã Sơn Đông",
    code: 28783,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 829,
  },
  {
    name: "Xã Phú Hưng",
    code: 28786,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 829,
  },
  {
    name: "Xã Bình Phú",
    code: 28789,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 829,
  },
  {
    name: "Xã Mỹ Thạnh An",
    code: 28792,
    division_type: "xã",
    codename: "xa_my_thanh_an",
    district_code: 829,
  },
  {
    name: "Xã Nhơn Thạnh",
    code: 28795,
    division_type: "xã",
    codename: "xa_nhon_thanh",
    district_code: 829,
  },
  {
    name: "Xã Phú Nhuận",
    code: 28798,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 829,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 28801,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 831,
  },
  {
    name: "Xã Tân Thạch",
    code: 28804,
    division_type: "xã",
    codename: "xa_tan_thach",
    district_code: 831,
  },
  {
    name: "Xã Qưới Sơn",
    code: 28807,
    division_type: "xã",
    codename: "xa_quoi_son",
    district_code: 831,
  },
  {
    name: "Xã An Khánh",
    code: 28810,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 831,
  },
  {
    name: "Xã Giao Long",
    code: 28813,
    division_type: "xã",
    codename: "xa_giao_long",
    district_code: 831,
  },
  {
    name: "Xã Phú Túc",
    code: 28819,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 831,
  },
  {
    name: "Xã Phú Đức",
    code: 28822,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 831,
  },
  {
    name: "Xã Phú An Hòa",
    code: 28825,
    division_type: "xã",
    codename: "xa_phu_an_hoa",
    district_code: 831,
  },
  {
    name: "Xã An Phước",
    code: 28828,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 831,
  },
  {
    name: "Xã Tam Phước",
    code: 28831,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 831,
  },
  {
    name: "Xã Thành Triệu",
    code: 28834,
    division_type: "xã",
    codename: "xa_thanh_trieu",
    district_code: 831,
  },
  {
    name: "Xã Tường Đa",
    code: 28837,
    division_type: "xã",
    codename: "xa_tuong_da",
    district_code: 831,
  },
  {
    name: "Xã Tân Phú",
    code: 28840,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 831,
  },
  {
    name: "Xã Quới Thành",
    code: 28843,
    division_type: "xã",
    codename: "xa_quoi_thanh",
    district_code: 831,
  },
  {
    name: "Xã Phước Thạnh",
    code: 28846,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 831,
  },
  {
    name: "Xã An Hóa",
    code: 28849,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 831,
  },
  {
    name: "Xã Tiên Long",
    code: 28852,
    division_type: "xã",
    codename: "xa_tien_long",
    district_code: 831,
  },
  {
    name: "Xã An Hiệp",
    code: 28855,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 831,
  },
  {
    name: "Xã Hữu Định",
    code: 28858,
    division_type: "xã",
    codename: "xa_huu_dinh",
    district_code: 831,
  },
  {
    name: "Xã Tiên Thủy",
    code: 28861,
    division_type: "xã",
    codename: "xa_tien_thuy",
    district_code: 831,
  },
  {
    name: "Xã Sơn Hòa",
    code: 28864,
    division_type: "xã",
    codename: "xa_son_hoa",
    district_code: 831,
  },
  {
    name: "Thị trấn Chợ Lách",
    code: 28870,
    division_type: "thị trấn",
    codename: "thi_tran_cho_lach",
    district_code: 832,
  },
  {
    name: "Xã Phú Phụng",
    code: 28873,
    division_type: "xã",
    codename: "xa_phu_phung",
    district_code: 832,
  },
  {
    name: "Xã Sơn Định",
    code: 28876,
    division_type: "xã",
    codename: "xa_son_dinh",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 28879,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 832,
  },
  {
    name: "Xã Hòa Nghĩa",
    code: 28882,
    division_type: "xã",
    codename: "xa_hoa_nghia",
    district_code: 832,
  },
  {
    name: "Xã Long Thới",
    code: 28885,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 832,
  },
  {
    name: "Xã Phú Sơn",
    code: 28888,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 832,
  },
  {
    name: "Xã Tân Thiềng",
    code: 28891,
    division_type: "xã",
    codename: "xa_tan_thieng",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 28894,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 28897,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 832,
  },
  {
    name: "Xã Hưng Khánh Trung B",
    code: 28900,
    division_type: "xã",
    codename: "xa_hung_khanh_trung_b",
    district_code: 832,
  },
  {
    name: "Thị trấn Mỏ Cày",
    code: 28903,
    division_type: "thị trấn",
    codename: "thi_tran_mo_cay",
    district_code: 833,
  },
  {
    name: "Xã Định Thủy",
    code: 28930,
    division_type: "xã",
    codename: "xa_dinh_thuy",
    district_code: 833,
  },
  {
    name: "Xã Đa Phước Hội",
    code: 28939,
    division_type: "xã",
    codename: "xa_da_phuoc_hoi",
    district_code: 833,
  },
  {
    name: "Xã Tân Hội",
    code: 28940,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 833,
  },
  {
    name: "Xã Phước Hiệp",
    code: 28942,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 833,
  },
  {
    name: "Xã Bình Khánh",
    code: 28945,
    division_type: "xã",
    codename: "xa_binh_khanh",
    district_code: 833,
  },
  {
    name: "Xã An Thạnh",
    code: 28951,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 833,
  },
  {
    name: "Xã An Định",
    code: 28957,
    division_type: "xã",
    codename: "xa_an_dinh",
    district_code: 833,
  },
  {
    name: "Xã Thành Thới B",
    code: 28960,
    division_type: "xã",
    codename: "xa_thanh_thoi_b",
    district_code: 833,
  },
  {
    name: "Xã Tân Trung",
    code: 28963,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 833,
  },
  {
    name: "Xã An Thới",
    code: 28966,
    division_type: "xã",
    codename: "xa_an_thoi",
    district_code: 833,
  },
  {
    name: "Xã Thành Thới A",
    code: 28969,
    division_type: "xã",
    codename: "xa_thanh_thoi_a",
    district_code: 833,
  },
  {
    name: "Xã Minh Đức",
    code: 28972,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 833,
  },
  {
    name: "Xã Ngãi Đăng",
    code: 28975,
    division_type: "xã",
    codename: "xa_ngai_dang",
    district_code: 833,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 28978,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 833,
  },
  {
    name: "Xã Hương Mỹ",
    code: 28981,
    division_type: "xã",
    codename: "xa_huong_my",
    district_code: 833,
  },
  {
    name: "Thị trấn Giồng Trôm",
    code: 28984,
    division_type: "thị trấn",
    codename: "thi_tran_giong_trom",
    district_code: 834,
  },
  {
    name: "Xã Phong Nẫm",
    code: 28987,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 834,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 28993,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 834,
  },
  {
    name: "Xã Châu Hòa",
    code: 28996,
    division_type: "xã",
    codename: "xa_chau_hoa",
    district_code: 834,
  },
  {
    name: "Xã Lương Hòa",
    code: 28999,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 834,
  },
  {
    name: "Xã Lương Quới",
    code: 29002,
    division_type: "xã",
    codename: "xa_luong_quoi",
    district_code: 834,
  },
  {
    name: "Xã Lương Phú",
    code: 29005,
    division_type: "xã",
    codename: "xa_luong_phu",
    district_code: 834,
  },
  {
    name: "Xã Châu Bình",
    code: 29008,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 834,
  },
  {
    name: "Xã Thuận Điền",
    code: 29011,
    division_type: "xã",
    codename: "xa_thuan_dien",
    district_code: 834,
  },
  {
    name: "Xã Sơn Phú",
    code: 29014,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 834,
  },
  {
    name: "Xã Bình Hoà",
    code: 29017,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 834,
  },
  {
    name: "Xã Phước Long",
    code: 29020,
    division_type: "xã",
    codename: "xa_phuoc_long",
    district_code: 834,
  },
  {
    name: "Xã Hưng Phong",
    code: 29023,
    division_type: "xã",
    codename: "xa_hung_phong",
    district_code: 834,
  },
  {
    name: "Xã Long Mỹ",
    code: 29026,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 834,
  },
  {
    name: "Xã Tân Hào",
    code: 29029,
    division_type: "xã",
    codename: "xa_tan_hao",
    district_code: 834,
  },
  {
    name: "Xã Bình Thành",
    code: 29032,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 834,
  },
  {
    name: "Xã Tân Thanh",
    code: 29035,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 834,
  },
  {
    name: "Xã Tân Lợi Thạnh",
    code: 29038,
    division_type: "xã",
    codename: "xa_tan_loi_thanh",
    district_code: 834,
  },
  {
    name: "Xã Thạnh Phú Đông",
    code: 29041,
    division_type: "xã",
    codename: "xa_thanh_phu_dong",
    district_code: 834,
  },
  {
    name: "Xã Hưng Nhượng",
    code: 29044,
    division_type: "xã",
    codename: "xa_hung_nhuong",
    district_code: 834,
  },
  {
    name: "Xã Hưng Lễ",
    code: 29047,
    division_type: "xã",
    codename: "xa_hung_le",
    district_code: 834,
  },
  {
    name: "Thị trấn Bình Đại",
    code: 29050,
    division_type: "thị trấn",
    codename: "thi_tran_binh_dai",
    district_code: 835,
  },
  {
    name: "Xã Tam Hiệp",
    code: 29053,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 835,
  },
  {
    name: "Xã Long Định",
    code: 29056,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 835,
  },
  {
    name: "Xã Long Hòa",
    code: 29059,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 835,
  },
  {
    name: "Xã Phú Thuận",
    code: 29062,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 835,
  },
  {
    name: "Xã Vang Quới Tây",
    code: 29065,
    division_type: "xã",
    codename: "xa_vang_quoi_tay",
    district_code: 835,
  },
  {
    name: "Xã Vang Quới Đông",
    code: 29068,
    division_type: "xã",
    codename: "xa_vang_quoi_dong",
    district_code: 835,
  },
  {
    name: "Xã Châu Hưng",
    code: 29071,
    division_type: "xã",
    codename: "xa_chau_hung",
    district_code: 835,
  },
  {
    name: "Xã Phú Vang",
    code: 29074,
    division_type: "xã",
    codename: "xa_phu_vang",
    district_code: 835,
  },
  {
    name: "Xã Lộc Thuận",
    code: 29077,
    division_type: "xã",
    codename: "xa_loc_thuan",
    district_code: 835,
  },
  {
    name: "Xã Định Trung",
    code: 29080,
    division_type: "xã",
    codename: "xa_dinh_trung",
    district_code: 835,
  },
  {
    name: "Xã Thới Lai",
    code: 29083,
    division_type: "xã",
    codename: "xa_thoi_lai",
    district_code: 835,
  },
  {
    name: "Xã Bình Thới",
    code: 29086,
    division_type: "xã",
    codename: "xa_binh_thoi",
    district_code: 835,
  },
  {
    name: "Xã Phú Long",
    code: 29089,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 835,
  },
  {
    name: "Xã Bình Thắng",
    code: 29092,
    division_type: "xã",
    codename: "xa_binh_thang",
    district_code: 835,
  },
  {
    name: "Xã Thạnh Trị",
    code: 29095,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 835,
  },
  {
    name: "Xã Đại Hòa Lộc",
    code: 29098,
    division_type: "xã",
    codename: "xa_dai_hoa_loc",
    district_code: 835,
  },
  {
    name: "Xã Thừa Đức",
    code: 29101,
    division_type: "xã",
    codename: "xa_thua_duc",
    district_code: 835,
  },
  {
    name: "Xã Thạnh Phước",
    code: 29104,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 835,
  },
  {
    name: "Xã Thới Thuận",
    code: 29107,
    division_type: "xã",
    codename: "xa_thoi_thuan",
    district_code: 835,
  },
  {
    name: "Thị trấn Ba Tri",
    code: 29110,
    division_type: "thị trấn",
    codename: "thi_tran_ba_tri",
    district_code: 836,
  },
  {
    name: "Xã Tân Mỹ",
    code: 29113,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29116,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 836,
  },
  {
    name: "Xã Tân Xuân",
    code: 29119,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 29122,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 836,
  },
  {
    name: "Xã Bảo Thạnh",
    code: 29125,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 836,
  },
  {
    name: "Xã An Phú Trung",
    code: 29128,
    division_type: "xã",
    codename: "xa_an_phu_trung",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 29131,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Nhơn",
    code: 29134,
    division_type: "xã",
    codename: "xa_my_nhon",
    district_code: 836,
  },
  {
    name: "Xã Phước Ngãi",
    code: 29137,
    division_type: "xã",
    codename: "xa_phuoc_ngai",
    district_code: 836,
  },
  {
    name: "Xã An Ngãi Trung",
    code: 29143,
    division_type: "xã",
    codename: "xa_an_ngai_trung",
    district_code: 836,
  },
  {
    name: "Xã Phú Lễ",
    code: 29146,
    division_type: "xã",
    codename: "xa_phu_le",
    district_code: 836,
  },
  {
    name: "Xã An Bình Tây",
    code: 29149,
    division_type: "xã",
    codename: "xa_an_binh_tay",
    district_code: 836,
  },
  {
    name: "Xã Bảo Thuận",
    code: 29152,
    division_type: "xã",
    codename: "xa_bao_thuan",
    district_code: 836,
  },
  {
    name: "Xã Tân Hưng",
    code: 29155,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 836,
  },
  {
    name: "Xã An Ngãi Tây",
    code: 29158,
    division_type: "xã",
    codename: "xa_an_ngai_tay",
    district_code: 836,
  },
  {
    name: "Xã An Hiệp",
    code: 29161,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 836,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 29164,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 836,
  },
  {
    name: "Xã Tân Thủy",
    code: 29167,
    division_type: "xã",
    codename: "xa_tan_thuy",
    district_code: 836,
  },
  {
    name: "Xã Vĩnh An",
    code: 29170,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 836,
  },
  {
    name: "Xã An Đức",
    code: 29173,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 836,
  },
  {
    name: "Xã An Hòa Tây",
    code: 29176,
    division_type: "xã",
    codename: "xa_an_hoa_tay",
    district_code: 836,
  },
  {
    name: "Xã An Thủy",
    code: 29179,
    division_type: "xã",
    codename: "xa_an_thuy",
    district_code: 836,
  },
  {
    name: "Thị trấn Thạnh Phú",
    code: 29182,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_phu",
    district_code: 837,
  },
  {
    name: "Xã Phú Khánh",
    code: 29185,
    division_type: "xã",
    codename: "xa_phu_khanh",
    district_code: 837,
  },
  {
    name: "Xã Đại Điền",
    code: 29188,
    division_type: "xã",
    codename: "xa_dai_dien",
    district_code: 837,
  },
  {
    name: "Xã Quới Điền",
    code: 29191,
    division_type: "xã",
    codename: "xa_quoi_dien",
    district_code: 837,
  },
  {
    name: "Xã Tân Phong",
    code: 29194,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 837,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 29197,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 837,
  },
  {
    name: "Xã An Thạnh",
    code: 29200,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 837,
  },
  {
    name: "Xã Thới Thạnh",
    code: 29203,
    division_type: "xã",
    codename: "xa_thoi_thanh",
    district_code: 837,
  },
  {
    name: "Xã Hòa Lợi",
    code: 29206,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 837,
  },
  {
    name: "Xã An Điền",
    code: 29209,
    division_type: "xã",
    codename: "xa_an_dien",
    district_code: 837,
  },
  {
    name: "Xã Bình Thạnh",
    code: 29212,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 837,
  },
  {
    name: "Xã An Thuận",
    code: 29215,
    division_type: "xã",
    codename: "xa_an_thuan",
    district_code: 837,
  },
  {
    name: "Xã An Quy",
    code: 29218,
    division_type: "xã",
    codename: "xa_an_quy",
    district_code: 837,
  },
  {
    name: "Xã Thạnh Hải",
    code: 29221,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 837,
  },
  {
    name: "Xã An Nhơn",
    code: 29224,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 837,
  },
  {
    name: "Xã Giao Thạnh",
    code: 29227,
    division_type: "xã",
    codename: "xa_giao_thanh",
    district_code: 837,
  },
  {
    name: "Xã Thạnh Phong",
    code: 29230,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 837,
  },
  {
    name: "Xã Mỹ An",
    code: 29233,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 837,
  },
  {
    name: "Xã Phú Mỹ",
    code: 28889,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 838,
  },
  {
    name: "Xã Hưng Khánh Trung A",
    code: 28901,
    division_type: "xã",
    codename: "xa_hung_khanh_trung_a",
    district_code: 838,
  },
  {
    name: "Xã Thanh Tân",
    code: 28906,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 838,
  },
  {
    name: "Xã Thạnh Ngãi",
    code: 28909,
    division_type: "xã",
    codename: "xa_thanh_ngai",
    district_code: 838,
  },
  {
    name: "Xã Tân Phú Tây",
    code: 28912,
    division_type: "xã",
    codename: "xa_tan_phu_tay",
    district_code: 838,
  },
  {
    name: "Xã Phước Mỹ Trung",
    code: 28915,
    division_type: "xã",
    codename: "xa_phuoc_my_trung",
    district_code: 838,
  },
  {
    name: "Xã Tân Thành Bình",
    code: 28918,
    division_type: "xã",
    codename: "xa_tan_thanh_binh",
    district_code: 838,
  },
  {
    name: "Xã Thành An",
    code: 28921,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 838,
  },
  {
    name: "Xã Hòa Lộc",
    code: 28924,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 838,
  },
  {
    name: "Xã Tân Thanh Tây",
    code: 28927,
    division_type: "xã",
    codename: "xa_tan_thanh_tay",
    district_code: 838,
  },
  {
    name: "Xã Tân Bình",
    code: 28933,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 838,
  },
  {
    name: "Xã Nhuận Phú Tân",
    code: 28936,
    division_type: "xã",
    codename: "xa_nhuan_phu_tan",
    district_code: 838,
  },
  {
    name: "Xã Khánh Thạnh Tân",
    code: 28948,
    division_type: "xã",
    codename: "xa_khanh_thanh_tan",
    district_code: 838,
  },
  {
    name: "Phường 4",
    code: 29236,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 842,
  },
  {
    name: "Phường 1",
    code: 29239,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 842,
  },
  {
    name: "Phường 3",
    code: 29242,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 842,
  },
  {
    name: "Phường 2",
    code: 29245,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 842,
  },
  {
    name: "Phường 5",
    code: 29248,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 842,
  },
  {
    name: "Phường 6",
    code: 29251,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 842,
  },
  {
    name: "Phường 7",
    code: 29254,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 842,
  },
  {
    name: "Phường 8",
    code: 29257,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 842,
  },
  {
    name: "Phường 9",
    code: 29260,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 842,
  },
  {
    name: "Xã Long Đức",
    code: 29263,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 842,
  },
  {
    name: "Thị trấn Càng Long",
    code: 29266,
    division_type: "thị trấn",
    codename: "thi_tran_cang_long",
    district_code: 844,
  },
  {
    name: "Xã Mỹ Cẩm",
    code: 29269,
    division_type: "xã",
    codename: "xa_my_cam",
    district_code: 844,
  },
  {
    name: "Xã An Trường A",
    code: 29272,
    division_type: "xã",
    codename: "xa_an_truong_a",
    district_code: 844,
  },
  {
    name: "Xã An Trường",
    code: 29275,
    division_type: "xã",
    codename: "xa_an_truong",
    district_code: 844,
  },
  {
    name: "Xã Huyền Hội",
    code: 29278,
    division_type: "xã",
    codename: "xa_huyen_hoi",
    district_code: 844,
  },
  {
    name: "Xã Tân An",
    code: 29281,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 844,
  },
  {
    name: "Xã Tân Bình",
    code: 29284,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 844,
  },
  {
    name: "Xã Bình Phú",
    code: 29287,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 844,
  },
  {
    name: "Xã Phương Thạnh",
    code: 29290,
    division_type: "xã",
    codename: "xa_phuong_thanh",
    district_code: 844,
  },
  {
    name: "Xã Đại Phúc",
    code: 29293,
    division_type: "xã",
    codename: "xa_dai_phuc",
    district_code: 844,
  },
  {
    name: "Xã Đại Phước",
    code: 29296,
    division_type: "xã",
    codename: "xa_dai_phuoc",
    district_code: 844,
  },
  {
    name: "Xã Nhị Long Phú",
    code: 29299,
    division_type: "xã",
    codename: "xa_nhi_long_phu",
    district_code: 844,
  },
  {
    name: "Xã Nhị Long",
    code: 29302,
    division_type: "xã",
    codename: "xa_nhi_long",
    district_code: 844,
  },
  {
    name: "Xã Đức Mỹ",
    code: 29305,
    division_type: "xã",
    codename: "xa_duc_my",
    district_code: 844,
  },
  {
    name: "Thị trấn Cầu Kè",
    code: 29308,
    division_type: "thị trấn",
    codename: "thi_tran_cau_ke",
    district_code: 845,
  },
  {
    name: "Xã Hòa Ân",
    code: 29311,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 845,
  },
  {
    name: "Xã Châu Điền",
    code: 29314,
    division_type: "xã",
    codename: "xa_chau_dien",
    district_code: 845,
  },
  {
    name: "Xã An Phú Tân",
    code: 29317,
    division_type: "xã",
    codename: "xa_an_phu_tan",
    district_code: 845,
  },
  {
    name: "Xã Hoà Tân",
    code: 29320,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 845,
  },
  {
    name: "Xã Ninh Thới",
    code: 29323,
    division_type: "xã",
    codename: "xa_ninh_thoi",
    district_code: 845,
  },
  {
    name: "Xã Phong Phú",
    code: 29326,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 845,
  },
  {
    name: "Xã Phong Thạnh",
    code: 29329,
    division_type: "xã",
    codename: "xa_phong_thanh",
    district_code: 845,
  },
  {
    name: "Xã Tam Ngãi",
    code: 29332,
    division_type: "xã",
    codename: "xa_tam_ngai",
    district_code: 845,
  },
  {
    name: "Xã Thông Hòa",
    code: 29335,
    division_type: "xã",
    codename: "xa_thong_hoa",
    district_code: 845,
  },
  {
    name: "Xã Thạnh Phú",
    code: 29338,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 845,
  },
  {
    name: "Thị trấn Tiểu Cần",
    code: 29341,
    division_type: "thị trấn",
    codename: "thi_tran_tieu_can",
    district_code: 846,
  },
  {
    name: "Thị trấn Cầu Quan",
    code: 29344,
    division_type: "thị trấn",
    codename: "thi_tran_cau_quan",
    district_code: 846,
  },
  {
    name: "Xã Phú Cần",
    code: 29347,
    division_type: "xã",
    codename: "xa_phu_can",
    district_code: 846,
  },
  {
    name: "Xã Hiếu Tử",
    code: 29350,
    division_type: "xã",
    codename: "xa_hieu_tu",
    district_code: 846,
  },
  {
    name: "Xã Hiếu Trung",
    code: 29353,
    division_type: "xã",
    codename: "xa_hieu_trung",
    district_code: 846,
  },
  {
    name: "Xã Long Thới",
    code: 29356,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 846,
  },
  {
    name: "Xã Hùng Hòa",
    code: 29359,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 846,
  },
  {
    name: "Xã Tân Hùng",
    code: 29362,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 846,
  },
  {
    name: "Xã Tập Ngãi",
    code: 29365,
    division_type: "xã",
    codename: "xa_tap_ngai",
    district_code: 846,
  },
  {
    name: "Xã Ngãi Hùng",
    code: 29368,
    division_type: "xã",
    codename: "xa_ngai_hung",
    district_code: 846,
  },
  {
    name: "Xã Tân Hòa",
    code: 29371,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 846,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 29374,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 847,
  },
  {
    name: "Xã Đa Lộc",
    code: 29377,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 847,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 29380,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 847,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 29383,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 847,
  },
  {
    name: "Xã Lương Hoà A",
    code: 29386,
    division_type: "xã",
    codename: "xa_luong_hoa_a",
    district_code: 847,
  },
  {
    name: "Xã Lương Hòa",
    code: 29389,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 847,
  },
  {
    name: "Xã Song Lộc",
    code: 29392,
    division_type: "xã",
    codename: "xa_song_loc",
    district_code: 847,
  },
  {
    name: "Xã Nguyệt Hóa",
    code: 29395,
    division_type: "xã",
    codename: "xa_nguyet_hoa",
    district_code: 847,
  },
  {
    name: "Xã Hòa Thuận",
    code: 29398,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 847,
  },
  {
    name: "Xã Hòa Lợi",
    code: 29401,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 847,
  },
  {
    name: "Xã Phước Hảo",
    code: 29404,
    division_type: "xã",
    codename: "xa_phuoc_hao",
    district_code: 847,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 29407,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 847,
  },
  {
    name: "Xã Hòa Minh",
    code: 29410,
    division_type: "xã",
    codename: "xa_hoa_minh",
    district_code: 847,
  },
  {
    name: "Xã Long Hòa",
    code: 29413,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 847,
  },
  {
    name: "Thị trấn Cầu Ngang",
    code: 29416,
    division_type: "thị trấn",
    codename: "thi_tran_cau_ngang",
    district_code: 848,
  },
  {
    name: "Thị trấn Mỹ Long",
    code: 29419,
    division_type: "thị trấn",
    codename: "thi_tran_my_long",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Long Bắc",
    code: 29422,
    division_type: "xã",
    codename: "xa_my_long_bac",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Long Nam",
    code: 29425,
    division_type: "xã",
    codename: "xa_my_long_nam",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29428,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 848,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 29431,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 848,
  },
  {
    name: "Xã Kim Hòa",
    code: 29434,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 29437,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 848,
  },
  {
    name: "Xã Thuận Hòa",
    code: 29440,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 848,
  },
  {
    name: "Xã Long Sơn",
    code: 29443,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 848,
  },
  {
    name: "Xã Nhị Trường",
    code: 29446,
    division_type: "xã",
    codename: "xa_nhi_truong",
    district_code: 848,
  },
  {
    name: "Xã Trường Thọ",
    code: 29449,
    division_type: "xã",
    codename: "xa_truong_tho",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Mỹ Đông",
    code: 29452,
    division_type: "xã",
    codename: "xa_hiep_my_dong",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Mỹ Tây",
    code: 29455,
    division_type: "xã",
    codename: "xa_hiep_my_tay",
    district_code: 848,
  },
  {
    name: "Xã Thạnh Hòa Sơn",
    code: 29458,
    division_type: "xã",
    codename: "xa_thanh_hoa_son",
    district_code: 848,
  },
  {
    name: "Thị trấn Trà Cú",
    code: 29461,
    division_type: "thị trấn",
    codename: "thi_tran_tra_cu",
    district_code: 849,
  },
  {
    name: "Thị trấn Định An",
    code: 29462,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_an",
    district_code: 849,
  },
  {
    name: "Xã Phước Hưng",
    code: 29464,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 849,
  },
  {
    name: "Xã Tập Sơn",
    code: 29467,
    division_type: "xã",
    codename: "xa_tap_son",
    district_code: 849,
  },
  {
    name: "Xã Tân Sơn",
    code: 29470,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 849,
  },
  {
    name: "Xã An Quảng Hữu",
    code: 29473,
    division_type: "xã",
    codename: "xa_an_quang_huu",
    district_code: 849,
  },
  {
    name: "Xã Lưu Nghiệp Anh",
    code: 29476,
    division_type: "xã",
    codename: "xa_luu_nghiep_anh",
    district_code: 849,
  },
  {
    name: "Xã Ngãi Xuyên",
    code: 29479,
    division_type: "xã",
    codename: "xa_ngai_xuyen",
    district_code: 849,
  },
  {
    name: "Xã Kim Sơn",
    code: 29482,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 849,
  },
  {
    name: "Xã Thanh Sơn",
    code: 29485,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 849,
  },
  {
    name: "Xã Hàm Giang",
    code: 29488,
    division_type: "xã",
    codename: "xa_ham_giang",
    district_code: 849,
  },
  {
    name: "Xã Hàm Tân",
    code: 29489,
    division_type: "xã",
    codename: "xa_ham_tan",
    district_code: 849,
  },
  {
    name: "Xã Đại An",
    code: 29491,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 849,
  },
  {
    name: "Xã Định An",
    code: 29494,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 849,
  },
  {
    name: "Xã Ngọc Biên",
    code: 29503,
    division_type: "xã",
    codename: "xa_ngoc_bien",
    district_code: 849,
  },
  {
    name: "Xã Long Hiệp",
    code: 29506,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 849,
  },
  {
    name: "Xã Tân Hiệp",
    code: 29509,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 849,
  },
  {
    name: "Xã Đôn Xuân",
    code: 29497,
    division_type: "xã",
    codename: "xa_don_xuan",
    district_code: 850,
  },
  {
    name: "Xã Đôn Châu",
    code: 29500,
    division_type: "xã",
    codename: "xa_don_chau",
    district_code: 850,
  },
  {
    name: "Thị trấn Long Thành",
    code: 29513,
    division_type: "thị trấn",
    codename: "thi_tran_long_thanh",
    district_code: 850,
  },
  {
    name: "Xã Long Khánh",
    code: 29521,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 850,
  },
  {
    name: "Xã Ngũ Lạc",
    code: 29530,
    division_type: "xã",
    codename: "xa_ngu_lac",
    district_code: 850,
  },
  {
    name: "Xã Long Vĩnh",
    code: 29533,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 850,
  },
  {
    name: "Xã Đông Hải",
    code: 29536,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 850,
  },
  {
    name: "Phường 1",
    code: 29512,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 851,
  },
  {
    name: "Xã Long Toàn",
    code: 29515,
    division_type: "xã",
    codename: "xa_long_toan",
    district_code: 851,
  },
  {
    name: "Phường 2",
    code: 29516,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 851,
  },
  {
    name: "Xã Long Hữu",
    code: 29518,
    division_type: "xã",
    codename: "xa_long_huu",
    district_code: 851,
  },
  {
    name: "Xã Dân Thành",
    code: 29524,
    division_type: "xã",
    codename: "xa_dan_thanh",
    district_code: 851,
  },
  {
    name: "Xã Trường Long Hòa",
    code: 29527,
    division_type: "xã",
    codename: "xa_truong_long_hoa",
    district_code: 851,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 29539,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 851,
  },
  {
    name: "Phường 9",
    code: 29542,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 855,
  },
  {
    name: "Phường 5",
    code: 29545,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 855,
  },
  {
    name: "Phường 1",
    code: 29548,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 855,
  },
  {
    name: "Phường 2",
    code: 29551,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 855,
  },
  {
    name: "Phường 4",
    code: 29554,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 855,
  },
  {
    name: "Phường 3",
    code: 29557,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 855,
  },
  {
    name: "Phường 8",
    code: 29560,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 855,
  },
  {
    name: "Phường Tân Ngãi",
    code: 29563,
    division_type: "phường",
    codename: "phuong_tan_ngai",
    district_code: 855,
  },
  {
    name: "Phường Tân Hòa",
    code: 29566,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 855,
  },
  {
    name: "Phường Tân Hội",
    code: 29569,
    division_type: "phường",
    codename: "phuong_tan_hoi",
    district_code: 855,
  },
  {
    name: "Phường Trường An",
    code: 29572,
    division_type: "phường",
    codename: "phuong_truong_an",
    district_code: 855,
  },
  {
    name: "Thị trấn Long Hồ",
    code: 29575,
    division_type: "thị trấn",
    codename: "thi_tran_long_ho",
    district_code: 857,
  },
  {
    name: "Xã Đồng Phú",
    code: 29578,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 857,
  },
  {
    name: "Xã Bình Hòa Phước",
    code: 29581,
    division_type: "xã",
    codename: "xa_binh_hoa_phuoc",
    district_code: 857,
  },
  {
    name: "Xã Hòa Ninh",
    code: 29584,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 857,
  },
  {
    name: "Xã An Bình",
    code: 29587,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 857,
  },
  {
    name: "Xã Thanh Đức",
    code: 29590,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 857,
  },
  {
    name: "Xã Tân Hạnh",
    code: 29593,
    division_type: "xã",
    codename: "xa_tan_hanh",
    district_code: 857,
  },
  {
    name: "Xã Phước Hậu",
    code: 29596,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 857,
  },
  {
    name: "Xã Long Phước",
    code: 29599,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 857,
  },
  {
    name: "Xã Phú Đức",
    code: 29602,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 857,
  },
  {
    name: "Xã Lộc Hòa",
    code: 29605,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 857,
  },
  {
    name: "Xã Long An",
    code: 29608,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 857,
  },
  {
    name: "Xã Phú Quới",
    code: 29611,
    division_type: "xã",
    codename: "xa_phu_quoi",
    district_code: 857,
  },
  {
    name: "Xã Thạnh Quới",
    code: 29614,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 857,
  },
  {
    name: "Xã Hòa Phú",
    code: 29617,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 857,
  },
  {
    name: "Xã Mỹ An",
    code: 29623,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 858,
  },
  {
    name: "Xã Mỹ Phước",
    code: 29626,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 858,
  },
  {
    name: "Xã An Phước",
    code: 29629,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 858,
  },
  {
    name: "Xã Nhơn Phú",
    code: 29632,
    division_type: "xã",
    codename: "xa_nhon_phu",
    district_code: 858,
  },
  {
    name: "Xã Long Mỹ",
    code: 29635,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 858,
  },
  {
    name: "Xã Hòa Tịnh",
    code: 29638,
    division_type: "xã",
    codename: "xa_hoa_tinh",
    district_code: 858,
  },
  {
    name: "Thị trấn Cái Nhum",
    code: 29641,
    division_type: "thị trấn",
    codename: "thi_tran_cai_nhum",
    district_code: 858,
  },
  {
    name: "Xã Bình Phước",
    code: 29644,
    division_type: "xã",
    codename: "xa_binh_phuoc",
    district_code: 858,
  },
  {
    name: "Xã Chánh An",
    code: 29647,
    division_type: "xã",
    codename: "xa_chanh_an",
    district_code: 858,
  },
  {
    name: "Xã Tân An Hội",
    code: 29650,
    division_type: "xã",
    codename: "xa_tan_an_hoi",
    district_code: 858,
  },
  {
    name: "Xã Tân Long",
    code: 29653,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 858,
  },
  {
    name: "Xã Tân Long Hội",
    code: 29656,
    division_type: "xã",
    codename: "xa_tan_long_hoi",
    district_code: 858,
  },
  {
    name: "Thị trấn Vũng Liêm",
    code: 29659,
    division_type: "thị trấn",
    codename: "thi_tran_vung_liem",
    district_code: 859,
  },
  {
    name: "Xã Tân Quới Trung",
    code: 29662,
    division_type: "xã",
    codename: "xa_tan_quoi_trung",
    district_code: 859,
  },
  {
    name: "Xã Quới Thiện",
    code: 29665,
    division_type: "xã",
    codename: "xa_quoi_thien",
    district_code: 859,
  },
  {
    name: "Xã Quới An",
    code: 29668,
    division_type: "xã",
    codename: "xa_quoi_an",
    district_code: 859,
  },
  {
    name: "Xã Trung Chánh",
    code: 29671,
    division_type: "xã",
    codename: "xa_trung_chanh",
    district_code: 859,
  },
  {
    name: "Xã Tân An Luông",
    code: 29674,
    division_type: "xã",
    codename: "xa_tan_an_luong",
    district_code: 859,
  },
  {
    name: "Xã Thanh Bình",
    code: 29677,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành Tây",
    code: 29680,
    division_type: "xã",
    codename: "xa_trung_thanh_tay",
    district_code: 859,
  },
  {
    name: "Xã Trung Hiệp",
    code: 29683,
    division_type: "xã",
    codename: "xa_trung_hiep",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Phụng",
    code: 29686,
    division_type: "xã",
    codename: "xa_hieu_phung",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành Đông",
    code: 29689,
    division_type: "xã",
    codename: "xa_trung_thanh_dong",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành",
    code: 29692,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 859,
  },
  {
    name: "Xã Trung Hiếu",
    code: 29695,
    division_type: "xã",
    codename: "xa_trung_hieu",
    district_code: 859,
  },
  {
    name: "Xã Trung Ngãi",
    code: 29698,
    division_type: "xã",
    codename: "xa_trung_ngai",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Thuận",
    code: 29701,
    division_type: "xã",
    codename: "xa_hieu_thuan",
    district_code: 859,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 29704,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 859,
  },
  {
    name: "Xã Trung An",
    code: 29707,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Nhơn",
    code: 29710,
    division_type: "xã",
    codename: "xa_hieu_nhon",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Thành",
    code: 29713,
    division_type: "xã",
    codename: "xa_hieu_thanh",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Nghĩa",
    code: 29716,
    division_type: "xã",
    codename: "xa_hieu_nghia",
    district_code: 859,
  },
  {
    name: "Thị trấn Tam Bình",
    code: 29719,
    division_type: "thị trấn",
    codename: "thi_tran_tam_binh",
    district_code: 860,
  },
  {
    name: "Xã Tân Lộc",
    code: 29722,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 860,
  },
  {
    name: "Xã Phú Thịnh",
    code: 29725,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 860,
  },
  {
    name: "Xã Hậu Lộc",
    code: 29728,
    division_type: "xã",
    codename: "xa_hau_loc",
    district_code: 860,
  },
  {
    name: "Xã Hòa Thạnh",
    code: 29731,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 860,
  },
  {
    name: "Xã Hoà Lộc",
    code: 29734,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 860,
  },
  {
    name: "Xã Phú Lộc",
    code: 29737,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 860,
  },
  {
    name: "Xã Song Phú",
    code: 29740,
    division_type: "xã",
    codename: "xa_song_phu",
    district_code: 860,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 29743,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 860,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 29746,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 860,
  },
  {
    name: "Xã Tân Phú",
    code: 29749,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 860,
  },
  {
    name: "Xã Long Phú",
    code: 29752,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 860,
  },
  {
    name: "Xã Mỹ Thạnh Trung",
    code: 29755,
    division_type: "xã",
    codename: "xa_my_thanh_trung",
    district_code: 860,
  },
  {
    name: "Xã Tường Lộc",
    code: 29758,
    division_type: "xã",
    codename: "xa_tuong_loc",
    district_code: 860,
  },
  {
    name: "Xã Loan Mỹ",
    code: 29761,
    division_type: "xã",
    codename: "xa_loan_my",
    district_code: 860,
  },
  {
    name: "Xã Ngãi Tứ",
    code: 29764,
    division_type: "xã",
    codename: "xa_ngai_tu",
    district_code: 860,
  },
  {
    name: "Xã Bình Ninh",
    code: 29767,
    division_type: "xã",
    codename: "xa_binh_ninh",
    district_code: 860,
  },
  {
    name: "Phường Cái Vồn",
    code: 29770,
    division_type: "phường",
    codename: "phuong_cai_von",
    district_code: 861,
  },
  {
    name: "Phường Thành Phước",
    code: 29771,
    division_type: "phường",
    codename: "phuong_thanh_phuoc",
    district_code: 861,
  },
  {
    name: "Xã Thuận An",
    code: 29806,
    division_type: "xã",
    codename: "xa_thuan_an",
    district_code: 861,
  },
  {
    name: "Xã Đông Thạnh",
    code: 29809,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 861,
  },
  {
    name: "Xã Đông Bình",
    code: 29812,
    division_type: "xã",
    codename: "xa_dong_binh",
    district_code: 861,
  },
  {
    name: "Phường Đông Thuận",
    code: 29813,
    division_type: "phường",
    codename: "phuong_dong_thuan",
    district_code: 861,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29815,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 861,
  },
  {
    name: "Xã Đông Thành",
    code: 29818,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 861,
  },
  {
    name: "Thị trấn Trà Ôn",
    code: 29821,
    division_type: "thị trấn",
    codename: "thi_tran_tra_on",
    district_code: 862,
  },
  {
    name: "Xã Xuân Hiệp",
    code: 29824,
    division_type: "xã",
    codename: "xa_xuan_hiep",
    district_code: 862,
  },
  {
    name: "Xã Nhơn Bình",
    code: 29827,
    division_type: "xã",
    codename: "xa_nhon_binh",
    district_code: 862,
  },
  {
    name: "Xã Hòa Bình",
    code: 29830,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 862,
  },
  {
    name: "Xã Thới Hòa",
    code: 29833,
    division_type: "xã",
    codename: "xa_thoi_hoa",
    district_code: 862,
  },
  {
    name: "Xã Trà Côn",
    code: 29836,
    division_type: "xã",
    codename: "xa_tra_con",
    district_code: 862,
  },
  {
    name: "Xã Tân Mỹ",
    code: 29839,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 862,
  },
  {
    name: "Xã Hựu Thành",
    code: 29842,
    division_type: "xã",
    codename: "xa_huu_thanh",
    district_code: 862,
  },
  {
    name: "Xã Vĩnh Xuân",
    code: 29845,
    division_type: "xã",
    codename: "xa_vinh_xuan",
    district_code: 862,
  },
  {
    name: "Xã Thuận Thới",
    code: 29848,
    division_type: "xã",
    codename: "xa_thuan_thoi",
    district_code: 862,
  },
  {
    name: "Xã Phú Thành",
    code: 29851,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 862,
  },
  {
    name: "Xã Thiện Mỹ",
    code: 29854,
    division_type: "xã",
    codename: "xa_thien_my",
    district_code: 862,
  },
  {
    name: "Xã Lục Sỹ Thành",
    code: 29857,
    division_type: "xã",
    codename: "xa_luc_sy_thanh",
    district_code: 862,
  },
  {
    name: "Xã Tích Thiện",
    code: 29860,
    division_type: "xã",
    codename: "xa_tich_thien",
    district_code: 862,
  },
  {
    name: "Xã Tân Hưng",
    code: 29773,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 863,
  },
  {
    name: "Xã Tân Thành",
    code: 29776,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 863,
  },
  {
    name: "Xã Thành Trung",
    code: 29779,
    division_type: "xã",
    codename: "xa_thanh_trung",
    district_code: 863,
  },
  {
    name: "Xã Tân An Thạnh",
    code: 29782,
    division_type: "xã",
    codename: "xa_tan_an_thanh",
    district_code: 863,
  },
  {
    name: "Xã Tân Lược",
    code: 29785,
    division_type: "xã",
    codename: "xa_tan_luoc",
    district_code: 863,
  },
  {
    name: "Xã Nguyễn Văn Thảnh",
    code: 29788,
    division_type: "xã",
    codename: "xa_nguyen_van_thanh",
    district_code: 863,
  },
  {
    name: "Xã Thành Lợi",
    code: 29791,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 863,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 29794,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 863,
  },
  {
    name: "Xã Tân Bình",
    code: 29797,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 863,
  },
  {
    name: "Thị trấn Tân Quới",
    code: 29800,
    division_type: "thị trấn",
    codename: "thi_tran_tan_quoi",
    district_code: 863,
  },
  {
    name: "Phường 11",
    code: 29863,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 866,
  },
  {
    name: "Phường 1",
    code: 29866,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 866,
  },
  {
    name: "Phường 2",
    code: 29869,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 866,
  },
  {
    name: "Phường 4",
    code: 29872,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 866,
  },
  {
    name: "Phường 3",
    code: 29875,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 866,
  },
  {
    name: "Phường 6",
    code: 29878,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Ngãi",
    code: 29881,
    division_type: "xã",
    codename: "xa_my_ngai",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Tân",
    code: 29884,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Trà",
    code: 29887,
    division_type: "xã",
    codename: "xa_my_tra",
    district_code: 866,
  },
  {
    name: "Phường Mỹ Phú",
    code: 29888,
    division_type: "phường",
    codename: "phuong_my_phu",
    district_code: 866,
  },
  {
    name: "Xã Tân Thuận Tây",
    code: 29890,
    division_type: "xã",
    codename: "xa_tan_thuan_tay",
    district_code: 866,
  },
  {
    name: "Phường Hoà Thuận",
    code: 29892,
    division_type: "phường",
    codename: "phuong_hoa_thuan",
    district_code: 866,
  },
  {
    name: "Xã Hòa An",
    code: 29893,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 866,
  },
  {
    name: "Xã Tân Thuận Đông",
    code: 29896,
    division_type: "xã",
    codename: "xa_tan_thuan_dong",
    district_code: 866,
  },
  {
    name: "Xã Tịnh Thới",
    code: 29899,
    division_type: "xã",
    codename: "xa_tinh_thoi",
    district_code: 866,
  },
  {
    name: "Phường 3",
    code: 29902,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 867,
  },
  {
    name: "Phường 1",
    code: 29905,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 867,
  },
  {
    name: "Phường 4",
    code: 29908,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 867,
  },
  {
    name: "Phường 2",
    code: 29911,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 867,
  },
  {
    name: "Xã Tân Khánh Đông",
    code: 29914,
    division_type: "xã",
    codename: "xa_tan_khanh_dong",
    district_code: 867,
  },
  {
    name: "Phường Tân Quy Đông",
    code: 29917,
    division_type: "phường",
    codename: "phuong_tan_quy_dong",
    district_code: 867,
  },
  {
    name: "Phường An Hoà",
    code: 29919,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 867,
  },
  {
    name: "Xã Tân Quy Tây",
    code: 29920,
    division_type: "xã",
    codename: "xa_tan_quy_tay",
    district_code: 867,
  },
  {
    name: "Xã Tân Phú Đông",
    code: 29923,
    division_type: "xã",
    codename: "xa_tan_phu_dong",
    district_code: 867,
  },
  {
    name: "Phường An Lộc",
    code: 29954,
    division_type: "phường",
    codename: "phuong_an_loc",
    district_code: 868,
  },
  {
    name: "Phường An Thạnh",
    code: 29955,
    division_type: "phường",
    codename: "phuong_an_thanh",
    district_code: 868,
  },
  {
    name: "Xã Bình Thạnh",
    code: 29959,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 868,
  },
  {
    name: "Xã Tân Hội",
    code: 29965,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 868,
  },
  {
    name: "Phường An Lạc",
    code: 29978,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 868,
  },
  {
    name: "Phường An Bình B",
    code: 29986,
    division_type: "phường",
    codename: "phuong_an_binh_b",
    district_code: 868,
  },
  {
    name: "Phường An Bình A",
    code: 29989,
    division_type: "phường",
    codename: "phuong_an_binh_a",
    district_code: 868,
  },
  {
    name: "Thị trấn Sa Rài",
    code: 29926,
    division_type: "thị trấn",
    codename: "thi_tran_sa_rai",
    district_code: 869,
  },
  {
    name: "Xã Tân Hộ Cơ",
    code: 29929,
    division_type: "xã",
    codename: "xa_tan_ho_co",
    district_code: 869,
  },
  {
    name: "Xã Thông Bình",
    code: 29932,
    division_type: "xã",
    codename: "xa_thong_binh",
    district_code: 869,
  },
  {
    name: "Xã Bình Phú",
    code: 29935,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 869,
  },
  {
    name: "Xã Tân Thành A",
    code: 29938,
    division_type: "xã",
    codename: "xa_tan_thanh_a",
    district_code: 869,
  },
  {
    name: "Xã Tân Thành B",
    code: 29941,
    division_type: "xã",
    codename: "xa_tan_thanh_b",
    district_code: 869,
  },
  {
    name: "Xã Tân Phước",
    code: 29944,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 869,
  },
  {
    name: "Xã Tân Công Chí",
    code: 29947,
    division_type: "xã",
    codename: "xa_tan_cong_chi",
    district_code: 869,
  },
  {
    name: "Xã An Phước",
    code: 29950,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 869,
  },
  {
    name: "Xã Thường Phước 1",
    code: 29956,
    division_type: "xã",
    codename: "xa_thuong_phuoc_1",
    district_code: 870,
  },
  {
    name: "Xã Thường Thới Hậu A",
    code: 29962,
    division_type: "xã",
    codename: "xa_thuong_thoi_hau_a",
    district_code: 870,
  },
  {
    name: "Thị trấn Thường Thới Tiền",
    code: 29971,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_thoi_tien",
    district_code: 870,
  },
  {
    name: "Xã Thường Phước 2",
    code: 29974,
    division_type: "xã",
    codename: "xa_thuong_phuoc_2",
    district_code: 870,
  },
  {
    name: "Xã Thường Lạc",
    code: 29977,
    division_type: "xã",
    codename: "xa_thuong_lac",
    district_code: 870,
  },
  {
    name: "Xã Long Khánh A",
    code: 29980,
    division_type: "xã",
    codename: "xa_long_khanh_a",
    district_code: 870,
  },
  {
    name: "Xã Long Khánh B",
    code: 29983,
    division_type: "xã",
    codename: "xa_long_khanh_b",
    district_code: 870,
  },
  {
    name: "Xã Long Thuận",
    code: 29992,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 870,
  },
  {
    name: "Xã Phú Thuận B",
    code: 29995,
    division_type: "xã",
    codename: "xa_phu_thuan_b",
    district_code: 870,
  },
  {
    name: "Xã Phú Thuận A",
    code: 29998,
    division_type: "xã",
    codename: "xa_phu_thuan_a",
    district_code: 870,
  },
  {
    name: "Thị trấn Tràm Chim",
    code: 30001,
    division_type: "thị trấn",
    codename: "thi_tran_tram_chim",
    district_code: 871,
  },
  {
    name: "Xã Hoà Bình",
    code: 30004,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 871,
  },
  {
    name: "Xã Tân Công Sính",
    code: 30007,
    division_type: "xã",
    codename: "xa_tan_cong_sinh",
    district_code: 871,
  },
  {
    name: "Xã Phú Hiệp",
    code: 30010,
    division_type: "xã",
    codename: "xa_phu_hiep",
    district_code: 871,
  },
  {
    name: "Xã Phú Đức",
    code: 30013,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 871,
  },
  {
    name: "Xã Phú Thành B",
    code: 30016,
    division_type: "xã",
    codename: "xa_phu_thanh_b",
    district_code: 871,
  },
  {
    name: "Xã An Hòa",
    code: 30019,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 871,
  },
  {
    name: "Xã An Long",
    code: 30022,
    division_type: "xã",
    codename: "xa_an_long",
    district_code: 871,
  },
  {
    name: "Xã Phú Cường",
    code: 30025,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 871,
  },
  {
    name: "Xã Phú Ninh",
    code: 30028,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 871,
  },
  {
    name: "Xã Phú Thọ",
    code: 30031,
    division_type: "xã",
    codename: "xa_phu_tho",
    district_code: 871,
  },
  {
    name: "Xã Phú Thành A",
    code: 30034,
    division_type: "xã",
    codename: "xa_phu_thanh_a",
    district_code: 871,
  },
  {
    name: "Thị trấn Mỹ An",
    code: 30037,
    division_type: "thị trấn",
    codename: "thi_tran_my_an",
    district_code: 872,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 30040,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 872,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 30043,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 872,
  },
  {
    name: "Xã Trường Xuân",
    code: 30046,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 872,
  },
  {
    name: "Xã Tân Kiều",
    code: 30049,
    division_type: "xã",
    codename: "xa_tan_kieu",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 30052,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Quý",
    code: 30055,
    division_type: "xã",
    codename: "xa_my_quy",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Đông",
    code: 30058,
    division_type: "xã",
    codename: "xa_my_dong",
    district_code: 872,
  },
  {
    name: "Xã Đốc Binh Kiều",
    code: 30061,
    division_type: "xã",
    codename: "xa_doc_binh_kieu",
    district_code: 872,
  },
  {
    name: "Xã Mỹ An",
    code: 30064,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 872,
  },
  {
    name: "Xã Phú Điền",
    code: 30067,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 872,
  },
  {
    name: "Xã Láng Biển",
    code: 30070,
    division_type: "xã",
    codename: "xa_lang_bien",
    district_code: 872,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 30073,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 872,
  },
  {
    name: "Thị trấn Mỹ Thọ",
    code: 30076,
    division_type: "thị trấn",
    codename: "thi_tran_my_tho",
    district_code: 873,
  },
  {
    name: "Xã Gáo Giồng",
    code: 30079,
    division_type: "xã",
    codename: "xa_gao_giong",
    district_code: 873,
  },
  {
    name: "Xã Phương Thịnh",
    code: 30082,
    division_type: "xã",
    codename: "xa_phuong_thinh",
    district_code: 873,
  },
  {
    name: "Xã Ba Sao",
    code: 30085,
    division_type: "xã",
    codename: "xa_ba_sao",
    district_code: 873,
  },
  {
    name: "Xã Phong Mỹ",
    code: 30088,
    division_type: "xã",
    codename: "xa_phong_my",
    district_code: 873,
  },
  {
    name: "Xã Tân Nghĩa",
    code: 30091,
    division_type: "xã",
    codename: "xa_tan_nghia",
    district_code: 873,
  },
  {
    name: "Xã Phương Trà",
    code: 30094,
    division_type: "xã",
    codename: "xa_phuong_tra",
    district_code: 873,
  },
  {
    name: "Xã Nhị Mỹ",
    code: 30097,
    division_type: "xã",
    codename: "xa_nhi_my",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Thọ",
    code: 30100,
    division_type: "xã",
    codename: "xa_my_tho",
    district_code: 873,
  },
  {
    name: "Xã Tân Hội Trung",
    code: 30103,
    division_type: "xã",
    codename: "xa_tan_hoi_trung",
    district_code: 873,
  },
  {
    name: "Xã An Bình",
    code: 30106,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Hội",
    code: 30109,
    division_type: "xã",
    codename: "xa_my_hoi",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 30112,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Long",
    code: 30115,
    division_type: "xã",
    codename: "xa_my_long",
    district_code: 873,
  },
  {
    name: "Xã Bình Hàng Trung",
    code: 30118,
    division_type: "xã",
    codename: "xa_binh_hang_trung",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Xương",
    code: 30121,
    division_type: "xã",
    codename: "xa_my_xuong",
    district_code: 873,
  },
  {
    name: "Xã Bình Hàng Tây",
    code: 30124,
    division_type: "xã",
    codename: "xa_binh_hang_tay",
    district_code: 873,
  },
  {
    name: "Xã Bình Thạnh",
    code: 30127,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 873,
  },
  {
    name: "Thị trấn Thanh Bình",
    code: 30130,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_binh",
    district_code: 874,
  },
  {
    name: "Xã Tân Quới",
    code: 30133,
    division_type: "xã",
    codename: "xa_tan_quoi",
    district_code: 874,
  },
  {
    name: "Xã Tân Hòa",
    code: 30136,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 874,
  },
  {
    name: "Xã An Phong",
    code: 30139,
    division_type: "xã",
    codename: "xa_an_phong",
    district_code: 874,
  },
  {
    name: "Xã Phú Lợi",
    code: 30142,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 874,
  },
  {
    name: "Xã Tân Mỹ",
    code: 30145,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 874,
  },
  {
    name: "Xã Bình Tấn",
    code: 30148,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 874,
  },
  {
    name: "Xã Tân Huề",
    code: 30151,
    division_type: "xã",
    codename: "xa_tan_hue",
    district_code: 874,
  },
  {
    name: "Xã Tân Bình",
    code: 30154,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 874,
  },
  {
    name: "Xã Tân Thạnh",
    code: 30157,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 874,
  },
  {
    name: "Xã Tân Phú",
    code: 30160,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 874,
  },
  {
    name: "Xã Bình Thành",
    code: 30163,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 874,
  },
  {
    name: "Xã Tân Long",
    code: 30166,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 874,
  },
  {
    name: "Thị trấn Lấp Vò",
    code: 30169,
    division_type: "thị trấn",
    codename: "thi_tran_lap_vo",
    district_code: 875,
  },
  {
    name: "Xã Mỹ An Hưng A",
    code: 30172,
    division_type: "xã",
    codename: "xa_my_an_hung_a",
    district_code: 875,
  },
  {
    name: "Xã Tân Mỹ",
    code: 30175,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 875,
  },
  {
    name: "Xã Mỹ An Hưng B",
    code: 30178,
    division_type: "xã",
    codename: "xa_my_an_hung_b",
    district_code: 875,
  },
  {
    name: "Xã Tân Khánh Trung",
    code: 30181,
    division_type: "xã",
    codename: "xa_tan_khanh_trung",
    district_code: 875,
  },
  {
    name: "Xã Long Hưng A",
    code: 30184,
    division_type: "xã",
    codename: "xa_long_hung_a",
    district_code: 875,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 30187,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 875,
  },
  {
    name: "Xã Long Hưng B",
    code: 30190,
    division_type: "xã",
    codename: "xa_long_hung_b",
    district_code: 875,
  },
  {
    name: "Xã Bình Thành",
    code: 30193,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 875,
  },
  {
    name: "Xã Định An",
    code: 30196,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 875,
  },
  {
    name: "Xã Định Yên",
    code: 30199,
    division_type: "xã",
    codename: "xa_dinh_yen",
    district_code: 875,
  },
  {
    name: "Xã Hội An Đông",
    code: 30202,
    division_type: "xã",
    codename: "xa_hoi_an_dong",
    district_code: 875,
  },
  {
    name: "Xã Bình Thạnh Trung",
    code: 30205,
    division_type: "xã",
    codename: "xa_binh_thanh_trung",
    district_code: 875,
  },
  {
    name: "Thị trấn Lai Vung",
    code: 30208,
    division_type: "thị trấn",
    codename: "thi_tran_lai_vung",
    district_code: 876,
  },
  {
    name: "Xã Tân Dương",
    code: 30211,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 876,
  },
  {
    name: "Xã Hòa Thành",
    code: 30214,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 876,
  },
  {
    name: "Xã Long Hậu",
    code: 30217,
    division_type: "xã",
    codename: "xa_long_hau",
    district_code: 876,
  },
  {
    name: "Xã Tân Phước",
    code: 30220,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 876,
  },
  {
    name: "Xã Hòa Long",
    code: 30223,
    division_type: "xã",
    codename: "xa_hoa_long",
    district_code: 876,
  },
  {
    name: "Xã Tân Thành",
    code: 30226,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 876,
  },
  {
    name: "Xã Long Thắng",
    code: 30229,
    division_type: "xã",
    codename: "xa_long_thang",
    district_code: 876,
  },
  {
    name: "Xã Vĩnh Thới",
    code: 30232,
    division_type: "xã",
    codename: "xa_vinh_thoi",
    district_code: 876,
  },
  {
    name: "Xã Tân Hòa",
    code: 30235,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 876,
  },
  {
    name: "Xã Định Hòa",
    code: 30238,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 876,
  },
  {
    name: "Xã Phong Hòa",
    code: 30241,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 876,
  },
  {
    name: "Thị trấn Cái Tàu Hạ",
    code: 30244,
    division_type: "thị trấn",
    codename: "thi_tran_cai_tau_ha",
    district_code: 877,
  },
  {
    name: "Xã An Hiệp",
    code: 30247,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 877,
  },
  {
    name: "Xã An Nhơn",
    code: 30250,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 877,
  },
  {
    name: "Xã Tân Nhuận Đông",
    code: 30253,
    division_type: "xã",
    codename: "xa_tan_nhuan_dong",
    district_code: 877,
  },
  {
    name: "Xã Tân Bình",
    code: 30256,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 877,
  },
  {
    name: "Xã Tân Phú Trung",
    code: 30259,
    division_type: "xã",
    codename: "xa_tan_phu_trung",
    district_code: 877,
  },
  {
    name: "Xã Phú Long",
    code: 30262,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 877,
  },
  {
    name: "Xã An Phú Thuận",
    code: 30265,
    division_type: "xã",
    codename: "xa_an_phu_thuan",
    district_code: 877,
  },
  {
    name: "Xã Phú Hựu",
    code: 30268,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 877,
  },
  {
    name: "Xã An Khánh",
    code: 30271,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 877,
  },
  {
    name: "Xã Tân Phú",
    code: 30274,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 877,
  },
  {
    name: "Xã Hòa Tân",
    code: 30277,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 877,
  },
  {
    name: "Phường Mỹ Bình",
    code: 30280,
    division_type: "phường",
    codename: "phuong_my_binh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Long",
    code: 30283,
    division_type: "phường",
    codename: "phuong_my_long",
    district_code: 883,
  },
  {
    name: "Phường Đông Xuyên",
    code: 30285,
    division_type: "phường",
    codename: "phuong_dong_xuyen",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Xuyên",
    code: 30286,
    division_type: "phường",
    codename: "phuong_my_xuyen",
    district_code: 883,
  },
  {
    name: "Phường Bình Đức",
    code: 30289,
    division_type: "phường",
    codename: "phuong_binh_duc",
    district_code: 883,
  },
  {
    name: "Phường Bình Khánh",
    code: 30292,
    division_type: "phường",
    codename: "phuong_binh_khanh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Phước",
    code: 30295,
    division_type: "phường",
    codename: "phuong_my_phuoc",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Quý",
    code: 30298,
    division_type: "phường",
    codename: "phuong_my_quy",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Thới",
    code: 30301,
    division_type: "phường",
    codename: "phuong_my_thoi",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Thạnh",
    code: 30304,
    division_type: "phường",
    codename: "phuong_my_thanh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Hòa",
    code: 30307,
    division_type: "phường",
    codename: "phuong_my_hoa",
    district_code: 883,
  },
  {
    name: "Xã Mỹ Khánh",
    code: 30310,
    division_type: "xã",
    codename: "xa_my_khanh",
    district_code: 883,
  },
  {
    name: "Xã Mỹ Hoà Hưng",
    code: 30313,
    division_type: "xã",
    codename: "xa_my_hoa_hung",
    district_code: 883,
  },
  {
    name: "Phường Châu Phú B",
    code: 30316,
    division_type: "phường",
    codename: "phuong_chau_phu_b",
    district_code: 884,
  },
  {
    name: "Phường Châu Phú A",
    code: 30319,
    division_type: "phường",
    codename: "phuong_chau_phu_a",
    district_code: 884,
  },
  {
    name: "Phường Vĩnh Mỹ",
    code: 30322,
    division_type: "phường",
    codename: "phuong_vinh_my",
    district_code: 884,
  },
  {
    name: "Phường Núi Sam",
    code: 30325,
    division_type: "phường",
    codename: "phuong_nui_sam",
    district_code: 884,
  },
  {
    name: "Phường Vĩnh Ngươn",
    code: 30328,
    division_type: "phường",
    codename: "phuong_vinh_nguon",
    district_code: 884,
  },
  {
    name: "Xã Vĩnh Tế",
    code: 30331,
    division_type: "xã",
    codename: "xa_vinh_te",
    district_code: 884,
  },
  {
    name: "Xã Vĩnh Châu",
    code: 30334,
    division_type: "xã",
    codename: "xa_vinh_chau",
    district_code: 884,
  },
  {
    name: "Thị trấn An Phú",
    code: 30337,
    division_type: "thị trấn",
    codename: "thi_tran_an_phu",
    district_code: 886,
  },
  {
    name: "Xã Khánh An",
    code: 30340,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 886,
  },
  {
    name: "Thị trấn Long Bình",
    code: 30341,
    division_type: "thị trấn",
    codename: "thi_tran_long_binh",
    district_code: 886,
  },
  {
    name: "Xã Khánh Bình",
    code: 30343,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 886,
  },
  {
    name: "Xã Quốc Thái",
    code: 30346,
    division_type: "xã",
    codename: "xa_quoc_thai",
    district_code: 886,
  },
  {
    name: "Xã Nhơn Hội",
    code: 30349,
    division_type: "xã",
    codename: "xa_nhon_hoi",
    district_code: 886,
  },
  {
    name: "Xã Phú Hữu",
    code: 30352,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 886,
  },
  {
    name: "Xã Phú Hội",
    code: 30355,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 886,
  },
  {
    name: "Xã Phước Hưng",
    code: 30358,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Lộc",
    code: 30361,
    division_type: "xã",
    codename: "xa_vinh_loc",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Hậu",
    code: 30364,
    division_type: "xã",
    codename: "xa_vinh_hau",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Trường",
    code: 30367,
    division_type: "xã",
    codename: "xa_vinh_truong",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Hội Đông",
    code: 30370,
    division_type: "xã",
    codename: "xa_vinh_hoi_dong",
    district_code: 886,
  },
  {
    name: "Xã Đa Phước",
    code: 30373,
    division_type: "xã",
    codename: "xa_da_phuoc",
    district_code: 886,
  },
  {
    name: "Phường Long Thạnh",
    code: 30376,
    division_type: "phường",
    codename: "phuong_long_thanh",
    district_code: 887,
  },
  {
    name: "Phường Long Hưng",
    code: 30377,
    division_type: "phường",
    codename: "phuong_long_hung",
    district_code: 887,
  },
  {
    name: "Phường Long Châu",
    code: 30378,
    division_type: "phường",
    codename: "phuong_long_chau",
    district_code: 887,
  },
  {
    name: "Xã Phú Lộc",
    code: 30379,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 887,
  },
  {
    name: "Xã Vĩnh Xương",
    code: 30382,
    division_type: "xã",
    codename: "xa_vinh_xuong",
    district_code: 887,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 30385,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 887,
  },
  {
    name: "Xã Tân Thạnh",
    code: 30387,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 887,
  },
  {
    name: "Xã Tân An",
    code: 30388,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 887,
  },
  {
    name: "Xã Long An",
    code: 30391,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 887,
  },
  {
    name: "Phường Long Phú",
    code: 30394,
    division_type: "phường",
    codename: "phuong_long_phu",
    district_code: 887,
  },
  {
    name: "Xã Châu Phong",
    code: 30397,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 887,
  },
  {
    name: "Xã Phú Vĩnh",
    code: 30400,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 887,
  },
  {
    name: "Xã Lê Chánh",
    code: 30403,
    division_type: "xã",
    codename: "xa_le_chanh",
    district_code: 887,
  },
  {
    name: "Phường Long Sơn",
    code: 30412,
    division_type: "phường",
    codename: "phuong_long_son",
    district_code: 887,
  },
  {
    name: "Thị trấn Phú Mỹ",
    code: 30406,
    division_type: "thị trấn",
    codename: "thi_tran_phu_my",
    district_code: 888,
  },
  {
    name: "Thị trấn Chợ Vàm",
    code: 30409,
    division_type: "thị trấn",
    codename: "thi_tran_cho_vam",
    district_code: 888,
  },
  {
    name: "Xã Long Hoà",
    code: 30415,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 888,
  },
  {
    name: "Xã Phú Long",
    code: 30418,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 888,
  },
  {
    name: "Xã Phú Lâm",
    code: 30421,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 888,
  },
  {
    name: "Xã Phú Hiệp",
    code: 30424,
    division_type: "xã",
    codename: "xa_phu_hiep",
    district_code: 888,
  },
  {
    name: "Xã Phú Thạnh",
    code: 30427,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 888,
  },
  {
    name: "Xã Hoà Lạc",
    code: 30430,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 888,
  },
  {
    name: "Xã Phú Thành",
    code: 30433,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 888,
  },
  {
    name: "Xã Phú An",
    code: 30436,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 888,
  },
  {
    name: "Xã Phú Xuân",
    code: 30439,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 888,
  },
  {
    name: "Xã Hiệp Xương",
    code: 30442,
    division_type: "xã",
    codename: "xa_hiep_xuong",
    district_code: 888,
  },
  {
    name: "Xã Phú Bình",
    code: 30445,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 888,
  },
  {
    name: "Xã Phú Thọ",
    code: 30448,
    division_type: "xã",
    codename: "xa_phu_tho",
    district_code: 888,
  },
  {
    name: "Xã Phú Hưng",
    code: 30451,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 888,
  },
  {
    name: "Xã Bình Thạnh Đông",
    code: 30454,
    division_type: "xã",
    codename: "xa_binh_thanh_dong",
    district_code: 888,
  },
  {
    name: "Xã Tân Hòa",
    code: 30457,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 888,
  },
  {
    name: "Xã Tân Trung",
    code: 30460,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 888,
  },
  {
    name: "Thị trấn Cái Dầu",
    code: 30463,
    division_type: "thị trấn",
    codename: "thi_tran_cai_dau",
    district_code: 889,
  },
  {
    name: "Xã Khánh Hòa",
    code: 30466,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 889,
  },
  {
    name: "Xã Mỹ Đức",
    code: 30469,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 889,
  },
  {
    name: "Xã Mỹ Phú",
    code: 30472,
    division_type: "xã",
    codename: "xa_my_phu",
    district_code: 889,
  },
  {
    name: "Xã Ô Long Vỹ",
    code: 30475,
    division_type: "xã",
    codename: "xa_o_long_vy",
    district_code: 889,
  },
  {
    name: "Thị trấn Vĩnh Thạnh Trung",
    code: 30478,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh_trung",
    district_code: 889,
  },
  {
    name: "Xã Thạnh Mỹ Tây",
    code: 30481,
    division_type: "xã",
    codename: "xa_thanh_my_tay",
    district_code: 889,
  },
  {
    name: "Xã Bình Long",
    code: 30484,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 889,
  },
  {
    name: "Xã Bình Mỹ",
    code: 30487,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 889,
  },
  {
    name: "Xã Bình Thủy",
    code: 30490,
    division_type: "xã",
    codename: "xa_binh_thuy",
    district_code: 889,
  },
  {
    name: "Xã Đào Hữu Cảnh",
    code: 30493,
    division_type: "xã",
    codename: "xa_dao_huu_canh",
    district_code: 889,
  },
  {
    name: "Xã Bình Phú",
    code: 30496,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 889,
  },
  {
    name: "Xã Bình Chánh",
    code: 30499,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 889,
  },
  {
    name: "Thị trấn Nhà Bàng",
    code: 30502,
    division_type: "thị trấn",
    codename: "thi_tran_nha_bang",
    district_code: 890,
  },
  {
    name: "Thị trấn Chi Lăng",
    code: 30505,
    division_type: "thị trấn",
    codename: "thi_tran_chi_lang",
    district_code: 890,
  },
  {
    name: "Xã Núi Voi",
    code: 30508,
    division_type: "xã",
    codename: "xa_nui_voi",
    district_code: 890,
  },
  {
    name: "Xã Nhơn Hưng",
    code: 30511,
    division_type: "xã",
    codename: "xa_nhon_hung",
    district_code: 890,
  },
  {
    name: "Xã An Phú",
    code: 30514,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 890,
  },
  {
    name: "Xã Thới Sơn",
    code: 30517,
    division_type: "xã",
    codename: "xa_thoi_son",
    district_code: 890,
  },
  {
    name: "Thị trấn Tịnh Biên",
    code: 30520,
    division_type: "thị trấn",
    codename: "thi_tran_tinh_bien",
    district_code: 890,
  },
  {
    name: "Xã Văn Giáo",
    code: 30523,
    division_type: "xã",
    codename: "xa_van_giao",
    district_code: 890,
  },
  {
    name: "Xã An Cư",
    code: 30526,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 890,
  },
  {
    name: "Xã An Nông",
    code: 30529,
    division_type: "xã",
    codename: "xa_an_nong",
    district_code: 890,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 30532,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 890,
  },
  {
    name: "Xã Tân Lợi",
    code: 30535,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 890,
  },
  {
    name: "Xã An Hảo",
    code: 30538,
    division_type: "xã",
    codename: "xa_an_hao",
    district_code: 890,
  },
  {
    name: "Xã Tân Lập",
    code: 30541,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 890,
  },
  {
    name: "Thị trấn Tri Tôn",
    code: 30544,
    division_type: "thị trấn",
    codename: "thi_tran_tri_ton",
    district_code: 891,
  },
  {
    name: "Thị trấn Ba Chúc",
    code: 30547,
    division_type: "thị trấn",
    codename: "thi_tran_ba_chuc",
    district_code: 891,
  },
  {
    name: "Xã Lạc Quới",
    code: 30550,
    division_type: "xã",
    codename: "xa_lac_quoi",
    district_code: 891,
  },
  {
    name: "Xã Lê Trì",
    code: 30553,
    division_type: "xã",
    codename: "xa_le_tri",
    district_code: 891,
  },
  {
    name: "Xã Vĩnh Gia",
    code: 30556,
    division_type: "xã",
    codename: "xa_vinh_gia",
    district_code: 891,
  },
  {
    name: "Xã Vĩnh Phước",
    code: 30559,
    division_type: "xã",
    codename: "xa_vinh_phuoc",
    district_code: 891,
  },
  {
    name: "Xã Châu Lăng",
    code: 30562,
    division_type: "xã",
    codename: "xa_chau_lang",
    district_code: 891,
  },
  {
    name: "Xã Lương Phi",
    code: 30565,
    division_type: "xã",
    codename: "xa_luong_phi",
    district_code: 891,
  },
  {
    name: "Xã Lương An Trà",
    code: 30568,
    division_type: "xã",
    codename: "xa_luong_an_tra",
    district_code: 891,
  },
  {
    name: "Xã Tà Đảnh",
    code: 30571,
    division_type: "xã",
    codename: "xa_ta_danh",
    district_code: 891,
  },
  {
    name: "Xã Núi Tô",
    code: 30574,
    division_type: "xã",
    codename: "xa_nui_to",
    district_code: 891,
  },
  {
    name: "Xã An Tức",
    code: 30577,
    division_type: "xã",
    codename: "xa_an_tuc",
    district_code: 891,
  },
  {
    name: "Thị trấn Cô Tô",
    code: 30580,
    division_type: "thị trấn",
    codename: "thi_tran_co_to",
    district_code: 891,
  },
  {
    name: "Xã Tân Tuyến",
    code: 30583,
    division_type: "xã",
    codename: "xa_tan_tuyen",
    district_code: 891,
  },
  {
    name: "Xã Ô Lâm",
    code: 30586,
    division_type: "xã",
    codename: "xa_o_lam",
    district_code: 891,
  },
  {
    name: "Thị trấn An Châu",
    code: 30589,
    division_type: "thị trấn",
    codename: "thi_tran_an_chau",
    district_code: 892,
  },
  {
    name: "Xã An Hòa",
    code: 30592,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 892,
  },
  {
    name: "Xã Cần Đăng",
    code: 30595,
    division_type: "xã",
    codename: "xa_can_dang",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Hanh",
    code: 30598,
    division_type: "xã",
    codename: "xa_vinh_hanh",
    district_code: 892,
  },
  {
    name: "Xã Bình Thạnh",
    code: 30601,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 892,
  },
  {
    name: "Thị trấn Vĩnh Bình",
    code: 30604,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_binh",
    district_code: 892,
  },
  {
    name: "Xã Bình Hòa",
    code: 30607,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh An",
    code: 30610,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 892,
  },
  {
    name: "Xã Hòa Bình Thạnh",
    code: 30613,
    division_type: "xã",
    codename: "xa_hoa_binh_thanh",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 30616,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Nhuận",
    code: 30619,
    division_type: "xã",
    codename: "xa_vinh_nhuan",
    district_code: 892,
  },
  {
    name: "Xã Tân Phú",
    code: 30622,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 30625,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 892,
  },
  {
    name: "Thị trấn Chợ Mới",
    code: 30628,
    division_type: "thị trấn",
    codename: "thi_tran_cho_moi",
    district_code: 893,
  },
  {
    name: "Thị trấn Mỹ Luông",
    code: 30631,
    division_type: "thị trấn",
    codename: "thi_tran_my_luong",
    district_code: 893,
  },
  {
    name: "Xã Kiến An",
    code: 30634,
    division_type: "xã",
    codename: "xa_kien_an",
    district_code: 893,
  },
  {
    name: "Xã Mỹ Hội Đông",
    code: 30637,
    division_type: "xã",
    codename: "xa_my_hoi_dong",
    district_code: 893,
  },
  {
    name: "Xã Long Điền A",
    code: 30640,
    division_type: "xã",
    codename: "xa_long_dien_a",
    district_code: 893,
  },
  {
    name: "Xã Tấn Mỹ",
    code: 30643,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 893,
  },
  {
    name: "Xã Long Điền B",
    code: 30646,
    division_type: "xã",
    codename: "xa_long_dien_b",
    district_code: 893,
  },
  {
    name: "Xã Kiến Thành",
    code: 30649,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 893,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 30652,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 893,
  },
  {
    name: "Xã Mỹ An",
    code: 30655,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 893,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 30658,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 893,
  },
  {
    name: "Xã Long Giang",
    code: 30661,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 893,
  },
  {
    name: "Xã Long Kiến",
    code: 30664,
    division_type: "xã",
    codename: "xa_long_kien",
    district_code: 893,
  },
  {
    name: "Xã Bình Phước Xuân",
    code: 30667,
    division_type: "xã",
    codename: "xa_binh_phuoc_xuan",
    district_code: 893,
  },
  {
    name: "Xã An Thạnh Trung",
    code: 30670,
    division_type: "xã",
    codename: "xa_an_thanh_trung",
    district_code: 893,
  },
  {
    name: "Xã Hội An",
    code: 30673,
    division_type: "xã",
    codename: "xa_hoi_an",
    district_code: 893,
  },
  {
    name: "Xã Hòa Bình",
    code: 30676,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 893,
  },
  {
    name: "Xã Hòa An",
    code: 30679,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 893,
  },
  {
    name: "Thị trấn Núi Sập",
    code: 30682,
    division_type: "thị trấn",
    codename: "thi_tran_nui_sap",
    district_code: 894,
  },
  {
    name: "Thị trấn Phú Hoà",
    code: 30685,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 894,
  },
  {
    name: "Thị trấn Óc Eo",
    code: 30688,
    division_type: "thị trấn",
    codename: "thi_tran_oc_eo",
    district_code: 894,
  },
  {
    name: "Xã Tây Phú",
    code: 30691,
    division_type: "xã",
    codename: "xa_tay_phu",
    district_code: 894,
  },
  {
    name: "Xã An Bình",
    code: 30692,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30694,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Trạch",
    code: 30697,
    division_type: "xã",
    codename: "xa_vinh_trach",
    district_code: 894,
  },
  {
    name: "Xã Phú Thuận",
    code: 30700,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Chánh",
    code: 30703,
    division_type: "xã",
    codename: "xa_vinh_chanh",
    district_code: 894,
  },
  {
    name: "Xã Định Mỹ",
    code: 30706,
    division_type: "xã",
    codename: "xa_dinh_my",
    district_code: 894,
  },
  {
    name: "Xã Định Thành",
    code: 30709,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 894,
  },
  {
    name: "Xã Mỹ Phú Đông",
    code: 30712,
    division_type: "xã",
    codename: "xa_my_phu_dong",
    district_code: 894,
  },
  {
    name: "Xã Vọng Đông",
    code: 30715,
    division_type: "xã",
    codename: "xa_vong_dong",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Khánh",
    code: 30718,
    division_type: "xã",
    codename: "xa_vinh_khanh",
    district_code: 894,
  },
  {
    name: "Xã Thoại Giang",
    code: 30721,
    division_type: "xã",
    codename: "xa_thoai_giang",
    district_code: 894,
  },
  {
    name: "Xã Bình Thành",
    code: 30724,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 894,
  },
  {
    name: "Xã Vọng Thê",
    code: 30727,
    division_type: "xã",
    codename: "xa_vong_the",
    district_code: 894,
  },
  {
    name: "Phường Vĩnh Thanh Vân",
    code: 30730,
    division_type: "phường",
    codename: "phuong_vinh_thanh_van",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Thanh",
    code: 30733,
    division_type: "phường",
    codename: "phuong_vinh_thanh",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Quang",
    code: 30736,
    division_type: "phường",
    codename: "phuong_vinh_quang",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Hiệp",
    code: 30739,
    division_type: "phường",
    codename: "phuong_vinh_hiep",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Bảo",
    code: 30742,
    division_type: "phường",
    codename: "phuong_vinh_bao",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Lạc",
    code: 30745,
    division_type: "phường",
    codename: "phuong_vinh_lac",
    district_code: 899,
  },
  {
    name: "Phường An Hòa",
    code: 30748,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 899,
  },
  {
    name: "Phường An Bình",
    code: 30751,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 899,
  },
  {
    name: "Phường Rạch Sỏi",
    code: 30754,
    division_type: "phường",
    codename: "phuong_rach_soi",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Lợi",
    code: 30757,
    division_type: "phường",
    codename: "phuong_vinh_loi",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Thông",
    code: 30760,
    division_type: "phường",
    codename: "phuong_vinh_thong",
    district_code: 899,
  },
  {
    name: "Xã Phi Thông",
    code: 30763,
    division_type: "xã",
    codename: "xa_phi_thong",
    district_code: 899,
  },
  {
    name: "Phường Tô Châu",
    code: 30766,
    division_type: "phường",
    codename: "phuong_to_chau",
    district_code: 900,
  },
  {
    name: "Phường Đông Hồ",
    code: 30769,
    division_type: "phường",
    codename: "phuong_dong_ho",
    district_code: 900,
  },
  {
    name: "Phường Bình San",
    code: 30772,
    division_type: "phường",
    codename: "phuong_binh_san",
    district_code: 900,
  },
  {
    name: "Phường Pháo Đài",
    code: 30775,
    division_type: "phường",
    codename: "phuong_phao_dai",
    district_code: 900,
  },
  {
    name: "Phường Mỹ Đức",
    code: 30778,
    division_type: "phường",
    codename: "phuong_my_duc",
    district_code: 900,
  },
  {
    name: "Xã Tiên Hải",
    code: 30781,
    division_type: "xã",
    codename: "xa_tien_hai",
    district_code: 900,
  },
  {
    name: "Xã Thuận Yên",
    code: 30784,
    division_type: "xã",
    codename: "xa_thuan_yen",
    district_code: 900,
  },
  {
    name: "Thị trấn Kiên Lương",
    code: 30787,
    division_type: "thị trấn",
    codename: "thi_tran_kien_luong",
    district_code: 902,
  },
  {
    name: "Xã Kiên Bình",
    code: 30790,
    division_type: "xã",
    codename: "xa_kien_binh",
    district_code: 902,
  },
  {
    name: "Xã Hòa Điền",
    code: 30802,
    division_type: "xã",
    codename: "xa_hoa_dien",
    district_code: 902,
  },
  {
    name: "Xã Dương Hòa",
    code: 30805,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 902,
  },
  {
    name: "Xã Bình An",
    code: 30808,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 902,
  },
  {
    name: "Xã Bình Trị",
    code: 30809,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 902,
  },
  {
    name: "Xã Sơn Hải",
    code: 30811,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 902,
  },
  {
    name: "Xã Hòn Nghệ",
    code: 30814,
    division_type: "xã",
    codename: "xa_hon_nghe",
    district_code: 902,
  },
  {
    name: "Thị trấn Hòn Đất",
    code: 30817,
    division_type: "thị trấn",
    codename: "thi_tran_hon_dat",
    district_code: 903,
  },
  {
    name: "Thị trấn Sóc Sơn",
    code: 30820,
    division_type: "thị trấn",
    codename: "thi_tran_soc_son",
    district_code: 903,
  },
  {
    name: "Xã Bình Sơn",
    code: 30823,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 903,
  },
  {
    name: "Xã Bình Giang",
    code: 30826,
    division_type: "xã",
    codename: "xa_binh_giang",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Thái",
    code: 30828,
    division_type: "xã",
    codename: "xa_my_thai",
    district_code: 903,
  },
  {
    name: "Xã Nam Thái Sơn",
    code: 30829,
    division_type: "xã",
    codename: "xa_nam_thai_son",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Hiệp Sơn",
    code: 30832,
    division_type: "xã",
    codename: "xa_my_hiep_son",
    district_code: 903,
  },
  {
    name: "Xã Sơn Kiên",
    code: 30835,
    division_type: "xã",
    codename: "xa_son_kien",
    district_code: 903,
  },
  {
    name: "Xã Sơn Bình",
    code: 30836,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 30838,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 903,
  },
  {
    name: "Xã Lình Huỳnh",
    code: 30840,
    division_type: "xã",
    codename: "xa_linh_huynh",
    district_code: 903,
  },
  {
    name: "Xã Thổ Sơn",
    code: 30841,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Lâm",
    code: 30844,
    division_type: "xã",
    codename: "xa_my_lam",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Phước",
    code: 30847,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 903,
  },
  {
    name: "Thị trấn Tân Hiệp",
    code: 30850,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hiep",
    district_code: 904,
  },
  {
    name: "Xã Tân Hội",
    code: 30853,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 904,
  },
  {
    name: "Xã Tân Thành",
    code: 30856,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 904,
  },
  {
    name: "Xã Tân Hiệp B",
    code: 30859,
    division_type: "xã",
    codename: "xa_tan_hiep_b",
    district_code: 904,
  },
  {
    name: "Xã Tân Hoà",
    code: 30860,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông B",
    code: 30862,
    division_type: "xã",
    codename: "xa_thanh_dong_b",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông",
    code: 30865,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 904,
  },
  {
    name: "Xã Tân Hiệp A",
    code: 30868,
    division_type: "xã",
    codename: "xa_tan_hiep_a",
    district_code: 904,
  },
  {
    name: "Xã Tân An",
    code: 30871,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông A",
    code: 30874,
    division_type: "xã",
    codename: "xa_thanh_dong_a",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Trị",
    code: 30877,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 904,
  },
  {
    name: "Thị trấn Minh Lương",
    code: 30880,
    division_type: "thị trấn",
    codename: "thi_tran_minh_luong",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ A",
    code: 30883,
    division_type: "xã",
    codename: "xa_mong_tho_a",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ B",
    code: 30886,
    division_type: "xã",
    codename: "xa_mong_tho_b",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ",
    code: 30887,
    division_type: "xã",
    codename: "xa_mong_tho",
    district_code: 905,
  },
  {
    name: "Xã Giục Tượng",
    code: 30889,
    division_type: "xã",
    codename: "xa_giuc_tuong",
    district_code: 905,
  },
  {
    name: "Xã Vĩnh Hòa Hiệp",
    code: 30892,
    division_type: "xã",
    codename: "xa_vinh_hoa_hiep",
    district_code: 905,
  },
  {
    name: "Xã Vĩnh Hoà Phú",
    code: 30893,
    division_type: "xã",
    codename: "xa_vinh_hoa_phu",
    district_code: 905,
  },
  {
    name: "Xã Minh Hòa",
    code: 30895,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 905,
  },
  {
    name: "Xã Bình An",
    code: 30898,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 905,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 30901,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 905,
  },
  {
    name: "Thị trấn Giồng Riềng",
    code: 30904,
    division_type: "thị trấn",
    codename: "thi_tran_giong_rieng",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 30907,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Phước",
    code: 30910,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 30913,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 30916,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Bình",
    code: 30917,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 906,
  },
  {
    name: "Xã Bàn Thạch",
    code: 30919,
    division_type: "xã",
    codename: "xa_ban_thach",
    district_code: 906,
  },
  {
    name: "Xã Bàn Tân Định",
    code: 30922,
    division_type: "xã",
    codename: "xa_ban_tan_dinh",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Thành",
    code: 30925,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Chúc",
    code: 30928,
    division_type: "xã",
    codename: "xa_ngoc_chuc",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Thuận",
    code: 30931,
    division_type: "xã",
    codename: "xa_ngoc_thuan",
    district_code: 906,
  },
  {
    name: "Xã Hòa Hưng",
    code: 30934,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 906,
  },
  {
    name: "Xã Hoà Lợi",
    code: 30937,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 906,
  },
  {
    name: "Xã Hoà An",
    code: 30940,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 906,
  },
  {
    name: "Xã Long Thạnh",
    code: 30943,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 906,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 30946,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 906,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30947,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 906,
  },
  {
    name: "Xã Hòa Thuận",
    code: 30949,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Hoà",
    code: 30950,
    division_type: "xã",
    codename: "xa_ngoc_hoa",
    district_code: 906,
  },
  {
    name: "Thị trấn Gò Quao",
    code: 30952,
    division_type: "thị trấn",
    codename: "thi_tran_go_quao",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Hòa Hưng Bắc",
    code: 30955,
    division_type: "xã",
    codename: "xa_vinh_hoa_hung_bac",
    district_code: 907,
  },
  {
    name: "Xã Định Hòa",
    code: 30958,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 907,
  },
  {
    name: "Xã Thới Quản",
    code: 30961,
    division_type: "xã",
    codename: "xa_thoi_quan",
    district_code: 907,
  },
  {
    name: "Xã Định An",
    code: 30964,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 907,
  },
  {
    name: "Xã Thủy Liễu",
    code: 30967,
    division_type: "xã",
    codename: "xa_thuy_lieu",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Hòa Hưng Nam",
    code: 30970,
    division_type: "xã",
    codename: "xa_vinh_hoa_hung_nam",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Phước A",
    code: 30973,
    division_type: "xã",
    codename: "xa_vinh_phuoc_a",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Phước B",
    code: 30976,
    division_type: "xã",
    codename: "xa_vinh_phuoc_b",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Tuy",
    code: 30979,
    division_type: "xã",
    codename: "xa_vinh_tuy",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Thắng",
    code: 30982,
    division_type: "xã",
    codename: "xa_vinh_thang",
    district_code: 907,
  },
  {
    name: "Thị trấn Thứ Ba",
    code: 30985,
    division_type: "thị trấn",
    codename: "thi_tran_thu_ba",
    district_code: 908,
  },
  {
    name: "Xã Tây Yên",
    code: 30988,
    division_type: "xã",
    codename: "xa_tay_yen",
    district_code: 908,
  },
  {
    name: "Xã Tây Yên A",
    code: 30991,
    division_type: "xã",
    codename: "xa_tay_yen_a",
    district_code: 908,
  },
  {
    name: "Xã Nam Yên",
    code: 30994,
    division_type: "xã",
    codename: "xa_nam_yen",
    district_code: 908,
  },
  {
    name: "Xã Hưng Yên",
    code: 30997,
    division_type: "xã",
    codename: "xa_hung_yen",
    district_code: 908,
  },
  {
    name: "Xã Nam Thái",
    code: 31000,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 908,
  },
  {
    name: "Xã Nam Thái A",
    code: 31003,
    division_type: "xã",
    codename: "xa_nam_thai_a",
    district_code: 908,
  },
  {
    name: "Xã Đông Thái",
    code: 31006,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 908,
  },
  {
    name: "Xã Đông Yên",
    code: 31009,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 908,
  },
  {
    name: "Thị trấn Thứ Mười Một",
    code: 31018,
    division_type: "thị trấn",
    codename: "thi_tran_thu_muoi_mot",
    district_code: 909,
  },
  {
    name: "Xã Thuận Hoà",
    code: 31021,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 909,
  },
  {
    name: "Xã Đông Hòa",
    code: 31024,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 909,
  },
  {
    name: "Xã Đông Thạnh",
    code: 31030,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 909,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31031,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng",
    code: 31033,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng A",
    code: 31036,
    division_type: "xã",
    codename: "xa_dong_hung_a",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng B",
    code: 31039,
    division_type: "xã",
    codename: "xa_dong_hung_b",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh",
    code: 31042,
    division_type: "xã",
    codename: "xa_van_khanh",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh Đông",
    code: 31045,
    division_type: "xã",
    codename: "xa_van_khanh_dong",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh Tây",
    code: 31048,
    division_type: "xã",
    codename: "xa_van_khanh_tay",
    district_code: 909,
  },
  {
    name: "Thị trấn Vĩnh Thuận",
    code: 31051,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thuan",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Bình Bắc",
    code: 31060,
    division_type: "xã",
    codename: "xa_vinh_binh_bac",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Bình Nam",
    code: 31063,
    division_type: "xã",
    codename: "xa_vinh_binh_nam",
    district_code: 910,
  },
  {
    name: "Xã Bình Minh",
    code: 31064,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 31069,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 910,
  },
  {
    name: "Xã Tân Thuận",
    code: 31072,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 910,
  },
  {
    name: "Xã Phong Đông",
    code: 31074,
    division_type: "xã",
    codename: "xa_phong_dong",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 31075,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 910,
  },
  {
    name: "Phường Dương Đông",
    code: 31078,
    division_type: "phường",
    codename: "phuong_duong_dong",
    district_code: 911,
  },
  {
    name: "Phường An Thới",
    code: 31081,
    division_type: "phường",
    codename: "phuong_an_thoi",
    district_code: 911,
  },
  {
    name: "Xã Cửa Cạn",
    code: 31084,
    division_type: "xã",
    codename: "xa_cua_can",
    district_code: 911,
  },
  {
    name: "Xã Gành Dầu",
    code: 31087,
    division_type: "xã",
    codename: "xa_ganh_dau",
    district_code: 911,
  },
  {
    name: "Xã Cửa Dương",
    code: 31090,
    division_type: "xã",
    codename: "xa_cua_duong",
    district_code: 911,
  },
  {
    name: "Xã Hàm Ninh",
    code: 31093,
    division_type: "xã",
    codename: "xa_ham_ninh",
    district_code: 911,
  },
  {
    name: "Xã Dương Tơ",
    code: 31096,
    division_type: "xã",
    codename: "xa_duong_to",
    district_code: 911,
  },
  {
    name: "Xã Bãi Thơm",
    code: 31102,
    division_type: "xã",
    codename: "xa_bai_thom",
    district_code: 911,
  },
  {
    name: "Xã Thổ Châu",
    code: 31105,
    division_type: "xã",
    codename: "xa_tho_chau",
    district_code: 911,
  },
  {
    name: "Xã Hòn Tre",
    code: 31108,
    division_type: "xã",
    codename: "xa_hon_tre",
    district_code: 912,
  },
  {
    name: "Xã Lại Sơn",
    code: 31111,
    division_type: "xã",
    codename: "xa_lai_son",
    district_code: 912,
  },
  {
    name: "Xã An Sơn",
    code: 31114,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 912,
  },
  {
    name: "Xã Nam Du",
    code: 31115,
    division_type: "xã",
    codename: "xa_nam_du",
    district_code: 912,
  },
  {
    name: "Xã Thạnh Yên",
    code: 31012,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 913,
  },
  {
    name: "Xã Thạnh Yên A",
    code: 31015,
    division_type: "xã",
    codename: "xa_thanh_yen_a",
    district_code: 913,
  },
  {
    name: "Xã An Minh Bắc",
    code: 31027,
    division_type: "xã",
    codename: "xa_an_minh_bac",
    district_code: 913,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 31054,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 913,
  },
  {
    name: "Xã Hoà Chánh",
    code: 31057,
    division_type: "xã",
    codename: "xa_hoa_chanh",
    district_code: 913,
  },
  {
    name: "Xã Minh Thuận",
    code: 31066,
    division_type: "xã",
    codename: "xa_minh_thuan",
    district_code: 913,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30791,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 914,
  },
  {
    name: "Xã Vĩnh Điều",
    code: 30793,
    division_type: "xã",
    codename: "xa_vinh_dieu",
    district_code: 914,
  },
  {
    name: "Xã Tân Khánh Hòa",
    code: 30796,
    division_type: "xã",
    codename: "xa_tan_khanh_hoa",
    district_code: 914,
  },
  {
    name: "Xã Phú Lợi",
    code: 30797,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 914,
  },
  {
    name: "Xã Phú Mỹ",
    code: 30799,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 914,
  },
  {
    name: "Phường Cái Khế",
    code: 31117,
    division_type: "phường",
    codename: "phuong_cai_khe",
    district_code: 916,
  },
  {
    name: "Phường An Hòa",
    code: 31120,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 916,
  },
  {
    name: "Phường Thới Bình",
    code: 31123,
    division_type: "phường",
    codename: "phuong_thoi_binh",
    district_code: 916,
  },
  {
    name: "Phường An Nghiệp",
    code: 31126,
    division_type: "phường",
    codename: "phuong_an_nghiep",
    district_code: 916,
  },
  {
    name: "Phường An Cư",
    code: 31129,
    division_type: "phường",
    codename: "phuong_an_cu",
    district_code: 916,
  },
  {
    name: "Phường Tân An",
    code: 31135,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 916,
  },
  {
    name: "Phường An Phú",
    code: 31141,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 916,
  },
  {
    name: "Phường Xuân Khánh",
    code: 31144,
    division_type: "phường",
    codename: "phuong_xuan_khanh",
    district_code: 916,
  },
  {
    name: "Phường Hưng Lợi",
    code: 31147,
    division_type: "phường",
    codename: "phuong_hung_loi",
    district_code: 916,
  },
  {
    name: "Phường An Khánh",
    code: 31149,
    division_type: "phường",
    codename: "phuong_an_khanh",
    district_code: 916,
  },
  {
    name: "Phường An Bình",
    code: 31150,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 916,
  },
  {
    name: "Phường Châu Văn Liêm",
    code: 31153,
    division_type: "phường",
    codename: "phuong_chau_van_liem",
    district_code: 917,
  },
  {
    name: "Phường Thới Hòa",
    code: 31154,
    division_type: "phường",
    codename: "phuong_thoi_hoa",
    district_code: 917,
  },
  {
    name: "Phường Thới Long",
    code: 31156,
    division_type: "phường",
    codename: "phuong_thoi_long",
    district_code: 917,
  },
  {
    name: "Phường Long Hưng",
    code: 31157,
    division_type: "phường",
    codename: "phuong_long_hung",
    district_code: 917,
  },
  {
    name: "Phường Thới An",
    code: 31159,
    division_type: "phường",
    codename: "phuong_thoi_an",
    district_code: 917,
  },
  {
    name: "Phường Phước Thới",
    code: 31162,
    division_type: "phường",
    codename: "phuong_phuoc_thoi",
    district_code: 917,
  },
  {
    name: "Phường Trường Lạc",
    code: 31165,
    division_type: "phường",
    codename: "phuong_truong_lac",
    district_code: 917,
  },
  {
    name: "Phường Bình Thủy",
    code: 31168,
    division_type: "phường",
    codename: "phuong_binh_thuy",
    district_code: 918,
  },
  {
    name: "Phường Trà An",
    code: 31169,
    division_type: "phường",
    codename: "phuong_tra_an",
    district_code: 918,
  },
  {
    name: "Phường Trà Nóc",
    code: 31171,
    division_type: "phường",
    codename: "phuong_tra_noc",
    district_code: 918,
  },
  {
    name: "Phường Thới An Đông",
    code: 31174,
    division_type: "phường",
    codename: "phuong_thoi_an_dong",
    district_code: 918,
  },
  {
    name: "Phường An Thới",
    code: 31177,
    division_type: "phường",
    codename: "phuong_an_thoi",
    district_code: 918,
  },
  {
    name: "Phường Bùi Hữu Nghĩa",
    code: 31178,
    division_type: "phường",
    codename: "phuong_bui_huu_nghia",
    district_code: 918,
  },
  {
    name: "Phường Long Hòa",
    code: 31180,
    division_type: "phường",
    codename: "phuong_long_hoa",
    district_code: 918,
  },
  {
    name: "Phường Long Tuyền",
    code: 31183,
    division_type: "phường",
    codename: "phuong_long_tuyen",
    district_code: 918,
  },
  {
    name: "Phường Lê Bình",
    code: 31186,
    division_type: "phường",
    codename: "phuong_le_binh",
    district_code: 919,
  },
  {
    name: "Phường Hưng Phú",
    code: 31189,
    division_type: "phường",
    codename: "phuong_hung_phu",
    district_code: 919,
  },
  {
    name: "Phường Hưng Thạnh",
    code: 31192,
    division_type: "phường",
    codename: "phuong_hung_thanh",
    district_code: 919,
  },
  {
    name: "Phường Ba Láng",
    code: 31195,
    division_type: "phường",
    codename: "phuong_ba_lang",
    district_code: 919,
  },
  {
    name: "Phường Thường Thạnh",
    code: 31198,
    division_type: "phường",
    codename: "phuong_thuong_thanh",
    district_code: 919,
  },
  {
    name: "Phường Phú Thứ",
    code: 31201,
    division_type: "phường",
    codename: "phuong_phu_thu",
    district_code: 919,
  },
  {
    name: "Phường Tân Phú",
    code: 31204,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 919,
  },
  {
    name: "Phường Thốt Nốt",
    code: 31207,
    division_type: "phường",
    codename: "phuong_thot_not",
    district_code: 923,
  },
  {
    name: "Phường Thới Thuận",
    code: 31210,
    division_type: "phường",
    codename: "phuong_thoi_thuan",
    district_code: 923,
  },
  {
    name: "Phường Thuận An",
    code: 31212,
    division_type: "phường",
    codename: "phuong_thuan_an",
    district_code: 923,
  },
  {
    name: "Phường Tân Lộc",
    code: 31213,
    division_type: "phường",
    codename: "phuong_tan_loc",
    district_code: 923,
  },
  {
    name: "Phường Trung Nhứt",
    code: 31216,
    division_type: "phường",
    codename: "phuong_trung_nhut",
    district_code: 923,
  },
  {
    name: "Phường Thạnh Hoà",
    code: 31217,
    division_type: "phường",
    codename: "phuong_thanh_hoa",
    district_code: 923,
  },
  {
    name: "Phường Trung Kiên",
    code: 31219,
    division_type: "phường",
    codename: "phuong_trung_kien",
    district_code: 923,
  },
  {
    name: "Phường Tân Hưng",
    code: 31227,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 923,
  },
  {
    name: "Phường Thuận Hưng",
    code: 31228,
    division_type: "phường",
    codename: "phuong_thuan_hung",
    district_code: 923,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 31211,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 924,
  },
  {
    name: "Thị trấn Thanh An",
    code: 31231,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_an",
    district_code: 924,
  },
  {
    name: "Thị trấn Vĩnh Thạnh",
    code: 31232,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Mỹ",
    code: 31234,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 924,
  },
  {
    name: "Xã Vĩnh Trinh",
    code: 31237,
    division_type: "xã",
    codename: "xa_vinh_trinh",
    district_code: 924,
  },
  {
    name: "Xã Thạnh An",
    code: 31240,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Tiến",
    code: 31241,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Thắng",
    code: 31243,
    division_type: "xã",
    codename: "xa_thanh_thang",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 31244,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Qưới",
    code: 31246,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 31252,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 924,
  },
  {
    name: "Xã Trung An",
    code: 31222,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 925,
  },
  {
    name: "Xã Trung Thạnh",
    code: 31225,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 925,
  },
  {
    name: "Xã Thạnh Phú",
    code: 31249,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 925,
  },
  {
    name: "Xã Trung Hưng",
    code: 31255,
    division_type: "xã",
    codename: "xa_trung_hung",
    district_code: 925,
  },
  {
    name: "Thị trấn Cờ Đỏ",
    code: 31261,
    division_type: "thị trấn",
    codename: "thi_tran_co_do",
    district_code: 925,
  },
  {
    name: "Xã Thới Hưng",
    code: 31264,
    division_type: "xã",
    codename: "xa_thoi_hung",
    district_code: 925,
  },
  {
    name: "Xã Đông Hiệp",
    code: 31273,
    division_type: "xã",
    codename: "xa_dong_hiep",
    district_code: 925,
  },
  {
    name: "Xã Đông Thắng",
    code: 31274,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 925,
  },
  {
    name: "Xã Thới Đông",
    code: 31276,
    division_type: "xã",
    codename: "xa_thoi_dong",
    district_code: 925,
  },
  {
    name: "Xã Thới Xuân",
    code: 31277,
    division_type: "xã",
    codename: "xa_thoi_xuan",
    district_code: 925,
  },
  {
    name: "Thị trấn Phong Điền",
    code: 31299,
    division_type: "thị trấn",
    codename: "thi_tran_phong_dien",
    district_code: 926,
  },
  {
    name: "Xã Nhơn Ái",
    code: 31300,
    division_type: "xã",
    codename: "xa_nhon_ai",
    district_code: 926,
  },
  {
    name: "Xã Giai Xuân",
    code: 31303,
    division_type: "xã",
    codename: "xa_giai_xuan",
    district_code: 926,
  },
  {
    name: "Xã Tân Thới",
    code: 31306,
    division_type: "xã",
    codename: "xa_tan_thoi",
    district_code: 926,
  },
  {
    name: "Xã Trường Long",
    code: 31309,
    division_type: "xã",
    codename: "xa_truong_long",
    district_code: 926,
  },
  {
    name: "Xã Mỹ Khánh",
    code: 31312,
    division_type: "xã",
    codename: "xa_my_khanh",
    district_code: 926,
  },
  {
    name: "Xã Nhơn Nghĩa",
    code: 31315,
    division_type: "xã",
    codename: "xa_nhon_nghia",
    district_code: 926,
  },
  {
    name: "Thị trấn Thới Lai",
    code: 31258,
    division_type: "thị trấn",
    codename: "thi_tran_thoi_lai",
    district_code: 927,
  },
  {
    name: "Xã Thới Thạnh",
    code: 31267,
    division_type: "xã",
    codename: "xa_thoi_thanh",
    district_code: 927,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31268,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 927,
  },
  {
    name: "Xã Xuân Thắng",
    code: 31270,
    division_type: "xã",
    codename: "xa_xuan_thang",
    district_code: 927,
  },
  {
    name: "Xã Đông Bình",
    code: 31279,
    division_type: "xã",
    codename: "xa_dong_binh",
    district_code: 927,
  },
  {
    name: "Xã Đông Thuận",
    code: 31282,
    division_type: "xã",
    codename: "xa_dong_thuan",
    district_code: 927,
  },
  {
    name: "Xã Thới Tân",
    code: 31285,
    division_type: "xã",
    codename: "xa_thoi_tan",
    district_code: 927,
  },
  {
    name: "Xã Trường Thắng",
    code: 31286,
    division_type: "xã",
    codename: "xa_truong_thang",
    district_code: 927,
  },
  {
    name: "Xã Định Môn",
    code: 31288,
    division_type: "xã",
    codename: "xa_dinh_mon",
    district_code: 927,
  },
  {
    name: "Xã Trường Thành",
    code: 31291,
    division_type: "xã",
    codename: "xa_truong_thanh",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân",
    code: 31294,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân A",
    code: 31297,
    division_type: "xã",
    codename: "xa_truong_xuan_a",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân B",
    code: 31298,
    division_type: "xã",
    codename: "xa_truong_xuan_b",
    district_code: 927,
  },
  {
    name: "Phường I",
    code: 31318,
    division_type: "phường",
    codename: "phuong_i",
    district_code: 930,
  },
  {
    name: "Phường III",
    code: 31321,
    division_type: "phường",
    codename: "phuong_iii",
    district_code: 930,
  },
  {
    name: "Phường IV",
    code: 31324,
    division_type: "phường",
    codename: "phuong_iv",
    district_code: 930,
  },
  {
    name: "Phường V",
    code: 31327,
    division_type: "phường",
    codename: "phuong_v",
    district_code: 930,
  },
  {
    name: "Phường VII",
    code: 31330,
    division_type: "phường",
    codename: "phuong_vii",
    district_code: 930,
  },
  {
    name: "Xã Vị Tân",
    code: 31333,
    division_type: "xã",
    codename: "xa_vi_tan",
    district_code: 930,
  },
  {
    name: "Xã Hoả Lựu",
    code: 31336,
    division_type: "xã",
    codename: "xa_hoa_luu",
    district_code: 930,
  },
  {
    name: "Xã Tân Tiến",
    code: 31338,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 930,
  },
  {
    name: "Xã Hoả Tiến",
    code: 31339,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 930,
  },
  {
    name: "Phường Ngã Bảy",
    code: 31340,
    division_type: "phường",
    codename: "phuong_nga_bay",
    district_code: 931,
  },
  {
    name: "Phường Lái Hiếu",
    code: 31341,
    division_type: "phường",
    codename: "phuong_lai_hieu",
    district_code: 931,
  },
  {
    name: "Phường Hiệp Thành",
    code: 31343,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 931,
  },
  {
    name: "Phường Hiệp Lợi",
    code: 31344,
    division_type: "phường",
    codename: "phuong_hiep_loi",
    district_code: 931,
  },
  {
    name: "Xã Đại Thành",
    code: 31411,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 931,
  },
  {
    name: "Xã Tân Thành",
    code: 31414,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 931,
  },
  {
    name: "Thị trấn Một Ngàn",
    code: 31342,
    division_type: "thị trấn",
    codename: "thi_tran_mot_ngan",
    district_code: 932,
  },
  {
    name: "Xã Tân Hoà",
    code: 31345,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 932,
  },
  {
    name: "Thị trấn Bảy Ngàn",
    code: 31346,
    division_type: "thị trấn",
    codename: "thi_tran_bay_ngan",
    district_code: 932,
  },
  {
    name: "Xã Trường Long Tây",
    code: 31348,
    division_type: "xã",
    codename: "xa_truong_long_tay",
    district_code: 932,
  },
  {
    name: "Xã Trường Long A",
    code: 31351,
    division_type: "xã",
    codename: "xa_truong_long_a",
    district_code: 932,
  },
  {
    name: "Xã Nhơn Nghĩa A",
    code: 31357,
    division_type: "xã",
    codename: "xa_nhon_nghia_a",
    district_code: 932,
  },
  {
    name: "Thị trấn Rạch Gòi",
    code: 31359,
    division_type: "thị trấn",
    codename: "thi_tran_rach_goi",
    district_code: 932,
  },
  {
    name: "Xã Thạnh Xuân",
    code: 31360,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 932,
  },
  {
    name: "Thị trấn Cái Tắc",
    code: 31362,
    division_type: "thị trấn",
    codename: "thi_tran_cai_tac",
    district_code: 932,
  },
  {
    name: "Xã Tân Phú Thạnh",
    code: 31363,
    division_type: "xã",
    codename: "xa_tan_phu_thanh",
    district_code: 932,
  },
  {
    name: "Thị trấn Ngã Sáu",
    code: 31366,
    division_type: "thị trấn",
    codename: "thi_tran_nga_sau",
    district_code: 933,
  },
  {
    name: "Xã Đông Thạnh",
    code: 31369,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 933,
  },
  {
    name: "Xã Đông Phú",
    code: 31375,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 933,
  },
  {
    name: "Xã Phú Hữu",
    code: 31378,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 933,
  },
  {
    name: "Xã Phú Tân",
    code: 31379,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 933,
  },
  {
    name: "Thị trấn Mái Dầm",
    code: 31381,
    division_type: "thị trấn",
    codename: "thi_tran_mai_dam",
    district_code: 933,
  },
  {
    name: "Xã Đông Phước",
    code: 31384,
    division_type: "xã",
    codename: "xa_dong_phuoc",
    district_code: 933,
  },
  {
    name: "Xã Đông Phước A",
    code: 31387,
    division_type: "xã",
    codename: "xa_dong_phuoc_a",
    district_code: 933,
  },
  {
    name: "Thị trấn Kinh Cùng",
    code: 31393,
    division_type: "thị trấn",
    codename: "thi_tran_kinh_cung",
    district_code: 934,
  },
  {
    name: "Thị trấn Cây Dương",
    code: 31396,
    division_type: "thị trấn",
    codename: "thi_tran_cay_duong",
    district_code: 934,
  },
  {
    name: "Xã Tân Bình",
    code: 31399,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 934,
  },
  {
    name: "Xã Bình Thành",
    code: 31402,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 934,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 31405,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 934,
  },
  {
    name: "Xã Long Thạnh",
    code: 31408,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 934,
  },
  {
    name: "Xã Phụng Hiệp",
    code: 31417,
    division_type: "xã",
    codename: "xa_phung_hiep",
    district_code: 934,
  },
  {
    name: "Xã Hòa Mỹ",
    code: 31420,
    division_type: "xã",
    codename: "xa_hoa_my",
    district_code: 934,
  },
  {
    name: "Xã Hòa An",
    code: 31423,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 934,
  },
  {
    name: "Xã Phương Bình",
    code: 31426,
    division_type: "xã",
    codename: "xa_phuong_binh",
    district_code: 934,
  },
  {
    name: "Xã Hiệp Hưng",
    code: 31429,
    division_type: "xã",
    codename: "xa_hiep_hung",
    district_code: 934,
  },
  {
    name: "Xã Tân Phước Hưng",
    code: 31432,
    division_type: "xã",
    codename: "xa_tan_phuoc_hung",
    district_code: 934,
  },
  {
    name: "Thị trấn Búng Tàu",
    code: 31433,
    division_type: "thị trấn",
    codename: "thi_tran_bung_tau",
    district_code: 934,
  },
  {
    name: "Xã Phương Phú",
    code: 31435,
    division_type: "xã",
    codename: "xa_phuong_phu",
    district_code: 934,
  },
  {
    name: "Xã Tân Long",
    code: 31438,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 934,
  },
  {
    name: "Thị trấn Nàng Mau",
    code: 31441,
    division_type: "thị trấn",
    codename: "thi_tran_nang_mau",
    district_code: 935,
  },
  {
    name: "Xã Vị Trung",
    code: 31444,
    division_type: "xã",
    codename: "xa_vi_trung",
    district_code: 935,
  },
  {
    name: "Xã Vị Thuỷ",
    code: 31447,
    division_type: "xã",
    codename: "xa_vi_thuy",
    district_code: 935,
  },
  {
    name: "Xã Vị Thắng",
    code: 31450,
    division_type: "xã",
    codename: "xa_vi_thang",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Thuận Tây",
    code: 31453,
    division_type: "xã",
    codename: "xa_vinh_thuan_tay",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 31456,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Tường",
    code: 31459,
    division_type: "xã",
    codename: "xa_vinh_tuong",
    district_code: 935,
  },
  {
    name: "Xã Vị Đông",
    code: 31462,
    division_type: "xã",
    codename: "xa_vi_dong",
    district_code: 935,
  },
  {
    name: "Xã Vị Thanh",
    code: 31465,
    division_type: "xã",
    codename: "xa_vi_thanh",
    district_code: 935,
  },
  {
    name: "Xã Vị Bình",
    code: 31468,
    division_type: "xã",
    codename: "xa_vi_binh",
    district_code: 935,
  },
  {
    name: "Xã Thuận Hưng",
    code: 31483,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 936,
  },
  {
    name: "Xã Thuận Hòa",
    code: 31484,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 936,
  },
  {
    name: "Xã Vĩnh Thuận Đông",
    code: 31486,
    division_type: "xã",
    codename: "xa_vinh_thuan_dong",
    district_code: 936,
  },
  {
    name: "Thị trấn Vĩnh Viễn",
    code: 31489,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_vien",
    district_code: 936,
  },
  {
    name: "Xã Vĩnh Viễn A",
    code: 31490,
    division_type: "xã",
    codename: "xa_vinh_vien_a",
    district_code: 936,
  },
  {
    name: "Xã Lương Tâm",
    code: 31492,
    division_type: "xã",
    codename: "xa_luong_tam",
    district_code: 936,
  },
  {
    name: "Xã Lương Nghĩa",
    code: 31493,
    division_type: "xã",
    codename: "xa_luong_nghia",
    district_code: 936,
  },
  {
    name: "Xã Xà Phiên",
    code: 31495,
    division_type: "xã",
    codename: "xa_xa_phien",
    district_code: 936,
  },
  {
    name: "Phường Thuận An",
    code: 31471,
    division_type: "phường",
    codename: "phuong_thuan_an",
    district_code: 937,
  },
  {
    name: "Phường Trà Lồng",
    code: 31472,
    division_type: "phường",
    codename: "phuong_tra_long",
    district_code: 937,
  },
  {
    name: "Phường Bình Thạnh",
    code: 31473,
    division_type: "phường",
    codename: "phuong_binh_thanh",
    district_code: 937,
  },
  {
    name: "Xã Long Bình",
    code: 31474,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 937,
  },
  {
    name: "Phường Vĩnh Tường",
    code: 31475,
    division_type: "phường",
    codename: "phuong_vinh_tuong",
    district_code: 937,
  },
  {
    name: "Xã Long Trị",
    code: 31477,
    division_type: "xã",
    codename: "xa_long_tri",
    district_code: 937,
  },
  {
    name: "Xã Long Trị A",
    code: 31478,
    division_type: "xã",
    codename: "xa_long_tri_a",
    district_code: 937,
  },
  {
    name: "Xã Long Phú",
    code: 31480,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 937,
  },
  {
    name: "Xã Tân Phú",
    code: 31481,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 937,
  },
  {
    name: "Phường 5",
    code: 31498,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 941,
  },
  {
    name: "Phường 7",
    code: 31501,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 941,
  },
  {
    name: "Phường 8",
    code: 31504,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 941,
  },
  {
    name: "Phường 6",
    code: 31507,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 941,
  },
  {
    name: "Phường 2",
    code: 31510,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 941,
  },
  {
    name: "Phường 1",
    code: 31513,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 941,
  },
  {
    name: "Phường 4",
    code: 31516,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 941,
  },
  {
    name: "Phường 3",
    code: 31519,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 941,
  },
  {
    name: "Phường 9",
    code: 31522,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 941,
  },
  {
    name: "Phường 10",
    code: 31525,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 941,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 31569,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 942,
  },
  {
    name: "Xã Hồ Đắc Kiện",
    code: 31570,
    division_type: "xã",
    codename: "xa_ho_dac_kien",
    district_code: 942,
  },
  {
    name: "Xã Phú Tâm",
    code: 31573,
    division_type: "xã",
    codename: "xa_phu_tam",
    district_code: 942,
  },
  {
    name: "Xã Thuận Hòa",
    code: 31576,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 942,
  },
  {
    name: "Xã Phú Tân",
    code: 31582,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 942,
  },
  {
    name: "Xã Thiện Mỹ",
    code: 31585,
    division_type: "xã",
    codename: "xa_thien_my",
    district_code: 942,
  },
  {
    name: "Xã An Hiệp",
    code: 31594,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 942,
  },
  {
    name: "Xã An Ninh",
    code: 31600,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 942,
  },
  {
    name: "Thị trấn Kế Sách",
    code: 31528,
    division_type: "thị trấn",
    codename: "thi_tran_ke_sach",
    district_code: 943,
  },
  {
    name: "Thị trấn An Lạc Thôn",
    code: 31531,
    division_type: "thị trấn",
    codename: "thi_tran_an_lac_thon",
    district_code: 943,
  },
  {
    name: "Xã Xuân Hòa",
    code: 31534,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 943,
  },
  {
    name: "Xã Phong Nẫm",
    code: 31537,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 943,
  },
  {
    name: "Xã An Lạc Tây",
    code: 31540,
    division_type: "xã",
    codename: "xa_an_lac_tay",
    district_code: 943,
  },
  {
    name: "Xã Trinh Phú",
    code: 31543,
    division_type: "xã",
    codename: "xa_trinh_phu",
    district_code: 943,
  },
  {
    name: "Xã Ba Trinh",
    code: 31546,
    division_type: "xã",
    codename: "xa_ba_trinh",
    district_code: 943,
  },
  {
    name: "Xã Thới An Hội",
    code: 31549,
    division_type: "xã",
    codename: "xa_thoi_an_hoi",
    district_code: 943,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 31552,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 943,
  },
  {
    name: "Xã Kế Thành",
    code: 31555,
    division_type: "xã",
    codename: "xa_ke_thanh",
    district_code: 943,
  },
  {
    name: "Xã Kế An",
    code: 31558,
    division_type: "xã",
    codename: "xa_ke_an",
    district_code: 943,
  },
  {
    name: "Xã Đại Hải",
    code: 31561,
    division_type: "xã",
    codename: "xa_dai_hai",
    district_code: 943,
  },
  {
    name: "Xã An Mỹ",
    code: 31564,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 943,
  },
  {
    name: "Thị trấn Huỳnh Hữu Nghĩa",
    code: 31567,
    division_type: "thị trấn",
    codename: "thi_tran_huynh_huu_nghia",
    district_code: 944,
  },
  {
    name: "Xã Long Hưng",
    code: 31579,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 944,
  },
  {
    name: "Xã Hưng Phú",
    code: 31588,
    division_type: "xã",
    codename: "xa_hung_phu",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Hương",
    code: 31591,
    division_type: "xã",
    codename: "xa_my_huong",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Tú",
    code: 31597,
    division_type: "xã",
    codename: "xa_my_tu",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Phước",
    code: 31603,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 944,
  },
  {
    name: "Xã Thuận Hưng",
    code: 31606,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 31609,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 944,
  },
  {
    name: "Xã Phú Mỹ",
    code: 31612,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 944,
  },
  {
    name: "Thị trấn Cù Lao Dung",
    code: 31615,
    division_type: "thị trấn",
    codename: "thi_tran_cu_lao_dung",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 1",
    code: 31618,
    division_type: "xã",
    codename: "xa_an_thanh_1",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Tây",
    code: 31621,
    division_type: "xã",
    codename: "xa_an_thanh_tay",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Đông",
    code: 31624,
    division_type: "xã",
    codename: "xa_an_thanh_dong",
    district_code: 945,
  },
  {
    name: "Xã Đại Ân 1",
    code: 31627,
    division_type: "xã",
    codename: "xa_dai_an_1",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 2",
    code: 31630,
    division_type: "xã",
    codename: "xa_an_thanh_2",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 3",
    code: 31633,
    division_type: "xã",
    codename: "xa_an_thanh_3",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Nam",
    code: 31636,
    division_type: "xã",
    codename: "xa_an_thanh_nam",
    district_code: 945,
  },
  {
    name: "Thị trấn Long Phú",
    code: 31639,
    division_type: "thị trấn",
    codename: "thi_tran_long_phu",
    district_code: 946,
  },
  {
    name: "Xã Song Phụng",
    code: 31642,
    division_type: "xã",
    codename: "xa_song_phung",
    district_code: 946,
  },
  {
    name: "Thị trấn Đại Ngãi",
    code: 31645,
    division_type: "thị trấn",
    codename: "thi_tran_dai_ngai",
    district_code: 946,
  },
  {
    name: "Xã Hậu Thạnh",
    code: 31648,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 946,
  },
  {
    name: "Xã Long Đức",
    code: 31651,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 946,
  },
  {
    name: "Xã Trường Khánh",
    code: 31654,
    division_type: "xã",
    codename: "xa_truong_khanh",
    district_code: 946,
  },
  {
    name: "Xã Phú Hữu",
    code: 31657,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 946,
  },
  {
    name: "Xã Tân Hưng",
    code: 31660,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 946,
  },
  {
    name: "Xã Châu Khánh",
    code: 31663,
    division_type: "xã",
    codename: "xa_chau_khanh",
    district_code: 946,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31666,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 946,
  },
  {
    name: "Xã Long Phú",
    code: 31669,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 946,
  },
  {
    name: "Thị trấn Mỹ Xuyên",
    code: 31684,
    division_type: "thị trấn",
    codename: "thi_tran_my_xuyen",
    district_code: 947,
  },
  {
    name: "Xã Đại Tâm",
    code: 31690,
    division_type: "xã",
    codename: "xa_dai_tam",
    district_code: 947,
  },
  {
    name: "Xã Tham Đôn",
    code: 31693,
    division_type: "xã",
    codename: "xa_tham_don",
    district_code: 947,
  },
  {
    name: "Xã Thạnh Phú",
    code: 31708,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 947,
  },
  {
    name: "Xã Ngọc Đông",
    code: 31711,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 947,
  },
  {
    name: "Xã Thạnh Quới",
    code: 31714,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 947,
  },
  {
    name: "Xã Hòa Tú 1",
    code: 31717,
    division_type: "xã",
    codename: "xa_hoa_tu_1",
    district_code: 947,
  },
  {
    name: "Xã Gia Hòa 1",
    code: 31720,
    division_type: "xã",
    codename: "xa_gia_hoa_1",
    district_code: 947,
  },
  {
    name: "Xã Ngọc Tố",
    code: 31723,
    division_type: "xã",
    codename: "xa_ngoc_to",
    district_code: 947,
  },
  {
    name: "Xã Gia Hòa 2",
    code: 31726,
    division_type: "xã",
    codename: "xa_gia_hoa_2",
    district_code: 947,
  },
  {
    name: "Xã Hòa Tú II",
    code: 31729,
    division_type: "xã",
    codename: "xa_hoa_tu_ii",
    district_code: 947,
  },
  {
    name: "Phường 1",
    code: 31732,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 948,
  },
  {
    name: "Phường 2",
    code: 31735,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 948,
  },
  {
    name: "Xã Vĩnh Quới",
    code: 31738,
    division_type: "xã",
    codename: "xa_vinh_quoi",
    district_code: 948,
  },
  {
    name: "Xã Tân Long",
    code: 31741,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 948,
  },
  {
    name: "Xã Long Bình",
    code: 31744,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 948,
  },
  {
    name: "Phường 3",
    code: 31747,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 948,
  },
  {
    name: "Xã Mỹ Bình",
    code: 31750,
    division_type: "xã",
    codename: "xa_my_binh",
    district_code: 948,
  },
  {
    name: "Xã Mỹ Quới",
    code: 31753,
    division_type: "xã",
    codename: "xa_my_quoi",
    district_code: 948,
  },
  {
    name: "Thị trấn Phú Lộc",
    code: 31756,
    division_type: "thị trấn",
    codename: "thi_tran_phu_loc",
    district_code: 949,
  },
  {
    name: "Thị trấn Hưng Lợi",
    code: 31757,
    division_type: "thị trấn",
    codename: "thi_tran_hung_loi",
    district_code: 949,
  },
  {
    name: "Xã Lâm Tân",
    code: 31759,
    division_type: "xã",
    codename: "xa_lam_tan",
    district_code: 949,
  },
  {
    name: "Xã Thạnh Tân",
    code: 31762,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 949,
  },
  {
    name: "Xã Lâm Kiết",
    code: 31765,
    division_type: "xã",
    codename: "xa_lam_kiet",
    district_code: 949,
  },
  {
    name: "Xã Tuân Tức",
    code: 31768,
    division_type: "xã",
    codename: "xa_tuan_tuc",
    district_code: 949,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 31771,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 949,
  },
  {
    name: "Xã Thạnh Trị",
    code: 31774,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 949,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 31777,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 949,
  },
  {
    name: "Xã Châu Hưng",
    code: 31780,
    division_type: "xã",
    codename: "xa_chau_hung",
    district_code: 949,
  },
  {
    name: "Phường 1",
    code: 31783,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 950,
  },
  {
    name: "Xã Hòa Đông",
    code: 31786,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 950,
  },
  {
    name: "Phường Khánh Hòa",
    code: 31789,
    division_type: "phường",
    codename: "phuong_khanh_hoa",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 31792,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Hải",
    code: 31795,
    division_type: "xã",
    codename: "xa_vinh_hai",
    district_code: 950,
  },
  {
    name: "Xã Lạc Hòa",
    code: 31798,
    division_type: "xã",
    codename: "xa_lac_hoa",
    district_code: 950,
  },
  {
    name: "Phường 2",
    code: 31801,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 950,
  },
  {
    name: "Phường Vĩnh Phước",
    code: 31804,
    division_type: "phường",
    codename: "phuong_vinh_phuoc",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 31807,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 950,
  },
  {
    name: "Xã Lai Hòa",
    code: 31810,
    division_type: "xã",
    codename: "xa_lai_hoa",
    district_code: 950,
  },
  {
    name: "Xã Đại Ân 2",
    code: 31672,
    division_type: "xã",
    codename: "xa_dai_an_2",
    district_code: 951,
  },
  {
    name: "Thị trấn Trần Đề",
    code: 31673,
    division_type: "thị trấn",
    codename: "thi_tran_tran_de",
    district_code: 951,
  },
  {
    name: "Xã Liêu Tú",
    code: 31675,
    division_type: "xã",
    codename: "xa_lieu_tu",
    district_code: 951,
  },
  {
    name: "Xã Lịch Hội Thượng",
    code: 31678,
    division_type: "xã",
    codename: "xa_lich_hoi_thuong",
    district_code: 951,
  },
  {
    name: "Thị trấn Lịch Hội Thượng",
    code: 31679,
    division_type: "thị trấn",
    codename: "thi_tran_lich_hoi_thuong",
    district_code: 951,
  },
  {
    name: "Xã Trung Bình",
    code: 31681,
    division_type: "xã",
    codename: "xa_trung_binh",
    district_code: 951,
  },
  {
    name: "Xã Tài Văn",
    code: 31687,
    division_type: "xã",
    codename: "xa_tai_van",
    district_code: 951,
  },
  {
    name: "Xã Viên An",
    code: 31696,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 951,
  },
  {
    name: "Xã Thạnh Thới An",
    code: 31699,
    division_type: "xã",
    codename: "xa_thanh_thoi_an",
    district_code: 951,
  },
  {
    name: "Xã Thạnh Thới Thuận",
    code: 31702,
    division_type: "xã",
    codename: "xa_thanh_thoi_thuan",
    district_code: 951,
  },
  {
    name: "Xã Viên Bình",
    code: 31705,
    division_type: "xã",
    codename: "xa_vien_binh",
    district_code: 951,
  },
  {
    name: "Phường 2",
    code: 31813,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 954,
  },
  {
    name: "Phường 3",
    code: 31816,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 954,
  },
  {
    name: "Phường 5",
    code: 31819,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 954,
  },
  {
    name: "Phường 7",
    code: 31822,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 954,
  },
  {
    name: "Phường 1",
    code: 31825,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 954,
  },
  {
    name: "Phường 8",
    code: 31828,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 954,
  },
  {
    name: "Phường Nhà Mát",
    code: 31831,
    division_type: "phường",
    codename: "phuong_nha_mat",
    district_code: 954,
  },
  {
    name: "Xã Vĩnh Trạch",
    code: 31834,
    division_type: "xã",
    codename: "xa_vinh_trach",
    district_code: 954,
  },
  {
    name: "Xã Vĩnh Trạch Đông",
    code: 31837,
    division_type: "xã",
    codename: "xa_vinh_trach_dong",
    district_code: 954,
  },
  {
    name: "Xã Hiệp Thành",
    code: 31840,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 954,
  },
  {
    name: "Thị trấn Ngan Dừa",
    code: 31843,
    division_type: "thị trấn",
    codename: "thi_tran_ngan_dua",
    district_code: 956,
  },
  {
    name: "Xã Ninh Quới",
    code: 31846,
    division_type: "xã",
    codename: "xa_ninh_quoi",
    district_code: 956,
  },
  {
    name: "Xã Ninh Quới A",
    code: 31849,
    division_type: "xã",
    codename: "xa_ninh_quoi_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Hòa",
    code: 31852,
    division_type: "xã",
    codename: "xa_ninh_hoa",
    district_code: 956,
  },
  {
    name: "Xã Lộc Ninh",
    code: 31855,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 956,
  },
  {
    name: "Xã Vĩnh Lộc",
    code: 31858,
    division_type: "xã",
    codename: "xa_vinh_loc",
    district_code: 956,
  },
  {
    name: "Xã Vĩnh Lộc A",
    code: 31861,
    division_type: "xã",
    codename: "xa_vinh_loc_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Thạnh Lợi A",
    code: 31863,
    division_type: "xã",
    codename: "xa_ninh_thanh_loi_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Thạnh Lợi",
    code: 31864,
    division_type: "xã",
    codename: "xa_ninh_thanh_loi",
    district_code: 956,
  },
  {
    name: "Thị trấn Phước Long",
    code: 31867,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_long",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Phú Đông",
    code: 31870,
    division_type: "xã",
    codename: "xa_vinh_phu_dong",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Phú Tây",
    code: 31873,
    division_type: "xã",
    codename: "xa_vinh_phu_tay",
    district_code: 957,
  },
  {
    name: "Xã Phước Long",
    code: 31876,
    division_type: "xã",
    codename: "xa_phuoc_long",
    district_code: 957,
  },
  {
    name: "Xã Hưng Phú",
    code: 31879,
    division_type: "xã",
    codename: "xa_hung_phu",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Thanh",
    code: 31882,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 957,
  },
  {
    name: "Xã Phong Thạnh Tây A",
    code: 31885,
    division_type: "xã",
    codename: "xa_phong_thanh_tay_a",
    district_code: 957,
  },
  {
    name: "Xã Phong Thạnh Tây B",
    code: 31888,
    division_type: "xã",
    codename: "xa_phong_thanh_tay_b",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 31894,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 958,
  },
  {
    name: "Xã Vĩnh Hưng A",
    code: 31897,
    division_type: "xã",
    codename: "xa_vinh_hung_a",
    district_code: 958,
  },
  {
    name: "Thị trấn Châu Hưng",
    code: 31900,
    division_type: "thị trấn",
    codename: "thi_tran_chau_hung",
    district_code: 958,
  },
  {
    name: "Xã Châu Hưng A",
    code: 31903,
    division_type: "xã",
    codename: "xa_chau_hung_a",
    district_code: 958,
  },
  {
    name: "Xã Hưng Thành",
    code: 31906,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 958,
  },
  {
    name: "Xã Hưng Hội",
    code: 31909,
    division_type: "xã",
    codename: "xa_hung_hoi",
    district_code: 958,
  },
  {
    name: "Xã Châu Thới",
    code: 31912,
    division_type: "xã",
    codename: "xa_chau_thoi",
    district_code: 958,
  },
  {
    name: "Xã Long Thạnh",
    code: 31921,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 958,
  },
  {
    name: "Phường 1",
    code: 31942,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 959,
  },
  {
    name: "Phường Hộ Phòng",
    code: 31945,
    division_type: "phường",
    codename: "phuong_ho_phong",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh Đông",
    code: 31948,
    division_type: "xã",
    codename: "xa_phong_thanh_dong",
    district_code: 959,
  },
  {
    name: "Phường Láng Tròn",
    code: 31951,
    division_type: "phường",
    codename: "phuong_lang_tron",
    district_code: 959,
  },
  {
    name: "Xã Phong Tân",
    code: 31954,
    division_type: "xã",
    codename: "xa_phong_tan",
    district_code: 959,
  },
  {
    name: "Xã Tân Phong",
    code: 31957,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh",
    code: 31960,
    division_type: "xã",
    codename: "xa_phong_thanh",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh A",
    code: 31963,
    division_type: "xã",
    codename: "xa_phong_thanh_a",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh Tây",
    code: 31966,
    division_type: "xã",
    codename: "xa_phong_thanh_tay",
    district_code: 959,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31969,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 959,
  },
  {
    name: "Thị trấn Gành Hào",
    code: 31972,
    division_type: "thị trấn",
    codename: "thi_tran_ganh_hao",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Đông",
    code: 31975,
    division_type: "xã",
    codename: "xa_long_dien_dong",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Đông A",
    code: 31978,
    division_type: "xã",
    codename: "xa_long_dien_dong_a",
    district_code: 960,
  },
  {
    name: "Xã Long Điền",
    code: 31981,
    division_type: "xã",
    codename: "xa_long_dien",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Tây",
    code: 31984,
    division_type: "xã",
    codename: "xa_long_dien_tay",
    district_code: 960,
  },
  {
    name: "Xã Điền Hải",
    code: 31985,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 960,
  },
  {
    name: "Xã An Trạch",
    code: 31987,
    division_type: "xã",
    codename: "xa_an_trach",
    district_code: 960,
  },
  {
    name: "Xã An Trạch A",
    code: 31988,
    division_type: "xã",
    codename: "xa_an_trach_a",
    district_code: 960,
  },
  {
    name: "Xã An Phúc",
    code: 31990,
    division_type: "xã",
    codename: "xa_an_phuc",
    district_code: 960,
  },
  {
    name: "Xã Định Thành",
    code: 31993,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 960,
  },
  {
    name: "Xã Định Thành A",
    code: 31996,
    division_type: "xã",
    codename: "xa_dinh_thanh_a",
    district_code: 960,
  },
  {
    name: "Thị trấn Hòa Bình",
    code: 31891,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_binh",
    district_code: 961,
  },
  {
    name: "Xã Minh Diệu",
    code: 31915,
    division_type: "xã",
    codename: "xa_minh_dieu",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 31918,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Mỹ B",
    code: 31924,
    division_type: "xã",
    codename: "xa_vinh_my_b",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Hậu",
    code: 31927,
    division_type: "xã",
    codename: "xa_vinh_hau",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Hậu A",
    code: 31930,
    division_type: "xã",
    codename: "xa_vinh_hau_a",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Mỹ A",
    code: 31933,
    division_type: "xã",
    codename: "xa_vinh_my_a",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 31936,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 961,
  },
  {
    name: "Phường 9",
    code: 31999,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 964,
  },
  {
    name: "Phường 4",
    code: 32002,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 964,
  },
  {
    name: "Phường 1",
    code: 32005,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 964,
  },
  {
    name: "Phường 5",
    code: 32008,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 964,
  },
  {
    name: "Phường 2",
    code: 32011,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 964,
  },
  {
    name: "Phường 8",
    code: 32014,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 964,
  },
  {
    name: "Phường 6",
    code: 32017,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 964,
  },
  {
    name: "Phường 7",
    code: 32020,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 964,
  },
  {
    name: "Phường Tân Xuyên",
    code: 32022,
    division_type: "phường",
    codename: "phuong_tan_xuyen",
    district_code: 964,
  },
  {
    name: "Xã An Xuyên",
    code: 32023,
    division_type: "xã",
    codename: "xa_an_xuyen",
    district_code: 964,
  },
  {
    name: "Phường Tân Thành",
    code: 32025,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 964,
  },
  {
    name: "Xã Tân Thành",
    code: 32026,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 964,
  },
  {
    name: "Xã Tắc Vân",
    code: 32029,
    division_type: "xã",
    codename: "xa_tac_van",
    district_code: 964,
  },
  {
    name: "Xã Lý Văn Lâm",
    code: 32032,
    division_type: "xã",
    codename: "xa_ly_van_lam",
    district_code: 964,
  },
  {
    name: "Xã Định Bình",
    code: 32035,
    division_type: "xã",
    codename: "xa_dinh_binh",
    district_code: 964,
  },
  {
    name: "Xã Hòa Thành",
    code: 32038,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 964,
  },
  {
    name: "Xã Hòa Tân",
    code: 32041,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 964,
  },
  {
    name: "Thị trấn U Minh",
    code: 32044,
    division_type: "thị trấn",
    codename: "thi_tran_u_minh",
    district_code: 966,
  },
  {
    name: "Xã Khánh Hòa",
    code: 32047,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 966,
  },
  {
    name: "Xã Khánh Thuận",
    code: 32048,
    division_type: "xã",
    codename: "xa_khanh_thuan",
    district_code: 966,
  },
  {
    name: "Xã Khánh Tiến",
    code: 32050,
    division_type: "xã",
    codename: "xa_khanh_tien",
    district_code: 966,
  },
  {
    name: "Xã Nguyễn Phích",
    code: 32053,
    division_type: "xã",
    codename: "xa_nguyen_phich",
    district_code: 966,
  },
  {
    name: "Xã Khánh Lâm",
    code: 32056,
    division_type: "xã",
    codename: "xa_khanh_lam",
    district_code: 966,
  },
  {
    name: "Xã Khánh An",
    code: 32059,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 966,
  },
  {
    name: "Xã Khánh Hội",
    code: 32062,
    division_type: "xã",
    codename: "xa_khanh_hoi",
    district_code: 966,
  },
  {
    name: "Thị trấn Thới Bình",
    code: 32065,
    division_type: "thị trấn",
    codename: "thi_tran_thoi_binh",
    district_code: 967,
  },
  {
    name: "Xã Biển Bạch",
    code: 32068,
    division_type: "xã",
    codename: "xa_bien_bach",
    district_code: 967,
  },
  {
    name: "Xã Tân Bằng",
    code: 32069,
    division_type: "xã",
    codename: "xa_tan_bang",
    district_code: 967,
  },
  {
    name: "Xã Trí Phải",
    code: 32071,
    division_type: "xã",
    codename: "xa_tri_phai",
    district_code: 967,
  },
  {
    name: "Xã Trí Lực",
    code: 32072,
    division_type: "xã",
    codename: "xa_tri_luc",
    district_code: 967,
  },
  {
    name: "Xã Biển Bạch Đông",
    code: 32074,
    division_type: "xã",
    codename: "xa_bien_bach_dong",
    district_code: 967,
  },
  {
    name: "Xã Thới Bình",
    code: 32077,
    division_type: "xã",
    codename: "xa_thoi_binh",
    district_code: 967,
  },
  {
    name: "Xã Tân Phú",
    code: 32080,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc Bắc",
    code: 32083,
    division_type: "xã",
    codename: "xa_tan_loc_bac",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc",
    code: 32086,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc Đông",
    code: 32089,
    division_type: "xã",
    codename: "xa_tan_loc_dong",
    district_code: 967,
  },
  {
    name: "Xã Hồ Thị Kỷ",
    code: 32092,
    division_type: "xã",
    codename: "xa_ho_thi_ky",
    district_code: 967,
  },
  {
    name: "Thị trấn Trần Văn Thời",
    code: 32095,
    division_type: "thị trấn",
    codename: "thi_tran_tran_van_thoi",
    district_code: 968,
  },
  {
    name: "Thị trấn Sông Đốc",
    code: 32098,
    division_type: "thị trấn",
    codename: "thi_tran_song_doc",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Tây Bắc",
    code: 32101,
    division_type: "xã",
    codename: "xa_khanh_binh_tay_bac",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Tây",
    code: 32104,
    division_type: "xã",
    codename: "xa_khanh_binh_tay",
    district_code: 968,
  },
  {
    name: "Xã Trần Hợi",
    code: 32107,
    division_type: "xã",
    codename: "xa_tran_hoi",
    district_code: 968,
  },
  {
    name: "Xã Khánh Lộc",
    code: 32108,
    division_type: "xã",
    codename: "xa_khanh_loc",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình",
    code: 32110,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 968,
  },
  {
    name: "Xã Khánh Hưng",
    code: 32113,
    division_type: "xã",
    codename: "xa_khanh_hung",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Đông",
    code: 32116,
    division_type: "xã",
    codename: "xa_khanh_binh_dong",
    district_code: 968,
  },
  {
    name: "Xã Khánh Hải",
    code: 32119,
    division_type: "xã",
    codename: "xa_khanh_hai",
    district_code: 968,
  },
  {
    name: "Xã Lợi An",
    code: 32122,
    division_type: "xã",
    codename: "xa_loi_an",
    district_code: 968,
  },
  {
    name: "Xã Phong Điền",
    code: 32124,
    division_type: "xã",
    codename: "xa_phong_dien",
    district_code: 968,
  },
  {
    name: "Xã Phong Lạc",
    code: 32125,
    division_type: "xã",
    codename: "xa_phong_lac",
    district_code: 968,
  },
  {
    name: "Thị trấn Cái Nước",
    code: 32128,
    division_type: "thị trấn",
    codename: "thi_tran_cai_nuoc",
    district_code: 969,
  },
  {
    name: "Xã Thạnh Phú",
    code: 32130,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 969,
  },
  {
    name: "Xã Lương Thế Trân",
    code: 32131,
    division_type: "xã",
    codename: "xa_luong_the_tran",
    district_code: 969,
  },
  {
    name: "Xã Phú Hưng",
    code: 32134,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 969,
  },
  {
    name: "Xã Tân Hưng",
    code: 32137,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 969,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 32140,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 969,
  },
  {
    name: "Xã Hoà Mỹ",
    code: 32141,
    division_type: "xã",
    codename: "xa_hoa_my",
    district_code: 969,
  },
  {
    name: "Xã Đông Hưng",
    code: 32142,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 969,
  },
  {
    name: "Xã Đông Thới",
    code: 32143,
    division_type: "xã",
    codename: "xa_dong_thoi",
    district_code: 969,
  },
  {
    name: "Xã Tân Hưng Đông",
    code: 32146,
    division_type: "xã",
    codename: "xa_tan_hung_dong",
    district_code: 969,
  },
  {
    name: "Xã Trần Thới",
    code: 32149,
    division_type: "xã",
    codename: "xa_tran_thoi",
    district_code: 969,
  },
  {
    name: "Thị trấn Đầm Dơi",
    code: 32152,
    division_type: "thị trấn",
    codename: "thi_tran_dam_doi",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương",
    code: 32155,
    division_type: "xã",
    codename: "xa_ta_an_khuong",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương Đông",
    code: 32158,
    division_type: "xã",
    codename: "xa_ta_an_khuong_dong",
    district_code: 970,
  },
  {
    name: "Xã Trần Phán",
    code: 32161,
    division_type: "xã",
    codename: "xa_tran_phan",
    district_code: 970,
  },
  {
    name: "Xã Tân Trung",
    code: 32162,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 970,
  },
  {
    name: "Xã Tân Đức",
    code: 32164,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 970,
  },
  {
    name: "Xã Tân Thuận",
    code: 32167,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương Nam",
    code: 32170,
    division_type: "xã",
    codename: "xa_ta_an_khuong_nam",
    district_code: 970,
  },
  {
    name: "Xã Tân Duyệt",
    code: 32173,
    division_type: "xã",
    codename: "xa_tan_duyet",
    district_code: 970,
  },
  {
    name: "Xã Tân Dân",
    code: 32174,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 970,
  },
  {
    name: "Xã Tân Tiến",
    code: 32176,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 970,
  },
  {
    name: "Xã Quách Phẩm Bắc",
    code: 32179,
    division_type: "xã",
    codename: "xa_quach_pham_bac",
    district_code: 970,
  },
  {
    name: "Xã Quách Phẩm",
    code: 32182,
    division_type: "xã",
    codename: "xa_quach_pham",
    district_code: 970,
  },
  {
    name: "Xã Thanh Tùng",
    code: 32185,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 970,
  },
  {
    name: "Xã Ngọc Chánh",
    code: 32186,
    division_type: "xã",
    codename: "xa_ngoc_chanh",
    district_code: 970,
  },
  {
    name: "Xã Nguyễn Huân",
    code: 32188,
    division_type: "xã",
    codename: "xa_nguyen_huan",
    district_code: 970,
  },
  {
    name: "Thị trấn Năm Căn",
    code: 32191,
    division_type: "thị trấn",
    codename: "thi_tran_nam_can",
    district_code: 971,
  },
  {
    name: "Xã Hàm Rồng",
    code: 32194,
    division_type: "xã",
    codename: "xa_ham_rong",
    district_code: 971,
  },
  {
    name: "Xã Hiệp Tùng",
    code: 32197,
    division_type: "xã",
    codename: "xa_hiep_tung",
    district_code: 971,
  },
  {
    name: "Xã Đất Mới",
    code: 32200,
    division_type: "xã",
    codename: "xa_dat_moi",
    district_code: 971,
  },
  {
    name: "Xã Lâm Hải",
    code: 32201,
    division_type: "xã",
    codename: "xa_lam_hai",
    district_code: 971,
  },
  {
    name: "Xã Hàng Vịnh",
    code: 32203,
    division_type: "xã",
    codename: "xa_hang_vinh",
    district_code: 971,
  },
  {
    name: "Xã Tam Giang",
    code: 32206,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 971,
  },
  {
    name: "Xã Tam Giang Đông",
    code: 32209,
    division_type: "xã",
    codename: "xa_tam_giang_dong",
    district_code: 971,
  },
  {
    name: "Thị trấn Cái Đôi Vàm",
    code: 32212,
    division_type: "thị trấn",
    codename: "thi_tran_cai_doi_vam",
    district_code: 972,
  },
  {
    name: "Xã Phú Thuận",
    code: 32214,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 972,
  },
  {
    name: "Xã Phú Mỹ",
    code: 32215,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 972,
  },
  {
    name: "Xã Phú Tân",
    code: 32218,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 972,
  },
  {
    name: "Xã Tân Hải",
    code: 32221,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 972,
  },
  {
    name: "Xã Việt Thắng",
    code: 32224,
    division_type: "xã",
    codename: "xa_viet_thang",
    district_code: 972,
  },
  {
    name: "Xã Tân Hưng Tây",
    code: 32227,
    division_type: "xã",
    codename: "xa_tan_hung_tay",
    district_code: 972,
  },
  {
    name: "Xã Rạch Chèo",
    code: 32228,
    division_type: "xã",
    codename: "xa_rach_cheo",
    district_code: 972,
  },
  {
    name: "Xã Nguyễn Việt Khái",
    code: 32230,
    division_type: "xã",
    codename: "xa_nguyen_viet_khai",
    district_code: 972,
  },
  {
    name: "Xã Tam Giang Tây",
    code: 32233,
    division_type: "xã",
    codename: "xa_tam_giang_tay",
    district_code: 973,
  },
  {
    name: "Xã Tân Ân Tây",
    code: 32236,
    division_type: "xã",
    codename: "xa_tan_an_tay",
    district_code: 973,
  },
  {
    name: "Xã Viên An Đông",
    code: 32239,
    division_type: "xã",
    codename: "xa_vien_an_dong",
    district_code: 973,
  },
  {
    name: "Xã Viên An",
    code: 32242,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 973,
  },
  {
    name: "Thị trấn Rạch Gốc",
    code: 32244,
    division_type: "thị trấn",
    codename: "thi_tran_rach_goc",
    district_code: 973,
  },
  {
    name: "Xã Tân Ân",
    code: 32245,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 973,
  },
  {
    name: "Xã Đất Mũi",
    code: 32248,
    division_type: "xã",
    codename: "xa_dat_mui",
    district_code: 973,
  },
  {
    name: "Phường Phúc Xá",
    code: 1,
    division_type: "phường",
    codename: "phuong_phuc_xa",
    district_code: 1,
  },
  {
    name: "Phường Trúc Bạch",
    code: 4,
    division_type: "phường",
    codename: "phuong_truc_bach",
    district_code: 1,
  },
  {
    name: "Phường Vĩnh Phúc",
    code: 6,
    division_type: "phường",
    codename: "phuong_vinh_phuc",
    district_code: 1,
  },
  {
    name: "Phường Cống Vị",
    code: 7,
    division_type: "phường",
    codename: "phuong_cong_vi",
    district_code: 1,
  },
  {
    name: "Phường Liễu Giai",
    code: 8,
    division_type: "phường",
    codename: "phuong_lieu_giai",
    district_code: 1,
  },
  {
    name: "Phường Nguyễn Trung Trực",
    code: 10,
    division_type: "phường",
    codename: "phuong_nguyen_trung_truc",
    district_code: 1,
  },
  {
    name: "Phường Quán Thánh",
    code: 13,
    division_type: "phường",
    codename: "phuong_quan_thanh",
    district_code: 1,
  },
  {
    name: "Phường Ngọc Hà",
    code: 16,
    division_type: "phường",
    codename: "phuong_ngoc_ha",
    district_code: 1,
  },
  {
    name: "Phường Điện Biên",
    code: 19,
    division_type: "phường",
    codename: "phuong_dien_bien",
    district_code: 1,
  },
  {
    name: "Phường Đội Cấn",
    code: 22,
    division_type: "phường",
    codename: "phuong_doi_can",
    district_code: 1,
  },
  {
    name: "Phường Ngọc Khánh",
    code: 25,
    division_type: "phường",
    codename: "phuong_ngoc_khanh",
    district_code: 1,
  },
  {
    name: "Phường Kim Mã",
    code: 28,
    division_type: "phường",
    codename: "phuong_kim_ma",
    district_code: 1,
  },
  {
    name: "Phường Giảng Võ",
    code: 31,
    division_type: "phường",
    codename: "phuong_giang_vo",
    district_code: 1,
  },
  {
    name: "Phường Thành Công",
    code: 34,
    division_type: "phường",
    codename: "phuong_thanh_cong",
    district_code: 1,
  },
  {
    name: "Phường Phúc Tân",
    code: 37,
    division_type: "phường",
    codename: "phuong_phuc_tan",
    district_code: 2,
  },
  {
    name: "Phường Đồng Xuân",
    code: 40,
    division_type: "phường",
    codename: "phuong_dong_xuan",
    district_code: 2,
  },
  {
    name: "Phường Hàng Mã",
    code: 43,
    division_type: "phường",
    codename: "phuong_hang_ma",
    district_code: 2,
  },
  {
    name: "Phường Hàng Buồm",
    code: 46,
    division_type: "phường",
    codename: "phuong_hang_buom",
    district_code: 2,
  },
  {
    name: "Phường Hàng Đào",
    code: 49,
    division_type: "phường",
    codename: "phuong_hang_dao",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bồ",
    code: 52,
    division_type: "phường",
    codename: "phuong_hang_bo",
    district_code: 2,
  },
  {
    name: "Phường Cửa Đông",
    code: 55,
    division_type: "phường",
    codename: "phuong_cua_dong",
    district_code: 2,
  },
  {
    name: "Phường Lý Thái Tổ",
    code: 58,
    division_type: "phường",
    codename: "phuong_ly_thai_to",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bạc",
    code: 61,
    division_type: "phường",
    codename: "phuong_hang_bac",
    district_code: 2,
  },
  {
    name: "Phường Hàng Gai",
    code: 64,
    division_type: "phường",
    codename: "phuong_hang_gai",
    district_code: 2,
  },
  {
    name: "Phường Chương Dương",
    code: 67,
    division_type: "phường",
    codename: "phuong_chuong_duong",
    district_code: 2,
  },
  {
    name: "Phường Hàng Trống",
    code: 70,
    division_type: "phường",
    codename: "phuong_hang_trong",
    district_code: 2,
  },
  {
    name: "Phường Cửa Nam",
    code: 73,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bông",
    code: 76,
    division_type: "phường",
    codename: "phuong_hang_bong",
    district_code: 2,
  },
  {
    name: "Phường Tràng Tiền",
    code: 79,
    division_type: "phường",
    codename: "phuong_trang_tien",
    district_code: 2,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 82,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 2,
  },
  {
    name: "Phường Phan Chu Trinh",
    code: 85,
    division_type: "phường",
    codename: "phuong_phan_chu_trinh",
    district_code: 2,
  },
  {
    name: "Phường Hàng Bài",
    code: 88,
    division_type: "phường",
    codename: "phuong_hang_bai",
    district_code: 2,
  },
  {
    name: "Phường Phú Thượng",
    code: 91,
    division_type: "phường",
    codename: "phuong_phu_thuong",
    district_code: 3,
  },
  {
    name: "Phường Nhật Tân",
    code: 94,
    division_type: "phường",
    codename: "phuong_nhat_tan",
    district_code: 3,
  },
  {
    name: "Phường Tứ Liên",
    code: 97,
    division_type: "phường",
    codename: "phuong_tu_lien",
    district_code: 3,
  },
  {
    name: "Phường Quảng An",
    code: 100,
    division_type: "phường",
    codename: "phuong_quang_an",
    district_code: 3,
  },
  {
    name: "Phường Xuân La",
    code: 103,
    division_type: "phường",
    codename: "phuong_xuan_la",
    district_code: 3,
  },
  {
    name: "Phường Yên Phụ",
    code: 106,
    division_type: "phường",
    codename: "phuong_yen_phu",
    district_code: 3,
  },
  {
    name: "Phường Bưởi",
    code: 109,
    division_type: "phường",
    codename: "phuong_buoi",
    district_code: 3,
  },
  {
    name: "Phường Thụy Khuê",
    code: 112,
    division_type: "phường",
    codename: "phuong_thuy_khue",
    district_code: 3,
  },
  {
    name: "Phường Thượng Thanh",
    code: 115,
    division_type: "phường",
    codename: "phuong_thuong_thanh",
    district_code: 4,
  },
  {
    name: "Phường Ngọc Thụy",
    code: 118,
    division_type: "phường",
    codename: "phuong_ngoc_thuy",
    district_code: 4,
  },
  {
    name: "Phường Giang Biên",
    code: 121,
    division_type: "phường",
    codename: "phuong_giang_bien",
    district_code: 4,
  },
  {
    name: "Phường Đức Giang",
    code: 124,
    division_type: "phường",
    codename: "phuong_duc_giang",
    district_code: 4,
  },
  {
    name: "Phường Việt Hưng",
    code: 127,
    division_type: "phường",
    codename: "phuong_viet_hung",
    district_code: 4,
  },
  {
    name: "Phường Gia Thụy",
    code: 130,
    division_type: "phường",
    codename: "phuong_gia_thuy",
    district_code: 4,
  },
  {
    name: "Phường Ngọc Lâm",
    code: 133,
    division_type: "phường",
    codename: "phuong_ngoc_lam",
    district_code: 4,
  },
  {
    name: "Phường Phúc Lợi",
    code: 136,
    division_type: "phường",
    codename: "phuong_phuc_loi",
    district_code: 4,
  },
  {
    name: "Phường Bồ Đề",
    code: 139,
    division_type: "phường",
    codename: "phuong_bo_de",
    district_code: 4,
  },
  {
    name: "Phường Sài Đồng",
    code: 142,
    division_type: "phường",
    codename: "phuong_sai_dong",
    district_code: 4,
  },
  {
    name: "Phường Long Biên",
    code: 145,
    division_type: "phường",
    codename: "phuong_long_bien",
    district_code: 4,
  },
  {
    name: "Phường Thạch Bàn",
    code: 148,
    division_type: "phường",
    codename: "phuong_thach_ban",
    district_code: 4,
  },
  {
    name: "Phường Phúc Đồng",
    code: 151,
    division_type: "phường",
    codename: "phuong_phuc_dong",
    district_code: 4,
  },
  {
    name: "Phường Cự Khối",
    code: 154,
    division_type: "phường",
    codename: "phuong_cu_khoi",
    district_code: 4,
  },
  {
    name: "Phường Nghĩa Đô",
    code: 157,
    division_type: "phường",
    codename: "phuong_nghia_do",
    district_code: 5,
  },
  {
    name: "Phường Nghĩa Tân",
    code: 160,
    division_type: "phường",
    codename: "phuong_nghia_tan",
    district_code: 5,
  },
  {
    name: "Phường Mai Dịch",
    code: 163,
    division_type: "phường",
    codename: "phuong_mai_dich",
    district_code: 5,
  },
  {
    name: "Phường Dịch Vọng",
    code: 166,
    division_type: "phường",
    codename: "phuong_dich_vong",
    district_code: 5,
  },
  {
    name: "Phường Dịch Vọng Hậu",
    code: 167,
    division_type: "phường",
    codename: "phuong_dich_vong_hau",
    district_code: 5,
  },
  {
    name: "Phường Quan Hoa",
    code: 169,
    division_type: "phường",
    codename: "phuong_quan_hoa",
    district_code: 5,
  },
  {
    name: "Phường Yên Hoà",
    code: 172,
    division_type: "phường",
    codename: "phuong_yen_hoa",
    district_code: 5,
  },
  {
    name: "Phường Trung Hoà",
    code: 175,
    division_type: "phường",
    codename: "phuong_trung_hoa",
    district_code: 5,
  },
  {
    name: "Phường Cát Linh",
    code: 178,
    division_type: "phường",
    codename: "phuong_cat_linh",
    district_code: 6,
  },
  {
    name: "Phường Văn Miếu",
    code: 181,
    division_type: "phường",
    codename: "phuong_van_mieu",
    district_code: 6,
  },
  {
    name: "Phường Quốc Tử Giám",
    code: 184,
    division_type: "phường",
    codename: "phuong_quoc_tu_giam",
    district_code: 6,
  },
  {
    name: "Phường Láng Thượng",
    code: 187,
    division_type: "phường",
    codename: "phuong_lang_thuong",
    district_code: 6,
  },
  {
    name: "Phường Ô Chợ Dừa",
    code: 190,
    division_type: "phường",
    codename: "phuong_o_cho_dua",
    district_code: 6,
  },
  {
    name: "Phường Văn Chương",
    code: 193,
    division_type: "phường",
    codename: "phuong_van_chuong",
    district_code: 6,
  },
  {
    name: "Phường Hàng Bột",
    code: 196,
    division_type: "phường",
    codename: "phuong_hang_bot",
    district_code: 6,
  },
  {
    name: "Phường Láng Hạ",
    code: 199,
    division_type: "phường",
    codename: "phuong_lang_ha",
    district_code: 6,
  },
  {
    name: "Phường Khâm Thiên",
    code: 202,
    division_type: "phường",
    codename: "phuong_kham_thien",
    district_code: 6,
  },
  {
    name: "Phường Thổ Quan",
    code: 205,
    division_type: "phường",
    codename: "phuong_tho_quan",
    district_code: 6,
  },
  {
    name: "Phường Nam Đồng",
    code: 208,
    division_type: "phường",
    codename: "phuong_nam_dong",
    district_code: 6,
  },
  {
    name: "Phường Trung Phụng",
    code: 211,
    division_type: "phường",
    codename: "phuong_trung_phung",
    district_code: 6,
  },
  {
    name: "Phường Quang Trung",
    code: 214,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 6,
  },
  {
    name: "Phường Trung Liệt",
    code: 217,
    division_type: "phường",
    codename: "phuong_trung_liet",
    district_code: 6,
  },
  {
    name: "Phường Phương Liên",
    code: 220,
    division_type: "phường",
    codename: "phuong_phuong_lien",
    district_code: 6,
  },
  {
    name: "Phường Thịnh Quang",
    code: 223,
    division_type: "phường",
    codename: "phuong_thinh_quang",
    district_code: 6,
  },
  {
    name: "Phường Trung Tự",
    code: 226,
    division_type: "phường",
    codename: "phuong_trung_tu",
    district_code: 6,
  },
  {
    name: "Phường Kim Liên",
    code: 229,
    division_type: "phường",
    codename: "phuong_kim_lien",
    district_code: 6,
  },
  {
    name: "Phường Phương Mai",
    code: 232,
    division_type: "phường",
    codename: "phuong_phuong_mai",
    district_code: 6,
  },
  {
    name: "Phường Ngã Tư Sở",
    code: 235,
    division_type: "phường",
    codename: "phuong_nga_tu_so",
    district_code: 6,
  },
  {
    name: "Phường Khương Thượng",
    code: 238,
    division_type: "phường",
    codename: "phuong_khuong_thuong",
    district_code: 6,
  },
  {
    name: "Phường Nguyễn Du",
    code: 241,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 7,
  },
  {
    name: "Phường Bạch Đằng",
    code: 244,
    division_type: "phường",
    codename: "phuong_bach_dang",
    district_code: 7,
  },
  {
    name: "Phường Phạm Đình Hổ",
    code: 247,
    division_type: "phường",
    codename: "phuong_pham_dinh_ho",
    district_code: 7,
  },
  {
    name: "Phường Lê Đại Hành",
    code: 256,
    division_type: "phường",
    codename: "phuong_le_dai_hanh",
    district_code: 7,
  },
  {
    name: "Phường Đồng Nhân",
    code: 259,
    division_type: "phường",
    codename: "phuong_dong_nhan",
    district_code: 7,
  },
  {
    name: "Phường Phố Huế",
    code: 262,
    division_type: "phường",
    codename: "phuong_pho_hue",
    district_code: 7,
  },
  {
    name: "Phường Đống Mác",
    code: 265,
    division_type: "phường",
    codename: "phuong_dong_mac",
    district_code: 7,
  },
  {
    name: "Phường Thanh Lương",
    code: 268,
    division_type: "phường",
    codename: "phuong_thanh_luong",
    district_code: 7,
  },
  {
    name: "Phường Thanh Nhàn",
    code: 271,
    division_type: "phường",
    codename: "phuong_thanh_nhan",
    district_code: 7,
  },
  {
    name: "Phường Cầu Dền",
    code: 274,
    division_type: "phường",
    codename: "phuong_cau_den",
    district_code: 7,
  },
  {
    name: "Phường Bách Khoa",
    code: 277,
    division_type: "phường",
    codename: "phuong_bach_khoa",
    district_code: 7,
  },
  {
    name: "Phường Đồng Tâm",
    code: 280,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 7,
  },
  {
    name: "Phường Vĩnh Tuy",
    code: 283,
    division_type: "phường",
    codename: "phuong_vinh_tuy",
    district_code: 7,
  },
  {
    name: "Phường Bạch Mai",
    code: 286,
    division_type: "phường",
    codename: "phuong_bach_mai",
    district_code: 7,
  },
  {
    name: "Phường Quỳnh Mai",
    code: 289,
    division_type: "phường",
    codename: "phuong_quynh_mai",
    district_code: 7,
  },
  {
    name: "Phường Quỳnh Lôi",
    code: 292,
    division_type: "phường",
    codename: "phuong_quynh_loi",
    district_code: 7,
  },
  {
    name: "Phường Minh Khai",
    code: 295,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 7,
  },
  {
    name: "Phường Trương Định",
    code: 298,
    division_type: "phường",
    codename: "phuong_truong_dinh",
    district_code: 7,
  },
  {
    name: "Phường Thanh Trì",
    code: 301,
    division_type: "phường",
    codename: "phuong_thanh_tri",
    district_code: 8,
  },
  {
    name: "Phường Vĩnh Hưng",
    code: 304,
    division_type: "phường",
    codename: "phuong_vinh_hung",
    district_code: 8,
  },
  {
    name: "Phường Định Công",
    code: 307,
    division_type: "phường",
    codename: "phuong_dinh_cong",
    district_code: 8,
  },
  {
    name: "Phường Mai Động",
    code: 310,
    division_type: "phường",
    codename: "phuong_mai_dong",
    district_code: 8,
  },
  {
    name: "Phường Tương Mai",
    code: 313,
    division_type: "phường",
    codename: "phuong_tuong_mai",
    district_code: 8,
  },
  {
    name: "Phường Đại Kim",
    code: 316,
    division_type: "phường",
    codename: "phuong_dai_kim",
    district_code: 8,
  },
  {
    name: "Phường Tân Mai",
    code: 319,
    division_type: "phường",
    codename: "phuong_tan_mai",
    district_code: 8,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 322,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 8,
  },
  {
    name: "Phường Giáp Bát",
    code: 325,
    division_type: "phường",
    codename: "phuong_giap_bat",
    district_code: 8,
  },
  {
    name: "Phường Lĩnh Nam",
    code: 328,
    division_type: "phường",
    codename: "phuong_linh_nam",
    district_code: 8,
  },
  {
    name: "Phường Thịnh Liệt",
    code: 331,
    division_type: "phường",
    codename: "phuong_thinh_liet",
    district_code: 8,
  },
  {
    name: "Phường Trần Phú",
    code: 334,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 8,
  },
  {
    name: "Phường Hoàng Liệt",
    code: 337,
    division_type: "phường",
    codename: "phuong_hoang_liet",
    district_code: 8,
  },
  {
    name: "Phường Yên Sở",
    code: 340,
    division_type: "phường",
    codename: "phuong_yen_so",
    district_code: 8,
  },
  {
    name: "Phường Nhân Chính",
    code: 343,
    division_type: "phường",
    codename: "phuong_nhan_chinh",
    district_code: 9,
  },
  {
    name: "Phường Thượng Đình",
    code: 346,
    division_type: "phường",
    codename: "phuong_thuong_dinh",
    district_code: 9,
  },
  {
    name: "Phường Khương Trung",
    code: 349,
    division_type: "phường",
    codename: "phuong_khuong_trung",
    district_code: 9,
  },
  {
    name: "Phường Khương Mai",
    code: 352,
    division_type: "phường",
    codename: "phuong_khuong_mai",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Trung",
    code: 355,
    division_type: "phường",
    codename: "phuong_thanh_xuan_trung",
    district_code: 9,
  },
  {
    name: "Phường Phương Liệt",
    code: 358,
    division_type: "phường",
    codename: "phuong_phuong_liet",
    district_code: 9,
  },
  {
    name: "Phường Hạ Đình",
    code: 361,
    division_type: "phường",
    codename: "phuong_ha_dinh",
    district_code: 9,
  },
  {
    name: "Phường Khương Đình",
    code: 364,
    division_type: "phường",
    codename: "phuong_khuong_dinh",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Bắc",
    code: 367,
    division_type: "phường",
    codename: "phuong_thanh_xuan_bac",
    district_code: 9,
  },
  {
    name: "Phường Thanh Xuân Nam",
    code: 370,
    division_type: "phường",
    codename: "phuong_thanh_xuan_nam",
    district_code: 9,
  },
  {
    name: "Phường Kim Giang",
    code: 373,
    division_type: "phường",
    codename: "phuong_kim_giang",
    district_code: 9,
  },
  {
    name: "Thị trấn Sóc Sơn",
    code: 376,
    division_type: "thị trấn",
    codename: "thi_tran_soc_son",
    district_code: 16,
  },
  {
    name: "Xã Bắc Sơn",
    code: 379,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 16,
  },
  {
    name: "Xã Minh Trí",
    code: 382,
    division_type: "xã",
    codename: "xa_minh_tri",
    district_code: 16,
  },
  {
    name: "Xã Hồng Kỳ",
    code: 385,
    division_type: "xã",
    codename: "xa_hong_ky",
    district_code: 16,
  },
  {
    name: "Xã Nam Sơn",
    code: 388,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 16,
  },
  {
    name: "Xã Trung Giã",
    code: 391,
    division_type: "xã",
    codename: "xa_trung_gia",
    district_code: 16,
  },
  {
    name: "Xã Tân Hưng",
    code: 394,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 16,
  },
  {
    name: "Xã Minh Phú",
    code: 397,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 16,
  },
  {
    name: "Xã Phù Linh",
    code: 400,
    division_type: "xã",
    codename: "xa_phu_linh",
    district_code: 16,
  },
  {
    name: "Xã Bắc Phú",
    code: 403,
    division_type: "xã",
    codename: "xa_bac_phu",
    district_code: 16,
  },
  {
    name: "Xã Tân Minh",
    code: 406,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 16,
  },
  {
    name: "Xã Quang Tiến",
    code: 409,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 16,
  },
  {
    name: "Xã Hiền Ninh",
    code: 412,
    division_type: "xã",
    codename: "xa_hien_ninh",
    district_code: 16,
  },
  {
    name: "Xã Tân Dân",
    code: 415,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 16,
  },
  {
    name: "Xã Tiên Dược",
    code: 418,
    division_type: "xã",
    codename: "xa_tien_duoc",
    district_code: 16,
  },
  {
    name: "Xã Việt Long",
    code: 421,
    division_type: "xã",
    codename: "xa_viet_long",
    district_code: 16,
  },
  {
    name: "Xã Xuân Giang",
    code: 424,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 16,
  },
  {
    name: "Xã Mai Đình",
    code: 427,
    division_type: "xã",
    codename: "xa_mai_dinh",
    district_code: 16,
  },
  {
    name: "Xã Đức Hoà",
    code: 430,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 16,
  },
  {
    name: "Xã Thanh Xuân",
    code: 433,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 16,
  },
  {
    name: "Xã Đông Xuân",
    code: 436,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 16,
  },
  {
    name: "Xã Kim Lũ",
    code: 439,
    division_type: "xã",
    codename: "xa_kim_lu",
    district_code: 16,
  },
  {
    name: "Xã Phú Cường",
    code: 442,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 16,
  },
  {
    name: "Xã Phú Minh",
    code: 445,
    division_type: "xã",
    codename: "xa_phu_minh",
    district_code: 16,
  },
  {
    name: "Xã Phù Lỗ",
    code: 448,
    division_type: "xã",
    codename: "xa_phu_lo",
    district_code: 16,
  },
  {
    name: "Xã Xuân Thu",
    code: 451,
    division_type: "xã",
    codename: "xa_xuan_thu",
    district_code: 16,
  },
  {
    name: "Thị trấn Đông Anh",
    code: 454,
    division_type: "thị trấn",
    codename: "thi_tran_dong_anh",
    district_code: 17,
  },
  {
    name: "Xã Xuân Nộn",
    code: 457,
    division_type: "xã",
    codename: "xa_xuan_non",
    district_code: 17,
  },
  {
    name: "Xã Thuỵ Lâm",
    code: 460,
    division_type: "xã",
    codename: "xa_thuy_lam",
    district_code: 17,
  },
  {
    name: "Xã Bắc Hồng",
    code: 463,
    division_type: "xã",
    codename: "xa_bac_hong",
    district_code: 17,
  },
  {
    name: "Xã Nguyên Khê",
    code: 466,
    division_type: "xã",
    codename: "xa_nguyen_khe",
    district_code: 17,
  },
  {
    name: "Xã Nam Hồng",
    code: 469,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 17,
  },
  {
    name: "Xã Tiên Dương",
    code: 472,
    division_type: "xã",
    codename: "xa_tien_duong",
    district_code: 17,
  },
  {
    name: "Xã Vân Hà",
    code: 475,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 17,
  },
  {
    name: "Xã Uy Nỗ",
    code: 478,
    division_type: "xã",
    codename: "xa_uy_no",
    district_code: 17,
  },
  {
    name: "Xã Vân Nội",
    code: 481,
    division_type: "xã",
    codename: "xa_van_noi",
    district_code: 17,
  },
  {
    name: "Xã Liên Hà",
    code: 484,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 17,
  },
  {
    name: "Xã Việt Hùng",
    code: 487,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 17,
  },
  {
    name: "Xã Kim Nỗ",
    code: 490,
    division_type: "xã",
    codename: "xa_kim_no",
    district_code: 17,
  },
  {
    name: "Xã Kim Chung",
    code: 493,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 17,
  },
  {
    name: "Xã Dục Tú",
    code: 496,
    division_type: "xã",
    codename: "xa_duc_tu",
    district_code: 17,
  },
  {
    name: "Xã Đại Mạch",
    code: 499,
    division_type: "xã",
    codename: "xa_dai_mach",
    district_code: 17,
  },
  {
    name: "Xã Vĩnh Ngọc",
    code: 502,
    division_type: "xã",
    codename: "xa_vinh_ngoc",
    district_code: 17,
  },
  {
    name: "Xã Cổ Loa",
    code: 505,
    division_type: "xã",
    codename: "xa_co_loa",
    district_code: 17,
  },
  {
    name: "Xã Hải Bối",
    code: 508,
    division_type: "xã",
    codename: "xa_hai_boi",
    district_code: 17,
  },
  {
    name: "Xã Xuân Canh",
    code: 511,
    division_type: "xã",
    codename: "xa_xuan_canh",
    district_code: 17,
  },
  {
    name: "Xã Võng La",
    code: 514,
    division_type: "xã",
    codename: "xa_vong_la",
    district_code: 17,
  },
  {
    name: "Xã Tàm Xá",
    code: 517,
    division_type: "xã",
    codename: "xa_tam_xa",
    district_code: 17,
  },
  {
    name: "Xã Mai Lâm",
    code: 520,
    division_type: "xã",
    codename: "xa_mai_lam",
    district_code: 17,
  },
  {
    name: "Xã Đông Hội",
    code: 523,
    division_type: "xã",
    codename: "xa_dong_hoi",
    district_code: 17,
  },
  {
    name: "Thị trấn Yên Viên",
    code: 526,
    division_type: "thị trấn",
    codename: "thi_tran_yen_vien",
    district_code: 18,
  },
  {
    name: "Xã Yên Thường",
    code: 529,
    division_type: "xã",
    codename: "xa_yen_thuong",
    district_code: 18,
  },
  {
    name: "Xã Yên Viên",
    code: 532,
    division_type: "xã",
    codename: "xa_yen_vien",
    district_code: 18,
  },
  {
    name: "Xã Ninh Hiệp",
    code: 535,
    division_type: "xã",
    codename: "xa_ninh_hiep",
    district_code: 18,
  },
  {
    name: "Xã Đình Xuyên",
    code: 538,
    division_type: "xã",
    codename: "xa_dinh_xuyen",
    district_code: 18,
  },
  {
    name: "Xã Dương Hà",
    code: 541,
    division_type: "xã",
    codename: "xa_duong_ha",
    district_code: 18,
  },
  {
    name: "Xã Phù Đổng",
    code: 544,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 18,
  },
  {
    name: "Xã Trung Mầu",
    code: 547,
    division_type: "xã",
    codename: "xa_trung_mau",
    district_code: 18,
  },
  {
    name: "Xã Lệ Chi",
    code: 550,
    division_type: "xã",
    codename: "xa_le_chi",
    district_code: 18,
  },
  {
    name: "Xã Cổ Bi",
    code: 553,
    division_type: "xã",
    codename: "xa_co_bi",
    district_code: 18,
  },
  {
    name: "Xã Đặng Xá",
    code: 556,
    division_type: "xã",
    codename: "xa_dang_xa",
    district_code: 18,
  },
  {
    name: "Xã Phú Thị",
    code: 559,
    division_type: "xã",
    codename: "xa_phu_thi",
    district_code: 18,
  },
  {
    name: "Xã Kim Sơn",
    code: 562,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 18,
  },
  {
    name: "Thị trấn Trâu Quỳ",
    code: 565,
    division_type: "thị trấn",
    codename: "thi_tran_trau_quy",
    district_code: 18,
  },
  {
    name: "Xã Dương Quang",
    code: 568,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 18,
  },
  {
    name: "Xã Dương Xá",
    code: 571,
    division_type: "xã",
    codename: "xa_duong_xa",
    district_code: 18,
  },
  {
    name: "Xã Đông Dư",
    code: 574,
    division_type: "xã",
    codename: "xa_dong_du",
    district_code: 18,
  },
  {
    name: "Xã Đa Tốn",
    code: 577,
    division_type: "xã",
    codename: "xa_da_ton",
    district_code: 18,
  },
  {
    name: "Xã Kiêu Kỵ",
    code: 580,
    division_type: "xã",
    codename: "xa_kieu_ky",
    district_code: 18,
  },
  {
    name: "Xã Bát Tràng",
    code: 583,
    division_type: "xã",
    codename: "xa_bat_trang",
    district_code: 18,
  },
  {
    name: "Xã Kim Lan",
    code: 586,
    division_type: "xã",
    codename: "xa_kim_lan",
    district_code: 18,
  },
  {
    name: "Xã Văn Đức",
    code: 589,
    division_type: "xã",
    codename: "xa_van_duc",
    district_code: 18,
  },
  {
    name: "Phường Cầu Diễn",
    code: 592,
    division_type: "phường",
    codename: "phuong_cau_dien",
    district_code: 19,
  },
  {
    name: "Phường Xuân Phương",
    code: 622,
    division_type: "phường",
    codename: "phuong_xuan_phuong",
    district_code: 19,
  },
  {
    name: "Phường Phương Canh",
    code: 623,
    division_type: "phường",
    codename: "phuong_phuong_canh",
    district_code: 19,
  },
  {
    name: "Phường Mỹ Đình 1",
    code: 625,
    division_type: "phường",
    codename: "phuong_my_dinh_1",
    district_code: 19,
  },
  {
    name: "Phường Mỹ Đình 2",
    code: 626,
    division_type: "phường",
    codename: "phuong_my_dinh_2",
    district_code: 19,
  },
  {
    name: "Phường Tây Mỗ",
    code: 628,
    division_type: "phường",
    codename: "phuong_tay_mo",
    district_code: 19,
  },
  {
    name: "Phường Mễ Trì",
    code: 631,
    division_type: "phường",
    codename: "phuong_me_tri",
    district_code: 19,
  },
  {
    name: "Phường Phú Đô",
    code: 632,
    division_type: "phường",
    codename: "phuong_phu_do",
    district_code: 19,
  },
  {
    name: "Phường Đại Mỗ",
    code: 634,
    division_type: "phường",
    codename: "phuong_dai_mo",
    district_code: 19,
  },
  {
    name: "Phường Trung Văn",
    code: 637,
    division_type: "phường",
    codename: "phuong_trung_van",
    district_code: 19,
  },
  {
    name: "Thị trấn Văn Điển",
    code: 640,
    division_type: "thị trấn",
    codename: "thi_tran_van_dien",
    district_code: 20,
  },
  {
    name: "Xã Tân Triều",
    code: 643,
    division_type: "xã",
    codename: "xa_tan_trieu",
    district_code: 20,
  },
  {
    name: "Xã Thanh Liệt",
    code: 646,
    division_type: "xã",
    codename: "xa_thanh_liet",
    district_code: 20,
  },
  {
    name: "Xã Tả Thanh Oai",
    code: 649,
    division_type: "xã",
    codename: "xa_ta_thanh_oai",
    district_code: 20,
  },
  {
    name: "Xã Hữu Hoà",
    code: 652,
    division_type: "xã",
    codename: "xa_huu_hoa",
    district_code: 20,
  },
  {
    name: "Xã Tam Hiệp",
    code: 655,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 20,
  },
  {
    name: "Xã Tứ Hiệp",
    code: 658,
    division_type: "xã",
    codename: "xa_tu_hiep",
    district_code: 20,
  },
  {
    name: "Xã Yên Mỹ",
    code: 661,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 20,
  },
  {
    name: "Xã Vĩnh Quỳnh",
    code: 664,
    division_type: "xã",
    codename: "xa_vinh_quynh",
    district_code: 20,
  },
  {
    name: "Xã Ngũ Hiệp",
    code: 667,
    division_type: "xã",
    codename: "xa_ngu_hiep",
    district_code: 20,
  },
  {
    name: "Xã Duyên Hà",
    code: 670,
    division_type: "xã",
    codename: "xa_duyen_ha",
    district_code: 20,
  },
  {
    name: "Xã Ngọc Hồi",
    code: 673,
    division_type: "xã",
    codename: "xa_ngoc_hoi",
    district_code: 20,
  },
  {
    name: "Xã Vạn Phúc",
    code: 676,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 20,
  },
  {
    name: "Xã Đại áng",
    code: 679,
    division_type: "xã",
    codename: "xa_dai_ang",
    district_code: 20,
  },
  {
    name: "Xã Liên Ninh",
    code: 682,
    division_type: "xã",
    codename: "xa_lien_ninh",
    district_code: 20,
  },
  {
    name: "Xã Đông Mỹ",
    code: 685,
    division_type: "xã",
    codename: "xa_dong_my",
    district_code: 20,
  },
  {
    name: "Phường Thượng Cát",
    code: 595,
    division_type: "phường",
    codename: "phuong_thuong_cat",
    district_code: 21,
  },
  {
    name: "Phường Liên Mạc",
    code: 598,
    division_type: "phường",
    codename: "phuong_lien_mac",
    district_code: 21,
  },
  {
    name: "Phường Đông Ngạc",
    code: 601,
    division_type: "phường",
    codename: "phuong_dong_ngac",
    district_code: 21,
  },
  {
    name: "Phường Đức Thắng",
    code: 602,
    division_type: "phường",
    codename: "phuong_duc_thang",
    district_code: 21,
  },
  {
    name: "Phường Thụy Phương",
    code: 604,
    division_type: "phường",
    codename: "phuong_thuy_phuong",
    district_code: 21,
  },
  {
    name: "Phường Tây Tựu",
    code: 607,
    division_type: "phường",
    codename: "phuong_tay_tuu",
    district_code: 21,
  },
  {
    name: "Phường Xuân Đỉnh",
    code: 610,
    division_type: "phường",
    codename: "phuong_xuan_dinh",
    district_code: 21,
  },
  {
    name: "Phường Xuân Tảo",
    code: 611,
    division_type: "phường",
    codename: "phuong_xuan_tao",
    district_code: 21,
  },
  {
    name: "Phường Minh Khai",
    code: 613,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 21,
  },
  {
    name: "Phường Cổ Nhuế 1",
    code: 616,
    division_type: "phường",
    codename: "phuong_co_nhue_1",
    district_code: 21,
  },
  {
    name: "Phường Cổ Nhuế 2",
    code: 617,
    division_type: "phường",
    codename: "phuong_co_nhue_2",
    district_code: 21,
  },
  {
    name: "Phường Phú Diễn",
    code: 619,
    division_type: "phường",
    codename: "phuong_phu_dien",
    district_code: 21,
  },
  {
    name: "Phường Phúc Diễn",
    code: 620,
    division_type: "phường",
    codename: "phuong_phuc_dien",
    district_code: 21,
  },
  {
    name: "Thị trấn Chi Đông",
    code: 8973,
    division_type: "thị trấn",
    codename: "thi_tran_chi_dong",
    district_code: 250,
  },
  {
    name: "Xã Đại Thịnh",
    code: 8974,
    division_type: "xã",
    codename: "xa_dai_thinh",
    district_code: 250,
  },
  {
    name: "Xã Kim Hoa",
    code: 8977,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 250,
  },
  {
    name: "Xã Thạch Đà",
    code: 8980,
    division_type: "xã",
    codename: "xa_thach_da",
    district_code: 250,
  },
  {
    name: "Xã Tiến Thắng",
    code: 8983,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 250,
  },
  {
    name: "Xã Tự Lập",
    code: 8986,
    division_type: "xã",
    codename: "xa_tu_lap",
    district_code: 250,
  },
  {
    name: "Thị trấn Quang Minh",
    code: 8989,
    division_type: "thị trấn",
    codename: "thi_tran_quang_minh",
    district_code: 250,
  },
  {
    name: "Xã Thanh Lâm",
    code: 8992,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 250,
  },
  {
    name: "Xã Tam Đồng",
    code: 8995,
    division_type: "xã",
    codename: "xa_tam_dong",
    district_code: 250,
  },
  {
    name: "Xã Liên Mạc",
    code: 8998,
    division_type: "xã",
    codename: "xa_lien_mac",
    district_code: 250,
  },
  {
    name: "Xã Vạn Yên",
    code: 9001,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 250,
  },
  {
    name: "Xã Chu Phan",
    code: 9004,
    division_type: "xã",
    codename: "xa_chu_phan",
    district_code: 250,
  },
  {
    name: "Xã Tiến Thịnh",
    code: 9007,
    division_type: "xã",
    codename: "xa_tien_thinh",
    district_code: 250,
  },
  {
    name: "Xã Mê Linh",
    code: 9010,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 250,
  },
  {
    name: "Xã Văn Khê",
    code: 9013,
    division_type: "xã",
    codename: "xa_van_khe",
    district_code: 250,
  },
  {
    name: "Xã Hoàng Kim",
    code: 9016,
    division_type: "xã",
    codename: "xa_hoang_kim",
    district_code: 250,
  },
  {
    name: "Xã Tiền Phong",
    code: 9019,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 250,
  },
  {
    name: "Xã Tráng Việt",
    code: 9022,
    division_type: "xã",
    codename: "xa_trang_viet",
    district_code: 250,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 9538,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 268,
  },
  {
    name: "Phường Mộ Lao",
    code: 9541,
    division_type: "phường",
    codename: "phuong_mo_lao",
    district_code: 268,
  },
  {
    name: "Phường Văn Quán",
    code: 9542,
    division_type: "phường",
    codename: "phuong_van_quan",
    district_code: 268,
  },
  {
    name: "Phường Vạn Phúc",
    code: 9544,
    division_type: "phường",
    codename: "phuong_van_phuc",
    district_code: 268,
  },
  {
    name: "Phường Yết Kiêu",
    code: 9547,
    division_type: "phường",
    codename: "phuong_yet_kieu",
    district_code: 268,
  },
  {
    name: "Phường Quang Trung",
    code: 9550,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 268,
  },
  {
    name: "Phường La Khê",
    code: 9551,
    division_type: "phường",
    codename: "phuong_la_khe",
    district_code: 268,
  },
  {
    name: "Phường Phú La",
    code: 9552,
    division_type: "phường",
    codename: "phuong_phu_la",
    district_code: 268,
  },
  {
    name: "Phường Phúc La",
    code: 9553,
    division_type: "phường",
    codename: "phuong_phuc_la",
    district_code: 268,
  },
  {
    name: "Phường Hà Cầu",
    code: 9556,
    division_type: "phường",
    codename: "phuong_ha_cau",
    district_code: 268,
  },
  {
    name: "Phường Yên Nghĩa",
    code: 9562,
    division_type: "phường",
    codename: "phuong_yen_nghia",
    district_code: 268,
  },
  {
    name: "Phường Kiến Hưng",
    code: 9565,
    division_type: "phường",
    codename: "phuong_kien_hung",
    district_code: 268,
  },
  {
    name: "Phường Phú Lãm",
    code: 9568,
    division_type: "phường",
    codename: "phuong_phu_lam",
    district_code: 268,
  },
  {
    name: "Phường Phú Lương",
    code: 9571,
    division_type: "phường",
    codename: "phuong_phu_luong",
    district_code: 268,
  },
  {
    name: "Phường Dương Nội",
    code: 9886,
    division_type: "phường",
    codename: "phuong_duong_noi",
    district_code: 268,
  },
  {
    name: "Phường Đồng Mai",
    code: 10117,
    division_type: "phường",
    codename: "phuong_dong_mai",
    district_code: 268,
  },
  {
    name: "Phường Biên Giang",
    code: 10123,
    division_type: "phường",
    codename: "phuong_bien_giang",
    district_code: 268,
  },
  {
    name: "Phường Lê Lợi",
    code: 9574,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 269,
  },
  {
    name: "Phường Phú Thịnh",
    code: 9577,
    division_type: "phường",
    codename: "phuong_phu_thinh",
    district_code: 269,
  },
  {
    name: "Phường Ngô Quyền",
    code: 9580,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 269,
  },
  {
    name: "Phường Quang Trung",
    code: 9583,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 269,
  },
  {
    name: "Phường Sơn Lộc",
    code: 9586,
    division_type: "phường",
    codename: "phuong_son_loc",
    district_code: 269,
  },
  {
    name: "Phường Xuân Khanh",
    code: 9589,
    division_type: "phường",
    codename: "phuong_xuan_khanh",
    district_code: 269,
  },
  {
    name: "Xã Đường Lâm",
    code: 9592,
    division_type: "xã",
    codename: "xa_duong_lam",
    district_code: 269,
  },
  {
    name: "Phường Viên Sơn",
    code: 9595,
    division_type: "phường",
    codename: "phuong_vien_son",
    district_code: 269,
  },
  {
    name: "Xã Xuân Sơn",
    code: 9598,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 269,
  },
  {
    name: "Phường Trung Hưng",
    code: 9601,
    division_type: "phường",
    codename: "phuong_trung_hung",
    district_code: 269,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 9604,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 269,
  },
  {
    name: "Phường Trung Sơn Trầm",
    code: 9607,
    division_type: "phường",
    codename: "phuong_trung_son_tram",
    district_code: 269,
  },
  {
    name: "Xã Kim Sơn",
    code: 9610,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 269,
  },
  {
    name: "Xã Sơn Đông",
    code: 9613,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 269,
  },
  {
    name: "Xã Cổ Đông",
    code: 9616,
    division_type: "xã",
    codename: "xa_co_dong",
    district_code: 269,
  },
  {
    name: "Thị trấn Tây Đằng",
    code: 9619,
    division_type: "thị trấn",
    codename: "thi_tran_tay_dang",
    district_code: 271,
  },
  {
    name: "Xã Phú Cường",
    code: 9625,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 271,
  },
  {
    name: "Xã Cổ Đô",
    code: 9628,
    division_type: "xã",
    codename: "xa_co_do",
    district_code: 271,
  },
  {
    name: "Xã Tản Hồng",
    code: 9631,
    division_type: "xã",
    codename: "xa_tan_hong",
    district_code: 271,
  },
  {
    name: "Xã Vạn Thắng",
    code: 9634,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 271,
  },
  {
    name: "Xã Châu Sơn",
    code: 9637,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 271,
  },
  {
    name: "Xã Phong Vân",
    code: 9640,
    division_type: "xã",
    codename: "xa_phong_van",
    district_code: 271,
  },
  {
    name: "Xã Phú Đông",
    code: 9643,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 271,
  },
  {
    name: "Xã Phú Phương",
    code: 9646,
    division_type: "xã",
    codename: "xa_phu_phuong",
    district_code: 271,
  },
  {
    name: "Xã Phú Châu",
    code: 9649,
    division_type: "xã",
    codename: "xa_phu_chau",
    district_code: 271,
  },
  {
    name: "Xã Thái Hòa",
    code: 9652,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 271,
  },
  {
    name: "Xã Đồng Thái",
    code: 9655,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 271,
  },
  {
    name: "Xã Phú Sơn",
    code: 9658,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 271,
  },
  {
    name: "Xã Minh Châu",
    code: 9661,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 271,
  },
  {
    name: "Xã Vật Lại",
    code: 9664,
    division_type: "xã",
    codename: "xa_vat_lai",
    district_code: 271,
  },
  {
    name: "Xã Chu Minh",
    code: 9667,
    division_type: "xã",
    codename: "xa_chu_minh",
    district_code: 271,
  },
  {
    name: "Xã Tòng Bạt",
    code: 9670,
    division_type: "xã",
    codename: "xa_tong_bat",
    district_code: 271,
  },
  {
    name: "Xã Cẩm Lĩnh",
    code: 9673,
    division_type: "xã",
    codename: "xa_cam_linh",
    district_code: 271,
  },
  {
    name: "Xã Sơn Đà",
    code: 9676,
    division_type: "xã",
    codename: "xa_son_da",
    district_code: 271,
  },
  {
    name: "Xã Đông Quang",
    code: 9679,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 271,
  },
  {
    name: "Xã Tiên Phong",
    code: 9682,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 271,
  },
  {
    name: "Xã Thụy An",
    code: 9685,
    division_type: "xã",
    codename: "xa_thuy_an",
    district_code: 271,
  },
  {
    name: "Xã Cam Thượng",
    code: 9688,
    division_type: "xã",
    codename: "xa_cam_thuong",
    district_code: 271,
  },
  {
    name: "Xã Thuần Mỹ",
    code: 9691,
    division_type: "xã",
    codename: "xa_thuan_my",
    district_code: 271,
  },
  {
    name: "Xã Tản Lĩnh",
    code: 9694,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 271,
  },
  {
    name: "Xã Ba Trại",
    code: 9697,
    division_type: "xã",
    codename: "xa_ba_trai",
    district_code: 271,
  },
  {
    name: "Xã Minh Quang",
    code: 9700,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 271,
  },
  {
    name: "Xã Ba Vì",
    code: 9703,
    division_type: "xã",
    codename: "xa_ba_vi",
    district_code: 271,
  },
  {
    name: "Xã Vân Hòa",
    code: 9706,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 271,
  },
  {
    name: "Xã Yên Bài",
    code: 9709,
    division_type: "xã",
    codename: "xa_yen_bai",
    district_code: 271,
  },
  {
    name: "Xã Khánh Thượng",
    code: 9712,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 271,
  },
  {
    name: "Thị trấn Phúc Thọ",
    code: 9715,
    division_type: "thị trấn",
    codename: "thi_tran_phuc_tho",
    district_code: 272,
  },
  {
    name: "Xã Vân Hà",
    code: 9718,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 272,
  },
  {
    name: "Xã Vân Phúc",
    code: 9721,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 272,
  },
  {
    name: "Xã Vân Nam",
    code: 9724,
    division_type: "xã",
    codename: "xa_van_nam",
    district_code: 272,
  },
  {
    name: "Xã Xuân Đình",
    code: 9727,
    division_type: "xã",
    codename: "xa_xuan_dinh",
    district_code: 272,
  },
  {
    name: "Xã Sen Phương",
    code: 9733,
    division_type: "xã",
    codename: "xa_sen_phuong",
    district_code: 272,
  },
  {
    name: "Xã Võng Xuyên",
    code: 9739,
    division_type: "xã",
    codename: "xa_vong_xuyen",
    district_code: 272,
  },
  {
    name: "Xã Thọ Lộc",
    code: 9742,
    division_type: "xã",
    codename: "xa_tho_loc",
    district_code: 272,
  },
  {
    name: "Xã Long Xuyên",
    code: 9745,
    division_type: "xã",
    codename: "xa_long_xuyen",
    district_code: 272,
  },
  {
    name: "Xã Thượng Cốc",
    code: 9748,
    division_type: "xã",
    codename: "xa_thuong_coc",
    district_code: 272,
  },
  {
    name: "Xã Hát Môn",
    code: 9751,
    division_type: "xã",
    codename: "xa_hat_mon",
    district_code: 272,
  },
  {
    name: "Xã Tích Giang",
    code: 9754,
    division_type: "xã",
    codename: "xa_tich_giang",
    district_code: 272,
  },
  {
    name: "Xã Thanh Đa",
    code: 9757,
    division_type: "xã",
    codename: "xa_thanh_da",
    district_code: 272,
  },
  {
    name: "Xã Trạch Mỹ Lộc",
    code: 9760,
    division_type: "xã",
    codename: "xa_trach_my_loc",
    district_code: 272,
  },
  {
    name: "Xã Phúc Hòa",
    code: 9763,
    division_type: "xã",
    codename: "xa_phuc_hoa",
    district_code: 272,
  },
  {
    name: "Xã Ngọc Tảo",
    code: 9766,
    division_type: "xã",
    codename: "xa_ngoc_tao",
    district_code: 272,
  },
  {
    name: "Xã Phụng Thượng",
    code: 9769,
    division_type: "xã",
    codename: "xa_phung_thuong",
    district_code: 272,
  },
  {
    name: "Xã Tam Thuấn",
    code: 9772,
    division_type: "xã",
    codename: "xa_tam_thuan",
    district_code: 272,
  },
  {
    name: "Xã Tam Hiệp",
    code: 9775,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 272,
  },
  {
    name: "Xã Hiệp Thuận",
    code: 9778,
    division_type: "xã",
    codename: "xa_hiep_thuan",
    district_code: 272,
  },
  {
    name: "Xã Liên Hiệp",
    code: 9781,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 272,
  },
  {
    name: "Thị trấn Phùng",
    code: 9784,
    division_type: "thị trấn",
    codename: "thi_tran_phung",
    district_code: 273,
  },
  {
    name: "Xã Trung Châu",
    code: 9787,
    division_type: "xã",
    codename: "xa_trung_chau",
    district_code: 273,
  },
  {
    name: "Xã Thọ An",
    code: 9790,
    division_type: "xã",
    codename: "xa_tho_an",
    district_code: 273,
  },
  {
    name: "Xã Thọ Xuân",
    code: 9793,
    division_type: "xã",
    codename: "xa_tho_xuan",
    district_code: 273,
  },
  {
    name: "Xã Hồng Hà",
    code: 9796,
    division_type: "xã",
    codename: "xa_hong_ha",
    district_code: 273,
  },
  {
    name: "Xã Liên Hồng",
    code: 9799,
    division_type: "xã",
    codename: "xa_lien_hong",
    district_code: 273,
  },
  {
    name: "Xã Liên Hà",
    code: 9802,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 273,
  },
  {
    name: "Xã Hạ Mỗ",
    code: 9805,
    division_type: "xã",
    codename: "xa_ha_mo",
    district_code: 273,
  },
  {
    name: "Xã Liên Trung",
    code: 9808,
    division_type: "xã",
    codename: "xa_lien_trung",
    district_code: 273,
  },
  {
    name: "Xã Phương Đình",
    code: 9811,
    division_type: "xã",
    codename: "xa_phuong_dinh",
    district_code: 273,
  },
  {
    name: "Xã Thượng Mỗ",
    code: 9814,
    division_type: "xã",
    codename: "xa_thuong_mo",
    district_code: 273,
  },
  {
    name: "Xã Tân Hội",
    code: 9817,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 273,
  },
  {
    name: "Xã Tân Lập",
    code: 9820,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 273,
  },
  {
    name: "Xã Đan Phượng",
    code: 9823,
    division_type: "xã",
    codename: "xa_dan_phuong",
    district_code: 273,
  },
  {
    name: "Xã Đồng Tháp",
    code: 9826,
    division_type: "xã",
    codename: "xa_dong_thap",
    district_code: 273,
  },
  {
    name: "Xã Song Phượng",
    code: 9829,
    division_type: "xã",
    codename: "xa_song_phuong",
    district_code: 273,
  },
  {
    name: "Thị trấn Trạm Trôi",
    code: 9832,
    division_type: "thị trấn",
    codename: "thi_tran_tram_troi",
    district_code: 274,
  },
  {
    name: "Xã Đức Thượng",
    code: 9835,
    division_type: "xã",
    codename: "xa_duc_thuong",
    district_code: 274,
  },
  {
    name: "Xã Minh Khai",
    code: 9838,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 274,
  },
  {
    name: "Xã Dương Liễu",
    code: 9841,
    division_type: "xã",
    codename: "xa_duong_lieu",
    district_code: 274,
  },
  {
    name: "Xã Di Trạch",
    code: 9844,
    division_type: "xã",
    codename: "xa_di_trach",
    district_code: 274,
  },
  {
    name: "Xã Đức Giang",
    code: 9847,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 274,
  },
  {
    name: "Xã Cát Quế",
    code: 9850,
    division_type: "xã",
    codename: "xa_cat_que",
    district_code: 274,
  },
  {
    name: "Xã Kim Chung",
    code: 9853,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 274,
  },
  {
    name: "Xã Yên Sở",
    code: 9856,
    division_type: "xã",
    codename: "xa_yen_so",
    district_code: 274,
  },
  {
    name: "Xã Sơn Đồng",
    code: 9859,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 274,
  },
  {
    name: "Xã Vân Canh",
    code: 9862,
    division_type: "xã",
    codename: "xa_van_canh",
    district_code: 274,
  },
  {
    name: "Xã Đắc Sở",
    code: 9865,
    division_type: "xã",
    codename: "xa_dac_so",
    district_code: 274,
  },
  {
    name: "Xã Lại Yên",
    code: 9868,
    division_type: "xã",
    codename: "xa_lai_yen",
    district_code: 274,
  },
  {
    name: "Xã Tiền Yên",
    code: 9871,
    division_type: "xã",
    codename: "xa_tien_yen",
    district_code: 274,
  },
  {
    name: "Xã Song Phương",
    code: 9874,
    division_type: "xã",
    codename: "xa_song_phuong",
    district_code: 274,
  },
  {
    name: "Xã An Khánh",
    code: 9877,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 274,
  },
  {
    name: "Xã An Thượng",
    code: 9880,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 274,
  },
  {
    name: "Xã Vân Côn",
    code: 9883,
    division_type: "xã",
    codename: "xa_van_con",
    district_code: 274,
  },
  {
    name: "Xã La Phù",
    code: 9889,
    division_type: "xã",
    codename: "xa_la_phu",
    district_code: 274,
  },
  {
    name: "Xã Đông La",
    code: 9892,
    division_type: "xã",
    codename: "xa_dong_la",
    district_code: 274,
  },
  {
    name: "Xã Đông Xuân",
    code: 4939,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 275,
  },
  {
    name: "Thị trấn Quốc Oai",
    code: 9895,
    division_type: "thị trấn",
    codename: "thi_tran_quoc_oai",
    district_code: 275,
  },
  {
    name: "Xã Sài Sơn",
    code: 9898,
    division_type: "xã",
    codename: "xa_sai_son",
    district_code: 275,
  },
  {
    name: "Xã Phượng Cách",
    code: 9901,
    division_type: "xã",
    codename: "xa_phuong_cach",
    district_code: 275,
  },
  {
    name: "Xã Yên Sơn",
    code: 9904,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 275,
  },
  {
    name: "Xã Ngọc Liệp",
    code: 9907,
    division_type: "xã",
    codename: "xa_ngoc_liep",
    district_code: 275,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 9910,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 275,
  },
  {
    name: "Xã Liệp Tuyết",
    code: 9913,
    division_type: "xã",
    codename: "xa_liep_tuyet",
    district_code: 275,
  },
  {
    name: "Xã Thạch Thán",
    code: 9916,
    division_type: "xã",
    codename: "xa_thach_than",
    district_code: 275,
  },
  {
    name: "Xã Đồng Quang",
    code: 9919,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 275,
  },
  {
    name: "Xã Phú Cát",
    code: 9922,
    division_type: "xã",
    codename: "xa_phu_cat",
    district_code: 275,
  },
  {
    name: "Xã Tuyết Nghĩa",
    code: 9925,
    division_type: "xã",
    codename: "xa_tuyet_nghia",
    district_code: 275,
  },
  {
    name: "Xã Nghĩa Hương",
    code: 9928,
    division_type: "xã",
    codename: "xa_nghia_huong",
    district_code: 275,
  },
  {
    name: "Xã Cộng Hòa",
    code: 9931,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 275,
  },
  {
    name: "Xã Tân Phú",
    code: 9934,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 275,
  },
  {
    name: "Xã Đại Thành",
    code: 9937,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 275,
  },
  {
    name: "Xã Phú Mãn",
    code: 9940,
    division_type: "xã",
    codename: "xa_phu_man",
    district_code: 275,
  },
  {
    name: "Xã Cấn Hữu",
    code: 9943,
    division_type: "xã",
    codename: "xa_can_huu",
    district_code: 275,
  },
  {
    name: "Xã Tân Hòa",
    code: 9946,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 275,
  },
  {
    name: "Xã Hòa Thạch",
    code: 9949,
    division_type: "xã",
    codename: "xa_hoa_thach",
    district_code: 275,
  },
  {
    name: "Xã Đông Yên",
    code: 9952,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 275,
  },
  {
    name: "Xã Yên Trung",
    code: 4927,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 276,
  },
  {
    name: "Xã Yên Bình",
    code: 4930,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 276,
  },
  {
    name: "Xã Tiến Xuân",
    code: 4936,
    division_type: "xã",
    codename: "xa_tien_xuan",
    district_code: 276,
  },
  {
    name: "Thị trấn Liên Quan",
    code: 9955,
    division_type: "thị trấn",
    codename: "thi_tran_lien_quan",
    district_code: 276,
  },
  {
    name: "Xã Đại Đồng",
    code: 9958,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 276,
  },
  {
    name: "Xã Cẩm Yên",
    code: 9961,
    division_type: "xã",
    codename: "xa_cam_yen",
    district_code: 276,
  },
  {
    name: "Xã Lại Thượng",
    code: 9964,
    division_type: "xã",
    codename: "xa_lai_thuong",
    district_code: 276,
  },
  {
    name: "Xã Phú Kim",
    code: 9967,
    division_type: "xã",
    codename: "xa_phu_kim",
    district_code: 276,
  },
  {
    name: "Xã Hương Ngải",
    code: 9970,
    division_type: "xã",
    codename: "xa_huong_ngai",
    district_code: 276,
  },
  {
    name: "Xã Canh Nậu",
    code: 9973,
    division_type: "xã",
    codename: "xa_canh_nau",
    district_code: 276,
  },
  {
    name: "Xã Kim Quan",
    code: 9976,
    division_type: "xã",
    codename: "xa_kim_quan",
    district_code: 276,
  },
  {
    name: "Xã Dị Nậu",
    code: 9979,
    division_type: "xã",
    codename: "xa_di_nau",
    district_code: 276,
  },
  {
    name: "Xã Bình Yên",
    code: 9982,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 276,
  },
  {
    name: "Xã Chàng Sơn",
    code: 9985,
    division_type: "xã",
    codename: "xa_chang_son",
    district_code: 276,
  },
  {
    name: "Xã Thạch Hoà",
    code: 9988,
    division_type: "xã",
    codename: "xa_thach_hoa",
    district_code: 276,
  },
  {
    name: "Xã Cần Kiệm",
    code: 9991,
    division_type: "xã",
    codename: "xa_can_kiem",
    district_code: 276,
  },
  {
    name: "Xã Hữu Bằng",
    code: 9994,
    division_type: "xã",
    codename: "xa_huu_bang",
    district_code: 276,
  },
  {
    name: "Xã Phùng Xá",
    code: 9997,
    division_type: "xã",
    codename: "xa_phung_xa",
    district_code: 276,
  },
  {
    name: "Xã Tân Xã",
    code: 10000,
    division_type: "xã",
    codename: "xa_tan_xa",
    district_code: 276,
  },
  {
    name: "Xã Thạch Xá",
    code: 10003,
    division_type: "xã",
    codename: "xa_thach_xa",
    district_code: 276,
  },
  {
    name: "Xã Bình Phú",
    code: 10006,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 276,
  },
  {
    name: "Xã Hạ Bằng",
    code: 10009,
    division_type: "xã",
    codename: "xa_ha_bang",
    district_code: 276,
  },
  {
    name: "Xã Đồng Trúc",
    code: 10012,
    division_type: "xã",
    codename: "xa_dong_truc",
    district_code: 276,
  },
  {
    name: "Thị trấn Chúc Sơn",
    code: 10015,
    division_type: "thị trấn",
    codename: "thi_tran_chuc_son",
    district_code: 277,
  },
  {
    name: "Thị trấn Xuân Mai",
    code: 10018,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_mai",
    district_code: 277,
  },
  {
    name: "Xã Phụng Châu",
    code: 10021,
    division_type: "xã",
    codename: "xa_phung_chau",
    district_code: 277,
  },
  {
    name: "Xã Tiên Phương",
    code: 10024,
    division_type: "xã",
    codename: "xa_tien_phuong",
    district_code: 277,
  },
  {
    name: "Xã Đông Sơn",
    code: 10027,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 277,
  },
  {
    name: "Xã Đông Phương Yên",
    code: 10030,
    division_type: "xã",
    codename: "xa_dong_phuong_yen",
    district_code: 277,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 10033,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 277,
  },
  {
    name: "Xã Trường Yên",
    code: 10039,
    division_type: "xã",
    codename: "xa_truong_yen",
    district_code: 277,
  },
  {
    name: "Xã Ngọc Hòa",
    code: 10042,
    division_type: "xã",
    codename: "xa_ngoc_hoa",
    district_code: 277,
  },
  {
    name: "Xã Thủy Xuân Tiên",
    code: 10045,
    division_type: "xã",
    codename: "xa_thuy_xuan_tien",
    district_code: 277,
  },
  {
    name: "Xã Thanh Bình",
    code: 10048,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 277,
  },
  {
    name: "Xã Trung Hòa",
    code: 10051,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 277,
  },
  {
    name: "Xã Đại Yên",
    code: 10054,
    division_type: "xã",
    codename: "xa_dai_yen",
    district_code: 277,
  },
  {
    name: "Xã Thụy Hương",
    code: 10057,
    division_type: "xã",
    codename: "xa_thuy_huong",
    district_code: 277,
  },
  {
    name: "Xã Tốt Động",
    code: 10060,
    division_type: "xã",
    codename: "xa_tot_dong",
    district_code: 277,
  },
  {
    name: "Xã Lam Điền",
    code: 10063,
    division_type: "xã",
    codename: "xa_lam_dien",
    district_code: 277,
  },
  {
    name: "Xã Tân Tiến",
    code: 10066,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 277,
  },
  {
    name: "Xã Nam Phương Tiến",
    code: 10069,
    division_type: "xã",
    codename: "xa_nam_phuong_tien",
    district_code: 277,
  },
  {
    name: "Xã Hợp Đồng",
    code: 10072,
    division_type: "xã",
    codename: "xa_hop_dong",
    district_code: 277,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 10075,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 277,
  },
  {
    name: "Xã Hoàng Diệu",
    code: 10078,
    division_type: "xã",
    codename: "xa_hoang_dieu",
    district_code: 277,
  },
  {
    name: "Xã Hữu Văn",
    code: 10081,
    division_type: "xã",
    codename: "xa_huu_van",
    district_code: 277,
  },
  {
    name: "Xã Quảng Bị",
    code: 10084,
    division_type: "xã",
    codename: "xa_quang_bi",
    district_code: 277,
  },
  {
    name: "Xã Mỹ Lương",
    code: 10087,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 277,
  },
  {
    name: "Xã Thượng Vực",
    code: 10090,
    division_type: "xã",
    codename: "xa_thuong_vuc",
    district_code: 277,
  },
  {
    name: "Xã Hồng Phong",
    code: 10093,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 277,
  },
  {
    name: "Xã Đồng Phú",
    code: 10096,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 277,
  },
  {
    name: "Xã Trần Phú",
    code: 10099,
    division_type: "xã",
    codename: "xa_tran_phu",
    district_code: 277,
  },
  {
    name: "Xã Văn Võ",
    code: 10102,
    division_type: "xã",
    codename: "xa_van_vo",
    district_code: 277,
  },
  {
    name: "Xã Đồng Lạc",
    code: 10105,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 277,
  },
  {
    name: "Xã Hòa Chính",
    code: 10108,
    division_type: "xã",
    codename: "xa_hoa_chinh",
    district_code: 277,
  },
  {
    name: "Xã Phú Nam An",
    code: 10111,
    division_type: "xã",
    codename: "xa_phu_nam_an",
    district_code: 277,
  },
  {
    name: "Thị trấn Kim Bài",
    code: 10114,
    division_type: "thị trấn",
    codename: "thi_tran_kim_bai",
    district_code: 278,
  },
  {
    name: "Xã Cự Khê",
    code: 10120,
    division_type: "xã",
    codename: "xa_cu_khe",
    district_code: 278,
  },
  {
    name: "Xã Bích Hòa",
    code: 10126,
    division_type: "xã",
    codename: "xa_bich_hoa",
    district_code: 278,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 10129,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 278,
  },
  {
    name: "Xã Cao Viên",
    code: 10132,
    division_type: "xã",
    codename: "xa_cao_vien",
    district_code: 278,
  },
  {
    name: "Xã Bình Minh",
    code: 10135,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 278,
  },
  {
    name: "Xã Tam Hưng",
    code: 10138,
    division_type: "xã",
    codename: "xa_tam_hung",
    district_code: 278,
  },
  {
    name: "Xã Thanh Cao",
    code: 10141,
    division_type: "xã",
    codename: "xa_thanh_cao",
    district_code: 278,
  },
  {
    name: "Xã Thanh Thùy",
    code: 10144,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 278,
  },
  {
    name: "Xã Thanh Mai",
    code: 10147,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 278,
  },
  {
    name: "Xã Thanh Văn",
    code: 10150,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 278,
  },
  {
    name: "Xã Đỗ Động",
    code: 10153,
    division_type: "xã",
    codename: "xa_do_dong",
    district_code: 278,
  },
  {
    name: "Xã Kim An",
    code: 10156,
    division_type: "xã",
    codename: "xa_kim_an",
    district_code: 278,
  },
  {
    name: "Xã Kim Thư",
    code: 10159,
    division_type: "xã",
    codename: "xa_kim_thu",
    district_code: 278,
  },
  {
    name: "Xã Phương Trung",
    code: 10162,
    division_type: "xã",
    codename: "xa_phuong_trung",
    district_code: 278,
  },
  {
    name: "Xã Tân Ước",
    code: 10165,
    division_type: "xã",
    codename: "xa_tan_uoc",
    district_code: 278,
  },
  {
    name: "Xã Dân Hòa",
    code: 10168,
    division_type: "xã",
    codename: "xa_dan_hoa",
    district_code: 278,
  },
  {
    name: "Xã Liên Châu",
    code: 10171,
    division_type: "xã",
    codename: "xa_lien_chau",
    district_code: 278,
  },
  {
    name: "Xã Cao Dương",
    code: 10174,
    division_type: "xã",
    codename: "xa_cao_duong",
    district_code: 278,
  },
  {
    name: "Xã Xuân Dương",
    code: 10177,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 278,
  },
  {
    name: "Xã Hồng Dương",
    code: 10180,
    division_type: "xã",
    codename: "xa_hong_duong",
    district_code: 278,
  },
  {
    name: "Thị trấn Thường Tín",
    code: 10183,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_tin",
    district_code: 279,
  },
  {
    name: "Xã Ninh Sở",
    code: 10186,
    division_type: "xã",
    codename: "xa_ninh_so",
    district_code: 279,
  },
  {
    name: "Xã Nhị Khê",
    code: 10189,
    division_type: "xã",
    codename: "xa_nhi_khe",
    district_code: 279,
  },
  {
    name: "Xã Duyên Thái",
    code: 10192,
    division_type: "xã",
    codename: "xa_duyen_thai",
    district_code: 279,
  },
  {
    name: "Xã Khánh Hà",
    code: 10195,
    division_type: "xã",
    codename: "xa_khanh_ha",
    district_code: 279,
  },
  {
    name: "Xã Hòa Bình",
    code: 10198,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 279,
  },
  {
    name: "Xã Văn Bình",
    code: 10201,
    division_type: "xã",
    codename: "xa_van_binh",
    district_code: 279,
  },
  {
    name: "Xã Hiền Giang",
    code: 10204,
    division_type: "xã",
    codename: "xa_hien_giang",
    district_code: 279,
  },
  {
    name: "Xã Hồng Vân",
    code: 10207,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 279,
  },
  {
    name: "Xã Vân Tảo",
    code: 10210,
    division_type: "xã",
    codename: "xa_van_tao",
    district_code: 279,
  },
  {
    name: "Xã Liên Phương",
    code: 10213,
    division_type: "xã",
    codename: "xa_lien_phuong",
    district_code: 279,
  },
  {
    name: "Xã Văn Phú",
    code: 10216,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 279,
  },
  {
    name: "Xã Tự Nhiên",
    code: 10219,
    division_type: "xã",
    codename: "xa_tu_nhien",
    district_code: 279,
  },
  {
    name: "Xã Tiền Phong",
    code: 10222,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 279,
  },
  {
    name: "Xã Hà Hồi",
    code: 10225,
    division_type: "xã",
    codename: "xa_ha_hoi",
    district_code: 279,
  },
  {
    name: "Xã Thư Phú",
    code: 10228,
    division_type: "xã",
    codename: "xa_thu_phu",
    district_code: 279,
  },
  {
    name: "Xã Nguyễn Trãi",
    code: 10231,
    division_type: "xã",
    codename: "xa_nguyen_trai",
    district_code: 279,
  },
  {
    name: "Xã Quất Động",
    code: 10234,
    division_type: "xã",
    codename: "xa_quat_dong",
    district_code: 279,
  },
  {
    name: "Xã Chương Dương",
    code: 10237,
    division_type: "xã",
    codename: "xa_chuong_duong",
    district_code: 279,
  },
  {
    name: "Xã Tân Minh",
    code: 10240,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 279,
  },
  {
    name: "Xã Lê Lợi",
    code: 10243,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 279,
  },
  {
    name: "Xã Thắng Lợi",
    code: 10246,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 279,
  },
  {
    name: "Xã Dũng Tiến",
    code: 10249,
    division_type: "xã",
    codename: "xa_dung_tien",
    district_code: 279,
  },
  {
    name: "Xã Thống Nhất",
    code: 10252,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 279,
  },
  {
    name: "Xã Nghiêm Xuyên",
    code: 10255,
    division_type: "xã",
    codename: "xa_nghiem_xuyen",
    district_code: 279,
  },
  {
    name: "Xã Tô Hiệu",
    code: 10258,
    division_type: "xã",
    codename: "xa_to_hieu",
    district_code: 279,
  },
  {
    name: "Xã Văn Tự",
    code: 10261,
    division_type: "xã",
    codename: "xa_van_tu",
    district_code: 279,
  },
  {
    name: "Xã Vạn Điểm",
    code: 10264,
    division_type: "xã",
    codename: "xa_van_diem",
    district_code: 279,
  },
  {
    name: "Xã Minh Cường",
    code: 10267,
    division_type: "xã",
    codename: "xa_minh_cuong",
    district_code: 279,
  },
  {
    name: "Thị trấn Phú Minh",
    code: 10270,
    division_type: "thị trấn",
    codename: "thi_tran_phu_minh",
    district_code: 280,
  },
  {
    name: "Thị trấn Phú Xuyên",
    code: 10273,
    division_type: "thị trấn",
    codename: "thi_tran_phu_xuyen",
    district_code: 280,
  },
  {
    name: "Xã Hồng Minh",
    code: 10276,
    division_type: "xã",
    codename: "xa_hong_minh",
    district_code: 280,
  },
  {
    name: "Xã Phượng Dực",
    code: 10279,
    division_type: "xã",
    codename: "xa_phuong_duc",
    district_code: 280,
  },
  {
    name: "Xã Nam Tiến",
    code: 10282,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 280,
  },
  {
    name: "Xã Tri Trung",
    code: 10288,
    division_type: "xã",
    codename: "xa_tri_trung",
    district_code: 280,
  },
  {
    name: "Xã Đại Thắng",
    code: 10291,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 280,
  },
  {
    name: "Xã Phú Túc",
    code: 10294,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 280,
  },
  {
    name: "Xã Văn Hoàng",
    code: 10297,
    division_type: "xã",
    codename: "xa_van_hoang",
    district_code: 280,
  },
  {
    name: "Xã Hồng Thái",
    code: 10300,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 280,
  },
  {
    name: "Xã Hoàng Long",
    code: 10303,
    division_type: "xã",
    codename: "xa_hoang_long",
    district_code: 280,
  },
  {
    name: "Xã Quang Trung",
    code: 10306,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 280,
  },
  {
    name: "Xã Nam Phong",
    code: 10309,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 280,
  },
  {
    name: "Xã Nam Triều",
    code: 10312,
    division_type: "xã",
    codename: "xa_nam_trieu",
    district_code: 280,
  },
  {
    name: "Xã Tân Dân",
    code: 10315,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 280,
  },
  {
    name: "Xã Sơn Hà",
    code: 10318,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 280,
  },
  {
    name: "Xã Chuyên Mỹ",
    code: 10321,
    division_type: "xã",
    codename: "xa_chuyen_my",
    district_code: 280,
  },
  {
    name: "Xã Khai Thái",
    code: 10324,
    division_type: "xã",
    codename: "xa_khai_thai",
    district_code: 280,
  },
  {
    name: "Xã Phúc Tiến",
    code: 10327,
    division_type: "xã",
    codename: "xa_phuc_tien",
    district_code: 280,
  },
  {
    name: "Xã Vân Từ",
    code: 10330,
    division_type: "xã",
    codename: "xa_van_tu",
    district_code: 280,
  },
  {
    name: "Xã Tri Thủy",
    code: 10333,
    division_type: "xã",
    codename: "xa_tri_thuy",
    district_code: 280,
  },
  {
    name: "Xã Đại Xuyên",
    code: 10336,
    division_type: "xã",
    codename: "xa_dai_xuyen",
    district_code: 280,
  },
  {
    name: "Xã Phú Yên",
    code: 10339,
    division_type: "xã",
    codename: "xa_phu_yen",
    district_code: 280,
  },
  {
    name: "Xã Bạch Hạ",
    code: 10342,
    division_type: "xã",
    codename: "xa_bach_ha",
    district_code: 280,
  },
  {
    name: "Xã Quang Lãng",
    code: 10345,
    division_type: "xã",
    codename: "xa_quang_lang",
    district_code: 280,
  },
  {
    name: "Xã Châu Can",
    code: 10348,
    division_type: "xã",
    codename: "xa_chau_can",
    district_code: 280,
  },
  {
    name: "Xã Minh Tân",
    code: 10351,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 280,
  },
  {
    name: "Thị trấn Vân Đình",
    code: 10354,
    division_type: "thị trấn",
    codename: "thi_tran_van_dinh",
    district_code: 281,
  },
  {
    name: "Xã Viên An",
    code: 10357,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 281,
  },
  {
    name: "Xã Viên Nội",
    code: 10360,
    division_type: "xã",
    codename: "xa_vien_noi",
    district_code: 281,
  },
  {
    name: "Xã Hoa Sơn",
    code: 10363,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 281,
  },
  {
    name: "Xã Quảng Phú Cầu",
    code: 10366,
    division_type: "xã",
    codename: "xa_quang_phu_cau",
    district_code: 281,
  },
  {
    name: "Xã Trường Thịnh",
    code: 10369,
    division_type: "xã",
    codename: "xa_truong_thinh",
    district_code: 281,
  },
  {
    name: "Xã Cao Thành",
    code: 10372,
    division_type: "xã",
    codename: "xa_cao_thanh",
    district_code: 281,
  },
  {
    name: "Xã Liên Bạt",
    code: 10375,
    division_type: "xã",
    codename: "xa_lien_bat",
    district_code: 281,
  },
  {
    name: "Xã Sơn Công",
    code: 10378,
    division_type: "xã",
    codename: "xa_son_cong",
    district_code: 281,
  },
  {
    name: "Xã Đồng Tiến",
    code: 10381,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 281,
  },
  {
    name: "Xã Phương Tú",
    code: 10384,
    division_type: "xã",
    codename: "xa_phuong_tu",
    district_code: 281,
  },
  {
    name: "Xã Trung Tú",
    code: 10387,
    division_type: "xã",
    codename: "xa_trung_tu",
    district_code: 281,
  },
  {
    name: "Xã Đồng Tân",
    code: 10390,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 281,
  },
  {
    name: "Xã Tảo Dương Văn",
    code: 10393,
    division_type: "xã",
    codename: "xa_tao_duong_van",
    district_code: 281,
  },
  {
    name: "Xã Vạn Thái",
    code: 10396,
    division_type: "xã",
    codename: "xa_van_thai",
    district_code: 281,
  },
  {
    name: "Xã Minh Đức",
    code: 10399,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 281,
  },
  {
    name: "Xã Hòa Lâm",
    code: 10402,
    division_type: "xã",
    codename: "xa_hoa_lam",
    district_code: 281,
  },
  {
    name: "Xã Hòa Xá",
    code: 10405,
    division_type: "xã",
    codename: "xa_hoa_xa",
    district_code: 281,
  },
  {
    name: "Xã Trầm Lộng",
    code: 10408,
    division_type: "xã",
    codename: "xa_tram_long",
    district_code: 281,
  },
  {
    name: "Xã Kim Đường",
    code: 10411,
    division_type: "xã",
    codename: "xa_kim_duong",
    district_code: 281,
  },
  {
    name: "Xã Hòa Nam",
    code: 10414,
    division_type: "xã",
    codename: "xa_hoa_nam",
    district_code: 281,
  },
  {
    name: "Xã Hòa Phú",
    code: 10417,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 281,
  },
  {
    name: "Xã Đội Bình",
    code: 10420,
    division_type: "xã",
    codename: "xa_doi_binh",
    district_code: 281,
  },
  {
    name: "Xã Đại Hùng",
    code: 10423,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 281,
  },
  {
    name: "Xã Đông Lỗ",
    code: 10426,
    division_type: "xã",
    codename: "xa_dong_lo",
    district_code: 281,
  },
  {
    name: "Xã Phù Lưu",
    code: 10429,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 281,
  },
  {
    name: "Xã Đại Cường",
    code: 10432,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 281,
  },
  {
    name: "Xã Lưu Hoàng",
    code: 10435,
    division_type: "xã",
    codename: "xa_luu_hoang",
    district_code: 281,
  },
  {
    name: "Xã Hồng Quang",
    code: 10438,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 281,
  },
  {
    name: "Thị trấn Đại Nghĩa",
    code: 10441,
    division_type: "thị trấn",
    codename: "thi_tran_dai_nghia",
    district_code: 282,
  },
  {
    name: "Xã Đồng Tâm",
    code: 10444,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 282,
  },
  {
    name: "Xã Thượng Lâm",
    code: 10447,
    division_type: "xã",
    codename: "xa_thuong_lam",
    district_code: 282,
  },
  {
    name: "Xã Tuy Lai",
    code: 10450,
    division_type: "xã",
    codename: "xa_tuy_lai",
    district_code: 282,
  },
  {
    name: "Xã Phúc Lâm",
    code: 10453,
    division_type: "xã",
    codename: "xa_phuc_lam",
    district_code: 282,
  },
  {
    name: "Xã Mỹ Thành",
    code: 10456,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 282,
  },
  {
    name: "Xã Bột Xuyên",
    code: 10459,
    division_type: "xã",
    codename: "xa_bot_xuyen",
    district_code: 282,
  },
  {
    name: "Xã An Mỹ",
    code: 10462,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 282,
  },
  {
    name: "Xã Hồng Sơn",
    code: 10465,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 282,
  },
  {
    name: "Xã Lê Thanh",
    code: 10468,
    division_type: "xã",
    codename: "xa_le_thanh",
    district_code: 282,
  },
  {
    name: "Xã Xuy Xá",
    code: 10471,
    division_type: "xã",
    codename: "xa_xuy_xa",
    district_code: 282,
  },
  {
    name: "Xã Phùng Xá",
    code: 10474,
    division_type: "xã",
    codename: "xa_phung_xa",
    district_code: 282,
  },
  {
    name: "Xã Phù Lưu Tế",
    code: 10477,
    division_type: "xã",
    codename: "xa_phu_luu_te",
    district_code: 282,
  },
  {
    name: "Xã Đại Hưng",
    code: 10480,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 282,
  },
  {
    name: "Xã Vạn Kim",
    code: 10483,
    division_type: "xã",
    codename: "xa_van_kim",
    district_code: 282,
  },
  {
    name: "Xã Đốc Tín",
    code: 10486,
    division_type: "xã",
    codename: "xa_doc_tin",
    district_code: 282,
  },
  {
    name: "Xã Hương Sơn",
    code: 10489,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 282,
  },
  {
    name: "Xã Hùng Tiến",
    code: 10492,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 282,
  },
  {
    name: "Xã An Tiến",
    code: 10495,
    division_type: "xã",
    codename: "xa_an_tien",
    district_code: 282,
  },
  {
    name: "Xã Hợp Tiến",
    code: 10498,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 282,
  },
  {
    name: "Xã Hợp Thanh",
    code: 10501,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 282,
  },
  {
    name: "Xã An Phú",
    code: 10504,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 282,
  },
  {
    name: "Phường Quang Trung",
    code: 688,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 24,
  },
  {
    name: "Phường Trần Phú",
    code: 691,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 24,
  },
  {
    name: "Phường Ngọc Hà",
    code: 692,
    division_type: "phường",
    codename: "phuong_ngoc_ha",
    district_code: 24,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 694,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 24,
  },
  {
    name: "Phường Minh Khai",
    code: 697,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 24,
  },
  {
    name: "Xã Ngọc Đường",
    code: 700,
    division_type: "xã",
    codename: "xa_ngoc_duong",
    district_code: 24,
  },
  {
    name: "Xã Phương Độ",
    code: 946,
    division_type: "xã",
    codename: "xa_phuong_do",
    district_code: 24,
  },
  {
    name: "Xã Phương Thiện",
    code: 949,
    division_type: "xã",
    codename: "xa_phuong_thien",
    district_code: 24,
  },
  {
    name: "Thị trấn Phó Bảng",
    code: 712,
    division_type: "thị trấn",
    codename: "thi_tran_pho_bang",
    district_code: 26,
  },
  {
    name: "Xã Lũng Cú",
    code: 715,
    division_type: "xã",
    codename: "xa_lung_cu",
    district_code: 26,
  },
  {
    name: "Xã Má Lé",
    code: 718,
    division_type: "xã",
    codename: "xa_ma_le",
    district_code: 26,
  },
  {
    name: "Thị trấn Đồng Văn",
    code: 721,
    division_type: "thị trấn",
    codename: "thi_tran_dong_van",
    district_code: 26,
  },
  {
    name: "Xã Lũng Táo",
    code: 724,
    division_type: "xã",
    codename: "xa_lung_tao",
    district_code: 26,
  },
  {
    name: "Xã Phố Là",
    code: 727,
    division_type: "xã",
    codename: "xa_pho_la",
    district_code: 26,
  },
  {
    name: "Xã Thài Phìn Tủng",
    code: 730,
    division_type: "xã",
    codename: "xa_thai_phin_tung",
    district_code: 26,
  },
  {
    name: "Xã Sủng Là",
    code: 733,
    division_type: "xã",
    codename: "xa_sung_la",
    district_code: 26,
  },
  {
    name: "Xã Xà Phìn",
    code: 736,
    division_type: "xã",
    codename: "xa_xa_phin",
    district_code: 26,
  },
  {
    name: "Xã Tả Phìn",
    code: 739,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 26,
  },
  {
    name: "Xã Tả Lủng",
    code: 742,
    division_type: "xã",
    codename: "xa_ta_lung",
    district_code: 26,
  },
  {
    name: "Xã Phố Cáo",
    code: 745,
    division_type: "xã",
    codename: "xa_pho_cao",
    district_code: 26,
  },
  {
    name: "Xã Sính Lủng",
    code: 748,
    division_type: "xã",
    codename: "xa_sinh_lung",
    district_code: 26,
  },
  {
    name: "Xã Sảng Tủng",
    code: 751,
    division_type: "xã",
    codename: "xa_sang_tung",
    district_code: 26,
  },
  {
    name: "Xã Lũng Thầu",
    code: 754,
    division_type: "xã",
    codename: "xa_lung_thau",
    district_code: 26,
  },
  {
    name: "Xã Hố Quáng Phìn",
    code: 757,
    division_type: "xã",
    codename: "xa_ho_quang_phin",
    district_code: 26,
  },
  {
    name: "Xã Vần Chải",
    code: 760,
    division_type: "xã",
    codename: "xa_van_chai",
    district_code: 26,
  },
  {
    name: "Xã Lũng Phìn",
    code: 763,
    division_type: "xã",
    codename: "xa_lung_phin",
    district_code: 26,
  },
  {
    name: "Xã Sủng Trái",
    code: 766,
    division_type: "xã",
    codename: "xa_sung_trai",
    district_code: 26,
  },
  {
    name: "Thị trấn Mèo Vạc",
    code: 769,
    division_type: "thị trấn",
    codename: "thi_tran_meo_vac",
    district_code: 27,
  },
  {
    name: "Xã Thượng Phùng",
    code: 772,
    division_type: "xã",
    codename: "xa_thuong_phung",
    district_code: 27,
  },
  {
    name: "Xã Pải Lủng",
    code: 775,
    division_type: "xã",
    codename: "xa_pai_lung",
    district_code: 27,
  },
  {
    name: "Xã Xín Cái",
    code: 778,
    division_type: "xã",
    codename: "xa_xin_cai",
    district_code: 27,
  },
  {
    name: "Xã Pả Vi",
    code: 781,
    division_type: "xã",
    codename: "xa_pa_vi",
    district_code: 27,
  },
  {
    name: "Xã Giàng Chu Phìn",
    code: 784,
    division_type: "xã",
    codename: "xa_giang_chu_phin",
    district_code: 27,
  },
  {
    name: "Xã Sủng Trà",
    code: 787,
    division_type: "xã",
    codename: "xa_sung_tra",
    district_code: 27,
  },
  {
    name: "Xã Sủng Máng",
    code: 790,
    division_type: "xã",
    codename: "xa_sung_mang",
    district_code: 27,
  },
  {
    name: "Xã Sơn Vĩ",
    code: 793,
    division_type: "xã",
    codename: "xa_son_vi",
    district_code: 27,
  },
  {
    name: "Xã Tả Lủng",
    code: 796,
    division_type: "xã",
    codename: "xa_ta_lung",
    district_code: 27,
  },
  {
    name: "Xã Cán Chu Phìn",
    code: 799,
    division_type: "xã",
    codename: "xa_can_chu_phin",
    district_code: 27,
  },
  {
    name: "Xã Lũng Pù",
    code: 802,
    division_type: "xã",
    codename: "xa_lung_pu",
    district_code: 27,
  },
  {
    name: "Xã Lũng Chinh",
    code: 805,
    division_type: "xã",
    codename: "xa_lung_chinh",
    district_code: 27,
  },
  {
    name: "Xã Tát Ngà",
    code: 808,
    division_type: "xã",
    codename: "xa_tat_nga",
    district_code: 27,
  },
  {
    name: "Xã Nậm Ban",
    code: 811,
    division_type: "xã",
    codename: "xa_nam_ban",
    district_code: 27,
  },
  {
    name: "Xã Khâu Vai",
    code: 814,
    division_type: "xã",
    codename: "xa_khau_vai",
    district_code: 27,
  },
  {
    name: "Xã Niêm Tòng",
    code: 815,
    division_type: "xã",
    codename: "xa_niem_tong",
    district_code: 27,
  },
  {
    name: "Xã Niêm Sơn",
    code: 817,
    division_type: "xã",
    codename: "xa_niem_son",
    district_code: 27,
  },
  {
    name: "Thị trấn Yên Minh",
    code: 820,
    division_type: "thị trấn",
    codename: "thi_tran_yen_minh",
    district_code: 28,
  },
  {
    name: "Xã Thắng Mố",
    code: 823,
    division_type: "xã",
    codename: "xa_thang_mo",
    district_code: 28,
  },
  {
    name: "Xã Phú Lũng",
    code: 826,
    division_type: "xã",
    codename: "xa_phu_lung",
    district_code: 28,
  },
  {
    name: "Xã Sủng Tráng",
    code: 829,
    division_type: "xã",
    codename: "xa_sung_trang",
    district_code: 28,
  },
  {
    name: "Xã Bạch Đích",
    code: 832,
    division_type: "xã",
    codename: "xa_bach_dich",
    district_code: 28,
  },
  {
    name: "Xã Na Khê",
    code: 835,
    division_type: "xã",
    codename: "xa_na_khe",
    district_code: 28,
  },
  {
    name: "Xã Sủng Thài",
    code: 838,
    division_type: "xã",
    codename: "xa_sung_thai",
    district_code: 28,
  },
  {
    name: "Xã Hữu Vinh",
    code: 841,
    division_type: "xã",
    codename: "xa_huu_vinh",
    district_code: 28,
  },
  {
    name: "Xã Lao Và Chải",
    code: 844,
    division_type: "xã",
    codename: "xa_lao_va_chai",
    district_code: 28,
  },
  {
    name: "Xã Mậu Duệ",
    code: 847,
    division_type: "xã",
    codename: "xa_mau_due",
    district_code: 28,
  },
  {
    name: "Xã Đông Minh",
    code: 850,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 28,
  },
  {
    name: "Xã Mậu Long",
    code: 853,
    division_type: "xã",
    codename: "xa_mau_long",
    district_code: 28,
  },
  {
    name: "Xã Ngam La",
    code: 856,
    division_type: "xã",
    codename: "xa_ngam_la",
    district_code: 28,
  },
  {
    name: "Xã Ngọc Long",
    code: 859,
    division_type: "xã",
    codename: "xa_ngoc_long",
    district_code: 28,
  },
  {
    name: "Xã Đường Thượng",
    code: 862,
    division_type: "xã",
    codename: "xa_duong_thuong",
    district_code: 28,
  },
  {
    name: "Xã Lũng Hồ",
    code: 865,
    division_type: "xã",
    codename: "xa_lung_ho",
    district_code: 28,
  },
  {
    name: "Xã Du Tiến",
    code: 868,
    division_type: "xã",
    codename: "xa_du_tien",
    district_code: 28,
  },
  {
    name: "Xã Du Già",
    code: 871,
    division_type: "xã",
    codename: "xa_du_gia",
    district_code: 28,
  },
  {
    name: "Thị trấn Tam Sơn",
    code: 874,
    division_type: "thị trấn",
    codename: "thi_tran_tam_son",
    district_code: 29,
  },
  {
    name: "Xã Bát Đại Sơn",
    code: 877,
    division_type: "xã",
    codename: "xa_bat_dai_son",
    district_code: 29,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 880,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 29,
  },
  {
    name: "Xã Cán Tỷ",
    code: 883,
    division_type: "xã",
    codename: "xa_can_ty",
    district_code: 29,
  },
  {
    name: "Xã Cao Mã Pờ",
    code: 886,
    division_type: "xã",
    codename: "xa_cao_ma_po",
    district_code: 29,
  },
  {
    name: "Xã Thanh Vân",
    code: 889,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 29,
  },
  {
    name: "Xã Tùng Vài",
    code: 892,
    division_type: "xã",
    codename: "xa_tung_vai",
    district_code: 29,
  },
  {
    name: "Xã Đông Hà",
    code: 895,
    division_type: "xã",
    codename: "xa_dong_ha",
    district_code: 29,
  },
  {
    name: "Xã Quản Bạ",
    code: 898,
    division_type: "xã",
    codename: "xa_quan_ba",
    district_code: 29,
  },
  {
    name: "Xã Lùng Tám",
    code: 901,
    division_type: "xã",
    codename: "xa_lung_tam",
    district_code: 29,
  },
  {
    name: "Xã Quyết Tiến",
    code: 904,
    division_type: "xã",
    codename: "xa_quyet_tien",
    district_code: 29,
  },
  {
    name: "Xã Tả Ván",
    code: 907,
    division_type: "xã",
    codename: "xa_ta_van",
    district_code: 29,
  },
  {
    name: "Xã Thái An",
    code: 910,
    division_type: "xã",
    codename: "xa_thai_an",
    district_code: 29,
  },
  {
    name: "Xã Kim Thạch",
    code: 703,
    division_type: "xã",
    codename: "xa_kim_thach",
    district_code: 30,
  },
  {
    name: "Xã Phú Linh",
    code: 706,
    division_type: "xã",
    codename: "xa_phu_linh",
    district_code: 30,
  },
  {
    name: "Xã Kim Linh",
    code: 709,
    division_type: "xã",
    codename: "xa_kim_linh",
    district_code: 30,
  },
  {
    name: "Thị trấn Vị Xuyên",
    code: 913,
    division_type: "thị trấn",
    codename: "thi_tran_vi_xuyen",
    district_code: 30,
  },
  {
    name: "Thị trấn Nông Trường Việt Lâm",
    code: 916,
    division_type: "thị trấn",
    codename: "thi_tran_nong_truong_viet_lam",
    district_code: 30,
  },
  {
    name: "Xã Minh Tân",
    code: 919,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 30,
  },
  {
    name: "Xã Thuận Hoà",
    code: 922,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 30,
  },
  {
    name: "Xã Tùng Bá",
    code: 925,
    division_type: "xã",
    codename: "xa_tung_ba",
    district_code: 30,
  },
  {
    name: "Xã Thanh Thủy",
    code: 928,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 30,
  },
  {
    name: "Xã Thanh Đức",
    code: 931,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 30,
  },
  {
    name: "Xã Phong Quang",
    code: 934,
    division_type: "xã",
    codename: "xa_phong_quang",
    district_code: 30,
  },
  {
    name: "Xã Xín Chải",
    code: 937,
    division_type: "xã",
    codename: "xa_xin_chai",
    district_code: 30,
  },
  {
    name: "Xã Phương Tiến",
    code: 940,
    division_type: "xã",
    codename: "xa_phuong_tien",
    district_code: 30,
  },
  {
    name: "Xã Lao Chải",
    code: 943,
    division_type: "xã",
    codename: "xa_lao_chai",
    district_code: 30,
  },
  {
    name: "Xã Cao Bồ",
    code: 952,
    division_type: "xã",
    codename: "xa_cao_bo",
    district_code: 30,
  },
  {
    name: "Xã Đạo Đức",
    code: 955,
    division_type: "xã",
    codename: "xa_dao_duc",
    district_code: 30,
  },
  {
    name: "Xã Thượng Sơn",
    code: 958,
    division_type: "xã",
    codename: "xa_thuong_son",
    district_code: 30,
  },
  {
    name: "Xã Linh Hồ",
    code: 961,
    division_type: "xã",
    codename: "xa_linh_ho",
    district_code: 30,
  },
  {
    name: "Xã Quảng Ngần",
    code: 964,
    division_type: "xã",
    codename: "xa_quang_ngan",
    district_code: 30,
  },
  {
    name: "Xã Việt Lâm",
    code: 967,
    division_type: "xã",
    codename: "xa_viet_lam",
    district_code: 30,
  },
  {
    name: "Xã Ngọc Linh",
    code: 970,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 30,
  },
  {
    name: "Xã Ngọc Minh",
    code: 973,
    division_type: "xã",
    codename: "xa_ngoc_minh",
    district_code: 30,
  },
  {
    name: "Xã Bạch Ngọc",
    code: 976,
    division_type: "xã",
    codename: "xa_bach_ngoc",
    district_code: 30,
  },
  {
    name: "Xã Trung Thành",
    code: 979,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 30,
  },
  {
    name: "Xã Minh Sơn",
    code: 982,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 31,
  },
  {
    name: "Xã Giáp Trung",
    code: 985,
    division_type: "xã",
    codename: "xa_giap_trung",
    district_code: 31,
  },
  {
    name: "Xã Yên Định",
    code: 988,
    division_type: "xã",
    codename: "xa_yen_dinh",
    district_code: 31,
  },
  {
    name: "Thị trấn Yên Phú",
    code: 991,
    division_type: "thị trấn",
    codename: "thi_tran_yen_phu",
    district_code: 31,
  },
  {
    name: "Xã Minh Ngọc",
    code: 994,
    division_type: "xã",
    codename: "xa_minh_ngoc",
    district_code: 31,
  },
  {
    name: "Xã Yên Phong",
    code: 997,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 31,
  },
  {
    name: "Xã Lạc Nông",
    code: 1000,
    division_type: "xã",
    codename: "xa_lac_nong",
    district_code: 31,
  },
  {
    name: "Xã Phú Nam",
    code: 1003,
    division_type: "xã",
    codename: "xa_phu_nam",
    district_code: 31,
  },
  {
    name: "Xã Yên Cường",
    code: 1006,
    division_type: "xã",
    codename: "xa_yen_cuong",
    district_code: 31,
  },
  {
    name: "Xã Thượng Tân",
    code: 1009,
    division_type: "xã",
    codename: "xa_thuong_tan",
    district_code: 31,
  },
  {
    name: "Xã Đường Âm",
    code: 1012,
    division_type: "xã",
    codename: "xa_duong_am",
    district_code: 31,
  },
  {
    name: "Xã Đường Hồng",
    code: 1015,
    division_type: "xã",
    codename: "xa_duong_hong",
    district_code: 31,
  },
  {
    name: "Xã Phiêng Luông",
    code: 1018,
    division_type: "xã",
    codename: "xa_phieng_luong",
    district_code: 31,
  },
  {
    name: "Thị trấn Vinh Quang",
    code: 1021,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_quang",
    district_code: 32,
  },
  {
    name: "Xã Bản Máy",
    code: 1024,
    division_type: "xã",
    codename: "xa_ban_may",
    district_code: 32,
  },
  {
    name: "Xã Thàng Tín",
    code: 1027,
    division_type: "xã",
    codename: "xa_thang_tin",
    district_code: 32,
  },
  {
    name: "Xã Thèn Chu Phìn",
    code: 1030,
    division_type: "xã",
    codename: "xa_then_chu_phin",
    district_code: 32,
  },
  {
    name: "Xã Pố Lồ",
    code: 1033,
    division_type: "xã",
    codename: "xa_po_lo",
    district_code: 32,
  },
  {
    name: "Xã Bản Phùng",
    code: 1036,
    division_type: "xã",
    codename: "xa_ban_phung",
    district_code: 32,
  },
  {
    name: "Xã Túng Sán",
    code: 1039,
    division_type: "xã",
    codename: "xa_tung_san",
    district_code: 32,
  },
  {
    name: "Xã Chiến Phố",
    code: 1042,
    division_type: "xã",
    codename: "xa_chien_pho",
    district_code: 32,
  },
  {
    name: "Xã Đản Ván",
    code: 1045,
    division_type: "xã",
    codename: "xa_dan_van",
    district_code: 32,
  },
  {
    name: "Xã Tụ Nhân",
    code: 1048,
    division_type: "xã",
    codename: "xa_tu_nhan",
    district_code: 32,
  },
  {
    name: "Xã Tân Tiến",
    code: 1051,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 32,
  },
  {
    name: "Xã Nàng Đôn",
    code: 1054,
    division_type: "xã",
    codename: "xa_nang_don",
    district_code: 32,
  },
  {
    name: "Xã Pờ Ly Ngài",
    code: 1057,
    division_type: "xã",
    codename: "xa_po_ly_ngai",
    district_code: 32,
  },
  {
    name: "Xã Sán Xả Hồ",
    code: 1060,
    division_type: "xã",
    codename: "xa_san_xa_ho",
    district_code: 32,
  },
  {
    name: "Xã Bản Luốc",
    code: 1063,
    division_type: "xã",
    codename: "xa_ban_luoc",
    district_code: 32,
  },
  {
    name: "Xã Ngàm Đăng Vài",
    code: 1066,
    division_type: "xã",
    codename: "xa_ngam_dang_vai",
    district_code: 32,
  },
  {
    name: "Xã Bản Nhùng",
    code: 1069,
    division_type: "xã",
    codename: "xa_ban_nhung",
    district_code: 32,
  },
  {
    name: "Xã Tả Sử Choóng",
    code: 1072,
    division_type: "xã",
    codename: "xa_ta_su_choong",
    district_code: 32,
  },
  {
    name: "Xã Nậm Dịch",
    code: 1075,
    division_type: "xã",
    codename: "xa_nam_dich",
    district_code: 32,
  },
  {
    name: "Xã Hồ Thầu",
    code: 1081,
    division_type: "xã",
    codename: "xa_ho_thau",
    district_code: 32,
  },
  {
    name: "Xã Nam Sơn",
    code: 1084,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 32,
  },
  {
    name: "Xã Nậm Tỵ",
    code: 1087,
    division_type: "xã",
    codename: "xa_nam_ty",
    district_code: 32,
  },
  {
    name: "Xã Thông Nguyên",
    code: 1090,
    division_type: "xã",
    codename: "xa_thong_nguyen",
    district_code: 32,
  },
  {
    name: "Xã Nậm Khòa",
    code: 1093,
    division_type: "xã",
    codename: "xa_nam_khoa",
    district_code: 32,
  },
  {
    name: "Thị trấn Cốc Pài",
    code: 1096,
    division_type: "thị trấn",
    codename: "thi_tran_coc_pai",
    district_code: 33,
  },
  {
    name: "Xã Nàn Xỉn",
    code: 1099,
    division_type: "xã",
    codename: "xa_nan_xin",
    district_code: 33,
  },
  {
    name: "Xã Bản Díu",
    code: 1102,
    division_type: "xã",
    codename: "xa_ban_diu",
    district_code: 33,
  },
  {
    name: "Xã Chí Cà",
    code: 1105,
    division_type: "xã",
    codename: "xa_chi_ca",
    district_code: 33,
  },
  {
    name: "Xã Xín Mần",
    code: 1108,
    division_type: "xã",
    codename: "xa_xin_man",
    district_code: 33,
  },
  {
    name: "Xã Thèn Phàng",
    code: 1114,
    division_type: "xã",
    codename: "xa_then_phang",
    district_code: 33,
  },
  {
    name: "Xã Trung Thịnh",
    code: 1117,
    division_type: "xã",
    codename: "xa_trung_thinh",
    district_code: 33,
  },
  {
    name: "Xã Pà Vầy Sủ",
    code: 1120,
    division_type: "xã",
    codename: "xa_pa_vay_su",
    district_code: 33,
  },
  {
    name: "Xã Cốc Rế",
    code: 1123,
    division_type: "xã",
    codename: "xa_coc_re",
    district_code: 33,
  },
  {
    name: "Xã Thu Tà",
    code: 1126,
    division_type: "xã",
    codename: "xa_thu_ta",
    district_code: 33,
  },
  {
    name: "Xã Nàn Ma",
    code: 1129,
    division_type: "xã",
    codename: "xa_nan_ma",
    district_code: 33,
  },
  {
    name: "Xã Tả Nhìu",
    code: 1132,
    division_type: "xã",
    codename: "xa_ta_nhiu",
    district_code: 33,
  },
  {
    name: "Xã Bản Ngò",
    code: 1135,
    division_type: "xã",
    codename: "xa_ban_ngo",
    district_code: 33,
  },
  {
    name: "Xã Chế Là",
    code: 1138,
    division_type: "xã",
    codename: "xa_che_la",
    district_code: 33,
  },
  {
    name: "Xã Nấm Dẩn",
    code: 1141,
    division_type: "xã",
    codename: "xa_nam_dan",
    district_code: 33,
  },
  {
    name: "Xã Quảng Nguyên",
    code: 1144,
    division_type: "xã",
    codename: "xa_quang_nguyen",
    district_code: 33,
  },
  {
    name: "Xã Nà Chì",
    code: 1147,
    division_type: "xã",
    codename: "xa_na_chi",
    district_code: 33,
  },
  {
    name: "Xã Khuôn Lùng",
    code: 1150,
    division_type: "xã",
    codename: "xa_khuon_lung",
    district_code: 33,
  },
  {
    name: "Thị trấn Việt Quang",
    code: 1153,
    division_type: "thị trấn",
    codename: "thi_tran_viet_quang",
    district_code: 34,
  },
  {
    name: "Thị trấn Vĩnh Tuy",
    code: 1156,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tuy",
    district_code: 34,
  },
  {
    name: "Xã Tân Lập",
    code: 1159,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 34,
  },
  {
    name: "Xã Tân Thành",
    code: 1162,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 34,
  },
  {
    name: "Xã Đồng Tiến",
    code: 1165,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 34,
  },
  {
    name: "Xã Đồng Tâm",
    code: 1168,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 34,
  },
  {
    name: "Xã Tân Quang",
    code: 1171,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 34,
  },
  {
    name: "Xã Thượng Bình",
    code: 1174,
    division_type: "xã",
    codename: "xa_thuong_binh",
    district_code: 34,
  },
  {
    name: "Xã Hữu Sản",
    code: 1177,
    division_type: "xã",
    codename: "xa_huu_san",
    district_code: 34,
  },
  {
    name: "Xã Kim Ngọc",
    code: 1180,
    division_type: "xã",
    codename: "xa_kim_ngoc",
    district_code: 34,
  },
  {
    name: "Xã Việt Vinh",
    code: 1183,
    division_type: "xã",
    codename: "xa_viet_vinh",
    district_code: 34,
  },
  {
    name: "Xã Bằng Hành",
    code: 1186,
    division_type: "xã",
    codename: "xa_bang_hanh",
    district_code: 34,
  },
  {
    name: "Xã Quang Minh",
    code: 1189,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 34,
  },
  {
    name: "Xã Liên Hiệp",
    code: 1192,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 34,
  },
  {
    name: "Xã Vô Điếm",
    code: 1195,
    division_type: "xã",
    codename: "xa_vo_diem",
    district_code: 34,
  },
  {
    name: "Xã Việt Hồng",
    code: 1198,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 34,
  },
  {
    name: "Xã Hùng An",
    code: 1201,
    division_type: "xã",
    codename: "xa_hung_an",
    district_code: 34,
  },
  {
    name: "Xã Đức Xuân",
    code: 1204,
    division_type: "xã",
    codename: "xa_duc_xuan",
    district_code: 34,
  },
  {
    name: "Xã Tiên Kiều",
    code: 1207,
    division_type: "xã",
    codename: "xa_tien_kieu",
    district_code: 34,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 1210,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 34,
  },
  {
    name: "Xã Vĩnh Phúc",
    code: 1213,
    division_type: "xã",
    codename: "xa_vinh_phuc",
    district_code: 34,
  },
  {
    name: "Xã Đồng Yên",
    code: 1216,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 34,
  },
  {
    name: "Xã Đông Thành",
    code: 1219,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 34,
  },
  {
    name: "Xã Xuân Minh",
    code: 1222,
    division_type: "xã",
    codename: "xa_xuan_minh",
    district_code: 35,
  },
  {
    name: "Xã Tiên Nguyên",
    code: 1225,
    division_type: "xã",
    codename: "xa_tien_nguyen",
    district_code: 35,
  },
  {
    name: "Xã Tân Nam",
    code: 1228,
    division_type: "xã",
    codename: "xa_tan_nam",
    district_code: 35,
  },
  {
    name: "Xã Bản Rịa",
    code: 1231,
    division_type: "xã",
    codename: "xa_ban_ria",
    district_code: 35,
  },
  {
    name: "Xã Yên Thành",
    code: 1234,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 35,
  },
  {
    name: "Thị trấn Yên Bình",
    code: 1237,
    division_type: "thị trấn",
    codename: "thi_tran_yen_binh",
    district_code: 35,
  },
  {
    name: "Xã Tân Trịnh",
    code: 1240,
    division_type: "xã",
    codename: "xa_tan_trinh",
    district_code: 35,
  },
  {
    name: "Xã Tân Bắc",
    code: 1243,
    division_type: "xã",
    codename: "xa_tan_bac",
    district_code: 35,
  },
  {
    name: "Xã Bằng Lang",
    code: 1246,
    division_type: "xã",
    codename: "xa_bang_lang",
    district_code: 35,
  },
  {
    name: "Xã Yên Hà",
    code: 1249,
    division_type: "xã",
    codename: "xa_yen_ha",
    district_code: 35,
  },
  {
    name: "Xã Hương Sơn",
    code: 1252,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 35,
  },
  {
    name: "Xã Xuân Giang",
    code: 1255,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 35,
  },
  {
    name: "Xã Nà Khương",
    code: 1258,
    division_type: "xã",
    codename: "xa_na_khuong",
    district_code: 35,
  },
  {
    name: "Xã Tiên Yên",
    code: 1261,
    division_type: "xã",
    codename: "xa_tien_yen",
    district_code: 35,
  },
  {
    name: "Xã Vĩ Thượng",
    code: 1264,
    division_type: "xã",
    codename: "xa_vi_thuong",
    district_code: 35,
  },
  {
    name: "Phường Sông Hiến",
    code: 1267,
    division_type: "phường",
    codename: "phuong_song_hien",
    district_code: 40,
  },
  {
    name: "Phường Sông Bằng",
    code: 1270,
    division_type: "phường",
    codename: "phuong_song_bang",
    district_code: 40,
  },
  {
    name: "Phường Hợp Giang",
    code: 1273,
    division_type: "phường",
    codename: "phuong_hop_giang",
    district_code: 40,
  },
  {
    name: "Phường Tân Giang",
    code: 1276,
    division_type: "phường",
    codename: "phuong_tan_giang",
    district_code: 40,
  },
  {
    name: "Phường Ngọc Xuân",
    code: 1279,
    division_type: "phường",
    codename: "phuong_ngoc_xuan",
    district_code: 40,
  },
  {
    name: "Phường Đề Thám",
    code: 1282,
    division_type: "phường",
    codename: "phuong_de_tham",
    district_code: 40,
  },
  {
    name: "Phường Hoà Chung",
    code: 1285,
    division_type: "phường",
    codename: "phuong_hoa_chung",
    district_code: 40,
  },
  {
    name: "Phường Duyệt Trung",
    code: 1288,
    division_type: "phường",
    codename: "phuong_duyet_trung",
    district_code: 40,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 1693,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 40,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1705,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 40,
  },
  {
    name: "Xã Chu Trinh",
    code: 1720,
    division_type: "xã",
    codename: "xa_chu_trinh",
    district_code: 40,
  },
  {
    name: "Thị trấn Pác Miầu",
    code: 1290,
    division_type: "thị trấn",
    codename: "thi_tran_pac_miau",
    district_code: 42,
  },
  {
    name: "Xã Đức Hạnh",
    code: 1291,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 42,
  },
  {
    name: "Xã Lý Bôn",
    code: 1294,
    division_type: "xã",
    codename: "xa_ly_bon",
    district_code: 42,
  },
  {
    name: "Xã Nam Cao",
    code: 1296,
    division_type: "xã",
    codename: "xa_nam_cao",
    district_code: 42,
  },
  {
    name: "Xã Nam Quang",
    code: 1297,
    division_type: "xã",
    codename: "xa_nam_quang",
    district_code: 42,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 1300,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 42,
  },
  {
    name: "Xã Quảng Lâm",
    code: 1303,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 42,
  },
  {
    name: "Xã Thạch Lâm",
    code: 1304,
    division_type: "xã",
    codename: "xa_thach_lam",
    district_code: 42,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 1309,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 42,
  },
  {
    name: "Xã Mông Ân",
    code: 1312,
    division_type: "xã",
    codename: "xa_mong_an",
    district_code: 42,
  },
  {
    name: "Xã Thái Học",
    code: 1315,
    division_type: "xã",
    codename: "xa_thai_hoc",
    district_code: 42,
  },
  {
    name: "Xã Thái Sơn",
    code: 1316,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 42,
  },
  {
    name: "Xã Yên Thổ",
    code: 1318,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 42,
  },
  {
    name: "Thị trấn Bảo Lạc",
    code: 1321,
    division_type: "thị trấn",
    codename: "thi_tran_bao_lac",
    district_code: 43,
  },
  {
    name: "Xã Cốc Pàng",
    code: 1324,
    division_type: "xã",
    codename: "xa_coc_pang",
    district_code: 43,
  },
  {
    name: "Xã Thượng Hà",
    code: 1327,
    division_type: "xã",
    codename: "xa_thuong_ha",
    district_code: 43,
  },
  {
    name: "Xã Cô Ba",
    code: 1330,
    division_type: "xã",
    codename: "xa_co_ba",
    district_code: 43,
  },
  {
    name: "Xã Bảo Toàn",
    code: 1333,
    division_type: "xã",
    codename: "xa_bao_toan",
    district_code: 43,
  },
  {
    name: "Xã Khánh Xuân",
    code: 1336,
    division_type: "xã",
    codename: "xa_khanh_xuan",
    district_code: 43,
  },
  {
    name: "Xã Xuân Trường",
    code: 1339,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 43,
  },
  {
    name: "Xã Hồng Trị",
    code: 1342,
    division_type: "xã",
    codename: "xa_hong_tri",
    district_code: 43,
  },
  {
    name: "Xã Kim Cúc",
    code: 1343,
    division_type: "xã",
    codename: "xa_kim_cuc",
    district_code: 43,
  },
  {
    name: "Xã Phan Thanh",
    code: 1345,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 43,
  },
  {
    name: "Xã Hồng An",
    code: 1348,
    division_type: "xã",
    codename: "xa_hong_an",
    district_code: 43,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1351,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 43,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 1352,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 43,
  },
  {
    name: "Xã Huy Giáp",
    code: 1354,
    division_type: "xã",
    codename: "xa_huy_giap",
    district_code: 43,
  },
  {
    name: "Xã Đình Phùng",
    code: 1357,
    division_type: "xã",
    codename: "xa_dinh_phung",
    district_code: 43,
  },
  {
    name: "Xã Sơn Lập",
    code: 1359,
    division_type: "xã",
    codename: "xa_son_lap",
    district_code: 43,
  },
  {
    name: "Xã Sơn Lộ",
    code: 1360,
    division_type: "xã",
    codename: "xa_son_lo",
    district_code: 43,
  },
  {
    name: "Thị trấn Thông Nông",
    code: 1363,
    division_type: "thị trấn",
    codename: "thi_tran_thong_nong",
    district_code: 45,
  },
  {
    name: "Xã Cần Yên",
    code: 1366,
    division_type: "xã",
    codename: "xa_can_yen",
    district_code: 45,
  },
  {
    name: "Xã Cần Nông",
    code: 1367,
    division_type: "xã",
    codename: "xa_can_nong",
    district_code: 45,
  },
  {
    name: "Xã Lương Thông",
    code: 1372,
    division_type: "xã",
    codename: "xa_luong_thong",
    district_code: 45,
  },
  {
    name: "Xã Đa Thông",
    code: 1375,
    division_type: "xã",
    codename: "xa_da_thong",
    district_code: 45,
  },
  {
    name: "Xã Ngọc Động",
    code: 1378,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 45,
  },
  {
    name: "Xã Yên Sơn",
    code: 1381,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 45,
  },
  {
    name: "Xã Lương Can",
    code: 1384,
    division_type: "xã",
    codename: "xa_luong_can",
    district_code: 45,
  },
  {
    name: "Xã Thanh Long",
    code: 1387,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 45,
  },
  {
    name: "Thị trấn Xuân Hòa",
    code: 1392,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_hoa",
    district_code: 45,
  },
  {
    name: "Xã Lũng Nặm",
    code: 1393,
    division_type: "xã",
    codename: "xa_lung_nam",
    district_code: 45,
  },
  {
    name: "Xã Trường Hà",
    code: 1399,
    division_type: "xã",
    codename: "xa_truong_ha",
    district_code: 45,
  },
  {
    name: "Xã Cải Viên",
    code: 1402,
    division_type: "xã",
    codename: "xa_cai_vien",
    district_code: 45,
  },
  {
    name: "Xã Nội Thôn",
    code: 1411,
    division_type: "xã",
    codename: "xa_noi_thon",
    district_code: 45,
  },
  {
    name: "Xã Tổng Cọt",
    code: 1414,
    division_type: "xã",
    codename: "xa_tong_cot",
    district_code: 45,
  },
  {
    name: "Xã Sóc Hà",
    code: 1417,
    division_type: "xã",
    codename: "xa_soc_ha",
    district_code: 45,
  },
  {
    name: "Xã Thượng Thôn",
    code: 1420,
    division_type: "xã",
    codename: "xa_thuong_thon",
    district_code: 45,
  },
  {
    name: "Xã Hồng Sỹ",
    code: 1429,
    division_type: "xã",
    codename: "xa_hong_sy",
    district_code: 45,
  },
  {
    name: "Xã Quý Quân",
    code: 1432,
    division_type: "xã",
    codename: "xa_quy_quan",
    district_code: 45,
  },
  {
    name: "Xã Mã Ba",
    code: 1435,
    division_type: "xã",
    codename: "xa_ma_ba",
    district_code: 45,
  },
  {
    name: "Xã Ngọc Đào",
    code: 1438,
    division_type: "xã",
    codename: "xa_ngoc_dao",
    district_code: 45,
  },
  {
    name: "Thị trấn Trà Lĩnh",
    code: 1447,
    division_type: "thị trấn",
    codename: "thi_tran_tra_linh",
    district_code: 47,
  },
  {
    name: "Xã Tri Phương",
    code: 1453,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 47,
  },
  {
    name: "Xã Quang Hán",
    code: 1456,
    division_type: "xã",
    codename: "xa_quang_han",
    district_code: 47,
  },
  {
    name: "Xã Xuân Nội",
    code: 1462,
    division_type: "xã",
    codename: "xa_xuan_noi",
    district_code: 47,
  },
  {
    name: "Xã Quang Trung",
    code: 1465,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 47,
  },
  {
    name: "Xã Quang Vinh",
    code: 1468,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 47,
  },
  {
    name: "Xã Cao Chương",
    code: 1471,
    division_type: "xã",
    codename: "xa_cao_chuong",
    district_code: 47,
  },
  {
    name: "Thị trấn Trùng Khánh",
    code: 1477,
    division_type: "thị trấn",
    codename: "thi_tran_trung_khanh",
    district_code: 47,
  },
  {
    name: "Xã Ngọc Khê",
    code: 1480,
    division_type: "xã",
    codename: "xa_ngoc_khe",
    district_code: 47,
  },
  {
    name: "Xã Ngọc Côn",
    code: 1481,
    division_type: "xã",
    codename: "xa_ngoc_con",
    district_code: 47,
  },
  {
    name: "Xã Phong Nậm",
    code: 1483,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 47,
  },
  {
    name: "Xã Đình Phong",
    code: 1489,
    division_type: "xã",
    codename: "xa_dinh_phong",
    district_code: 47,
  },
  {
    name: "Xã Đàm Thuỷ",
    code: 1495,
    division_type: "xã",
    codename: "xa_dam_thuy",
    district_code: 47,
  },
  {
    name: "Xã Khâm Thành",
    code: 1498,
    division_type: "xã",
    codename: "xa_kham_thanh",
    district_code: 47,
  },
  {
    name: "Xã Chí Viễn",
    code: 1501,
    division_type: "xã",
    codename: "xa_chi_vien",
    district_code: 47,
  },
  {
    name: "Xã Lăng Hiếu",
    code: 1504,
    division_type: "xã",
    codename: "xa_lang_hieu",
    district_code: 47,
  },
  {
    name: "Xã Phong Châu",
    code: 1507,
    division_type: "xã",
    codename: "xa_phong_chau",
    district_code: 47,
  },
  {
    name: "Xã Trung Phúc",
    code: 1516,
    division_type: "xã",
    codename: "xa_trung_phuc",
    district_code: 47,
  },
  {
    name: "Xã Cao Thăng",
    code: 1519,
    division_type: "xã",
    codename: "xa_cao_thang",
    district_code: 47,
  },
  {
    name: "Xã Đức Hồng",
    code: 1522,
    division_type: "xã",
    codename: "xa_duc_hong",
    district_code: 47,
  },
  {
    name: "Xã Đoài Dương",
    code: 1525,
    division_type: "xã",
    codename: "xa_doai_duong",
    district_code: 47,
  },
  {
    name: "Xã Minh Long",
    code: 1534,
    division_type: "xã",
    codename: "xa_minh_long",
    district_code: 48,
  },
  {
    name: "Xã Lý Quốc",
    code: 1537,
    division_type: "xã",
    codename: "xa_ly_quoc",
    district_code: 48,
  },
  {
    name: "Xã Thắng Lợi",
    code: 1540,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 48,
  },
  {
    name: "Xã Đồng Loan",
    code: 1543,
    division_type: "xã",
    codename: "xa_dong_loan",
    district_code: 48,
  },
  {
    name: "Xã Đức Quang",
    code: 1546,
    division_type: "xã",
    codename: "xa_duc_quang",
    district_code: 48,
  },
  {
    name: "Xã Kim Loan",
    code: 1549,
    division_type: "xã",
    codename: "xa_kim_loan",
    district_code: 48,
  },
  {
    name: "Xã Quang Long",
    code: 1552,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 48,
  },
  {
    name: "Xã An Lạc",
    code: 1555,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 48,
  },
  {
    name: "Thị trấn Thanh Nhật",
    code: 1558,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_nhat",
    district_code: 48,
  },
  {
    name: "Xã Vinh Quý",
    code: 1561,
    division_type: "xã",
    codename: "xa_vinh_quy",
    district_code: 48,
  },
  {
    name: "Xã Thống Nhất",
    code: 1564,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 48,
  },
  {
    name: "Xã Cô Ngân",
    code: 1567,
    division_type: "xã",
    codename: "xa_co_ngan",
    district_code: 48,
  },
  {
    name: "Xã Thị Hoa",
    code: 1573,
    division_type: "xã",
    codename: "xa_thi_hoa",
    district_code: 48,
  },
  {
    name: "Xã Quốc Toản",
    code: 1474,
    division_type: "xã",
    codename: "xa_quoc_toan",
    district_code: 49,
  },
  {
    name: "Thị trấn Quảng Uyên",
    code: 1576,
    division_type: "thị trấn",
    codename: "thi_tran_quang_uyen",
    district_code: 49,
  },
  {
    name: "Xã Phi Hải",
    code: 1579,
    division_type: "xã",
    codename: "xa_phi_hai",
    district_code: 49,
  },
  {
    name: "Xã Quảng Hưng",
    code: 1582,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 49,
  },
  {
    name: "Xã Độc Lập",
    code: 1594,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 49,
  },
  {
    name: "Xã Cai Bộ",
    code: 1597,
    division_type: "xã",
    codename: "xa_cai_bo",
    district_code: 49,
  },
  {
    name: "Xã Phúc Sen",
    code: 1603,
    division_type: "xã",
    codename: "xa_phuc_sen",
    district_code: 49,
  },
  {
    name: "Xã Chí Thảo",
    code: 1606,
    division_type: "xã",
    codename: "xa_chi_thao",
    district_code: 49,
  },
  {
    name: "Xã Tự Do",
    code: 1609,
    division_type: "xã",
    codename: "xa_tu_do",
    district_code: 49,
  },
  {
    name: "Xã Hồng Quang",
    code: 1615,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 49,
  },
  {
    name: "Xã Ngọc Động",
    code: 1618,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 49,
  },
  {
    name: "Xã Hạnh Phúc",
    code: 1624,
    division_type: "xã",
    codename: "xa_hanh_phuc",
    district_code: 49,
  },
  {
    name: "Thị trấn Tà Lùng",
    code: 1627,
    division_type: "thị trấn",
    codename: "thi_tran_ta_lung",
    district_code: 49,
  },
  {
    name: "Xã Bế Văn Đàn",
    code: 1630,
    division_type: "xã",
    codename: "xa_be_van_dan",
    district_code: 49,
  },
  {
    name: "Xã Cách Linh",
    code: 1636,
    division_type: "xã",
    codename: "xa_cach_linh",
    district_code: 49,
  },
  {
    name: "Xã Đại Sơn",
    code: 1639,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 49,
  },
  {
    name: "Xã Tiên Thành",
    code: 1645,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 49,
  },
  {
    name: "Thị trấn Hoà Thuận",
    code: 1648,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_thuan",
    district_code: 49,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 1651,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 49,
  },
  {
    name: "Thị trấn Nước Hai",
    code: 1654,
    division_type: "thị trấn",
    codename: "thi_tran_nuoc_hai",
    district_code: 51,
  },
  {
    name: "Xã Dân Chủ",
    code: 1657,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 51,
  },
  {
    name: "Xã Nam Tuấn",
    code: 1660,
    division_type: "xã",
    codename: "xa_nam_tuan",
    district_code: 51,
  },
  {
    name: "Xã Đại Tiến",
    code: 1666,
    division_type: "xã",
    codename: "xa_dai_tien",
    district_code: 51,
  },
  {
    name: "Xã Đức Long",
    code: 1669,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 51,
  },
  {
    name: "Xã Ngũ Lão",
    code: 1672,
    division_type: "xã",
    codename: "xa_ngu_lao",
    district_code: 51,
  },
  {
    name: "Xã Trương Lương",
    code: 1675,
    division_type: "xã",
    codename: "xa_truong_luong",
    district_code: 51,
  },
  {
    name: "Xã Hồng Việt",
    code: 1687,
    division_type: "xã",
    codename: "xa_hong_viet",
    district_code: 51,
  },
  {
    name: "Xã Hoàng Tung",
    code: 1696,
    division_type: "xã",
    codename: "xa_hoang_tung",
    district_code: 51,
  },
  {
    name: "Xã Nguyễn Huệ",
    code: 1699,
    division_type: "xã",
    codename: "xa_nguyen_hue",
    district_code: 51,
  },
  {
    name: "Xã Quang Trung",
    code: 1702,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 51,
  },
  {
    name: "Xã Bạch Đằng",
    code: 1708,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 51,
  },
  {
    name: "Xã Bình Dương",
    code: 1711,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 51,
  },
  {
    name: "Xã Lê Chung",
    code: 1714,
    division_type: "xã",
    codename: "xa_le_chung",
    district_code: 51,
  },
  {
    name: "Xã Hồng Nam",
    code: 1723,
    division_type: "xã",
    codename: "xa_hong_nam",
    district_code: 51,
  },
  {
    name: "Thị trấn Nguyên Bình",
    code: 1726,
    division_type: "thị trấn",
    codename: "thi_tran_nguyen_binh",
    district_code: 52,
  },
  {
    name: "Thị trấn Tĩnh Túc",
    code: 1729,
    division_type: "thị trấn",
    codename: "thi_tran_tinh_tuc",
    district_code: 52,
  },
  {
    name: "Xã Yên Lạc",
    code: 1732,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 52,
  },
  {
    name: "Xã Triệu Nguyên",
    code: 1735,
    division_type: "xã",
    codename: "xa_trieu_nguyen",
    district_code: 52,
  },
  {
    name: "Xã Ca Thành",
    code: 1738,
    division_type: "xã",
    codename: "xa_ca_thanh",
    district_code: 52,
  },
  {
    name: "Xã Vũ Nông",
    code: 1744,
    division_type: "xã",
    codename: "xa_vu_nong",
    district_code: 52,
  },
  {
    name: "Xã Minh Tâm",
    code: 1747,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 52,
  },
  {
    name: "Xã Thể Dục",
    code: 1750,
    division_type: "xã",
    codename: "xa_the_duc",
    district_code: 52,
  },
  {
    name: "Xã Mai Long",
    code: 1756,
    division_type: "xã",
    codename: "xa_mai_long",
    district_code: 52,
  },
  {
    name: "Xã Vũ Minh",
    code: 1762,
    division_type: "xã",
    codename: "xa_vu_minh",
    district_code: 52,
  },
  {
    name: "Xã Hoa Thám",
    code: 1765,
    division_type: "xã",
    codename: "xa_hoa_tham",
    district_code: 52,
  },
  {
    name: "Xã Phan Thanh",
    code: 1768,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 52,
  },
  {
    name: "Xã Quang Thành",
    code: 1771,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 52,
  },
  {
    name: "Xã Tam Kim",
    code: 1774,
    division_type: "xã",
    codename: "xa_tam_kim",
    district_code: 52,
  },
  {
    name: "Xã Thành Công",
    code: 1777,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 52,
  },
  {
    name: "Xã Thịnh Vượng",
    code: 1780,
    division_type: "xã",
    codename: "xa_thinh_vuong",
    district_code: 52,
  },
  {
    name: "Xã Hưng Đạo",
    code: 1783,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 52,
  },
  {
    name: "Thị trấn Đông Khê",
    code: 1786,
    division_type: "thị trấn",
    codename: "thi_tran_dong_khe",
    district_code: 53,
  },
  {
    name: "Xã Canh Tân",
    code: 1789,
    division_type: "xã",
    codename: "xa_canh_tan",
    district_code: 53,
  },
  {
    name: "Xã Kim Đồng",
    code: 1792,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 53,
  },
  {
    name: "Xã Minh Khai",
    code: 1795,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 53,
  },
  {
    name: "Xã Đức Thông",
    code: 1801,
    division_type: "xã",
    codename: "xa_duc_thong",
    district_code: 53,
  },
  {
    name: "Xã Thái Cường",
    code: 1804,
    division_type: "xã",
    codename: "xa_thai_cuong",
    district_code: 53,
  },
  {
    name: "Xã Vân Trình",
    code: 1807,
    division_type: "xã",
    codename: "xa_van_trinh",
    district_code: 53,
  },
  {
    name: "Xã Thụy Hùng",
    code: 1810,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 53,
  },
  {
    name: "Xã Quang Trọng",
    code: 1813,
    division_type: "xã",
    codename: "xa_quang_trong",
    district_code: 53,
  },
  {
    name: "Xã Trọng Con",
    code: 1816,
    division_type: "xã",
    codename: "xa_trong_con",
    district_code: 53,
  },
  {
    name: "Xã Lê Lai",
    code: 1819,
    division_type: "xã",
    codename: "xa_le_lai",
    district_code: 53,
  },
  {
    name: "Xã Đức Long",
    code: 1822,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 53,
  },
  {
    name: "Xã Lê Lợi",
    code: 1828,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 53,
  },
  {
    name: "Xã Đức Xuân",
    code: 1831,
    division_type: "xã",
    codename: "xa_duc_xuan",
    district_code: 53,
  },
  {
    name: "Phường Nguyễn Thị Minh Khai",
    code: 1834,
    division_type: "phường",
    codename: "phuong_nguyen_thi_minh_khai",
    district_code: 58,
  },
  {
    name: "Phường Sông Cầu",
    code: 1837,
    division_type: "phường",
    codename: "phuong_song_cau",
    district_code: 58,
  },
  {
    name: "Phường Đức Xuân",
    code: 1840,
    division_type: "phường",
    codename: "phuong_duc_xuan",
    district_code: 58,
  },
  {
    name: "Phường Phùng Chí Kiên",
    code: 1843,
    division_type: "phường",
    codename: "phuong_phung_chi_kien",
    district_code: 58,
  },
  {
    name: "Phường Huyền Tụng",
    code: 1846,
    division_type: "phường",
    codename: "phuong_huyen_tung",
    district_code: 58,
  },
  {
    name: "Xã Dương Quang",
    code: 1849,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 58,
  },
  {
    name: "Xã Nông Thượng",
    code: 1852,
    division_type: "xã",
    codename: "xa_nong_thuong",
    district_code: 58,
  },
  {
    name: "Phường Xuất Hóa",
    code: 1855,
    division_type: "phường",
    codename: "phuong_xuat_hoa",
    district_code: 58,
  },
  {
    name: "Xã Bằng Thành",
    code: 1858,
    division_type: "xã",
    codename: "xa_bang_thanh",
    district_code: 60,
  },
  {
    name: "Xã Nhạn Môn",
    code: 1861,
    division_type: "xã",
    codename: "xa_nhan_mon",
    district_code: 60,
  },
  {
    name: "Xã Bộc Bố",
    code: 1864,
    division_type: "xã",
    codename: "xa_boc_bo",
    district_code: 60,
  },
  {
    name: "Xã Công Bằng",
    code: 1867,
    division_type: "xã",
    codename: "xa_cong_bang",
    district_code: 60,
  },
  {
    name: "Xã Giáo Hiệu",
    code: 1870,
    division_type: "xã",
    codename: "xa_giao_hieu",
    district_code: 60,
  },
  {
    name: "Xã Xuân La",
    code: 1873,
    division_type: "xã",
    codename: "xa_xuan_la",
    district_code: 60,
  },
  {
    name: "Xã An Thắng",
    code: 1876,
    division_type: "xã",
    codename: "xa_an_thang",
    district_code: 60,
  },
  {
    name: "Xã Cổ Linh",
    code: 1879,
    division_type: "xã",
    codename: "xa_co_linh",
    district_code: 60,
  },
  {
    name: "Xã Nghiên Loan",
    code: 1882,
    division_type: "xã",
    codename: "xa_nghien_loan",
    district_code: 60,
  },
  {
    name: "Xã Cao Tân",
    code: 1885,
    division_type: "xã",
    codename: "xa_cao_tan",
    district_code: 60,
  },
  {
    name: "Thị trấn Chợ Rã",
    code: 1888,
    division_type: "thị trấn",
    codename: "thi_tran_cho_ra",
    district_code: 61,
  },
  {
    name: "Xã Bành Trạch",
    code: 1891,
    division_type: "xã",
    codename: "xa_banh_trach",
    district_code: 61,
  },
  {
    name: "Xã Phúc Lộc",
    code: 1894,
    division_type: "xã",
    codename: "xa_phuc_loc",
    district_code: 61,
  },
  {
    name: "Xã Hà Hiệu",
    code: 1897,
    division_type: "xã",
    codename: "xa_ha_hieu",
    district_code: 61,
  },
  {
    name: "Xã Cao Thượng",
    code: 1900,
    division_type: "xã",
    codename: "xa_cao_thuong",
    district_code: 61,
  },
  {
    name: "Xã Khang Ninh",
    code: 1906,
    division_type: "xã",
    codename: "xa_khang_ninh",
    district_code: 61,
  },
  {
    name: "Xã Nam Mẫu",
    code: 1909,
    division_type: "xã",
    codename: "xa_nam_mau",
    district_code: 61,
  },
  {
    name: "Xã Thượng Giáo",
    code: 1912,
    division_type: "xã",
    codename: "xa_thuong_giao",
    district_code: 61,
  },
  {
    name: "Xã Địa Linh",
    code: 1915,
    division_type: "xã",
    codename: "xa_dia_linh",
    district_code: 61,
  },
  {
    name: "Xã Yến Dương",
    code: 1918,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 61,
  },
  {
    name: "Xã Chu Hương",
    code: 1921,
    division_type: "xã",
    codename: "xa_chu_huong",
    district_code: 61,
  },
  {
    name: "Xã Quảng Khê",
    code: 1924,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 61,
  },
  {
    name: "Xã Mỹ Phương",
    code: 1927,
    division_type: "xã",
    codename: "xa_my_phuong",
    district_code: 61,
  },
  {
    name: "Xã Hoàng Trĩ",
    code: 1930,
    division_type: "xã",
    codename: "xa_hoang_tri",
    district_code: 61,
  },
  {
    name: "Xã Đồng Phúc",
    code: 1933,
    division_type: "xã",
    codename: "xa_dong_phuc",
    district_code: 61,
  },
  {
    name: "Thị trấn Nà Phặc",
    code: 1936,
    division_type: "thị trấn",
    codename: "thi_tran_na_phac",
    district_code: 62,
  },
  {
    name: "Xã Thượng Ân",
    code: 1939,
    division_type: "xã",
    codename: "xa_thuong_an",
    district_code: 62,
  },
  {
    name: "Xã Bằng Vân",
    code: 1942,
    division_type: "xã",
    codename: "xa_bang_van",
    district_code: 62,
  },
  {
    name: "Xã Cốc Đán",
    code: 1945,
    division_type: "xã",
    codename: "xa_coc_dan",
    district_code: 62,
  },
  {
    name: "Xã Trung Hoà",
    code: 1948,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 62,
  },
  {
    name: "Xã Đức Vân",
    code: 1951,
    division_type: "xã",
    codename: "xa_duc_van",
    district_code: 62,
  },
  {
    name: "Xã Vân Tùng",
    code: 1954,
    division_type: "xã",
    codename: "xa_van_tung",
    district_code: 62,
  },
  {
    name: "Xã Thượng Quan",
    code: 1957,
    division_type: "xã",
    codename: "xa_thuong_quan",
    district_code: 62,
  },
  {
    name: "Xã Hiệp Lực",
    code: 1960,
    division_type: "xã",
    codename: "xa_hiep_luc",
    district_code: 62,
  },
  {
    name: "Xã Thuần Mang",
    code: 1963,
    division_type: "xã",
    codename: "xa_thuan_mang",
    district_code: 62,
  },
  {
    name: "Thị trấn Phủ Thông",
    code: 1969,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thong",
    district_code: 63,
  },
  {
    name: "Xã Vi Hương",
    code: 1975,
    division_type: "xã",
    codename: "xa_vi_huong",
    district_code: 63,
  },
  {
    name: "Xã Sĩ Bình",
    code: 1978,
    division_type: "xã",
    codename: "xa_si_binh",
    district_code: 63,
  },
  {
    name: "Xã Vũ Muộn",
    code: 1981,
    division_type: "xã",
    codename: "xa_vu_muon",
    district_code: 63,
  },
  {
    name: "Xã Đôn Phong",
    code: 1984,
    division_type: "xã",
    codename: "xa_don_phong",
    district_code: 63,
  },
  {
    name: "Xã Lục Bình",
    code: 1990,
    division_type: "xã",
    codename: "xa_luc_binh",
    district_code: 63,
  },
  {
    name: "Xã Tân Tú",
    code: 1993,
    division_type: "xã",
    codename: "xa_tan_tu",
    district_code: 63,
  },
  {
    name: "Xã Nguyên Phúc",
    code: 1999,
    division_type: "xã",
    codename: "xa_nguyen_phuc",
    district_code: 63,
  },
  {
    name: "Xã Cao Sơn",
    code: 2002,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 63,
  },
  {
    name: "Xã Quân Hà",
    code: 2005,
    division_type: "xã",
    codename: "xa_quan_ha",
    district_code: 63,
  },
  {
    name: "Xã Cẩm Giàng",
    code: 2008,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 63,
  },
  {
    name: "Xã Mỹ Thanh",
    code: 2011,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 63,
  },
  {
    name: "Xã Dương Phong",
    code: 2014,
    division_type: "xã",
    codename: "xa_duong_phong",
    district_code: 63,
  },
  {
    name: "Xã Quang Thuận",
    code: 2017,
    division_type: "xã",
    codename: "xa_quang_thuan",
    district_code: 63,
  },
  {
    name: "Thị trấn Bằng Lũng",
    code: 2020,
    division_type: "thị trấn",
    codename: "thi_tran_bang_lung",
    district_code: 64,
  },
  {
    name: "Xã Xuân Lạc",
    code: 2023,
    division_type: "xã",
    codename: "xa_xuan_lac",
    district_code: 64,
  },
  {
    name: "Xã Nam Cường",
    code: 2026,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 64,
  },
  {
    name: "Xã Đồng Lạc",
    code: 2029,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 64,
  },
  {
    name: "Xã Tân Lập",
    code: 2032,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 64,
  },
  {
    name: "Xã Bản Thi",
    code: 2035,
    division_type: "xã",
    codename: "xa_ban_thi",
    district_code: 64,
  },
  {
    name: "Xã Quảng Bạch",
    code: 2038,
    division_type: "xã",
    codename: "xa_quang_bach",
    district_code: 64,
  },
  {
    name: "Xã Bằng Phúc",
    code: 2041,
    division_type: "xã",
    codename: "xa_bang_phuc",
    district_code: 64,
  },
  {
    name: "Xã Yên Thịnh",
    code: 2044,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 64,
  },
  {
    name: "Xã Yên Thượng",
    code: 2047,
    division_type: "xã",
    codename: "xa_yen_thuong",
    district_code: 64,
  },
  {
    name: "Xã Phương Viên",
    code: 2050,
    division_type: "xã",
    codename: "xa_phuong_vien",
    district_code: 64,
  },
  {
    name: "Xã Ngọc Phái",
    code: 2053,
    division_type: "xã",
    codename: "xa_ngoc_phai",
    district_code: 64,
  },
  {
    name: "Xã Đồng Thắng",
    code: 2059,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 64,
  },
  {
    name: "Xã Lương Bằng",
    code: 2062,
    division_type: "xã",
    codename: "xa_luong_bang",
    district_code: 64,
  },
  {
    name: "Xã Bằng Lãng",
    code: 2065,
    division_type: "xã",
    codename: "xa_bang_lang",
    district_code: 64,
  },
  {
    name: "Xã Đại Sảo",
    code: 2068,
    division_type: "xã",
    codename: "xa_dai_sao",
    district_code: 64,
  },
  {
    name: "Xã Nghĩa Tá",
    code: 2071,
    division_type: "xã",
    codename: "xa_nghia_ta",
    district_code: 64,
  },
  {
    name: "Xã Yên Mỹ",
    code: 2077,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 64,
  },
  {
    name: "Xã Bình Trung",
    code: 2080,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 64,
  },
  {
    name: "Xã Yên Phong",
    code: 2083,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 64,
  },
  {
    name: "Thị trấn Đồng Tâm",
    code: 2086,
    division_type: "thị trấn",
    codename: "thi_tran_dong_tam",
    district_code: 65,
  },
  {
    name: "Xã Tân Sơn",
    code: 2089,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 65,
  },
  {
    name: "Xã Thanh Vận",
    code: 2092,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 65,
  },
  {
    name: "Xã Mai Lạp",
    code: 2095,
    division_type: "xã",
    codename: "xa_mai_lap",
    district_code: 65,
  },
  {
    name: "Xã Hoà Mục",
    code: 2098,
    division_type: "xã",
    codename: "xa_hoa_muc",
    district_code: 65,
  },
  {
    name: "Xã Thanh Mai",
    code: 2101,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 65,
  },
  {
    name: "Xã Cao Kỳ",
    code: 2104,
    division_type: "xã",
    codename: "xa_cao_ky",
    district_code: 65,
  },
  {
    name: "Xã Nông Hạ",
    code: 2107,
    division_type: "xã",
    codename: "xa_nong_ha",
    district_code: 65,
  },
  {
    name: "Xã Yên Cư",
    code: 2110,
    division_type: "xã",
    codename: "xa_yen_cu",
    district_code: 65,
  },
  {
    name: "Xã Thanh Thịnh",
    code: 2113,
    division_type: "xã",
    codename: "xa_thanh_thinh",
    district_code: 65,
  },
  {
    name: "Xã Yên Hân",
    code: 2116,
    division_type: "xã",
    codename: "xa_yen_han",
    district_code: 65,
  },
  {
    name: "Xã Như Cố",
    code: 2122,
    division_type: "xã",
    codename: "xa_nhu_co",
    district_code: 65,
  },
  {
    name: "Xã Bình Văn",
    code: 2125,
    division_type: "xã",
    codename: "xa_binh_van",
    district_code: 65,
  },
  {
    name: "Xã Quảng Chu",
    code: 2131,
    division_type: "xã",
    codename: "xa_quang_chu",
    district_code: 65,
  },
  {
    name: "Xã Văn Vũ",
    code: 2137,
    division_type: "xã",
    codename: "xa_van_vu",
    district_code: 66,
  },
  {
    name: "Xã Văn Lang",
    code: 2140,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 66,
  },
  {
    name: "Xã Lương Thượng",
    code: 2143,
    division_type: "xã",
    codename: "xa_luong_thuong",
    district_code: 66,
  },
  {
    name: "Xã Kim Hỷ",
    code: 2146,
    division_type: "xã",
    codename: "xa_kim_hy",
    district_code: 66,
  },
  {
    name: "Xã Cường Lợi",
    code: 2152,
    division_type: "xã",
    codename: "xa_cuong_loi",
    district_code: 66,
  },
  {
    name: "Thị trấn Yến Lạc",
    code: 2155,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lac",
    district_code: 66,
  },
  {
    name: "Xã Kim Lư",
    code: 2158,
    division_type: "xã",
    codename: "xa_kim_lu",
    district_code: 66,
  },
  {
    name: "Xã Sơn Thành",
    code: 2161,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 66,
  },
  {
    name: "Xã Văn Minh",
    code: 2170,
    division_type: "xã",
    codename: "xa_van_minh",
    district_code: 66,
  },
  {
    name: "Xã Côn Minh",
    code: 2173,
    division_type: "xã",
    codename: "xa_con_minh",
    district_code: 66,
  },
  {
    name: "Xã Cư Lễ",
    code: 2176,
    division_type: "xã",
    codename: "xa_cu_le",
    district_code: 66,
  },
  {
    name: "Xã Trần Phú",
    code: 2179,
    division_type: "xã",
    codename: "xa_tran_phu",
    district_code: 66,
  },
  {
    name: "Xã Quang Phong",
    code: 2185,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 66,
  },
  {
    name: "Xã Dương Sơn",
    code: 2188,
    division_type: "xã",
    codename: "xa_duong_son",
    district_code: 66,
  },
  {
    name: "Xã Xuân Dương",
    code: 2191,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 66,
  },
  {
    name: "Xã Đổng Xá",
    code: 2194,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 66,
  },
  {
    name: "Xã Liêm Thuỷ",
    code: 2197,
    division_type: "xã",
    codename: "xa_liem_thuy",
    district_code: 66,
  },
  {
    name: "Phường Phan Thiết",
    code: 2200,
    division_type: "phường",
    codename: "phuong_phan_thiet",
    district_code: 70,
  },
  {
    name: "Phường Minh Xuân",
    code: 2203,
    division_type: "phường",
    codename: "phuong_minh_xuan",
    district_code: 70,
  },
  {
    name: "Phường Tân Quang",
    code: 2206,
    division_type: "phường",
    codename: "phuong_tan_quang",
    district_code: 70,
  },
  {
    name: "Xã Tràng Đà",
    code: 2209,
    division_type: "xã",
    codename: "xa_trang_da",
    district_code: 70,
  },
  {
    name: "Phường Nông Tiến",
    code: 2212,
    division_type: "phường",
    codename: "phuong_nong_tien",
    district_code: 70,
  },
  {
    name: "Phường Ỷ La",
    code: 2215,
    division_type: "phường",
    codename: "phuong_y_la",
    district_code: 70,
  },
  {
    name: "Phường Tân Hà",
    code: 2216,
    division_type: "phường",
    codename: "phuong_tan_ha",
    district_code: 70,
  },
  {
    name: "Phường Hưng Thành",
    code: 2218,
    division_type: "phường",
    codename: "phuong_hung_thanh",
    district_code: 70,
  },
  {
    name: "Xã Kim Phú",
    code: 2497,
    division_type: "xã",
    codename: "xa_kim_phu",
    district_code: 70,
  },
  {
    name: "Xã An Khang",
    code: 2503,
    division_type: "xã",
    codename: "xa_an_khang",
    district_code: 70,
  },
  {
    name: "Phường Mỹ Lâm",
    code: 2509,
    division_type: "phường",
    codename: "phuong_my_lam",
    district_code: 70,
  },
  {
    name: "Phường An Tường",
    code: 2512,
    division_type: "phường",
    codename: "phuong_an_tuong",
    district_code: 70,
  },
  {
    name: "Xã Lưỡng Vượng",
    code: 2515,
    division_type: "xã",
    codename: "xa_luong_vuong",
    district_code: 70,
  },
  {
    name: "Xã Thái Long",
    code: 2521,
    division_type: "xã",
    codename: "xa_thai_long",
    district_code: 70,
  },
  {
    name: "Phường Đội Cấn",
    code: 2524,
    division_type: "phường",
    codename: "phuong_doi_can",
    district_code: 70,
  },
  {
    name: "Xã Phúc Yên",
    code: 2233,
    division_type: "xã",
    codename: "xa_phuc_yen",
    district_code: 71,
  },
  {
    name: "Xã Xuân Lập",
    code: 2242,
    division_type: "xã",
    codename: "xa_xuan_lap",
    district_code: 71,
  },
  {
    name: "Xã Khuôn Hà",
    code: 2251,
    division_type: "xã",
    codename: "xa_khuon_ha",
    district_code: 71,
  },
  {
    name: "Xã Lăng Can",
    code: 2266,
    division_type: "xã",
    codename: "xa_lang_can",
    district_code: 71,
  },
  {
    name: "Xã Thượng Lâm",
    code: 2269,
    division_type: "xã",
    codename: "xa_thuong_lam",
    district_code: 71,
  },
  {
    name: "Xã Bình An",
    code: 2290,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 71,
  },
  {
    name: "Xã Hồng Quang",
    code: 2293,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 71,
  },
  {
    name: "Xã Thổ Bình",
    code: 2296,
    division_type: "xã",
    codename: "xa_tho_binh",
    district_code: 71,
  },
  {
    name: "Thị trấn Na Hang",
    code: 2221,
    division_type: "thị trấn",
    codename: "thi_tran_na_hang",
    district_code: 72,
  },
  {
    name: "Xã Sinh Long",
    code: 2227,
    division_type: "xã",
    codename: "xa_sinh_long",
    district_code: 72,
  },
  {
    name: "Xã Thượng Giáp",
    code: 2230,
    division_type: "xã",
    codename: "xa_thuong_giap",
    district_code: 72,
  },
  {
    name: "Xã Thượng Nông",
    code: 2239,
    division_type: "xã",
    codename: "xa_thuong_nong",
    district_code: 72,
  },
  {
    name: "Xã Côn Lôn",
    code: 2245,
    division_type: "xã",
    codename: "xa_con_lon",
    district_code: 72,
  },
  {
    name: "Xã Yên Hoa",
    code: 2248,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 72,
  },
  {
    name: "Xã Hồng Thái",
    code: 2254,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 72,
  },
  {
    name: "Xã Đà Vị",
    code: 2260,
    division_type: "xã",
    codename: "xa_da_vi",
    district_code: 72,
  },
  {
    name: "Xã Khau Tinh",
    code: 2263,
    division_type: "xã",
    codename: "xa_khau_tinh",
    district_code: 72,
  },
  {
    name: "Xã Sơn Phú",
    code: 2275,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 72,
  },
  {
    name: "Xã Năng Khả",
    code: 2281,
    division_type: "xã",
    codename: "xa_nang_kha",
    district_code: 72,
  },
  {
    name: "Xã Thanh Tương",
    code: 2284,
    division_type: "xã",
    codename: "xa_thanh_tuong",
    district_code: 72,
  },
  {
    name: "Thị trấn Vĩnh Lộc",
    code: 2287,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_loc",
    district_code: 73,
  },
  {
    name: "Xã Phúc Sơn",
    code: 2299,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 73,
  },
  {
    name: "Xã Minh Quang",
    code: 2302,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 73,
  },
  {
    name: "Xã Trung Hà",
    code: 2305,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 73,
  },
  {
    name: "Xã Tân Mỹ",
    code: 2308,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 73,
  },
  {
    name: "Xã Hà Lang",
    code: 2311,
    division_type: "xã",
    codename: "xa_ha_lang",
    district_code: 73,
  },
  {
    name: "Xã Hùng Mỹ",
    code: 2314,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 73,
  },
  {
    name: "Xã Yên Lập",
    code: 2317,
    division_type: "xã",
    codename: "xa_yen_lap",
    district_code: 73,
  },
  {
    name: "Xã Tân An",
    code: 2320,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 73,
  },
  {
    name: "Xã Bình Phú",
    code: 2323,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 73,
  },
  {
    name: "Xã Xuân Quang",
    code: 2326,
    division_type: "xã",
    codename: "xa_xuan_quang",
    district_code: 73,
  },
  {
    name: "Xã Ngọc Hội",
    code: 2329,
    division_type: "xã",
    codename: "xa_ngoc_hoi",
    district_code: 73,
  },
  {
    name: "Xã Phú Bình",
    code: 2332,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 73,
  },
  {
    name: "Xã Hòa Phú",
    code: 2335,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 73,
  },
  {
    name: "Xã Phúc Thịnh",
    code: 2338,
    division_type: "xã",
    codename: "xa_phuc_thinh",
    district_code: 73,
  },
  {
    name: "Xã Kiên Đài",
    code: 2341,
    division_type: "xã",
    codename: "xa_kien_dai",
    district_code: 73,
  },
  {
    name: "Xã Tân Thịnh",
    code: 2344,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 73,
  },
  {
    name: "Xã Trung Hòa",
    code: 2347,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 73,
  },
  {
    name: "Xã Kim Bình",
    code: 2350,
    division_type: "xã",
    codename: "xa_kim_binh",
    district_code: 73,
  },
  {
    name: "Xã Hòa An",
    code: 2353,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 73,
  },
  {
    name: "Xã Vinh Quang",
    code: 2356,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 73,
  },
  {
    name: "Xã Tri Phú",
    code: 2359,
    division_type: "xã",
    codename: "xa_tri_phu",
    district_code: 73,
  },
  {
    name: "Xã Nhân Lý",
    code: 2362,
    division_type: "xã",
    codename: "xa_nhan_ly",
    district_code: 73,
  },
  {
    name: "Xã Yên Nguyên",
    code: 2365,
    division_type: "xã",
    codename: "xa_yen_nguyen",
    district_code: 73,
  },
  {
    name: "Xã Linh Phú",
    code: 2368,
    division_type: "xã",
    codename: "xa_linh_phu",
    district_code: 73,
  },
  {
    name: "Xã Bình Nhân",
    code: 2371,
    division_type: "xã",
    codename: "xa_binh_nhan",
    district_code: 73,
  },
  {
    name: "Thị trấn Tân Yên",
    code: 2374,
    division_type: "thị trấn",
    codename: "thi_tran_tan_yen",
    district_code: 74,
  },
  {
    name: "Xã Yên Thuận",
    code: 2377,
    division_type: "xã",
    codename: "xa_yen_thuan",
    district_code: 74,
  },
  {
    name: "Xã Bạch Xa",
    code: 2380,
    division_type: "xã",
    codename: "xa_bach_xa",
    district_code: 74,
  },
  {
    name: "Xã Minh Khương",
    code: 2383,
    division_type: "xã",
    codename: "xa_minh_khuong",
    district_code: 74,
  },
  {
    name: "Xã Yên Lâm",
    code: 2386,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 74,
  },
  {
    name: "Xã Minh Dân",
    code: 2389,
    division_type: "xã",
    codename: "xa_minh_dan",
    district_code: 74,
  },
  {
    name: "Xã Phù Lưu",
    code: 2392,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 74,
  },
  {
    name: "Xã Minh Hương",
    code: 2395,
    division_type: "xã",
    codename: "xa_minh_huong",
    district_code: 74,
  },
  {
    name: "Xã Yên Phú",
    code: 2398,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 74,
  },
  {
    name: "Xã Tân Thành",
    code: 2401,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 74,
  },
  {
    name: "Xã Bình Xa",
    code: 2404,
    division_type: "xã",
    codename: "xa_binh_xa",
    district_code: 74,
  },
  {
    name: "Xã Thái Sơn",
    code: 2407,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 74,
  },
  {
    name: "Xã Nhân Mục",
    code: 2410,
    division_type: "xã",
    codename: "xa_nhan_muc",
    district_code: 74,
  },
  {
    name: "Xã Thành Long",
    code: 2413,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 74,
  },
  {
    name: "Xã Bằng Cốc",
    code: 2416,
    division_type: "xã",
    codename: "xa_bang_coc",
    district_code: 74,
  },
  {
    name: "Xã Thái Hòa",
    code: 2419,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 74,
  },
  {
    name: "Xã Đức Ninh",
    code: 2422,
    division_type: "xã",
    codename: "xa_duc_ninh",
    district_code: 74,
  },
  {
    name: "Xã Hùng Đức",
    code: 2425,
    division_type: "xã",
    codename: "xa_hung_duc",
    district_code: 74,
  },
  {
    name: "Xã Quí Quân",
    code: 2431,
    division_type: "xã",
    codename: "xa_qui_quan",
    district_code: 75,
  },
  {
    name: "Xã Lực Hành",
    code: 2434,
    division_type: "xã",
    codename: "xa_luc_hanh",
    district_code: 75,
  },
  {
    name: "Xã Kiến Thiết",
    code: 2437,
    division_type: "xã",
    codename: "xa_kien_thiet",
    district_code: 75,
  },
  {
    name: "Xã Trung Minh",
    code: 2440,
    division_type: "xã",
    codename: "xa_trung_minh",
    district_code: 75,
  },
  {
    name: "Xã Chiêu Yên",
    code: 2443,
    division_type: "xã",
    codename: "xa_chieu_yen",
    district_code: 75,
  },
  {
    name: "Xã Trung Trực",
    code: 2446,
    division_type: "xã",
    codename: "xa_trung_truc",
    district_code: 75,
  },
  {
    name: "Xã Xuân Vân",
    code: 2449,
    division_type: "xã",
    codename: "xa_xuan_van",
    district_code: 75,
  },
  {
    name: "Xã Phúc Ninh",
    code: 2452,
    division_type: "xã",
    codename: "xa_phuc_ninh",
    district_code: 75,
  },
  {
    name: "Xã Hùng Lợi",
    code: 2455,
    division_type: "xã",
    codename: "xa_hung_loi",
    district_code: 75,
  },
  {
    name: "Xã Trung Sơn",
    code: 2458,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 75,
  },
  {
    name: "Xã Tân Tiến",
    code: 2461,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 75,
  },
  {
    name: "Xã Tứ Quận",
    code: 2464,
    division_type: "xã",
    codename: "xa_tu_quan",
    district_code: 75,
  },
  {
    name: "Xã Đạo Viện",
    code: 2467,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 75,
  },
  {
    name: "Xã Tân Long",
    code: 2470,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 75,
  },
  {
    name: "Xã Thắng Quân",
    code: 2473,
    division_type: "xã",
    codename: "xa_thang_quan",
    district_code: 75,
  },
  {
    name: "Xã Kim Quan",
    code: 2476,
    division_type: "xã",
    codename: "xa_kim_quan",
    district_code: 75,
  },
  {
    name: "Xã Lang Quán",
    code: 2479,
    division_type: "xã",
    codename: "xa_lang_quan",
    district_code: 75,
  },
  {
    name: "Xã Phú Thịnh",
    code: 2482,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 75,
  },
  {
    name: "Xã Công Đa",
    code: 2485,
    division_type: "xã",
    codename: "xa_cong_da",
    district_code: 75,
  },
  {
    name: "Xã Trung Môn",
    code: 2488,
    division_type: "xã",
    codename: "xa_trung_mon",
    district_code: 75,
  },
  {
    name: "Xã Chân Sơn",
    code: 2491,
    division_type: "xã",
    codename: "xa_chan_son",
    district_code: 75,
  },
  {
    name: "Xã Thái Bình",
    code: 2494,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 75,
  },
  {
    name: "Xã Tiến Bộ",
    code: 2500,
    division_type: "xã",
    codename: "xa_tien_bo",
    district_code: 75,
  },
  {
    name: "Xã Mỹ Bằng",
    code: 2506,
    division_type: "xã",
    codename: "xa_my_bang",
    district_code: 75,
  },
  {
    name: "Xã Hoàng Khai",
    code: 2518,
    division_type: "xã",
    codename: "xa_hoang_khai",
    district_code: 75,
  },
  {
    name: "Xã Nhữ Hán",
    code: 2527,
    division_type: "xã",
    codename: "xa_nhu_han",
    district_code: 75,
  },
  {
    name: "Xã Nhữ Khê",
    code: 2530,
    division_type: "xã",
    codename: "xa_nhu_khe",
    district_code: 75,
  },
  {
    name: "Xã Đội Bình",
    code: 2533,
    division_type: "xã",
    codename: "xa_doi_binh",
    district_code: 75,
  },
  {
    name: "Thị trấn Sơn Dương",
    code: 2536,
    division_type: "thị trấn",
    codename: "thi_tran_son_duong",
    district_code: 76,
  },
  {
    name: "Xã Trung Yên",
    code: 2539,
    division_type: "xã",
    codename: "xa_trung_yen",
    district_code: 76,
  },
  {
    name: "Xã Minh Thanh",
    code: 2542,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 76,
  },
  {
    name: "Xã Tân Trào",
    code: 2545,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 76,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 2548,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 76,
  },
  {
    name: "Xã Thượng Ấm",
    code: 2551,
    division_type: "xã",
    codename: "xa_thuong_am",
    district_code: 76,
  },
  {
    name: "Xã Bình Yên",
    code: 2554,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 76,
  },
  {
    name: "Xã Lương Thiện",
    code: 2557,
    division_type: "xã",
    codename: "xa_luong_thien",
    district_code: 76,
  },
  {
    name: "Xã Tú Thịnh",
    code: 2560,
    division_type: "xã",
    codename: "xa_tu_thinh",
    district_code: 76,
  },
  {
    name: "Xã Cấp Tiến",
    code: 2563,
    division_type: "xã",
    codename: "xa_cap_tien",
    district_code: 76,
  },
  {
    name: "Xã Hợp Thành",
    code: 2566,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 76,
  },
  {
    name: "Xã Phúc Ứng",
    code: 2569,
    division_type: "xã",
    codename: "xa_phuc_ung",
    district_code: 76,
  },
  {
    name: "Xã Đông Thọ",
    code: 2572,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 76,
  },
  {
    name: "Xã Kháng Nhật",
    code: 2575,
    division_type: "xã",
    codename: "xa_khang_nhat",
    district_code: 76,
  },
  {
    name: "Xã Hợp Hòa",
    code: 2578,
    division_type: "xã",
    codename: "xa_hop_hoa",
    district_code: 76,
  },
  {
    name: "Xã Quyết Thắng",
    code: 2584,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 76,
  },
  {
    name: "Xã Đồng Quý",
    code: 2587,
    division_type: "xã",
    codename: "xa_dong_quy",
    district_code: 76,
  },
  {
    name: "Xã Tân Thanh",
    code: 2590,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 76,
  },
  {
    name: "Xã Vân Sơn",
    code: 2593,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 76,
  },
  {
    name: "Xã Văn Phú",
    code: 2596,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 76,
  },
  {
    name: "Xã Chi Thiết",
    code: 2599,
    division_type: "xã",
    codename: "xa_chi_thiet",
    district_code: 76,
  },
  {
    name: "Xã Đông Lợi",
    code: 2602,
    division_type: "xã",
    codename: "xa_dong_loi",
    district_code: 76,
  },
  {
    name: "Xã Thiện Kế",
    code: 2605,
    division_type: "xã",
    codename: "xa_thien_ke",
    district_code: 76,
  },
  {
    name: "Xã Hồng Lạc",
    code: 2608,
    division_type: "xã",
    codename: "xa_hong_lac",
    district_code: 76,
  },
  {
    name: "Xã Phú Lương",
    code: 2611,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 76,
  },
  {
    name: "Xã Ninh Lai",
    code: 2614,
    division_type: "xã",
    codename: "xa_ninh_lai",
    district_code: 76,
  },
  {
    name: "Xã Đại Phú",
    code: 2617,
    division_type: "xã",
    codename: "xa_dai_phu",
    district_code: 76,
  },
  {
    name: "Xã Sơn Nam",
    code: 2620,
    division_type: "xã",
    codename: "xa_son_nam",
    district_code: 76,
  },
  {
    name: "Xã Hào Phú",
    code: 2623,
    division_type: "xã",
    codename: "xa_hao_phu",
    district_code: 76,
  },
  {
    name: "Xã Tam Đa",
    code: 2626,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 76,
  },
  {
    name: "Xã Trường Sinh",
    code: 2632,
    division_type: "xã",
    codename: "xa_truong_sinh",
    district_code: 76,
  },
  {
    name: "Phường Duyên Hải",
    code: 2635,
    division_type: "phường",
    codename: "phuong_duyen_hai",
    district_code: 80,
  },
  {
    name: "Phường Lào Cai",
    code: 2641,
    division_type: "phường",
    codename: "phuong_lao_cai",
    district_code: 80,
  },
  {
    name: "Phường Cốc Lếu",
    code: 2644,
    division_type: "phường",
    codename: "phuong_coc_leu",
    district_code: 80,
  },
  {
    name: "Phường Kim Tân",
    code: 2647,
    division_type: "phường",
    codename: "phuong_kim_tan",
    district_code: 80,
  },
  {
    name: "Phường Bắc Lệnh",
    code: 2650,
    division_type: "phường",
    codename: "phuong_bac_lenh",
    district_code: 80,
  },
  {
    name: "Phường Pom Hán",
    code: 2653,
    division_type: "phường",
    codename: "phuong_pom_han",
    district_code: 80,
  },
  {
    name: "Phường Xuân Tăng",
    code: 2656,
    division_type: "phường",
    codename: "phuong_xuan_tang",
    district_code: 80,
  },
  {
    name: "Phường Bình Minh",
    code: 2658,
    division_type: "phường",
    codename: "phuong_binh_minh",
    district_code: 80,
  },
  {
    name: "Xã Thống Nhất",
    code: 2659,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 80,
  },
  {
    name: "Xã Đồng Tuyển",
    code: 2662,
    division_type: "xã",
    codename: "xa_dong_tuyen",
    district_code: 80,
  },
  {
    name: "Xã Vạn Hoà",
    code: 2665,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 80,
  },
  {
    name: "Phường Bắc Cường",
    code: 2668,
    division_type: "phường",
    codename: "phuong_bac_cuong",
    district_code: 80,
  },
  {
    name: "Phường Nam Cường",
    code: 2671,
    division_type: "phường",
    codename: "phuong_nam_cuong",
    district_code: 80,
  },
  {
    name: "Xã Cam Đường",
    code: 2674,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 80,
  },
  {
    name: "Xã Tả Phời",
    code: 2677,
    division_type: "xã",
    codename: "xa_ta_phoi",
    district_code: 80,
  },
  {
    name: "Xã Hợp Thành",
    code: 2680,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 80,
  },
  {
    name: "Xã Cốc San",
    code: 2746,
    division_type: "xã",
    codename: "xa_coc_san",
    district_code: 80,
  },
  {
    name: "Thị trấn Bát Xát",
    code: 2683,
    division_type: "thị trấn",
    codename: "thi_tran_bat_xat",
    district_code: 82,
  },
  {
    name: "Xã A Mú Sung",
    code: 2686,
    division_type: "xã",
    codename: "xa_a_mu_sung",
    district_code: 82,
  },
  {
    name: "Xã Nậm Chạc",
    code: 2689,
    division_type: "xã",
    codename: "xa_nam_chac",
    district_code: 82,
  },
  {
    name: "Xã A Lù",
    code: 2692,
    division_type: "xã",
    codename: "xa_a_lu",
    district_code: 82,
  },
  {
    name: "Xã Trịnh Tường",
    code: 2695,
    division_type: "xã",
    codename: "xa_trinh_tuong",
    district_code: 82,
  },
  {
    name: "Xã Y Tý",
    code: 2701,
    division_type: "xã",
    codename: "xa_y_ty",
    district_code: 82,
  },
  {
    name: "Xã Cốc Mỳ",
    code: 2704,
    division_type: "xã",
    codename: "xa_coc_my",
    district_code: 82,
  },
  {
    name: "Xã Dền Sáng",
    code: 2707,
    division_type: "xã",
    codename: "xa_den_sang",
    district_code: 82,
  },
  {
    name: "Xã Bản Vược",
    code: 2710,
    division_type: "xã",
    codename: "xa_ban_vuoc",
    district_code: 82,
  },
  {
    name: "Xã Sàng Ma Sáo",
    code: 2713,
    division_type: "xã",
    codename: "xa_sang_ma_sao",
    district_code: 82,
  },
  {
    name: "Xã Bản Qua",
    code: 2716,
    division_type: "xã",
    codename: "xa_ban_qua",
    district_code: 82,
  },
  {
    name: "Xã Mường Vi",
    code: 2719,
    division_type: "xã",
    codename: "xa_muong_vi",
    district_code: 82,
  },
  {
    name: "Xã Dền Thàng",
    code: 2722,
    division_type: "xã",
    codename: "xa_den_thang",
    district_code: 82,
  },
  {
    name: "Xã Bản Xèo",
    code: 2725,
    division_type: "xã",
    codename: "xa_ban_xeo",
    district_code: 82,
  },
  {
    name: "Xã Mường Hum",
    code: 2728,
    division_type: "xã",
    codename: "xa_muong_hum",
    district_code: 82,
  },
  {
    name: "Xã Trung Lèng Hồ",
    code: 2731,
    division_type: "xã",
    codename: "xa_trung_leng_ho",
    district_code: 82,
  },
  {
    name: "Xã Quang Kim",
    code: 2734,
    division_type: "xã",
    codename: "xa_quang_kim",
    district_code: 82,
  },
  {
    name: "Xã Pa Cheo",
    code: 2737,
    division_type: "xã",
    codename: "xa_pa_cheo",
    district_code: 82,
  },
  {
    name: "Xã Nậm Pung",
    code: 2740,
    division_type: "xã",
    codename: "xa_nam_pung",
    district_code: 82,
  },
  {
    name: "Xã Phìn Ngan",
    code: 2743,
    division_type: "xã",
    codename: "xa_phin_ngan",
    district_code: 82,
  },
  {
    name: "Xã Tòng Sành",
    code: 2749,
    division_type: "xã",
    codename: "xa_tong_sanh",
    district_code: 82,
  },
  {
    name: "Xã Pha Long",
    code: 2752,
    division_type: "xã",
    codename: "xa_pha_long",
    district_code: 83,
  },
  {
    name: "Xã Tả Ngải Chồ",
    code: 2755,
    division_type: "xã",
    codename: "xa_ta_ngai_cho",
    district_code: 83,
  },
  {
    name: "Xã Tung Chung Phố",
    code: 2758,
    division_type: "xã",
    codename: "xa_tung_chung_pho",
    district_code: 83,
  },
  {
    name: "Thị trấn Mường Khương",
    code: 2761,
    division_type: "thị trấn",
    codename: "thi_tran_muong_khuong",
    district_code: 83,
  },
  {
    name: "Xã Dìn Chin",
    code: 2764,
    division_type: "xã",
    codename: "xa_din_chin",
    district_code: 83,
  },
  {
    name: "Xã Tả Gia Khâu",
    code: 2767,
    division_type: "xã",
    codename: "xa_ta_gia_khau",
    district_code: 83,
  },
  {
    name: "Xã Nậm Chảy",
    code: 2770,
    division_type: "xã",
    codename: "xa_nam_chay",
    district_code: 83,
  },
  {
    name: "Xã Nấm Lư",
    code: 2773,
    division_type: "xã",
    codename: "xa_nam_lu",
    district_code: 83,
  },
  {
    name: "Xã Lùng Khấu Nhin",
    code: 2776,
    division_type: "xã",
    codename: "xa_lung_khau_nhin",
    district_code: 83,
  },
  {
    name: "Xã Thanh Bình",
    code: 2779,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 83,
  },
  {
    name: "Xã Cao Sơn",
    code: 2782,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 83,
  },
  {
    name: "Xã Lùng Vai",
    code: 2785,
    division_type: "xã",
    codename: "xa_lung_vai",
    district_code: 83,
  },
  {
    name: "Xã Bản Lầu",
    code: 2788,
    division_type: "xã",
    codename: "xa_ban_lau",
    district_code: 83,
  },
  {
    name: "Xã La Pan Tẩn",
    code: 2791,
    division_type: "xã",
    codename: "xa_la_pan_tan",
    district_code: 83,
  },
  {
    name: "Xã Tả Thàng",
    code: 2794,
    division_type: "xã",
    codename: "xa_ta_thang",
    district_code: 83,
  },
  {
    name: "Xã Bản Sen",
    code: 2797,
    division_type: "xã",
    codename: "xa_ban_sen",
    district_code: 83,
  },
  {
    name: "Xã Nàn Sán",
    code: 2800,
    division_type: "xã",
    codename: "xa_nan_san",
    district_code: 84,
  },
  {
    name: "Xã Thào Chư Phìn",
    code: 2803,
    division_type: "xã",
    codename: "xa_thao_chu_phin",
    district_code: 84,
  },
  {
    name: "Xã Bản Mế",
    code: 2806,
    division_type: "xã",
    codename: "xa_ban_me",
    district_code: 84,
  },
  {
    name: "Thị trấn Si Ma Cai",
    code: 2809,
    division_type: "thị trấn",
    codename: "thi_tran_si_ma_cai",
    district_code: 84,
  },
  {
    name: "Xã Sán Chải",
    code: 2812,
    division_type: "xã",
    codename: "xa_san_chai",
    district_code: 84,
  },
  {
    name: "Xã Lùng Thẩn",
    code: 2818,
    division_type: "xã",
    codename: "xa_lung_than",
    district_code: 84,
  },
  {
    name: "Xã Cán Cấu",
    code: 2821,
    division_type: "xã",
    codename: "xa_can_cau",
    district_code: 84,
  },
  {
    name: "Xã Sín Chéng",
    code: 2824,
    division_type: "xã",
    codename: "xa_sin_cheng",
    district_code: 84,
  },
  {
    name: "Xã Quan Hồ Thẩn",
    code: 2827,
    division_type: "xã",
    codename: "xa_quan_ho_than",
    district_code: 84,
  },
  {
    name: "Xã Nàn Xín",
    code: 2836,
    division_type: "xã",
    codename: "xa_nan_xin",
    district_code: 84,
  },
  {
    name: "Thị trấn Bắc Hà",
    code: 2839,
    division_type: "thị trấn",
    codename: "thi_tran_bac_ha",
    district_code: 85,
  },
  {
    name: "Xã Lùng Cải",
    code: 2842,
    division_type: "xã",
    codename: "xa_lung_cai",
    district_code: 85,
  },
  {
    name: "Xã Lùng Phình",
    code: 2848,
    division_type: "xã",
    codename: "xa_lung_phinh",
    district_code: 85,
  },
  {
    name: "Xã Tả Van Chư",
    code: 2851,
    division_type: "xã",
    codename: "xa_ta_van_chu",
    district_code: 85,
  },
  {
    name: "Xã Tả Củ Tỷ",
    code: 2854,
    division_type: "xã",
    codename: "xa_ta_cu_ty",
    district_code: 85,
  },
  {
    name: "Xã Thải Giàng Phố",
    code: 2857,
    division_type: "xã",
    codename: "xa_thai_giang_pho",
    district_code: 85,
  },
  {
    name: "Xã Hoàng Thu Phố",
    code: 2863,
    division_type: "xã",
    codename: "xa_hoang_thu_pho",
    district_code: 85,
  },
  {
    name: "Xã Bản Phố",
    code: 2866,
    division_type: "xã",
    codename: "xa_ban_pho",
    district_code: 85,
  },
  {
    name: "Xã Bản Liền",
    code: 2869,
    division_type: "xã",
    codename: "xa_ban_lien",
    district_code: 85,
  },
  {
    name: "Xã Tà Chải",
    code: 2872,
    division_type: "xã",
    codename: "xa_ta_chai",
    district_code: 85,
  },
  {
    name: "Xã Na Hối",
    code: 2875,
    division_type: "xã",
    codename: "xa_na_hoi",
    district_code: 85,
  },
  {
    name: "Xã Cốc Ly",
    code: 2878,
    division_type: "xã",
    codename: "xa_coc_ly",
    district_code: 85,
  },
  {
    name: "Xã Nậm Mòn",
    code: 2881,
    division_type: "xã",
    codename: "xa_nam_mon",
    district_code: 85,
  },
  {
    name: "Xã Nậm Đét",
    code: 2884,
    division_type: "xã",
    codename: "xa_nam_det",
    district_code: 85,
  },
  {
    name: "Xã Nậm Khánh",
    code: 2887,
    division_type: "xã",
    codename: "xa_nam_khanh",
    district_code: 85,
  },
  {
    name: "Xã Bảo Nhai",
    code: 2890,
    division_type: "xã",
    codename: "xa_bao_nhai",
    district_code: 85,
  },
  {
    name: "Xã Nậm Lúc",
    code: 2893,
    division_type: "xã",
    codename: "xa_nam_luc",
    district_code: 85,
  },
  {
    name: "Xã Cốc Lầu",
    code: 2896,
    division_type: "xã",
    codename: "xa_coc_lau",
    district_code: 85,
  },
  {
    name: "Xã Bản Cái",
    code: 2899,
    division_type: "xã",
    codename: "xa_ban_cai",
    district_code: 85,
  },
  {
    name: "Thị trấn N.T Phong Hải",
    code: 2902,
    division_type: "thị trấn",
    codename: "thi_tran_n_t_phong_hai",
    district_code: 86,
  },
  {
    name: "Thị trấn Phố Lu",
    code: 2905,
    division_type: "thị trấn",
    codename: "thi_tran_pho_lu",
    district_code: 86,
  },
  {
    name: "Thị trấn Tằng Loỏng",
    code: 2908,
    division_type: "thị trấn",
    codename: "thi_tran_tang_loong",
    district_code: 86,
  },
  {
    name: "Xã Bản Phiệt",
    code: 2911,
    division_type: "xã",
    codename: "xa_ban_phiet",
    district_code: 86,
  },
  {
    name: "Xã Bản Cầm",
    code: 2914,
    division_type: "xã",
    codename: "xa_ban_cam",
    district_code: 86,
  },
  {
    name: "Xã Thái Niên",
    code: 2917,
    division_type: "xã",
    codename: "xa_thai_nien",
    district_code: 86,
  },
  {
    name: "Xã Phong Niên",
    code: 2920,
    division_type: "xã",
    codename: "xa_phong_nien",
    district_code: 86,
  },
  {
    name: "Xã Gia Phú",
    code: 2923,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 86,
  },
  {
    name: "Xã Xuân Quang",
    code: 2926,
    division_type: "xã",
    codename: "xa_xuan_quang",
    district_code: 86,
  },
  {
    name: "Xã Sơn Hải",
    code: 2929,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 86,
  },
  {
    name: "Xã Xuân Giao",
    code: 2932,
    division_type: "xã",
    codename: "xa_xuan_giao",
    district_code: 86,
  },
  {
    name: "Xã Trì Quang",
    code: 2935,
    division_type: "xã",
    codename: "xa_tri_quang",
    district_code: 86,
  },
  {
    name: "Xã Sơn Hà",
    code: 2938,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 86,
  },
  {
    name: "Xã Phú Nhuận",
    code: 2944,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 86,
  },
  {
    name: "Thị trấn Phố Ràng",
    code: 2947,
    division_type: "thị trấn",
    codename: "thi_tran_pho_rang",
    district_code: 87,
  },
  {
    name: "Xã Tân Tiến",
    code: 2950,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 87,
  },
  {
    name: "Xã Nghĩa Đô",
    code: 2953,
    division_type: "xã",
    codename: "xa_nghia_do",
    district_code: 87,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 2956,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 87,
  },
  {
    name: "Xã Điện Quan",
    code: 2959,
    division_type: "xã",
    codename: "xa_dien_quan",
    district_code: 87,
  },
  {
    name: "Xã Xuân Hoà",
    code: 2962,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 87,
  },
  {
    name: "Xã Tân Dương",
    code: 2965,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 87,
  },
  {
    name: "Xã Thượng Hà",
    code: 2968,
    division_type: "xã",
    codename: "xa_thuong_ha",
    district_code: 87,
  },
  {
    name: "Xã Kim Sơn",
    code: 2971,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 87,
  },
  {
    name: "Xã Cam Cọn",
    code: 2974,
    division_type: "xã",
    codename: "xa_cam_con",
    district_code: 87,
  },
  {
    name: "Xã Minh Tân",
    code: 2977,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 87,
  },
  {
    name: "Xã Xuân Thượng",
    code: 2980,
    division_type: "xã",
    codename: "xa_xuan_thuong",
    district_code: 87,
  },
  {
    name: "Xã Việt Tiến",
    code: 2983,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 87,
  },
  {
    name: "Xã Yên Sơn",
    code: 2986,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 87,
  },
  {
    name: "Xã Bảo Hà",
    code: 2989,
    division_type: "xã",
    codename: "xa_bao_ha",
    district_code: 87,
  },
  {
    name: "Xã Lương Sơn",
    code: 2992,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 87,
  },
  {
    name: "Xã Phúc Khánh",
    code: 2998,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 87,
  },
  {
    name: "Phường Sa Pa",
    code: 3001,
    division_type: "phường",
    codename: "phuong_sa_pa",
    district_code: 88,
  },
  {
    name: "Phường Sa Pả",
    code: 3002,
    division_type: "phường",
    codename: "phuong_sa_pa",
    district_code: 88,
  },
  {
    name: "Phường Ô Quý Hồ",
    code: 3003,
    division_type: "phường",
    codename: "phuong_o_quy_ho",
    district_code: 88,
  },
  {
    name: "Xã Ngũ Chỉ Sơn",
    code: 3004,
    division_type: "xã",
    codename: "xa_ngu_chi_son",
    district_code: 88,
  },
  {
    name: "Phường Phan Si Păng",
    code: 3006,
    division_type: "phường",
    codename: "phuong_phan_si_pang",
    district_code: 88,
  },
  {
    name: "Xã Trung Chải",
    code: 3010,
    division_type: "xã",
    codename: "xa_trung_chai",
    district_code: 88,
  },
  {
    name: "Xã Tả Phìn",
    code: 3013,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 88,
  },
  {
    name: "Phường Hàm Rồng",
    code: 3016,
    division_type: "phường",
    codename: "phuong_ham_rong",
    district_code: 88,
  },
  {
    name: "Xã Hoàng Liên",
    code: 3019,
    division_type: "xã",
    codename: "xa_hoang_lien",
    district_code: 88,
  },
  {
    name: "Xã Thanh Bình",
    code: 3022,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 88,
  },
  {
    name: "Phường Cầu Mây",
    code: 3028,
    division_type: "phường",
    codename: "phuong_cau_may",
    district_code: 88,
  },
  {
    name: "Xã Mường Hoa",
    code: 3037,
    division_type: "xã",
    codename: "xa_muong_hoa",
    district_code: 88,
  },
  {
    name: "Xã Tả Van",
    code: 3040,
    division_type: "xã",
    codename: "xa_ta_van",
    district_code: 88,
  },
  {
    name: "Xã Mường Bo",
    code: 3043,
    division_type: "xã",
    codename: "xa_muong_bo",
    district_code: 88,
  },
  {
    name: "Xã Bản Hồ",
    code: 3046,
    division_type: "xã",
    codename: "xa_ban_ho",
    district_code: 88,
  },
  {
    name: "Xã Liên Minh",
    code: 3052,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 88,
  },
  {
    name: "Thị trấn Khánh Yên",
    code: 3055,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_yen",
    district_code: 89,
  },
  {
    name: "Xã Võ Lao",
    code: 3061,
    division_type: "xã",
    codename: "xa_vo_lao",
    district_code: 89,
  },
  {
    name: "Xã Sơn Thuỷ",
    code: 3064,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 89,
  },
  {
    name: "Xã Nậm Mả",
    code: 3067,
    division_type: "xã",
    codename: "xa_nam_ma",
    district_code: 89,
  },
  {
    name: "Xã Tân Thượng",
    code: 3070,
    division_type: "xã",
    codename: "xa_tan_thuong",
    district_code: 89,
  },
  {
    name: "Xã Nậm Rạng",
    code: 3073,
    division_type: "xã",
    codename: "xa_nam_rang",
    district_code: 89,
  },
  {
    name: "Xã Nậm Chầy",
    code: 3076,
    division_type: "xã",
    codename: "xa_nam_chay",
    district_code: 89,
  },
  {
    name: "Xã Tân An",
    code: 3079,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Thượng",
    code: 3082,
    division_type: "xã",
    codename: "xa_khanh_yen_thuong",
    district_code: 89,
  },
  {
    name: "Xã Nậm Xé",
    code: 3085,
    division_type: "xã",
    codename: "xa_nam_xe",
    district_code: 89,
  },
  {
    name: "Xã Dần Thàng",
    code: 3088,
    division_type: "xã",
    codename: "xa_dan_thang",
    district_code: 89,
  },
  {
    name: "Xã Chiềng Ken",
    code: 3091,
    division_type: "xã",
    codename: "xa_chieng_ken",
    district_code: 89,
  },
  {
    name: "Xã Làng Giàng",
    code: 3094,
    division_type: "xã",
    codename: "xa_lang_giang",
    district_code: 89,
  },
  {
    name: "Xã Hoà Mạc",
    code: 3097,
    division_type: "xã",
    codename: "xa_hoa_mac",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Trung",
    code: 3100,
    division_type: "xã",
    codename: "xa_khanh_yen_trung",
    district_code: 89,
  },
  {
    name: "Xã Khánh Yên Hạ",
    code: 3103,
    division_type: "xã",
    codename: "xa_khanh_yen_ha",
    district_code: 89,
  },
  {
    name: "Xã Dương Quỳ",
    code: 3106,
    division_type: "xã",
    codename: "xa_duong_quy",
    district_code: 89,
  },
  {
    name: "Xã Nậm Tha",
    code: 3109,
    division_type: "xã",
    codename: "xa_nam_tha",
    district_code: 89,
  },
  {
    name: "Xã Minh Lương",
    code: 3112,
    division_type: "xã",
    codename: "xa_minh_luong",
    district_code: 89,
  },
  {
    name: "Xã Thẩm Dương",
    code: 3115,
    division_type: "xã",
    codename: "xa_tham_duong",
    district_code: 89,
  },
  {
    name: "Xã Liêm Phú",
    code: 3118,
    division_type: "xã",
    codename: "xa_liem_phu",
    district_code: 89,
  },
  {
    name: "Xã Nậm Xây",
    code: 3121,
    division_type: "xã",
    codename: "xa_nam_xay",
    district_code: 89,
  },
  {
    name: "Phường Noong Bua",
    code: 3124,
    division_type: "phường",
    codename: "phuong_noong_bua",
    district_code: 94,
  },
  {
    name: "Phường Him Lam",
    code: 3127,
    division_type: "phường",
    codename: "phuong_him_lam",
    district_code: 94,
  },
  {
    name: "Phường Thanh Bình",
    code: 3130,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 94,
  },
  {
    name: "Phường Tân Thanh",
    code: 3133,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 94,
  },
  {
    name: "Phường Mường Thanh",
    code: 3136,
    division_type: "phường",
    codename: "phuong_muong_thanh",
    district_code: 94,
  },
  {
    name: "Phường Nam Thanh",
    code: 3139,
    division_type: "phường",
    codename: "phuong_nam_thanh",
    district_code: 94,
  },
  {
    name: "Phường Thanh Trường",
    code: 3142,
    division_type: "phường",
    codename: "phuong_thanh_truong",
    district_code: 94,
  },
  {
    name: "Xã Thanh Minh",
    code: 3145,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 94,
  },
  {
    name: "Xã Nà Tấu",
    code: 3316,
    division_type: "xã",
    codename: "xa_na_tau",
    district_code: 94,
  },
  {
    name: "Xã Nà Nhạn",
    code: 3317,
    division_type: "xã",
    codename: "xa_na_nhan",
    district_code: 94,
  },
  {
    name: "Xã Mường Phăng",
    code: 3325,
    division_type: "xã",
    codename: "xa_muong_phang",
    district_code: 94,
  },
  {
    name: "Xã Pá Khoang",
    code: 3326,
    division_type: "xã",
    codename: "xa_pa_khoang",
    district_code: 94,
  },
  {
    name: "Phường Sông Đà",
    code: 3148,
    division_type: "phường",
    codename: "phuong_song_da",
    district_code: 95,
  },
  {
    name: "Phường Na Lay",
    code: 3151,
    division_type: "phường",
    codename: "phuong_na_lay",
    district_code: 95,
  },
  {
    name: "Xã Lay Nưa",
    code: 3184,
    division_type: "xã",
    codename: "xa_lay_nua",
    district_code: 95,
  },
  {
    name: "Xã Sín Thầu",
    code: 3154,
    division_type: "xã",
    codename: "xa_sin_thau",
    district_code: 96,
  },
  {
    name: "Xã Sen Thượng",
    code: 3155,
    division_type: "xã",
    codename: "xa_sen_thuong",
    district_code: 96,
  },
  {
    name: "Xã Chung Chải",
    code: 3157,
    division_type: "xã",
    codename: "xa_chung_chai",
    district_code: 96,
  },
  {
    name: "Xã Leng Su Sìn",
    code: 3158,
    division_type: "xã",
    codename: "xa_leng_su_sin",
    district_code: 96,
  },
  {
    name: "Xã Pá Mỳ",
    code: 3159,
    division_type: "xã",
    codename: "xa_pa_my",
    district_code: 96,
  },
  {
    name: "Xã Mường Nhé",
    code: 3160,
    division_type: "xã",
    codename: "xa_muong_nhe",
    district_code: 96,
  },
  {
    name: "Xã Nậm Vì",
    code: 3161,
    division_type: "xã",
    codename: "xa_nam_vi",
    district_code: 96,
  },
  {
    name: "Xã Nậm Kè",
    code: 3162,
    division_type: "xã",
    codename: "xa_nam_ke",
    district_code: 96,
  },
  {
    name: "Xã Mường Toong",
    code: 3163,
    division_type: "xã",
    codename: "xa_muong_toong",
    district_code: 96,
  },
  {
    name: "Xã Quảng Lâm",
    code: 3164,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 96,
  },
  {
    name: "Xã Huổi Lếnh",
    code: 3177,
    division_type: "xã",
    codename: "xa_huoi_lenh",
    district_code: 96,
  },
  {
    name: "Thị trấn Mường Chà",
    code: 3172,
    division_type: "thị trấn",
    codename: "thi_tran_muong_cha",
    district_code: 97,
  },
  {
    name: "Xã Xá Tổng",
    code: 3178,
    division_type: "xã",
    codename: "xa_xa_tong",
    district_code: 97,
  },
  {
    name: "Xã Mường Tùng",
    code: 3181,
    division_type: "xã",
    codename: "xa_muong_tung",
    district_code: 97,
  },
  {
    name: "Xã Hừa Ngài",
    code: 3190,
    division_type: "xã",
    codename: "xa_hua_ngai",
    district_code: 97,
  },
  {
    name: "Xã Huổi Mí",
    code: 3191,
    division_type: "xã",
    codename: "xa_huoi_mi",
    district_code: 97,
  },
  {
    name: "Xã Pa Ham",
    code: 3193,
    division_type: "xã",
    codename: "xa_pa_ham",
    district_code: 97,
  },
  {
    name: "Xã Nậm Nèn",
    code: 3194,
    division_type: "xã",
    codename: "xa_nam_nen",
    district_code: 97,
  },
  {
    name: "Xã Huổi Lèng",
    code: 3196,
    division_type: "xã",
    codename: "xa_huoi_leng",
    district_code: 97,
  },
  {
    name: "Xã Sa Lông",
    code: 3197,
    division_type: "xã",
    codename: "xa_sa_long",
    district_code: 97,
  },
  {
    name: "Xã Ma Thì Hồ",
    code: 3200,
    division_type: "xã",
    codename: "xa_ma_thi_ho",
    district_code: 97,
  },
  {
    name: "Xã Na Sang",
    code: 3201,
    division_type: "xã",
    codename: "xa_na_sang",
    district_code: 97,
  },
  {
    name: "Xã Mường Mươn",
    code: 3202,
    division_type: "xã",
    codename: "xa_muong_muon",
    district_code: 97,
  },
  {
    name: "Thị trấn Tủa Chùa",
    code: 3217,
    division_type: "thị trấn",
    codename: "thi_tran_tua_chua",
    district_code: 98,
  },
  {
    name: "Xã Huổi Só",
    code: 3220,
    division_type: "xã",
    codename: "xa_huoi_so",
    district_code: 98,
  },
  {
    name: "Xã Xín Chải",
    code: 3223,
    division_type: "xã",
    codename: "xa_xin_chai",
    district_code: 98,
  },
  {
    name: "Xã Tả Sìn Thàng",
    code: 3226,
    division_type: "xã",
    codename: "xa_ta_sin_thang",
    district_code: 98,
  },
  {
    name: "Xã Lao Xả Phình",
    code: 3229,
    division_type: "xã",
    codename: "xa_lao_xa_phinh",
    district_code: 98,
  },
  {
    name: "Xã Tả Phìn",
    code: 3232,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 98,
  },
  {
    name: "Xã Tủa Thàng",
    code: 3235,
    division_type: "xã",
    codename: "xa_tua_thang",
    district_code: 98,
  },
  {
    name: "Xã Trung Thu",
    code: 3238,
    division_type: "xã",
    codename: "xa_trung_thu",
    district_code: 98,
  },
  {
    name: "Xã Sính Phình",
    code: 3241,
    division_type: "xã",
    codename: "xa_sinh_phinh",
    district_code: 98,
  },
  {
    name: "Xã Sáng Nhè",
    code: 3244,
    division_type: "xã",
    codename: "xa_sang_nhe",
    district_code: 98,
  },
  {
    name: "Xã Mường Đun",
    code: 3247,
    division_type: "xã",
    codename: "xa_muong_dun",
    district_code: 98,
  },
  {
    name: "Xã Mường Báng",
    code: 3250,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 98,
  },
  {
    name: "Thị trấn Tuần Giáo",
    code: 3253,
    division_type: "thị trấn",
    codename: "thi_tran_tuan_giao",
    district_code: 99,
  },
  {
    name: "Xã Phình Sáng",
    code: 3259,
    division_type: "xã",
    codename: "xa_phinh_sang",
    district_code: 99,
  },
  {
    name: "Xã Rạng Đông",
    code: 3260,
    division_type: "xã",
    codename: "xa_rang_dong",
    district_code: 99,
  },
  {
    name: "Xã Mùn Chung",
    code: 3262,
    division_type: "xã",
    codename: "xa_mun_chung",
    district_code: 99,
  },
  {
    name: "Xã Nà Tòng",
    code: 3263,
    division_type: "xã",
    codename: "xa_na_tong",
    district_code: 99,
  },
  {
    name: "Xã Ta Ma",
    code: 3265,
    division_type: "xã",
    codename: "xa_ta_ma",
    district_code: 99,
  },
  {
    name: "Xã Mường Mùn",
    code: 3268,
    division_type: "xã",
    codename: "xa_muong_mun",
    district_code: 99,
  },
  {
    name: "Xã Pú Xi",
    code: 3269,
    division_type: "xã",
    codename: "xa_pu_xi",
    district_code: 99,
  },
  {
    name: "Xã Pú Nhung",
    code: 3271,
    division_type: "xã",
    codename: "xa_pu_nhung",
    district_code: 99,
  },
  {
    name: "Xã Quài Nưa",
    code: 3274,
    division_type: "xã",
    codename: "xa_quai_nua",
    district_code: 99,
  },
  {
    name: "Xã Mường Thín",
    code: 3277,
    division_type: "xã",
    codename: "xa_muong_thin",
    district_code: 99,
  },
  {
    name: "Xã Tỏa Tình",
    code: 3280,
    division_type: "xã",
    codename: "xa_toa_tinh",
    district_code: 99,
  },
  {
    name: "Xã Nà Sáy",
    code: 3283,
    division_type: "xã",
    codename: "xa_na_say",
    district_code: 99,
  },
  {
    name: "Xã Mường Khong",
    code: 3284,
    division_type: "xã",
    codename: "xa_muong_khong",
    district_code: 99,
  },
  {
    name: "Xã Quài Cang",
    code: 3289,
    division_type: "xã",
    codename: "xa_quai_cang",
    district_code: 99,
  },
  {
    name: "Xã Quài Tở",
    code: 3295,
    division_type: "xã",
    codename: "xa_quai_to",
    district_code: 99,
  },
  {
    name: "Xã Chiềng Sinh",
    code: 3298,
    division_type: "xã",
    codename: "xa_chieng_sinh",
    district_code: 99,
  },
  {
    name: "Xã Chiềng Đông",
    code: 3299,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 99,
  },
  {
    name: "Xã Tênh Phông",
    code: 3304,
    division_type: "xã",
    codename: "xa_tenh_phong",
    district_code: 99,
  },
  {
    name: "Xã Mường Pồn",
    code: 3319,
    division_type: "xã",
    codename: "xa_muong_pon",
    district_code: 100,
  },
  {
    name: "Xã Thanh Nưa",
    code: 3322,
    division_type: "xã",
    codename: "xa_thanh_nua",
    district_code: 100,
  },
  {
    name: "Xã Hua Thanh",
    code: 3323,
    division_type: "xã",
    codename: "xa_hua_thanh",
    district_code: 100,
  },
  {
    name: "Xã Thanh Luông",
    code: 3328,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 100,
  },
  {
    name: "Xã Thanh Hưng",
    code: 3331,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 100,
  },
  {
    name: "Xã Thanh Xương",
    code: 3334,
    division_type: "xã",
    codename: "xa_thanh_xuong",
    district_code: 100,
  },
  {
    name: "Xã Thanh Chăn",
    code: 3337,
    division_type: "xã",
    codename: "xa_thanh_chan",
    district_code: 100,
  },
  {
    name: "Xã Pa Thơm",
    code: 3340,
    division_type: "xã",
    codename: "xa_pa_thom",
    district_code: 100,
  },
  {
    name: "Xã Thanh An",
    code: 3343,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 100,
  },
  {
    name: "Xã Thanh Yên",
    code: 3346,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 100,
  },
  {
    name: "Xã Noong Luống",
    code: 3349,
    division_type: "xã",
    codename: "xa_noong_luong",
    district_code: 100,
  },
  {
    name: "Xã Noọng Hẹt",
    code: 3352,
    division_type: "xã",
    codename: "xa_noong_het",
    district_code: 100,
  },
  {
    name: "Xã Sam Mứn",
    code: 3355,
    division_type: "xã",
    codename: "xa_sam_mun",
    district_code: 100,
  },
  {
    name: "Xã Pom Lót",
    code: 3356,
    division_type: "xã",
    codename: "xa_pom_lot",
    district_code: 100,
  },
  {
    name: "Xã Núa Ngam",
    code: 3358,
    division_type: "xã",
    codename: "xa_nua_ngam",
    district_code: 100,
  },
  {
    name: "Xã Hẹ Muông",
    code: 3359,
    division_type: "xã",
    codename: "xa_he_muong",
    district_code: 100,
  },
  {
    name: "Xã Na Ư",
    code: 3361,
    division_type: "xã",
    codename: "xa_na_u",
    district_code: 100,
  },
  {
    name: "Xã Mường Nhà",
    code: 3364,
    division_type: "xã",
    codename: "xa_muong_nha",
    district_code: 100,
  },
  {
    name: "Xã Na Tông",
    code: 3365,
    division_type: "xã",
    codename: "xa_na_tong",
    district_code: 100,
  },
  {
    name: "Xã Mường Lói",
    code: 3367,
    division_type: "xã",
    codename: "xa_muong_loi",
    district_code: 100,
  },
  {
    name: "Xã Phu Luông",
    code: 3368,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 100,
  },
  {
    name: "Thị trấn Điện Biên Đông",
    code: 3203,
    division_type: "thị trấn",
    codename: "thi_tran_dien_bien_dong",
    district_code: 101,
  },
  {
    name: "Xã Na Son",
    code: 3205,
    division_type: "xã",
    codename: "xa_na_son",
    district_code: 101,
  },
  {
    name: "Xã Phì Nhừ",
    code: 3208,
    division_type: "xã",
    codename: "xa_phi_nhu",
    district_code: 101,
  },
  {
    name: "Xã Chiềng Sơ",
    code: 3211,
    division_type: "xã",
    codename: "xa_chieng_so",
    district_code: 101,
  },
  {
    name: "Xã Mường Luân",
    code: 3214,
    division_type: "xã",
    codename: "xa_muong_luan",
    district_code: 101,
  },
  {
    name: "Xã Pú Nhi",
    code: 3370,
    division_type: "xã",
    codename: "xa_pu_nhi",
    district_code: 101,
  },
  {
    name: "Xã Nong U",
    code: 3371,
    division_type: "xã",
    codename: "xa_nong_u",
    district_code: 101,
  },
  {
    name: "Xã Xa Dung",
    code: 3373,
    division_type: "xã",
    codename: "xa_xa_dung",
    district_code: 101,
  },
  {
    name: "Xã Keo Lôm",
    code: 3376,
    division_type: "xã",
    codename: "xa_keo_lom",
    district_code: 101,
  },
  {
    name: "Xã Luân Giới",
    code: 3379,
    division_type: "xã",
    codename: "xa_luan_gioi",
    district_code: 101,
  },
  {
    name: "Xã Phình Giàng",
    code: 3382,
    division_type: "xã",
    codename: "xa_phinh_giang",
    district_code: 101,
  },
  {
    name: "Xã Pú Hồng",
    code: 3383,
    division_type: "xã",
    codename: "xa_pu_hong",
    district_code: 101,
  },
  {
    name: "Xã Tìa Dình",
    code: 3384,
    division_type: "xã",
    codename: "xa_tia_dinh",
    district_code: 101,
  },
  {
    name: "Xã Háng Lìa",
    code: 3385,
    division_type: "xã",
    codename: "xa_hang_lia",
    district_code: 101,
  },
  {
    name: "Thị trấn Mường Ảng",
    code: 3256,
    division_type: "thị trấn",
    codename: "thi_tran_muong_ang",
    district_code: 102,
  },
  {
    name: "Xã Mường Đăng",
    code: 3286,
    division_type: "xã",
    codename: "xa_muong_dang",
    district_code: 102,
  },
  {
    name: "Xã Ngối Cáy",
    code: 3287,
    division_type: "xã",
    codename: "xa_ngoi_cay",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Tở",
    code: 3292,
    division_type: "xã",
    codename: "xa_ang_to",
    district_code: 102,
  },
  {
    name: "Xã Búng Lao",
    code: 3301,
    division_type: "xã",
    codename: "xa_bung_lao",
    district_code: 102,
  },
  {
    name: "Xã Xuân Lao",
    code: 3302,
    division_type: "xã",
    codename: "xa_xuan_lao",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Nưa",
    code: 3307,
    division_type: "xã",
    codename: "xa_ang_nua",
    district_code: 102,
  },
  {
    name: "Xã Ẳng Cang",
    code: 3310,
    division_type: "xã",
    codename: "xa_ang_cang",
    district_code: 102,
  },
  {
    name: "Xã Nặm Lịch",
    code: 3312,
    division_type: "xã",
    codename: "xa_nam_lich",
    district_code: 102,
  },
  {
    name: "Xã Mường Lạn",
    code: 3313,
    division_type: "xã",
    codename: "xa_muong_lan",
    district_code: 102,
  },
  {
    name: "Xã Nậm Tin",
    code: 3156,
    division_type: "xã",
    codename: "xa_nam_tin",
    district_code: 103,
  },
  {
    name: "Xã Pa Tần",
    code: 3165,
    division_type: "xã",
    codename: "xa_pa_tan",
    district_code: 103,
  },
  {
    name: "Xã Chà Cang",
    code: 3166,
    division_type: "xã",
    codename: "xa_cha_cang",
    district_code: 103,
  },
  {
    name: "Xã Na Cô Sa",
    code: 3167,
    division_type: "xã",
    codename: "xa_na_co_sa",
    district_code: 103,
  },
  {
    name: "Xã Nà Khoa",
    code: 3168,
    division_type: "xã",
    codename: "xa_na_khoa",
    district_code: 103,
  },
  {
    name: "Xã Nà Hỳ",
    code: 3169,
    division_type: "xã",
    codename: "xa_na_hy",
    district_code: 103,
  },
  {
    name: "Xã Nà Bủng",
    code: 3170,
    division_type: "xã",
    codename: "xa_na_bung",
    district_code: 103,
  },
  {
    name: "Xã Nậm Nhừ",
    code: 3171,
    division_type: "xã",
    codename: "xa_nam_nhu",
    district_code: 103,
  },
  {
    name: "Xã Nậm Chua",
    code: 3173,
    division_type: "xã",
    codename: "xa_nam_chua",
    district_code: 103,
  },
  {
    name: "Xã Nậm Khăn",
    code: 3174,
    division_type: "xã",
    codename: "xa_nam_khan",
    district_code: 103,
  },
  {
    name: "Xã Chà Tở",
    code: 3175,
    division_type: "xã",
    codename: "xa_cha_to",
    district_code: 103,
  },
  {
    name: "Xã Vàng Đán",
    code: 3176,
    division_type: "xã",
    codename: "xa_vang_dan",
    district_code: 103,
  },
  {
    name: "Xã Chà Nưa",
    code: 3187,
    division_type: "xã",
    codename: "xa_cha_nua",
    district_code: 103,
  },
  {
    name: "Xã Phìn Hồ",
    code: 3198,
    division_type: "xã",
    codename: "xa_phin_ho",
    district_code: 103,
  },
  {
    name: "Xã Si Pa Phìn",
    code: 3199,
    division_type: "xã",
    codename: "xa_si_pa_phin",
    district_code: 103,
  },
  {
    name: "Phường Quyết Thắng",
    code: 3386,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 105,
  },
  {
    name: "Phường Tân Phong",
    code: 3387,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 105,
  },
  {
    name: "Phường Quyết Tiến",
    code: 3388,
    division_type: "phường",
    codename: "phuong_quyet_tien",
    district_code: 105,
  },
  {
    name: "Phường Đoàn Kết",
    code: 3389,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 105,
  },
  {
    name: "Xã Sùng Phài",
    code: 3403,
    division_type: "xã",
    codename: "xa_sung_phai",
    district_code: 105,
  },
  {
    name: "Phường Đông Phong",
    code: 3408,
    division_type: "phường",
    codename: "phuong_dong_phong",
    district_code: 105,
  },
  {
    name: "Xã San Thàng",
    code: 3409,
    division_type: "xã",
    codename: "xa_san_thang",
    district_code: 105,
  },
  {
    name: "Thị trấn Tam Đường",
    code: 3390,
    division_type: "thị trấn",
    codename: "thi_tran_tam_duong",
    district_code: 106,
  },
  {
    name: "Xã Thèn Sin",
    code: 3394,
    division_type: "xã",
    codename: "xa_then_sin",
    district_code: 106,
  },
  {
    name: "Xã Tả Lèng",
    code: 3400,
    division_type: "xã",
    codename: "xa_ta_leng",
    district_code: 106,
  },
  {
    name: "Xã Giang Ma",
    code: 3405,
    division_type: "xã",
    codename: "xa_giang_ma",
    district_code: 106,
  },
  {
    name: "Xã Hồ Thầu",
    code: 3406,
    division_type: "xã",
    codename: "xa_ho_thau",
    district_code: 106,
  },
  {
    name: "Xã Bình Lư",
    code: 3412,
    division_type: "xã",
    codename: "xa_binh_lu",
    district_code: 106,
  },
  {
    name: "Xã Sơn Bình",
    code: 3413,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 106,
  },
  {
    name: "Xã Nùng Nàng",
    code: 3415,
    division_type: "xã",
    codename: "xa_nung_nang",
    district_code: 106,
  },
  {
    name: "Xã Bản Giang",
    code: 3418,
    division_type: "xã",
    codename: "xa_ban_giang",
    district_code: 106,
  },
  {
    name: "Xã Bản Hon",
    code: 3421,
    division_type: "xã",
    codename: "xa_ban_hon",
    district_code: 106,
  },
  {
    name: "Xã Bản Bo",
    code: 3424,
    division_type: "xã",
    codename: "xa_ban_bo",
    district_code: 106,
  },
  {
    name: "Xã Nà Tăm",
    code: 3427,
    division_type: "xã",
    codename: "xa_na_tam",
    district_code: 106,
  },
  {
    name: "Xã Khun Há",
    code: 3430,
    division_type: "xã",
    codename: "xa_khun_ha",
    district_code: 106,
  },
  {
    name: "Thị trấn Mường Tè",
    code: 3433,
    division_type: "thị trấn",
    codename: "thi_tran_muong_te",
    district_code: 107,
  },
  {
    name: "Xã Thu Lũm",
    code: 3436,
    division_type: "xã",
    codename: "xa_thu_lum",
    district_code: 107,
  },
  {
    name: "Xã Ka Lăng",
    code: 3439,
    division_type: "xã",
    codename: "xa_ka_lang",
    district_code: 107,
  },
  {
    name: "Xã Tá Bạ",
    code: 3440,
    division_type: "xã",
    codename: "xa_ta_ba",
    district_code: 107,
  },
  {
    name: "Xã Pa ủ",
    code: 3442,
    division_type: "xã",
    codename: "xa_pa_u",
    district_code: 107,
  },
  {
    name: "Xã Mường Tè",
    code: 3445,
    division_type: "xã",
    codename: "xa_muong_te",
    district_code: 107,
  },
  {
    name: "Xã Pa Vệ Sử",
    code: 3448,
    division_type: "xã",
    codename: "xa_pa_ve_su",
    district_code: 107,
  },
  {
    name: "Xã Mù Cả",
    code: 3451,
    division_type: "xã",
    codename: "xa_mu_ca",
    district_code: 107,
  },
  {
    name: "Xã Bum Tở",
    code: 3454,
    division_type: "xã",
    codename: "xa_bum_to",
    district_code: 107,
  },
  {
    name: "Xã Nậm Khao",
    code: 3457,
    division_type: "xã",
    codename: "xa_nam_khao",
    district_code: 107,
  },
  {
    name: "Xã Tà Tổng",
    code: 3463,
    division_type: "xã",
    codename: "xa_ta_tong",
    district_code: 107,
  },
  {
    name: "Xã Bum Nưa",
    code: 3466,
    division_type: "xã",
    codename: "xa_bum_nua",
    district_code: 107,
  },
  {
    name: "Xã Vàng San",
    code: 3467,
    division_type: "xã",
    codename: "xa_vang_san",
    district_code: 107,
  },
  {
    name: "Xã Kan Hồ",
    code: 3469,
    division_type: "xã",
    codename: "xa_kan_ho",
    district_code: 107,
  },
  {
    name: "Thị trấn Sìn Hồ",
    code: 3478,
    division_type: "thị trấn",
    codename: "thi_tran_sin_ho",
    district_code: 108,
  },
  {
    name: "Xã Chăn Nưa",
    code: 3487,
    division_type: "xã",
    codename: "xa_chan_nua",
    district_code: 108,
  },
  {
    name: "Xã Pa Tần",
    code: 3493,
    division_type: "xã",
    codename: "xa_pa_tan",
    district_code: 108,
  },
  {
    name: "Xã Phìn Hồ",
    code: 3496,
    division_type: "xã",
    codename: "xa_phin_ho",
    district_code: 108,
  },
  {
    name: "Xã Hồng Thu",
    code: 3499,
    division_type: "xã",
    codename: "xa_hong_thu",
    district_code: 108,
  },
  {
    name: "Xã Phăng Sô Lin",
    code: 3505,
    division_type: "xã",
    codename: "xa_phang_so_lin",
    district_code: 108,
  },
  {
    name: "Xã Ma Quai",
    code: 3508,
    division_type: "xã",
    codename: "xa_ma_quai",
    district_code: 108,
  },
  {
    name: "Xã Lùng Thàng",
    code: 3509,
    division_type: "xã",
    codename: "xa_lung_thang",
    district_code: 108,
  },
  {
    name: "Xã Tả Phìn",
    code: 3511,
    division_type: "xã",
    codename: "xa_ta_phin",
    district_code: 108,
  },
  {
    name: "Xã Sà Dề Phìn",
    code: 3514,
    division_type: "xã",
    codename: "xa_sa_de_phin",
    district_code: 108,
  },
  {
    name: "Xã Nậm Tăm",
    code: 3517,
    division_type: "xã",
    codename: "xa_nam_tam",
    district_code: 108,
  },
  {
    name: "Xã Tả Ngảo",
    code: 3520,
    division_type: "xã",
    codename: "xa_ta_ngao",
    district_code: 108,
  },
  {
    name: "Xã Pu Sam Cáp",
    code: 3523,
    division_type: "xã",
    codename: "xa_pu_sam_cap",
    district_code: 108,
  },
  {
    name: "Xã Nậm Cha",
    code: 3526,
    division_type: "xã",
    codename: "xa_nam_cha",
    district_code: 108,
  },
  {
    name: "Xã Pa Khoá",
    code: 3527,
    division_type: "xã",
    codename: "xa_pa_khoa",
    district_code: 108,
  },
  {
    name: "Xã Làng Mô",
    code: 3529,
    division_type: "xã",
    codename: "xa_lang_mo",
    district_code: 108,
  },
  {
    name: "Xã Noong Hẻo",
    code: 3532,
    division_type: "xã",
    codename: "xa_noong_heo",
    district_code: 108,
  },
  {
    name: "Xã Nậm Mạ",
    code: 3535,
    division_type: "xã",
    codename: "xa_nam_ma",
    district_code: 108,
  },
  {
    name: "Xã Căn Co",
    code: 3538,
    division_type: "xã",
    codename: "xa_can_co",
    district_code: 108,
  },
  {
    name: "Xã Tủa Sín Chải",
    code: 3541,
    division_type: "xã",
    codename: "xa_tua_sin_chai",
    district_code: 108,
  },
  {
    name: "Xã Nậm Cuổi",
    code: 3544,
    division_type: "xã",
    codename: "xa_nam_cuoi",
    district_code: 108,
  },
  {
    name: "Xã Nậm Hăn",
    code: 3547,
    division_type: "xã",
    codename: "xa_nam_han",
    district_code: 108,
  },
  {
    name: "Xã Lả Nhì Thàng",
    code: 3391,
    division_type: "xã",
    codename: "xa_la_nhi_thang",
    district_code: 109,
  },
  {
    name: "Xã Huổi Luông",
    code: 3490,
    division_type: "xã",
    codename: "xa_huoi_luong",
    district_code: 109,
  },
  {
    name: "Thị trấn Phong Thổ",
    code: 3549,
    division_type: "thị trấn",
    codename: "thi_tran_phong_tho",
    district_code: 109,
  },
  {
    name: "Xã Sì Lở Lầu",
    code: 3550,
    division_type: "xã",
    codename: "xa_si_lo_lau",
    district_code: 109,
  },
  {
    name: "Xã Mồ Sì San",
    code: 3553,
    division_type: "xã",
    codename: "xa_mo_si_san",
    district_code: 109,
  },
  {
    name: "Xã Pa Vây Sử",
    code: 3559,
    division_type: "xã",
    codename: "xa_pa_vay_su",
    district_code: 109,
  },
  {
    name: "Xã Vàng Ma Chải",
    code: 3562,
    division_type: "xã",
    codename: "xa_vang_ma_chai",
    district_code: 109,
  },
  {
    name: "Xã Tông Qua Lìn",
    code: 3565,
    division_type: "xã",
    codename: "xa_tong_qua_lin",
    district_code: 109,
  },
  {
    name: "Xã Mù Sang",
    code: 3568,
    division_type: "xã",
    codename: "xa_mu_sang",
    district_code: 109,
  },
  {
    name: "Xã Dào San",
    code: 3571,
    division_type: "xã",
    codename: "xa_dao_san",
    district_code: 109,
  },
  {
    name: "Xã Ma Ly Pho",
    code: 3574,
    division_type: "xã",
    codename: "xa_ma_ly_pho",
    district_code: 109,
  },
  {
    name: "Xã Bản Lang",
    code: 3577,
    division_type: "xã",
    codename: "xa_ban_lang",
    district_code: 109,
  },
  {
    name: "Xã Hoang Thèn",
    code: 3580,
    division_type: "xã",
    codename: "xa_hoang_then",
    district_code: 109,
  },
  {
    name: "Xã Khổng Lào",
    code: 3583,
    division_type: "xã",
    codename: "xa_khong_lao",
    district_code: 109,
  },
  {
    name: "Xã Nậm Xe",
    code: 3586,
    division_type: "xã",
    codename: "xa_nam_xe",
    district_code: 109,
  },
  {
    name: "Xã Mường So",
    code: 3589,
    division_type: "xã",
    codename: "xa_muong_so",
    district_code: 109,
  },
  {
    name: "Xã Sin Suối Hồ",
    code: 3592,
    division_type: "xã",
    codename: "xa_sin_suoi_ho",
    district_code: 109,
  },
  {
    name: "Thị trấn Than Uyên",
    code: 3595,
    division_type: "thị trấn",
    codename: "thi_tran_than_uyen",
    district_code: 110,
  },
  {
    name: "Xã Phúc Than",
    code: 3618,
    division_type: "xã",
    codename: "xa_phuc_than",
    district_code: 110,
  },
  {
    name: "Xã Mường Than",
    code: 3619,
    division_type: "xã",
    codename: "xa_muong_than",
    district_code: 110,
  },
  {
    name: "Xã Mường Mít",
    code: 3625,
    division_type: "xã",
    codename: "xa_muong_mit",
    district_code: 110,
  },
  {
    name: "Xã Pha Mu",
    code: 3628,
    division_type: "xã",
    codename: "xa_pha_mu",
    district_code: 110,
  },
  {
    name: "Xã Mường Cang",
    code: 3631,
    division_type: "xã",
    codename: "xa_muong_cang",
    district_code: 110,
  },
  {
    name: "Xã Hua Nà",
    code: 3632,
    division_type: "xã",
    codename: "xa_hua_na",
    district_code: 110,
  },
  {
    name: "Xã Tà Hừa",
    code: 3634,
    division_type: "xã",
    codename: "xa_ta_hua",
    district_code: 110,
  },
  {
    name: "Xã Mường Kim",
    code: 3637,
    division_type: "xã",
    codename: "xa_muong_kim",
    district_code: 110,
  },
  {
    name: "Xã Tà Mung",
    code: 3638,
    division_type: "xã",
    codename: "xa_ta_mung",
    district_code: 110,
  },
  {
    name: "Xã Tà Gia",
    code: 3640,
    division_type: "xã",
    codename: "xa_ta_gia",
    district_code: 110,
  },
  {
    name: "Xã Khoen On",
    code: 3643,
    division_type: "xã",
    codename: "xa_khoen_on",
    district_code: 110,
  },
  {
    name: "Thị trấn Tân Uyên",
    code: 3598,
    division_type: "thị trấn",
    codename: "thi_tran_tan_uyen",
    district_code: 111,
  },
  {
    name: "Xã Mường Khoa",
    code: 3601,
    division_type: "xã",
    codename: "xa_muong_khoa",
    district_code: 111,
  },
  {
    name: "Xã Phúc Khoa",
    code: 3602,
    division_type: "xã",
    codename: "xa_phuc_khoa",
    district_code: 111,
  },
  {
    name: "Xã Thân Thuộc",
    code: 3604,
    division_type: "xã",
    codename: "xa_than_thuoc",
    district_code: 111,
  },
  {
    name: "Xã Trung Đồng",
    code: 3605,
    division_type: "xã",
    codename: "xa_trung_dong",
    district_code: 111,
  },
  {
    name: "Xã Hố Mít",
    code: 3607,
    division_type: "xã",
    codename: "xa_ho_mit",
    district_code: 111,
  },
  {
    name: "Xã Nậm Cần",
    code: 3610,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 111,
  },
  {
    name: "Xã Nậm Sỏ",
    code: 3613,
    division_type: "xã",
    codename: "xa_nam_so",
    district_code: 111,
  },
  {
    name: "Xã Pắc Ta",
    code: 3616,
    division_type: "xã",
    codename: "xa_pac_ta",
    district_code: 111,
  },
  {
    name: "Xã Tà Mít",
    code: 3622,
    division_type: "xã",
    codename: "xa_ta_mit",
    district_code: 111,
  },
  {
    name: "Thị trấn Nậm Nhùn",
    code: 3434,
    division_type: "thị trấn",
    codename: "thi_tran_nam_nhun",
    district_code: 112,
  },
  {
    name: "Xã Hua Bun",
    code: 3460,
    division_type: "xã",
    codename: "xa_hua_bun",
    district_code: 112,
  },
  {
    name: "Xã Mường Mô",
    code: 3472,
    division_type: "xã",
    codename: "xa_muong_mo",
    district_code: 112,
  },
  {
    name: "Xã Nậm Chà",
    code: 3473,
    division_type: "xã",
    codename: "xa_nam_cha",
    district_code: 112,
  },
  {
    name: "Xã Nậm Manh",
    code: 3474,
    division_type: "xã",
    codename: "xa_nam_manh",
    district_code: 112,
  },
  {
    name: "Xã Nậm Hàng",
    code: 3475,
    division_type: "xã",
    codename: "xa_nam_hang",
    district_code: 112,
  },
  {
    name: "Xã Lê Lợi",
    code: 3481,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 112,
  },
  {
    name: "Xã Pú Đao",
    code: 3484,
    division_type: "xã",
    codename: "xa_pu_dao",
    district_code: 112,
  },
  {
    name: "Xã Nậm Pì",
    code: 3488,
    division_type: "xã",
    codename: "xa_nam_pi",
    district_code: 112,
  },
  {
    name: "Xã Nậm Ban",
    code: 3502,
    division_type: "xã",
    codename: "xa_nam_ban",
    district_code: 112,
  },
  {
    name: "Xã Trung Chải",
    code: 3503,
    division_type: "xã",
    codename: "xa_trung_chai",
    district_code: 112,
  },
  {
    name: "Phường Chiềng Lề",
    code: 3646,
    division_type: "phường",
    codename: "phuong_chieng_le",
    district_code: 116,
  },
  {
    name: "Phường Tô Hiệu",
    code: 3649,
    division_type: "phường",
    codename: "phuong_to_hieu",
    district_code: 116,
  },
  {
    name: "Phường Quyết Thắng",
    code: 3652,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 116,
  },
  {
    name: "Phường Quyết Tâm",
    code: 3655,
    division_type: "phường",
    codename: "phuong_quyet_tam",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Cọ",
    code: 3658,
    division_type: "xã",
    codename: "xa_chieng_co",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Đen",
    code: 3661,
    division_type: "xã",
    codename: "xa_chieng_den",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Xôm",
    code: 3664,
    division_type: "xã",
    codename: "xa_chieng_xom",
    district_code: 116,
  },
  {
    name: "Phường Chiềng An",
    code: 3667,
    division_type: "phường",
    codename: "phuong_chieng_an",
    district_code: 116,
  },
  {
    name: "Phường Chiềng Cơi",
    code: 3670,
    division_type: "phường",
    codename: "phuong_chieng_coi",
    district_code: 116,
  },
  {
    name: "Xã Chiềng Ngần",
    code: 3673,
    division_type: "xã",
    codename: "xa_chieng_ngan",
    district_code: 116,
  },
  {
    name: "Xã Hua La",
    code: 3676,
    division_type: "xã",
    codename: "xa_hua_la",
    district_code: 116,
  },
  {
    name: "Phường Chiềng Sinh",
    code: 3679,
    division_type: "phường",
    codename: "phuong_chieng_sinh",
    district_code: 116,
  },
  {
    name: "Xã Mường Chiên",
    code: 3682,
    division_type: "xã",
    codename: "xa_muong_chien",
    district_code: 118,
  },
  {
    name: "Xã Cà Nàng",
    code: 3685,
    division_type: "xã",
    codename: "xa_ca_nang",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Khay",
    code: 3688,
    division_type: "xã",
    codename: "xa_chieng_khay",
    district_code: 118,
  },
  {
    name: "Xã Mường Giôn",
    code: 3694,
    division_type: "xã",
    codename: "xa_muong_gion",
    district_code: 118,
  },
  {
    name: "Xã Pá Ma Pha Khinh",
    code: 3697,
    division_type: "xã",
    codename: "xa_pa_ma_pha_khinh",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Ơn",
    code: 3700,
    division_type: "xã",
    codename: "xa_chieng_on",
    district_code: 118,
  },
  {
    name: "Xã Mường Giàng",
    code: 3703,
    division_type: "xã",
    codename: "xa_muong_giang",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Bằng",
    code: 3706,
    division_type: "xã",
    codename: "xa_chieng_bang",
    district_code: 118,
  },
  {
    name: "Xã Mường Sại",
    code: 3709,
    division_type: "xã",
    codename: "xa_muong_sai",
    district_code: 118,
  },
  {
    name: "Xã Nậm ét",
    code: 3712,
    division_type: "xã",
    codename: "xa_nam_et",
    district_code: 118,
  },
  {
    name: "Xã Chiềng Khoang",
    code: 3718,
    division_type: "xã",
    codename: "xa_chieng_khoang",
    district_code: 118,
  },
  {
    name: "Thị trấn Thuận Châu",
    code: 3721,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_chau",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lái",
    code: 3724,
    division_type: "xã",
    codename: "xa_phong_lai",
    district_code: 119,
  },
  {
    name: "Xã Mường é",
    code: 3727,
    division_type: "xã",
    codename: "xa_muong_e",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Pha",
    code: 3730,
    division_type: "xã",
    codename: "xa_chieng_pha",
    district_code: 119,
  },
  {
    name: "Xã Chiềng La",
    code: 3733,
    division_type: "xã",
    codename: "xa_chieng_la",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Ngàm",
    code: 3736,
    division_type: "xã",
    codename: "xa_chieng_ngam",
    district_code: 119,
  },
  {
    name: "Xã Liệp Tè",
    code: 3739,
    division_type: "xã",
    codename: "xa_liep_te",
    district_code: 119,
  },
  {
    name: "Xã é Tòng",
    code: 3742,
    division_type: "xã",
    codename: "xa_e_tong",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lập",
    code: 3745,
    division_type: "xã",
    codename: "xa_phong_lap",
    district_code: 119,
  },
  {
    name: "Xã Phổng Lăng",
    code: 3748,
    division_type: "xã",
    codename: "xa_phong_lang",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Ly",
    code: 3751,
    division_type: "xã",
    codename: "xa_chieng_ly",
    district_code: 119,
  },
  {
    name: "Xã Noong Lay",
    code: 3754,
    division_type: "xã",
    codename: "xa_noong_lay",
    district_code: 119,
  },
  {
    name: "Xã Mường Khiêng",
    code: 3757,
    division_type: "xã",
    codename: "xa_muong_khieng",
    district_code: 119,
  },
  {
    name: "Xã Mường Bám",
    code: 3760,
    division_type: "xã",
    codename: "xa_muong_bam",
    district_code: 119,
  },
  {
    name: "Xã Long Hẹ",
    code: 3763,
    division_type: "xã",
    codename: "xa_long_he",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Bôm",
    code: 3766,
    division_type: "xã",
    codename: "xa_chieng_bom",
    district_code: 119,
  },
  {
    name: "Xã Thôm Mòn",
    code: 3769,
    division_type: "xã",
    codename: "xa_thom_mon",
    district_code: 119,
  },
  {
    name: "Xã Tông Lạnh",
    code: 3772,
    division_type: "xã",
    codename: "xa_tong_lanh",
    district_code: 119,
  },
  {
    name: "Xã Tông Cọ",
    code: 3775,
    division_type: "xã",
    codename: "xa_tong_co",
    district_code: 119,
  },
  {
    name: "Xã Bó Mười",
    code: 3778,
    division_type: "xã",
    codename: "xa_bo_muoi",
    district_code: 119,
  },
  {
    name: "Xã Co Mạ",
    code: 3781,
    division_type: "xã",
    codename: "xa_co_ma",
    district_code: 119,
  },
  {
    name: "Xã Púng Tra",
    code: 3784,
    division_type: "xã",
    codename: "xa_pung_tra",
    district_code: 119,
  },
  {
    name: "Xã Chiềng Pấc",
    code: 3787,
    division_type: "xã",
    codename: "xa_chieng_pac",
    district_code: 119,
  },
  {
    name: "Xã Nậm Lầu",
    code: 3790,
    division_type: "xã",
    codename: "xa_nam_lau",
    district_code: 119,
  },
  {
    name: "Xã Bon Phặng",
    code: 3793,
    division_type: "xã",
    codename: "xa_bon_phang",
    district_code: 119,
  },
  {
    name: "Xã Co Tòng",
    code: 3796,
    division_type: "xã",
    codename: "xa_co_tong",
    district_code: 119,
  },
  {
    name: "Xã Muổi Nọi",
    code: 3799,
    division_type: "xã",
    codename: "xa_muoi_noi",
    district_code: 119,
  },
  {
    name: "Xã Pá Lông",
    code: 3802,
    division_type: "xã",
    codename: "xa_pa_long",
    district_code: 119,
  },
  {
    name: "Xã Bản Lầm",
    code: 3805,
    division_type: "xã",
    codename: "xa_ban_lam",
    district_code: 119,
  },
  {
    name: "Thị trấn Ít Ong",
    code: 3808,
    division_type: "thị trấn",
    codename: "thi_tran_it_ong",
    district_code: 120,
  },
  {
    name: "Xã Nậm Giôn",
    code: 3811,
    division_type: "xã",
    codename: "xa_nam_gion",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Lao",
    code: 3814,
    division_type: "xã",
    codename: "xa_chieng_lao",
    district_code: 120,
  },
  {
    name: "Xã Hua Trai",
    code: 3817,
    division_type: "xã",
    codename: "xa_hua_trai",
    district_code: 120,
  },
  {
    name: "Xã Ngọc Chiến",
    code: 3820,
    division_type: "xã",
    codename: "xa_ngoc_chien",
    district_code: 120,
  },
  {
    name: "Xã Mường Trai",
    code: 3823,
    division_type: "xã",
    codename: "xa_muong_trai",
    district_code: 120,
  },
  {
    name: "Xã Nậm Păm",
    code: 3826,
    division_type: "xã",
    codename: "xa_nam_pam",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Muôn",
    code: 3829,
    division_type: "xã",
    codename: "xa_chieng_muon",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Ân",
    code: 3832,
    division_type: "xã",
    codename: "xa_chieng_an",
    district_code: 120,
  },
  {
    name: "Xã Pi Toong",
    code: 3835,
    division_type: "xã",
    codename: "xa_pi_toong",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Công",
    code: 3838,
    division_type: "xã",
    codename: "xa_chieng_cong",
    district_code: 120,
  },
  {
    name: "Xã Tạ Bú",
    code: 3841,
    division_type: "xã",
    codename: "xa_ta_bu",
    district_code: 120,
  },
  {
    name: "Xã Chiềng San",
    code: 3844,
    division_type: "xã",
    codename: "xa_chieng_san",
    district_code: 120,
  },
  {
    name: "Xã Mường Bú",
    code: 3847,
    division_type: "xã",
    codename: "xa_muong_bu",
    district_code: 120,
  },
  {
    name: "Xã Chiềng Hoa",
    code: 3850,
    division_type: "xã",
    codename: "xa_chieng_hoa",
    district_code: 120,
  },
  {
    name: "Xã Mường Chùm",
    code: 3853,
    division_type: "xã",
    codename: "xa_muong_chum",
    district_code: 120,
  },
  {
    name: "Thị trấn Bắc Yên",
    code: 3856,
    division_type: "thị trấn",
    codename: "thi_tran_bac_yen",
    district_code: 121,
  },
  {
    name: "Xã Phiêng Ban",
    code: 3859,
    division_type: "xã",
    codename: "xa_phieng_ban",
    district_code: 121,
  },
  {
    name: "Xã Hang Chú",
    code: 3862,
    division_type: "xã",
    codename: "xa_hang_chu",
    district_code: 121,
  },
  {
    name: "Xã Xím Vàng",
    code: 3865,
    division_type: "xã",
    codename: "xa_xim_vang",
    district_code: 121,
  },
  {
    name: "Xã Tà Xùa",
    code: 3868,
    division_type: "xã",
    codename: "xa_ta_xua",
    district_code: 121,
  },
  {
    name: "Xã Háng Đồng",
    code: 3869,
    division_type: "xã",
    codename: "xa_hang_dong",
    district_code: 121,
  },
  {
    name: "Xã Pắc Ngà",
    code: 3871,
    division_type: "xã",
    codename: "xa_pac_nga",
    district_code: 121,
  },
  {
    name: "Xã Làng Chếu",
    code: 3874,
    division_type: "xã",
    codename: "xa_lang_cheu",
    district_code: 121,
  },
  {
    name: "Xã Chim Vàn",
    code: 3877,
    division_type: "xã",
    codename: "xa_chim_van",
    district_code: 121,
  },
  {
    name: "Xã Mường Khoa",
    code: 3880,
    division_type: "xã",
    codename: "xa_muong_khoa",
    district_code: 121,
  },
  {
    name: "Xã Song Pe",
    code: 3883,
    division_type: "xã",
    codename: "xa_song_pe",
    district_code: 121,
  },
  {
    name: "Xã Hồng Ngài",
    code: 3886,
    division_type: "xã",
    codename: "xa_hong_ngai",
    district_code: 121,
  },
  {
    name: "Xã Tạ Khoa",
    code: 3889,
    division_type: "xã",
    codename: "xa_ta_khoa",
    district_code: 121,
  },
  {
    name: "Xã Hua Nhàn",
    code: 3890,
    division_type: "xã",
    codename: "xa_hua_nhan",
    district_code: 121,
  },
  {
    name: "Xã Phiêng Côn",
    code: 3892,
    division_type: "xã",
    codename: "xa_phieng_con",
    district_code: 121,
  },
  {
    name: "Xã Chiềng Sại",
    code: 3895,
    division_type: "xã",
    codename: "xa_chieng_sai",
    district_code: 121,
  },
  {
    name: "Thị trấn Phù Yên",
    code: 3898,
    division_type: "thị trấn",
    codename: "thi_tran_phu_yen",
    district_code: 122,
  },
  {
    name: "Xã Suối Tọ",
    code: 3901,
    division_type: "xã",
    codename: "xa_suoi_to",
    district_code: 122,
  },
  {
    name: "Xã Mường Thải",
    code: 3904,
    division_type: "xã",
    codename: "xa_muong_thai",
    district_code: 122,
  },
  {
    name: "Xã Mường Cơi",
    code: 3907,
    division_type: "xã",
    codename: "xa_muong_coi",
    district_code: 122,
  },
  {
    name: "Xã Quang Huy",
    code: 3910,
    division_type: "xã",
    codename: "xa_quang_huy",
    district_code: 122,
  },
  {
    name: "Xã Huy Bắc",
    code: 3913,
    division_type: "xã",
    codename: "xa_huy_bac",
    district_code: 122,
  },
  {
    name: "Xã Huy Thượng",
    code: 3916,
    division_type: "xã",
    codename: "xa_huy_thuong",
    district_code: 122,
  },
  {
    name: "Xã Tân Lang",
    code: 3919,
    division_type: "xã",
    codename: "xa_tan_lang",
    district_code: 122,
  },
  {
    name: "Xã Gia Phù",
    code: 3922,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 122,
  },
  {
    name: "Xã Tường Phù",
    code: 3925,
    division_type: "xã",
    codename: "xa_tuong_phu",
    district_code: 122,
  },
  {
    name: "Xã Huy Hạ",
    code: 3928,
    division_type: "xã",
    codename: "xa_huy_ha",
    district_code: 122,
  },
  {
    name: "Xã Huy Tân",
    code: 3931,
    division_type: "xã",
    codename: "xa_huy_tan",
    district_code: 122,
  },
  {
    name: "Xã Mường Lang",
    code: 3934,
    division_type: "xã",
    codename: "xa_muong_lang",
    district_code: 122,
  },
  {
    name: "Xã Suối Bau",
    code: 3937,
    division_type: "xã",
    codename: "xa_suoi_bau",
    district_code: 122,
  },
  {
    name: "Xã Huy Tường",
    code: 3940,
    division_type: "xã",
    codename: "xa_huy_tuong",
    district_code: 122,
  },
  {
    name: "Xã Mường Do",
    code: 3943,
    division_type: "xã",
    codename: "xa_muong_do",
    district_code: 122,
  },
  {
    name: "Xã Sập Xa",
    code: 3946,
    division_type: "xã",
    codename: "xa_sap_xa",
    district_code: 122,
  },
  {
    name: "Xã Tường Thượng",
    code: 3949,
    division_type: "xã",
    codename: "xa_tuong_thuong",
    district_code: 122,
  },
  {
    name: "Xã Tường Tiến",
    code: 3952,
    division_type: "xã",
    codename: "xa_tuong_tien",
    district_code: 122,
  },
  {
    name: "Xã Tường Phong",
    code: 3955,
    division_type: "xã",
    codename: "xa_tuong_phong",
    district_code: 122,
  },
  {
    name: "Xã Tường Hạ",
    code: 3958,
    division_type: "xã",
    codename: "xa_tuong_ha",
    district_code: 122,
  },
  {
    name: "Xã Kim Bon",
    code: 3961,
    division_type: "xã",
    codename: "xa_kim_bon",
    district_code: 122,
  },
  {
    name: "Xã Mường Bang",
    code: 3964,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 122,
  },
  {
    name: "Xã Đá Đỏ",
    code: 3967,
    division_type: "xã",
    codename: "xa_da_do",
    district_code: 122,
  },
  {
    name: "Xã Tân Phong",
    code: 3970,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 122,
  },
  {
    name: "Xã Nam Phong",
    code: 3973,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 122,
  },
  {
    name: "Xã Bắc Phong",
    code: 3976,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 122,
  },
  {
    name: "Thị trấn Mộc Châu",
    code: 3979,
    division_type: "thị trấn",
    codename: "thi_tran_moc_chau",
    district_code: 123,
  },
  {
    name: "Thị trấn NT Mộc Châu",
    code: 3982,
    division_type: "thị trấn",
    codename: "thi_tran_nt_moc_chau",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Sơn",
    code: 3985,
    division_type: "xã",
    codename: "xa_chieng_son",
    district_code: 123,
  },
  {
    name: "Xã Tân Hợp",
    code: 3988,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 123,
  },
  {
    name: "Xã Qui Hướng",
    code: 3991,
    division_type: "xã",
    codename: "xa_qui_huong",
    district_code: 123,
  },
  {
    name: "Xã Tân Lập",
    code: 3997,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 123,
  },
  {
    name: "Xã Nà Mường",
    code: 4000,
    division_type: "xã",
    codename: "xa_na_muong",
    district_code: 123,
  },
  {
    name: "Xã Tà Lai",
    code: 4003,
    division_type: "xã",
    codename: "xa_ta_lai",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Hắc",
    code: 4012,
    division_type: "xã",
    codename: "xa_chieng_hac",
    district_code: 123,
  },
  {
    name: "Xã Hua Păng",
    code: 4015,
    division_type: "xã",
    codename: "xa_hua_pang",
    district_code: 123,
  },
  {
    name: "Xã Chiềng Khừa",
    code: 4024,
    division_type: "xã",
    codename: "xa_chieng_khua",
    district_code: 123,
  },
  {
    name: "Xã Mường Sang",
    code: 4027,
    division_type: "xã",
    codename: "xa_muong_sang",
    district_code: 123,
  },
  {
    name: "Xã Đông Sang",
    code: 4030,
    division_type: "xã",
    codename: "xa_dong_sang",
    district_code: 123,
  },
  {
    name: "Xã Phiêng Luông",
    code: 4033,
    division_type: "xã",
    codename: "xa_phieng_luong",
    district_code: 123,
  },
  {
    name: "Xã Lóng Sập",
    code: 4045,
    division_type: "xã",
    codename: "xa_long_sap",
    district_code: 123,
  },
  {
    name: "Thị trấn Yên Châu",
    code: 4060,
    division_type: "thị trấn",
    codename: "thi_tran_yen_chau",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Đông",
    code: 4063,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 124,
  },
  {
    name: "Xã Sập Vạt",
    code: 4066,
    division_type: "xã",
    codename: "xa_sap_vat",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Sàng",
    code: 4069,
    division_type: "xã",
    codename: "xa_chieng_sang",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Pằn",
    code: 4072,
    division_type: "xã",
    codename: "xa_chieng_pan",
    district_code: 124,
  },
  {
    name: "Xã Viêng Lán",
    code: 4075,
    division_type: "xã",
    codename: "xa_vieng_lan",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Hặc",
    code: 4078,
    division_type: "xã",
    codename: "xa_chieng_hac",
    district_code: 124,
  },
  {
    name: "Xã Mường Lựm",
    code: 4081,
    division_type: "xã",
    codename: "xa_muong_lum",
    district_code: 124,
  },
  {
    name: "Xã Chiềng On",
    code: 4084,
    division_type: "xã",
    codename: "xa_chieng_on",
    district_code: 124,
  },
  {
    name: "Xã Yên Sơn",
    code: 4087,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Khoi",
    code: 4090,
    division_type: "xã",
    codename: "xa_chieng_khoi",
    district_code: 124,
  },
  {
    name: "Xã Tú Nang",
    code: 4093,
    division_type: "xã",
    codename: "xa_tu_nang",
    district_code: 124,
  },
  {
    name: "Xã Lóng Phiêng",
    code: 4096,
    division_type: "xã",
    codename: "xa_long_phieng",
    district_code: 124,
  },
  {
    name: "Xã Phiêng Khoài",
    code: 4099,
    division_type: "xã",
    codename: "xa_phieng_khoai",
    district_code: 124,
  },
  {
    name: "Xã Chiềng Tương",
    code: 4102,
    division_type: "xã",
    codename: "xa_chieng_tuong",
    district_code: 124,
  },
  {
    name: "Thị trấn Hát Lót",
    code: 4105,
    division_type: "thị trấn",
    codename: "thi_tran_hat_lot",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Sung",
    code: 4108,
    division_type: "xã",
    codename: "xa_chieng_sung",
    district_code: 125,
  },
  {
    name: "Xã Mường Bằng",
    code: 4111,
    division_type: "xã",
    codename: "xa_muong_bang",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Chăn",
    code: 4114,
    division_type: "xã",
    codename: "xa_chieng_chan",
    district_code: 125,
  },
  {
    name: "Xã Mương Chanh",
    code: 4117,
    division_type: "xã",
    codename: "xa_muong_chanh",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Ban",
    code: 4120,
    division_type: "xã",
    codename: "xa_chieng_ban",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Mung",
    code: 4123,
    division_type: "xã",
    codename: "xa_chieng_mung",
    district_code: 125,
  },
  {
    name: "Xã Mường Bon",
    code: 4126,
    division_type: "xã",
    codename: "xa_muong_bon",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Chung",
    code: 4129,
    division_type: "xã",
    codename: "xa_chieng_chung",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Mai",
    code: 4132,
    division_type: "xã",
    codename: "xa_chieng_mai",
    district_code: 125,
  },
  {
    name: "Xã Hát Lót",
    code: 4135,
    division_type: "xã",
    codename: "xa_hat_lot",
    district_code: 125,
  },
  {
    name: "Xã Nà Pó",
    code: 4136,
    division_type: "xã",
    codename: "xa_na_po",
    district_code: 125,
  },
  {
    name: "Xã Cò Nòi",
    code: 4138,
    division_type: "xã",
    codename: "xa_co_noi",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Nơi",
    code: 4141,
    division_type: "xã",
    codename: "xa_chieng_noi",
    district_code: 125,
  },
  {
    name: "Xã Phiêng Cằm",
    code: 4144,
    division_type: "xã",
    codename: "xa_phieng_cam",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Dong",
    code: 4147,
    division_type: "xã",
    codename: "xa_chieng_dong",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Kheo",
    code: 4150,
    division_type: "xã",
    codename: "xa_chieng_kheo",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Ve",
    code: 4153,
    division_type: "xã",
    codename: "xa_chieng_ve",
    district_code: 125,
  },
  {
    name: "Xã Chiềng Lương",
    code: 4156,
    division_type: "xã",
    codename: "xa_chieng_luong",
    district_code: 125,
  },
  {
    name: "Xã Phiêng Pằn",
    code: 4159,
    division_type: "xã",
    codename: "xa_phieng_pan",
    district_code: 125,
  },
  {
    name: "Xã Nà Ơt",
    code: 4162,
    division_type: "xã",
    codename: "xa_na_ot",
    district_code: 125,
  },
  {
    name: "Xã Tà Hộc",
    code: 4165,
    division_type: "xã",
    codename: "xa_ta_hoc",
    district_code: 125,
  },
  {
    name: "Thị trấn Sông Mã",
    code: 4168,
    division_type: "thị trấn",
    codename: "thi_tran_song_ma",
    district_code: 126,
  },
  {
    name: "Xã Bó Sinh",
    code: 4171,
    division_type: "xã",
    codename: "xa_bo_sinh",
    district_code: 126,
  },
  {
    name: "Xã Pú Pẩu",
    code: 4174,
    division_type: "xã",
    codename: "xa_pu_pau",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Phung",
    code: 4177,
    division_type: "xã",
    codename: "xa_chieng_phung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng En",
    code: 4180,
    division_type: "xã",
    codename: "xa_chieng_en",
    district_code: 126,
  },
  {
    name: "Xã Mường Lầm",
    code: 4183,
    division_type: "xã",
    codename: "xa_muong_lam",
    district_code: 126,
  },
  {
    name: "Xã Nậm Ty",
    code: 4186,
    division_type: "xã",
    codename: "xa_nam_ty",
    district_code: 126,
  },
  {
    name: "Xã Đứa Mòn",
    code: 4189,
    division_type: "xã",
    codename: "xa_dua_mon",
    district_code: 126,
  },
  {
    name: "Xã Yên Hưng",
    code: 4192,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Sơ",
    code: 4195,
    division_type: "xã",
    codename: "xa_chieng_so",
    district_code: 126,
  },
  {
    name: "Xã Nà Nghịu",
    code: 4198,
    division_type: "xã",
    codename: "xa_na_nghiu",
    district_code: 126,
  },
  {
    name: "Xã Nậm Mằn",
    code: 4201,
    division_type: "xã",
    codename: "xa_nam_man",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Khoong",
    code: 4204,
    division_type: "xã",
    codename: "xa_chieng_khoong",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Cang",
    code: 4207,
    division_type: "xã",
    codename: "xa_chieng_cang",
    district_code: 126,
  },
  {
    name: "Xã Huổi Một",
    code: 4210,
    division_type: "xã",
    codename: "xa_huoi_mot",
    district_code: 126,
  },
  {
    name: "Xã Mường Sai",
    code: 4213,
    division_type: "xã",
    codename: "xa_muong_sai",
    district_code: 126,
  },
  {
    name: "Xã Mường Cai",
    code: 4216,
    division_type: "xã",
    codename: "xa_muong_cai",
    district_code: 126,
  },
  {
    name: "Xã Mường Hung",
    code: 4219,
    division_type: "xã",
    codename: "xa_muong_hung",
    district_code: 126,
  },
  {
    name: "Xã Chiềng Khương",
    code: 4222,
    division_type: "xã",
    codename: "xa_chieng_khuong",
    district_code: 126,
  },
  {
    name: "Xã Sam Kha",
    code: 4225,
    division_type: "xã",
    codename: "xa_sam_kha",
    district_code: 127,
  },
  {
    name: "Xã Púng Bánh",
    code: 4228,
    division_type: "xã",
    codename: "xa_pung_banh",
    district_code: 127,
  },
  {
    name: "Xã Sốp Cộp",
    code: 4231,
    division_type: "xã",
    codename: "xa_sop_cop",
    district_code: 127,
  },
  {
    name: "Xã Dồm Cang",
    code: 4234,
    division_type: "xã",
    codename: "xa_dom_cang",
    district_code: 127,
  },
  {
    name: "Xã Nậm Lạnh",
    code: 4237,
    division_type: "xã",
    codename: "xa_nam_lanh",
    district_code: 127,
  },
  {
    name: "Xã Mường Lèo",
    code: 4240,
    division_type: "xã",
    codename: "xa_muong_leo",
    district_code: 127,
  },
  {
    name: "Xã Mường Và",
    code: 4243,
    division_type: "xã",
    codename: "xa_muong_va",
    district_code: 127,
  },
  {
    name: "Xã Mường Lạn",
    code: 4246,
    division_type: "xã",
    codename: "xa_muong_lan",
    district_code: 127,
  },
  {
    name: "Xã Suối Bàng",
    code: 3994,
    division_type: "xã",
    codename: "xa_suoi_bang",
    district_code: 128,
  },
  {
    name: "Xã Song Khủa",
    code: 4006,
    division_type: "xã",
    codename: "xa_song_khua",
    district_code: 128,
  },
  {
    name: "Xã Liên Hoà",
    code: 4009,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 128,
  },
  {
    name: "Xã Tô Múa",
    code: 4018,
    division_type: "xã",
    codename: "xa_to_mua",
    district_code: 128,
  },
  {
    name: "Xã Mường Tè",
    code: 4021,
    division_type: "xã",
    codename: "xa_muong_te",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Khoa",
    code: 4036,
    division_type: "xã",
    codename: "xa_chieng_khoa",
    district_code: 128,
  },
  {
    name: "Xã Mường Men",
    code: 4039,
    division_type: "xã",
    codename: "xa_muong_men",
    district_code: 128,
  },
  {
    name: "Xã Quang Minh",
    code: 4042,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 128,
  },
  {
    name: "Xã Vân Hồ",
    code: 4048,
    division_type: "xã",
    codename: "xa_van_ho",
    district_code: 128,
  },
  {
    name: "Xã Lóng Luông",
    code: 4051,
    division_type: "xã",
    codename: "xa_long_luong",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Yên",
    code: 4054,
    division_type: "xã",
    codename: "xa_chieng_yen",
    district_code: 128,
  },
  {
    name: "Xã Chiềng Xuân",
    code: 4056,
    division_type: "xã",
    codename: "xa_chieng_xuan",
    district_code: 128,
  },
  {
    name: "Xã Xuân Nha",
    code: 4057,
    division_type: "xã",
    codename: "xa_xuan_nha",
    district_code: 128,
  },
  {
    name: "Xã Tân Xuân",
    code: 4058,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 128,
  },
  {
    name: "Phường Yên Thịnh",
    code: 4249,
    division_type: "phường",
    codename: "phuong_yen_thinh",
    district_code: 132,
  },
  {
    name: "Phường Yên Ninh",
    code: 4252,
    division_type: "phường",
    codename: "phuong_yen_ninh",
    district_code: 132,
  },
  {
    name: "Phường Minh Tân",
    code: 4255,
    division_type: "phường",
    codename: "phuong_minh_tan",
    district_code: 132,
  },
  {
    name: "Phường Nguyễn Thái Học",
    code: 4258,
    division_type: "phường",
    codename: "phuong_nguyen_thai_hoc",
    district_code: 132,
  },
  {
    name: "Phường Đồng Tâm",
    code: 4261,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 132,
  },
  {
    name: "Phường Nguyễn Phúc",
    code: 4264,
    division_type: "phường",
    codename: "phuong_nguyen_phuc",
    district_code: 132,
  },
  {
    name: "Phường Hồng Hà",
    code: 4267,
    division_type: "phường",
    codename: "phuong_hong_ha",
    district_code: 132,
  },
  {
    name: "Xã Minh Bảo",
    code: 4270,
    division_type: "xã",
    codename: "xa_minh_bao",
    district_code: 132,
  },
  {
    name: "Phường Nam Cường",
    code: 4273,
    division_type: "phường",
    codename: "phuong_nam_cuong",
    district_code: 132,
  },
  {
    name: "Xã Tuy Lộc",
    code: 4276,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 132,
  },
  {
    name: "Xã Tân Thịnh",
    code: 4279,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 132,
  },
  {
    name: "Xã Âu Lâu",
    code: 4540,
    division_type: "xã",
    codename: "xa_au_lau",
    district_code: 132,
  },
  {
    name: "Xã Giới Phiên",
    code: 4543,
    division_type: "xã",
    codename: "xa_gioi_phien",
    district_code: 132,
  },
  {
    name: "Phường Hợp Minh",
    code: 4546,
    division_type: "phường",
    codename: "phuong_hop_minh",
    district_code: 132,
  },
  {
    name: "Xã Văn Phú",
    code: 4558,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 132,
  },
  {
    name: "Phường Pú Trạng",
    code: 4282,
    division_type: "phường",
    codename: "phuong_pu_trang",
    district_code: 133,
  },
  {
    name: "Phường Trung Tâm",
    code: 4285,
    division_type: "phường",
    codename: "phuong_trung_tam",
    district_code: 133,
  },
  {
    name: "Phường Tân An",
    code: 4288,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 133,
  },
  {
    name: "Phường Cầu Thia",
    code: 4291,
    division_type: "phường",
    codename: "phuong_cau_thia",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 4294,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Phúc",
    code: 4297,
    division_type: "xã",
    codename: "xa_nghia_phuc",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa An",
    code: 4300,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 133,
  },
  {
    name: "Xã Nghĩa Lộ",
    code: 4624,
    division_type: "xã",
    codename: "xa_nghia_lo",
    district_code: 133,
  },
  {
    name: "Xã Sơn A",
    code: 4660,
    division_type: "xã",
    codename: "xa_son_a",
    district_code: 133,
  },
  {
    name: "Xã Phù Nham",
    code: 4663,
    division_type: "xã",
    codename: "xa_phu_nham",
    district_code: 133,
  },
  {
    name: "Xã Thanh Lương",
    code: 4675,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 133,
  },
  {
    name: "Xã Hạnh Sơn",
    code: 4678,
    division_type: "xã",
    codename: "xa_hanh_son",
    district_code: 133,
  },
  {
    name: "Xã Phúc Sơn",
    code: 4681,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 133,
  },
  {
    name: "Xã Thạch Lương",
    code: 4684,
    division_type: "xã",
    codename: "xa_thach_luong",
    district_code: 133,
  },
  {
    name: "Thị trấn Yên Thế",
    code: 4303,
    division_type: "thị trấn",
    codename: "thi_tran_yen_the",
    district_code: 135,
  },
  {
    name: "Xã Tân Phượng",
    code: 4306,
    division_type: "xã",
    codename: "xa_tan_phuong",
    district_code: 135,
  },
  {
    name: "Xã Lâm Thượng",
    code: 4309,
    division_type: "xã",
    codename: "xa_lam_thuong",
    district_code: 135,
  },
  {
    name: "Xã Khánh Thiện",
    code: 4312,
    division_type: "xã",
    codename: "xa_khanh_thien",
    district_code: 135,
  },
  {
    name: "Xã Minh Chuẩn",
    code: 4315,
    division_type: "xã",
    codename: "xa_minh_chuan",
    district_code: 135,
  },
  {
    name: "Xã Mai Sơn",
    code: 4318,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 135,
  },
  {
    name: "Xã Khai Trung",
    code: 4321,
    division_type: "xã",
    codename: "xa_khai_trung",
    district_code: 135,
  },
  {
    name: "Xã Mường Lai",
    code: 4324,
    division_type: "xã",
    codename: "xa_muong_lai",
    district_code: 135,
  },
  {
    name: "Xã An Lạc",
    code: 4327,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 135,
  },
  {
    name: "Xã Minh Xuân",
    code: 4330,
    division_type: "xã",
    codename: "xa_minh_xuan",
    district_code: 135,
  },
  {
    name: "Xã Tô Mậu",
    code: 4333,
    division_type: "xã",
    codename: "xa_to_mau",
    district_code: 135,
  },
  {
    name: "Xã Tân Lĩnh",
    code: 4336,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 135,
  },
  {
    name: "Xã Yên Thắng",
    code: 4339,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 135,
  },
  {
    name: "Xã Khánh Hoà",
    code: 4342,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 135,
  },
  {
    name: "Xã Vĩnh Lạc",
    code: 4345,
    division_type: "xã",
    codename: "xa_vinh_lac",
    district_code: 135,
  },
  {
    name: "Xã Liễu Đô",
    code: 4348,
    division_type: "xã",
    codename: "xa_lieu_do",
    district_code: 135,
  },
  {
    name: "Xã Động Quan",
    code: 4351,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 135,
  },
  {
    name: "Xã Tân Lập",
    code: 4354,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 135,
  },
  {
    name: "Xã Minh Tiến",
    code: 4357,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 135,
  },
  {
    name: "Xã Trúc Lâu",
    code: 4360,
    division_type: "xã",
    codename: "xa_truc_lau",
    district_code: 135,
  },
  {
    name: "Xã Phúc Lợi",
    code: 4363,
    division_type: "xã",
    codename: "xa_phuc_loi",
    district_code: 135,
  },
  {
    name: "Xã Phan Thanh",
    code: 4366,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 135,
  },
  {
    name: "Xã An Phú",
    code: 4369,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 135,
  },
  {
    name: "Xã Trung Tâm",
    code: 4372,
    division_type: "xã",
    codename: "xa_trung_tam",
    district_code: 135,
  },
  {
    name: "Thị trấn Mậu A",
    code: 4375,
    division_type: "thị trấn",
    codename: "thi_tran_mau_a",
    district_code: 136,
  },
  {
    name: "Xã Lang Thíp",
    code: 4378,
    division_type: "xã",
    codename: "xa_lang_thip",
    district_code: 136,
  },
  {
    name: "Xã Lâm Giang",
    code: 4381,
    division_type: "xã",
    codename: "xa_lam_giang",
    district_code: 136,
  },
  {
    name: "Xã Châu Quế Thượng",
    code: 4384,
    division_type: "xã",
    codename: "xa_chau_que_thuong",
    district_code: 136,
  },
  {
    name: "Xã Châu Quế Hạ",
    code: 4387,
    division_type: "xã",
    codename: "xa_chau_que_ha",
    district_code: 136,
  },
  {
    name: "Xã An Bình",
    code: 4390,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 136,
  },
  {
    name: "Xã Quang Minh",
    code: 4393,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 136,
  },
  {
    name: "Xã Đông An",
    code: 4396,
    division_type: "xã",
    codename: "xa_dong_an",
    district_code: 136,
  },
  {
    name: "Xã Đông Cuông",
    code: 4399,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 136,
  },
  {
    name: "Xã Phong Dụ Hạ",
    code: 4402,
    division_type: "xã",
    codename: "xa_phong_du_ha",
    district_code: 136,
  },
  {
    name: "Xã Mậu Đông",
    code: 4405,
    division_type: "xã",
    codename: "xa_mau_dong",
    district_code: 136,
  },
  {
    name: "Xã Ngòi A",
    code: 4408,
    division_type: "xã",
    codename: "xa_ngoi_a",
    district_code: 136,
  },
  {
    name: "Xã Xuân Tầm",
    code: 4411,
    division_type: "xã",
    codename: "xa_xuan_tam",
    district_code: 136,
  },
  {
    name: "Xã Tân Hợp",
    code: 4414,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 136,
  },
  {
    name: "Xã An Thịnh",
    code: 4417,
    division_type: "xã",
    codename: "xa_an_thinh",
    district_code: 136,
  },
  {
    name: "Xã Yên Thái",
    code: 4420,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 136,
  },
  {
    name: "Xã Phong Dụ Thượng",
    code: 4423,
    division_type: "xã",
    codename: "xa_phong_du_thuong",
    district_code: 136,
  },
  {
    name: "Xã Yên Hợp",
    code: 4426,
    division_type: "xã",
    codename: "xa_yen_hop",
    district_code: 136,
  },
  {
    name: "Xã Đại Sơn",
    code: 4429,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 136,
  },
  {
    name: "Xã Đại Phác",
    code: 4435,
    division_type: "xã",
    codename: "xa_dai_phac",
    district_code: 136,
  },
  {
    name: "Xã Yên Phú",
    code: 4438,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 136,
  },
  {
    name: "Xã Xuân Ái",
    code: 4441,
    division_type: "xã",
    codename: "xa_xuan_ai",
    district_code: 136,
  },
  {
    name: "Xã Viễn Sơn",
    code: 4447,
    division_type: "xã",
    codename: "xa_vien_son",
    district_code: 136,
  },
  {
    name: "Xã Mỏ Vàng",
    code: 4450,
    division_type: "xã",
    codename: "xa_mo_vang",
    district_code: 136,
  },
  {
    name: "Xã Nà Hẩu",
    code: 4453,
    division_type: "xã",
    codename: "xa_na_hau",
    district_code: 136,
  },
  {
    name: "Thị trấn Mù Căng Chải",
    code: 4456,
    division_type: "thị trấn",
    codename: "thi_tran_mu_cang_chai",
    district_code: 137,
  },
  {
    name: "Xã Hồ Bốn",
    code: 4459,
    division_type: "xã",
    codename: "xa_ho_bon",
    district_code: 137,
  },
  {
    name: "Xã Nậm Có",
    code: 4462,
    division_type: "xã",
    codename: "xa_nam_co",
    district_code: 137,
  },
  {
    name: "Xã Khao Mang",
    code: 4465,
    division_type: "xã",
    codename: "xa_khao_mang",
    district_code: 137,
  },
  {
    name: "Xã Mồ Dề",
    code: 4468,
    division_type: "xã",
    codename: "xa_mo_de",
    district_code: 137,
  },
  {
    name: "Xã Chế Cu Nha",
    code: 4471,
    division_type: "xã",
    codename: "xa_che_cu_nha",
    district_code: 137,
  },
  {
    name: "Xã Lao Chải",
    code: 4474,
    division_type: "xã",
    codename: "xa_lao_chai",
    district_code: 137,
  },
  {
    name: "Xã Kim Nọi",
    code: 4477,
    division_type: "xã",
    codename: "xa_kim_noi",
    district_code: 137,
  },
  {
    name: "Xã Cao Phạ",
    code: 4480,
    division_type: "xã",
    codename: "xa_cao_pha",
    district_code: 137,
  },
  {
    name: "Xã La Pán Tẩn",
    code: 4483,
    division_type: "xã",
    codename: "xa_la_pan_tan",
    district_code: 137,
  },
  {
    name: "Xã Dế Su Phình",
    code: 4486,
    division_type: "xã",
    codename: "xa_de_su_phinh",
    district_code: 137,
  },
  {
    name: "Xã Chế Tạo",
    code: 4489,
    division_type: "xã",
    codename: "xa_che_tao",
    district_code: 137,
  },
  {
    name: "Xã Púng Luông",
    code: 4492,
    division_type: "xã",
    codename: "xa_pung_luong",
    district_code: 137,
  },
  {
    name: "Xã Nậm Khắt",
    code: 4495,
    division_type: "xã",
    codename: "xa_nam_khat",
    district_code: 137,
  },
  {
    name: "Thị trấn Cổ Phúc",
    code: 4498,
    division_type: "thị trấn",
    codename: "thi_tran_co_phuc",
    district_code: 138,
  },
  {
    name: "Xã Tân Đồng",
    code: 4501,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 138,
  },
  {
    name: "Xã Báo Đáp",
    code: 4504,
    division_type: "xã",
    codename: "xa_bao_dap",
    district_code: 138,
  },
  {
    name: "Xã Đào Thịnh",
    code: 4507,
    division_type: "xã",
    codename: "xa_dao_thinh",
    district_code: 138,
  },
  {
    name: "Xã Việt Thành",
    code: 4510,
    division_type: "xã",
    codename: "xa_viet_thanh",
    district_code: 138,
  },
  {
    name: "Xã Hòa Cuông",
    code: 4513,
    division_type: "xã",
    codename: "xa_hoa_cuong",
    district_code: 138,
  },
  {
    name: "Xã Minh Quán",
    code: 4516,
    division_type: "xã",
    codename: "xa_minh_quan",
    district_code: 138,
  },
  {
    name: "Xã Quy Mông",
    code: 4519,
    division_type: "xã",
    codename: "xa_quy_mong",
    district_code: 138,
  },
  {
    name: "Xã Cường Thịnh",
    code: 4522,
    division_type: "xã",
    codename: "xa_cuong_thinh",
    district_code: 138,
  },
  {
    name: "Xã Kiên Thành",
    code: 4525,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 138,
  },
  {
    name: "Xã Nga Quán",
    code: 4528,
    division_type: "xã",
    codename: "xa_nga_quan",
    district_code: 138,
  },
  {
    name: "Xã Y Can",
    code: 4531,
    division_type: "xã",
    codename: "xa_y_can",
    district_code: 138,
  },
  {
    name: "Xã Lương Thịnh",
    code: 4537,
    division_type: "xã",
    codename: "xa_luong_thinh",
    district_code: 138,
  },
  {
    name: "Xã Bảo Hưng",
    code: 4561,
    division_type: "xã",
    codename: "xa_bao_hung",
    district_code: 138,
  },
  {
    name: "Xã Việt Cường",
    code: 4564,
    division_type: "xã",
    codename: "xa_viet_cuong",
    district_code: 138,
  },
  {
    name: "Xã Minh Quân",
    code: 4567,
    division_type: "xã",
    codename: "xa_minh_quan",
    district_code: 138,
  },
  {
    name: "Xã Hồng Ca",
    code: 4570,
    division_type: "xã",
    codename: "xa_hong_ca",
    district_code: 138,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 4573,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 138,
  },
  {
    name: "Xã Hưng Khánh",
    code: 4576,
    division_type: "xã",
    codename: "xa_hung_khanh",
    district_code: 138,
  },
  {
    name: "Xã Việt Hồng",
    code: 4579,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 138,
  },
  {
    name: "Xã Vân Hội",
    code: 4582,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 138,
  },
  {
    name: "Thị trấn Trạm Tấu",
    code: 4585,
    division_type: "thị trấn",
    codename: "thi_tran_tram_tau",
    district_code: 139,
  },
  {
    name: "Xã Túc Đán",
    code: 4588,
    division_type: "xã",
    codename: "xa_tuc_dan",
    district_code: 139,
  },
  {
    name: "Xã Pá Lau",
    code: 4591,
    division_type: "xã",
    codename: "xa_pa_lau",
    district_code: 139,
  },
  {
    name: "Xã Xà Hồ",
    code: 4594,
    division_type: "xã",
    codename: "xa_xa_ho",
    district_code: 139,
  },
  {
    name: "Xã Phình Hồ",
    code: 4597,
    division_type: "xã",
    codename: "xa_phinh_ho",
    district_code: 139,
  },
  {
    name: "Xã Trạm Tấu",
    code: 4600,
    division_type: "xã",
    codename: "xa_tram_tau",
    district_code: 139,
  },
  {
    name: "Xã Tà Si Láng",
    code: 4603,
    division_type: "xã",
    codename: "xa_ta_si_lang",
    district_code: 139,
  },
  {
    name: "Xã Pá Hu",
    code: 4606,
    division_type: "xã",
    codename: "xa_pa_hu",
    district_code: 139,
  },
  {
    name: "Xã Làng Nhì",
    code: 4609,
    division_type: "xã",
    codename: "xa_lang_nhi",
    district_code: 139,
  },
  {
    name: "Xã Bản Công",
    code: 4612,
    division_type: "xã",
    codename: "xa_ban_cong",
    district_code: 139,
  },
  {
    name: "Xã Bản Mù",
    code: 4615,
    division_type: "xã",
    codename: "xa_ban_mu",
    district_code: 139,
  },
  {
    name: "Xã Hát Lìu",
    code: 4618,
    division_type: "xã",
    codename: "xa_hat_liu",
    district_code: 139,
  },
  {
    name: "Thị trấn NT Liên Sơn",
    code: 4621,
    division_type: "thị trấn",
    codename: "thi_tran_nt_lien_son",
    district_code: 140,
  },
  {
    name: "Thị trấn NT Trần Phú",
    code: 4627,
    division_type: "thị trấn",
    codename: "thi_tran_nt_tran_phu",
    district_code: 140,
  },
  {
    name: "Xã Tú Lệ",
    code: 4630,
    division_type: "xã",
    codename: "xa_tu_le",
    district_code: 140,
  },
  {
    name: "Xã Nậm Búng",
    code: 4633,
    division_type: "xã",
    codename: "xa_nam_bung",
    district_code: 140,
  },
  {
    name: "Xã Gia Hội",
    code: 4636,
    division_type: "xã",
    codename: "xa_gia_hoi",
    district_code: 140,
  },
  {
    name: "Xã Sùng Đô",
    code: 4639,
    division_type: "xã",
    codename: "xa_sung_do",
    district_code: 140,
  },
  {
    name: "Xã Nậm Mười",
    code: 4642,
    division_type: "xã",
    codename: "xa_nam_muoi",
    district_code: 140,
  },
  {
    name: "Xã An Lương",
    code: 4645,
    division_type: "xã",
    codename: "xa_an_luong",
    district_code: 140,
  },
  {
    name: "Xã Nậm Lành",
    code: 4648,
    division_type: "xã",
    codename: "xa_nam_lanh",
    district_code: 140,
  },
  {
    name: "Xã Sơn Lương",
    code: 4651,
    division_type: "xã",
    codename: "xa_son_luong",
    district_code: 140,
  },
  {
    name: "Xã Suối Quyền",
    code: 4654,
    division_type: "xã",
    codename: "xa_suoi_quyen",
    district_code: 140,
  },
  {
    name: "Xã Suối Giàng",
    code: 4657,
    division_type: "xã",
    codename: "xa_suoi_giang",
    district_code: 140,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 4666,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 140,
  },
  {
    name: "Xã Suối Bu",
    code: 4669,
    division_type: "xã",
    codename: "xa_suoi_bu",
    district_code: 140,
  },
  {
    name: "Thị trấn Sơn Thịnh",
    code: 4672,
    division_type: "thị trấn",
    codename: "thi_tran_son_thinh",
    district_code: 140,
  },
  {
    name: "Xã Đại Lịch",
    code: 4687,
    division_type: "xã",
    codename: "xa_dai_lich",
    district_code: 140,
  },
  {
    name: "Xã Đồng Khê",
    code: 4690,
    division_type: "xã",
    codename: "xa_dong_khe",
    district_code: 140,
  },
  {
    name: "Xã Cát Thịnh",
    code: 4693,
    division_type: "xã",
    codename: "xa_cat_thinh",
    district_code: 140,
  },
  {
    name: "Xã Tân Thịnh",
    code: 4696,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 140,
  },
  {
    name: "Xã Chấn Thịnh",
    code: 4699,
    division_type: "xã",
    codename: "xa_chan_thinh",
    district_code: 140,
  },
  {
    name: "Xã Bình Thuận",
    code: 4702,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 140,
  },
  {
    name: "Xã Thượng Bằng La",
    code: 4705,
    division_type: "xã",
    codename: "xa_thuong_bang_la",
    district_code: 140,
  },
  {
    name: "Xã Minh An",
    code: 4708,
    division_type: "xã",
    codename: "xa_minh_an",
    district_code: 140,
  },
  {
    name: "Xã Nghĩa Tâm",
    code: 4711,
    division_type: "xã",
    codename: "xa_nghia_tam",
    district_code: 140,
  },
  {
    name: "Thị trấn Yên Bình",
    code: 4714,
    division_type: "thị trấn",
    codename: "thi_tran_yen_binh",
    district_code: 141,
  },
  {
    name: "Thị trấn Thác Bà",
    code: 4717,
    division_type: "thị trấn",
    codename: "thi_tran_thac_ba",
    district_code: 141,
  },
  {
    name: "Xã Xuân Long",
    code: 4720,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 141,
  },
  {
    name: "Xã Cảm Nhân",
    code: 4726,
    division_type: "xã",
    codename: "xa_cam_nhan",
    district_code: 141,
  },
  {
    name: "Xã Ngọc Chấn",
    code: 4729,
    division_type: "xã",
    codename: "xa_ngoc_chan",
    district_code: 141,
  },
  {
    name: "Xã Tân Nguyên",
    code: 4732,
    division_type: "xã",
    codename: "xa_tan_nguyen",
    district_code: 141,
  },
  {
    name: "Xã Phúc Ninh",
    code: 4735,
    division_type: "xã",
    codename: "xa_phuc_ninh",
    district_code: 141,
  },
  {
    name: "Xã Bảo Ái",
    code: 4738,
    division_type: "xã",
    codename: "xa_bao_ai",
    district_code: 141,
  },
  {
    name: "Xã Mỹ Gia",
    code: 4741,
    division_type: "xã",
    codename: "xa_my_gia",
    district_code: 141,
  },
  {
    name: "Xã Xuân Lai",
    code: 4744,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 141,
  },
  {
    name: "Xã Mông Sơn",
    code: 4747,
    division_type: "xã",
    codename: "xa_mong_son",
    district_code: 141,
  },
  {
    name: "Xã Cảm Ân",
    code: 4750,
    division_type: "xã",
    codename: "xa_cam_an",
    district_code: 141,
  },
  {
    name: "Xã Yên Thành",
    code: 4753,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 141,
  },
  {
    name: "Xã Tân Hương",
    code: 4756,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 141,
  },
  {
    name: "Xã Phúc An",
    code: 4759,
    division_type: "xã",
    codename: "xa_phuc_an",
    district_code: 141,
  },
  {
    name: "Xã Bạch Hà",
    code: 4762,
    division_type: "xã",
    codename: "xa_bach_ha",
    district_code: 141,
  },
  {
    name: "Xã Vũ Linh",
    code: 4765,
    division_type: "xã",
    codename: "xa_vu_linh",
    district_code: 141,
  },
  {
    name: "Xã Đại Đồng",
    code: 4768,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 141,
  },
  {
    name: "Xã Vĩnh Kiên",
    code: 4771,
    division_type: "xã",
    codename: "xa_vinh_kien",
    district_code: 141,
  },
  {
    name: "Xã Yên Bình",
    code: 4774,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 141,
  },
  {
    name: "Xã Thịnh Hưng",
    code: 4777,
    division_type: "xã",
    codename: "xa_thinh_hung",
    district_code: 141,
  },
  {
    name: "Xã Hán Đà",
    code: 4780,
    division_type: "xã",
    codename: "xa_han_da",
    district_code: 141,
  },
  {
    name: "Xã Phú Thịnh",
    code: 4783,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 141,
  },
  {
    name: "Xã Đại Minh",
    code: 4786,
    division_type: "xã",
    codename: "xa_dai_minh",
    district_code: 141,
  },
  {
    name: "Phường Thái Bình",
    code: 4789,
    division_type: "phường",
    codename: "phuong_thai_binh",
    district_code: 148,
  },
  {
    name: "Phường Tân Hòa",
    code: 4792,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 148,
  },
  {
    name: "Phường Thịnh Lang",
    code: 4795,
    division_type: "phường",
    codename: "phuong_thinh_lang",
    district_code: 148,
  },
  {
    name: "Phường Hữu Nghị",
    code: 4798,
    division_type: "phường",
    codename: "phuong_huu_nghi",
    district_code: 148,
  },
  {
    name: "Phường Tân Thịnh",
    code: 4801,
    division_type: "phường",
    codename: "phuong_tan_thinh",
    district_code: 148,
  },
  {
    name: "Phường Đồng Tiến",
    code: 4804,
    division_type: "phường",
    codename: "phuong_dong_tien",
    district_code: 148,
  },
  {
    name: "Phường Phương Lâm",
    code: 4807,
    division_type: "phường",
    codename: "phuong_phuong_lam",
    district_code: 148,
  },
  {
    name: "Xã Yên Mông",
    code: 4813,
    division_type: "xã",
    codename: "xa_yen_mong",
    district_code: 148,
  },
  {
    name: "Phường Quỳnh Lâm",
    code: 4816,
    division_type: "phường",
    codename: "phuong_quynh_lam",
    district_code: 148,
  },
  {
    name: "Phường Dân Chủ",
    code: 4819,
    division_type: "phường",
    codename: "phuong_dan_chu",
    district_code: 148,
  },
  {
    name: "Xã Hòa Bình",
    code: 4825,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 148,
  },
  {
    name: "Phường Thống Nhất",
    code: 4828,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 148,
  },
  {
    name: "Phường Kỳ Sơn",
    code: 4894,
    division_type: "phường",
    codename: "phuong_ky_son",
    district_code: 148,
  },
  {
    name: "Xã Thịnh Minh",
    code: 4897,
    division_type: "xã",
    codename: "xa_thinh_minh",
    district_code: 148,
  },
  {
    name: "Xã Hợp Thành",
    code: 4903,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 148,
  },
  {
    name: "Xã Quang Tiến",
    code: 4906,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 148,
  },
  {
    name: "Xã Mông Hóa",
    code: 4912,
    division_type: "xã",
    codename: "xa_mong_hoa",
    district_code: 148,
  },
  {
    name: "Phường Trung Minh",
    code: 4918,
    division_type: "phường",
    codename: "phuong_trung_minh",
    district_code: 148,
  },
  {
    name: "Xã Độc Lập",
    code: 4921,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 148,
  },
  {
    name: "Thị trấn Đà Bắc",
    code: 4831,
    division_type: "thị trấn",
    codename: "thi_tran_da_bac",
    district_code: 150,
  },
  {
    name: "Xã Nánh Nghê",
    code: 4834,
    division_type: "xã",
    codename: "xa_nanh_nghe",
    district_code: 150,
  },
  {
    name: "Xã Giáp Đắt",
    code: 4840,
    division_type: "xã",
    codename: "xa_giap_dat",
    district_code: 150,
  },
  {
    name: "Xã Mường Chiềng",
    code: 4846,
    division_type: "xã",
    codename: "xa_muong_chieng",
    district_code: 150,
  },
  {
    name: "Xã Tân Pheo",
    code: 4849,
    division_type: "xã",
    codename: "xa_tan_pheo",
    district_code: 150,
  },
  {
    name: "Xã Đồng Chum",
    code: 4852,
    division_type: "xã",
    codename: "xa_dong_chum",
    district_code: 150,
  },
  {
    name: "Xã Tân Minh",
    code: 4855,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 150,
  },
  {
    name: "Xã Đoàn Kết",
    code: 4858,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 150,
  },
  {
    name: "Xã Đồng Ruộng",
    code: 4861,
    division_type: "xã",
    codename: "xa_dong_ruong",
    district_code: 150,
  },
  {
    name: "Xã Tú Lý",
    code: 4867,
    division_type: "xã",
    codename: "xa_tu_ly",
    district_code: 150,
  },
  {
    name: "Xã Trung Thành",
    code: 4870,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 150,
  },
  {
    name: "Xã Yên Hòa",
    code: 4873,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 150,
  },
  {
    name: "Xã Cao Sơn",
    code: 4876,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 150,
  },
  {
    name: "Xã Toàn Sơn",
    code: 4879,
    division_type: "xã",
    codename: "xa_toan_son",
    district_code: 150,
  },
  {
    name: "Xã Hiền Lương",
    code: 4885,
    division_type: "xã",
    codename: "xa_hien_luong",
    district_code: 150,
  },
  {
    name: "Xã Tiền Phong",
    code: 4888,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 150,
  },
  {
    name: "Xã Vầy Nưa",
    code: 4891,
    division_type: "xã",
    codename: "xa_vay_nua",
    district_code: 150,
  },
  {
    name: "Thị trấn Lương Sơn",
    code: 4924,
    division_type: "thị trấn",
    codename: "thi_tran_luong_son",
    district_code: 152,
  },
  {
    name: "Xã Lâm Sơn",
    code: 4942,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 152,
  },
  {
    name: "Xã Hòa Sơn",
    code: 4945,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 152,
  },
  {
    name: "Xã Tân Vinh",
    code: 4951,
    division_type: "xã",
    codename: "xa_tan_vinh",
    district_code: 152,
  },
  {
    name: "Xã Nhuận Trạch",
    code: 4954,
    division_type: "xã",
    codename: "xa_nhuan_trach",
    district_code: 152,
  },
  {
    name: "Xã Cao Sơn",
    code: 4957,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 152,
  },
  {
    name: "Xã Cư Yên",
    code: 4960,
    division_type: "xã",
    codename: "xa_cu_yen",
    district_code: 152,
  },
  {
    name: "Xã Liên Sơn",
    code: 4969,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 152,
  },
  {
    name: "Xã Cao Dương",
    code: 5008,
    division_type: "xã",
    codename: "xa_cao_duong",
    district_code: 152,
  },
  {
    name: "Xã Thanh Sơn",
    code: 5041,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 152,
  },
  {
    name: "Xã Thanh Cao",
    code: 5047,
    division_type: "xã",
    codename: "xa_thanh_cao",
    district_code: 152,
  },
  {
    name: "Thị trấn Bo",
    code: 4978,
    division_type: "thị trấn",
    codename: "thi_tran_bo",
    district_code: 153,
  },
  {
    name: "Xã Đú Sáng",
    code: 4984,
    division_type: "xã",
    codename: "xa_du_sang",
    district_code: 153,
  },
  {
    name: "Xã Hùng Sơn",
    code: 4987,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 153,
  },
  {
    name: "Xã Bình Sơn",
    code: 4990,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 153,
  },
  {
    name: "Xã Tú Sơn",
    code: 4999,
    division_type: "xã",
    codename: "xa_tu_son",
    district_code: 153,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 5005,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 153,
  },
  {
    name: "Xã Đông Bắc",
    code: 5014,
    division_type: "xã",
    codename: "xa_dong_bac",
    district_code: 153,
  },
  {
    name: "Xã Xuân Thủy",
    code: 5017,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 153,
  },
  {
    name: "Xã Vĩnh Đồng",
    code: 5026,
    division_type: "xã",
    codename: "xa_vinh_dong",
    district_code: 153,
  },
  {
    name: "Xã Kim Lập",
    code: 5035,
    division_type: "xã",
    codename: "xa_kim_lap",
    district_code: 153,
  },
  {
    name: "Xã Hợp Tiến",
    code: 5038,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 153,
  },
  {
    name: "Xã Kim Bôi",
    code: 5065,
    division_type: "xã",
    codename: "xa_kim_boi",
    district_code: 153,
  },
  {
    name: "Xã Nam Thượng",
    code: 5068,
    division_type: "xã",
    codename: "xa_nam_thuong",
    district_code: 153,
  },
  {
    name: "Xã Cuối Hạ",
    code: 5077,
    division_type: "xã",
    codename: "xa_cuoi_ha",
    district_code: 153,
  },
  {
    name: "Xã Sào Báy",
    code: 5080,
    division_type: "xã",
    codename: "xa_sao_bay",
    district_code: 153,
  },
  {
    name: "Xã Mi Hòa",
    code: 5083,
    division_type: "xã",
    codename: "xa_mi_hoa",
    district_code: 153,
  },
  {
    name: "Xã Nuông Dăm",
    code: 5086,
    division_type: "xã",
    codename: "xa_nuong_dam",
    district_code: 153,
  },
  {
    name: "Thị trấn Cao Phong",
    code: 5089,
    division_type: "thị trấn",
    codename: "thi_tran_cao_phong",
    district_code: 154,
  },
  {
    name: "Xã Bình Thanh",
    code: 5092,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 154,
  },
  {
    name: "Xã Thung Nai",
    code: 5095,
    division_type: "xã",
    codename: "xa_thung_nai",
    district_code: 154,
  },
  {
    name: "Xã Bắc Phong",
    code: 5098,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 154,
  },
  {
    name: "Xã Thu Phong",
    code: 5101,
    division_type: "xã",
    codename: "xa_thu_phong",
    district_code: 154,
  },
  {
    name: "Xã Hợp Phong",
    code: 5104,
    division_type: "xã",
    codename: "xa_hop_phong",
    district_code: 154,
  },
  {
    name: "Xã Tây Phong",
    code: 5110,
    division_type: "xã",
    codename: "xa_tay_phong",
    district_code: 154,
  },
  {
    name: "Xã Dũng Phong",
    code: 5116,
    division_type: "xã",
    codename: "xa_dung_phong",
    district_code: 154,
  },
  {
    name: "Xã Nam Phong",
    code: 5119,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 154,
  },
  {
    name: "Xã Thạch Yên",
    code: 5125,
    division_type: "xã",
    codename: "xa_thach_yen",
    district_code: 154,
  },
  {
    name: "Thị trấn Mãn Đức",
    code: 5128,
    division_type: "thị trấn",
    codename: "thi_tran_man_duc",
    district_code: 155,
  },
  {
    name: "Xã Suối Hoa",
    code: 5134,
    division_type: "xã",
    codename: "xa_suoi_hoa",
    district_code: 155,
  },
  {
    name: "Xã Phú Vinh",
    code: 5137,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 155,
  },
  {
    name: "Xã Phú Cường",
    code: 5140,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 155,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 5143,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 155,
  },
  {
    name: "Xã Quyết Chiến",
    code: 5152,
    division_type: "xã",
    codename: "xa_quyet_chien",
    district_code: 155,
  },
  {
    name: "Xã Phong Phú",
    code: 5158,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 155,
  },
  {
    name: "Xã Tử Nê",
    code: 5164,
    division_type: "xã",
    codename: "xa_tu_ne",
    district_code: 155,
  },
  {
    name: "Xã Thanh Hối",
    code: 5167,
    division_type: "xã",
    codename: "xa_thanh_hoi",
    district_code: 155,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 5170,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 155,
  },
  {
    name: "Xã Đông Lai",
    code: 5173,
    division_type: "xã",
    codename: "xa_dong_lai",
    district_code: 155,
  },
  {
    name: "Xã Vân Sơn",
    code: 5176,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 155,
  },
  {
    name: "Xã Nhân Mỹ",
    code: 5182,
    division_type: "xã",
    codename: "xa_nhan_my",
    district_code: 155,
  },
  {
    name: "Xã Lỗ Sơn",
    code: 5191,
    division_type: "xã",
    codename: "xa_lo_son",
    district_code: 155,
  },
  {
    name: "Xã Ngổ Luông",
    code: 5194,
    division_type: "xã",
    codename: "xa_ngo_luong",
    district_code: 155,
  },
  {
    name: "Xã Gia Mô",
    code: 5197,
    division_type: "xã",
    codename: "xa_gia_mo",
    district_code: 155,
  },
  {
    name: "Xã Tân Thành",
    code: 4882,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 156,
  },
  {
    name: "Thị trấn Mai Châu",
    code: 5200,
    division_type: "thị trấn",
    codename: "thi_tran_mai_chau",
    district_code: 156,
  },
  {
    name: "Xã Sơn Thủy",
    code: 5206,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 156,
  },
  {
    name: "Xã Pà Cò",
    code: 5209,
    division_type: "xã",
    codename: "xa_pa_co",
    district_code: 156,
  },
  {
    name: "Xã Hang Kia",
    code: 5212,
    division_type: "xã",
    codename: "xa_hang_kia",
    district_code: 156,
  },
  {
    name: "Xã Đồng Tân",
    code: 5221,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 156,
  },
  {
    name: "Xã Cun Pheo",
    code: 5224,
    division_type: "xã",
    codename: "xa_cun_pheo",
    district_code: 156,
  },
  {
    name: "Xã Bao La",
    code: 5227,
    division_type: "xã",
    codename: "xa_bao_la",
    district_code: 156,
  },
  {
    name: "Xã Tòng Đậu",
    code: 5233,
    division_type: "xã",
    codename: "xa_tong_dau",
    district_code: 156,
  },
  {
    name: "Xã Nà Phòn",
    code: 5242,
    division_type: "xã",
    codename: "xa_na_phon",
    district_code: 156,
  },
  {
    name: "Xã Săm Khóe",
    code: 5245,
    division_type: "xã",
    codename: "xa_sam_khoe",
    district_code: 156,
  },
  {
    name: "Xã Chiềng Châu",
    code: 5248,
    division_type: "xã",
    codename: "xa_chieng_chau",
    district_code: 156,
  },
  {
    name: "Xã Mai Hạ",
    code: 5251,
    division_type: "xã",
    codename: "xa_mai_ha",
    district_code: 156,
  },
  {
    name: "Xã Thành Sơn",
    code: 5254,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 156,
  },
  {
    name: "Xã Mai Hịch",
    code: 5257,
    division_type: "xã",
    codename: "xa_mai_hich",
    district_code: 156,
  },
  {
    name: "Xã Vạn Mai",
    code: 5263,
    division_type: "xã",
    codename: "xa_van_mai",
    district_code: 156,
  },
  {
    name: "Thị trấn Vụ Bản",
    code: 5266,
    division_type: "thị trấn",
    codename: "thi_tran_vu_ban",
    district_code: 157,
  },
  {
    name: "Xã Quý Hòa",
    code: 5269,
    division_type: "xã",
    codename: "xa_quy_hoa",
    district_code: 157,
  },
  {
    name: "Xã Miền Đồi",
    code: 5272,
    division_type: "xã",
    codename: "xa_mien_doi",
    district_code: 157,
  },
  {
    name: "Xã Mỹ Thành",
    code: 5275,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 157,
  },
  {
    name: "Xã Tuân Đạo",
    code: 5278,
    division_type: "xã",
    codename: "xa_tuan_dao",
    district_code: 157,
  },
  {
    name: "Xã Văn Nghĩa",
    code: 5281,
    division_type: "xã",
    codename: "xa_van_nghia",
    district_code: 157,
  },
  {
    name: "Xã Văn Sơn",
    code: 5284,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 157,
  },
  {
    name: "Xã Tân Lập",
    code: 5287,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 157,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 5290,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 157,
  },
  {
    name: "Xã Thượng Cốc",
    code: 5293,
    division_type: "xã",
    codename: "xa_thuong_coc",
    district_code: 157,
  },
  {
    name: "Xã Quyết Thắng",
    code: 5299,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 157,
  },
  {
    name: "Xã Xuất Hóa",
    code: 5302,
    division_type: "xã",
    codename: "xa_xuat_hoa",
    district_code: 157,
  },
  {
    name: "Xã Yên Phú",
    code: 5305,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 157,
  },
  {
    name: "Xã Bình Hẻm",
    code: 5308,
    division_type: "xã",
    codename: "xa_binh_hem",
    district_code: 157,
  },
  {
    name: "Xã Định Cư",
    code: 5320,
    division_type: "xã",
    codename: "xa_dinh_cu",
    district_code: 157,
  },
  {
    name: "Xã Chí Đạo",
    code: 5323,
    division_type: "xã",
    codename: "xa_chi_dao",
    district_code: 157,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 5329,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 157,
  },
  {
    name: "Xã Hương Nhượng",
    code: 5332,
    division_type: "xã",
    codename: "xa_huong_nhuong",
    district_code: 157,
  },
  {
    name: "Xã Vũ Bình",
    code: 5335,
    division_type: "xã",
    codename: "xa_vu_binh",
    district_code: 157,
  },
  {
    name: "Xã Tự Do",
    code: 5338,
    division_type: "xã",
    codename: "xa_tu_do",
    district_code: 157,
  },
  {
    name: "Xã Yên Nghiệp",
    code: 5341,
    division_type: "xã",
    codename: "xa_yen_nghiep",
    district_code: 157,
  },
  {
    name: "Xã Tân Mỹ",
    code: 5344,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 157,
  },
  {
    name: "Xã Ân Nghĩa",
    code: 5347,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 157,
  },
  {
    name: "Xã Ngọc Lâu",
    code: 5350,
    division_type: "xã",
    codename: "xa_ngoc_lau",
    district_code: 157,
  },
  {
    name: "Thị trấn Hàng Trạm",
    code: 5353,
    division_type: "thị trấn",
    codename: "thi_tran_hang_tram",
    district_code: 158,
  },
  {
    name: "Xã Lạc Sỹ",
    code: 5356,
    division_type: "xã",
    codename: "xa_lac_sy",
    district_code: 158,
  },
  {
    name: "Xã Lạc Lương",
    code: 5362,
    division_type: "xã",
    codename: "xa_lac_luong",
    district_code: 158,
  },
  {
    name: "Xã Bảo Hiệu",
    code: 5365,
    division_type: "xã",
    codename: "xa_bao_hieu",
    district_code: 158,
  },
  {
    name: "Xã Đa Phúc",
    code: 5368,
    division_type: "xã",
    codename: "xa_da_phuc",
    district_code: 158,
  },
  {
    name: "Xã Hữu Lợi",
    code: 5371,
    division_type: "xã",
    codename: "xa_huu_loi",
    district_code: 158,
  },
  {
    name: "Xã Lạc Thịnh",
    code: 5374,
    division_type: "xã",
    codename: "xa_lac_thinh",
    district_code: 158,
  },
  {
    name: "Xã Đoàn Kết",
    code: 5380,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 158,
  },
  {
    name: "Xã Phú Lai",
    code: 5383,
    division_type: "xã",
    codename: "xa_phu_lai",
    district_code: 158,
  },
  {
    name: "Xã Yên Trị",
    code: 5386,
    division_type: "xã",
    codename: "xa_yen_tri",
    district_code: 158,
  },
  {
    name: "Xã Ngọc Lương",
    code: 5389,
    division_type: "xã",
    codename: "xa_ngoc_luong",
    district_code: 158,
  },
  {
    name: "Thị trấn Ba Hàng Đồi",
    code: 4981,
    division_type: "thị trấn",
    codename: "thi_tran_ba_hang_doi",
    district_code: 159,
  },
  {
    name: "Thị trấn Chi Nê",
    code: 5392,
    division_type: "thị trấn",
    codename: "thi_tran_chi_ne",
    district_code: 159,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 5395,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 159,
  },
  {
    name: "Xã Phú Thành",
    code: 5398,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 159,
  },
  {
    name: "Xã Hưng Thi",
    code: 5404,
    division_type: "xã",
    codename: "xa_hung_thi",
    district_code: 159,
  },
  {
    name: "Xã Khoan Dụ",
    code: 5413,
    division_type: "xã",
    codename: "xa_khoan_du",
    district_code: 159,
  },
  {
    name: "Xã Đồng Tâm",
    code: 5419,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 159,
  },
  {
    name: "Xã Yên Bồng",
    code: 5422,
    division_type: "xã",
    codename: "xa_yen_bong",
    district_code: 159,
  },
  {
    name: "Xã Thống Nhất",
    code: 5425,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 159,
  },
  {
    name: "Xã An Bình",
    code: 5428,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 159,
  },
  {
    name: "Phường Quán Triều",
    code: 5431,
    division_type: "phường",
    codename: "phuong_quan_trieu",
    district_code: 164,
  },
  {
    name: "Phường Quang Vinh",
    code: 5434,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 164,
  },
  {
    name: "Phường Túc Duyên",
    code: 5437,
    division_type: "phường",
    codename: "phuong_tuc_duyen",
    district_code: 164,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 5440,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 164,
  },
  {
    name: "Phường Trưng Vương",
    code: 5443,
    division_type: "phường",
    codename: "phuong_trung_vuong",
    district_code: 164,
  },
  {
    name: "Phường Quang Trung",
    code: 5446,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 164,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 5449,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 164,
  },
  {
    name: "Phường Tân Thịnh",
    code: 5452,
    division_type: "phường",
    codename: "phuong_tan_thinh",
    district_code: 164,
  },
  {
    name: "Phường Thịnh Đán",
    code: 5455,
    division_type: "phường",
    codename: "phuong_thinh_dan",
    district_code: 164,
  },
  {
    name: "Phường Đồng Quang",
    code: 5458,
    division_type: "phường",
    codename: "phuong_dong_quang",
    district_code: 164,
  },
  {
    name: "Phường Gia Sàng",
    code: 5461,
    division_type: "phường",
    codename: "phuong_gia_sang",
    district_code: 164,
  },
  {
    name: "Phường Tân Lập",
    code: 5464,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 164,
  },
  {
    name: "Phường Cam Giá",
    code: 5467,
    division_type: "phường",
    codename: "phuong_cam_gia",
    district_code: 164,
  },
  {
    name: "Phường Phú Xá",
    code: 5470,
    division_type: "phường",
    codename: "phuong_phu_xa",
    district_code: 164,
  },
  {
    name: "Phường Hương Sơn",
    code: 5473,
    division_type: "phường",
    codename: "phuong_huong_son",
    district_code: 164,
  },
  {
    name: "Phường Trung Thành",
    code: 5476,
    division_type: "phường",
    codename: "phuong_trung_thanh",
    district_code: 164,
  },
  {
    name: "Phường Tân Thành",
    code: 5479,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 164,
  },
  {
    name: "Phường Tân Long",
    code: 5482,
    division_type: "phường",
    codename: "phuong_tan_long",
    district_code: 164,
  },
  {
    name: "Xã Phúc Hà",
    code: 5485,
    division_type: "xã",
    codename: "xa_phuc_ha",
    district_code: 164,
  },
  {
    name: "Xã Phúc Xuân",
    code: 5488,
    division_type: "xã",
    codename: "xa_phuc_xuan",
    district_code: 164,
  },
  {
    name: "Xã Quyết Thắng",
    code: 5491,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 164,
  },
  {
    name: "Xã Phúc Trìu",
    code: 5494,
    division_type: "xã",
    codename: "xa_phuc_triu",
    district_code: 164,
  },
  {
    name: "Xã Thịnh Đức",
    code: 5497,
    division_type: "xã",
    codename: "xa_thinh_duc",
    district_code: 164,
  },
  {
    name: "Phường Tích Lương",
    code: 5500,
    division_type: "phường",
    codename: "phuong_tich_luong",
    district_code: 164,
  },
  {
    name: "Xã Tân Cương",
    code: 5503,
    division_type: "xã",
    codename: "xa_tan_cuong",
    district_code: 164,
  },
  {
    name: "Xã Sơn Cẩm",
    code: 5653,
    division_type: "xã",
    codename: "xa_son_cam",
    district_code: 164,
  },
  {
    name: "Phường Chùa Hang",
    code: 5659,
    division_type: "phường",
    codename: "phuong_chua_hang",
    district_code: 164,
  },
  {
    name: "Xã Cao Ngạn",
    code: 5695,
    division_type: "xã",
    codename: "xa_cao_ngan",
    district_code: 164,
  },
  {
    name: "Xã Linh Sơn",
    code: 5701,
    division_type: "xã",
    codename: "xa_linh_son",
    district_code: 164,
  },
  {
    name: "Phường Đồng Bẩm",
    code: 5710,
    division_type: "phường",
    codename: "phuong_dong_bam",
    district_code: 164,
  },
  {
    name: "Xã Huống Thượng",
    code: 5713,
    division_type: "xã",
    codename: "xa_huong_thuong",
    district_code: 164,
  },
  {
    name: "Xã Đồng Liên",
    code: 5914,
    division_type: "xã",
    codename: "xa_dong_lien",
    district_code: 164,
  },
  {
    name: "Phường Lương Sơn",
    code: 5506,
    division_type: "phường",
    codename: "phuong_luong_son",
    district_code: 165,
  },
  {
    name: "Phường Châu Sơn",
    code: 5509,
    division_type: "phường",
    codename: "phuong_chau_son",
    district_code: 165,
  },
  {
    name: "Phường Mỏ Chè",
    code: 5512,
    division_type: "phường",
    codename: "phuong_mo_che",
    district_code: 165,
  },
  {
    name: "Phường Cải Đan",
    code: 5515,
    division_type: "phường",
    codename: "phuong_cai_dan",
    district_code: 165,
  },
  {
    name: "Phường Thắng Lợi",
    code: 5518,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 165,
  },
  {
    name: "Phường Phố Cò",
    code: 5521,
    division_type: "phường",
    codename: "phuong_pho_co",
    district_code: 165,
  },
  {
    name: "Xã Tân Quang",
    code: 5527,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 165,
  },
  {
    name: "Phường Bách Quang",
    code: 5528,
    division_type: "phường",
    codename: "phuong_bach_quang",
    district_code: 165,
  },
  {
    name: "Xã Bình Sơn",
    code: 5530,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 165,
  },
  {
    name: "Xã Bá Xuyên",
    code: 5533,
    division_type: "xã",
    codename: "xa_ba_xuyen",
    district_code: 165,
  },
  {
    name: "Thị trấn Chợ Chu",
    code: 5536,
    division_type: "thị trấn",
    codename: "thi_tran_cho_chu",
    district_code: 167,
  },
  {
    name: "Xã Linh Thông",
    code: 5539,
    division_type: "xã",
    codename: "xa_linh_thong",
    district_code: 167,
  },
  {
    name: "Xã Lam Vỹ",
    code: 5542,
    division_type: "xã",
    codename: "xa_lam_vy",
    district_code: 167,
  },
  {
    name: "Xã Quy Kỳ",
    code: 5545,
    division_type: "xã",
    codename: "xa_quy_ky",
    district_code: 167,
  },
  {
    name: "Xã Tân Thịnh",
    code: 5548,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 167,
  },
  {
    name: "Xã Kim Phượng",
    code: 5551,
    division_type: "xã",
    codename: "xa_kim_phuong",
    district_code: 167,
  },
  {
    name: "Xã Bảo Linh",
    code: 5554,
    division_type: "xã",
    codename: "xa_bao_linh",
    district_code: 167,
  },
  {
    name: "Xã Phúc Chu",
    code: 5560,
    division_type: "xã",
    codename: "xa_phuc_chu",
    district_code: 167,
  },
  {
    name: "Xã Tân Dương",
    code: 5563,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 167,
  },
  {
    name: "Xã Phượng Tiến",
    code: 5566,
    division_type: "xã",
    codename: "xa_phuong_tien",
    district_code: 167,
  },
  {
    name: "Xã Bảo Cường",
    code: 5569,
    division_type: "xã",
    codename: "xa_bao_cuong",
    district_code: 167,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 5572,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 167,
  },
  {
    name: "Xã Định Biên",
    code: 5575,
    division_type: "xã",
    codename: "xa_dinh_bien",
    district_code: 167,
  },
  {
    name: "Xã Thanh Định",
    code: 5578,
    division_type: "xã",
    codename: "xa_thanh_dinh",
    district_code: 167,
  },
  {
    name: "Xã Trung Hội",
    code: 5581,
    division_type: "xã",
    codename: "xa_trung_hoi",
    district_code: 167,
  },
  {
    name: "Xã Trung Lương",
    code: 5584,
    division_type: "xã",
    codename: "xa_trung_luong",
    district_code: 167,
  },
  {
    name: "Xã Bình Yên",
    code: 5587,
    division_type: "xã",
    codename: "xa_binh_yen",
    district_code: 167,
  },
  {
    name: "Xã Điềm Mặc",
    code: 5590,
    division_type: "xã",
    codename: "xa_diem_mac",
    district_code: 167,
  },
  {
    name: "Xã Phú Tiến",
    code: 5593,
    division_type: "xã",
    codename: "xa_phu_tien",
    district_code: 167,
  },
  {
    name: "Xã Bộc Nhiêu",
    code: 5596,
    division_type: "xã",
    codename: "xa_boc_nhieu",
    district_code: 167,
  },
  {
    name: "Xã Sơn Phú",
    code: 5599,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 167,
  },
  {
    name: "Xã Phú Đình",
    code: 5602,
    division_type: "xã",
    codename: "xa_phu_dinh",
    district_code: 167,
  },
  {
    name: "Xã Bình Thành",
    code: 5605,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 167,
  },
  {
    name: "Thị trấn Giang Tiên",
    code: 5608,
    division_type: "thị trấn",
    codename: "thi_tran_giang_tien",
    district_code: 168,
  },
  {
    name: "Thị trấn Đu",
    code: 5611,
    division_type: "thị trấn",
    codename: "thi_tran_du",
    district_code: 168,
  },
  {
    name: "Xã Yên Ninh",
    code: 5614,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 168,
  },
  {
    name: "Xã Yên Trạch",
    code: 5617,
    division_type: "xã",
    codename: "xa_yen_trach",
    district_code: 168,
  },
  {
    name: "Xã Yên Đổ",
    code: 5620,
    division_type: "xã",
    codename: "xa_yen_do",
    district_code: 168,
  },
  {
    name: "Xã Yên Lạc",
    code: 5623,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 168,
  },
  {
    name: "Xã Ôn Lương",
    code: 5626,
    division_type: "xã",
    codename: "xa_on_luong",
    district_code: 168,
  },
  {
    name: "Xã Động Đạt",
    code: 5629,
    division_type: "xã",
    codename: "xa_dong_dat",
    district_code: 168,
  },
  {
    name: "Xã Phủ Lý",
    code: 5632,
    division_type: "xã",
    codename: "xa_phu_ly",
    district_code: 168,
  },
  {
    name: "Xã Phú Đô",
    code: 5635,
    division_type: "xã",
    codename: "xa_phu_do",
    district_code: 168,
  },
  {
    name: "Xã Hợp Thành",
    code: 5638,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 168,
  },
  {
    name: "Xã Tức Tranh",
    code: 5641,
    division_type: "xã",
    codename: "xa_tuc_tranh",
    district_code: 168,
  },
  {
    name: "Xã Phấn Mễ",
    code: 5644,
    division_type: "xã",
    codename: "xa_phan_me",
    district_code: 168,
  },
  {
    name: "Xã Vô Tranh",
    code: 5647,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 168,
  },
  {
    name: "Xã Cổ Lũng",
    code: 5650,
    division_type: "xã",
    codename: "xa_co_lung",
    district_code: 168,
  },
  {
    name: "Thị trấn Sông Cầu",
    code: 5656,
    division_type: "thị trấn",
    codename: "thi_tran_song_cau",
    district_code: 169,
  },
  {
    name: "Thị trấn Trại Cau",
    code: 5662,
    division_type: "thị trấn",
    codename: "thi_tran_trai_cau",
    district_code: 169,
  },
  {
    name: "Xã Văn Lăng",
    code: 5665,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 169,
  },
  {
    name: "Xã Tân Long",
    code: 5668,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 169,
  },
  {
    name: "Xã Hòa Bình",
    code: 5671,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 169,
  },
  {
    name: "Xã Quang Sơn",
    code: 5674,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 169,
  },
  {
    name: "Xã Minh Lập",
    code: 5677,
    division_type: "xã",
    codename: "xa_minh_lap",
    district_code: 169,
  },
  {
    name: "Xã Văn Hán",
    code: 5680,
    division_type: "xã",
    codename: "xa_van_han",
    district_code: 169,
  },
  {
    name: "Xã Hóa Trung",
    code: 5683,
    division_type: "xã",
    codename: "xa_hoa_trung",
    district_code: 169,
  },
  {
    name: "Xã Khe Mo",
    code: 5686,
    division_type: "xã",
    codename: "xa_khe_mo",
    district_code: 169,
  },
  {
    name: "Xã Cây Thị",
    code: 5689,
    division_type: "xã",
    codename: "xa_cay_thi",
    district_code: 169,
  },
  {
    name: "Xã Hóa Thượng",
    code: 5692,
    division_type: "xã",
    codename: "xa_hoa_thuong",
    district_code: 169,
  },
  {
    name: "Xã Hợp Tiến",
    code: 5698,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 169,
  },
  {
    name: "Xã Tân Lợi",
    code: 5704,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 169,
  },
  {
    name: "Xã Nam Hòa",
    code: 5707,
    division_type: "xã",
    codename: "xa_nam_hoa",
    district_code: 169,
  },
  {
    name: "Thị trấn Đình Cả",
    code: 5716,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_ca",
    district_code: 170,
  },
  {
    name: "Xã Sảng Mộc",
    code: 5719,
    division_type: "xã",
    codename: "xa_sang_moc",
    district_code: 170,
  },
  {
    name: "Xã Nghinh Tường",
    code: 5722,
    division_type: "xã",
    codename: "xa_nghinh_tuong",
    district_code: 170,
  },
  {
    name: "Xã Thần Xa",
    code: 5725,
    division_type: "xã",
    codename: "xa_than_xa",
    district_code: 170,
  },
  {
    name: "Xã Vũ Chấn",
    code: 5728,
    division_type: "xã",
    codename: "xa_vu_chan",
    district_code: 170,
  },
  {
    name: "Xã Thượng Nung",
    code: 5731,
    division_type: "xã",
    codename: "xa_thuong_nung",
    district_code: 170,
  },
  {
    name: "Xã Phú Thượng",
    code: 5734,
    division_type: "xã",
    codename: "xa_phu_thuong",
    district_code: 170,
  },
  {
    name: "Xã Cúc Đường",
    code: 5737,
    division_type: "xã",
    codename: "xa_cuc_duong",
    district_code: 170,
  },
  {
    name: "Xã La Hiên",
    code: 5740,
    division_type: "xã",
    codename: "xa_la_hien",
    district_code: 170,
  },
  {
    name: "Xã Lâu Thượng",
    code: 5743,
    division_type: "xã",
    codename: "xa_lau_thuong",
    district_code: 170,
  },
  {
    name: "Xã Tràng Xá",
    code: 5746,
    division_type: "xã",
    codename: "xa_trang_xa",
    district_code: 170,
  },
  {
    name: "Xã Phương Giao",
    code: 5749,
    division_type: "xã",
    codename: "xa_phuong_giao",
    district_code: 170,
  },
  {
    name: "Xã Liên Minh",
    code: 5752,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 170,
  },
  {
    name: "Xã Dân Tiến",
    code: 5755,
    division_type: "xã",
    codename: "xa_dan_tien",
    district_code: 170,
  },
  {
    name: "Xã Bình Long",
    code: 5758,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 170,
  },
  {
    name: "Thị trấn Hùng Sơn",
    code: 5761,
    division_type: "thị trấn",
    codename: "thi_tran_hung_son",
    district_code: 171,
  },
  {
    name: "Thị trấn Quân Chu",
    code: 5764,
    division_type: "thị trấn",
    codename: "thi_tran_quan_chu",
    district_code: 171,
  },
  {
    name: "Xã Phúc Lương",
    code: 5767,
    division_type: "xã",
    codename: "xa_phuc_luong",
    district_code: 171,
  },
  {
    name: "Xã Minh Tiến",
    code: 5770,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 171,
  },
  {
    name: "Xã Yên Lãng",
    code: 5773,
    division_type: "xã",
    codename: "xa_yen_lang",
    district_code: 171,
  },
  {
    name: "Xã Đức Lương",
    code: 5776,
    division_type: "xã",
    codename: "xa_duc_luong",
    district_code: 171,
  },
  {
    name: "Xã Phú Cường",
    code: 5779,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 171,
  },
  {
    name: "Xã Na Mao",
    code: 5782,
    division_type: "xã",
    codename: "xa_na_mao",
    district_code: 171,
  },
  {
    name: "Xã Phú Lạc",
    code: 5785,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 171,
  },
  {
    name: "Xã Tân Linh",
    code: 5788,
    division_type: "xã",
    codename: "xa_tan_linh",
    district_code: 171,
  },
  {
    name: "Xã Phú Thịnh",
    code: 5791,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 171,
  },
  {
    name: "Xã Phục Linh",
    code: 5794,
    division_type: "xã",
    codename: "xa_phuc_linh",
    district_code: 171,
  },
  {
    name: "Xã Phú Xuyên",
    code: 5797,
    division_type: "xã",
    codename: "xa_phu_xuyen",
    district_code: 171,
  },
  {
    name: "Xã Bản Ngoại",
    code: 5800,
    division_type: "xã",
    codename: "xa_ban_ngoai",
    district_code: 171,
  },
  {
    name: "Xã Tiên Hội",
    code: 5803,
    division_type: "xã",
    codename: "xa_tien_hoi",
    district_code: 171,
  },
  {
    name: "Xã Cù Vân",
    code: 5809,
    division_type: "xã",
    codename: "xa_cu_van",
    district_code: 171,
  },
  {
    name: "Xã Hà Thượng",
    code: 5812,
    division_type: "xã",
    codename: "xa_ha_thuong",
    district_code: 171,
  },
  {
    name: "Xã La Bằng",
    code: 5815,
    division_type: "xã",
    codename: "xa_la_bang",
    district_code: 171,
  },
  {
    name: "Xã Hoàng Nông",
    code: 5818,
    division_type: "xã",
    codename: "xa_hoang_nong",
    district_code: 171,
  },
  {
    name: "Xã Khôi Kỳ",
    code: 5821,
    division_type: "xã",
    codename: "xa_khoi_ky",
    district_code: 171,
  },
  {
    name: "Xã An Khánh",
    code: 5824,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 171,
  },
  {
    name: "Xã Tân Thái",
    code: 5827,
    division_type: "xã",
    codename: "xa_tan_thai",
    district_code: 171,
  },
  {
    name: "Xã Bình Thuận",
    code: 5830,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 171,
  },
  {
    name: "Xã Lục Ba",
    code: 5833,
    division_type: "xã",
    codename: "xa_luc_ba",
    district_code: 171,
  },
  {
    name: "Xã Mỹ Yên",
    code: 5836,
    division_type: "xã",
    codename: "xa_my_yen",
    district_code: 171,
  },
  {
    name: "Xã Vạn Thọ",
    code: 5839,
    division_type: "xã",
    codename: "xa_van_tho",
    district_code: 171,
  },
  {
    name: "Xã Văn Yên",
    code: 5842,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 171,
  },
  {
    name: "Xã Ký Phú",
    code: 5845,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 171,
  },
  {
    name: "Xã Cát Nê",
    code: 5848,
    division_type: "xã",
    codename: "xa_cat_ne",
    district_code: 171,
  },
  {
    name: "Xã Quân Chu",
    code: 5851,
    division_type: "xã",
    codename: "xa_quan_chu",
    district_code: 171,
  },
  {
    name: "Phường Bãi Bông",
    code: 5854,
    division_type: "phường",
    codename: "phuong_bai_bong",
    district_code: 172,
  },
  {
    name: "Phường Bắc Sơn",
    code: 5857,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 172,
  },
  {
    name: "Phường Ba Hàng",
    code: 5860,
    division_type: "phường",
    codename: "phuong_ba_hang",
    district_code: 172,
  },
  {
    name: "Xã Phúc Tân",
    code: 5863,
    division_type: "xã",
    codename: "xa_phuc_tan",
    district_code: 172,
  },
  {
    name: "Xã Phúc Thuận",
    code: 5866,
    division_type: "xã",
    codename: "xa_phuc_thuan",
    district_code: 172,
  },
  {
    name: "Xã Hồng Tiến",
    code: 5869,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 172,
  },
  {
    name: "Xã Minh Đức",
    code: 5872,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 172,
  },
  {
    name: "Xã Đắc Sơn",
    code: 5875,
    division_type: "xã",
    codename: "xa_dac_son",
    district_code: 172,
  },
  {
    name: "Phường Đồng Tiến",
    code: 5878,
    division_type: "phường",
    codename: "phuong_dong_tien",
    district_code: 172,
  },
  {
    name: "Xã Thành Công",
    code: 5881,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 172,
  },
  {
    name: "Xã Tiên Phong",
    code: 5884,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 172,
  },
  {
    name: "Xã Vạn Phái",
    code: 5887,
    division_type: "xã",
    codename: "xa_van_phai",
    district_code: 172,
  },
  {
    name: "Xã Nam Tiến",
    code: 5890,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 172,
  },
  {
    name: "Xã Tân Hương",
    code: 5893,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 172,
  },
  {
    name: "Xã Đông Cao",
    code: 5896,
    division_type: "xã",
    codename: "xa_dong_cao",
    district_code: 172,
  },
  {
    name: "Xã Trung Thành",
    code: 5899,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 172,
  },
  {
    name: "Xã Tân Phú",
    code: 5902,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 172,
  },
  {
    name: "Xã Thuận Thành",
    code: 5905,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 172,
  },
  {
    name: "Thị trấn Hương Sơn",
    code: 5908,
    division_type: "thị trấn",
    codename: "thi_tran_huong_son",
    district_code: 173,
  },
  {
    name: "Xã Bàn Đạt",
    code: 5911,
    division_type: "xã",
    codename: "xa_ban_dat",
    district_code: 173,
  },
  {
    name: "Xã Tân Khánh",
    code: 5917,
    division_type: "xã",
    codename: "xa_tan_khanh",
    district_code: 173,
  },
  {
    name: "Xã Tân Kim",
    code: 5920,
    division_type: "xã",
    codename: "xa_tan_kim",
    district_code: 173,
  },
  {
    name: "Xã Tân Thành",
    code: 5923,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 173,
  },
  {
    name: "Xã Đào Xá",
    code: 5926,
    division_type: "xã",
    codename: "xa_dao_xa",
    district_code: 173,
  },
  {
    name: "Xã Bảo Lý",
    code: 5929,
    division_type: "xã",
    codename: "xa_bao_ly",
    district_code: 173,
  },
  {
    name: "Xã Thượng Đình",
    code: 5932,
    division_type: "xã",
    codename: "xa_thuong_dinh",
    district_code: 173,
  },
  {
    name: "Xã Tân Hòa",
    code: 5935,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 173,
  },
  {
    name: "Xã Nhã Lộng",
    code: 5938,
    division_type: "xã",
    codename: "xa_nha_long",
    district_code: 173,
  },
  {
    name: "Xã Điềm Thụy",
    code: 5941,
    division_type: "xã",
    codename: "xa_diem_thuy",
    district_code: 173,
  },
  {
    name: "Xã Xuân Phương",
    code: 5944,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 173,
  },
  {
    name: "Xã Tân Đức",
    code: 5947,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 173,
  },
  {
    name: "Xã Úc Kỳ",
    code: 5950,
    division_type: "xã",
    codename: "xa_uc_ky",
    district_code: 173,
  },
  {
    name: "Xã Lương Phú",
    code: 5953,
    division_type: "xã",
    codename: "xa_luong_phu",
    district_code: 173,
  },
  {
    name: "Xã Nga My",
    code: 5956,
    division_type: "xã",
    codename: "xa_nga_my",
    district_code: 173,
  },
  {
    name: "Xã Kha Sơn",
    code: 5959,
    division_type: "xã",
    codename: "xa_kha_son",
    district_code: 173,
  },
  {
    name: "Xã Thanh Ninh",
    code: 5962,
    division_type: "xã",
    codename: "xa_thanh_ninh",
    district_code: 173,
  },
  {
    name: "Xã Dương Thành",
    code: 5965,
    division_type: "xã",
    codename: "xa_duong_thanh",
    district_code: 173,
  },
  {
    name: "Xã Hà Châu",
    code: 5968,
    division_type: "xã",
    codename: "xa_ha_chau",
    district_code: 173,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 5971,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 178,
  },
  {
    name: "Phường Tam Thanh",
    code: 5974,
    division_type: "phường",
    codename: "phuong_tam_thanh",
    district_code: 178,
  },
  {
    name: "Phường Vĩnh Trại",
    code: 5977,
    division_type: "phường",
    codename: "phuong_vinh_trai",
    district_code: 178,
  },
  {
    name: "Phường Đông Kinh",
    code: 5980,
    division_type: "phường",
    codename: "phuong_dong_kinh",
    district_code: 178,
  },
  {
    name: "Phường Chi Lăng",
    code: 5983,
    division_type: "phường",
    codename: "phuong_chi_lang",
    district_code: 178,
  },
  {
    name: "Xã Hoàng Đồng",
    code: 5986,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 178,
  },
  {
    name: "Xã Quảng Lạc",
    code: 5989,
    division_type: "xã",
    codename: "xa_quang_lac",
    district_code: 178,
  },
  {
    name: "Xã Mai Pha",
    code: 5992,
    division_type: "xã",
    codename: "xa_mai_pha",
    district_code: 178,
  },
  {
    name: "Thị trấn Thất Khê",
    code: 5995,
    division_type: "thị trấn",
    codename: "thi_tran_that_khe",
    district_code: 180,
  },
  {
    name: "Xã Khánh Long",
    code: 5998,
    division_type: "xã",
    codename: "xa_khanh_long",
    district_code: 180,
  },
  {
    name: "Xã Đoàn Kết",
    code: 6001,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 180,
  },
  {
    name: "Xã Quốc Khánh",
    code: 6004,
    division_type: "xã",
    codename: "xa_quoc_khanh",
    district_code: 180,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 6007,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 180,
  },
  {
    name: "Xã Cao Minh",
    code: 6010,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 180,
  },
  {
    name: "Xã Chí Minh",
    code: 6013,
    division_type: "xã",
    codename: "xa_chi_minh",
    district_code: 180,
  },
  {
    name: "Xã Tri Phương",
    code: 6016,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 180,
  },
  {
    name: "Xã Tân Tiến",
    code: 6019,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 180,
  },
  {
    name: "Xã Tân Yên",
    code: 6022,
    division_type: "xã",
    codename: "xa_tan_yen",
    district_code: 180,
  },
  {
    name: "Xã Đội Cấn",
    code: 6025,
    division_type: "xã",
    codename: "xa_doi_can",
    district_code: 180,
  },
  {
    name: "Xã Tân Minh",
    code: 6028,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 180,
  },
  {
    name: "Xã Kim Đồng",
    code: 6031,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 180,
  },
  {
    name: "Xã Chi Lăng",
    code: 6034,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 180,
  },
  {
    name: "Xã Trung Thành",
    code: 6037,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 180,
  },
  {
    name: "Xã Đại Đồng",
    code: 6040,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 180,
  },
  {
    name: "Xã Đào Viên",
    code: 6043,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 180,
  },
  {
    name: "Xã Đề Thám",
    code: 6046,
    division_type: "xã",
    codename: "xa_de_tham",
    district_code: 180,
  },
  {
    name: "Xã Kháng Chiến",
    code: 6049,
    division_type: "xã",
    codename: "xa_khang_chien",
    district_code: 180,
  },
  {
    name: "Xã Hùng Sơn",
    code: 6055,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 180,
  },
  {
    name: "Xã Quốc Việt",
    code: 6058,
    division_type: "xã",
    codename: "xa_quoc_viet",
    district_code: 180,
  },
  {
    name: "Xã Hùng Việt",
    code: 6061,
    division_type: "xã",
    codename: "xa_hung_viet",
    district_code: 180,
  },
  {
    name: "Xã Hưng Đạo",
    code: 6067,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 181,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 6070,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 181,
  },
  {
    name: "Xã Hoa Thám",
    code: 6073,
    division_type: "xã",
    codename: "xa_hoa_tham",
    district_code: 181,
  },
  {
    name: "Xã Quý Hòa",
    code: 6076,
    division_type: "xã",
    codename: "xa_quy_hoa",
    district_code: 181,
  },
  {
    name: "Xã Hồng Phong",
    code: 6079,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 181,
  },
  {
    name: "Xã Yên Lỗ",
    code: 6082,
    division_type: "xã",
    codename: "xa_yen_lo",
    district_code: 181,
  },
  {
    name: "Xã Thiện Hòa",
    code: 6085,
    division_type: "xã",
    codename: "xa_thien_hoa",
    district_code: 181,
  },
  {
    name: "Xã Quang Trung",
    code: 6088,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 181,
  },
  {
    name: "Xã Thiện Thuật",
    code: 6091,
    division_type: "xã",
    codename: "xa_thien_thuat",
    district_code: 181,
  },
  {
    name: "Xã Minh Khai",
    code: 6094,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 181,
  },
  {
    name: "Xã Thiện Long",
    code: 6097,
    division_type: "xã",
    codename: "xa_thien_long",
    district_code: 181,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 6100,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 181,
  },
  {
    name: "Xã Hòa Bình",
    code: 6103,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 181,
  },
  {
    name: "Xã Mông Ân",
    code: 6106,
    division_type: "xã",
    codename: "xa_mong_an",
    district_code: 181,
  },
  {
    name: "Xã Tân Hòa",
    code: 6109,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 181,
  },
  {
    name: "Thị trấn Bình Gia",
    code: 6112,
    division_type: "thị trấn",
    codename: "thi_tran_binh_gia",
    district_code: 181,
  },
  {
    name: "Xã Hồng Thái",
    code: 6115,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 181,
  },
  {
    name: "Xã Bình La",
    code: 6118,
    division_type: "xã",
    codename: "xa_binh_la",
    district_code: 181,
  },
  {
    name: "Xã Tân Văn",
    code: 6121,
    division_type: "xã",
    codename: "xa_tan_van",
    district_code: 181,
  },
  {
    name: "Thị trấn Na Sầm",
    code: 6124,
    division_type: "thị trấn",
    codename: "thi_tran_na_sam",
    district_code: 182,
  },
  {
    name: "Xã Trùng Khánh",
    code: 6127,
    division_type: "xã",
    codename: "xa_trung_khanh",
    district_code: 182,
  },
  {
    name: "Xã Bắc La",
    code: 6133,
    division_type: "xã",
    codename: "xa_bac_la",
    district_code: 182,
  },
  {
    name: "Xã Thụy Hùng",
    code: 6136,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 182,
  },
  {
    name: "Xã Bắc Hùng",
    code: 6139,
    division_type: "xã",
    codename: "xa_bac_hung",
    district_code: 182,
  },
  {
    name: "Xã Tân Tác",
    code: 6142,
    division_type: "xã",
    codename: "xa_tan_tac",
    district_code: 182,
  },
  {
    name: "Xã Thanh Long",
    code: 6148,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 182,
  },
  {
    name: "Xã Hội Hoan",
    code: 6151,
    division_type: "xã",
    codename: "xa_hoi_hoan",
    district_code: 182,
  },
  {
    name: "Xã Bắc Việt",
    code: 6154,
    division_type: "xã",
    codename: "xa_bac_viet",
    district_code: 182,
  },
  {
    name: "Xã Hoàng Việt",
    code: 6157,
    division_type: "xã",
    codename: "xa_hoang_viet",
    district_code: 182,
  },
  {
    name: "Xã Gia Miễn",
    code: 6160,
    division_type: "xã",
    codename: "xa_gia_mien",
    district_code: 182,
  },
  {
    name: "Xã Thành Hòa",
    code: 6163,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 182,
  },
  {
    name: "Xã Tân Thanh",
    code: 6166,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 182,
  },
  {
    name: "Xã Tân Mỹ",
    code: 6172,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 182,
  },
  {
    name: "Xã Hồng Thái",
    code: 6175,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 182,
  },
  {
    name: "Xã Hoàng Văn Thụ",
    code: 6178,
    division_type: "xã",
    codename: "xa_hoang_van_thu",
    district_code: 182,
  },
  {
    name: "Xã Nhạc Kỳ",
    code: 6181,
    division_type: "xã",
    codename: "xa_nhac_ky",
    district_code: 182,
  },
  {
    name: "Thị trấn Đồng Đăng",
    code: 6184,
    division_type: "thị trấn",
    codename: "thi_tran_dong_dang",
    district_code: 183,
  },
  {
    name: "Thị trấn Cao Lộc",
    code: 6187,
    division_type: "thị trấn",
    codename: "thi_tran_cao_loc",
    district_code: 183,
  },
  {
    name: "Xã Bảo Lâm",
    code: 6190,
    division_type: "xã",
    codename: "xa_bao_lam",
    district_code: 183,
  },
  {
    name: "Xã Thanh Lòa",
    code: 6193,
    division_type: "xã",
    codename: "xa_thanh_loa",
    district_code: 183,
  },
  {
    name: "Xã Cao Lâu",
    code: 6196,
    division_type: "xã",
    codename: "xa_cao_lau",
    district_code: 183,
  },
  {
    name: "Xã Thạch Đạn",
    code: 6199,
    division_type: "xã",
    codename: "xa_thach_dan",
    district_code: 183,
  },
  {
    name: "Xã Xuất Lễ",
    code: 6202,
    division_type: "xã",
    codename: "xa_xuat_le",
    district_code: 183,
  },
  {
    name: "Xã Hồng Phong",
    code: 6205,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 183,
  },
  {
    name: "Xã Thụy Hùng",
    code: 6208,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 183,
  },
  {
    name: "Xã Lộc Yên",
    code: 6211,
    division_type: "xã",
    codename: "xa_loc_yen",
    district_code: 183,
  },
  {
    name: "Xã Phú Xá",
    code: 6214,
    division_type: "xã",
    codename: "xa_phu_xa",
    district_code: 183,
  },
  {
    name: "Xã Bình Trung",
    code: 6217,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 183,
  },
  {
    name: "Xã Hải Yến",
    code: 6220,
    division_type: "xã",
    codename: "xa_hai_yen",
    district_code: 183,
  },
  {
    name: "Xã Hòa Cư",
    code: 6223,
    division_type: "xã",
    codename: "xa_hoa_cu",
    district_code: 183,
  },
  {
    name: "Xã Hợp Thành",
    code: 6226,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 183,
  },
  {
    name: "Xã Công Sơn",
    code: 6232,
    division_type: "xã",
    codename: "xa_cong_son",
    district_code: 183,
  },
  {
    name: "Xã Gia Cát",
    code: 6235,
    division_type: "xã",
    codename: "xa_gia_cat",
    district_code: 183,
  },
  {
    name: "Xã Mẫu Sơn",
    code: 6238,
    division_type: "xã",
    codename: "xa_mau_son",
    district_code: 183,
  },
  {
    name: "Xã Xuân Long",
    code: 6241,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 183,
  },
  {
    name: "Xã Tân Liên",
    code: 6244,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 183,
  },
  {
    name: "Xã Yên Trạch",
    code: 6247,
    division_type: "xã",
    codename: "xa_yen_trach",
    district_code: 183,
  },
  {
    name: "Xã Tân Thành",
    code: 6250,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 183,
  },
  {
    name: "Thị trấn Văn Quan",
    code: 6253,
    division_type: "thị trấn",
    codename: "thi_tran_van_quan",
    district_code: 184,
  },
  {
    name: "Xã Trấn Ninh",
    code: 6256,
    division_type: "xã",
    codename: "xa_tran_ninh",
    district_code: 184,
  },
  {
    name: "Xã Liên Hội",
    code: 6268,
    division_type: "xã",
    codename: "xa_lien_hoi",
    district_code: 184,
  },
  {
    name: "Xã Hòa Bình",
    code: 6274,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 184,
  },
  {
    name: "Xã Tú Xuyên",
    code: 6277,
    division_type: "xã",
    codename: "xa_tu_xuyen",
    district_code: 184,
  },
  {
    name: "Xã Điềm He",
    code: 6280,
    division_type: "xã",
    codename: "xa_diem_he",
    district_code: 184,
  },
  {
    name: "Xã An Sơn",
    code: 6283,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 184,
  },
  {
    name: "Xã Khánh Khê",
    code: 6286,
    division_type: "xã",
    codename: "xa_khanh_khe",
    district_code: 184,
  },
  {
    name: "Xã Lương Năng",
    code: 6292,
    division_type: "xã",
    codename: "xa_luong_nang",
    district_code: 184,
  },
  {
    name: "Xã Đồng Giáp",
    code: 6295,
    division_type: "xã",
    codename: "xa_dong_giap",
    district_code: 184,
  },
  {
    name: "Xã Bình Phúc",
    code: 6298,
    division_type: "xã",
    codename: "xa_binh_phuc",
    district_code: 184,
  },
  {
    name: "Xã Tràng Các",
    code: 6301,
    division_type: "xã",
    codename: "xa_trang_cac",
    district_code: 184,
  },
  {
    name: "Xã Tân Đoàn",
    code: 6307,
    division_type: "xã",
    codename: "xa_tan_doan",
    district_code: 184,
  },
  {
    name: "Xã Tri Lễ",
    code: 6313,
    division_type: "xã",
    codename: "xa_tri_le",
    district_code: 184,
  },
  {
    name: "Xã Tràng Phái",
    code: 6316,
    division_type: "xã",
    codename: "xa_trang_phai",
    district_code: 184,
  },
  {
    name: "Xã Yên Phúc",
    code: 6319,
    division_type: "xã",
    codename: "xa_yen_phuc",
    district_code: 184,
  },
  {
    name: "Xã Hữu Lễ",
    code: 6322,
    division_type: "xã",
    codename: "xa_huu_le",
    district_code: 184,
  },
  {
    name: "Thị trấn Bắc Sơn",
    code: 6325,
    division_type: "thị trấn",
    codename: "thi_tran_bac_son",
    district_code: 185,
  },
  {
    name: "Xã Long Đống",
    code: 6328,
    division_type: "xã",
    codename: "xa_long_dong",
    district_code: 185,
  },
  {
    name: "Xã Vạn Thủy",
    code: 6331,
    division_type: "xã",
    codename: "xa_van_thuy",
    district_code: 185,
  },
  {
    name: "Xã Đồng ý",
    code: 6337,
    division_type: "xã",
    codename: "xa_dong_y",
    district_code: 185,
  },
  {
    name: "Xã Tân Tri",
    code: 6340,
    division_type: "xã",
    codename: "xa_tan_tri",
    district_code: 185,
  },
  {
    name: "Xã Bắc Quỳnh",
    code: 6343,
    division_type: "xã",
    codename: "xa_bac_quynh",
    district_code: 185,
  },
  {
    name: "Xã Hưng Vũ",
    code: 6349,
    division_type: "xã",
    codename: "xa_hung_vu",
    district_code: 185,
  },
  {
    name: "Xã Tân Lập",
    code: 6352,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 185,
  },
  {
    name: "Xã Vũ Sơn",
    code: 6355,
    division_type: "xã",
    codename: "xa_vu_son",
    district_code: 185,
  },
  {
    name: "Xã Chiêu Vũ",
    code: 6358,
    division_type: "xã",
    codename: "xa_chieu_vu",
    district_code: 185,
  },
  {
    name: "Xã Tân Hương",
    code: 6361,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 185,
  },
  {
    name: "Xã Chiến Thắng",
    code: 6364,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 185,
  },
  {
    name: "Xã Vũ Lăng",
    code: 6367,
    division_type: "xã",
    codename: "xa_vu_lang",
    district_code: 185,
  },
  {
    name: "Xã Trấn Yên",
    code: 6370,
    division_type: "xã",
    codename: "xa_tran_yen",
    district_code: 185,
  },
  {
    name: "Xã Vũ Lễ",
    code: 6373,
    division_type: "xã",
    codename: "xa_vu_le",
    district_code: 185,
  },
  {
    name: "Xã Nhất Hòa",
    code: 6376,
    division_type: "xã",
    codename: "xa_nhat_hoa",
    district_code: 185,
  },
  {
    name: "Xã Tân Thành",
    code: 6379,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 185,
  },
  {
    name: "Xã Nhất Tiến",
    code: 6382,
    division_type: "xã",
    codename: "xa_nhat_tien",
    district_code: 185,
  },
  {
    name: "Thị trấn Hữu Lũng",
    code: 6385,
    division_type: "thị trấn",
    codename: "thi_tran_huu_lung",
    district_code: 186,
  },
  {
    name: "Xã Hữu Liên",
    code: 6388,
    division_type: "xã",
    codename: "xa_huu_lien",
    district_code: 186,
  },
  {
    name: "Xã Yên Bình",
    code: 6391,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 186,
  },
  {
    name: "Xã Quyết Thắng",
    code: 6394,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 186,
  },
  {
    name: "Xã Hòa Bình",
    code: 6397,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 186,
  },
  {
    name: "Xã Yên Thịnh",
    code: 6400,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 186,
  },
  {
    name: "Xã Yên Sơn",
    code: 6403,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 186,
  },
  {
    name: "Xã Thiện Tân",
    code: 6406,
    division_type: "xã",
    codename: "xa_thien_tan",
    district_code: 186,
  },
  {
    name: "Xã Yên Vượng",
    code: 6412,
    division_type: "xã",
    codename: "xa_yen_vuong",
    district_code: 186,
  },
  {
    name: "Xã Minh Tiến",
    code: 6415,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 186,
  },
  {
    name: "Xã Nhật Tiến",
    code: 6418,
    division_type: "xã",
    codename: "xa_nhat_tien",
    district_code: 186,
  },
  {
    name: "Xã Thanh Sơn",
    code: 6421,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 186,
  },
  {
    name: "Xã Đồng Tân",
    code: 6424,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 186,
  },
  {
    name: "Xã Cai Kinh",
    code: 6427,
    division_type: "xã",
    codename: "xa_cai_kinh",
    district_code: 186,
  },
  {
    name: "Xã Hòa Lạc",
    code: 6430,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 186,
  },
  {
    name: "Xã Vân Nham",
    code: 6433,
    division_type: "xã",
    codename: "xa_van_nham",
    district_code: 186,
  },
  {
    name: "Xã Đồng Tiến",
    code: 6436,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 186,
  },
  {
    name: "Xã Tân Thành",
    code: 6442,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 186,
  },
  {
    name: "Xã Hòa Sơn",
    code: 6445,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 186,
  },
  {
    name: "Xã Minh Sơn",
    code: 6448,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 186,
  },
  {
    name: "Xã Hồ Sơn",
    code: 6451,
    division_type: "xã",
    codename: "xa_ho_son",
    district_code: 186,
  },
  {
    name: "Xã Sơn Hà",
    code: 6454,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 186,
  },
  {
    name: "Xã Minh Hòa",
    code: 6457,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 186,
  },
  {
    name: "Xã Hòa Thắng",
    code: 6460,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 186,
  },
  {
    name: "Thị trấn Đồng Mỏ",
    code: 6463,
    division_type: "thị trấn",
    codename: "thi_tran_dong_mo",
    district_code: 187,
  },
  {
    name: "Thị trấn Chi Lăng",
    code: 6466,
    division_type: "thị trấn",
    codename: "thi_tran_chi_lang",
    district_code: 187,
  },
  {
    name: "Xã Vân An",
    code: 6469,
    division_type: "xã",
    codename: "xa_van_an",
    district_code: 187,
  },
  {
    name: "Xã Vân Thủy",
    code: 6472,
    division_type: "xã",
    codename: "xa_van_thuy",
    district_code: 187,
  },
  {
    name: "Xã Gia Lộc",
    code: 6475,
    division_type: "xã",
    codename: "xa_gia_loc",
    district_code: 187,
  },
  {
    name: "Xã Bắc Thủy",
    code: 6478,
    division_type: "xã",
    codename: "xa_bac_thuy",
    district_code: 187,
  },
  {
    name: "Xã Chiến Thắng",
    code: 6481,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 187,
  },
  {
    name: "Xã Mai Sao",
    code: 6484,
    division_type: "xã",
    codename: "xa_mai_sao",
    district_code: 187,
  },
  {
    name: "Xã Bằng Hữu",
    code: 6487,
    division_type: "xã",
    codename: "xa_bang_huu",
    district_code: 187,
  },
  {
    name: "Xã Thượng Cường",
    code: 6490,
    division_type: "xã",
    codename: "xa_thuong_cuong",
    district_code: 187,
  },
  {
    name: "Xã Bằng Mạc",
    code: 6493,
    division_type: "xã",
    codename: "xa_bang_mac",
    district_code: 187,
  },
  {
    name: "Xã Nhân Lý",
    code: 6496,
    division_type: "xã",
    codename: "xa_nhan_ly",
    district_code: 187,
  },
  {
    name: "Xã Lâm Sơn",
    code: 6499,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 187,
  },
  {
    name: "Xã Liên Sơn",
    code: 6502,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 187,
  },
  {
    name: "Xã Vạn Linh",
    code: 6505,
    division_type: "xã",
    codename: "xa_van_linh",
    district_code: 187,
  },
  {
    name: "Xã Hòa Bình",
    code: 6508,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 187,
  },
  {
    name: "Xã Hữu Kiên",
    code: 6514,
    division_type: "xã",
    codename: "xa_huu_kien",
    district_code: 187,
  },
  {
    name: "Xã Quan Sơn",
    code: 6517,
    division_type: "xã",
    codename: "xa_quan_son",
    district_code: 187,
  },
  {
    name: "Xã Y Tịch",
    code: 6520,
    division_type: "xã",
    codename: "xa_y_tich",
    district_code: 187,
  },
  {
    name: "Xã Chi Lăng",
    code: 6523,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 187,
  },
  {
    name: "Thị trấn Na Dương",
    code: 6526,
    division_type: "thị trấn",
    codename: "thi_tran_na_duong",
    district_code: 188,
  },
  {
    name: "Thị trấn Lộc Bình",
    code: 6529,
    division_type: "thị trấn",
    codename: "thi_tran_loc_binh",
    district_code: 188,
  },
  {
    name: "Xã Mẫu Sơn",
    code: 6532,
    division_type: "xã",
    codename: "xa_mau_son",
    district_code: 188,
  },
  {
    name: "Xã Yên Khoái",
    code: 6541,
    division_type: "xã",
    codename: "xa_yen_khoai",
    district_code: 188,
  },
  {
    name: "Xã Khánh Xuân",
    code: 6544,
    division_type: "xã",
    codename: "xa_khanh_xuan",
    district_code: 188,
  },
  {
    name: "Xã Tú Mịch",
    code: 6547,
    division_type: "xã",
    codename: "xa_tu_mich",
    district_code: 188,
  },
  {
    name: "Xã Hữu Khánh",
    code: 6550,
    division_type: "xã",
    codename: "xa_huu_khanh",
    district_code: 188,
  },
  {
    name: "Xã Đồng Bục",
    code: 6553,
    division_type: "xã",
    codename: "xa_dong_buc",
    district_code: 188,
  },
  {
    name: "Xã Tam Gia",
    code: 6559,
    division_type: "xã",
    codename: "xa_tam_gia",
    district_code: 188,
  },
  {
    name: "Xã Tú Đoạn",
    code: 6562,
    division_type: "xã",
    codename: "xa_tu_doan",
    district_code: 188,
  },
  {
    name: "Xã Khuất Xá",
    code: 6565,
    division_type: "xã",
    codename: "xa_khuat_xa",
    district_code: 188,
  },
  {
    name: "Xã Tĩnh Bắc",
    code: 6574,
    division_type: "xã",
    codename: "xa_tinh_bac",
    district_code: 188,
  },
  {
    name: "Xã Thống Nhất",
    code: 6577,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 188,
  },
  {
    name: "Xã Sàn Viên",
    code: 6589,
    division_type: "xã",
    codename: "xa_san_vien",
    district_code: 188,
  },
  {
    name: "Xã Đông Quan",
    code: 6592,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 188,
  },
  {
    name: "Xã Minh Hiệp",
    code: 6595,
    division_type: "xã",
    codename: "xa_minh_hiep",
    district_code: 188,
  },
  {
    name: "Xã Hữu Lân",
    code: 6598,
    division_type: "xã",
    codename: "xa_huu_lan",
    district_code: 188,
  },
  {
    name: "Xã Lợi Bác",
    code: 6601,
    division_type: "xã",
    codename: "xa_loi_bac",
    district_code: 188,
  },
  {
    name: "Xã Nam Quan",
    code: 6604,
    division_type: "xã",
    codename: "xa_nam_quan",
    district_code: 188,
  },
  {
    name: "Xã Xuân Dương",
    code: 6607,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 188,
  },
  {
    name: "Xã Ái Quốc",
    code: 6610,
    division_type: "xã",
    codename: "xa_ai_quoc",
    district_code: 188,
  },
  {
    name: "Thị trấn Đình Lập",
    code: 6613,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_lap",
    district_code: 189,
  },
  {
    name: "Thị trấn NT Thái Bình",
    code: 6616,
    division_type: "thị trấn",
    codename: "thi_tran_nt_thai_binh",
    district_code: 189,
  },
  {
    name: "Xã Bắc Xa",
    code: 6619,
    division_type: "xã",
    codename: "xa_bac_xa",
    district_code: 189,
  },
  {
    name: "Xã Bính Xá",
    code: 6622,
    division_type: "xã",
    codename: "xa_binh_xa",
    district_code: 189,
  },
  {
    name: "Xã Kiên Mộc",
    code: 6625,
    division_type: "xã",
    codename: "xa_kien_moc",
    district_code: 189,
  },
  {
    name: "Xã Đình Lập",
    code: 6628,
    division_type: "xã",
    codename: "xa_dinh_lap",
    district_code: 189,
  },
  {
    name: "Xã Thái Bình",
    code: 6631,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 189,
  },
  {
    name: "Xã Cường Lợi",
    code: 6634,
    division_type: "xã",
    codename: "xa_cuong_loi",
    district_code: 189,
  },
  {
    name: "Xã Châu Sơn",
    code: 6637,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 189,
  },
  {
    name: "Xã Lâm Ca",
    code: 6640,
    division_type: "xã",
    codename: "xa_lam_ca",
    district_code: 189,
  },
  {
    name: "Xã Đồng Thắng",
    code: 6643,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 189,
  },
  {
    name: "Xã Bắc Lãng",
    code: 6646,
    division_type: "xã",
    codename: "xa_bac_lang",
    district_code: 189,
  },
  {
    name: "Phường Hà Khánh",
    code: 6649,
    division_type: "phường",
    codename: "phuong_ha_khanh",
    district_code: 193,
  },
  {
    name: "Phường Hà Phong",
    code: 6652,
    division_type: "phường",
    codename: "phuong_ha_phong",
    district_code: 193,
  },
  {
    name: "Phường Hà Khẩu",
    code: 6655,
    division_type: "phường",
    codename: "phuong_ha_khau",
    district_code: 193,
  },
  {
    name: "Phường Cao Xanh",
    code: 6658,
    division_type: "phường",
    codename: "phuong_cao_xanh",
    district_code: 193,
  },
  {
    name: "Phường Giếng Đáy",
    code: 6661,
    division_type: "phường",
    codename: "phuong_gieng_day",
    district_code: 193,
  },
  {
    name: "Phường Hà Tu",
    code: 6664,
    division_type: "phường",
    codename: "phuong_ha_tu",
    district_code: 193,
  },
  {
    name: "Phường Hà Trung",
    code: 6667,
    division_type: "phường",
    codename: "phuong_ha_trung",
    district_code: 193,
  },
  {
    name: "Phường Hà Lầm",
    code: 6670,
    division_type: "phường",
    codename: "phuong_ha_lam",
    district_code: 193,
  },
  {
    name: "Phường Bãi Cháy",
    code: 6673,
    division_type: "phường",
    codename: "phuong_bai_chay",
    district_code: 193,
  },
  {
    name: "Phường Cao Thắng",
    code: 6676,
    division_type: "phường",
    codename: "phuong_cao_thang",
    district_code: 193,
  },
  {
    name: "Phường Hùng Thắng",
    code: 6679,
    division_type: "phường",
    codename: "phuong_hung_thang",
    district_code: 193,
  },
  {
    name: "Phường Yết Kiêu",
    code: 6682,
    division_type: "phường",
    codename: "phuong_yet_kieu",
    district_code: 193,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 6685,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 193,
  },
  {
    name: "Phường Hồng Hải",
    code: 6688,
    division_type: "phường",
    codename: "phuong_hong_hai",
    district_code: 193,
  },
  {
    name: "Phường Hồng Gai",
    code: 6691,
    division_type: "phường",
    codename: "phuong_hong_gai",
    district_code: 193,
  },
  {
    name: "Phường Bạch Đằng",
    code: 6694,
    division_type: "phường",
    codename: "phuong_bach_dang",
    district_code: 193,
  },
  {
    name: "Phường Hồng Hà",
    code: 6697,
    division_type: "phường",
    codename: "phuong_hong_ha",
    district_code: 193,
  },
  {
    name: "Phường Tuần Châu",
    code: 6700,
    division_type: "phường",
    codename: "phuong_tuan_chau",
    district_code: 193,
  },
  {
    name: "Phường Việt Hưng",
    code: 6703,
    division_type: "phường",
    codename: "phuong_viet_hung",
    district_code: 193,
  },
  {
    name: "Phường Đại Yên",
    code: 6706,
    division_type: "phường",
    codename: "phuong_dai_yen",
    district_code: 193,
  },
  {
    name: "Phường Hoành Bồ",
    code: 7030,
    division_type: "phường",
    codename: "phuong_hoanh_bo",
    district_code: 193,
  },
  {
    name: "Xã Kỳ Thượng",
    code: 7033,
    division_type: "xã",
    codename: "xa_ky_thuong",
    district_code: 193,
  },
  {
    name: "Xã Đồng Sơn",
    code: 7036,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 193,
  },
  {
    name: "Xã Tân Dân",
    code: 7039,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 193,
  },
  {
    name: "Xã Đồng Lâm",
    code: 7042,
    division_type: "xã",
    codename: "xa_dong_lam",
    district_code: 193,
  },
  {
    name: "Xã Hòa Bình",
    code: 7045,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 193,
  },
  {
    name: "Xã Vũ Oai",
    code: 7048,
    division_type: "xã",
    codename: "xa_vu_oai",
    district_code: 193,
  },
  {
    name: "Xã Dân Chủ",
    code: 7051,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 193,
  },
  {
    name: "Xã Quảng La",
    code: 7054,
    division_type: "xã",
    codename: "xa_quang_la",
    district_code: 193,
  },
  {
    name: "Xã Bằng Cả",
    code: 7057,
    division_type: "xã",
    codename: "xa_bang_ca",
    district_code: 193,
  },
  {
    name: "Xã Thống Nhất",
    code: 7060,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 193,
  },
  {
    name: "Xã Sơn Dương",
    code: 7063,
    division_type: "xã",
    codename: "xa_son_duong",
    district_code: 193,
  },
  {
    name: "Xã Lê Lợi",
    code: 7066,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 193,
  },
  {
    name: "Phường Ka Long",
    code: 6709,
    division_type: "phường",
    codename: "phuong_ka_long",
    district_code: 194,
  },
  {
    name: "Phường Trần Phú",
    code: 6712,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 194,
  },
  {
    name: "Phường Ninh Dương",
    code: 6715,
    division_type: "phường",
    codename: "phuong_ninh_duong",
    district_code: 194,
  },
  {
    name: "Phường Hoà Lạc",
    code: 6718,
    division_type: "phường",
    codename: "phuong_hoa_lac",
    district_code: 194,
  },
  {
    name: "Phường Trà Cổ",
    code: 6721,
    division_type: "phường",
    codename: "phuong_tra_co",
    district_code: 194,
  },
  {
    name: "Xã Hải Sơn",
    code: 6724,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 194,
  },
  {
    name: "Xã Bắc Sơn",
    code: 6727,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 194,
  },
  {
    name: "Xã Hải Đông",
    code: 6730,
    division_type: "xã",
    codename: "xa_hai_dong",
    district_code: 194,
  },
  {
    name: "Xã Hải Tiến",
    code: 6733,
    division_type: "xã",
    codename: "xa_hai_tien",
    district_code: 194,
  },
  {
    name: "Phường Hải Yên",
    code: 6736,
    division_type: "phường",
    codename: "phuong_hai_yen",
    district_code: 194,
  },
  {
    name: "Xã Quảng Nghĩa",
    code: 6739,
    division_type: "xã",
    codename: "xa_quang_nghia",
    district_code: 194,
  },
  {
    name: "Phường Hải Hoà",
    code: 6742,
    division_type: "phường",
    codename: "phuong_hai_hoa",
    district_code: 194,
  },
  {
    name: "Xã Hải Xuân",
    code: 6745,
    division_type: "xã",
    codename: "xa_hai_xuan",
    district_code: 194,
  },
  {
    name: "Xã Vạn Ninh",
    code: 6748,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 194,
  },
  {
    name: "Phường Bình Ngọc",
    code: 6751,
    division_type: "phường",
    codename: "phuong_binh_ngoc",
    district_code: 194,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 6754,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 194,
  },
  {
    name: "Xã Vĩnh Thực",
    code: 6757,
    division_type: "xã",
    codename: "xa_vinh_thuc",
    district_code: 194,
  },
  {
    name: "Phường Mông Dương",
    code: 6760,
    division_type: "phường",
    codename: "phuong_mong_duong",
    district_code: 195,
  },
  {
    name: "Phường Cửa Ông",
    code: 6763,
    division_type: "phường",
    codename: "phuong_cua_ong",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Sơn",
    code: 6766,
    division_type: "phường",
    codename: "phuong_cam_son",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Đông",
    code: 6769,
    division_type: "phường",
    codename: "phuong_cam_dong",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Phú",
    code: 6772,
    division_type: "phường",
    codename: "phuong_cam_phu",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Tây",
    code: 6775,
    division_type: "phường",
    codename: "phuong_cam_tay",
    district_code: 195,
  },
  {
    name: "Phường Quang Hanh",
    code: 6778,
    division_type: "phường",
    codename: "phuong_quang_hanh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thịnh",
    code: 6781,
    division_type: "phường",
    codename: "phuong_cam_thinh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thủy",
    code: 6784,
    division_type: "phường",
    codename: "phuong_cam_thuy",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thạch",
    code: 6787,
    division_type: "phường",
    codename: "phuong_cam_thach",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Thành",
    code: 6790,
    division_type: "phường",
    codename: "phuong_cam_thanh",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Trung",
    code: 6793,
    division_type: "phường",
    codename: "phuong_cam_trung",
    district_code: 195,
  },
  {
    name: "Phường Cẩm Bình",
    code: 6796,
    division_type: "phường",
    codename: "phuong_cam_binh",
    district_code: 195,
  },
  {
    name: "Xã Cộng Hòa",
    code: 6799,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 195,
  },
  {
    name: "Xã Cẩm Hải",
    code: 6802,
    division_type: "xã",
    codename: "xa_cam_hai",
    district_code: 195,
  },
  {
    name: "Xã Dương Huy",
    code: 6805,
    division_type: "xã",
    codename: "xa_duong_huy",
    district_code: 195,
  },
  {
    name: "Phường Vàng Danh",
    code: 6808,
    division_type: "phường",
    codename: "phuong_vang_danh",
    district_code: 196,
  },
  {
    name: "Phường Thanh Sơn",
    code: 6811,
    division_type: "phường",
    codename: "phuong_thanh_son",
    district_code: 196,
  },
  {
    name: "Phường Bắc Sơn",
    code: 6814,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 196,
  },
  {
    name: "Phường Quang Trung",
    code: 6817,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 196,
  },
  {
    name: "Phường Trưng Vương",
    code: 6820,
    division_type: "phường",
    codename: "phuong_trung_vuong",
    district_code: 196,
  },
  {
    name: "Phường Nam Khê",
    code: 6823,
    division_type: "phường",
    codename: "phuong_nam_khe",
    district_code: 196,
  },
  {
    name: "Phường Yên Thanh",
    code: 6826,
    division_type: "phường",
    codename: "phuong_yen_thanh",
    district_code: 196,
  },
  {
    name: "Xã Thượng Yên Công",
    code: 6829,
    division_type: "xã",
    codename: "xa_thuong_yen_cong",
    district_code: 196,
  },
  {
    name: "Phường Phương Đông",
    code: 6832,
    division_type: "phường",
    codename: "phuong_phuong_dong",
    district_code: 196,
  },
  {
    name: "Phường Phương Nam",
    code: 6835,
    division_type: "phường",
    codename: "phuong_phuong_nam",
    district_code: 196,
  },
  {
    name: "Thị trấn Bình Liêu",
    code: 6838,
    division_type: "thị trấn",
    codename: "thi_tran_binh_lieu",
    district_code: 198,
  },
  {
    name: "Xã Hoành Mô",
    code: 6841,
    division_type: "xã",
    codename: "xa_hoanh_mo",
    district_code: 198,
  },
  {
    name: "Xã Đồng Tâm",
    code: 6844,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 198,
  },
  {
    name: "Xã Đồng Văn",
    code: 6847,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 198,
  },
  {
    name: "Xã Vô Ngại",
    code: 6853,
    division_type: "xã",
    codename: "xa_vo_ngai",
    district_code: 198,
  },
  {
    name: "Xã Lục Hồn",
    code: 6856,
    division_type: "xã",
    codename: "xa_luc_hon",
    district_code: 198,
  },
  {
    name: "Xã Húc Động",
    code: 6859,
    division_type: "xã",
    codename: "xa_huc_dong",
    district_code: 198,
  },
  {
    name: "Thị trấn Tiên Yên",
    code: 6862,
    division_type: "thị trấn",
    codename: "thi_tran_tien_yen",
    district_code: 199,
  },
  {
    name: "Xã Hà Lâu",
    code: 6865,
    division_type: "xã",
    codename: "xa_ha_lau",
    district_code: 199,
  },
  {
    name: "Xã Đại Dực",
    code: 6868,
    division_type: "xã",
    codename: "xa_dai_duc",
    district_code: 199,
  },
  {
    name: "Xã Phong Dụ",
    code: 6871,
    division_type: "xã",
    codename: "xa_phong_du",
    district_code: 199,
  },
  {
    name: "Xã Điền Xá",
    code: 6874,
    division_type: "xã",
    codename: "xa_dien_xa",
    district_code: 199,
  },
  {
    name: "Xã Đông Ngũ",
    code: 6877,
    division_type: "xã",
    codename: "xa_dong_ngu",
    district_code: 199,
  },
  {
    name: "Xã Yên Than",
    code: 6880,
    division_type: "xã",
    codename: "xa_yen_than",
    district_code: 199,
  },
  {
    name: "Xã Đông Hải",
    code: 6883,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 199,
  },
  {
    name: "Xã Hải Lạng",
    code: 6886,
    division_type: "xã",
    codename: "xa_hai_lang",
    district_code: 199,
  },
  {
    name: "Xã Tiên Lãng",
    code: 6889,
    division_type: "xã",
    codename: "xa_tien_lang",
    district_code: 199,
  },
  {
    name: "Xã Đồng Rui",
    code: 6892,
    division_type: "xã",
    codename: "xa_dong_rui",
    district_code: 199,
  },
  {
    name: "Thị trấn Đầm Hà",
    code: 6895,
    division_type: "thị trấn",
    codename: "thi_tran_dam_ha",
    district_code: 200,
  },
  {
    name: "Xã Quảng Lâm",
    code: 6898,
    division_type: "xã",
    codename: "xa_quang_lam",
    district_code: 200,
  },
  {
    name: "Xã Quảng An",
    code: 6901,
    division_type: "xã",
    codename: "xa_quang_an",
    district_code: 200,
  },
  {
    name: "Xã Tân Bình",
    code: 6904,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 200,
  },
  {
    name: "Xã Dực Yên",
    code: 6910,
    division_type: "xã",
    codename: "xa_duc_yen",
    district_code: 200,
  },
  {
    name: "Xã Quảng Tân",
    code: 6913,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 200,
  },
  {
    name: "Xã Đầm Hà",
    code: 6916,
    division_type: "xã",
    codename: "xa_dam_ha",
    district_code: 200,
  },
  {
    name: "Xã Tân Lập",
    code: 6917,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 200,
  },
  {
    name: "Xã Đại Bình",
    code: 6919,
    division_type: "xã",
    codename: "xa_dai_binh",
    district_code: 200,
  },
  {
    name: "Thị trấn Quảng Hà",
    code: 6922,
    division_type: "thị trấn",
    codename: "thi_tran_quang_ha",
    district_code: 201,
  },
  {
    name: "Xã Quảng Đức",
    code: 6925,
    division_type: "xã",
    codename: "xa_quang_duc",
    district_code: 201,
  },
  {
    name: "Xã Quảng Sơn",
    code: 6928,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 201,
  },
  {
    name: "Xã Quảng Thành",
    code: 6931,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Thịnh",
    code: 6937,
    division_type: "xã",
    codename: "xa_quang_thinh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Minh",
    code: 6940,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Chính",
    code: 6943,
    division_type: "xã",
    codename: "xa_quang_chinh",
    district_code: 201,
  },
  {
    name: "Xã Quảng Long",
    code: 6946,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 201,
  },
  {
    name: "Xã Đường Hoa",
    code: 6949,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 201,
  },
  {
    name: "Xã Quảng Phong",
    code: 6952,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 201,
  },
  {
    name: "Xã Cái Chiên",
    code: 6967,
    division_type: "xã",
    codename: "xa_cai_chien",
    district_code: 201,
  },
  {
    name: "Thị trấn Ba Chẽ",
    code: 6970,
    division_type: "thị trấn",
    codename: "thi_tran_ba_che",
    district_code: 202,
  },
  {
    name: "Xã Thanh Sơn",
    code: 6973,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 202,
  },
  {
    name: "Xã Thanh Lâm",
    code: 6976,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 202,
  },
  {
    name: "Xã Đạp Thanh",
    code: 6979,
    division_type: "xã",
    codename: "xa_dap_thanh",
    district_code: 202,
  },
  {
    name: "Xã Nam Sơn",
    code: 6982,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 202,
  },
  {
    name: "Xã Lương Mông",
    code: 6985,
    division_type: "xã",
    codename: "xa_luong_mong",
    district_code: 202,
  },
  {
    name: "Xã Đồn Đạc",
    code: 6988,
    division_type: "xã",
    codename: "xa_don_dac",
    district_code: 202,
  },
  {
    name: "Xã Minh Cầm",
    code: 6991,
    division_type: "xã",
    codename: "xa_minh_cam",
    district_code: 202,
  },
  {
    name: "Thị trấn Cái Rồng",
    code: 6994,
    division_type: "thị trấn",
    codename: "thi_tran_cai_rong",
    district_code: 203,
  },
  {
    name: "Xã Đài Xuyên",
    code: 6997,
    division_type: "xã",
    codename: "xa_dai_xuyen",
    district_code: 203,
  },
  {
    name: "Xã Bình Dân",
    code: 7000,
    division_type: "xã",
    codename: "xa_binh_dan",
    district_code: 203,
  },
  {
    name: "Xã Vạn Yên",
    code: 7003,
    division_type: "xã",
    codename: "xa_van_yen",
    district_code: 203,
  },
  {
    name: "Xã Minh Châu",
    code: 7006,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 203,
  },
  {
    name: "Xã Đoàn Kết",
    code: 7009,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 203,
  },
  {
    name: "Xã Hạ Long",
    code: 7012,
    division_type: "xã",
    codename: "xa_ha_long",
    district_code: 203,
  },
  {
    name: "Xã Đông Xá",
    code: 7015,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 203,
  },
  {
    name: "Xã Bản Sen",
    code: 7018,
    division_type: "xã",
    codename: "xa_ban_sen",
    district_code: 203,
  },
  {
    name: "Xã Thắng Lợi",
    code: 7021,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 203,
  },
  {
    name: "Xã Quan Lạn",
    code: 7024,
    division_type: "xã",
    codename: "xa_quan_lan",
    district_code: 203,
  },
  {
    name: "Xã Ngọc Vừng",
    code: 7027,
    division_type: "xã",
    codename: "xa_ngoc_vung",
    district_code: 203,
  },
  {
    name: "Phường Mạo Khê",
    code: 7069,
    division_type: "phường",
    codename: "phuong_mao_khe",
    district_code: 205,
  },
  {
    name: "Phường Đông Triều",
    code: 7072,
    division_type: "phường",
    codename: "phuong_dong_trieu",
    district_code: 205,
  },
  {
    name: "Xã An Sinh",
    code: 7075,
    division_type: "xã",
    codename: "xa_an_sinh",
    district_code: 205,
  },
  {
    name: "Xã Tràng Lương",
    code: 7078,
    division_type: "xã",
    codename: "xa_trang_luong",
    district_code: 205,
  },
  {
    name: "Xã Bình Khê",
    code: 7081,
    division_type: "xã",
    codename: "xa_binh_khe",
    district_code: 205,
  },
  {
    name: "Xã Việt Dân",
    code: 7084,
    division_type: "xã",
    codename: "xa_viet_dan",
    district_code: 205,
  },
  {
    name: "Xã Tân Việt",
    code: 7087,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 205,
  },
  {
    name: "Xã Bình Dương",
    code: 7090,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 205,
  },
  {
    name: "Phường Đức Chính",
    code: 7093,
    division_type: "phường",
    codename: "phuong_duc_chinh",
    district_code: 205,
  },
  {
    name: "Phường Tràng An",
    code: 7096,
    division_type: "phường",
    codename: "phuong_trang_an",
    district_code: 205,
  },
  {
    name: "Xã Nguyễn Huệ",
    code: 7099,
    division_type: "xã",
    codename: "xa_nguyen_hue",
    district_code: 205,
  },
  {
    name: "Xã Thủy An",
    code: 7102,
    division_type: "xã",
    codename: "xa_thuy_an",
    district_code: 205,
  },
  {
    name: "Phường Xuân Sơn",
    code: 7105,
    division_type: "phường",
    codename: "phuong_xuan_son",
    district_code: 205,
  },
  {
    name: "Xã Hồng Thái Tây",
    code: 7108,
    division_type: "xã",
    codename: "xa_hong_thai_tay",
    district_code: 205,
  },
  {
    name: "Xã Hồng Thái Đông",
    code: 7111,
    division_type: "xã",
    codename: "xa_hong_thai_dong",
    district_code: 205,
  },
  {
    name: "Phường Hoàng Quế",
    code: 7114,
    division_type: "phường",
    codename: "phuong_hoang_que",
    district_code: 205,
  },
  {
    name: "Phường Yên Thọ",
    code: 7117,
    division_type: "phường",
    codename: "phuong_yen_tho",
    district_code: 205,
  },
  {
    name: "Phường Hồng Phong",
    code: 7120,
    division_type: "phường",
    codename: "phuong_hong_phong",
    district_code: 205,
  },
  {
    name: "Phường Kim Sơn",
    code: 7123,
    division_type: "phường",
    codename: "phuong_kim_son",
    district_code: 205,
  },
  {
    name: "Phường Hưng Đạo",
    code: 7126,
    division_type: "phường",
    codename: "phuong_hung_dao",
    district_code: 205,
  },
  {
    name: "Xã Yên Đức",
    code: 7129,
    division_type: "xã",
    codename: "xa_yen_duc",
    district_code: 205,
  },
  {
    name: "Phường Quảng Yên",
    code: 7132,
    division_type: "phường",
    codename: "phuong_quang_yen",
    district_code: 206,
  },
  {
    name: "Phường Đông Mai",
    code: 7135,
    division_type: "phường",
    codename: "phuong_dong_mai",
    district_code: 206,
  },
  {
    name: "Phường Minh Thành",
    code: 7138,
    division_type: "phường",
    codename: "phuong_minh_thanh",
    district_code: 206,
  },
  {
    name: "Xã Sông Khoai",
    code: 7144,
    division_type: "xã",
    codename: "xa_song_khoai",
    district_code: 206,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 7147,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 206,
  },
  {
    name: "Phường Cộng Hòa",
    code: 7150,
    division_type: "phường",
    codename: "phuong_cong_hoa",
    district_code: 206,
  },
  {
    name: "Xã Tiền An",
    code: 7153,
    division_type: "xã",
    codename: "xa_tien_an",
    district_code: 206,
  },
  {
    name: "Xã Hoàng Tân",
    code: 7156,
    division_type: "xã",
    codename: "xa_hoang_tan",
    district_code: 206,
  },
  {
    name: "Phường Tân An",
    code: 7159,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 206,
  },
  {
    name: "Phường Yên Giang",
    code: 7162,
    division_type: "phường",
    codename: "phuong_yen_giang",
    district_code: 206,
  },
  {
    name: "Phường Nam Hoà",
    code: 7165,
    division_type: "phường",
    codename: "phuong_nam_hoa",
    district_code: 206,
  },
  {
    name: "Phường Hà An",
    code: 7168,
    division_type: "phường",
    codename: "phuong_ha_an",
    district_code: 206,
  },
  {
    name: "Xã Cẩm La",
    code: 7171,
    division_type: "xã",
    codename: "xa_cam_la",
    district_code: 206,
  },
  {
    name: "Phường Phong Hải",
    code: 7174,
    division_type: "phường",
    codename: "phuong_phong_hai",
    district_code: 206,
  },
  {
    name: "Phường Yên Hải",
    code: 7177,
    division_type: "phường",
    codename: "phuong_yen_hai",
    district_code: 206,
  },
  {
    name: "Xã Liên Hòa",
    code: 7180,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 206,
  },
  {
    name: "Phường Phong Cốc",
    code: 7183,
    division_type: "phường",
    codename: "phuong_phong_coc",
    district_code: 206,
  },
  {
    name: "Xã Liên Vị",
    code: 7186,
    division_type: "xã",
    codename: "xa_lien_vi",
    district_code: 206,
  },
  {
    name: "Xã Tiền Phong",
    code: 7189,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 206,
  },
  {
    name: "Thị trấn Cô Tô",
    code: 7192,
    division_type: "thị trấn",
    codename: "thi_tran_co_to",
    district_code: 207,
  },
  {
    name: "Xã Đồng Tiến",
    code: 7195,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 207,
  },
  {
    name: "Xã Thanh Lân",
    code: 7198,
    division_type: "xã",
    codename: "xa_thanh_lan",
    district_code: 207,
  },
  {
    name: "Phường Thọ Xương",
    code: 7201,
    division_type: "phường",
    codename: "phuong_tho_xuong",
    district_code: 213,
  },
  {
    name: "Phường Trần Nguyên Hãn",
    code: 7204,
    division_type: "phường",
    codename: "phuong_tran_nguyen_han",
    district_code: 213,
  },
  {
    name: "Phường Ngô Quyền",
    code: 7207,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 213,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 7210,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 213,
  },
  {
    name: "Phường Trần Phú",
    code: 7213,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 213,
  },
  {
    name: "Phường Mỹ Độ",
    code: 7216,
    division_type: "phường",
    codename: "phuong_my_do",
    district_code: 213,
  },
  {
    name: "Phường Lê Lợi",
    code: 7219,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 213,
  },
  {
    name: "Xã Song Mai",
    code: 7222,
    division_type: "xã",
    codename: "xa_song_mai",
    district_code: 213,
  },
  {
    name: "Phường Xương Giang",
    code: 7225,
    division_type: "phường",
    codename: "phuong_xuong_giang",
    district_code: 213,
  },
  {
    name: "Phường Đa Mai",
    code: 7228,
    division_type: "phường",
    codename: "phuong_da_mai",
    district_code: 213,
  },
  {
    name: "Phường Dĩnh Kế",
    code: 7231,
    division_type: "phường",
    codename: "phuong_dinh_ke",
    district_code: 213,
  },
  {
    name: "Xã Dĩnh Trì",
    code: 7441,
    division_type: "xã",
    codename: "xa_dinh_tri",
    district_code: 213,
  },
  {
    name: "Xã Tân Mỹ",
    code: 7687,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 213,
  },
  {
    name: "Xã Đồng Sơn",
    code: 7696,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 213,
  },
  {
    name: "Xã Tân Tiến",
    code: 7699,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 213,
  },
  {
    name: "Xã Song Khê",
    code: 7705,
    division_type: "xã",
    codename: "xa_song_khe",
    district_code: 213,
  },
  {
    name: "Xã Đồng Tiến",
    code: 7243,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 215,
  },
  {
    name: "Xã Canh Nậu",
    code: 7246,
    division_type: "xã",
    codename: "xa_canh_nau",
    district_code: 215,
  },
  {
    name: "Xã Xuân Lương",
    code: 7249,
    division_type: "xã",
    codename: "xa_xuan_luong",
    district_code: 215,
  },
  {
    name: "Xã Tam Tiến",
    code: 7252,
    division_type: "xã",
    codename: "xa_tam_tien",
    district_code: 215,
  },
  {
    name: "Xã Đồng Vương",
    code: 7255,
    division_type: "xã",
    codename: "xa_dong_vuong",
    district_code: 215,
  },
  {
    name: "Xã Đồng Hưu",
    code: 7258,
    division_type: "xã",
    codename: "xa_dong_huu",
    district_code: 215,
  },
  {
    name: "Xã Đồng Tâm",
    code: 7260,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 215,
  },
  {
    name: "Xã Tam Hiệp",
    code: 7261,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 215,
  },
  {
    name: "Xã Tiến Thắng",
    code: 7264,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 215,
  },
  {
    name: "Xã Hồng Kỳ",
    code: 7267,
    division_type: "xã",
    codename: "xa_hong_ky",
    district_code: 215,
  },
  {
    name: "Xã Đồng Lạc",
    code: 7270,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 215,
  },
  {
    name: "Xã Đông Sơn",
    code: 7273,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 215,
  },
  {
    name: "Xã Tân Hiệp",
    code: 7276,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 215,
  },
  {
    name: "Xã Hương Vĩ",
    code: 7279,
    division_type: "xã",
    codename: "xa_huong_vi",
    district_code: 215,
  },
  {
    name: "Xã Đồng Kỳ",
    code: 7282,
    division_type: "xã",
    codename: "xa_dong_ky",
    district_code: 215,
  },
  {
    name: "Xã An Thượng",
    code: 7285,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 215,
  },
  {
    name: "Thị trấn Phồn Xương",
    code: 7288,
    division_type: "thị trấn",
    codename: "thi_tran_phon_xuong",
    district_code: 215,
  },
  {
    name: "Xã Tân Sỏi",
    code: 7291,
    division_type: "xã",
    codename: "xa_tan_soi",
    district_code: 215,
  },
  {
    name: "Thị trấn Bố Hạ",
    code: 7294,
    division_type: "thị trấn",
    codename: "thi_tran_bo_ha",
    district_code: 215,
  },
  {
    name: "Xã Lan Giới",
    code: 7303,
    division_type: "xã",
    codename: "xa_lan_gioi",
    district_code: 216,
  },
  {
    name: "Thị trấn Nhã Nam",
    code: 7306,
    division_type: "thị trấn",
    codename: "thi_tran_nha_nam",
    district_code: 216,
  },
  {
    name: "Xã Tân Trung",
    code: 7309,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 216,
  },
  {
    name: "Xã Đại Hóa",
    code: 7312,
    division_type: "xã",
    codename: "xa_dai_hoa",
    district_code: 216,
  },
  {
    name: "Xã Quang Tiến",
    code: 7315,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 216,
  },
  {
    name: "Xã Phúc Sơn",
    code: 7318,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 216,
  },
  {
    name: "Xã An Dương",
    code: 7321,
    division_type: "xã",
    codename: "xa_an_duong",
    district_code: 216,
  },
  {
    name: "Xã Phúc Hòa",
    code: 7324,
    division_type: "xã",
    codename: "xa_phuc_hoa",
    district_code: 216,
  },
  {
    name: "Xã Liên Sơn",
    code: 7327,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 216,
  },
  {
    name: "Xã Hợp Đức",
    code: 7330,
    division_type: "xã",
    codename: "xa_hop_duc",
    district_code: 216,
  },
  {
    name: "Xã Lam Cốt",
    code: 7333,
    division_type: "xã",
    codename: "xa_lam_cot",
    district_code: 216,
  },
  {
    name: "Xã Cao Xá",
    code: 7336,
    division_type: "xã",
    codename: "xa_cao_xa",
    district_code: 216,
  },
  {
    name: "Thị trấn Cao Thượng",
    code: 7339,
    division_type: "thị trấn",
    codename: "thi_tran_cao_thuong",
    district_code: 216,
  },
  {
    name: "Xã Việt Ngọc",
    code: 7342,
    division_type: "xã",
    codename: "xa_viet_ngoc",
    district_code: 216,
  },
  {
    name: "Xã Song Vân",
    code: 7345,
    division_type: "xã",
    codename: "xa_song_van",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Châu",
    code: 7348,
    division_type: "xã",
    codename: "xa_ngoc_chau",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Vân",
    code: 7351,
    division_type: "xã",
    codename: "xa_ngoc_van",
    district_code: 216,
  },
  {
    name: "Xã Việt Lập",
    code: 7354,
    division_type: "xã",
    codename: "xa_viet_lap",
    district_code: 216,
  },
  {
    name: "Xã Liên Chung",
    code: 7357,
    division_type: "xã",
    codename: "xa_lien_chung",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Thiện",
    code: 7360,
    division_type: "xã",
    codename: "xa_ngoc_thien",
    district_code: 216,
  },
  {
    name: "Xã Ngọc Lý",
    code: 7363,
    division_type: "xã",
    codename: "xa_ngoc_ly",
    district_code: 216,
  },
  {
    name: "Xã Quế Nham",
    code: 7366,
    division_type: "xã",
    codename: "xa_que_nham",
    district_code: 216,
  },
  {
    name: "Thị trấn Vôi",
    code: 7375,
    division_type: "thị trấn",
    codename: "thi_tran_voi",
    district_code: 217,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 7378,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 217,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 7381,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 217,
  },
  {
    name: "Xã Quang Thịnh",
    code: 7384,
    division_type: "xã",
    codename: "xa_quang_thinh",
    district_code: 217,
  },
  {
    name: "Xã Hương Sơn",
    code: 7387,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 217,
  },
  {
    name: "Xã Đào Mỹ",
    code: 7390,
    division_type: "xã",
    codename: "xa_dao_my",
    district_code: 217,
  },
  {
    name: "Xã Tiên Lục",
    code: 7393,
    division_type: "xã",
    codename: "xa_tien_luc",
    district_code: 217,
  },
  {
    name: "Xã An Hà",
    code: 7396,
    division_type: "xã",
    codename: "xa_an_ha",
    district_code: 217,
  },
  {
    name: "Thị trấn Kép",
    code: 7399,
    division_type: "thị trấn",
    codename: "thi_tran_kep",
    district_code: 217,
  },
  {
    name: "Xã Mỹ Hà",
    code: 7402,
    division_type: "xã",
    codename: "xa_my_ha",
    district_code: 217,
  },
  {
    name: "Xã Hương Lạc",
    code: 7405,
    division_type: "xã",
    codename: "xa_huong_lac",
    district_code: 217,
  },
  {
    name: "Xã Dương Đức",
    code: 7408,
    division_type: "xã",
    codename: "xa_duong_duc",
    district_code: 217,
  },
  {
    name: "Xã Tân Thanh",
    code: 7411,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 217,
  },
  {
    name: "Xã Yên Mỹ",
    code: 7414,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 217,
  },
  {
    name: "Xã Tân Hưng",
    code: 7417,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 217,
  },
  {
    name: "Xã Mỹ Thái",
    code: 7420,
    division_type: "xã",
    codename: "xa_my_thai",
    district_code: 217,
  },
  {
    name: "Xã Xương Lâm",
    code: 7426,
    division_type: "xã",
    codename: "xa_xuong_lam",
    district_code: 217,
  },
  {
    name: "Xã Xuân Hương",
    code: 7429,
    division_type: "xã",
    codename: "xa_xuan_huong",
    district_code: 217,
  },
  {
    name: "Xã Tân Dĩnh",
    code: 7432,
    division_type: "xã",
    codename: "xa_tan_dinh",
    district_code: 217,
  },
  {
    name: "Xã Đại Lâm",
    code: 7435,
    division_type: "xã",
    codename: "xa_dai_lam",
    district_code: 217,
  },
  {
    name: "Xã Thái Đào",
    code: 7438,
    division_type: "xã",
    codename: "xa_thai_dao",
    district_code: 217,
  },
  {
    name: "Thị trấn Đồi Ngô",
    code: 7444,
    division_type: "thị trấn",
    codename: "thi_tran_doi_ngo",
    district_code: 218,
  },
  {
    name: "Xã Đông Hưng",
    code: 7450,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 218,
  },
  {
    name: "Xã Đông Phú",
    code: 7453,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 218,
  },
  {
    name: "Xã Tam Dị",
    code: 7456,
    division_type: "xã",
    codename: "xa_tam_di",
    district_code: 218,
  },
  {
    name: "Xã Bảo Sơn",
    code: 7459,
    division_type: "xã",
    codename: "xa_bao_son",
    district_code: 218,
  },
  {
    name: "Xã Bảo Đài",
    code: 7462,
    division_type: "xã",
    codename: "xa_bao_dai",
    district_code: 218,
  },
  {
    name: "Xã Thanh Lâm",
    code: 7465,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 218,
  },
  {
    name: "Xã Tiên Nha",
    code: 7468,
    division_type: "xã",
    codename: "xa_tien_nha",
    district_code: 218,
  },
  {
    name: "Xã Trường Giang",
    code: 7471,
    division_type: "xã",
    codename: "xa_truong_giang",
    district_code: 218,
  },
  {
    name: "Xã Phương Sơn",
    code: 7477,
    division_type: "xã",
    codename: "xa_phuong_son",
    district_code: 218,
  },
  {
    name: "Xã Chu Điện",
    code: 7480,
    division_type: "xã",
    codename: "xa_chu_dien",
    district_code: 218,
  },
  {
    name: "Xã Cương Sơn",
    code: 7483,
    division_type: "xã",
    codename: "xa_cuong_son",
    district_code: 218,
  },
  {
    name: "Xã Nghĩa Phương",
    code: 7486,
    division_type: "xã",
    codename: "xa_nghia_phuong",
    district_code: 218,
  },
  {
    name: "Xã Vô Tranh",
    code: 7489,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 218,
  },
  {
    name: "Xã Bình Sơn",
    code: 7492,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 218,
  },
  {
    name: "Xã Lan Mẫu",
    code: 7495,
    division_type: "xã",
    codename: "xa_lan_mau",
    district_code: 218,
  },
  {
    name: "Xã Yên Sơn",
    code: 7498,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 218,
  },
  {
    name: "Xã Khám Lạng",
    code: 7501,
    division_type: "xã",
    codename: "xa_kham_lang",
    district_code: 218,
  },
  {
    name: "Xã Huyền Sơn",
    code: 7504,
    division_type: "xã",
    codename: "xa_huyen_son",
    district_code: 218,
  },
  {
    name: "Xã Trường Sơn",
    code: 7507,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 218,
  },
  {
    name: "Xã Lục Sơn",
    code: 7510,
    division_type: "xã",
    codename: "xa_luc_son",
    district_code: 218,
  },
  {
    name: "Xã Bắc Lũng",
    code: 7513,
    division_type: "xã",
    codename: "xa_bac_lung",
    district_code: 218,
  },
  {
    name: "Xã Vũ Xá",
    code: 7516,
    division_type: "xã",
    codename: "xa_vu_xa",
    district_code: 218,
  },
  {
    name: "Xã Cẩm Lý",
    code: 7519,
    division_type: "xã",
    codename: "xa_cam_ly",
    district_code: 218,
  },
  {
    name: "Xã Đan Hội",
    code: 7522,
    division_type: "xã",
    codename: "xa_dan_hoi",
    district_code: 218,
  },
  {
    name: "Thị trấn Chũ",
    code: 7525,
    division_type: "thị trấn",
    codename: "thi_tran_chu",
    district_code: 219,
  },
  {
    name: "Xã Cấm Sơn",
    code: 7528,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 219,
  },
  {
    name: "Xã Tân Sơn",
    code: 7531,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 219,
  },
  {
    name: "Xã Phong Minh",
    code: 7534,
    division_type: "xã",
    codename: "xa_phong_minh",
    district_code: 219,
  },
  {
    name: "Xã Phong Vân",
    code: 7537,
    division_type: "xã",
    codename: "xa_phong_van",
    district_code: 219,
  },
  {
    name: "Xã Xa Lý",
    code: 7540,
    division_type: "xã",
    codename: "xa_xa_ly",
    district_code: 219,
  },
  {
    name: "Xã Hộ Đáp",
    code: 7543,
    division_type: "xã",
    codename: "xa_ho_dap",
    district_code: 219,
  },
  {
    name: "Xã Sơn Hải",
    code: 7546,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 219,
  },
  {
    name: "Xã Thanh Hải",
    code: 7549,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 219,
  },
  {
    name: "Xã Kiên Lao",
    code: 7552,
    division_type: "xã",
    codename: "xa_kien_lao",
    district_code: 219,
  },
  {
    name: "Xã Biên Sơn",
    code: 7555,
    division_type: "xã",
    codename: "xa_bien_son",
    district_code: 219,
  },
  {
    name: "Xã Kiên Thành",
    code: 7558,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 219,
  },
  {
    name: "Xã Hồng Giang",
    code: 7561,
    division_type: "xã",
    codename: "xa_hong_giang",
    district_code: 219,
  },
  {
    name: "Xã Kim Sơn",
    code: 7564,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 219,
  },
  {
    name: "Xã Tân Hoa",
    code: 7567,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 219,
  },
  {
    name: "Xã Giáp Sơn",
    code: 7570,
    division_type: "xã",
    codename: "xa_giap_son",
    district_code: 219,
  },
  {
    name: "Xã Biển Động",
    code: 7573,
    division_type: "xã",
    codename: "xa_bien_dong",
    district_code: 219,
  },
  {
    name: "Xã Quý Sơn",
    code: 7576,
    division_type: "xã",
    codename: "xa_quy_son",
    district_code: 219,
  },
  {
    name: "Xã Trù Hựu",
    code: 7579,
    division_type: "xã",
    codename: "xa_tru_huu",
    district_code: 219,
  },
  {
    name: "Xã Phì Điền",
    code: 7582,
    division_type: "xã",
    codename: "xa_phi_dien",
    district_code: 219,
  },
  {
    name: "Xã Tân Quang",
    code: 7588,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 219,
  },
  {
    name: "Xã Đồng Cốc",
    code: 7591,
    division_type: "xã",
    codename: "xa_dong_coc",
    district_code: 219,
  },
  {
    name: "Xã Tân Lập",
    code: 7594,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 219,
  },
  {
    name: "Xã Phú Nhuận",
    code: 7597,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 219,
  },
  {
    name: "Xã Mỹ An",
    code: 7600,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 219,
  },
  {
    name: "Xã Nam Dương",
    code: 7603,
    division_type: "xã",
    codename: "xa_nam_duong",
    district_code: 219,
  },
  {
    name: "Xã Tân Mộc",
    code: 7606,
    division_type: "xã",
    codename: "xa_tan_moc",
    district_code: 219,
  },
  {
    name: "Xã Đèo Gia",
    code: 7609,
    division_type: "xã",
    codename: "xa_deo_gia",
    district_code: 219,
  },
  {
    name: "Xã Phượng Sơn",
    code: 7612,
    division_type: "xã",
    codename: "xa_phuong_son",
    district_code: 219,
  },
  {
    name: "Thị trấn An Châu",
    code: 7615,
    division_type: "thị trấn",
    codename: "thi_tran_an_chau",
    district_code: 220,
  },
  {
    name: "Thị trấn Tây Yên Tử",
    code: 7616,
    division_type: "thị trấn",
    codename: "thi_tran_tay_yen_tu",
    district_code: 220,
  },
  {
    name: "Xã Vân Sơn",
    code: 7621,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 220,
  },
  {
    name: "Xã Hữu Sản",
    code: 7624,
    division_type: "xã",
    codename: "xa_huu_san",
    district_code: 220,
  },
  {
    name: "Xã Đại Sơn",
    code: 7627,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 220,
  },
  {
    name: "Xã Phúc Sơn",
    code: 7630,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 220,
  },
  {
    name: "Xã Giáo Liêm",
    code: 7636,
    division_type: "xã",
    codename: "xa_giao_liem",
    district_code: 220,
  },
  {
    name: "Xã Cẩm Đàn",
    code: 7642,
    division_type: "xã",
    codename: "xa_cam_dan",
    district_code: 220,
  },
  {
    name: "Xã An Lạc",
    code: 7645,
    division_type: "xã",
    codename: "xa_an_lac",
    district_code: 220,
  },
  {
    name: "Xã Vĩnh An",
    code: 7648,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 220,
  },
  {
    name: "Xã Yên Định",
    code: 7651,
    division_type: "xã",
    codename: "xa_yen_dinh",
    district_code: 220,
  },
  {
    name: "Xã Lệ Viễn",
    code: 7654,
    division_type: "xã",
    codename: "xa_le_vien",
    district_code: 220,
  },
  {
    name: "Xã An Bá",
    code: 7660,
    division_type: "xã",
    codename: "xa_an_ba",
    district_code: 220,
  },
  {
    name: "Xã Tuấn Đạo",
    code: 7663,
    division_type: "xã",
    codename: "xa_tuan_dao",
    district_code: 220,
  },
  {
    name: "Xã Dương Hưu",
    code: 7666,
    division_type: "xã",
    codename: "xa_duong_huu",
    district_code: 220,
  },
  {
    name: "Xã Long Sơn",
    code: 7672,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 220,
  },
  {
    name: "Xã Thanh Luận",
    code: 7678,
    division_type: "xã",
    codename: "xa_thanh_luan",
    district_code: 220,
  },
  {
    name: "Thị trấn Nham Biền",
    code: 7681,
    division_type: "thị trấn",
    codename: "thi_tran_nham_bien",
    district_code: 221,
  },
  {
    name: "Thị trấn Tân An",
    code: 7682,
    division_type: "thị trấn",
    codename: "thi_tran_tan_an",
    district_code: 221,
  },
  {
    name: "Xã Lão Hộ",
    code: 7684,
    division_type: "xã",
    codename: "xa_lao_ho",
    district_code: 221,
  },
  {
    name: "Xã Hương Gián",
    code: 7690,
    division_type: "xã",
    codename: "xa_huong_gian",
    district_code: 221,
  },
  {
    name: "Xã Quỳnh Sơn",
    code: 7702,
    division_type: "xã",
    codename: "xa_quynh_son",
    district_code: 221,
  },
  {
    name: "Xã Nội Hoàng",
    code: 7708,
    division_type: "xã",
    codename: "xa_noi_hoang",
    district_code: 221,
  },
  {
    name: "Xã Tiền Phong",
    code: 7711,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 221,
  },
  {
    name: "Xã Xuân Phú",
    code: 7714,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 221,
  },
  {
    name: "Xã Tân Liễu",
    code: 7717,
    division_type: "xã",
    codename: "xa_tan_lieu",
    district_code: 221,
  },
  {
    name: "Xã Trí Yên",
    code: 7720,
    division_type: "xã",
    codename: "xa_tri_yen",
    district_code: 221,
  },
  {
    name: "Xã Lãng Sơn",
    code: 7723,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 221,
  },
  {
    name: "Xã Yên Lư",
    code: 7726,
    division_type: "xã",
    codename: "xa_yen_lu",
    district_code: 221,
  },
  {
    name: "Xã Tiến Dũng",
    code: 7729,
    division_type: "xã",
    codename: "xa_tien_dung",
    district_code: 221,
  },
  {
    name: "Xã Đức Giang",
    code: 7735,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 221,
  },
  {
    name: "Xã Cảnh Thụy",
    code: 7738,
    division_type: "xã",
    codename: "xa_canh_thuy",
    district_code: 221,
  },
  {
    name: "Xã Tư Mại",
    code: 7741,
    division_type: "xã",
    codename: "xa_tu_mai",
    district_code: 221,
  },
  {
    name: "Xã Đồng Việt",
    code: 7747,
    division_type: "xã",
    codename: "xa_dong_viet",
    district_code: 221,
  },
  {
    name: "Xã Đồng Phúc",
    code: 7750,
    division_type: "xã",
    codename: "xa_dong_phuc",
    district_code: 221,
  },
  {
    name: "Xã Thượng Lan",
    code: 7759,
    division_type: "xã",
    codename: "xa_thuong_lan",
    district_code: 222,
  },
  {
    name: "Xã Việt Tiến",
    code: 7762,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 222,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 7765,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 222,
  },
  {
    name: "Xã Minh Đức",
    code: 7768,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 222,
  },
  {
    name: "Xã Hương Mai",
    code: 7771,
    division_type: "xã",
    codename: "xa_huong_mai",
    district_code: 222,
  },
  {
    name: "Xã Tự Lạn",
    code: 7774,
    division_type: "xã",
    codename: "xa_tu_lan",
    district_code: 222,
  },
  {
    name: "Thị trấn Bích Động",
    code: 7777,
    division_type: "thị trấn",
    codename: "thi_tran_bich_dong",
    district_code: 222,
  },
  {
    name: "Xã Trung Sơn",
    code: 7780,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 222,
  },
  {
    name: "Xã Hồng Thái",
    code: 7783,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 222,
  },
  {
    name: "Xã Tiên Sơn",
    code: 7786,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 222,
  },
  {
    name: "Xã Tăng Tiến",
    code: 7789,
    division_type: "xã",
    codename: "xa_tang_tien",
    district_code: 222,
  },
  {
    name: "Xã Quảng Minh",
    code: 7792,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 222,
  },
  {
    name: "Thị trấn Nếnh",
    code: 7795,
    division_type: "thị trấn",
    codename: "thi_tran_nenh",
    district_code: 222,
  },
  {
    name: "Xã Ninh Sơn",
    code: 7798,
    division_type: "xã",
    codename: "xa_ninh_son",
    district_code: 222,
  },
  {
    name: "Xã Vân Trung",
    code: 7801,
    division_type: "xã",
    codename: "xa_van_trung",
    district_code: 222,
  },
  {
    name: "Xã Vân Hà",
    code: 7804,
    division_type: "xã",
    codename: "xa_van_ha",
    district_code: 222,
  },
  {
    name: "Xã Quang Châu",
    code: 7807,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 222,
  },
  {
    name: "Xã Đồng Tân",
    code: 7813,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 223,
  },
  {
    name: "Xã Thanh Vân",
    code: 7816,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Lương",
    code: 7819,
    division_type: "xã",
    codename: "xa_hoang_luong",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Vân",
    code: 7822,
    division_type: "xã",
    codename: "xa_hoang_van",
    district_code: 223,
  },
  {
    name: "Xã Hoàng Thanh",
    code: 7825,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 223,
  },
  {
    name: "Xã Hoàng An",
    code: 7828,
    division_type: "xã",
    codename: "xa_hoang_an",
    district_code: 223,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 7831,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 223,
  },
  {
    name: "Xã Thái Sơn",
    code: 7834,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 223,
  },
  {
    name: "Xã Hòa Sơn",
    code: 7837,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 223,
  },
  {
    name: "Thị trấn Thắng",
    code: 7840,
    division_type: "thị trấn",
    codename: "thi_tran_thang",
    district_code: 223,
  },
  {
    name: "Xã Quang Minh",
    code: 7843,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 223,
  },
  {
    name: "Xã Lương Phong",
    code: 7846,
    division_type: "xã",
    codename: "xa_luong_phong",
    district_code: 223,
  },
  {
    name: "Xã Hùng Sơn",
    code: 7849,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 223,
  },
  {
    name: "Xã Đại Thành",
    code: 7852,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 223,
  },
  {
    name: "Xã Thường Thắng",
    code: 7855,
    division_type: "xã",
    codename: "xa_thuong_thang",
    district_code: 223,
  },
  {
    name: "Xã Hợp Thịnh",
    code: 7858,
    division_type: "xã",
    codename: "xa_hop_thinh",
    district_code: 223,
  },
  {
    name: "Xã Danh Thắng",
    code: 7861,
    division_type: "xã",
    codename: "xa_danh_thang",
    district_code: 223,
  },
  {
    name: "Xã Mai Trung",
    code: 7864,
    division_type: "xã",
    codename: "xa_mai_trung",
    district_code: 223,
  },
  {
    name: "Xã Đoan Bái",
    code: 7867,
    division_type: "xã",
    codename: "xa_doan_bai",
    district_code: 223,
  },
  {
    name: "Xã Bắc Lý",
    code: 7870,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 223,
  },
  {
    name: "Xã Xuân Cẩm",
    code: 7873,
    division_type: "xã",
    codename: "xa_xuan_cam",
    district_code: 223,
  },
  {
    name: "Xã Hương Lâm",
    code: 7876,
    division_type: "xã",
    codename: "xa_huong_lam",
    district_code: 223,
  },
  {
    name: "Xã Đông Lỗ",
    code: 7879,
    division_type: "xã",
    codename: "xa_dong_lo",
    district_code: 223,
  },
  {
    name: "Xã Châu Minh",
    code: 7882,
    division_type: "xã",
    codename: "xa_chau_minh",
    district_code: 223,
  },
  {
    name: "Xã Mai Đình",
    code: 7885,
    division_type: "xã",
    codename: "xa_mai_dinh",
    district_code: 223,
  },
  {
    name: "Phường Dữu Lâu",
    code: 7888,
    division_type: "phường",
    codename: "phuong_duu_lau",
    district_code: 227,
  },
  {
    name: "Phường Vân Cơ",
    code: 7891,
    division_type: "phường",
    codename: "phuong_van_co",
    district_code: 227,
  },
  {
    name: "Phường Nông Trang",
    code: 7894,
    division_type: "phường",
    codename: "phuong_nong_trang",
    district_code: 227,
  },
  {
    name: "Phường Tân Dân",
    code: 7897,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 227,
  },
  {
    name: "Phường Gia Cẩm",
    code: 7900,
    division_type: "phường",
    codename: "phuong_gia_cam",
    district_code: 227,
  },
  {
    name: "Phường Tiên Cát",
    code: 7903,
    division_type: "phường",
    codename: "phuong_tien_cat",
    district_code: 227,
  },
  {
    name: "Phường Thọ Sơn",
    code: 7906,
    division_type: "phường",
    codename: "phuong_tho_son",
    district_code: 227,
  },
  {
    name: "Phường Thanh Miếu",
    code: 7909,
    division_type: "phường",
    codename: "phuong_thanh_mieu",
    district_code: 227,
  },
  {
    name: "Phường Bạch Hạc",
    code: 7912,
    division_type: "phường",
    codename: "phuong_bach_hac",
    district_code: 227,
  },
  {
    name: "Phường Bến Gót",
    code: 7915,
    division_type: "phường",
    codename: "phuong_ben_got",
    district_code: 227,
  },
  {
    name: "Phường Vân Phú",
    code: 7918,
    division_type: "phường",
    codename: "phuong_van_phu",
    district_code: 227,
  },
  {
    name: "Xã Phượng Lâu",
    code: 7921,
    division_type: "xã",
    codename: "xa_phuong_lau",
    district_code: 227,
  },
  {
    name: "Xã Thụy Vân",
    code: 7924,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 227,
  },
  {
    name: "Phường Minh Phương",
    code: 7927,
    division_type: "phường",
    codename: "phuong_minh_phuong",
    district_code: 227,
  },
  {
    name: "Xã Trưng Vương",
    code: 7930,
    division_type: "xã",
    codename: "xa_trung_vuong",
    district_code: 227,
  },
  {
    name: "Phường Minh Nông",
    code: 7933,
    division_type: "phường",
    codename: "phuong_minh_nong",
    district_code: 227,
  },
  {
    name: "Xã Sông Lô",
    code: 7936,
    division_type: "xã",
    codename: "xa_song_lo",
    district_code: 227,
  },
  {
    name: "Xã Kim Đức",
    code: 8281,
    division_type: "xã",
    codename: "xa_kim_duc",
    district_code: 227,
  },
  {
    name: "Xã Hùng Lô",
    code: 8287,
    division_type: "xã",
    codename: "xa_hung_lo",
    district_code: 227,
  },
  {
    name: "Xã Hy Cương",
    code: 8503,
    division_type: "xã",
    codename: "xa_hy_cuong",
    district_code: 227,
  },
  {
    name: "Xã Chu Hóa",
    code: 8506,
    division_type: "xã",
    codename: "xa_chu_hoa",
    district_code: 227,
  },
  {
    name: "Xã Thanh Đình",
    code: 8515,
    division_type: "xã",
    codename: "xa_thanh_dinh",
    district_code: 227,
  },
  {
    name: "Phường Hùng Vương",
    code: 7942,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 228,
  },
  {
    name: "Phường Phong Châu",
    code: 7945,
    division_type: "phường",
    codename: "phuong_phong_chau",
    district_code: 228,
  },
  {
    name: "Phường Âu Cơ",
    code: 7948,
    division_type: "phường",
    codename: "phuong_au_co",
    district_code: 228,
  },
  {
    name: "Xã Hà Lộc",
    code: 7951,
    division_type: "xã",
    codename: "xa_ha_loc",
    district_code: 228,
  },
  {
    name: "Xã Phú Hộ",
    code: 7954,
    division_type: "xã",
    codename: "xa_phu_ho",
    district_code: 228,
  },
  {
    name: "Xã Văn Lung",
    code: 7957,
    division_type: "xã",
    codename: "xa_van_lung",
    district_code: 228,
  },
  {
    name: "Xã Thanh Minh",
    code: 7960,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 228,
  },
  {
    name: "Xã Hà Thạch",
    code: 7963,
    division_type: "xã",
    codename: "xa_ha_thach",
    district_code: 228,
  },
  {
    name: "Phường Thanh Vinh",
    code: 7966,
    division_type: "phường",
    codename: "phuong_thanh_vinh",
    district_code: 228,
  },
  {
    name: "Thị trấn Đoan Hùng",
    code: 7969,
    division_type: "thị trấn",
    codename: "thi_tran_doan_hung",
    district_code: 230,
  },
  {
    name: "Xã Hùng Xuyên",
    code: 7975,
    division_type: "xã",
    codename: "xa_hung_xuyen",
    district_code: 230,
  },
  {
    name: "Xã Bằng Luân",
    code: 7981,
    division_type: "xã",
    codename: "xa_bang_luan",
    district_code: 230,
  },
  {
    name: "Xã Vân Du",
    code: 7984,
    division_type: "xã",
    codename: "xa_van_du",
    district_code: 230,
  },
  {
    name: "Xã Phú Lâm",
    code: 7987,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 230,
  },
  {
    name: "Xã Minh Lương",
    code: 7993,
    division_type: "xã",
    codename: "xa_minh_luong",
    district_code: 230,
  },
  {
    name: "Xã Bằng Doãn",
    code: 7996,
    division_type: "xã",
    codename: "xa_bang_doan",
    district_code: 230,
  },
  {
    name: "Xã Chí Đám",
    code: 7999,
    division_type: "xã",
    codename: "xa_chi_dam",
    district_code: 230,
  },
  {
    name: "Xã Phúc Lai",
    code: 8005,
    division_type: "xã",
    codename: "xa_phuc_lai",
    district_code: 230,
  },
  {
    name: "Xã Ngọc Quan",
    code: 8008,
    division_type: "xã",
    codename: "xa_ngoc_quan",
    district_code: 230,
  },
  {
    name: "Xã Hợp Nhất",
    code: 8014,
    division_type: "xã",
    codename: "xa_hop_nhat",
    district_code: 230,
  },
  {
    name: "Xã Sóc Đăng",
    code: 8017,
    division_type: "xã",
    codename: "xa_soc_dang",
    district_code: 230,
  },
  {
    name: "Xã Tây Cốc",
    code: 8023,
    division_type: "xã",
    codename: "xa_tay_coc",
    district_code: 230,
  },
  {
    name: "Xã Yên Kiện",
    code: 8026,
    division_type: "xã",
    codename: "xa_yen_kien",
    district_code: 230,
  },
  {
    name: "Xã Hùng Long",
    code: 8029,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 230,
  },
  {
    name: "Xã Vụ Quang",
    code: 8032,
    division_type: "xã",
    codename: "xa_vu_quang",
    district_code: 230,
  },
  {
    name: "Xã Vân Đồn",
    code: 8035,
    division_type: "xã",
    codename: "xa_van_don",
    district_code: 230,
  },
  {
    name: "Xã Tiêu Sơn",
    code: 8038,
    division_type: "xã",
    codename: "xa_tieu_son",
    district_code: 230,
  },
  {
    name: "Xã Minh Tiến",
    code: 8041,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 230,
  },
  {
    name: "Xã Minh Phú",
    code: 8044,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 230,
  },
  {
    name: "Xã Chân Mộng",
    code: 8047,
    division_type: "xã",
    codename: "xa_chan_mong",
    district_code: 230,
  },
  {
    name: "Xã Ca Đình",
    code: 8050,
    division_type: "xã",
    codename: "xa_ca_dinh",
    district_code: 230,
  },
  {
    name: "Thị trấn Hạ Hoà",
    code: 8053,
    division_type: "thị trấn",
    codename: "thi_tran_ha_hoa",
    district_code: 231,
  },
  {
    name: "Xã Đại Phạm",
    code: 8056,
    division_type: "xã",
    codename: "xa_dai_pham",
    district_code: 231,
  },
  {
    name: "Xã Đan Thượng",
    code: 8062,
    division_type: "xã",
    codename: "xa_dan_thuong",
    district_code: 231,
  },
  {
    name: "Xã Hà Lương",
    code: 8065,
    division_type: "xã",
    codename: "xa_ha_luong",
    district_code: 231,
  },
  {
    name: "Xã Tứ Hiệp",
    code: 8071,
    division_type: "xã",
    codename: "xa_tu_hiep",
    district_code: 231,
  },
  {
    name: "Xã Hiền Lương",
    code: 8080,
    division_type: "xã",
    codename: "xa_hien_luong",
    district_code: 231,
  },
  {
    name: "Xã Phương Viên",
    code: 8089,
    division_type: "xã",
    codename: "xa_phuong_vien",
    district_code: 231,
  },
  {
    name: "Xã Gia Điền",
    code: 8092,
    division_type: "xã",
    codename: "xa_gia_dien",
    district_code: 231,
  },
  {
    name: "Xã Ấm Hạ",
    code: 8095,
    division_type: "xã",
    codename: "xa_am_ha",
    district_code: 231,
  },
  {
    name: "Xã Hương Xạ",
    code: 8104,
    division_type: "xã",
    codename: "xa_huong_xa",
    district_code: 231,
  },
  {
    name: "Xã Xuân Áng",
    code: 8110,
    division_type: "xã",
    codename: "xa_xuan_ang",
    district_code: 231,
  },
  {
    name: "Xã Yên Kỳ",
    code: 8113,
    division_type: "xã",
    codename: "xa_yen_ky",
    district_code: 231,
  },
  {
    name: "Xã Minh Hạc",
    code: 8119,
    division_type: "xã",
    codename: "xa_minh_hac",
    district_code: 231,
  },
  {
    name: "Xã Lang Sơn",
    code: 8122,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 231,
  },
  {
    name: "Xã Bằng Giã",
    code: 8125,
    division_type: "xã",
    codename: "xa_bang_gia",
    district_code: 231,
  },
  {
    name: "Xã Yên Luật",
    code: 8128,
    division_type: "xã",
    codename: "xa_yen_luat",
    district_code: 231,
  },
  {
    name: "Xã Vô Tranh",
    code: 8131,
    division_type: "xã",
    codename: "xa_vo_tranh",
    district_code: 231,
  },
  {
    name: "Xã Văn Lang",
    code: 8134,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 231,
  },
  {
    name: "Xã Minh Côi",
    code: 8140,
    division_type: "xã",
    codename: "xa_minh_coi",
    district_code: 231,
  },
  {
    name: "Xã Vĩnh Chân",
    code: 8143,
    division_type: "xã",
    codename: "xa_vinh_chan",
    district_code: 231,
  },
  {
    name: "Thị trấn Thanh Ba",
    code: 8152,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_ba",
    district_code: 232,
  },
  {
    name: "Xã Vân Lĩnh",
    code: 8156,
    division_type: "xã",
    codename: "xa_van_linh",
    district_code: 232,
  },
  {
    name: "Xã Đông Lĩnh",
    code: 8158,
    division_type: "xã",
    codename: "xa_dong_linh",
    district_code: 232,
  },
  {
    name: "Xã Đại An",
    code: 8161,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 232,
  },
  {
    name: "Xã Hanh Cù",
    code: 8164,
    division_type: "xã",
    codename: "xa_hanh_cu",
    district_code: 232,
  },
  {
    name: "Xã Đồng Xuân",
    code: 8170,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 232,
  },
  {
    name: "Xã Quảng Yên",
    code: 8173,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 232,
  },
  {
    name: "Xã Ninh Dân",
    code: 8179,
    division_type: "xã",
    codename: "xa_ninh_dan",
    district_code: 232,
  },
  {
    name: "Xã Võ Lao",
    code: 8194,
    division_type: "xã",
    codename: "xa_vo_lao",
    district_code: 232,
  },
  {
    name: "Xã Khải Xuân",
    code: 8197,
    division_type: "xã",
    codename: "xa_khai_xuan",
    district_code: 232,
  },
  {
    name: "Xã Mạn Lạn",
    code: 8200,
    division_type: "xã",
    codename: "xa_man_lan",
    district_code: 232,
  },
  {
    name: "Xã Hoàng Cương",
    code: 8203,
    division_type: "xã",
    codename: "xa_hoang_cuong",
    district_code: 232,
  },
  {
    name: "Xã Chí Tiên",
    code: 8206,
    division_type: "xã",
    codename: "xa_chi_tien",
    district_code: 232,
  },
  {
    name: "Xã Đông Thành",
    code: 8209,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 232,
  },
  {
    name: "Xã Sơn Cương",
    code: 8215,
    division_type: "xã",
    codename: "xa_son_cuong",
    district_code: 232,
  },
  {
    name: "Xã Thanh Hà",
    code: 8218,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 232,
  },
  {
    name: "Xã Đỗ Sơn",
    code: 8221,
    division_type: "xã",
    codename: "xa_do_son",
    district_code: 232,
  },
  {
    name: "Xã Đỗ Xuyên",
    code: 8224,
    division_type: "xã",
    codename: "xa_do_xuyen",
    district_code: 232,
  },
  {
    name: "Xã Lương Lỗ",
    code: 8227,
    division_type: "xã",
    codename: "xa_luong_lo",
    district_code: 232,
  },
  {
    name: "Thị trấn Phong Châu",
    code: 8230,
    division_type: "thị trấn",
    codename: "thi_tran_phong_chau",
    district_code: 233,
  },
  {
    name: "Xã Phú Mỹ",
    code: 8233,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 233,
  },
  {
    name: "Xã Lệ Mỹ",
    code: 8234,
    division_type: "xã",
    codename: "xa_le_my",
    district_code: 233,
  },
  {
    name: "Xã Liên Hoa",
    code: 8236,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 233,
  },
  {
    name: "Xã Trạm Thản",
    code: 8239,
    division_type: "xã",
    codename: "xa_tram_than",
    district_code: 233,
  },
  {
    name: "Xã Trị Quận",
    code: 8242,
    division_type: "xã",
    codename: "xa_tri_quan",
    district_code: 233,
  },
  {
    name: "Xã Trung Giáp",
    code: 8245,
    division_type: "xã",
    codename: "xa_trung_giap",
    district_code: 233,
  },
  {
    name: "Xã Tiên Phú",
    code: 8248,
    division_type: "xã",
    codename: "xa_tien_phu",
    district_code: 233,
  },
  {
    name: "Xã Hạ Giáp",
    code: 8251,
    division_type: "xã",
    codename: "xa_ha_giap",
    district_code: 233,
  },
  {
    name: "Xã Bảo Thanh",
    code: 8254,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 233,
  },
  {
    name: "Xã Phú Lộc",
    code: 8257,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 233,
  },
  {
    name: "Xã Gia Thanh",
    code: 8260,
    division_type: "xã",
    codename: "xa_gia_thanh",
    district_code: 233,
  },
  {
    name: "Xã Tiên Du",
    code: 8263,
    division_type: "xã",
    codename: "xa_tien_du",
    district_code: 233,
  },
  {
    name: "Xã Phú Nham",
    code: 8266,
    division_type: "xã",
    codename: "xa_phu_nham",
    district_code: 233,
  },
  {
    name: "Xã An Đạo",
    code: 8272,
    division_type: "xã",
    codename: "xa_an_dao",
    district_code: 233,
  },
  {
    name: "Xã Bình Phú",
    code: 8275,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 233,
  },
  {
    name: "Xã Phù Ninh",
    code: 8278,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 233,
  },
  {
    name: "Thị trấn Yên Lập",
    code: 8290,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lap",
    district_code: 234,
  },
  {
    name: "Xã Mỹ Lung",
    code: 8293,
    division_type: "xã",
    codename: "xa_my_lung",
    district_code: 234,
  },
  {
    name: "Xã Mỹ Lương",
    code: 8296,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 234,
  },
  {
    name: "Xã Lương Sơn",
    code: 8299,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 234,
  },
  {
    name: "Xã Xuân An",
    code: 8302,
    division_type: "xã",
    codename: "xa_xuan_an",
    district_code: 234,
  },
  {
    name: "Xã Xuân Viên",
    code: 8305,
    division_type: "xã",
    codename: "xa_xuan_vien",
    district_code: 234,
  },
  {
    name: "Xã Xuân Thủy",
    code: 8308,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 234,
  },
  {
    name: "Xã Trung Sơn",
    code: 8311,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 234,
  },
  {
    name: "Xã Hưng Long",
    code: 8314,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 234,
  },
  {
    name: "Xã Nga Hoàng",
    code: 8317,
    division_type: "xã",
    codename: "xa_nga_hoang",
    district_code: 234,
  },
  {
    name: "Xã Đồng Lạc",
    code: 8320,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 234,
  },
  {
    name: "Xã Thượng Long",
    code: 8323,
    division_type: "xã",
    codename: "xa_thuong_long",
    district_code: 234,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 8326,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 234,
  },
  {
    name: "Xã Phúc Khánh",
    code: 8329,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 234,
  },
  {
    name: "Xã Minh Hòa",
    code: 8332,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 234,
  },
  {
    name: "Xã Ngọc Lập",
    code: 8335,
    division_type: "xã",
    codename: "xa_ngoc_lap",
    district_code: 234,
  },
  {
    name: "Xã Ngọc Đồng",
    code: 8338,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 234,
  },
  {
    name: "Thị trấn Cẩm Khê",
    code: 8341,
    division_type: "thị trấn",
    codename: "thi_tran_cam_khe",
    district_code: 235,
  },
  {
    name: "Xã Tiên Lương",
    code: 8344,
    division_type: "xã",
    codename: "xa_tien_luong",
    district_code: 235,
  },
  {
    name: "Xã Tuy Lộc",
    code: 8347,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 235,
  },
  {
    name: "Xã Ngô Xá",
    code: 8350,
    division_type: "xã",
    codename: "xa_ngo_xa",
    district_code: 235,
  },
  {
    name: "Xã Minh Tân",
    code: 8353,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 235,
  },
  {
    name: "Xã Phượng Vĩ",
    code: 8356,
    division_type: "xã",
    codename: "xa_phuong_vi",
    district_code: 235,
  },
  {
    name: "Xã Thụy Liễu",
    code: 8362,
    division_type: "xã",
    codename: "xa_thuy_lieu",
    district_code: 235,
  },
  {
    name: "Xã Tùng Khê",
    code: 8374,
    division_type: "xã",
    codename: "xa_tung_khe",
    district_code: 235,
  },
  {
    name: "Xã Tam Sơn",
    code: 8377,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 235,
  },
  {
    name: "Xã Văn Bán",
    code: 8380,
    division_type: "xã",
    codename: "xa_van_ban",
    district_code: 235,
  },
  {
    name: "Xã Cấp Dẫn",
    code: 8383,
    division_type: "xã",
    codename: "xa_cap_dan",
    district_code: 235,
  },
  {
    name: "Xã Xương Thịnh",
    code: 8389,
    division_type: "xã",
    codename: "xa_xuong_thinh",
    district_code: 235,
  },
  {
    name: "Xã Phú Khê",
    code: 8392,
    division_type: "xã",
    codename: "xa_phu_khe",
    district_code: 235,
  },
  {
    name: "Xã Sơn Tình",
    code: 8395,
    division_type: "xã",
    codename: "xa_son_tinh",
    district_code: 235,
  },
  {
    name: "Xã Yên Tập",
    code: 8398,
    division_type: "xã",
    codename: "xa_yen_tap",
    district_code: 235,
  },
  {
    name: "Xã Hương Lung",
    code: 8401,
    division_type: "xã",
    codename: "xa_huong_lung",
    district_code: 235,
  },
  {
    name: "Xã Tạ Xá",
    code: 8404,
    division_type: "xã",
    codename: "xa_ta_xa",
    district_code: 235,
  },
  {
    name: "Xã Phú Lạc",
    code: 8407,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 235,
  },
  {
    name: "Xã Chương Xá",
    code: 8413,
    division_type: "xã",
    codename: "xa_chuong_xa",
    district_code: 235,
  },
  {
    name: "Xã Hùng Việt",
    code: 8416,
    division_type: "xã",
    codename: "xa_hung_viet",
    district_code: 235,
  },
  {
    name: "Xã Văn Khúc",
    code: 8419,
    division_type: "xã",
    codename: "xa_van_khuc",
    district_code: 235,
  },
  {
    name: "Xã Yên Dưỡng",
    code: 8422,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 235,
  },
  {
    name: "Xã Điêu Lương",
    code: 8428,
    division_type: "xã",
    codename: "xa_dieu_luong",
    district_code: 235,
  },
  {
    name: "Xã Đồng Lương",
    code: 8431,
    division_type: "xã",
    codename: "xa_dong_luong",
    district_code: 235,
  },
  {
    name: "Thị trấn Hưng Hoá",
    code: 8434,
    division_type: "thị trấn",
    codename: "thi_tran_hung_hoa",
    district_code: 236,
  },
  {
    name: "Xã Hiền Quan",
    code: 8440,
    division_type: "xã",
    codename: "xa_hien_quan",
    district_code: 236,
  },
  {
    name: "Xã Bắc Sơn",
    code: 8443,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 236,
  },
  {
    name: "Xã Thanh Uyên",
    code: 8446,
    division_type: "xã",
    codename: "xa_thanh_uyen",
    district_code: 236,
  },
  {
    name: "Xã Lam Sơn",
    code: 8461,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 236,
  },
  {
    name: "Xã Vạn Xuân",
    code: 8467,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 236,
  },
  {
    name: "Xã Quang Húc",
    code: 8470,
    division_type: "xã",
    codename: "xa_quang_huc",
    district_code: 236,
  },
  {
    name: "Xã Hương Nộn",
    code: 8473,
    division_type: "xã",
    codename: "xa_huong_non",
    district_code: 236,
  },
  {
    name: "Xã Tề Lễ",
    code: 8476,
    division_type: "xã",
    codename: "xa_te_le",
    district_code: 236,
  },
  {
    name: "Xã Thọ Văn",
    code: 8479,
    division_type: "xã",
    codename: "xa_tho_van",
    district_code: 236,
  },
  {
    name: "Xã Dị Nậu",
    code: 8482,
    division_type: "xã",
    codename: "xa_di_nau",
    district_code: 236,
  },
  {
    name: "Xã Dân Quyền",
    code: 8491,
    division_type: "xã",
    codename: "xa_dan_quyen",
    district_code: 236,
  },
  {
    name: "Thị trấn Lâm Thao",
    code: 8494,
    division_type: "thị trấn",
    codename: "thi_tran_lam_thao",
    district_code: 237,
  },
  {
    name: "Xã Tiên Kiên",
    code: 8497,
    division_type: "xã",
    codename: "xa_tien_kien",
    district_code: 237,
  },
  {
    name: "Thị trấn Hùng Sơn",
    code: 8498,
    division_type: "thị trấn",
    codename: "thi_tran_hung_son",
    district_code: 237,
  },
  {
    name: "Xã Xuân Lũng",
    code: 8500,
    division_type: "xã",
    codename: "xa_xuan_lung",
    district_code: 237,
  },
  {
    name: "Xã Xuân Huy",
    code: 8509,
    division_type: "xã",
    codename: "xa_xuan_huy",
    district_code: 237,
  },
  {
    name: "Xã Thạch Sơn",
    code: 8512,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 237,
  },
  {
    name: "Xã Sơn Vi",
    code: 8518,
    division_type: "xã",
    codename: "xa_son_vi",
    district_code: 237,
  },
  {
    name: "Xã Phùng Nguyên",
    code: 8521,
    division_type: "xã",
    codename: "xa_phung_nguyen",
    district_code: 237,
  },
  {
    name: "Xã Cao Xá",
    code: 8527,
    division_type: "xã",
    codename: "xa_cao_xa",
    district_code: 237,
  },
  {
    name: "Xã Vĩnh Lại",
    code: 8533,
    division_type: "xã",
    codename: "xa_vinh_lai",
    district_code: 237,
  },
  {
    name: "Xã Tứ Xã",
    code: 8536,
    division_type: "xã",
    codename: "xa_tu_xa",
    district_code: 237,
  },
  {
    name: "Xã Bản Nguyên",
    code: 8539,
    division_type: "xã",
    codename: "xa_ban_nguyen",
    district_code: 237,
  },
  {
    name: "Thị trấn Thanh Sơn",
    code: 8542,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_son",
    district_code: 238,
  },
  {
    name: "Xã Sơn Hùng",
    code: 8563,
    division_type: "xã",
    codename: "xa_son_hung",
    district_code: 238,
  },
  {
    name: "Xã Địch Quả",
    code: 8572,
    division_type: "xã",
    codename: "xa_dich_qua",
    district_code: 238,
  },
  {
    name: "Xã Giáp Lai",
    code: 8575,
    division_type: "xã",
    codename: "xa_giap_lai",
    district_code: 238,
  },
  {
    name: "Xã Thục Luyện",
    code: 8581,
    division_type: "xã",
    codename: "xa_thuc_luyen",
    district_code: 238,
  },
  {
    name: "Xã Võ Miếu",
    code: 8584,
    division_type: "xã",
    codename: "xa_vo_mieu",
    district_code: 238,
  },
  {
    name: "Xã Thạch Khoán",
    code: 8587,
    division_type: "xã",
    codename: "xa_thach_khoan",
    district_code: 238,
  },
  {
    name: "Xã Cự Thắng",
    code: 8602,
    division_type: "xã",
    codename: "xa_cu_thang",
    district_code: 238,
  },
  {
    name: "Xã Tất Thắng",
    code: 8605,
    division_type: "xã",
    codename: "xa_tat_thang",
    district_code: 238,
  },
  {
    name: "Xã Văn Miếu",
    code: 8611,
    division_type: "xã",
    codename: "xa_van_mieu",
    district_code: 238,
  },
  {
    name: "Xã Cự Đồng",
    code: 8614,
    division_type: "xã",
    codename: "xa_cu_dong",
    district_code: 238,
  },
  {
    name: "Xã Thắng Sơn",
    code: 8623,
    division_type: "xã",
    codename: "xa_thang_son",
    district_code: 238,
  },
  {
    name: "Xã Tân Minh",
    code: 8629,
    division_type: "xã",
    codename: "xa_tan_minh",
    district_code: 238,
  },
  {
    name: "Xã Hương Cần",
    code: 8632,
    division_type: "xã",
    codename: "xa_huong_can",
    district_code: 238,
  },
  {
    name: "Xã Khả Cửu",
    code: 8635,
    division_type: "xã",
    codename: "xa_kha_cuu",
    district_code: 238,
  },
  {
    name: "Xã Đông Cửu",
    code: 8638,
    division_type: "xã",
    codename: "xa_dong_cuu",
    district_code: 238,
  },
  {
    name: "Xã Tân Lập",
    code: 8641,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 238,
  },
  {
    name: "Xã Yên Lãng",
    code: 8644,
    division_type: "xã",
    codename: "xa_yen_lang",
    district_code: 238,
  },
  {
    name: "Xã Yên Lương",
    code: 8647,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 238,
  },
  {
    name: "Xã Thượng Cửu",
    code: 8650,
    division_type: "xã",
    codename: "xa_thuong_cuu",
    district_code: 238,
  },
  {
    name: "Xã Lương Nha",
    code: 8653,
    division_type: "xã",
    codename: "xa_luong_nha",
    district_code: 238,
  },
  {
    name: "Xã Yên Sơn",
    code: 8656,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 238,
  },
  {
    name: "Xã Tinh Nhuệ",
    code: 8659,
    division_type: "xã",
    codename: "xa_tinh_nhue",
    district_code: 238,
  },
  {
    name: "Xã Đào Xá",
    code: 8662,
    division_type: "xã",
    codename: "xa_dao_xa",
    district_code: 239,
  },
  {
    name: "Xã Thạch Đồng",
    code: 8665,
    division_type: "xã",
    codename: "xa_thach_dong",
    district_code: 239,
  },
  {
    name: "Xã Xuân Lộc",
    code: 8668,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 239,
  },
  {
    name: "Xã Tân Phương",
    code: 8671,
    division_type: "xã",
    codename: "xa_tan_phuong",
    district_code: 239,
  },
  {
    name: "Thị trấn Thanh Thủy",
    code: 8674,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_thuy",
    district_code: 239,
  },
  {
    name: "Xã Sơn Thủy",
    code: 8677,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 239,
  },
  {
    name: "Xã Bảo Yên",
    code: 8680,
    division_type: "xã",
    codename: "xa_bao_yen",
    district_code: 239,
  },
  {
    name: "Xã Đoan Hạ",
    code: 8683,
    division_type: "xã",
    codename: "xa_doan_ha",
    district_code: 239,
  },
  {
    name: "Xã Đồng Trung",
    code: 8686,
    division_type: "xã",
    codename: "xa_dong_trung",
    district_code: 239,
  },
  {
    name: "Xã Hoàng Xá",
    code: 8689,
    division_type: "xã",
    codename: "xa_hoang_xa",
    district_code: 239,
  },
  {
    name: "Xã Tu Vũ",
    code: 8701,
    division_type: "xã",
    codename: "xa_tu_vu",
    district_code: 239,
  },
  {
    name: "Xã Thu Cúc",
    code: 8545,
    division_type: "xã",
    codename: "xa_thu_cuc",
    district_code: 240,
  },
  {
    name: "Xã Thạch Kiệt",
    code: 8548,
    division_type: "xã",
    codename: "xa_thach_kiet",
    district_code: 240,
  },
  {
    name: "Xã Thu Ngạc",
    code: 8551,
    division_type: "xã",
    codename: "xa_thu_ngac",
    district_code: 240,
  },
  {
    name: "Xã Kiệt Sơn",
    code: 8554,
    division_type: "xã",
    codename: "xa_kiet_son",
    district_code: 240,
  },
  {
    name: "Xã Đồng Sơn",
    code: 8557,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 240,
  },
  {
    name: "Xã Lai Đồng",
    code: 8560,
    division_type: "xã",
    codename: "xa_lai_dong",
    district_code: 240,
  },
  {
    name: "Xã Tân Phú",
    code: 8566,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 240,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 8569,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 240,
  },
  {
    name: "Xã Tân Sơn",
    code: 8578,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 240,
  },
  {
    name: "Xã Xuân Đài",
    code: 8590,
    division_type: "xã",
    codename: "xa_xuan_dai",
    district_code: 240,
  },
  {
    name: "Xã Minh Đài",
    code: 8593,
    division_type: "xã",
    codename: "xa_minh_dai",
    district_code: 240,
  },
  {
    name: "Xã Văn Luông",
    code: 8596,
    division_type: "xã",
    codename: "xa_van_luong",
    district_code: 240,
  },
  {
    name: "Xã Xuân Sơn",
    code: 8599,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 240,
  },
  {
    name: "Xã Long Cốc",
    code: 8608,
    division_type: "xã",
    codename: "xa_long_coc",
    district_code: 240,
  },
  {
    name: "Xã Kim Thượng",
    code: 8617,
    division_type: "xã",
    codename: "xa_kim_thuong",
    district_code: 240,
  },
  {
    name: "Xã Tam Thanh",
    code: 8620,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 240,
  },
  {
    name: "Xã Vinh Tiền",
    code: 8626,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 240,
  },
  {
    name: "Phường Tích Sơn",
    code: 8707,
    division_type: "phường",
    codename: "phuong_tich_son",
    district_code: 243,
  },
  {
    name: "Phường Liên Bảo",
    code: 8710,
    division_type: "phường",
    codename: "phuong_lien_bao",
    district_code: 243,
  },
  {
    name: "Phường Hội Hợp",
    code: 8713,
    division_type: "phường",
    codename: "phuong_hoi_hop",
    district_code: 243,
  },
  {
    name: "Phường Đống Đa",
    code: 8716,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 243,
  },
  {
    name: "Phường Ngô Quyền",
    code: 8719,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 243,
  },
  {
    name: "Phường Đồng Tâm",
    code: 8722,
    division_type: "phường",
    codename: "phuong_dong_tam",
    district_code: 243,
  },
  {
    name: "Xã Định Trung",
    code: 8725,
    division_type: "xã",
    codename: "xa_dinh_trung",
    district_code: 243,
  },
  {
    name: "Phường Khai Quang",
    code: 8728,
    division_type: "phường",
    codename: "phuong_khai_quang",
    district_code: 243,
  },
  {
    name: "Xã Thanh Trù",
    code: 8731,
    division_type: "xã",
    codename: "xa_thanh_tru",
    district_code: 243,
  },
  {
    name: "Phường Trưng Trắc",
    code: 8734,
    division_type: "phường",
    codename: "phuong_trung_trac",
    district_code: 244,
  },
  {
    name: "Phường Hùng Vương",
    code: 8737,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 244,
  },
  {
    name: "Phường Trưng Nhị",
    code: 8740,
    division_type: "phường",
    codename: "phuong_trung_nhi",
    district_code: 244,
  },
  {
    name: "Phường Phúc Thắng",
    code: 8743,
    division_type: "phường",
    codename: "phuong_phuc_thang",
    district_code: 244,
  },
  {
    name: "Phường Xuân Hoà",
    code: 8746,
    division_type: "phường",
    codename: "phuong_xuan_hoa",
    district_code: 244,
  },
  {
    name: "Phường Đồng Xuân",
    code: 8747,
    division_type: "phường",
    codename: "phuong_dong_xuan",
    district_code: 244,
  },
  {
    name: "Xã Ngọc Thanh",
    code: 8749,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 244,
  },
  {
    name: "Xã Cao Minh",
    code: 8752,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 244,
  },
  {
    name: "Phường Nam Viêm",
    code: 8755,
    division_type: "phường",
    codename: "phuong_nam_viem",
    district_code: 244,
  },
  {
    name: "Phường Tiền Châu",
    code: 8758,
    division_type: "phường",
    codename: "phuong_tien_chau",
    district_code: 244,
  },
  {
    name: "Thị trấn Lập Thạch",
    code: 8761,
    division_type: "thị trấn",
    codename: "thi_tran_lap_thach",
    district_code: 246,
  },
  {
    name: "Xã Quang Sơn",
    code: 8764,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 246,
  },
  {
    name: "Xã Ngọc Mỹ",
    code: 8767,
    division_type: "xã",
    codename: "xa_ngoc_my",
    district_code: 246,
  },
  {
    name: "Xã Hợp Lý",
    code: 8770,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 246,
  },
  {
    name: "Xã Bắc Bình",
    code: 8785,
    division_type: "xã",
    codename: "xa_bac_binh",
    district_code: 246,
  },
  {
    name: "Xã Thái Hòa",
    code: 8788,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 246,
  },
  {
    name: "Thị trấn Hoa Sơn",
    code: 8789,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_son",
    district_code: 246,
  },
  {
    name: "Xã Liễn Sơn",
    code: 8791,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 246,
  },
  {
    name: "Xã Xuân Hòa",
    code: 8794,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 246,
  },
  {
    name: "Xã Vân Trục",
    code: 8797,
    division_type: "xã",
    codename: "xa_van_truc",
    district_code: 246,
  },
  {
    name: "Xã Liên Hòa",
    code: 8812,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 246,
  },
  {
    name: "Xã Tử Du",
    code: 8815,
    division_type: "xã",
    codename: "xa_tu_du",
    district_code: 246,
  },
  {
    name: "Xã Bàn Giản",
    code: 8833,
    division_type: "xã",
    codename: "xa_ban_gian",
    district_code: 246,
  },
  {
    name: "Xã Xuân Lôi",
    code: 8836,
    division_type: "xã",
    codename: "xa_xuan_loi",
    district_code: 246,
  },
  {
    name: "Xã Đồng Ích",
    code: 8839,
    division_type: "xã",
    codename: "xa_dong_ich",
    district_code: 246,
  },
  {
    name: "Xã Tiên Lữ",
    code: 8842,
    division_type: "xã",
    codename: "xa_tien_lu",
    district_code: 246,
  },
  {
    name: "Xã Văn Quán",
    code: 8845,
    division_type: "xã",
    codename: "xa_van_quan",
    district_code: 246,
  },
  {
    name: "Xã Đình Chu",
    code: 8857,
    division_type: "xã",
    codename: "xa_dinh_chu",
    district_code: 246,
  },
  {
    name: "Xã Triệu Đề",
    code: 8863,
    division_type: "xã",
    codename: "xa_trieu_de",
    district_code: 246,
  },
  {
    name: "Xã Sơn Đông",
    code: 8866,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 246,
  },
  {
    name: "Thị trấn Hợp Hòa",
    code: 8869,
    division_type: "thị trấn",
    codename: "thi_tran_hop_hoa",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Hoa",
    code: 8872,
    division_type: "xã",
    codename: "xa_hoang_hoa",
    district_code: 247,
  },
  {
    name: "Xã Đồng Tĩnh",
    code: 8875,
    division_type: "xã",
    codename: "xa_dong_tinh",
    district_code: 247,
  },
  {
    name: "Xã Kim Long",
    code: 8878,
    division_type: "xã",
    codename: "xa_kim_long",
    district_code: 247,
  },
  {
    name: "Xã Hướng Đạo",
    code: 8881,
    division_type: "xã",
    codename: "xa_huong_dao",
    district_code: 247,
  },
  {
    name: "Xã Đạo Tú",
    code: 8884,
    division_type: "xã",
    codename: "xa_dao_tu",
    district_code: 247,
  },
  {
    name: "Xã An Hòa",
    code: 8887,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 247,
  },
  {
    name: "Xã Thanh Vân",
    code: 8890,
    division_type: "xã",
    codename: "xa_thanh_van",
    district_code: 247,
  },
  {
    name: "Xã Duy Phiên",
    code: 8893,
    division_type: "xã",
    codename: "xa_duy_phien",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Đan",
    code: 8896,
    division_type: "xã",
    codename: "xa_hoang_dan",
    district_code: 247,
  },
  {
    name: "Xã Hoàng Lâu",
    code: 8899,
    division_type: "xã",
    codename: "xa_hoang_lau",
    district_code: 247,
  },
  {
    name: "Xã Vân Hội",
    code: 8902,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 247,
  },
  {
    name: "Xã Hợp Thịnh",
    code: 8905,
    division_type: "xã",
    codename: "xa_hop_thinh",
    district_code: 247,
  },
  {
    name: "Thị trấn Tam Đảo",
    code: 8908,
    division_type: "thị trấn",
    codename: "thi_tran_tam_dao",
    district_code: 248,
  },
  {
    name: "Thị trấn Hợp Châu",
    code: 8911,
    division_type: "thị trấn",
    codename: "thi_tran_hop_chau",
    district_code: 248,
  },
  {
    name: "Xã Đạo Trù",
    code: 8914,
    division_type: "xã",
    codename: "xa_dao_tru",
    district_code: 248,
  },
  {
    name: "Xã Yên Dương",
    code: 8917,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 248,
  },
  {
    name: "Xã Bồ Lý",
    code: 8920,
    division_type: "xã",
    codename: "xa_bo_ly",
    district_code: 248,
  },
  {
    name: "Thị trấn Đại Đình",
    code: 8923,
    division_type: "thị trấn",
    codename: "thi_tran_dai_dinh",
    district_code: 248,
  },
  {
    name: "Xã Tam Quan",
    code: 8926,
    division_type: "xã",
    codename: "xa_tam_quan",
    district_code: 248,
  },
  {
    name: "Xã Hồ Sơn",
    code: 8929,
    division_type: "xã",
    codename: "xa_ho_son",
    district_code: 248,
  },
  {
    name: "Xã Minh Quang",
    code: 8932,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 248,
  },
  {
    name: "Thị trấn Hương Canh",
    code: 8935,
    division_type: "thị trấn",
    codename: "thi_tran_huong_canh",
    district_code: 249,
  },
  {
    name: "Thị trấn Gia Khánh",
    code: 8936,
    division_type: "thị trấn",
    codename: "thi_tran_gia_khanh",
    district_code: 249,
  },
  {
    name: "Xã Trung Mỹ",
    code: 8938,
    division_type: "xã",
    codename: "xa_trung_my",
    district_code: 249,
  },
  {
    name: "Thị trấn Bá Hiến",
    code: 8944,
    division_type: "thị trấn",
    codename: "thi_tran_ba_hien",
    district_code: 249,
  },
  {
    name: "Xã Thiện Kế",
    code: 8947,
    division_type: "xã",
    codename: "xa_thien_ke",
    district_code: 249,
  },
  {
    name: "Xã Hương Sơn",
    code: 8950,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 249,
  },
  {
    name: "Xã Tam Hợp",
    code: 8953,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 249,
  },
  {
    name: "Xã Quất Lưu",
    code: 8956,
    division_type: "xã",
    codename: "xa_quat_luu",
    district_code: 249,
  },
  {
    name: "Xã Sơn Lôi",
    code: 8959,
    division_type: "xã",
    codename: "xa_son_loi",
    district_code: 249,
  },
  {
    name: "Thị trấn Đạo Đức",
    code: 8962,
    division_type: "thị trấn",
    codename: "thi_tran_dao_duc",
    district_code: 249,
  },
  {
    name: "Xã Tân Phong",
    code: 8965,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 249,
  },
  {
    name: "Thị trấn Thanh Lãng",
    code: 8968,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_lang",
    district_code: 249,
  },
  {
    name: "Xã Phú Xuân",
    code: 8971,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 249,
  },
  {
    name: "Thị trấn Yên Lạc",
    code: 9025,
    division_type: "thị trấn",
    codename: "thi_tran_yen_lac",
    district_code: 251,
  },
  {
    name: "Xã Đồng Cương",
    code: 9028,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 251,
  },
  {
    name: "Xã Đồng Văn",
    code: 9031,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 251,
  },
  {
    name: "Xã Bình Định",
    code: 9034,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 251,
  },
  {
    name: "Xã Trung Nguyên",
    code: 9037,
    division_type: "xã",
    codename: "xa_trung_nguyen",
    district_code: 251,
  },
  {
    name: "Xã Tề Lỗ",
    code: 9040,
    division_type: "xã",
    codename: "xa_te_lo",
    district_code: 251,
  },
  {
    name: "Xã Tam Hồng",
    code: 9043,
    division_type: "xã",
    codename: "xa_tam_hong",
    district_code: 251,
  },
  {
    name: "Xã Yên Đồng",
    code: 9046,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 251,
  },
  {
    name: "Xã Văn Tiến",
    code: 9049,
    division_type: "xã",
    codename: "xa_van_tien",
    district_code: 251,
  },
  {
    name: "Xã Nguyệt Đức",
    code: 9052,
    division_type: "xã",
    codename: "xa_nguyet_duc",
    district_code: 251,
  },
  {
    name: "Xã Yên Phương",
    code: 9055,
    division_type: "xã",
    codename: "xa_yen_phuong",
    district_code: 251,
  },
  {
    name: "Xã Hồng Phương",
    code: 9058,
    division_type: "xã",
    codename: "xa_hong_phuong",
    district_code: 251,
  },
  {
    name: "Xã Trung Kiên",
    code: 9061,
    division_type: "xã",
    codename: "xa_trung_kien",
    district_code: 251,
  },
  {
    name: "Xã Liên Châu",
    code: 9064,
    division_type: "xã",
    codename: "xa_lien_chau",
    district_code: 251,
  },
  {
    name: "Xã Đại Tự",
    code: 9067,
    division_type: "xã",
    codename: "xa_dai_tu",
    district_code: 251,
  },
  {
    name: "Xã Hồng Châu",
    code: 9070,
    division_type: "xã",
    codename: "xa_hong_chau",
    district_code: 251,
  },
  {
    name: "Xã Trung Hà",
    code: 9073,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 251,
  },
  {
    name: "Thị trấn Vĩnh Tường",
    code: 9076,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tuong",
    district_code: 252,
  },
  {
    name: "Xã Kim Xá",
    code: 9079,
    division_type: "xã",
    codename: "xa_kim_xa",
    district_code: 252,
  },
  {
    name: "Xã Yên Bình",
    code: 9082,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 252,
  },
  {
    name: "Xã Chấn Hưng",
    code: 9085,
    division_type: "xã",
    codename: "xa_chan_hung",
    district_code: 252,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 9088,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 252,
  },
  {
    name: "Xã Yên Lập",
    code: 9091,
    division_type: "xã",
    codename: "xa_yen_lap",
    district_code: 252,
  },
  {
    name: "Xã Việt Xuân",
    code: 9094,
    division_type: "xã",
    codename: "xa_viet_xuan",
    district_code: 252,
  },
  {
    name: "Xã Bồ Sao",
    code: 9097,
    division_type: "xã",
    codename: "xa_bo_sao",
    district_code: 252,
  },
  {
    name: "Xã Đại Đồng",
    code: 9100,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 252,
  },
  {
    name: "Xã Tân Tiến",
    code: 9103,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 252,
  },
  {
    name: "Xã Lũng Hoà",
    code: 9106,
    division_type: "xã",
    codename: "xa_lung_hoa",
    district_code: 252,
  },
  {
    name: "Xã Cao Đại",
    code: 9109,
    division_type: "xã",
    codename: "xa_cao_dai",
    district_code: 252,
  },
  {
    name: "Thị trấn Thổ Tang",
    code: 9112,
    division_type: "thị trấn",
    codename: "thi_tran_tho_tang",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 9115,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 252,
  },
  {
    name: "Xã Bình Dương",
    code: 9118,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 252,
  },
  {
    name: "Xã Tân Phú",
    code: 9124,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 252,
  },
  {
    name: "Xã Thượng Trưng",
    code: 9127,
    division_type: "xã",
    codename: "xa_thuong_trung",
    district_code: 252,
  },
  {
    name: "Xã Vũ Di",
    code: 9130,
    division_type: "xã",
    codename: "xa_vu_di",
    district_code: 252,
  },
  {
    name: "Xã Lý Nhân",
    code: 9133,
    division_type: "xã",
    codename: "xa_ly_nhan",
    district_code: 252,
  },
  {
    name: "Xã Tuân Chính",
    code: 9136,
    division_type: "xã",
    codename: "xa_tuan_chinh",
    district_code: 252,
  },
  {
    name: "Xã Vân Xuân",
    code: 9139,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 252,
  },
  {
    name: "Xã Tam Phúc",
    code: 9142,
    division_type: "xã",
    codename: "xa_tam_phuc",
    district_code: 252,
  },
  {
    name: "Thị trấn Tứ Trưng",
    code: 9145,
    division_type: "thị trấn",
    codename: "thi_tran_tu_trung",
    district_code: 252,
  },
  {
    name: "Xã Ngũ Kiên",
    code: 9148,
    division_type: "xã",
    codename: "xa_ngu_kien",
    district_code: 252,
  },
  {
    name: "Xã An Tường",
    code: 9151,
    division_type: "xã",
    codename: "xa_an_tuong",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 9154,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 252,
  },
  {
    name: "Xã Phú Đa",
    code: 9157,
    division_type: "xã",
    codename: "xa_phu_da",
    district_code: 252,
  },
  {
    name: "Xã Vĩnh Ninh",
    code: 9160,
    division_type: "xã",
    codename: "xa_vinh_ninh",
    district_code: 252,
  },
  {
    name: "Xã Lãng Công",
    code: 8773,
    division_type: "xã",
    codename: "xa_lang_cong",
    district_code: 253,
  },
  {
    name: "Xã Quang Yên",
    code: 8776,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 253,
  },
  {
    name: "Xã Bạch Lưu",
    code: 8779,
    division_type: "xã",
    codename: "xa_bach_luu",
    district_code: 253,
  },
  {
    name: "Xã Hải Lựu",
    code: 8782,
    division_type: "xã",
    codename: "xa_hai_luu",
    district_code: 253,
  },
  {
    name: "Xã Đồng Quế",
    code: 8800,
    division_type: "xã",
    codename: "xa_dong_que",
    district_code: 253,
  },
  {
    name: "Xã Nhân Đạo",
    code: 8803,
    division_type: "xã",
    codename: "xa_nhan_dao",
    district_code: 253,
  },
  {
    name: "Xã Đôn Nhân",
    code: 8806,
    division_type: "xã",
    codename: "xa_don_nhan",
    district_code: 253,
  },
  {
    name: "Xã Phương Khoan",
    code: 8809,
    division_type: "xã",
    codename: "xa_phuong_khoan",
    district_code: 253,
  },
  {
    name: "Xã Tân Lập",
    code: 8818,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 253,
  },
  {
    name: "Xã Nhạo Sơn",
    code: 8821,
    division_type: "xã",
    codename: "xa_nhao_son",
    district_code: 253,
  },
  {
    name: "Thị trấn Tam Sơn",
    code: 8824,
    division_type: "thị trấn",
    codename: "thi_tran_tam_son",
    district_code: 253,
  },
  {
    name: "Xã Như Thụy",
    code: 8827,
    division_type: "xã",
    codename: "xa_nhu_thuy",
    district_code: 253,
  },
  {
    name: "Xã Yên Thạch",
    code: 8830,
    division_type: "xã",
    codename: "xa_yen_thach",
    district_code: 253,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 8848,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 253,
  },
  {
    name: "Xã Tứ Yên",
    code: 8851,
    division_type: "xã",
    codename: "xa_tu_yen",
    district_code: 253,
  },
  {
    name: "Xã Đức Bác",
    code: 8854,
    division_type: "xã",
    codename: "xa_duc_bac",
    district_code: 253,
  },
  {
    name: "Xã Cao Phong",
    code: 8860,
    division_type: "xã",
    codename: "xa_cao_phong",
    district_code: 253,
  },
  {
    name: "Phường Vũ Ninh",
    code: 9163,
    division_type: "phường",
    codename: "phuong_vu_ninh",
    district_code: 256,
  },
  {
    name: "Phường Đáp Cầu",
    code: 9166,
    division_type: "phường",
    codename: "phuong_dap_cau",
    district_code: 256,
  },
  {
    name: "Phường Thị Cầu",
    code: 9169,
    division_type: "phường",
    codename: "phuong_thi_cau",
    district_code: 256,
  },
  {
    name: "Phường Kinh Bắc",
    code: 9172,
    division_type: "phường",
    codename: "phuong_kinh_bac",
    district_code: 256,
  },
  {
    name: "Phường Vệ An",
    code: 9175,
    division_type: "phường",
    codename: "phuong_ve_an",
    district_code: 256,
  },
  {
    name: "Phường Tiền An",
    code: 9178,
    division_type: "phường",
    codename: "phuong_tien_an",
    district_code: 256,
  },
  {
    name: "Phường Đại Phúc",
    code: 9181,
    division_type: "phường",
    codename: "phuong_dai_phuc",
    district_code: 256,
  },
  {
    name: "Phường Ninh Xá",
    code: 9184,
    division_type: "phường",
    codename: "phuong_ninh_xa",
    district_code: 256,
  },
  {
    name: "Phường Suối Hoa",
    code: 9187,
    division_type: "phường",
    codename: "phuong_suoi_hoa",
    district_code: 256,
  },
  {
    name: "Phường Võ Cường",
    code: 9190,
    division_type: "phường",
    codename: "phuong_vo_cuong",
    district_code: 256,
  },
  {
    name: "Phường Hòa Long",
    code: 9214,
    division_type: "phường",
    codename: "phuong_hoa_long",
    district_code: 256,
  },
  {
    name: "Phường Vạn An",
    code: 9226,
    division_type: "phường",
    codename: "phuong_van_an",
    district_code: 256,
  },
  {
    name: "Phường Khúc Xuyên",
    code: 9235,
    division_type: "phường",
    codename: "phuong_khuc_xuyen",
    district_code: 256,
  },
  {
    name: "Phường Phong Khê",
    code: 9244,
    division_type: "phường",
    codename: "phuong_phong_khe",
    district_code: 256,
  },
  {
    name: "Phường Kim Chân",
    code: 9256,
    division_type: "phường",
    codename: "phuong_kim_chan",
    district_code: 256,
  },
  {
    name: "Phường Vân Dương",
    code: 9271,
    division_type: "phường",
    codename: "phuong_van_duong",
    district_code: 256,
  },
  {
    name: "Phường Nam Sơn",
    code: 9286,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 256,
  },
  {
    name: "Phường Khắc Niệm",
    code: 9325,
    division_type: "phường",
    codename: "phuong_khac_niem",
    district_code: 256,
  },
  {
    name: "Phường Hạp Lĩnh",
    code: 9331,
    division_type: "phường",
    codename: "phuong_hap_linh",
    district_code: 256,
  },
  {
    name: "Thị trấn Chờ",
    code: 9193,
    division_type: "thị trấn",
    codename: "thi_tran_cho",
    district_code: 258,
  },
  {
    name: "Xã Dũng Liệt",
    code: 9196,
    division_type: "xã",
    codename: "xa_dung_liet",
    district_code: 258,
  },
  {
    name: "Xã Tam Đa",
    code: 9199,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 258,
  },
  {
    name: "Xã Tam Giang",
    code: 9202,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 258,
  },
  {
    name: "Xã Yên Trung",
    code: 9205,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 258,
  },
  {
    name: "Xã Thụy Hòa",
    code: 9208,
    division_type: "xã",
    codename: "xa_thuy_hoa",
    district_code: 258,
  },
  {
    name: "Xã Hòa Tiến",
    code: 9211,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 258,
  },
  {
    name: "Xã Đông Tiến",
    code: 9217,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 258,
  },
  {
    name: "Xã Yên Phụ",
    code: 9220,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 258,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 9223,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 258,
  },
  {
    name: "Xã Đông Phong",
    code: 9229,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 258,
  },
  {
    name: "Xã Long Châu",
    code: 9232,
    division_type: "xã",
    codename: "xa_long_chau",
    district_code: 258,
  },
  {
    name: "Xã Văn Môn",
    code: 9238,
    division_type: "xã",
    codename: "xa_van_mon",
    district_code: 258,
  },
  {
    name: "Xã Đông Thọ",
    code: 9241,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 258,
  },
  {
    name: "Thị trấn Phố Mới",
    code: 9247,
    division_type: "thị trấn",
    codename: "thi_tran_pho_moi",
    district_code: 259,
  },
  {
    name: "Xã Việt Thống",
    code: 9250,
    division_type: "xã",
    codename: "xa_viet_thong",
    district_code: 259,
  },
  {
    name: "Xã Đại Xuân",
    code: 9253,
    division_type: "xã",
    codename: "xa_dai_xuan",
    district_code: 259,
  },
  {
    name: "Xã Nhân Hòa",
    code: 9259,
    division_type: "xã",
    codename: "xa_nhan_hoa",
    district_code: 259,
  },
  {
    name: "Xã Bằng An",
    code: 9262,
    division_type: "xã",
    codename: "xa_bang_an",
    district_code: 259,
  },
  {
    name: "Xã Phương Liễu",
    code: 9265,
    division_type: "xã",
    codename: "xa_phuong_lieu",
    district_code: 259,
  },
  {
    name: "Xã Quế Tân",
    code: 9268,
    division_type: "xã",
    codename: "xa_que_tan",
    district_code: 259,
  },
  {
    name: "Xã Phù Lương",
    code: 9274,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 259,
  },
  {
    name: "Xã Phù Lãng",
    code: 9277,
    division_type: "xã",
    codename: "xa_phu_lang",
    district_code: 259,
  },
  {
    name: "Xã Phượng Mao",
    code: 9280,
    division_type: "xã",
    codename: "xa_phuong_mao",
    district_code: 259,
  },
  {
    name: "Xã Việt Hùng",
    code: 9283,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 259,
  },
  {
    name: "Xã Ngọc Xá",
    code: 9289,
    division_type: "xã",
    codename: "xa_ngoc_xa",
    district_code: 259,
  },
  {
    name: "Xã Châu Phong",
    code: 9292,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 259,
  },
  {
    name: "Xã Bồng Lai",
    code: 9295,
    division_type: "xã",
    codename: "xa_bong_lai",
    district_code: 259,
  },
  {
    name: "Xã Cách Bi",
    code: 9298,
    division_type: "xã",
    codename: "xa_cach_bi",
    district_code: 259,
  },
  {
    name: "Xã Đào Viên",
    code: 9301,
    division_type: "xã",
    codename: "xa_dao_vien",
    district_code: 259,
  },
  {
    name: "Xã Yên Giả",
    code: 9304,
    division_type: "xã",
    codename: "xa_yen_gia",
    district_code: 259,
  },
  {
    name: "Xã Mộ Đạo",
    code: 9307,
    division_type: "xã",
    codename: "xa_mo_dao",
    district_code: 259,
  },
  {
    name: "Xã Đức Long",
    code: 9310,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 259,
  },
  {
    name: "Xã Chi Lăng",
    code: 9313,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 259,
  },
  {
    name: "Xã Hán Quảng",
    code: 9316,
    division_type: "xã",
    codename: "xa_han_quang",
    district_code: 259,
  },
  {
    name: "Thị trấn Lim",
    code: 9319,
    division_type: "thị trấn",
    codename: "thi_tran_lim",
    district_code: 260,
  },
  {
    name: "Xã Phú Lâm",
    code: 9322,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 260,
  },
  {
    name: "Xã Nội Duệ",
    code: 9328,
    division_type: "xã",
    codename: "xa_noi_due",
    district_code: 260,
  },
  {
    name: "Xã Liên Bão",
    code: 9334,
    division_type: "xã",
    codename: "xa_lien_bao",
    district_code: 260,
  },
  {
    name: "Xã Hiên Vân",
    code: 9337,
    division_type: "xã",
    codename: "xa_hien_van",
    district_code: 260,
  },
  {
    name: "Xã Hoàn Sơn",
    code: 9340,
    division_type: "xã",
    codename: "xa_hoan_son",
    district_code: 260,
  },
  {
    name: "Xã Lạc Vệ",
    code: 9343,
    division_type: "xã",
    codename: "xa_lac_ve",
    district_code: 260,
  },
  {
    name: "Xã Việt Đoàn",
    code: 9346,
    division_type: "xã",
    codename: "xa_viet_doan",
    district_code: 260,
  },
  {
    name: "Xã Phật Tích",
    code: 9349,
    division_type: "xã",
    codename: "xa_phat_tich",
    district_code: 260,
  },
  {
    name: "Xã Tân Chi",
    code: 9352,
    division_type: "xã",
    codename: "xa_tan_chi",
    district_code: 260,
  },
  {
    name: "Xã Đại Đồng",
    code: 9355,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 260,
  },
  {
    name: "Xã Tri Phương",
    code: 9358,
    division_type: "xã",
    codename: "xa_tri_phuong",
    district_code: 260,
  },
  {
    name: "Xã Minh Đạo",
    code: 9361,
    division_type: "xã",
    codename: "xa_minh_dao",
    district_code: 260,
  },
  {
    name: "Xã Cảnh Hưng",
    code: 9364,
    division_type: "xã",
    codename: "xa_canh_hung",
    district_code: 260,
  },
  {
    name: "Phường Đông Ngàn",
    code: 9367,
    division_type: "phường",
    codename: "phuong_dong_ngan",
    district_code: 261,
  },
  {
    name: "Phường Tam Sơn",
    code: 9370,
    division_type: "phường",
    codename: "phuong_tam_son",
    district_code: 261,
  },
  {
    name: "Phường Hương Mạc",
    code: 9373,
    division_type: "phường",
    codename: "phuong_huong_mac",
    district_code: 261,
  },
  {
    name: "Phường Tương Giang",
    code: 9376,
    division_type: "phường",
    codename: "phuong_tuong_giang",
    district_code: 261,
  },
  {
    name: "Phường Phù Khê",
    code: 9379,
    division_type: "phường",
    codename: "phuong_phu_khe",
    district_code: 261,
  },
  {
    name: "Phường Đồng Kỵ",
    code: 9382,
    division_type: "phường",
    codename: "phuong_dong_ky",
    district_code: 261,
  },
  {
    name: "Phường Trang Hạ",
    code: 9383,
    division_type: "phường",
    codename: "phuong_trang_ha",
    district_code: 261,
  },
  {
    name: "Phường Đồng Nguyên",
    code: 9385,
    division_type: "phường",
    codename: "phuong_dong_nguyen",
    district_code: 261,
  },
  {
    name: "Phường Châu Khê",
    code: 9388,
    division_type: "phường",
    codename: "phuong_chau_khe",
    district_code: 261,
  },
  {
    name: "Phường Tân Hồng",
    code: 9391,
    division_type: "phường",
    codename: "phuong_tan_hong",
    district_code: 261,
  },
  {
    name: "Phường Đình Bảng",
    code: 9394,
    division_type: "phường",
    codename: "phuong_dinh_bang",
    district_code: 261,
  },
  {
    name: "Phường Phù Chẩn",
    code: 9397,
    division_type: "phường",
    codename: "phuong_phu_chan",
    district_code: 261,
  },
  {
    name: "Thị trấn Hồ",
    code: 9400,
    division_type: "thị trấn",
    codename: "thi_tran_ho",
    district_code: 262,
  },
  {
    name: "Xã Hoài Thượng",
    code: 9403,
    division_type: "xã",
    codename: "xa_hoai_thuong",
    district_code: 262,
  },
  {
    name: "Xã Đại Đồng Thành",
    code: 9406,
    division_type: "xã",
    codename: "xa_dai_dong_thanh",
    district_code: 262,
  },
  {
    name: "Xã Mão Điền",
    code: 9409,
    division_type: "xã",
    codename: "xa_mao_dien",
    district_code: 262,
  },
  {
    name: "Xã Song Hồ",
    code: 9412,
    division_type: "xã",
    codename: "xa_song_ho",
    district_code: 262,
  },
  {
    name: "Xã Đình Tổ",
    code: 9415,
    division_type: "xã",
    codename: "xa_dinh_to",
    district_code: 262,
  },
  {
    name: "Xã An Bình",
    code: 9418,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 262,
  },
  {
    name: "Xã Trí Quả",
    code: 9421,
    division_type: "xã",
    codename: "xa_tri_qua",
    district_code: 262,
  },
  {
    name: "Xã Gia Đông",
    code: 9424,
    division_type: "xã",
    codename: "xa_gia_dong",
    district_code: 262,
  },
  {
    name: "Xã Thanh Khương",
    code: 9427,
    division_type: "xã",
    codename: "xa_thanh_khuong",
    district_code: 262,
  },
  {
    name: "Xã Trạm Lộ",
    code: 9430,
    division_type: "xã",
    codename: "xa_tram_lo",
    district_code: 262,
  },
  {
    name: "Xã Xuân Lâm",
    code: 9433,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 262,
  },
  {
    name: "Xã Hà Mãn",
    code: 9436,
    division_type: "xã",
    codename: "xa_ha_man",
    district_code: 262,
  },
  {
    name: "Xã Ngũ Thái",
    code: 9439,
    division_type: "xã",
    codename: "xa_ngu_thai",
    district_code: 262,
  },
  {
    name: "Xã Nguyệt Đức",
    code: 9442,
    division_type: "xã",
    codename: "xa_nguyet_duc",
    district_code: 262,
  },
  {
    name: "Xã Ninh Xá",
    code: 9445,
    division_type: "xã",
    codename: "xa_ninh_xa",
    district_code: 262,
  },
  {
    name: "Xã Nghĩa Đạo",
    code: 9448,
    division_type: "xã",
    codename: "xa_nghia_dao",
    district_code: 262,
  },
  {
    name: "Xã Song Liễu",
    code: 9451,
    division_type: "xã",
    codename: "xa_song_lieu",
    district_code: 262,
  },
  {
    name: "Thị trấn Gia Bình",
    code: 9454,
    division_type: "thị trấn",
    codename: "thi_tran_gia_binh",
    district_code: 263,
  },
  {
    name: "Xã Vạn Ninh",
    code: 9457,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 263,
  },
  {
    name: "Xã Thái Bảo",
    code: 9460,
    division_type: "xã",
    codename: "xa_thai_bao",
    district_code: 263,
  },
  {
    name: "Xã Giang Sơn",
    code: 9463,
    division_type: "xã",
    codename: "xa_giang_son",
    district_code: 263,
  },
  {
    name: "Xã Cao Đức",
    code: 9466,
    division_type: "xã",
    codename: "xa_cao_duc",
    district_code: 263,
  },
  {
    name: "Xã Đại Lai",
    code: 9469,
    division_type: "xã",
    codename: "xa_dai_lai",
    district_code: 263,
  },
  {
    name: "Xã Song Giang",
    code: 9472,
    division_type: "xã",
    codename: "xa_song_giang",
    district_code: 263,
  },
  {
    name: "Xã Bình Dương",
    code: 9475,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 263,
  },
  {
    name: "Xã Lãng Ngâm",
    code: 9478,
    division_type: "xã",
    codename: "xa_lang_ngam",
    district_code: 263,
  },
  {
    name: "Xã Nhân Thắng",
    code: 9481,
    division_type: "xã",
    codename: "xa_nhan_thang",
    district_code: 263,
  },
  {
    name: "Xã Xuân Lai",
    code: 9484,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 263,
  },
  {
    name: "Xã Đông Cứu",
    code: 9487,
    division_type: "xã",
    codename: "xa_dong_cuu",
    district_code: 263,
  },
  {
    name: "Xã Đại Bái",
    code: 9490,
    division_type: "xã",
    codename: "xa_dai_bai",
    district_code: 263,
  },
  {
    name: "Xã Quỳnh Phú",
    code: 9493,
    division_type: "xã",
    codename: "xa_quynh_phu",
    district_code: 263,
  },
  {
    name: "Thị trấn Thứa",
    code: 9496,
    division_type: "thị trấn",
    codename: "thi_tran_thua",
    district_code: 264,
  },
  {
    name: "Xã An Thịnh",
    code: 9499,
    division_type: "xã",
    codename: "xa_an_thinh",
    district_code: 264,
  },
  {
    name: "Xã Trung Kênh",
    code: 9502,
    division_type: "xã",
    codename: "xa_trung_kenh",
    district_code: 264,
  },
  {
    name: "Xã Phú Hòa",
    code: 9505,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 264,
  },
  {
    name: "Xã Mỹ Hương",
    code: 9508,
    division_type: "xã",
    codename: "xa_my_huong",
    district_code: 264,
  },
  {
    name: "Xã Tân Lãng",
    code: 9511,
    division_type: "xã",
    codename: "xa_tan_lang",
    district_code: 264,
  },
  {
    name: "Xã Quảng Phú",
    code: 9514,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 264,
  },
  {
    name: "Xã Trừng Xá",
    code: 9517,
    division_type: "xã",
    codename: "xa_trung_xa",
    district_code: 264,
  },
  {
    name: "Xã Lai Hạ",
    code: 9520,
    division_type: "xã",
    codename: "xa_lai_ha",
    district_code: 264,
  },
  {
    name: "Xã Trung Chính",
    code: 9523,
    division_type: "xã",
    codename: "xa_trung_chinh",
    district_code: 264,
  },
  {
    name: "Xã Minh Tân",
    code: 9526,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 264,
  },
  {
    name: "Xã Bình Định",
    code: 9529,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 264,
  },
  {
    name: "Xã Phú Lương",
    code: 9532,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 264,
  },
  {
    name: "Xã Lâm Thao",
    code: 9535,
    division_type: "xã",
    codename: "xa_lam_thao",
    district_code: 264,
  },
  {
    name: "Phường Cẩm Thượng",
    code: 10507,
    division_type: "phường",
    codename: "phuong_cam_thuong",
    district_code: 288,
  },
  {
    name: "Phường Bình Hàn",
    code: 10510,
    division_type: "phường",
    codename: "phuong_binh_han",
    district_code: 288,
  },
  {
    name: "Phường Ngọc Châu",
    code: 10513,
    division_type: "phường",
    codename: "phuong_ngoc_chau",
    district_code: 288,
  },
  {
    name: "Phường Nhị Châu",
    code: 10514,
    division_type: "phường",
    codename: "phuong_nhi_chau",
    district_code: 288,
  },
  {
    name: "Phường Quang Trung",
    code: 10516,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 288,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 10519,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 288,
  },
  {
    name: "Phường Phạm Ngũ Lão",
    code: 10522,
    division_type: "phường",
    codename: "phuong_pham_ngu_lao",
    district_code: 288,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 10525,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 288,
  },
  {
    name: "Phường Trần Phú",
    code: 10528,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 288,
  },
  {
    name: "Phường Thanh Bình",
    code: 10531,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 288,
  },
  {
    name: "Phường Tân Bình",
    code: 10532,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 288,
  },
  {
    name: "Phường Lê Thanh Nghị",
    code: 10534,
    division_type: "phường",
    codename: "phuong_le_thanh_nghi",
    district_code: 288,
  },
  {
    name: "Phường Hải Tân",
    code: 10537,
    division_type: "phường",
    codename: "phuong_hai_tan",
    district_code: 288,
  },
  {
    name: "Phường Tứ Minh",
    code: 10540,
    division_type: "phường",
    codename: "phuong_tu_minh",
    district_code: 288,
  },
  {
    name: "Phường Việt Hoà",
    code: 10543,
    division_type: "phường",
    codename: "phuong_viet_hoa",
    district_code: 288,
  },
  {
    name: "Phường Ái Quốc",
    code: 10660,
    division_type: "phường",
    codename: "phuong_ai_quoc",
    district_code: 288,
  },
  {
    name: "Xã An Thượng",
    code: 10663,
    division_type: "xã",
    codename: "xa_an_thuong",
    district_code: 288,
  },
  {
    name: "Phường Nam Đồng",
    code: 10672,
    division_type: "phường",
    codename: "phuong_nam_dong",
    district_code: 288,
  },
  {
    name: "Xã Quyết Thắng",
    code: 10822,
    division_type: "xã",
    codename: "xa_quyet_thang",
    district_code: 288,
  },
  {
    name: "Xã Tiền Tiến",
    code: 10837,
    division_type: "xã",
    codename: "xa_tien_tien",
    district_code: 288,
  },
  {
    name: "Phường Thạch Khôi",
    code: 11002,
    division_type: "phường",
    codename: "phuong_thach_khoi",
    district_code: 288,
  },
  {
    name: "Xã Liên Hồng",
    code: 11005,
    division_type: "xã",
    codename: "xa_lien_hong",
    district_code: 288,
  },
  {
    name: "Phường Tân Hưng",
    code: 11011,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 288,
  },
  {
    name: "Xã Gia Xuyên",
    code: 11017,
    division_type: "xã",
    codename: "xa_gia_xuyen",
    district_code: 288,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 11077,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 288,
  },
  {
    name: "Phường Phả Lại",
    code: 10546,
    division_type: "phường",
    codename: "phuong_pha_lai",
    district_code: 290,
  },
  {
    name: "Phường Sao Đỏ",
    code: 10549,
    division_type: "phường",
    codename: "phuong_sao_do",
    district_code: 290,
  },
  {
    name: "Phường Bến Tắm",
    code: 10552,
    division_type: "phường",
    codename: "phuong_ben_tam",
    district_code: 290,
  },
  {
    name: "Xã Hoàng Hoa Thám",
    code: 10555,
    division_type: "xã",
    codename: "xa_hoang_hoa_tham",
    district_code: 290,
  },
  {
    name: "Xã Bắc An",
    code: 10558,
    division_type: "xã",
    codename: "xa_bac_an",
    district_code: 290,
  },
  {
    name: "Xã Hưng Đạo",
    code: 10561,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 290,
  },
  {
    name: "Xã Lê Lợi",
    code: 10564,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 290,
  },
  {
    name: "Phường Hoàng Tiến",
    code: 10567,
    division_type: "phường",
    codename: "phuong_hoang_tien",
    district_code: 290,
  },
  {
    name: "Phường Cộng Hoà",
    code: 10570,
    division_type: "phường",
    codename: "phuong_cong_hoa",
    district_code: 290,
  },
  {
    name: "Phường Hoàng Tân",
    code: 10573,
    division_type: "phường",
    codename: "phuong_hoang_tan",
    district_code: 290,
  },
  {
    name: "Phường Cổ Thành",
    code: 10576,
    division_type: "phường",
    codename: "phuong_co_thanh",
    district_code: 290,
  },
  {
    name: "Phường Văn An",
    code: 10579,
    division_type: "phường",
    codename: "phuong_van_an",
    district_code: 290,
  },
  {
    name: "Phường Chí Minh",
    code: 10582,
    division_type: "phường",
    codename: "phuong_chi_minh",
    district_code: 290,
  },
  {
    name: "Phường Văn Đức",
    code: 10585,
    division_type: "phường",
    codename: "phuong_van_duc",
    district_code: 290,
  },
  {
    name: "Phường Thái Học",
    code: 10588,
    division_type: "phường",
    codename: "phuong_thai_hoc",
    district_code: 290,
  },
  {
    name: "Xã Nhân Huệ",
    code: 10591,
    division_type: "xã",
    codename: "xa_nhan_hue",
    district_code: 290,
  },
  {
    name: "Phường An Lạc",
    code: 10594,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 290,
  },
  {
    name: "Phường Đồng Lạc",
    code: 10600,
    division_type: "phường",
    codename: "phuong_dong_lac",
    district_code: 290,
  },
  {
    name: "Phường Tân Dân",
    code: 10603,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 290,
  },
  {
    name: "Thị trấn Nam Sách",
    code: 10606,
    division_type: "thị trấn",
    codename: "thi_tran_nam_sach",
    district_code: 291,
  },
  {
    name: "Xã Nam Hưng",
    code: 10609,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 291,
  },
  {
    name: "Xã Nam Tân",
    code: 10612,
    division_type: "xã",
    codename: "xa_nam_tan",
    district_code: 291,
  },
  {
    name: "Xã Hợp Tiến",
    code: 10615,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 291,
  },
  {
    name: "Xã Hiệp Cát",
    code: 10618,
    division_type: "xã",
    codename: "xa_hiep_cat",
    district_code: 291,
  },
  {
    name: "Xã Thanh Quang",
    code: 10621,
    division_type: "xã",
    codename: "xa_thanh_quang",
    district_code: 291,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 10624,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 291,
  },
  {
    name: "Xã Nam Chính",
    code: 10627,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 291,
  },
  {
    name: "Xã An Bình",
    code: 10630,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 291,
  },
  {
    name: "Xã Nam Trung",
    code: 10633,
    division_type: "xã",
    codename: "xa_nam_trung",
    district_code: 291,
  },
  {
    name: "Xã An Sơn",
    code: 10636,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 291,
  },
  {
    name: "Xã Cộng Hòa",
    code: 10639,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 291,
  },
  {
    name: "Xã Thái Tân",
    code: 10642,
    division_type: "xã",
    codename: "xa_thai_tan",
    district_code: 291,
  },
  {
    name: "Xã An Lâm",
    code: 10645,
    division_type: "xã",
    codename: "xa_an_lam",
    district_code: 291,
  },
  {
    name: "Xã Phú Điền",
    code: 10648,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 291,
  },
  {
    name: "Xã Nam Hồng",
    code: 10651,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 291,
  },
  {
    name: "Xã Hồng Phong",
    code: 10654,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 291,
  },
  {
    name: "Xã Đồng Lạc",
    code: 10657,
    division_type: "xã",
    codename: "xa_dong_lac",
    district_code: 291,
  },
  {
    name: "Xã Minh Tân",
    code: 10666,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 291,
  },
  {
    name: "Phường An Lưu",
    code: 10675,
    division_type: "phường",
    codename: "phuong_an_luu",
    district_code: 292,
  },
  {
    name: "Xã Bạch Đằng",
    code: 10678,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 292,
  },
  {
    name: "Phường Thất Hùng",
    code: 10681,
    division_type: "phường",
    codename: "phuong_that_hung",
    district_code: 292,
  },
  {
    name: "Xã Lê Ninh",
    code: 10684,
    division_type: "xã",
    codename: "xa_le_ninh",
    district_code: 292,
  },
  {
    name: "Xã Hoành Sơn",
    code: 10687,
    division_type: "xã",
    codename: "xa_hoanh_son",
    district_code: 292,
  },
  {
    name: "Phường Phạm Thái",
    code: 10693,
    division_type: "phường",
    codename: "phuong_pham_thai",
    district_code: 292,
  },
  {
    name: "Phường Duy Tân",
    code: 10696,
    division_type: "phường",
    codename: "phuong_duy_tan",
    district_code: 292,
  },
  {
    name: "Phường Tân Dân",
    code: 10699,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 292,
  },
  {
    name: "Phường Minh Tân",
    code: 10702,
    division_type: "phường",
    codename: "phuong_minh_tan",
    district_code: 292,
  },
  {
    name: "Xã Quang Thành",
    code: 10705,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 292,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 10708,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 292,
  },
  {
    name: "Phường Phú Thứ",
    code: 10714,
    division_type: "phường",
    codename: "phuong_phu_thu",
    district_code: 292,
  },
  {
    name: "Xã Thăng Long",
    code: 10717,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 292,
  },
  {
    name: "Xã Lạc Long",
    code: 10720,
    division_type: "xã",
    codename: "xa_lac_long",
    district_code: 292,
  },
  {
    name: "Phường An Sinh",
    code: 10723,
    division_type: "phường",
    codename: "phuong_an_sinh",
    district_code: 292,
  },
  {
    name: "Phường Hiệp Sơn",
    code: 10726,
    division_type: "phường",
    codename: "phuong_hiep_son",
    district_code: 292,
  },
  {
    name: "Xã Thượng Quận",
    code: 10729,
    division_type: "xã",
    codename: "xa_thuong_quan",
    district_code: 292,
  },
  {
    name: "Phường An Phụ",
    code: 10732,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 292,
  },
  {
    name: "Phường Hiệp An",
    code: 10735,
    division_type: "phường",
    codename: "phuong_hiep_an",
    district_code: 292,
  },
  {
    name: "Phường Long Xuyên",
    code: 10738,
    division_type: "phường",
    codename: "phuong_long_xuyen",
    district_code: 292,
  },
  {
    name: "Phường Thái Thịnh",
    code: 10741,
    division_type: "phường",
    codename: "phuong_thai_thinh",
    district_code: 292,
  },
  {
    name: "Phường Hiến Thành",
    code: 10744,
    division_type: "phường",
    codename: "phuong_hien_thanh",
    district_code: 292,
  },
  {
    name: "Xã Minh Hòa",
    code: 10747,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 292,
  },
  {
    name: "Thị trấn Phú Thái",
    code: 10750,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thai",
    district_code: 293,
  },
  {
    name: "Xã Lai Vu",
    code: 10753,
    division_type: "xã",
    codename: "xa_lai_vu",
    district_code: 293,
  },
  {
    name: "Xã Cộng Hòa",
    code: 10756,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 293,
  },
  {
    name: "Xã Thượng Vũ",
    code: 10759,
    division_type: "xã",
    codename: "xa_thuong_vu",
    district_code: 293,
  },
  {
    name: "Xã Cổ Dũng",
    code: 10762,
    division_type: "xã",
    codename: "xa_co_dung",
    district_code: 293,
  },
  {
    name: "Xã Tuấn Việt",
    code: 10768,
    division_type: "xã",
    codename: "xa_tuan_viet",
    district_code: 293,
  },
  {
    name: "Xã Kim Xuyên",
    code: 10771,
    division_type: "xã",
    codename: "xa_kim_xuyen",
    district_code: 293,
  },
  {
    name: "Xã Phúc Thành A",
    code: 10774,
    division_type: "xã",
    codename: "xa_phuc_thanh_a",
    district_code: 293,
  },
  {
    name: "Xã Ngũ Phúc",
    code: 10777,
    division_type: "xã",
    codename: "xa_ngu_phuc",
    district_code: 293,
  },
  {
    name: "Xã Kim Anh",
    code: 10780,
    division_type: "xã",
    codename: "xa_kim_anh",
    district_code: 293,
  },
  {
    name: "Xã Kim Liên",
    code: 10783,
    division_type: "xã",
    codename: "xa_kim_lien",
    district_code: 293,
  },
  {
    name: "Xã Kim Tân",
    code: 10786,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 293,
  },
  {
    name: "Xã Kim Đính",
    code: 10792,
    division_type: "xã",
    codename: "xa_kim_dinh",
    district_code: 293,
  },
  {
    name: "Xã Bình Dân",
    code: 10798,
    division_type: "xã",
    codename: "xa_binh_dan",
    district_code: 293,
  },
  {
    name: "Xã Tam Kỳ",
    code: 10801,
    division_type: "xã",
    codename: "xa_tam_ky",
    district_code: 293,
  },
  {
    name: "Xã Đồng Cẩm",
    code: 10804,
    division_type: "xã",
    codename: "xa_dong_cam",
    district_code: 293,
  },
  {
    name: "Xã Liên Hòa",
    code: 10807,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 293,
  },
  {
    name: "Xã Đại Đức",
    code: 10810,
    division_type: "xã",
    codename: "xa_dai_duc",
    district_code: 293,
  },
  {
    name: "Thị trấn Thanh Hà",
    code: 10813,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_ha",
    district_code: 294,
  },
  {
    name: "Xã Hồng Lạc",
    code: 10816,
    division_type: "xã",
    codename: "xa_hong_lac",
    district_code: 294,
  },
  {
    name: "Xã Việt Hồng",
    code: 10819,
    division_type: "xã",
    codename: "xa_viet_hong",
    district_code: 294,
  },
  {
    name: "Xã Tân Việt",
    code: 10825,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 294,
  },
  {
    name: "Xã Cẩm Chế",
    code: 10828,
    division_type: "xã",
    codename: "xa_cam_che",
    district_code: 294,
  },
  {
    name: "Xã Thanh An",
    code: 10831,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 294,
  },
  {
    name: "Xã Thanh Lang",
    code: 10834,
    division_type: "xã",
    codename: "xa_thanh_lang",
    district_code: 294,
  },
  {
    name: "Xã Tân An",
    code: 10840,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 294,
  },
  {
    name: "Xã Liên Mạc",
    code: 10843,
    division_type: "xã",
    codename: "xa_lien_mac",
    district_code: 294,
  },
  {
    name: "Xã Thanh Hải",
    code: 10846,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 294,
  },
  {
    name: "Xã Thanh Khê",
    code: 10849,
    division_type: "xã",
    codename: "xa_thanh_khe",
    district_code: 294,
  },
  {
    name: "Xã Thanh Xá",
    code: 10852,
    division_type: "xã",
    codename: "xa_thanh_xa",
    district_code: 294,
  },
  {
    name: "Xã Thanh Xuân",
    code: 10855,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 294,
  },
  {
    name: "Xã Thanh Thủy",
    code: 10861,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 294,
  },
  {
    name: "Xã An Phượng",
    code: 10864,
    division_type: "xã",
    codename: "xa_an_phuong",
    district_code: 294,
  },
  {
    name: "Xã Thanh Sơn",
    code: 10867,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 294,
  },
  {
    name: "Xã Thanh Quang",
    code: 10876,
    division_type: "xã",
    codename: "xa_thanh_quang",
    district_code: 294,
  },
  {
    name: "Xã Thanh Hồng",
    code: 10879,
    division_type: "xã",
    codename: "xa_thanh_hong",
    district_code: 294,
  },
  {
    name: "Xã Thanh Cường",
    code: 10882,
    division_type: "xã",
    codename: "xa_thanh_cuong",
    district_code: 294,
  },
  {
    name: "Xã Vĩnh Lập",
    code: 10885,
    division_type: "xã",
    codename: "xa_vinh_lap",
    district_code: 294,
  },
  {
    name: "Thị trấn Cẩm Giang",
    code: 10888,
    division_type: "thị trấn",
    codename: "thi_tran_cam_giang",
    district_code: 295,
  },
  {
    name: "Thị trấn Lai Cách",
    code: 10891,
    division_type: "thị trấn",
    codename: "thi_tran_lai_cach",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Hưng",
    code: 10894,
    division_type: "xã",
    codename: "xa_cam_hung",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Hoàng",
    code: 10897,
    division_type: "xã",
    codename: "xa_cam_hoang",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Văn",
    code: 10900,
    division_type: "xã",
    codename: "xa_cam_van",
    district_code: 295,
  },
  {
    name: "Xã Ngọc Liên",
    code: 10903,
    division_type: "xã",
    codename: "xa_ngoc_lien",
    district_code: 295,
  },
  {
    name: "Xã Thạch Lỗi",
    code: 10906,
    division_type: "xã",
    codename: "xa_thach_loi",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Vũ",
    code: 10909,
    division_type: "xã",
    codename: "xa_cam_vu",
    district_code: 295,
  },
  {
    name: "Xã Đức Chính",
    code: 10912,
    division_type: "xã",
    codename: "xa_duc_chinh",
    district_code: 295,
  },
  {
    name: "Xã Định Sơn",
    code: 10918,
    division_type: "xã",
    codename: "xa_dinh_son",
    district_code: 295,
  },
  {
    name: "Xã Lương Điền",
    code: 10924,
    division_type: "xã",
    codename: "xa_luong_dien",
    district_code: 295,
  },
  {
    name: "Xã Cao An",
    code: 10927,
    division_type: "xã",
    codename: "xa_cao_an",
    district_code: 295,
  },
  {
    name: "Xã Tân Trường",
    code: 10930,
    division_type: "xã",
    codename: "xa_tan_truong",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Phúc",
    code: 10933,
    division_type: "xã",
    codename: "xa_cam_phuc",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Điền",
    code: 10936,
    division_type: "xã",
    codename: "xa_cam_dien",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Đông",
    code: 10939,
    division_type: "xã",
    codename: "xa_cam_dong",
    district_code: 295,
  },
  {
    name: "Xã Cẩm Đoài",
    code: 10942,
    division_type: "xã",
    codename: "xa_cam_doai",
    district_code: 295,
  },
  {
    name: "Thị trấn Kẻ Sặt",
    code: 10945,
    division_type: "thị trấn",
    codename: "thi_tran_ke_sat",
    district_code: 296,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 10951,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 296,
  },
  {
    name: "Xã Hùng Thắng",
    code: 10954,
    division_type: "xã",
    codename: "xa_hung_thang",
    district_code: 296,
  },
  {
    name: "Xã Vĩnh Hồng",
    code: 10960,
    division_type: "xã",
    codename: "xa_vinh_hong",
    district_code: 296,
  },
  {
    name: "Xã Long Xuyên",
    code: 10963,
    division_type: "xã",
    codename: "xa_long_xuyen",
    district_code: 296,
  },
  {
    name: "Xã Tân Việt",
    code: 10966,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 296,
  },
  {
    name: "Xã Thúc Kháng",
    code: 10969,
    division_type: "xã",
    codename: "xa_thuc_khang",
    district_code: 296,
  },
  {
    name: "Xã Tân Hồng",
    code: 10972,
    division_type: "xã",
    codename: "xa_tan_hong",
    district_code: 296,
  },
  {
    name: "Xã Bình Minh",
    code: 10975,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 296,
  },
  {
    name: "Xã Hồng Khê",
    code: 10978,
    division_type: "xã",
    codename: "xa_hong_khe",
    district_code: 296,
  },
  {
    name: "Xã Thái Học",
    code: 10981,
    division_type: "xã",
    codename: "xa_thai_hoc",
    district_code: 296,
  },
  {
    name: "Xã Cổ Bì",
    code: 10984,
    division_type: "xã",
    codename: "xa_co_bi",
    district_code: 296,
  },
  {
    name: "Xã Nhân Quyền",
    code: 10987,
    division_type: "xã",
    codename: "xa_nhan_quyen",
    district_code: 296,
  },
  {
    name: "Xã Thái Dương",
    code: 10990,
    division_type: "xã",
    codename: "xa_thai_duong",
    district_code: 296,
  },
  {
    name: "Xã Thái Hòa",
    code: 10993,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 296,
  },
  {
    name: "Xã Bình Xuyên",
    code: 10996,
    division_type: "xã",
    codename: "xa_binh_xuyen",
    district_code: 296,
  },
  {
    name: "Thị trấn Gia Lộc",
    code: 10999,
    division_type: "thị trấn",
    codename: "thi_tran_gia_loc",
    district_code: 297,
  },
  {
    name: "Xã Thống Nhất",
    code: 11008,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 297,
  },
  {
    name: "Xã Yết Kiêu",
    code: 11020,
    division_type: "xã",
    codename: "xa_yet_kieu",
    district_code: 297,
  },
  {
    name: "Xã Gia Tân",
    code: 11029,
    division_type: "xã",
    codename: "xa_gia_tan",
    district_code: 297,
  },
  {
    name: "Xã Tân Tiến",
    code: 11032,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 297,
  },
  {
    name: "Xã Gia Khánh",
    code: 11035,
    division_type: "xã",
    codename: "xa_gia_khanh",
    district_code: 297,
  },
  {
    name: "Xã Gia Lương",
    code: 11038,
    division_type: "xã",
    codename: "xa_gia_luong",
    district_code: 297,
  },
  {
    name: "Xã Lê Lợi",
    code: 11041,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 297,
  },
  {
    name: "Xã Toàn Thắng",
    code: 11044,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 297,
  },
  {
    name: "Xã Hoàng Diệu",
    code: 11047,
    division_type: "xã",
    codename: "xa_hoang_dieu",
    district_code: 297,
  },
  {
    name: "Xã Hồng Hưng",
    code: 11050,
    division_type: "xã",
    codename: "xa_hong_hung",
    district_code: 297,
  },
  {
    name: "Xã Phạm Trấn",
    code: 11053,
    division_type: "xã",
    codename: "xa_pham_tran",
    district_code: 297,
  },
  {
    name: "Xã Đoàn Thượng",
    code: 11056,
    division_type: "xã",
    codename: "xa_doan_thuong",
    district_code: 297,
  },
  {
    name: "Xã Thống Kênh",
    code: 11059,
    division_type: "xã",
    codename: "xa_thong_kenh",
    district_code: 297,
  },
  {
    name: "Xã Quang Minh",
    code: 11062,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 297,
  },
  {
    name: "Xã Đồng Quang",
    code: 11065,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 297,
  },
  {
    name: "Xã Nhật Tân",
    code: 11068,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 297,
  },
  {
    name: "Xã Đức Xương",
    code: 11071,
    division_type: "xã",
    codename: "xa_duc_xuong",
    district_code: 297,
  },
  {
    name: "Thị trấn Tứ Kỳ",
    code: 11074,
    division_type: "thị trấn",
    codename: "thi_tran_tu_ky",
    district_code: 298,
  },
  {
    name: "Xã Đại Sơn",
    code: 11083,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 298,
  },
  {
    name: "Xã Hưng Đạo",
    code: 11086,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 298,
  },
  {
    name: "Xã Ngọc Kỳ",
    code: 11089,
    division_type: "xã",
    codename: "xa_ngoc_ky",
    district_code: 298,
  },
  {
    name: "Xã Bình Lăng",
    code: 11092,
    division_type: "xã",
    codename: "xa_binh_lang",
    district_code: 298,
  },
  {
    name: "Xã Chí Minh",
    code: 11095,
    division_type: "xã",
    codename: "xa_chi_minh",
    district_code: 298,
  },
  {
    name: "Xã Tái Sơn",
    code: 11098,
    division_type: "xã",
    codename: "xa_tai_son",
    district_code: 298,
  },
  {
    name: "Xã Quang Phục",
    code: 11101,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 298,
  },
  {
    name: "Xã Dân Chủ",
    code: 11110,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 298,
  },
  {
    name: "Xã Tân Kỳ",
    code: 11113,
    division_type: "xã",
    codename: "xa_tan_ky",
    district_code: 298,
  },
  {
    name: "Xã Quang Khải",
    code: 11116,
    division_type: "xã",
    codename: "xa_quang_khai",
    district_code: 298,
  },
  {
    name: "Xã Đại Hợp",
    code: 11119,
    division_type: "xã",
    codename: "xa_dai_hop",
    district_code: 298,
  },
  {
    name: "Xã Quảng Nghiệp",
    code: 11122,
    division_type: "xã",
    codename: "xa_quang_nghiep",
    district_code: 298,
  },
  {
    name: "Xã An Thanh",
    code: 11125,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 298,
  },
  {
    name: "Xã Minh Đức",
    code: 11128,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 298,
  },
  {
    name: "Xã Văn Tố",
    code: 11131,
    division_type: "xã",
    codename: "xa_van_to",
    district_code: 298,
  },
  {
    name: "Xã Quang Trung",
    code: 11134,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 298,
  },
  {
    name: "Xã Phượng Kỳ",
    code: 11137,
    division_type: "xã",
    codename: "xa_phuong_ky",
    district_code: 298,
  },
  {
    name: "Xã Cộng Lạc",
    code: 11140,
    division_type: "xã",
    codename: "xa_cong_lac",
    district_code: 298,
  },
  {
    name: "Xã Tiên Động",
    code: 11143,
    division_type: "xã",
    codename: "xa_tien_dong",
    district_code: 298,
  },
  {
    name: "Xã Nguyên Giáp",
    code: 11146,
    division_type: "xã",
    codename: "xa_nguyen_giap",
    district_code: 298,
  },
  {
    name: "Xã Hà Kỳ",
    code: 11149,
    division_type: "xã",
    codename: "xa_ha_ky",
    district_code: 298,
  },
  {
    name: "Xã Hà Thanh",
    code: 11152,
    division_type: "xã",
    codename: "xa_ha_thanh",
    district_code: 298,
  },
  {
    name: "Thị trấn Ninh Giang",
    code: 11155,
    division_type: "thị trấn",
    codename: "thi_tran_ninh_giang",
    district_code: 299,
  },
  {
    name: "Xã Ứng Hoè",
    code: 11161,
    division_type: "xã",
    codename: "xa_ung_hoe",
    district_code: 299,
  },
  {
    name: "Xã Nghĩa An",
    code: 11164,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 299,
  },
  {
    name: "Xã Hồng Đức",
    code: 11167,
    division_type: "xã",
    codename: "xa_hong_duc",
    district_code: 299,
  },
  {
    name: "Xã An Đức",
    code: 11173,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 299,
  },
  {
    name: "Xã Vạn Phúc",
    code: 11176,
    division_type: "xã",
    codename: "xa_van_phuc",
    district_code: 299,
  },
  {
    name: "Xã Tân Hương",
    code: 11179,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 299,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 11185,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 299,
  },
  {
    name: "Xã Đông Xuyên",
    code: 11188,
    division_type: "xã",
    codename: "xa_dong_xuyen",
    district_code: 299,
  },
  {
    name: "Xã Tân Phong",
    code: 11197,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 299,
  },
  {
    name: "Xã Ninh Hải",
    code: 11200,
    division_type: "xã",
    codename: "xa_ninh_hai",
    district_code: 299,
  },
  {
    name: "Xã Đồng Tâm",
    code: 11203,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 299,
  },
  {
    name: "Xã Tân Quang",
    code: 11206,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 299,
  },
  {
    name: "Xã Kiến Quốc",
    code: 11209,
    division_type: "xã",
    codename: "xa_kien_quoc",
    district_code: 299,
  },
  {
    name: "Xã Hồng Dụ",
    code: 11215,
    division_type: "xã",
    codename: "xa_hong_du",
    district_code: 299,
  },
  {
    name: "Xã Văn Hội",
    code: 11218,
    division_type: "xã",
    codename: "xa_van_hoi",
    district_code: 299,
  },
  {
    name: "Xã Hồng Phong",
    code: 11224,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 299,
  },
  {
    name: "Xã Hiệp Lực",
    code: 11227,
    division_type: "xã",
    codename: "xa_hiep_luc",
    district_code: 299,
  },
  {
    name: "Xã Hồng Phúc",
    code: 11230,
    division_type: "xã",
    codename: "xa_hong_phuc",
    district_code: 299,
  },
  {
    name: "Xã Hưng Long",
    code: 11233,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 299,
  },
  {
    name: "Thị trấn Thanh Miện",
    code: 11239,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_mien",
    district_code: 300,
  },
  {
    name: "Xã Thanh Tùng",
    code: 11242,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 300,
  },
  {
    name: "Xã Phạm Kha",
    code: 11245,
    division_type: "xã",
    codename: "xa_pham_kha",
    district_code: 300,
  },
  {
    name: "Xã Ngô Quyền",
    code: 11248,
    division_type: "xã",
    codename: "xa_ngo_quyen",
    district_code: 300,
  },
  {
    name: "Xã Đoàn Tùng",
    code: 11251,
    division_type: "xã",
    codename: "xa_doan_tung",
    district_code: 300,
  },
  {
    name: "Xã Hồng Quang",
    code: 11254,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 300,
  },
  {
    name: "Xã Tân Trào",
    code: 11257,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 300,
  },
  {
    name: "Xã Lam Sơn",
    code: 11260,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 300,
  },
  {
    name: "Xã Đoàn Kết",
    code: 11263,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 300,
  },
  {
    name: "Xã Lê Hồng",
    code: 11266,
    division_type: "xã",
    codename: "xa_le_hong",
    district_code: 300,
  },
  {
    name: "Xã Tứ Cường",
    code: 11269,
    division_type: "xã",
    codename: "xa_tu_cuong",
    district_code: 300,
  },
  {
    name: "Xã Ngũ Hùng",
    code: 11275,
    division_type: "xã",
    codename: "xa_ngu_hung",
    district_code: 300,
  },
  {
    name: "Xã Cao Thắng",
    code: 11278,
    division_type: "xã",
    codename: "xa_cao_thang",
    district_code: 300,
  },
  {
    name: "Xã Chi Lăng Bắc",
    code: 11281,
    division_type: "xã",
    codename: "xa_chi_lang_bac",
    district_code: 300,
  },
  {
    name: "Xã Chi Lăng Nam",
    code: 11284,
    division_type: "xã",
    codename: "xa_chi_lang_nam",
    district_code: 300,
  },
  {
    name: "Xã Thanh Giang",
    code: 11287,
    division_type: "xã",
    codename: "xa_thanh_giang",
    district_code: 300,
  },
  {
    name: "Xã Hồng Phong",
    code: 11293,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 300,
  },
  {
    name: "Phường Quán Toan",
    code: 11296,
    division_type: "phường",
    codename: "phuong_quan_toan",
    district_code: 303,
  },
  {
    name: "Phường Hùng Vương",
    code: 11299,
    division_type: "phường",
    codename: "phuong_hung_vuong",
    district_code: 303,
  },
  {
    name: "Phường Sở Dầu",
    code: 11302,
    division_type: "phường",
    codename: "phuong_so_dau",
    district_code: 303,
  },
  {
    name: "Phường Thượng Lý",
    code: 11305,
    division_type: "phường",
    codename: "phuong_thuong_ly",
    district_code: 303,
  },
  {
    name: "Phường Hạ Lý",
    code: 11308,
    division_type: "phường",
    codename: "phuong_ha_ly",
    district_code: 303,
  },
  {
    name: "Phường Minh Khai",
    code: 11311,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 303,
  },
  {
    name: "Phường Trại Chuối",
    code: 11314,
    division_type: "phường",
    codename: "phuong_trai_chuoi",
    district_code: 303,
  },
  {
    name: "Phường Hoàng Văn Thụ",
    code: 11320,
    division_type: "phường",
    codename: "phuong_hoang_van_thu",
    district_code: 303,
  },
  {
    name: "Phường Phan Bội Châu",
    code: 11323,
    division_type: "phường",
    codename: "phuong_phan_boi_chau",
    district_code: 303,
  },
  {
    name: "Phường Máy Chai",
    code: 11329,
    division_type: "phường",
    codename: "phuong_may_chai",
    district_code: 304,
  },
  {
    name: "Phường Máy Tơ",
    code: 11332,
    division_type: "phường",
    codename: "phuong_may_to",
    district_code: 304,
  },
  {
    name: "Phường Vạn Mỹ",
    code: 11335,
    division_type: "phường",
    codename: "phuong_van_my",
    district_code: 304,
  },
  {
    name: "Phường Cầu Tre",
    code: 11338,
    division_type: "phường",
    codename: "phuong_cau_tre",
    district_code: 304,
  },
  {
    name: "Phường Lạc Viên",
    code: 11341,
    division_type: "phường",
    codename: "phuong_lac_vien",
    district_code: 304,
  },
  {
    name: "Phường Gia Viên",
    code: 11347,
    division_type: "phường",
    codename: "phuong_gia_vien",
    district_code: 304,
  },
  {
    name: "Phường Đông Khê",
    code: 11350,
    division_type: "phường",
    codename: "phuong_dong_khe",
    district_code: 304,
  },
  {
    name: "Phường Cầu Đất",
    code: 11353,
    division_type: "phường",
    codename: "phuong_cau_dat",
    district_code: 304,
  },
  {
    name: "Phường Lê Lợi",
    code: 11356,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 304,
  },
  {
    name: "Phường Đằng Giang",
    code: 11359,
    division_type: "phường",
    codename: "phuong_dang_giang",
    district_code: 304,
  },
  {
    name: "Phường Lạch Tray",
    code: 11362,
    division_type: "phường",
    codename: "phuong_lach_tray",
    district_code: 304,
  },
  {
    name: "Phường Đổng Quốc Bình",
    code: 11365,
    division_type: "phường",
    codename: "phuong_dong_quoc_binh",
    district_code: 304,
  },
  {
    name: "Phường Cát Dài",
    code: 11368,
    division_type: "phường",
    codename: "phuong_cat_dai",
    district_code: 305,
  },
  {
    name: "Phường An Biên",
    code: 11371,
    division_type: "phường",
    codename: "phuong_an_bien",
    district_code: 305,
  },
  {
    name: "Phường Lam Sơn",
    code: 11374,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 305,
  },
  {
    name: "Phường An Dương",
    code: 11377,
    division_type: "phường",
    codename: "phuong_an_duong",
    district_code: 305,
  },
  {
    name: "Phường Trần Nguyên Hãn",
    code: 11380,
    division_type: "phường",
    codename: "phuong_tran_nguyen_han",
    district_code: 305,
  },
  {
    name: "Phường Hồ Nam",
    code: 11383,
    division_type: "phường",
    codename: "phuong_ho_nam",
    district_code: 305,
  },
  {
    name: "Phường Trại Cau",
    code: 11386,
    division_type: "phường",
    codename: "phuong_trai_cau",
    district_code: 305,
  },
  {
    name: "Phường Dư Hàng",
    code: 11389,
    division_type: "phường",
    codename: "phuong_du_hang",
    district_code: 305,
  },
  {
    name: "Phường Hàng Kênh",
    code: 11392,
    division_type: "phường",
    codename: "phuong_hang_kenh",
    district_code: 305,
  },
  {
    name: "Phường Đông Hải",
    code: 11395,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 305,
  },
  {
    name: "Phường Niệm Nghĩa",
    code: 11398,
    division_type: "phường",
    codename: "phuong_niem_nghia",
    district_code: 305,
  },
  {
    name: "Phường Nghĩa Xá",
    code: 11401,
    division_type: "phường",
    codename: "phuong_nghia_xa",
    district_code: 305,
  },
  {
    name: "Phường Dư Hàng Kênh",
    code: 11404,
    division_type: "phường",
    codename: "phuong_du_hang_kenh",
    district_code: 305,
  },
  {
    name: "Phường Kênh Dương",
    code: 11405,
    division_type: "phường",
    codename: "phuong_kenh_duong",
    district_code: 305,
  },
  {
    name: "Phường Vĩnh Niệm",
    code: 11407,
    division_type: "phường",
    codename: "phuong_vinh_niem",
    district_code: 305,
  },
  {
    name: "Phường Đông Hải 1",
    code: 11410,
    division_type: "phường",
    codename: "phuong_dong_hai_1",
    district_code: 306,
  },
  {
    name: "Phường Đông Hải 2",
    code: 11411,
    division_type: "phường",
    codename: "phuong_dong_hai_2",
    district_code: 306,
  },
  {
    name: "Phường Đằng Lâm",
    code: 11413,
    division_type: "phường",
    codename: "phuong_dang_lam",
    district_code: 306,
  },
  {
    name: "Phường Thành Tô",
    code: 11414,
    division_type: "phường",
    codename: "phuong_thanh_to",
    district_code: 306,
  },
  {
    name: "Phường Đằng Hải",
    code: 11416,
    division_type: "phường",
    codename: "phuong_dang_hai",
    district_code: 306,
  },
  {
    name: "Phường Nam Hải",
    code: 11419,
    division_type: "phường",
    codename: "phuong_nam_hai",
    district_code: 306,
  },
  {
    name: "Phường Cát Bi",
    code: 11422,
    division_type: "phường",
    codename: "phuong_cat_bi",
    district_code: 306,
  },
  {
    name: "Phường Tràng Cát",
    code: 11425,
    division_type: "phường",
    codename: "phuong_trang_cat",
    district_code: 306,
  },
  {
    name: "Phường Quán Trữ",
    code: 11428,
    division_type: "phường",
    codename: "phuong_quan_tru",
    district_code: 307,
  },
  {
    name: "Phường Lãm Hà",
    code: 11429,
    division_type: "phường",
    codename: "phuong_lam_ha",
    district_code: 307,
  },
  {
    name: "Phường Đồng Hoà",
    code: 11431,
    division_type: "phường",
    codename: "phuong_dong_hoa",
    district_code: 307,
  },
  {
    name: "Phường Bắc Sơn",
    code: 11434,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 307,
  },
  {
    name: "Phường Nam Sơn",
    code: 11437,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 307,
  },
  {
    name: "Phường Ngọc Sơn",
    code: 11440,
    division_type: "phường",
    codename: "phuong_ngoc_son",
    district_code: 307,
  },
  {
    name: "Phường Trần Thành Ngọ",
    code: 11443,
    division_type: "phường",
    codename: "phuong_tran_thanh_ngo",
    district_code: 307,
  },
  {
    name: "Phường Văn Đẩu",
    code: 11446,
    division_type: "phường",
    codename: "phuong_van_dau",
    district_code: 307,
  },
  {
    name: "Phường Phù Liễn",
    code: 11449,
    division_type: "phường",
    codename: "phuong_phu_lien",
    district_code: 307,
  },
  {
    name: "Phường Tràng Minh",
    code: 11452,
    division_type: "phường",
    codename: "phuong_trang_minh",
    district_code: 307,
  },
  {
    name: "Phường Ngọc Xuyên",
    code: 11455,
    division_type: "phường",
    codename: "phuong_ngoc_xuyen",
    district_code: 308,
  },
  {
    name: "Phường Hải Sơn",
    code: 11458,
    division_type: "phường",
    codename: "phuong_hai_son",
    district_code: 308,
  },
  {
    name: "Phường Vạn Hương",
    code: 11461,
    division_type: "phường",
    codename: "phuong_van_huong",
    district_code: 308,
  },
  {
    name: "Phường Minh Đức",
    code: 11465,
    division_type: "phường",
    codename: "phuong_minh_duc",
    district_code: 308,
  },
  {
    name: "Phường Bàng La",
    code: 11467,
    division_type: "phường",
    codename: "phuong_bang_la",
    district_code: 308,
  },
  {
    name: "Phường Hợp Đức",
    code: 11737,
    division_type: "phường",
    codename: "phuong_hop_duc",
    district_code: 308,
  },
  {
    name: "Phường Đa Phúc",
    code: 11683,
    division_type: "phường",
    codename: "phuong_da_phuc",
    district_code: 309,
  },
  {
    name: "Phường Hưng Đạo",
    code: 11686,
    division_type: "phường",
    codename: "phuong_hung_dao",
    district_code: 309,
  },
  {
    name: "Phường Anh Dũng",
    code: 11689,
    division_type: "phường",
    codename: "phuong_anh_dung",
    district_code: 309,
  },
  {
    name: "Phường Hải Thành",
    code: 11692,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 309,
  },
  {
    name: "Phường Hoà Nghĩa",
    code: 11707,
    division_type: "phường",
    codename: "phuong_hoa_nghia",
    district_code: 309,
  },
  {
    name: "Phường Tân Thành",
    code: 11740,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 309,
  },
  {
    name: "Thị trấn Núi Đèo",
    code: 11470,
    division_type: "thị trấn",
    codename: "thi_tran_nui_deo",
    district_code: 311,
  },
  {
    name: "Thị trấn Minh Đức",
    code: 11473,
    division_type: "thị trấn",
    codename: "thi_tran_minh_duc",
    district_code: 311,
  },
  {
    name: "Xã Lại Xuân",
    code: 11476,
    division_type: "xã",
    codename: "xa_lai_xuan",
    district_code: 311,
  },
  {
    name: "Xã An Sơn",
    code: 11479,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 311,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 11482,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 311,
  },
  {
    name: "Xã Liên Khê",
    code: 11485,
    division_type: "xã",
    codename: "xa_lien_khe",
    district_code: 311,
  },
  {
    name: "Xã Lưu Kiếm",
    code: 11488,
    division_type: "xã",
    codename: "xa_luu_kiem",
    district_code: 311,
  },
  {
    name: "Xã Lưu Kỳ",
    code: 11491,
    division_type: "xã",
    codename: "xa_luu_ky",
    district_code: 311,
  },
  {
    name: "Xã Gia Minh",
    code: 11494,
    division_type: "xã",
    codename: "xa_gia_minh",
    district_code: 311,
  },
  {
    name: "Xã Gia Đức",
    code: 11497,
    division_type: "xã",
    codename: "xa_gia_duc",
    district_code: 311,
  },
  {
    name: "Xã Minh Tân",
    code: 11500,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 311,
  },
  {
    name: "Xã Phù Ninh",
    code: 11503,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 311,
  },
  {
    name: "Xã Quảng Thanh",
    code: 11506,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 311,
  },
  {
    name: "Xã Chính Mỹ",
    code: 11509,
    division_type: "xã",
    codename: "xa_chinh_my",
    district_code: 311,
  },
  {
    name: "Xã Kênh Giang",
    code: 11512,
    division_type: "xã",
    codename: "xa_kenh_giang",
    district_code: 311,
  },
  {
    name: "Xã Hợp Thành",
    code: 11515,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 311,
  },
  {
    name: "Xã Cao Nhân",
    code: 11518,
    division_type: "xã",
    codename: "xa_cao_nhan",
    district_code: 311,
  },
  {
    name: "Xã Mỹ Đồng",
    code: 11521,
    division_type: "xã",
    codename: "xa_my_dong",
    district_code: 311,
  },
  {
    name: "Xã Đông Sơn",
    code: 11524,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 311,
  },
  {
    name: "Xã Hoà Bình",
    code: 11527,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 311,
  },
  {
    name: "Xã Trung Hà",
    code: 11530,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 311,
  },
  {
    name: "Xã An Lư",
    code: 11533,
    division_type: "xã",
    codename: "xa_an_lu",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Triều",
    code: 11536,
    division_type: "xã",
    codename: "xa_thuy_trieu",
    district_code: 311,
  },
  {
    name: "Xã Ngũ Lão",
    code: 11539,
    division_type: "xã",
    codename: "xa_ngu_lao",
    district_code: 311,
  },
  {
    name: "Xã Phục Lễ",
    code: 11542,
    division_type: "xã",
    codename: "xa_phuc_le",
    district_code: 311,
  },
  {
    name: "Xã Tam Hưng",
    code: 11545,
    division_type: "xã",
    codename: "xa_tam_hung",
    district_code: 311,
  },
  {
    name: "Xã Phả Lễ",
    code: 11548,
    division_type: "xã",
    codename: "xa_pha_le",
    district_code: 311,
  },
  {
    name: "Xã Lập Lễ",
    code: 11551,
    division_type: "xã",
    codename: "xa_lap_le",
    district_code: 311,
  },
  {
    name: "Xã Kiền Bái",
    code: 11554,
    division_type: "xã",
    codename: "xa_kien_bai",
    district_code: 311,
  },
  {
    name: "Xã Thiên Hương",
    code: 11557,
    division_type: "xã",
    codename: "xa_thien_huong",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Sơn",
    code: 11560,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 311,
  },
  {
    name: "Xã Thuỷ Đường",
    code: 11563,
    division_type: "xã",
    codename: "xa_thuy_duong",
    district_code: 311,
  },
  {
    name: "Xã Hoàng Động",
    code: 11566,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 311,
  },
  {
    name: "Xã Lâm Động",
    code: 11569,
    division_type: "xã",
    codename: "xa_lam_dong",
    district_code: 311,
  },
  {
    name: "Xã Hoa Động",
    code: 11572,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 311,
  },
  {
    name: "Xã Tân Dương",
    code: 11575,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 311,
  },
  {
    name: "Xã Dương Quan",
    code: 11578,
    division_type: "xã",
    codename: "xa_duong_quan",
    district_code: 311,
  },
  {
    name: "Thị trấn An Dương",
    code: 11581,
    division_type: "thị trấn",
    codename: "thi_tran_an_duong",
    district_code: 312,
  },
  {
    name: "Xã Lê Thiện",
    code: 11584,
    division_type: "xã",
    codename: "xa_le_thien",
    district_code: 312,
  },
  {
    name: "Xã Đại Bản",
    code: 11587,
    division_type: "xã",
    codename: "xa_dai_ban",
    district_code: 312,
  },
  {
    name: "Xã An Hoà",
    code: 11590,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 312,
  },
  {
    name: "Xã Hồng Phong",
    code: 11593,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 312,
  },
  {
    name: "Xã Tân Tiến",
    code: 11596,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 312,
  },
  {
    name: "Xã An Hưng",
    code: 11599,
    division_type: "xã",
    codename: "xa_an_hung",
    district_code: 312,
  },
  {
    name: "Xã An Hồng",
    code: 11602,
    division_type: "xã",
    codename: "xa_an_hong",
    district_code: 312,
  },
  {
    name: "Xã Bắc Sơn",
    code: 11605,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 312,
  },
  {
    name: "Xã Nam Sơn",
    code: 11608,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 312,
  },
  {
    name: "Xã Lê Lợi",
    code: 11611,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 312,
  },
  {
    name: "Xã Đặng Cương",
    code: 11614,
    division_type: "xã",
    codename: "xa_dang_cuong",
    district_code: 312,
  },
  {
    name: "Xã Đồng Thái",
    code: 11617,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 312,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 11620,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 312,
  },
  {
    name: "Xã An Đồng",
    code: 11623,
    division_type: "xã",
    codename: "xa_an_dong",
    district_code: 312,
  },
  {
    name: "Xã Hồng Thái",
    code: 11626,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 312,
  },
  {
    name: "Thị trấn An Lão",
    code: 11629,
    division_type: "thị trấn",
    codename: "thi_tran_an_lao",
    district_code: 313,
  },
  {
    name: "Xã Bát Trang",
    code: 11632,
    division_type: "xã",
    codename: "xa_bat_trang",
    district_code: 313,
  },
  {
    name: "Xã Trường Thọ",
    code: 11635,
    division_type: "xã",
    codename: "xa_truong_tho",
    district_code: 313,
  },
  {
    name: "Xã Trường Thành",
    code: 11638,
    division_type: "xã",
    codename: "xa_truong_thanh",
    district_code: 313,
  },
  {
    name: "Xã An Tiến",
    code: 11641,
    division_type: "xã",
    codename: "xa_an_tien",
    district_code: 313,
  },
  {
    name: "Xã Quang Hưng",
    code: 11644,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 313,
  },
  {
    name: "Xã Quang Trung",
    code: 11647,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 313,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 11650,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 313,
  },
  {
    name: "Xã An Thắng",
    code: 11653,
    division_type: "xã",
    codename: "xa_an_thang",
    district_code: 313,
  },
  {
    name: "Thị trấn Trường Sơn",
    code: 11656,
    division_type: "thị trấn",
    codename: "thi_tran_truong_son",
    district_code: 313,
  },
  {
    name: "Xã Tân Dân",
    code: 11659,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 313,
  },
  {
    name: "Xã Thái Sơn",
    code: 11662,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 313,
  },
  {
    name: "Xã Tân Viên",
    code: 11665,
    division_type: "xã",
    codename: "xa_tan_vien",
    district_code: 313,
  },
  {
    name: "Xã Mỹ Đức",
    code: 11668,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 313,
  },
  {
    name: "Xã Chiến Thắng",
    code: 11671,
    division_type: "xã",
    codename: "xa_chien_thang",
    district_code: 313,
  },
  {
    name: "Xã An Thọ",
    code: 11674,
    division_type: "xã",
    codename: "xa_an_tho",
    district_code: 313,
  },
  {
    name: "Xã An Thái",
    code: 11677,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 313,
  },
  {
    name: "Thị trấn Núi Đối",
    code: 11680,
    division_type: "thị trấn",
    codename: "thi_tran_nui_doi",
    district_code: 314,
  },
  {
    name: "Xã Đông Phương",
    code: 11695,
    division_type: "xã",
    codename: "xa_dong_phuong",
    district_code: 314,
  },
  {
    name: "Xã Thuận Thiên",
    code: 11698,
    division_type: "xã",
    codename: "xa_thuan_thien",
    district_code: 314,
  },
  {
    name: "Xã Hữu Bằng",
    code: 11701,
    division_type: "xã",
    codename: "xa_huu_bang",
    district_code: 314,
  },
  {
    name: "Xã Đại Đồng",
    code: 11704,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 314,
  },
  {
    name: "Xã Ngũ Phúc",
    code: 11710,
    division_type: "xã",
    codename: "xa_ngu_phuc",
    district_code: 314,
  },
  {
    name: "Xã Kiến Quốc",
    code: 11713,
    division_type: "xã",
    codename: "xa_kien_quoc",
    district_code: 314,
  },
  {
    name: "Xã Du Lễ",
    code: 11716,
    division_type: "xã",
    codename: "xa_du_le",
    district_code: 314,
  },
  {
    name: "Xã Thuỵ Hương",
    code: 11719,
    division_type: "xã",
    codename: "xa_thuy_huong",
    district_code: 314,
  },
  {
    name: "Xã Thanh Sơn",
    code: 11722,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 314,
  },
  {
    name: "Xã Minh Tân",
    code: 11725,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 314,
  },
  {
    name: "Xã Đại Hà",
    code: 11728,
    division_type: "xã",
    codename: "xa_dai_ha",
    district_code: 314,
  },
  {
    name: "Xã Ngũ Đoan",
    code: 11731,
    division_type: "xã",
    codename: "xa_ngu_doan",
    district_code: 314,
  },
  {
    name: "Xã Tân Phong",
    code: 11734,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 314,
  },
  {
    name: "Xã Tân Trào",
    code: 11743,
    division_type: "xã",
    codename: "xa_tan_trao",
    district_code: 314,
  },
  {
    name: "Xã Đoàn Xá",
    code: 11746,
    division_type: "xã",
    codename: "xa_doan_xa",
    district_code: 314,
  },
  {
    name: "Xã Tú Sơn",
    code: 11749,
    division_type: "xã",
    codename: "xa_tu_son",
    district_code: 314,
  },
  {
    name: "Xã Đại Hợp",
    code: 11752,
    division_type: "xã",
    codename: "xa_dai_hop",
    district_code: 314,
  },
  {
    name: "Thị trấn Tiên Lãng",
    code: 11755,
    division_type: "thị trấn",
    codename: "thi_tran_tien_lang",
    district_code: 315,
  },
  {
    name: "Xã Đại Thắng",
    code: 11758,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Cường",
    code: 11761,
    division_type: "xã",
    codename: "xa_tien_cuong",
    district_code: 315,
  },
  {
    name: "Xã Tự Cường",
    code: 11764,
    division_type: "xã",
    codename: "xa_tu_cuong",
    district_code: 315,
  },
  {
    name: "Xã Quyết Tiến",
    code: 11770,
    division_type: "xã",
    codename: "xa_quyet_tien",
    district_code: 315,
  },
  {
    name: "Xã Khởi Nghĩa",
    code: 11773,
    division_type: "xã",
    codename: "xa_khoi_nghia",
    district_code: 315,
  },
  {
    name: "Xã Tiên Thanh",
    code: 11776,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 315,
  },
  {
    name: "Xã Cấp Tiến",
    code: 11779,
    division_type: "xã",
    codename: "xa_cap_tien",
    district_code: 315,
  },
  {
    name: "Xã Kiến Thiết",
    code: 11782,
    division_type: "xã",
    codename: "xa_kien_thiet",
    district_code: 315,
  },
  {
    name: "Xã Đoàn Lập",
    code: 11785,
    division_type: "xã",
    codename: "xa_doan_lap",
    district_code: 315,
  },
  {
    name: "Xã Bạch Đằng",
    code: 11788,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 315,
  },
  {
    name: "Xã Quang Phục",
    code: 11791,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 315,
  },
  {
    name: "Xã Toàn Thắng",
    code: 11794,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Thắng",
    code: 11797,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 315,
  },
  {
    name: "Xã Tiên Minh",
    code: 11800,
    division_type: "xã",
    codename: "xa_tien_minh",
    district_code: 315,
  },
  {
    name: "Xã Bắc Hưng",
    code: 11803,
    division_type: "xã",
    codename: "xa_bac_hung",
    district_code: 315,
  },
  {
    name: "Xã Nam Hưng",
    code: 11806,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 315,
  },
  {
    name: "Xã Hùng Thắng",
    code: 11809,
    division_type: "xã",
    codename: "xa_hung_thang",
    district_code: 315,
  },
  {
    name: "Xã Tây Hưng",
    code: 11812,
    division_type: "xã",
    codename: "xa_tay_hung",
    district_code: 315,
  },
  {
    name: "Xã Đông Hưng",
    code: 11815,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 315,
  },
  {
    name: "Xã Vinh Quang",
    code: 11821,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 315,
  },
  {
    name: "Thị trấn Vĩnh Bảo",
    code: 11824,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_bao",
    district_code: 316,
  },
  {
    name: "Xã Dũng Tiến",
    code: 11827,
    division_type: "xã",
    codename: "xa_dung_tien",
    district_code: 316,
  },
  {
    name: "Xã Giang Biên",
    code: 11830,
    division_type: "xã",
    codename: "xa_giang_bien",
    district_code: 316,
  },
  {
    name: "Xã Thắng Thuỷ",
    code: 11833,
    division_type: "xã",
    codename: "xa_thang_thuy",
    district_code: 316,
  },
  {
    name: "Xã Trung Lập",
    code: 11836,
    division_type: "xã",
    codename: "xa_trung_lap",
    district_code: 316,
  },
  {
    name: "Xã Việt Tiến",
    code: 11839,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh An",
    code: 11842,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Long",
    code: 11845,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 316,
  },
  {
    name: "Xã Hiệp Hoà",
    code: 11848,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 316,
  },
  {
    name: "Xã Hùng Tiến",
    code: 11851,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 316,
  },
  {
    name: "Xã An Hoà",
    code: 11854,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 316,
  },
  {
    name: "Xã Tân Hưng",
    code: 11857,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 316,
  },
  {
    name: "Xã Tân Liên",
    code: 11860,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 316,
  },
  {
    name: "Xã Nhân Hoà",
    code: 11863,
    division_type: "xã",
    codename: "xa_nhan_hoa",
    district_code: 316,
  },
  {
    name: "Xã Tam Đa",
    code: 11866,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 316,
  },
  {
    name: "Xã Hưng Nhân",
    code: 11869,
    division_type: "xã",
    codename: "xa_hung_nhan",
    district_code: 316,
  },
  {
    name: "Xã Vinh Quang",
    code: 11872,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 316,
  },
  {
    name: "Xã Đồng Minh",
    code: 11875,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 316,
  },
  {
    name: "Xã Thanh Lương",
    code: 11878,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 316,
  },
  {
    name: "Xã Liên Am",
    code: 11881,
    division_type: "xã",
    codename: "xa_lien_am",
    district_code: 316,
  },
  {
    name: "Xã Lý Học",
    code: 11884,
    division_type: "xã",
    codename: "xa_ly_hoc",
    district_code: 316,
  },
  {
    name: "Xã Tam Cường",
    code: 11887,
    division_type: "xã",
    codename: "xa_tam_cuong",
    district_code: 316,
  },
  {
    name: "Xã Hoà Bình",
    code: 11890,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 316,
  },
  {
    name: "Xã Tiền Phong",
    code: 11893,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 11896,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 316,
  },
  {
    name: "Xã Cộng Hiền",
    code: 11899,
    division_type: "xã",
    codename: "xa_cong_hien",
    district_code: 316,
  },
  {
    name: "Xã Cao Minh",
    code: 11902,
    division_type: "xã",
    codename: "xa_cao_minh",
    district_code: 316,
  },
  {
    name: "Xã Cổ Am",
    code: 11905,
    division_type: "xã",
    codename: "xa_co_am",
    district_code: 316,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 11908,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 316,
  },
  {
    name: "Xã Trấn Dương",
    code: 11911,
    division_type: "xã",
    codename: "xa_tran_duong",
    district_code: 316,
  },
  {
    name: "Thị trấn Cát Bà",
    code: 11914,
    division_type: "thị trấn",
    codename: "thi_tran_cat_ba",
    district_code: 317,
  },
  {
    name: "Thị trấn Cát Hải",
    code: 11917,
    division_type: "thị trấn",
    codename: "thi_tran_cat_hai",
    district_code: 317,
  },
  {
    name: "Xã Nghĩa Lộ",
    code: 11920,
    division_type: "xã",
    codename: "xa_nghia_lo",
    district_code: 317,
  },
  {
    name: "Xã Đồng Bài",
    code: 11923,
    division_type: "xã",
    codename: "xa_dong_bai",
    district_code: 317,
  },
  {
    name: "Xã Hoàng Châu",
    code: 11926,
    division_type: "xã",
    codename: "xa_hoang_chau",
    district_code: 317,
  },
  {
    name: "Xã Văn Phong",
    code: 11929,
    division_type: "xã",
    codename: "xa_van_phong",
    district_code: 317,
  },
  {
    name: "Xã Phù Long",
    code: 11932,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 317,
  },
  {
    name: "Xã Gia Luận",
    code: 11935,
    division_type: "xã",
    codename: "xa_gia_luan",
    district_code: 317,
  },
  {
    name: "Xã Hiền Hào",
    code: 11938,
    division_type: "xã",
    codename: "xa_hien_hao",
    district_code: 317,
  },
  {
    name: "Xã Trân Châu",
    code: 11941,
    division_type: "xã",
    codename: "xa_tran_chau",
    district_code: 317,
  },
  {
    name: "Xã Việt Hải",
    code: 11944,
    division_type: "xã",
    codename: "xa_viet_hai",
    district_code: 317,
  },
  {
    name: "Xã Xuân Đám",
    code: 11947,
    division_type: "xã",
    codename: "xa_xuan_dam",
    district_code: 317,
  },
  {
    name: "Phường Lam Sơn",
    code: 11950,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 323,
  },
  {
    name: "Phường Hiến Nam",
    code: 11953,
    division_type: "phường",
    codename: "phuong_hien_nam",
    district_code: 323,
  },
  {
    name: "Phường An Tảo",
    code: 11956,
    division_type: "phường",
    codename: "phuong_an_tao",
    district_code: 323,
  },
  {
    name: "Phường Lê Lợi",
    code: 11959,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 323,
  },
  {
    name: "Phường Minh Khai",
    code: 11962,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 323,
  },
  {
    name: "Phường Quang Trung",
    code: 11965,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 323,
  },
  {
    name: "Phường Hồng Châu",
    code: 11968,
    division_type: "phường",
    codename: "phuong_hong_chau",
    district_code: 323,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 11971,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 323,
  },
  {
    name: "Xã Liên Phương",
    code: 11974,
    division_type: "xã",
    codename: "xa_lien_phuong",
    district_code: 323,
  },
  {
    name: "Xã Hồng Nam",
    code: 11977,
    division_type: "xã",
    codename: "xa_hong_nam",
    district_code: 323,
  },
  {
    name: "Xã Quảng Châu",
    code: 11980,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 323,
  },
  {
    name: "Xã Bảo Khê",
    code: 11983,
    division_type: "xã",
    codename: "xa_bao_khe",
    district_code: 323,
  },
  {
    name: "Xã Phú Cường",
    code: 12331,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 323,
  },
  {
    name: "Xã Hùng Cường",
    code: 12334,
    division_type: "xã",
    codename: "xa_hung_cuong",
    district_code: 323,
  },
  {
    name: "Xã Phương Chiểu",
    code: 12382,
    division_type: "xã",
    codename: "xa_phuong_chieu",
    district_code: 323,
  },
  {
    name: "Xã Tân Hưng",
    code: 12385,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 323,
  },
  {
    name: "Xã Hoàng Hanh",
    code: 12388,
    division_type: "xã",
    codename: "xa_hoang_hanh",
    district_code: 323,
  },
  {
    name: "Thị trấn Như Quỳnh",
    code: 11986,
    division_type: "thị trấn",
    codename: "thi_tran_nhu_quynh",
    district_code: 325,
  },
  {
    name: "Xã Lạc Đạo",
    code: 11989,
    division_type: "xã",
    codename: "xa_lac_dao",
    district_code: 325,
  },
  {
    name: "Xã Chỉ Đạo",
    code: 11992,
    division_type: "xã",
    codename: "xa_chi_dao",
    district_code: 325,
  },
  {
    name: "Xã Đại Đồng",
    code: 11995,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 325,
  },
  {
    name: "Xã Việt Hưng",
    code: 11998,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 325,
  },
  {
    name: "Xã Tân Quang",
    code: 12001,
    division_type: "xã",
    codename: "xa_tan_quang",
    district_code: 325,
  },
  {
    name: "Xã Đình Dù",
    code: 12004,
    division_type: "xã",
    codename: "xa_dinh_du",
    district_code: 325,
  },
  {
    name: "Xã Minh Hải",
    code: 12007,
    division_type: "xã",
    codename: "xa_minh_hai",
    district_code: 325,
  },
  {
    name: "Xã Lương Tài",
    code: 12010,
    division_type: "xã",
    codename: "xa_luong_tai",
    district_code: 325,
  },
  {
    name: "Xã Trưng Trắc",
    code: 12013,
    division_type: "xã",
    codename: "xa_trung_trac",
    district_code: 325,
  },
  {
    name: "Xã Lạc Hồng",
    code: 12016,
    division_type: "xã",
    codename: "xa_lac_hong",
    district_code: 325,
  },
  {
    name: "Thị trấn Văn Giang",
    code: 12019,
    division_type: "thị trấn",
    codename: "thi_tran_van_giang",
    district_code: 326,
  },
  {
    name: "Xã Xuân Quan",
    code: 12022,
    division_type: "xã",
    codename: "xa_xuan_quan",
    district_code: 326,
  },
  {
    name: "Xã Cửu Cao",
    code: 12025,
    division_type: "xã",
    codename: "xa_cuu_cao",
    district_code: 326,
  },
  {
    name: "Xã Phụng Công",
    code: 12028,
    division_type: "xã",
    codename: "xa_phung_cong",
    district_code: 326,
  },
  {
    name: "Xã Nghĩa Trụ",
    code: 12031,
    division_type: "xã",
    codename: "xa_nghia_tru",
    district_code: 326,
  },
  {
    name: "Xã Long Hưng",
    code: 12034,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 326,
  },
  {
    name: "Xã Vĩnh Khúc",
    code: 12037,
    division_type: "xã",
    codename: "xa_vinh_khuc",
    district_code: 326,
  },
  {
    name: "Xã Liên Nghĩa",
    code: 12040,
    division_type: "xã",
    codename: "xa_lien_nghia",
    district_code: 326,
  },
  {
    name: "Xã Tân Tiến",
    code: 12043,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 326,
  },
  {
    name: "Xã Thắng Lợi",
    code: 12046,
    division_type: "xã",
    codename: "xa_thang_loi",
    district_code: 326,
  },
  {
    name: "Xã Mễ Sở",
    code: 12049,
    division_type: "xã",
    codename: "xa_me_so",
    district_code: 326,
  },
  {
    name: "Thị trấn Yên Mỹ",
    code: 12052,
    division_type: "thị trấn",
    codename: "thi_tran_yen_my",
    district_code: 327,
  },
  {
    name: "Xã Giai Phạm",
    code: 12055,
    division_type: "xã",
    codename: "xa_giai_pham",
    district_code: 327,
  },
  {
    name: "Xã Nghĩa Hiệp",
    code: 12058,
    division_type: "xã",
    codename: "xa_nghia_hiep",
    district_code: 327,
  },
  {
    name: "Xã Đồng Than",
    code: 12061,
    division_type: "xã",
    codename: "xa_dong_than",
    district_code: 327,
  },
  {
    name: "Xã Ngọc Long",
    code: 12064,
    division_type: "xã",
    codename: "xa_ngoc_long",
    district_code: 327,
  },
  {
    name: "Xã Liêu Xá",
    code: 12067,
    division_type: "xã",
    codename: "xa_lieu_xa",
    district_code: 327,
  },
  {
    name: "Xã Hoàn Long",
    code: 12070,
    division_type: "xã",
    codename: "xa_hoan_long",
    district_code: 327,
  },
  {
    name: "Xã Tân Lập",
    code: 12073,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 327,
  },
  {
    name: "Xã Thanh Long",
    code: 12076,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 327,
  },
  {
    name: "Xã Yên Phú",
    code: 12079,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 327,
  },
  {
    name: "Xã Việt Cường",
    code: 12082,
    division_type: "xã",
    codename: "xa_viet_cuong",
    district_code: 327,
  },
  {
    name: "Xã Trung Hòa",
    code: 12085,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 327,
  },
  {
    name: "Xã Yên Hòa",
    code: 12088,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 327,
  },
  {
    name: "Xã Minh Châu",
    code: 12091,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 327,
  },
  {
    name: "Xã Trung Hưng",
    code: 12094,
    division_type: "xã",
    codename: "xa_trung_hung",
    district_code: 327,
  },
  {
    name: "Xã Lý Thường Kiệt",
    code: 12097,
    division_type: "xã",
    codename: "xa_ly_thuong_kiet",
    district_code: 327,
  },
  {
    name: "Xã Tân Việt",
    code: 12100,
    division_type: "xã",
    codename: "xa_tan_viet",
    district_code: 327,
  },
  {
    name: "Phường Bần Yên Nhân",
    code: 12103,
    division_type: "phường",
    codename: "phuong_ban_yen_nhan",
    district_code: 328,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 12106,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 328,
  },
  {
    name: "Xã Cẩm Xá",
    code: 12109,
    division_type: "xã",
    codename: "xa_cam_xa",
    district_code: 328,
  },
  {
    name: "Xã Dương Quang",
    code: 12112,
    division_type: "xã",
    codename: "xa_duong_quang",
    district_code: 328,
  },
  {
    name: "Xã Hòa Phong",
    code: 12115,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 328,
  },
  {
    name: "Phường Nhân Hòa",
    code: 12118,
    division_type: "phường",
    codename: "phuong_nhan_hoa",
    district_code: 328,
  },
  {
    name: "Phường Dị Sử",
    code: 12121,
    division_type: "phường",
    codename: "phuong_di_su",
    district_code: 328,
  },
  {
    name: "Phường Bạch Sam",
    code: 12124,
    division_type: "phường",
    codename: "phuong_bach_sam",
    district_code: 328,
  },
  {
    name: "Phường Minh Đức",
    code: 12127,
    division_type: "phường",
    codename: "phuong_minh_duc",
    district_code: 328,
  },
  {
    name: "Phường Phùng Chí Kiên",
    code: 12130,
    division_type: "phường",
    codename: "phuong_phung_chi_kien",
    district_code: 328,
  },
  {
    name: "Xã Xuân Dục",
    code: 12133,
    division_type: "xã",
    codename: "xa_xuan_duc",
    district_code: 328,
  },
  {
    name: "Xã Ngọc Lâm",
    code: 12136,
    division_type: "xã",
    codename: "xa_ngoc_lam",
    district_code: 328,
  },
  {
    name: "Xã Hưng Long",
    code: 12139,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 328,
  },
  {
    name: "Thị trấn Ân Thi",
    code: 12142,
    division_type: "thị trấn",
    codename: "thi_tran_an_thi",
    district_code: 329,
  },
  {
    name: "Xã Phù Ủng",
    code: 12145,
    division_type: "xã",
    codename: "xa_phu_ung",
    district_code: 329,
  },
  {
    name: "Xã Bắc Sơn",
    code: 12148,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 329,
  },
  {
    name: "Xã Bãi Sậy",
    code: 12151,
    division_type: "xã",
    codename: "xa_bai_say",
    district_code: 329,
  },
  {
    name: "Xã Đào Dương",
    code: 12154,
    division_type: "xã",
    codename: "xa_dao_duong",
    district_code: 329,
  },
  {
    name: "Xã Tân Phúc",
    code: 12157,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 329,
  },
  {
    name: "Xã Vân Du",
    code: 12160,
    division_type: "xã",
    codename: "xa_van_du",
    district_code: 329,
  },
  {
    name: "Xã Quang Vinh",
    code: 12163,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 329,
  },
  {
    name: "Xã Xuân Trúc",
    code: 12166,
    division_type: "xã",
    codename: "xa_xuan_truc",
    district_code: 329,
  },
  {
    name: "Xã Hoàng Hoa Thám",
    code: 12169,
    division_type: "xã",
    codename: "xa_hoang_hoa_tham",
    district_code: 329,
  },
  {
    name: "Xã Quảng Lãng",
    code: 12172,
    division_type: "xã",
    codename: "xa_quang_lang",
    district_code: 329,
  },
  {
    name: "Xã Văn Nhuệ",
    code: 12175,
    division_type: "xã",
    codename: "xa_van_nhue",
    district_code: 329,
  },
  {
    name: "Xã Đặng Lễ",
    code: 12178,
    division_type: "xã",
    codename: "xa_dang_le",
    district_code: 329,
  },
  {
    name: "Xã Cẩm Ninh",
    code: 12181,
    division_type: "xã",
    codename: "xa_cam_ninh",
    district_code: 329,
  },
  {
    name: "Xã Nguyễn Trãi",
    code: 12184,
    division_type: "xã",
    codename: "xa_nguyen_trai",
    district_code: 329,
  },
  {
    name: "Xã Đa Lộc",
    code: 12187,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 329,
  },
  {
    name: "Xã Hồ Tùng Mậu",
    code: 12190,
    division_type: "xã",
    codename: "xa_ho_tung_mau",
    district_code: 329,
  },
  {
    name: "Xã Tiền Phong",
    code: 12193,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 329,
  },
  {
    name: "Xã Hồng Vân",
    code: 12196,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 329,
  },
  {
    name: "Xã Hồng Quang",
    code: 12199,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 329,
  },
  {
    name: "Xã Hạ Lễ",
    code: 12202,
    division_type: "xã",
    codename: "xa_ha_le",
    district_code: 329,
  },
  {
    name: "Thị trấn Khoái Châu",
    code: 12205,
    division_type: "thị trấn",
    codename: "thi_tran_khoai_chau",
    district_code: 330,
  },
  {
    name: "Xã Đông Tảo",
    code: 12208,
    division_type: "xã",
    codename: "xa_dong_tao",
    district_code: 330,
  },
  {
    name: "Xã Bình Minh",
    code: 12211,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 330,
  },
  {
    name: "Xã Dạ Trạch",
    code: 12214,
    division_type: "xã",
    codename: "xa_da_trach",
    district_code: 330,
  },
  {
    name: "Xã Hàm Tử",
    code: 12217,
    division_type: "xã",
    codename: "xa_ham_tu",
    district_code: 330,
  },
  {
    name: "Xã Ông Đình",
    code: 12220,
    division_type: "xã",
    codename: "xa_ong_dinh",
    district_code: 330,
  },
  {
    name: "Xã Tân Dân",
    code: 12223,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 330,
  },
  {
    name: "Xã Tứ Dân",
    code: 12226,
    division_type: "xã",
    codename: "xa_tu_dan",
    district_code: 330,
  },
  {
    name: "Xã An Vĩ",
    code: 12229,
    division_type: "xã",
    codename: "xa_an_vi",
    district_code: 330,
  },
  {
    name: "Xã Đông Kết",
    code: 12232,
    division_type: "xã",
    codename: "xa_dong_ket",
    district_code: 330,
  },
  {
    name: "Xã Bình Kiều",
    code: 12235,
    division_type: "xã",
    codename: "xa_binh_kieu",
    district_code: 330,
  },
  {
    name: "Xã Dân Tiến",
    code: 12238,
    division_type: "xã",
    codename: "xa_dan_tien",
    district_code: 330,
  },
  {
    name: "Xã Đồng Tiến",
    code: 12241,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 330,
  },
  {
    name: "Xã Hồng Tiến",
    code: 12244,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 330,
  },
  {
    name: "Xã Tân Châu",
    code: 12247,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 330,
  },
  {
    name: "Xã Liên Khê",
    code: 12250,
    division_type: "xã",
    codename: "xa_lien_khe",
    district_code: 330,
  },
  {
    name: "Xã Phùng Hưng",
    code: 12253,
    division_type: "xã",
    codename: "xa_phung_hung",
    district_code: 330,
  },
  {
    name: "Xã Việt Hòa",
    code: 12256,
    division_type: "xã",
    codename: "xa_viet_hoa",
    district_code: 330,
  },
  {
    name: "Xã Đông Ninh",
    code: 12259,
    division_type: "xã",
    codename: "xa_dong_ninh",
    district_code: 330,
  },
  {
    name: "Xã Đại Tập",
    code: 12262,
    division_type: "xã",
    codename: "xa_dai_tap",
    district_code: 330,
  },
  {
    name: "Xã Chí Tân",
    code: 12265,
    division_type: "xã",
    codename: "xa_chi_tan",
    district_code: 330,
  },
  {
    name: "Xã Đại Hưng",
    code: 12268,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 330,
  },
  {
    name: "Xã Thuần Hưng",
    code: 12271,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 330,
  },
  {
    name: "Xã Thành Công",
    code: 12274,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 330,
  },
  {
    name: "Xã Nhuế Dương",
    code: 12277,
    division_type: "xã",
    codename: "xa_nhue_duong",
    district_code: 330,
  },
  {
    name: "Thị trấn Lương Bằng",
    code: 12280,
    division_type: "thị trấn",
    codename: "thi_tran_luong_bang",
    district_code: 331,
  },
  {
    name: "Xã Nghĩa Dân",
    code: 12283,
    division_type: "xã",
    codename: "xa_nghia_dan",
    district_code: 331,
  },
  {
    name: "Xã Toàn Thắng",
    code: 12286,
    division_type: "xã",
    codename: "xa_toan_thang",
    district_code: 331,
  },
  {
    name: "Xã Vĩnh Xá",
    code: 12289,
    division_type: "xã",
    codename: "xa_vinh_xa",
    district_code: 331,
  },
  {
    name: "Xã Phạm Ngũ Lão",
    code: 12292,
    division_type: "xã",
    codename: "xa_pham_ngu_lao",
    district_code: 331,
  },
  {
    name: "Xã Thọ Vinh",
    code: 12295,
    division_type: "xã",
    codename: "xa_tho_vinh",
    district_code: 331,
  },
  {
    name: "Xã Đồng Thanh",
    code: 12298,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 331,
  },
  {
    name: "Xã Song Mai",
    code: 12301,
    division_type: "xã",
    codename: "xa_song_mai",
    district_code: 331,
  },
  {
    name: "Xã Chính Nghĩa",
    code: 12304,
    division_type: "xã",
    codename: "xa_chinh_nghia",
    district_code: 331,
  },
  {
    name: "Xã Nhân La",
    code: 12307,
    division_type: "xã",
    codename: "xa_nhan_la",
    district_code: 331,
  },
  {
    name: "Xã Phú Thịnh",
    code: 12310,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 331,
  },
  {
    name: "Xã Mai Động",
    code: 12313,
    division_type: "xã",
    codename: "xa_mai_dong",
    district_code: 331,
  },
  {
    name: "Xã Đức Hợp",
    code: 12316,
    division_type: "xã",
    codename: "xa_duc_hop",
    district_code: 331,
  },
  {
    name: "Xã Hùng An",
    code: 12319,
    division_type: "xã",
    codename: "xa_hung_an",
    district_code: 331,
  },
  {
    name: "Xã Ngọc Thanh",
    code: 12322,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 331,
  },
  {
    name: "Xã Vũ Xá",
    code: 12325,
    division_type: "xã",
    codename: "xa_vu_xa",
    district_code: 331,
  },
  {
    name: "Xã Hiệp Cường",
    code: 12328,
    division_type: "xã",
    codename: "xa_hiep_cuong",
    district_code: 331,
  },
  {
    name: "Thị trấn Vương",
    code: 12337,
    division_type: "thị trấn",
    codename: "thi_tran_vuong",
    district_code: 332,
  },
  {
    name: "Xã Hưng Đạo",
    code: 12340,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 332,
  },
  {
    name: "Xã Ngô Quyền",
    code: 12343,
    division_type: "xã",
    codename: "xa_ngo_quyen",
    district_code: 332,
  },
  {
    name: "Xã Nhật Tân",
    code: 12346,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 332,
  },
  {
    name: "Xã Dị Chế",
    code: 12349,
    division_type: "xã",
    codename: "xa_di_che",
    district_code: 332,
  },
  {
    name: "Xã Lệ Xá",
    code: 12352,
    division_type: "xã",
    codename: "xa_le_xa",
    district_code: 332,
  },
  {
    name: "Xã An Viên",
    code: 12355,
    division_type: "xã",
    codename: "xa_an_vien",
    district_code: 332,
  },
  {
    name: "Xã Đức Thắng",
    code: 12358,
    division_type: "xã",
    codename: "xa_duc_thang",
    district_code: 332,
  },
  {
    name: "Xã Trung Dũng",
    code: 12361,
    division_type: "xã",
    codename: "xa_trung_dung",
    district_code: 332,
  },
  {
    name: "Xã Hải Triều",
    code: 12364,
    division_type: "xã",
    codename: "xa_hai_trieu",
    district_code: 332,
  },
  {
    name: "Xã Thủ Sỹ",
    code: 12367,
    division_type: "xã",
    codename: "xa_thu_sy",
    district_code: 332,
  },
  {
    name: "Xã Thiện Phiến",
    code: 12370,
    division_type: "xã",
    codename: "xa_thien_phien",
    district_code: 332,
  },
  {
    name: "Xã Thụy Lôi",
    code: 12373,
    division_type: "xã",
    codename: "xa_thuy_loi",
    district_code: 332,
  },
  {
    name: "Xã Cương Chính",
    code: 12376,
    division_type: "xã",
    codename: "xa_cuong_chinh",
    district_code: 332,
  },
  {
    name: "Xã Minh Phượng",
    code: 12379,
    division_type: "xã",
    codename: "xa_minh_phuong",
    district_code: 332,
  },
  {
    name: "Thị trấn Trần Cao",
    code: 12391,
    division_type: "thị trấn",
    codename: "thi_tran_tran_cao",
    district_code: 333,
  },
  {
    name: "Xã Minh Tân",
    code: 12394,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 333,
  },
  {
    name: "Xã Phan Sào Nam",
    code: 12397,
    division_type: "xã",
    codename: "xa_phan_sao_nam",
    district_code: 333,
  },
  {
    name: "Xã Quang Hưng",
    code: 12400,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 333,
  },
  {
    name: "Xã Minh Hoàng",
    code: 12403,
    division_type: "xã",
    codename: "xa_minh_hoang",
    district_code: 333,
  },
  {
    name: "Xã Đoàn Đào",
    code: 12406,
    division_type: "xã",
    codename: "xa_doan_dao",
    district_code: 333,
  },
  {
    name: "Xã Tống Phan",
    code: 12409,
    division_type: "xã",
    codename: "xa_tong_phan",
    district_code: 333,
  },
  {
    name: "Xã Đình Cao",
    code: 12412,
    division_type: "xã",
    codename: "xa_dinh_cao",
    district_code: 333,
  },
  {
    name: "Xã Nhật Quang",
    code: 12415,
    division_type: "xã",
    codename: "xa_nhat_quang",
    district_code: 333,
  },
  {
    name: "Xã Tiền Tiến",
    code: 12418,
    division_type: "xã",
    codename: "xa_tien_tien",
    district_code: 333,
  },
  {
    name: "Xã Tam Đa",
    code: 12421,
    division_type: "xã",
    codename: "xa_tam_da",
    district_code: 333,
  },
  {
    name: "Xã Minh Tiến",
    code: 12424,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 333,
  },
  {
    name: "Xã Nguyên Hòa",
    code: 12427,
    division_type: "xã",
    codename: "xa_nguyen_hoa",
    district_code: 333,
  },
  {
    name: "Xã Tống Trân",
    code: 12430,
    division_type: "xã",
    codename: "xa_tong_tran",
    district_code: 333,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 12433,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 336,
  },
  {
    name: "Phường Bồ Xuyên",
    code: 12436,
    division_type: "phường",
    codename: "phuong_bo_xuyen",
    district_code: 336,
  },
  {
    name: "Phường Đề Thám",
    code: 12439,
    division_type: "phường",
    codename: "phuong_de_tham",
    district_code: 336,
  },
  {
    name: "Phường Kỳ Bá",
    code: 12442,
    division_type: "phường",
    codename: "phuong_ky_ba",
    district_code: 336,
  },
  {
    name: "Phường Quang Trung",
    code: 12445,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 336,
  },
  {
    name: "Phường Phú Khánh",
    code: 12448,
    division_type: "phường",
    codename: "phuong_phu_khanh",
    district_code: 336,
  },
  {
    name: "Phường Tiền Phong",
    code: 12451,
    division_type: "phường",
    codename: "phuong_tien_phong",
    district_code: 336,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 12452,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 336,
  },
  {
    name: "Phường Trần Lãm",
    code: 12454,
    division_type: "phường",
    codename: "phuong_tran_lam",
    district_code: 336,
  },
  {
    name: "Xã Đông Hòa",
    code: 12457,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 336,
  },
  {
    name: "Phường Hoàng Diệu",
    code: 12460,
    division_type: "phường",
    codename: "phuong_hoang_dieu",
    district_code: 336,
  },
  {
    name: "Xã Phú Xuân",
    code: 12463,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 336,
  },
  {
    name: "Xã Vũ Phúc",
    code: 12466,
    division_type: "xã",
    codename: "xa_vu_phuc",
    district_code: 336,
  },
  {
    name: "Xã Vũ Chính",
    code: 12469,
    division_type: "xã",
    codename: "xa_vu_chinh",
    district_code: 336,
  },
  {
    name: "Xã Đông Mỹ",
    code: 12817,
    division_type: "xã",
    codename: "xa_dong_my",
    district_code: 336,
  },
  {
    name: "Xã Đông Thọ",
    code: 12820,
    division_type: "xã",
    codename: "xa_dong_tho",
    district_code: 336,
  },
  {
    name: "Xã Vũ Đông",
    code: 13084,
    division_type: "xã",
    codename: "xa_vu_dong",
    district_code: 336,
  },
  {
    name: "Xã Vũ Lạc",
    code: 13108,
    division_type: "xã",
    codename: "xa_vu_lac",
    district_code: 336,
  },
  {
    name: "Xã Tân Bình",
    code: 13225,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 336,
  },
  {
    name: "Thị trấn Quỳnh Côi",
    code: 12472,
    division_type: "thị trấn",
    codename: "thi_tran_quynh_coi",
    district_code: 338,
  },
  {
    name: "Xã An Khê",
    code: 12475,
    division_type: "xã",
    codename: "xa_an_khe",
    district_code: 338,
  },
  {
    name: "Xã An Đồng",
    code: 12478,
    division_type: "xã",
    codename: "xa_an_dong",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hoa",
    code: 12481,
    division_type: "xã",
    codename: "xa_quynh_hoa",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Lâm",
    code: 12484,
    division_type: "xã",
    codename: "xa_quynh_lam",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Thọ",
    code: 12487,
    division_type: "xã",
    codename: "xa_quynh_tho",
    district_code: 338,
  },
  {
    name: "Xã An Hiệp",
    code: 12490,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hoàng",
    code: 12493,
    division_type: "xã",
    codename: "xa_quynh_hoang",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Giao",
    code: 12496,
    division_type: "xã",
    codename: "xa_quynh_giao",
    district_code: 338,
  },
  {
    name: "Xã An Thái",
    code: 12499,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 338,
  },
  {
    name: "Xã An Cầu",
    code: 12502,
    division_type: "xã",
    codename: "xa_an_cau",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hồng",
    code: 12505,
    division_type: "xã",
    codename: "xa_quynh_hong",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Khê",
    code: 12508,
    division_type: "xã",
    codename: "xa_quynh_khe",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Minh",
    code: 12511,
    division_type: "xã",
    codename: "xa_quynh_minh",
    district_code: 338,
  },
  {
    name: "Xã An Ninh",
    code: 12514,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Ngọc",
    code: 12517,
    division_type: "xã",
    codename: "xa_quynh_ngoc",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hải",
    code: 12520,
    division_type: "xã",
    codename: "xa_quynh_hai",
    district_code: 338,
  },
  {
    name: "Thị trấn An Bài",
    code: 12523,
    division_type: "thị trấn",
    codename: "thi_tran_an_bai",
    district_code: 338,
  },
  {
    name: "Xã An Ấp",
    code: 12526,
    division_type: "xã",
    codename: "xa_an_ap",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hội",
    code: 12529,
    division_type: "xã",
    codename: "xa_quynh_hoi",
    district_code: 338,
  },
  {
    name: "Xã Châu Sơn",
    code: 12532,
    division_type: "xã",
    codename: "xa_chau_son",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Mỹ",
    code: 12535,
    division_type: "xã",
    codename: "xa_quynh_my",
    district_code: 338,
  },
  {
    name: "Xã An Quí",
    code: 12538,
    division_type: "xã",
    codename: "xa_an_qui",
    district_code: 338,
  },
  {
    name: "Xã An Thanh",
    code: 12541,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 338,
  },
  {
    name: "Xã An Vũ",
    code: 12547,
    division_type: "xã",
    codename: "xa_an_vu",
    district_code: 338,
  },
  {
    name: "Xã An Lễ",
    code: 12550,
    division_type: "xã",
    codename: "xa_an_le",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Hưng",
    code: 12553,
    division_type: "xã",
    codename: "xa_quynh_hung",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Bảo",
    code: 12556,
    division_type: "xã",
    codename: "xa_quynh_bao",
    district_code: 338,
  },
  {
    name: "Xã An Mỹ",
    code: 12559,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Nguyên",
    code: 12562,
    division_type: "xã",
    codename: "xa_quynh_nguyen",
    district_code: 338,
  },
  {
    name: "Xã An Vinh",
    code: 12565,
    division_type: "xã",
    codename: "xa_an_vinh",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Xá",
    code: 12568,
    division_type: "xã",
    codename: "xa_quynh_xa",
    district_code: 338,
  },
  {
    name: "Xã An Dục",
    code: 12571,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 338,
  },
  {
    name: "Xã Đông Hải",
    code: 12574,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 338,
  },
  {
    name: "Xã Quỳnh Trang",
    code: 12577,
    division_type: "xã",
    codename: "xa_quynh_trang",
    district_code: 338,
  },
  {
    name: "Xã An Tràng",
    code: 12580,
    division_type: "xã",
    codename: "xa_an_trang",
    district_code: 338,
  },
  {
    name: "Xã Đồng Tiến",
    code: 12583,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 338,
  },
  {
    name: "Thị trấn Hưng Hà",
    code: 12586,
    division_type: "thị trấn",
    codename: "thi_tran_hung_ha",
    district_code: 339,
  },
  {
    name: "Xã Điệp Nông",
    code: 12589,
    division_type: "xã",
    codename: "xa_diep_nong",
    district_code: 339,
  },
  {
    name: "Xã Tân Lễ",
    code: 12592,
    division_type: "xã",
    codename: "xa_tan_le",
    district_code: 339,
  },
  {
    name: "Xã Cộng Hòa",
    code: 12595,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 339,
  },
  {
    name: "Xã Dân Chủ",
    code: 12598,
    division_type: "xã",
    codename: "xa_dan_chu",
    district_code: 339,
  },
  {
    name: "Xã Canh Tân",
    code: 12601,
    division_type: "xã",
    codename: "xa_canh_tan",
    district_code: 339,
  },
  {
    name: "Xã Hòa Tiến",
    code: 12604,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 339,
  },
  {
    name: "Xã Hùng Dũng",
    code: 12607,
    division_type: "xã",
    codename: "xa_hung_dung",
    district_code: 339,
  },
  {
    name: "Xã Tân Tiến",
    code: 12610,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 339,
  },
  {
    name: "Thị trấn Hưng Nhân",
    code: 12613,
    division_type: "thị trấn",
    codename: "thi_tran_hung_nhan",
    district_code: 339,
  },
  {
    name: "Xã Đoan Hùng",
    code: 12616,
    division_type: "xã",
    codename: "xa_doan_hung",
    district_code: 339,
  },
  {
    name: "Xã Duyên Hải",
    code: 12619,
    division_type: "xã",
    codename: "xa_duyen_hai",
    district_code: 339,
  },
  {
    name: "Xã Tân Hòa",
    code: 12622,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 339,
  },
  {
    name: "Xã Văn Cẩm",
    code: 12625,
    division_type: "xã",
    codename: "xa_van_cam",
    district_code: 339,
  },
  {
    name: "Xã Bắc Sơn",
    code: 12628,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 339,
  },
  {
    name: "Xã Đông Đô",
    code: 12631,
    division_type: "xã",
    codename: "xa_dong_do",
    district_code: 339,
  },
  {
    name: "Xã Phúc Khánh",
    code: 12634,
    division_type: "xã",
    codename: "xa_phuc_khanh",
    district_code: 339,
  },
  {
    name: "Xã Liên Hiệp",
    code: 12637,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 339,
  },
  {
    name: "Xã Tây Đô",
    code: 12640,
    division_type: "xã",
    codename: "xa_tay_do",
    district_code: 339,
  },
  {
    name: "Xã Thống Nhất",
    code: 12643,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 339,
  },
  {
    name: "Xã Tiến Đức",
    code: 12646,
    division_type: "xã",
    codename: "xa_tien_duc",
    district_code: 339,
  },
  {
    name: "Xã Thái Hưng",
    code: 12649,
    division_type: "xã",
    codename: "xa_thai_hung",
    district_code: 339,
  },
  {
    name: "Xã Thái Phương",
    code: 12652,
    division_type: "xã",
    codename: "xa_thai_phuong",
    district_code: 339,
  },
  {
    name: "Xã Hòa Bình",
    code: 12655,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 339,
  },
  {
    name: "Xã Chi Lăng",
    code: 12656,
    division_type: "xã",
    codename: "xa_chi_lang",
    district_code: 339,
  },
  {
    name: "Xã Minh Khai",
    code: 12658,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 339,
  },
  {
    name: "Xã Hồng An",
    code: 12661,
    division_type: "xã",
    codename: "xa_hong_an",
    district_code: 339,
  },
  {
    name: "Xã Kim Chung",
    code: 12664,
    division_type: "xã",
    codename: "xa_kim_chung",
    district_code: 339,
  },
  {
    name: "Xã Hồng Lĩnh",
    code: 12667,
    division_type: "xã",
    codename: "xa_hong_linh",
    district_code: 339,
  },
  {
    name: "Xã Minh Tân",
    code: 12670,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 339,
  },
  {
    name: "Xã Văn Lang",
    code: 12673,
    division_type: "xã",
    codename: "xa_van_lang",
    district_code: 339,
  },
  {
    name: "Xã Độc Lập",
    code: 12676,
    division_type: "xã",
    codename: "xa_doc_lap",
    district_code: 339,
  },
  {
    name: "Xã Chí Hòa",
    code: 12679,
    division_type: "xã",
    codename: "xa_chi_hoa",
    district_code: 339,
  },
  {
    name: "Xã Minh Hòa",
    code: 12682,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 339,
  },
  {
    name: "Xã Hồng Minh",
    code: 12685,
    division_type: "xã",
    codename: "xa_hong_minh",
    district_code: 339,
  },
  {
    name: "Thị trấn Đông Hưng",
    code: 12688,
    division_type: "thị trấn",
    codename: "thi_tran_dong_hung",
    district_code: 340,
  },
  {
    name: "Xã Đô Lương",
    code: 12691,
    division_type: "xã",
    codename: "xa_do_luong",
    district_code: 340,
  },
  {
    name: "Xã Đông Phương",
    code: 12694,
    division_type: "xã",
    codename: "xa_dong_phuong",
    district_code: 340,
  },
  {
    name: "Xã Liên Giang",
    code: 12697,
    division_type: "xã",
    codename: "xa_lien_giang",
    district_code: 340,
  },
  {
    name: "Xã An Châu",
    code: 12700,
    division_type: "xã",
    codename: "xa_an_chau",
    district_code: 340,
  },
  {
    name: "Xã Đông Sơn",
    code: 12703,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 340,
  },
  {
    name: "Xã Đông Cường",
    code: 12706,
    division_type: "xã",
    codename: "xa_dong_cuong",
    district_code: 340,
  },
  {
    name: "Xã Phú Lương",
    code: 12709,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 340,
  },
  {
    name: "Xã Mê Linh",
    code: 12712,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 340,
  },
  {
    name: "Xã Lô Giang",
    code: 12715,
    division_type: "xã",
    codename: "xa_lo_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông La",
    code: 12718,
    division_type: "xã",
    codename: "xa_dong_la",
    district_code: 340,
  },
  {
    name: "Xã Minh Tân",
    code: 12721,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 340,
  },
  {
    name: "Xã Đông Xá",
    code: 12724,
    division_type: "xã",
    codename: "xa_dong_xa",
    district_code: 340,
  },
  {
    name: "Xã Chương Dương",
    code: 12727,
    division_type: "xã",
    codename: "xa_chuong_duong",
    district_code: 340,
  },
  {
    name: "Xã Nguyên Xá",
    code: 12730,
    division_type: "xã",
    codename: "xa_nguyen_xa",
    district_code: 340,
  },
  {
    name: "Xã Phong Châu",
    code: 12733,
    division_type: "xã",
    codename: "xa_phong_chau",
    district_code: 340,
  },
  {
    name: "Xã Hợp Tiến",
    code: 12736,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 340,
  },
  {
    name: "Xã Hồng Việt",
    code: 12739,
    division_type: "xã",
    codename: "xa_hong_viet",
    district_code: 340,
  },
  {
    name: "Xã Hà Giang",
    code: 12745,
    division_type: "xã",
    codename: "xa_ha_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông Kinh",
    code: 12748,
    division_type: "xã",
    codename: "xa_dong_kinh",
    district_code: 340,
  },
  {
    name: "Xã Đông Hợp",
    code: 12751,
    division_type: "xã",
    codename: "xa_dong_hop",
    district_code: 340,
  },
  {
    name: "Xã Thăng Long",
    code: 12754,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 340,
  },
  {
    name: "Xã Đông Các",
    code: 12757,
    division_type: "xã",
    codename: "xa_dong_cac",
    district_code: 340,
  },
  {
    name: "Xã Phú Châu",
    code: 12760,
    division_type: "xã",
    codename: "xa_phu_chau",
    district_code: 340,
  },
  {
    name: "Xã Liên Hoa",
    code: 12763,
    division_type: "xã",
    codename: "xa_lien_hoa",
    district_code: 340,
  },
  {
    name: "Xã Đông Tân",
    code: 12769,
    division_type: "xã",
    codename: "xa_dong_tan",
    district_code: 340,
  },
  {
    name: "Xã Đông Vinh",
    code: 12772,
    division_type: "xã",
    codename: "xa_dong_vinh",
    district_code: 340,
  },
  {
    name: "Xã Đông Động",
    code: 12775,
    division_type: "xã",
    codename: "xa_dong_dong",
    district_code: 340,
  },
  {
    name: "Xã Hồng Bạch",
    code: 12778,
    division_type: "xã",
    codename: "xa_hong_bach",
    district_code: 340,
  },
  {
    name: "Xã Trọng Quan",
    code: 12784,
    division_type: "xã",
    codename: "xa_trong_quan",
    district_code: 340,
  },
  {
    name: "Xã Hồng Giang",
    code: 12790,
    division_type: "xã",
    codename: "xa_hong_giang",
    district_code: 340,
  },
  {
    name: "Xã Đông Quan",
    code: 12793,
    division_type: "xã",
    codename: "xa_dong_quan",
    district_code: 340,
  },
  {
    name: "Xã Đông Quang",
    code: 12796,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 340,
  },
  {
    name: "Xã Đông Xuân",
    code: 12799,
    division_type: "xã",
    codename: "xa_dong_xuan",
    district_code: 340,
  },
  {
    name: "Xã Đông Á",
    code: 12802,
    division_type: "xã",
    codename: "xa_dong_a",
    district_code: 340,
  },
  {
    name: "Xã Đông Hoàng",
    code: 12808,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 340,
  },
  {
    name: "Xã Đông Dương",
    code: 12811,
    division_type: "xã",
    codename: "xa_dong_duong",
    district_code: 340,
  },
  {
    name: "Xã Minh Phú",
    code: 12823,
    division_type: "xã",
    codename: "xa_minh_phu",
    district_code: 340,
  },
  {
    name: "Thị trấn Diêm Điền",
    code: 12826,
    division_type: "thị trấn",
    codename: "thi_tran_diem_dien",
    district_code: 341,
  },
  {
    name: "Xã Thụy Trường",
    code: 12832,
    division_type: "xã",
    codename: "xa_thuy_truong",
    district_code: 341,
  },
  {
    name: "Xã Hồng Dũng",
    code: 12841,
    division_type: "xã",
    codename: "xa_hong_dung",
    district_code: 341,
  },
  {
    name: "Xã Thụy Quỳnh",
    code: 12844,
    division_type: "xã",
    codename: "xa_thuy_quynh",
    district_code: 341,
  },
  {
    name: "Xã An Tân",
    code: 12847,
    division_type: "xã",
    codename: "xa_an_tan",
    district_code: 341,
  },
  {
    name: "Xã Thụy Ninh",
    code: 12850,
    division_type: "xã",
    codename: "xa_thuy_ninh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Hưng",
    code: 12853,
    division_type: "xã",
    codename: "xa_thuy_hung",
    district_code: 341,
  },
  {
    name: "Xã Thụy Việt",
    code: 12856,
    division_type: "xã",
    codename: "xa_thuy_viet",
    district_code: 341,
  },
  {
    name: "Xã Thụy Văn",
    code: 12859,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 341,
  },
  {
    name: "Xã Thụy Xuân",
    code: 12862,
    division_type: "xã",
    codename: "xa_thuy_xuan",
    district_code: 341,
  },
  {
    name: "Xã Dương Phúc",
    code: 12865,
    division_type: "xã",
    codename: "xa_duong_phuc",
    district_code: 341,
  },
  {
    name: "Xã Thụy Trình",
    code: 12868,
    division_type: "xã",
    codename: "xa_thuy_trinh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Bình",
    code: 12871,
    division_type: "xã",
    codename: "xa_thuy_binh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Chính",
    code: 12874,
    division_type: "xã",
    codename: "xa_thuy_chinh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Dân",
    code: 12877,
    division_type: "xã",
    codename: "xa_thuy_dan",
    district_code: 341,
  },
  {
    name: "Xã Thụy Hải",
    code: 12880,
    division_type: "xã",
    codename: "xa_thuy_hai",
    district_code: 341,
  },
  {
    name: "Xã Thụy Liên",
    code: 12889,
    division_type: "xã",
    codename: "xa_thuy_lien",
    district_code: 341,
  },
  {
    name: "Xã Thụy Duyên",
    code: 12892,
    division_type: "xã",
    codename: "xa_thuy_duyen",
    district_code: 341,
  },
  {
    name: "Xã Thụy Thanh",
    code: 12898,
    division_type: "xã",
    codename: "xa_thuy_thanh",
    district_code: 341,
  },
  {
    name: "Xã Thụy Sơn",
    code: 12901,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 341,
  },
  {
    name: "Xã Thụy Phong",
    code: 12904,
    division_type: "xã",
    codename: "xa_thuy_phong",
    district_code: 341,
  },
  {
    name: "Xã Thái Thượng",
    code: 12907,
    division_type: "xã",
    codename: "xa_thai_thuong",
    district_code: 341,
  },
  {
    name: "Xã Thái Nguyên",
    code: 12910,
    division_type: "xã",
    codename: "xa_thai_nguyen",
    district_code: 341,
  },
  {
    name: "Xã Dương Hồng Thủy",
    code: 12916,
    division_type: "xã",
    codename: "xa_duong_hong_thuy",
    district_code: 341,
  },
  {
    name: "Xã Thái Giang",
    code: 12919,
    division_type: "xã",
    codename: "xa_thai_giang",
    district_code: 341,
  },
  {
    name: "Xã Hòa An",
    code: 12922,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 341,
  },
  {
    name: "Xã Sơn Hà",
    code: 12925,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 341,
  },
  {
    name: "Xã Thái Phúc",
    code: 12934,
    division_type: "xã",
    codename: "xa_thai_phuc",
    district_code: 341,
  },
  {
    name: "Xã Thái Hưng",
    code: 12937,
    division_type: "xã",
    codename: "xa_thai_hung",
    district_code: 341,
  },
  {
    name: "Xã Thái Đô",
    code: 12940,
    division_type: "xã",
    codename: "xa_thai_do",
    district_code: 341,
  },
  {
    name: "Xã Thái Xuyên",
    code: 12943,
    division_type: "xã",
    codename: "xa_thai_xuyen",
    district_code: 341,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 12949,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 341,
  },
  {
    name: "Xã Tân Học",
    code: 12958,
    division_type: "xã",
    codename: "xa_tan_hoc",
    district_code: 341,
  },
  {
    name: "Xã Thái Thịnh",
    code: 12961,
    division_type: "xã",
    codename: "xa_thai_thinh",
    district_code: 341,
  },
  {
    name: "Xã Thuần Thành",
    code: 12964,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 341,
  },
  {
    name: "Xã Thái Thọ",
    code: 12967,
    division_type: "xã",
    codename: "xa_thai_tho",
    district_code: 341,
  },
  {
    name: "Thị trấn Tiền Hải",
    code: 12970,
    division_type: "thị trấn",
    codename: "thi_tran_tien_hai",
    district_code: 342,
  },
  {
    name: "Xã Đông Trà",
    code: 12976,
    division_type: "xã",
    codename: "xa_dong_tra",
    district_code: 342,
  },
  {
    name: "Xã Đông Long",
    code: 12979,
    division_type: "xã",
    codename: "xa_dong_long",
    district_code: 342,
  },
  {
    name: "Xã Đông Quí",
    code: 12982,
    division_type: "xã",
    codename: "xa_dong_qui",
    district_code: 342,
  },
  {
    name: "Xã Vũ Lăng",
    code: 12985,
    division_type: "xã",
    codename: "xa_vu_lang",
    district_code: 342,
  },
  {
    name: "Xã Đông Xuyên",
    code: 12988,
    division_type: "xã",
    codename: "xa_dong_xuyen",
    district_code: 342,
  },
  {
    name: "Xã Tây Lương",
    code: 12991,
    division_type: "xã",
    codename: "xa_tay_luong",
    district_code: 342,
  },
  {
    name: "Xã Tây Ninh",
    code: 12994,
    division_type: "xã",
    codename: "xa_tay_ninh",
    district_code: 342,
  },
  {
    name: "Xã Đông Trung",
    code: 12997,
    division_type: "xã",
    codename: "xa_dong_trung",
    district_code: 342,
  },
  {
    name: "Xã Đông Hoàng",
    code: 13000,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 342,
  },
  {
    name: "Xã Đông Minh",
    code: 13003,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 342,
  },
  {
    name: "Xã Đông Phong",
    code: 13009,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 342,
  },
  {
    name: "Xã An Ninh",
    code: 13012,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 342,
  },
  {
    name: "Xã Đông Cơ",
    code: 13018,
    division_type: "xã",
    codename: "xa_dong_co",
    district_code: 342,
  },
  {
    name: "Xã Tây Giang",
    code: 13021,
    division_type: "xã",
    codename: "xa_tay_giang",
    district_code: 342,
  },
  {
    name: "Xã Đông Lâm",
    code: 13024,
    division_type: "xã",
    codename: "xa_dong_lam",
    district_code: 342,
  },
  {
    name: "Xã Phương Công",
    code: 13027,
    division_type: "xã",
    codename: "xa_phuong_cong",
    district_code: 342,
  },
  {
    name: "Xã Tây Phong",
    code: 13030,
    division_type: "xã",
    codename: "xa_tay_phong",
    district_code: 342,
  },
  {
    name: "Xã Tây Tiến",
    code: 13033,
    division_type: "xã",
    codename: "xa_tay_tien",
    district_code: 342,
  },
  {
    name: "Xã Nam Cường",
    code: 13036,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 342,
  },
  {
    name: "Xã Vân Trường",
    code: 13039,
    division_type: "xã",
    codename: "xa_van_truong",
    district_code: 342,
  },
  {
    name: "Xã Nam Thắng",
    code: 13042,
    division_type: "xã",
    codename: "xa_nam_thang",
    district_code: 342,
  },
  {
    name: "Xã Nam Chính",
    code: 13045,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 342,
  },
  {
    name: "Xã Bắc Hải",
    code: 13048,
    division_type: "xã",
    codename: "xa_bac_hai",
    district_code: 342,
  },
  {
    name: "Xã Nam Thịnh",
    code: 13051,
    division_type: "xã",
    codename: "xa_nam_thinh",
    district_code: 342,
  },
  {
    name: "Xã Nam Hà",
    code: 13054,
    division_type: "xã",
    codename: "xa_nam_ha",
    district_code: 342,
  },
  {
    name: "Xã Nam Thanh",
    code: 13057,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 342,
  },
  {
    name: "Xã Nam Trung",
    code: 13060,
    division_type: "xã",
    codename: "xa_nam_trung",
    district_code: 342,
  },
  {
    name: "Xã Nam Hồng",
    code: 13063,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 342,
  },
  {
    name: "Xã Nam Hưng",
    code: 13066,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 342,
  },
  {
    name: "Xã Nam Hải",
    code: 13069,
    division_type: "xã",
    codename: "xa_nam_hai",
    district_code: 342,
  },
  {
    name: "Xã Nam Phú",
    code: 13072,
    division_type: "xã",
    codename: "xa_nam_phu",
    district_code: 342,
  },
  {
    name: "Thị trấn Kiến Xương",
    code: 13075,
    division_type: "thị trấn",
    codename: "thi_tran_kien_xuong",
    district_code: 343,
  },
  {
    name: "Xã Trà Giang",
    code: 13078,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 343,
  },
  {
    name: "Xã Quốc Tuấn",
    code: 13081,
    division_type: "xã",
    codename: "xa_quoc_tuan",
    district_code: 343,
  },
  {
    name: "Xã An Bình",
    code: 13087,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 343,
  },
  {
    name: "Xã Tây Sơn",
    code: 13090,
    division_type: "xã",
    codename: "xa_tay_son",
    district_code: 343,
  },
  {
    name: "Xã Hồng Thái",
    code: 13093,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 343,
  },
  {
    name: "Xã Bình Nguyên",
    code: 13096,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 343,
  },
  {
    name: "Xã Lê Lợi",
    code: 13102,
    division_type: "xã",
    codename: "xa_le_loi",
    district_code: 343,
  },
  {
    name: "Xã Vũ Lễ",
    code: 13111,
    division_type: "xã",
    codename: "xa_vu_le",
    district_code: 343,
  },
  {
    name: "Xã Thanh Tân",
    code: 13114,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 343,
  },
  {
    name: "Xã Thượng Hiền",
    code: 13117,
    division_type: "xã",
    codename: "xa_thuong_hien",
    district_code: 343,
  },
  {
    name: "Xã Nam Cao",
    code: 13120,
    division_type: "xã",
    codename: "xa_nam_cao",
    district_code: 343,
  },
  {
    name: "Xã Đình Phùng",
    code: 13123,
    division_type: "xã",
    codename: "xa_dinh_phung",
    district_code: 343,
  },
  {
    name: "Xã Vũ Ninh",
    code: 13126,
    division_type: "xã",
    codename: "xa_vu_ninh",
    district_code: 343,
  },
  {
    name: "Xã Vũ An",
    code: 13129,
    division_type: "xã",
    codename: "xa_vu_an",
    district_code: 343,
  },
  {
    name: "Xã Quang Lịch",
    code: 13132,
    division_type: "xã",
    codename: "xa_quang_lich",
    district_code: 343,
  },
  {
    name: "Xã Hòa Bình",
    code: 13135,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 343,
  },
  {
    name: "Xã Bình Minh",
    code: 13138,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 343,
  },
  {
    name: "Xã Vũ Quí",
    code: 13141,
    division_type: "xã",
    codename: "xa_vu_qui",
    district_code: 343,
  },
  {
    name: "Xã Quang Bình",
    code: 13144,
    division_type: "xã",
    codename: "xa_quang_binh",
    district_code: 343,
  },
  {
    name: "Xã Vũ Trung",
    code: 13150,
    division_type: "xã",
    codename: "xa_vu_trung",
    district_code: 343,
  },
  {
    name: "Xã Vũ Thắng",
    code: 13153,
    division_type: "xã",
    codename: "xa_vu_thang",
    district_code: 343,
  },
  {
    name: "Xã Vũ Công",
    code: 13156,
    division_type: "xã",
    codename: "xa_vu_cong",
    district_code: 343,
  },
  {
    name: "Xã Vũ Hòa",
    code: 13159,
    division_type: "xã",
    codename: "xa_vu_hoa",
    district_code: 343,
  },
  {
    name: "Xã Quang Minh",
    code: 13162,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 343,
  },
  {
    name: "Xã Quang Trung",
    code: 13165,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 343,
  },
  {
    name: "Xã Minh Quang",
    code: 13171,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 343,
  },
  {
    name: "Xã Vũ Bình",
    code: 13174,
    division_type: "xã",
    codename: "xa_vu_binh",
    district_code: 343,
  },
  {
    name: "Xã Minh Tân",
    code: 13177,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 343,
  },
  {
    name: "Xã Nam Bình",
    code: 13180,
    division_type: "xã",
    codename: "xa_nam_binh",
    district_code: 343,
  },
  {
    name: "Xã Bình Thanh",
    code: 13183,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 343,
  },
  {
    name: "Xã Bình Định",
    code: 13186,
    division_type: "xã",
    codename: "xa_binh_dinh",
    district_code: 343,
  },
  {
    name: "Xã Hồng Tiến",
    code: 13189,
    division_type: "xã",
    codename: "xa_hong_tien",
    district_code: 343,
  },
  {
    name: "Thị trấn Vũ Thư",
    code: 13192,
    division_type: "thị trấn",
    codename: "thi_tran_vu_thu",
    district_code: 344,
  },
  {
    name: "Xã Hồng Lý",
    code: 13195,
    division_type: "xã",
    codename: "xa_hong_ly",
    district_code: 344,
  },
  {
    name: "Xã Đồng Thanh",
    code: 13198,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 344,
  },
  {
    name: "Xã Xuân Hòa",
    code: 13201,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 344,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 13204,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 344,
  },
  {
    name: "Xã Phúc Thành",
    code: 13207,
    division_type: "xã",
    codename: "xa_phuc_thanh",
    district_code: 344,
  },
  {
    name: "Xã Tân Phong",
    code: 13210,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 344,
  },
  {
    name: "Xã Song Lãng",
    code: 13213,
    division_type: "xã",
    codename: "xa_song_lang",
    district_code: 344,
  },
  {
    name: "Xã Tân Hòa",
    code: 13216,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 344,
  },
  {
    name: "Xã Việt Hùng",
    code: 13219,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 344,
  },
  {
    name: "Xã Minh Lãng",
    code: 13222,
    division_type: "xã",
    codename: "xa_minh_lang",
    district_code: 344,
  },
  {
    name: "Xã Minh Khai",
    code: 13228,
    division_type: "xã",
    codename: "xa_minh_khai",
    district_code: 344,
  },
  {
    name: "Xã Dũng Nghĩa",
    code: 13231,
    division_type: "xã",
    codename: "xa_dung_nghia",
    district_code: 344,
  },
  {
    name: "Xã Minh Quang",
    code: 13234,
    division_type: "xã",
    codename: "xa_minh_quang",
    district_code: 344,
  },
  {
    name: "Xã Tam Quang",
    code: 13237,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 344,
  },
  {
    name: "Xã Tân Lập",
    code: 13240,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 344,
  },
  {
    name: "Xã Bách Thuận",
    code: 13243,
    division_type: "xã",
    codename: "xa_bach_thuan",
    district_code: 344,
  },
  {
    name: "Xã Tự Tân",
    code: 13246,
    division_type: "xã",
    codename: "xa_tu_tan",
    district_code: 344,
  },
  {
    name: "Xã Song An",
    code: 13249,
    division_type: "xã",
    codename: "xa_song_an",
    district_code: 344,
  },
  {
    name: "Xã Trung An",
    code: 13252,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 344,
  },
  {
    name: "Xã Vũ Hội",
    code: 13255,
    division_type: "xã",
    codename: "xa_vu_hoi",
    district_code: 344,
  },
  {
    name: "Xã Hòa Bình",
    code: 13258,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 344,
  },
  {
    name: "Xã Nguyên Xá",
    code: 13261,
    division_type: "xã",
    codename: "xa_nguyen_xa",
    district_code: 344,
  },
  {
    name: "Xã Việt Thuận",
    code: 13264,
    division_type: "xã",
    codename: "xa_viet_thuan",
    district_code: 344,
  },
  {
    name: "Xã Vũ Vinh",
    code: 13267,
    division_type: "xã",
    codename: "xa_vu_vinh",
    district_code: 344,
  },
  {
    name: "Xã Vũ Đoài",
    code: 13270,
    division_type: "xã",
    codename: "xa_vu_doai",
    district_code: 344,
  },
  {
    name: "Xã Vũ Tiến",
    code: 13273,
    division_type: "xã",
    codename: "xa_vu_tien",
    district_code: 344,
  },
  {
    name: "Xã Vũ Vân",
    code: 13276,
    division_type: "xã",
    codename: "xa_vu_van",
    district_code: 344,
  },
  {
    name: "Xã Duy Nhất",
    code: 13279,
    division_type: "xã",
    codename: "xa_duy_nhat",
    district_code: 344,
  },
  {
    name: "Xã Hồng Phong",
    code: 13282,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 344,
  },
  {
    name: "Phường Quang Trung",
    code: 13285,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 347,
  },
  {
    name: "Phường Lương Khánh Thiện",
    code: 13288,
    division_type: "phường",
    codename: "phuong_luong_khanh_thien",
    district_code: 347,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 13291,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 347,
  },
  {
    name: "Phường Minh Khai",
    code: 13294,
    division_type: "phường",
    codename: "phuong_minh_khai",
    district_code: 347,
  },
  {
    name: "Phường Hai Bà Trưng",
    code: 13297,
    division_type: "phường",
    codename: "phuong_hai_ba_trung",
    district_code: 347,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 13300,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 347,
  },
  {
    name: "Phường Lam Hạ",
    code: 13303,
    division_type: "phường",
    codename: "phuong_lam_ha",
    district_code: 347,
  },
  {
    name: "Xã Phù Vân",
    code: 13306,
    division_type: "xã",
    codename: "xa_phu_van",
    district_code: 347,
  },
  {
    name: "Phường Liêm Chính",
    code: 13309,
    division_type: "phường",
    codename: "phuong_liem_chinh",
    district_code: 347,
  },
  {
    name: "Xã Liêm Chung",
    code: 13312,
    division_type: "xã",
    codename: "xa_liem_chung",
    district_code: 347,
  },
  {
    name: "Phường Thanh Châu",
    code: 13315,
    division_type: "phường",
    codename: "phuong_thanh_chau",
    district_code: 347,
  },
  {
    name: "Phường Châu Sơn",
    code: 13318,
    division_type: "phường",
    codename: "phuong_chau_son",
    district_code: 347,
  },
  {
    name: "Xã Tiên Tân",
    code: 13366,
    division_type: "xã",
    codename: "xa_tien_tan",
    district_code: 347,
  },
  {
    name: "Xã Tiên Hiệp",
    code: 13372,
    division_type: "xã",
    codename: "xa_tien_hiep",
    district_code: 347,
  },
  {
    name: "Xã Tiên Hải",
    code: 13381,
    division_type: "xã",
    codename: "xa_tien_hai",
    district_code: 347,
  },
  {
    name: "Xã Kim Bình",
    code: 13426,
    division_type: "xã",
    codename: "xa_kim_binh",
    district_code: 347,
  },
  {
    name: "Xã Liêm Tuyền",
    code: 13444,
    division_type: "xã",
    codename: "xa_liem_tuyen",
    district_code: 347,
  },
  {
    name: "Xã Liêm Tiết",
    code: 13447,
    division_type: "xã",
    codename: "xa_liem_tiet",
    district_code: 347,
  },
  {
    name: "Phường Thanh Tuyền",
    code: 13459,
    division_type: "phường",
    codename: "phuong_thanh_tuyen",
    district_code: 347,
  },
  {
    name: "Xã Đinh Xá",
    code: 13507,
    division_type: "xã",
    codename: "xa_dinh_xa",
    district_code: 347,
  },
  {
    name: "Xã Trịnh Xá",
    code: 13513,
    division_type: "xã",
    codename: "xa_trinh_xa",
    district_code: 347,
  },
  {
    name: "Phường Đồng Văn",
    code: 13321,
    division_type: "phường",
    codename: "phuong_dong_van",
    district_code: 349,
  },
  {
    name: "Phường Hòa Mạc",
    code: 13324,
    division_type: "phường",
    codename: "phuong_hoa_mac",
    district_code: 349,
  },
  {
    name: "Xã Mộc Bắc",
    code: 13327,
    division_type: "xã",
    codename: "xa_moc_bac",
    district_code: 349,
  },
  {
    name: "Phường Châu Giang",
    code: 13330,
    division_type: "phường",
    codename: "phuong_chau_giang",
    district_code: 349,
  },
  {
    name: "Phường Bạch Thượng",
    code: 13333,
    division_type: "phường",
    codename: "phuong_bach_thuong",
    district_code: 349,
  },
  {
    name: "Phường Duy Minh",
    code: 13336,
    division_type: "phường",
    codename: "phuong_duy_minh",
    district_code: 349,
  },
  {
    name: "Xã Mộc Nam",
    code: 13339,
    division_type: "xã",
    codename: "xa_moc_nam",
    district_code: 349,
  },
  {
    name: "Phường Duy Hải",
    code: 13342,
    division_type: "phường",
    codename: "phuong_duy_hai",
    district_code: 349,
  },
  {
    name: "Xã Chuyên Ngoại",
    code: 13345,
    division_type: "xã",
    codename: "xa_chuyen_ngoai",
    district_code: 349,
  },
  {
    name: "Phường Yên Bắc",
    code: 13348,
    division_type: "phường",
    codename: "phuong_yen_bac",
    district_code: 349,
  },
  {
    name: "Xã Trác Văn",
    code: 13351,
    division_type: "xã",
    codename: "xa_trac_van",
    district_code: 349,
  },
  {
    name: "Phường Tiên Nội",
    code: 13354,
    division_type: "phường",
    codename: "phuong_tien_noi",
    district_code: 349,
  },
  {
    name: "Phường Hoàng Đông",
    code: 13357,
    division_type: "phường",
    codename: "phuong_hoang_dong",
    district_code: 349,
  },
  {
    name: "Xã Yên Nam",
    code: 13360,
    division_type: "xã",
    codename: "xa_yen_nam",
    district_code: 349,
  },
  {
    name: "Xã Tiên Ngoại",
    code: 13363,
    division_type: "xã",
    codename: "xa_tien_ngoai",
    district_code: 349,
  },
  {
    name: "Xã Tiên Sơn",
    code: 13369,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 349,
  },
  {
    name: "Thị trấn Quế",
    code: 13384,
    division_type: "thị trấn",
    codename: "thi_tran_que",
    district_code: 350,
  },
  {
    name: "Xã Nguyễn Úy",
    code: 13387,
    division_type: "xã",
    codename: "xa_nguyen_uy",
    district_code: 350,
  },
  {
    name: "Xã Đại Cương",
    code: 13390,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 350,
  },
  {
    name: "Xã Lê Hồ",
    code: 13393,
    division_type: "xã",
    codename: "xa_le_ho",
    district_code: 350,
  },
  {
    name: "Xã Tượng Lĩnh",
    code: 13396,
    division_type: "xã",
    codename: "xa_tuong_linh",
    district_code: 350,
  },
  {
    name: "Xã Nhật Tựu",
    code: 13399,
    division_type: "xã",
    codename: "xa_nhat_tuu",
    district_code: 350,
  },
  {
    name: "Xã Nhật Tân",
    code: 13402,
    division_type: "xã",
    codename: "xa_nhat_tan",
    district_code: 350,
  },
  {
    name: "Xã Đồng Hóa",
    code: 13405,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 350,
  },
  {
    name: "Xã Hoàng Tây",
    code: 13408,
    division_type: "xã",
    codename: "xa_hoang_tay",
    district_code: 350,
  },
  {
    name: "Xã Tân Sơn",
    code: 13411,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 350,
  },
  {
    name: "Xã Thụy Lôi",
    code: 13414,
    division_type: "xã",
    codename: "xa_thuy_loi",
    district_code: 350,
  },
  {
    name: "Xã Văn Xá",
    code: 13417,
    division_type: "xã",
    codename: "xa_van_xa",
    district_code: 350,
  },
  {
    name: "Xã Khả Phong",
    code: 13420,
    division_type: "xã",
    codename: "xa_kha_phong",
    district_code: 350,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 13423,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 350,
  },
  {
    name: "Thị trấn Ba Sao",
    code: 13429,
    division_type: "thị trấn",
    codename: "thi_tran_ba_sao",
    district_code: 350,
  },
  {
    name: "Xã Liên Sơn",
    code: 13432,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 350,
  },
  {
    name: "Xã Thi Sơn",
    code: 13435,
    division_type: "xã",
    codename: "xa_thi_son",
    district_code: 350,
  },
  {
    name: "Xã Thanh Sơn",
    code: 13438,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 350,
  },
  {
    name: "Thị trấn Kiện Khê",
    code: 13441,
    division_type: "thị trấn",
    codename: "thi_tran_kien_khe",
    district_code: 351,
  },
  {
    name: "Xã Liêm Phong",
    code: 13450,
    division_type: "xã",
    codename: "xa_liem_phong",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hà",
    code: 13453,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 351,
  },
  {
    name: "Xã Liêm Cần",
    code: 13456,
    division_type: "xã",
    codename: "xa_liem_can",
    district_code: 351,
  },
  {
    name: "Xã Liêm Thuận",
    code: 13465,
    division_type: "xã",
    codename: "xa_liem_thuan",
    district_code: 351,
  },
  {
    name: "Xã Thanh Thủy",
    code: 13468,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 351,
  },
  {
    name: "Xã Thanh Phong",
    code: 13471,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 351,
  },
  {
    name: "Thị trấn Tân Thanh",
    code: 13474,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 351,
  },
  {
    name: "Xã Thanh Tân",
    code: 13477,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 351,
  },
  {
    name: "Xã Liêm Túc",
    code: 13480,
    division_type: "xã",
    codename: "xa_liem_tuc",
    district_code: 351,
  },
  {
    name: "Xã Liêm Sơn",
    code: 13483,
    division_type: "xã",
    codename: "xa_liem_son",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hương",
    code: 13486,
    division_type: "xã",
    codename: "xa_thanh_huong",
    district_code: 351,
  },
  {
    name: "Xã Thanh Nghị",
    code: 13489,
    division_type: "xã",
    codename: "xa_thanh_nghi",
    district_code: 351,
  },
  {
    name: "Xã Thanh Tâm",
    code: 13492,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 351,
  },
  {
    name: "Xã Thanh Nguyên",
    code: 13495,
    division_type: "xã",
    codename: "xa_thanh_nguyen",
    district_code: 351,
  },
  {
    name: "Xã Thanh Hải",
    code: 13498,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 351,
  },
  {
    name: "Thị trấn Bình Mỹ",
    code: 13501,
    division_type: "thị trấn",
    codename: "thi_tran_binh_my",
    district_code: 352,
  },
  {
    name: "Xã Bình Nghĩa",
    code: 13504,
    division_type: "xã",
    codename: "xa_binh_nghia",
    district_code: 352,
  },
  {
    name: "Xã Tràng An",
    code: 13510,
    division_type: "xã",
    codename: "xa_trang_an",
    district_code: 352,
  },
  {
    name: "Xã Đồng Du",
    code: 13516,
    division_type: "xã",
    codename: "xa_dong_du",
    district_code: 352,
  },
  {
    name: "Xã Ngọc Lũ",
    code: 13519,
    division_type: "xã",
    codename: "xa_ngoc_lu",
    district_code: 352,
  },
  {
    name: "Xã Hưng Công",
    code: 13522,
    division_type: "xã",
    codename: "xa_hung_cong",
    district_code: 352,
  },
  {
    name: "Xã Đồn Xá",
    code: 13525,
    division_type: "xã",
    codename: "xa_don_xa",
    district_code: 352,
  },
  {
    name: "Xã An Ninh",
    code: 13528,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 352,
  },
  {
    name: "Xã Bồ Đề",
    code: 13531,
    division_type: "xã",
    codename: "xa_bo_de",
    district_code: 352,
  },
  {
    name: "Xã Bối Cầu",
    code: 13534,
    division_type: "xã",
    codename: "xa_boi_cau",
    district_code: 352,
  },
  {
    name: "Xã An Nội",
    code: 13540,
    division_type: "xã",
    codename: "xa_an_noi",
    district_code: 352,
  },
  {
    name: "Xã Vũ Bản",
    code: 13543,
    division_type: "xã",
    codename: "xa_vu_ban",
    district_code: 352,
  },
  {
    name: "Xã Trung Lương",
    code: 13546,
    division_type: "xã",
    codename: "xa_trung_luong",
    district_code: 352,
  },
  {
    name: "Xã An Đổ",
    code: 13552,
    division_type: "xã",
    codename: "xa_an_do",
    district_code: 352,
  },
  {
    name: "Xã La Sơn",
    code: 13555,
    division_type: "xã",
    codename: "xa_la_son",
    district_code: 352,
  },
  {
    name: "Xã Tiêu Động",
    code: 13558,
    division_type: "xã",
    codename: "xa_tieu_dong",
    district_code: 352,
  },
  {
    name: "Xã An Lão",
    code: 13561,
    division_type: "xã",
    codename: "xa_an_lao",
    district_code: 352,
  },
  {
    name: "Xã Hợp Lý",
    code: 13567,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 353,
  },
  {
    name: "Xã Nguyên Lý",
    code: 13570,
    division_type: "xã",
    codename: "xa_nguyen_ly",
    district_code: 353,
  },
  {
    name: "Xã Chính Lý",
    code: 13573,
    division_type: "xã",
    codename: "xa_chinh_ly",
    district_code: 353,
  },
  {
    name: "Xã Chân Lý",
    code: 13576,
    division_type: "xã",
    codename: "xa_chan_ly",
    district_code: 353,
  },
  {
    name: "Xã Đạo Lý",
    code: 13579,
    division_type: "xã",
    codename: "xa_dao_ly",
    district_code: 353,
  },
  {
    name: "Xã Công Lý",
    code: 13582,
    division_type: "xã",
    codename: "xa_cong_ly",
    district_code: 353,
  },
  {
    name: "Xã Văn Lý",
    code: 13585,
    division_type: "xã",
    codename: "xa_van_ly",
    district_code: 353,
  },
  {
    name: "Xã Bắc Lý",
    code: 13588,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 353,
  },
  {
    name: "Xã Đức Lý",
    code: 13591,
    division_type: "xã",
    codename: "xa_duc_ly",
    district_code: 353,
  },
  {
    name: "Xã Trần Hưng Đạo",
    code: 13594,
    division_type: "xã",
    codename: "xa_tran_hung_dao",
    district_code: 353,
  },
  {
    name: "Thị trấn Vĩnh Trụ",
    code: 13597,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_tru",
    district_code: 353,
  },
  {
    name: "Xã Nhân Thịnh",
    code: 13600,
    division_type: "xã",
    codename: "xa_nhan_thinh",
    district_code: 353,
  },
  {
    name: "Xã Nhân Khang",
    code: 13606,
    division_type: "xã",
    codename: "xa_nhan_khang",
    district_code: 353,
  },
  {
    name: "Xã Nhân Mỹ",
    code: 13609,
    division_type: "xã",
    codename: "xa_nhan_my",
    district_code: 353,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 13612,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 353,
  },
  {
    name: "Xã Nhân Chính",
    code: 13615,
    division_type: "xã",
    codename: "xa_nhan_chinh",
    district_code: 353,
  },
  {
    name: "Xã Nhân Bình",
    code: 13618,
    division_type: "xã",
    codename: "xa_nhan_binh",
    district_code: 353,
  },
  {
    name: "Xã Phú Phúc",
    code: 13621,
    division_type: "xã",
    codename: "xa_phu_phuc",
    district_code: 353,
  },
  {
    name: "Xã Xuân Khê",
    code: 13624,
    division_type: "xã",
    codename: "xa_xuan_khe",
    district_code: 353,
  },
  {
    name: "Xã Tiến Thắng",
    code: 13627,
    division_type: "xã",
    codename: "xa_tien_thang",
    district_code: 353,
  },
  {
    name: "Xã Hòa Hậu",
    code: 13630,
    division_type: "xã",
    codename: "xa_hoa_hau",
    district_code: 353,
  },
  {
    name: "Phường Hạ Long",
    code: 13633,
    division_type: "phường",
    codename: "phuong_ha_long",
    district_code: 356,
  },
  {
    name: "Phường Trần Tế Xương",
    code: 13636,
    division_type: "phường",
    codename: "phuong_tran_te_xuong",
    district_code: 356,
  },
  {
    name: "Phường Vị Hoàng",
    code: 13639,
    division_type: "phường",
    codename: "phuong_vi_hoang",
    district_code: 356,
  },
  {
    name: "Phường Vị Xuyên",
    code: 13642,
    division_type: "phường",
    codename: "phuong_vi_xuyen",
    district_code: 356,
  },
  {
    name: "Phường Quang Trung",
    code: 13645,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 356,
  },
  {
    name: "Phường Cửa Bắc",
    code: 13648,
    division_type: "phường",
    codename: "phuong_cua_bac",
    district_code: 356,
  },
  {
    name: "Phường Nguyễn Du",
    code: 13651,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 356,
  },
  {
    name: "Phường Bà Triệu",
    code: 13654,
    division_type: "phường",
    codename: "phuong_ba_trieu",
    district_code: 356,
  },
  {
    name: "Phường Trường Thi",
    code: 13657,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 356,
  },
  {
    name: "Phường Phan Đình Phùng",
    code: 13660,
    division_type: "phường",
    codename: "phuong_phan_dinh_phung",
    district_code: 356,
  },
  {
    name: "Phường Ngô Quyền",
    code: 13663,
    division_type: "phường",
    codename: "phuong_ngo_quyen",
    district_code: 356,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 13666,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 356,
  },
  {
    name: "Phường Trần Đăng Ninh",
    code: 13669,
    division_type: "phường",
    codename: "phuong_tran_dang_ninh",
    district_code: 356,
  },
  {
    name: "Phường Năng Tĩnh",
    code: 13672,
    division_type: "phường",
    codename: "phuong_nang_tinh",
    district_code: 356,
  },
  {
    name: "Phường Văn Miếu",
    code: 13675,
    division_type: "phường",
    codename: "phuong_van_mieu",
    district_code: 356,
  },
  {
    name: "Phường Trần Quang Khải",
    code: 13678,
    division_type: "phường",
    codename: "phuong_tran_quang_khai",
    district_code: 356,
  },
  {
    name: "Phường Thống Nhất",
    code: 13681,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 356,
  },
  {
    name: "Phường Lộc Hạ",
    code: 13684,
    division_type: "phường",
    codename: "phuong_loc_ha",
    district_code: 356,
  },
  {
    name: "Phường Lộc Vượng",
    code: 13687,
    division_type: "phường",
    codename: "phuong_loc_vuong",
    district_code: 356,
  },
  {
    name: "Phường Cửa Nam",
    code: 13690,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 356,
  },
  {
    name: "Phường Lộc Hòa",
    code: 13693,
    division_type: "phường",
    codename: "phuong_loc_hoa",
    district_code: 356,
  },
  {
    name: "Xã Nam Phong",
    code: 13696,
    division_type: "xã",
    codename: "xa_nam_phong",
    district_code: 356,
  },
  {
    name: "Phường Mỹ Xá",
    code: 13699,
    division_type: "phường",
    codename: "phuong_my_xa",
    district_code: 356,
  },
  {
    name: "Xã Lộc An",
    code: 13702,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 356,
  },
  {
    name: "Xã Nam Vân",
    code: 13705,
    division_type: "xã",
    codename: "xa_nam_van",
    district_code: 356,
  },
  {
    name: "Thị trấn Mỹ Lộc",
    code: 13708,
    division_type: "thị trấn",
    codename: "thi_tran_my_loc",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Hà",
    code: 13711,
    division_type: "xã",
    codename: "xa_my_ha",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Tiến",
    code: 13714,
    division_type: "xã",
    codename: "xa_my_tien",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thắng",
    code: 13717,
    division_type: "xã",
    codename: "xa_my_thang",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Trung",
    code: 13720,
    division_type: "xã",
    codename: "xa_my_trung",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Tân",
    code: 13723,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Phúc",
    code: 13726,
    division_type: "xã",
    codename: "xa_my_phuc",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 13729,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 13732,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thịnh",
    code: 13735,
    division_type: "xã",
    codename: "xa_my_thinh",
    district_code: 358,
  },
  {
    name: "Xã Mỹ Thành",
    code: 13738,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 358,
  },
  {
    name: "Thị trấn Gôi",
    code: 13741,
    division_type: "thị trấn",
    codename: "thi_tran_goi",
    district_code: 359,
  },
  {
    name: "Xã Minh Thuận",
    code: 13744,
    division_type: "xã",
    codename: "xa_minh_thuan",
    district_code: 359,
  },
  {
    name: "Xã Hiển Khánh",
    code: 13747,
    division_type: "xã",
    codename: "xa_hien_khanh",
    district_code: 359,
  },
  {
    name: "Xã Tân Khánh",
    code: 13750,
    division_type: "xã",
    codename: "xa_tan_khanh",
    district_code: 359,
  },
  {
    name: "Xã Hợp Hưng",
    code: 13753,
    division_type: "xã",
    codename: "xa_hop_hung",
    district_code: 359,
  },
  {
    name: "Xã Đại An",
    code: 13756,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 359,
  },
  {
    name: "Xã Tân Thành",
    code: 13759,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 359,
  },
  {
    name: "Xã Cộng Hòa",
    code: 13762,
    division_type: "xã",
    codename: "xa_cong_hoa",
    district_code: 359,
  },
  {
    name: "Xã Trung Thành",
    code: 13765,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 359,
  },
  {
    name: "Xã Quang Trung",
    code: 13768,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 359,
  },
  {
    name: "Xã Minh Tân",
    code: 13771,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 359,
  },
  {
    name: "Xã Liên Bảo",
    code: 13774,
    division_type: "xã",
    codename: "xa_lien_bao",
    district_code: 359,
  },
  {
    name: "Xã Thành Lợi",
    code: 13777,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 359,
  },
  {
    name: "Xã Kim Thái",
    code: 13780,
    division_type: "xã",
    codename: "xa_kim_thai",
    district_code: 359,
  },
  {
    name: "Xã Liên Minh",
    code: 13783,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 359,
  },
  {
    name: "Xã Đại Thắng",
    code: 13786,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 359,
  },
  {
    name: "Xã Tam Thanh",
    code: 13789,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 359,
  },
  {
    name: "Xã Vĩnh Hào",
    code: 13792,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 359,
  },
  {
    name: "Thị trấn Lâm",
    code: 13795,
    division_type: "thị trấn",
    codename: "thi_tran_lam",
    district_code: 360,
  },
  {
    name: "Xã Yên Trung",
    code: 13798,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 360,
  },
  {
    name: "Xã Yên Thành",
    code: 13801,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 360,
  },
  {
    name: "Xã Yên Tân",
    code: 13804,
    division_type: "xã",
    codename: "xa_yen_tan",
    district_code: 360,
  },
  {
    name: "Xã Yên Lợi",
    code: 13807,
    division_type: "xã",
    codename: "xa_yen_loi",
    district_code: 360,
  },
  {
    name: "Xã Yên Thọ",
    code: 13810,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 360,
  },
  {
    name: "Xã Yên Nghĩa",
    code: 13813,
    division_type: "xã",
    codename: "xa_yen_nghia",
    district_code: 360,
  },
  {
    name: "Xã Yên Minh",
    code: 13816,
    division_type: "xã",
    codename: "xa_yen_minh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phương",
    code: 13819,
    division_type: "xã",
    codename: "xa_yen_phuong",
    district_code: 360,
  },
  {
    name: "Xã Yên Chính",
    code: 13822,
    division_type: "xã",
    codename: "xa_yen_chinh",
    district_code: 360,
  },
  {
    name: "Xã Yên Bình",
    code: 13825,
    division_type: "xã",
    codename: "xa_yen_binh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phú",
    code: 13828,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 360,
  },
  {
    name: "Xã Yên Mỹ",
    code: 13831,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 360,
  },
  {
    name: "Xã Yên Dương",
    code: 13834,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 360,
  },
  {
    name: "Xã Yên Hưng",
    code: 13840,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 360,
  },
  {
    name: "Xã Yên Khánh",
    code: 13843,
    division_type: "xã",
    codename: "xa_yen_khanh",
    district_code: 360,
  },
  {
    name: "Xã Yên Phong",
    code: 13846,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 360,
  },
  {
    name: "Xã Yên Ninh",
    code: 13849,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 360,
  },
  {
    name: "Xã Yên Lương",
    code: 13852,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 360,
  },
  {
    name: "Xã Yên Hồng",
    code: 13855,
    division_type: "xã",
    codename: "xa_yen_hong",
    district_code: 360,
  },
  {
    name: "Xã Yên Quang",
    code: 13858,
    division_type: "xã",
    codename: "xa_yen_quang",
    district_code: 360,
  },
  {
    name: "Xã Yên Tiến",
    code: 13861,
    division_type: "xã",
    codename: "xa_yen_tien",
    district_code: 360,
  },
  {
    name: "Xã Yên Thắng",
    code: 13864,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 360,
  },
  {
    name: "Xã Yên Phúc",
    code: 13867,
    division_type: "xã",
    codename: "xa_yen_phuc",
    district_code: 360,
  },
  {
    name: "Xã Yên Cường",
    code: 13870,
    division_type: "xã",
    codename: "xa_yen_cuong",
    district_code: 360,
  },
  {
    name: "Xã Yên Lộc",
    code: 13873,
    division_type: "xã",
    codename: "xa_yen_loc",
    district_code: 360,
  },
  {
    name: "Xã Yên Bằng",
    code: 13876,
    division_type: "xã",
    codename: "xa_yen_bang",
    district_code: 360,
  },
  {
    name: "Xã Yên Đồng",
    code: 13879,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 360,
  },
  {
    name: "Xã Yên Khang",
    code: 13882,
    division_type: "xã",
    codename: "xa_yen_khang",
    district_code: 360,
  },
  {
    name: "Xã Yên Nhân",
    code: 13885,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 360,
  },
  {
    name: "Xã Yên Trị",
    code: 13888,
    division_type: "xã",
    codename: "xa_yen_tri",
    district_code: 360,
  },
  {
    name: "Thị trấn Liễu Đề",
    code: 13891,
    division_type: "thị trấn",
    codename: "thi_tran_lieu_de",
    district_code: 361,
  },
  {
    name: "Thị trấn Rạng Đông",
    code: 13894,
    division_type: "thị trấn",
    codename: "thi_tran_rang_dong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Đồng",
    code: 13897,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thịnh",
    code: 13900,
    division_type: "xã",
    codename: "xa_nghia_thinh",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Minh",
    code: 13903,
    division_type: "xã",
    codename: "xa_nghia_minh",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thái",
    code: 13906,
    division_type: "xã",
    codename: "xa_nghia_thai",
    district_code: 361,
  },
  {
    name: "Xã Hoàng Nam",
    code: 13909,
    division_type: "xã",
    codename: "xa_hoang_nam",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Châu",
    code: 13912,
    division_type: "xã",
    codename: "xa_nghia_chau",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 13915,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 13918,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lạc",
    code: 13921,
    division_type: "xã",
    codename: "xa_nghia_lac",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hồng",
    code: 13924,
    division_type: "xã",
    codename: "xa_nghia_hong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Phong",
    code: 13927,
    division_type: "xã",
    codename: "xa_nghia_phong",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 13930,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 13933,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 361,
  },
  {
    name: "Thị trấn Quỹ Nhất",
    code: 13936,
    division_type: "thị trấn",
    codename: "thi_tran_quy_nhat",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Tân",
    code: 13939,
    division_type: "xã",
    codename: "xa_nghia_tan",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hùng",
    code: 13942,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 13945,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 13948,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 361,
  },
  {
    name: "Xã Phúc Thắng",
    code: 13951,
    division_type: "xã",
    codename: "xa_phuc_thang",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 13954,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 361,
  },
  {
    name: "Xã Nghĩa Hải",
    code: 13957,
    division_type: "xã",
    codename: "xa_nghia_hai",
    district_code: 361,
  },
  {
    name: "Xã Nam Điền",
    code: 13963,
    division_type: "xã",
    codename: "xa_nam_dien",
    district_code: 361,
  },
  {
    name: "Thị trấn Nam Giang",
    code: 13966,
    division_type: "thị trấn",
    codename: "thi_tran_nam_giang",
    district_code: 362,
  },
  {
    name: "Xã Nam Mỹ",
    code: 13969,
    division_type: "xã",
    codename: "xa_nam_my",
    district_code: 362,
  },
  {
    name: "Xã Điền Xá",
    code: 13972,
    division_type: "xã",
    codename: "xa_dien_xa",
    district_code: 362,
  },
  {
    name: "Xã Nghĩa An",
    code: 13975,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 362,
  },
  {
    name: "Xã Nam Thắng",
    code: 13978,
    division_type: "xã",
    codename: "xa_nam_thang",
    district_code: 362,
  },
  {
    name: "Xã Nam Toàn",
    code: 13981,
    division_type: "xã",
    codename: "xa_nam_toan",
    district_code: 362,
  },
  {
    name: "Xã Hồng Quang",
    code: 13984,
    division_type: "xã",
    codename: "xa_hong_quang",
    district_code: 362,
  },
  {
    name: "Xã Tân Thịnh",
    code: 13987,
    division_type: "xã",
    codename: "xa_tan_thinh",
    district_code: 362,
  },
  {
    name: "Xã Nam Cường",
    code: 13990,
    division_type: "xã",
    codename: "xa_nam_cuong",
    district_code: 362,
  },
  {
    name: "Xã Nam Hồng",
    code: 13993,
    division_type: "xã",
    codename: "xa_nam_hong",
    district_code: 362,
  },
  {
    name: "Xã Nam Hùng",
    code: 13996,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 362,
  },
  {
    name: "Xã Nam Hoa",
    code: 13999,
    division_type: "xã",
    codename: "xa_nam_hoa",
    district_code: 362,
  },
  {
    name: "Xã Nam Dương",
    code: 14002,
    division_type: "xã",
    codename: "xa_nam_duong",
    district_code: 362,
  },
  {
    name: "Xã Nam Thanh",
    code: 14005,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 362,
  },
  {
    name: "Xã Nam Lợi",
    code: 14008,
    division_type: "xã",
    codename: "xa_nam_loi",
    district_code: 362,
  },
  {
    name: "Xã Bình Minh",
    code: 14011,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 362,
  },
  {
    name: "Xã Đồng Sơn",
    code: 14014,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 362,
  },
  {
    name: "Xã Nam Tiến",
    code: 14017,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 362,
  },
  {
    name: "Xã Nam Hải",
    code: 14020,
    division_type: "xã",
    codename: "xa_nam_hai",
    district_code: 362,
  },
  {
    name: "Xã Nam Thái",
    code: 14023,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 362,
  },
  {
    name: "Thị trấn Cổ Lễ",
    code: 14026,
    division_type: "thị trấn",
    codename: "thi_tran_co_le",
    district_code: 363,
  },
  {
    name: "Xã Phương Định",
    code: 14029,
    division_type: "xã",
    codename: "xa_phuong_dinh",
    district_code: 363,
  },
  {
    name: "Xã Trực Chính",
    code: 14032,
    division_type: "xã",
    codename: "xa_truc_chinh",
    district_code: 363,
  },
  {
    name: "Xã Trung Đông",
    code: 14035,
    division_type: "xã",
    codename: "xa_trung_dong",
    district_code: 363,
  },
  {
    name: "Xã Liêm Hải",
    code: 14038,
    division_type: "xã",
    codename: "xa_liem_hai",
    district_code: 363,
  },
  {
    name: "Xã Trực Tuấn",
    code: 14041,
    division_type: "xã",
    codename: "xa_truc_tuan",
    district_code: 363,
  },
  {
    name: "Xã Việt Hùng",
    code: 14044,
    division_type: "xã",
    codename: "xa_viet_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Đạo",
    code: 14047,
    division_type: "xã",
    codename: "xa_truc_dao",
    district_code: 363,
  },
  {
    name: "Xã Trực Hưng",
    code: 14050,
    division_type: "xã",
    codename: "xa_truc_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Nội",
    code: 14053,
    division_type: "xã",
    codename: "xa_truc_noi",
    district_code: 363,
  },
  {
    name: "Thị trấn Cát Thành",
    code: 14056,
    division_type: "thị trấn",
    codename: "thi_tran_cat_thanh",
    district_code: 363,
  },
  {
    name: "Xã Trực Thanh",
    code: 14059,
    division_type: "xã",
    codename: "xa_truc_thanh",
    district_code: 363,
  },
  {
    name: "Xã Trực Khang",
    code: 14062,
    division_type: "xã",
    codename: "xa_truc_khang",
    district_code: 363,
  },
  {
    name: "Xã Trực Thuận",
    code: 14065,
    division_type: "xã",
    codename: "xa_truc_thuan",
    district_code: 363,
  },
  {
    name: "Xã Trực Mỹ",
    code: 14068,
    division_type: "xã",
    codename: "xa_truc_my",
    district_code: 363,
  },
  {
    name: "Xã Trực Đại",
    code: 14071,
    division_type: "xã",
    codename: "xa_truc_dai",
    district_code: 363,
  },
  {
    name: "Xã Trực Cường",
    code: 14074,
    division_type: "xã",
    codename: "xa_truc_cuong",
    district_code: 363,
  },
  {
    name: "Thị trấn Ninh Cường",
    code: 14077,
    division_type: "thị trấn",
    codename: "thi_tran_ninh_cuong",
    district_code: 363,
  },
  {
    name: "Xã Trực Thái",
    code: 14080,
    division_type: "xã",
    codename: "xa_truc_thai",
    district_code: 363,
  },
  {
    name: "Xã Trực Hùng",
    code: 14083,
    division_type: "xã",
    codename: "xa_truc_hung",
    district_code: 363,
  },
  {
    name: "Xã Trực Thắng",
    code: 14086,
    division_type: "xã",
    codename: "xa_truc_thang",
    district_code: 363,
  },
  {
    name: "Thị trấn Xuân Trường",
    code: 14089,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_truong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Châu",
    code: 14092,
    division_type: "xã",
    codename: "xa_xuan_chau",
    district_code: 364,
  },
  {
    name: "Xã Xuân Hồng",
    code: 14095,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thành",
    code: 14098,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thượng",
    code: 14101,
    division_type: "xã",
    codename: "xa_xuan_thuong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phong",
    code: 14104,
    division_type: "xã",
    codename: "xa_xuan_phong",
    district_code: 364,
  },
  {
    name: "Xã Xuân Đài",
    code: 14107,
    division_type: "xã",
    codename: "xa_xuan_dai",
    district_code: 364,
  },
  {
    name: "Xã Xuân Tân",
    code: 14110,
    division_type: "xã",
    codename: "xa_xuan_tan",
    district_code: 364,
  },
  {
    name: "Xã Xuân Thủy",
    code: 14113,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 364,
  },
  {
    name: "Xã Xuân Ngọc",
    code: 14116,
    division_type: "xã",
    codename: "xa_xuan_ngoc",
    district_code: 364,
  },
  {
    name: "Xã Xuân Bắc",
    code: 14119,
    division_type: "xã",
    codename: "xa_xuan_bac",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phương",
    code: 14122,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 364,
  },
  {
    name: "Xã Thọ Nghiệp",
    code: 14125,
    division_type: "xã",
    codename: "xa_tho_nghiep",
    district_code: 364,
  },
  {
    name: "Xã Xuân Phú",
    code: 14128,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 364,
  },
  {
    name: "Xã Xuân Trung",
    code: 14131,
    division_type: "xã",
    codename: "xa_xuan_trung",
    district_code: 364,
  },
  {
    name: "Xã Xuân Vinh",
    code: 14134,
    division_type: "xã",
    codename: "xa_xuan_vinh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Kiên",
    code: 14137,
    division_type: "xã",
    codename: "xa_xuan_kien",
    district_code: 364,
  },
  {
    name: "Xã Xuân Tiến",
    code: 14140,
    division_type: "xã",
    codename: "xa_xuan_tien",
    district_code: 364,
  },
  {
    name: "Xã Xuân Ninh",
    code: 14143,
    division_type: "xã",
    codename: "xa_xuan_ninh",
    district_code: 364,
  },
  {
    name: "Xã Xuân Hòa",
    code: 14146,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 364,
  },
  {
    name: "Thị trấn Ngô Đồng",
    code: 14149,
    division_type: "thị trấn",
    codename: "thi_tran_ngo_dong",
    district_code: 365,
  },
  {
    name: "Thị trấn Quất Lâm",
    code: 14152,
    division_type: "thị trấn",
    codename: "thi_tran_quat_lam",
    district_code: 365,
  },
  {
    name: "Xã Giao Hương",
    code: 14155,
    division_type: "xã",
    codename: "xa_giao_huong",
    district_code: 365,
  },
  {
    name: "Xã Hồng Thuận",
    code: 14158,
    division_type: "xã",
    codename: "xa_hong_thuan",
    district_code: 365,
  },
  {
    name: "Xã Giao Thiện",
    code: 14161,
    division_type: "xã",
    codename: "xa_giao_thien",
    district_code: 365,
  },
  {
    name: "Xã Giao Thanh",
    code: 14164,
    division_type: "xã",
    codename: "xa_giao_thanh",
    district_code: 365,
  },
  {
    name: "Xã Hoành Sơn",
    code: 14167,
    division_type: "xã",
    codename: "xa_hoanh_son",
    district_code: 365,
  },
  {
    name: "Xã Bình Hòa",
    code: 14170,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 365,
  },
  {
    name: "Xã Giao Tiến",
    code: 14173,
    division_type: "xã",
    codename: "xa_giao_tien",
    district_code: 365,
  },
  {
    name: "Xã Giao Hà",
    code: 14176,
    division_type: "xã",
    codename: "xa_giao_ha",
    district_code: 365,
  },
  {
    name: "Xã Giao Nhân",
    code: 14179,
    division_type: "xã",
    codename: "xa_giao_nhan",
    district_code: 365,
  },
  {
    name: "Xã Giao An",
    code: 14182,
    division_type: "xã",
    codename: "xa_giao_an",
    district_code: 365,
  },
  {
    name: "Xã Giao Lạc",
    code: 14185,
    division_type: "xã",
    codename: "xa_giao_lac",
    district_code: 365,
  },
  {
    name: "Xã Giao Châu",
    code: 14188,
    division_type: "xã",
    codename: "xa_giao_chau",
    district_code: 365,
  },
  {
    name: "Xã Giao Tân",
    code: 14191,
    division_type: "xã",
    codename: "xa_giao_tan",
    district_code: 365,
  },
  {
    name: "Xã Giao Yến",
    code: 14194,
    division_type: "xã",
    codename: "xa_giao_yen",
    district_code: 365,
  },
  {
    name: "Xã Giao Xuân",
    code: 14197,
    division_type: "xã",
    codename: "xa_giao_xuan",
    district_code: 365,
  },
  {
    name: "Xã Giao Thịnh",
    code: 14200,
    division_type: "xã",
    codename: "xa_giao_thinh",
    district_code: 365,
  },
  {
    name: "Xã Giao Hải",
    code: 14203,
    division_type: "xã",
    codename: "xa_giao_hai",
    district_code: 365,
  },
  {
    name: "Xã Bạch Long",
    code: 14206,
    division_type: "xã",
    codename: "xa_bach_long",
    district_code: 365,
  },
  {
    name: "Xã Giao Long",
    code: 14209,
    division_type: "xã",
    codename: "xa_giao_long",
    district_code: 365,
  },
  {
    name: "Xã Giao Phong",
    code: 14212,
    division_type: "xã",
    codename: "xa_giao_phong",
    district_code: 365,
  },
  {
    name: "Thị trấn Yên Định",
    code: 14215,
    division_type: "thị trấn",
    codename: "thi_tran_yen_dinh",
    district_code: 366,
  },
  {
    name: "Thị trấn Cồn",
    code: 14218,
    division_type: "thị trấn",
    codename: "thi_tran_con",
    district_code: 366,
  },
  {
    name: "Thị trấn Thịnh Long",
    code: 14221,
    division_type: "thị trấn",
    codename: "thi_tran_thinh_long",
    district_code: 366,
  },
  {
    name: "Xã Hải Nam",
    code: 14224,
    division_type: "xã",
    codename: "xa_hai_nam",
    district_code: 366,
  },
  {
    name: "Xã Hải Trung",
    code: 14227,
    division_type: "xã",
    codename: "xa_hai_trung",
    district_code: 366,
  },
  {
    name: "Xã Hải Vân",
    code: 14230,
    division_type: "xã",
    codename: "xa_hai_van",
    district_code: 366,
  },
  {
    name: "Xã Hải Minh",
    code: 14233,
    division_type: "xã",
    codename: "xa_hai_minh",
    district_code: 366,
  },
  {
    name: "Xã Hải Anh",
    code: 14236,
    division_type: "xã",
    codename: "xa_hai_anh",
    district_code: 366,
  },
  {
    name: "Xã Hải Hưng",
    code: 14239,
    division_type: "xã",
    codename: "xa_hai_hung",
    district_code: 366,
  },
  {
    name: "Xã Hải Bắc",
    code: 14242,
    division_type: "xã",
    codename: "xa_hai_bac",
    district_code: 366,
  },
  {
    name: "Xã Hải Phúc",
    code: 14245,
    division_type: "xã",
    codename: "xa_hai_phuc",
    district_code: 366,
  },
  {
    name: "Xã Hải Thanh",
    code: 14248,
    division_type: "xã",
    codename: "xa_hai_thanh",
    district_code: 366,
  },
  {
    name: "Xã Hải Hà",
    code: 14251,
    division_type: "xã",
    codename: "xa_hai_ha",
    district_code: 366,
  },
  {
    name: "Xã Hải Long",
    code: 14254,
    division_type: "xã",
    codename: "xa_hai_long",
    district_code: 366,
  },
  {
    name: "Xã Hải Phương",
    code: 14257,
    division_type: "xã",
    codename: "xa_hai_phuong",
    district_code: 366,
  },
  {
    name: "Xã Hải Đường",
    code: 14260,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 366,
  },
  {
    name: "Xã Hải Lộc",
    code: 14263,
    division_type: "xã",
    codename: "xa_hai_loc",
    district_code: 366,
  },
  {
    name: "Xã Hải Quang",
    code: 14266,
    division_type: "xã",
    codename: "xa_hai_quang",
    district_code: 366,
  },
  {
    name: "Xã Hải Đông",
    code: 14269,
    division_type: "xã",
    codename: "xa_hai_dong",
    district_code: 366,
  },
  {
    name: "Xã Hải Sơn",
    code: 14272,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 366,
  },
  {
    name: "Xã Hải Tân",
    code: 14275,
    division_type: "xã",
    codename: "xa_hai_tan",
    district_code: 366,
  },
  {
    name: "Xã Hải Phong",
    code: 14281,
    division_type: "xã",
    codename: "xa_hai_phong",
    district_code: 366,
  },
  {
    name: "Xã Hải An",
    code: 14284,
    division_type: "xã",
    codename: "xa_hai_an",
    district_code: 366,
  },
  {
    name: "Xã Hải Tây",
    code: 14287,
    division_type: "xã",
    codename: "xa_hai_tay",
    district_code: 366,
  },
  {
    name: "Xã Hải Lý",
    code: 14290,
    division_type: "xã",
    codename: "xa_hai_ly",
    district_code: 366,
  },
  {
    name: "Xã Hải Phú",
    code: 14293,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 366,
  },
  {
    name: "Xã Hải Giang",
    code: 14296,
    division_type: "xã",
    codename: "xa_hai_giang",
    district_code: 366,
  },
  {
    name: "Xã Hải Cường",
    code: 14299,
    division_type: "xã",
    codename: "xa_hai_cuong",
    district_code: 366,
  },
  {
    name: "Xã Hải Ninh",
    code: 14302,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 366,
  },
  {
    name: "Xã Hải Chính",
    code: 14305,
    division_type: "xã",
    codename: "xa_hai_chinh",
    district_code: 366,
  },
  {
    name: "Xã Hải Xuân",
    code: 14308,
    division_type: "xã",
    codename: "xa_hai_xuan",
    district_code: 366,
  },
  {
    name: "Xã Hải Châu",
    code: 14311,
    division_type: "xã",
    codename: "xa_hai_chau",
    district_code: 366,
  },
  {
    name: "Xã Hải Triều",
    code: 14314,
    division_type: "xã",
    codename: "xa_hai_trieu",
    district_code: 366,
  },
  {
    name: "Xã Hải Hòa",
    code: 14317,
    division_type: "xã",
    codename: "xa_hai_hoa",
    district_code: 366,
  },
  {
    name: "Phường Đông Thành",
    code: 14320,
    division_type: "phường",
    codename: "phuong_dong_thanh",
    district_code: 369,
  },
  {
    name: "Phường Tân Thành",
    code: 14323,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 369,
  },
  {
    name: "Phường Thanh Bình",
    code: 14326,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 369,
  },
  {
    name: "Phường Vân Giang",
    code: 14329,
    division_type: "phường",
    codename: "phuong_van_giang",
    district_code: 369,
  },
  {
    name: "Phường Bích Đào",
    code: 14332,
    division_type: "phường",
    codename: "phuong_bich_dao",
    district_code: 369,
  },
  {
    name: "Phường Phúc Thành",
    code: 14335,
    division_type: "phường",
    codename: "phuong_phuc_thanh",
    district_code: 369,
  },
  {
    name: "Phường Nam Bình",
    code: 14338,
    division_type: "phường",
    codename: "phuong_nam_binh",
    district_code: 369,
  },
  {
    name: "Phường Nam Thành",
    code: 14341,
    division_type: "phường",
    codename: "phuong_nam_thanh",
    district_code: 369,
  },
  {
    name: "Phường Ninh Khánh",
    code: 14344,
    division_type: "phường",
    codename: "phuong_ninh_khanh",
    district_code: 369,
  },
  {
    name: "Xã Ninh Nhất",
    code: 14347,
    division_type: "xã",
    codename: "xa_ninh_nhat",
    district_code: 369,
  },
  {
    name: "Xã Ninh Tiến",
    code: 14350,
    division_type: "xã",
    codename: "xa_ninh_tien",
    district_code: 369,
  },
  {
    name: "Xã Ninh Phúc",
    code: 14353,
    division_type: "xã",
    codename: "xa_ninh_phuc",
    district_code: 369,
  },
  {
    name: "Phường Ninh Sơn",
    code: 14356,
    division_type: "phường",
    codename: "phuong_ninh_son",
    district_code: 369,
  },
  {
    name: "Phường Ninh Phong",
    code: 14359,
    division_type: "phường",
    codename: "phuong_ninh_phong",
    district_code: 369,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14362,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 370,
  },
  {
    name: "Phường Trung Sơn",
    code: 14365,
    division_type: "phường",
    codename: "phuong_trung_son",
    district_code: 370,
  },
  {
    name: "Phường Nam Sơn",
    code: 14368,
    division_type: "phường",
    codename: "phuong_nam_son",
    district_code: 370,
  },
  {
    name: "Phường Tây Sơn",
    code: 14369,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 370,
  },
  {
    name: "Xã Yên Sơn",
    code: 14371,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 370,
  },
  {
    name: "Phường Yên Bình",
    code: 14374,
    division_type: "phường",
    codename: "phuong_yen_binh",
    district_code: 370,
  },
  {
    name: "Phường Tân Bình",
    code: 14375,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 370,
  },
  {
    name: "Xã Quang Sơn",
    code: 14377,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 370,
  },
  {
    name: "Xã Đông Sơn",
    code: 14380,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 370,
  },
  {
    name: "Thị trấn Nho Quan",
    code: 14383,
    division_type: "thị trấn",
    codename: "thi_tran_nho_quan",
    district_code: 372,
  },
  {
    name: "Xã Xích Thổ",
    code: 14386,
    division_type: "xã",
    codename: "xa_xich_tho",
    district_code: 372,
  },
  {
    name: "Xã Gia Lâm",
    code: 14389,
    division_type: "xã",
    codename: "xa_gia_lam",
    district_code: 372,
  },
  {
    name: "Xã Gia Sơn",
    code: 14392,
    division_type: "xã",
    codename: "xa_gia_son",
    district_code: 372,
  },
  {
    name: "Xã Thạch Bình",
    code: 14395,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 372,
  },
  {
    name: "Xã Gia Thủy",
    code: 14398,
    division_type: "xã",
    codename: "xa_gia_thuy",
    district_code: 372,
  },
  {
    name: "Xã Gia Tường",
    code: 14401,
    division_type: "xã",
    codename: "xa_gia_tuong",
    district_code: 372,
  },
  {
    name: "Xã Cúc Phương",
    code: 14404,
    division_type: "xã",
    codename: "xa_cuc_phuong",
    district_code: 372,
  },
  {
    name: "Xã Phú Sơn",
    code: 14407,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 372,
  },
  {
    name: "Xã Đức Long",
    code: 14410,
    division_type: "xã",
    codename: "xa_duc_long",
    district_code: 372,
  },
  {
    name: "Xã Lạc Vân",
    code: 14413,
    division_type: "xã",
    codename: "xa_lac_van",
    district_code: 372,
  },
  {
    name: "Xã Đồng Phong",
    code: 14416,
    division_type: "xã",
    codename: "xa_dong_phong",
    district_code: 372,
  },
  {
    name: "Xã Yên Quang",
    code: 14419,
    division_type: "xã",
    codename: "xa_yen_quang",
    district_code: 372,
  },
  {
    name: "Xã Lạng Phong",
    code: 14422,
    division_type: "xã",
    codename: "xa_lang_phong",
    district_code: 372,
  },
  {
    name: "Xã Thượng Hòa",
    code: 14425,
    division_type: "xã",
    codename: "xa_thuong_hoa",
    district_code: 372,
  },
  {
    name: "Xã Văn Phong",
    code: 14428,
    division_type: "xã",
    codename: "xa_van_phong",
    district_code: 372,
  },
  {
    name: "Xã Văn Phương",
    code: 14431,
    division_type: "xã",
    codename: "xa_van_phuong",
    district_code: 372,
  },
  {
    name: "Xã Thanh Lạc",
    code: 14434,
    division_type: "xã",
    codename: "xa_thanh_lac",
    district_code: 372,
  },
  {
    name: "Xã Sơn Lai",
    code: 14437,
    division_type: "xã",
    codename: "xa_son_lai",
    district_code: 372,
  },
  {
    name: "Xã Sơn Thành",
    code: 14440,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 372,
  },
  {
    name: "Xã Văn Phú",
    code: 14443,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 372,
  },
  {
    name: "Xã Phú Lộc",
    code: 14446,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 372,
  },
  {
    name: "Xã Kỳ Phú",
    code: 14449,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 372,
  },
  {
    name: "Xã Quỳnh Lưu",
    code: 14452,
    division_type: "xã",
    codename: "xa_quynh_luu",
    district_code: 372,
  },
  {
    name: "Xã Sơn Hà",
    code: 14455,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 372,
  },
  {
    name: "Xã Phú Long",
    code: 14458,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 372,
  },
  {
    name: "Xã Quảng Lạc",
    code: 14461,
    division_type: "xã",
    codename: "xa_quang_lac",
    district_code: 372,
  },
  {
    name: "Thị trấn Me",
    code: 14464,
    division_type: "thị trấn",
    codename: "thi_tran_me",
    district_code: 373,
  },
  {
    name: "Xã Gia Hòa",
    code: 14467,
    division_type: "xã",
    codename: "xa_gia_hoa",
    district_code: 373,
  },
  {
    name: "Xã Gia Hưng",
    code: 14470,
    division_type: "xã",
    codename: "xa_gia_hung",
    district_code: 373,
  },
  {
    name: "Xã Liên Sơn",
    code: 14473,
    division_type: "xã",
    codename: "xa_lien_son",
    district_code: 373,
  },
  {
    name: "Xã Gia Thanh",
    code: 14476,
    division_type: "xã",
    codename: "xa_gia_thanh",
    district_code: 373,
  },
  {
    name: "Xã Gia Vân",
    code: 14479,
    division_type: "xã",
    codename: "xa_gia_van",
    district_code: 373,
  },
  {
    name: "Xã Gia Phú",
    code: 14482,
    division_type: "xã",
    codename: "xa_gia_phu",
    district_code: 373,
  },
  {
    name: "Xã Gia Xuân",
    code: 14485,
    division_type: "xã",
    codename: "xa_gia_xuan",
    district_code: 373,
  },
  {
    name: "Xã Gia Lập",
    code: 14488,
    division_type: "xã",
    codename: "xa_gia_lap",
    district_code: 373,
  },
  {
    name: "Xã Gia Vượng",
    code: 14491,
    division_type: "xã",
    codename: "xa_gia_vuong",
    district_code: 373,
  },
  {
    name: "Xã Gia Trấn",
    code: 14494,
    division_type: "xã",
    codename: "xa_gia_tran",
    district_code: 373,
  },
  {
    name: "Xã Gia Thịnh",
    code: 14497,
    division_type: "xã",
    codename: "xa_gia_thinh",
    district_code: 373,
  },
  {
    name: "Xã Gia Phương",
    code: 14500,
    division_type: "xã",
    codename: "xa_gia_phuong",
    district_code: 373,
  },
  {
    name: "Xã Gia Tân",
    code: 14503,
    division_type: "xã",
    codename: "xa_gia_tan",
    district_code: 373,
  },
  {
    name: "Xã Gia Thắng",
    code: 14506,
    division_type: "xã",
    codename: "xa_gia_thang",
    district_code: 373,
  },
  {
    name: "Xã Gia Trung",
    code: 14509,
    division_type: "xã",
    codename: "xa_gia_trung",
    district_code: 373,
  },
  {
    name: "Xã Gia Minh",
    code: 14512,
    division_type: "xã",
    codename: "xa_gia_minh",
    district_code: 373,
  },
  {
    name: "Xã Gia Lạc",
    code: 14515,
    division_type: "xã",
    codename: "xa_gia_lac",
    district_code: 373,
  },
  {
    name: "Xã Gia Tiến",
    code: 14518,
    division_type: "xã",
    codename: "xa_gia_tien",
    district_code: 373,
  },
  {
    name: "Xã Gia Sinh",
    code: 14521,
    division_type: "xã",
    codename: "xa_gia_sinh",
    district_code: 373,
  },
  {
    name: "Xã Gia Phong",
    code: 14524,
    division_type: "xã",
    codename: "xa_gia_phong",
    district_code: 373,
  },
  {
    name: "Thị trấn Thiên Tôn",
    code: 14527,
    division_type: "thị trấn",
    codename: "thi_tran_thien_ton",
    district_code: 374,
  },
  {
    name: "Xã Ninh Giang",
    code: 14530,
    division_type: "xã",
    codename: "xa_ninh_giang",
    district_code: 374,
  },
  {
    name: "Xã Trường Yên",
    code: 14533,
    division_type: "xã",
    codename: "xa_truong_yen",
    district_code: 374,
  },
  {
    name: "Xã Ninh Khang",
    code: 14536,
    division_type: "xã",
    codename: "xa_ninh_khang",
    district_code: 374,
  },
  {
    name: "Xã Ninh Mỹ",
    code: 14539,
    division_type: "xã",
    codename: "xa_ninh_my",
    district_code: 374,
  },
  {
    name: "Xã Ninh Hòa",
    code: 14542,
    division_type: "xã",
    codename: "xa_ninh_hoa",
    district_code: 374,
  },
  {
    name: "Xã Ninh Xuân",
    code: 14545,
    division_type: "xã",
    codename: "xa_ninh_xuan",
    district_code: 374,
  },
  {
    name: "Xã Ninh Hải",
    code: 14548,
    division_type: "xã",
    codename: "xa_ninh_hai",
    district_code: 374,
  },
  {
    name: "Xã Ninh Thắng",
    code: 14551,
    division_type: "xã",
    codename: "xa_ninh_thang",
    district_code: 374,
  },
  {
    name: "Xã Ninh Vân",
    code: 14554,
    division_type: "xã",
    codename: "xa_ninh_van",
    district_code: 374,
  },
  {
    name: "Xã Ninh An",
    code: 14557,
    division_type: "xã",
    codename: "xa_ninh_an",
    district_code: 374,
  },
  {
    name: "Thị trấn Yên Ninh",
    code: 14560,
    division_type: "thị trấn",
    codename: "thi_tran_yen_ninh",
    district_code: 375,
  },
  {
    name: "Xã Khánh Tiên",
    code: 14563,
    division_type: "xã",
    codename: "xa_khanh_tien",
    district_code: 375,
  },
  {
    name: "Xã Khánh Phú",
    code: 14566,
    division_type: "xã",
    codename: "xa_khanh_phu",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hòa",
    code: 14569,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 375,
  },
  {
    name: "Xã Khánh Lợi",
    code: 14572,
    division_type: "xã",
    codename: "xa_khanh_loi",
    district_code: 375,
  },
  {
    name: "Xã Khánh An",
    code: 14575,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 375,
  },
  {
    name: "Xã Khánh Cường",
    code: 14578,
    division_type: "xã",
    codename: "xa_khanh_cuong",
    district_code: 375,
  },
  {
    name: "Xã Khánh Cư",
    code: 14581,
    division_type: "xã",
    codename: "xa_khanh_cu",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thiện",
    code: 14584,
    division_type: "xã",
    codename: "xa_khanh_thien",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hải",
    code: 14587,
    division_type: "xã",
    codename: "xa_khanh_hai",
    district_code: 375,
  },
  {
    name: "Xã Khánh Trung",
    code: 14590,
    division_type: "xã",
    codename: "xa_khanh_trung",
    district_code: 375,
  },
  {
    name: "Xã Khánh Mậu",
    code: 14593,
    division_type: "xã",
    codename: "xa_khanh_mau",
    district_code: 375,
  },
  {
    name: "Xã Khánh Vân",
    code: 14596,
    division_type: "xã",
    codename: "xa_khanh_van",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hội",
    code: 14599,
    division_type: "xã",
    codename: "xa_khanh_hoi",
    district_code: 375,
  },
  {
    name: "Xã Khánh Công",
    code: 14602,
    division_type: "xã",
    codename: "xa_khanh_cong",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thành",
    code: 14608,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 375,
  },
  {
    name: "Xã Khánh Nhạc",
    code: 14611,
    division_type: "xã",
    codename: "xa_khanh_nhac",
    district_code: 375,
  },
  {
    name: "Xã Khánh Thủy",
    code: 14614,
    division_type: "xã",
    codename: "xa_khanh_thuy",
    district_code: 375,
  },
  {
    name: "Xã Khánh Hồng",
    code: 14617,
    division_type: "xã",
    codename: "xa_khanh_hong",
    district_code: 375,
  },
  {
    name: "Thị trấn Phát Diệm",
    code: 14620,
    division_type: "thị trấn",
    codename: "thi_tran_phat_diem",
    district_code: 376,
  },
  {
    name: "Thị trấn Bình Minh",
    code: 14623,
    division_type: "thị trấn",
    codename: "thi_tran_binh_minh",
    district_code: 376,
  },
  {
    name: "Xã Hồi Ninh",
    code: 14629,
    division_type: "xã",
    codename: "xa_hoi_ninh",
    district_code: 376,
  },
  {
    name: "Xã Xuân Chính",
    code: 14632,
    division_type: "xã",
    codename: "xa_xuan_chinh",
    district_code: 376,
  },
  {
    name: "Xã Kim Định",
    code: 14635,
    division_type: "xã",
    codename: "xa_kim_dinh",
    district_code: 376,
  },
  {
    name: "Xã Ân Hòa",
    code: 14638,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 376,
  },
  {
    name: "Xã Hùng Tiến",
    code: 14641,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 376,
  },
  {
    name: "Xã Quang Thiện",
    code: 14647,
    division_type: "xã",
    codename: "xa_quang_thien",
    district_code: 376,
  },
  {
    name: "Xã Như Hòa",
    code: 14650,
    division_type: "xã",
    codename: "xa_nhu_hoa",
    district_code: 376,
  },
  {
    name: "Xã Chất Bình",
    code: 14653,
    division_type: "xã",
    codename: "xa_chat_binh",
    district_code: 376,
  },
  {
    name: "Xã Đồng Hướng",
    code: 14656,
    division_type: "xã",
    codename: "xa_dong_huong",
    district_code: 376,
  },
  {
    name: "Xã Kim Chính",
    code: 14659,
    division_type: "xã",
    codename: "xa_kim_chinh",
    district_code: 376,
  },
  {
    name: "Xã Thượng Kiệm",
    code: 14662,
    division_type: "xã",
    codename: "xa_thuong_kiem",
    district_code: 376,
  },
  {
    name: "Xã Lưu Phương",
    code: 14665,
    division_type: "xã",
    codename: "xa_luu_phuong",
    district_code: 376,
  },
  {
    name: "Xã Tân Thành",
    code: 14668,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 376,
  },
  {
    name: "Xã Yên Lộc",
    code: 14671,
    division_type: "xã",
    codename: "xa_yen_loc",
    district_code: 376,
  },
  {
    name: "Xã Lai Thành",
    code: 14674,
    division_type: "xã",
    codename: "xa_lai_thanh",
    district_code: 376,
  },
  {
    name: "Xã Định Hóa",
    code: 14677,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 376,
  },
  {
    name: "Xã Văn Hải",
    code: 14680,
    division_type: "xã",
    codename: "xa_van_hai",
    district_code: 376,
  },
  {
    name: "Xã Kim Tân",
    code: 14683,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 376,
  },
  {
    name: "Xã Kim Mỹ",
    code: 14686,
    division_type: "xã",
    codename: "xa_kim_my",
    district_code: 376,
  },
  {
    name: "Xã Cồn Thoi",
    code: 14689,
    division_type: "xã",
    codename: "xa_con_thoi",
    district_code: 376,
  },
  {
    name: "Xã Kim Hải",
    code: 14692,
    division_type: "xã",
    codename: "xa_kim_hai",
    district_code: 376,
  },
  {
    name: "Xã Kim Trung",
    code: 14695,
    division_type: "xã",
    codename: "xa_kim_trung",
    district_code: 376,
  },
  {
    name: "Xã Kim Đông",
    code: 14698,
    division_type: "xã",
    codename: "xa_kim_dong",
    district_code: 376,
  },
  {
    name: "Thị trấn Yên Thịnh",
    code: 14701,
    division_type: "thị trấn",
    codename: "thi_tran_yen_thinh",
    district_code: 377,
  },
  {
    name: "Xã Khánh Thượng",
    code: 14704,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 377,
  },
  {
    name: "Xã Khánh Dương",
    code: 14707,
    division_type: "xã",
    codename: "xa_khanh_duong",
    district_code: 377,
  },
  {
    name: "Xã Mai Sơn",
    code: 14710,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 377,
  },
  {
    name: "Xã Khánh Thịnh",
    code: 14713,
    division_type: "xã",
    codename: "xa_khanh_thinh",
    district_code: 377,
  },
  {
    name: "Xã Yên Phong",
    code: 14719,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 377,
  },
  {
    name: "Xã Yên Hòa",
    code: 14722,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 377,
  },
  {
    name: "Xã Yên Thắng",
    code: 14725,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 377,
  },
  {
    name: "Xã Yên Từ",
    code: 14728,
    division_type: "xã",
    codename: "xa_yen_tu",
    district_code: 377,
  },
  {
    name: "Xã Yên Hưng",
    code: 14731,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 377,
  },
  {
    name: "Xã Yên Thành",
    code: 14734,
    division_type: "xã",
    codename: "xa_yen_thanh",
    district_code: 377,
  },
  {
    name: "Xã Yên Nhân",
    code: 14737,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 377,
  },
  {
    name: "Xã Yên Mỹ",
    code: 14740,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 377,
  },
  {
    name: "Xã Yên Mạc",
    code: 14743,
    division_type: "xã",
    codename: "xa_yen_mac",
    district_code: 377,
  },
  {
    name: "Xã Yên Đồng",
    code: 14746,
    division_type: "xã",
    codename: "xa_yen_dong",
    district_code: 377,
  },
  {
    name: "Xã Yên Thái",
    code: 14749,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 377,
  },
  {
    name: "Xã Yên Lâm",
    code: 14752,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 377,
  },
  {
    name: "Phường Hàm Rồng",
    code: 14755,
    division_type: "phường",
    codename: "phuong_ham_rong",
    district_code: 380,
  },
  {
    name: "Phường Đông Thọ",
    code: 14758,
    division_type: "phường",
    codename: "phuong_dong_tho",
    district_code: 380,
  },
  {
    name: "Phường Nam Ngạn",
    code: 14761,
    division_type: "phường",
    codename: "phuong_nam_ngan",
    district_code: 380,
  },
  {
    name: "Phường Trường Thi",
    code: 14764,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 380,
  },
  {
    name: "Phường Điện Biên",
    code: 14767,
    division_type: "phường",
    codename: "phuong_dien_bien",
    district_code: 380,
  },
  {
    name: "Phường Phú Sơn",
    code: 14770,
    division_type: "phường",
    codename: "phuong_phu_son",
    district_code: 380,
  },
  {
    name: "Phường Lam Sơn",
    code: 14773,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 380,
  },
  {
    name: "Phường Ba Đình",
    code: 14776,
    division_type: "phường",
    codename: "phuong_ba_dinh",
    district_code: 380,
  },
  {
    name: "Phường Ngọc Trạo",
    code: 14779,
    division_type: "phường",
    codename: "phuong_ngoc_trao",
    district_code: 380,
  },
  {
    name: "Phường Đông Vệ",
    code: 14782,
    division_type: "phường",
    codename: "phuong_dong_ve",
    district_code: 380,
  },
  {
    name: "Phường Đông Sơn",
    code: 14785,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 380,
  },
  {
    name: "Phường Tân Sơn",
    code: 14788,
    division_type: "phường",
    codename: "phuong_tan_son",
    district_code: 380,
  },
  {
    name: "Phường Đông Cương",
    code: 14791,
    division_type: "phường",
    codename: "phuong_dong_cuong",
    district_code: 380,
  },
  {
    name: "Phường Đông Hương",
    code: 14794,
    division_type: "phường",
    codename: "phuong_dong_huong",
    district_code: 380,
  },
  {
    name: "Phường Đông Hải",
    code: 14797,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 380,
  },
  {
    name: "Phường Quảng Hưng",
    code: 14800,
    division_type: "phường",
    codename: "phuong_quang_hung",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thắng",
    code: 14803,
    division_type: "phường",
    codename: "phuong_quang_thang",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thành",
    code: 14806,
    division_type: "phường",
    codename: "phuong_quang_thanh",
    district_code: 380,
  },
  {
    name: "Xã Thiệu Vân",
    code: 15850,
    division_type: "xã",
    codename: "xa_thieu_van",
    district_code: 380,
  },
  {
    name: "Phường Thiệu Khánh",
    code: 15856,
    division_type: "phường",
    codename: "phuong_thieu_khanh",
    district_code: 380,
  },
  {
    name: "Phường Thiệu Dương",
    code: 15859,
    division_type: "phường",
    codename: "phuong_thieu_duong",
    district_code: 380,
  },
  {
    name: "Phường Tào Xuyên",
    code: 15913,
    division_type: "phường",
    codename: "phuong_tao_xuyen",
    district_code: 380,
  },
  {
    name: "Phường Long Anh",
    code: 15922,
    division_type: "phường",
    codename: "phuong_long_anh",
    district_code: 380,
  },
  {
    name: "Xã Hoằng Quang",
    code: 15925,
    division_type: "xã",
    codename: "xa_hoang_quang",
    district_code: 380,
  },
  {
    name: "Xã Hoằng Đại",
    code: 15970,
    division_type: "xã",
    codename: "xa_hoang_dai",
    district_code: 380,
  },
  {
    name: "Phường Đông Lĩnh",
    code: 16396,
    division_type: "phường",
    codename: "phuong_dong_linh",
    district_code: 380,
  },
  {
    name: "Xã Đông Vinh",
    code: 16429,
    division_type: "xã",
    codename: "xa_dong_vinh",
    district_code: 380,
  },
  {
    name: "Phường Đông Tân",
    code: 16432,
    division_type: "phường",
    codename: "phuong_dong_tan",
    district_code: 380,
  },
  {
    name: "Phường An Hưng",
    code: 16435,
    division_type: "phường",
    codename: "phuong_an_hung",
    district_code: 380,
  },
  {
    name: "Phường Quảng Thịnh",
    code: 16441,
    division_type: "phường",
    codename: "phuong_quang_thinh",
    district_code: 380,
  },
  {
    name: "Phường Quảng Đông",
    code: 16459,
    division_type: "phường",
    codename: "phuong_quang_dong",
    district_code: 380,
  },
  {
    name: "Phường Quảng Cát",
    code: 16507,
    division_type: "phường",
    codename: "phuong_quang_cat",
    district_code: 380,
  },
  {
    name: "Phường Quảng Phú",
    code: 16522,
    division_type: "phường",
    codename: "phuong_quang_phu",
    district_code: 380,
  },
  {
    name: "Phường Quảng Tâm",
    code: 16525,
    division_type: "phường",
    codename: "phuong_quang_tam",
    district_code: 380,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14809,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 381,
  },
  {
    name: "Phường Ba Đình",
    code: 14812,
    division_type: "phường",
    codename: "phuong_ba_dinh",
    district_code: 381,
  },
  {
    name: "Phường Lam Sơn",
    code: 14815,
    division_type: "phường",
    codename: "phuong_lam_son",
    district_code: 381,
  },
  {
    name: "Phường Ngọc Trạo",
    code: 14818,
    division_type: "phường",
    codename: "phuong_ngoc_trao",
    district_code: 381,
  },
  {
    name: "Phường Đông Sơn",
    code: 14821,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 381,
  },
  {
    name: "Phường Phú Sơn",
    code: 14823,
    division_type: "phường",
    codename: "phuong_phu_son",
    district_code: 381,
  },
  {
    name: "Xã Quang Trung",
    code: 14824,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 381,
  },
  {
    name: "Phường Trung Sơn",
    code: 14830,
    division_type: "phường",
    codename: "phuong_trung_son",
    district_code: 382,
  },
  {
    name: "Phường Bắc Sơn",
    code: 14833,
    division_type: "phường",
    codename: "phuong_bac_son",
    district_code: 382,
  },
  {
    name: "Phường Trường Sơn",
    code: 14836,
    division_type: "phường",
    codename: "phuong_truong_son",
    district_code: 382,
  },
  {
    name: "Phường Quảng Cư",
    code: 14839,
    division_type: "phường",
    codename: "phuong_quang_cu",
    district_code: 382,
  },
  {
    name: "Phường Quảng Tiến",
    code: 14842,
    division_type: "phường",
    codename: "phuong_quang_tien",
    district_code: 382,
  },
  {
    name: "Xã Quảng Minh",
    code: 16513,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 382,
  },
  {
    name: "Xã Quảng Hùng",
    code: 16516,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 382,
  },
  {
    name: "Phường Quảng Thọ",
    code: 16528,
    division_type: "phường",
    codename: "phuong_quang_tho",
    district_code: 382,
  },
  {
    name: "Phường Quảng Châu",
    code: 16531,
    division_type: "phường",
    codename: "phuong_quang_chau",
    district_code: 382,
  },
  {
    name: "Phường Quảng Vinh",
    code: 16534,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 382,
  },
  {
    name: "Xã Quảng Đại",
    code: 16537,
    division_type: "xã",
    codename: "xa_quang_dai",
    district_code: 382,
  },
  {
    name: "Thị trấn Mường Lát",
    code: 14845,
    division_type: "thị trấn",
    codename: "thi_tran_muong_lat",
    district_code: 384,
  },
  {
    name: "Xã Tam Chung",
    code: 14848,
    division_type: "xã",
    codename: "xa_tam_chung",
    district_code: 384,
  },
  {
    name: "Xã Mường Lý",
    code: 14854,
    division_type: "xã",
    codename: "xa_muong_ly",
    district_code: 384,
  },
  {
    name: "Xã Trung Lý",
    code: 14857,
    division_type: "xã",
    codename: "xa_trung_ly",
    district_code: 384,
  },
  {
    name: "Xã Quang Chiểu",
    code: 14860,
    division_type: "xã",
    codename: "xa_quang_chieu",
    district_code: 384,
  },
  {
    name: "Xã Pù Nhi",
    code: 14863,
    division_type: "xã",
    codename: "xa_pu_nhi",
    district_code: 384,
  },
  {
    name: "Xã Nhi Sơn",
    code: 14864,
    division_type: "xã",
    codename: "xa_nhi_son",
    district_code: 384,
  },
  {
    name: "Xã Mường Chanh",
    code: 14866,
    division_type: "xã",
    codename: "xa_muong_chanh",
    district_code: 384,
  },
  {
    name: "Thị trấn Hồi Xuân",
    code: 14869,
    division_type: "thị trấn",
    codename: "thi_tran_hoi_xuan",
    district_code: 385,
  },
  {
    name: "Xã Thành Sơn",
    code: 14872,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 385,
  },
  {
    name: "Xã Trung Sơn",
    code: 14875,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 385,
  },
  {
    name: "Xã Phú Thanh",
    code: 14878,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 385,
  },
  {
    name: "Xã Trung Thành",
    code: 14881,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 385,
  },
  {
    name: "Xã Phú Lệ",
    code: 14884,
    division_type: "xã",
    codename: "xa_phu_le",
    district_code: 385,
  },
  {
    name: "Xã Phú Sơn",
    code: 14887,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 385,
  },
  {
    name: "Xã Phú Xuân",
    code: 14890,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 385,
  },
  {
    name: "Xã Hiền Chung",
    code: 14896,
    division_type: "xã",
    codename: "xa_hien_chung",
    district_code: 385,
  },
  {
    name: "Xã Hiền Kiệt",
    code: 14899,
    division_type: "xã",
    codename: "xa_hien_kiet",
    district_code: 385,
  },
  {
    name: "Xã Nam Tiến",
    code: 14902,
    division_type: "xã",
    codename: "xa_nam_tien",
    district_code: 385,
  },
  {
    name: "Xã Thiên Phủ",
    code: 14908,
    division_type: "xã",
    codename: "xa_thien_phu",
    district_code: 385,
  },
  {
    name: "Xã Phú Nghiêm",
    code: 14911,
    division_type: "xã",
    codename: "xa_phu_nghiem",
    district_code: 385,
  },
  {
    name: "Xã Nam Xuân",
    code: 14914,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 385,
  },
  {
    name: "Xã Nam Động",
    code: 14917,
    division_type: "xã",
    codename: "xa_nam_dong",
    district_code: 385,
  },
  {
    name: "Thị trấn Cành Nàng",
    code: 14923,
    division_type: "thị trấn",
    codename: "thi_tran_canh_nang",
    district_code: 386,
  },
  {
    name: "Xã Điền Thượng",
    code: 14926,
    division_type: "xã",
    codename: "xa_dien_thuong",
    district_code: 386,
  },
  {
    name: "Xã Điền Hạ",
    code: 14929,
    division_type: "xã",
    codename: "xa_dien_ha",
    district_code: 386,
  },
  {
    name: "Xã Điền Quang",
    code: 14932,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 386,
  },
  {
    name: "Xã Điền Trung",
    code: 14935,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 386,
  },
  {
    name: "Xã Thành Sơn",
    code: 14938,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 386,
  },
  {
    name: "Xã Lương Ngoại",
    code: 14941,
    division_type: "xã",
    codename: "xa_luong_ngoai",
    district_code: 386,
  },
  {
    name: "Xã Ái Thượng",
    code: 14944,
    division_type: "xã",
    codename: "xa_ai_thuong",
    district_code: 386,
  },
  {
    name: "Xã Lương Nội",
    code: 14947,
    division_type: "xã",
    codename: "xa_luong_noi",
    district_code: 386,
  },
  {
    name: "Xã Điền Lư",
    code: 14950,
    division_type: "xã",
    codename: "xa_dien_lu",
    district_code: 386,
  },
  {
    name: "Xã Lương Trung",
    code: 14953,
    division_type: "xã",
    codename: "xa_luong_trung",
    district_code: 386,
  },
  {
    name: "Xã Lũng Niêm",
    code: 14956,
    division_type: "xã",
    codename: "xa_lung_niem",
    district_code: 386,
  },
  {
    name: "Xã Lũng Cao",
    code: 14959,
    division_type: "xã",
    codename: "xa_lung_cao",
    district_code: 386,
  },
  {
    name: "Xã Hạ Trung",
    code: 14962,
    division_type: "xã",
    codename: "xa_ha_trung",
    district_code: 386,
  },
  {
    name: "Xã Cổ Lũng",
    code: 14965,
    division_type: "xã",
    codename: "xa_co_lung",
    district_code: 386,
  },
  {
    name: "Xã Thành Lâm",
    code: 14968,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 386,
  },
  {
    name: "Xã Ban Công",
    code: 14971,
    division_type: "xã",
    codename: "xa_ban_cong",
    district_code: 386,
  },
  {
    name: "Xã Kỳ Tân",
    code: 14974,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 386,
  },
  {
    name: "Xã Văn Nho",
    code: 14977,
    division_type: "xã",
    codename: "xa_van_nho",
    district_code: 386,
  },
  {
    name: "Xã Thiết Ống",
    code: 14980,
    division_type: "xã",
    codename: "xa_thiet_ong",
    district_code: 386,
  },
  {
    name: "Xã Thiết Kế",
    code: 14986,
    division_type: "xã",
    codename: "xa_thiet_ke",
    district_code: 386,
  },
  {
    name: "Xã Trung Xuân",
    code: 14995,
    division_type: "xã",
    codename: "xa_trung_xuan",
    district_code: 387,
  },
  {
    name: "Xã Trung Thượng",
    code: 14998,
    division_type: "xã",
    codename: "xa_trung_thuong",
    district_code: 387,
  },
  {
    name: "Xã Trung Tiến",
    code: 14999,
    division_type: "xã",
    codename: "xa_trung_tien",
    district_code: 387,
  },
  {
    name: "Xã Trung Hạ",
    code: 15001,
    division_type: "xã",
    codename: "xa_trung_ha",
    district_code: 387,
  },
  {
    name: "Xã Sơn Hà",
    code: 15004,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 387,
  },
  {
    name: "Xã Tam Thanh",
    code: 15007,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 387,
  },
  {
    name: "Xã Sơn Thủy",
    code: 15010,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 387,
  },
  {
    name: "Xã Na Mèo",
    code: 15013,
    division_type: "xã",
    codename: "xa_na_meo",
    district_code: 387,
  },
  {
    name: "Thị trấn Sơn Lư",
    code: 15016,
    division_type: "thị trấn",
    codename: "thi_tran_son_lu",
    district_code: 387,
  },
  {
    name: "Xã Tam Lư",
    code: 15019,
    division_type: "xã",
    codename: "xa_tam_lu",
    district_code: 387,
  },
  {
    name: "Xã Sơn Điện",
    code: 15022,
    division_type: "xã",
    codename: "xa_son_dien",
    district_code: 387,
  },
  {
    name: "Xã Mường Mìn",
    code: 15025,
    division_type: "xã",
    codename: "xa_muong_min",
    district_code: 387,
  },
  {
    name: "Xã Yên Khương",
    code: 15031,
    division_type: "xã",
    codename: "xa_yen_khuong",
    district_code: 388,
  },
  {
    name: "Xã Yên Thắng",
    code: 15034,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 388,
  },
  {
    name: "Xã Trí Nang",
    code: 15037,
    division_type: "xã",
    codename: "xa_tri_nang",
    district_code: 388,
  },
  {
    name: "Xã Giao An",
    code: 15040,
    division_type: "xã",
    codename: "xa_giao_an",
    district_code: 388,
  },
  {
    name: "Xã Giao Thiện",
    code: 15043,
    division_type: "xã",
    codename: "xa_giao_thien",
    district_code: 388,
  },
  {
    name: "Xã Tân Phúc",
    code: 15046,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 388,
  },
  {
    name: "Xã Tam Văn",
    code: 15049,
    division_type: "xã",
    codename: "xa_tam_van",
    district_code: 388,
  },
  {
    name: "Xã Lâm Phú",
    code: 15052,
    division_type: "xã",
    codename: "xa_lam_phu",
    district_code: 388,
  },
  {
    name: "Thị trấn Lang Chánh",
    code: 15055,
    division_type: "thị trấn",
    codename: "thi_tran_lang_chanh",
    district_code: 388,
  },
  {
    name: "Xã Đồng Lương",
    code: 15058,
    division_type: "xã",
    codename: "xa_dong_luong",
    district_code: 388,
  },
  {
    name: "Thị trấn Ngọc Lặc",
    code: 15061,
    division_type: "thị trấn",
    codename: "thi_tran_ngoc_lac",
    district_code: 389,
  },
  {
    name: "Xã Lam Sơn",
    code: 15064,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 389,
  },
  {
    name: "Xã Mỹ Tân",
    code: 15067,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 389,
  },
  {
    name: "Xã Thúy Sơn",
    code: 15070,
    division_type: "xã",
    codename: "xa_thuy_son",
    district_code: 389,
  },
  {
    name: "Xã Thạch Lập",
    code: 15073,
    division_type: "xã",
    codename: "xa_thach_lap",
    district_code: 389,
  },
  {
    name: "Xã Vân Âm",
    code: 15076,
    division_type: "xã",
    codename: "xa_van_am",
    district_code: 389,
  },
  {
    name: "Xã Cao Ngọc",
    code: 15079,
    division_type: "xã",
    codename: "xa_cao_ngoc",
    district_code: 389,
  },
  {
    name: "Xã Quang Trung",
    code: 15085,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 389,
  },
  {
    name: "Xã Đồng Thịnh",
    code: 15088,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Liên",
    code: 15091,
    division_type: "xã",
    codename: "xa_ngoc_lien",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 15094,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 389,
  },
  {
    name: "Xã Lộc Thịnh",
    code: 15097,
    division_type: "xã",
    codename: "xa_loc_thinh",
    district_code: 389,
  },
  {
    name: "Xã Cao Thịnh",
    code: 15100,
    division_type: "xã",
    codename: "xa_cao_thinh",
    district_code: 389,
  },
  {
    name: "Xã Ngọc Trung",
    code: 15103,
    division_type: "xã",
    codename: "xa_ngoc_trung",
    district_code: 389,
  },
  {
    name: "Xã Phùng Giáo",
    code: 15106,
    division_type: "xã",
    codename: "xa_phung_giao",
    district_code: 389,
  },
  {
    name: "Xã Phùng Minh",
    code: 15109,
    division_type: "xã",
    codename: "xa_phung_minh",
    district_code: 389,
  },
  {
    name: "Xã Phúc Thịnh",
    code: 15112,
    division_type: "xã",
    codename: "xa_phuc_thinh",
    district_code: 389,
  },
  {
    name: "Xã Nguyệt Ấn",
    code: 15115,
    division_type: "xã",
    codename: "xa_nguyet_an",
    district_code: 389,
  },
  {
    name: "Xã Kiên Thọ",
    code: 15118,
    division_type: "xã",
    codename: "xa_kien_tho",
    district_code: 389,
  },
  {
    name: "Xã Minh Tiến",
    code: 15121,
    division_type: "xã",
    codename: "xa_minh_tien",
    district_code: 389,
  },
  {
    name: "Xã Minh Sơn",
    code: 15124,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 389,
  },
  {
    name: "Thị trấn Phong Sơn",
    code: 15127,
    division_type: "thị trấn",
    codename: "thi_tran_phong_son",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Thành",
    code: 15133,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Quý",
    code: 15136,
    division_type: "xã",
    codename: "xa_cam_quy",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Lương",
    code: 15139,
    division_type: "xã",
    codename: "xa_cam_luong",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Thạch",
    code: 15142,
    division_type: "xã",
    codename: "xa_cam_thach",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Liên",
    code: 15145,
    division_type: "xã",
    codename: "xa_cam_lien",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Giang",
    code: 15148,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Bình",
    code: 15151,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tú",
    code: 15154,
    division_type: "xã",
    codename: "xa_cam_tu",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Châu",
    code: 15160,
    division_type: "xã",
    codename: "xa_cam_chau",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tâm",
    code: 15163,
    division_type: "xã",
    codename: "xa_cam_tam",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Ngọc",
    code: 15169,
    division_type: "xã",
    codename: "xa_cam_ngoc",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Long",
    code: 15172,
    division_type: "xã",
    codename: "xa_cam_long",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Yên",
    code: 15175,
    division_type: "xã",
    codename: "xa_cam_yen",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Tân",
    code: 15178,
    division_type: "xã",
    codename: "xa_cam_tan",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Phú",
    code: 15181,
    division_type: "xã",
    codename: "xa_cam_phu",
    district_code: 390,
  },
  {
    name: "Xã Cẩm Vân",
    code: 15184,
    division_type: "xã",
    codename: "xa_cam_van",
    district_code: 390,
  },
  {
    name: "Thị trấn Kim Tân",
    code: 15187,
    division_type: "thị trấn",
    codename: "thi_tran_kim_tan",
    district_code: 391,
  },
  {
    name: "Thị trấn Vân Du",
    code: 15190,
    division_type: "thị trấn",
    codename: "thi_tran_van_du",
    district_code: 391,
  },
  {
    name: "Xã Thạch Lâm",
    code: 15196,
    division_type: "xã",
    codename: "xa_thach_lam",
    district_code: 391,
  },
  {
    name: "Xã Thạch Quảng",
    code: 15199,
    division_type: "xã",
    codename: "xa_thach_quang",
    district_code: 391,
  },
  {
    name: "Xã Thạch Tượng",
    code: 15202,
    division_type: "xã",
    codename: "xa_thach_tuong",
    district_code: 391,
  },
  {
    name: "Xã Thạch Cẩm",
    code: 15205,
    division_type: "xã",
    codename: "xa_thach_cam",
    district_code: 391,
  },
  {
    name: "Xã Thạch Sơn",
    code: 15208,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 391,
  },
  {
    name: "Xã Thạch Bình",
    code: 15211,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 391,
  },
  {
    name: "Xã Thạch Định",
    code: 15214,
    division_type: "xã",
    codename: "xa_thach_dinh",
    district_code: 391,
  },
  {
    name: "Xã Thạch Đồng",
    code: 15217,
    division_type: "xã",
    codename: "xa_thach_dong",
    district_code: 391,
  },
  {
    name: "Xã Thạch Long",
    code: 15220,
    division_type: "xã",
    codename: "xa_thach_long",
    district_code: 391,
  },
  {
    name: "Xã Thành Mỹ",
    code: 15223,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 391,
  },
  {
    name: "Xã Thành Yên",
    code: 15226,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 391,
  },
  {
    name: "Xã Thành Vinh",
    code: 15229,
    division_type: "xã",
    codename: "xa_thanh_vinh",
    district_code: 391,
  },
  {
    name: "Xã Thành Minh",
    code: 15232,
    division_type: "xã",
    codename: "xa_thanh_minh",
    district_code: 391,
  },
  {
    name: "Xã Thành Công",
    code: 15235,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 391,
  },
  {
    name: "Xã Thành Tân",
    code: 15238,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 391,
  },
  {
    name: "Xã Thành Trực",
    code: 15241,
    division_type: "xã",
    codename: "xa_thanh_truc",
    district_code: 391,
  },
  {
    name: "Xã Thành Tâm",
    code: 15247,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 391,
  },
  {
    name: "Xã Thành An",
    code: 15250,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 391,
  },
  {
    name: "Xã Thành Thọ",
    code: 15253,
    division_type: "xã",
    codename: "xa_thanh_tho",
    district_code: 391,
  },
  {
    name: "Xã Thành Tiến",
    code: 15256,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 391,
  },
  {
    name: "Xã Thành Long",
    code: 15259,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 391,
  },
  {
    name: "Xã Thành Hưng",
    code: 15265,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 391,
  },
  {
    name: "Xã Ngọc Trạo",
    code: 15268,
    division_type: "xã",
    codename: "xa_ngoc_trao",
    district_code: 391,
  },
  {
    name: "Thị trấn Hà Trung",
    code: 15271,
    division_type: "thị trấn",
    codename: "thi_tran_ha_trung",
    district_code: 392,
  },
  {
    name: "Xã Hà Long",
    code: 15274,
    division_type: "xã",
    codename: "xa_ha_long",
    district_code: 392,
  },
  {
    name: "Xã Hà Vinh",
    code: 15277,
    division_type: "xã",
    codename: "xa_ha_vinh",
    district_code: 392,
  },
  {
    name: "Xã Hà Bắc",
    code: 15280,
    division_type: "xã",
    codename: "xa_ha_bac",
    district_code: 392,
  },
  {
    name: "Xã Hoạt Giang",
    code: 15283,
    division_type: "xã",
    codename: "xa_hoat_giang",
    district_code: 392,
  },
  {
    name: "Xã Yên Dương",
    code: 15286,
    division_type: "xã",
    codename: "xa_yen_duong",
    district_code: 392,
  },
  {
    name: "Xã Hà Giang",
    code: 15292,
    division_type: "xã",
    codename: "xa_ha_giang",
    district_code: 392,
  },
  {
    name: "Xã Lĩnh Toại",
    code: 15298,
    division_type: "xã",
    codename: "xa_linh_toai",
    district_code: 392,
  },
  {
    name: "Xã Hà Ngọc",
    code: 15304,
    division_type: "xã",
    codename: "xa_ha_ngoc",
    district_code: 392,
  },
  {
    name: "Xã Yến Sơn",
    code: 15307,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 392,
  },
  {
    name: "Xã Hà Sơn",
    code: 15313,
    division_type: "xã",
    codename: "xa_ha_son",
    district_code: 392,
  },
  {
    name: "Xã Hà Lĩnh",
    code: 15316,
    division_type: "xã",
    codename: "xa_ha_linh",
    district_code: 392,
  },
  {
    name: "Xã Hà Đông",
    code: 15319,
    division_type: "xã",
    codename: "xa_ha_dong",
    district_code: 392,
  },
  {
    name: "Xã Hà Tân",
    code: 15322,
    division_type: "xã",
    codename: "xa_ha_tan",
    district_code: 392,
  },
  {
    name: "Xã Hà Tiến",
    code: 15325,
    division_type: "xã",
    codename: "xa_ha_tien",
    district_code: 392,
  },
  {
    name: "Xã Hà Bình",
    code: 15328,
    division_type: "xã",
    codename: "xa_ha_binh",
    district_code: 392,
  },
  {
    name: "Xã Hà Lai",
    code: 15331,
    division_type: "xã",
    codename: "xa_ha_lai",
    district_code: 392,
  },
  {
    name: "Xã Hà Châu",
    code: 15334,
    division_type: "xã",
    codename: "xa_ha_chau",
    district_code: 392,
  },
  {
    name: "Xã Hà Thái",
    code: 15340,
    division_type: "xã",
    codename: "xa_ha_thai",
    district_code: 392,
  },
  {
    name: "Xã Hà Hải",
    code: 15343,
    division_type: "xã",
    codename: "xa_ha_hai",
    district_code: 392,
  },
  {
    name: "Thị trấn Vĩnh Lộc",
    code: 15349,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_loc",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 15352,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Yên",
    code: 15355,
    division_type: "xã",
    codename: "xa_vinh_yen",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Tiến",
    code: 15358,
    division_type: "xã",
    codename: "xa_vinh_tien",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Long",
    code: 15361,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Phúc",
    code: 15364,
    division_type: "xã",
    codename: "xa_vinh_phuc",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 15367,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 15376,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Hùng",
    code: 15379,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 393,
  },
  {
    name: "Xã Minh Tân",
    code: 15382,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 393,
  },
  {
    name: "Xã Ninh Khang",
    code: 15385,
    division_type: "xã",
    codename: "xa_ninh_khang",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 15388,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 393,
  },
  {
    name: "Xã Vĩnh An",
    code: 15391,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 393,
  },
  {
    name: "Thị trấn Thống Nhất",
    code: 15397,
    division_type: "thị trấn",
    codename: "thi_tran_thong_nhat",
    district_code: 394,
  },
  {
    name: "Xã Yên Lâm",
    code: 15403,
    division_type: "xã",
    codename: "xa_yen_lam",
    district_code: 394,
  },
  {
    name: "Xã Yên Tâm",
    code: 15406,
    division_type: "xã",
    codename: "xa_yen_tam",
    district_code: 394,
  },
  {
    name: "Xã Yên Phú",
    code: 15409,
    division_type: "xã",
    codename: "xa_yen_phu",
    district_code: 394,
  },
  {
    name: "Xã Quí Lộc",
    code: 15412,
    division_type: "xã",
    codename: "xa_qui_loc",
    district_code: 394,
  },
  {
    name: "Xã Yên Thọ",
    code: 15415,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 394,
  },
  {
    name: "Xã Yên Trung",
    code: 15418,
    division_type: "xã",
    codename: "xa_yen_trung",
    district_code: 394,
  },
  {
    name: "Xã Yên Trường",
    code: 15421,
    division_type: "xã",
    codename: "xa_yen_truong",
    district_code: 394,
  },
  {
    name: "Xã Yên Phong",
    code: 15427,
    division_type: "xã",
    codename: "xa_yen_phong",
    district_code: 394,
  },
  {
    name: "Xã Yên Thái",
    code: 15430,
    division_type: "xã",
    codename: "xa_yen_thai",
    district_code: 394,
  },
  {
    name: "Xã Yên Hùng",
    code: 15433,
    division_type: "xã",
    codename: "xa_yen_hung",
    district_code: 394,
  },
  {
    name: "Xã Yên Thịnh",
    code: 15436,
    division_type: "xã",
    codename: "xa_yen_thinh",
    district_code: 394,
  },
  {
    name: "Xã Yên Ninh",
    code: 15439,
    division_type: "xã",
    codename: "xa_yen_ninh",
    district_code: 394,
  },
  {
    name: "Xã Yên Lạc",
    code: 15442,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 394,
  },
  {
    name: "Xã Định Tăng",
    code: 15445,
    division_type: "xã",
    codename: "xa_dinh_tang",
    district_code: 394,
  },
  {
    name: "Xã Định Hòa",
    code: 15448,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 394,
  },
  {
    name: "Xã Định Thành",
    code: 15451,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 394,
  },
  {
    name: "Xã Định Công",
    code: 15454,
    division_type: "xã",
    codename: "xa_dinh_cong",
    district_code: 394,
  },
  {
    name: "Xã Định Tân",
    code: 15457,
    division_type: "xã",
    codename: "xa_dinh_tan",
    district_code: 394,
  },
  {
    name: "Xã Định Tiến",
    code: 15460,
    division_type: "xã",
    codename: "xa_dinh_tien",
    district_code: 394,
  },
  {
    name: "Xã Định Long",
    code: 15463,
    division_type: "xã",
    codename: "xa_dinh_long",
    district_code: 394,
  },
  {
    name: "Xã Định Liên",
    code: 15466,
    division_type: "xã",
    codename: "xa_dinh_lien",
    district_code: 394,
  },
  {
    name: "Thị trấn Quán Lào",
    code: 15469,
    division_type: "thị trấn",
    codename: "thi_tran_quan_lao",
    district_code: 394,
  },
  {
    name: "Xã Định Hưng",
    code: 15472,
    division_type: "xã",
    codename: "xa_dinh_hung",
    district_code: 394,
  },
  {
    name: "Xã Định Hải",
    code: 15475,
    division_type: "xã",
    codename: "xa_dinh_hai",
    district_code: 394,
  },
  {
    name: "Xã Định Bình",
    code: 15478,
    division_type: "xã",
    codename: "xa_dinh_binh",
    district_code: 394,
  },
  {
    name: "Xã Xuân Hồng",
    code: 15493,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 395,
  },
  {
    name: "Thị trấn Thọ Xuân",
    code: 15499,
    division_type: "thị trấn",
    codename: "thi_tran_tho_xuan",
    district_code: 395,
  },
  {
    name: "Xã Bắc Lương",
    code: 15502,
    division_type: "xã",
    codename: "xa_bac_luong",
    district_code: 395,
  },
  {
    name: "Xã Nam Giang",
    code: 15505,
    division_type: "xã",
    codename: "xa_nam_giang",
    district_code: 395,
  },
  {
    name: "Xã Xuân Phong",
    code: 15508,
    division_type: "xã",
    codename: "xa_xuan_phong",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lộc",
    code: 15511,
    division_type: "xã",
    codename: "xa_tho_loc",
    district_code: 395,
  },
  {
    name: "Xã Xuân Trường",
    code: 15514,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 395,
  },
  {
    name: "Xã Xuân Hòa",
    code: 15517,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 395,
  },
  {
    name: "Xã Thọ Hải",
    code: 15520,
    division_type: "xã",
    codename: "xa_tho_hai",
    district_code: 395,
  },
  {
    name: "Xã Tây Hồ",
    code: 15523,
    division_type: "xã",
    codename: "xa_tay_ho",
    district_code: 395,
  },
  {
    name: "Xã Xuân Giang",
    code: 15526,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 395,
  },
  {
    name: "Xã Xuân Sinh",
    code: 15532,
    division_type: "xã",
    codename: "xa_xuan_sinh",
    district_code: 395,
  },
  {
    name: "Xã Xuân Hưng",
    code: 15535,
    division_type: "xã",
    codename: "xa_xuan_hung",
    district_code: 395,
  },
  {
    name: "Xã Thọ Diên",
    code: 15538,
    division_type: "xã",
    codename: "xa_tho_dien",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lâm",
    code: 15541,
    division_type: "xã",
    codename: "xa_tho_lam",
    district_code: 395,
  },
  {
    name: "Xã Thọ Xương",
    code: 15544,
    division_type: "xã",
    codename: "xa_tho_xuong",
    district_code: 395,
  },
  {
    name: "Xã Xuân Bái",
    code: 15547,
    division_type: "xã",
    codename: "xa_xuan_bai",
    district_code: 395,
  },
  {
    name: "Xã Xuân Phú",
    code: 15550,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 395,
  },
  {
    name: "Thị trấn Sao Vàng",
    code: 15553,
    division_type: "thị trấn",
    codename: "thi_tran_sao_vang",
    district_code: 395,
  },
  {
    name: "Thị trấn Lam Sơn",
    code: 15556,
    division_type: "thị trấn",
    codename: "thi_tran_lam_son",
    district_code: 395,
  },
  {
    name: "Xã Xuân Thiên",
    code: 15559,
    division_type: "xã",
    codename: "xa_xuan_thien",
    district_code: 395,
  },
  {
    name: "Xã Thuận Minh",
    code: 15565,
    division_type: "xã",
    codename: "xa_thuan_minh",
    district_code: 395,
  },
  {
    name: "Xã Thọ Lập",
    code: 15568,
    division_type: "xã",
    codename: "xa_tho_lap",
    district_code: 395,
  },
  {
    name: "Xã Quảng Phú",
    code: 15571,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 395,
  },
  {
    name: "Xã Xuân Tín",
    code: 15574,
    division_type: "xã",
    codename: "xa_xuan_tin",
    district_code: 395,
  },
  {
    name: "Xã Phú Xuân",
    code: 15577,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 395,
  },
  {
    name: "Xã Xuân Lai",
    code: 15583,
    division_type: "xã",
    codename: "xa_xuan_lai",
    district_code: 395,
  },
  {
    name: "Xã Xuân Lập",
    code: 15586,
    division_type: "xã",
    codename: "xa_xuan_lap",
    district_code: 395,
  },
  {
    name: "Xã Xuân Minh",
    code: 15592,
    division_type: "xã",
    codename: "xa_xuan_minh",
    district_code: 395,
  },
  {
    name: "Xã Trường Xuân",
    code: 15598,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 395,
  },
  {
    name: "Xã Bát Mọt",
    code: 15607,
    division_type: "xã",
    codename: "xa_bat_mot",
    district_code: 396,
  },
  {
    name: "Xã Yên Nhân",
    code: 15610,
    division_type: "xã",
    codename: "xa_yen_nhan",
    district_code: 396,
  },
  {
    name: "Xã Xuân Lẹ",
    code: 15619,
    division_type: "xã",
    codename: "xa_xuan_le",
    district_code: 396,
  },
  {
    name: "Xã Vạn Xuân",
    code: 15622,
    division_type: "xã",
    codename: "xa_van_xuan",
    district_code: 396,
  },
  {
    name: "Xã Lương Sơn",
    code: 15628,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 396,
  },
  {
    name: "Xã Xuân Cao",
    code: 15631,
    division_type: "xã",
    codename: "xa_xuan_cao",
    district_code: 396,
  },
  {
    name: "Xã Luận Thành",
    code: 15634,
    division_type: "xã",
    codename: "xa_luan_thanh",
    district_code: 396,
  },
  {
    name: "Xã Luận Khê",
    code: 15637,
    division_type: "xã",
    codename: "xa_luan_khe",
    district_code: 396,
  },
  {
    name: "Xã Xuân Thắng",
    code: 15640,
    division_type: "xã",
    codename: "xa_xuan_thang",
    district_code: 396,
  },
  {
    name: "Xã Xuân Lộc",
    code: 15643,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 396,
  },
  {
    name: "Thị trấn Thường Xuân",
    code: 15646,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_xuan",
    district_code: 396,
  },
  {
    name: "Xã Xuân Dương",
    code: 15649,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 396,
  },
  {
    name: "Xã Thọ Thanh",
    code: 15652,
    division_type: "xã",
    codename: "xa_tho_thanh",
    district_code: 396,
  },
  {
    name: "Xã Ngọc Phụng",
    code: 15655,
    division_type: "xã",
    codename: "xa_ngoc_phung",
    district_code: 396,
  },
  {
    name: "Xã Xuân Chinh",
    code: 15658,
    division_type: "xã",
    codename: "xa_xuan_chinh",
    district_code: 396,
  },
  {
    name: "Xã Tân Thành",
    code: 15661,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 396,
  },
  {
    name: "Thị trấn Triệu Sơn",
    code: 15664,
    division_type: "thị trấn",
    codename: "thi_tran_trieu_son",
    district_code: 397,
  },
  {
    name: "Xã Thọ Sơn",
    code: 15667,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 397,
  },
  {
    name: "Xã Thọ Bình",
    code: 15670,
    division_type: "xã",
    codename: "xa_tho_binh",
    district_code: 397,
  },
  {
    name: "Xã Thọ Tiến",
    code: 15673,
    division_type: "xã",
    codename: "xa_tho_tien",
    district_code: 397,
  },
  {
    name: "Xã Hợp Lý",
    code: 15676,
    division_type: "xã",
    codename: "xa_hop_ly",
    district_code: 397,
  },
  {
    name: "Xã Hợp Tiến",
    code: 15679,
    division_type: "xã",
    codename: "xa_hop_tien",
    district_code: 397,
  },
  {
    name: "Xã Hợp Thành",
    code: 15682,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 397,
  },
  {
    name: "Xã Triệu Thành",
    code: 15685,
    division_type: "xã",
    codename: "xa_trieu_thanh",
    district_code: 397,
  },
  {
    name: "Xã Hợp Thắng",
    code: 15688,
    division_type: "xã",
    codename: "xa_hop_thang",
    district_code: 397,
  },
  {
    name: "Xã Minh Sơn",
    code: 15691,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 397,
  },
  {
    name: "Xã Dân Lực",
    code: 15700,
    division_type: "xã",
    codename: "xa_dan_luc",
    district_code: 397,
  },
  {
    name: "Xã Dân Lý",
    code: 15703,
    division_type: "xã",
    codename: "xa_dan_ly",
    district_code: 397,
  },
  {
    name: "Xã Dân Quyền",
    code: 15706,
    division_type: "xã",
    codename: "xa_dan_quyen",
    district_code: 397,
  },
  {
    name: "Xã An Nông",
    code: 15709,
    division_type: "xã",
    codename: "xa_an_nong",
    district_code: 397,
  },
  {
    name: "Xã Văn Sơn",
    code: 15712,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 397,
  },
  {
    name: "Xã Thái Hòa",
    code: 15715,
    division_type: "xã",
    codename: "xa_thai_hoa",
    district_code: 397,
  },
  {
    name: "Thị trấn Nưa",
    code: 15718,
    division_type: "thị trấn",
    codename: "thi_tran_nua",
    district_code: 397,
  },
  {
    name: "Xã Đồng Lợi",
    code: 15721,
    division_type: "xã",
    codename: "xa_dong_loi",
    district_code: 397,
  },
  {
    name: "Xã Đồng Tiến",
    code: 15724,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 397,
  },
  {
    name: "Xã Đồng Thắng",
    code: 15727,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 397,
  },
  {
    name: "Xã Tiến Nông",
    code: 15730,
    division_type: "xã",
    codename: "xa_tien_nong",
    district_code: 397,
  },
  {
    name: "Xã Khuyến Nông",
    code: 15733,
    division_type: "xã",
    codename: "xa_khuyen_nong",
    district_code: 397,
  },
  {
    name: "Xã Xuân Thịnh",
    code: 15736,
    division_type: "xã",
    codename: "xa_xuan_thinh",
    district_code: 397,
  },
  {
    name: "Xã Xuân Lộc",
    code: 15739,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Dân",
    code: 15742,
    division_type: "xã",
    codename: "xa_tho_dan",
    district_code: 397,
  },
  {
    name: "Xã Xuân Thọ",
    code: 15745,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 397,
  },
  {
    name: "Xã Thọ Tân",
    code: 15748,
    division_type: "xã",
    codename: "xa_tho_tan",
    district_code: 397,
  },
  {
    name: "Xã Thọ Ngọc",
    code: 15751,
    division_type: "xã",
    codename: "xa_tho_ngoc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Cường",
    code: 15754,
    division_type: "xã",
    codename: "xa_tho_cuong",
    district_code: 397,
  },
  {
    name: "Xã Thọ Phú",
    code: 15757,
    division_type: "xã",
    codename: "xa_tho_phu",
    district_code: 397,
  },
  {
    name: "Xã Thọ Vực",
    code: 15760,
    division_type: "xã",
    codename: "xa_tho_vuc",
    district_code: 397,
  },
  {
    name: "Xã Thọ Thế",
    code: 15763,
    division_type: "xã",
    codename: "xa_tho_the",
    district_code: 397,
  },
  {
    name: "Xã Nông Trường",
    code: 15766,
    division_type: "xã",
    codename: "xa_nong_truong",
    district_code: 397,
  },
  {
    name: "Xã Bình Sơn",
    code: 15769,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 397,
  },
  {
    name: "Thị trấn Thiệu Hóa",
    code: 15772,
    division_type: "thị trấn",
    codename: "thi_tran_thieu_hoa",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Ngọc",
    code: 15775,
    division_type: "xã",
    codename: "xa_thieu_ngoc",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Vũ",
    code: 15778,
    division_type: "xã",
    codename: "xa_thieu_vu",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Phúc",
    code: 15781,
    division_type: "xã",
    codename: "xa_thieu_phuc",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Tiến",
    code: 15784,
    division_type: "xã",
    codename: "xa_thieu_tien",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Công",
    code: 15787,
    division_type: "xã",
    codename: "xa_thieu_cong",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Phú",
    code: 15790,
    division_type: "xã",
    codename: "xa_thieu_phu",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Long",
    code: 15793,
    division_type: "xã",
    codename: "xa_thieu_long",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Giang",
    code: 15796,
    division_type: "xã",
    codename: "xa_thieu_giang",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Duy",
    code: 15799,
    division_type: "xã",
    codename: "xa_thieu_duy",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Nguyên",
    code: 15802,
    division_type: "xã",
    codename: "xa_thieu_nguyen",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Hợp",
    code: 15805,
    division_type: "xã",
    codename: "xa_thieu_hop",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Thịnh",
    code: 15808,
    division_type: "xã",
    codename: "xa_thieu_thinh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Quang",
    code: 15811,
    division_type: "xã",
    codename: "xa_thieu_quang",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Thành",
    code: 15814,
    division_type: "xã",
    codename: "xa_thieu_thanh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Toán",
    code: 15817,
    division_type: "xã",
    codename: "xa_thieu_toan",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Chính",
    code: 15820,
    division_type: "xã",
    codename: "xa_thieu_chinh",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Hòa",
    code: 15823,
    division_type: "xã",
    codename: "xa_thieu_hoa",
    district_code: 398,
  },
  {
    name: "Xã Minh Tâm",
    code: 15829,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Viên",
    code: 15832,
    division_type: "xã",
    codename: "xa_thieu_vien",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Lý",
    code: 15835,
    division_type: "xã",
    codename: "xa_thieu_ly",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Vận",
    code: 15838,
    division_type: "xã",
    codename: "xa_thieu_van",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Trung",
    code: 15841,
    division_type: "xã",
    codename: "xa_thieu_trung",
    district_code: 398,
  },
  {
    name: "Xã Tân Châu",
    code: 15847,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 398,
  },
  {
    name: "Xã Thiệu Giao",
    code: 15853,
    division_type: "xã",
    codename: "xa_thieu_giao",
    district_code: 398,
  },
  {
    name: "Thị trấn Bút Sơn",
    code: 15865,
    division_type: "thị trấn",
    codename: "thi_tran_but_son",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Giang",
    code: 15871,
    division_type: "xã",
    codename: "xa_hoang_giang",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Xuân",
    code: 15877,
    division_type: "xã",
    codename: "xa_hoang_xuan",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phượng",
    code: 15880,
    division_type: "xã",
    codename: "xa_hoang_phuong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phú",
    code: 15883,
    division_type: "xã",
    codename: "xa_hoang_phu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Quỳ",
    code: 15886,
    division_type: "xã",
    codename: "xa_hoang_quy",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Kim",
    code: 15889,
    division_type: "xã",
    codename: "xa_hoang_kim",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trung",
    code: 15892,
    division_type: "xã",
    codename: "xa_hoang_trung",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trinh",
    code: 15895,
    division_type: "xã",
    codename: "xa_hoang_trinh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Sơn",
    code: 15901,
    division_type: "xã",
    codename: "xa_hoang_son",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Cát",
    code: 15907,
    division_type: "xã",
    codename: "xa_hoang_cat",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Xuyên",
    code: 15910,
    division_type: "xã",
    codename: "xa_hoang_xuyen",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Quý",
    code: 15916,
    division_type: "xã",
    codename: "xa_hoang_quy",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hợp",
    code: 15919,
    division_type: "xã",
    codename: "xa_hoang_hop",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đức",
    code: 15928,
    division_type: "xã",
    codename: "xa_hoang_duc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hà",
    code: 15937,
    division_type: "xã",
    codename: "xa_hoang_ha",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đạt",
    code: 15940,
    division_type: "xã",
    codename: "xa_hoang_dat",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đạo",
    code: 15946,
    division_type: "xã",
    codename: "xa_hoang_dao",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thắng",
    code: 15949,
    division_type: "xã",
    codename: "xa_hoang_thang",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đồng",
    code: 15952,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thái",
    code: 15955,
    division_type: "xã",
    codename: "xa_hoang_thai",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thịnh",
    code: 15958,
    division_type: "xã",
    codename: "xa_hoang_thinh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thành",
    code: 15961,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Lộc",
    code: 15964,
    division_type: "xã",
    codename: "xa_hoang_loc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trạch",
    code: 15967,
    division_type: "xã",
    codename: "xa_hoang_trach",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phong",
    code: 15973,
    division_type: "xã",
    codename: "xa_hoang_phong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Lưu",
    code: 15976,
    division_type: "xã",
    codename: "xa_hoang_luu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Châu",
    code: 15979,
    division_type: "xã",
    codename: "xa_hoang_chau",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Tân",
    code: 15982,
    division_type: "xã",
    codename: "xa_hoang_tan",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Yến",
    code: 15985,
    division_type: "xã",
    codename: "xa_hoang_yen",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Tiến",
    code: 15988,
    division_type: "xã",
    codename: "xa_hoang_tien",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Hải",
    code: 15991,
    division_type: "xã",
    codename: "xa_hoang_hai",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Ngọc",
    code: 15994,
    division_type: "xã",
    codename: "xa_hoang_ngoc",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Đông",
    code: 15997,
    division_type: "xã",
    codename: "xa_hoang_dong",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Thanh",
    code: 16000,
    division_type: "xã",
    codename: "xa_hoang_thanh",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Phụ",
    code: 16003,
    division_type: "xã",
    codename: "xa_hoang_phu",
    district_code: 399,
  },
  {
    name: "Xã Hoằng Trường",
    code: 16006,
    division_type: "xã",
    codename: "xa_hoang_truong",
    district_code: 399,
  },
  {
    name: "Thị trấn Hậu Lộc",
    code: 16012,
    division_type: "thị trấn",
    codename: "thi_tran_hau_loc",
    district_code: 400,
  },
  {
    name: "Xã Đồng Lộc",
    code: 16015,
    division_type: "xã",
    codename: "xa_dong_loc",
    district_code: 400,
  },
  {
    name: "Xã Đại Lộc",
    code: 16018,
    division_type: "xã",
    codename: "xa_dai_loc",
    district_code: 400,
  },
  {
    name: "Xã Triệu Lộc",
    code: 16021,
    division_type: "xã",
    codename: "xa_trieu_loc",
    district_code: 400,
  },
  {
    name: "Xã Tiến Lộc",
    code: 16027,
    division_type: "xã",
    codename: "xa_tien_loc",
    district_code: 400,
  },
  {
    name: "Xã Lộc Sơn",
    code: 16030,
    division_type: "xã",
    codename: "xa_loc_son",
    district_code: 400,
  },
  {
    name: "Xã Cầu Lộc",
    code: 16033,
    division_type: "xã",
    codename: "xa_cau_loc",
    district_code: 400,
  },
  {
    name: "Xã Thành Lộc",
    code: 16036,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 400,
  },
  {
    name: "Xã Tuy Lộc",
    code: 16039,
    division_type: "xã",
    codename: "xa_tuy_loc",
    district_code: 400,
  },
  {
    name: "Xã Phong Lộc",
    code: 16042,
    division_type: "xã",
    codename: "xa_phong_loc",
    district_code: 400,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 16045,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 400,
  },
  {
    name: "Xã Thuần Lộc",
    code: 16048,
    division_type: "xã",
    codename: "xa_thuan_loc",
    district_code: 400,
  },
  {
    name: "Xã Xuân Lộc",
    code: 16057,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 400,
  },
  {
    name: "Xã Hoa Lộc",
    code: 16063,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 400,
  },
  {
    name: "Xã Liên Lộc",
    code: 16066,
    division_type: "xã",
    codename: "xa_lien_loc",
    district_code: 400,
  },
  {
    name: "Xã Quang Lộc",
    code: 16069,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 400,
  },
  {
    name: "Xã Phú Lộc",
    code: 16072,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 400,
  },
  {
    name: "Xã Hòa Lộc",
    code: 16075,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 400,
  },
  {
    name: "Xã Minh Lộc",
    code: 16078,
    division_type: "xã",
    codename: "xa_minh_loc",
    district_code: 400,
  },
  {
    name: "Xã Hưng Lộc",
    code: 16081,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 400,
  },
  {
    name: "Xã Hải Lộc",
    code: 16084,
    division_type: "xã",
    codename: "xa_hai_loc",
    district_code: 400,
  },
  {
    name: "Xã Đa Lộc",
    code: 16087,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 400,
  },
  {
    name: "Xã Ngư Lộc",
    code: 16090,
    division_type: "xã",
    codename: "xa_ngu_loc",
    district_code: 400,
  },
  {
    name: "Thị trấn Nga Sơn",
    code: 16093,
    division_type: "thị trấn",
    codename: "thi_tran_nga_son",
    district_code: 401,
  },
  {
    name: "Xã Ba Đình",
    code: 16096,
    division_type: "xã",
    codename: "xa_ba_dinh",
    district_code: 401,
  },
  {
    name: "Xã Nga Vịnh",
    code: 16099,
    division_type: "xã",
    codename: "xa_nga_vinh",
    district_code: 401,
  },
  {
    name: "Xã Nga Văn",
    code: 16102,
    division_type: "xã",
    codename: "xa_nga_van",
    district_code: 401,
  },
  {
    name: "Xã Nga Thiện",
    code: 16105,
    division_type: "xã",
    codename: "xa_nga_thien",
    district_code: 401,
  },
  {
    name: "Xã Nga Tiến",
    code: 16108,
    division_type: "xã",
    codename: "xa_nga_tien",
    district_code: 401,
  },
  {
    name: "Xã Nga Phượng",
    code: 16114,
    division_type: "xã",
    codename: "xa_nga_phuong",
    district_code: 401,
  },
  {
    name: "Xã Nga Trung",
    code: 16117,
    division_type: "xã",
    codename: "xa_nga_trung",
    district_code: 401,
  },
  {
    name: "Xã Nga Bạch",
    code: 16120,
    division_type: "xã",
    codename: "xa_nga_bach",
    district_code: 401,
  },
  {
    name: "Xã Nga Thanh",
    code: 16123,
    division_type: "xã",
    codename: "xa_nga_thanh",
    district_code: 401,
  },
  {
    name: "Xã Nga Yên",
    code: 16132,
    division_type: "xã",
    codename: "xa_nga_yen",
    district_code: 401,
  },
  {
    name: "Xã Nga Giáp",
    code: 16135,
    division_type: "xã",
    codename: "xa_nga_giap",
    district_code: 401,
  },
  {
    name: "Xã Nga Hải",
    code: 16138,
    division_type: "xã",
    codename: "xa_nga_hai",
    district_code: 401,
  },
  {
    name: "Xã Nga Thành",
    code: 16141,
    division_type: "xã",
    codename: "xa_nga_thanh",
    district_code: 401,
  },
  {
    name: "Xã Nga An",
    code: 16144,
    division_type: "xã",
    codename: "xa_nga_an",
    district_code: 401,
  },
  {
    name: "Xã Nga Phú",
    code: 16147,
    division_type: "xã",
    codename: "xa_nga_phu",
    district_code: 401,
  },
  {
    name: "Xã Nga Điền",
    code: 16150,
    division_type: "xã",
    codename: "xa_nga_dien",
    district_code: 401,
  },
  {
    name: "Xã Nga Tân",
    code: 16153,
    division_type: "xã",
    codename: "xa_nga_tan",
    district_code: 401,
  },
  {
    name: "Xã Nga Thủy",
    code: 16156,
    division_type: "xã",
    codename: "xa_nga_thuy",
    district_code: 401,
  },
  {
    name: "Xã Nga Liên",
    code: 16159,
    division_type: "xã",
    codename: "xa_nga_lien",
    district_code: 401,
  },
  {
    name: "Xã Nga Thái",
    code: 16162,
    division_type: "xã",
    codename: "xa_nga_thai",
    district_code: 401,
  },
  {
    name: "Xã Nga Thạch",
    code: 16165,
    division_type: "xã",
    codename: "xa_nga_thach",
    district_code: 401,
  },
  {
    name: "Xã Nga Thắng",
    code: 16168,
    division_type: "xã",
    codename: "xa_nga_thang",
    district_code: 401,
  },
  {
    name: "Xã Nga Trường",
    code: 16171,
    division_type: "xã",
    codename: "xa_nga_truong",
    district_code: 401,
  },
  {
    name: "Thị trấn Yên Cát",
    code: 16174,
    division_type: "thị trấn",
    codename: "thi_tran_yen_cat",
    district_code: 402,
  },
  {
    name: "Xã Bãi Trành",
    code: 16177,
    division_type: "xã",
    codename: "xa_bai_tranh",
    district_code: 402,
  },
  {
    name: "Xã Xuân Hòa",
    code: 16180,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 402,
  },
  {
    name: "Xã Xuân Bình",
    code: 16183,
    division_type: "xã",
    codename: "xa_xuan_binh",
    district_code: 402,
  },
  {
    name: "Xã Hóa Quỳ",
    code: 16186,
    division_type: "xã",
    codename: "xa_hoa_quy",
    district_code: 402,
  },
  {
    name: "Xã Cát Vân",
    code: 16195,
    division_type: "xã",
    codename: "xa_cat_van",
    district_code: 402,
  },
  {
    name: "Xã Cát Tân",
    code: 16198,
    division_type: "xã",
    codename: "xa_cat_tan",
    district_code: 402,
  },
  {
    name: "Xã Tân Bình",
    code: 16201,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 402,
  },
  {
    name: "Xã Bình Lương",
    code: 16204,
    division_type: "xã",
    codename: "xa_binh_luong",
    district_code: 402,
  },
  {
    name: "Xã Thanh Quân",
    code: 16207,
    division_type: "xã",
    codename: "xa_thanh_quan",
    district_code: 402,
  },
  {
    name: "Xã Thanh Xuân",
    code: 16210,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 402,
  },
  {
    name: "Xã Thanh Hòa",
    code: 16213,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 402,
  },
  {
    name: "Xã Thanh Phong",
    code: 16216,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 402,
  },
  {
    name: "Xã Thanh Lâm",
    code: 16219,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 402,
  },
  {
    name: "Xã Thanh Sơn",
    code: 16222,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 402,
  },
  {
    name: "Xã Thượng Ninh",
    code: 16225,
    division_type: "xã",
    codename: "xa_thuong_ninh",
    district_code: 402,
  },
  {
    name: "Thị trấn Bến Sung",
    code: 16228,
    division_type: "thị trấn",
    codename: "thi_tran_ben_sung",
    district_code: 403,
  },
  {
    name: "Xã Cán Khê",
    code: 16231,
    division_type: "xã",
    codename: "xa_can_khe",
    district_code: 403,
  },
  {
    name: "Xã Xuân Du",
    code: 16234,
    division_type: "xã",
    codename: "xa_xuan_du",
    district_code: 403,
  },
  {
    name: "Xã Phượng Nghi",
    code: 16240,
    division_type: "xã",
    codename: "xa_phuong_nghi",
    district_code: 403,
  },
  {
    name: "Xã Mậu Lâm",
    code: 16243,
    division_type: "xã",
    codename: "xa_mau_lam",
    district_code: 403,
  },
  {
    name: "Xã Xuân Khang",
    code: 16246,
    division_type: "xã",
    codename: "xa_xuan_khang",
    district_code: 403,
  },
  {
    name: "Xã Phú Nhuận",
    code: 16249,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 403,
  },
  {
    name: "Xã Hải Long",
    code: 16252,
    division_type: "xã",
    codename: "xa_hai_long",
    district_code: 403,
  },
  {
    name: "Xã Xuân Thái",
    code: 16258,
    division_type: "xã",
    codename: "xa_xuan_thai",
    district_code: 403,
  },
  {
    name: "Xã Xuân Phúc",
    code: 16261,
    division_type: "xã",
    codename: "xa_xuan_phuc",
    district_code: 403,
  },
  {
    name: "Xã Yên Thọ",
    code: 16264,
    division_type: "xã",
    codename: "xa_yen_tho",
    district_code: 403,
  },
  {
    name: "Xã Yên Lạc",
    code: 16267,
    division_type: "xã",
    codename: "xa_yen_lac",
    district_code: 403,
  },
  {
    name: "Xã Thanh Tân",
    code: 16273,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 403,
  },
  {
    name: "Xã Thanh Kỳ",
    code: 16276,
    division_type: "xã",
    codename: "xa_thanh_ky",
    district_code: 403,
  },
  {
    name: "Thị trấn Nông Cống",
    code: 16279,
    division_type: "thị trấn",
    codename: "thi_tran_nong_cong",
    district_code: 404,
  },
  {
    name: "Xã Tân Phúc",
    code: 16282,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 404,
  },
  {
    name: "Xã Tân Thọ",
    code: 16285,
    division_type: "xã",
    codename: "xa_tan_tho",
    district_code: 404,
  },
  {
    name: "Xã Hoàng Sơn",
    code: 16288,
    division_type: "xã",
    codename: "xa_hoang_son",
    district_code: 404,
  },
  {
    name: "Xã Tân Khang",
    code: 16291,
    division_type: "xã",
    codename: "xa_tan_khang",
    district_code: 404,
  },
  {
    name: "Xã Hoàng Giang",
    code: 16294,
    division_type: "xã",
    codename: "xa_hoang_giang",
    district_code: 404,
  },
  {
    name: "Xã Trung Chính",
    code: 16297,
    division_type: "xã",
    codename: "xa_trung_chinh",
    district_code: 404,
  },
  {
    name: "Xã Trung Thành",
    code: 16303,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 404,
  },
  {
    name: "Xã Tế Thắng",
    code: 16309,
    division_type: "xã",
    codename: "xa_te_thang",
    district_code: 404,
  },
  {
    name: "Xã Tế Lợi",
    code: 16315,
    division_type: "xã",
    codename: "xa_te_loi",
    district_code: 404,
  },
  {
    name: "Xã Tế Nông",
    code: 16318,
    division_type: "xã",
    codename: "xa_te_nong",
    district_code: 404,
  },
  {
    name: "Xã Minh Nghĩa",
    code: 16321,
    division_type: "xã",
    codename: "xa_minh_nghia",
    district_code: 404,
  },
  {
    name: "Xã Minh Khôi",
    code: 16324,
    division_type: "xã",
    codename: "xa_minh_khoi",
    district_code: 404,
  },
  {
    name: "Xã Vạn Hòa",
    code: 16327,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 404,
  },
  {
    name: "Xã Trường Trung",
    code: 16330,
    division_type: "xã",
    codename: "xa_truong_trung",
    district_code: 404,
  },
  {
    name: "Xã Vạn Thắng",
    code: 16333,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 404,
  },
  {
    name: "Xã Trường Giang",
    code: 16336,
    division_type: "xã",
    codename: "xa_truong_giang",
    district_code: 404,
  },
  {
    name: "Xã Vạn Thiện",
    code: 16339,
    division_type: "xã",
    codename: "xa_van_thien",
    district_code: 404,
  },
  {
    name: "Xã Thăng Long",
    code: 16342,
    division_type: "xã",
    codename: "xa_thang_long",
    district_code: 404,
  },
  {
    name: "Xã Trường Minh",
    code: 16345,
    division_type: "xã",
    codename: "xa_truong_minh",
    district_code: 404,
  },
  {
    name: "Xã Trường Sơn",
    code: 16348,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 404,
  },
  {
    name: "Xã Thăng Bình",
    code: 16351,
    division_type: "xã",
    codename: "xa_thang_binh",
    district_code: 404,
  },
  {
    name: "Xã Công Liêm",
    code: 16354,
    division_type: "xã",
    codename: "xa_cong_liem",
    district_code: 404,
  },
  {
    name: "Xã Tượng Văn",
    code: 16357,
    division_type: "xã",
    codename: "xa_tuong_van",
    district_code: 404,
  },
  {
    name: "Xã Thăng Thọ",
    code: 16360,
    division_type: "xã",
    codename: "xa_thang_tho",
    district_code: 404,
  },
  {
    name: "Xã Tượng Lĩnh",
    code: 16363,
    division_type: "xã",
    codename: "xa_tuong_linh",
    district_code: 404,
  },
  {
    name: "Xã Tượng Sơn",
    code: 16366,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 404,
  },
  {
    name: "Xã Công Chính",
    code: 16369,
    division_type: "xã",
    codename: "xa_cong_chinh",
    district_code: 404,
  },
  {
    name: "Xã Yên Mỹ",
    code: 16375,
    division_type: "xã",
    codename: "xa_yen_my",
    district_code: 404,
  },
  {
    name: "Thị trấn Rừng Thông",
    code: 16378,
    division_type: "thị trấn",
    codename: "thi_tran_rung_thong",
    district_code: 405,
  },
  {
    name: "Xã Đông Hoàng",
    code: 16381,
    division_type: "xã",
    codename: "xa_dong_hoang",
    district_code: 405,
  },
  {
    name: "Xã Đông Ninh",
    code: 16384,
    division_type: "xã",
    codename: "xa_dong_ninh",
    district_code: 405,
  },
  {
    name: "Xã Đông Hòa",
    code: 16390,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 405,
  },
  {
    name: "Xã Đông Yên",
    code: 16393,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 405,
  },
  {
    name: "Xã Đông Minh",
    code: 16399,
    division_type: "xã",
    codename: "xa_dong_minh",
    district_code: 405,
  },
  {
    name: "Xã Đông Thanh",
    code: 16402,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 405,
  },
  {
    name: "Xã Đông Tiến",
    code: 16405,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 405,
  },
  {
    name: "Xã Đông Khê",
    code: 16408,
    division_type: "xã",
    codename: "xa_dong_khe",
    district_code: 405,
  },
  {
    name: "Xã Đông Thịnh",
    code: 16414,
    division_type: "xã",
    codename: "xa_dong_thinh",
    district_code: 405,
  },
  {
    name: "Xã Đông Văn",
    code: 16417,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 405,
  },
  {
    name: "Xã Đông Phú",
    code: 16420,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 405,
  },
  {
    name: "Xã Đông Nam",
    code: 16423,
    division_type: "xã",
    codename: "xa_dong_nam",
    district_code: 405,
  },
  {
    name: "Xã Đông Quang",
    code: 16426,
    division_type: "xã",
    codename: "xa_dong_quang",
    district_code: 405,
  },
  {
    name: "Thị trấn Tân Phong",
    code: 16438,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phong",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trạch",
    code: 16447,
    division_type: "xã",
    codename: "xa_quang_trach",
    district_code: 406,
  },
  {
    name: "Xã Quảng Đức",
    code: 16453,
    division_type: "xã",
    codename: "xa_quang_duc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Định",
    code: 16456,
    division_type: "xã",
    codename: "xa_quang_dinh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Nhân",
    code: 16462,
    division_type: "xã",
    codename: "xa_quang_nhan",
    district_code: 406,
  },
  {
    name: "Xã Quảng Ninh",
    code: 16465,
    division_type: "xã",
    codename: "xa_quang_ninh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Bình",
    code: 16468,
    division_type: "xã",
    codename: "xa_quang_binh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hợp",
    code: 16471,
    division_type: "xã",
    codename: "xa_quang_hop",
    district_code: 406,
  },
  {
    name: "Xã Quảng Văn",
    code: 16474,
    division_type: "xã",
    codename: "xa_quang_van",
    district_code: 406,
  },
  {
    name: "Xã Quảng Long",
    code: 16477,
    division_type: "xã",
    codename: "xa_quang_long",
    district_code: 406,
  },
  {
    name: "Xã Quảng Yên",
    code: 16480,
    division_type: "xã",
    codename: "xa_quang_yen",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hòa",
    code: 16483,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 406,
  },
  {
    name: "Xã Quảng Khê",
    code: 16489,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trung",
    code: 16492,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 406,
  },
  {
    name: "Xã Quảng Chính",
    code: 16495,
    division_type: "xã",
    codename: "xa_quang_chinh",
    district_code: 406,
  },
  {
    name: "Xã Quảng Ngọc",
    code: 16498,
    division_type: "xã",
    codename: "xa_quang_ngoc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Trường",
    code: 16501,
    division_type: "xã",
    codename: "xa_quang_truong",
    district_code: 406,
  },
  {
    name: "Xã Quảng Phúc",
    code: 16510,
    division_type: "xã",
    codename: "xa_quang_phuc",
    district_code: 406,
  },
  {
    name: "Xã Quảng Giao",
    code: 16519,
    division_type: "xã",
    codename: "xa_quang_giao",
    district_code: 406,
  },
  {
    name: "Xã Quảng Hải",
    code: 16540,
    division_type: "xã",
    codename: "xa_quang_hai",
    district_code: 406,
  },
  {
    name: "Xã Quảng Lưu",
    code: 16543,
    division_type: "xã",
    codename: "xa_quang_luu",
    district_code: 406,
  },
  {
    name: "Xã Quảng Lộc",
    code: 16546,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 406,
  },
  {
    name: "Xã Tiên Trang",
    code: 16549,
    division_type: "xã",
    codename: "xa_tien_trang",
    district_code: 406,
  },
  {
    name: "Xã Quảng Nham",
    code: 16552,
    division_type: "xã",
    codename: "xa_quang_nham",
    district_code: 406,
  },
  {
    name: "Xã Quảng Thạch",
    code: 16555,
    division_type: "xã",
    codename: "xa_quang_thach",
    district_code: 406,
  },
  {
    name: "Xã Quảng Thái",
    code: 16558,
    division_type: "xã",
    codename: "xa_quang_thai",
    district_code: 406,
  },
  {
    name: "Phường Hải Hòa",
    code: 16561,
    division_type: "phường",
    codename: "phuong_hai_hoa",
    district_code: 407,
  },
  {
    name: "Phường Hải Châu",
    code: 16564,
    division_type: "phường",
    codename: "phuong_hai_chau",
    district_code: 407,
  },
  {
    name: "Xã Thanh Thủy",
    code: 16567,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 407,
  },
  {
    name: "Xã Thanh Sơn",
    code: 16570,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 407,
  },
  {
    name: "Phường Hải Ninh",
    code: 16576,
    division_type: "phường",
    codename: "phuong_hai_ninh",
    district_code: 407,
  },
  {
    name: "Xã Anh Sơn",
    code: 16579,
    division_type: "xã",
    codename: "xa_anh_son",
    district_code: 407,
  },
  {
    name: "Xã Ngọc Lĩnh",
    code: 16582,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 407,
  },
  {
    name: "Phường Hải An",
    code: 16585,
    division_type: "phường",
    codename: "phuong_hai_an",
    district_code: 407,
  },
  {
    name: "Xã Các Sơn",
    code: 16591,
    division_type: "xã",
    codename: "xa_cac_son",
    district_code: 407,
  },
  {
    name: "Phường Tân Dân",
    code: 16594,
    division_type: "phường",
    codename: "phuong_tan_dan",
    district_code: 407,
  },
  {
    name: "Phường Hải Lĩnh",
    code: 16597,
    division_type: "phường",
    codename: "phuong_hai_linh",
    district_code: 407,
  },
  {
    name: "Xã Định Hải",
    code: 16600,
    division_type: "xã",
    codename: "xa_dinh_hai",
    district_code: 407,
  },
  {
    name: "Xã Phú Sơn",
    code: 16603,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 407,
  },
  {
    name: "Phường Ninh Hải",
    code: 16606,
    division_type: "phường",
    codename: "phuong_ninh_hai",
    district_code: 407,
  },
  {
    name: "Phường Nguyên Bình",
    code: 16609,
    division_type: "phường",
    codename: "phuong_nguyen_binh",
    district_code: 407,
  },
  {
    name: "Xã Hải Nhân",
    code: 16612,
    division_type: "xã",
    codename: "xa_hai_nhan",
    district_code: 407,
  },
  {
    name: "Phường Bình Minh",
    code: 16618,
    division_type: "phường",
    codename: "phuong_binh_minh",
    district_code: 407,
  },
  {
    name: "Phường Hải Thanh",
    code: 16621,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 407,
  },
  {
    name: "Xã Phú Lâm",
    code: 16624,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 407,
  },
  {
    name: "Phường Xuân Lâm",
    code: 16627,
    division_type: "phường",
    codename: "phuong_xuan_lam",
    district_code: 407,
  },
  {
    name: "Phường Trúc Lâm",
    code: 16630,
    division_type: "phường",
    codename: "phuong_truc_lam",
    district_code: 407,
  },
  {
    name: "Phường Hải Bình",
    code: 16633,
    division_type: "phường",
    codename: "phuong_hai_binh",
    district_code: 407,
  },
  {
    name: "Xã Tân Trường",
    code: 16636,
    division_type: "xã",
    codename: "xa_tan_truong",
    district_code: 407,
  },
  {
    name: "Xã Tùng Lâm",
    code: 16639,
    division_type: "xã",
    codename: "xa_tung_lam",
    district_code: 407,
  },
  {
    name: "Phường Tĩnh Hải",
    code: 16642,
    division_type: "phường",
    codename: "phuong_tinh_hai",
    district_code: 407,
  },
  {
    name: "Phường Mai Lâm",
    code: 16645,
    division_type: "phường",
    codename: "phuong_mai_lam",
    district_code: 407,
  },
  {
    name: "Xã Trường Lâm",
    code: 16648,
    division_type: "xã",
    codename: "xa_truong_lam",
    district_code: 407,
  },
  {
    name: "Xã Hải Yến",
    code: 16651,
    division_type: "xã",
    codename: "xa_hai_yen",
    district_code: 407,
  },
  {
    name: "Phường Hải Thượng",
    code: 16654,
    division_type: "phường",
    codename: "phuong_hai_thuong",
    district_code: 407,
  },
  {
    name: "Xã Nghi Sơn",
    code: 16657,
    division_type: "xã",
    codename: "xa_nghi_son",
    district_code: 407,
  },
  {
    name: "Xã Hải Hà",
    code: 16660,
    division_type: "xã",
    codename: "xa_hai_ha",
    district_code: 407,
  },
  {
    name: "Phường Đông Vĩnh",
    code: 16663,
    division_type: "phường",
    codename: "phuong_dong_vinh",
    district_code: 412,
  },
  {
    name: "Phường Hà Huy Tập",
    code: 16666,
    division_type: "phường",
    codename: "phuong_ha_huy_tap",
    district_code: 412,
  },
  {
    name: "Phường Lê Lợi",
    code: 16669,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 412,
  },
  {
    name: "Phường Quán Bàu",
    code: 16670,
    division_type: "phường",
    codename: "phuong_quan_bau",
    district_code: 412,
  },
  {
    name: "Phường Hưng Bình",
    code: 16672,
    division_type: "phường",
    codename: "phuong_hung_binh",
    district_code: 412,
  },
  {
    name: "Phường Hưng Phúc",
    code: 16673,
    division_type: "phường",
    codename: "phuong_hung_phuc",
    district_code: 412,
  },
  {
    name: "Phường Hưng Dũng",
    code: 16675,
    division_type: "phường",
    codename: "phuong_hung_dung",
    district_code: 412,
  },
  {
    name: "Phường Cửa Nam",
    code: 16678,
    division_type: "phường",
    codename: "phuong_cua_nam",
    district_code: 412,
  },
  {
    name: "Phường Quang Trung",
    code: 16681,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 412,
  },
  {
    name: "Phường Đội Cung",
    code: 16684,
    division_type: "phường",
    codename: "phuong_doi_cung",
    district_code: 412,
  },
  {
    name: "Phường Lê Mao",
    code: 16687,
    division_type: "phường",
    codename: "phuong_le_mao",
    district_code: 412,
  },
  {
    name: "Phường Trường Thi",
    code: 16690,
    division_type: "phường",
    codename: "phuong_truong_thi",
    district_code: 412,
  },
  {
    name: "Phường Bến Thủy",
    code: 16693,
    division_type: "phường",
    codename: "phuong_ben_thuy",
    district_code: 412,
  },
  {
    name: "Phường Hồng Sơn",
    code: 16696,
    division_type: "phường",
    codename: "phuong_hong_son",
    district_code: 412,
  },
  {
    name: "Phường Trung Đô",
    code: 16699,
    division_type: "phường",
    codename: "phuong_trung_do",
    district_code: 412,
  },
  {
    name: "Xã Nghi Phú",
    code: 16702,
    division_type: "xã",
    codename: "xa_nghi_phu",
    district_code: 412,
  },
  {
    name: "Xã Hưng Đông",
    code: 16705,
    division_type: "xã",
    codename: "xa_hung_dong",
    district_code: 412,
  },
  {
    name: "Xã Hưng Lộc",
    code: 16708,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 412,
  },
  {
    name: "Xã Hưng Hòa",
    code: 16711,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 412,
  },
  {
    name: "Phường Vinh Tân",
    code: 16714,
    division_type: "phường",
    codename: "phuong_vinh_tan",
    district_code: 412,
  },
  {
    name: "Xã Nghi Liên",
    code: 17908,
    division_type: "xã",
    codename: "xa_nghi_lien",
    district_code: 412,
  },
  {
    name: "Xã Nghi Ân",
    code: 17914,
    division_type: "xã",
    codename: "xa_nghi_an",
    district_code: 412,
  },
  {
    name: "Xã Nghi Kim",
    code: 17920,
    division_type: "xã",
    codename: "xa_nghi_kim",
    district_code: 412,
  },
  {
    name: "Xã Nghi Đức",
    code: 17923,
    division_type: "xã",
    codename: "xa_nghi_duc",
    district_code: 412,
  },
  {
    name: "Xã Hưng Chính",
    code: 18013,
    division_type: "xã",
    codename: "xa_hung_chinh",
    district_code: 412,
  },
  {
    name: "Phường Nghi Thuỷ",
    code: 16717,
    division_type: "phường",
    codename: "phuong_nghi_thuy",
    district_code: 413,
  },
  {
    name: "Phường Nghi Tân",
    code: 16720,
    division_type: "phường",
    codename: "phuong_nghi_tan",
    district_code: 413,
  },
  {
    name: "Phường Thu Thuỷ",
    code: 16723,
    division_type: "phường",
    codename: "phuong_thu_thuy",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hòa",
    code: 16726,
    division_type: "phường",
    codename: "phuong_nghi_hoa",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hải",
    code: 16729,
    division_type: "phường",
    codename: "phuong_nghi_hai",
    district_code: 413,
  },
  {
    name: "Phường Nghi Hương",
    code: 16732,
    division_type: "phường",
    codename: "phuong_nghi_huong",
    district_code: 413,
  },
  {
    name: "Phường Nghi Thu",
    code: 16735,
    division_type: "phường",
    codename: "phuong_nghi_thu",
    district_code: 413,
  },
  {
    name: "Phường Hoà Hiếu",
    code: 16939,
    division_type: "phường",
    codename: "phuong_hoa_hieu",
    district_code: 414,
  },
  {
    name: "Phường Quang Phong",
    code: 16993,
    division_type: "phường",
    codename: "phuong_quang_phong",
    district_code: 414,
  },
  {
    name: "Phường Quang Tiến",
    code: 16994,
    division_type: "phường",
    codename: "phuong_quang_tien",
    district_code: 414,
  },
  {
    name: "Phường Long Sơn",
    code: 17003,
    division_type: "phường",
    codename: "phuong_long_son",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Tiến",
    code: 17005,
    division_type: "xã",
    codename: "xa_nghia_tien",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Mỹ",
    code: 17008,
    division_type: "xã",
    codename: "xa_nghia_my",
    district_code: 414,
  },
  {
    name: "Xã Tây Hiếu",
    code: 17011,
    division_type: "xã",
    codename: "xa_tay_hieu",
    district_code: 414,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 17014,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 414,
  },
  {
    name: "Xã Đông Hiếu",
    code: 17017,
    division_type: "xã",
    codename: "xa_dong_hieu",
    district_code: 414,
  },
  {
    name: "Thị trấn Kim Sơn",
    code: 16738,
    division_type: "thị trấn",
    codename: "thi_tran_kim_son",
    district_code: 415,
  },
  {
    name: "Xã Thông Thụ",
    code: 16741,
    division_type: "xã",
    codename: "xa_thong_thu",
    district_code: 415,
  },
  {
    name: "Xã Đồng Văn",
    code: 16744,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 415,
  },
  {
    name: "Xã Hạnh Dịch",
    code: 16747,
    division_type: "xã",
    codename: "xa_hanh_dich",
    district_code: 415,
  },
  {
    name: "Xã Tiền Phong",
    code: 16750,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 415,
  },
  {
    name: "Xã Nậm Giải",
    code: 16753,
    division_type: "xã",
    codename: "xa_nam_giai",
    district_code: 415,
  },
  {
    name: "Xã Tri Lễ",
    code: 16756,
    division_type: "xã",
    codename: "xa_tri_le",
    district_code: 415,
  },
  {
    name: "Xã Châu Kim",
    code: 16759,
    division_type: "xã",
    codename: "xa_chau_kim",
    district_code: 415,
  },
  {
    name: "Xã Mường Nọc",
    code: 16763,
    division_type: "xã",
    codename: "xa_muong_noc",
    district_code: 415,
  },
  {
    name: "Xã Châu Thôn",
    code: 16765,
    division_type: "xã",
    codename: "xa_chau_thon",
    district_code: 415,
  },
  {
    name: "Xã Nậm Nhoóng",
    code: 16768,
    division_type: "xã",
    codename: "xa_nam_nhoong",
    district_code: 415,
  },
  {
    name: "Xã Quang Phong",
    code: 16771,
    division_type: "xã",
    codename: "xa_quang_phong",
    district_code: 415,
  },
  {
    name: "Xã Căm Muộn",
    code: 16774,
    division_type: "xã",
    codename: "xa_cam_muon",
    district_code: 415,
  },
  {
    name: "Thị trấn Tân Lạc",
    code: 16777,
    division_type: "thị trấn",
    codename: "thi_tran_tan_lac",
    district_code: 416,
  },
  {
    name: "Xã Châu Bính",
    code: 16780,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 416,
  },
  {
    name: "Xã Châu Thuận",
    code: 16783,
    division_type: "xã",
    codename: "xa_chau_thuan",
    district_code: 416,
  },
  {
    name: "Xã Châu Hội",
    code: 16786,
    division_type: "xã",
    codename: "xa_chau_hoi",
    district_code: 416,
  },
  {
    name: "Xã Châu Nga",
    code: 16789,
    division_type: "xã",
    codename: "xa_chau_nga",
    district_code: 416,
  },
  {
    name: "Xã Châu Tiến",
    code: 16792,
    division_type: "xã",
    codename: "xa_chau_tien",
    district_code: 416,
  },
  {
    name: "Xã Châu Hạnh",
    code: 16795,
    division_type: "xã",
    codename: "xa_chau_hanh",
    district_code: 416,
  },
  {
    name: "Xã Châu Thắng",
    code: 16798,
    division_type: "xã",
    codename: "xa_chau_thang",
    district_code: 416,
  },
  {
    name: "Xã Châu Phong",
    code: 16801,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 416,
  },
  {
    name: "Xã Châu Bình",
    code: 16804,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 416,
  },
  {
    name: "Xã Châu Hoàn",
    code: 16807,
    division_type: "xã",
    codename: "xa_chau_hoan",
    district_code: 416,
  },
  {
    name: "Xã Diên Lãm",
    code: 16810,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 416,
  },
  {
    name: "Thị trấn Mường Xén",
    code: 16813,
    division_type: "thị trấn",
    codename: "thi_tran_muong_xen",
    district_code: 417,
  },
  {
    name: "Xã Mỹ Lý",
    code: 16816,
    division_type: "xã",
    codename: "xa_my_ly",
    district_code: 417,
  },
  {
    name: "Xã Bắc Lý",
    code: 16819,
    division_type: "xã",
    codename: "xa_bac_ly",
    district_code: 417,
  },
  {
    name: "Xã Keng Đu",
    code: 16822,
    division_type: "xã",
    codename: "xa_keng_du",
    district_code: 417,
  },
  {
    name: "Xã Đoọc Mạy",
    code: 16825,
    division_type: "xã",
    codename: "xa_dooc_may",
    district_code: 417,
  },
  {
    name: "Xã Huồi Tụ",
    code: 16828,
    division_type: "xã",
    codename: "xa_huoi_tu",
    district_code: 417,
  },
  {
    name: "Xã Mường Lống",
    code: 16831,
    division_type: "xã",
    codename: "xa_muong_long",
    district_code: 417,
  },
  {
    name: "Xã Na Loi",
    code: 16834,
    division_type: "xã",
    codename: "xa_na_loi",
    district_code: 417,
  },
  {
    name: "Xã Nậm Cắn",
    code: 16837,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 417,
  },
  {
    name: "Xã Bảo Nam",
    code: 16840,
    division_type: "xã",
    codename: "xa_bao_nam",
    district_code: 417,
  },
  {
    name: "Xã Phà Đánh",
    code: 16843,
    division_type: "xã",
    codename: "xa_pha_danh",
    district_code: 417,
  },
  {
    name: "Xã Bảo Thắng",
    code: 16846,
    division_type: "xã",
    codename: "xa_bao_thang",
    district_code: 417,
  },
  {
    name: "Xã Hữu Lập",
    code: 16849,
    division_type: "xã",
    codename: "xa_huu_lap",
    district_code: 417,
  },
  {
    name: "Xã Tà Cạ",
    code: 16852,
    division_type: "xã",
    codename: "xa_ta_ca",
    district_code: 417,
  },
  {
    name: "Xã Chiêu Lưu",
    code: 16855,
    division_type: "xã",
    codename: "xa_chieu_luu",
    district_code: 417,
  },
  {
    name: "Xã Mường Típ",
    code: 16858,
    division_type: "xã",
    codename: "xa_muong_tip",
    district_code: 417,
  },
  {
    name: "Xã Hữu Kiệm",
    code: 16861,
    division_type: "xã",
    codename: "xa_huu_kiem",
    district_code: 417,
  },
  {
    name: "Xã Tây Sơn",
    code: 16864,
    division_type: "xã",
    codename: "xa_tay_son",
    district_code: 417,
  },
  {
    name: "Xã Mường Ải",
    code: 16867,
    division_type: "xã",
    codename: "xa_muong_ai",
    district_code: 417,
  },
  {
    name: "Xã Na Ngoi",
    code: 16870,
    division_type: "xã",
    codename: "xa_na_ngoi",
    district_code: 417,
  },
  {
    name: "Xã Nậm Càn",
    code: 16873,
    division_type: "xã",
    codename: "xa_nam_can",
    district_code: 417,
  },
  {
    name: "Thị trấn Thạch Giám",
    code: 16876,
    division_type: "thị trấn",
    codename: "thi_tran_thach_giam",
    district_code: 418,
  },
  {
    name: "Xã Mai Sơn",
    code: 16879,
    division_type: "xã",
    codename: "xa_mai_son",
    district_code: 418,
  },
  {
    name: "Xã Nhôn Mai",
    code: 16882,
    division_type: "xã",
    codename: "xa_nhon_mai",
    district_code: 418,
  },
  {
    name: "Xã Hữu Khuông",
    code: 16885,
    division_type: "xã",
    codename: "xa_huu_khuong",
    district_code: 418,
  },
  {
    name: "Xã Yên Tĩnh",
    code: 16900,
    division_type: "xã",
    codename: "xa_yen_tinh",
    district_code: 418,
  },
  {
    name: "Xã Nga My",
    code: 16903,
    division_type: "xã",
    codename: "xa_nga_my",
    district_code: 418,
  },
  {
    name: "Xã Xiêng My",
    code: 16904,
    division_type: "xã",
    codename: "xa_xieng_my",
    district_code: 418,
  },
  {
    name: "Xã Lưỡng Minh",
    code: 16906,
    division_type: "xã",
    codename: "xa_luong_minh",
    district_code: 418,
  },
  {
    name: "Xã Yên Hòa",
    code: 16909,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 418,
  },
  {
    name: "Xã Yên Na",
    code: 16912,
    division_type: "xã",
    codename: "xa_yen_na",
    district_code: 418,
  },
  {
    name: "Xã Lưu Kiền",
    code: 16915,
    division_type: "xã",
    codename: "xa_luu_kien",
    district_code: 418,
  },
  {
    name: "Xã Xá Lượng",
    code: 16921,
    division_type: "xã",
    codename: "xa_xa_luong",
    district_code: 418,
  },
  {
    name: "Xã Tam Thái",
    code: 16924,
    division_type: "xã",
    codename: "xa_tam_thai",
    district_code: 418,
  },
  {
    name: "Xã Tam Đình",
    code: 16927,
    division_type: "xã",
    codename: "xa_tam_dinh",
    district_code: 418,
  },
  {
    name: "Xã Yên Thắng",
    code: 16930,
    division_type: "xã",
    codename: "xa_yen_thang",
    district_code: 418,
  },
  {
    name: "Xã Tam Quang",
    code: 16933,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 418,
  },
  {
    name: "Xã Tam Hợp",
    code: 16936,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 418,
  },
  {
    name: "Thị trấn Nghĩa Đàn",
    code: 16941,
    division_type: "thị trấn",
    codename: "thi_tran_nghia_dan",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Mai",
    code: 16942,
    division_type: "xã",
    codename: "xa_nghia_mai",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Yên",
    code: 16945,
    division_type: "xã",
    codename: "xa_nghia_yen",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lạc",
    code: 16948,
    division_type: "xã",
    codename: "xa_nghia_lac",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 16951,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 16954,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lợi",
    code: 16957,
    division_type: "xã",
    codename: "xa_nghia_loi",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 16960,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thọ",
    code: 16963,
    division_type: "xã",
    codename: "xa_nghia_tho",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Minh",
    code: 16966,
    division_type: "xã",
    codename: "xa_nghia_minh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 16969,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 16972,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hồng",
    code: 16975,
    division_type: "xã",
    codename: "xa_nghia_hong",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thịnh",
    code: 16978,
    division_type: "xã",
    codename: "xa_nghia_thinh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 16981,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hội",
    code: 16984,
    division_type: "xã",
    codename: "xa_nghia_hoi",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 16987,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Hiếu",
    code: 16996,
    division_type: "xã",
    codename: "xa_nghia_hieu",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Đức",
    code: 17020,
    division_type: "xã",
    codename: "xa_nghia_duc",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa An",
    code: 17023,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Long",
    code: 17026,
    division_type: "xã",
    codename: "xa_nghia_long",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Lộc",
    code: 17029,
    division_type: "xã",
    codename: "xa_nghia_loc",
    district_code: 419,
  },
  {
    name: "Xã Nghĩa Khánh",
    code: 17032,
    division_type: "xã",
    codename: "xa_nghia_khanh",
    district_code: 419,
  },
  {
    name: "Thị trấn Quỳ Hợp",
    code: 17035,
    division_type: "thị trấn",
    codename: "thi_tran_quy_hop",
    district_code: 420,
  },
  {
    name: "Xã Yên Hợp",
    code: 17038,
    division_type: "xã",
    codename: "xa_yen_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Tiến",
    code: 17041,
    division_type: "xã",
    codename: "xa_chau_tien",
    district_code: 420,
  },
  {
    name: "Xã Châu Hồng",
    code: 17044,
    division_type: "xã",
    codename: "xa_chau_hong",
    district_code: 420,
  },
  {
    name: "Xã Đồng Hợp",
    code: 17047,
    division_type: "xã",
    codename: "xa_dong_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Thành",
    code: 17050,
    division_type: "xã",
    codename: "xa_chau_thanh",
    district_code: 420,
  },
  {
    name: "Xã Liên Hợp",
    code: 17053,
    division_type: "xã",
    codename: "xa_lien_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Lộc",
    code: 17056,
    division_type: "xã",
    codename: "xa_chau_loc",
    district_code: 420,
  },
  {
    name: "Xã Tam Hợp",
    code: 17059,
    division_type: "xã",
    codename: "xa_tam_hop",
    district_code: 420,
  },
  {
    name: "Xã Châu Cường",
    code: 17062,
    division_type: "xã",
    codename: "xa_chau_cuong",
    district_code: 420,
  },
  {
    name: "Xã Châu Quang",
    code: 17065,
    division_type: "xã",
    codename: "xa_chau_quang",
    district_code: 420,
  },
  {
    name: "Xã Thọ Hợp",
    code: 17068,
    division_type: "xã",
    codename: "xa_tho_hop",
    district_code: 420,
  },
  {
    name: "Xã Minh Hợp",
    code: 17071,
    division_type: "xã",
    codename: "xa_minh_hop",
    district_code: 420,
  },
  {
    name: "Xã Nghĩa Xuân",
    code: 17074,
    division_type: "xã",
    codename: "xa_nghia_xuan",
    district_code: 420,
  },
  {
    name: "Xã Châu Thái",
    code: 17077,
    division_type: "xã",
    codename: "xa_chau_thai",
    district_code: 420,
  },
  {
    name: "Xã Châu Đình",
    code: 17080,
    division_type: "xã",
    codename: "xa_chau_dinh",
    district_code: 420,
  },
  {
    name: "Xã Văn Lợi",
    code: 17083,
    division_type: "xã",
    codename: "xa_van_loi",
    district_code: 420,
  },
  {
    name: "Xã Nam Sơn",
    code: 17086,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 420,
  },
  {
    name: "Xã Châu Lý",
    code: 17089,
    division_type: "xã",
    codename: "xa_chau_ly",
    district_code: 420,
  },
  {
    name: "Xã Hạ Sơn",
    code: 17092,
    division_type: "xã",
    codename: "xa_ha_son",
    district_code: 420,
  },
  {
    name: "Xã Bắc Sơn",
    code: 17095,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 420,
  },
  {
    name: "Thị trấn Cầu Giát",
    code: 17098,
    division_type: "thị trấn",
    codename: "thi_tran_cau_giat",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thắng",
    code: 17101,
    division_type: "xã",
    codename: "xa_quynh_thang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Tân",
    code: 17119,
    division_type: "xã",
    codename: "xa_quynh_tan",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Châu",
    code: 17122,
    division_type: "xã",
    codename: "xa_quynh_chau",
    district_code: 421,
  },
  {
    name: "Xã Tân Sơn",
    code: 17140,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Văn",
    code: 17143,
    division_type: "xã",
    codename: "xa_quynh_van",
    district_code: 421,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17146,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Tam",
    code: 17149,
    division_type: "xã",
    codename: "xa_quynh_tam",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hoa",
    code: 17152,
    division_type: "xã",
    codename: "xa_quynh_hoa",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thạch",
    code: 17155,
    division_type: "xã",
    codename: "xa_quynh_thach",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Bảng",
    code: 17158,
    division_type: "xã",
    codename: "xa_quynh_bang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Mỹ",
    code: 17161,
    division_type: "xã",
    codename: "xa_quynh_my",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thanh",
    code: 17164,
    division_type: "xã",
    codename: "xa_quynh_thanh",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hậu",
    code: 17167,
    division_type: "xã",
    codename: "xa_quynh_hau",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Lâm",
    code: 17170,
    division_type: "xã",
    codename: "xa_quynh_lam",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Đôi",
    code: 17173,
    division_type: "xã",
    codename: "xa_quynh_doi",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Lương",
    code: 17176,
    division_type: "xã",
    codename: "xa_quynh_luong",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hồng",
    code: 17179,
    division_type: "xã",
    codename: "xa_quynh_hong",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Yên",
    code: 17182,
    division_type: "xã",
    codename: "xa_quynh_yen",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Bá",
    code: 17185,
    division_type: "xã",
    codename: "xa_quynh_ba",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Minh",
    code: 17188,
    division_type: "xã",
    codename: "xa_quynh_minh",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Diễn",
    code: 17191,
    division_type: "xã",
    codename: "xa_quynh_dien",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Hưng",
    code: 17194,
    division_type: "xã",
    codename: "xa_quynh_hung",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Giang",
    code: 17197,
    division_type: "xã",
    codename: "xa_quynh_giang",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Ngọc",
    code: 17200,
    division_type: "xã",
    codename: "xa_quynh_ngoc",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Nghĩa",
    code: 17203,
    division_type: "xã",
    codename: "xa_quynh_nghia",
    district_code: 421,
  },
  {
    name: "Xã An Hòa",
    code: 17206,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 421,
  },
  {
    name: "Xã Tiến Thủy",
    code: 17209,
    division_type: "xã",
    codename: "xa_tien_thuy",
    district_code: 421,
  },
  {
    name: "Xã Sơn Hải",
    code: 17212,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thọ",
    code: 17215,
    division_type: "xã",
    codename: "xa_quynh_tho",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Thuận",
    code: 17218,
    division_type: "xã",
    codename: "xa_quynh_thuan",
    district_code: 421,
  },
  {
    name: "Xã Quỳnh Long",
    code: 17221,
    division_type: "xã",
    codename: "xa_quynh_long",
    district_code: 421,
  },
  {
    name: "Xã Tân Thắng",
    code: 17224,
    division_type: "xã",
    codename: "xa_tan_thang",
    district_code: 421,
  },
  {
    name: "Thị trấn Con Cuông",
    code: 17227,
    division_type: "thị trấn",
    codename: "thi_tran_con_cuong",
    district_code: 422,
  },
  {
    name: "Xã Bình Chuẩn",
    code: 17230,
    division_type: "xã",
    codename: "xa_binh_chuan",
    district_code: 422,
  },
  {
    name: "Xã Lạng Khê",
    code: 17233,
    division_type: "xã",
    codename: "xa_lang_khe",
    district_code: 422,
  },
  {
    name: "Xã Cam Lâm",
    code: 17236,
    division_type: "xã",
    codename: "xa_cam_lam",
    district_code: 422,
  },
  {
    name: "Xã Thạch Ngàn",
    code: 17239,
    division_type: "xã",
    codename: "xa_thach_ngan",
    district_code: 422,
  },
  {
    name: "Xã Đôn Phục",
    code: 17242,
    division_type: "xã",
    codename: "xa_don_phuc",
    district_code: 422,
  },
  {
    name: "Xã Mậu Đức",
    code: 17245,
    division_type: "xã",
    codename: "xa_mau_duc",
    district_code: 422,
  },
  {
    name: "Xã Châu Khê",
    code: 17248,
    division_type: "xã",
    codename: "xa_chau_khe",
    district_code: 422,
  },
  {
    name: "Xã Chi Khê",
    code: 17251,
    division_type: "xã",
    codename: "xa_chi_khe",
    district_code: 422,
  },
  {
    name: "Xã Bồng Khê",
    code: 17254,
    division_type: "xã",
    codename: "xa_bong_khe",
    district_code: 422,
  },
  {
    name: "Xã Yên Khê",
    code: 17257,
    division_type: "xã",
    codename: "xa_yen_khe",
    district_code: 422,
  },
  {
    name: "Xã Lục Dạ",
    code: 17260,
    division_type: "xã",
    codename: "xa_luc_da",
    district_code: 422,
  },
  {
    name: "Xã Môn Sơn",
    code: 17263,
    division_type: "xã",
    codename: "xa_mon_son",
    district_code: 422,
  },
  {
    name: "Thị trấn Tân Kỳ",
    code: 17266,
    division_type: "thị trấn",
    codename: "thi_tran_tan_ky",
    district_code: 423,
  },
  {
    name: "Xã Tân Hợp",
    code: 17269,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 423,
  },
  {
    name: "Xã Tân Phú",
    code: 17272,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 423,
  },
  {
    name: "Xã Tân Xuân",
    code: 17275,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 423,
  },
  {
    name: "Xã Giai Xuân",
    code: 17278,
    division_type: "xã",
    codename: "xa_giai_xuan",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 17281,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Đồng",
    code: 17284,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 423,
  },
  {
    name: "Xã Đồng Văn",
    code: 17287,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Thái",
    code: 17290,
    division_type: "xã",
    codename: "xa_nghia_thai",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hợp",
    code: 17293,
    division_type: "xã",
    codename: "xa_nghia_hop",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hoàn",
    code: 17296,
    division_type: "xã",
    codename: "xa_nghia_hoan",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Phúc",
    code: 17299,
    division_type: "xã",
    codename: "xa_nghia_phuc",
    district_code: 423,
  },
  {
    name: "Xã Tiên Kỳ",
    code: 17302,
    division_type: "xã",
    codename: "xa_tien_ky",
    district_code: 423,
  },
  {
    name: "Xã Tân An",
    code: 17305,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Dũng",
    code: 17308,
    division_type: "xã",
    codename: "xa_nghia_dung",
    district_code: 423,
  },
  {
    name: "Xã Tân Long",
    code: 17311,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 423,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 17314,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 423,
  },
  {
    name: "Xã Hương Sơn",
    code: 17317,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 423,
  },
  {
    name: "Xã Kỳ Tân",
    code: 17320,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 423,
  },
  {
    name: "Xã Phú Sơn",
    code: 17323,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 423,
  },
  {
    name: "Xã Tân Hương",
    code: 17325,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 423,
  },
  {
    name: "Xã Nghĩa Hành",
    code: 17326,
    division_type: "xã",
    codename: "xa_nghia_hanh",
    district_code: 423,
  },
  {
    name: "Thị trấn Anh Sơn",
    code: 17329,
    division_type: "thị trấn",
    codename: "thi_tran_anh_son",
    district_code: 424,
  },
  {
    name: "Xã Thọ Sơn",
    code: 17332,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 424,
  },
  {
    name: "Xã Thành Sơn",
    code: 17335,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 424,
  },
  {
    name: "Xã Bình Sơn",
    code: 17338,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 424,
  },
  {
    name: "Xã Tam Sơn",
    code: 17341,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 424,
  },
  {
    name: "Xã Đỉnh Sơn",
    code: 17344,
    division_type: "xã",
    codename: "xa_dinh_son",
    district_code: 424,
  },
  {
    name: "Xã Hùng Sơn",
    code: 17347,
    division_type: "xã",
    codename: "xa_hung_son",
    district_code: 424,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 17350,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 424,
  },
  {
    name: "Xã Đức Sơn",
    code: 17353,
    division_type: "xã",
    codename: "xa_duc_son",
    district_code: 424,
  },
  {
    name: "Xã Tường Sơn",
    code: 17356,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 424,
  },
  {
    name: "Xã Hoa Sơn",
    code: 17357,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 424,
  },
  {
    name: "Xã Tào Sơn",
    code: 17359,
    division_type: "xã",
    codename: "xa_tao_son",
    district_code: 424,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 17362,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 424,
  },
  {
    name: "Xã Lạng Sơn",
    code: 17365,
    division_type: "xã",
    codename: "xa_lang_son",
    district_code: 424,
  },
  {
    name: "Xã Hội Sơn",
    code: 17368,
    division_type: "xã",
    codename: "xa_hoi_son",
    district_code: 424,
  },
  {
    name: "Xã Thạch Sơn",
    code: 17371,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 424,
  },
  {
    name: "Xã Phúc Sơn",
    code: 17374,
    division_type: "xã",
    codename: "xa_phuc_son",
    district_code: 424,
  },
  {
    name: "Xã Long Sơn",
    code: 17377,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 424,
  },
  {
    name: "Xã Khai Sơn",
    code: 17380,
    division_type: "xã",
    codename: "xa_khai_son",
    district_code: 424,
  },
  {
    name: "Xã Lĩnh Sơn",
    code: 17383,
    division_type: "xã",
    codename: "xa_linh_son",
    district_code: 424,
  },
  {
    name: "Xã Cao Sơn",
    code: 17386,
    division_type: "xã",
    codename: "xa_cao_son",
    district_code: 424,
  },
  {
    name: "Thị trấn Diễn Châu",
    code: 17389,
    division_type: "thị trấn",
    codename: "thi_tran_dien_chau",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lâm",
    code: 17392,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 425,
  },
  {
    name: "Xã Diễn Đoài",
    code: 17395,
    division_type: "xã",
    codename: "xa_dien_doai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Trường",
    code: 17398,
    division_type: "xã",
    codename: "xa_dien_truong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Yên",
    code: 17401,
    division_type: "xã",
    codename: "xa_dien_yen",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hoàng",
    code: 17404,
    division_type: "xã",
    codename: "xa_dien_hoang",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hùng",
    code: 17407,
    division_type: "xã",
    codename: "xa_dien_hung",
    district_code: 425,
  },
  {
    name: "Xã Diễn Mỹ",
    code: 17410,
    division_type: "xã",
    codename: "xa_dien_my",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hồng",
    code: 17413,
    division_type: "xã",
    codename: "xa_dien_hong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phong",
    code: 17416,
    division_type: "xã",
    codename: "xa_dien_phong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hải",
    code: 17419,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Tháp",
    code: 17422,
    division_type: "xã",
    codename: "xa_dien_thap",
    district_code: 425,
  },
  {
    name: "Xã Diễn Liên",
    code: 17425,
    division_type: "xã",
    codename: "xa_dien_lien",
    district_code: 425,
  },
  {
    name: "Xã Diễn Vạn",
    code: 17428,
    division_type: "xã",
    codename: "xa_dien_van",
    district_code: 425,
  },
  {
    name: "Xã Diễn Kim",
    code: 17431,
    division_type: "xã",
    codename: "xa_dien_kim",
    district_code: 425,
  },
  {
    name: "Xã Diễn Kỷ",
    code: 17434,
    division_type: "xã",
    codename: "xa_dien_ky",
    district_code: 425,
  },
  {
    name: "Xã Diễn Xuân",
    code: 17437,
    division_type: "xã",
    codename: "xa_dien_xuan",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thái",
    code: 17440,
    division_type: "xã",
    codename: "xa_dien_thai",
    district_code: 425,
  },
  {
    name: "Xã Diễn Đồng",
    code: 17443,
    division_type: "xã",
    codename: "xa_dien_dong",
    district_code: 425,
  },
  {
    name: "Xã Diễn Bích",
    code: 17446,
    division_type: "xã",
    codename: "xa_dien_bich",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hạnh",
    code: 17449,
    division_type: "xã",
    codename: "xa_dien_hanh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Ngọc",
    code: 17452,
    division_type: "xã",
    codename: "xa_dien_ngoc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Quảng",
    code: 17455,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 425,
  },
  {
    name: "Xã Diễn Nguyên",
    code: 17458,
    division_type: "xã",
    codename: "xa_dien_nguyen",
    district_code: 425,
  },
  {
    name: "Xã Diễn Hoa",
    code: 17461,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thành",
    code: 17464,
    division_type: "xã",
    codename: "xa_dien_thanh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phúc",
    code: 17467,
    division_type: "xã",
    codename: "xa_dien_phuc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Cát",
    code: 17476,
    division_type: "xã",
    codename: "xa_dien_cat",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thịnh",
    code: 17479,
    division_type: "xã",
    codename: "xa_dien_thinh",
    district_code: 425,
  },
  {
    name: "Xã Diễn Tân",
    code: 17482,
    division_type: "xã",
    codename: "xa_dien_tan",
    district_code: 425,
  },
  {
    name: "Xã Minh Châu",
    code: 17485,
    division_type: "xã",
    codename: "xa_minh_chau",
    district_code: 425,
  },
  {
    name: "Xã Diễn Thọ",
    code: 17488,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lợi",
    code: 17491,
    division_type: "xã",
    codename: "xa_dien_loi",
    district_code: 425,
  },
  {
    name: "Xã Diễn Lộc",
    code: 17494,
    division_type: "xã",
    codename: "xa_dien_loc",
    district_code: 425,
  },
  {
    name: "Xã Diễn Trung",
    code: 17497,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 425,
  },
  {
    name: "Xã Diễn An",
    code: 17500,
    division_type: "xã",
    codename: "xa_dien_an",
    district_code: 425,
  },
  {
    name: "Xã Diễn Phú",
    code: 17503,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 425,
  },
  {
    name: "Thị trấn Yên Thành",
    code: 17506,
    division_type: "thị trấn",
    codename: "thi_tran_yen_thanh",
    district_code: 426,
  },
  {
    name: "Xã Mã Thành",
    code: 17509,
    division_type: "xã",
    codename: "xa_ma_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tiến Thành",
    code: 17510,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Lăng Thành",
    code: 17512,
    division_type: "xã",
    codename: "xa_lang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tân Thành",
    code: 17515,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đức Thành",
    code: 17518,
    division_type: "xã",
    codename: "xa_duc_thanh",
    district_code: 426,
  },
  {
    name: "Xã Kim Thành",
    code: 17521,
    division_type: "xã",
    codename: "xa_kim_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hậu Thành",
    code: 17524,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hùng Thành",
    code: 17525,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đô Thành",
    code: 17527,
    division_type: "xã",
    codename: "xa_do_thanh",
    district_code: 426,
  },
  {
    name: "Xã Thọ Thành",
    code: 17530,
    division_type: "xã",
    codename: "xa_tho_thanh",
    district_code: 426,
  },
  {
    name: "Xã Quang Thành",
    code: 17533,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tây Thành",
    code: 17536,
    division_type: "xã",
    codename: "xa_tay_thanh",
    district_code: 426,
  },
  {
    name: "Xã Phúc Thành",
    code: 17539,
    division_type: "xã",
    codename: "xa_phuc_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hồng Thành",
    code: 17542,
    division_type: "xã",
    codename: "xa_hong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đồng Thành",
    code: 17545,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Phú Thành",
    code: 17548,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hoa Thành",
    code: 17551,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 426,
  },
  {
    name: "Xã Tăng Thành",
    code: 17554,
    division_type: "xã",
    codename: "xa_tang_thanh",
    district_code: 426,
  },
  {
    name: "Xã Văn Thành",
    code: 17557,
    division_type: "xã",
    codename: "xa_van_thanh",
    district_code: 426,
  },
  {
    name: "Xã Thịnh Thành",
    code: 17560,
    division_type: "xã",
    codename: "xa_thinh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Hợp Thành",
    code: 17563,
    division_type: "xã",
    codename: "xa_hop_thanh",
    district_code: 426,
  },
  {
    name: "Xã Xuân Thành",
    code: 17566,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Bắc Thành",
    code: 17569,
    division_type: "xã",
    codename: "xa_bac_thanh",
    district_code: 426,
  },
  {
    name: "Xã Nhân Thành",
    code: 17572,
    division_type: "xã",
    codename: "xa_nhan_thanh",
    district_code: 426,
  },
  {
    name: "Xã Trung Thành",
    code: 17575,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 426,
  },
  {
    name: "Xã Long Thành",
    code: 17578,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 426,
  },
  {
    name: "Xã Minh Thành",
    code: 17581,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Nam Thành",
    code: 17584,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 426,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 17587,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Lý Thành",
    code: 17590,
    division_type: "xã",
    codename: "xa_ly_thanh",
    district_code: 426,
  },
  {
    name: "Xã Khánh Thành",
    code: 17593,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 426,
  },
  {
    name: "Xã Viên Thành",
    code: 17596,
    division_type: "xã",
    codename: "xa_vien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Đại Thành",
    code: 17599,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 426,
  },
  {
    name: "Xã Liên Thành",
    code: 17602,
    division_type: "xã",
    codename: "xa_lien_thanh",
    district_code: 426,
  },
  {
    name: "Xã Bảo Thành",
    code: 17605,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 426,
  },
  {
    name: "Xã Mỹ Thành",
    code: 17608,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 426,
  },
  {
    name: "Xã Công Thành",
    code: 17611,
    division_type: "xã",
    codename: "xa_cong_thanh",
    district_code: 426,
  },
  {
    name: "Xã Sơn Thành",
    code: 17614,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 426,
  },
  {
    name: "Thị trấn Đô Lương",
    code: 17617,
    division_type: "thị trấn",
    codename: "thi_tran_do_luong",
    district_code: 427,
  },
  {
    name: "Xã Giang Sơn Đông",
    code: 17619,
    division_type: "xã",
    codename: "xa_giang_son_dong",
    district_code: 427,
  },
  {
    name: "Xã Giang Sơn Tây",
    code: 17620,
    division_type: "xã",
    codename: "xa_giang_son_tay",
    district_code: 427,
  },
  {
    name: "Xã Lam Sơn",
    code: 17623,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 427,
  },
  {
    name: "Xã Bồi Sơn",
    code: 17626,
    division_type: "xã",
    codename: "xa_boi_son",
    district_code: 427,
  },
  {
    name: "Xã Hồng Sơn",
    code: 17629,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 427,
  },
  {
    name: "Xã Bài Sơn",
    code: 17632,
    division_type: "xã",
    codename: "xa_bai_son",
    district_code: 427,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17635,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 427,
  },
  {
    name: "Xã Bắc Sơn",
    code: 17638,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 427,
  },
  {
    name: "Xã Tràng Sơn",
    code: 17641,
    division_type: "xã",
    codename: "xa_trang_son",
    district_code: 427,
  },
  {
    name: "Xã Thượng Sơn",
    code: 17644,
    division_type: "xã",
    codename: "xa_thuong_son",
    district_code: 427,
  },
  {
    name: "Xã Hòa Sơn",
    code: 17647,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 427,
  },
  {
    name: "Xã Đặng Sơn",
    code: 17650,
    division_type: "xã",
    codename: "xa_dang_son",
    district_code: 427,
  },
  {
    name: "Xã Đông Sơn",
    code: 17653,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 427,
  },
  {
    name: "Xã Nam Sơn",
    code: 17656,
    division_type: "xã",
    codename: "xa_nam_son",
    district_code: 427,
  },
  {
    name: "Xã Lưu Sơn",
    code: 17659,
    division_type: "xã",
    codename: "xa_luu_son",
    district_code: 427,
  },
  {
    name: "Xã Yên Sơn",
    code: 17662,
    division_type: "xã",
    codename: "xa_yen_son",
    district_code: 427,
  },
  {
    name: "Xã Văn Sơn",
    code: 17665,
    division_type: "xã",
    codename: "xa_van_son",
    district_code: 427,
  },
  {
    name: "Xã Đà Sơn",
    code: 17668,
    division_type: "xã",
    codename: "xa_da_son",
    district_code: 427,
  },
  {
    name: "Xã Lạc Sơn",
    code: 17671,
    division_type: "xã",
    codename: "xa_lac_son",
    district_code: 427,
  },
  {
    name: "Xã Tân Sơn",
    code: 17674,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 427,
  },
  {
    name: "Xã Thái Sơn",
    code: 17677,
    division_type: "xã",
    codename: "xa_thai_son",
    district_code: 427,
  },
  {
    name: "Xã Quang Sơn",
    code: 17680,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 427,
  },
  {
    name: "Xã Thịnh Sơn",
    code: 17683,
    division_type: "xã",
    codename: "xa_thinh_son",
    district_code: 427,
  },
  {
    name: "Xã Trung Sơn",
    code: 17686,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 427,
  },
  {
    name: "Xã Xuân Sơn",
    code: 17689,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 427,
  },
  {
    name: "Xã Minh Sơn",
    code: 17692,
    division_type: "xã",
    codename: "xa_minh_son",
    district_code: 427,
  },
  {
    name: "Xã Thuận Sơn",
    code: 17695,
    division_type: "xã",
    codename: "xa_thuan_son",
    district_code: 427,
  },
  {
    name: "Xã Nhân Sơn",
    code: 17698,
    division_type: "xã",
    codename: "xa_nhan_son",
    district_code: 427,
  },
  {
    name: "Xã Hiến Sơn",
    code: 17701,
    division_type: "xã",
    codename: "xa_hien_son",
    district_code: 427,
  },
  {
    name: "Xã Mỹ Sơn",
    code: 17704,
    division_type: "xã",
    codename: "xa_my_son",
    district_code: 427,
  },
  {
    name: "Xã Trù Sơn",
    code: 17707,
    division_type: "xã",
    codename: "xa_tru_son",
    district_code: 427,
  },
  {
    name: "Xã Đại Sơn",
    code: 17710,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 427,
  },
  {
    name: "Thị trấn Thanh Chương",
    code: 17713,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_chuong",
    district_code: 428,
  },
  {
    name: "Xã Cát Văn",
    code: 17716,
    division_type: "xã",
    codename: "xa_cat_van",
    district_code: 428,
  },
  {
    name: "Xã Thanh Nho",
    code: 17719,
    division_type: "xã",
    codename: "xa_thanh_nho",
    district_code: 428,
  },
  {
    name: "Xã Hạnh Lâm",
    code: 17722,
    division_type: "xã",
    codename: "xa_hanh_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Sơn",
    code: 17723,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hòa",
    code: 17725,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 428,
  },
  {
    name: "Xã Phong Thịnh",
    code: 17728,
    division_type: "xã",
    codename: "xa_phong_thinh",
    district_code: 428,
  },
  {
    name: "Xã Thanh Phong",
    code: 17731,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 17734,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 428,
  },
  {
    name: "Xã Thanh Tiên",
    code: 17737,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 428,
  },
  {
    name: "Xã Thanh Liên",
    code: 17743,
    division_type: "xã",
    codename: "xa_thanh_lien",
    district_code: 428,
  },
  {
    name: "Xã Đại Đồng",
    code: 17749,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Đồng",
    code: 17752,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Ngọc",
    code: 17755,
    division_type: "xã",
    codename: "xa_thanh_ngoc",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hương",
    code: 17758,
    division_type: "xã",
    codename: "xa_thanh_huong",
    district_code: 428,
  },
  {
    name: "Xã Ngọc Lâm",
    code: 17759,
    division_type: "xã",
    codename: "xa_ngoc_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lĩnh",
    code: 17761,
    division_type: "xã",
    codename: "xa_thanh_linh",
    district_code: 428,
  },
  {
    name: "Xã Đồng Văn",
    code: 17764,
    division_type: "xã",
    codename: "xa_dong_van",
    district_code: 428,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 17767,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 428,
  },
  {
    name: "Xã Thanh Thịnh",
    code: 17770,
    division_type: "xã",
    codename: "xa_thanh_thinh",
    district_code: 428,
  },
  {
    name: "Xã Thanh An",
    code: 17773,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 428,
  },
  {
    name: "Xã Thanh Chi",
    code: 17776,
    division_type: "xã",
    codename: "xa_thanh_chi",
    district_code: 428,
  },
  {
    name: "Xã Xuân Tường",
    code: 17779,
    division_type: "xã",
    codename: "xa_xuan_tuong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Dương",
    code: 17782,
    division_type: "xã",
    codename: "xa_thanh_duong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lương",
    code: 17785,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 428,
  },
  {
    name: "Xã Thanh Khê",
    code: 17788,
    division_type: "xã",
    codename: "xa_thanh_khe",
    district_code: 428,
  },
  {
    name: "Xã Võ Liệt",
    code: 17791,
    division_type: "xã",
    codename: "xa_vo_liet",
    district_code: 428,
  },
  {
    name: "Xã Thanh Long",
    code: 17794,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 428,
  },
  {
    name: "Xã Thanh Thủy",
    code: 17797,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 428,
  },
  {
    name: "Xã Thanh Khai",
    code: 17800,
    division_type: "xã",
    codename: "xa_thanh_khai",
    district_code: 428,
  },
  {
    name: "Xã Thanh Yên",
    code: 17803,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 428,
  },
  {
    name: "Xã Thanh Hà",
    code: 17806,
    division_type: "xã",
    codename: "xa_thanh_ha",
    district_code: 428,
  },
  {
    name: "Xã Thanh Giang",
    code: 17809,
    division_type: "xã",
    codename: "xa_thanh_giang",
    district_code: 428,
  },
  {
    name: "Xã Thanh Tùng",
    code: 17812,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 428,
  },
  {
    name: "Xã Thanh Lâm",
    code: 17815,
    division_type: "xã",
    codename: "xa_thanh_lam",
    district_code: 428,
  },
  {
    name: "Xã Thanh Mai",
    code: 17818,
    division_type: "xã",
    codename: "xa_thanh_mai",
    district_code: 428,
  },
  {
    name: "Xã Thanh Xuân",
    code: 17821,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 428,
  },
  {
    name: "Xã Thanh Đức",
    code: 17824,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 428,
  },
  {
    name: "Thị trấn Quán Hành",
    code: 17827,
    division_type: "thị trấn",
    codename: "thi_tran_quan_hanh",
    district_code: 429,
  },
  {
    name: "Xã Nghi Văn",
    code: 17830,
    division_type: "xã",
    codename: "xa_nghi_van",
    district_code: 429,
  },
  {
    name: "Xã Nghi Yên",
    code: 17833,
    division_type: "xã",
    codename: "xa_nghi_yen",
    district_code: 429,
  },
  {
    name: "Xã Nghi Tiến",
    code: 17836,
    division_type: "xã",
    codename: "xa_nghi_tien",
    district_code: 429,
  },
  {
    name: "Xã Nghi Hưng",
    code: 17839,
    division_type: "xã",
    codename: "xa_nghi_hung",
    district_code: 429,
  },
  {
    name: "Xã Nghi Đồng",
    code: 17842,
    division_type: "xã",
    codename: "xa_nghi_dong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thiết",
    code: 17845,
    division_type: "xã",
    codename: "xa_nghi_thiet",
    district_code: 429,
  },
  {
    name: "Xã Nghi Lâm",
    code: 17848,
    division_type: "xã",
    codename: "xa_nghi_lam",
    district_code: 429,
  },
  {
    name: "Xã Nghi Quang",
    code: 17851,
    division_type: "xã",
    codename: "xa_nghi_quang",
    district_code: 429,
  },
  {
    name: "Xã Nghi Kiều",
    code: 17854,
    division_type: "xã",
    codename: "xa_nghi_kieu",
    district_code: 429,
  },
  {
    name: "Xã Nghi Mỹ",
    code: 17857,
    division_type: "xã",
    codename: "xa_nghi_my",
    district_code: 429,
  },
  {
    name: "Xã Nghi Phương",
    code: 17860,
    division_type: "xã",
    codename: "xa_nghi_phuong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thuận",
    code: 17863,
    division_type: "xã",
    codename: "xa_nghi_thuan",
    district_code: 429,
  },
  {
    name: "Xã Nghi Long",
    code: 17866,
    division_type: "xã",
    codename: "xa_nghi_long",
    district_code: 429,
  },
  {
    name: "Xã Nghi Xá",
    code: 17869,
    division_type: "xã",
    codename: "xa_nghi_xa",
    district_code: 429,
  },
  {
    name: "Xã Nghi Hoa",
    code: 17875,
    division_type: "xã",
    codename: "xa_nghi_hoa",
    district_code: 429,
  },
  {
    name: "Xã Khánh Hợp",
    code: 17878,
    division_type: "xã",
    codename: "xa_khanh_hop",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thịnh",
    code: 17881,
    division_type: "xã",
    codename: "xa_nghi_thinh",
    district_code: 429,
  },
  {
    name: "Xã Nghi Công Bắc",
    code: 17884,
    division_type: "xã",
    codename: "xa_nghi_cong_bac",
    district_code: 429,
  },
  {
    name: "Xã Nghi Công Nam",
    code: 17887,
    division_type: "xã",
    codename: "xa_nghi_cong_nam",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thạch",
    code: 17890,
    division_type: "xã",
    codename: "xa_nghi_thach",
    district_code: 429,
  },
  {
    name: "Xã Nghi Trung",
    code: 17893,
    division_type: "xã",
    codename: "xa_nghi_trung",
    district_code: 429,
  },
  {
    name: "Xã Nghi Trường",
    code: 17896,
    division_type: "xã",
    codename: "xa_nghi_truong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Diên",
    code: 17899,
    division_type: "xã",
    codename: "xa_nghi_dien",
    district_code: 429,
  },
  {
    name: "Xã Nghi Phong",
    code: 17902,
    division_type: "xã",
    codename: "xa_nghi_phong",
    district_code: 429,
  },
  {
    name: "Xã Nghi Xuân",
    code: 17905,
    division_type: "xã",
    codename: "xa_nghi_xuan",
    district_code: 429,
  },
  {
    name: "Xã Nghi Vạn",
    code: 17911,
    division_type: "xã",
    codename: "xa_nghi_van",
    district_code: 429,
  },
  {
    name: "Xã Phúc Thọ",
    code: 17917,
    division_type: "xã",
    codename: "xa_phuc_tho",
    district_code: 429,
  },
  {
    name: "Xã Nghi Thái",
    code: 17926,
    division_type: "xã",
    codename: "xa_nghi_thai",
    district_code: 429,
  },
  {
    name: "Xã Nam Hưng",
    code: 17932,
    division_type: "xã",
    codename: "xa_nam_hung",
    district_code: 430,
  },
  {
    name: "Xã Nam Nghĩa",
    code: 17935,
    division_type: "xã",
    codename: "xa_nam_nghia",
    district_code: 430,
  },
  {
    name: "Xã Nam Thanh",
    code: 17938,
    division_type: "xã",
    codename: "xa_nam_thanh",
    district_code: 430,
  },
  {
    name: "Xã Nam Anh",
    code: 17941,
    division_type: "xã",
    codename: "xa_nam_anh",
    district_code: 430,
  },
  {
    name: "Xã Nam Xuân",
    code: 17944,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 430,
  },
  {
    name: "Xã Nam Thái",
    code: 17947,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 430,
  },
  {
    name: "Thị trấn Nam Đàn",
    code: 17950,
    division_type: "thị trấn",
    codename: "thi_tran_nam_dan",
    district_code: 430,
  },
  {
    name: "Xã Nam Lĩnh",
    code: 17953,
    division_type: "xã",
    codename: "xa_nam_linh",
    district_code: 430,
  },
  {
    name: "Xã Nam Giang",
    code: 17956,
    division_type: "xã",
    codename: "xa_nam_giang",
    district_code: 430,
  },
  {
    name: "Xã Xuân Hòa",
    code: 17959,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 430,
  },
  {
    name: "Xã Hùng Tiến",
    code: 17962,
    division_type: "xã",
    codename: "xa_hung_tien",
    district_code: 430,
  },
  {
    name: "Xã Thượng Tân Lộc",
    code: 17968,
    division_type: "xã",
    codename: "xa_thuong_tan_loc",
    district_code: 430,
  },
  {
    name: "Xã Kim Liên",
    code: 17971,
    division_type: "xã",
    codename: "xa_kim_lien",
    district_code: 430,
  },
  {
    name: "Xã Hồng Long",
    code: 17977,
    division_type: "xã",
    codename: "xa_hong_long",
    district_code: 430,
  },
  {
    name: "Xã Xuân Lâm",
    code: 17980,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 430,
  },
  {
    name: "Xã Nam Cát",
    code: 17983,
    division_type: "xã",
    codename: "xa_nam_cat",
    district_code: 430,
  },
  {
    name: "Xã Khánh Sơn",
    code: 17986,
    division_type: "xã",
    codename: "xa_khanh_son",
    district_code: 430,
  },
  {
    name: "Xã Trung Phúc Cường",
    code: 17989,
    division_type: "xã",
    codename: "xa_trung_phuc_cuong",
    district_code: 430,
  },
  {
    name: "Xã Nam Kim",
    code: 17998,
    division_type: "xã",
    codename: "xa_nam_kim",
    district_code: 430,
  },
  {
    name: "Thị trấn Hưng Nguyên",
    code: 18001,
    division_type: "thị trấn",
    codename: "thi_tran_hung_nguyen",
    district_code: 431,
  },
  {
    name: "Xã Hưng Trung",
    code: 18004,
    division_type: "xã",
    codename: "xa_hung_trung",
    district_code: 431,
  },
  {
    name: "Xã Hưng Yên",
    code: 18007,
    division_type: "xã",
    codename: "xa_hung_yen",
    district_code: 431,
  },
  {
    name: "Xã Hưng Yên Bắc",
    code: 18008,
    division_type: "xã",
    codename: "xa_hung_yen_bac",
    district_code: 431,
  },
  {
    name: "Xã Hưng Tây",
    code: 18010,
    division_type: "xã",
    codename: "xa_hung_tay",
    district_code: 431,
  },
  {
    name: "Xã Hưng Đạo",
    code: 18016,
    division_type: "xã",
    codename: "xa_hung_dao",
    district_code: 431,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 18019,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 18022,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 431,
  },
  {
    name: "Xã Hưng Lĩnh",
    code: 18025,
    division_type: "xã",
    codename: "xa_hung_linh",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thông",
    code: 18028,
    division_type: "xã",
    codename: "xa_hung_thong",
    district_code: 431,
  },
  {
    name: "Xã Hưng Tân",
    code: 18031,
    division_type: "xã",
    codename: "xa_hung_tan",
    district_code: 431,
  },
  {
    name: "Xã Hưng Lợi",
    code: 18034,
    division_type: "xã",
    codename: "xa_hung_loi",
    district_code: 431,
  },
  {
    name: "Xã Hưng Nghĩa",
    code: 18037,
    division_type: "xã",
    codename: "xa_hung_nghia",
    district_code: 431,
  },
  {
    name: "Xã Hưng Phúc",
    code: 18040,
    division_type: "xã",
    codename: "xa_hung_phuc",
    district_code: 431,
  },
  {
    name: "Xã Long Xá",
    code: 18043,
    division_type: "xã",
    codename: "xa_long_xa",
    district_code: 431,
  },
  {
    name: "Xã Châu Nhân",
    code: 18052,
    division_type: "xã",
    codename: "xa_chau_nhan",
    district_code: 431,
  },
  {
    name: "Xã Xuân Lam",
    code: 18055,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 431,
  },
  {
    name: "Xã Hưng Thành",
    code: 18064,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 431,
  },
  {
    name: "Xã Quỳnh Vinh",
    code: 17104,
    division_type: "xã",
    codename: "xa_quynh_vinh",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Lộc",
    code: 17107,
    division_type: "xã",
    codename: "xa_quynh_loc",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Thiện",
    code: 17110,
    division_type: "phường",
    codename: "phuong_quynh_thien",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Lập",
    code: 17113,
    division_type: "xã",
    codename: "xa_quynh_lap",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Trang",
    code: 17116,
    division_type: "xã",
    codename: "xa_quynh_trang",
    district_code: 432,
  },
  {
    name: "Phường Mai Hùng",
    code: 17125,
    division_type: "phường",
    codename: "phuong_mai_hung",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Dị",
    code: 17128,
    division_type: "phường",
    codename: "phuong_quynh_di",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Xuân",
    code: 17131,
    division_type: "phường",
    codename: "phuong_quynh_xuan",
    district_code: 432,
  },
  {
    name: "Phường Quỳnh Phương",
    code: 17134,
    division_type: "phường",
    codename: "phuong_quynh_phuong",
    district_code: 432,
  },
  {
    name: "Xã Quỳnh Liên",
    code: 17137,
    division_type: "xã",
    codename: "xa_quynh_lien",
    district_code: 432,
  },
  {
    name: "Phường Trần Phú",
    code: 18070,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 436,
  },
  {
    name: "Phường Nam Hà",
    code: 18073,
    division_type: "phường",
    codename: "phuong_nam_ha",
    district_code: 436,
  },
  {
    name: "Phường Bắc Hà",
    code: 18076,
    division_type: "phường",
    codename: "phuong_bac_ha",
    district_code: 436,
  },
  {
    name: "Phường Nguyễn Du",
    code: 18077,
    division_type: "phường",
    codename: "phuong_nguyen_du",
    district_code: 436,
  },
  {
    name: "Phường Tân Giang",
    code: 18079,
    division_type: "phường",
    codename: "phuong_tan_giang",
    district_code: 436,
  },
  {
    name: "Phường Đại Nài",
    code: 18082,
    division_type: "phường",
    codename: "phuong_dai_nai",
    district_code: 436,
  },
  {
    name: "Phường Hà Huy Tập",
    code: 18085,
    division_type: "phường",
    codename: "phuong_ha_huy_tap",
    district_code: 436,
  },
  {
    name: "Xã Thạch Trung",
    code: 18088,
    division_type: "xã",
    codename: "xa_thach_trung",
    district_code: 436,
  },
  {
    name: "Phường Thạch Quý",
    code: 18091,
    division_type: "phường",
    codename: "phuong_thach_quy",
    district_code: 436,
  },
  {
    name: "Phường Thạch Linh",
    code: 18094,
    division_type: "phường",
    codename: "phuong_thach_linh",
    district_code: 436,
  },
  {
    name: "Phường Văn Yên",
    code: 18097,
    division_type: "phường",
    codename: "phuong_van_yen",
    district_code: 436,
  },
  {
    name: "Xã Thạch Hạ",
    code: 18100,
    division_type: "xã",
    codename: "xa_thach_ha",
    district_code: 436,
  },
  {
    name: "Xã Đồng Môn",
    code: 18103,
    division_type: "xã",
    codename: "xa_dong_mon",
    district_code: 436,
  },
  {
    name: "Xã Thạch Hưng",
    code: 18109,
    division_type: "xã",
    codename: "xa_thach_hung",
    district_code: 436,
  },
  {
    name: "Xã Thạch Bình",
    code: 18112,
    division_type: "xã",
    codename: "xa_thach_binh",
    district_code: 436,
  },
  {
    name: "Phường Bắc Hồng",
    code: 18115,
    division_type: "phường",
    codename: "phuong_bac_hong",
    district_code: 437,
  },
  {
    name: "Phường Nam Hồng",
    code: 18118,
    division_type: "phường",
    codename: "phuong_nam_hong",
    district_code: 437,
  },
  {
    name: "Phường Trung Lương",
    code: 18121,
    division_type: "phường",
    codename: "phuong_trung_luong",
    district_code: 437,
  },
  {
    name: "Phường Đức Thuận",
    code: 18124,
    division_type: "phường",
    codename: "phuong_duc_thuan",
    district_code: 437,
  },
  {
    name: "Phường Đậu Liêu",
    code: 18127,
    division_type: "phường",
    codename: "phuong_dau_lieu",
    district_code: 437,
  },
  {
    name: "Xã Thuận Lộc",
    code: 18130,
    division_type: "xã",
    codename: "xa_thuan_loc",
    district_code: 437,
  },
  {
    name: "Thị trấn Phố Châu",
    code: 18133,
    division_type: "thị trấn",
    codename: "thi_tran_pho_chau",
    district_code: 439,
  },
  {
    name: "Thị trấn Tây Sơn",
    code: 18136,
    division_type: "thị trấn",
    codename: "thi_tran_tay_son",
    district_code: 439,
  },
  {
    name: "Xã Sơn Hồng",
    code: 18139,
    division_type: "xã",
    codename: "xa_son_hong",
    district_code: 439,
  },
  {
    name: "Xã Sơn Tiến",
    code: 18142,
    division_type: "xã",
    codename: "xa_son_tien",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lâm",
    code: 18145,
    division_type: "xã",
    codename: "xa_son_lam",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lễ",
    code: 18148,
    division_type: "xã",
    codename: "xa_son_le",
    district_code: 439,
  },
  {
    name: "Xã Sơn Giang",
    code: 18157,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 439,
  },
  {
    name: "Xã Sơn Lĩnh",
    code: 18160,
    division_type: "xã",
    codename: "xa_son_linh",
    district_code: 439,
  },
  {
    name: "Xã An Hòa Thịnh",
    code: 18163,
    division_type: "xã",
    codename: "xa_an_hoa_thinh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Tây",
    code: 18172,
    division_type: "xã",
    codename: "xa_son_tay",
    district_code: 439,
  },
  {
    name: "Xã Sơn Ninh",
    code: 18175,
    division_type: "xã",
    codename: "xa_son_ninh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Châu",
    code: 18178,
    division_type: "xã",
    codename: "xa_son_chau",
    district_code: 439,
  },
  {
    name: "Xã Tân Mỹ Hà",
    code: 18181,
    division_type: "xã",
    codename: "xa_tan_my_ha",
    district_code: 439,
  },
  {
    name: "Xã Quang Diệm",
    code: 18184,
    division_type: "xã",
    codename: "xa_quang_diem",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trung",
    code: 18187,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 439,
  },
  {
    name: "Xã Sơn Bằng",
    code: 18190,
    division_type: "xã",
    codename: "xa_son_bang",
    district_code: 439,
  },
  {
    name: "Xã Sơn Bình",
    code: 18193,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 439,
  },
  {
    name: "Xã Sơn Kim 1",
    code: 18196,
    division_type: "xã",
    codename: "xa_son_kim_1",
    district_code: 439,
  },
  {
    name: "Xã Sơn Kim 2",
    code: 18199,
    division_type: "xã",
    codename: "xa_son_kim_2",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trà",
    code: 18202,
    division_type: "xã",
    codename: "xa_son_tra",
    district_code: 439,
  },
  {
    name: "Xã Sơn Long",
    code: 18205,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 439,
  },
  {
    name: "Xã Kim Hoa",
    code: 18211,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 439,
  },
  {
    name: "Xã Sơn Hàm",
    code: 18214,
    division_type: "xã",
    codename: "xa_son_ham",
    district_code: 439,
  },
  {
    name: "Xã Sơn Phú",
    code: 18217,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 439,
  },
  {
    name: "Xã Sơn Trường",
    code: 18223,
    division_type: "xã",
    codename: "xa_son_truong",
    district_code: 439,
  },
  {
    name: "Thị trấn Đức Thọ",
    code: 18229,
    division_type: "thị trấn",
    codename: "thi_tran_duc_tho",
    district_code: 440,
  },
  {
    name: "Xã Quang Vĩnh",
    code: 18235,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 440,
  },
  {
    name: "Xã Tùng Châu",
    code: 18241,
    division_type: "xã",
    codename: "xa_tung_chau",
    district_code: 440,
  },
  {
    name: "Xã Trường Sơn",
    code: 18244,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 440,
  },
  {
    name: "Xã Liên Minh",
    code: 18247,
    division_type: "xã",
    codename: "xa_lien_minh",
    district_code: 440,
  },
  {
    name: "Xã Yên Hồ",
    code: 18253,
    division_type: "xã",
    codename: "xa_yen_ho",
    district_code: 440,
  },
  {
    name: "Xã Tùng Ảnh",
    code: 18259,
    division_type: "xã",
    codename: "xa_tung_anh",
    district_code: 440,
  },
  {
    name: "Xã Bùi La Nhân",
    code: 18262,
    division_type: "xã",
    codename: "xa_bui_la_nhan",
    district_code: 440,
  },
  {
    name: "Xã Thanh Bình Thịnh",
    code: 18274,
    division_type: "xã",
    codename: "xa_thanh_binh_thinh",
    district_code: 440,
  },
  {
    name: "Xã Lâm Trung Thủy",
    code: 18277,
    division_type: "xã",
    codename: "xa_lam_trung_thuy",
    district_code: 440,
  },
  {
    name: "Xã Hòa Lạc",
    code: 18280,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 440,
  },
  {
    name: "Xã Tân Dân",
    code: 18283,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 440,
  },
  {
    name: "Xã An Dũng",
    code: 18298,
    division_type: "xã",
    codename: "xa_an_dung",
    district_code: 440,
  },
  {
    name: "Xã Đức Đồng",
    code: 18304,
    division_type: "xã",
    codename: "xa_duc_dong",
    district_code: 440,
  },
  {
    name: "Xã Đức Lạng",
    code: 18307,
    division_type: "xã",
    codename: "xa_duc_lang",
    district_code: 440,
  },
  {
    name: "Xã Tân Hương",
    code: 18310,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 440,
  },
  {
    name: "Thị trấn Vũ Quang",
    code: 18313,
    division_type: "thị trấn",
    codename: "thi_tran_vu_quang",
    district_code: 441,
  },
  {
    name: "Xã Ân Phú",
    code: 18316,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 441,
  },
  {
    name: "Xã Đức Giang",
    code: 18319,
    division_type: "xã",
    codename: "xa_duc_giang",
    district_code: 441,
  },
  {
    name: "Xã Đức Lĩnh",
    code: 18322,
    division_type: "xã",
    codename: "xa_duc_linh",
    district_code: 441,
  },
  {
    name: "Xã Thọ Điền",
    code: 18325,
    division_type: "xã",
    codename: "xa_tho_dien",
    district_code: 441,
  },
  {
    name: "Xã Đức Hương",
    code: 18328,
    division_type: "xã",
    codename: "xa_duc_huong",
    district_code: 441,
  },
  {
    name: "Xã Đức Bồng",
    code: 18331,
    division_type: "xã",
    codename: "xa_duc_bong",
    district_code: 441,
  },
  {
    name: "Xã Đức Liên",
    code: 18334,
    division_type: "xã",
    codename: "xa_duc_lien",
    district_code: 441,
  },
  {
    name: "Xã Hương Minh",
    code: 18340,
    division_type: "xã",
    codename: "xa_huong_minh",
    district_code: 441,
  },
  {
    name: "Xã Quang Thọ",
    code: 18343,
    division_type: "xã",
    codename: "xa_quang_tho",
    district_code: 441,
  },
  {
    name: "Thị trấn Xuân An",
    code: 18352,
    division_type: "thị trấn",
    codename: "thi_tran_xuan_an",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hội",
    code: 18355,
    division_type: "xã",
    codename: "xa_xuan_hoi",
    district_code: 442,
  },
  {
    name: "Xã Đan Trường",
    code: 18358,
    division_type: "xã",
    codename: "xa_dan_truong",
    district_code: 442,
  },
  {
    name: "Xã Xuân Phổ",
    code: 18364,
    division_type: "xã",
    codename: "xa_xuan_pho",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hải",
    code: 18367,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 442,
  },
  {
    name: "Xã Xuân Giang",
    code: 18370,
    division_type: "xã",
    codename: "xa_xuan_giang",
    district_code: 442,
  },
  {
    name: "Thị trấn Tiên Điền",
    code: 18373,
    division_type: "thị trấn",
    codename: "thi_tran_tien_dien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Yên",
    code: 18376,
    division_type: "xã",
    codename: "xa_xuan_yen",
    district_code: 442,
  },
  {
    name: "Xã Xuân Mỹ",
    code: 18379,
    division_type: "xã",
    codename: "xa_xuan_my",
    district_code: 442,
  },
  {
    name: "Xã Xuân Thành",
    code: 18382,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 442,
  },
  {
    name: "Xã Xuân Viên",
    code: 18385,
    division_type: "xã",
    codename: "xa_xuan_vien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Hồng",
    code: 18388,
    division_type: "xã",
    codename: "xa_xuan_hong",
    district_code: 442,
  },
  {
    name: "Xã Cỗ Đạm",
    code: 18391,
    division_type: "xã",
    codename: "xa_co_dam",
    district_code: 442,
  },
  {
    name: "Xã Xuân Liên",
    code: 18394,
    division_type: "xã",
    codename: "xa_xuan_lien",
    district_code: 442,
  },
  {
    name: "Xã Xuân Lĩnh",
    code: 18397,
    division_type: "xã",
    codename: "xa_xuan_linh",
    district_code: 442,
  },
  {
    name: "Xã Xuân Lam",
    code: 18400,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 442,
  },
  {
    name: "Xã Cương Gián",
    code: 18403,
    division_type: "xã",
    codename: "xa_cuong_gian",
    district_code: 442,
  },
  {
    name: "Thị trấn Nghèn",
    code: 18406,
    division_type: "thị trấn",
    codename: "thi_tran_nghen",
    district_code: 443,
  },
  {
    name: "Xã Thiên Lộc",
    code: 18415,
    division_type: "xã",
    codename: "xa_thien_loc",
    district_code: 443,
  },
  {
    name: "Xã Thuần Thiện",
    code: 18418,
    division_type: "xã",
    codename: "xa_thuan_thien",
    district_code: 443,
  },
  {
    name: "Xã Vượng Lộc",
    code: 18427,
    division_type: "xã",
    codename: "xa_vuong_loc",
    district_code: 443,
  },
  {
    name: "Xã Thanh Lộc",
    code: 18433,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 443,
  },
  {
    name: "Xã Kim Song Trường",
    code: 18436,
    division_type: "xã",
    codename: "xa_kim_song_truong",
    district_code: 443,
  },
  {
    name: "Xã Thường Nga",
    code: 18439,
    division_type: "xã",
    codename: "xa_thuong_nga",
    district_code: 443,
  },
  {
    name: "Xã Tùng Lộc",
    code: 18445,
    division_type: "xã",
    codename: "xa_tung_loc",
    district_code: 443,
  },
  {
    name: "Xã Phú Lộc",
    code: 18454,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 443,
  },
  {
    name: "Xã Gia Hanh",
    code: 18463,
    division_type: "xã",
    codename: "xa_gia_hanh",
    district_code: 443,
  },
  {
    name: "Xã Khánh Vĩnh Yên",
    code: 18466,
    division_type: "xã",
    codename: "xa_khanh_vinh_yen",
    district_code: 443,
  },
  {
    name: "Xã Trung Lộc",
    code: 18472,
    division_type: "xã",
    codename: "xa_trung_loc",
    district_code: 443,
  },
  {
    name: "Xã Xuân Lộc",
    code: 18475,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 443,
  },
  {
    name: "Xã Thượng Lộc",
    code: 18478,
    division_type: "xã",
    codename: "xa_thuong_loc",
    district_code: 443,
  },
  {
    name: "Xã Quang Lộc",
    code: 18481,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 443,
  },
  {
    name: "Thị trấn Đồng Lộc",
    code: 18484,
    division_type: "thị trấn",
    codename: "thi_tran_dong_loc",
    district_code: 443,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 18487,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 443,
  },
  {
    name: "Xã Sơn Lộc",
    code: 18490,
    division_type: "xã",
    codename: "xa_son_loc",
    district_code: 443,
  },
  {
    name: "Thị trấn Hương Khê",
    code: 18496,
    division_type: "thị trấn",
    codename: "thi_tran_huong_khe",
    district_code: 444,
  },
  {
    name: "Xã Điền Mỹ",
    code: 18499,
    division_type: "xã",
    codename: "xa_dien_my",
    district_code: 444,
  },
  {
    name: "Xã Hà Linh",
    code: 18502,
    division_type: "xã",
    codename: "xa_ha_linh",
    district_code: 444,
  },
  {
    name: "Xã Hương Thủy",
    code: 18505,
    division_type: "xã",
    codename: "xa_huong_thuy",
    district_code: 444,
  },
  {
    name: "Xã Hòa Hải",
    code: 18508,
    division_type: "xã",
    codename: "xa_hoa_hai",
    district_code: 444,
  },
  {
    name: "Xã Phúc Đồng",
    code: 18514,
    division_type: "xã",
    codename: "xa_phuc_dong",
    district_code: 444,
  },
  {
    name: "Xã Hương Giang",
    code: 18517,
    division_type: "xã",
    codename: "xa_huong_giang",
    district_code: 444,
  },
  {
    name: "Xã Lộc Yên",
    code: 18520,
    division_type: "xã",
    codename: "xa_loc_yen",
    district_code: 444,
  },
  {
    name: "Xã Hương Bình",
    code: 18523,
    division_type: "xã",
    codename: "xa_huong_binh",
    district_code: 444,
  },
  {
    name: "Xã Hương Long",
    code: 18526,
    division_type: "xã",
    codename: "xa_huong_long",
    district_code: 444,
  },
  {
    name: "Xã Phú Gia",
    code: 18529,
    division_type: "xã",
    codename: "xa_phu_gia",
    district_code: 444,
  },
  {
    name: "Xã Gia Phố",
    code: 18532,
    division_type: "xã",
    codename: "xa_gia_pho",
    district_code: 444,
  },
  {
    name: "Xã Phú Phong",
    code: 18535,
    division_type: "xã",
    codename: "xa_phu_phong",
    district_code: 444,
  },
  {
    name: "Xã Hương Đô",
    code: 18538,
    division_type: "xã",
    codename: "xa_huong_do",
    district_code: 444,
  },
  {
    name: "Xã Hương Vĩnh",
    code: 18541,
    division_type: "xã",
    codename: "xa_huong_vinh",
    district_code: 444,
  },
  {
    name: "Xã Hương Xuân",
    code: 18544,
    division_type: "xã",
    codename: "xa_huong_xuan",
    district_code: 444,
  },
  {
    name: "Xã Phúc Trạch",
    code: 18547,
    division_type: "xã",
    codename: "xa_phuc_trach",
    district_code: 444,
  },
  {
    name: "Xã Hương Trà",
    code: 18550,
    division_type: "xã",
    codename: "xa_huong_tra",
    district_code: 444,
  },
  {
    name: "Xã Hương Trạch",
    code: 18553,
    division_type: "xã",
    codename: "xa_huong_trach",
    district_code: 444,
  },
  {
    name: "Xã Hương Lâm",
    code: 18556,
    division_type: "xã",
    codename: "xa_huong_lam",
    district_code: 444,
  },
  {
    name: "Xã Hương Liên",
    code: 18559,
    division_type: "xã",
    codename: "xa_huong_lien",
    district_code: 444,
  },
  {
    name: "Thị trấn Thạch Hà",
    code: 18562,
    division_type: "thị trấn",
    codename: "thi_tran_thach_ha",
    district_code: 445,
  },
  {
    name: "Xã Ngọc Sơn",
    code: 18565,
    division_type: "xã",
    codename: "xa_ngoc_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Hải",
    code: 18571,
    division_type: "xã",
    codename: "xa_thach_hai",
    district_code: 445,
  },
  {
    name: "Xã Thạch Kênh",
    code: 18586,
    division_type: "xã",
    codename: "xa_thach_kenh",
    district_code: 445,
  },
  {
    name: "Xã Thạch Sơn",
    code: 18589,
    division_type: "xã",
    codename: "xa_thach_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Liên",
    code: 18592,
    division_type: "xã",
    codename: "xa_thach_lien",
    district_code: 445,
  },
  {
    name: "Xã Đỉnh Bàn",
    code: 18595,
    division_type: "xã",
    codename: "xa_dinh_ban",
    district_code: 445,
  },
  {
    name: "Xã Việt Tiến",
    code: 18601,
    division_type: "xã",
    codename: "xa_viet_tien",
    district_code: 445,
  },
  {
    name: "Xã Thạch Khê",
    code: 18604,
    division_type: "xã",
    codename: "xa_thach_khe",
    district_code: 445,
  },
  {
    name: "Xã Thạch Long",
    code: 18607,
    division_type: "xã",
    codename: "xa_thach_long",
    district_code: 445,
  },
  {
    name: "Xã Thạch Trị",
    code: 18619,
    division_type: "xã",
    codename: "xa_thach_tri",
    district_code: 445,
  },
  {
    name: "Xã Thạch Lạc",
    code: 18622,
    division_type: "xã",
    codename: "xa_thach_lac",
    district_code: 445,
  },
  {
    name: "Xã Thạch Ngọc",
    code: 18625,
    division_type: "xã",
    codename: "xa_thach_ngoc",
    district_code: 445,
  },
  {
    name: "Xã Tượng Sơn",
    code: 18628,
    division_type: "xã",
    codename: "xa_tuong_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Văn",
    code: 18631,
    division_type: "xã",
    codename: "xa_thach_van",
    district_code: 445,
  },
  {
    name: "Xã Lưu Vĩnh Sơn",
    code: 18634,
    division_type: "xã",
    codename: "xa_luu_vinh_son",
    district_code: 445,
  },
  {
    name: "Xã Thạch Thắng",
    code: 18637,
    division_type: "xã",
    codename: "xa_thach_thang",
    district_code: 445,
  },
  {
    name: "Xã Thạch Đài",
    code: 18643,
    division_type: "xã",
    codename: "xa_thach_dai",
    district_code: 445,
  },
  {
    name: "Xã Thạch Hội",
    code: 18649,
    division_type: "xã",
    codename: "xa_thach_hoi",
    district_code: 445,
  },
  {
    name: "Xã Tân Lâm Hương",
    code: 18652,
    division_type: "xã",
    codename: "xa_tan_lam_huong",
    district_code: 445,
  },
  {
    name: "Xã Thạch Xuân",
    code: 18658,
    division_type: "xã",
    codename: "xa_thach_xuan",
    district_code: 445,
  },
  {
    name: "Xã Nam Điền",
    code: 18667,
    division_type: "xã",
    codename: "xa_nam_dien",
    district_code: 445,
  },
  {
    name: "Thị trấn Cẩm Xuyên",
    code: 18673,
    division_type: "thị trấn",
    codename: "thi_tran_cam_xuyen",
    district_code: 446,
  },
  {
    name: "Thị trấn Thiên Cầm",
    code: 18676,
    division_type: "thị trấn",
    codename: "thi_tran_thien_cam",
    district_code: 446,
  },
  {
    name: "Xã Yên Hòa",
    code: 18679,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Dương",
    code: 18682,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Bình",
    code: 18685,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Vĩnh",
    code: 18691,
    division_type: "xã",
    codename: "xa_cam_vinh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thành",
    code: 18694,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Quang",
    code: 18697,
    division_type: "xã",
    codename: "xa_cam_quang",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thạch",
    code: 18706,
    division_type: "xã",
    codename: "xa_cam_thach",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Nhượng",
    code: 18709,
    division_type: "xã",
    codename: "xa_cam_nhuong",
    district_code: 446,
  },
  {
    name: "Xã Nam Phúc Thăng",
    code: 18712,
    division_type: "xã",
    codename: "xa_nam_phuc_thang",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Duệ",
    code: 18715,
    division_type: "xã",
    codename: "xa_cam_due",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lĩnh",
    code: 18721,
    division_type: "xã",
    codename: "xa_cam_linh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Quan",
    code: 18724,
    division_type: "xã",
    codename: "xa_cam_quan",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Hà",
    code: 18727,
    division_type: "xã",
    codename: "xa_cam_ha",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lộc",
    code: 18730,
    division_type: "xã",
    codename: "xa_cam_loc",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Hưng",
    code: 18733,
    division_type: "xã",
    codename: "xa_cam_hung",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Thịnh",
    code: 18736,
    division_type: "xã",
    codename: "xa_cam_thinh",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Mỹ",
    code: 18739,
    division_type: "xã",
    codename: "xa_cam_my",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Trung",
    code: 18742,
    division_type: "xã",
    codename: "xa_cam_trung",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 18745,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Lạc",
    code: 18748,
    division_type: "xã",
    codename: "xa_cam_lac",
    district_code: 446,
  },
  {
    name: "Xã Cẩm Minh",
    code: 18751,
    division_type: "xã",
    codename: "xa_cam_minh",
    district_code: 446,
  },
  {
    name: "Xã Kỳ Xuân",
    code: 18757,
    division_type: "xã",
    codename: "xa_ky_xuan",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Bắc",
    code: 18760,
    division_type: "xã",
    codename: "xa_ky_bac",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Phú",
    code: 18763,
    division_type: "xã",
    codename: "xa_ky_phu",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Phong",
    code: 18766,
    division_type: "xã",
    codename: "xa_ky_phong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tiến",
    code: 18769,
    division_type: "xã",
    codename: "xa_ky_tien",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Giang",
    code: 18772,
    division_type: "xã",
    codename: "xa_ky_giang",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Đồng",
    code: 18775,
    division_type: "xã",
    codename: "xa_ky_dong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Khang",
    code: 18778,
    division_type: "xã",
    codename: "xa_ky_khang",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Văn",
    code: 18784,
    division_type: "xã",
    codename: "xa_ky_van",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Trung",
    code: 18787,
    division_type: "xã",
    codename: "xa_ky_trung",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thọ",
    code: 18790,
    division_type: "xã",
    codename: "xa_ky_tho",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tây",
    code: 18793,
    division_type: "xã",
    codename: "xa_ky_tay",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thượng",
    code: 18799,
    division_type: "xã",
    codename: "xa_ky_thuong",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Hải",
    code: 18802,
    division_type: "xã",
    codename: "xa_ky_hai",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Thư",
    code: 18805,
    division_type: "xã",
    codename: "xa_ky_thu",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Châu",
    code: 18811,
    division_type: "xã",
    codename: "xa_ky_chau",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Tân",
    code: 18814,
    division_type: "xã",
    codename: "xa_ky_tan",
    district_code: 447,
  },
  {
    name: "Xã Lâm Hợp",
    code: 18838,
    division_type: "xã",
    codename: "xa_lam_hop",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Sơn",
    code: 18844,
    division_type: "xã",
    codename: "xa_ky_son",
    district_code: 447,
  },
  {
    name: "Xã Kỳ Lạc",
    code: 18850,
    division_type: "xã",
    codename: "xa_ky_lac",
    district_code: 447,
  },
  {
    name: "Xã Tân Lộc",
    code: 18409,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 448,
  },
  {
    name: "Xã Hồng Lộc",
    code: 18412,
    division_type: "xã",
    codename: "xa_hong_loc",
    district_code: 448,
  },
  {
    name: "Xã Thịnh Lộc",
    code: 18421,
    division_type: "xã",
    codename: "xa_thinh_loc",
    district_code: 448,
  },
  {
    name: "Xã Bình An",
    code: 18430,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 448,
  },
  {
    name: "Xã Ích Hậu",
    code: 18457,
    division_type: "xã",
    codename: "xa_ich_hau",
    district_code: 448,
  },
  {
    name: "Xã Phù Lưu",
    code: 18493,
    division_type: "xã",
    codename: "xa_phu_luu",
    district_code: 448,
  },
  {
    name: "Thị trấn Lộc Hà",
    code: 18568,
    division_type: "thị trấn",
    codename: "thi_tran_loc_ha",
    district_code: 448,
  },
  {
    name: "Xã Thạch Mỹ",
    code: 18577,
    division_type: "xã",
    codename: "xa_thach_my",
    district_code: 448,
  },
  {
    name: "Xã Thạch Kim",
    code: 18580,
    division_type: "xã",
    codename: "xa_thach_kim",
    district_code: 448,
  },
  {
    name: "Xã Thạch Châu",
    code: 18583,
    division_type: "xã",
    codename: "xa_thach_chau",
    district_code: 448,
  },
  {
    name: "Xã Hộ Độ",
    code: 18598,
    division_type: "xã",
    codename: "xa_ho_do",
    district_code: 448,
  },
  {
    name: "Xã Mai Phụ",
    code: 18670,
    division_type: "xã",
    codename: "xa_mai_phu",
    district_code: 448,
  },
  {
    name: "Phường Hưng Trí",
    code: 18754,
    division_type: "phường",
    codename: "phuong_hung_tri",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Ninh",
    code: 18781,
    division_type: "xã",
    codename: "xa_ky_ninh",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Lợi",
    code: 18796,
    division_type: "xã",
    codename: "xa_ky_loi",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Hà",
    code: 18808,
    division_type: "xã",
    codename: "xa_ky_ha",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Trinh",
    code: 18820,
    division_type: "phường",
    codename: "phuong_ky_trinh",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Thịnh",
    code: 18823,
    division_type: "phường",
    codename: "phuong_ky_thinh",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Hoa",
    code: 18829,
    division_type: "xã",
    codename: "xa_ky_hoa",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Phương",
    code: 18832,
    division_type: "phường",
    codename: "phuong_ky_phuong",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Long",
    code: 18835,
    division_type: "phường",
    codename: "phuong_ky_long",
    district_code: 449,
  },
  {
    name: "Phường Kỳ Liên",
    code: 18841,
    division_type: "phường",
    codename: "phuong_ky_lien",
    district_code: 449,
  },
  {
    name: "Xã Kỳ Nam",
    code: 18847,
    division_type: "xã",
    codename: "xa_ky_nam",
    district_code: 449,
  },
  {
    name: "Phường Hải Thành",
    code: 18853,
    division_type: "phường",
    codename: "phuong_hai_thanh",
    district_code: 450,
  },
  {
    name: "Phường Đồng Phú",
    code: 18856,
    division_type: "phường",
    codename: "phuong_dong_phu",
    district_code: 450,
  },
  {
    name: "Phường Bắc Lý",
    code: 18859,
    division_type: "phường",
    codename: "phuong_bac_ly",
    district_code: 450,
  },
  {
    name: "Phường Nam Lý",
    code: 18865,
    division_type: "phường",
    codename: "phuong_nam_ly",
    district_code: 450,
  },
  {
    name: "Phường Đồng Hải",
    code: 18868,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 450,
  },
  {
    name: "Phường Đồng Sơn",
    code: 18871,
    division_type: "phường",
    codename: "phuong_dong_son",
    district_code: 450,
  },
  {
    name: "Phường Phú Hải",
    code: 18874,
    division_type: "phường",
    codename: "phuong_phu_hai",
    district_code: 450,
  },
  {
    name: "Phường Bắc Nghĩa",
    code: 18877,
    division_type: "phường",
    codename: "phuong_bac_nghia",
    district_code: 450,
  },
  {
    name: "Phường Đức Ninh Đông",
    code: 18880,
    division_type: "phường",
    codename: "phuong_duc_ninh_dong",
    district_code: 450,
  },
  {
    name: "Xã Quang Phú",
    code: 18883,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 450,
  },
  {
    name: "Xã Lộc Ninh",
    code: 18886,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 450,
  },
  {
    name: "Xã Bảo Ninh",
    code: 18889,
    division_type: "xã",
    codename: "xa_bao_ninh",
    district_code: 450,
  },
  {
    name: "Xã Nghĩa Ninh",
    code: 18892,
    division_type: "xã",
    codename: "xa_nghia_ninh",
    district_code: 450,
  },
  {
    name: "Xã Thuận Đức",
    code: 18895,
    division_type: "xã",
    codename: "xa_thuan_duc",
    district_code: 450,
  },
  {
    name: "Xã Đức Ninh",
    code: 18898,
    division_type: "xã",
    codename: "xa_duc_ninh",
    district_code: 450,
  },
  {
    name: "Thị trấn Quy Đạt",
    code: 18901,
    division_type: "thị trấn",
    codename: "thi_tran_quy_dat",
    district_code: 452,
  },
  {
    name: "Xã Dân Hóa",
    code: 18904,
    division_type: "xã",
    codename: "xa_dan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Trọng Hóa",
    code: 18907,
    division_type: "xã",
    codename: "xa_trong_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Phúc",
    code: 18910,
    division_type: "xã",
    codename: "xa_hoa_phuc",
    district_code: 452,
  },
  {
    name: "Xã Hồng Hóa",
    code: 18913,
    division_type: "xã",
    codename: "xa_hong_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Thanh",
    code: 18916,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 452,
  },
  {
    name: "Xã Hóa Tiến",
    code: 18919,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 452,
  },
  {
    name: "Xã Hóa Hợp",
    code: 18922,
    division_type: "xã",
    codename: "xa_hoa_hop",
    district_code: 452,
  },
  {
    name: "Xã Xuân Hóa",
    code: 18925,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Yên Hóa",
    code: 18928,
    division_type: "xã",
    codename: "xa_yen_hoa",
    district_code: 452,
  },
  {
    name: "Xã Minh Hóa",
    code: 18931,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 452,
  },
  {
    name: "Xã Tân Hóa",
    code: 18934,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 452,
  },
  {
    name: "Xã Hóa Sơn",
    code: 18937,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 452,
  },
  {
    name: "Xã Trung Hóa",
    code: 18943,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 452,
  },
  {
    name: "Xã Thượng Hóa",
    code: 18946,
    division_type: "xã",
    codename: "xa_thuong_hoa",
    district_code: 452,
  },
  {
    name: "Thị trấn Đồng Lê",
    code: 18949,
    division_type: "thị trấn",
    codename: "thi_tran_dong_le",
    district_code: 453,
  },
  {
    name: "Xã Hương Hóa",
    code: 18952,
    division_type: "xã",
    codename: "xa_huong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Kim Hóa",
    code: 18955,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thanh Hóa",
    code: 18958,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thanh Thạch",
    code: 18961,
    division_type: "xã",
    codename: "xa_thanh_thach",
    district_code: 453,
  },
  {
    name: "Xã Thuận Hóa",
    code: 18964,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 453,
  },
  {
    name: "Xã Lâm Hóa",
    code: 18967,
    division_type: "xã",
    codename: "xa_lam_hoa",
    district_code: 453,
  },
  {
    name: "Xã Lê Hóa",
    code: 18970,
    division_type: "xã",
    codename: "xa_le_hoa",
    district_code: 453,
  },
  {
    name: "Xã Sơn Hóa",
    code: 18973,
    division_type: "xã",
    codename: "xa_son_hoa",
    district_code: 453,
  },
  {
    name: "Xã Đồng Hóa",
    code: 18976,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Ngư Hóa",
    code: 18979,
    division_type: "xã",
    codename: "xa_ngu_hoa",
    district_code: 453,
  },
  {
    name: "Xã Thạch Hóa",
    code: 18985,
    division_type: "xã",
    codename: "xa_thach_hoa",
    district_code: 453,
  },
  {
    name: "Xã Đức Hóa",
    code: 18988,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 453,
  },
  {
    name: "Xã Phong Hóa",
    code: 18991,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 453,
  },
  {
    name: "Xã Mai Hóa",
    code: 18994,
    division_type: "xã",
    codename: "xa_mai_hoa",
    district_code: 453,
  },
  {
    name: "Xã Tiến Hóa",
    code: 18997,
    division_type: "xã",
    codename: "xa_tien_hoa",
    district_code: 453,
  },
  {
    name: "Xã Châu Hóa",
    code: 19000,
    division_type: "xã",
    codename: "xa_chau_hoa",
    district_code: 453,
  },
  {
    name: "Xã Cao Quảng",
    code: 19003,
    division_type: "xã",
    codename: "xa_cao_quang",
    district_code: 453,
  },
  {
    name: "Xã Văn Hóa",
    code: 19006,
    division_type: "xã",
    codename: "xa_van_hoa",
    district_code: 453,
  },
  {
    name: "Xã Quảng Hợp",
    code: 19012,
    division_type: "xã",
    codename: "xa_quang_hop",
    district_code: 454,
  },
  {
    name: "Xã Quảng Kim",
    code: 19015,
    division_type: "xã",
    codename: "xa_quang_kim",
    district_code: 454,
  },
  {
    name: "Xã Quảng Đông",
    code: 19018,
    division_type: "xã",
    codename: "xa_quang_dong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Phú",
    code: 19021,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 454,
  },
  {
    name: "Xã Quảng Châu",
    code: 19024,
    division_type: "xã",
    codename: "xa_quang_chau",
    district_code: 454,
  },
  {
    name: "Xã Quảng Thạch",
    code: 19027,
    division_type: "xã",
    codename: "xa_quang_thach",
    district_code: 454,
  },
  {
    name: "Xã Quảng Lưu",
    code: 19030,
    division_type: "xã",
    codename: "xa_quang_luu",
    district_code: 454,
  },
  {
    name: "Xã Quảng Tùng",
    code: 19033,
    division_type: "xã",
    codename: "xa_quang_tung",
    district_code: 454,
  },
  {
    name: "Xã Cảnh Dương",
    code: 19036,
    division_type: "xã",
    codename: "xa_canh_duong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Tiến",
    code: 19039,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 454,
  },
  {
    name: "Xã Quảng Hưng",
    code: 19042,
    division_type: "xã",
    codename: "xa_quang_hung",
    district_code: 454,
  },
  {
    name: "Xã Quảng Xuân",
    code: 19045,
    division_type: "xã",
    codename: "xa_quang_xuan",
    district_code: 454,
  },
  {
    name: "Xã Cảnh Hóa",
    code: 19048,
    division_type: "xã",
    codename: "xa_canh_hoa",
    district_code: 454,
  },
  {
    name: "Xã Liên Trường",
    code: 19051,
    division_type: "xã",
    codename: "xa_lien_truong",
    district_code: 454,
  },
  {
    name: "Xã Quảng Phương",
    code: 19057,
    division_type: "xã",
    codename: "xa_quang_phuong",
    district_code: 454,
  },
  {
    name: "Xã Phù Hóa",
    code: 19063,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 454,
  },
  {
    name: "Xã Quảng Thanh",
    code: 19072,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 454,
  },
  {
    name: "Thị trấn Hoàn Lão",
    code: 19111,
    division_type: "thị trấn",
    codename: "thi_tran_hoan_lao",
    district_code: 455,
  },
  {
    name: "Thị trấn NT Việt Trung",
    code: 19114,
    division_type: "thị trấn",
    codename: "thi_tran_nt_viet_trung",
    district_code: 455,
  },
  {
    name: "Xã Xuân Trạch",
    code: 19117,
    division_type: "xã",
    codename: "xa_xuan_trach",
    district_code: 455,
  },
  {
    name: "Xã Mỹ Trạch",
    code: 19120,
    division_type: "xã",
    codename: "xa_my_trach",
    district_code: 455,
  },
  {
    name: "Xã Hạ Trạch",
    code: 19123,
    division_type: "xã",
    codename: "xa_ha_trach",
    district_code: 455,
  },
  {
    name: "Xã Bắc Trạch",
    code: 19126,
    division_type: "xã",
    codename: "xa_bac_trach",
    district_code: 455,
  },
  {
    name: "Xã Lâm Trạch",
    code: 19129,
    division_type: "xã",
    codename: "xa_lam_trach",
    district_code: 455,
  },
  {
    name: "Xã Thanh Trạch",
    code: 19132,
    division_type: "xã",
    codename: "xa_thanh_trach",
    district_code: 455,
  },
  {
    name: "Xã Liên Trạch",
    code: 19135,
    division_type: "xã",
    codename: "xa_lien_trach",
    district_code: 455,
  },
  {
    name: "Xã Phúc Trạch",
    code: 19138,
    division_type: "xã",
    codename: "xa_phuc_trach",
    district_code: 455,
  },
  {
    name: "Xã Cự Nẫm",
    code: 19141,
    division_type: "xã",
    codename: "xa_cu_nam",
    district_code: 455,
  },
  {
    name: "Xã Hải Phú",
    code: 19144,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 455,
  },
  {
    name: "Xã Thượng Trạch",
    code: 19147,
    division_type: "xã",
    codename: "xa_thuong_trach",
    district_code: 455,
  },
  {
    name: "Xã Sơn Lộc",
    code: 19150,
    division_type: "xã",
    codename: "xa_son_loc",
    district_code: 455,
  },
  {
    name: "Xã Hưng Trạch",
    code: 19156,
    division_type: "xã",
    codename: "xa_hung_trach",
    district_code: 455,
  },
  {
    name: "Xã Đồng Trạch",
    code: 19159,
    division_type: "xã",
    codename: "xa_dong_trach",
    district_code: 455,
  },
  {
    name: "Xã Đức Trạch",
    code: 19162,
    division_type: "xã",
    codename: "xa_duc_trach",
    district_code: 455,
  },
  {
    name: "Thị trấn Phong Nha",
    code: 19165,
    division_type: "thị trấn",
    codename: "thi_tran_phong_nha",
    district_code: 455,
  },
  {
    name: "Xã Vạn Trạch",
    code: 19168,
    division_type: "xã",
    codename: "xa_van_trach",
    district_code: 455,
  },
  {
    name: "Xã Phú Định",
    code: 19174,
    division_type: "xã",
    codename: "xa_phu_dinh",
    district_code: 455,
  },
  {
    name: "Xã Trung Trạch",
    code: 19177,
    division_type: "xã",
    codename: "xa_trung_trach",
    district_code: 455,
  },
  {
    name: "Xã Tây Trạch",
    code: 19180,
    division_type: "xã",
    codename: "xa_tay_trach",
    district_code: 455,
  },
  {
    name: "Xã Hòa Trạch",
    code: 19183,
    division_type: "xã",
    codename: "xa_hoa_trach",
    district_code: 455,
  },
  {
    name: "Xã Đại Trạch",
    code: 19186,
    division_type: "xã",
    codename: "xa_dai_trach",
    district_code: 455,
  },
  {
    name: "Xã Nhân Trạch",
    code: 19189,
    division_type: "xã",
    codename: "xa_nhan_trach",
    district_code: 455,
  },
  {
    name: "Xã Tân Trạch",
    code: 19192,
    division_type: "xã",
    codename: "xa_tan_trach",
    district_code: 455,
  },
  {
    name: "Xã Nam Trạch",
    code: 19195,
    division_type: "xã",
    codename: "xa_nam_trach",
    district_code: 455,
  },
  {
    name: "Xã Lý Trạch",
    code: 19198,
    division_type: "xã",
    codename: "xa_ly_trach",
    district_code: 455,
  },
  {
    name: "Thị trấn Quán Hàu",
    code: 19201,
    division_type: "thị trấn",
    codename: "thi_tran_quan_hau",
    district_code: 456,
  },
  {
    name: "Xã Trường Sơn",
    code: 19204,
    division_type: "xã",
    codename: "xa_truong_son",
    district_code: 456,
  },
  {
    name: "Xã Lương Ninh",
    code: 19207,
    division_type: "xã",
    codename: "xa_luong_ninh",
    district_code: 456,
  },
  {
    name: "Xã Vĩnh Ninh",
    code: 19210,
    division_type: "xã",
    codename: "xa_vinh_ninh",
    district_code: 456,
  },
  {
    name: "Xã Võ Ninh",
    code: 19213,
    division_type: "xã",
    codename: "xa_vo_ninh",
    district_code: 456,
  },
  {
    name: "Xã Hải Ninh",
    code: 19216,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 456,
  },
  {
    name: "Xã Hàm Ninh",
    code: 19219,
    division_type: "xã",
    codename: "xa_ham_ninh",
    district_code: 456,
  },
  {
    name: "Xã Duy Ninh",
    code: 19222,
    division_type: "xã",
    codename: "xa_duy_ninh",
    district_code: 456,
  },
  {
    name: "Xã Gia Ninh",
    code: 19225,
    division_type: "xã",
    codename: "xa_gia_ninh",
    district_code: 456,
  },
  {
    name: "Xã Trường Xuân",
    code: 19228,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 456,
  },
  {
    name: "Xã Hiền Ninh",
    code: 19231,
    division_type: "xã",
    codename: "xa_hien_ninh",
    district_code: 456,
  },
  {
    name: "Xã Tân Ninh",
    code: 19234,
    division_type: "xã",
    codename: "xa_tan_ninh",
    district_code: 456,
  },
  {
    name: "Xã Xuân Ninh",
    code: 19237,
    division_type: "xã",
    codename: "xa_xuan_ninh",
    district_code: 456,
  },
  {
    name: "Xã An Ninh",
    code: 19240,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 456,
  },
  {
    name: "Xã Vạn Ninh",
    code: 19243,
    division_type: "xã",
    codename: "xa_van_ninh",
    district_code: 456,
  },
  {
    name: "Thị trấn NT Lệ Ninh",
    code: 19246,
    division_type: "thị trấn",
    codename: "thi_tran_nt_le_ninh",
    district_code: 457,
  },
  {
    name: "Thị trấn Kiến Giang",
    code: 19249,
    division_type: "thị trấn",
    codename: "thi_tran_kien_giang",
    district_code: 457,
  },
  {
    name: "Xã Hồng Thủy",
    code: 19252,
    division_type: "xã",
    codename: "xa_hong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngư Thủy Bắc",
    code: 19255,
    division_type: "xã",
    codename: "xa_ngu_thuy_bac",
    district_code: 457,
  },
  {
    name: "Xã Hoa Thủy",
    code: 19258,
    division_type: "xã",
    codename: "xa_hoa_thuy",
    district_code: 457,
  },
  {
    name: "Xã Thanh Thủy",
    code: 19261,
    division_type: "xã",
    codename: "xa_thanh_thuy",
    district_code: 457,
  },
  {
    name: "Xã An Thủy",
    code: 19264,
    division_type: "xã",
    codename: "xa_an_thuy",
    district_code: 457,
  },
  {
    name: "Xã Phong Thủy",
    code: 19267,
    division_type: "xã",
    codename: "xa_phong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Cam Thủy",
    code: 19270,
    division_type: "xã",
    codename: "xa_cam_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngân Thủy",
    code: 19273,
    division_type: "xã",
    codename: "xa_ngan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Sơn Thủy",
    code: 19276,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 457,
  },
  {
    name: "Xã Lộc Thủy",
    code: 19279,
    division_type: "xã",
    codename: "xa_loc_thuy",
    district_code: 457,
  },
  {
    name: "Xã Liên Thủy",
    code: 19285,
    division_type: "xã",
    codename: "xa_lien_thuy",
    district_code: 457,
  },
  {
    name: "Xã Hưng Thủy",
    code: 19288,
    division_type: "xã",
    codename: "xa_hung_thuy",
    district_code: 457,
  },
  {
    name: "Xã Dương Thủy",
    code: 19291,
    division_type: "xã",
    codename: "xa_duong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Tân Thủy",
    code: 19294,
    division_type: "xã",
    codename: "xa_tan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Phú Thủy",
    code: 19297,
    division_type: "xã",
    codename: "xa_phu_thuy",
    district_code: 457,
  },
  {
    name: "Xã Xuân Thủy",
    code: 19300,
    division_type: "xã",
    codename: "xa_xuan_thuy",
    district_code: 457,
  },
  {
    name: "Xã Mỹ Thủy",
    code: 19303,
    division_type: "xã",
    codename: "xa_my_thuy",
    district_code: 457,
  },
  {
    name: "Xã Ngư Thủy",
    code: 19306,
    division_type: "xã",
    codename: "xa_ngu_thuy",
    district_code: 457,
  },
  {
    name: "Xã Mai Thủy",
    code: 19309,
    division_type: "xã",
    codename: "xa_mai_thuy",
    district_code: 457,
  },
  {
    name: "Xã Sen Thủy",
    code: 19312,
    division_type: "xã",
    codename: "xa_sen_thuy",
    district_code: 457,
  },
  {
    name: "Xã Thái Thủy",
    code: 19315,
    division_type: "xã",
    codename: "xa_thai_thuy",
    district_code: 457,
  },
  {
    name: "Xã Kim Thủy",
    code: 19318,
    division_type: "xã",
    codename: "xa_kim_thuy",
    district_code: 457,
  },
  {
    name: "Xã Trường Thủy",
    code: 19321,
    division_type: "xã",
    codename: "xa_truong_thuy",
    district_code: 457,
  },
  {
    name: "Xã Lâm Thủy",
    code: 19327,
    division_type: "xã",
    codename: "xa_lam_thuy",
    district_code: 457,
  },
  {
    name: "Phường Ba Đồn",
    code: 19009,
    division_type: "phường",
    codename: "phuong_ba_don",
    district_code: 458,
  },
  {
    name: "Phường Quảng Long",
    code: 19060,
    division_type: "phường",
    codename: "phuong_quang_long",
    district_code: 458,
  },
  {
    name: "Phường Quảng Thọ",
    code: 19066,
    division_type: "phường",
    codename: "phuong_quang_tho",
    district_code: 458,
  },
  {
    name: "Xã Quảng Tiên",
    code: 19069,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 458,
  },
  {
    name: "Xã Quảng Trung",
    code: 19075,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 458,
  },
  {
    name: "Phường Quảng Phong",
    code: 19078,
    division_type: "phường",
    codename: "phuong_quang_phong",
    district_code: 458,
  },
  {
    name: "Phường Quảng Thuận",
    code: 19081,
    division_type: "phường",
    codename: "phuong_quang_thuan",
    district_code: 458,
  },
  {
    name: "Xã Quảng Tân",
    code: 19084,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 458,
  },
  {
    name: "Xã Quảng Hải",
    code: 19087,
    division_type: "xã",
    codename: "xa_quang_hai",
    district_code: 458,
  },
  {
    name: "Xã Quảng Sơn",
    code: 19090,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 458,
  },
  {
    name: "Xã Quảng Lộc",
    code: 19093,
    division_type: "xã",
    codename: "xa_quang_loc",
    district_code: 458,
  },
  {
    name: "Xã Quảng Thủy",
    code: 19096,
    division_type: "xã",
    codename: "xa_quang_thuy",
    district_code: 458,
  },
  {
    name: "Xã Quảng Văn",
    code: 19099,
    division_type: "xã",
    codename: "xa_quang_van",
    district_code: 458,
  },
  {
    name: "Phường Quảng Phúc",
    code: 19102,
    division_type: "phường",
    codename: "phuong_quang_phuc",
    district_code: 458,
  },
  {
    name: "Xã Quảng Hòa",
    code: 19105,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 458,
  },
  {
    name: "Xã Quảng Minh",
    code: 19108,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 458,
  },
  {
    name: "Phường Đông Giang",
    code: 19330,
    division_type: "phường",
    codename: "phuong_dong_giang",
    district_code: 461,
  },
  {
    name: "Phường 1",
    code: 19333,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 461,
  },
  {
    name: "Phường Đông Lễ",
    code: 19336,
    division_type: "phường",
    codename: "phuong_dong_le",
    district_code: 461,
  },
  {
    name: "Phường Đông Thanh",
    code: 19339,
    division_type: "phường",
    codename: "phuong_dong_thanh",
    district_code: 461,
  },
  {
    name: "Phường 2",
    code: 19342,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 461,
  },
  {
    name: "Phường 4",
    code: 19345,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 461,
  },
  {
    name: "Phường 5",
    code: 19348,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 461,
  },
  {
    name: "Phường Đông Lương",
    code: 19351,
    division_type: "phường",
    codename: "phuong_dong_luong",
    district_code: 461,
  },
  {
    name: "Phường 3",
    code: 19354,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 461,
  },
  {
    name: "Phường 1",
    code: 19357,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 462,
  },
  {
    name: "Phường An Đôn",
    code: 19358,
    division_type: "phường",
    codename: "phuong_an_don",
    district_code: 462,
  },
  {
    name: "Phường 2",
    code: 19360,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 462,
  },
  {
    name: "Phường 3",
    code: 19361,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 462,
  },
  {
    name: "Xã Hải Lệ",
    code: 19705,
    division_type: "xã",
    codename: "xa_hai_le",
    district_code: 462,
  },
  {
    name: "Thị trấn Hồ Xá",
    code: 19363,
    division_type: "thị trấn",
    codename: "thi_tran_ho_xa",
    district_code: 464,
  },
  {
    name: "Thị trấn Bến Quan",
    code: 19366,
    division_type: "thị trấn",
    codename: "thi_tran_ben_quan",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Thái",
    code: 19369,
    division_type: "xã",
    codename: "xa_vinh_thai",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Tú",
    code: 19372,
    division_type: "xã",
    codename: "xa_vinh_tu",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Chấp",
    code: 19375,
    division_type: "xã",
    codename: "xa_vinh_chap",
    district_code: 464,
  },
  {
    name: "Xã Trung Nam",
    code: 19378,
    division_type: "xã",
    codename: "xa_trung_nam",
    district_code: 464,
  },
  {
    name: "Xã Kim Thạch",
    code: 19384,
    division_type: "xã",
    codename: "xa_kim_thach",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Long",
    code: 19387,
    division_type: "xã",
    codename: "xa_vinh_long",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Khê",
    code: 19393,
    division_type: "xã",
    codename: "xa_vinh_khe",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 19396,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Thủy",
    code: 19402,
    division_type: "xã",
    codename: "xa_vinh_thuy",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Lâm",
    code: 19405,
    division_type: "xã",
    codename: "xa_vinh_lam",
    district_code: 464,
  },
  {
    name: "Xã Hiền Thành",
    code: 19408,
    division_type: "xã",
    codename: "xa_hien_thanh",
    district_code: 464,
  },
  {
    name: "Thị trấn Cửa Tùng",
    code: 19414,
    division_type: "thị trấn",
    codename: "thi_tran_cua_tung",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Hà",
    code: 19417,
    division_type: "xã",
    codename: "xa_vinh_ha",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 19420,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Giang",
    code: 19423,
    division_type: "xã",
    codename: "xa_vinh_giang",
    district_code: 464,
  },
  {
    name: "Xã Vĩnh Ô",
    code: 19426,
    division_type: "xã",
    codename: "xa_vinh_o",
    district_code: 464,
  },
  {
    name: "Thị trấn Khe Sanh",
    code: 19429,
    division_type: "thị trấn",
    codename: "thi_tran_khe_sanh",
    district_code: 465,
  },
  {
    name: "Thị trấn Lao Bảo",
    code: 19432,
    division_type: "thị trấn",
    codename: "thi_tran_lao_bao",
    district_code: 465,
  },
  {
    name: "Xã Hướng Lập",
    code: 19435,
    division_type: "xã",
    codename: "xa_huong_lap",
    district_code: 465,
  },
  {
    name: "Xã Hướng Việt",
    code: 19438,
    division_type: "xã",
    codename: "xa_huong_viet",
    district_code: 465,
  },
  {
    name: "Xã Hướng Phùng",
    code: 19441,
    division_type: "xã",
    codename: "xa_huong_phung",
    district_code: 465,
  },
  {
    name: "Xã Hướng Sơn",
    code: 19444,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 465,
  },
  {
    name: "Xã Hướng Linh",
    code: 19447,
    division_type: "xã",
    codename: "xa_huong_linh",
    district_code: 465,
  },
  {
    name: "Xã Tân Hợp",
    code: 19450,
    division_type: "xã",
    codename: "xa_tan_hop",
    district_code: 465,
  },
  {
    name: "Xã Hướng Tân",
    code: 19453,
    division_type: "xã",
    codename: "xa_huong_tan",
    district_code: 465,
  },
  {
    name: "Xã Tân Thành",
    code: 19456,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 465,
  },
  {
    name: "Xã Tân Long",
    code: 19459,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 465,
  },
  {
    name: "Xã Tân Lập",
    code: 19462,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 465,
  },
  {
    name: "Xã Tân Liên",
    code: 19465,
    division_type: "xã",
    codename: "xa_tan_lien",
    district_code: 465,
  },
  {
    name: "Xã Húc",
    code: 19468,
    division_type: "xã",
    codename: "xa_huc",
    district_code: 465,
  },
  {
    name: "Xã Thuận",
    code: 19471,
    division_type: "xã",
    codename: "xa_thuan",
    district_code: 465,
  },
  {
    name: "Xã Hướng Lộc",
    code: 19474,
    division_type: "xã",
    codename: "xa_huong_loc",
    district_code: 465,
  },
  {
    name: "Xã Ba Tầng",
    code: 19477,
    division_type: "xã",
    codename: "xa_ba_tang",
    district_code: 465,
  },
  {
    name: "Xã Thanh",
    code: 19480,
    division_type: "xã",
    codename: "xa_thanh",
    district_code: 465,
  },
  {
    name: "Xã A Dơi",
    code: 19483,
    division_type: "xã",
    codename: "xa_a_doi",
    district_code: 465,
  },
  {
    name: "Xã Lìa",
    code: 19489,
    division_type: "xã",
    codename: "xa_lia",
    district_code: 465,
  },
  {
    name: "Xã Xy",
    code: 19492,
    division_type: "xã",
    codename: "xa_xy",
    district_code: 465,
  },
  {
    name: "Thị trấn Gio Linh",
    code: 19495,
    division_type: "thị trấn",
    codename: "thi_tran_gio_linh",
    district_code: 466,
  },
  {
    name: "Thị trấn Cửa Việt",
    code: 19496,
    division_type: "thị trấn",
    codename: "thi_tran_cua_viet",
    district_code: 466,
  },
  {
    name: "Xã Trung Giang",
    code: 19498,
    division_type: "xã",
    codename: "xa_trung_giang",
    district_code: 466,
  },
  {
    name: "Xã Trung Hải",
    code: 19501,
    division_type: "xã",
    codename: "xa_trung_hai",
    district_code: 466,
  },
  {
    name: "Xã Trung Sơn",
    code: 19504,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 466,
  },
  {
    name: "Xã Phong Bình",
    code: 19507,
    division_type: "xã",
    codename: "xa_phong_binh",
    district_code: 466,
  },
  {
    name: "Xã Gio Mỹ",
    code: 19510,
    division_type: "xã",
    codename: "xa_gio_my",
    district_code: 466,
  },
  {
    name: "Xã Gio Hải",
    code: 19519,
    division_type: "xã",
    codename: "xa_gio_hai",
    district_code: 466,
  },
  {
    name: "Xã Gio An",
    code: 19522,
    division_type: "xã",
    codename: "xa_gio_an",
    district_code: 466,
  },
  {
    name: "Xã Gio Châu",
    code: 19525,
    division_type: "xã",
    codename: "xa_gio_chau",
    district_code: 466,
  },
  {
    name: "Xã Gio Việt",
    code: 19531,
    division_type: "xã",
    codename: "xa_gio_viet",
    district_code: 466,
  },
  {
    name: "Xã Linh Trường",
    code: 19534,
    division_type: "xã",
    codename: "xa_linh_truong",
    district_code: 466,
  },
  {
    name: "Xã Gio Sơn",
    code: 19537,
    division_type: "xã",
    codename: "xa_gio_son",
    district_code: 466,
  },
  {
    name: "Xã Gio Mai",
    code: 19543,
    division_type: "xã",
    codename: "xa_gio_mai",
    district_code: 466,
  },
  {
    name: "Xã Hải Thái",
    code: 19546,
    division_type: "xã",
    codename: "xa_hai_thai",
    district_code: 466,
  },
  {
    name: "Xã Linh Hải",
    code: 19549,
    division_type: "xã",
    codename: "xa_linh_hai",
    district_code: 466,
  },
  {
    name: "Xã Gio Quang",
    code: 19552,
    division_type: "xã",
    codename: "xa_gio_quang",
    district_code: 466,
  },
  {
    name: "Thị trấn Krông Klang",
    code: 19555,
    division_type: "thị trấn",
    codename: "thi_tran_krong_klang",
    district_code: 467,
  },
  {
    name: "Xã Mò Ó",
    code: 19558,
    division_type: "xã",
    codename: "xa_mo_o",
    district_code: 467,
  },
  {
    name: "Xã Hướng Hiệp",
    code: 19561,
    division_type: "xã",
    codename: "xa_huong_hiep",
    district_code: 467,
  },
  {
    name: "Xã Đa Krông",
    code: 19564,
    division_type: "xã",
    codename: "xa_da_krong",
    district_code: 467,
  },
  {
    name: "Xã Triệu Nguyên",
    code: 19567,
    division_type: "xã",
    codename: "xa_trieu_nguyen",
    district_code: 467,
  },
  {
    name: "Xã Ba Lòng",
    code: 19570,
    division_type: "xã",
    codename: "xa_ba_long",
    district_code: 467,
  },
  {
    name: "Xã Ba Nang",
    code: 19576,
    division_type: "xã",
    codename: "xa_ba_nang",
    district_code: 467,
  },
  {
    name: "Xã Tà Long",
    code: 19579,
    division_type: "xã",
    codename: "xa_ta_long",
    district_code: 467,
  },
  {
    name: "Xã Húc Nghì",
    code: 19582,
    division_type: "xã",
    codename: "xa_huc_nghi",
    district_code: 467,
  },
  {
    name: "Xã A Vao",
    code: 19585,
    division_type: "xã",
    codename: "xa_a_vao",
    district_code: 467,
  },
  {
    name: "Xã Tà Rụt",
    code: 19588,
    division_type: "xã",
    codename: "xa_ta_rut",
    district_code: 467,
  },
  {
    name: "Xã A Bung",
    code: 19591,
    division_type: "xã",
    codename: "xa_a_bung",
    district_code: 467,
  },
  {
    name: "Xã A Ngo",
    code: 19594,
    division_type: "xã",
    codename: "xa_a_ngo",
    district_code: 467,
  },
  {
    name: "Thị trấn Cam Lộ",
    code: 19597,
    division_type: "thị trấn",
    codename: "thi_tran_cam_lo",
    district_code: 468,
  },
  {
    name: "Xã Cam Tuyền",
    code: 19600,
    division_type: "xã",
    codename: "xa_cam_tuyen",
    district_code: 468,
  },
  {
    name: "Xã Thanh An",
    code: 19603,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 468,
  },
  {
    name: "Xã Cam Thủy",
    code: 19606,
    division_type: "xã",
    codename: "xa_cam_thuy",
    district_code: 468,
  },
  {
    name: "Xã Cam Thành",
    code: 19612,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 468,
  },
  {
    name: "Xã Cam Hiếu",
    code: 19615,
    division_type: "xã",
    codename: "xa_cam_hieu",
    district_code: 468,
  },
  {
    name: "Xã Cam Chính",
    code: 19618,
    division_type: "xã",
    codename: "xa_cam_chinh",
    district_code: 468,
  },
  {
    name: "Xã Cam Nghĩa",
    code: 19621,
    division_type: "xã",
    codename: "xa_cam_nghia",
    district_code: 468,
  },
  {
    name: "Thị trấn Ái Tử",
    code: 19624,
    division_type: "thị trấn",
    codename: "thi_tran_ai_tu",
    district_code: 469,
  },
  {
    name: "Xã Triệu An",
    code: 19627,
    division_type: "xã",
    codename: "xa_trieu_an",
    district_code: 469,
  },
  {
    name: "Xã Triệu Vân",
    code: 19630,
    division_type: "xã",
    codename: "xa_trieu_van",
    district_code: 469,
  },
  {
    name: "Xã Triệu Phước",
    code: 19633,
    division_type: "xã",
    codename: "xa_trieu_phuoc",
    district_code: 469,
  },
  {
    name: "Xã Triệu Độ",
    code: 19636,
    division_type: "xã",
    codename: "xa_trieu_do",
    district_code: 469,
  },
  {
    name: "Xã Triệu Trạch",
    code: 19639,
    division_type: "xã",
    codename: "xa_trieu_trach",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thuận",
    code: 19642,
    division_type: "xã",
    codename: "xa_trieu_thuan",
    district_code: 469,
  },
  {
    name: "Xã Triệu Đại",
    code: 19645,
    division_type: "xã",
    codename: "xa_trieu_dai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Hòa",
    code: 19648,
    division_type: "xã",
    codename: "xa_trieu_hoa",
    district_code: 469,
  },
  {
    name: "Xã Triệu Lăng",
    code: 19651,
    division_type: "xã",
    codename: "xa_trieu_lang",
    district_code: 469,
  },
  {
    name: "Xã Triệu Sơn",
    code: 19654,
    division_type: "xã",
    codename: "xa_trieu_son",
    district_code: 469,
  },
  {
    name: "Xã Triệu Long",
    code: 19657,
    division_type: "xã",
    codename: "xa_trieu_long",
    district_code: 469,
  },
  {
    name: "Xã Triệu Tài",
    code: 19660,
    division_type: "xã",
    codename: "xa_trieu_tai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Trung",
    code: 19666,
    division_type: "xã",
    codename: "xa_trieu_trung",
    district_code: 469,
  },
  {
    name: "Xã Triệu Ái",
    code: 19669,
    division_type: "xã",
    codename: "xa_trieu_ai",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thượng",
    code: 19672,
    division_type: "xã",
    codename: "xa_trieu_thuong",
    district_code: 469,
  },
  {
    name: "Xã Triệu Giang",
    code: 19675,
    division_type: "xã",
    codename: "xa_trieu_giang",
    district_code: 469,
  },
  {
    name: "Xã Triệu Thành",
    code: 19678,
    division_type: "xã",
    codename: "xa_trieu_thanh",
    district_code: 469,
  },
  {
    name: "Thị trấn Diên Sanh",
    code: 19681,
    division_type: "thị trấn",
    codename: "thi_tran_dien_sanh",
    district_code: 470,
  },
  {
    name: "Xã Hải An",
    code: 19684,
    division_type: "xã",
    codename: "xa_hai_an",
    district_code: 470,
  },
  {
    name: "Xã Hải Ba",
    code: 19687,
    division_type: "xã",
    codename: "xa_hai_ba",
    district_code: 470,
  },
  {
    name: "Xã Hải Quy",
    code: 19693,
    division_type: "xã",
    codename: "xa_hai_quy",
    district_code: 470,
  },
  {
    name: "Xã Hải Quế",
    code: 19696,
    division_type: "xã",
    codename: "xa_hai_que",
    district_code: 470,
  },
  {
    name: "Xã Hải Hưng",
    code: 19699,
    division_type: "xã",
    codename: "xa_hai_hung",
    district_code: 470,
  },
  {
    name: "Xã Hải Phú",
    code: 19702,
    division_type: "xã",
    codename: "xa_hai_phu",
    district_code: 470,
  },
  {
    name: "Xã Hải Thượng",
    code: 19708,
    division_type: "xã",
    codename: "xa_hai_thuong",
    district_code: 470,
  },
  {
    name: "Xã Hải Dương",
    code: 19711,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 470,
  },
  {
    name: "Xã Hải Định",
    code: 19714,
    division_type: "xã",
    codename: "xa_hai_dinh",
    district_code: 470,
  },
  {
    name: "Xã Hải Lâm",
    code: 19717,
    division_type: "xã",
    codename: "xa_hai_lam",
    district_code: 470,
  },
  {
    name: "Xã Hải Phong",
    code: 19726,
    division_type: "xã",
    codename: "xa_hai_phong",
    district_code: 470,
  },
  {
    name: "Xã Hải Trường",
    code: 19729,
    division_type: "xã",
    codename: "xa_hai_truong",
    district_code: 470,
  },
  {
    name: "Xã Hải Sơn",
    code: 19735,
    division_type: "xã",
    codename: "xa_hai_son",
    district_code: 470,
  },
  {
    name: "Xã Hải Chánh",
    code: 19738,
    division_type: "xã",
    codename: "xa_hai_chanh",
    district_code: 470,
  },
  {
    name: "Xã Hải Khê",
    code: 19741,
    division_type: "xã",
    codename: "xa_hai_khe",
    district_code: 470,
  },
  {
    name: "Phường Phú Thuận",
    code: 19744,
    division_type: "phường",
    codename: "phuong_phu_thuan",
    district_code: 474,
  },
  {
    name: "Phường Phú Bình",
    code: 19747,
    division_type: "phường",
    codename: "phuong_phu_binh",
    district_code: 474,
  },
  {
    name: "Phường Tây Lộc",
    code: 19750,
    division_type: "phường",
    codename: "phuong_tay_loc",
    district_code: 474,
  },
  {
    name: "Phường Thuận Lộc",
    code: 19753,
    division_type: "phường",
    codename: "phuong_thuan_loc",
    district_code: 474,
  },
  {
    name: "Phường Phú Hiệp",
    code: 19756,
    division_type: "phường",
    codename: "phuong_phu_hiep",
    district_code: 474,
  },
  {
    name: "Phường Phú Hậu",
    code: 19759,
    division_type: "phường",
    codename: "phuong_phu_hau",
    district_code: 474,
  },
  {
    name: "Phường Thuận Hòa",
    code: 19762,
    division_type: "phường",
    codename: "phuong_thuan_hoa",
    district_code: 474,
  },
  {
    name: "Phường Thuận Thành",
    code: 19765,
    division_type: "phường",
    codename: "phuong_thuan_thanh",
    district_code: 474,
  },
  {
    name: "Phường Phú Hòa",
    code: 19768,
    division_type: "phường",
    codename: "phuong_phu_hoa",
    district_code: 474,
  },
  {
    name: "Phường Phú Cát",
    code: 19771,
    division_type: "phường",
    codename: "phuong_phu_cat",
    district_code: 474,
  },
  {
    name: "Phường Kim Long",
    code: 19774,
    division_type: "phường",
    codename: "phuong_kim_long",
    district_code: 474,
  },
  {
    name: "Phường Vỹ Dạ",
    code: 19777,
    division_type: "phường",
    codename: "phuong_vy_da",
    district_code: 474,
  },
  {
    name: "Phường Phường Đúc",
    code: 19780,
    division_type: "phường",
    codename: "phuong_phuong_duc",
    district_code: 474,
  },
  {
    name: "Phường Vĩnh Ninh",
    code: 19783,
    division_type: "phường",
    codename: "phuong_vinh_ninh",
    district_code: 474,
  },
  {
    name: "Phường Phú Hội",
    code: 19786,
    division_type: "phường",
    codename: "phuong_phu_hoi",
    district_code: 474,
  },
  {
    name: "Phường Phú Nhuận",
    code: 19789,
    division_type: "phường",
    codename: "phuong_phu_nhuan",
    district_code: 474,
  },
  {
    name: "Phường Xuân Phú",
    code: 19792,
    division_type: "phường",
    codename: "phuong_xuan_phu",
    district_code: 474,
  },
  {
    name: "Phường Trường An",
    code: 19795,
    division_type: "phường",
    codename: "phuong_truong_an",
    district_code: 474,
  },
  {
    name: "Phường Phước Vĩnh",
    code: 19798,
    division_type: "phường",
    codename: "phuong_phuoc_vinh",
    district_code: 474,
  },
  {
    name: "Phường An Cựu",
    code: 19801,
    division_type: "phường",
    codename: "phuong_an_cuu",
    district_code: 474,
  },
  {
    name: "Phường An Hòa",
    code: 19803,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 474,
  },
  {
    name: "Phường Hương Sơ",
    code: 19804,
    division_type: "phường",
    codename: "phuong_huong_so",
    district_code: 474,
  },
  {
    name: "Phường Thuỷ Biều",
    code: 19807,
    division_type: "phường",
    codename: "phuong_thuy_bieu",
    district_code: 474,
  },
  {
    name: "Phường Hương Long",
    code: 19810,
    division_type: "phường",
    codename: "phuong_huong_long",
    district_code: 474,
  },
  {
    name: "Phường Thuỷ Xuân",
    code: 19813,
    division_type: "phường",
    codename: "phuong_thuy_xuan",
    district_code: 474,
  },
  {
    name: "Phường An Đông",
    code: 19815,
    division_type: "phường",
    codename: "phuong_an_dong",
    district_code: 474,
  },
  {
    name: "Phường An Tây",
    code: 19816,
    division_type: "phường",
    codename: "phuong_an_tay",
    district_code: 474,
  },
  {
    name: "Thị trấn Phong Điền",
    code: 19819,
    division_type: "thị trấn",
    codename: "thi_tran_phong_dien",
    district_code: 476,
  },
  {
    name: "Xã Điền Hương",
    code: 19822,
    division_type: "xã",
    codename: "xa_dien_huong",
    district_code: 476,
  },
  {
    name: "Xã Điền Môn",
    code: 19825,
    division_type: "xã",
    codename: "xa_dien_mon",
    district_code: 476,
  },
  {
    name: "Xã Điền Lộc",
    code: 19828,
    division_type: "xã",
    codename: "xa_dien_loc",
    district_code: 476,
  },
  {
    name: "Xã Phong Bình",
    code: 19831,
    division_type: "xã",
    codename: "xa_phong_binh",
    district_code: 476,
  },
  {
    name: "Xã Điền Hòa",
    code: 19834,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 476,
  },
  {
    name: "Xã Phong Chương",
    code: 19837,
    division_type: "xã",
    codename: "xa_phong_chuong",
    district_code: 476,
  },
  {
    name: "Xã Phong Hải",
    code: 19840,
    division_type: "xã",
    codename: "xa_phong_hai",
    district_code: 476,
  },
  {
    name: "Xã Điền Hải",
    code: 19843,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 476,
  },
  {
    name: "Xã Phong Hòa",
    code: 19846,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 476,
  },
  {
    name: "Xã Phong Thu",
    code: 19849,
    division_type: "xã",
    codename: "xa_phong_thu",
    district_code: 476,
  },
  {
    name: "Xã Phong Hiền",
    code: 19852,
    division_type: "xã",
    codename: "xa_phong_hien",
    district_code: 476,
  },
  {
    name: "Xã Phong Mỹ",
    code: 19855,
    division_type: "xã",
    codename: "xa_phong_my",
    district_code: 476,
  },
  {
    name: "Xã Phong An",
    code: 19858,
    division_type: "xã",
    codename: "xa_phong_an",
    district_code: 476,
  },
  {
    name: "Xã Phong Xuân",
    code: 19861,
    division_type: "xã",
    codename: "xa_phong_xuan",
    district_code: 476,
  },
  {
    name: "Xã Phong Sơn",
    code: 19864,
    division_type: "xã",
    codename: "xa_phong_son",
    district_code: 476,
  },
  {
    name: "Thị trấn Sịa",
    code: 19867,
    division_type: "thị trấn",
    codename: "thi_tran_sia",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thái",
    code: 19870,
    division_type: "xã",
    codename: "xa_quang_thai",
    district_code: 477,
  },
  {
    name: "Xã Quảng Ngạn",
    code: 19873,
    division_type: "xã",
    codename: "xa_quang_ngan",
    district_code: 477,
  },
  {
    name: "Xã Quảng Lợi",
    code: 19876,
    division_type: "xã",
    codename: "xa_quang_loi",
    district_code: 477,
  },
  {
    name: "Xã Quảng Công",
    code: 19879,
    division_type: "xã",
    codename: "xa_quang_cong",
    district_code: 477,
  },
  {
    name: "Xã Quảng Phước",
    code: 19882,
    division_type: "xã",
    codename: "xa_quang_phuoc",
    district_code: 477,
  },
  {
    name: "Xã Quảng Vinh",
    code: 19885,
    division_type: "xã",
    codename: "xa_quang_vinh",
    district_code: 477,
  },
  {
    name: "Xã Quảng An",
    code: 19888,
    division_type: "xã",
    codename: "xa_quang_an",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thành",
    code: 19891,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 477,
  },
  {
    name: "Xã Quảng Thọ",
    code: 19894,
    division_type: "xã",
    codename: "xa_quang_tho",
    district_code: 477,
  },
  {
    name: "Xã Quảng Phú",
    code: 19897,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 477,
  },
  {
    name: "Thị trấn Thuận An",
    code: 19900,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Thuận",
    code: 19903,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Dương",
    code: 19906,
    division_type: "xã",
    codename: "xa_phu_duong",
    district_code: 478,
  },
  {
    name: "Xã Phú Mậu",
    code: 19909,
    division_type: "xã",
    codename: "xa_phu_mau",
    district_code: 478,
  },
  {
    name: "Xã Phú An",
    code: 19912,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Hải",
    code: 19915,
    division_type: "xã",
    codename: "xa_phu_hai",
    district_code: 478,
  },
  {
    name: "Xã Phú Xuân",
    code: 19918,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Diên",
    code: 19921,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 478,
  },
  {
    name: "Xã Phú Thanh",
    code: 19924,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 478,
  },
  {
    name: "Xã Phú Mỹ",
    code: 19927,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 478,
  },
  {
    name: "Xã Phú Thượng",
    code: 19930,
    division_type: "xã",
    codename: "xa_phu_thuong",
    district_code: 478,
  },
  {
    name: "Xã Phú Hồ",
    code: 19933,
    division_type: "xã",
    codename: "xa_phu_ho",
    district_code: 478,
  },
  {
    name: "Xã Vinh Xuân",
    code: 19936,
    division_type: "xã",
    codename: "xa_vinh_xuan",
    district_code: 478,
  },
  {
    name: "Xã Phú Lương",
    code: 19939,
    division_type: "xã",
    codename: "xa_phu_luong",
    district_code: 478,
  },
  {
    name: "Thị trấn Phú Đa",
    code: 19942,
    division_type: "thị trấn",
    codename: "thi_tran_phu_da",
    district_code: 478,
  },
  {
    name: "Xã Vinh Thanh",
    code: 19945,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 478,
  },
  {
    name: "Xã Vinh An",
    code: 19948,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 478,
  },
  {
    name: "Xã Phú Gia",
    code: 19954,
    division_type: "xã",
    codename: "xa_phu_gia",
    district_code: 478,
  },
  {
    name: "Xã Vinh Hà",
    code: 19957,
    division_type: "xã",
    codename: "xa_vinh_ha",
    district_code: 478,
  },
  {
    name: "Phường Phú Bài",
    code: 19960,
    division_type: "phường",
    codename: "phuong_phu_bai",
    district_code: 479,
  },
  {
    name: "Xã Thủy Vân",
    code: 19963,
    division_type: "xã",
    codename: "xa_thuy_van",
    district_code: 479,
  },
  {
    name: "Xã Thủy Thanh",
    code: 19966,
    division_type: "xã",
    codename: "xa_thuy_thanh",
    district_code: 479,
  },
  {
    name: "Phường Thủy Dương",
    code: 19969,
    division_type: "phường",
    codename: "phuong_thuy_duong",
    district_code: 479,
  },
  {
    name: "Phường Thủy Phương",
    code: 19972,
    division_type: "phường",
    codename: "phuong_thuy_phuong",
    district_code: 479,
  },
  {
    name: "Phường Thủy Châu",
    code: 19975,
    division_type: "phường",
    codename: "phuong_thuy_chau",
    district_code: 479,
  },
  {
    name: "Phường Thủy Lương",
    code: 19978,
    division_type: "phường",
    codename: "phuong_thuy_luong",
    district_code: 479,
  },
  {
    name: "Xã Thủy Bằng",
    code: 19981,
    division_type: "xã",
    codename: "xa_thuy_bang",
    district_code: 479,
  },
  {
    name: "Xã Thủy Tân",
    code: 19984,
    division_type: "xã",
    codename: "xa_thuy_tan",
    district_code: 479,
  },
  {
    name: "Xã Thủy Phù",
    code: 19987,
    division_type: "xã",
    codename: "xa_thuy_phu",
    district_code: 479,
  },
  {
    name: "Xã Phú Sơn",
    code: 19990,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 479,
  },
  {
    name: "Xã Dương Hòa",
    code: 19993,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 479,
  },
  {
    name: "Phường Tứ Hạ",
    code: 19996,
    division_type: "phường",
    codename: "phuong_tu_ha",
    district_code: 480,
  },
  {
    name: "Xã Hải Dương",
    code: 19999,
    division_type: "xã",
    codename: "xa_hai_duong",
    district_code: 480,
  },
  {
    name: "Xã Hương Phong",
    code: 20002,
    division_type: "xã",
    codename: "xa_huong_phong",
    district_code: 480,
  },
  {
    name: "Xã Hương Toàn",
    code: 20005,
    division_type: "xã",
    codename: "xa_huong_toan",
    district_code: 480,
  },
  {
    name: "Phường Hương Vân",
    code: 20008,
    division_type: "phường",
    codename: "phuong_huong_van",
    district_code: 480,
  },
  {
    name: "Phường Hương Văn",
    code: 20011,
    division_type: "phường",
    codename: "phuong_huong_van",
    district_code: 480,
  },
  {
    name: "Xã Hương Vinh",
    code: 20014,
    division_type: "xã",
    codename: "xa_huong_vinh",
    district_code: 480,
  },
  {
    name: "Phường Hương Xuân",
    code: 20017,
    division_type: "phường",
    codename: "phuong_huong_xuan",
    district_code: 480,
  },
  {
    name: "Phường Hương Chữ",
    code: 20020,
    division_type: "phường",
    codename: "phuong_huong_chu",
    district_code: 480,
  },
  {
    name: "Phường Hương An",
    code: 20023,
    division_type: "phường",
    codename: "phuong_huong_an",
    district_code: 480,
  },
  {
    name: "Xã Hương Bình",
    code: 20026,
    division_type: "xã",
    codename: "xa_huong_binh",
    district_code: 480,
  },
  {
    name: "Phường Hương Hồ",
    code: 20029,
    division_type: "phường",
    codename: "phuong_huong_ho",
    district_code: 480,
  },
  {
    name: "Xã Hương Thọ",
    code: 20032,
    division_type: "xã",
    codename: "xa_huong_tho",
    district_code: 480,
  },
  {
    name: "Xã Bình Tiến",
    code: 20035,
    division_type: "xã",
    codename: "xa_binh_tien",
    district_code: 480,
  },
  {
    name: "Xã Bình Thành",
    code: 20041,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 480,
  },
  {
    name: "Thị trấn A Lưới",
    code: 20044,
    division_type: "thị trấn",
    codename: "thi_tran_a_luoi",
    district_code: 481,
  },
  {
    name: "Xã Hồng Vân",
    code: 20047,
    division_type: "xã",
    codename: "xa_hong_van",
    district_code: 481,
  },
  {
    name: "Xã Hồng Hạ",
    code: 20050,
    division_type: "xã",
    codename: "xa_hong_ha",
    district_code: 481,
  },
  {
    name: "Xã Hồng Kim",
    code: 20053,
    division_type: "xã",
    codename: "xa_hong_kim",
    district_code: 481,
  },
  {
    name: "Xã Trung Sơn",
    code: 20056,
    division_type: "xã",
    codename: "xa_trung_son",
    district_code: 481,
  },
  {
    name: "Xã Hương Nguyên",
    code: 20059,
    division_type: "xã",
    codename: "xa_huong_nguyen",
    district_code: 481,
  },
  {
    name: "Xã Hồng Bắc",
    code: 20065,
    division_type: "xã",
    codename: "xa_hong_bac",
    district_code: 481,
  },
  {
    name: "Xã A Ngo",
    code: 20068,
    division_type: "xã",
    codename: "xa_a_ngo",
    district_code: 481,
  },
  {
    name: "Xã Sơn Thủy",
    code: 20071,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 481,
  },
  {
    name: "Xã Phú Vinh",
    code: 20074,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 481,
  },
  {
    name: "Xã Hương Phong",
    code: 20080,
    division_type: "xã",
    codename: "xa_huong_phong",
    district_code: 481,
  },
  {
    name: "Xã Quảng Nhâm",
    code: 20083,
    division_type: "xã",
    codename: "xa_quang_nham",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thượng",
    code: 20086,
    division_type: "xã",
    codename: "xa_hong_thuong",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thái",
    code: 20089,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 481,
  },
  {
    name: "Xã A Roàng",
    code: 20095,
    division_type: "xã",
    codename: "xa_a_roang",
    district_code: 481,
  },
  {
    name: "Xã Đông Sơn",
    code: 20098,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 481,
  },
  {
    name: "Xã Lâm Đớt",
    code: 20101,
    division_type: "xã",
    codename: "xa_lam_dot",
    district_code: 481,
  },
  {
    name: "Xã Hồng Thủy",
    code: 20104,
    division_type: "xã",
    codename: "xa_hong_thuy",
    district_code: 481,
  },
  {
    name: "Thị trấn Phú Lộc",
    code: 20107,
    division_type: "thị trấn",
    codename: "thi_tran_phu_loc",
    district_code: 482,
  },
  {
    name: "Thị trấn Lăng Cô",
    code: 20110,
    division_type: "thị trấn",
    codename: "thi_tran_lang_co",
    district_code: 482,
  },
  {
    name: "Xã Vinh Mỹ",
    code: 20113,
    division_type: "xã",
    codename: "xa_vinh_my",
    district_code: 482,
  },
  {
    name: "Xã Vinh Hưng",
    code: 20116,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 482,
  },
  {
    name: "Xã Giang Hải",
    code: 20122,
    division_type: "xã",
    codename: "xa_giang_hai",
    district_code: 482,
  },
  {
    name: "Xã Vinh Hiền",
    code: 20125,
    division_type: "xã",
    codename: "xa_vinh_hien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Bổn",
    code: 20128,
    division_type: "xã",
    codename: "xa_loc_bon",
    district_code: 482,
  },
  {
    name: "Xã Lộc Sơn",
    code: 20131,
    division_type: "xã",
    codename: "xa_loc_son",
    district_code: 482,
  },
  {
    name: "Xã Lộc Bình",
    code: 20134,
    division_type: "xã",
    codename: "xa_loc_binh",
    district_code: 482,
  },
  {
    name: "Xã Lộc Vĩnh",
    code: 20137,
    division_type: "xã",
    codename: "xa_loc_vinh",
    district_code: 482,
  },
  {
    name: "Xã Lộc An",
    code: 20140,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 482,
  },
  {
    name: "Xã Lộc Điền",
    code: 20143,
    division_type: "xã",
    codename: "xa_loc_dien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Thủy",
    code: 20146,
    division_type: "xã",
    codename: "xa_loc_thuy",
    district_code: 482,
  },
  {
    name: "Xã Lộc Trì",
    code: 20149,
    division_type: "xã",
    codename: "xa_loc_tri",
    district_code: 482,
  },
  {
    name: "Xã Lộc Tiến",
    code: 20152,
    division_type: "xã",
    codename: "xa_loc_tien",
    district_code: 482,
  },
  {
    name: "Xã Lộc Hòa",
    code: 20155,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 482,
  },
  {
    name: "Xã Xuân Lộc",
    code: 20158,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 482,
  },
  {
    name: "Thị trấn Khe Tre",
    code: 20161,
    division_type: "thị trấn",
    codename: "thi_tran_khe_tre",
    district_code: 483,
  },
  {
    name: "Xã Hương Phú",
    code: 20164,
    division_type: "xã",
    codename: "xa_huong_phu",
    district_code: 483,
  },
  {
    name: "Xã Hương Sơn",
    code: 20167,
    division_type: "xã",
    codename: "xa_huong_son",
    district_code: 483,
  },
  {
    name: "Xã Hương Lộc",
    code: 20170,
    division_type: "xã",
    codename: "xa_huong_loc",
    district_code: 483,
  },
  {
    name: "Xã Thượng Quảng",
    code: 20173,
    division_type: "xã",
    codename: "xa_thuong_quang",
    district_code: 483,
  },
  {
    name: "Xã Hương Xuân",
    code: 20179,
    division_type: "xã",
    codename: "xa_huong_xuan",
    district_code: 483,
  },
  {
    name: "Xã Hương Hữu",
    code: 20182,
    division_type: "xã",
    codename: "xa_huong_huu",
    district_code: 483,
  },
  {
    name: "Xã Thượng Lộ",
    code: 20185,
    division_type: "xã",
    codename: "xa_thuong_lo",
    district_code: 483,
  },
  {
    name: "Xã Thượng Long",
    code: 20188,
    division_type: "xã",
    codename: "xa_thuong_long",
    district_code: 483,
  },
  {
    name: "Xã Thượng Nhật",
    code: 20191,
    division_type: "xã",
    codename: "xa_thuong_nhat",
    district_code: 483,
  },
  {
    name: "Phường Hòa Hiệp Bắc",
    code: 20194,
    division_type: "phường",
    codename: "phuong_hoa_hiep_bac",
    district_code: 490,
  },
  {
    name: "Phường Hòa Hiệp Nam",
    code: 20195,
    division_type: "phường",
    codename: "phuong_hoa_hiep_nam",
    district_code: 490,
  },
  {
    name: "Phường Hòa Khánh Bắc",
    code: 20197,
    division_type: "phường",
    codename: "phuong_hoa_khanh_bac",
    district_code: 490,
  },
  {
    name: "Phường Hòa Khánh Nam",
    code: 20198,
    division_type: "phường",
    codename: "phuong_hoa_khanh_nam",
    district_code: 490,
  },
  {
    name: "Phường Hòa Minh",
    code: 20200,
    division_type: "phường",
    codename: "phuong_hoa_minh",
    district_code: 490,
  },
  {
    name: "Phường Tam Thuận",
    code: 20203,
    division_type: "phường",
    codename: "phuong_tam_thuan",
    district_code: 491,
  },
  {
    name: "Phường Thanh Khê Tây",
    code: 20206,
    division_type: "phường",
    codename: "phuong_thanh_khe_tay",
    district_code: 491,
  },
  {
    name: "Phường Thanh Khê Đông",
    code: 20207,
    division_type: "phường",
    codename: "phuong_thanh_khe_dong",
    district_code: 491,
  },
  {
    name: "Phường Xuân Hà",
    code: 20209,
    division_type: "phường",
    codename: "phuong_xuan_ha",
    district_code: 491,
  },
  {
    name: "Phường Tân Chính",
    code: 20212,
    division_type: "phường",
    codename: "phuong_tan_chinh",
    district_code: 491,
  },
  {
    name: "Phường Chính Gián",
    code: 20215,
    division_type: "phường",
    codename: "phuong_chinh_gian",
    district_code: 491,
  },
  {
    name: "Phường Vĩnh Trung",
    code: 20218,
    division_type: "phường",
    codename: "phuong_vinh_trung",
    district_code: 491,
  },
  {
    name: "Phường Thạc Gián",
    code: 20221,
    division_type: "phường",
    codename: "phuong_thac_gian",
    district_code: 491,
  },
  {
    name: "Phường An Khê",
    code: 20224,
    division_type: "phường",
    codename: "phuong_an_khe",
    district_code: 491,
  },
  {
    name: "Phường Hòa Khê",
    code: 20225,
    division_type: "phường",
    codename: "phuong_hoa_khe",
    district_code: 491,
  },
  {
    name: "Phường Thanh Bình",
    code: 20227,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 492,
  },
  {
    name: "Phường Thuận Phước",
    code: 20230,
    division_type: "phường",
    codename: "phuong_thuan_phuoc",
    district_code: 492,
  },
  {
    name: "Phường Thạch Thang",
    code: 20233,
    division_type: "phường",
    codename: "phuong_thach_thang",
    district_code: 492,
  },
  {
    name: "Phường Hải Châu I",
    code: 20236,
    division_type: "phường",
    codename: "phuong_hai_chau_i",
    district_code: 492,
  },
  {
    name: "Phường Hải Châu II",
    code: 20239,
    division_type: "phường",
    codename: "phuong_hai_chau_ii",
    district_code: 492,
  },
  {
    name: "Phường Phước Ninh",
    code: 20242,
    division_type: "phường",
    codename: "phuong_phuoc_ninh",
    district_code: 492,
  },
  {
    name: "Phường Hòa Thuận Tây",
    code: 20245,
    division_type: "phường",
    codename: "phuong_hoa_thuan_tay",
    district_code: 492,
  },
  {
    name: "Phường Hòa Thuận Đông",
    code: 20246,
    division_type: "phường",
    codename: "phuong_hoa_thuan_dong",
    district_code: 492,
  },
  {
    name: "Phường Nam Dương",
    code: 20248,
    division_type: "phường",
    codename: "phuong_nam_duong",
    district_code: 492,
  },
  {
    name: "Phường Bình Hiên",
    code: 20251,
    division_type: "phường",
    codename: "phuong_binh_hien",
    district_code: 492,
  },
  {
    name: "Phường Bình Thuận",
    code: 20254,
    division_type: "phường",
    codename: "phuong_binh_thuan",
    district_code: 492,
  },
  {
    name: "Phường Hòa Cường Bắc",
    code: 20257,
    division_type: "phường",
    codename: "phuong_hoa_cuong_bac",
    district_code: 492,
  },
  {
    name: "Phường Hòa Cường Nam",
    code: 20258,
    division_type: "phường",
    codename: "phuong_hoa_cuong_nam",
    district_code: 492,
  },
  {
    name: "Phường Thọ Quang",
    code: 20263,
    division_type: "phường",
    codename: "phuong_tho_quang",
    district_code: 493,
  },
  {
    name: "Phường Nại Hiên Đông",
    code: 20266,
    division_type: "phường",
    codename: "phuong_nai_hien_dong",
    district_code: 493,
  },
  {
    name: "Phường Mân Thái",
    code: 20269,
    division_type: "phường",
    codename: "phuong_man_thai",
    district_code: 493,
  },
  {
    name: "Phường An Hải Bắc",
    code: 20272,
    division_type: "phường",
    codename: "phuong_an_hai_bac",
    district_code: 493,
  },
  {
    name: "Phường Phước Mỹ",
    code: 20275,
    division_type: "phường",
    codename: "phuong_phuoc_my",
    district_code: 493,
  },
  {
    name: "Phường An Hải Tây",
    code: 20278,
    division_type: "phường",
    codename: "phuong_an_hai_tay",
    district_code: 493,
  },
  {
    name: "Phường An Hải Đông",
    code: 20281,
    division_type: "phường",
    codename: "phuong_an_hai_dong",
    district_code: 493,
  },
  {
    name: "Phường Mỹ An",
    code: 20284,
    division_type: "phường",
    codename: "phuong_my_an",
    district_code: 494,
  },
  {
    name: "Phường Khuê Mỹ",
    code: 20285,
    division_type: "phường",
    codename: "phuong_khue_my",
    district_code: 494,
  },
  {
    name: "Phường Hoà Quý",
    code: 20287,
    division_type: "phường",
    codename: "phuong_hoa_quy",
    district_code: 494,
  },
  {
    name: "Phường Hoà Hải",
    code: 20290,
    division_type: "phường",
    codename: "phuong_hoa_hai",
    district_code: 494,
  },
  {
    name: "Phường Khuê Trung",
    code: 20260,
    division_type: "phường",
    codename: "phuong_khue_trung",
    district_code: 495,
  },
  {
    name: "Phường Hòa Phát",
    code: 20305,
    division_type: "phường",
    codename: "phuong_hoa_phat",
    district_code: 495,
  },
  {
    name: "Phường Hòa An",
    code: 20306,
    division_type: "phường",
    codename: "phuong_hoa_an",
    district_code: 495,
  },
  {
    name: "Phường Hòa Thọ Tây",
    code: 20311,
    division_type: "phường",
    codename: "phuong_hoa_tho_tay",
    district_code: 495,
  },
  {
    name: "Phường Hòa Thọ Đông",
    code: 20312,
    division_type: "phường",
    codename: "phuong_hoa_tho_dong",
    district_code: 495,
  },
  {
    name: "Phường Hòa Xuân",
    code: 20314,
    division_type: "phường",
    codename: "phuong_hoa_xuan",
    district_code: 495,
  },
  {
    name: "Xã Hòa Bắc",
    code: 20293,
    division_type: "xã",
    codename: "xa_hoa_bac",
    district_code: 497,
  },
  {
    name: "Xã Hòa Liên",
    code: 20296,
    division_type: "xã",
    codename: "xa_hoa_lien",
    district_code: 497,
  },
  {
    name: "Xã Hòa Ninh",
    code: 20299,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 497,
  },
  {
    name: "Xã Hòa Sơn",
    code: 20302,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 497,
  },
  {
    name: "Xã Hòa Nhơn",
    code: 20308,
    division_type: "xã",
    codename: "xa_hoa_nhon",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phú",
    code: 20317,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phong",
    code: 20320,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 497,
  },
  {
    name: "Xã Hòa Châu",
    code: 20323,
    division_type: "xã",
    codename: "xa_hoa_chau",
    district_code: 497,
  },
  {
    name: "Xã Hòa Tiến",
    code: 20326,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 497,
  },
  {
    name: "Xã Hòa Phước",
    code: 20329,
    division_type: "xã",
    codename: "xa_hoa_phuoc",
    district_code: 497,
  },
  {
    name: "Xã Hòa Khương",
    code: 20332,
    division_type: "xã",
    codename: "xa_hoa_khuong",
    district_code: 497,
  },
  {
    name: "Phường Tân Thạnh",
    code: 20335,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 502,
  },
  {
    name: "Phường Phước Hòa",
    code: 20338,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 502,
  },
  {
    name: "Phường An Mỹ",
    code: 20341,
    division_type: "phường",
    codename: "phuong_an_my",
    district_code: 502,
  },
  {
    name: "Phường Hòa Hương",
    code: 20344,
    division_type: "phường",
    codename: "phuong_hoa_huong",
    district_code: 502,
  },
  {
    name: "Phường An Xuân",
    code: 20347,
    division_type: "phường",
    codename: "phuong_an_xuan",
    district_code: 502,
  },
  {
    name: "Phường An Sơn",
    code: 20350,
    division_type: "phường",
    codename: "phuong_an_son",
    district_code: 502,
  },
  {
    name: "Phường Trường Xuân",
    code: 20353,
    division_type: "phường",
    codename: "phuong_truong_xuan",
    district_code: 502,
  },
  {
    name: "Phường An Phú",
    code: 20356,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 502,
  },
  {
    name: "Xã Tam Thanh",
    code: 20359,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 502,
  },
  {
    name: "Xã Tam Thăng",
    code: 20362,
    division_type: "xã",
    codename: "xa_tam_thang",
    district_code: 502,
  },
  {
    name: "Xã Tam Phú",
    code: 20371,
    division_type: "xã",
    codename: "xa_tam_phu",
    district_code: 502,
  },
  {
    name: "Phường Hoà Thuận",
    code: 20375,
    division_type: "phường",
    codename: "phuong_hoa_thuan",
    district_code: 502,
  },
  {
    name: "Xã Tam Ngọc",
    code: 20389,
    division_type: "xã",
    codename: "xa_tam_ngoc",
    district_code: 502,
  },
  {
    name: "Phường Minh An",
    code: 20398,
    division_type: "phường",
    codename: "phuong_minh_an",
    district_code: 503,
  },
  {
    name: "Phường Tân An",
    code: 20401,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Phô",
    code: 20404,
    division_type: "phường",
    codename: "phuong_cam_pho",
    district_code: 503,
  },
  {
    name: "Phường Thanh Hà",
    code: 20407,
    division_type: "phường",
    codename: "phuong_thanh_ha",
    district_code: 503,
  },
  {
    name: "Phường Sơn Phong",
    code: 20410,
    division_type: "phường",
    codename: "phuong_son_phong",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Châu",
    code: 20413,
    division_type: "phường",
    codename: "phuong_cam_chau",
    district_code: 503,
  },
  {
    name: "Phường Cửa Đại",
    code: 20416,
    division_type: "phường",
    codename: "phuong_cua_dai",
    district_code: 503,
  },
  {
    name: "Phường Cẩm An",
    code: 20419,
    division_type: "phường",
    codename: "phuong_cam_an",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Hà",
    code: 20422,
    division_type: "xã",
    codename: "xa_cam_ha",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Kim",
    code: 20425,
    division_type: "xã",
    codename: "xa_cam_kim",
    district_code: 503,
  },
  {
    name: "Phường Cẩm Nam",
    code: 20428,
    division_type: "phường",
    codename: "phuong_cam_nam",
    district_code: 503,
  },
  {
    name: "Xã Cẩm Thanh",
    code: 20431,
    division_type: "xã",
    codename: "xa_cam_thanh",
    district_code: 503,
  },
  {
    name: "Xã Tân Hiệp",
    code: 20434,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 503,
  },
  {
    name: "Xã Ch'ơm",
    code: 20437,
    division_type: "xã",
    codename: "xa_chom",
    district_code: 504,
  },
  {
    name: "Xã Ga Ri",
    code: 20440,
    division_type: "xã",
    codename: "xa_ga_ri",
    district_code: 504,
  },
  {
    name: "Xã A Xan",
    code: 20443,
    division_type: "xã",
    codename: "xa_a_xan",
    district_code: 504,
  },
  {
    name: "Xã Tr'Hy",
    code: 20446,
    division_type: "xã",
    codename: "xa_trhy",
    district_code: 504,
  },
  {
    name: "Xã Lăng",
    code: 20449,
    division_type: "xã",
    codename: "xa_lang",
    district_code: 504,
  },
  {
    name: "Xã A Nông",
    code: 20452,
    division_type: "xã",
    codename: "xa_a_nong",
    district_code: 504,
  },
  {
    name: "Xã A Tiêng",
    code: 20455,
    division_type: "xã",
    codename: "xa_a_tieng",
    district_code: 504,
  },
  {
    name: "Xã Bha Lê",
    code: 20458,
    division_type: "xã",
    codename: "xa_bha_le",
    district_code: 504,
  },
  {
    name: "Xã A Vương",
    code: 20461,
    division_type: "xã",
    codename: "xa_a_vuong",
    district_code: 504,
  },
  {
    name: "Xã Dang",
    code: 20464,
    division_type: "xã",
    codename: "xa_dang",
    district_code: 504,
  },
  {
    name: "Thị trấn P Rao",
    code: 20467,
    division_type: "thị trấn",
    codename: "thi_tran_p_rao",
    district_code: 505,
  },
  {
    name: "Xã Tà Lu",
    code: 20470,
    division_type: "xã",
    codename: "xa_ta_lu",
    district_code: 505,
  },
  {
    name: "Xã Sông Kôn",
    code: 20473,
    division_type: "xã",
    codename: "xa_song_kon",
    district_code: 505,
  },
  {
    name: "Xã Jơ Ngây",
    code: 20476,
    division_type: "xã",
    codename: "xa_jo_ngay",
    district_code: 505,
  },
  {
    name: "Xã A Ting",
    code: 20479,
    division_type: "xã",
    codename: "xa_a_ting",
    district_code: 505,
  },
  {
    name: "Xã Tư",
    code: 20482,
    division_type: "xã",
    codename: "xa_tu",
    district_code: 505,
  },
  {
    name: "Xã Ba",
    code: 20485,
    division_type: "xã",
    codename: "xa_ba",
    district_code: 505,
  },
  {
    name: "Xã A Rooi",
    code: 20488,
    division_type: "xã",
    codename: "xa_a_rooi",
    district_code: 505,
  },
  {
    name: "Xã Za Hung",
    code: 20491,
    division_type: "xã",
    codename: "xa_za_hung",
    district_code: 505,
  },
  {
    name: "Xã Mà Cooi",
    code: 20494,
    division_type: "xã",
    codename: "xa_ma_cooi",
    district_code: 505,
  },
  {
    name: "Xã Ka Dăng",
    code: 20497,
    division_type: "xã",
    codename: "xa_ka_dang",
    district_code: 505,
  },
  {
    name: "Thị trấn Ái Nghĩa",
    code: 20500,
    division_type: "thị trấn",
    codename: "thi_tran_ai_nghia",
    district_code: 506,
  },
  {
    name: "Xã Đại Sơn",
    code: 20503,
    division_type: "xã",
    codename: "xa_dai_son",
    district_code: 506,
  },
  {
    name: "Xã Đại Lãnh",
    code: 20506,
    division_type: "xã",
    codename: "xa_dai_lanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Hưng",
    code: 20509,
    division_type: "xã",
    codename: "xa_dai_hung",
    district_code: 506,
  },
  {
    name: "Xã Đại Hồng",
    code: 20512,
    division_type: "xã",
    codename: "xa_dai_hong",
    district_code: 506,
  },
  {
    name: "Xã Đại Đồng",
    code: 20515,
    division_type: "xã",
    codename: "xa_dai_dong",
    district_code: 506,
  },
  {
    name: "Xã Đại Quang",
    code: 20518,
    division_type: "xã",
    codename: "xa_dai_quang",
    district_code: 506,
  },
  {
    name: "Xã Đại Nghĩa",
    code: 20521,
    division_type: "xã",
    codename: "xa_dai_nghia",
    district_code: 506,
  },
  {
    name: "Xã Đại Hiệp",
    code: 20524,
    division_type: "xã",
    codename: "xa_dai_hiep",
    district_code: 506,
  },
  {
    name: "Xã Đại Thạnh",
    code: 20527,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Chánh",
    code: 20530,
    division_type: "xã",
    codename: "xa_dai_chanh",
    district_code: 506,
  },
  {
    name: "Xã Đại Tân",
    code: 20533,
    division_type: "xã",
    codename: "xa_dai_tan",
    district_code: 506,
  },
  {
    name: "Xã Đại Phong",
    code: 20536,
    division_type: "xã",
    codename: "xa_dai_phong",
    district_code: 506,
  },
  {
    name: "Xã Đại Minh",
    code: 20539,
    division_type: "xã",
    codename: "xa_dai_minh",
    district_code: 506,
  },
  {
    name: "Xã Đại Thắng",
    code: 20542,
    division_type: "xã",
    codename: "xa_dai_thang",
    district_code: 506,
  },
  {
    name: "Xã Đại Cường",
    code: 20545,
    division_type: "xã",
    codename: "xa_dai_cuong",
    district_code: 506,
  },
  {
    name: "Xã Đại An",
    code: 20547,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 506,
  },
  {
    name: "Xã Đại Hòa",
    code: 20548,
    division_type: "xã",
    codename: "xa_dai_hoa",
    district_code: 506,
  },
  {
    name: "Phường Vĩnh Điện",
    code: 20551,
    division_type: "phường",
    codename: "phuong_vinh_dien",
    district_code: 507,
  },
  {
    name: "Xã Điện Tiến",
    code: 20554,
    division_type: "xã",
    codename: "xa_dien_tien",
    district_code: 507,
  },
  {
    name: "Xã Điện Hòa",
    code: 20557,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Bắc",
    code: 20560,
    division_type: "xã",
    codename: "xa_dien_thang_bac",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Trung",
    code: 20561,
    division_type: "xã",
    codename: "xa_dien_thang_trung",
    district_code: 507,
  },
  {
    name: "Xã Điện Thắng Nam",
    code: 20562,
    division_type: "xã",
    codename: "xa_dien_thang_nam",
    district_code: 507,
  },
  {
    name: "Phường Điện Ngọc",
    code: 20563,
    division_type: "phường",
    codename: "phuong_dien_ngoc",
    district_code: 507,
  },
  {
    name: "Xã Điện Hồng",
    code: 20566,
    division_type: "xã",
    codename: "xa_dien_hong",
    district_code: 507,
  },
  {
    name: "Xã Điện Thọ",
    code: 20569,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 507,
  },
  {
    name: "Xã Điện Phước",
    code: 20572,
    division_type: "xã",
    codename: "xa_dien_phuoc",
    district_code: 507,
  },
  {
    name: "Phường Điện An",
    code: 20575,
    division_type: "phường",
    codename: "phuong_dien_an",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Bắc",
    code: 20578,
    division_type: "phường",
    codename: "phuong_dien_nam_bac",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Trung",
    code: 20579,
    division_type: "phường",
    codename: "phuong_dien_nam_trung",
    district_code: 507,
  },
  {
    name: "Phường Điện Nam Đông",
    code: 20580,
    division_type: "phường",
    codename: "phuong_dien_nam_dong",
    district_code: 507,
  },
  {
    name: "Phường Điện Dương",
    code: 20581,
    division_type: "phường",
    codename: "phuong_dien_duong",
    district_code: 507,
  },
  {
    name: "Xã Điện Quang",
    code: 20584,
    division_type: "xã",
    codename: "xa_dien_quang",
    district_code: 507,
  },
  {
    name: "Xã Điện Trung",
    code: 20587,
    division_type: "xã",
    codename: "xa_dien_trung",
    district_code: 507,
  },
  {
    name: "Xã Điện Phong",
    code: 20590,
    division_type: "xã",
    codename: "xa_dien_phong",
    district_code: 507,
  },
  {
    name: "Xã Điện Minh",
    code: 20593,
    division_type: "xã",
    codename: "xa_dien_minh",
    district_code: 507,
  },
  {
    name: "Xã Điện Phương",
    code: 20596,
    division_type: "xã",
    codename: "xa_dien_phuong",
    district_code: 507,
  },
  {
    name: "Thị trấn Nam Phước",
    code: 20599,
    division_type: "thị trấn",
    codename: "thi_tran_nam_phuoc",
    district_code: 508,
  },
  {
    name: "Xã Duy Thu",
    code: 20602,
    division_type: "xã",
    codename: "xa_duy_thu",
    district_code: 508,
  },
  {
    name: "Xã Duy Phú",
    code: 20605,
    division_type: "xã",
    codename: "xa_duy_phu",
    district_code: 508,
  },
  {
    name: "Xã Duy Tân",
    code: 20608,
    division_type: "xã",
    codename: "xa_duy_tan",
    district_code: 508,
  },
  {
    name: "Xã Duy Hòa",
    code: 20611,
    division_type: "xã",
    codename: "xa_duy_hoa",
    district_code: 508,
  },
  {
    name: "Xã Duy Châu",
    code: 20614,
    division_type: "xã",
    codename: "xa_duy_chau",
    district_code: 508,
  },
  {
    name: "Xã Duy Trinh",
    code: 20617,
    division_type: "xã",
    codename: "xa_duy_trinh",
    district_code: 508,
  },
  {
    name: "Xã Duy Sơn",
    code: 20620,
    division_type: "xã",
    codename: "xa_duy_son",
    district_code: 508,
  },
  {
    name: "Xã Duy Trung",
    code: 20623,
    division_type: "xã",
    codename: "xa_duy_trung",
    district_code: 508,
  },
  {
    name: "Xã Duy Phước",
    code: 20626,
    division_type: "xã",
    codename: "xa_duy_phuoc",
    district_code: 508,
  },
  {
    name: "Xã Duy Thành",
    code: 20629,
    division_type: "xã",
    codename: "xa_duy_thanh",
    district_code: 508,
  },
  {
    name: "Xã Duy Vinh",
    code: 20632,
    division_type: "xã",
    codename: "xa_duy_vinh",
    district_code: 508,
  },
  {
    name: "Xã Duy Nghĩa",
    code: 20635,
    division_type: "xã",
    codename: "xa_duy_nghia",
    district_code: 508,
  },
  {
    name: "Xã Duy Hải",
    code: 20638,
    division_type: "xã",
    codename: "xa_duy_hai",
    district_code: 508,
  },
  {
    name: "Thị trấn Đông Phú",
    code: 20641,
    division_type: "thị trấn",
    codename: "thi_tran_dong_phu",
    district_code: 509,
  },
  {
    name: "Xã Quế Xuân 1",
    code: 20644,
    division_type: "xã",
    codename: "xa_que_xuan_1",
    district_code: 509,
  },
  {
    name: "Xã Quế Xuân 2",
    code: 20647,
    division_type: "xã",
    codename: "xa_que_xuan_2",
    district_code: 509,
  },
  {
    name: "Xã Quế Phú",
    code: 20650,
    division_type: "xã",
    codename: "xa_que_phu",
    district_code: 509,
  },
  {
    name: "Thị trấn Hương An",
    code: 20651,
    division_type: "thị trấn",
    codename: "thi_tran_huong_an",
    district_code: 509,
  },
  {
    name: "Xã Quế Hiệp",
    code: 20659,
    division_type: "xã",
    codename: "xa_que_hiep",
    district_code: 509,
  },
  {
    name: "Xã Quế Thuận",
    code: 20662,
    division_type: "xã",
    codename: "xa_que_thuan",
    district_code: 509,
  },
  {
    name: "Xã Quế Mỹ",
    code: 20665,
    division_type: "xã",
    codename: "xa_que_my",
    district_code: 509,
  },
  {
    name: "Xã Quế Long",
    code: 20677,
    division_type: "xã",
    codename: "xa_que_long",
    district_code: 509,
  },
  {
    name: "Xã Quế Châu",
    code: 20680,
    division_type: "xã",
    codename: "xa_que_chau",
    district_code: 509,
  },
  {
    name: "Xã Quế Phong",
    code: 20683,
    division_type: "xã",
    codename: "xa_que_phong",
    district_code: 509,
  },
  {
    name: "Xã Quế An",
    code: 20686,
    division_type: "xã",
    codename: "xa_que_an",
    district_code: 509,
  },
  {
    name: "Xã Quế Minh",
    code: 20689,
    division_type: "xã",
    codename: "xa_que_minh",
    district_code: 509,
  },
  {
    name: "Thị trấn Thạnh Mỹ",
    code: 20695,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_my",
    district_code: 510,
  },
  {
    name: "Xã Laêê",
    code: 20698,
    division_type: "xã",
    codename: "xa_laee",
    district_code: 510,
  },
  {
    name: "Xã Chơ Chun",
    code: 20699,
    division_type: "xã",
    codename: "xa_cho_chun",
    district_code: 510,
  },
  {
    name: "Xã Zuôich",
    code: 20701,
    division_type: "xã",
    codename: "xa_zuoich",
    district_code: 510,
  },
  {
    name: "Xã Tà Pơơ",
    code: 20702,
    division_type: "xã",
    codename: "xa_ta_poo",
    district_code: 510,
  },
  {
    name: "Xã La Dêê",
    code: 20704,
    division_type: "xã",
    codename: "xa_la_dee",
    district_code: 510,
  },
  {
    name: "Xã Đắc Tôi",
    code: 20705,
    division_type: "xã",
    codename: "xa_dac_toi",
    district_code: 510,
  },
  {
    name: "Xã Chà Vàl",
    code: 20707,
    division_type: "xã",
    codename: "xa_cha_val",
    district_code: 510,
  },
  {
    name: "Xã Tà Bhinh",
    code: 20710,
    division_type: "xã",
    codename: "xa_ta_bhinh",
    district_code: 510,
  },
  {
    name: "Xã Cà Dy",
    code: 20713,
    division_type: "xã",
    codename: "xa_ca_dy",
    district_code: 510,
  },
  {
    name: "Xã Đắc Pre",
    code: 20716,
    division_type: "xã",
    codename: "xa_dac_pre",
    district_code: 510,
  },
  {
    name: "Xã Đắc Pring",
    code: 20719,
    division_type: "xã",
    codename: "xa_dac_pring",
    district_code: 510,
  },
  {
    name: "Thị trấn Khâm Đức",
    code: 20722,
    division_type: "thị trấn",
    codename: "thi_tran_kham_duc",
    district_code: 511,
  },
  {
    name: "Xã Phước Xuân",
    code: 20725,
    division_type: "xã",
    codename: "xa_phuoc_xuan",
    district_code: 511,
  },
  {
    name: "Xã Phước Hiệp",
    code: 20728,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 511,
  },
  {
    name: "Xã Phước Hoà",
    code: 20729,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 511,
  },
  {
    name: "Xã Phước Đức",
    code: 20731,
    division_type: "xã",
    codename: "xa_phuoc_duc",
    district_code: 511,
  },
  {
    name: "Xã Phước Năng",
    code: 20734,
    division_type: "xã",
    codename: "xa_phuoc_nang",
    district_code: 511,
  },
  {
    name: "Xã Phước Mỹ",
    code: 20737,
    division_type: "xã",
    codename: "xa_phuoc_my",
    district_code: 511,
  },
  {
    name: "Xã Phước Chánh",
    code: 20740,
    division_type: "xã",
    codename: "xa_phuoc_chanh",
    district_code: 511,
  },
  {
    name: "Xã Phước Công",
    code: 20743,
    division_type: "xã",
    codename: "xa_phuoc_cong",
    district_code: 511,
  },
  {
    name: "Xã Phước Kim",
    code: 20746,
    division_type: "xã",
    codename: "xa_phuoc_kim",
    district_code: 511,
  },
  {
    name: "Xã Phước Lộc",
    code: 20749,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 511,
  },
  {
    name: "Xã Phước Thành",
    code: 20752,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 511,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 20758,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 512,
  },
  {
    name: "Xã Hiệp Thuận",
    code: 20761,
    division_type: "xã",
    codename: "xa_hiep_thuan",
    district_code: 512,
  },
  {
    name: "Xã Quế Thọ",
    code: 20764,
    division_type: "xã",
    codename: "xa_que_tho",
    district_code: 512,
  },
  {
    name: "Xã Bình Lâm",
    code: 20767,
    division_type: "xã",
    codename: "xa_binh_lam",
    district_code: 512,
  },
  {
    name: "Xã Sông Trà",
    code: 20770,
    division_type: "xã",
    codename: "xa_song_tra",
    district_code: 512,
  },
  {
    name: "Xã Phước Trà",
    code: 20773,
    division_type: "xã",
    codename: "xa_phuoc_tra",
    district_code: 512,
  },
  {
    name: "Xã Phước Gia",
    code: 20776,
    division_type: "xã",
    codename: "xa_phuoc_gia",
    district_code: 512,
  },
  {
    name: "Thị trấn Tân Bình",
    code: 20779,
    division_type: "thị trấn",
    codename: "thi_tran_tan_binh",
    district_code: 512,
  },
  {
    name: "Xã Quế Lưu",
    code: 20782,
    division_type: "xã",
    codename: "xa_que_luu",
    district_code: 512,
  },
  {
    name: "Xã Thăng Phước",
    code: 20785,
    division_type: "xã",
    codename: "xa_thang_phuoc",
    district_code: 512,
  },
  {
    name: "Xã Bình Sơn",
    code: 20788,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 512,
  },
  {
    name: "Thị trấn Hà Lam",
    code: 20791,
    division_type: "thị trấn",
    codename: "thi_tran_ha_lam",
    district_code: 513,
  },
  {
    name: "Xã Bình Dương",
    code: 20794,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 513,
  },
  {
    name: "Xã Bình Giang",
    code: 20797,
    division_type: "xã",
    codename: "xa_binh_giang",
    district_code: 513,
  },
  {
    name: "Xã Bình Nguyên",
    code: 20800,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 513,
  },
  {
    name: "Xã Bình Phục",
    code: 20803,
    division_type: "xã",
    codename: "xa_binh_phuc",
    district_code: 513,
  },
  {
    name: "Xã Bình Triều",
    code: 20806,
    division_type: "xã",
    codename: "xa_binh_trieu",
    district_code: 513,
  },
  {
    name: "Xã Bình Đào",
    code: 20809,
    division_type: "xã",
    codename: "xa_binh_dao",
    district_code: 513,
  },
  {
    name: "Xã Bình Minh",
    code: 20812,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 513,
  },
  {
    name: "Xã Bình Lãnh",
    code: 20815,
    division_type: "xã",
    codename: "xa_binh_lanh",
    district_code: 513,
  },
  {
    name: "Xã Bình Trị",
    code: 20818,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 513,
  },
  {
    name: "Xã Bình Định Bắc",
    code: 20821,
    division_type: "xã",
    codename: "xa_binh_dinh_bac",
    district_code: 513,
  },
  {
    name: "Xã Bình Định Nam",
    code: 20822,
    division_type: "xã",
    codename: "xa_binh_dinh_nam",
    district_code: 513,
  },
  {
    name: "Xã Bình Quý",
    code: 20824,
    division_type: "xã",
    codename: "xa_binh_quy",
    district_code: 513,
  },
  {
    name: "Xã Bình Phú",
    code: 20827,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 513,
  },
  {
    name: "Xã Bình Chánh",
    code: 20830,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 513,
  },
  {
    name: "Xã Bình Tú",
    code: 20833,
    division_type: "xã",
    codename: "xa_binh_tu",
    district_code: 513,
  },
  {
    name: "Xã Bình Sa",
    code: 20836,
    division_type: "xã",
    codename: "xa_binh_sa",
    district_code: 513,
  },
  {
    name: "Xã Bình Hải",
    code: 20839,
    division_type: "xã",
    codename: "xa_binh_hai",
    district_code: 513,
  },
  {
    name: "Xã Bình Quế",
    code: 20842,
    division_type: "xã",
    codename: "xa_binh_que",
    district_code: 513,
  },
  {
    name: "Xã Bình An",
    code: 20845,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 513,
  },
  {
    name: "Xã Bình Trung",
    code: 20848,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 513,
  },
  {
    name: "Xã Bình Nam",
    code: 20851,
    division_type: "xã",
    codename: "xa_binh_nam",
    district_code: 513,
  },
  {
    name: "Thị trấn Tiên Kỳ",
    code: 20854,
    division_type: "thị trấn",
    codename: "thi_tran_tien_ky",
    district_code: 514,
  },
  {
    name: "Xã Tiên Sơn",
    code: 20857,
    division_type: "xã",
    codename: "xa_tien_son",
    district_code: 514,
  },
  {
    name: "Xã Tiên Hà",
    code: 20860,
    division_type: "xã",
    codename: "xa_tien_ha",
    district_code: 514,
  },
  {
    name: "Xã Tiên Cẩm",
    code: 20863,
    division_type: "xã",
    codename: "xa_tien_cam",
    district_code: 514,
  },
  {
    name: "Xã Tiên Châu",
    code: 20866,
    division_type: "xã",
    codename: "xa_tien_chau",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lãnh",
    code: 20869,
    division_type: "xã",
    codename: "xa_tien_lanh",
    district_code: 514,
  },
  {
    name: "Xã Tiên Ngọc",
    code: 20872,
    division_type: "xã",
    codename: "xa_tien_ngoc",
    district_code: 514,
  },
  {
    name: "Xã Tiên Hiệp",
    code: 20875,
    division_type: "xã",
    codename: "xa_tien_hiep",
    district_code: 514,
  },
  {
    name: "Xã Tiên Cảnh",
    code: 20878,
    division_type: "xã",
    codename: "xa_tien_canh",
    district_code: 514,
  },
  {
    name: "Xã Tiên Mỹ",
    code: 20881,
    division_type: "xã",
    codename: "xa_tien_my",
    district_code: 514,
  },
  {
    name: "Xã Tiên Phong",
    code: 20884,
    division_type: "xã",
    codename: "xa_tien_phong",
    district_code: 514,
  },
  {
    name: "Xã Tiên Thọ",
    code: 20887,
    division_type: "xã",
    codename: "xa_tien_tho",
    district_code: 514,
  },
  {
    name: "Xã Tiên An",
    code: 20890,
    division_type: "xã",
    codename: "xa_tien_an",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lộc",
    code: 20893,
    division_type: "xã",
    codename: "xa_tien_loc",
    district_code: 514,
  },
  {
    name: "Xã Tiên Lập",
    code: 20896,
    division_type: "xã",
    codename: "xa_tien_lap",
    district_code: 514,
  },
  {
    name: "Thị trấn Trà My",
    code: 20899,
    division_type: "thị trấn",
    codename: "thi_tran_tra_my",
    district_code: 515,
  },
  {
    name: "Xã Trà Sơn",
    code: 20900,
    division_type: "xã",
    codename: "xa_tra_son",
    district_code: 515,
  },
  {
    name: "Xã Trà Kót",
    code: 20902,
    division_type: "xã",
    codename: "xa_tra_kot",
    district_code: 515,
  },
  {
    name: "Xã Trà Nú",
    code: 20905,
    division_type: "xã",
    codename: "xa_tra_nu",
    district_code: 515,
  },
  {
    name: "Xã Trà Đông",
    code: 20908,
    division_type: "xã",
    codename: "xa_tra_dong",
    district_code: 515,
  },
  {
    name: "Xã Trà Dương",
    code: 20911,
    division_type: "xã",
    codename: "xa_tra_duong",
    district_code: 515,
  },
  {
    name: "Xã Trà Giang",
    code: 20914,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 515,
  },
  {
    name: "Xã Trà Bui",
    code: 20917,
    division_type: "xã",
    codename: "xa_tra_bui",
    district_code: 515,
  },
  {
    name: "Xã Trà Đốc",
    code: 20920,
    division_type: "xã",
    codename: "xa_tra_doc",
    district_code: 515,
  },
  {
    name: "Xã Trà Tân",
    code: 20923,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 515,
  },
  {
    name: "Xã Trà Giác",
    code: 20926,
    division_type: "xã",
    codename: "xa_tra_giac",
    district_code: 515,
  },
  {
    name: "Xã Trà Giáp",
    code: 20929,
    division_type: "xã",
    codename: "xa_tra_giap",
    district_code: 515,
  },
  {
    name: "Xã Trà Ka",
    code: 20932,
    division_type: "xã",
    codename: "xa_tra_ka",
    district_code: 515,
  },
  {
    name: "Xã Trà Leng",
    code: 20935,
    division_type: "xã",
    codename: "xa_tra_leng",
    district_code: 516,
  },
  {
    name: "Xã Trà Dơn",
    code: 20938,
    division_type: "xã",
    codename: "xa_tra_don",
    district_code: 516,
  },
  {
    name: "Xã Trà Tập",
    code: 20941,
    division_type: "xã",
    codename: "xa_tra_tap",
    district_code: 516,
  },
  {
    name: "Xã Trà Mai",
    code: 20944,
    division_type: "xã",
    codename: "xa_tra_mai",
    district_code: 516,
  },
  {
    name: "Xã Trà Cang",
    code: 20947,
    division_type: "xã",
    codename: "xa_tra_cang",
    district_code: 516,
  },
  {
    name: "Xã Trà Linh",
    code: 20950,
    division_type: "xã",
    codename: "xa_tra_linh",
    district_code: 516,
  },
  {
    name: "Xã Trà Nam",
    code: 20953,
    division_type: "xã",
    codename: "xa_tra_nam",
    district_code: 516,
  },
  {
    name: "Xã Trà Don",
    code: 20956,
    division_type: "xã",
    codename: "xa_tra_don",
    district_code: 516,
  },
  {
    name: "Xã Trà Vân",
    code: 20959,
    division_type: "xã",
    codename: "xa_tra_van",
    district_code: 516,
  },
  {
    name: "Xã Trà Vinh",
    code: 20962,
    division_type: "xã",
    codename: "xa_tra_vinh",
    district_code: 516,
  },
  {
    name: "Thị trấn Núi Thành",
    code: 20965,
    division_type: "thị trấn",
    codename: "thi_tran_nui_thanh",
    district_code: 517,
  },
  {
    name: "Xã Tam Xuân I",
    code: 20968,
    division_type: "xã",
    codename: "xa_tam_xuan_i",
    district_code: 517,
  },
  {
    name: "Xã Tam Xuân II",
    code: 20971,
    division_type: "xã",
    codename: "xa_tam_xuan_ii",
    district_code: 517,
  },
  {
    name: "Xã Tam Tiến",
    code: 20974,
    division_type: "xã",
    codename: "xa_tam_tien",
    district_code: 517,
  },
  {
    name: "Xã Tam Sơn",
    code: 20977,
    division_type: "xã",
    codename: "xa_tam_son",
    district_code: 517,
  },
  {
    name: "Xã Tam Thạnh",
    code: 20980,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 517,
  },
  {
    name: "Xã Tam Anh Bắc",
    code: 20983,
    division_type: "xã",
    codename: "xa_tam_anh_bac",
    district_code: 517,
  },
  {
    name: "Xã Tam Anh Nam",
    code: 20984,
    division_type: "xã",
    codename: "xa_tam_anh_nam",
    district_code: 517,
  },
  {
    name: "Xã Tam Hòa",
    code: 20986,
    division_type: "xã",
    codename: "xa_tam_hoa",
    district_code: 517,
  },
  {
    name: "Xã Tam Hiệp",
    code: 20989,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 517,
  },
  {
    name: "Xã Tam Hải",
    code: 20992,
    division_type: "xã",
    codename: "xa_tam_hai",
    district_code: 517,
  },
  {
    name: "Xã Tam Giang",
    code: 20995,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 517,
  },
  {
    name: "Xã Tam Quang",
    code: 20998,
    division_type: "xã",
    codename: "xa_tam_quang",
    district_code: 517,
  },
  {
    name: "Xã Tam Nghĩa",
    code: 21001,
    division_type: "xã",
    codename: "xa_tam_nghia",
    district_code: 517,
  },
  {
    name: "Xã Tam Mỹ Tây",
    code: 21004,
    division_type: "xã",
    codename: "xa_tam_my_tay",
    district_code: 517,
  },
  {
    name: "Xã Tam Mỹ Đông",
    code: 21005,
    division_type: "xã",
    codename: "xa_tam_my_dong",
    district_code: 517,
  },
  {
    name: "Xã Tam Trà",
    code: 21007,
    division_type: "xã",
    codename: "xa_tam_tra",
    district_code: 517,
  },
  {
    name: "Thị trấn Phú Thịnh",
    code: 20364,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thinh",
    district_code: 518,
  },
  {
    name: "Xã Tam Thành",
    code: 20365,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 518,
  },
  {
    name: "Xã Tam An",
    code: 20368,
    division_type: "xã",
    codename: "xa_tam_an",
    district_code: 518,
  },
  {
    name: "Xã Tam Đàn",
    code: 20374,
    division_type: "xã",
    codename: "xa_tam_dan",
    district_code: 518,
  },
  {
    name: "Xã Tam Lộc",
    code: 20377,
    division_type: "xã",
    codename: "xa_tam_loc",
    district_code: 518,
  },
  {
    name: "Xã Tam Phước",
    code: 20380,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 518,
  },
  {
    name: "Xã Tam Vinh",
    code: 20383,
    division_type: "xã",
    codename: "xa_tam_vinh",
    district_code: 518,
  },
  {
    name: "Xã Tam Thái",
    code: 20386,
    division_type: "xã",
    codename: "xa_tam_thai",
    district_code: 518,
  },
  {
    name: "Xã Tam Đại",
    code: 20387,
    division_type: "xã",
    codename: "xa_tam_dai",
    district_code: 518,
  },
  {
    name: "Xã Tam Dân",
    code: 20392,
    division_type: "xã",
    codename: "xa_tam_dan",
    district_code: 518,
  },
  {
    name: "Xã Tam Lãnh",
    code: 20395,
    division_type: "xã",
    codename: "xa_tam_lanh",
    district_code: 518,
  },
  {
    name: "Xã Quế Trung",
    code: 20656,
    division_type: "xã",
    codename: "xa_que_trung",
    district_code: 519,
  },
  {
    name: "Xã Ninh Phước",
    code: 20668,
    division_type: "xã",
    codename: "xa_ninh_phuoc",
    district_code: 519,
  },
  {
    name: "Xã Phước Ninh",
    code: 20669,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 519,
  },
  {
    name: "Xã Quế Lộc",
    code: 20671,
    division_type: "xã",
    codename: "xa_que_loc",
    district_code: 519,
  },
  {
    name: "Xã Sơn Viên",
    code: 20672,
    division_type: "xã",
    codename: "xa_son_vien",
    district_code: 519,
  },
  {
    name: "Xã Quế Lâm",
    code: 20692,
    division_type: "xã",
    codename: "xa_que_lam",
    district_code: 519,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 21010,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 522,
  },
  {
    name: "Phường Trần Phú",
    code: 21013,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 522,
  },
  {
    name: "Phường Quảng Phú",
    code: 21016,
    division_type: "phường",
    codename: "phuong_quang_phu",
    district_code: 522,
  },
  {
    name: "Phường Nghĩa Chánh",
    code: 21019,
    division_type: "phường",
    codename: "phuong_nghia_chanh",
    district_code: 522,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 21022,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 522,
  },
  {
    name: "Phường Nguyễn Nghiêm",
    code: 21025,
    division_type: "phường",
    codename: "phuong_nguyen_nghiem",
    district_code: 522,
  },
  {
    name: "Phường Nghĩa Lộ",
    code: 21028,
    division_type: "phường",
    codename: "phuong_nghia_lo",
    district_code: 522,
  },
  {
    name: "Phường Chánh Lộ",
    code: 21031,
    division_type: "phường",
    codename: "phuong_chanh_lo",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Dũng",
    code: 21034,
    division_type: "xã",
    codename: "xa_nghia_dung",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Dõng",
    code: 21037,
    division_type: "xã",
    codename: "xa_nghia_dong",
    district_code: 522,
  },
  {
    name: "Phường Trương Quang Trọng",
    code: 21172,
    division_type: "phường",
    codename: "phuong_truong_quang_trong",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Hòa",
    code: 21187,
    division_type: "xã",
    codename: "xa_tinh_hoa",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Kỳ",
    code: 21190,
    division_type: "xã",
    codename: "xa_tinh_ky",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Thiện",
    code: 21199,
    division_type: "xã",
    codename: "xa_tinh_thien",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Ấn Đông",
    code: 21202,
    division_type: "xã",
    codename: "xa_tinh_an_dong",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Châu",
    code: 21208,
    division_type: "xã",
    codename: "xa_tinh_chau",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Khê",
    code: 21211,
    division_type: "xã",
    codename: "xa_tinh_khe",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Long",
    code: 21214,
    division_type: "xã",
    codename: "xa_tinh_long",
    district_code: 522,
  },
  {
    name: "Xã Tịnh Ấn Tây",
    code: 21223,
    division_type: "xã",
    codename: "xa_tinh_an_tay",
    district_code: 522,
  },
  {
    name: "Xã Tịnh An",
    code: 21232,
    division_type: "xã",
    codename: "xa_tinh_an",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Phú",
    code: 21253,
    division_type: "xã",
    codename: "xa_nghia_phu",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa Hà",
    code: 21256,
    division_type: "xã",
    codename: "xa_nghia_ha",
    district_code: 522,
  },
  {
    name: "Xã Nghĩa An",
    code: 21262,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 522,
  },
  {
    name: "Thị trấn Châu Ổ",
    code: 21040,
    division_type: "thị trấn",
    codename: "thi_tran_chau_o",
    district_code: 524,
  },
  {
    name: "Xã Bình Thuận",
    code: 21043,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 524,
  },
  {
    name: "Xã Bình Thạnh",
    code: 21046,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Đông",
    code: 21049,
    division_type: "xã",
    codename: "xa_binh_dong",
    district_code: 524,
  },
  {
    name: "Xã Bình Chánh",
    code: 21052,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Nguyên",
    code: 21055,
    division_type: "xã",
    codename: "xa_binh_nguyen",
    district_code: 524,
  },
  {
    name: "Xã Bình Khương",
    code: 21058,
    division_type: "xã",
    codename: "xa_binh_khuong",
    district_code: 524,
  },
  {
    name: "Xã Bình Trị",
    code: 21061,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 524,
  },
  {
    name: "Xã Bình An",
    code: 21064,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 524,
  },
  {
    name: "Xã Bình Hải",
    code: 21067,
    division_type: "xã",
    codename: "xa_binh_hai",
    district_code: 524,
  },
  {
    name: "Xã Bình Dương",
    code: 21070,
    division_type: "xã",
    codename: "xa_binh_duong",
    district_code: 524,
  },
  {
    name: "Xã Bình Phước",
    code: 21073,
    division_type: "xã",
    codename: "xa_binh_phuoc",
    district_code: 524,
  },
  {
    name: "Xã Bình Hòa",
    code: 21079,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 524,
  },
  {
    name: "Xã Bình Trung",
    code: 21082,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 524,
  },
  {
    name: "Xã Bình Minh",
    code: 21085,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 524,
  },
  {
    name: "Xã Bình Long",
    code: 21088,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 524,
  },
  {
    name: "Xã Bình Thanh",
    code: 21091,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 524,
  },
  {
    name: "Xã Bình Chương",
    code: 21100,
    division_type: "xã",
    codename: "xa_binh_chuong",
    district_code: 524,
  },
  {
    name: "Xã Bình Hiệp",
    code: 21103,
    division_type: "xã",
    codename: "xa_binh_hiep",
    district_code: 524,
  },
  {
    name: "Xã Bình Mỹ",
    code: 21106,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 524,
  },
  {
    name: "Xã Bình Tân Phú",
    code: 21109,
    division_type: "xã",
    codename: "xa_binh_tan_phu",
    district_code: 524,
  },
  {
    name: "Xã Bình Châu",
    code: 21112,
    division_type: "xã",
    codename: "xa_binh_chau",
    district_code: 524,
  },
  {
    name: "Thị trấn Trà Xuân",
    code: 21115,
    division_type: "thị trấn",
    codename: "thi_tran_tra_xuan",
    district_code: 525,
  },
  {
    name: "Xã Trà Giang",
    code: 21118,
    division_type: "xã",
    codename: "xa_tra_giang",
    district_code: 525,
  },
  {
    name: "Xã Trà Thủy",
    code: 21121,
    division_type: "xã",
    codename: "xa_tra_thuy",
    district_code: 525,
  },
  {
    name: "Xã Trà Hiệp",
    code: 21124,
    division_type: "xã",
    codename: "xa_tra_hiep",
    district_code: 525,
  },
  {
    name: "Xã Trà Bình",
    code: 21127,
    division_type: "xã",
    codename: "xa_tra_binh",
    district_code: 525,
  },
  {
    name: "Xã Trà Phú",
    code: 21130,
    division_type: "xã",
    codename: "xa_tra_phu",
    district_code: 525,
  },
  {
    name: "Xã Trà Lâm",
    code: 21133,
    division_type: "xã",
    codename: "xa_tra_lam",
    district_code: 525,
  },
  {
    name: "Xã Trà Tân",
    code: 21136,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 525,
  },
  {
    name: "Xã Trà Sơn",
    code: 21139,
    division_type: "xã",
    codename: "xa_tra_son",
    district_code: 525,
  },
  {
    name: "Xã Trà Bùi",
    code: 21142,
    division_type: "xã",
    codename: "xa_tra_bui",
    district_code: 525,
  },
  {
    name: "Xã Trà Thanh",
    code: 21145,
    division_type: "xã",
    codename: "xa_tra_thanh",
    district_code: 525,
  },
  {
    name: "Xã Sơn Trà",
    code: 21148,
    division_type: "xã",
    codename: "xa_son_tra",
    district_code: 525,
  },
  {
    name: "Xã Trà Phong",
    code: 21154,
    division_type: "xã",
    codename: "xa_tra_phong",
    district_code: 525,
  },
  {
    name: "Xã Hương Trà",
    code: 21157,
    division_type: "xã",
    codename: "xa_huong_tra",
    district_code: 525,
  },
  {
    name: "Xã Trà Xinh",
    code: 21163,
    division_type: "xã",
    codename: "xa_tra_xinh",
    district_code: 525,
  },
  {
    name: "Xã Trà Tây",
    code: 21166,
    division_type: "xã",
    codename: "xa_tra_tay",
    district_code: 525,
  },
  {
    name: "Xã Tịnh Thọ",
    code: 21175,
    division_type: "xã",
    codename: "xa_tinh_tho",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Trà",
    code: 21178,
    division_type: "xã",
    codename: "xa_tinh_tra",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Phong",
    code: 21181,
    division_type: "xã",
    codename: "xa_tinh_phong",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Hiệp",
    code: 21184,
    division_type: "xã",
    codename: "xa_tinh_hiep",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Bình",
    code: 21193,
    division_type: "xã",
    codename: "xa_tinh_binh",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Đông",
    code: 21196,
    division_type: "xã",
    codename: "xa_tinh_dong",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Bắc",
    code: 21205,
    division_type: "xã",
    codename: "xa_tinh_bac",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Sơn",
    code: 21217,
    division_type: "xã",
    codename: "xa_tinh_son",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Hà",
    code: 21220,
    division_type: "xã",
    codename: "xa_tinh_ha",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Giang",
    code: 21226,
    division_type: "xã",
    codename: "xa_tinh_giang",
    district_code: 527,
  },
  {
    name: "Xã Tịnh Minh",
    code: 21229,
    division_type: "xã",
    codename: "xa_tinh_minh",
    district_code: 527,
  },
  {
    name: "Thị trấn La Hà",
    code: 21235,
    division_type: "thị trấn",
    codename: "thi_tran_la_ha",
    district_code: 528,
  },
  {
    name: "Thị trấn Sông Vệ",
    code: 21238,
    division_type: "thị trấn",
    codename: "thi_tran_song_ve",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Lâm",
    code: 21241,
    division_type: "xã",
    codename: "xa_nghia_lam",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thắng",
    code: 21244,
    division_type: "xã",
    codename: "xa_nghia_thang",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thuận",
    code: 21247,
    division_type: "xã",
    codename: "xa_nghia_thuan",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Kỳ",
    code: 21250,
    division_type: "xã",
    codename: "xa_nghia_ky",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Sơn",
    code: 21259,
    division_type: "xã",
    codename: "xa_nghia_son",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 21268,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Điền",
    code: 21271,
    division_type: "xã",
    codename: "xa_nghia_dien",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Thương",
    code: 21274,
    division_type: "xã",
    codename: "xa_nghia_thuong",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 21277,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Hiệp",
    code: 21280,
    division_type: "xã",
    codename: "xa_nghia_hiep",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Phương",
    code: 21283,
    division_type: "xã",
    codename: "xa_nghia_phuong",
    district_code: 528,
  },
  {
    name: "Xã Nghĩa Mỹ",
    code: 21286,
    division_type: "xã",
    codename: "xa_nghia_my",
    district_code: 528,
  },
  {
    name: "Thị trấn Di Lăng",
    code: 21289,
    division_type: "thị trấn",
    codename: "thi_tran_di_lang",
    district_code: 529,
  },
  {
    name: "Xã Sơn Hạ",
    code: 21292,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thành",
    code: 21295,
    division_type: "xã",
    codename: "xa_son_thanh",
    district_code: 529,
  },
  {
    name: "Xã Sơn Nham",
    code: 21298,
    division_type: "xã",
    codename: "xa_son_nham",
    district_code: 529,
  },
  {
    name: "Xã Sơn Bao",
    code: 21301,
    division_type: "xã",
    codename: "xa_son_bao",
    district_code: 529,
  },
  {
    name: "Xã Sơn Linh",
    code: 21304,
    division_type: "xã",
    codename: "xa_son_linh",
    district_code: 529,
  },
  {
    name: "Xã Sơn Giang",
    code: 21307,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 529,
  },
  {
    name: "Xã Sơn Trung",
    code: 21310,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thượng",
    code: 21313,
    division_type: "xã",
    codename: "xa_son_thuong",
    district_code: 529,
  },
  {
    name: "Xã Sơn Cao",
    code: 21316,
    division_type: "xã",
    codename: "xa_son_cao",
    district_code: 529,
  },
  {
    name: "Xã Sơn Hải",
    code: 21319,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 529,
  },
  {
    name: "Xã Sơn Thủy",
    code: 21322,
    division_type: "xã",
    codename: "xa_son_thuy",
    district_code: 529,
  },
  {
    name: "Xã Sơn Kỳ",
    code: 21325,
    division_type: "xã",
    codename: "xa_son_ky",
    district_code: 529,
  },
  {
    name: "Xã Sơn Ba",
    code: 21328,
    division_type: "xã",
    codename: "xa_son_ba",
    district_code: 529,
  },
  {
    name: "Xã Sơn Bua",
    code: 21331,
    division_type: "xã",
    codename: "xa_son_bua",
    district_code: 530,
  },
  {
    name: "Xã Sơn Mùa",
    code: 21334,
    division_type: "xã",
    codename: "xa_son_mua",
    district_code: 530,
  },
  {
    name: "Xã Sơn Liên",
    code: 21335,
    division_type: "xã",
    codename: "xa_son_lien",
    district_code: 530,
  },
  {
    name: "Xã Sơn Tân",
    code: 21337,
    division_type: "xã",
    codename: "xa_son_tan",
    district_code: 530,
  },
  {
    name: "Xã Sơn Màu",
    code: 21338,
    division_type: "xã",
    codename: "xa_son_mau",
    district_code: 530,
  },
  {
    name: "Xã Sơn Dung",
    code: 21340,
    division_type: "xã",
    codename: "xa_son_dung",
    district_code: 530,
  },
  {
    name: "Xã Sơn Long",
    code: 21341,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 530,
  },
  {
    name: "Xã Sơn Tinh",
    code: 21343,
    division_type: "xã",
    codename: "xa_son_tinh",
    district_code: 530,
  },
  {
    name: "Xã Sơn Lập",
    code: 21346,
    division_type: "xã",
    codename: "xa_son_lap",
    district_code: 530,
  },
  {
    name: "Xã Long Sơn",
    code: 21349,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 531,
  },
  {
    name: "Xã Long Mai",
    code: 21352,
    division_type: "xã",
    codename: "xa_long_mai",
    district_code: 531,
  },
  {
    name: "Xã Thanh An",
    code: 21355,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 531,
  },
  {
    name: "Xã Long Môn",
    code: 21358,
    division_type: "xã",
    codename: "xa_long_mon",
    district_code: 531,
  },
  {
    name: "Xã Long Hiệp",
    code: 21361,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 531,
  },
  {
    name: "Thị trấn Chợ Chùa",
    code: 21364,
    division_type: "thị trấn",
    codename: "thi_tran_cho_chua",
    district_code: 532,
  },
  {
    name: "Xã Hành Thuận",
    code: 21367,
    division_type: "xã",
    codename: "xa_hanh_thuan",
    district_code: 532,
  },
  {
    name: "Xã Hành Dũng",
    code: 21370,
    division_type: "xã",
    codename: "xa_hanh_dung",
    district_code: 532,
  },
  {
    name: "Xã Hành Trung",
    code: 21373,
    division_type: "xã",
    codename: "xa_hanh_trung",
    district_code: 532,
  },
  {
    name: "Xã Hành Nhân",
    code: 21376,
    division_type: "xã",
    codename: "xa_hanh_nhan",
    district_code: 532,
  },
  {
    name: "Xã Hành Đức",
    code: 21379,
    division_type: "xã",
    codename: "xa_hanh_duc",
    district_code: 532,
  },
  {
    name: "Xã Hành Minh",
    code: 21382,
    division_type: "xã",
    codename: "xa_hanh_minh",
    district_code: 532,
  },
  {
    name: "Xã Hành Phước",
    code: 21385,
    division_type: "xã",
    codename: "xa_hanh_phuoc",
    district_code: 532,
  },
  {
    name: "Xã Hành Thiện",
    code: 21388,
    division_type: "xã",
    codename: "xa_hanh_thien",
    district_code: 532,
  },
  {
    name: "Xã Hành Thịnh",
    code: 21391,
    division_type: "xã",
    codename: "xa_hanh_thinh",
    district_code: 532,
  },
  {
    name: "Xã Hành Tín Tây",
    code: 21394,
    division_type: "xã",
    codename: "xa_hanh_tin_tay",
    district_code: 532,
  },
  {
    name: "Xã Hành Tín Đông",
    code: 21397,
    division_type: "xã",
    codename: "xa_hanh_tin_dong",
    district_code: 532,
  },
  {
    name: "Thị trấn Mộ Đức",
    code: 21400,
    division_type: "thị trấn",
    codename: "thi_tran_mo_duc",
    district_code: 533,
  },
  {
    name: "Xã Đức Lợi",
    code: 21403,
    division_type: "xã",
    codename: "xa_duc_loi",
    district_code: 533,
  },
  {
    name: "Xã Đức Thắng",
    code: 21406,
    division_type: "xã",
    codename: "xa_duc_thang",
    district_code: 533,
  },
  {
    name: "Xã Đức Nhuận",
    code: 21409,
    division_type: "xã",
    codename: "xa_duc_nhuan",
    district_code: 533,
  },
  {
    name: "Xã Đức Chánh",
    code: 21412,
    division_type: "xã",
    codename: "xa_duc_chanh",
    district_code: 533,
  },
  {
    name: "Xã Đức Hiệp",
    code: 21415,
    division_type: "xã",
    codename: "xa_duc_hiep",
    district_code: 533,
  },
  {
    name: "Xã Đức Minh",
    code: 21418,
    division_type: "xã",
    codename: "xa_duc_minh",
    district_code: 533,
  },
  {
    name: "Xã Đức Thạnh",
    code: 21421,
    division_type: "xã",
    codename: "xa_duc_thanh",
    district_code: 533,
  },
  {
    name: "Xã Đức Hòa",
    code: 21424,
    division_type: "xã",
    codename: "xa_duc_hoa",
    district_code: 533,
  },
  {
    name: "Xã Đức Tân",
    code: 21427,
    division_type: "xã",
    codename: "xa_duc_tan",
    district_code: 533,
  },
  {
    name: "Xã Đức Phú",
    code: 21430,
    division_type: "xã",
    codename: "xa_duc_phu",
    district_code: 533,
  },
  {
    name: "Xã Đức Phong",
    code: 21433,
    division_type: "xã",
    codename: "xa_duc_phong",
    district_code: 533,
  },
  {
    name: "Xã Đức Lân",
    code: 21436,
    division_type: "xã",
    codename: "xa_duc_lan",
    district_code: 533,
  },
  {
    name: "Phường Nguyễn Nghiêm",
    code: 21439,
    division_type: "phường",
    codename: "phuong_nguyen_nghiem",
    district_code: 534,
  },
  {
    name: "Xã Phổ An",
    code: 21442,
    division_type: "xã",
    codename: "xa_pho_an",
    district_code: 534,
  },
  {
    name: "Xã Phổ Phong",
    code: 21445,
    division_type: "xã",
    codename: "xa_pho_phong",
    district_code: 534,
  },
  {
    name: "Xã Phổ Thuận",
    code: 21448,
    division_type: "xã",
    codename: "xa_pho_thuan",
    district_code: 534,
  },
  {
    name: "Phường Phổ Văn",
    code: 21451,
    division_type: "phường",
    codename: "phuong_pho_van",
    district_code: 534,
  },
  {
    name: "Phường Phổ Quang",
    code: 21454,
    division_type: "phường",
    codename: "phuong_pho_quang",
    district_code: 534,
  },
  {
    name: "Xã Phổ Nhơn",
    code: 21457,
    division_type: "xã",
    codename: "xa_pho_nhon",
    district_code: 534,
  },
  {
    name: "Phường Phổ Ninh",
    code: 21460,
    division_type: "phường",
    codename: "phuong_pho_ninh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Minh",
    code: 21463,
    division_type: "phường",
    codename: "phuong_pho_minh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Vinh",
    code: 21466,
    division_type: "phường",
    codename: "phuong_pho_vinh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Hòa",
    code: 21469,
    division_type: "phường",
    codename: "phuong_pho_hoa",
    district_code: 534,
  },
  {
    name: "Xã Phổ Cường",
    code: 21472,
    division_type: "xã",
    codename: "xa_pho_cuong",
    district_code: 534,
  },
  {
    name: "Xã Phổ Khánh",
    code: 21475,
    division_type: "xã",
    codename: "xa_pho_khanh",
    district_code: 534,
  },
  {
    name: "Phường Phổ Thạnh",
    code: 21478,
    division_type: "phường",
    codename: "phuong_pho_thanh",
    district_code: 534,
  },
  {
    name: "Xã Phổ Châu",
    code: 21481,
    division_type: "xã",
    codename: "xa_pho_chau",
    district_code: 534,
  },
  {
    name: "Thị trấn Ba Tơ",
    code: 21484,
    division_type: "thị trấn",
    codename: "thi_tran_ba_to",
    district_code: 535,
  },
  {
    name: "Xã Ba Điền",
    code: 21487,
    division_type: "xã",
    codename: "xa_ba_dien",
    district_code: 535,
  },
  {
    name: "Xã Ba Vinh",
    code: 21490,
    division_type: "xã",
    codename: "xa_ba_vinh",
    district_code: 535,
  },
  {
    name: "Xã Ba Thành",
    code: 21493,
    division_type: "xã",
    codename: "xa_ba_thanh",
    district_code: 535,
  },
  {
    name: "Xã Ba Động",
    code: 21496,
    division_type: "xã",
    codename: "xa_ba_dong",
    district_code: 535,
  },
  {
    name: "Xã Ba Dinh",
    code: 21499,
    division_type: "xã",
    codename: "xa_ba_dinh",
    district_code: 535,
  },
  {
    name: "Xã Ba Giang",
    code: 21500,
    division_type: "xã",
    codename: "xa_ba_giang",
    district_code: 535,
  },
  {
    name: "Xã Ba Liên",
    code: 21502,
    division_type: "xã",
    codename: "xa_ba_lien",
    district_code: 535,
  },
  {
    name: "Xã Ba Ngạc",
    code: 21505,
    division_type: "xã",
    codename: "xa_ba_ngac",
    district_code: 535,
  },
  {
    name: "Xã Ba Khâm",
    code: 21508,
    division_type: "xã",
    codename: "xa_ba_kham",
    district_code: 535,
  },
  {
    name: "Xã Ba Cung",
    code: 21511,
    division_type: "xã",
    codename: "xa_ba_cung",
    district_code: 535,
  },
  {
    name: "Xã Ba Tiêu",
    code: 21517,
    division_type: "xã",
    codename: "xa_ba_tieu",
    district_code: 535,
  },
  {
    name: "Xã Ba Trang",
    code: 21520,
    division_type: "xã",
    codename: "xa_ba_trang",
    district_code: 535,
  },
  {
    name: "Xã Ba Tô",
    code: 21523,
    division_type: "xã",
    codename: "xa_ba_to",
    district_code: 535,
  },
  {
    name: "Xã Ba Bích",
    code: 21526,
    division_type: "xã",
    codename: "xa_ba_bich",
    district_code: 535,
  },
  {
    name: "Xã Ba Vì",
    code: 21529,
    division_type: "xã",
    codename: "xa_ba_vi",
    district_code: 535,
  },
  {
    name: "Xã Ba Lế",
    code: 21532,
    division_type: "xã",
    codename: "xa_ba_le",
    district_code: 535,
  },
  {
    name: "Xã Ba Nam",
    code: 21535,
    division_type: "xã",
    codename: "xa_ba_nam",
    district_code: 535,
  },
  {
    name: "Xã Ba Xa",
    code: 21538,
    division_type: "xã",
    codename: "xa_ba_xa",
    district_code: 535,
  },
  {
    name: "Phường Nhơn Bình",
    code: 21550,
    division_type: "phường",
    codename: "phuong_nhon_binh",
    district_code: 540,
  },
  {
    name: "Phường Nhơn Phú",
    code: 21553,
    division_type: "phường",
    codename: "phuong_nhon_phu",
    district_code: 540,
  },
  {
    name: "Phường Đống Đa",
    code: 21556,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 540,
  },
  {
    name: "Phường Trần Quang Diệu",
    code: 21559,
    division_type: "phường",
    codename: "phuong_tran_quang_dieu",
    district_code: 540,
  },
  {
    name: "Phường Hải Cảng",
    code: 21562,
    division_type: "phường",
    codename: "phuong_hai_cang",
    district_code: 540,
  },
  {
    name: "Phường Quang Trung",
    code: 21565,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 540,
  },
  {
    name: "Phường Thị Nại",
    code: 21568,
    division_type: "phường",
    codename: "phuong_thi_nai",
    district_code: 540,
  },
  {
    name: "Phường Lê Hồng Phong",
    code: 21571,
    division_type: "phường",
    codename: "phuong_le_hong_phong",
    district_code: 540,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 21574,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 540,
  },
  {
    name: "Phường Ngô Mây",
    code: 21577,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 540,
  },
  {
    name: "Phường Lý Thường Kiệt",
    code: 21580,
    division_type: "phường",
    codename: "phuong_ly_thuong_kiet",
    district_code: 540,
  },
  {
    name: "Phường Lê Lợi",
    code: 21583,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 540,
  },
  {
    name: "Phường Trần Phú",
    code: 21586,
    division_type: "phường",
    codename: "phuong_tran_phu",
    district_code: 540,
  },
  {
    name: "Phường Bùi Thị Xuân",
    code: 21589,
    division_type: "phường",
    codename: "phuong_bui_thi_xuan",
    district_code: 540,
  },
  {
    name: "Phường Nguyễn Văn Cừ",
    code: 21592,
    division_type: "phường",
    codename: "phuong_nguyen_van_cu",
    district_code: 540,
  },
  {
    name: "Phường Ghềnh Ráng",
    code: 21595,
    division_type: "phường",
    codename: "phuong_ghenh_rang",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Lý",
    code: 21598,
    division_type: "xã",
    codename: "xa_nhon_ly",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Hội",
    code: 21601,
    division_type: "xã",
    codename: "xa_nhon_hoi",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Hải",
    code: 21604,
    division_type: "xã",
    codename: "xa_nhon_hai",
    district_code: 540,
  },
  {
    name: "Xã Nhơn Châu",
    code: 21607,
    division_type: "xã",
    codename: "xa_nhon_chau",
    district_code: 540,
  },
  {
    name: "Xã Phước Mỹ",
    code: 21991,
    division_type: "xã",
    codename: "xa_phuoc_my",
    district_code: 540,
  },
  {
    name: "Thị trấn An Lão",
    code: 21609,
    division_type: "thị trấn",
    codename: "thi_tran_an_lao",
    district_code: 542,
  },
  {
    name: "Xã An Hưng",
    code: 21610,
    division_type: "xã",
    codename: "xa_an_hung",
    district_code: 542,
  },
  {
    name: "Xã An Trung",
    code: 21613,
    division_type: "xã",
    codename: "xa_an_trung",
    district_code: 542,
  },
  {
    name: "Xã An Dũng",
    code: 21616,
    division_type: "xã",
    codename: "xa_an_dung",
    district_code: 542,
  },
  {
    name: "Xã An Vinh",
    code: 21619,
    division_type: "xã",
    codename: "xa_an_vinh",
    district_code: 542,
  },
  {
    name: "Xã An Toàn",
    code: 21622,
    division_type: "xã",
    codename: "xa_an_toan",
    district_code: 542,
  },
  {
    name: "Xã An Tân",
    code: 21625,
    division_type: "xã",
    codename: "xa_an_tan",
    district_code: 542,
  },
  {
    name: "Xã An Hòa",
    code: 21628,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 542,
  },
  {
    name: "Xã An Quang",
    code: 21631,
    division_type: "xã",
    codename: "xa_an_quang",
    district_code: 542,
  },
  {
    name: "Xã An Nghĩa",
    code: 21634,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 542,
  },
  {
    name: "Phường Tam Quan",
    code: 21637,
    division_type: "phường",
    codename: "phuong_tam_quan",
    district_code: 543,
  },
  {
    name: "Phường Bồng Sơn",
    code: 21640,
    division_type: "phường",
    codename: "phuong_bong_son",
    district_code: 543,
  },
  {
    name: "Xã Hoài Sơn",
    code: 21643,
    division_type: "xã",
    codename: "xa_hoai_son",
    district_code: 543,
  },
  {
    name: "Xã Hoài Châu Bắc",
    code: 21646,
    division_type: "xã",
    codename: "xa_hoai_chau_bac",
    district_code: 543,
  },
  {
    name: "Xã Hoài Châu",
    code: 21649,
    division_type: "xã",
    codename: "xa_hoai_chau",
    district_code: 543,
  },
  {
    name: "Xã Hoài Phú",
    code: 21652,
    division_type: "xã",
    codename: "xa_hoai_phu",
    district_code: 543,
  },
  {
    name: "Phường Tam Quan Bắc",
    code: 21655,
    division_type: "phường",
    codename: "phuong_tam_quan_bac",
    district_code: 543,
  },
  {
    name: "Phường Tam Quan Nam",
    code: 21658,
    division_type: "phường",
    codename: "phuong_tam_quan_nam",
    district_code: 543,
  },
  {
    name: "Phường Hoài Hảo",
    code: 21661,
    division_type: "phường",
    codename: "phuong_hoai_hao",
    district_code: 543,
  },
  {
    name: "Phường Hoài Thanh Tây",
    code: 21664,
    division_type: "phường",
    codename: "phuong_hoai_thanh_tay",
    district_code: 543,
  },
  {
    name: "Phường Hoài Thanh",
    code: 21667,
    division_type: "phường",
    codename: "phuong_hoai_thanh",
    district_code: 543,
  },
  {
    name: "Phường Hoài Hương",
    code: 21670,
    division_type: "phường",
    codename: "phuong_hoai_huong",
    district_code: 543,
  },
  {
    name: "Phường Hoài Tân",
    code: 21673,
    division_type: "phường",
    codename: "phuong_hoai_tan",
    district_code: 543,
  },
  {
    name: "Xã Hoài Hải",
    code: 21676,
    division_type: "xã",
    codename: "xa_hoai_hai",
    district_code: 543,
  },
  {
    name: "Phường Hoài Xuân",
    code: 21679,
    division_type: "phường",
    codename: "phuong_hoai_xuan",
    district_code: 543,
  },
  {
    name: "Xã Hoài Mỹ",
    code: 21682,
    division_type: "xã",
    codename: "xa_hoai_my",
    district_code: 543,
  },
  {
    name: "Phường Hoài Đức",
    code: 21685,
    division_type: "phường",
    codename: "phuong_hoai_duc",
    district_code: 543,
  },
  {
    name: "Thị trấn Tăng Bạt Hổ",
    code: 21688,
    division_type: "thị trấn",
    codename: "thi_tran_tang_bat_ho",
    district_code: 544,
  },
  {
    name: "Xã Ân Hảo Tây",
    code: 21690,
    division_type: "xã",
    codename: "xa_an_hao_tay",
    district_code: 544,
  },
  {
    name: "Xã Ân Hảo Đông",
    code: 21691,
    division_type: "xã",
    codename: "xa_an_hao_dong",
    district_code: 544,
  },
  {
    name: "Xã Ân Sơn",
    code: 21694,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 544,
  },
  {
    name: "Xã Ân Mỹ",
    code: 21697,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 544,
  },
  {
    name: "Xã Đak Mang",
    code: 21700,
    division_type: "xã",
    codename: "xa_dak_mang",
    district_code: 544,
  },
  {
    name: "Xã Ân Tín",
    code: 21703,
    division_type: "xã",
    codename: "xa_an_tin",
    district_code: 544,
  },
  {
    name: "Xã Ân Thạnh",
    code: 21706,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 544,
  },
  {
    name: "Xã Ân Phong",
    code: 21709,
    division_type: "xã",
    codename: "xa_an_phong",
    district_code: 544,
  },
  {
    name: "Xã Ân Đức",
    code: 21712,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 544,
  },
  {
    name: "Xã Ân Hữu",
    code: 21715,
    division_type: "xã",
    codename: "xa_an_huu",
    district_code: 544,
  },
  {
    name: "Xã Bok Tới",
    code: 21718,
    division_type: "xã",
    codename: "xa_bok_toi",
    district_code: 544,
  },
  {
    name: "Xã Ân Tường Tây",
    code: 21721,
    division_type: "xã",
    codename: "xa_an_tuong_tay",
    district_code: 544,
  },
  {
    name: "Xã Ân Tường Đông",
    code: 21724,
    division_type: "xã",
    codename: "xa_an_tuong_dong",
    district_code: 544,
  },
  {
    name: "Xã Ân Nghĩa",
    code: 21727,
    division_type: "xã",
    codename: "xa_an_nghia",
    district_code: 544,
  },
  {
    name: "Thị trấn Phù Mỹ",
    code: 21730,
    division_type: "thị trấn",
    codename: "thi_tran_phu_my",
    district_code: 545,
  },
  {
    name: "Thị trấn Bình Dương",
    code: 21733,
    division_type: "thị trấn",
    codename: "thi_tran_binh_duong",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Đức",
    code: 21736,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Châu",
    code: 21739,
    division_type: "xã",
    codename: "xa_my_chau",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thắng",
    code: 21742,
    division_type: "xã",
    codename: "xa_my_thang",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 21745,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Lợi",
    code: 21748,
    division_type: "xã",
    codename: "xa_my_loi",
    district_code: 545,
  },
  {
    name: "Xã Mỹ An",
    code: 21751,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Phong",
    code: 21754,
    division_type: "xã",
    codename: "xa_my_phong",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Trinh",
    code: 21757,
    division_type: "xã",
    codename: "xa_my_trinh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thọ",
    code: 21760,
    division_type: "xã",
    codename: "xa_my_tho",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 21763,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Thành",
    code: 21766,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 21769,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Quang",
    code: 21772,
    division_type: "xã",
    codename: "xa_my_quang",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 21775,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Tài",
    code: 21778,
    division_type: "xã",
    codename: "xa_my_tai",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Cát",
    code: 21781,
    division_type: "xã",
    codename: "xa_my_cat",
    district_code: 545,
  },
  {
    name: "Xã Mỹ Chánh Tây",
    code: 21784,
    division_type: "xã",
    codename: "xa_my_chanh_tay",
    district_code: 545,
  },
  {
    name: "Thị trấn Vĩnh Thạnh",
    code: 21786,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Sơn",
    code: 21787,
    division_type: "xã",
    codename: "xa_vinh_son",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 21790,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 21796,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 21799,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 21801,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 21802,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 21804,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 546,
  },
  {
    name: "Xã Vĩnh Quang",
    code: 21805,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 546,
  },
  {
    name: "Thị trấn Phú Phong",
    code: 21808,
    division_type: "thị trấn",
    codename: "thi_tran_phu_phong",
    district_code: 547,
  },
  {
    name: "Xã Bình Tân",
    code: 21811,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 547,
  },
  {
    name: "Xã Tây Thuận",
    code: 21814,
    division_type: "xã",
    codename: "xa_tay_thuan",
    district_code: 547,
  },
  {
    name: "Xã Bình Thuận",
    code: 21817,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 547,
  },
  {
    name: "Xã Tây Giang",
    code: 21820,
    division_type: "xã",
    codename: "xa_tay_giang",
    district_code: 547,
  },
  {
    name: "Xã Bình Thành",
    code: 21823,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 547,
  },
  {
    name: "Xã Tây An",
    code: 21826,
    division_type: "xã",
    codename: "xa_tay_an",
    district_code: 547,
  },
  {
    name: "Xã Bình Hòa",
    code: 21829,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 547,
  },
  {
    name: "Xã Tây Bình",
    code: 21832,
    division_type: "xã",
    codename: "xa_tay_binh",
    district_code: 547,
  },
  {
    name: "Xã Bình Tường",
    code: 21835,
    division_type: "xã",
    codename: "xa_binh_tuong",
    district_code: 547,
  },
  {
    name: "Xã Tây Vinh",
    code: 21838,
    division_type: "xã",
    codename: "xa_tay_vinh",
    district_code: 547,
  },
  {
    name: "Xã Vĩnh An",
    code: 21841,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 547,
  },
  {
    name: "Xã Tây Xuân",
    code: 21844,
    division_type: "xã",
    codename: "xa_tay_xuan",
    district_code: 547,
  },
  {
    name: "Xã Bình Nghi",
    code: 21847,
    division_type: "xã",
    codename: "xa_binh_nghi",
    district_code: 547,
  },
  {
    name: "Xã Tây Phú",
    code: 21850,
    division_type: "xã",
    codename: "xa_tay_phu",
    district_code: 547,
  },
  {
    name: "Thị trấn Ngô Mây",
    code: 21853,
    division_type: "thị trấn",
    codename: "thi_tran_ngo_may",
    district_code: 548,
  },
  {
    name: "Xã Cát Sơn",
    code: 21856,
    division_type: "xã",
    codename: "xa_cat_son",
    district_code: 548,
  },
  {
    name: "Xã Cát Minh",
    code: 21859,
    division_type: "xã",
    codename: "xa_cat_minh",
    district_code: 548,
  },
  {
    name: "Xã Cát Khánh",
    code: 21862,
    division_type: "xã",
    codename: "xa_cat_khanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Tài",
    code: 21865,
    division_type: "xã",
    codename: "xa_cat_tai",
    district_code: 548,
  },
  {
    name: "Xã Cát Lâm",
    code: 21868,
    division_type: "xã",
    codename: "xa_cat_lam",
    district_code: 548,
  },
  {
    name: "Xã Cát Hanh",
    code: 21871,
    division_type: "xã",
    codename: "xa_cat_hanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Thành",
    code: 21874,
    division_type: "xã",
    codename: "xa_cat_thanh",
    district_code: 548,
  },
  {
    name: "Xã Cát Trinh",
    code: 21877,
    division_type: "xã",
    codename: "xa_cat_trinh",
    district_code: 548,
  },
  {
    name: "Xã Cát Hải",
    code: 21880,
    division_type: "xã",
    codename: "xa_cat_hai",
    district_code: 548,
  },
  {
    name: "Xã Cát Hiệp",
    code: 21883,
    division_type: "xã",
    codename: "xa_cat_hiep",
    district_code: 548,
  },
  {
    name: "Xã Cát Nhơn",
    code: 21886,
    division_type: "xã",
    codename: "xa_cat_nhon",
    district_code: 548,
  },
  {
    name: "Xã Cát Hưng",
    code: 21889,
    division_type: "xã",
    codename: "xa_cat_hung",
    district_code: 548,
  },
  {
    name: "Xã Cát Tường",
    code: 21892,
    division_type: "xã",
    codename: "xa_cat_tuong",
    district_code: 548,
  },
  {
    name: "Xã Cát Tân",
    code: 21895,
    division_type: "xã",
    codename: "xa_cat_tan",
    district_code: 548,
  },
  {
    name: "Thị trấn Cát Tiến",
    code: 21898,
    division_type: "thị trấn",
    codename: "thi_tran_cat_tien",
    district_code: 548,
  },
  {
    name: "Xã Cát Thắng",
    code: 21901,
    division_type: "xã",
    codename: "xa_cat_thang",
    district_code: 548,
  },
  {
    name: "Xã Cát Chánh",
    code: 21904,
    division_type: "xã",
    codename: "xa_cat_chanh",
    district_code: 548,
  },
  {
    name: "Phường Bình Định",
    code: 21907,
    division_type: "phường",
    codename: "phuong_binh_dinh",
    district_code: 549,
  },
  {
    name: "Phường Đập Đá",
    code: 21910,
    division_type: "phường",
    codename: "phuong_dap_da",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 21913,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Thành",
    code: 21916,
    division_type: "phường",
    codename: "phuong_nhon_thanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Hạnh",
    code: 21919,
    division_type: "xã",
    codename: "xa_nhon_hanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Hậu",
    code: 21922,
    division_type: "xã",
    codename: "xa_nhon_hau",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Phong",
    code: 21925,
    division_type: "xã",
    codename: "xa_nhon_phong",
    district_code: 549,
  },
  {
    name: "Xã Nhơn An",
    code: 21928,
    division_type: "xã",
    codename: "xa_nhon_an",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Phúc",
    code: 21931,
    division_type: "xã",
    codename: "xa_nhon_phuc",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Hưng",
    code: 21934,
    division_type: "phường",
    codename: "phuong_nhon_hung",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Khánh",
    code: 21937,
    division_type: "xã",
    codename: "xa_nhon_khanh",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Lộc",
    code: 21940,
    division_type: "xã",
    codename: "xa_nhon_loc",
    district_code: 549,
  },
  {
    name: "Phường Nhơn Hoà",
    code: 21943,
    division_type: "phường",
    codename: "phuong_nhon_hoa",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Tân",
    code: 21946,
    division_type: "xã",
    codename: "xa_nhon_tan",
    district_code: 549,
  },
  {
    name: "Xã Nhơn Thọ",
    code: 21949,
    division_type: "xã",
    codename: "xa_nhon_tho",
    district_code: 549,
  },
  {
    name: "Thị trấn Tuy Phước",
    code: 21952,
    division_type: "thị trấn",
    codename: "thi_tran_tuy_phuoc",
    district_code: 550,
  },
  {
    name: "Thị trấn Diêu Trì",
    code: 21955,
    division_type: "thị trấn",
    codename: "thi_tran_dieu_tri",
    district_code: 550,
  },
  {
    name: "Xã Phước Thắng",
    code: 21958,
    division_type: "xã",
    codename: "xa_phuoc_thang",
    district_code: 550,
  },
  {
    name: "Xã Phước Hưng",
    code: 21961,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 550,
  },
  {
    name: "Xã Phước Quang",
    code: 21964,
    division_type: "xã",
    codename: "xa_phuoc_quang",
    district_code: 550,
  },
  {
    name: "Xã Phước Hòa",
    code: 21967,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 550,
  },
  {
    name: "Xã Phước Sơn",
    code: 21970,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 550,
  },
  {
    name: "Xã Phước Hiệp",
    code: 21973,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 550,
  },
  {
    name: "Xã Phước Lộc",
    code: 21976,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 550,
  },
  {
    name: "Xã Phước Nghĩa",
    code: 21979,
    division_type: "xã",
    codename: "xa_phuoc_nghia",
    district_code: 550,
  },
  {
    name: "Xã Phước Thuận",
    code: 21982,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 550,
  },
  {
    name: "Xã Phước An",
    code: 21985,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 550,
  },
  {
    name: "Xã Phước Thành",
    code: 21988,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 550,
  },
  {
    name: "Thị trấn Vân Canh",
    code: 21994,
    division_type: "thị trấn",
    codename: "thi_tran_van_canh",
    district_code: 551,
  },
  {
    name: "Xã Canh Liên",
    code: 21997,
    division_type: "xã",
    codename: "xa_canh_lien",
    district_code: 551,
  },
  {
    name: "Xã Canh Hiệp",
    code: 22000,
    division_type: "xã",
    codename: "xa_canh_hiep",
    district_code: 551,
  },
  {
    name: "Xã Canh Vinh",
    code: 22003,
    division_type: "xã",
    codename: "xa_canh_vinh",
    district_code: 551,
  },
  {
    name: "Xã Canh Hiển",
    code: 22006,
    division_type: "xã",
    codename: "xa_canh_hien",
    district_code: 551,
  },
  {
    name: "Xã Canh Thuận",
    code: 22009,
    division_type: "xã",
    codename: "xa_canh_thuan",
    district_code: 551,
  },
  {
    name: "Xã Canh Hòa",
    code: 22012,
    division_type: "xã",
    codename: "xa_canh_hoa",
    district_code: 551,
  },
  {
    name: "Phường 1",
    code: 22015,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 555,
  },
  {
    name: "Phường 8",
    code: 22018,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 555,
  },
  {
    name: "Phường 2",
    code: 22021,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 555,
  },
  {
    name: "Phường 9",
    code: 22024,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 555,
  },
  {
    name: "Phường 3",
    code: 22027,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 555,
  },
  {
    name: "Phường 4",
    code: 22030,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 555,
  },
  {
    name: "Phường 5",
    code: 22033,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 555,
  },
  {
    name: "Phường 7",
    code: 22036,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 555,
  },
  {
    name: "Phường 6",
    code: 22039,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 555,
  },
  {
    name: "Phường Phú Thạnh",
    code: 22040,
    division_type: "phường",
    codename: "phuong_phu_thanh",
    district_code: 555,
  },
  {
    name: "Phường Phú Đông",
    code: 22041,
    division_type: "phường",
    codename: "phuong_phu_dong",
    district_code: 555,
  },
  {
    name: "Xã Hòa Kiến",
    code: 22042,
    division_type: "xã",
    codename: "xa_hoa_kien",
    district_code: 555,
  },
  {
    name: "Xã Bình Kiến",
    code: 22045,
    division_type: "xã",
    codename: "xa_binh_kien",
    district_code: 555,
  },
  {
    name: "Xã Bình Ngọc",
    code: 22048,
    division_type: "xã",
    codename: "xa_binh_ngoc",
    district_code: 555,
  },
  {
    name: "Xã An Phú",
    code: 22162,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 555,
  },
  {
    name: "Phường Phú Lâm",
    code: 22240,
    division_type: "phường",
    codename: "phuong_phu_lam",
    district_code: 555,
  },
  {
    name: "Phường Xuân Phú",
    code: 22051,
    division_type: "phường",
    codename: "phuong_xuan_phu",
    district_code: 557,
  },
  {
    name: "Xã Xuân Lâm",
    code: 22052,
    division_type: "xã",
    codename: "xa_xuan_lam",
    district_code: 557,
  },
  {
    name: "Phường Xuân Thành",
    code: 22053,
    division_type: "phường",
    codename: "phuong_xuan_thanh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Hải",
    code: 22054,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 557,
  },
  {
    name: "Xã Xuân Lộc",
    code: 22057,
    division_type: "xã",
    codename: "xa_xuan_loc",
    district_code: 557,
  },
  {
    name: "Xã Xuân Bình",
    code: 22060,
    division_type: "xã",
    codename: "xa_xuan_binh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Cảnh",
    code: 22066,
    division_type: "xã",
    codename: "xa_xuan_canh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thịnh",
    code: 22069,
    division_type: "xã",
    codename: "xa_xuan_thinh",
    district_code: 557,
  },
  {
    name: "Xã Xuân Phương",
    code: 22072,
    division_type: "xã",
    codename: "xa_xuan_phuong",
    district_code: 557,
  },
  {
    name: "Phường Xuân Yên",
    code: 22073,
    division_type: "phường",
    codename: "phuong_xuan_yen",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thọ 1",
    code: 22075,
    division_type: "xã",
    codename: "xa_xuan_tho_1",
    district_code: 557,
  },
  {
    name: "Phường Xuân Đài",
    code: 22076,
    division_type: "phường",
    codename: "phuong_xuan_dai",
    district_code: 557,
  },
  {
    name: "Xã Xuân Thọ 2",
    code: 22078,
    division_type: "xã",
    codename: "xa_xuan_tho_2",
    district_code: 557,
  },
  {
    name: "Thị trấn La Hai",
    code: 22081,
    division_type: "thị trấn",
    codename: "thi_tran_la_hai",
    district_code: 558,
  },
  {
    name: "Xã Đa Lộc",
    code: 22084,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 558,
  },
  {
    name: "Xã Phú Mỡ",
    code: 22087,
    division_type: "xã",
    codename: "xa_phu_mo",
    district_code: 558,
  },
  {
    name: "Xã Xuân Lãnh",
    code: 22090,
    division_type: "xã",
    codename: "xa_xuan_lanh",
    district_code: 558,
  },
  {
    name: "Xã Xuân Long",
    code: 22093,
    division_type: "xã",
    codename: "xa_xuan_long",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 1",
    code: 22096,
    division_type: "xã",
    codename: "xa_xuan_quang_1",
    district_code: 558,
  },
  {
    name: "Xã Xuân Sơn Bắc",
    code: 22099,
    division_type: "xã",
    codename: "xa_xuan_son_bac",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 2",
    code: 22102,
    division_type: "xã",
    codename: "xa_xuan_quang_2",
    district_code: 558,
  },
  {
    name: "Xã Xuân Sơn Nam",
    code: 22105,
    division_type: "xã",
    codename: "xa_xuan_son_nam",
    district_code: 558,
  },
  {
    name: "Xã Xuân Quang 3",
    code: 22108,
    division_type: "xã",
    codename: "xa_xuan_quang_3",
    district_code: 558,
  },
  {
    name: "Xã Xuân Phước",
    code: 22111,
    division_type: "xã",
    codename: "xa_xuan_phuoc",
    district_code: 558,
  },
  {
    name: "Thị trấn Chí Thạnh",
    code: 22114,
    division_type: "thị trấn",
    codename: "thi_tran_chi_thanh",
    district_code: 559,
  },
  {
    name: "Xã An Dân",
    code: 22117,
    division_type: "xã",
    codename: "xa_an_dan",
    district_code: 559,
  },
  {
    name: "Xã An Ninh Tây",
    code: 22120,
    division_type: "xã",
    codename: "xa_an_ninh_tay",
    district_code: 559,
  },
  {
    name: "Xã An Ninh Đông",
    code: 22123,
    division_type: "xã",
    codename: "xa_an_ninh_dong",
    district_code: 559,
  },
  {
    name: "Xã An Thạch",
    code: 22126,
    division_type: "xã",
    codename: "xa_an_thach",
    district_code: 559,
  },
  {
    name: "Xã An Định",
    code: 22129,
    division_type: "xã",
    codename: "xa_an_dinh",
    district_code: 559,
  },
  {
    name: "Xã An Nghiệp",
    code: 22132,
    division_type: "xã",
    codename: "xa_an_nghiep",
    district_code: 559,
  },
  {
    name: "Xã An Cư",
    code: 22138,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 559,
  },
  {
    name: "Xã An Xuân",
    code: 22141,
    division_type: "xã",
    codename: "xa_an_xuan",
    district_code: 559,
  },
  {
    name: "Xã An Lĩnh",
    code: 22144,
    division_type: "xã",
    codename: "xa_an_linh",
    district_code: 559,
  },
  {
    name: "Xã An Hòa Hải",
    code: 22147,
    division_type: "xã",
    codename: "xa_an_hoa_hai",
    district_code: 559,
  },
  {
    name: "Xã An Hiệp",
    code: 22150,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 559,
  },
  {
    name: "Xã An Mỹ",
    code: 22153,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 559,
  },
  {
    name: "Xã An Chấn",
    code: 22156,
    division_type: "xã",
    codename: "xa_an_chan",
    district_code: 559,
  },
  {
    name: "Xã An Thọ",
    code: 22159,
    division_type: "xã",
    codename: "xa_an_tho",
    district_code: 559,
  },
  {
    name: "Thị trấn Củng Sơn",
    code: 22165,
    division_type: "thị trấn",
    codename: "thi_tran_cung_son",
    district_code: 560,
  },
  {
    name: "Xã Phước Tân",
    code: 22168,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 560,
  },
  {
    name: "Xã Sơn Hội",
    code: 22171,
    division_type: "xã",
    codename: "xa_son_hoi",
    district_code: 560,
  },
  {
    name: "Xã Sơn Định",
    code: 22174,
    division_type: "xã",
    codename: "xa_son_dinh",
    district_code: 560,
  },
  {
    name: "Xã Sơn Long",
    code: 22177,
    division_type: "xã",
    codename: "xa_son_long",
    district_code: 560,
  },
  {
    name: "Xã Cà Lúi",
    code: 22180,
    division_type: "xã",
    codename: "xa_ca_lui",
    district_code: 560,
  },
  {
    name: "Xã Sơn Phước",
    code: 22183,
    division_type: "xã",
    codename: "xa_son_phuoc",
    district_code: 560,
  },
  {
    name: "Xã Sơn Xuân",
    code: 22186,
    division_type: "xã",
    codename: "xa_son_xuan",
    district_code: 560,
  },
  {
    name: "Xã Sơn Nguyên",
    code: 22189,
    division_type: "xã",
    codename: "xa_son_nguyen",
    district_code: 560,
  },
  {
    name: "Xã Eachà Rang",
    code: 22192,
    division_type: "xã",
    codename: "xa_eacha_rang",
    district_code: 560,
  },
  {
    name: "Xã Krông Pa",
    code: 22195,
    division_type: "xã",
    codename: "xa_krong_pa",
    district_code: 560,
  },
  {
    name: "Xã Suối Bạc",
    code: 22198,
    division_type: "xã",
    codename: "xa_suoi_bac",
    district_code: 560,
  },
  {
    name: "Xã Sơn Hà",
    code: 22201,
    division_type: "xã",
    codename: "xa_son_ha",
    district_code: 560,
  },
  {
    name: "Xã Suối Trai",
    code: 22204,
    division_type: "xã",
    codename: "xa_suoi_trai",
    district_code: 560,
  },
  {
    name: "Thị trấn Hai Riêng",
    code: 22207,
    division_type: "thị trấn",
    codename: "thi_tran_hai_rieng",
    district_code: 561,
  },
  {
    name: "Xã Ea Lâm",
    code: 22210,
    division_type: "xã",
    codename: "xa_ea_lam",
    district_code: 561,
  },
  {
    name: "Xã Đức Bình Tây",
    code: 22213,
    division_type: "xã",
    codename: "xa_duc_binh_tay",
    district_code: 561,
  },
  {
    name: "Xã Ea Bá",
    code: 22216,
    division_type: "xã",
    codename: "xa_ea_ba",
    district_code: 561,
  },
  {
    name: "Xã Sơn Giang",
    code: 22219,
    division_type: "xã",
    codename: "xa_son_giang",
    district_code: 561,
  },
  {
    name: "Xã Đức Bình Đông",
    code: 22222,
    division_type: "xã",
    codename: "xa_duc_binh_dong",
    district_code: 561,
  },
  {
    name: "Xã EaBar",
    code: 22225,
    division_type: "xã",
    codename: "xa_eabar",
    district_code: 561,
  },
  {
    name: "Xã EaBia",
    code: 22228,
    division_type: "xã",
    codename: "xa_eabia",
    district_code: 561,
  },
  {
    name: "Xã EaTrol",
    code: 22231,
    division_type: "xã",
    codename: "xa_eatrol",
    district_code: 561,
  },
  {
    name: "Xã Sông Hinh",
    code: 22234,
    division_type: "xã",
    codename: "xa_song_hinh",
    district_code: 561,
  },
  {
    name: "Xã Ealy",
    code: 22237,
    division_type: "xã",
    codename: "xa_ealy",
    district_code: 561,
  },
  {
    name: "Xã Sơn Thành Tây",
    code: 22249,
    division_type: "xã",
    codename: "xa_son_thanh_tay",
    district_code: 562,
  },
  {
    name: "Xã Sơn Thành Đông",
    code: 22250,
    division_type: "xã",
    codename: "xa_son_thanh_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Bình 1",
    code: 22252,
    division_type: "xã",
    codename: "xa_hoa_binh_1",
    district_code: 562,
  },
  {
    name: "Thị trấn Phú Thứ",
    code: 22255,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thu",
    district_code: 562,
  },
  {
    name: "Xã Hòa Phong",
    code: 22264,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Phú",
    code: 22270,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 562,
  },
  {
    name: "Xã Hòa Tân Tây",
    code: 22273,
    division_type: "xã",
    codename: "xa_hoa_tan_tay",
    district_code: 562,
  },
  {
    name: "Xã Hòa Đồng",
    code: 22276,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Mỹ Đông",
    code: 22285,
    division_type: "xã",
    codename: "xa_hoa_my_dong",
    district_code: 562,
  },
  {
    name: "Xã Hòa Mỹ Tây",
    code: 22288,
    division_type: "xã",
    codename: "xa_hoa_my_tay",
    district_code: 562,
  },
  {
    name: "Xã Hòa Thịnh",
    code: 22294,
    division_type: "xã",
    codename: "xa_hoa_thinh",
    district_code: 562,
  },
  {
    name: "Xã Hòa Quang Bắc",
    code: 22303,
    division_type: "xã",
    codename: "xa_hoa_quang_bac",
    district_code: 563,
  },
  {
    name: "Xã Hòa Quang Nam",
    code: 22306,
    division_type: "xã",
    codename: "xa_hoa_quang_nam",
    district_code: 563,
  },
  {
    name: "Xã Hòa Hội",
    code: 22309,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 563,
  },
  {
    name: "Xã Hòa Trị",
    code: 22312,
    division_type: "xã",
    codename: "xa_hoa_tri",
    district_code: 563,
  },
  {
    name: "Xã Hòa An",
    code: 22315,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 563,
  },
  {
    name: "Xã Hòa Định Đông",
    code: 22318,
    division_type: "xã",
    codename: "xa_hoa_dinh_dong",
    district_code: 563,
  },
  {
    name: "Thị trấn Phú Hoà",
    code: 22319,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 563,
  },
  {
    name: "Xã Hòa Định Tây",
    code: 22321,
    division_type: "xã",
    codename: "xa_hoa_dinh_tay",
    district_code: 563,
  },
  {
    name: "Xã Hòa Thắng",
    code: 22324,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 563,
  },
  {
    name: "Xã Hòa Thành",
    code: 22243,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 564,
  },
  {
    name: "Phường Hòa Hiệp Bắc",
    code: 22246,
    division_type: "phường",
    codename: "phuong_hoa_hiep_bac",
    district_code: 564,
  },
  {
    name: "Phường Hoà Vinh",
    code: 22258,
    division_type: "phường",
    codename: "phuong_hoa_vinh",
    district_code: 564,
  },
  {
    name: "Phường Hoà Hiệp Trung",
    code: 22261,
    division_type: "phường",
    codename: "phuong_hoa_hiep_trung",
    district_code: 564,
  },
  {
    name: "Xã Hòa Tân Đông",
    code: 22267,
    division_type: "xã",
    codename: "xa_hoa_tan_dong",
    district_code: 564,
  },
  {
    name: "Phường Hòa Xuân Tây",
    code: 22279,
    division_type: "phường",
    codename: "phuong_hoa_xuan_tay",
    district_code: 564,
  },
  {
    name: "Phường Hòa Hiệp Nam",
    code: 22282,
    division_type: "phường",
    codename: "phuong_hoa_hiep_nam",
    district_code: 564,
  },
  {
    name: "Xã Hòa Xuân Đông",
    code: 22291,
    division_type: "xã",
    codename: "xa_hoa_xuan_dong",
    district_code: 564,
  },
  {
    name: "Xã Hòa Tâm",
    code: 22297,
    division_type: "xã",
    codename: "xa_hoa_tam",
    district_code: 564,
  },
  {
    name: "Xã Hòa Xuân Nam",
    code: 22300,
    division_type: "xã",
    codename: "xa_hoa_xuan_nam",
    district_code: 564,
  },
  {
    name: "Phường Vĩnh Hòa",
    code: 22327,
    division_type: "phường",
    codename: "phuong_vinh_hoa",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Hải",
    code: 22330,
    division_type: "phường",
    codename: "phuong_vinh_hai",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Phước",
    code: 22333,
    division_type: "phường",
    codename: "phuong_vinh_phuoc",
    district_code: 568,
  },
  {
    name: "Phường Ngọc Hiệp",
    code: 22336,
    division_type: "phường",
    codename: "phuong_ngoc_hiep",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Thọ",
    code: 22339,
    division_type: "phường",
    codename: "phuong_vinh_tho",
    district_code: 568,
  },
  {
    name: "Phường Xương Huân",
    code: 22342,
    division_type: "phường",
    codename: "phuong_xuong_huan",
    district_code: 568,
  },
  {
    name: "Phường Vạn Thắng",
    code: 22345,
    division_type: "phường",
    codename: "phuong_van_thang",
    district_code: 568,
  },
  {
    name: "Phường Vạn Thạnh",
    code: 22348,
    division_type: "phường",
    codename: "phuong_van_thanh",
    district_code: 568,
  },
  {
    name: "Phường Phương Sài",
    code: 22351,
    division_type: "phường",
    codename: "phuong_phuong_sai",
    district_code: 568,
  },
  {
    name: "Phường Phương Sơn",
    code: 22354,
    division_type: "phường",
    codename: "phuong_phuong_son",
    district_code: 568,
  },
  {
    name: "Phường Phước Hải",
    code: 22357,
    division_type: "phường",
    codename: "phuong_phuoc_hai",
    district_code: 568,
  },
  {
    name: "Phường Phước Tân",
    code: 22360,
    division_type: "phường",
    codename: "phuong_phuoc_tan",
    district_code: 568,
  },
  {
    name: "Phường Lộc Thọ",
    code: 22363,
    division_type: "phường",
    codename: "phuong_loc_tho",
    district_code: 568,
  },
  {
    name: "Phường Phước Tiến",
    code: 22366,
    division_type: "phường",
    codename: "phuong_phuoc_tien",
    district_code: 568,
  },
  {
    name: "Phường Tân Lập",
    code: 22369,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 568,
  },
  {
    name: "Phường Phước Hòa",
    code: 22372,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Nguyên",
    code: 22375,
    division_type: "phường",
    codename: "phuong_vinh_nguyen",
    district_code: 568,
  },
  {
    name: "Phường Phước Long",
    code: 22378,
    division_type: "phường",
    codename: "phuong_phuoc_long",
    district_code: 568,
  },
  {
    name: "Phường Vĩnh Trường",
    code: 22381,
    division_type: "phường",
    codename: "phuong_vinh_truong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Lương",
    code: 22384,
    division_type: "xã",
    codename: "xa_vinh_luong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Phương",
    code: 22387,
    division_type: "xã",
    codename: "xa_vinh_phuong",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Ngọc",
    code: 22390,
    division_type: "xã",
    codename: "xa_vinh_ngoc",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 22393,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 22396,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 22399,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 568,
  },
  {
    name: "Xã Vĩnh Thái",
    code: 22402,
    division_type: "xã",
    codename: "xa_vinh_thai",
    district_code: 568,
  },
  {
    name: "Xã Phước Đồng",
    code: 22405,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 568,
  },
  {
    name: "Phường Cam Nghĩa",
    code: 22408,
    division_type: "phường",
    codename: "phuong_cam_nghia",
    district_code: 569,
  },
  {
    name: "Phường Cam Phúc Bắc",
    code: 22411,
    division_type: "phường",
    codename: "phuong_cam_phuc_bac",
    district_code: 569,
  },
  {
    name: "Phường Cam Phúc Nam",
    code: 22414,
    division_type: "phường",
    codename: "phuong_cam_phuc_nam",
    district_code: 569,
  },
  {
    name: "Phường Cam Lộc",
    code: 22417,
    division_type: "phường",
    codename: "phuong_cam_loc",
    district_code: 569,
  },
  {
    name: "Phường Cam Phú",
    code: 22420,
    division_type: "phường",
    codename: "phuong_cam_phu",
    district_code: 569,
  },
  {
    name: "Phường Ba Ngòi",
    code: 22423,
    division_type: "phường",
    codename: "phuong_ba_ngoi",
    district_code: 569,
  },
  {
    name: "Phường Cam Thuận",
    code: 22426,
    division_type: "phường",
    codename: "phuong_cam_thuan",
    district_code: 569,
  },
  {
    name: "Phường Cam Lợi",
    code: 22429,
    division_type: "phường",
    codename: "phuong_cam_loi",
    district_code: 569,
  },
  {
    name: "Phường Cam Linh",
    code: 22432,
    division_type: "phường",
    codename: "phuong_cam_linh",
    district_code: 569,
  },
  {
    name: "Xã Cam Thành Nam",
    code: 22468,
    division_type: "xã",
    codename: "xa_cam_thanh_nam",
    district_code: 569,
  },
  {
    name: "Xã Cam Phước Đông",
    code: 22474,
    division_type: "xã",
    codename: "xa_cam_phuoc_dong",
    district_code: 569,
  },
  {
    name: "Xã Cam Thịnh Tây",
    code: 22477,
    division_type: "xã",
    codename: "xa_cam_thinh_tay",
    district_code: 569,
  },
  {
    name: "Xã Cam Thịnh Đông",
    code: 22480,
    division_type: "xã",
    codename: "xa_cam_thinh_dong",
    district_code: 569,
  },
  {
    name: "Xã Cam Lập",
    code: 22483,
    division_type: "xã",
    codename: "xa_cam_lap",
    district_code: 569,
  },
  {
    name: "Xã Cam Bình",
    code: 22486,
    division_type: "xã",
    codename: "xa_cam_binh",
    district_code: 569,
  },
  {
    name: "Xã Cam Tân",
    code: 22435,
    division_type: "xã",
    codename: "xa_cam_tan",
    district_code: 570,
  },
  {
    name: "Xã Cam Hòa",
    code: 22438,
    division_type: "xã",
    codename: "xa_cam_hoa",
    district_code: 570,
  },
  {
    name: "Xã Cam Hải Đông",
    code: 22441,
    division_type: "xã",
    codename: "xa_cam_hai_dong",
    district_code: 570,
  },
  {
    name: "Xã Cam Hải Tây",
    code: 22444,
    division_type: "xã",
    codename: "xa_cam_hai_tay",
    district_code: 570,
  },
  {
    name: "Xã Sơn Tân",
    code: 22447,
    division_type: "xã",
    codename: "xa_son_tan",
    district_code: 570,
  },
  {
    name: "Xã Cam Hiệp Bắc",
    code: 22450,
    division_type: "xã",
    codename: "xa_cam_hiep_bac",
    district_code: 570,
  },
  {
    name: "Thị trấn Cam Đức",
    code: 22453,
    division_type: "thị trấn",
    codename: "thi_tran_cam_duc",
    district_code: 570,
  },
  {
    name: "Xã Cam Hiệp Nam",
    code: 22456,
    division_type: "xã",
    codename: "xa_cam_hiep_nam",
    district_code: 570,
  },
  {
    name: "Xã Cam Phước Tây",
    code: 22459,
    division_type: "xã",
    codename: "xa_cam_phuoc_tay",
    district_code: 570,
  },
  {
    name: "Xã Cam Thành Bắc",
    code: 22462,
    division_type: "xã",
    codename: "xa_cam_thanh_bac",
    district_code: 570,
  },
  {
    name: "Xã Cam An Bắc",
    code: 22465,
    division_type: "xã",
    codename: "xa_cam_an_bac",
    district_code: 570,
  },
  {
    name: "Xã Cam An Nam",
    code: 22471,
    division_type: "xã",
    codename: "xa_cam_an_nam",
    district_code: 570,
  },
  {
    name: "Xã Suối Cát",
    code: 22708,
    division_type: "xã",
    codename: "xa_suoi_cat",
    district_code: 570,
  },
  {
    name: "Xã Suối Tân",
    code: 22711,
    division_type: "xã",
    codename: "xa_suoi_tan",
    district_code: 570,
  },
  {
    name: "Thị trấn Vạn Giã",
    code: 22489,
    division_type: "thị trấn",
    codename: "thi_tran_van_gia",
    district_code: 571,
  },
  {
    name: "Xã Đại Lãnh",
    code: 22492,
    division_type: "xã",
    codename: "xa_dai_lanh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Phước",
    code: 22495,
    division_type: "xã",
    codename: "xa_van_phuoc",
    district_code: 571,
  },
  {
    name: "Xã Vạn Long",
    code: 22498,
    division_type: "xã",
    codename: "xa_van_long",
    district_code: 571,
  },
  {
    name: "Xã Vạn Bình",
    code: 22501,
    division_type: "xã",
    codename: "xa_van_binh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thọ",
    code: 22504,
    division_type: "xã",
    codename: "xa_van_tho",
    district_code: 571,
  },
  {
    name: "Xã Vạn Khánh",
    code: 22507,
    division_type: "xã",
    codename: "xa_van_khanh",
    district_code: 571,
  },
  {
    name: "Xã Vạn Phú",
    code: 22510,
    division_type: "xã",
    codename: "xa_van_phu",
    district_code: 571,
  },
  {
    name: "Xã Vạn Lương",
    code: 22513,
    division_type: "xã",
    codename: "xa_van_luong",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thắng",
    code: 22516,
    division_type: "xã",
    codename: "xa_van_thang",
    district_code: 571,
  },
  {
    name: "Xã Vạn Thạnh",
    code: 22519,
    division_type: "xã",
    codename: "xa_van_thanh",
    district_code: 571,
  },
  {
    name: "Xã Xuân Sơn",
    code: 22522,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 571,
  },
  {
    name: "Xã Vạn Hưng",
    code: 22525,
    division_type: "xã",
    codename: "xa_van_hung",
    district_code: 571,
  },
  {
    name: "Phường Ninh Hiệp",
    code: 22528,
    division_type: "phường",
    codename: "phuong_ninh_hiep",
    district_code: 572,
  },
  {
    name: "Xã Ninh Sơn",
    code: 22531,
    division_type: "xã",
    codename: "xa_ninh_son",
    district_code: 572,
  },
  {
    name: "Xã Ninh Tây",
    code: 22534,
    division_type: "xã",
    codename: "xa_ninh_tay",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thượng",
    code: 22537,
    division_type: "xã",
    codename: "xa_ninh_thuong",
    district_code: 572,
  },
  {
    name: "Xã Ninh An",
    code: 22540,
    division_type: "xã",
    codename: "xa_ninh_an",
    district_code: 572,
  },
  {
    name: "Phường Ninh Hải",
    code: 22543,
    division_type: "phường",
    codename: "phuong_ninh_hai",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thọ",
    code: 22546,
    division_type: "xã",
    codename: "xa_ninh_tho",
    district_code: 572,
  },
  {
    name: "Xã Ninh Trung",
    code: 22549,
    division_type: "xã",
    codename: "xa_ninh_trung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Sim",
    code: 22552,
    division_type: "xã",
    codename: "xa_ninh_sim",
    district_code: 572,
  },
  {
    name: "Xã Ninh Xuân",
    code: 22555,
    division_type: "xã",
    codename: "xa_ninh_xuan",
    district_code: 572,
  },
  {
    name: "Xã Ninh Thân",
    code: 22558,
    division_type: "xã",
    codename: "xa_ninh_than",
    district_code: 572,
  },
  {
    name: "Phường Ninh Diêm",
    code: 22561,
    division_type: "phường",
    codename: "phuong_ninh_diem",
    district_code: 572,
  },
  {
    name: "Xã Ninh Đông",
    code: 22564,
    division_type: "xã",
    codename: "xa_ninh_dong",
    district_code: 572,
  },
  {
    name: "Phường Ninh Thủy",
    code: 22567,
    division_type: "phường",
    codename: "phuong_ninh_thuy",
    district_code: 572,
  },
  {
    name: "Phường Ninh Đa",
    code: 22570,
    division_type: "phường",
    codename: "phuong_ninh_da",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phụng",
    code: 22573,
    division_type: "xã",
    codename: "xa_ninh_phung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Bình",
    code: 22576,
    division_type: "xã",
    codename: "xa_ninh_binh",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phước",
    code: 22579,
    division_type: "xã",
    codename: "xa_ninh_phuoc",
    district_code: 572,
  },
  {
    name: "Xã Ninh Phú",
    code: 22582,
    division_type: "xã",
    codename: "xa_ninh_phu",
    district_code: 572,
  },
  {
    name: "Xã Ninh Tân",
    code: 22585,
    division_type: "xã",
    codename: "xa_ninh_tan",
    district_code: 572,
  },
  {
    name: "Xã Ninh Quang",
    code: 22588,
    division_type: "xã",
    codename: "xa_ninh_quang",
    district_code: 572,
  },
  {
    name: "Phường Ninh Giang",
    code: 22591,
    division_type: "phường",
    codename: "phuong_ninh_giang",
    district_code: 572,
  },
  {
    name: "Phường Ninh Hà",
    code: 22594,
    division_type: "phường",
    codename: "phuong_ninh_ha",
    district_code: 572,
  },
  {
    name: "Xã Ninh Hưng",
    code: 22597,
    division_type: "xã",
    codename: "xa_ninh_hung",
    district_code: 572,
  },
  {
    name: "Xã Ninh Lộc",
    code: 22600,
    division_type: "xã",
    codename: "xa_ninh_loc",
    district_code: 572,
  },
  {
    name: "Xã Ninh Ích",
    code: 22603,
    division_type: "xã",
    codename: "xa_ninh_ich",
    district_code: 572,
  },
  {
    name: "Xã Ninh Vân",
    code: 22606,
    division_type: "xã",
    codename: "xa_ninh_van",
    district_code: 572,
  },
  {
    name: "Thị trấn Khánh Vĩnh",
    code: 22609,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_vinh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Hiệp",
    code: 22612,
    division_type: "xã",
    codename: "xa_khanh_hiep",
    district_code: 573,
  },
  {
    name: "Xã Khánh Bình",
    code: 22615,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Trung",
    code: 22618,
    division_type: "xã",
    codename: "xa_khanh_trung",
    district_code: 573,
  },
  {
    name: "Xã Khánh Đông",
    code: 22621,
    division_type: "xã",
    codename: "xa_khanh_dong",
    district_code: 573,
  },
  {
    name: "Xã Khánh Thượng",
    code: 22624,
    division_type: "xã",
    codename: "xa_khanh_thuong",
    district_code: 573,
  },
  {
    name: "Xã Khánh Nam",
    code: 22627,
    division_type: "xã",
    codename: "xa_khanh_nam",
    district_code: 573,
  },
  {
    name: "Xã Sông Cầu",
    code: 22630,
    division_type: "xã",
    codename: "xa_song_cau",
    district_code: 573,
  },
  {
    name: "Xã Giang Ly",
    code: 22633,
    division_type: "xã",
    codename: "xa_giang_ly",
    district_code: 573,
  },
  {
    name: "Xã Cầu Bà",
    code: 22636,
    division_type: "xã",
    codename: "xa_cau_ba",
    district_code: 573,
  },
  {
    name: "Xã Liên Sang",
    code: 22639,
    division_type: "xã",
    codename: "xa_lien_sang",
    district_code: 573,
  },
  {
    name: "Xã Khánh Thành",
    code: 22642,
    division_type: "xã",
    codename: "xa_khanh_thanh",
    district_code: 573,
  },
  {
    name: "Xã Khánh Phú",
    code: 22645,
    division_type: "xã",
    codename: "xa_khanh_phu",
    district_code: 573,
  },
  {
    name: "Xã Sơn Thái",
    code: 22648,
    division_type: "xã",
    codename: "xa_son_thai",
    district_code: 573,
  },
  {
    name: "Thị trấn Diên Khánh",
    code: 22651,
    division_type: "thị trấn",
    codename: "thi_tran_dien_khanh",
    district_code: 574,
  },
  {
    name: "Xã Diên Lâm",
    code: 22654,
    division_type: "xã",
    codename: "xa_dien_lam",
    district_code: 574,
  },
  {
    name: "Xã Diên Điền",
    code: 22657,
    division_type: "xã",
    codename: "xa_dien_dien",
    district_code: 574,
  },
  {
    name: "Xã Diên Xuân",
    code: 22660,
    division_type: "xã",
    codename: "xa_dien_xuan",
    district_code: 574,
  },
  {
    name: "Xã Diên Sơn",
    code: 22663,
    division_type: "xã",
    codename: "xa_dien_son",
    district_code: 574,
  },
  {
    name: "Xã Diên Đồng",
    code: 22666,
    division_type: "xã",
    codename: "xa_dien_dong",
    district_code: 574,
  },
  {
    name: "Xã Diên Phú",
    code: 22669,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 574,
  },
  {
    name: "Xã Diên Thọ",
    code: 22672,
    division_type: "xã",
    codename: "xa_dien_tho",
    district_code: 574,
  },
  {
    name: "Xã Diên Phước",
    code: 22675,
    division_type: "xã",
    codename: "xa_dien_phuoc",
    district_code: 574,
  },
  {
    name: "Xã Diên Lạc",
    code: 22678,
    division_type: "xã",
    codename: "xa_dien_lac",
    district_code: 574,
  },
  {
    name: "Xã Diên Tân",
    code: 22681,
    division_type: "xã",
    codename: "xa_dien_tan",
    district_code: 574,
  },
  {
    name: "Xã Diên Hòa",
    code: 22684,
    division_type: "xã",
    codename: "xa_dien_hoa",
    district_code: 574,
  },
  {
    name: "Xã Diên Thạnh",
    code: 22687,
    division_type: "xã",
    codename: "xa_dien_thanh",
    district_code: 574,
  },
  {
    name: "Xã Diên Toàn",
    code: 22690,
    division_type: "xã",
    codename: "xa_dien_toan",
    district_code: 574,
  },
  {
    name: "Xã Diên An",
    code: 22693,
    division_type: "xã",
    codename: "xa_dien_an",
    district_code: 574,
  },
  {
    name: "Xã Bình Lộc",
    code: 22696,
    division_type: "xã",
    codename: "xa_binh_loc",
    district_code: 574,
  },
  {
    name: "Xã Suối Hiệp",
    code: 22702,
    division_type: "xã",
    codename: "xa_suoi_hiep",
    district_code: 574,
  },
  {
    name: "Xã Suối Tiên",
    code: 22705,
    division_type: "xã",
    codename: "xa_suoi_tien",
    district_code: 574,
  },
  {
    name: "Thị trấn Tô Hạp",
    code: 22714,
    division_type: "thị trấn",
    codename: "thi_tran_to_hap",
    district_code: 575,
  },
  {
    name: "Xã Thành Sơn",
    code: 22717,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 575,
  },
  {
    name: "Xã Sơn Lâm",
    code: 22720,
    division_type: "xã",
    codename: "xa_son_lam",
    district_code: 575,
  },
  {
    name: "Xã Sơn Hiệp",
    code: 22723,
    division_type: "xã",
    codename: "xa_son_hiep",
    district_code: 575,
  },
  {
    name: "Xã Sơn Bình",
    code: 22726,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 575,
  },
  {
    name: "Xã Sơn Trung",
    code: 22729,
    division_type: "xã",
    codename: "xa_son_trung",
    district_code: 575,
  },
  {
    name: "Xã Ba Cụm Bắc",
    code: 22732,
    division_type: "xã",
    codename: "xa_ba_cum_bac",
    district_code: 575,
  },
  {
    name: "Xã Ba Cụm Nam",
    code: 22735,
    division_type: "xã",
    codename: "xa_ba_cum_nam",
    district_code: 575,
  },
  {
    name: "Thị trấn Trường Sa",
    code: 22736,
    division_type: "thị trấn",
    codename: "thi_tran_truong_sa",
    district_code: 576,
  },
  {
    name: "Xã Song Tử Tây",
    code: 22737,
    division_type: "xã",
    codename: "xa_song_tu_tay",
    district_code: 576,
  },
  {
    name: "Xã Sinh Tồn",
    code: 22739,
    division_type: "xã",
    codename: "xa_sinh_ton",
    district_code: 576,
  },
  {
    name: "Phường Đô Vinh",
    code: 22738,
    division_type: "phường",
    codename: "phuong_do_vinh",
    district_code: 582,
  },
  {
    name: "Phường Phước Mỹ",
    code: 22741,
    division_type: "phường",
    codename: "phuong_phuoc_my",
    district_code: 582,
  },
  {
    name: "Phường Bảo An",
    code: 22744,
    division_type: "phường",
    codename: "phuong_bao_an",
    district_code: 582,
  },
  {
    name: "Phường Phủ Hà",
    code: 22747,
    division_type: "phường",
    codename: "phuong_phu_ha",
    district_code: 582,
  },
  {
    name: "Phường Thanh Sơn",
    code: 22750,
    division_type: "phường",
    codename: "phuong_thanh_son",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Hương",
    code: 22753,
    division_type: "phường",
    codename: "phuong_my_huong",
    district_code: 582,
  },
  {
    name: "Phường Tấn Tài",
    code: 22756,
    division_type: "phường",
    codename: "phuong_tan_tai",
    district_code: 582,
  },
  {
    name: "Phường Kinh Dinh",
    code: 22759,
    division_type: "phường",
    codename: "phuong_kinh_dinh",
    district_code: 582,
  },
  {
    name: "Phường Đạo Long",
    code: 22762,
    division_type: "phường",
    codename: "phuong_dao_long",
    district_code: 582,
  },
  {
    name: "Phường Đài Sơn",
    code: 22765,
    division_type: "phường",
    codename: "phuong_dai_son",
    district_code: 582,
  },
  {
    name: "Phường Đông Hải",
    code: 22768,
    division_type: "phường",
    codename: "phuong_dong_hai",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Đông",
    code: 22771,
    division_type: "phường",
    codename: "phuong_my_dong",
    district_code: 582,
  },
  {
    name: "Xã Thành Hải",
    code: 22774,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 582,
  },
  {
    name: "Phường Văn Hải",
    code: 22777,
    division_type: "phường",
    codename: "phuong_van_hai",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Bình",
    code: 22779,
    division_type: "phường",
    codename: "phuong_my_binh",
    district_code: 582,
  },
  {
    name: "Phường Mỹ Hải",
    code: 22780,
    division_type: "phường",
    codename: "phuong_my_hai",
    district_code: 582,
  },
  {
    name: "Xã Phước Bình",
    code: 22783,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 584,
  },
  {
    name: "Xã Phước Hòa",
    code: 22786,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 584,
  },
  {
    name: "Xã Phước Tân",
    code: 22789,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 584,
  },
  {
    name: "Xã Phước Tiến",
    code: 22792,
    division_type: "xã",
    codename: "xa_phuoc_tien",
    district_code: 584,
  },
  {
    name: "Xã Phước Thắng",
    code: 22795,
    division_type: "xã",
    codename: "xa_phuoc_thang",
    district_code: 584,
  },
  {
    name: "Xã Phước Thành",
    code: 22798,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 584,
  },
  {
    name: "Xã Phước Đại",
    code: 22801,
    division_type: "xã",
    codename: "xa_phuoc_dai",
    district_code: 584,
  },
  {
    name: "Xã Phước Chính",
    code: 22804,
    division_type: "xã",
    codename: "xa_phuoc_chinh",
    district_code: 584,
  },
  {
    name: "Xã Phước Trung",
    code: 22807,
    division_type: "xã",
    codename: "xa_phuoc_trung",
    district_code: 584,
  },
  {
    name: "Thị trấn Tân Sơn",
    code: 22810,
    division_type: "thị trấn",
    codename: "thi_tran_tan_son",
    district_code: 585,
  },
  {
    name: "Xã Lâm Sơn",
    code: 22813,
    division_type: "xã",
    codename: "xa_lam_son",
    district_code: 585,
  },
  {
    name: "Xã Lương Sơn",
    code: 22816,
    division_type: "xã",
    codename: "xa_luong_son",
    district_code: 585,
  },
  {
    name: "Xã Quảng Sơn",
    code: 22819,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 585,
  },
  {
    name: "Xã Mỹ Sơn",
    code: 22822,
    division_type: "xã",
    codename: "xa_my_son",
    district_code: 585,
  },
  {
    name: "Xã Hòa Sơn",
    code: 22825,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 585,
  },
  {
    name: "Xã Ma Nới",
    code: 22828,
    division_type: "xã",
    codename: "xa_ma_noi",
    district_code: 585,
  },
  {
    name: "Xã Nhơn Sơn",
    code: 22831,
    division_type: "xã",
    codename: "xa_nhon_son",
    district_code: 585,
  },
  {
    name: "Thị trấn Khánh Hải",
    code: 22834,
    division_type: "thị trấn",
    codename: "thi_tran_khanh_hai",
    district_code: 586,
  },
  {
    name: "Xã Vĩnh Hải",
    code: 22846,
    division_type: "xã",
    codename: "xa_vinh_hai",
    district_code: 586,
  },
  {
    name: "Xã Phương Hải",
    code: 22852,
    division_type: "xã",
    codename: "xa_phuong_hai",
    district_code: 586,
  },
  {
    name: "Xã Tân Hải",
    code: 22855,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 586,
  },
  {
    name: "Xã Xuân Hải",
    code: 22858,
    division_type: "xã",
    codename: "xa_xuan_hai",
    district_code: 586,
  },
  {
    name: "Xã Hộ Hải",
    code: 22861,
    division_type: "xã",
    codename: "xa_ho_hai",
    district_code: 586,
  },
  {
    name: "Xã Tri Hải",
    code: 22864,
    division_type: "xã",
    codename: "xa_tri_hai",
    district_code: 586,
  },
  {
    name: "Xã Nhơn Hải",
    code: 22867,
    division_type: "xã",
    codename: "xa_nhon_hai",
    district_code: 586,
  },
  {
    name: "Xã Thanh Hải",
    code: 22868,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 586,
  },
  {
    name: "Thị trấn Phước Dân",
    code: 22870,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_dan",
    district_code: 587,
  },
  {
    name: "Xã Phước Sơn",
    code: 22873,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 587,
  },
  {
    name: "Xã Phước Thái",
    code: 22876,
    division_type: "xã",
    codename: "xa_phuoc_thai",
    district_code: 587,
  },
  {
    name: "Xã Phước Hậu",
    code: 22879,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 587,
  },
  {
    name: "Xã Phước Thuận",
    code: 22882,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 587,
  },
  {
    name: "Xã An Hải",
    code: 22888,
    division_type: "xã",
    codename: "xa_an_hai",
    district_code: 587,
  },
  {
    name: "Xã Phước Hữu",
    code: 22891,
    division_type: "xã",
    codename: "xa_phuoc_huu",
    district_code: 587,
  },
  {
    name: "Xã Phước Hải",
    code: 22894,
    division_type: "xã",
    codename: "xa_phuoc_hai",
    district_code: 587,
  },
  {
    name: "Xã Phước Vinh",
    code: 22912,
    division_type: "xã",
    codename: "xa_phuoc_vinh",
    district_code: 587,
  },
  {
    name: "Xã Phước Chiến",
    code: 22837,
    division_type: "xã",
    codename: "xa_phuoc_chien",
    district_code: 588,
  },
  {
    name: "Xã Công Hải",
    code: 22840,
    division_type: "xã",
    codename: "xa_cong_hai",
    district_code: 588,
  },
  {
    name: "Xã Phước Kháng",
    code: 22843,
    division_type: "xã",
    codename: "xa_phuoc_khang",
    district_code: 588,
  },
  {
    name: "Xã Lợi Hải",
    code: 22849,
    division_type: "xã",
    codename: "xa_loi_hai",
    district_code: 588,
  },
  {
    name: "Xã Bắc Sơn",
    code: 22853,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 588,
  },
  {
    name: "Xã Bắc Phong",
    code: 22856,
    division_type: "xã",
    codename: "xa_bac_phong",
    district_code: 588,
  },
  {
    name: "Xã Phước Hà",
    code: 22885,
    division_type: "xã",
    codename: "xa_phuoc_ha",
    district_code: 589,
  },
  {
    name: "Xã Phước Nam",
    code: 22897,
    division_type: "xã",
    codename: "xa_phuoc_nam",
    district_code: 589,
  },
  {
    name: "Xã Phước Ninh",
    code: 22898,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 589,
  },
  {
    name: "Xã Nhị Hà",
    code: 22900,
    division_type: "xã",
    codename: "xa_nhi_ha",
    district_code: 589,
  },
  {
    name: "Xã Phước Dinh",
    code: 22903,
    division_type: "xã",
    codename: "xa_phuoc_dinh",
    district_code: 589,
  },
  {
    name: "Xã Phước Minh",
    code: 22906,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 589,
  },
  {
    name: "Xã Phước Diêm",
    code: 22909,
    division_type: "xã",
    codename: "xa_phuoc_diem",
    district_code: 589,
  },
  {
    name: "Xã Cà Ná",
    code: 22910,
    division_type: "xã",
    codename: "xa_ca_na",
    district_code: 589,
  },
  {
    name: "Phường Mũi Né",
    code: 22915,
    division_type: "phường",
    codename: "phuong_mui_ne",
    district_code: 593,
  },
  {
    name: "Phường Hàm Tiến",
    code: 22918,
    division_type: "phường",
    codename: "phuong_ham_tien",
    district_code: 593,
  },
  {
    name: "Phường Phú Hài",
    code: 22921,
    division_type: "phường",
    codename: "phuong_phu_hai",
    district_code: 593,
  },
  {
    name: "Phường Phú Thủy",
    code: 22924,
    division_type: "phường",
    codename: "phuong_phu_thuy",
    district_code: 593,
  },
  {
    name: "Phường Phú Tài",
    code: 22927,
    division_type: "phường",
    codename: "phuong_phu_tai",
    district_code: 593,
  },
  {
    name: "Phường Phú Trinh",
    code: 22930,
    division_type: "phường",
    codename: "phuong_phu_trinh",
    district_code: 593,
  },
  {
    name: "Phường Xuân An",
    code: 22933,
    division_type: "phường",
    codename: "phuong_xuan_an",
    district_code: 593,
  },
  {
    name: "Phường Thanh Hải",
    code: 22936,
    division_type: "phường",
    codename: "phuong_thanh_hai",
    district_code: 593,
  },
  {
    name: "Phường Bình Hưng",
    code: 22939,
    division_type: "phường",
    codename: "phuong_binh_hung",
    district_code: 593,
  },
  {
    name: "Phường Đức Nghĩa",
    code: 22942,
    division_type: "phường",
    codename: "phuong_duc_nghia",
    district_code: 593,
  },
  {
    name: "Phường Lạc Đạo",
    code: 22945,
    division_type: "phường",
    codename: "phuong_lac_dao",
    district_code: 593,
  },
  {
    name: "Phường Đức Thắng",
    code: 22948,
    division_type: "phường",
    codename: "phuong_duc_thang",
    district_code: 593,
  },
  {
    name: "Phường Hưng Long",
    code: 22951,
    division_type: "phường",
    codename: "phuong_hung_long",
    district_code: 593,
  },
  {
    name: "Phường Đức Long",
    code: 22954,
    division_type: "phường",
    codename: "phuong_duc_long",
    district_code: 593,
  },
  {
    name: "Xã Thiện Nghiệp",
    code: 22957,
    division_type: "xã",
    codename: "xa_thien_nghiep",
    district_code: 593,
  },
  {
    name: "Xã Phong Nẫm",
    code: 22960,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 593,
  },
  {
    name: "Xã Tiến Lợi",
    code: 22963,
    division_type: "xã",
    codename: "xa_tien_loi",
    district_code: 593,
  },
  {
    name: "Xã Tiến Thành",
    code: 22966,
    division_type: "xã",
    codename: "xa_tien_thanh",
    district_code: 593,
  },
  {
    name: "Phường Phước Hội",
    code: 23231,
    division_type: "phường",
    codename: "phuong_phuoc_hoi",
    district_code: 594,
  },
  {
    name: "Phường Phước Lộc",
    code: 23232,
    division_type: "phường",
    codename: "phuong_phuoc_loc",
    district_code: 594,
  },
  {
    name: "Phường Tân Thiện",
    code: 23234,
    division_type: "phường",
    codename: "phuong_tan_thien",
    district_code: 594,
  },
  {
    name: "Phường Tân An",
    code: 23235,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 594,
  },
  {
    name: "Phường Bình Tân",
    code: 23237,
    division_type: "phường",
    codename: "phuong_binh_tan",
    district_code: 594,
  },
  {
    name: "Xã Tân Hải",
    code: 23245,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 594,
  },
  {
    name: "Xã Tân Tiến",
    code: 23246,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 594,
  },
  {
    name: "Xã Tân Bình",
    code: 23248,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 594,
  },
  {
    name: "Xã Tân Phước",
    code: 23268,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 594,
  },
  {
    name: "Thị trấn Liên Hương",
    code: 22969,
    division_type: "thị trấn",
    codename: "thi_tran_lien_huong",
    district_code: 595,
  },
  {
    name: "Thị trấn Phan Rí Cửa",
    code: 22972,
    division_type: "thị trấn",
    codename: "thi_tran_phan_ri_cua",
    district_code: 595,
  },
  {
    name: "Xã Phan Dũng",
    code: 22975,
    division_type: "xã",
    codename: "xa_phan_dung",
    district_code: 595,
  },
  {
    name: "Xã Phong Phú",
    code: 22978,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 595,
  },
  {
    name: "Xã Vĩnh Hảo",
    code: 22981,
    division_type: "xã",
    codename: "xa_vinh_hao",
    district_code: 595,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 22984,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 595,
  },
  {
    name: "Xã Phú Lạc",
    code: 22987,
    division_type: "xã",
    codename: "xa_phu_lac",
    district_code: 595,
  },
  {
    name: "Xã Phước Thể",
    code: 22990,
    division_type: "xã",
    codename: "xa_phuoc_the",
    district_code: 595,
  },
  {
    name: "Xã Hòa Minh",
    code: 22993,
    division_type: "xã",
    codename: "xa_hoa_minh",
    district_code: 595,
  },
  {
    name: "Xã Chí Công",
    code: 22996,
    division_type: "xã",
    codename: "xa_chi_cong",
    district_code: 595,
  },
  {
    name: "Xã Bình Thạnh",
    code: 22999,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 595,
  },
  {
    name: "Thị trấn Chợ Lầu",
    code: 23005,
    division_type: "thị trấn",
    codename: "thi_tran_cho_lau",
    district_code: 596,
  },
  {
    name: "Xã Phan Sơn",
    code: 23008,
    division_type: "xã",
    codename: "xa_phan_son",
    district_code: 596,
  },
  {
    name: "Xã Phan Lâm",
    code: 23011,
    division_type: "xã",
    codename: "xa_phan_lam",
    district_code: 596,
  },
  {
    name: "Xã Bình An",
    code: 23014,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 596,
  },
  {
    name: "Xã Phan Điền",
    code: 23017,
    division_type: "xã",
    codename: "xa_phan_dien",
    district_code: 596,
  },
  {
    name: "Xã Hải Ninh",
    code: 23020,
    division_type: "xã",
    codename: "xa_hai_ninh",
    district_code: 596,
  },
  {
    name: "Xã Sông Lũy",
    code: 23023,
    division_type: "xã",
    codename: "xa_song_luy",
    district_code: 596,
  },
  {
    name: "Xã Phan Tiến",
    code: 23026,
    division_type: "xã",
    codename: "xa_phan_tien",
    district_code: 596,
  },
  {
    name: "Xã Sông Bình",
    code: 23029,
    division_type: "xã",
    codename: "xa_song_binh",
    district_code: 596,
  },
  {
    name: "Thị trấn Lương Sơn",
    code: 23032,
    division_type: "thị trấn",
    codename: "thi_tran_luong_son",
    district_code: 596,
  },
  {
    name: "Xã Phan Hòa",
    code: 23035,
    division_type: "xã",
    codename: "xa_phan_hoa",
    district_code: 596,
  },
  {
    name: "Xã Phan Thanh",
    code: 23038,
    division_type: "xã",
    codename: "xa_phan_thanh",
    district_code: 596,
  },
  {
    name: "Xã Hồng Thái",
    code: 23041,
    division_type: "xã",
    codename: "xa_hong_thai",
    district_code: 596,
  },
  {
    name: "Xã Phan Hiệp",
    code: 23044,
    division_type: "xã",
    codename: "xa_phan_hiep",
    district_code: 596,
  },
  {
    name: "Xã Bình Tân",
    code: 23047,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 596,
  },
  {
    name: "Xã Phan Rí Thành",
    code: 23050,
    division_type: "xã",
    codename: "xa_phan_ri_thanh",
    district_code: 596,
  },
  {
    name: "Xã Hòa Thắng",
    code: 23053,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 596,
  },
  {
    name: "Xã Hồng Phong",
    code: 23056,
    division_type: "xã",
    codename: "xa_hong_phong",
    district_code: 596,
  },
  {
    name: "Thị trấn Ma Lâm",
    code: 23059,
    division_type: "thị trấn",
    codename: "thi_tran_ma_lam",
    district_code: 597,
  },
  {
    name: "Thị trấn Phú Long",
    code: 23062,
    division_type: "thị trấn",
    codename: "thi_tran_phu_long",
    district_code: 597,
  },
  {
    name: "Xã La Dạ",
    code: 23065,
    division_type: "xã",
    codename: "xa_la_da",
    district_code: 597,
  },
  {
    name: "Xã Đông Tiến",
    code: 23068,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 597,
  },
  {
    name: "Xã Thuận Hòa",
    code: 23071,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 597,
  },
  {
    name: "Xã Đông Giang",
    code: 23074,
    division_type: "xã",
    codename: "xa_dong_giang",
    district_code: 597,
  },
  {
    name: "Xã Hàm Phú",
    code: 23077,
    division_type: "xã",
    codename: "xa_ham_phu",
    district_code: 597,
  },
  {
    name: "Xã Hồng Liêm",
    code: 23080,
    division_type: "xã",
    codename: "xa_hong_liem",
    district_code: 597,
  },
  {
    name: "Xã Thuận Minh",
    code: 23083,
    division_type: "xã",
    codename: "xa_thuan_minh",
    district_code: 597,
  },
  {
    name: "Xã Hồng Sơn",
    code: 23086,
    division_type: "xã",
    codename: "xa_hong_son",
    district_code: 597,
  },
  {
    name: "Xã Hàm Trí",
    code: 23089,
    division_type: "xã",
    codename: "xa_ham_tri",
    district_code: 597,
  },
  {
    name: "Xã Hàm Đức",
    code: 23092,
    division_type: "xã",
    codename: "xa_ham_duc",
    district_code: 597,
  },
  {
    name: "Xã Hàm Liêm",
    code: 23095,
    division_type: "xã",
    codename: "xa_ham_liem",
    district_code: 597,
  },
  {
    name: "Xã Hàm Chính",
    code: 23098,
    division_type: "xã",
    codename: "xa_ham_chinh",
    district_code: 597,
  },
  {
    name: "Xã Hàm Hiệp",
    code: 23101,
    division_type: "xã",
    codename: "xa_ham_hiep",
    district_code: 597,
  },
  {
    name: "Xã Hàm Thắng",
    code: 23104,
    division_type: "xã",
    codename: "xa_ham_thang",
    district_code: 597,
  },
  {
    name: "Xã Đa Mi",
    code: 23107,
    division_type: "xã",
    codename: "xa_da_mi",
    district_code: 597,
  },
  {
    name: "Thị trấn Thuận Nam",
    code: 23110,
    division_type: "thị trấn",
    codename: "thi_tran_thuan_nam",
    district_code: 598,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 23113,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 598,
  },
  {
    name: "Xã Hàm Cần",
    code: 23116,
    division_type: "xã",
    codename: "xa_ham_can",
    district_code: 598,
  },
  {
    name: "Xã Mương Mán",
    code: 23119,
    division_type: "xã",
    codename: "xa_muong_man",
    district_code: 598,
  },
  {
    name: "Xã Hàm Thạnh",
    code: 23122,
    division_type: "xã",
    codename: "xa_ham_thanh",
    district_code: 598,
  },
  {
    name: "Xã Hàm Kiệm",
    code: 23125,
    division_type: "xã",
    codename: "xa_ham_kiem",
    district_code: 598,
  },
  {
    name: "Xã Hàm Cường",
    code: 23128,
    division_type: "xã",
    codename: "xa_ham_cuong",
    district_code: 598,
  },
  {
    name: "Xã Hàm Mỹ",
    code: 23131,
    division_type: "xã",
    codename: "xa_ham_my",
    district_code: 598,
  },
  {
    name: "Xã Tân Lập",
    code: 23134,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 598,
  },
  {
    name: "Xã Hàm Minh",
    code: 23137,
    division_type: "xã",
    codename: "xa_ham_minh",
    district_code: 598,
  },
  {
    name: "Xã Thuận Quí",
    code: 23140,
    division_type: "xã",
    codename: "xa_thuan_qui",
    district_code: 598,
  },
  {
    name: "Xã Tân Thuận",
    code: 23143,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 598,
  },
  {
    name: "Xã Tân Thành",
    code: 23146,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 598,
  },
  {
    name: "Thị trấn Lạc Tánh",
    code: 23149,
    division_type: "thị trấn",
    codename: "thi_tran_lac_tanh",
    district_code: 599,
  },
  {
    name: "Xã Bắc Ruộng",
    code: 23152,
    division_type: "xã",
    codename: "xa_bac_ruong",
    district_code: 599,
  },
  {
    name: "Xã Nghị Đức",
    code: 23158,
    division_type: "xã",
    codename: "xa_nghi_duc",
    district_code: 599,
  },
  {
    name: "Xã La Ngâu",
    code: 23161,
    division_type: "xã",
    codename: "xa_la_ngau",
    district_code: 599,
  },
  {
    name: "Xã Huy Khiêm",
    code: 23164,
    division_type: "xã",
    codename: "xa_huy_khiem",
    district_code: 599,
  },
  {
    name: "Xã Măng Tố",
    code: 23167,
    division_type: "xã",
    codename: "xa_mang_to",
    district_code: 599,
  },
  {
    name: "Xã Đức Phú",
    code: 23170,
    division_type: "xã",
    codename: "xa_duc_phu",
    district_code: 599,
  },
  {
    name: "Xã Đồng Kho",
    code: 23173,
    division_type: "xã",
    codename: "xa_dong_kho",
    district_code: 599,
  },
  {
    name: "Xã Gia An",
    code: 23176,
    division_type: "xã",
    codename: "xa_gia_an",
    district_code: 599,
  },
  {
    name: "Xã Đức Bình",
    code: 23179,
    division_type: "xã",
    codename: "xa_duc_binh",
    district_code: 599,
  },
  {
    name: "Xã Gia Huynh",
    code: 23182,
    division_type: "xã",
    codename: "xa_gia_huynh",
    district_code: 599,
  },
  {
    name: "Xã Đức Thuận",
    code: 23185,
    division_type: "xã",
    codename: "xa_duc_thuan",
    district_code: 599,
  },
  {
    name: "Xã Suối Kiết",
    code: 23188,
    division_type: "xã",
    codename: "xa_suoi_kiet",
    district_code: 599,
  },
  {
    name: "Thị trấn Võ Xu",
    code: 23191,
    division_type: "thị trấn",
    codename: "thi_tran_vo_xu",
    district_code: 600,
  },
  {
    name: "Thị trấn Đức Tài",
    code: 23194,
    division_type: "thị trấn",
    codename: "thi_tran_duc_tai",
    district_code: 600,
  },
  {
    name: "Xã Đa Kai",
    code: 23197,
    division_type: "xã",
    codename: "xa_da_kai",
    district_code: 600,
  },
  {
    name: "Xã Sùng Nhơn",
    code: 23200,
    division_type: "xã",
    codename: "xa_sung_nhon",
    district_code: 600,
  },
  {
    name: "Xã Mê Pu",
    code: 23203,
    division_type: "xã",
    codename: "xa_me_pu",
    district_code: 600,
  },
  {
    name: "Xã Nam Chính",
    code: 23206,
    division_type: "xã",
    codename: "xa_nam_chinh",
    district_code: 600,
  },
  {
    name: "Xã Đức Hạnh",
    code: 23212,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 600,
  },
  {
    name: "Xã Đức Tín",
    code: 23215,
    division_type: "xã",
    codename: "xa_duc_tin",
    district_code: 600,
  },
  {
    name: "Xã Vũ Hoà",
    code: 23218,
    division_type: "xã",
    codename: "xa_vu_hoa",
    district_code: 600,
  },
  {
    name: "Xã Tân Hà",
    code: 23221,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 600,
  },
  {
    name: "Xã Đông Hà",
    code: 23224,
    division_type: "xã",
    codename: "xa_dong_ha",
    district_code: 600,
  },
  {
    name: "Xã Trà Tân",
    code: 23227,
    division_type: "xã",
    codename: "xa_tra_tan",
    district_code: 600,
  },
  {
    name: "Thị trấn Tân Minh",
    code: 23230,
    division_type: "thị trấn",
    codename: "thi_tran_tan_minh",
    district_code: 601,
  },
  {
    name: "Thị trấn Tân Nghĩa",
    code: 23236,
    division_type: "thị trấn",
    codename: "thi_tran_tan_nghia",
    district_code: 601,
  },
  {
    name: "Xã Sông Phan",
    code: 23239,
    division_type: "xã",
    codename: "xa_song_phan",
    district_code: 601,
  },
  {
    name: "Xã Tân Phúc",
    code: 23242,
    division_type: "xã",
    codename: "xa_tan_phuc",
    district_code: 601,
  },
  {
    name: "Xã Tân Đức",
    code: 23251,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 601,
  },
  {
    name: "Xã Tân Thắng",
    code: 23254,
    division_type: "xã",
    codename: "xa_tan_thang",
    district_code: 601,
  },
  {
    name: "Xã Thắng Hải",
    code: 23255,
    division_type: "xã",
    codename: "xa_thang_hai",
    district_code: 601,
  },
  {
    name: "Xã Tân Hà",
    code: 23257,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 601,
  },
  {
    name: "Xã Tân Xuân",
    code: 23260,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 601,
  },
  {
    name: "Xã Sơn Mỹ",
    code: 23266,
    division_type: "xã",
    codename: "xa_son_my",
    district_code: 601,
  },
  {
    name: "Xã Ngũ Phụng",
    code: 23272,
    division_type: "xã",
    codename: "xa_ngu_phung",
    district_code: 602,
  },
  {
    name: "Xã Long Hải",
    code: 23275,
    division_type: "xã",
    codename: "xa_long_hai",
    district_code: 602,
  },
  {
    name: "Xã Tam Thanh",
    code: 23278,
    division_type: "xã",
    codename: "xa_tam_thanh",
    district_code: 602,
  },
  {
    name: "Phường Quang Trung",
    code: 23281,
    division_type: "phường",
    codename: "phuong_quang_trung",
    district_code: 608,
  },
  {
    name: "Phường Duy Tân",
    code: 23284,
    division_type: "phường",
    codename: "phuong_duy_tan",
    district_code: 608,
  },
  {
    name: "Phường Quyết Thắng",
    code: 23287,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 608,
  },
  {
    name: "Phường Trường Chinh",
    code: 23290,
    division_type: "phường",
    codename: "phuong_truong_chinh",
    district_code: 608,
  },
  {
    name: "Phường Thắng Lợi",
    code: 23293,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 608,
  },
  {
    name: "Phường Ngô Mây",
    code: 23296,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 608,
  },
  {
    name: "Phường Thống Nhất",
    code: 23299,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 608,
  },
  {
    name: "Phường Lê Lợi",
    code: 23302,
    division_type: "phường",
    codename: "phuong_le_loi",
    district_code: 608,
  },
  {
    name: "Phường Nguyễn Trãi",
    code: 23305,
    division_type: "phường",
    codename: "phuong_nguyen_trai",
    district_code: 608,
  },
  {
    name: "Phường Trần Hưng Đạo",
    code: 23308,
    division_type: "phường",
    codename: "phuong_tran_hung_dao",
    district_code: 608,
  },
  {
    name: "Xã Đắk Cấm",
    code: 23311,
    division_type: "xã",
    codename: "xa_dak_cam",
    district_code: 608,
  },
  {
    name: "Xã Kroong",
    code: 23314,
    division_type: "xã",
    codename: "xa_kroong",
    district_code: 608,
  },
  {
    name: "Xã Ngọk Bay",
    code: 23317,
    division_type: "xã",
    codename: "xa_ngok_bay",
    district_code: 608,
  },
  {
    name: "Xã Vinh Quang",
    code: 23320,
    division_type: "xã",
    codename: "xa_vinh_quang",
    district_code: 608,
  },
  {
    name: "Xã Đắk Blà",
    code: 23323,
    division_type: "xã",
    codename: "xa_dak_bla",
    district_code: 608,
  },
  {
    name: "Xã Ia Chim",
    code: 23326,
    division_type: "xã",
    codename: "xa_ia_chim",
    district_code: 608,
  },
  {
    name: "Xã Đăk Năng",
    code: 23327,
    division_type: "xã",
    codename: "xa_dak_nang",
    district_code: 608,
  },
  {
    name: "Xã Đoàn Kết",
    code: 23329,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 608,
  },
  {
    name: "Xã Chư Hreng",
    code: 23332,
    division_type: "xã",
    codename: "xa_chu_hreng",
    district_code: 608,
  },
  {
    name: "Xã Đắk Rơ Wa",
    code: 23335,
    division_type: "xã",
    codename: "xa_dak_ro_wa",
    district_code: 608,
  },
  {
    name: "Xã Hòa Bình",
    code: 23338,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 608,
  },
  {
    name: "Thị trấn Đắk Glei",
    code: 23341,
    division_type: "thị trấn",
    codename: "thi_tran_dak_glei",
    district_code: 610,
  },
  {
    name: "Xã Đắk Blô",
    code: 23344,
    division_type: "xã",
    codename: "xa_dak_blo",
    district_code: 610,
  },
  {
    name: "Xã Đắk Man",
    code: 23347,
    division_type: "xã",
    codename: "xa_dak_man",
    district_code: 610,
  },
  {
    name: "Xã Đắk Nhoong",
    code: 23350,
    division_type: "xã",
    codename: "xa_dak_nhoong",
    district_code: 610,
  },
  {
    name: "Xã Đắk Pék",
    code: 23353,
    division_type: "xã",
    codename: "xa_dak_pek",
    district_code: 610,
  },
  {
    name: "Xã Đắk Choong",
    code: 23356,
    division_type: "xã",
    codename: "xa_dak_choong",
    district_code: 610,
  },
  {
    name: "Xã Xốp",
    code: 23359,
    division_type: "xã",
    codename: "xa_xop",
    district_code: 610,
  },
  {
    name: "Xã Mường Hoong",
    code: 23362,
    division_type: "xã",
    codename: "xa_muong_hoong",
    district_code: 610,
  },
  {
    name: "Xã Ngọc Linh",
    code: 23365,
    division_type: "xã",
    codename: "xa_ngoc_linh",
    district_code: 610,
  },
  {
    name: "Xã Đắk Long",
    code: 23368,
    division_type: "xã",
    codename: "xa_dak_long",
    district_code: 610,
  },
  {
    name: "Xã Đắk KRoong",
    code: 23371,
    division_type: "xã",
    codename: "xa_dak_kroong",
    district_code: 610,
  },
  {
    name: "Xã Đắk Môn",
    code: 23374,
    division_type: "xã",
    codename: "xa_dak_mon",
    district_code: 610,
  },
  {
    name: "Thị trấn Plei Kần",
    code: 23377,
    division_type: "thị trấn",
    codename: "thi_tran_plei_kan",
    district_code: 611,
  },
  {
    name: "Xã Đắk Ang",
    code: 23380,
    division_type: "xã",
    codename: "xa_dak_ang",
    district_code: 611,
  },
  {
    name: "Xã Đắk Dục",
    code: 23383,
    division_type: "xã",
    codename: "xa_dak_duc",
    district_code: 611,
  },
  {
    name: "Xã Đắk Nông",
    code: 23386,
    division_type: "xã",
    codename: "xa_dak_nong",
    district_code: 611,
  },
  {
    name: "Xã Đắk Xú",
    code: 23389,
    division_type: "xã",
    codename: "xa_dak_xu",
    district_code: 611,
  },
  {
    name: "Xã Đắk Kan",
    code: 23392,
    division_type: "xã",
    codename: "xa_dak_kan",
    district_code: 611,
  },
  {
    name: "Xã Bờ Y",
    code: 23395,
    division_type: "xã",
    codename: "xa_bo_y",
    district_code: 611,
  },
  {
    name: "Xã Sa Loong",
    code: 23398,
    division_type: "xã",
    codename: "xa_sa_loong",
    district_code: 611,
  },
  {
    name: "Thị trấn Đắk Tô",
    code: 23401,
    division_type: "thị trấn",
    codename: "thi_tran_dak_to",
    district_code: 612,
  },
  {
    name: "Xã Đắk Rơ Nga",
    code: 23427,
    division_type: "xã",
    codename: "xa_dak_ro_nga",
    district_code: 612,
  },
  {
    name: "Xã Ngọk Tụ",
    code: 23428,
    division_type: "xã",
    codename: "xa_ngok_tu",
    district_code: 612,
  },
  {
    name: "Xã Đắk Trăm",
    code: 23430,
    division_type: "xã",
    codename: "xa_dak_tram",
    district_code: 612,
  },
  {
    name: "Xã Văn Lem",
    code: 23431,
    division_type: "xã",
    codename: "xa_van_lem",
    district_code: 612,
  },
  {
    name: "Xã Kon Đào",
    code: 23434,
    division_type: "xã",
    codename: "xa_kon_dao",
    district_code: 612,
  },
  {
    name: "Xã Tân Cảnh",
    code: 23437,
    division_type: "xã",
    codename: "xa_tan_canh",
    district_code: 612,
  },
  {
    name: "Xã Diên Bình",
    code: 23440,
    division_type: "xã",
    codename: "xa_dien_binh",
    district_code: 612,
  },
  {
    name: "Xã Pô Kô",
    code: 23443,
    division_type: "xã",
    codename: "xa_po_ko",
    district_code: 612,
  },
  {
    name: "Xã Đắk Nên",
    code: 23452,
    division_type: "xã",
    codename: "xa_dak_nen",
    district_code: 613,
  },
  {
    name: "Xã Đắk Ring",
    code: 23455,
    division_type: "xã",
    codename: "xa_dak_ring",
    district_code: 613,
  },
  {
    name: "Xã Măng Buk",
    code: 23458,
    division_type: "xã",
    codename: "xa_mang_buk",
    district_code: 613,
  },
  {
    name: "Xã Đắk Tăng",
    code: 23461,
    division_type: "xã",
    codename: "xa_dak_tang",
    district_code: 613,
  },
  {
    name: "Xã Ngok Tem",
    code: 23464,
    division_type: "xã",
    codename: "xa_ngok_tem",
    district_code: 613,
  },
  {
    name: "Xã Pờ Ê",
    code: 23467,
    division_type: "xã",
    codename: "xa_po_e",
    district_code: 613,
  },
  {
    name: "Xã Măng Cành",
    code: 23470,
    division_type: "xã",
    codename: "xa_mang_canh",
    district_code: 613,
  },
  {
    name: "Thị trấn Măng Đen",
    code: 23473,
    division_type: "thị trấn",
    codename: "thi_tran_mang_den",
    district_code: 613,
  },
  {
    name: "Xã Hiếu",
    code: 23476,
    division_type: "xã",
    codename: "xa_hieu",
    district_code: 613,
  },
  {
    name: "Thị trấn Đắk Rve",
    code: 23479,
    division_type: "thị trấn",
    codename: "thi_tran_dak_rve",
    district_code: 614,
  },
  {
    name: "Xã Đắk Kôi",
    code: 23482,
    division_type: "xã",
    codename: "xa_dak_koi",
    district_code: 614,
  },
  {
    name: "Xã Đắk Tơ Lung",
    code: 23485,
    division_type: "xã",
    codename: "xa_dak_to_lung",
    district_code: 614,
  },
  {
    name: "Xã Đắk Ruồng",
    code: 23488,
    division_type: "xã",
    codename: "xa_dak_ruong",
    district_code: 614,
  },
  {
    name: "Xã Đắk Pne",
    code: 23491,
    division_type: "xã",
    codename: "xa_dak_pne",
    district_code: 614,
  },
  {
    name: "Xã Đắk Tờ Re",
    code: 23494,
    division_type: "xã",
    codename: "xa_dak_to_re",
    district_code: 614,
  },
  {
    name: "Xã Tân Lập",
    code: 23497,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 614,
  },
  {
    name: "Thị trấn Đắk Hà",
    code: 23500,
    division_type: "thị trấn",
    codename: "thi_tran_dak_ha",
    district_code: 615,
  },
  {
    name: "Xã Đắk PXi",
    code: 23503,
    division_type: "xã",
    codename: "xa_dak_pxi",
    district_code: 615,
  },
  {
    name: "Xã Đăk Long",
    code: 23504,
    division_type: "xã",
    codename: "xa_dak_long",
    district_code: 615,
  },
  {
    name: "Xã Đắk HRing",
    code: 23506,
    division_type: "xã",
    codename: "xa_dak_hring",
    district_code: 615,
  },
  {
    name: "Xã Đắk Ui",
    code: 23509,
    division_type: "xã",
    codename: "xa_dak_ui",
    district_code: 615,
  },
  {
    name: "Xã Đăk Ngọk",
    code: 23510,
    division_type: "xã",
    codename: "xa_dak_ngok",
    district_code: 615,
  },
  {
    name: "Xã Đắk Mar",
    code: 23512,
    division_type: "xã",
    codename: "xa_dak_mar",
    district_code: 615,
  },
  {
    name: "Xã Ngok Wang",
    code: 23515,
    division_type: "xã",
    codename: "xa_ngok_wang",
    district_code: 615,
  },
  {
    name: "Xã Ngok Réo",
    code: 23518,
    division_type: "xã",
    codename: "xa_ngok_reo",
    district_code: 615,
  },
  {
    name: "Xã Hà Mòn",
    code: 23521,
    division_type: "xã",
    codename: "xa_ha_mon",
    district_code: 615,
  },
  {
    name: "Xã Đắk La",
    code: 23524,
    division_type: "xã",
    codename: "xa_dak_la",
    district_code: 615,
  },
  {
    name: "Thị trấn Sa Thầy",
    code: 23527,
    division_type: "thị trấn",
    codename: "thi_tran_sa_thay",
    district_code: 616,
  },
  {
    name: "Xã Rơ Kơi",
    code: 23530,
    division_type: "xã",
    codename: "xa_ro_koi",
    district_code: 616,
  },
  {
    name: "Xã Sa Nhơn",
    code: 23533,
    division_type: "xã",
    codename: "xa_sa_nhon",
    district_code: 616,
  },
  {
    name: "Xã Hơ Moong",
    code: 23534,
    division_type: "xã",
    codename: "xa_ho_moong",
    district_code: 616,
  },
  {
    name: "Xã Mô Rai",
    code: 23536,
    division_type: "xã",
    codename: "xa_mo_rai",
    district_code: 616,
  },
  {
    name: "Xã Sa Sơn",
    code: 23539,
    division_type: "xã",
    codename: "xa_sa_son",
    district_code: 616,
  },
  {
    name: "Xã Sa Nghĩa",
    code: 23542,
    division_type: "xã",
    codename: "xa_sa_nghia",
    district_code: 616,
  },
  {
    name: "Xã Sa Bình",
    code: 23545,
    division_type: "xã",
    codename: "xa_sa_binh",
    district_code: 616,
  },
  {
    name: "Xã Ya Xiêr",
    code: 23548,
    division_type: "xã",
    codename: "xa_ya_xier",
    district_code: 616,
  },
  {
    name: "Xã Ya Tăng",
    code: 23551,
    division_type: "xã",
    codename: "xa_ya_tang",
    district_code: 616,
  },
  {
    name: "Xã Ya ly",
    code: 23554,
    division_type: "xã",
    codename: "xa_ya_ly",
    district_code: 616,
  },
  {
    name: "Xã Ngọc Lây",
    code: 23404,
    division_type: "xã",
    codename: "xa_ngoc_lay",
    district_code: 617,
  },
  {
    name: "Xã Đắk Na",
    code: 23407,
    division_type: "xã",
    codename: "xa_dak_na",
    district_code: 617,
  },
  {
    name: "Xã Măng Ri",
    code: 23410,
    division_type: "xã",
    codename: "xa_mang_ri",
    district_code: 617,
  },
  {
    name: "Xã Ngọc Yêu",
    code: 23413,
    division_type: "xã",
    codename: "xa_ngoc_yeu",
    district_code: 617,
  },
  {
    name: "Xã Đắk Sao",
    code: 23416,
    division_type: "xã",
    codename: "xa_dak_sao",
    district_code: 617,
  },
  {
    name: "Xã Đắk Rơ Ông",
    code: 23417,
    division_type: "xã",
    codename: "xa_dak_ro_ong",
    district_code: 617,
  },
  {
    name: "Xã Đắk Tờ Kan",
    code: 23419,
    division_type: "xã",
    codename: "xa_dak_to_kan",
    district_code: 617,
  },
  {
    name: "Xã Tu Mơ Rông",
    code: 23422,
    division_type: "xã",
    codename: "xa_tu_mo_rong",
    district_code: 617,
  },
  {
    name: "Xã Đắk Hà",
    code: 23425,
    division_type: "xã",
    codename: "xa_dak_ha",
    district_code: 617,
  },
  {
    name: "Xã Tê Xăng",
    code: 23446,
    division_type: "xã",
    codename: "xa_te_xang",
    district_code: 617,
  },
  {
    name: "Xã Văn Xuôi",
    code: 23449,
    division_type: "xã",
    codename: "xa_van_xuoi",
    district_code: 617,
  },
  {
    name: "Xã Ia Đal",
    code: 23535,
    division_type: "xã",
    codename: "xa_ia_dal",
    district_code: 618,
  },
  {
    name: "Xã Ia Dom",
    code: 23537,
    division_type: "xã",
    codename: "xa_ia_dom",
    district_code: 618,
  },
  {
    name: "Xã Ia Tơi",
    code: 23538,
    division_type: "xã",
    codename: "xa_ia_toi",
    district_code: 618,
  },
  {
    name: "Phường Yên Đỗ",
    code: 23557,
    division_type: "phường",
    codename: "phuong_yen_do",
    district_code: 622,
  },
  {
    name: "Phường Diên Hồng",
    code: 23560,
    division_type: "phường",
    codename: "phuong_dien_hong",
    district_code: 622,
  },
  {
    name: "Phường Ia Kring",
    code: 23563,
    division_type: "phường",
    codename: "phuong_ia_kring",
    district_code: 622,
  },
  {
    name: "Phường Hội Thương",
    code: 23566,
    division_type: "phường",
    codename: "phuong_hoi_thuong",
    district_code: 622,
  },
  {
    name: "Phường Hội Phú",
    code: 23569,
    division_type: "phường",
    codename: "phuong_hoi_phu",
    district_code: 622,
  },
  {
    name: "Phường Phù Đổng",
    code: 23570,
    division_type: "phường",
    codename: "phuong_phu_dong",
    district_code: 622,
  },
  {
    name: "Phường Hoa Lư",
    code: 23572,
    division_type: "phường",
    codename: "phuong_hoa_lu",
    district_code: 622,
  },
  {
    name: "Phường Tây Sơn",
    code: 23575,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 622,
  },
  {
    name: "Phường Thống Nhất",
    code: 23578,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 622,
  },
  {
    name: "Phường Đống Đa",
    code: 23579,
    division_type: "phường",
    codename: "phuong_dong_da",
    district_code: 622,
  },
  {
    name: "Phường Trà Bá",
    code: 23581,
    division_type: "phường",
    codename: "phuong_tra_ba",
    district_code: 622,
  },
  {
    name: "Phường Thắng Lợi",
    code: 23582,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 622,
  },
  {
    name: "Phường Yên Thế",
    code: 23584,
    division_type: "phường",
    codename: "phuong_yen_the",
    district_code: 622,
  },
  {
    name: "Phường Chi Lăng",
    code: 23586,
    division_type: "phường",
    codename: "phuong_chi_lang",
    district_code: 622,
  },
  {
    name: "Xã Biển Hồ",
    code: 23590,
    division_type: "xã",
    codename: "xa_bien_ho",
    district_code: 622,
  },
  {
    name: "Xã Tân Sơn",
    code: 23593,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 622,
  },
  {
    name: "Xã Trà Đa",
    code: 23596,
    division_type: "xã",
    codename: "xa_tra_da",
    district_code: 622,
  },
  {
    name: "Xã Chư Á",
    code: 23599,
    division_type: "xã",
    codename: "xa_chu_a",
    district_code: 622,
  },
  {
    name: "Xã An Phú",
    code: 23602,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 622,
  },
  {
    name: "Xã Diên Phú",
    code: 23605,
    division_type: "xã",
    codename: "xa_dien_phu",
    district_code: 622,
  },
  {
    name: "Xã Ia Kênh",
    code: 23608,
    division_type: "xã",
    codename: "xa_ia_kenh",
    district_code: 622,
  },
  {
    name: "Xã Gào",
    code: 23611,
    division_type: "xã",
    codename: "xa_gao",
    district_code: 622,
  },
  {
    name: "Phường An Bình",
    code: 23614,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 623,
  },
  {
    name: "Phường Tây Sơn",
    code: 23617,
    division_type: "phường",
    codename: "phuong_tay_son",
    district_code: 623,
  },
  {
    name: "Phường An Phú",
    code: 23620,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 623,
  },
  {
    name: "Phường An Tân",
    code: 23623,
    division_type: "phường",
    codename: "phuong_an_tan",
    district_code: 623,
  },
  {
    name: "Xã Tú An",
    code: 23626,
    division_type: "xã",
    codename: "xa_tu_an",
    district_code: 623,
  },
  {
    name: "Xã Xuân An",
    code: 23627,
    division_type: "xã",
    codename: "xa_xuan_an",
    district_code: 623,
  },
  {
    name: "Xã Cửu An",
    code: 23629,
    division_type: "xã",
    codename: "xa_cuu_an",
    district_code: 623,
  },
  {
    name: "Phường An Phước",
    code: 23630,
    division_type: "phường",
    codename: "phuong_an_phuoc",
    district_code: 623,
  },
  {
    name: "Xã Song An",
    code: 23632,
    division_type: "xã",
    codename: "xa_song_an",
    district_code: 623,
  },
  {
    name: "Phường Ngô Mây",
    code: 23633,
    division_type: "phường",
    codename: "phuong_ngo_may",
    district_code: 623,
  },
  {
    name: "Xã Thành An",
    code: 23635,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 623,
  },
  {
    name: "Phường Cheo Reo",
    code: 24041,
    division_type: "phường",
    codename: "phuong_cheo_reo",
    district_code: 624,
  },
  {
    name: "Phường Hòa Bình",
    code: 24042,
    division_type: "phường",
    codename: "phuong_hoa_binh",
    district_code: 624,
  },
  {
    name: "Phường Đoàn Kết",
    code: 24044,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 624,
  },
  {
    name: "Phường Sông Bờ",
    code: 24045,
    division_type: "phường",
    codename: "phuong_song_bo",
    district_code: 624,
  },
  {
    name: "Xã Ia RBol",
    code: 24064,
    division_type: "xã",
    codename: "xa_ia_rbol",
    district_code: 624,
  },
  {
    name: "Xã Chư Băh",
    code: 24065,
    division_type: "xã",
    codename: "xa_chu_bah",
    district_code: 624,
  },
  {
    name: "Xã Ia RTô",
    code: 24070,
    division_type: "xã",
    codename: "xa_ia_rto",
    district_code: 624,
  },
  {
    name: "Xã Ia Sao",
    code: 24073,
    division_type: "xã",
    codename: "xa_ia_sao",
    district_code: 624,
  },
  {
    name: "Thị trấn KBang",
    code: 23638,
    division_type: "thị trấn",
    codename: "thi_tran_kbang",
    district_code: 625,
  },
  {
    name: "Xã Kon Pne",
    code: 23641,
    division_type: "xã",
    codename: "xa_kon_pne",
    district_code: 625,
  },
  {
    name: "Xã Đăk Roong",
    code: 23644,
    division_type: "xã",
    codename: "xa_dak_roong",
    district_code: 625,
  },
  {
    name: "Xã Sơn Lang",
    code: 23647,
    division_type: "xã",
    codename: "xa_son_lang",
    district_code: 625,
  },
  {
    name: "Xã KRong",
    code: 23650,
    division_type: "xã",
    codename: "xa_krong",
    district_code: 625,
  },
  {
    name: "Xã Sơ Pai",
    code: 23653,
    division_type: "xã",
    codename: "xa_so_pai",
    district_code: 625,
  },
  {
    name: "Xã Lơ Ku",
    code: 23656,
    division_type: "xã",
    codename: "xa_lo_ku",
    district_code: 625,
  },
  {
    name: "Xã Đông",
    code: 23659,
    division_type: "xã",
    codename: "xa_dong",
    district_code: 625,
  },
  {
    name: "Xã Đak SMar",
    code: 23660,
    division_type: "xã",
    codename: "xa_dak_smar",
    district_code: 625,
  },
  {
    name: "Xã Nghĩa An",
    code: 23662,
    division_type: "xã",
    codename: "xa_nghia_an",
    district_code: 625,
  },
  {
    name: "Xã Tơ Tung",
    code: 23665,
    division_type: "xã",
    codename: "xa_to_tung",
    district_code: 625,
  },
  {
    name: "Xã Kông Lơng Khơng",
    code: 23668,
    division_type: "xã",
    codename: "xa_kong_long_khong",
    district_code: 625,
  },
  {
    name: "Xã Kông Pla",
    code: 23671,
    division_type: "xã",
    codename: "xa_kong_pla",
    district_code: 625,
  },
  {
    name: "Xã Đăk HLơ",
    code: 23674,
    division_type: "xã",
    codename: "xa_dak_hlo",
    district_code: 625,
  },
  {
    name: "Thị trấn Đăk Đoa",
    code: 23677,
    division_type: "thị trấn",
    codename: "thi_tran_dak_doa",
    district_code: 626,
  },
  {
    name: "Xã Hà Đông",
    code: 23680,
    division_type: "xã",
    codename: "xa_ha_dong",
    district_code: 626,
  },
  {
    name: "Xã Đăk Sơmei",
    code: 23683,
    division_type: "xã",
    codename: "xa_dak_somei",
    district_code: 626,
  },
  {
    name: "Xã Đăk Krong",
    code: 23684,
    division_type: "xã",
    codename: "xa_dak_krong",
    district_code: 626,
  },
  {
    name: "Xã Hải Yang",
    code: 23686,
    division_type: "xã",
    codename: "xa_hai_yang",
    district_code: 626,
  },
  {
    name: "Xã Kon Gang",
    code: 23689,
    division_type: "xã",
    codename: "xa_kon_gang",
    district_code: 626,
  },
  {
    name: "Xã Hà Bầu",
    code: 23692,
    division_type: "xã",
    codename: "xa_ha_bau",
    district_code: 626,
  },
  {
    name: "Xã Nam Yang",
    code: 23695,
    division_type: "xã",
    codename: "xa_nam_yang",
    district_code: 626,
  },
  {
    name: "Xã K' Dang",
    code: 23698,
    division_type: "xã",
    codename: "xa_k_dang",
    district_code: 626,
  },
  {
    name: "Xã H' Neng",
    code: 23701,
    division_type: "xã",
    codename: "xa_h_neng",
    district_code: 626,
  },
  {
    name: "Xã Tân Bình",
    code: 23704,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 626,
  },
  {
    name: "Xã Glar",
    code: 23707,
    division_type: "xã",
    codename: "xa_glar",
    district_code: 626,
  },
  {
    name: "Xã A Dơk",
    code: 23710,
    division_type: "xã",
    codename: "xa_a_dok",
    district_code: 626,
  },
  {
    name: "Xã Trang",
    code: 23713,
    division_type: "xã",
    codename: "xa_trang",
    district_code: 626,
  },
  {
    name: "Xã HNol",
    code: 23714,
    division_type: "xã",
    codename: "xa_hnol",
    district_code: 626,
  },
  {
    name: "Xã Ia Pết",
    code: 23716,
    division_type: "xã",
    codename: "xa_ia_pet",
    district_code: 626,
  },
  {
    name: "Xã Ia Băng",
    code: 23719,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 626,
  },
  {
    name: "Thị trấn Phú Hòa",
    code: 23722,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 627,
  },
  {
    name: "Xã Hà Tây",
    code: 23725,
    division_type: "xã",
    codename: "xa_ha_tay",
    district_code: 627,
  },
  {
    name: "Xã Ia Khươl",
    code: 23728,
    division_type: "xã",
    codename: "xa_ia_khuol",
    district_code: 627,
  },
  {
    name: "Xã Ia Phí",
    code: 23731,
    division_type: "xã",
    codename: "xa_ia_phi",
    district_code: 627,
  },
  {
    name: "Thị trấn Ia Ly",
    code: 23734,
    division_type: "thị trấn",
    codename: "thi_tran_ia_ly",
    district_code: 627,
  },
  {
    name: "Xã Ia Mơ Nông",
    code: 23737,
    division_type: "xã",
    codename: "xa_ia_mo_nong",
    district_code: 627,
  },
  {
    name: "Xã Ia Kreng",
    code: 23738,
    division_type: "xã",
    codename: "xa_ia_kreng",
    district_code: 627,
  },
  {
    name: "Xã Đăk Tơ Ver",
    code: 23740,
    division_type: "xã",
    codename: "xa_dak_to_ver",
    district_code: 627,
  },
  {
    name: "Xã Hòa Phú",
    code: 23743,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 627,
  },
  {
    name: "Xã Chư Đăng Ya",
    code: 23746,
    division_type: "xã",
    codename: "xa_chu_dang_ya",
    district_code: 627,
  },
  {
    name: "Xã Ia Ka",
    code: 23749,
    division_type: "xã",
    codename: "xa_ia_ka",
    district_code: 627,
  },
  {
    name: "Xã Ia Nhin",
    code: 23752,
    division_type: "xã",
    codename: "xa_ia_nhin",
    district_code: 627,
  },
  {
    name: "Xã Nghĩa Hòa",
    code: 23755,
    division_type: "xã",
    codename: "xa_nghia_hoa",
    district_code: 627,
  },
  {
    name: "Xã Nghĩa Hưng",
    code: 23761,
    division_type: "xã",
    codename: "xa_nghia_hung",
    district_code: 627,
  },
  {
    name: "Thị trấn Ia Kha",
    code: 23764,
    division_type: "thị trấn",
    codename: "thi_tran_ia_kha",
    district_code: 628,
  },
  {
    name: "Xã Ia Sao",
    code: 23767,
    division_type: "xã",
    codename: "xa_ia_sao",
    district_code: 628,
  },
  {
    name: "Xã Ia Yok",
    code: 23768,
    division_type: "xã",
    codename: "xa_ia_yok",
    district_code: 628,
  },
  {
    name: "Xã Ia Hrung",
    code: 23770,
    division_type: "xã",
    codename: "xa_ia_hrung",
    district_code: 628,
  },
  {
    name: "Xã Ia Bă",
    code: 23771,
    division_type: "xã",
    codename: "xa_ia_ba",
    district_code: 628,
  },
  {
    name: "Xã Ia Khai",
    code: 23773,
    division_type: "xã",
    codename: "xa_ia_khai",
    district_code: 628,
  },
  {
    name: "Xã Ia KRai",
    code: 23776,
    division_type: "xã",
    codename: "xa_ia_krai",
    district_code: 628,
  },
  {
    name: "Xã Ia Grăng",
    code: 23778,
    division_type: "xã",
    codename: "xa_ia_grang",
    district_code: 628,
  },
  {
    name: "Xã Ia Tô",
    code: 23779,
    division_type: "xã",
    codename: "xa_ia_to",
    district_code: 628,
  },
  {
    name: "Xã Ia O",
    code: 23782,
    division_type: "xã",
    codename: "xa_ia_o",
    district_code: 628,
  },
  {
    name: "Xã Ia Dêr",
    code: 23785,
    division_type: "xã",
    codename: "xa_ia_der",
    district_code: 628,
  },
  {
    name: "Xã Ia Chia",
    code: 23788,
    division_type: "xã",
    codename: "xa_ia_chia",
    district_code: 628,
  },
  {
    name: "Xã Ia Pếch",
    code: 23791,
    division_type: "xã",
    codename: "xa_ia_pech",
    district_code: 628,
  },
  {
    name: "Thị trấn Kon Dơng",
    code: 23794,
    division_type: "thị trấn",
    codename: "thi_tran_kon_dong",
    district_code: 629,
  },
  {
    name: "Xã Ayun",
    code: 23797,
    division_type: "xã",
    codename: "xa_ayun",
    district_code: 629,
  },
  {
    name: "Xã Đak Jơ Ta",
    code: 23798,
    division_type: "xã",
    codename: "xa_dak_jo_ta",
    district_code: 629,
  },
  {
    name: "Xã Đak Ta Ley",
    code: 23799,
    division_type: "xã",
    codename: "xa_dak_ta_ley",
    district_code: 629,
  },
  {
    name: "Xã Hra",
    code: 23800,
    division_type: "xã",
    codename: "xa_hra",
    district_code: 629,
  },
  {
    name: "Xã Đăk Yă",
    code: 23803,
    division_type: "xã",
    codename: "xa_dak_ya",
    district_code: 629,
  },
  {
    name: "Xã Đăk Djrăng",
    code: 23806,
    division_type: "xã",
    codename: "xa_dak_djrang",
    district_code: 629,
  },
  {
    name: "Xã Lơ Pang",
    code: 23809,
    division_type: "xã",
    codename: "xa_lo_pang",
    district_code: 629,
  },
  {
    name: "Xã Kon Thụp",
    code: 23812,
    division_type: "xã",
    codename: "xa_kon_thup",
    district_code: 629,
  },
  {
    name: "Xã Đê Ar",
    code: 23815,
    division_type: "xã",
    codename: "xa_de_ar",
    district_code: 629,
  },
  {
    name: "Xã Kon Chiêng",
    code: 23818,
    division_type: "xã",
    codename: "xa_kon_chieng",
    district_code: 629,
  },
  {
    name: "Xã Đăk Trôi",
    code: 23821,
    division_type: "xã",
    codename: "xa_dak_troi",
    district_code: 629,
  },
  {
    name: "Thị trấn Kông Chro",
    code: 23824,
    division_type: "thị trấn",
    codename: "thi_tran_kong_chro",
    district_code: 630,
  },
  {
    name: "Xã Chư Krêy",
    code: 23827,
    division_type: "xã",
    codename: "xa_chu_krey",
    district_code: 630,
  },
  {
    name: "Xã An Trung",
    code: 23830,
    division_type: "xã",
    codename: "xa_an_trung",
    district_code: 630,
  },
  {
    name: "Xã Kông Yang",
    code: 23833,
    division_type: "xã",
    codename: "xa_kong_yang",
    district_code: 630,
  },
  {
    name: "Xã Đăk Tơ Pang",
    code: 23836,
    division_type: "xã",
    codename: "xa_dak_to_pang",
    district_code: 630,
  },
  {
    name: "Xã SRó",
    code: 23839,
    division_type: "xã",
    codename: "xa_sro",
    district_code: 630,
  },
  {
    name: "Xã Đắk Kơ Ning",
    code: 23840,
    division_type: "xã",
    codename: "xa_dak_ko_ning",
    district_code: 630,
  },
  {
    name: "Xã Đăk Song",
    code: 23842,
    division_type: "xã",
    codename: "xa_dak_song",
    district_code: 630,
  },
  {
    name: "Xã Đăk Pling",
    code: 23843,
    division_type: "xã",
    codename: "xa_dak_pling",
    district_code: 630,
  },
  {
    name: "Xã Yang Trung",
    code: 23845,
    division_type: "xã",
    codename: "xa_yang_trung",
    district_code: 630,
  },
  {
    name: "Xã Đăk Pơ Pho",
    code: 23846,
    division_type: "xã",
    codename: "xa_dak_po_pho",
    district_code: 630,
  },
  {
    name: "Xã Ya Ma",
    code: 23848,
    division_type: "xã",
    codename: "xa_ya_ma",
    district_code: 630,
  },
  {
    name: "Xã Chơ Long",
    code: 23851,
    division_type: "xã",
    codename: "xa_cho_long",
    district_code: 630,
  },
  {
    name: "Xã Yang Nam",
    code: 23854,
    division_type: "xã",
    codename: "xa_yang_nam",
    district_code: 630,
  },
  {
    name: "Thị trấn Chư Ty",
    code: 23857,
    division_type: "thị trấn",
    codename: "thi_tran_chu_ty",
    district_code: 631,
  },
  {
    name: "Xã Ia Dơk",
    code: 23860,
    division_type: "xã",
    codename: "xa_ia_dok",
    district_code: 631,
  },
  {
    name: "Xã Ia Krêl",
    code: 23863,
    division_type: "xã",
    codename: "xa_ia_krel",
    district_code: 631,
  },
  {
    name: "Xã Ia Din",
    code: 23866,
    division_type: "xã",
    codename: "xa_ia_din",
    district_code: 631,
  },
  {
    name: "Xã Ia Kla",
    code: 23869,
    division_type: "xã",
    codename: "xa_ia_kla",
    district_code: 631,
  },
  {
    name: "Xã Ia Dom",
    code: 23872,
    division_type: "xã",
    codename: "xa_ia_dom",
    district_code: 631,
  },
  {
    name: "Xã Ia Lang",
    code: 23875,
    division_type: "xã",
    codename: "xa_ia_lang",
    district_code: 631,
  },
  {
    name: "Xã Ia Kriêng",
    code: 23878,
    division_type: "xã",
    codename: "xa_ia_krieng",
    district_code: 631,
  },
  {
    name: "Xã Ia Pnôn",
    code: 23881,
    division_type: "xã",
    codename: "xa_ia_pnon",
    district_code: 631,
  },
  {
    name: "Xã Ia Nan",
    code: 23884,
    division_type: "xã",
    codename: "xa_ia_nan",
    district_code: 631,
  },
  {
    name: "Thị trấn Chư Prông",
    code: 23887,
    division_type: "thị trấn",
    codename: "thi_tran_chu_prong",
    district_code: 632,
  },
  {
    name: "Xã Ia Kly",
    code: 23888,
    division_type: "xã",
    codename: "xa_ia_kly",
    district_code: 632,
  },
  {
    name: "Xã Bình Giáo",
    code: 23890,
    division_type: "xã",
    codename: "xa_binh_giao",
    district_code: 632,
  },
  {
    name: "Xã Ia Drăng",
    code: 23893,
    division_type: "xã",
    codename: "xa_ia_drang",
    district_code: 632,
  },
  {
    name: "Xã Thăng Hưng",
    code: 23896,
    division_type: "xã",
    codename: "xa_thang_hung",
    district_code: 632,
  },
  {
    name: "Xã Bàu Cạn",
    code: 23899,
    division_type: "xã",
    codename: "xa_bau_can",
    district_code: 632,
  },
  {
    name: "Xã Ia Phìn",
    code: 23902,
    division_type: "xã",
    codename: "xa_ia_phin",
    district_code: 632,
  },
  {
    name: "Xã Ia Băng",
    code: 23905,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 632,
  },
  {
    name: "Xã Ia Tôr",
    code: 23908,
    division_type: "xã",
    codename: "xa_ia_tor",
    district_code: 632,
  },
  {
    name: "Xã Ia Boòng",
    code: 23911,
    division_type: "xã",
    codename: "xa_ia_boong",
    district_code: 632,
  },
  {
    name: "Xã Ia O",
    code: 23914,
    division_type: "xã",
    codename: "xa_ia_o",
    district_code: 632,
  },
  {
    name: "Xã Ia Púch",
    code: 23917,
    division_type: "xã",
    codename: "xa_ia_puch",
    district_code: 632,
  },
  {
    name: "Xã Ia Me",
    code: 23920,
    division_type: "xã",
    codename: "xa_ia_me",
    district_code: 632,
  },
  {
    name: "Xã Ia Vê",
    code: 23923,
    division_type: "xã",
    codename: "xa_ia_ve",
    district_code: 632,
  },
  {
    name: "Xã Ia Bang",
    code: 23924,
    division_type: "xã",
    codename: "xa_ia_bang",
    district_code: 632,
  },
  {
    name: "Xã Ia Pia",
    code: 23926,
    division_type: "xã",
    codename: "xa_ia_pia",
    district_code: 632,
  },
  {
    name: "Xã Ia Ga",
    code: 23929,
    division_type: "xã",
    codename: "xa_ia_ga",
    district_code: 632,
  },
  {
    name: "Xã Ia Lâu",
    code: 23932,
    division_type: "xã",
    codename: "xa_ia_lau",
    district_code: 632,
  },
  {
    name: "Xã Ia Piơr",
    code: 23935,
    division_type: "xã",
    codename: "xa_ia_pior",
    district_code: 632,
  },
  {
    name: "Xã Ia Mơ",
    code: 23938,
    division_type: "xã",
    codename: "xa_ia_mo",
    district_code: 632,
  },
  {
    name: "Thị trấn Chư Sê",
    code: 23941,
    division_type: "thị trấn",
    codename: "thi_tran_chu_se",
    district_code: 633,
  },
  {
    name: "Xã Ia Tiêm",
    code: 23944,
    division_type: "xã",
    codename: "xa_ia_tiem",
    district_code: 633,
  },
  {
    name: "Xã Chư Pơng",
    code: 23945,
    division_type: "xã",
    codename: "xa_chu_pong",
    district_code: 633,
  },
  {
    name: "Xã Bar Măih",
    code: 23946,
    division_type: "xã",
    codename: "xa_bar_maih",
    district_code: 633,
  },
  {
    name: "Xã Bờ Ngoong",
    code: 23947,
    division_type: "xã",
    codename: "xa_bo_ngoong",
    district_code: 633,
  },
  {
    name: "Xã Ia Glai",
    code: 23950,
    division_type: "xã",
    codename: "xa_ia_glai",
    district_code: 633,
  },
  {
    name: "Xã AL Bá",
    code: 23953,
    division_type: "xã",
    codename: "xa_al_ba",
    district_code: 633,
  },
  {
    name: "Xã Kông HTok",
    code: 23954,
    division_type: "xã",
    codename: "xa_kong_htok",
    district_code: 633,
  },
  {
    name: "Xã AYun",
    code: 23956,
    division_type: "xã",
    codename: "xa_ayun",
    district_code: 633,
  },
  {
    name: "Xã Ia HLốp",
    code: 23959,
    division_type: "xã",
    codename: "xa_ia_hlop",
    district_code: 633,
  },
  {
    name: "Xã Ia Blang",
    code: 23962,
    division_type: "xã",
    codename: "xa_ia_blang",
    district_code: 633,
  },
  {
    name: "Xã Dun",
    code: 23965,
    division_type: "xã",
    codename: "xa_dun",
    district_code: 633,
  },
  {
    name: "Xã Ia Pal",
    code: 23966,
    division_type: "xã",
    codename: "xa_ia_pal",
    district_code: 633,
  },
  {
    name: "Xã H Bông",
    code: 23968,
    division_type: "xã",
    codename: "xa_h_bong",
    district_code: 633,
  },
  {
    name: "Xã Ia Ko",
    code: 23977,
    division_type: "xã",
    codename: "xa_ia_ko",
    district_code: 633,
  },
  {
    name: "Xã Hà Tam",
    code: 23989,
    division_type: "xã",
    codename: "xa_ha_tam",
    district_code: 634,
  },
  {
    name: "Xã An Thành",
    code: 23992,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 634,
  },
  {
    name: "Thị trấn Đak Pơ",
    code: 23995,
    division_type: "thị trấn",
    codename: "thi_tran_dak_po",
    district_code: 634,
  },
  {
    name: "Xã Yang Bắc",
    code: 23998,
    division_type: "xã",
    codename: "xa_yang_bac",
    district_code: 634,
  },
  {
    name: "Xã Cư An",
    code: 24001,
    division_type: "xã",
    codename: "xa_cu_an",
    district_code: 634,
  },
  {
    name: "Xã Tân An",
    code: 24004,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 634,
  },
  {
    name: "Xã Phú An",
    code: 24007,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 634,
  },
  {
    name: "Xã Ya Hội",
    code: 24010,
    division_type: "xã",
    codename: "xa_ya_hoi",
    district_code: 634,
  },
  {
    name: "Xã Pờ Tó",
    code: 24013,
    division_type: "xã",
    codename: "xa_po_to",
    district_code: 635,
  },
  {
    name: "Xã Chư Răng",
    code: 24016,
    division_type: "xã",
    codename: "xa_chu_rang",
    district_code: 635,
  },
  {
    name: "Xã Ia KDăm",
    code: 24019,
    division_type: "xã",
    codename: "xa_ia_kdam",
    district_code: 635,
  },
  {
    name: "Xã Kim Tân",
    code: 24022,
    division_type: "xã",
    codename: "xa_kim_tan",
    district_code: 635,
  },
  {
    name: "Xã Chư Mố",
    code: 24025,
    division_type: "xã",
    codename: "xa_chu_mo",
    district_code: 635,
  },
  {
    name: "Xã Ia Tul",
    code: 24028,
    division_type: "xã",
    codename: "xa_ia_tul",
    district_code: 635,
  },
  {
    name: "Xã Ia Ma Rơn",
    code: 24031,
    division_type: "xã",
    codename: "xa_ia_ma_ron",
    district_code: 635,
  },
  {
    name: "Xã Ia Broăi",
    code: 24034,
    division_type: "xã",
    codename: "xa_ia_broai",
    district_code: 635,
  },
  {
    name: "Xã Ia Trok",
    code: 24037,
    division_type: "xã",
    codename: "xa_ia_trok",
    district_code: 635,
  },
  {
    name: "Thị trấn Phú Túc",
    code: 24076,
    division_type: "thị trấn",
    codename: "thi_tran_phu_tuc",
    district_code: 637,
  },
  {
    name: "Xã Ia RSai",
    code: 24079,
    division_type: "xã",
    codename: "xa_ia_rsai",
    district_code: 637,
  },
  {
    name: "Xã Ia RSươm",
    code: 24082,
    division_type: "xã",
    codename: "xa_ia_rsuom",
    district_code: 637,
  },
  {
    name: "Xã Chư Gu",
    code: 24085,
    division_type: "xã",
    codename: "xa_chu_gu",
    district_code: 637,
  },
  {
    name: "Xã Đất Bằng",
    code: 24088,
    division_type: "xã",
    codename: "xa_dat_bang",
    district_code: 637,
  },
  {
    name: "Xã Ia Mláh",
    code: 24091,
    division_type: "xã",
    codename: "xa_ia_mlah",
    district_code: 637,
  },
  {
    name: "Xã Chư Drăng",
    code: 24094,
    division_type: "xã",
    codename: "xa_chu_drang",
    district_code: 637,
  },
  {
    name: "Xã Phú Cần",
    code: 24097,
    division_type: "xã",
    codename: "xa_phu_can",
    district_code: 637,
  },
  {
    name: "Xã Ia HDreh",
    code: 24100,
    division_type: "xã",
    codename: "xa_ia_hdreh",
    district_code: 637,
  },
  {
    name: "Xã Ia RMok",
    code: 24103,
    division_type: "xã",
    codename: "xa_ia_rmok",
    district_code: 637,
  },
  {
    name: "Xã Chư Ngọc",
    code: 24106,
    division_type: "xã",
    codename: "xa_chu_ngoc",
    district_code: 637,
  },
  {
    name: "Xã Uar",
    code: 24109,
    division_type: "xã",
    codename: "xa_uar",
    district_code: 637,
  },
  {
    name: "Xã Chư Rcăm",
    code: 24112,
    division_type: "xã",
    codename: "xa_chu_rcam",
    district_code: 637,
  },
  {
    name: "Xã Krông Năng",
    code: 24115,
    division_type: "xã",
    codename: "xa_krong_nang",
    district_code: 637,
  },
  {
    name: "Thị trấn Phú Thiện",
    code: 24043,
    division_type: "thị trấn",
    codename: "thi_tran_phu_thien",
    district_code: 638,
  },
  {
    name: "Xã Chư A Thai",
    code: 24046,
    division_type: "xã",
    codename: "xa_chu_a_thai",
    district_code: 638,
  },
  {
    name: "Xã Ayun Hạ",
    code: 24048,
    division_type: "xã",
    codename: "xa_ayun_ha",
    district_code: 638,
  },
  {
    name: "Xã Ia Ake",
    code: 24049,
    division_type: "xã",
    codename: "xa_ia_ake",
    district_code: 638,
  },
  {
    name: "Xã Ia Sol",
    code: 24052,
    division_type: "xã",
    codename: "xa_ia_sol",
    district_code: 638,
  },
  {
    name: "Xã Ia Piar",
    code: 24055,
    division_type: "xã",
    codename: "xa_ia_piar",
    district_code: 638,
  },
  {
    name: "Xã Ia Peng",
    code: 24058,
    division_type: "xã",
    codename: "xa_ia_peng",
    district_code: 638,
  },
  {
    name: "Xã Chrôh Pơnan",
    code: 24060,
    division_type: "xã",
    codename: "xa_chroh_ponan",
    district_code: 638,
  },
  {
    name: "Xã Ia Hiao",
    code: 24061,
    division_type: "xã",
    codename: "xa_ia_hiao",
    district_code: 638,
  },
  {
    name: "Xã Ia Yeng",
    code: 24067,
    division_type: "xã",
    codename: "xa_ia_yeng",
    district_code: 638,
  },
  {
    name: "Thị trấn Nhơn Hoà",
    code: 23942,
    division_type: "thị trấn",
    codename: "thi_tran_nhon_hoa",
    district_code: 639,
  },
  {
    name: "Xã Ia Hrú",
    code: 23971,
    division_type: "xã",
    codename: "xa_ia_hru",
    district_code: 639,
  },
  {
    name: "Xã Ia Rong",
    code: 23972,
    division_type: "xã",
    codename: "xa_ia_rong",
    district_code: 639,
  },
  {
    name: "Xã Ia Dreng",
    code: 23974,
    division_type: "xã",
    codename: "xa_ia_dreng",
    district_code: 639,
  },
  {
    name: "Xã Ia Hla",
    code: 23978,
    division_type: "xã",
    codename: "xa_ia_hla",
    district_code: 639,
  },
  {
    name: "Xã Chư Don",
    code: 23980,
    division_type: "xã",
    codename: "xa_chu_don",
    district_code: 639,
  },
  {
    name: "Xã Ia Phang",
    code: 23983,
    division_type: "xã",
    codename: "xa_ia_phang",
    district_code: 639,
  },
  {
    name: "Xã Ia Le",
    code: 23986,
    division_type: "xã",
    codename: "xa_ia_le",
    district_code: 639,
  },
  {
    name: "Xã Ia BLứ",
    code: 23987,
    division_type: "xã",
    codename: "xa_ia_blu",
    district_code: 639,
  },
  {
    name: "Phường Tân Lập",
    code: 24118,
    division_type: "phường",
    codename: "phuong_tan_lap",
    district_code: 643,
  },
  {
    name: "Phường Tân Hòa",
    code: 24121,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 643,
  },
  {
    name: "Phường Tân An",
    code: 24124,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 643,
  },
  {
    name: "Phường Thống Nhất",
    code: 24127,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 643,
  },
  {
    name: "Phường Thành Nhất",
    code: 24130,
    division_type: "phường",
    codename: "phuong_thanh_nhat",
    district_code: 643,
  },
  {
    name: "Phường Thắng Lợi",
    code: 24133,
    division_type: "phường",
    codename: "phuong_thang_loi",
    district_code: 643,
  },
  {
    name: "Phường Tân Lợi",
    code: 24136,
    division_type: "phường",
    codename: "phuong_tan_loi",
    district_code: 643,
  },
  {
    name: "Phường Thành Công",
    code: 24139,
    division_type: "phường",
    codename: "phuong_thanh_cong",
    district_code: 643,
  },
  {
    name: "Phường Tân Thành",
    code: 24142,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 643,
  },
  {
    name: "Phường Tân Tiến",
    code: 24145,
    division_type: "phường",
    codename: "phuong_tan_tien",
    district_code: 643,
  },
  {
    name: "Phường Tự An",
    code: 24148,
    division_type: "phường",
    codename: "phuong_tu_an",
    district_code: 643,
  },
  {
    name: "Phường Ea Tam",
    code: 24151,
    division_type: "phường",
    codename: "phuong_ea_tam",
    district_code: 643,
  },
  {
    name: "Phường Khánh Xuân",
    code: 24154,
    division_type: "phường",
    codename: "phuong_khanh_xuan",
    district_code: 643,
  },
  {
    name: "Xã Hòa Thuận",
    code: 24157,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 643,
  },
  {
    name: "Xã Cư ÊBur",
    code: 24160,
    division_type: "xã",
    codename: "xa_cu_ebur",
    district_code: 643,
  },
  {
    name: "Xã Ea Tu",
    code: 24163,
    division_type: "xã",
    codename: "xa_ea_tu",
    district_code: 643,
  },
  {
    name: "Xã Hòa Thắng",
    code: 24166,
    division_type: "xã",
    codename: "xa_hoa_thang",
    district_code: 643,
  },
  {
    name: "Xã Ea Kao",
    code: 24169,
    division_type: "xã",
    codename: "xa_ea_kao",
    district_code: 643,
  },
  {
    name: "Xã Hòa Phú",
    code: 24172,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 643,
  },
  {
    name: "Xã Hòa Khánh",
    code: 24175,
    division_type: "xã",
    codename: "xa_hoa_khanh",
    district_code: 643,
  },
  {
    name: "Xã Hòa Xuân",
    code: 24178,
    division_type: "xã",
    codename: "xa_hoa_xuan",
    district_code: 643,
  },
  {
    name: "Phường An Lạc",
    code: 24305,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 644,
  },
  {
    name: "Phường An Bình",
    code: 24308,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 644,
  },
  {
    name: "Phường Thiện An",
    code: 24311,
    division_type: "phường",
    codename: "phuong_thien_an",
    district_code: 644,
  },
  {
    name: "Phường Đạt Hiếu",
    code: 24318,
    division_type: "phường",
    codename: "phuong_dat_hieu",
    district_code: 644,
  },
  {
    name: "Phường Đoàn Kết",
    code: 24322,
    division_type: "phường",
    codename: "phuong_doan_ket",
    district_code: 644,
  },
  {
    name: "Xã Ea Blang",
    code: 24325,
    division_type: "xã",
    codename: "xa_ea_blang",
    district_code: 644,
  },
  {
    name: "Xã Ea Drông",
    code: 24328,
    division_type: "xã",
    codename: "xa_ea_drong",
    district_code: 644,
  },
  {
    name: "Phường Thống Nhất",
    code: 24331,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 644,
  },
  {
    name: "Phường Bình Tân",
    code: 24332,
    division_type: "phường",
    codename: "phuong_binh_tan",
    district_code: 644,
  },
  {
    name: "Xã Ea Siên",
    code: 24334,
    division_type: "xã",
    codename: "xa_ea_sien",
    district_code: 644,
  },
  {
    name: "Xã Bình Thuận",
    code: 24337,
    division_type: "xã",
    codename: "xa_binh_thuan",
    district_code: 644,
  },
  {
    name: "Xã Cư Bao",
    code: 24340,
    division_type: "xã",
    codename: "xa_cu_bao",
    district_code: 644,
  },
  {
    name: "Thị trấn Ea Drăng",
    code: 24181,
    division_type: "thị trấn",
    codename: "thi_tran_ea_drang",
    district_code: 645,
  },
  {
    name: "Xã Ea H'leo",
    code: 24184,
    division_type: "xã",
    codename: "xa_ea_hleo",
    district_code: 645,
  },
  {
    name: "Xã Ea Sol",
    code: 24187,
    division_type: "xã",
    codename: "xa_ea_sol",
    district_code: 645,
  },
  {
    name: "Xã Ea Ral",
    code: 24190,
    division_type: "xã",
    codename: "xa_ea_ral",
    district_code: 645,
  },
  {
    name: "Xã Ea Wy",
    code: 24193,
    division_type: "xã",
    codename: "xa_ea_wy",
    district_code: 645,
  },
  {
    name: "Xã Cư A Mung",
    code: 24194,
    division_type: "xã",
    codename: "xa_cu_a_mung",
    district_code: 645,
  },
  {
    name: "Xã Cư Mốt",
    code: 24196,
    division_type: "xã",
    codename: "xa_cu_mot",
    district_code: 645,
  },
  {
    name: "Xã Ea Hiao",
    code: 24199,
    division_type: "xã",
    codename: "xa_ea_hiao",
    district_code: 645,
  },
  {
    name: "Xã Ea Khal",
    code: 24202,
    division_type: "xã",
    codename: "xa_ea_khal",
    district_code: 645,
  },
  {
    name: "Xã Dliê Yang",
    code: 24205,
    division_type: "xã",
    codename: "xa_dlie_yang",
    district_code: 645,
  },
  {
    name: "Xã Ea Tir",
    code: 24207,
    division_type: "xã",
    codename: "xa_ea_tir",
    district_code: 645,
  },
  {
    name: "Xã Ea Nam",
    code: 24208,
    division_type: "xã",
    codename: "xa_ea_nam",
    district_code: 645,
  },
  {
    name: "Thị trấn Ea Súp",
    code: 24211,
    division_type: "thị trấn",
    codename: "thi_tran_ea_sup",
    district_code: 646,
  },
  {
    name: "Xã Ia Lốp",
    code: 24214,
    division_type: "xã",
    codename: "xa_ia_lop",
    district_code: 646,
  },
  {
    name: "Xã Ia JLơi",
    code: 24215,
    division_type: "xã",
    codename: "xa_ia_jloi",
    district_code: 646,
  },
  {
    name: "Xã Ea Rốk",
    code: 24217,
    division_type: "xã",
    codename: "xa_ea_rok",
    district_code: 646,
  },
  {
    name: "Xã Ya Tờ Mốt",
    code: 24220,
    division_type: "xã",
    codename: "xa_ya_to_mot",
    district_code: 646,
  },
  {
    name: "Xã Ia RVê",
    code: 24221,
    division_type: "xã",
    codename: "xa_ia_rve",
    district_code: 646,
  },
  {
    name: "Xã Ea Lê",
    code: 24223,
    division_type: "xã",
    codename: "xa_ea_le",
    district_code: 646,
  },
  {
    name: "Xã Cư KBang",
    code: 24226,
    division_type: "xã",
    codename: "xa_cu_kbang",
    district_code: 646,
  },
  {
    name: "Xã Ea Bung",
    code: 24229,
    division_type: "xã",
    codename: "xa_ea_bung",
    district_code: 646,
  },
  {
    name: "Xã Cư M'Lan",
    code: 24232,
    division_type: "xã",
    codename: "xa_cu_mlan",
    district_code: 646,
  },
  {
    name: "Xã Krông Na",
    code: 24235,
    division_type: "xã",
    codename: "xa_krong_na",
    district_code: 647,
  },
  {
    name: "Xã Ea Huar",
    code: 24238,
    division_type: "xã",
    codename: "xa_ea_huar",
    district_code: 647,
  },
  {
    name: "Xã Ea Wer",
    code: 24241,
    division_type: "xã",
    codename: "xa_ea_wer",
    district_code: 647,
  },
  {
    name: "Xã Tân Hoà",
    code: 24244,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 647,
  },
  {
    name: "Xã Cuôr KNia",
    code: 24247,
    division_type: "xã",
    codename: "xa_cuor_knia",
    district_code: 647,
  },
  {
    name: "Xã Ea Bar",
    code: 24250,
    division_type: "xã",
    codename: "xa_ea_bar",
    district_code: 647,
  },
  {
    name: "Xã Ea Nuôl",
    code: 24253,
    division_type: "xã",
    codename: "xa_ea_nuol",
    district_code: 647,
  },
  {
    name: "Thị trấn Ea Pốk",
    code: 24256,
    division_type: "thị trấn",
    codename: "thi_tran_ea_pok",
    district_code: 648,
  },
  {
    name: "Thị trấn Quảng Phú",
    code: 24259,
    division_type: "thị trấn",
    codename: "thi_tran_quang_phu",
    district_code: 648,
  },
  {
    name: "Xã Quảng Tiến",
    code: 24262,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 648,
  },
  {
    name: "Xã Ea Kuêh",
    code: 24264,
    division_type: "xã",
    codename: "xa_ea_kueh",
    district_code: 648,
  },
  {
    name: "Xã Ea Kiết",
    code: 24265,
    division_type: "xã",
    codename: "xa_ea_kiet",
    district_code: 648,
  },
  {
    name: "Xã Ea Tar",
    code: 24268,
    division_type: "xã",
    codename: "xa_ea_tar",
    district_code: 648,
  },
  {
    name: "Xã Cư Dliê M'nông",
    code: 24271,
    division_type: "xã",
    codename: "xa_cu_dlie_mnong",
    district_code: 648,
  },
  {
    name: "Xã Ea H'đinh",
    code: 24274,
    division_type: "xã",
    codename: "xa_ea_hdinh",
    district_code: 648,
  },
  {
    name: "Xã Ea Tul",
    code: 24277,
    division_type: "xã",
    codename: "xa_ea_tul",
    district_code: 648,
  },
  {
    name: "Xã Ea KPam",
    code: 24280,
    division_type: "xã",
    codename: "xa_ea_kpam",
    district_code: 648,
  },
  {
    name: "Xã Ea M'DRóh",
    code: 24283,
    division_type: "xã",
    codename: "xa_ea_mdroh",
    district_code: 648,
  },
  {
    name: "Xã Quảng Hiệp",
    code: 24286,
    division_type: "xã",
    codename: "xa_quang_hiep",
    district_code: 648,
  },
  {
    name: "Xã Cư M'gar",
    code: 24289,
    division_type: "xã",
    codename: "xa_cu_mgar",
    district_code: 648,
  },
  {
    name: "Xã Ea D'Rơng",
    code: 24292,
    division_type: "xã",
    codename: "xa_ea_drong",
    district_code: 648,
  },
  {
    name: "Xã Ea M'nang",
    code: 24295,
    division_type: "xã",
    codename: "xa_ea_mnang",
    district_code: 648,
  },
  {
    name: "Xã Cư Suê",
    code: 24298,
    division_type: "xã",
    codename: "xa_cu_sue",
    district_code: 648,
  },
  {
    name: "Xã Cuor Đăng",
    code: 24301,
    division_type: "xã",
    codename: "xa_cuor_dang",
    district_code: 648,
  },
  {
    name: "Xã Cư Né",
    code: 24307,
    division_type: "xã",
    codename: "xa_cu_ne",
    district_code: 649,
  },
  {
    name: "Xã Chư KBô",
    code: 24310,
    division_type: "xã",
    codename: "xa_chu_kbo",
    district_code: 649,
  },
  {
    name: "Xã Cư Pơng",
    code: 24313,
    division_type: "xã",
    codename: "xa_cu_pong",
    district_code: 649,
  },
  {
    name: "Xã Ea Sin",
    code: 24314,
    division_type: "xã",
    codename: "xa_ea_sin",
    district_code: 649,
  },
  {
    name: "Xã Pơng Drang",
    code: 24316,
    division_type: "xã",
    codename: "xa_pong_drang",
    district_code: 649,
  },
  {
    name: "Xã Tân Lập",
    code: 24317,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 649,
  },
  {
    name: "Xã Ea Ngai",
    code: 24319,
    division_type: "xã",
    codename: "xa_ea_ngai",
    district_code: 649,
  },
  {
    name: "Thị trấn Krông Năng",
    code: 24343,
    division_type: "thị trấn",
    codename: "thi_tran_krong_nang",
    district_code: 650,
  },
  {
    name: "Xã ĐLiê Ya",
    code: 24346,
    division_type: "xã",
    codename: "xa_dlie_ya",
    district_code: 650,
  },
  {
    name: "Xã Ea Tóh",
    code: 24349,
    division_type: "xã",
    codename: "xa_ea_toh",
    district_code: 650,
  },
  {
    name: "Xã Ea Tam",
    code: 24352,
    division_type: "xã",
    codename: "xa_ea_tam",
    district_code: 650,
  },
  {
    name: "Xã Phú Lộc",
    code: 24355,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 650,
  },
  {
    name: "Xã Tam Giang",
    code: 24358,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 650,
  },
  {
    name: "Xã Ea Puk",
    code: 24359,
    division_type: "xã",
    codename: "xa_ea_puk",
    district_code: 650,
  },
  {
    name: "Xã Ea Dăh",
    code: 24360,
    division_type: "xã",
    codename: "xa_ea_dah",
    district_code: 650,
  },
  {
    name: "Xã Ea Hồ",
    code: 24361,
    division_type: "xã",
    codename: "xa_ea_ho",
    district_code: 650,
  },
  {
    name: "Xã Phú Xuân",
    code: 24364,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 650,
  },
  {
    name: "Xã Cư Klông",
    code: 24367,
    division_type: "xã",
    codename: "xa_cu_klong",
    district_code: 650,
  },
  {
    name: "Xã Ea Tân",
    code: 24370,
    division_type: "xã",
    codename: "xa_ea_tan",
    district_code: 650,
  },
  {
    name: "Thị trấn Ea Kar",
    code: 24373,
    division_type: "thị trấn",
    codename: "thi_tran_ea_kar",
    district_code: 651,
  },
  {
    name: "Thị trấn Ea Knốp",
    code: 24376,
    division_type: "thị trấn",
    codename: "thi_tran_ea_knop",
    district_code: 651,
  },
  {
    name: "Xã Ea Sô",
    code: 24379,
    division_type: "xã",
    codename: "xa_ea_so",
    district_code: 651,
  },
  {
    name: "Xã Ea Sar",
    code: 24380,
    division_type: "xã",
    codename: "xa_ea_sar",
    district_code: 651,
  },
  {
    name: "Xã Xuân Phú",
    code: 24382,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 651,
  },
  {
    name: "Xã Cư Huê",
    code: 24385,
    division_type: "xã",
    codename: "xa_cu_hue",
    district_code: 651,
  },
  {
    name: "Xã Ea Tih",
    code: 24388,
    division_type: "xã",
    codename: "xa_ea_tih",
    district_code: 651,
  },
  {
    name: "Xã Ea Đar",
    code: 24391,
    division_type: "xã",
    codename: "xa_ea_dar",
    district_code: 651,
  },
  {
    name: "Xã Ea Kmút",
    code: 24394,
    division_type: "xã",
    codename: "xa_ea_kmut",
    district_code: 651,
  },
  {
    name: "Xã Cư Ni",
    code: 24397,
    division_type: "xã",
    codename: "xa_cu_ni",
    district_code: 651,
  },
  {
    name: "Xã Ea Păl",
    code: 24400,
    division_type: "xã",
    codename: "xa_ea_pal",
    district_code: 651,
  },
  {
    name: "Xã Cư Prông",
    code: 24401,
    division_type: "xã",
    codename: "xa_cu_prong",
    district_code: 651,
  },
  {
    name: "Xã Ea Ô",
    code: 24403,
    division_type: "xã",
    codename: "xa_ea_o",
    district_code: 651,
  },
  {
    name: "Xã Cư ELang",
    code: 24404,
    division_type: "xã",
    codename: "xa_cu_elang",
    district_code: 651,
  },
  {
    name: "Xã Cư Bông",
    code: 24406,
    division_type: "xã",
    codename: "xa_cu_bong",
    district_code: 651,
  },
  {
    name: "Xã Cư Jang",
    code: 24409,
    division_type: "xã",
    codename: "xa_cu_jang",
    district_code: 651,
  },
  {
    name: "Thị trấn M'Đrắk",
    code: 24412,
    division_type: "thị trấn",
    codename: "thi_tran_mdrak",
    district_code: 652,
  },
  {
    name: "Xã Cư Prao",
    code: 24415,
    division_type: "xã",
    codename: "xa_cu_prao",
    district_code: 652,
  },
  {
    name: "Xã Ea Pil",
    code: 24418,
    division_type: "xã",
    codename: "xa_ea_pil",
    district_code: 652,
  },
  {
    name: "Xã Ea Lai",
    code: 24421,
    division_type: "xã",
    codename: "xa_ea_lai",
    district_code: 652,
  },
  {
    name: "Xã Ea H'MLay",
    code: 24424,
    division_type: "xã",
    codename: "xa_ea_hmlay",
    district_code: 652,
  },
  {
    name: "Xã Krông Jing",
    code: 24427,
    division_type: "xã",
    codename: "xa_krong_jing",
    district_code: 652,
  },
  {
    name: "Xã Ea M' Doal",
    code: 24430,
    division_type: "xã",
    codename: "xa_ea_m_doal",
    district_code: 652,
  },
  {
    name: "Xã Ea Riêng",
    code: 24433,
    division_type: "xã",
    codename: "xa_ea_rieng",
    district_code: 652,
  },
  {
    name: "Xã Cư M'ta",
    code: 24436,
    division_type: "xã",
    codename: "xa_cu_mta",
    district_code: 652,
  },
  {
    name: "Xã Cư K Róa",
    code: 24439,
    division_type: "xã",
    codename: "xa_cu_k_roa",
    district_code: 652,
  },
  {
    name: "Xã Krông Á",
    code: 24442,
    division_type: "xã",
    codename: "xa_krong_a",
    district_code: 652,
  },
  {
    name: "Xã Cư San",
    code: 24444,
    division_type: "xã",
    codename: "xa_cu_san",
    district_code: 652,
  },
  {
    name: "Xã Ea Trang",
    code: 24445,
    division_type: "xã",
    codename: "xa_ea_trang",
    district_code: 652,
  },
  {
    name: "Thị trấn Krông Kmar",
    code: 24448,
    division_type: "thị trấn",
    codename: "thi_tran_krong_kmar",
    district_code: 653,
  },
  {
    name: "Xã Dang Kang",
    code: 24451,
    division_type: "xã",
    codename: "xa_dang_kang",
    district_code: 653,
  },
  {
    name: "Xã Cư KTy",
    code: 24454,
    division_type: "xã",
    codename: "xa_cu_kty",
    district_code: 653,
  },
  {
    name: "Xã Hòa Thành",
    code: 24457,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 653,
  },
  {
    name: "Xã Hòa Tân",
    code: 24460,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 653,
  },
  {
    name: "Xã Hòa Phong",
    code: 24463,
    division_type: "xã",
    codename: "xa_hoa_phong",
    district_code: 653,
  },
  {
    name: "Xã Hòa Lễ",
    code: 24466,
    division_type: "xã",
    codename: "xa_hoa_le",
    district_code: 653,
  },
  {
    name: "Xã Yang Reh",
    code: 24469,
    division_type: "xã",
    codename: "xa_yang_reh",
    district_code: 653,
  },
  {
    name: "Xã Ea Trul",
    code: 24472,
    division_type: "xã",
    codename: "xa_ea_trul",
    district_code: 653,
  },
  {
    name: "Xã Khuê Ngọc Điền",
    code: 24475,
    division_type: "xã",
    codename: "xa_khue_ngoc_dien",
    district_code: 653,
  },
  {
    name: "Xã Cư Pui",
    code: 24478,
    division_type: "xã",
    codename: "xa_cu_pui",
    district_code: 653,
  },
  {
    name: "Xã Hòa Sơn",
    code: 24481,
    division_type: "xã",
    codename: "xa_hoa_son",
    district_code: 653,
  },
  {
    name: "Xã Cư Drăm",
    code: 24484,
    division_type: "xã",
    codename: "xa_cu_dram",
    district_code: 653,
  },
  {
    name: "Xã Yang Mao",
    code: 24487,
    division_type: "xã",
    codename: "xa_yang_mao",
    district_code: 653,
  },
  {
    name: "Thị trấn Phước An",
    code: 24490,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_an",
    district_code: 654,
  },
  {
    name: "Xã KRông Búk",
    code: 24493,
    division_type: "xã",
    codename: "xa_krong_buk",
    district_code: 654,
  },
  {
    name: "Xã Ea Kly",
    code: 24496,
    division_type: "xã",
    codename: "xa_ea_kly",
    district_code: 654,
  },
  {
    name: "Xã Ea Kênh",
    code: 24499,
    division_type: "xã",
    codename: "xa_ea_kenh",
    district_code: 654,
  },
  {
    name: "Xã Ea Phê",
    code: 24502,
    division_type: "xã",
    codename: "xa_ea_phe",
    district_code: 654,
  },
  {
    name: "Xã Ea KNuec",
    code: 24505,
    division_type: "xã",
    codename: "xa_ea_knuec",
    district_code: 654,
  },
  {
    name: "Xã Ea Yông",
    code: 24508,
    division_type: "xã",
    codename: "xa_ea_yong",
    district_code: 654,
  },
  {
    name: "Xã Hòa An",
    code: 24511,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 654,
  },
  {
    name: "Xã Ea Kuăng",
    code: 24514,
    division_type: "xã",
    codename: "xa_ea_kuang",
    district_code: 654,
  },
  {
    name: "Xã Hòa Đông",
    code: 24517,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 654,
  },
  {
    name: "Xã Ea Hiu",
    code: 24520,
    division_type: "xã",
    codename: "xa_ea_hiu",
    district_code: 654,
  },
  {
    name: "Xã Hòa Tiến",
    code: 24523,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 654,
  },
  {
    name: "Xã Tân Tiến",
    code: 24526,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 654,
  },
  {
    name: "Xã Vụ Bổn",
    code: 24529,
    division_type: "xã",
    codename: "xa_vu_bon",
    district_code: 654,
  },
  {
    name: "Xã Ea Uy",
    code: 24532,
    division_type: "xã",
    codename: "xa_ea_uy",
    district_code: 654,
  },
  {
    name: "Xã Ea Yiêng",
    code: 24535,
    division_type: "xã",
    codename: "xa_ea_yieng",
    district_code: 654,
  },
  {
    name: "Thị trấn Buôn Trấp",
    code: 24538,
    division_type: "thị trấn",
    codename: "thi_tran_buon_trap",
    district_code: 655,
  },
  {
    name: "Xã Dray Sáp",
    code: 24556,
    division_type: "xã",
    codename: "xa_dray_sap",
    district_code: 655,
  },
  {
    name: "Xã Ea Na",
    code: 24559,
    division_type: "xã",
    codename: "xa_ea_na",
    district_code: 655,
  },
  {
    name: "Xã Ea Bông",
    code: 24565,
    division_type: "xã",
    codename: "xa_ea_bong",
    district_code: 655,
  },
  {
    name: "Xã Băng A Drênh",
    code: 24568,
    division_type: "xã",
    codename: "xa_bang_a_drenh",
    district_code: 655,
  },
  {
    name: "Xã Dur KMăl",
    code: 24571,
    division_type: "xã",
    codename: "xa_dur_kmal",
    district_code: 655,
  },
  {
    name: "Xã Bình Hòa",
    code: 24574,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 655,
  },
  {
    name: "Xã Quảng Điền",
    code: 24577,
    division_type: "xã",
    codename: "xa_quang_dien",
    district_code: 655,
  },
  {
    name: "Thị trấn Liên Sơn",
    code: 24580,
    division_type: "thị trấn",
    codename: "thi_tran_lien_son",
    district_code: 656,
  },
  {
    name: "Xã Yang Tao",
    code: 24583,
    division_type: "xã",
    codename: "xa_yang_tao",
    district_code: 656,
  },
  {
    name: "Xã Bông Krang",
    code: 24586,
    division_type: "xã",
    codename: "xa_bong_krang",
    district_code: 656,
  },
  {
    name: "Xã Đắk Liêng",
    code: 24589,
    division_type: "xã",
    codename: "xa_dak_lieng",
    district_code: 656,
  },
  {
    name: "Xã Buôn Triết",
    code: 24592,
    division_type: "xã",
    codename: "xa_buon_triet",
    district_code: 656,
  },
  {
    name: "Xã Buôn Tría",
    code: 24595,
    division_type: "xã",
    codename: "xa_buon_tria",
    district_code: 656,
  },
  {
    name: "Xã Đắk Phơi",
    code: 24598,
    division_type: "xã",
    codename: "xa_dak_phoi",
    district_code: 656,
  },
  {
    name: "Xã Đắk Nuê",
    code: 24601,
    division_type: "xã",
    codename: "xa_dak_nue",
    district_code: 656,
  },
  {
    name: "Xã Krông Nô",
    code: 24604,
    division_type: "xã",
    codename: "xa_krong_no",
    district_code: 656,
  },
  {
    name: "Xã Nam Ka",
    code: 24607,
    division_type: "xã",
    codename: "xa_nam_ka",
    district_code: 656,
  },
  {
    name: "Xã Ea R'Bin",
    code: 24610,
    division_type: "xã",
    codename: "xa_ea_rbin",
    district_code: 656,
  },
  {
    name: "Xã Ea Ning",
    code: 24540,
    division_type: "xã",
    codename: "xa_ea_ning",
    district_code: 657,
  },
  {
    name: "Xã Cư Ê Wi",
    code: 24541,
    division_type: "xã",
    codename: "xa_cu_e_wi",
    district_code: 657,
  },
  {
    name: "Xã Ea Ktur",
    code: 24544,
    division_type: "xã",
    codename: "xa_ea_ktur",
    district_code: 657,
  },
  {
    name: "Xã Ea Tiêu",
    code: 24547,
    division_type: "xã",
    codename: "xa_ea_tieu",
    district_code: 657,
  },
  {
    name: "Xã Ea BHốk",
    code: 24550,
    division_type: "xã",
    codename: "xa_ea_bhok",
    district_code: 657,
  },
  {
    name: "Xã Ea Hu",
    code: 24553,
    division_type: "xã",
    codename: "xa_ea_hu",
    district_code: 657,
  },
  {
    name: "Xã Dray Bhăng",
    code: 24561,
    division_type: "xã",
    codename: "xa_dray_bhang",
    district_code: 657,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 24562,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 657,
  },
  {
    name: "Phường Nghĩa Đức",
    code: 24611,
    division_type: "phường",
    codename: "phuong_nghia_duc",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Thành",
    code: 24612,
    division_type: "phường",
    codename: "phuong_nghia_thanh",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Phú",
    code: 24614,
    division_type: "phường",
    codename: "phuong_nghia_phu",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Tân",
    code: 24615,
    division_type: "phường",
    codename: "phuong_nghia_tan",
    district_code: 660,
  },
  {
    name: "Phường Nghĩa Trung",
    code: 24617,
    division_type: "phường",
    codename: "phuong_nghia_trung",
    district_code: 660,
  },
  {
    name: "Xã Đăk R'Moan",
    code: 24618,
    division_type: "xã",
    codename: "xa_dak_rmoan",
    district_code: 660,
  },
  {
    name: "Phường Quảng Thành",
    code: 24619,
    division_type: "phường",
    codename: "phuong_quang_thanh",
    district_code: 660,
  },
  {
    name: "Xã Đắk Nia",
    code: 24628,
    division_type: "xã",
    codename: "xa_dak_nia",
    district_code: 660,
  },
  {
    name: "Xã Quảng Sơn",
    code: 24616,
    division_type: "xã",
    codename: "xa_quang_son",
    district_code: 661,
  },
  {
    name: "Xã Quảng Hoà",
    code: 24620,
    division_type: "xã",
    codename: "xa_quang_hoa",
    district_code: 661,
  },
  {
    name: "Xã Đắk Ha",
    code: 24622,
    division_type: "xã",
    codename: "xa_dak_ha",
    district_code: 661,
  },
  {
    name: "Xã Đắk R'Măng",
    code: 24625,
    division_type: "xã",
    codename: "xa_dak_rmang",
    district_code: 661,
  },
  {
    name: "Xã Quảng Khê",
    code: 24631,
    division_type: "xã",
    codename: "xa_quang_khe",
    district_code: 661,
  },
  {
    name: "Xã Đắk Plao",
    code: 24634,
    division_type: "xã",
    codename: "xa_dak_plao",
    district_code: 661,
  },
  {
    name: "Xã Đắk Som",
    code: 24637,
    division_type: "xã",
    codename: "xa_dak_som",
    district_code: 661,
  },
  {
    name: "Thị trấn Ea T'Ling",
    code: 24640,
    division_type: "thị trấn",
    codename: "thi_tran_ea_tling",
    district_code: 662,
  },
  {
    name: "Xã Đắk Wil",
    code: 24643,
    division_type: "xã",
    codename: "xa_dak_wil",
    district_code: 662,
  },
  {
    name: "Xã Ea Pô",
    code: 24646,
    division_type: "xã",
    codename: "xa_ea_po",
    district_code: 662,
  },
  {
    name: "Xã Nam Dong",
    code: 24649,
    division_type: "xã",
    codename: "xa_nam_dong",
    district_code: 662,
  },
  {
    name: "Xã Đắk DRông",
    code: 24652,
    division_type: "xã",
    codename: "xa_dak_drong",
    district_code: 662,
  },
  {
    name: "Xã Tâm Thắng",
    code: 24655,
    division_type: "xã",
    codename: "xa_tam_thang",
    district_code: 662,
  },
  {
    name: "Xã Cư Knia",
    code: 24658,
    division_type: "xã",
    codename: "xa_cu_knia",
    district_code: 662,
  },
  {
    name: "Xã Trúc Sơn",
    code: 24661,
    division_type: "xã",
    codename: "xa_truc_son",
    district_code: 662,
  },
  {
    name: "Thị trấn Đắk Mil",
    code: 24664,
    division_type: "thị trấn",
    codename: "thi_tran_dak_mil",
    district_code: 663,
  },
  {
    name: "Xã Đắk Lao",
    code: 24667,
    division_type: "xã",
    codename: "xa_dak_lao",
    district_code: 663,
  },
  {
    name: "Xã Đắk R'La",
    code: 24670,
    division_type: "xã",
    codename: "xa_dak_rla",
    district_code: 663,
  },
  {
    name: "Xã Đắk Gằn",
    code: 24673,
    division_type: "xã",
    codename: "xa_dak_gan",
    district_code: 663,
  },
  {
    name: "Xã Đức Mạnh",
    code: 24676,
    division_type: "xã",
    codename: "xa_duc_manh",
    district_code: 663,
  },
  {
    name: "Xã Đắk N'Drót",
    code: 24677,
    division_type: "xã",
    codename: "xa_dak_ndrot",
    district_code: 663,
  },
  {
    name: "Xã Long Sơn",
    code: 24678,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 663,
  },
  {
    name: "Xã Đắk Sắk",
    code: 24679,
    division_type: "xã",
    codename: "xa_dak_sak",
    district_code: 663,
  },
  {
    name: "Xã Thuận An",
    code: 24682,
    division_type: "xã",
    codename: "xa_thuan_an",
    district_code: 663,
  },
  {
    name: "Xã Đức Minh",
    code: 24685,
    division_type: "xã",
    codename: "xa_duc_minh",
    district_code: 663,
  },
  {
    name: "Thị trấn Đắk Mâm",
    code: 24688,
    division_type: "thị trấn",
    codename: "thi_tran_dak_mam",
    district_code: 664,
  },
  {
    name: "Xã Đắk Sôr",
    code: 24691,
    division_type: "xã",
    codename: "xa_dak_sor",
    district_code: 664,
  },
  {
    name: "Xã Nam Xuân",
    code: 24692,
    division_type: "xã",
    codename: "xa_nam_xuan",
    district_code: 664,
  },
  {
    name: "Xã Buôn Choah",
    code: 24694,
    division_type: "xã",
    codename: "xa_buon_choah",
    district_code: 664,
  },
  {
    name: "Xã Nam Đà",
    code: 24697,
    division_type: "xã",
    codename: "xa_nam_da",
    district_code: 664,
  },
  {
    name: "Xã Tân Thành",
    code: 24699,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 664,
  },
  {
    name: "Xã Đắk Drô",
    code: 24700,
    division_type: "xã",
    codename: "xa_dak_dro",
    district_code: 664,
  },
  {
    name: "Xã Nâm Nung",
    code: 24703,
    division_type: "xã",
    codename: "xa_nam_nung",
    district_code: 664,
  },
  {
    name: "Xã Đức Xuyên",
    code: 24706,
    division_type: "xã",
    codename: "xa_duc_xuyen",
    district_code: 664,
  },
  {
    name: "Xã Đắk Nang",
    code: 24709,
    division_type: "xã",
    codename: "xa_dak_nang",
    district_code: 664,
  },
  {
    name: "Xã Quảng Phú",
    code: 24712,
    division_type: "xã",
    codename: "xa_quang_phu",
    district_code: 664,
  },
  {
    name: "Xã Nâm N'Đir",
    code: 24715,
    division_type: "xã",
    codename: "xa_nam_ndir",
    district_code: 664,
  },
  {
    name: "Thị trấn Đức An",
    code: 24717,
    division_type: "thị trấn",
    codename: "thi_tran_duc_an",
    district_code: 665,
  },
  {
    name: "Xã Đắk Môl",
    code: 24718,
    division_type: "xã",
    codename: "xa_dak_mol",
    district_code: 665,
  },
  {
    name: "Xã Đắk Hòa",
    code: 24719,
    division_type: "xã",
    codename: "xa_dak_hoa",
    district_code: 665,
  },
  {
    name: "Xã Nam Bình",
    code: 24721,
    division_type: "xã",
    codename: "xa_nam_binh",
    district_code: 665,
  },
  {
    name: "Xã Thuận Hà",
    code: 24722,
    division_type: "xã",
    codename: "xa_thuan_ha",
    district_code: 665,
  },
  {
    name: "Xã Thuận Hạnh",
    code: 24724,
    division_type: "xã",
    codename: "xa_thuan_hanh",
    district_code: 665,
  },
  {
    name: "Xã Đắk N'Dung",
    code: 24727,
    division_type: "xã",
    codename: "xa_dak_ndung",
    district_code: 665,
  },
  {
    name: "Xã Nâm N'Jang",
    code: 24728,
    division_type: "xã",
    codename: "xa_nam_njang",
    district_code: 665,
  },
  {
    name: "Xã Trường Xuân",
    code: 24730,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 665,
  },
  {
    name: "Thị trấn Kiến Đức",
    code: 24733,
    division_type: "thị trấn",
    codename: "thi_tran_kien_duc",
    district_code: 666,
  },
  {
    name: "Xã Quảng Tín",
    code: 24745,
    division_type: "xã",
    codename: "xa_quang_tin",
    district_code: 666,
  },
  {
    name: "Xã Đắk Wer",
    code: 24750,
    division_type: "xã",
    codename: "xa_dak_wer",
    district_code: 666,
  },
  {
    name: "Xã Nhân Cơ",
    code: 24751,
    division_type: "xã",
    codename: "xa_nhan_co",
    district_code: 666,
  },
  {
    name: "Xã Kiến Thành",
    code: 24754,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 666,
  },
  {
    name: "Xã Nghĩa Thắng",
    code: 24756,
    division_type: "xã",
    codename: "xa_nghia_thang",
    district_code: 666,
  },
  {
    name: "Xã Đạo Nghĩa",
    code: 24757,
    division_type: "xã",
    codename: "xa_dao_nghia",
    district_code: 666,
  },
  {
    name: "Xã Đắk Sin",
    code: 24760,
    division_type: "xã",
    codename: "xa_dak_sin",
    district_code: 666,
  },
  {
    name: "Xã Hưng Bình",
    code: 24761,
    division_type: "xã",
    codename: "xa_hung_binh",
    district_code: 666,
  },
  {
    name: "Xã Đắk Ru",
    code: 24763,
    division_type: "xã",
    codename: "xa_dak_ru",
    district_code: 666,
  },
  {
    name: "Xã Nhân Đạo",
    code: 24766,
    division_type: "xã",
    codename: "xa_nhan_dao",
    district_code: 666,
  },
  {
    name: "Xã Quảng Trực",
    code: 24736,
    division_type: "xã",
    codename: "xa_quang_truc",
    district_code: 667,
  },
  {
    name: "Xã Đắk Búk So",
    code: 24739,
    division_type: "xã",
    codename: "xa_dak_buk_so",
    district_code: 667,
  },
  {
    name: "Xã Quảng Tâm",
    code: 24740,
    division_type: "xã",
    codename: "xa_quang_tam",
    district_code: 667,
  },
  {
    name: "Xã Đắk R'Tíh",
    code: 24742,
    division_type: "xã",
    codename: "xa_dak_rtih",
    district_code: 667,
  },
  {
    name: "Xã Đắk Ngo",
    code: 24746,
    division_type: "xã",
    codename: "xa_dak_ngo",
    district_code: 667,
  },
  {
    name: "Xã Quảng Tân",
    code: 24748,
    division_type: "xã",
    codename: "xa_quang_tan",
    district_code: 667,
  },
  {
    name: "Phường 7",
    code: 24769,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 672,
  },
  {
    name: "Phường 8",
    code: 24772,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 672,
  },
  {
    name: "Phường 12",
    code: 24775,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 672,
  },
  {
    name: "Phường 9",
    code: 24778,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 672,
  },
  {
    name: "Phường 2",
    code: 24781,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 672,
  },
  {
    name: "Phường 1",
    code: 24784,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 672,
  },
  {
    name: "Phường 6",
    code: 24787,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 672,
  },
  {
    name: "Phường 5",
    code: 24790,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 672,
  },
  {
    name: "Phường 4",
    code: 24793,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 672,
  },
  {
    name: "Phường 10",
    code: 24796,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 672,
  },
  {
    name: "Phường 11",
    code: 24799,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 672,
  },
  {
    name: "Phường 3",
    code: 24802,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 672,
  },
  {
    name: "Xã Xuân Thọ",
    code: 24805,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 672,
  },
  {
    name: "Xã Tà Nung",
    code: 24808,
    division_type: "xã",
    codename: "xa_ta_nung",
    district_code: 672,
  },
  {
    name: "Xã Trạm Hành",
    code: 24810,
    division_type: "xã",
    codename: "xa_tram_hanh",
    district_code: 672,
  },
  {
    name: "Xã Xuân Trường",
    code: 24811,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 672,
  },
  {
    name: "Phường Lộc Phát",
    code: 24814,
    division_type: "phường",
    codename: "phuong_loc_phat",
    district_code: 673,
  },
  {
    name: "Phường Lộc Tiến",
    code: 24817,
    division_type: "phường",
    codename: "phuong_loc_tien",
    district_code: 673,
  },
  {
    name: "Phường 2",
    code: 24820,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 673,
  },
  {
    name: "Phường 1",
    code: 24823,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 673,
  },
  {
    name: "Phường B'lao",
    code: 24826,
    division_type: "phường",
    codename: "phuong_blao",
    district_code: 673,
  },
  {
    name: "Phường Lộc Sơn",
    code: 24829,
    division_type: "phường",
    codename: "phuong_loc_son",
    district_code: 673,
  },
  {
    name: "Xã Đạm Bri",
    code: 24832,
    division_type: "xã",
    codename: "xa_dam_bri",
    district_code: 673,
  },
  {
    name: "Xã Lộc Thanh",
    code: 24835,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 673,
  },
  {
    name: "Xã Lộc Nga",
    code: 24838,
    division_type: "xã",
    codename: "xa_loc_nga",
    district_code: 673,
  },
  {
    name: "Xã Lộc Châu",
    code: 24841,
    division_type: "xã",
    codename: "xa_loc_chau",
    district_code: 673,
  },
  {
    name: "Xã Đại Lào",
    code: 24844,
    division_type: "xã",
    codename: "xa_dai_lao",
    district_code: 673,
  },
  {
    name: "Xã Đạ Tông",
    code: 24853,
    division_type: "xã",
    codename: "xa_da_tong",
    district_code: 674,
  },
  {
    name: "Xã Đạ Long",
    code: 24856,
    division_type: "xã",
    codename: "xa_da_long",
    district_code: 674,
  },
  {
    name: "Xã Đạ M' Rong",
    code: 24859,
    division_type: "xã",
    codename: "xa_da_m_rong",
    district_code: 674,
  },
  {
    name: "Xã Liêng Srônh",
    code: 24874,
    division_type: "xã",
    codename: "xa_lieng_sronh",
    district_code: 674,
  },
  {
    name: "Xã Đạ Rsal",
    code: 24875,
    division_type: "xã",
    codename: "xa_da_rsal",
    district_code: 674,
  },
  {
    name: "Xã Rô Men",
    code: 24877,
    division_type: "xã",
    codename: "xa_ro_men",
    district_code: 674,
  },
  {
    name: "Xã Phi Liêng",
    code: 24886,
    division_type: "xã",
    codename: "xa_phi_lieng",
    district_code: 674,
  },
  {
    name: "Xã Đạ K' Nàng",
    code: 24889,
    division_type: "xã",
    codename: "xa_da_k_nang",
    district_code: 674,
  },
  {
    name: "Thị trấn Lạc Dương",
    code: 24846,
    division_type: "thị trấn",
    codename: "thi_tran_lac_duong",
    district_code: 675,
  },
  {
    name: "Xã Đạ Chais",
    code: 24847,
    division_type: "xã",
    codename: "xa_da_chais",
    district_code: 675,
  },
  {
    name: "Xã Đạ Nhim",
    code: 24848,
    division_type: "xã",
    codename: "xa_da_nhim",
    district_code: 675,
  },
  {
    name: "Xã Đưng KNớ",
    code: 24850,
    division_type: "xã",
    codename: "xa_dung_kno",
    district_code: 675,
  },
  {
    name: "Xã Lát",
    code: 24862,
    division_type: "xã",
    codename: "xa_lat",
    district_code: 675,
  },
  {
    name: "Xã Đạ Sar",
    code: 24865,
    division_type: "xã",
    codename: "xa_da_sar",
    district_code: 675,
  },
  {
    name: "Thị trấn Nam Ban",
    code: 24868,
    division_type: "thị trấn",
    codename: "thi_tran_nam_ban",
    district_code: 676,
  },
  {
    name: "Thị trấn Đinh Văn",
    code: 24871,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_van",
    district_code: 676,
  },
  {
    name: "Xã Phú Sơn",
    code: 24880,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 676,
  },
  {
    name: "Xã Phi Tô",
    code: 24883,
    division_type: "xã",
    codename: "xa_phi_to",
    district_code: 676,
  },
  {
    name: "Xã Mê Linh",
    code: 24892,
    division_type: "xã",
    codename: "xa_me_linh",
    district_code: 676,
  },
  {
    name: "Xã Đạ Đờn",
    code: 24895,
    division_type: "xã",
    codename: "xa_da_don",
    district_code: 676,
  },
  {
    name: "Xã Phúc Thọ",
    code: 24898,
    division_type: "xã",
    codename: "xa_phuc_tho",
    district_code: 676,
  },
  {
    name: "Xã Đông Thanh",
    code: 24901,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 676,
  },
  {
    name: "Xã Gia Lâm",
    code: 24904,
    division_type: "xã",
    codename: "xa_gia_lam",
    district_code: 676,
  },
  {
    name: "Xã Tân Thanh",
    code: 24907,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 676,
  },
  {
    name: "Xã Tân Văn",
    code: 24910,
    division_type: "xã",
    codename: "xa_tan_van",
    district_code: 676,
  },
  {
    name: "Xã Hoài Đức",
    code: 24913,
    division_type: "xã",
    codename: "xa_hoai_duc",
    district_code: 676,
  },
  {
    name: "Xã Tân Hà",
    code: 24916,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 676,
  },
  {
    name: "Xã Liên Hà",
    code: 24919,
    division_type: "xã",
    codename: "xa_lien_ha",
    district_code: 676,
  },
  {
    name: "Xã Đan Phượng",
    code: 24922,
    division_type: "xã",
    codename: "xa_dan_phuong",
    district_code: 676,
  },
  {
    name: "Xã Nam Hà",
    code: 24925,
    division_type: "xã",
    codename: "xa_nam_ha",
    district_code: 676,
  },
  {
    name: "Thị trấn D'Ran",
    code: 24928,
    division_type: "thị trấn",
    codename: "thi_tran_dran",
    district_code: 677,
  },
  {
    name: "Thị trấn Thạnh Mỹ",
    code: 24931,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_my",
    district_code: 677,
  },
  {
    name: "Xã Lạc Xuân",
    code: 24934,
    division_type: "xã",
    codename: "xa_lac_xuan",
    district_code: 677,
  },
  {
    name: "Xã Đạ Ròn",
    code: 24937,
    division_type: "xã",
    codename: "xa_da_ron",
    district_code: 677,
  },
  {
    name: "Xã Lạc Lâm",
    code: 24940,
    division_type: "xã",
    codename: "xa_lac_lam",
    district_code: 677,
  },
  {
    name: "Xã Ka Đô",
    code: 24943,
    division_type: "xã",
    codename: "xa_ka_do",
    district_code: 677,
  },
  {
    name: "Xã Quảng Lập",
    code: 24946,
    division_type: "xã",
    codename: "xa_quang_lap",
    district_code: 677,
  },
  {
    name: "Xã Ka Đơn",
    code: 24949,
    division_type: "xã",
    codename: "xa_ka_don",
    district_code: 677,
  },
  {
    name: "Xã Tu Tra",
    code: 24952,
    division_type: "xã",
    codename: "xa_tu_tra",
    district_code: 677,
  },
  {
    name: "Xã Pró",
    code: 24955,
    division_type: "xã",
    codename: "xa_pro",
    district_code: 677,
  },
  {
    name: "Thị trấn Liên Nghĩa",
    code: 24958,
    division_type: "thị trấn",
    codename: "thi_tran_lien_nghia",
    district_code: 678,
  },
  {
    name: "Xã Hiệp An",
    code: 24961,
    division_type: "xã",
    codename: "xa_hiep_an",
    district_code: 678,
  },
  {
    name: "Xã Liên Hiệp",
    code: 24964,
    division_type: "xã",
    codename: "xa_lien_hiep",
    district_code: 678,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 24967,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 678,
  },
  {
    name: "Xã Bình Thạnh",
    code: 24970,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 678,
  },
  {
    name: "Xã N'Thol Hạ",
    code: 24973,
    division_type: "xã",
    codename: "xa_nthol_ha",
    district_code: 678,
  },
  {
    name: "Xã Tân Hội",
    code: 24976,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 678,
  },
  {
    name: "Xã Tân Thành",
    code: 24979,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 678,
  },
  {
    name: "Xã Phú Hội",
    code: 24982,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 678,
  },
  {
    name: "Xã Ninh Gia",
    code: 24985,
    division_type: "xã",
    codename: "xa_ninh_gia",
    district_code: 678,
  },
  {
    name: "Xã Tà Năng",
    code: 24988,
    division_type: "xã",
    codename: "xa_ta_nang",
    district_code: 678,
  },
  {
    name: "Xã Đa Quyn",
    code: 24989,
    division_type: "xã",
    codename: "xa_da_quyn",
    district_code: 678,
  },
  {
    name: "Xã Tà Hine",
    code: 24991,
    division_type: "xã",
    codename: "xa_ta_hine",
    district_code: 678,
  },
  {
    name: "Xã Đà Loan",
    code: 24994,
    division_type: "xã",
    codename: "xa_da_loan",
    district_code: 678,
  },
  {
    name: "Xã Ninh Loan",
    code: 24997,
    division_type: "xã",
    codename: "xa_ninh_loan",
    district_code: 678,
  },
  {
    name: "Thị trấn Di Linh",
    code: 25000,
    division_type: "thị trấn",
    codename: "thi_tran_di_linh",
    district_code: 679,
  },
  {
    name: "Xã Đinh Trang Thượng",
    code: 25003,
    division_type: "xã",
    codename: "xa_dinh_trang_thuong",
    district_code: 679,
  },
  {
    name: "Xã Tân Thượng",
    code: 25006,
    division_type: "xã",
    codename: "xa_tan_thuong",
    district_code: 679,
  },
  {
    name: "Xã Tân Lâm",
    code: 25007,
    division_type: "xã",
    codename: "xa_tan_lam",
    district_code: 679,
  },
  {
    name: "Xã Tân Châu",
    code: 25009,
    division_type: "xã",
    codename: "xa_tan_chau",
    district_code: 679,
  },
  {
    name: "Xã Tân Nghĩa",
    code: 25012,
    division_type: "xã",
    codename: "xa_tan_nghia",
    district_code: 679,
  },
  {
    name: "Xã Gia Hiệp",
    code: 25015,
    division_type: "xã",
    codename: "xa_gia_hiep",
    district_code: 679,
  },
  {
    name: "Xã Đinh Lạc",
    code: 25018,
    division_type: "xã",
    codename: "xa_dinh_lac",
    district_code: 679,
  },
  {
    name: "Xã Tam Bố",
    code: 25021,
    division_type: "xã",
    codename: "xa_tam_bo",
    district_code: 679,
  },
  {
    name: "Xã Đinh Trang Hòa",
    code: 25024,
    division_type: "xã",
    codename: "xa_dinh_trang_hoa",
    district_code: 679,
  },
  {
    name: "Xã Liên Đầm",
    code: 25027,
    division_type: "xã",
    codename: "xa_lien_dam",
    district_code: 679,
  },
  {
    name: "Xã Gung Ré",
    code: 25030,
    division_type: "xã",
    codename: "xa_gung_re",
    district_code: 679,
  },
  {
    name: "Xã Bảo Thuận",
    code: 25033,
    division_type: "xã",
    codename: "xa_bao_thuan",
    district_code: 679,
  },
  {
    name: "Xã Hòa Ninh",
    code: 25036,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 679,
  },
  {
    name: "Xã Hòa Trung",
    code: 25039,
    division_type: "xã",
    codename: "xa_hoa_trung",
    district_code: 679,
  },
  {
    name: "Xã Hòa Nam",
    code: 25042,
    division_type: "xã",
    codename: "xa_hoa_nam",
    district_code: 679,
  },
  {
    name: "Xã Hòa Bắc",
    code: 25045,
    division_type: "xã",
    codename: "xa_hoa_bac",
    district_code: 679,
  },
  {
    name: "Xã Sơn Điền",
    code: 25048,
    division_type: "xã",
    codename: "xa_son_dien",
    district_code: 679,
  },
  {
    name: "Xã Gia Bắc",
    code: 25051,
    division_type: "xã",
    codename: "xa_gia_bac",
    district_code: 679,
  },
  {
    name: "Thị trấn Lộc Thắng",
    code: 25054,
    division_type: "thị trấn",
    codename: "thi_tran_loc_thang",
    district_code: 680,
  },
  {
    name: "Xã Lộc Bảo",
    code: 25057,
    division_type: "xã",
    codename: "xa_loc_bao",
    district_code: 680,
  },
  {
    name: "Xã Lộc Lâm",
    code: 25060,
    division_type: "xã",
    codename: "xa_loc_lam",
    district_code: 680,
  },
  {
    name: "Xã Lộc Phú",
    code: 25063,
    division_type: "xã",
    codename: "xa_loc_phu",
    district_code: 680,
  },
  {
    name: "Xã Lộc Bắc",
    code: 25066,
    division_type: "xã",
    codename: "xa_loc_bac",
    district_code: 680,
  },
  {
    name: "Xã B' Lá",
    code: 25069,
    division_type: "xã",
    codename: "xa_b_la",
    district_code: 680,
  },
  {
    name: "Xã Lộc Ngãi",
    code: 25072,
    division_type: "xã",
    codename: "xa_loc_ngai",
    district_code: 680,
  },
  {
    name: "Xã Lộc Quảng",
    code: 25075,
    division_type: "xã",
    codename: "xa_loc_quang",
    district_code: 680,
  },
  {
    name: "Xã Lộc Tân",
    code: 25078,
    division_type: "xã",
    codename: "xa_loc_tan",
    district_code: 680,
  },
  {
    name: "Xã Lộc Đức",
    code: 25081,
    division_type: "xã",
    codename: "xa_loc_duc",
    district_code: 680,
  },
  {
    name: "Xã Lộc An",
    code: 25084,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 680,
  },
  {
    name: "Xã Tân Lạc",
    code: 25087,
    division_type: "xã",
    codename: "xa_tan_lac",
    district_code: 680,
  },
  {
    name: "Xã Lộc Thành",
    code: 25090,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 680,
  },
  {
    name: "Xã Lộc Nam",
    code: 25093,
    division_type: "xã",
    codename: "xa_loc_nam",
    district_code: 680,
  },
  {
    name: "Thị trấn Đạ M'ri",
    code: 25096,
    division_type: "thị trấn",
    codename: "thi_tran_da_mri",
    district_code: 681,
  },
  {
    name: "Thị trấn Ma Đa Guôi",
    code: 25099,
    division_type: "thị trấn",
    codename: "thi_tran_ma_da_guoi",
    district_code: 681,
  },
  {
    name: "Xã Hà Lâm",
    code: 25105,
    division_type: "xã",
    codename: "xa_ha_lam",
    district_code: 681,
  },
  {
    name: "Xã Đạ Tồn",
    code: 25108,
    division_type: "xã",
    codename: "xa_da_ton",
    district_code: 681,
  },
  {
    name: "Xã Đạ Oai",
    code: 25111,
    division_type: "xã",
    codename: "xa_da_oai",
    district_code: 681,
  },
  {
    name: "Xã Đạ Ploa",
    code: 25114,
    division_type: "xã",
    codename: "xa_da_ploa",
    district_code: 681,
  },
  {
    name: "Xã Ma Đa Guôi",
    code: 25117,
    division_type: "xã",
    codename: "xa_ma_da_guoi",
    district_code: 681,
  },
  {
    name: "Xã Đoàn Kết",
    code: 25120,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 681,
  },
  {
    name: "Xã Phước Lộc",
    code: 25123,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 681,
  },
  {
    name: "Thị trấn Đạ Tẻh",
    code: 25126,
    division_type: "thị trấn",
    codename: "thi_tran_da_teh",
    district_code: 682,
  },
  {
    name: "Xã An Nhơn",
    code: 25129,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 682,
  },
  {
    name: "Xã Quốc Oai",
    code: 25132,
    division_type: "xã",
    codename: "xa_quoc_oai",
    district_code: 682,
  },
  {
    name: "Xã Mỹ Đức",
    code: 25135,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 682,
  },
  {
    name: "Xã Quảng Trị",
    code: 25138,
    division_type: "xã",
    codename: "xa_quang_tri",
    district_code: 682,
  },
  {
    name: "Xã Đạ Lây",
    code: 25141,
    division_type: "xã",
    codename: "xa_da_lay",
    district_code: 682,
  },
  {
    name: "Xã Triệu Hải",
    code: 25147,
    division_type: "xã",
    codename: "xa_trieu_hai",
    district_code: 682,
  },
  {
    name: "Xã Đạ Kho",
    code: 25153,
    division_type: "xã",
    codename: "xa_da_kho",
    district_code: 682,
  },
  {
    name: "Xã Đạ Pal",
    code: 25156,
    division_type: "xã",
    codename: "xa_da_pal",
    district_code: 682,
  },
  {
    name: "Thị trấn Cát Tiên",
    code: 25159,
    division_type: "thị trấn",
    codename: "thi_tran_cat_tien",
    district_code: 683,
  },
  {
    name: "Xã Tiên Hoàng",
    code: 25162,
    division_type: "xã",
    codename: "xa_tien_hoang",
    district_code: 683,
  },
  {
    name: "Xã Phước Cát 2",
    code: 25165,
    division_type: "xã",
    codename: "xa_phuoc_cat_2",
    district_code: 683,
  },
  {
    name: "Xã Gia Viễn",
    code: 25168,
    division_type: "xã",
    codename: "xa_gia_vien",
    district_code: 683,
  },
  {
    name: "Xã Nam Ninh",
    code: 25171,
    division_type: "xã",
    codename: "xa_nam_ninh",
    district_code: 683,
  },
  {
    name: "Thị trấn Phước Cát",
    code: 25180,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_cat",
    district_code: 683,
  },
  {
    name: "Xã Đức Phổ",
    code: 25183,
    division_type: "xã",
    codename: "xa_duc_pho",
    district_code: 683,
  },
  {
    name: "Xã Quảng Ngãi",
    code: 25189,
    division_type: "xã",
    codename: "xa_quang_ngai",
    district_code: 683,
  },
  {
    name: "Xã Đồng Nai Thượng",
    code: 25192,
    division_type: "xã",
    codename: "xa_dong_nai_thuong",
    district_code: 683,
  },
  {
    name: "Phường Thác Mơ",
    code: 25216,
    division_type: "phường",
    codename: "phuong_thac_mo",
    district_code: 688,
  },
  {
    name: "Phường Long Thủy",
    code: 25217,
    division_type: "phường",
    codename: "phuong_long_thuy",
    district_code: 688,
  },
  {
    name: "Phường Phước Bình",
    code: 25219,
    division_type: "phường",
    codename: "phuong_phuoc_binh",
    district_code: 688,
  },
  {
    name: "Phường Long Phước",
    code: 25220,
    division_type: "phường",
    codename: "phuong_long_phuoc",
    district_code: 688,
  },
  {
    name: "Phường Sơn Giang",
    code: 25237,
    division_type: "phường",
    codename: "phuong_son_giang",
    district_code: 688,
  },
  {
    name: "Xã Long Giang",
    code: 25245,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 688,
  },
  {
    name: "Xã Phước Tín",
    code: 25249,
    division_type: "xã",
    codename: "xa_phuoc_tin",
    district_code: 688,
  },
  {
    name: "Phường Tân Phú",
    code: 25195,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 689,
  },
  {
    name: "Phường Tân Đồng",
    code: 25198,
    division_type: "phường",
    codename: "phuong_tan_dong",
    district_code: 689,
  },
  {
    name: "Phường Tân Bình",
    code: 25201,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 689,
  },
  {
    name: "Phường Tân Xuân",
    code: 25204,
    division_type: "phường",
    codename: "phuong_tan_xuan",
    district_code: 689,
  },
  {
    name: "Phường Tân Thiện",
    code: 25205,
    division_type: "phường",
    codename: "phuong_tan_thien",
    district_code: 689,
  },
  {
    name: "Xã Tân Thành",
    code: 25207,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 689,
  },
  {
    name: "Phường Tiến Thành",
    code: 25210,
    division_type: "phường",
    codename: "phuong_tien_thanh",
    district_code: 689,
  },
  {
    name: "Xã Tiến Hưng",
    code: 25213,
    division_type: "xã",
    codename: "xa_tien_hung",
    district_code: 689,
  },
  {
    name: "Phường Hưng Chiến",
    code: 25320,
    division_type: "phường",
    codename: "phuong_hung_chien",
    district_code: 690,
  },
  {
    name: "Phường An Lộc",
    code: 25324,
    division_type: "phường",
    codename: "phuong_an_loc",
    district_code: 690,
  },
  {
    name: "Phường Phú Thịnh",
    code: 25325,
    division_type: "phường",
    codename: "phuong_phu_thinh",
    district_code: 690,
  },
  {
    name: "Phường Phú Đức",
    code: 25326,
    division_type: "phường",
    codename: "phuong_phu_duc",
    district_code: 690,
  },
  {
    name: "Xã Thanh Lương",
    code: 25333,
    division_type: "xã",
    codename: "xa_thanh_luong",
    district_code: 690,
  },
  {
    name: "Xã Thanh Phú",
    code: 25336,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 690,
  },
  {
    name: "Xã Bù Gia Mập",
    code: 25222,
    division_type: "xã",
    codename: "xa_bu_gia_map",
    district_code: 691,
  },
  {
    name: "Xã Đak Ơ",
    code: 25225,
    division_type: "xã",
    codename: "xa_dak_o",
    district_code: 691,
  },
  {
    name: "Xã Đức Hạnh",
    code: 25228,
    division_type: "xã",
    codename: "xa_duc_hanh",
    district_code: 691,
  },
  {
    name: "Xã Phú Văn",
    code: 25229,
    division_type: "xã",
    codename: "xa_phu_van",
    district_code: 691,
  },
  {
    name: "Xã Đa Kia",
    code: 25231,
    division_type: "xã",
    codename: "xa_da_kia",
    district_code: 691,
  },
  {
    name: "Xã Phước Minh",
    code: 25232,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 691,
  },
  {
    name: "Xã Bình Thắng",
    code: 25234,
    division_type: "xã",
    codename: "xa_binh_thang",
    district_code: 691,
  },
  {
    name: "Xã Phú Nghĩa",
    code: 25267,
    division_type: "xã",
    codename: "xa_phu_nghia",
    district_code: 691,
  },
  {
    name: "Thị trấn Lộc Ninh",
    code: 25270,
    division_type: "thị trấn",
    codename: "thi_tran_loc_ninh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hòa",
    code: 25273,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 692,
  },
  {
    name: "Xã Lộc An",
    code: 25276,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 692,
  },
  {
    name: "Xã Lộc Tấn",
    code: 25279,
    division_type: "xã",
    codename: "xa_loc_tan",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thạnh",
    code: 25280,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hiệp",
    code: 25282,
    division_type: "xã",
    codename: "xa_loc_hiep",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thiện",
    code: 25285,
    division_type: "xã",
    codename: "xa_loc_thien",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thuận",
    code: 25288,
    division_type: "xã",
    codename: "xa_loc_thuan",
    district_code: 692,
  },
  {
    name: "Xã Lộc Quang",
    code: 25291,
    division_type: "xã",
    codename: "xa_loc_quang",
    district_code: 692,
  },
  {
    name: "Xã Lộc Phú",
    code: 25292,
    division_type: "xã",
    codename: "xa_loc_phu",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thành",
    code: 25294,
    division_type: "xã",
    codename: "xa_loc_thanh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thái",
    code: 25297,
    division_type: "xã",
    codename: "xa_loc_thai",
    district_code: 692,
  },
  {
    name: "Xã Lộc Điền",
    code: 25300,
    division_type: "xã",
    codename: "xa_loc_dien",
    district_code: 692,
  },
  {
    name: "Xã Lộc Hưng",
    code: 25303,
    division_type: "xã",
    codename: "xa_loc_hung",
    district_code: 692,
  },
  {
    name: "Xã Lộc Thịnh",
    code: 25305,
    division_type: "xã",
    codename: "xa_loc_thinh",
    district_code: 692,
  },
  {
    name: "Xã Lộc Khánh",
    code: 25306,
    division_type: "xã",
    codename: "xa_loc_khanh",
    district_code: 692,
  },
  {
    name: "Thị trấn Thanh Bình",
    code: 25308,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_binh",
    district_code: 693,
  },
  {
    name: "Xã Hưng Phước",
    code: 25309,
    division_type: "xã",
    codename: "xa_hung_phuoc",
    district_code: 693,
  },
  {
    name: "Xã Phước Thiện",
    code: 25310,
    division_type: "xã",
    codename: "xa_phuoc_thien",
    district_code: 693,
  },
  {
    name: "Xã Thiện Hưng",
    code: 25312,
    division_type: "xã",
    codename: "xa_thien_hung",
    district_code: 693,
  },
  {
    name: "Xã Thanh Hòa",
    code: 25315,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 693,
  },
  {
    name: "Xã Tân Thành",
    code: 25318,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 693,
  },
  {
    name: "Xã Tân Tiến",
    code: 25321,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 693,
  },
  {
    name: "Xã Thanh An",
    code: 25327,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 694,
  },
  {
    name: "Xã An Khương",
    code: 25330,
    division_type: "xã",
    codename: "xa_an_khuong",
    district_code: 694,
  },
  {
    name: "Xã An Phú",
    code: 25339,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 694,
  },
  {
    name: "Xã Tân Lợi",
    code: 25342,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 694,
  },
  {
    name: "Xã Tân Hưng",
    code: 25345,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 694,
  },
  {
    name: "Xã Minh Đức",
    code: 25348,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 694,
  },
  {
    name: "Xã Minh Tâm",
    code: 25349,
    division_type: "xã",
    codename: "xa_minh_tam",
    district_code: 694,
  },
  {
    name: "Xã Phước An",
    code: 25351,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 694,
  },
  {
    name: "Xã Thanh Bình",
    code: 25354,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 694,
  },
  {
    name: "Thị trấn Tân Khai",
    code: 25357,
    division_type: "thị trấn",
    codename: "thi_tran_tan_khai",
    district_code: 694,
  },
  {
    name: "Xã Đồng Nơ",
    code: 25360,
    division_type: "xã",
    codename: "xa_dong_no",
    district_code: 694,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25361,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 694,
  },
  {
    name: "Xã Tân Quan",
    code: 25438,
    division_type: "xã",
    codename: "xa_tan_quan",
    district_code: 694,
  },
  {
    name: "Thị trấn Tân Phú",
    code: 25363,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phu",
    district_code: 695,
  },
  {
    name: "Xã Thuận Lợi",
    code: 25366,
    division_type: "xã",
    codename: "xa_thuan_loi",
    district_code: 695,
  },
  {
    name: "Xã Đồng Tâm",
    code: 25369,
    division_type: "xã",
    codename: "xa_dong_tam",
    district_code: 695,
  },
  {
    name: "Xã Tân Phước",
    code: 25372,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 695,
  },
  {
    name: "Xã Tân Hưng",
    code: 25375,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 695,
  },
  {
    name: "Xã Tân Lợi",
    code: 25378,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 695,
  },
  {
    name: "Xã Tân Lập",
    code: 25381,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 695,
  },
  {
    name: "Xã Tân Hòa",
    code: 25384,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 695,
  },
  {
    name: "Xã Thuận Phú",
    code: 25387,
    division_type: "xã",
    codename: "xa_thuan_phu",
    district_code: 695,
  },
  {
    name: "Xã Đồng Tiến",
    code: 25390,
    division_type: "xã",
    codename: "xa_dong_tien",
    district_code: 695,
  },
  {
    name: "Xã Tân Tiến",
    code: 25393,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 695,
  },
  {
    name: "Thị trấn Đức Phong",
    code: 25396,
    division_type: "thị trấn",
    codename: "thi_tran_duc_phong",
    district_code: 696,
  },
  {
    name: "Xã Đường 10",
    code: 25398,
    division_type: "xã",
    codename: "xa_duong_10",
    district_code: 696,
  },
  {
    name: "Xã Đak Nhau",
    code: 25399,
    division_type: "xã",
    codename: "xa_dak_nhau",
    district_code: 696,
  },
  {
    name: "Xã Phú Sơn",
    code: 25400,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 696,
  },
  {
    name: "Xã Thọ Sơn",
    code: 25402,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 696,
  },
  {
    name: "Xã Bình Minh",
    code: 25404,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 696,
  },
  {
    name: "Xã Bom Bo",
    code: 25405,
    division_type: "xã",
    codename: "xa_bom_bo",
    district_code: 696,
  },
  {
    name: "Xã Minh Hưng",
    code: 25408,
    division_type: "xã",
    codename: "xa_minh_hung",
    district_code: 696,
  },
  {
    name: "Xã Đoàn Kết",
    code: 25411,
    division_type: "xã",
    codename: "xa_doan_ket",
    district_code: 696,
  },
  {
    name: "Xã Đồng Nai",
    code: 25414,
    division_type: "xã",
    codename: "xa_dong_nai",
    district_code: 696,
  },
  {
    name: "Xã Đức Liễu",
    code: 25417,
    division_type: "xã",
    codename: "xa_duc_lieu",
    district_code: 696,
  },
  {
    name: "Xã Thống Nhất",
    code: 25420,
    division_type: "xã",
    codename: "xa_thong_nhat",
    district_code: 696,
  },
  {
    name: "Xã Nghĩa Trung",
    code: 25423,
    division_type: "xã",
    codename: "xa_nghia_trung",
    district_code: 696,
  },
  {
    name: "Xã Nghĩa Bình",
    code: 25424,
    division_type: "xã",
    codename: "xa_nghia_binh",
    district_code: 696,
  },
  {
    name: "Xã Đăng Hà",
    code: 25426,
    division_type: "xã",
    codename: "xa_dang_ha",
    district_code: 696,
  },
  {
    name: "Xã Phước Sơn",
    code: 25429,
    division_type: "xã",
    codename: "xa_phuoc_son",
    district_code: 696,
  },
  {
    name: "Thị trấn Chơn Thành",
    code: 25432,
    division_type: "thị trấn",
    codename: "thi_tran_chon_thanh",
    district_code: 697,
  },
  {
    name: "Xã Thành Tâm",
    code: 25433,
    division_type: "xã",
    codename: "xa_thanh_tam",
    district_code: 697,
  },
  {
    name: "Xã Minh Lập",
    code: 25435,
    division_type: "xã",
    codename: "xa_minh_lap",
    district_code: 697,
  },
  {
    name: "Xã Quang Minh",
    code: 25439,
    division_type: "xã",
    codename: "xa_quang_minh",
    district_code: 697,
  },
  {
    name: "Xã Minh Hưng",
    code: 25441,
    division_type: "xã",
    codename: "xa_minh_hung",
    district_code: 697,
  },
  {
    name: "Xã Minh Long",
    code: 25444,
    division_type: "xã",
    codename: "xa_minh_long",
    district_code: 697,
  },
  {
    name: "Xã Minh Thành",
    code: 25447,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 697,
  },
  {
    name: "Xã Nha Bích",
    code: 25450,
    division_type: "xã",
    codename: "xa_nha_bich",
    district_code: 697,
  },
  {
    name: "Xã Minh Thắng",
    code: 25453,
    division_type: "xã",
    codename: "xa_minh_thang",
    district_code: 697,
  },
  {
    name: "Xã Long Bình",
    code: 25240,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 698,
  },
  {
    name: "Xã Bình Tân",
    code: 25243,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 698,
  },
  {
    name: "Xã Bình Sơn",
    code: 25244,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 698,
  },
  {
    name: "Xã Long Hưng",
    code: 25246,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 698,
  },
  {
    name: "Xã Phước Tân",
    code: 25250,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 698,
  },
  {
    name: "Xã Bù Nho",
    code: 25252,
    division_type: "xã",
    codename: "xa_bu_nho",
    district_code: 698,
  },
  {
    name: "Xã Long Hà",
    code: 25255,
    division_type: "xã",
    codename: "xa_long_ha",
    district_code: 698,
  },
  {
    name: "Xã Long Tân",
    code: 25258,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 698,
  },
  {
    name: "Xã Phú Trung",
    code: 25261,
    division_type: "xã",
    codename: "xa_phu_trung",
    district_code: 698,
  },
  {
    name: "Xã Phú Riềng",
    code: 25264,
    division_type: "xã",
    codename: "xa_phu_rieng",
    district_code: 698,
  },
  {
    name: "Phường 1",
    code: 25456,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 703,
  },
  {
    name: "Phường 3",
    code: 25459,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 703,
  },
  {
    name: "Phường 4",
    code: 25462,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 703,
  },
  {
    name: "Phường Hiệp Ninh",
    code: 25465,
    division_type: "phường",
    codename: "phuong_hiep_ninh",
    district_code: 703,
  },
  {
    name: "Phường 2",
    code: 25468,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 703,
  },
  {
    name: "Xã Thạnh Tân",
    code: 25471,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 703,
  },
  {
    name: "Xã Tân Bình",
    code: 25474,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 703,
  },
  {
    name: "Xã Bình Minh",
    code: 25477,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 703,
  },
  {
    name: "Phường Ninh Sơn",
    code: 25480,
    division_type: "phường",
    codename: "phuong_ninh_son",
    district_code: 703,
  },
  {
    name: "Phường Ninh Thạnh",
    code: 25483,
    division_type: "phường",
    codename: "phuong_ninh_thanh",
    district_code: 703,
  },
  {
    name: "Thị trấn Tân Biên",
    code: 25486,
    division_type: "thị trấn",
    codename: "thi_tran_tan_bien",
    district_code: 705,
  },
  {
    name: "Xã Tân Lập",
    code: 25489,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Bắc",
    code: 25492,
    division_type: "xã",
    codename: "xa_thanh_bac",
    district_code: 705,
  },
  {
    name: "Xã Tân Bình",
    code: 25495,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Bình",
    code: 25498,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 705,
  },
  {
    name: "Xã Thạnh Tây",
    code: 25501,
    division_type: "xã",
    codename: "xa_thanh_tay",
    district_code: 705,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 25504,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 705,
  },
  {
    name: "Xã Tân Phong",
    code: 25507,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 705,
  },
  {
    name: "Xã Mỏ Công",
    code: 25510,
    division_type: "xã",
    codename: "xa_mo_cong",
    district_code: 705,
  },
  {
    name: "Xã Trà Vong",
    code: 25513,
    division_type: "xã",
    codename: "xa_tra_vong",
    district_code: 705,
  },
  {
    name: "Thị trấn Tân Châu",
    code: 25516,
    division_type: "thị trấn",
    codename: "thi_tran_tan_chau",
    district_code: 706,
  },
  {
    name: "Xã Tân Hà",
    code: 25519,
    division_type: "xã",
    codename: "xa_tan_ha",
    district_code: 706,
  },
  {
    name: "Xã Tân Đông",
    code: 25522,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 706,
  },
  {
    name: "Xã Tân Hội",
    code: 25525,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 706,
  },
  {
    name: "Xã Tân Hòa",
    code: 25528,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 706,
  },
  {
    name: "Xã Suối Ngô",
    code: 25531,
    division_type: "xã",
    codename: "xa_suoi_ngo",
    district_code: 706,
  },
  {
    name: "Xã Suối Dây",
    code: 25534,
    division_type: "xã",
    codename: "xa_suoi_day",
    district_code: 706,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25537,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 706,
  },
  {
    name: "Xã Thạnh Đông",
    code: 25540,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 706,
  },
  {
    name: "Xã Tân Thành",
    code: 25543,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 706,
  },
  {
    name: "Xã Tân Phú",
    code: 25546,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 706,
  },
  {
    name: "Xã Tân Hưng",
    code: 25549,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 706,
  },
  {
    name: "Thị trấn Dương Minh Châu",
    code: 25552,
    division_type: "thị trấn",
    codename: "thi_tran_duong_minh_chau",
    district_code: 707,
  },
  {
    name: "Xã Suối Đá",
    code: 25555,
    division_type: "xã",
    codename: "xa_suoi_da",
    district_code: 707,
  },
  {
    name: "Xã Phan",
    code: 25558,
    division_type: "xã",
    codename: "xa_phan",
    district_code: 707,
  },
  {
    name: "Xã Phước Ninh",
    code: 25561,
    division_type: "xã",
    codename: "xa_phuoc_ninh",
    district_code: 707,
  },
  {
    name: "Xã Phước Minh",
    code: 25564,
    division_type: "xã",
    codename: "xa_phuoc_minh",
    district_code: 707,
  },
  {
    name: "Xã Bàu Năng",
    code: 25567,
    division_type: "xã",
    codename: "xa_bau_nang",
    district_code: 707,
  },
  {
    name: "Xã Chà Là",
    code: 25570,
    division_type: "xã",
    codename: "xa_cha_la",
    district_code: 707,
  },
  {
    name: "Xã Cầu Khởi",
    code: 25573,
    division_type: "xã",
    codename: "xa_cau_khoi",
    district_code: 707,
  },
  {
    name: "Xã Bến Củi",
    code: 25576,
    division_type: "xã",
    codename: "xa_ben_cui",
    district_code: 707,
  },
  {
    name: "Xã Lộc Ninh",
    code: 25579,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 707,
  },
  {
    name: "Xã Truông Mít",
    code: 25582,
    division_type: "xã",
    codename: "xa_truong_mit",
    district_code: 707,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 25585,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 708,
  },
  {
    name: "Xã Hảo Đước",
    code: 25588,
    division_type: "xã",
    codename: "xa_hao_duoc",
    district_code: 708,
  },
  {
    name: "Xã Phước Vinh",
    code: 25591,
    division_type: "xã",
    codename: "xa_phuoc_vinh",
    district_code: 708,
  },
  {
    name: "Xã Đồng Khởi",
    code: 25594,
    division_type: "xã",
    codename: "xa_dong_khoi",
    district_code: 708,
  },
  {
    name: "Xã Thái Bình",
    code: 25597,
    division_type: "xã",
    codename: "xa_thai_binh",
    district_code: 708,
  },
  {
    name: "Xã An Cơ",
    code: 25600,
    division_type: "xã",
    codename: "xa_an_co",
    district_code: 708,
  },
  {
    name: "Xã Biên Giới",
    code: 25603,
    division_type: "xã",
    codename: "xa_bien_gioi",
    district_code: 708,
  },
  {
    name: "Xã Hòa Thạnh",
    code: 25606,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 708,
  },
  {
    name: "Xã Trí Bình",
    code: 25609,
    division_type: "xã",
    codename: "xa_tri_binh",
    district_code: 708,
  },
  {
    name: "Xã Hòa Hội",
    code: 25612,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 708,
  },
  {
    name: "Xã An Bình",
    code: 25615,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 708,
  },
  {
    name: "Xã Thanh Điền",
    code: 25618,
    division_type: "xã",
    codename: "xa_thanh_dien",
    district_code: 708,
  },
  {
    name: "Xã Thành Long",
    code: 25621,
    division_type: "xã",
    codename: "xa_thanh_long",
    district_code: 708,
  },
  {
    name: "Xã Ninh Điền",
    code: 25624,
    division_type: "xã",
    codename: "xa_ninh_dien",
    district_code: 708,
  },
  {
    name: "Xã Long Vĩnh",
    code: 25627,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 708,
  },
  {
    name: "Phường Long Hoa",
    code: 25630,
    division_type: "phường",
    codename: "phuong_long_hoa",
    district_code: 709,
  },
  {
    name: "Phường Hiệp Tân",
    code: 25633,
    division_type: "phường",
    codename: "phuong_hiep_tan",
    district_code: 709,
  },
  {
    name: "Phường Long Thành Bắc",
    code: 25636,
    division_type: "phường",
    codename: "phuong_long_thanh_bac",
    district_code: 709,
  },
  {
    name: "Xã Trường Hòa",
    code: 25639,
    division_type: "xã",
    codename: "xa_truong_hoa",
    district_code: 709,
  },
  {
    name: "Xã Trường Đông",
    code: 25642,
    division_type: "xã",
    codename: "xa_truong_dong",
    district_code: 709,
  },
  {
    name: "Phường Long Thành Trung",
    code: 25645,
    division_type: "phường",
    codename: "phuong_long_thanh_trung",
    district_code: 709,
  },
  {
    name: "Xã Trường Tây",
    code: 25648,
    division_type: "xã",
    codename: "xa_truong_tay",
    district_code: 709,
  },
  {
    name: "Xã Long Thành Nam",
    code: 25651,
    division_type: "xã",
    codename: "xa_long_thanh_nam",
    district_code: 709,
  },
  {
    name: "Thị trấn Gò Dầu",
    code: 25654,
    division_type: "thị trấn",
    codename: "thi_tran_go_dau",
    district_code: 710,
  },
  {
    name: "Xã Thạnh Đức",
    code: 25657,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 710,
  },
  {
    name: "Xã Cẩm Giang",
    code: 25660,
    division_type: "xã",
    codename: "xa_cam_giang",
    district_code: 710,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 25663,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 710,
  },
  {
    name: "Xã Bàu Đồn",
    code: 25666,
    division_type: "xã",
    codename: "xa_bau_don",
    district_code: 710,
  },
  {
    name: "Xã Phước Thạnh",
    code: 25669,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 710,
  },
  {
    name: "Xã Phước Đông",
    code: 25672,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 710,
  },
  {
    name: "Xã Phước Trạch",
    code: 25675,
    division_type: "xã",
    codename: "xa_phuoc_trach",
    district_code: 710,
  },
  {
    name: "Xã Thanh Phước",
    code: 25678,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 710,
  },
  {
    name: "Thị trấn Bến Cầu",
    code: 25681,
    division_type: "thị trấn",
    codename: "thi_tran_ben_cau",
    district_code: 711,
  },
  {
    name: "Xã Long Chữ",
    code: 25684,
    division_type: "xã",
    codename: "xa_long_chu",
    district_code: 711,
  },
  {
    name: "Xã Long Phước",
    code: 25687,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 711,
  },
  {
    name: "Xã Long Giang",
    code: 25690,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 711,
  },
  {
    name: "Xã Tiên Thuận",
    code: 25693,
    division_type: "xã",
    codename: "xa_tien_thuan",
    district_code: 711,
  },
  {
    name: "Xã Long Khánh",
    code: 25696,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 711,
  },
  {
    name: "Xã Lợi Thuận",
    code: 25699,
    division_type: "xã",
    codename: "xa_loi_thuan",
    district_code: 711,
  },
  {
    name: "Xã Long Thuận",
    code: 25702,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 711,
  },
  {
    name: "Xã An Thạnh",
    code: 25705,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 711,
  },
  {
    name: "Phường Trảng Bàng",
    code: 25708,
    division_type: "phường",
    codename: "phuong_trang_bang",
    district_code: 712,
  },
  {
    name: "Xã Đôn Thuận",
    code: 25711,
    division_type: "xã",
    codename: "xa_don_thuan",
    district_code: 712,
  },
  {
    name: "Xã Hưng Thuận",
    code: 25714,
    division_type: "xã",
    codename: "xa_hung_thuan",
    district_code: 712,
  },
  {
    name: "Phường Lộc Hưng",
    code: 25717,
    division_type: "phường",
    codename: "phuong_loc_hung",
    district_code: 712,
  },
  {
    name: "Phường Gia Lộc",
    code: 25720,
    division_type: "phường",
    codename: "phuong_gia_loc",
    district_code: 712,
  },
  {
    name: "Phường Gia Bình",
    code: 25723,
    division_type: "phường",
    codename: "phuong_gia_binh",
    district_code: 712,
  },
  {
    name: "Xã Phước Bình",
    code: 25729,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 712,
  },
  {
    name: "Phường An Tịnh",
    code: 25732,
    division_type: "phường",
    codename: "phuong_an_tinh",
    district_code: 712,
  },
  {
    name: "Phường An Hòa",
    code: 25735,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 712,
  },
  {
    name: "Xã Phước Chỉ",
    code: 25738,
    division_type: "xã",
    codename: "xa_phuoc_chi",
    district_code: 712,
  },
  {
    name: "Phường Hiệp Thành",
    code: 25741,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 718,
  },
  {
    name: "Phường Phú Lợi",
    code: 25744,
    division_type: "phường",
    codename: "phuong_phu_loi",
    district_code: 718,
  },
  {
    name: "Phường Phú Cường",
    code: 25747,
    division_type: "phường",
    codename: "phuong_phu_cuong",
    district_code: 718,
  },
  {
    name: "Phường Phú Hòa",
    code: 25750,
    division_type: "phường",
    codename: "phuong_phu_hoa",
    district_code: 718,
  },
  {
    name: "Phường Phú Thọ",
    code: 25753,
    division_type: "phường",
    codename: "phuong_phu_tho",
    district_code: 718,
  },
  {
    name: "Phường Chánh Nghĩa",
    code: 25756,
    division_type: "phường",
    codename: "phuong_chanh_nghia",
    district_code: 718,
  },
  {
    name: "Phường Định Hoà",
    code: 25759,
    division_type: "phường",
    codename: "phuong_dinh_hoa",
    district_code: 718,
  },
  {
    name: "Phường Hoà Phú",
    code: 25760,
    division_type: "phường",
    codename: "phuong_hoa_phu",
    district_code: 718,
  },
  {
    name: "Phường Phú Mỹ",
    code: 25762,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 718,
  },
  {
    name: "Phường Phú Tân",
    code: 25763,
    division_type: "phường",
    codename: "phuong_phu_tan",
    district_code: 718,
  },
  {
    name: "Phường Tân An",
    code: 25765,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 718,
  },
  {
    name: "Phường Hiệp An",
    code: 25768,
    division_type: "phường",
    codename: "phuong_hiep_an",
    district_code: 718,
  },
  {
    name: "Phường Tương Bình Hiệp",
    code: 25771,
    division_type: "phường",
    codename: "phuong_tuong_binh_hiep",
    district_code: 718,
  },
  {
    name: "Phường Chánh Mỹ",
    code: 25774,
    division_type: "phường",
    codename: "phuong_chanh_my",
    district_code: 718,
  },
  {
    name: "Xã Trừ Văn Thố",
    code: 25816,
    division_type: "xã",
    codename: "xa_tru_van_tho",
    district_code: 719,
  },
  {
    name: "Xã Cây Trường II",
    code: 25819,
    division_type: "xã",
    codename: "xa_cay_truong_ii",
    district_code: 719,
  },
  {
    name: "Thị trấn Lai Uyên",
    code: 25822,
    division_type: "thị trấn",
    codename: "thi_tran_lai_uyen",
    district_code: 719,
  },
  {
    name: "Xã Tân Hưng",
    code: 25825,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 719,
  },
  {
    name: "Xã Long Nguyên",
    code: 25828,
    division_type: "xã",
    codename: "xa_long_nguyen",
    district_code: 719,
  },
  {
    name: "Xã Hưng Hòa",
    code: 25831,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 719,
  },
  {
    name: "Xã Lai Hưng",
    code: 25834,
    division_type: "xã",
    codename: "xa_lai_hung",
    district_code: 719,
  },
  {
    name: "Thị trấn Dầu Tiếng",
    code: 25777,
    division_type: "thị trấn",
    codename: "thi_tran_dau_tieng",
    district_code: 720,
  },
  {
    name: "Xã Minh Hoà",
    code: 25780,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 720,
  },
  {
    name: "Xã Minh Thạnh",
    code: 25783,
    division_type: "xã",
    codename: "xa_minh_thanh",
    district_code: 720,
  },
  {
    name: "Xã Minh Tân",
    code: 25786,
    division_type: "xã",
    codename: "xa_minh_tan",
    district_code: 720,
  },
  {
    name: "Xã Định An",
    code: 25789,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 720,
  },
  {
    name: "Xã Long Hoà",
    code: 25792,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 720,
  },
  {
    name: "Xã Định Thành",
    code: 25795,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 720,
  },
  {
    name: "Xã Định Hiệp",
    code: 25798,
    division_type: "xã",
    codename: "xa_dinh_hiep",
    district_code: 720,
  },
  {
    name: "Xã An Lập",
    code: 25801,
    division_type: "xã",
    codename: "xa_an_lap",
    district_code: 720,
  },
  {
    name: "Xã Long Tân",
    code: 25804,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 720,
  },
  {
    name: "Xã Thanh An",
    code: 25807,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 720,
  },
  {
    name: "Xã Thanh Tuyền",
    code: 25810,
    division_type: "xã",
    codename: "xa_thanh_tuyen",
    district_code: 720,
  },
  {
    name: "Phường Mỹ Phước",
    code: 25813,
    division_type: "phường",
    codename: "phuong_my_phuoc",
    district_code: 721,
  },
  {
    name: "Phường Chánh Phú Hòa",
    code: 25837,
    division_type: "phường",
    codename: "phuong_chanh_phu_hoa",
    district_code: 721,
  },
  {
    name: "Xã An Điền",
    code: 25840,
    division_type: "xã",
    codename: "xa_an_dien",
    district_code: 721,
  },
  {
    name: "Xã An Tây",
    code: 25843,
    division_type: "xã",
    codename: "xa_an_tay",
    district_code: 721,
  },
  {
    name: "Phường Thới Hòa",
    code: 25846,
    division_type: "phường",
    codename: "phuong_thoi_hoa",
    district_code: 721,
  },
  {
    name: "Phường Hòa Lợi",
    code: 25849,
    division_type: "phường",
    codename: "phuong_hoa_loi",
    district_code: 721,
  },
  {
    name: "Phường Tân Định",
    code: 25852,
    division_type: "phường",
    codename: "phuong_tan_dinh",
    district_code: 721,
  },
  {
    name: "Xã Phú An",
    code: 25855,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 721,
  },
  {
    name: "Thị trấn Phước Vĩnh",
    code: 25858,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_vinh",
    district_code: 722,
  },
  {
    name: "Xã An Linh",
    code: 25861,
    division_type: "xã",
    codename: "xa_an_linh",
    district_code: 722,
  },
  {
    name: "Xã Phước Sang",
    code: 25864,
    division_type: "xã",
    codename: "xa_phuoc_sang",
    district_code: 722,
  },
  {
    name: "Xã An Thái",
    code: 25865,
    division_type: "xã",
    codename: "xa_an_thai",
    district_code: 722,
  },
  {
    name: "Xã An Long",
    code: 25867,
    division_type: "xã",
    codename: "xa_an_long",
    district_code: 722,
  },
  {
    name: "Xã An Bình",
    code: 25870,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 722,
  },
  {
    name: "Xã Tân Hiệp",
    code: 25873,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 722,
  },
  {
    name: "Xã Tam Lập",
    code: 25876,
    division_type: "xã",
    codename: "xa_tam_lap",
    district_code: 722,
  },
  {
    name: "Xã Tân Long",
    code: 25879,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 722,
  },
  {
    name: "Xã Vĩnh Hoà",
    code: 25882,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 722,
  },
  {
    name: "Xã Phước Hoà",
    code: 25885,
    division_type: "xã",
    codename: "xa_phuoc_hoa",
    district_code: 722,
  },
  {
    name: "Phường Uyên Hưng",
    code: 25888,
    division_type: "phường",
    codename: "phuong_uyen_hung",
    district_code: 723,
  },
  {
    name: "Phường Tân Phước Khánh",
    code: 25891,
    division_type: "phường",
    codename: "phuong_tan_phuoc_khanh",
    district_code: 723,
  },
  {
    name: "Phường Vĩnh Tân",
    code: 25912,
    division_type: "phường",
    codename: "phuong_vinh_tan",
    district_code: 723,
  },
  {
    name: "Phường Hội Nghĩa",
    code: 25915,
    division_type: "phường",
    codename: "phuong_hoi_nghia",
    district_code: 723,
  },
  {
    name: "Phường Tân Hiệp",
    code: 25920,
    division_type: "phường",
    codename: "phuong_tan_hiep",
    district_code: 723,
  },
  {
    name: "Phường Khánh Bình",
    code: 25921,
    division_type: "phường",
    codename: "phuong_khanh_binh",
    district_code: 723,
  },
  {
    name: "Phường Phú Chánh",
    code: 25924,
    division_type: "phường",
    codename: "phuong_phu_chanh",
    district_code: 723,
  },
  {
    name: "Xã Bạch Đằng",
    code: 25930,
    division_type: "xã",
    codename: "xa_bach_dang",
    district_code: 723,
  },
  {
    name: "Phường Tân Vĩnh Hiệp",
    code: 25933,
    division_type: "phường",
    codename: "phuong_tan_vinh_hiep",
    district_code: 723,
  },
  {
    name: "Phường Thạnh Phước",
    code: 25936,
    division_type: "phường",
    codename: "phuong_thanh_phuoc",
    district_code: 723,
  },
  {
    name: "Xã Thạnh Hội",
    code: 25937,
    division_type: "xã",
    codename: "xa_thanh_hoi",
    district_code: 723,
  },
  {
    name: "Phường Thái Hòa",
    code: 25939,
    division_type: "phường",
    codename: "phuong_thai_hoa",
    district_code: 723,
  },
  {
    name: "Phường Dĩ An",
    code: 25942,
    division_type: "phường",
    codename: "phuong_di_an",
    district_code: 724,
  },
  {
    name: "Phường Tân Bình",
    code: 25945,
    division_type: "phường",
    codename: "phuong_tan_binh",
    district_code: 724,
  },
  {
    name: "Phường Tân Đông Hiệp",
    code: 25948,
    division_type: "phường",
    codename: "phuong_tan_dong_hiep",
    district_code: 724,
  },
  {
    name: "Phường Bình An",
    code: 25951,
    division_type: "phường",
    codename: "phuong_binh_an",
    district_code: 724,
  },
  {
    name: "Phường Bình Thắng",
    code: 25954,
    division_type: "phường",
    codename: "phuong_binh_thang",
    district_code: 724,
  },
  {
    name: "Phường Đông Hòa",
    code: 25957,
    division_type: "phường",
    codename: "phuong_dong_hoa",
    district_code: 724,
  },
  {
    name: "Phường An Bình",
    code: 25960,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 724,
  },
  {
    name: "Phường An Thạnh",
    code: 25963,
    division_type: "phường",
    codename: "phuong_an_thanh",
    district_code: 725,
  },
  {
    name: "Phường Lái Thiêu",
    code: 25966,
    division_type: "phường",
    codename: "phuong_lai_thieu",
    district_code: 725,
  },
  {
    name: "Phường Bình Chuẩn",
    code: 25969,
    division_type: "phường",
    codename: "phuong_binh_chuan",
    district_code: 725,
  },
  {
    name: "Phường Thuận Giao",
    code: 25972,
    division_type: "phường",
    codename: "phuong_thuan_giao",
    district_code: 725,
  },
  {
    name: "Phường An Phú",
    code: 25975,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 725,
  },
  {
    name: "Phường Hưng Định",
    code: 25978,
    division_type: "phường",
    codename: "phuong_hung_dinh",
    district_code: 725,
  },
  {
    name: "Xã An Sơn",
    code: 25981,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 725,
  },
  {
    name: "Phường Bình Nhâm",
    code: 25984,
    division_type: "phường",
    codename: "phuong_binh_nham",
    district_code: 725,
  },
  {
    name: "Phường Bình Hòa",
    code: 25987,
    division_type: "phường",
    codename: "phuong_binh_hoa",
    district_code: 725,
  },
  {
    name: "Phường Vĩnh Phú",
    code: 25990,
    division_type: "phường",
    codename: "phuong_vinh_phu",
    district_code: 725,
  },
  {
    name: "Xã Tân Định",
    code: 25894,
    division_type: "xã",
    codename: "xa_tan_dinh",
    district_code: 726,
  },
  {
    name: "Xã Bình Mỹ",
    code: 25897,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 726,
  },
  {
    name: "Thị trấn Tân Bình",
    code: 25900,
    division_type: "thị trấn",
    codename: "thi_tran_tan_binh",
    district_code: 726,
  },
  {
    name: "Xã Tân Lập",
    code: 25903,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 726,
  },
  {
    name: "Thị trấn Tân Thành",
    code: 25906,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 726,
  },
  {
    name: "Xã Đất Cuốc",
    code: 25907,
    division_type: "xã",
    codename: "xa_dat_cuoc",
    district_code: 726,
  },
  {
    name: "Xã Hiếu Liêm",
    code: 25908,
    division_type: "xã",
    codename: "xa_hieu_liem",
    district_code: 726,
  },
  {
    name: "Xã Lạc An",
    code: 25909,
    division_type: "xã",
    codename: "xa_lac_an",
    district_code: 726,
  },
  {
    name: "Xã Tân Mỹ",
    code: 25918,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 726,
  },
  {
    name: "Xã Thường Tân",
    code: 25927,
    division_type: "xã",
    codename: "xa_thuong_tan",
    district_code: 726,
  },
  {
    name: "Phường Trảng Dài",
    code: 25993,
    division_type: "phường",
    codename: "phuong_trang_dai",
    district_code: 731,
  },
  {
    name: "Phường Tân Phong",
    code: 25996,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 731,
  },
  {
    name: "Phường Tân Biên",
    code: 25999,
    division_type: "phường",
    codename: "phuong_tan_bien",
    district_code: 731,
  },
  {
    name: "Phường Hố Nai",
    code: 26002,
    division_type: "phường",
    codename: "phuong_ho_nai",
    district_code: 731,
  },
  {
    name: "Phường Tân Hòa",
    code: 26005,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 731,
  },
  {
    name: "Phường Tân Hiệp",
    code: 26008,
    division_type: "phường",
    codename: "phuong_tan_hiep",
    district_code: 731,
  },
  {
    name: "Phường Bửu Long",
    code: 26011,
    division_type: "phường",
    codename: "phuong_buu_long",
    district_code: 731,
  },
  {
    name: "Phường Tân Tiến",
    code: 26014,
    division_type: "phường",
    codename: "phuong_tan_tien",
    district_code: 731,
  },
  {
    name: "Phường Tam Hiệp",
    code: 26017,
    division_type: "phường",
    codename: "phuong_tam_hiep",
    district_code: 731,
  },
  {
    name: "Phường Long Bình",
    code: 26020,
    division_type: "phường",
    codename: "phuong_long_binh",
    district_code: 731,
  },
  {
    name: "Phường Quang Vinh",
    code: 26023,
    division_type: "phường",
    codename: "phuong_quang_vinh",
    district_code: 731,
  },
  {
    name: "Phường Tân Mai",
    code: 26026,
    division_type: "phường",
    codename: "phuong_tan_mai",
    district_code: 731,
  },
  {
    name: "Phường Thống Nhất",
    code: 26029,
    division_type: "phường",
    codename: "phuong_thong_nhat",
    district_code: 731,
  },
  {
    name: "Phường Trung Dũng",
    code: 26032,
    division_type: "phường",
    codename: "phuong_trung_dung",
    district_code: 731,
  },
  {
    name: "Phường Tam Hòa",
    code: 26035,
    division_type: "phường",
    codename: "phuong_tam_hoa",
    district_code: 731,
  },
  {
    name: "Phường Hòa Bình",
    code: 26038,
    division_type: "phường",
    codename: "phuong_hoa_binh",
    district_code: 731,
  },
  {
    name: "Phường Quyết Thắng",
    code: 26041,
    division_type: "phường",
    codename: "phuong_quyet_thang",
    district_code: 731,
  },
  {
    name: "Phường Thanh Bình",
    code: 26044,
    division_type: "phường",
    codename: "phuong_thanh_binh",
    district_code: 731,
  },
  {
    name: "Phường Bình Đa",
    code: 26047,
    division_type: "phường",
    codename: "phuong_binh_da",
    district_code: 731,
  },
  {
    name: "Phường An Bình",
    code: 26050,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 731,
  },
  {
    name: "Phường Bửu Hòa",
    code: 26053,
    division_type: "phường",
    codename: "phuong_buu_hoa",
    district_code: 731,
  },
  {
    name: "Phường Long Bình Tân",
    code: 26056,
    division_type: "phường",
    codename: "phuong_long_binh_tan",
    district_code: 731,
  },
  {
    name: "Phường Tân Vạn",
    code: 26059,
    division_type: "phường",
    codename: "phuong_tan_van",
    district_code: 731,
  },
  {
    name: "Phường Tân Hạnh",
    code: 26062,
    division_type: "phường",
    codename: "phuong_tan_hanh",
    district_code: 731,
  },
  {
    name: "Phường Hiệp Hòa",
    code: 26065,
    division_type: "phường",
    codename: "phuong_hiep_hoa",
    district_code: 731,
  },
  {
    name: "Phường Hóa An",
    code: 26068,
    division_type: "phường",
    codename: "phuong_hoa_an",
    district_code: 731,
  },
  {
    name: "Phường An Hòa",
    code: 26371,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 731,
  },
  {
    name: "Phường Tam Phước",
    code: 26374,
    division_type: "phường",
    codename: "phuong_tam_phuoc",
    district_code: 731,
  },
  {
    name: "Phường Phước Tân",
    code: 26377,
    division_type: "phường",
    codename: "phuong_phuoc_tan",
    district_code: 731,
  },
  {
    name: "Xã Long Hưng",
    code: 26380,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 731,
  },
  {
    name: "Phường Xuân Trung",
    code: 26071,
    division_type: "phường",
    codename: "phuong_xuan_trung",
    district_code: 732,
  },
  {
    name: "Phường Xuân Thanh",
    code: 26074,
    division_type: "phường",
    codename: "phuong_xuan_thanh",
    district_code: 732,
  },
  {
    name: "Phường Xuân Bình",
    code: 26077,
    division_type: "phường",
    codename: "phuong_xuan_binh",
    district_code: 732,
  },
  {
    name: "Phường Xuân An",
    code: 26080,
    division_type: "phường",
    codename: "phuong_xuan_an",
    district_code: 732,
  },
  {
    name: "Phường Xuân Hoà",
    code: 26083,
    division_type: "phường",
    codename: "phuong_xuan_hoa",
    district_code: 732,
  },
  {
    name: "Phường Phú Bình",
    code: 26086,
    division_type: "phường",
    codename: "phuong_phu_binh",
    district_code: 732,
  },
  {
    name: "Xã Bình Lộc",
    code: 26089,
    division_type: "xã",
    codename: "xa_binh_loc",
    district_code: 732,
  },
  {
    name: "Xã Bảo Quang",
    code: 26092,
    division_type: "xã",
    codename: "xa_bao_quang",
    district_code: 732,
  },
  {
    name: "Phường Suối Tre",
    code: 26095,
    division_type: "phường",
    codename: "phuong_suoi_tre",
    district_code: 732,
  },
  {
    name: "Phường Bảo Vinh",
    code: 26098,
    division_type: "phường",
    codename: "phuong_bao_vinh",
    district_code: 732,
  },
  {
    name: "Phường Xuân Lập",
    code: 26101,
    division_type: "phường",
    codename: "phuong_xuan_lap",
    district_code: 732,
  },
  {
    name: "Phường Bàu Sen",
    code: 26104,
    division_type: "phường",
    codename: "phuong_bau_sen",
    district_code: 732,
  },
  {
    name: "Xã Bàu Trâm",
    code: 26107,
    division_type: "xã",
    codename: "xa_bau_tram",
    district_code: 732,
  },
  {
    name: "Phường Xuân Tân",
    code: 26110,
    division_type: "phường",
    codename: "phuong_xuan_tan",
    district_code: 732,
  },
  {
    name: "Xã Hàng Gòn",
    code: 26113,
    division_type: "xã",
    codename: "xa_hang_gon",
    district_code: 732,
  },
  {
    name: "Thị trấn Tân Phú",
    code: 26116,
    division_type: "thị trấn",
    codename: "thi_tran_tan_phu",
    district_code: 734,
  },
  {
    name: "Xã Dak Lua",
    code: 26119,
    division_type: "xã",
    codename: "xa_dak_lua",
    district_code: 734,
  },
  {
    name: "Xã Nam Cát Tiên",
    code: 26122,
    division_type: "xã",
    codename: "xa_nam_cat_tien",
    district_code: 734,
  },
  {
    name: "Xã Phú An",
    code: 26125,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 734,
  },
  {
    name: "Xã Núi Tượng",
    code: 26128,
    division_type: "xã",
    codename: "xa_nui_tuong",
    district_code: 734,
  },
  {
    name: "Xã Tà Lài",
    code: 26131,
    division_type: "xã",
    codename: "xa_ta_lai",
    district_code: 734,
  },
  {
    name: "Xã Phú Lập",
    code: 26134,
    division_type: "xã",
    codename: "xa_phu_lap",
    district_code: 734,
  },
  {
    name: "Xã Phú Sơn",
    code: 26137,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 734,
  },
  {
    name: "Xã Phú Thịnh",
    code: 26140,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 734,
  },
  {
    name: "Xã Thanh Sơn",
    code: 26143,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 734,
  },
  {
    name: "Xã Phú Trung",
    code: 26146,
    division_type: "xã",
    codename: "xa_phu_trung",
    district_code: 734,
  },
  {
    name: "Xã Phú Xuân",
    code: 26149,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 734,
  },
  {
    name: "Xã Phú Lộc",
    code: 26152,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 734,
  },
  {
    name: "Xã Phú Lâm",
    code: 26155,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 734,
  },
  {
    name: "Xã Phú Bình",
    code: 26158,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 734,
  },
  {
    name: "Xã Phú Thanh",
    code: 26161,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 734,
  },
  {
    name: "Xã Trà Cổ",
    code: 26164,
    division_type: "xã",
    codename: "xa_tra_co",
    district_code: 734,
  },
  {
    name: "Xã Phú Điền",
    code: 26167,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 734,
  },
  {
    name: "Thị trấn Vĩnh An",
    code: 26170,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_an",
    district_code: 735,
  },
  {
    name: "Xã Phú Lý",
    code: 26173,
    division_type: "xã",
    codename: "xa_phu_ly",
    district_code: 735,
  },
  {
    name: "Xã Trị An",
    code: 26176,
    division_type: "xã",
    codename: "xa_tri_an",
    district_code: 735,
  },
  {
    name: "Xã Tân An",
    code: 26179,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 735,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 26182,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 735,
  },
  {
    name: "Xã Bình Lợi",
    code: 26185,
    division_type: "xã",
    codename: "xa_binh_loi",
    district_code: 735,
  },
  {
    name: "Xã Thạnh Phú",
    code: 26188,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 735,
  },
  {
    name: "Xã Thiện Tân",
    code: 26191,
    division_type: "xã",
    codename: "xa_thien_tan",
    district_code: 735,
  },
  {
    name: "Xã Tân Bình",
    code: 26194,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 735,
  },
  {
    name: "Xã Bình Hòa",
    code: 26197,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 735,
  },
  {
    name: "Xã Mã Đà",
    code: 26200,
    division_type: "xã",
    codename: "xa_ma_da",
    district_code: 735,
  },
  {
    name: "Xã Hiếu Liêm",
    code: 26203,
    division_type: "xã",
    codename: "xa_hieu_liem",
    district_code: 735,
  },
  {
    name: "Thị trấn Định Quán",
    code: 26206,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_quan",
    district_code: 736,
  },
  {
    name: "Xã Thanh Sơn",
    code: 26209,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 736,
  },
  {
    name: "Xã Phú Tân",
    code: 26212,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 736,
  },
  {
    name: "Xã Phú Vinh",
    code: 26215,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 736,
  },
  {
    name: "Xã Phú Lợi",
    code: 26218,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 736,
  },
  {
    name: "Xã Phú Hòa",
    code: 26221,
    division_type: "xã",
    codename: "xa_phu_hoa",
    district_code: 736,
  },
  {
    name: "Xã Ngọc Định",
    code: 26224,
    division_type: "xã",
    codename: "xa_ngoc_dinh",
    district_code: 736,
  },
  {
    name: "Xã La Ngà",
    code: 26227,
    division_type: "xã",
    codename: "xa_la_nga",
    district_code: 736,
  },
  {
    name: "Xã Gia Canh",
    code: 26230,
    division_type: "xã",
    codename: "xa_gia_canh",
    district_code: 736,
  },
  {
    name: "Xã Phú Ngọc",
    code: 26233,
    division_type: "xã",
    codename: "xa_phu_ngoc",
    district_code: 736,
  },
  {
    name: "Xã Phú Cường",
    code: 26236,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 736,
  },
  {
    name: "Xã Túc Trưng",
    code: 26239,
    division_type: "xã",
    codename: "xa_tuc_trung",
    district_code: 736,
  },
  {
    name: "Xã Phú Túc",
    code: 26242,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 736,
  },
  {
    name: "Xã Suối Nho",
    code: 26245,
    division_type: "xã",
    codename: "xa_suoi_nho",
    district_code: 736,
  },
  {
    name: "Thị trấn Trảng Bom",
    code: 26248,
    division_type: "thị trấn",
    codename: "thi_tran_trang_bom",
    district_code: 737,
  },
  {
    name: "Xã Thanh Bình",
    code: 26251,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 737,
  },
  {
    name: "Xã Cây Gáo",
    code: 26254,
    division_type: "xã",
    codename: "xa_cay_gao",
    district_code: 737,
  },
  {
    name: "Xã Bàu Hàm",
    code: 26257,
    division_type: "xã",
    codename: "xa_bau_ham",
    district_code: 737,
  },
  {
    name: "Xã Sông Thao",
    code: 26260,
    division_type: "xã",
    codename: "xa_song_thao",
    district_code: 737,
  },
  {
    name: "Xã Sông Trầu",
    code: 26263,
    division_type: "xã",
    codename: "xa_song_trau",
    district_code: 737,
  },
  {
    name: "Xã Đông Hoà",
    code: 26266,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 737,
  },
  {
    name: "Xã Bắc Sơn",
    code: 26269,
    division_type: "xã",
    codename: "xa_bac_son",
    district_code: 737,
  },
  {
    name: "Xã Hố Nai 3",
    code: 26272,
    division_type: "xã",
    codename: "xa_ho_nai_3",
    district_code: 737,
  },
  {
    name: "Xã Tây Hoà",
    code: 26275,
    division_type: "xã",
    codename: "xa_tay_hoa",
    district_code: 737,
  },
  {
    name: "Xã Bình Minh",
    code: 26278,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 737,
  },
  {
    name: "Xã Trung Hoà",
    code: 26281,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 737,
  },
  {
    name: "Xã Đồi 61",
    code: 26284,
    division_type: "xã",
    codename: "xa_doi_61",
    district_code: 737,
  },
  {
    name: "Xã Hưng Thịnh",
    code: 26287,
    division_type: "xã",
    codename: "xa_hung_thinh",
    district_code: 737,
  },
  {
    name: "Xã Quảng Tiến",
    code: 26290,
    division_type: "xã",
    codename: "xa_quang_tien",
    district_code: 737,
  },
  {
    name: "Xã Giang Điền",
    code: 26293,
    division_type: "xã",
    codename: "xa_giang_dien",
    district_code: 737,
  },
  {
    name: "Xã An Viễn",
    code: 26296,
    division_type: "xã",
    codename: "xa_an_vien",
    district_code: 737,
  },
  {
    name: "Xã Gia Tân 1",
    code: 26299,
    division_type: "xã",
    codename: "xa_gia_tan_1",
    district_code: 738,
  },
  {
    name: "Xã Gia Tân 2",
    code: 26302,
    division_type: "xã",
    codename: "xa_gia_tan_2",
    district_code: 738,
  },
  {
    name: "Xã Gia Tân 3",
    code: 26305,
    division_type: "xã",
    codename: "xa_gia_tan_3",
    district_code: 738,
  },
  {
    name: "Xã Gia Kiệm",
    code: 26308,
    division_type: "xã",
    codename: "xa_gia_kiem",
    district_code: 738,
  },
  {
    name: "Xã Quang Trung",
    code: 26311,
    division_type: "xã",
    codename: "xa_quang_trung",
    district_code: 738,
  },
  {
    name: "Xã Bàu Hàm 2",
    code: 26314,
    division_type: "xã",
    codename: "xa_bau_ham_2",
    district_code: 738,
  },
  {
    name: "Xã Hưng Lộc",
    code: 26317,
    division_type: "xã",
    codename: "xa_hung_loc",
    district_code: 738,
  },
  {
    name: "Xã Lộ 25",
    code: 26320,
    division_type: "xã",
    codename: "xa_lo_25",
    district_code: 738,
  },
  {
    name: "Xã Xuân Thiện",
    code: 26323,
    division_type: "xã",
    codename: "xa_xuan_thien",
    district_code: 738,
  },
  {
    name: "Thị trấn Dầu Giây",
    code: 26326,
    division_type: "thị trấn",
    codename: "thi_tran_dau_giay",
    district_code: 738,
  },
  {
    name: "Xã Sông Nhạn",
    code: 26329,
    division_type: "xã",
    codename: "xa_song_nhan",
    district_code: 739,
  },
  {
    name: "Xã Xuân Quế",
    code: 26332,
    division_type: "xã",
    codename: "xa_xuan_que",
    district_code: 739,
  },
  {
    name: "Xã Nhân Nghĩa",
    code: 26335,
    division_type: "xã",
    codename: "xa_nhan_nghia",
    district_code: 739,
  },
  {
    name: "Xã Xuân Đường",
    code: 26338,
    division_type: "xã",
    codename: "xa_xuan_duong",
    district_code: 739,
  },
  {
    name: "Xã Long Giao",
    code: 26341,
    division_type: "xã",
    codename: "xa_long_giao",
    district_code: 739,
  },
  {
    name: "Xã Xuân Mỹ",
    code: 26344,
    division_type: "xã",
    codename: "xa_xuan_my",
    district_code: 739,
  },
  {
    name: "Xã Thừa Đức",
    code: 26347,
    division_type: "xã",
    codename: "xa_thua_duc",
    district_code: 739,
  },
  {
    name: "Xã Bảo Bình",
    code: 26350,
    division_type: "xã",
    codename: "xa_bao_binh",
    district_code: 739,
  },
  {
    name: "Xã Xuân Bảo",
    code: 26353,
    division_type: "xã",
    codename: "xa_xuan_bao",
    district_code: 739,
  },
  {
    name: "Xã Xuân Tây",
    code: 26356,
    division_type: "xã",
    codename: "xa_xuan_tay",
    district_code: 739,
  },
  {
    name: "Xã Xuân Đông",
    code: 26359,
    division_type: "xã",
    codename: "xa_xuan_dong",
    district_code: 739,
  },
  {
    name: "Xã Sông Ray",
    code: 26362,
    division_type: "xã",
    codename: "xa_song_ray",
    district_code: 739,
  },
  {
    name: "Xã Lâm San",
    code: 26365,
    division_type: "xã",
    codename: "xa_lam_san",
    district_code: 739,
  },
  {
    name: "Thị trấn Long Thành",
    code: 26368,
    division_type: "thị trấn",
    codename: "thi_tran_long_thanh",
    district_code: 740,
  },
  {
    name: "Xã An Phước",
    code: 26383,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 740,
  },
  {
    name: "Xã Bình An",
    code: 26386,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 740,
  },
  {
    name: "Xã Long Đức",
    code: 26389,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 740,
  },
  {
    name: "Xã Lộc An",
    code: 26392,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 740,
  },
  {
    name: "Xã Bình Sơn",
    code: 26395,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 740,
  },
  {
    name: "Xã Tam An",
    code: 26398,
    division_type: "xã",
    codename: "xa_tam_an",
    district_code: 740,
  },
  {
    name: "Xã Cẩm Đường",
    code: 26401,
    division_type: "xã",
    codename: "xa_cam_duong",
    district_code: 740,
  },
  {
    name: "Xã Long An",
    code: 26404,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 740,
  },
  {
    name: "Xã Bàu Cạn",
    code: 26410,
    division_type: "xã",
    codename: "xa_bau_can",
    district_code: 740,
  },
  {
    name: "Xã Long Phước",
    code: 26413,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 740,
  },
  {
    name: "Xã Phước Bình",
    code: 26416,
    division_type: "xã",
    codename: "xa_phuoc_binh",
    district_code: 740,
  },
  {
    name: "Xã Tân Hiệp",
    code: 26419,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 740,
  },
  {
    name: "Xã Phước Thái",
    code: 26422,
    division_type: "xã",
    codename: "xa_phuoc_thai",
    district_code: 740,
  },
  {
    name: "Thị trấn Gia Ray",
    code: 26425,
    division_type: "thị trấn",
    codename: "thi_tran_gia_ray",
    district_code: 741,
  },
  {
    name: "Xã Xuân Bắc",
    code: 26428,
    division_type: "xã",
    codename: "xa_xuan_bac",
    district_code: 741,
  },
  {
    name: "Xã Suối Cao",
    code: 26431,
    division_type: "xã",
    codename: "xa_suoi_cao",
    district_code: 741,
  },
  {
    name: "Xã Xuân Thành",
    code: 26434,
    division_type: "xã",
    codename: "xa_xuan_thanh",
    district_code: 741,
  },
  {
    name: "Xã Xuân Thọ",
    code: 26437,
    division_type: "xã",
    codename: "xa_xuan_tho",
    district_code: 741,
  },
  {
    name: "Xã Xuân Trường",
    code: 26440,
    division_type: "xã",
    codename: "xa_xuan_truong",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hòa",
    code: 26443,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hưng",
    code: 26446,
    division_type: "xã",
    codename: "xa_xuan_hung",
    district_code: 741,
  },
  {
    name: "Xã Xuân Tâm",
    code: 26449,
    division_type: "xã",
    codename: "xa_xuan_tam",
    district_code: 741,
  },
  {
    name: "Xã Suối Cát",
    code: 26452,
    division_type: "xã",
    codename: "xa_suoi_cat",
    district_code: 741,
  },
  {
    name: "Xã Xuân Hiệp",
    code: 26455,
    division_type: "xã",
    codename: "xa_xuan_hiep",
    district_code: 741,
  },
  {
    name: "Xã Xuân Phú",
    code: 26458,
    division_type: "xã",
    codename: "xa_xuan_phu",
    district_code: 741,
  },
  {
    name: "Xã Xuân Định",
    code: 26461,
    division_type: "xã",
    codename: "xa_xuan_dinh",
    district_code: 741,
  },
  {
    name: "Xã Bảo Hoà",
    code: 26464,
    division_type: "xã",
    codename: "xa_bao_hoa",
    district_code: 741,
  },
  {
    name: "Xã Lang Minh",
    code: 26467,
    division_type: "xã",
    codename: "xa_lang_minh",
    district_code: 741,
  },
  {
    name: "Xã Phước Thiền",
    code: 26470,
    division_type: "xã",
    codename: "xa_phuoc_thien",
    district_code: 742,
  },
  {
    name: "Xã Long Tân",
    code: 26473,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 742,
  },
  {
    name: "Xã Đại Phước",
    code: 26476,
    division_type: "xã",
    codename: "xa_dai_phuoc",
    district_code: 742,
  },
  {
    name: "Thị trấn Hiệp Phước",
    code: 26479,
    division_type: "thị trấn",
    codename: "thi_tran_hiep_phuoc",
    district_code: 742,
  },
  {
    name: "Xã Phú Hữu",
    code: 26482,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 742,
  },
  {
    name: "Xã Phú Hội",
    code: 26485,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 742,
  },
  {
    name: "Xã Phú Thạnh",
    code: 26488,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 742,
  },
  {
    name: "Xã Phú Đông",
    code: 26491,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 742,
  },
  {
    name: "Xã Long Thọ",
    code: 26494,
    division_type: "xã",
    codename: "xa_long_tho",
    district_code: 742,
  },
  {
    name: "Xã Vĩnh Thanh",
    code: 26497,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 742,
  },
  {
    name: "Xã Phước Khánh",
    code: 26500,
    division_type: "xã",
    codename: "xa_phuoc_khanh",
    district_code: 742,
  },
  {
    name: "Xã Phước An",
    code: 26503,
    division_type: "xã",
    codename: "xa_phuoc_an",
    district_code: 742,
  },
  {
    name: "Phường 1",
    code: 26506,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 747,
  },
  {
    name: "Phường Thắng Tam",
    code: 26508,
    division_type: "phường",
    codename: "phuong_thang_tam",
    district_code: 747,
  },
  {
    name: "Phường 2",
    code: 26509,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 747,
  },
  {
    name: "Phường 3",
    code: 26512,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 747,
  },
  {
    name: "Phường 4",
    code: 26515,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 747,
  },
  {
    name: "Phường 5",
    code: 26518,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 747,
  },
  {
    name: "Phường Thắng Nhì",
    code: 26521,
    division_type: "phường",
    codename: "phuong_thang_nhi",
    district_code: 747,
  },
  {
    name: "Phường 7",
    code: 26524,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 747,
  },
  {
    name: "Phường Nguyễn An Ninh",
    code: 26526,
    division_type: "phường",
    codename: "phuong_nguyen_an_ninh",
    district_code: 747,
  },
  {
    name: "Phường 8",
    code: 26527,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 747,
  },
  {
    name: "Phường 9",
    code: 26530,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 747,
  },
  {
    name: "Phường Thắng Nhất",
    code: 26533,
    division_type: "phường",
    codename: "phuong_thang_nhat",
    district_code: 747,
  },
  {
    name: "Phường Rạch Dừa",
    code: 26535,
    division_type: "phường",
    codename: "phuong_rach_dua",
    district_code: 747,
  },
  {
    name: "Phường 10",
    code: 26536,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 747,
  },
  {
    name: "Phường 11",
    code: 26539,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 747,
  },
  {
    name: "Phường 12",
    code: 26542,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 747,
  },
  {
    name: "Xã Long Sơn",
    code: 26545,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 747,
  },
  {
    name: "Phường Phước Hưng",
    code: 26548,
    division_type: "phường",
    codename: "phuong_phuoc_hung",
    district_code: 748,
  },
  {
    name: "Phường Phước Hiệp",
    code: 26551,
    division_type: "phường",
    codename: "phuong_phuoc_hiep",
    district_code: 748,
  },
  {
    name: "Phường Phước Nguyên",
    code: 26554,
    division_type: "phường",
    codename: "phuong_phuoc_nguyen",
    district_code: 748,
  },
  {
    name: "Phường Long Toàn",
    code: 26557,
    division_type: "phường",
    codename: "phuong_long_toan",
    district_code: 748,
  },
  {
    name: "Phường Long Tâm",
    code: 26558,
    division_type: "phường",
    codename: "phuong_long_tam",
    district_code: 748,
  },
  {
    name: "Phường Phước Trung",
    code: 26560,
    division_type: "phường",
    codename: "phuong_phuoc_trung",
    district_code: 748,
  },
  {
    name: "Phường Long Hương",
    code: 26563,
    division_type: "phường",
    codename: "phuong_long_huong",
    district_code: 748,
  },
  {
    name: "Phường Kim Dinh",
    code: 26566,
    division_type: "phường",
    codename: "phuong_kim_dinh",
    district_code: 748,
  },
  {
    name: "Xã Tân Hưng",
    code: 26567,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 748,
  },
  {
    name: "Xã Long Phước",
    code: 26569,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 748,
  },
  {
    name: "Xã Hoà Long",
    code: 26572,
    division_type: "xã",
    codename: "xa_hoa_long",
    district_code: 748,
  },
  {
    name: "Xã Bàu Chinh",
    code: 26574,
    division_type: "xã",
    codename: "xa_bau_chinh",
    district_code: 750,
  },
  {
    name: "Thị trấn Ngãi Giao",
    code: 26575,
    division_type: "thị trấn",
    codename: "thi_tran_ngai_giao",
    district_code: 750,
  },
  {
    name: "Xã Bình Ba",
    code: 26578,
    division_type: "xã",
    codename: "xa_binh_ba",
    district_code: 750,
  },
  {
    name: "Xã Suối Nghệ",
    code: 26581,
    division_type: "xã",
    codename: "xa_suoi_nghe",
    district_code: 750,
  },
  {
    name: "Xã Xuân Sơn",
    code: 26584,
    division_type: "xã",
    codename: "xa_xuan_son",
    district_code: 750,
  },
  {
    name: "Xã Sơn Bình",
    code: 26587,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 750,
  },
  {
    name: "Xã Bình Giã",
    code: 26590,
    division_type: "xã",
    codename: "xa_binh_gia",
    district_code: 750,
  },
  {
    name: "Xã Bình Trung",
    code: 26593,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 750,
  },
  {
    name: "Xã Xà Bang",
    code: 26596,
    division_type: "xã",
    codename: "xa_xa_bang",
    district_code: 750,
  },
  {
    name: "Xã Cù Bị",
    code: 26599,
    division_type: "xã",
    codename: "xa_cu_bi",
    district_code: 750,
  },
  {
    name: "Xã Láng Lớn",
    code: 26602,
    division_type: "xã",
    codename: "xa_lang_lon",
    district_code: 750,
  },
  {
    name: "Xã Quảng Thành",
    code: 26605,
    division_type: "xã",
    codename: "xa_quang_thanh",
    district_code: 750,
  },
  {
    name: "Xã Kim Long",
    code: 26608,
    division_type: "xã",
    codename: "xa_kim_long",
    district_code: 750,
  },
  {
    name: "Xã Suối Rao",
    code: 26611,
    division_type: "xã",
    codename: "xa_suoi_rao",
    district_code: 750,
  },
  {
    name: "Xã Đá Bạc",
    code: 26614,
    division_type: "xã",
    codename: "xa_da_bac",
    district_code: 750,
  },
  {
    name: "Xã Nghĩa Thành",
    code: 26617,
    division_type: "xã",
    codename: "xa_nghia_thanh",
    district_code: 750,
  },
  {
    name: "Thị trấn Phước Bửu",
    code: 26620,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_buu",
    district_code: 751,
  },
  {
    name: "Xã Phước Thuận",
    code: 26623,
    division_type: "xã",
    codename: "xa_phuoc_thuan",
    district_code: 751,
  },
  {
    name: "Xã Phước Tân",
    code: 26626,
    division_type: "xã",
    codename: "xa_phuoc_tan",
    district_code: 751,
  },
  {
    name: "Xã Xuyên Mộc",
    code: 26629,
    division_type: "xã",
    codename: "xa_xuyen_moc",
    district_code: 751,
  },
  {
    name: "Xã Bông Trang",
    code: 26632,
    division_type: "xã",
    codename: "xa_bong_trang",
    district_code: 751,
  },
  {
    name: "Xã Tân Lâm",
    code: 26635,
    division_type: "xã",
    codename: "xa_tan_lam",
    district_code: 751,
  },
  {
    name: "Xã Bàu Lâm",
    code: 26638,
    division_type: "xã",
    codename: "xa_bau_lam",
    district_code: 751,
  },
  {
    name: "Xã Hòa Bình",
    code: 26641,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hưng",
    code: 26644,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 26647,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 751,
  },
  {
    name: "Xã Hòa Hội",
    code: 26650,
    division_type: "xã",
    codename: "xa_hoa_hoi",
    district_code: 751,
  },
  {
    name: "Xã Bưng Riềng",
    code: 26653,
    division_type: "xã",
    codename: "xa_bung_rieng",
    district_code: 751,
  },
  {
    name: "Xã Bình Châu",
    code: 26656,
    division_type: "xã",
    codename: "xa_binh_chau",
    district_code: 751,
  },
  {
    name: "Thị trấn Long Điền",
    code: 26659,
    division_type: "thị trấn",
    codename: "thi_tran_long_dien",
    district_code: 752,
  },
  {
    name: "Thị trấn Long Hải",
    code: 26662,
    division_type: "thị trấn",
    codename: "thi_tran_long_hai",
    district_code: 752,
  },
  {
    name: "Xã An Ngãi",
    code: 26665,
    division_type: "xã",
    codename: "xa_an_ngai",
    district_code: 752,
  },
  {
    name: "Xã Tam Phước",
    code: 26668,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 752,
  },
  {
    name: "Xã An Nhứt",
    code: 26671,
    division_type: "xã",
    codename: "xa_an_nhut",
    district_code: 752,
  },
  {
    name: "Xã Phước Tỉnh",
    code: 26674,
    division_type: "xã",
    codename: "xa_phuoc_tinh",
    district_code: 752,
  },
  {
    name: "Xã Phước Hưng",
    code: 26677,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 752,
  },
  {
    name: "Thị trấn Đất Đỏ",
    code: 26680,
    division_type: "thị trấn",
    codename: "thi_tran_dat_do",
    district_code: 753,
  },
  {
    name: "Xã Phước Long Thọ",
    code: 26683,
    division_type: "xã",
    codename: "xa_phuoc_long_tho",
    district_code: 753,
  },
  {
    name: "Xã Phước Hội",
    code: 26686,
    division_type: "xã",
    codename: "xa_phuoc_hoi",
    district_code: 753,
  },
  {
    name: "Xã Long Mỹ",
    code: 26689,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 753,
  },
  {
    name: "Thị trấn Phước Hải",
    code: 26692,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_hai",
    district_code: 753,
  },
  {
    name: "Xã Long Tân",
    code: 26695,
    division_type: "xã",
    codename: "xa_long_tan",
    district_code: 753,
  },
  {
    name: "Xã Láng Dài",
    code: 26698,
    division_type: "xã",
    codename: "xa_lang_dai",
    district_code: 753,
  },
  {
    name: "Xã Lộc An",
    code: 26701,
    division_type: "xã",
    codename: "xa_loc_an",
    district_code: 753,
  },
  {
    name: "Phường Phú Mỹ",
    code: 26704,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 754,
  },
  {
    name: "Xã Tân Hoà",
    code: 26707,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 754,
  },
  {
    name: "Xã Tân Hải",
    code: 26710,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 754,
  },
  {
    name: "Phường Phước Hoà",
    code: 26713,
    division_type: "phường",
    codename: "phuong_phuoc_hoa",
    district_code: 754,
  },
  {
    name: "Phường Tân Phước",
    code: 26716,
    division_type: "phường",
    codename: "phuong_tan_phuoc",
    district_code: 754,
  },
  {
    name: "Phường Mỹ Xuân",
    code: 26719,
    division_type: "phường",
    codename: "phuong_my_xuan",
    district_code: 754,
  },
  {
    name: "Xã Sông Xoài",
    code: 26722,
    division_type: "xã",
    codename: "xa_song_xoai",
    district_code: 754,
  },
  {
    name: "Phường Hắc Dịch",
    code: 26725,
    division_type: "phường",
    codename: "phuong_hac_dich",
    district_code: 754,
  },
  {
    name: "Xã Châu Pha",
    code: 26728,
    division_type: "xã",
    codename: "xa_chau_pha",
    district_code: 754,
  },
  {
    name: "Xã Tóc Tiên",
    code: 26731,
    division_type: "xã",
    codename: "xa_toc_tien",
    district_code: 754,
  },
  {
    name: "Phường Tân Định",
    code: 26734,
    division_type: "phường",
    codename: "phuong_tan_dinh",
    district_code: 760,
  },
  {
    name: "Phường Đa Kao",
    code: 26737,
    division_type: "phường",
    codename: "phuong_da_kao",
    district_code: 760,
  },
  {
    name: "Phường Bến Nghé",
    code: 26740,
    division_type: "phường",
    codename: "phuong_ben_nghe",
    district_code: 760,
  },
  {
    name: "Phường Bến Thành",
    code: 26743,
    division_type: "phường",
    codename: "phuong_ben_thanh",
    district_code: 760,
  },
  {
    name: "Phường Nguyễn Thái Bình",
    code: 26746,
    division_type: "phường",
    codename: "phuong_nguyen_thai_binh",
    district_code: 760,
  },
  {
    name: "Phường Phạm Ngũ Lão",
    code: 26749,
    division_type: "phường",
    codename: "phuong_pham_ngu_lao",
    district_code: 760,
  },
  {
    name: "Phường Cầu Ông Lãnh",
    code: 26752,
    division_type: "phường",
    codename: "phuong_cau_ong_lanh",
    district_code: 760,
  },
  {
    name: "Phường Cô Giang",
    code: 26755,
    division_type: "phường",
    codename: "phuong_co_giang",
    district_code: 760,
  },
  {
    name: "Phường Nguyễn Cư Trinh",
    code: 26758,
    division_type: "phường",
    codename: "phuong_nguyen_cu_trinh",
    district_code: 760,
  },
  {
    name: "Phường Cầu Kho",
    code: 26761,
    division_type: "phường",
    codename: "phuong_cau_kho",
    district_code: 760,
  },
  {
    name: "Phường Thạnh Xuân",
    code: 26764,
    division_type: "phường",
    codename: "phuong_thanh_xuan",
    district_code: 761,
  },
  {
    name: "Phường Thạnh Lộc",
    code: 26767,
    division_type: "phường",
    codename: "phuong_thanh_loc",
    district_code: 761,
  },
  {
    name: "Phường Hiệp Thành",
    code: 26770,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 761,
  },
  {
    name: "Phường Thới An",
    code: 26773,
    division_type: "phường",
    codename: "phuong_thoi_an",
    district_code: 761,
  },
  {
    name: "Phường Tân Chánh Hiệp",
    code: 26776,
    division_type: "phường",
    codename: "phuong_tan_chanh_hiep",
    district_code: 761,
  },
  {
    name: "Phường An Phú Đông",
    code: 26779,
    division_type: "phường",
    codename: "phuong_an_phu_dong",
    district_code: 761,
  },
  {
    name: "Phường Tân Thới Hiệp",
    code: 26782,
    division_type: "phường",
    codename: "phuong_tan_thoi_hiep",
    district_code: 761,
  },
  {
    name: "Phường Trung Mỹ Tây",
    code: 26785,
    division_type: "phường",
    codename: "phuong_trung_my_tay",
    district_code: 761,
  },
  {
    name: "Phường Tân Hưng Thuận",
    code: 26787,
    division_type: "phường",
    codename: "phuong_tan_hung_thuan",
    district_code: 761,
  },
  {
    name: "Phường Đông Hưng Thuận",
    code: 26788,
    division_type: "phường",
    codename: "phuong_dong_hung_thuan",
    district_code: 761,
  },
  {
    name: "Phường Tân Thới Nhất",
    code: 26791,
    division_type: "phường",
    codename: "phuong_tan_thoi_nhat",
    district_code: 761,
  },
  {
    name: "Phường 15",
    code: 26869,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 764,
  },
  {
    name: "Phường 13",
    code: 26872,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 764,
  },
  {
    name: "Phường 17",
    code: 26875,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 764,
  },
  {
    name: "Phường 6",
    code: 26876,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 764,
  },
  {
    name: "Phường 16",
    code: 26878,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 764,
  },
  {
    name: "Phường 12",
    code: 26881,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 764,
  },
  {
    name: "Phường 14",
    code: 26882,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 764,
  },
  {
    name: "Phường 10",
    code: 26884,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 764,
  },
  {
    name: "Phường 05",
    code: 26887,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 764,
  },
  {
    name: "Phường 07",
    code: 26890,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 764,
  },
  {
    name: "Phường 04",
    code: 26893,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 764,
  },
  {
    name: "Phường 01",
    code: 26896,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 764,
  },
  {
    name: "Phường 9",
    code: 26897,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 764,
  },
  {
    name: "Phường 8",
    code: 26898,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 764,
  },
  {
    name: "Phường 11",
    code: 26899,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 764,
  },
  {
    name: "Phường 03",
    code: 26902,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 764,
  },
  {
    name: "Phường 13",
    code: 26905,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 765,
  },
  {
    name: "Phường 11",
    code: 26908,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 765,
  },
  {
    name: "Phường 27",
    code: 26911,
    division_type: "phường",
    codename: "phuong_27",
    district_code: 765,
  },
  {
    name: "Phường 26",
    code: 26914,
    division_type: "phường",
    codename: "phuong_26",
    district_code: 765,
  },
  {
    name: "Phường 12",
    code: 26917,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 765,
  },
  {
    name: "Phường 25",
    code: 26920,
    division_type: "phường",
    codename: "phuong_25",
    district_code: 765,
  },
  {
    name: "Phường 05",
    code: 26923,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 765,
  },
  {
    name: "Phường 07",
    code: 26926,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 765,
  },
  {
    name: "Phường 24",
    code: 26929,
    division_type: "phường",
    codename: "phuong_24",
    district_code: 765,
  },
  {
    name: "Phường 06",
    code: 26932,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 765,
  },
  {
    name: "Phường 14",
    code: 26935,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 765,
  },
  {
    name: "Phường 15",
    code: 26938,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 765,
  },
  {
    name: "Phường 02",
    code: 26941,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 765,
  },
  {
    name: "Phường 01",
    code: 26944,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 765,
  },
  {
    name: "Phường 03",
    code: 26947,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 765,
  },
  {
    name: "Phường 17",
    code: 26950,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 765,
  },
  {
    name: "Phường 21",
    code: 26953,
    division_type: "phường",
    codename: "phuong_21",
    district_code: 765,
  },
  {
    name: "Phường 22",
    code: 26956,
    division_type: "phường",
    codename: "phuong_22",
    district_code: 765,
  },
  {
    name: "Phường 19",
    code: 26959,
    division_type: "phường",
    codename: "phuong_19",
    district_code: 765,
  },
  {
    name: "Phường 28",
    code: 26962,
    division_type: "phường",
    codename: "phuong_28",
    district_code: 765,
  },
  {
    name: "Phường 02",
    code: 26965,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 766,
  },
  {
    name: "Phường 04",
    code: 26968,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 766,
  },
  {
    name: "Phường 12",
    code: 26971,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 766,
  },
  {
    name: "Phường 13",
    code: 26974,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 766,
  },
  {
    name: "Phường 01",
    code: 26977,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 766,
  },
  {
    name: "Phường 03",
    code: 26980,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 766,
  },
  {
    name: "Phường 11",
    code: 26983,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 766,
  },
  {
    name: "Phường 07",
    code: 26986,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 766,
  },
  {
    name: "Phường 05",
    code: 26989,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 766,
  },
  {
    name: "Phường 10",
    code: 26992,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 766,
  },
  {
    name: "Phường 06",
    code: 26995,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 766,
  },
  {
    name: "Phường 08",
    code: 26998,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 766,
  },
  {
    name: "Phường 09",
    code: 27001,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 766,
  },
  {
    name: "Phường 14",
    code: 27004,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 766,
  },
  {
    name: "Phường 15",
    code: 27007,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 766,
  },
  {
    name: "Phường Tân Sơn Nhì",
    code: 27010,
    division_type: "phường",
    codename: "phuong_tan_son_nhi",
    district_code: 767,
  },
  {
    name: "Phường Tây Thạnh",
    code: 27013,
    division_type: "phường",
    codename: "phuong_tay_thanh",
    district_code: 767,
  },
  {
    name: "Phường Sơn Kỳ",
    code: 27016,
    division_type: "phường",
    codename: "phuong_son_ky",
    district_code: 767,
  },
  {
    name: "Phường Tân Quý",
    code: 27019,
    division_type: "phường",
    codename: "phuong_tan_quy",
    district_code: 767,
  },
  {
    name: "Phường Tân Thành",
    code: 27022,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 767,
  },
  {
    name: "Phường Phú Thọ Hòa",
    code: 27025,
    division_type: "phường",
    codename: "phuong_phu_tho_hoa",
    district_code: 767,
  },
  {
    name: "Phường Phú Thạnh",
    code: 27028,
    division_type: "phường",
    codename: "phuong_phu_thanh",
    district_code: 767,
  },
  {
    name: "Phường Phú Trung",
    code: 27031,
    division_type: "phường",
    codename: "phuong_phu_trung",
    district_code: 767,
  },
  {
    name: "Phường Hòa Thạnh",
    code: 27034,
    division_type: "phường",
    codename: "phuong_hoa_thanh",
    district_code: 767,
  },
  {
    name: "Phường Hiệp Tân",
    code: 27037,
    division_type: "phường",
    codename: "phuong_hiep_tan",
    district_code: 767,
  },
  {
    name: "Phường Tân Thới Hòa",
    code: 27040,
    division_type: "phường",
    codename: "phuong_tan_thoi_hoa",
    district_code: 767,
  },
  {
    name: "Phường 04",
    code: 27043,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 768,
  },
  {
    name: "Phường 05",
    code: 27046,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 768,
  },
  {
    name: "Phường 09",
    code: 27049,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 768,
  },
  {
    name: "Phường 07",
    code: 27052,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 768,
  },
  {
    name: "Phường 03",
    code: 27055,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 768,
  },
  {
    name: "Phường 01",
    code: 27058,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 768,
  },
  {
    name: "Phường 02",
    code: 27061,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 768,
  },
  {
    name: "Phường 08",
    code: 27064,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 768,
  },
  {
    name: "Phường 15",
    code: 27067,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 768,
  },
  {
    name: "Phường 10",
    code: 27070,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 768,
  },
  {
    name: "Phường 11",
    code: 27073,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 768,
  },
  {
    name: "Phường 17",
    code: 27076,
    division_type: "phường",
    codename: "phuong_17",
    district_code: 768,
  },
  {
    name: "Phường 13",
    code: 27085,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 768,
  },
  {
    name: "Phường Linh Xuân",
    code: 26794,
    division_type: "phường",
    codename: "phuong_linh_xuan",
    district_code: 769,
  },
  {
    name: "Phường Bình Chiểu",
    code: 26797,
    division_type: "phường",
    codename: "phuong_binh_chieu",
    district_code: 769,
  },
  {
    name: "Phường Linh Trung",
    code: 26800,
    division_type: "phường",
    codename: "phuong_linh_trung",
    district_code: 769,
  },
  {
    name: "Phường Tam Bình",
    code: 26803,
    division_type: "phường",
    codename: "phuong_tam_binh",
    district_code: 769,
  },
  {
    name: "Phường Tam Phú",
    code: 26806,
    division_type: "phường",
    codename: "phuong_tam_phu",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Bình Phước",
    code: 26809,
    division_type: "phường",
    codename: "phuong_hiep_binh_phuoc",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Bình Chánh",
    code: 26812,
    division_type: "phường",
    codename: "phuong_hiep_binh_chanh",
    district_code: 769,
  },
  {
    name: "Phường Linh Chiểu",
    code: 26815,
    division_type: "phường",
    codename: "phuong_linh_chieu",
    district_code: 769,
  },
  {
    name: "Phường Linh Tây",
    code: 26818,
    division_type: "phường",
    codename: "phuong_linh_tay",
    district_code: 769,
  },
  {
    name: "Phường Linh Đông",
    code: 26821,
    division_type: "phường",
    codename: "phuong_linh_dong",
    district_code: 769,
  },
  {
    name: "Phường Bình Thọ",
    code: 26824,
    division_type: "phường",
    codename: "phuong_binh_tho",
    district_code: 769,
  },
  {
    name: "Phường Trường Thọ",
    code: 26827,
    division_type: "phường",
    codename: "phuong_truong_tho",
    district_code: 769,
  },
  {
    name: "Phường Long Bình",
    code: 26830,
    division_type: "phường",
    codename: "phuong_long_binh",
    district_code: 769,
  },
  {
    name: "Phường Long Thạnh Mỹ",
    code: 26833,
    division_type: "phường",
    codename: "phuong_long_thanh_my",
    district_code: 769,
  },
  {
    name: "Phường Tân Phú",
    code: 26836,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 769,
  },
  {
    name: "Phường Hiệp Phú",
    code: 26839,
    division_type: "phường",
    codename: "phuong_hiep_phu",
    district_code: 769,
  },
  {
    name: "Phường Tăng Nhơn Phú A",
    code: 26842,
    division_type: "phường",
    codename: "phuong_tang_nhon_phu_a",
    district_code: 769,
  },
  {
    name: "Phường Tăng Nhơn Phú B",
    code: 26845,
    division_type: "phường",
    codename: "phuong_tang_nhon_phu_b",
    district_code: 769,
  },
  {
    name: "Phường Phước Long B",
    code: 26848,
    division_type: "phường",
    codename: "phuong_phuoc_long_b",
    district_code: 769,
  },
  {
    name: "Phường Phước Long A",
    code: 26851,
    division_type: "phường",
    codename: "phuong_phuoc_long_a",
    district_code: 769,
  },
  {
    name: "Phường Trường Thạnh",
    code: 26854,
    division_type: "phường",
    codename: "phuong_truong_thanh",
    district_code: 769,
  },
  {
    name: "Phường Long Phước",
    code: 26857,
    division_type: "phường",
    codename: "phuong_long_phuoc",
    district_code: 769,
  },
  {
    name: "Phường Long Trường",
    code: 26860,
    division_type: "phường",
    codename: "phuong_long_truong",
    district_code: 769,
  },
  {
    name: "Phường Phước Bình",
    code: 26863,
    division_type: "phường",
    codename: "phuong_phuoc_binh",
    district_code: 769,
  },
  {
    name: "Phường Phú Hữu",
    code: 26866,
    division_type: "phường",
    codename: "phuong_phu_huu",
    district_code: 769,
  },
  {
    name: "Phường Thảo Điền",
    code: 27088,
    division_type: "phường",
    codename: "phuong_thao_dien",
    district_code: 769,
  },
  {
    name: "Phường An Phú",
    code: 27091,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 769,
  },
  {
    name: "Phường An Khánh",
    code: 27094,
    division_type: "phường",
    codename: "phuong_an_khanh",
    district_code: 769,
  },
  {
    name: "Phường Bình Trưng Đông",
    code: 27097,
    division_type: "phường",
    codename: "phuong_binh_trung_dong",
    district_code: 769,
  },
  {
    name: "Phường Bình Trưng Tây",
    code: 27100,
    division_type: "phường",
    codename: "phuong_binh_trung_tay",
    district_code: 769,
  },
  {
    name: "Phường Cát Lái",
    code: 27109,
    division_type: "phường",
    codename: "phuong_cat_lai",
    district_code: 769,
  },
  {
    name: "Phường Thạnh Mỹ Lợi",
    code: 27112,
    division_type: "phường",
    codename: "phuong_thanh_my_loi",
    district_code: 769,
  },
  {
    name: "Phường An Lợi Đông",
    code: 27115,
    division_type: "phường",
    codename: "phuong_an_loi_dong",
    district_code: 769,
  },
  {
    name: "Phường Thủ Thiêm",
    code: 27118,
    division_type: "phường",
    codename: "phuong_thu_thiem",
    district_code: 769,
  },
  {
    name: "Phường 14",
    code: 27127,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 770,
  },
  {
    name: "Phường 12",
    code: 27130,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 770,
  },
  {
    name: "Phường 11",
    code: 27133,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 770,
  },
  {
    name: "Phường 13",
    code: 27136,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 770,
  },
  {
    name: "Phường Võ Thị Sáu",
    code: 27139,
    division_type: "phường",
    codename: "phuong_vo_thi_sau",
    district_code: 770,
  },
  {
    name: "Phường 09",
    code: 27142,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 770,
  },
  {
    name: "Phường 10",
    code: 27145,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 770,
  },
  {
    name: "Phường 04",
    code: 27148,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 770,
  },
  {
    name: "Phường 05",
    code: 27151,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 770,
  },
  {
    name: "Phường 03",
    code: 27154,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 770,
  },
  {
    name: "Phường 02",
    code: 27157,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 770,
  },
  {
    name: "Phường 01",
    code: 27160,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 770,
  },
  {
    name: "Phường 15",
    code: 27163,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 771,
  },
  {
    name: "Phường 13",
    code: 27166,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 771,
  },
  {
    name: "Phường 14",
    code: 27169,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 771,
  },
  {
    name: "Phường 12",
    code: 27172,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 771,
  },
  {
    name: "Phường 11",
    code: 27175,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 771,
  },
  {
    name: "Phường 10",
    code: 27178,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 771,
  },
  {
    name: "Phường 09",
    code: 27181,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 771,
  },
  {
    name: "Phường 01",
    code: 27184,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 771,
  },
  {
    name: "Phường 08",
    code: 27187,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 771,
  },
  {
    name: "Phường 02",
    code: 27190,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 771,
  },
  {
    name: "Phường 04",
    code: 27193,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 771,
  },
  {
    name: "Phường 07",
    code: 27196,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 771,
  },
  {
    name: "Phường 05",
    code: 27199,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 771,
  },
  {
    name: "Phường 06",
    code: 27202,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 771,
  },
  {
    name: "Phường 15",
    code: 27208,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 772,
  },
  {
    name: "Phường 05",
    code: 27211,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 772,
  },
  {
    name: "Phường 14",
    code: 27214,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 772,
  },
  {
    name: "Phường 11",
    code: 27217,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 772,
  },
  {
    name: "Phường 03",
    code: 27220,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 772,
  },
  {
    name: "Phường 10",
    code: 27223,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 772,
  },
  {
    name: "Phường 13",
    code: 27226,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 772,
  },
  {
    name: "Phường 08",
    code: 27229,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 772,
  },
  {
    name: "Phường 09",
    code: 27232,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 772,
  },
  {
    name: "Phường 12",
    code: 27235,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 772,
  },
  {
    name: "Phường 07",
    code: 27238,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 772,
  },
  {
    name: "Phường 06",
    code: 27241,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 772,
  },
  {
    name: "Phường 04",
    code: 27244,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 772,
  },
  {
    name: "Phường 01",
    code: 27247,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 772,
  },
  {
    name: "Phường 02",
    code: 27250,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 772,
  },
  {
    name: "Phường 16",
    code: 27253,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 772,
  },
  {
    name: "Phường 13",
    code: 27259,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 773,
  },
  {
    name: "Phường 09",
    code: 27262,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 773,
  },
  {
    name: "Phường 06",
    code: 27265,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 773,
  },
  {
    name: "Phường 08",
    code: 27268,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 773,
  },
  {
    name: "Phường 10",
    code: 27271,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 773,
  },
  {
    name: "Phường 18",
    code: 27277,
    division_type: "phường",
    codename: "phuong_18",
    district_code: 773,
  },
  {
    name: "Phường 14",
    code: 27280,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 773,
  },
  {
    name: "Phường 04",
    code: 27283,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 773,
  },
  {
    name: "Phường 03",
    code: 27286,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 773,
  },
  {
    name: "Phường 16",
    code: 27289,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 773,
  },
  {
    name: "Phường 02",
    code: 27292,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 773,
  },
  {
    name: "Phường 15",
    code: 27295,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 773,
  },
  {
    name: "Phường 01",
    code: 27298,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 773,
  },
  {
    name: "Phường 04",
    code: 27301,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 774,
  },
  {
    name: "Phường 09",
    code: 27304,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 774,
  },
  {
    name: "Phường 03",
    code: 27307,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 774,
  },
  {
    name: "Phường 12",
    code: 27310,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 774,
  },
  {
    name: "Phường 02",
    code: 27313,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 774,
  },
  {
    name: "Phường 08",
    code: 27316,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 774,
  },
  {
    name: "Phường 07",
    code: 27322,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 774,
  },
  {
    name: "Phường 01",
    code: 27325,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 774,
  },
  {
    name: "Phường 11",
    code: 27328,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 774,
  },
  {
    name: "Phường 14",
    code: 27331,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 774,
  },
  {
    name: "Phường 05",
    code: 27334,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 774,
  },
  {
    name: "Phường 06",
    code: 27337,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 774,
  },
  {
    name: "Phường 10",
    code: 27340,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 774,
  },
  {
    name: "Phường 13",
    code: 27343,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 774,
  },
  {
    name: "Phường 14",
    code: 27346,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 775,
  },
  {
    name: "Phường 13",
    code: 27349,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 775,
  },
  {
    name: "Phường 09",
    code: 27352,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 775,
  },
  {
    name: "Phường 06",
    code: 27355,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 775,
  },
  {
    name: "Phường 12",
    code: 27358,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 775,
  },
  {
    name: "Phường 05",
    code: 27361,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 775,
  },
  {
    name: "Phường 11",
    code: 27364,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 775,
  },
  {
    name: "Phường 02",
    code: 27367,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 775,
  },
  {
    name: "Phường 01",
    code: 27370,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 775,
  },
  {
    name: "Phường 04",
    code: 27373,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 775,
  },
  {
    name: "Phường 08",
    code: 27376,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 775,
  },
  {
    name: "Phường 03",
    code: 27379,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 775,
  },
  {
    name: "Phường 07",
    code: 27382,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 775,
  },
  {
    name: "Phường 10",
    code: 27385,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 775,
  },
  {
    name: "Phường 08",
    code: 27388,
    division_type: "phường",
    codename: "phuong_08",
    district_code: 776,
  },
  {
    name: "Phường 02",
    code: 27391,
    division_type: "phường",
    codename: "phuong_02",
    district_code: 776,
  },
  {
    name: "Phường 01",
    code: 27394,
    division_type: "phường",
    codename: "phuong_01",
    district_code: 776,
  },
  {
    name: "Phường 03",
    code: 27397,
    division_type: "phường",
    codename: "phuong_03",
    district_code: 776,
  },
  {
    name: "Phường 11",
    code: 27400,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 776,
  },
  {
    name: "Phường 09",
    code: 27403,
    division_type: "phường",
    codename: "phuong_09",
    district_code: 776,
  },
  {
    name: "Phường 10",
    code: 27406,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 776,
  },
  {
    name: "Phường 04",
    code: 27409,
    division_type: "phường",
    codename: "phuong_04",
    district_code: 776,
  },
  {
    name: "Phường 13",
    code: 27412,
    division_type: "phường",
    codename: "phuong_13",
    district_code: 776,
  },
  {
    name: "Phường 12",
    code: 27415,
    division_type: "phường",
    codename: "phuong_12",
    district_code: 776,
  },
  {
    name: "Phường 05",
    code: 27418,
    division_type: "phường",
    codename: "phuong_05",
    district_code: 776,
  },
  {
    name: "Phường 14",
    code: 27421,
    division_type: "phường",
    codename: "phuong_14",
    district_code: 776,
  },
  {
    name: "Phường 06",
    code: 27424,
    division_type: "phường",
    codename: "phuong_06",
    district_code: 776,
  },
  {
    name: "Phường 15",
    code: 27427,
    division_type: "phường",
    codename: "phuong_15",
    district_code: 776,
  },
  {
    name: "Phường 16",
    code: 27430,
    division_type: "phường",
    codename: "phuong_16",
    district_code: 776,
  },
  {
    name: "Phường 07",
    code: 27433,
    division_type: "phường",
    codename: "phuong_07",
    district_code: 776,
  },
  {
    name: "Phường Bình Hưng Hòa",
    code: 27436,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa",
    district_code: 777,
  },
  {
    name: "Phường Bình Hưng Hoà A",
    code: 27439,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa_a",
    district_code: 777,
  },
  {
    name: "Phường Bình Hưng Hoà B",
    code: 27442,
    division_type: "phường",
    codename: "phuong_binh_hung_hoa_b",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông",
    code: 27445,
    division_type: "phường",
    codename: "phuong_binh_tri_dong",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông A",
    code: 27448,
    division_type: "phường",
    codename: "phuong_binh_tri_dong_a",
    district_code: 777,
  },
  {
    name: "Phường Bình Trị Đông B",
    code: 27451,
    division_type: "phường",
    codename: "phuong_binh_tri_dong_b",
    district_code: 777,
  },
  {
    name: "Phường Tân Tạo",
    code: 27454,
    division_type: "phường",
    codename: "phuong_tan_tao",
    district_code: 777,
  },
  {
    name: "Phường Tân Tạo A",
    code: 27457,
    division_type: "phường",
    codename: "phuong_tan_tao_a",
    district_code: 777,
  },
  {
    name: "Phường An Lạc",
    code: 27460,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 777,
  },
  {
    name: "Phường An Lạc A",
    code: 27463,
    division_type: "phường",
    codename: "phuong_an_lac_a",
    district_code: 777,
  },
  {
    name: "Phường Tân Thuận Đông",
    code: 27466,
    division_type: "phường",
    codename: "phuong_tan_thuan_dong",
    district_code: 778,
  },
  {
    name: "Phường Tân Thuận Tây",
    code: 27469,
    division_type: "phường",
    codename: "phuong_tan_thuan_tay",
    district_code: 778,
  },
  {
    name: "Phường Tân Kiểng",
    code: 27472,
    division_type: "phường",
    codename: "phuong_tan_kieng",
    district_code: 778,
  },
  {
    name: "Phường Tân Hưng",
    code: 27475,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 778,
  },
  {
    name: "Phường Bình Thuận",
    code: 27478,
    division_type: "phường",
    codename: "phuong_binh_thuan",
    district_code: 778,
  },
  {
    name: "Phường Tân Quy",
    code: 27481,
    division_type: "phường",
    codename: "phuong_tan_quy",
    district_code: 778,
  },
  {
    name: "Phường Phú Thuận",
    code: 27484,
    division_type: "phường",
    codename: "phuong_phu_thuan",
    district_code: 778,
  },
  {
    name: "Phường Tân Phú",
    code: 27487,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 778,
  },
  {
    name: "Phường Tân Phong",
    code: 27490,
    division_type: "phường",
    codename: "phuong_tan_phong",
    district_code: 778,
  },
  {
    name: "Phường Phú Mỹ",
    code: 27493,
    division_type: "phường",
    codename: "phuong_phu_my",
    district_code: 778,
  },
  {
    name: "Thị trấn Củ Chi",
    code: 27496,
    division_type: "thị trấn",
    codename: "thi_tran_cu_chi",
    district_code: 783,
  },
  {
    name: "Xã Phú Mỹ Hưng",
    code: 27499,
    division_type: "xã",
    codename: "xa_phu_my_hung",
    district_code: 783,
  },
  {
    name: "Xã An Phú",
    code: 27502,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 783,
  },
  {
    name: "Xã Trung Lập Thượng",
    code: 27505,
    division_type: "xã",
    codename: "xa_trung_lap_thuong",
    district_code: 783,
  },
  {
    name: "Xã An Nhơn Tây",
    code: 27508,
    division_type: "xã",
    codename: "xa_an_nhon_tay",
    district_code: 783,
  },
  {
    name: "Xã Nhuận Đức",
    code: 27511,
    division_type: "xã",
    codename: "xa_nhuan_duc",
    district_code: 783,
  },
  {
    name: "Xã Phạm Văn Cội",
    code: 27514,
    division_type: "xã",
    codename: "xa_pham_van_coi",
    district_code: 783,
  },
  {
    name: "Xã Phú Hòa Đông",
    code: 27517,
    division_type: "xã",
    codename: "xa_phu_hoa_dong",
    district_code: 783,
  },
  {
    name: "Xã Trung Lập Hạ",
    code: 27520,
    division_type: "xã",
    codename: "xa_trung_lap_ha",
    district_code: 783,
  },
  {
    name: "Xã Trung An",
    code: 27523,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 783,
  },
  {
    name: "Xã Phước Thạnh",
    code: 27526,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 783,
  },
  {
    name: "Xã Phước Hiệp",
    code: 27529,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 783,
  },
  {
    name: "Xã Tân An Hội",
    code: 27532,
    division_type: "xã",
    codename: "xa_tan_an_hoi",
    district_code: 783,
  },
  {
    name: "Xã Phước Vĩnh An",
    code: 27535,
    division_type: "xã",
    codename: "xa_phuoc_vinh_an",
    district_code: 783,
  },
  {
    name: "Xã Thái Mỹ",
    code: 27538,
    division_type: "xã",
    codename: "xa_thai_my",
    district_code: 783,
  },
  {
    name: "Xã Tân Thạnh Tây",
    code: 27541,
    division_type: "xã",
    codename: "xa_tan_thanh_tay",
    district_code: 783,
  },
  {
    name: "Xã Hòa Phú",
    code: 27544,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 783,
  },
  {
    name: "Xã Tân Thạnh Đông",
    code: 27547,
    division_type: "xã",
    codename: "xa_tan_thanh_dong",
    district_code: 783,
  },
  {
    name: "Xã Bình Mỹ",
    code: 27550,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 783,
  },
  {
    name: "Xã Tân Phú Trung",
    code: 27553,
    division_type: "xã",
    codename: "xa_tan_phu_trung",
    district_code: 783,
  },
  {
    name: "Xã Tân Thông Hội",
    code: 27556,
    division_type: "xã",
    codename: "xa_tan_thong_hoi",
    district_code: 783,
  },
  {
    name: "Thị trấn Hóc Môn",
    code: 27559,
    division_type: "thị trấn",
    codename: "thi_tran_hoc_mon",
    district_code: 784,
  },
  {
    name: "Xã Tân Hiệp",
    code: 27562,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 784,
  },
  {
    name: "Xã Nhị Bình",
    code: 27565,
    division_type: "xã",
    codename: "xa_nhi_binh",
    district_code: 784,
  },
  {
    name: "Xã Đông Thạnh",
    code: 27568,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 784,
  },
  {
    name: "Xã Tân Thới Nhì",
    code: 27571,
    division_type: "xã",
    codename: "xa_tan_thoi_nhi",
    district_code: 784,
  },
  {
    name: "Xã Thới Tam Thôn",
    code: 27574,
    division_type: "xã",
    codename: "xa_thoi_tam_thon",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Sơn",
    code: 27577,
    division_type: "xã",
    codename: "xa_xuan_thoi_son",
    district_code: 784,
  },
  {
    name: "Xã Tân Xuân",
    code: 27580,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Đông",
    code: 27583,
    division_type: "xã",
    codename: "xa_xuan_thoi_dong",
    district_code: 784,
  },
  {
    name: "Xã Trung Chánh",
    code: 27586,
    division_type: "xã",
    codename: "xa_trung_chanh",
    district_code: 784,
  },
  {
    name: "Xã Xuân Thới Thượng",
    code: 27589,
    division_type: "xã",
    codename: "xa_xuan_thoi_thuong",
    district_code: 784,
  },
  {
    name: "Xã Bà Điểm",
    code: 27592,
    division_type: "xã",
    codename: "xa_ba_diem",
    district_code: 784,
  },
  {
    name: "Thị trấn Tân Túc",
    code: 27595,
    division_type: "thị trấn",
    codename: "thi_tran_tan_tuc",
    district_code: 785,
  },
  {
    name: "Xã Phạm Văn Hai",
    code: 27598,
    division_type: "xã",
    codename: "xa_pham_van_hai",
    district_code: 785,
  },
  {
    name: "Xã Vĩnh Lộc A",
    code: 27601,
    division_type: "xã",
    codename: "xa_vinh_loc_a",
    district_code: 785,
  },
  {
    name: "Xã Vĩnh Lộc B",
    code: 27604,
    division_type: "xã",
    codename: "xa_vinh_loc_b",
    district_code: 785,
  },
  {
    name: "Xã Bình Lợi",
    code: 27607,
    division_type: "xã",
    codename: "xa_binh_loi",
    district_code: 785,
  },
  {
    name: "Xã Lê Minh Xuân",
    code: 27610,
    division_type: "xã",
    codename: "xa_le_minh_xuan",
    district_code: 785,
  },
  {
    name: "Xã Tân Nhựt",
    code: 27613,
    division_type: "xã",
    codename: "xa_tan_nhut",
    district_code: 785,
  },
  {
    name: "Xã Tân Kiên",
    code: 27616,
    division_type: "xã",
    codename: "xa_tan_kien",
    district_code: 785,
  },
  {
    name: "Xã Bình Hưng",
    code: 27619,
    division_type: "xã",
    codename: "xa_binh_hung",
    district_code: 785,
  },
  {
    name: "Xã Phong Phú",
    code: 27622,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 785,
  },
  {
    name: "Xã An Phú Tây",
    code: 27625,
    division_type: "xã",
    codename: "xa_an_phu_tay",
    district_code: 785,
  },
  {
    name: "Xã Hưng Long",
    code: 27628,
    division_type: "xã",
    codename: "xa_hung_long",
    district_code: 785,
  },
  {
    name: "Xã Đa Phước",
    code: 27631,
    division_type: "xã",
    codename: "xa_da_phuoc",
    district_code: 785,
  },
  {
    name: "Xã Tân Quý Tây",
    code: 27634,
    division_type: "xã",
    codename: "xa_tan_quy_tay",
    district_code: 785,
  },
  {
    name: "Xã Bình Chánh",
    code: 27637,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 785,
  },
  {
    name: "Xã Quy Đức",
    code: 27640,
    division_type: "xã",
    codename: "xa_quy_duc",
    district_code: 785,
  },
  {
    name: "Thị trấn Nhà Bè",
    code: 27643,
    division_type: "thị trấn",
    codename: "thi_tran_nha_be",
    district_code: 786,
  },
  {
    name: "Xã Phước Kiển",
    code: 27646,
    division_type: "xã",
    codename: "xa_phuoc_kien",
    district_code: 786,
  },
  {
    name: "Xã Phước Lộc",
    code: 27649,
    division_type: "xã",
    codename: "xa_phuoc_loc",
    district_code: 786,
  },
  {
    name: "Xã Nhơn Đức",
    code: 27652,
    division_type: "xã",
    codename: "xa_nhon_duc",
    district_code: 786,
  },
  {
    name: "Xã Phú Xuân",
    code: 27655,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 786,
  },
  {
    name: "Xã Long Thới",
    code: 27658,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 786,
  },
  {
    name: "Xã Hiệp Phước",
    code: 27661,
    division_type: "xã",
    codename: "xa_hiep_phuoc",
    district_code: 786,
  },
  {
    name: "Thị trấn Cần Thạnh",
    code: 27664,
    division_type: "thị trấn",
    codename: "thi_tran_can_thanh",
    district_code: 787,
  },
  {
    name: "Xã Bình Khánh",
    code: 27667,
    division_type: "xã",
    codename: "xa_binh_khanh",
    district_code: 787,
  },
  {
    name: "Xã Tam Thôn Hiệp",
    code: 27670,
    division_type: "xã",
    codename: "xa_tam_thon_hiep",
    district_code: 787,
  },
  {
    name: "Xã An Thới Đông",
    code: 27673,
    division_type: "xã",
    codename: "xa_an_thoi_dong",
    district_code: 787,
  },
  {
    name: "Xã Thạnh An",
    code: 27676,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 787,
  },
  {
    name: "Xã Long Hòa",
    code: 27679,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 787,
  },
  {
    name: "Xã Lý Nhơn",
    code: 27682,
    division_type: "xã",
    codename: "xa_ly_nhon",
    district_code: 787,
  },
  {
    name: "Phường 5",
    code: 27685,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 794,
  },
  {
    name: "Phường 2",
    code: 27688,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 794,
  },
  {
    name: "Phường 4",
    code: 27691,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 794,
  },
  {
    name: "Phường Tân Khánh",
    code: 27692,
    division_type: "phường",
    codename: "phuong_tan_khanh",
    district_code: 794,
  },
  {
    name: "Phường 1",
    code: 27694,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 794,
  },
  {
    name: "Phường 3",
    code: 27697,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 794,
  },
  {
    name: "Phường 7",
    code: 27698,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 794,
  },
  {
    name: "Phường 6",
    code: 27700,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 794,
  },
  {
    name: "Xã Hướng Thọ Phú",
    code: 27703,
    division_type: "xã",
    codename: "xa_huong_tho_phu",
    district_code: 794,
  },
  {
    name: "Xã Nhơn Thạnh Trung",
    code: 27706,
    division_type: "xã",
    codename: "xa_nhon_thanh_trung",
    district_code: 794,
  },
  {
    name: "Xã Lợi Bình Nhơn",
    code: 27709,
    division_type: "xã",
    codename: "xa_loi_binh_nhon",
    district_code: 794,
  },
  {
    name: "Xã Bình Tâm",
    code: 27712,
    division_type: "xã",
    codename: "xa_binh_tam",
    district_code: 794,
  },
  {
    name: "Phường Khánh Hậu",
    code: 27715,
    division_type: "phường",
    codename: "phuong_khanh_hau",
    district_code: 794,
  },
  {
    name: "Xã An Vĩnh Ngãi",
    code: 27718,
    division_type: "xã",
    codename: "xa_an_vinh_ngai",
    district_code: 794,
  },
  {
    name: "Phường 1",
    code: 27787,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 795,
  },
  {
    name: "Phường 2",
    code: 27788,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 795,
  },
  {
    name: "Xã Thạnh Trị",
    code: 27790,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 795,
  },
  {
    name: "Xã Bình Hiệp",
    code: 27793,
    division_type: "xã",
    codename: "xa_binh_hiep",
    district_code: 795,
  },
  {
    name: "Xã Bình Tân",
    code: 27799,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 795,
  },
  {
    name: "Xã Tuyên Thạnh",
    code: 27805,
    division_type: "xã",
    codename: "xa_tuyen_thanh",
    district_code: 795,
  },
  {
    name: "Phường 3",
    code: 27806,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 795,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 27817,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 795,
  },
  {
    name: "Thị trấn Tân Hưng",
    code: 27721,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hung",
    district_code: 796,
  },
  {
    name: "Xã Hưng Hà",
    code: 27724,
    division_type: "xã",
    codename: "xa_hung_ha",
    district_code: 796,
  },
  {
    name: "Xã Hưng Điền B",
    code: 27727,
    division_type: "xã",
    codename: "xa_hung_dien_b",
    district_code: 796,
  },
  {
    name: "Xã Hưng Điền",
    code: 27730,
    division_type: "xã",
    codename: "xa_hung_dien",
    district_code: 796,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 27733,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 796,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 27736,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 27739,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Châu B",
    code: 27742,
    division_type: "xã",
    codename: "xa_vinh_chau_b",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 27745,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Đại",
    code: 27748,
    division_type: "xã",
    codename: "xa_vinh_dai",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Châu A",
    code: 27751,
    division_type: "xã",
    codename: "xa_vinh_chau_a",
    district_code: 796,
  },
  {
    name: "Xã Vĩnh Bửu",
    code: 27754,
    division_type: "xã",
    codename: "xa_vinh_buu",
    district_code: 796,
  },
  {
    name: "Thị trấn Vĩnh Hưng",
    code: 27757,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_hung",
    district_code: 797,
  },
  {
    name: "Xã Hưng Điền A",
    code: 27760,
    division_type: "xã",
    codename: "xa_hung_dien_a",
    district_code: 797,
  },
  {
    name: "Xã Khánh Hưng",
    code: 27763,
    division_type: "xã",
    codename: "xa_khanh_hung",
    district_code: 797,
  },
  {
    name: "Xã Thái Trị",
    code: 27766,
    division_type: "xã",
    codename: "xa_thai_tri",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Trị",
    code: 27769,
    division_type: "xã",
    codename: "xa_vinh_tri",
    district_code: 797,
  },
  {
    name: "Xã Thái Bình Trung",
    code: 27772,
    division_type: "xã",
    codename: "xa_thai_binh_trung",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 27775,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 797,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 27778,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 797,
  },
  {
    name: "Xã Tuyên Bình",
    code: 27781,
    division_type: "xã",
    codename: "xa_tuyen_binh",
    district_code: 797,
  },
  {
    name: "Xã Tuyên Bình Tây",
    code: 27784,
    division_type: "xã",
    codename: "xa_tuyen_binh_tay",
    district_code: 797,
  },
  {
    name: "Xã Bình Hòa Tây",
    code: 27796,
    division_type: "xã",
    codename: "xa_binh_hoa_tay",
    district_code: 798,
  },
  {
    name: "Xã Bình Thạnh",
    code: 27802,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 798,
  },
  {
    name: "Xã Bình Hòa Trung",
    code: 27808,
    division_type: "xã",
    codename: "xa_binh_hoa_trung",
    district_code: 798,
  },
  {
    name: "Xã Bình Hòa Đông",
    code: 27811,
    division_type: "xã",
    codename: "xa_binh_hoa_dong",
    district_code: 798,
  },
  {
    name: "Thị trấn Bình Phong Thạnh",
    code: 27814,
    division_type: "thị trấn",
    codename: "thi_tran_binh_phong_thanh",
    district_code: 798,
  },
  {
    name: "Xã Tân Lập",
    code: 27820,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 798,
  },
  {
    name: "Xã Tân Thành",
    code: 27823,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 798,
  },
  {
    name: "Thị trấn Tân Thạnh",
    code: 27826,
    division_type: "thị trấn",
    codename: "thi_tran_tan_thanh",
    district_code: 799,
  },
  {
    name: "Xã Bắc Hòa",
    code: 27829,
    division_type: "xã",
    codename: "xa_bac_hoa",
    district_code: 799,
  },
  {
    name: "Xã Hậu Thạnh Tây",
    code: 27832,
    division_type: "xã",
    codename: "xa_hau_thanh_tay",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Hòa Lập",
    code: 27835,
    division_type: "xã",
    codename: "xa_nhon_hoa_lap",
    district_code: 799,
  },
  {
    name: "Xã Tân Lập",
    code: 27838,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 799,
  },
  {
    name: "Xã Hậu Thạnh Đông",
    code: 27841,
    division_type: "xã",
    codename: "xa_hau_thanh_dong",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Hoà",
    code: 27844,
    division_type: "xã",
    codename: "xa_nhon_hoa",
    district_code: 799,
  },
  {
    name: "Xã Kiến Bình",
    code: 27847,
    division_type: "xã",
    codename: "xa_kien_binh",
    district_code: 799,
  },
  {
    name: "Xã Tân Thành",
    code: 27850,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 799,
  },
  {
    name: "Xã Tân Bình",
    code: 27853,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 799,
  },
  {
    name: "Xã Tân Ninh",
    code: 27856,
    division_type: "xã",
    codename: "xa_tan_ninh",
    district_code: 799,
  },
  {
    name: "Xã Nhơn Ninh",
    code: 27859,
    division_type: "xã",
    codename: "xa_nhon_ninh",
    district_code: 799,
  },
  {
    name: "Xã Tân Hòa",
    code: 27862,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 799,
  },
  {
    name: "Thị trấn Thạnh Hóa",
    code: 27865,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_hoa",
    district_code: 800,
  },
  {
    name: "Xã Tân Hiệp",
    code: 27868,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 800,
  },
  {
    name: "Xã Thuận Bình",
    code: 27871,
    division_type: "xã",
    codename: "xa_thuan_binh",
    district_code: 800,
  },
  {
    name: "Xã Thạnh Phước",
    code: 27874,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 800,
  },
  {
    name: "Xã Thạnh Phú",
    code: 27877,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 800,
  },
  {
    name: "Xã Thuận Nghĩa Hòa",
    code: 27880,
    division_type: "xã",
    codename: "xa_thuan_nghia_hoa",
    district_code: 800,
  },
  {
    name: "Xã Thủy Đông",
    code: 27883,
    division_type: "xã",
    codename: "xa_thuy_dong",
    district_code: 800,
  },
  {
    name: "Xã Thủy Tây",
    code: 27886,
    division_type: "xã",
    codename: "xa_thuy_tay",
    district_code: 800,
  },
  {
    name: "Xã Tân Tây",
    code: 27889,
    division_type: "xã",
    codename: "xa_tan_tay",
    district_code: 800,
  },
  {
    name: "Xã Tân Đông",
    code: 27892,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 800,
  },
  {
    name: "Xã Thạnh An",
    code: 27895,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 800,
  },
  {
    name: "Thị trấn Đông Thành",
    code: 27898,
    division_type: "thị trấn",
    codename: "thi_tran_dong_thanh",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Quý Đông",
    code: 27901,
    division_type: "xã",
    codename: "xa_my_quy_dong",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Bắc",
    code: 27904,
    division_type: "xã",
    codename: "xa_my_thanh_bac",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Quý Tây",
    code: 27907,
    division_type: "xã",
    codename: "xa_my_quy_tay",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Tây",
    code: 27910,
    division_type: "xã",
    codename: "xa_my_thanh_tay",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Thạnh Đông",
    code: 27913,
    division_type: "xã",
    codename: "xa_my_thanh_dong",
    district_code: 801,
  },
  {
    name: "Xã Bình Thành",
    code: 27916,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Bắc",
    code: 27919,
    division_type: "xã",
    codename: "xa_binh_hoa_bac",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Hưng",
    code: 27922,
    division_type: "xã",
    codename: "xa_binh_hoa_hung",
    district_code: 801,
  },
  {
    name: "Xã Bình Hòa Nam",
    code: 27925,
    division_type: "xã",
    codename: "xa_binh_hoa_nam",
    district_code: 801,
  },
  {
    name: "Xã Mỹ Bình",
    code: 27928,
    division_type: "xã",
    codename: "xa_my_binh",
    district_code: 801,
  },
  {
    name: "Thị trấn Hậu Nghĩa",
    code: 27931,
    division_type: "thị trấn",
    codename: "thi_tran_hau_nghia",
    district_code: 802,
  },
  {
    name: "Thị trấn Hiệp Hòa",
    code: 27934,
    division_type: "thị trấn",
    codename: "thi_tran_hiep_hoa",
    district_code: 802,
  },
  {
    name: "Thị trấn Đức Hòa",
    code: 27937,
    division_type: "thị trấn",
    codename: "thi_tran_duc_hoa",
    district_code: 802,
  },
  {
    name: "Xã Lộc Giang",
    code: 27940,
    division_type: "xã",
    codename: "xa_loc_giang",
    district_code: 802,
  },
  {
    name: "Xã An Ninh Đông",
    code: 27943,
    division_type: "xã",
    codename: "xa_an_ninh_dong",
    district_code: 802,
  },
  {
    name: "Xã An Ninh Tây",
    code: 27946,
    division_type: "xã",
    codename: "xa_an_ninh_tay",
    district_code: 802,
  },
  {
    name: "Xã Tân Mỹ",
    code: 27949,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 802,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 27952,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 802,
  },
  {
    name: "Xã Đức Lập Thượng",
    code: 27955,
    division_type: "xã",
    codename: "xa_duc_lap_thuong",
    district_code: 802,
  },
  {
    name: "Xã Đức Lập Hạ",
    code: 27958,
    division_type: "xã",
    codename: "xa_duc_lap_ha",
    district_code: 802,
  },
  {
    name: "Xã Tân Phú",
    code: 27961,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 802,
  },
  {
    name: "Xã Mỹ Hạnh Bắc",
    code: 27964,
    division_type: "xã",
    codename: "xa_my_hanh_bac",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Thượng",
    code: 27967,
    division_type: "xã",
    codename: "xa_duc_hoa_thuong",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Tây",
    code: 27970,
    division_type: "xã",
    codename: "xa_hoa_khanh_tay",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Đông",
    code: 27973,
    division_type: "xã",
    codename: "xa_hoa_khanh_dong",
    district_code: 802,
  },
  {
    name: "Xã Mỹ Hạnh Nam",
    code: 27976,
    division_type: "xã",
    codename: "xa_my_hanh_nam",
    district_code: 802,
  },
  {
    name: "Xã Hòa Khánh Nam",
    code: 27979,
    division_type: "xã",
    codename: "xa_hoa_khanh_nam",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Đông",
    code: 27982,
    division_type: "xã",
    codename: "xa_duc_hoa_dong",
    district_code: 802,
  },
  {
    name: "Xã Đức Hòa Hạ",
    code: 27985,
    division_type: "xã",
    codename: "xa_duc_hoa_ha",
    district_code: 802,
  },
  {
    name: "Xã Hựu Thạnh",
    code: 27988,
    division_type: "xã",
    codename: "xa_huu_thanh",
    district_code: 802,
  },
  {
    name: "Thị trấn Bến Lức",
    code: 27991,
    division_type: "thị trấn",
    codename: "thi_tran_ben_luc",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 27994,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 803,
  },
  {
    name: "Xã Lương Bình",
    code: 27997,
    division_type: "xã",
    codename: "xa_luong_binh",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 28000,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 803,
  },
  {
    name: "Xã Lương Hòa",
    code: 28003,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 803,
  },
  {
    name: "Xã Tân Hòa",
    code: 28006,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 803,
  },
  {
    name: "Xã Tân Bửu",
    code: 28009,
    division_type: "xã",
    codename: "xa_tan_buu",
    district_code: 803,
  },
  {
    name: "Xã An Thạnh",
    code: 28012,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 803,
  },
  {
    name: "Xã Bình Đức",
    code: 28015,
    division_type: "xã",
    codename: "xa_binh_duc",
    district_code: 803,
  },
  {
    name: "Xã Mỹ Yên",
    code: 28018,
    division_type: "xã",
    codename: "xa_my_yen",
    district_code: 803,
  },
  {
    name: "Xã Thanh Phú",
    code: 28021,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 803,
  },
  {
    name: "Xã Long Hiệp",
    code: 28024,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 803,
  },
  {
    name: "Xã Thạnh Đức",
    code: 28027,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 803,
  },
  {
    name: "Xã Phước Lợi",
    code: 28030,
    division_type: "xã",
    codename: "xa_phuoc_loi",
    district_code: 803,
  },
  {
    name: "Xã Nhựt Chánh",
    code: 28033,
    division_type: "xã",
    codename: "xa_nhut_chanh",
    district_code: 803,
  },
  {
    name: "Thị trấn Thủ Thừa",
    code: 28036,
    division_type: "thị trấn",
    codename: "thi_tran_thu_thua",
    district_code: 804,
  },
  {
    name: "Xã Long Thạnh",
    code: 28039,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 804,
  },
  {
    name: "Xã Tân Thành",
    code: 28042,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 804,
  },
  {
    name: "Xã Long Thuận",
    code: 28045,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Lạc",
    code: 28048,
    division_type: "xã",
    codename: "xa_my_lac",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 28051,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 804,
  },
  {
    name: "Xã Bình An",
    code: 28054,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 804,
  },
  {
    name: "Xã Nhị Thành",
    code: 28057,
    division_type: "xã",
    codename: "xa_nhi_thanh",
    district_code: 804,
  },
  {
    name: "Xã Mỹ An",
    code: 28060,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 804,
  },
  {
    name: "Xã Bình Thạnh",
    code: 28063,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 804,
  },
  {
    name: "Xã Mỹ Phú",
    code: 28066,
    division_type: "xã",
    codename: "xa_my_phu",
    district_code: 804,
  },
  {
    name: "Xã Tân Long",
    code: 28072,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 804,
  },
  {
    name: "Thị trấn Tân Trụ",
    code: 28075,
    division_type: "thị trấn",
    codename: "thi_tran_tan_tru",
    district_code: 805,
  },
  {
    name: "Xã Tân Bình",
    code: 28078,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 805,
  },
  {
    name: "Xã Quê Mỹ Thạnh",
    code: 28084,
    division_type: "xã",
    codename: "xa_que_my_thanh",
    district_code: 805,
  },
  {
    name: "Xã Lạc Tấn",
    code: 28087,
    division_type: "xã",
    codename: "xa_lac_tan",
    district_code: 805,
  },
  {
    name: "Xã Bình Trinh Đông",
    code: 28090,
    division_type: "xã",
    codename: "xa_binh_trinh_dong",
    district_code: 805,
  },
  {
    name: "Xã Tân Phước Tây",
    code: 28093,
    division_type: "xã",
    codename: "xa_tan_phuoc_tay",
    district_code: 805,
  },
  {
    name: "Xã Bình Lãng",
    code: 28096,
    division_type: "xã",
    codename: "xa_binh_lang",
    district_code: 805,
  },
  {
    name: "Xã Bình Tịnh",
    code: 28099,
    division_type: "xã",
    codename: "xa_binh_tinh",
    district_code: 805,
  },
  {
    name: "Xã Đức Tân",
    code: 28102,
    division_type: "xã",
    codename: "xa_duc_tan",
    district_code: 805,
  },
  {
    name: "Xã Nhựt Ninh",
    code: 28105,
    division_type: "xã",
    codename: "xa_nhut_ninh",
    district_code: 805,
  },
  {
    name: "Thị trấn Cần Đước",
    code: 28108,
    division_type: "thị trấn",
    codename: "thi_tran_can_duoc",
    district_code: 806,
  },
  {
    name: "Xã Long Trạch",
    code: 28111,
    division_type: "xã",
    codename: "xa_long_trach",
    district_code: 806,
  },
  {
    name: "Xã Long Khê",
    code: 28114,
    division_type: "xã",
    codename: "xa_long_khe",
    district_code: 806,
  },
  {
    name: "Xã Long Định",
    code: 28117,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 806,
  },
  {
    name: "Xã Phước Vân",
    code: 28120,
    division_type: "xã",
    codename: "xa_phuoc_van",
    district_code: 806,
  },
  {
    name: "Xã Long Hòa",
    code: 28123,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 806,
  },
  {
    name: "Xã Long Cang",
    code: 28126,
    division_type: "xã",
    codename: "xa_long_cang",
    district_code: 806,
  },
  {
    name: "Xã Long Sơn",
    code: 28129,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 806,
  },
  {
    name: "Xã Tân Trạch",
    code: 28132,
    division_type: "xã",
    codename: "xa_tan_trach",
    district_code: 806,
  },
  {
    name: "Xã Mỹ Lệ",
    code: 28135,
    division_type: "xã",
    codename: "xa_my_le",
    district_code: 806,
  },
  {
    name: "Xã Tân Lân",
    code: 28138,
    division_type: "xã",
    codename: "xa_tan_lan",
    district_code: 806,
  },
  {
    name: "Xã Phước Tuy",
    code: 28141,
    division_type: "xã",
    codename: "xa_phuoc_tuy",
    district_code: 806,
  },
  {
    name: "Xã Long Hựu Đông",
    code: 28144,
    division_type: "xã",
    codename: "xa_long_huu_dong",
    district_code: 806,
  },
  {
    name: "Xã Tân Ân",
    code: 28147,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 806,
  },
  {
    name: "Xã Phước Đông",
    code: 28150,
    division_type: "xã",
    codename: "xa_phuoc_dong",
    district_code: 806,
  },
  {
    name: "Xã Long Hựu Tây",
    code: 28153,
    division_type: "xã",
    codename: "xa_long_huu_tay",
    district_code: 806,
  },
  {
    name: "Xã Tân Chánh",
    code: 28156,
    division_type: "xã",
    codename: "xa_tan_chanh",
    district_code: 806,
  },
  {
    name: "Thị trấn Cần Giuộc",
    code: 28159,
    division_type: "thị trấn",
    codename: "thi_tran_can_giuoc",
    district_code: 807,
  },
  {
    name: "Xã Phước Lý",
    code: 28162,
    division_type: "xã",
    codename: "xa_phuoc_ly",
    district_code: 807,
  },
  {
    name: "Xã Long Thượng",
    code: 28165,
    division_type: "xã",
    codename: "xa_long_thuong",
    district_code: 807,
  },
  {
    name: "Xã Long Hậu",
    code: 28168,
    division_type: "xã",
    codename: "xa_long_hau",
    district_code: 807,
  },
  {
    name: "Xã Phước Hậu",
    code: 28174,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 807,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 28177,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 807,
  },
  {
    name: "Xã Phước Lại",
    code: 28180,
    division_type: "xã",
    codename: "xa_phuoc_lai",
    district_code: 807,
  },
  {
    name: "Xã Phước Lâm",
    code: 28183,
    division_type: "xã",
    codename: "xa_phuoc_lam",
    district_code: 807,
  },
  {
    name: "Xã Thuận Thành",
    code: 28189,
    division_type: "xã",
    codename: "xa_thuan_thanh",
    district_code: 807,
  },
  {
    name: "Xã Phước Vĩnh Tây",
    code: 28192,
    division_type: "xã",
    codename: "xa_phuoc_vinh_tay",
    district_code: 807,
  },
  {
    name: "Xã Phước Vĩnh Đông",
    code: 28195,
    division_type: "xã",
    codename: "xa_phuoc_vinh_dong",
    district_code: 807,
  },
  {
    name: "Xã Long An",
    code: 28198,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 807,
  },
  {
    name: "Xã Long Phụng",
    code: 28201,
    division_type: "xã",
    codename: "xa_long_phung",
    district_code: 807,
  },
  {
    name: "Xã Đông Thạnh",
    code: 28204,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 807,
  },
  {
    name: "Xã Tân Tập",
    code: 28207,
    division_type: "xã",
    codename: "xa_tan_tap",
    district_code: 807,
  },
  {
    name: "Thị trấn Tầm Vu",
    code: 28210,
    division_type: "thị trấn",
    codename: "thi_tran_tam_vu",
    district_code: 808,
  },
  {
    name: "Xã Bình Quới",
    code: 28213,
    division_type: "xã",
    codename: "xa_binh_quoi",
    district_code: 808,
  },
  {
    name: "Xã Hòa Phú",
    code: 28216,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 808,
  },
  {
    name: "Xã Phú Ngãi Trị",
    code: 28219,
    division_type: "xã",
    codename: "xa_phu_ngai_tri",
    district_code: 808,
  },
  {
    name: "Xã Vĩnh Công",
    code: 28222,
    division_type: "xã",
    codename: "xa_vinh_cong",
    district_code: 808,
  },
  {
    name: "Xã Thuận Mỹ",
    code: 28225,
    division_type: "xã",
    codename: "xa_thuan_my",
    district_code: 808,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 28228,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 808,
  },
  {
    name: "Xã Phước Tân Hưng",
    code: 28231,
    division_type: "xã",
    codename: "xa_phuoc_tan_hung",
    district_code: 808,
  },
  {
    name: "Xã Thanh Phú Long",
    code: 28234,
    division_type: "xã",
    codename: "xa_thanh_phu_long",
    district_code: 808,
  },
  {
    name: "Xã Dương Xuân Hội",
    code: 28237,
    division_type: "xã",
    codename: "xa_duong_xuan_hoi",
    district_code: 808,
  },
  {
    name: "Xã An Lục Long",
    code: 28240,
    division_type: "xã",
    codename: "xa_an_luc_long",
    district_code: 808,
  },
  {
    name: "Xã Long Trì",
    code: 28243,
    division_type: "xã",
    codename: "xa_long_tri",
    district_code: 808,
  },
  {
    name: "Xã Thanh Vĩnh Đông",
    code: 28246,
    division_type: "xã",
    codename: "xa_thanh_vinh_dong",
    district_code: 808,
  },
  {
    name: "Phường 5",
    code: 28249,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 815,
  },
  {
    name: "Phường 4",
    code: 28252,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 815,
  },
  {
    name: "Phường 7",
    code: 28255,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 815,
  },
  {
    name: "Phường 3",
    code: 28258,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 815,
  },
  {
    name: "Phường 1",
    code: 28261,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 815,
  },
  {
    name: "Phường 2",
    code: 28264,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 815,
  },
  {
    name: "Phường 8",
    code: 28267,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 815,
  },
  {
    name: "Phường 6",
    code: 28270,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 815,
  },
  {
    name: "Phường 9",
    code: 28273,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 815,
  },
  {
    name: "Phường 10",
    code: 28276,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 815,
  },
  {
    name: "Phường Tân Long",
    code: 28279,
    division_type: "phường",
    codename: "phuong_tan_long",
    district_code: 815,
  },
  {
    name: "Xã Đạo Thạnh",
    code: 28282,
    division_type: "xã",
    codename: "xa_dao_thanh",
    district_code: 815,
  },
  {
    name: "Xã Trung An",
    code: 28285,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 815,
  },
  {
    name: "Xã Mỹ Phong",
    code: 28288,
    division_type: "xã",
    codename: "xa_my_phong",
    district_code: 815,
  },
  {
    name: "Xã Tân Mỹ Chánh",
    code: 28291,
    division_type: "xã",
    codename: "xa_tan_my_chanh",
    district_code: 815,
  },
  {
    name: "Xã Phước Thạnh",
    code: 28567,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 815,
  },
  {
    name: "Xã Thới Sơn",
    code: 28591,
    division_type: "xã",
    codename: "xa_thoi_son",
    district_code: 815,
  },
  {
    name: "Phường 3",
    code: 28294,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 816,
  },
  {
    name: "Phường 2",
    code: 28297,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 816,
  },
  {
    name: "Phường 4",
    code: 28300,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 816,
  },
  {
    name: "Phường 1",
    code: 28303,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 816,
  },
  {
    name: "Phường 5",
    code: 28306,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 816,
  },
  {
    name: "Xã Long Hưng",
    code: 28309,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 816,
  },
  {
    name: "Xã Long Thuận",
    code: 28312,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 816,
  },
  {
    name: "Xã Long Chánh",
    code: 28315,
    division_type: "xã",
    codename: "xa_long_chanh",
    district_code: 816,
  },
  {
    name: "Xã Long Hòa",
    code: 28318,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 816,
  },
  {
    name: "Xã Bình Đông",
    code: 28708,
    division_type: "xã",
    codename: "xa_binh_dong",
    district_code: 816,
  },
  {
    name: "Xã Bình Xuân",
    code: 28717,
    division_type: "xã",
    codename: "xa_binh_xuan",
    district_code: 816,
  },
  {
    name: "Xã Tân Trung",
    code: 28729,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 816,
  },
  {
    name: "Phường 1",
    code: 28435,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 817,
  },
  {
    name: "Phường 2",
    code: 28436,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 817,
  },
  {
    name: "Phường 3",
    code: 28437,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 817,
  },
  {
    name: "Phường 4",
    code: 28439,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 817,
  },
  {
    name: "Phường 5",
    code: 28440,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Phước Tây",
    code: 28447,
    division_type: "xã",
    codename: "xa_my_phuoc_tay",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Hạnh Đông",
    code: 28450,
    division_type: "xã",
    codename: "xa_my_hanh_dong",
    district_code: 817,
  },
  {
    name: "Xã Mỹ Hạnh Trung",
    code: 28453,
    division_type: "xã",
    codename: "xa_my_hanh_trung",
    district_code: 817,
  },
  {
    name: "Xã Tân Phú",
    code: 28459,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 817,
  },
  {
    name: "Xã Tân Bình",
    code: 28462,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 817,
  },
  {
    name: "Xã Tân Hội",
    code: 28468,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 817,
  },
  {
    name: "Phường Nhị Mỹ",
    code: 28474,
    division_type: "phường",
    codename: "phuong_nhi_my",
    district_code: 817,
  },
  {
    name: "Xã Nhị Quý",
    code: 28477,
    division_type: "xã",
    codename: "xa_nhi_quy",
    district_code: 817,
  },
  {
    name: "Xã Thanh Hòa",
    code: 28480,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 817,
  },
  {
    name: "Xã Phú Quý",
    code: 28483,
    division_type: "xã",
    codename: "xa_phu_quy",
    district_code: 817,
  },
  {
    name: "Xã Long Khánh",
    code: 28486,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 817,
  },
  {
    name: "Thị trấn Mỹ Phước",
    code: 28321,
    division_type: "thị trấn",
    codename: "thi_tran_my_phuoc",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Đông",
    code: 28324,
    division_type: "xã",
    codename: "xa_tan_hoa_dong",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Tân",
    code: 28327,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Mỹ",
    code: 28330,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 818,
  },
  {
    name: "Xã Thạnh Hoà",
    code: 28333,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 818,
  },
  {
    name: "Xã Phú Mỹ",
    code: 28336,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Thành",
    code: 28339,
    division_type: "xã",
    codename: "xa_tan_hoa_thanh",
    district_code: 818,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 28342,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 818,
  },
  {
    name: "Xã Tân Lập 1",
    code: 28345,
    division_type: "xã",
    codename: "xa_tan_lap_1",
    district_code: 818,
  },
  {
    name: "Xã Tân Hòa Tây",
    code: 28348,
    division_type: "xã",
    codename: "xa_tan_hoa_tay",
    district_code: 818,
  },
  {
    name: "Xã Tân Lập 2",
    code: 28354,
    division_type: "xã",
    codename: "xa_tan_lap_2",
    district_code: 818,
  },
  {
    name: "Xã Phước Lập",
    code: 28357,
    division_type: "xã",
    codename: "xa_phuoc_lap",
    district_code: 818,
  },
  {
    name: "Thị trấn Cái Bè",
    code: 28360,
    division_type: "thị trấn",
    codename: "thi_tran_cai_be",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Bắc B",
    code: 28363,
    division_type: "xã",
    codename: "xa_hau_my_bac_b",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Bắc A",
    code: 28366,
    division_type: "xã",
    codename: "xa_hau_my_bac_a",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Trung",
    code: 28369,
    division_type: "xã",
    codename: "xa_my_trung",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Trinh",
    code: 28372,
    division_type: "xã",
    codename: "xa_hau_my_trinh",
    district_code: 819,
  },
  {
    name: "Xã Hậu Mỹ Phú",
    code: 28375,
    division_type: "xã",
    codename: "xa_hau_my_phu",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Tân",
    code: 28378,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lợi B",
    code: 28381,
    division_type: "xã",
    codename: "xa_my_loi_b",
    district_code: 819,
  },
  {
    name: "Xã Thiện Trung",
    code: 28384,
    division_type: "xã",
    codename: "xa_thien_trung",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Hội",
    code: 28387,
    division_type: "xã",
    codename: "xa_my_hoi",
    district_code: 819,
  },
  {
    name: "Xã An Cư",
    code: 28390,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 819,
  },
  {
    name: "Xã Hậu Thành",
    code: 28393,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lợi A",
    code: 28396,
    division_type: "xã",
    codename: "xa_my_loi_a",
    district_code: 819,
  },
  {
    name: "Xã Hòa Khánh",
    code: 28399,
    division_type: "xã",
    codename: "xa_hoa_khanh",
    district_code: 819,
  },
  {
    name: "Xã Thiện Trí",
    code: 28402,
    division_type: "xã",
    codename: "xa_thien_tri",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Đức Đông",
    code: 28405,
    division_type: "xã",
    codename: "xa_my_duc_dong",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Đức Tây",
    code: 28408,
    division_type: "xã",
    codename: "xa_my_duc_tay",
    district_code: 819,
  },
  {
    name: "Xã Đông Hòa Hiệp",
    code: 28411,
    division_type: "xã",
    codename: "xa_dong_hoa_hiep",
    district_code: 819,
  },
  {
    name: "Xã An Thái Đông",
    code: 28414,
    division_type: "xã",
    codename: "xa_an_thai_dong",
    district_code: 819,
  },
  {
    name: "Xã Tân Hưng",
    code: 28417,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 819,
  },
  {
    name: "Xã Mỹ Lương",
    code: 28420,
    division_type: "xã",
    codename: "xa_my_luong",
    district_code: 819,
  },
  {
    name: "Xã Tân Thanh",
    code: 28423,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 819,
  },
  {
    name: "Xã An Thái Trung",
    code: 28426,
    division_type: "xã",
    codename: "xa_an_thai_trung",
    district_code: 819,
  },
  {
    name: "Xã An Hữu",
    code: 28429,
    division_type: "xã",
    codename: "xa_an_huu",
    district_code: 819,
  },
  {
    name: "Xã Hòa Hưng",
    code: 28432,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 819,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 28438,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Thành Bắc",
    code: 28441,
    division_type: "xã",
    codename: "xa_my_thanh_bac",
    district_code: 820,
  },
  {
    name: "Xã Phú Cường",
    code: 28444,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Thành Nam",
    code: 28456,
    division_type: "xã",
    codename: "xa_my_thanh_nam",
    district_code: 820,
  },
  {
    name: "Xã Phú Nhuận",
    code: 28465,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 820,
  },
  {
    name: "Xã Bình Phú",
    code: 28471,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 820,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 28489,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 820,
  },
  {
    name: "Xã Phú An",
    code: 28492,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 820,
  },
  {
    name: "Xã Mỹ Long",
    code: 28495,
    division_type: "xã",
    codename: "xa_my_long",
    district_code: 820,
  },
  {
    name: "Xã Long Tiên",
    code: 28498,
    division_type: "xã",
    codename: "xa_long_tien",
    district_code: 820,
  },
  {
    name: "Xã Hiệp Đức",
    code: 28501,
    division_type: "xã",
    codename: "xa_hiep_duc",
    district_code: 820,
  },
  {
    name: "Xã Long Trung",
    code: 28504,
    division_type: "xã",
    codename: "xa_long_trung",
    district_code: 820,
  },
  {
    name: "Xã Hội Xuân",
    code: 28507,
    division_type: "xã",
    codename: "xa_hoi_xuan",
    district_code: 820,
  },
  {
    name: "Xã Tân Phong",
    code: 28510,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 820,
  },
  {
    name: "Xã Tam Bình",
    code: 28513,
    division_type: "xã",
    codename: "xa_tam_binh",
    district_code: 820,
  },
  {
    name: "Xã Ngũ Hiệp",
    code: 28516,
    division_type: "xã",
    codename: "xa_ngu_hiep",
    district_code: 820,
  },
  {
    name: "Thị trấn Tân Hiệp",
    code: 28519,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hiep",
    district_code: 821,
  },
  {
    name: "Xã Tân Hội Đông",
    code: 28522,
    division_type: "xã",
    codename: "xa_tan_hoi_dong",
    district_code: 821,
  },
  {
    name: "Xã Tân Hương",
    code: 28525,
    division_type: "xã",
    codename: "xa_tan_huong",
    district_code: 821,
  },
  {
    name: "Xã Tân Lý Đông",
    code: 28528,
    division_type: "xã",
    codename: "xa_tan_ly_dong",
    district_code: 821,
  },
  {
    name: "Xã Tân Lý Tây",
    code: 28531,
    division_type: "xã",
    codename: "xa_tan_ly_tay",
    district_code: 821,
  },
  {
    name: "Xã Thân Cửu Nghĩa",
    code: 28534,
    division_type: "xã",
    codename: "xa_than_cuu_nghia",
    district_code: 821,
  },
  {
    name: "Xã Tam Hiệp",
    code: 28537,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 821,
  },
  {
    name: "Xã Điềm Hy",
    code: 28540,
    division_type: "xã",
    codename: "xa_diem_hy",
    district_code: 821,
  },
  {
    name: "Xã Nhị Bình",
    code: 28543,
    division_type: "xã",
    codename: "xa_nhi_binh",
    district_code: 821,
  },
  {
    name: "Xã Dưỡng Điềm",
    code: 28546,
    division_type: "xã",
    codename: "xa_duong_diem",
    district_code: 821,
  },
  {
    name: "Xã Đông Hòa",
    code: 28549,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 821,
  },
  {
    name: "Xã Long Định",
    code: 28552,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 821,
  },
  {
    name: "Xã Hữu Đạo",
    code: 28555,
    division_type: "xã",
    codename: "xa_huu_dao",
    district_code: 821,
  },
  {
    name: "Xã Long An",
    code: 28558,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 821,
  },
  {
    name: "Xã Long Hưng",
    code: 28561,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 821,
  },
  {
    name: "Xã Bình Trưng",
    code: 28564,
    division_type: "xã",
    codename: "xa_binh_trung",
    district_code: 821,
  },
  {
    name: "Xã Thạnh Phú",
    code: 28570,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 821,
  },
  {
    name: "Xã Bàn Long",
    code: 28573,
    division_type: "xã",
    codename: "xa_ban_long",
    district_code: 821,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 28576,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 821,
  },
  {
    name: "Xã Bình Đức",
    code: 28579,
    division_type: "xã",
    codename: "xa_binh_duc",
    district_code: 821,
  },
  {
    name: "Xã Song Thuận",
    code: 28582,
    division_type: "xã",
    codename: "xa_song_thuan",
    district_code: 821,
  },
  {
    name: "Xã Kim Sơn",
    code: 28585,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 821,
  },
  {
    name: "Xã Phú Phong",
    code: 28588,
    division_type: "xã",
    codename: "xa_phu_phong",
    district_code: 821,
  },
  {
    name: "Thị trấn Chợ Gạo",
    code: 28594,
    division_type: "thị trấn",
    codename: "thi_tran_cho_gao",
    district_code: 822,
  },
  {
    name: "Xã Trung Hòa",
    code: 28597,
    division_type: "xã",
    codename: "xa_trung_hoa",
    district_code: 822,
  },
  {
    name: "Xã Hòa Tịnh",
    code: 28600,
    division_type: "xã",
    codename: "xa_hoa_tinh",
    district_code: 822,
  },
  {
    name: "Xã Mỹ Tịnh An",
    code: 28603,
    division_type: "xã",
    codename: "xa_my_tinh_an",
    district_code: 822,
  },
  {
    name: "Xã Tân Bình Thạnh",
    code: 28606,
    division_type: "xã",
    codename: "xa_tan_binh_thanh",
    district_code: 822,
  },
  {
    name: "Xã Phú Kiết",
    code: 28609,
    division_type: "xã",
    codename: "xa_phu_kiet",
    district_code: 822,
  },
  {
    name: "Xã Lương Hòa Lạc",
    code: 28612,
    division_type: "xã",
    codename: "xa_luong_hoa_lac",
    district_code: 822,
  },
  {
    name: "Xã Thanh Bình",
    code: 28615,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 822,
  },
  {
    name: "Xã Quơn Long",
    code: 28618,
    division_type: "xã",
    codename: "xa_quon_long",
    district_code: 822,
  },
  {
    name: "Xã Bình Phục Nhứt",
    code: 28621,
    division_type: "xã",
    codename: "xa_binh_phuc_nhut",
    district_code: 822,
  },
  {
    name: "Xã Đăng Hưng Phước",
    code: 28624,
    division_type: "xã",
    codename: "xa_dang_hung_phuoc",
    district_code: 822,
  },
  {
    name: "Xã Tân Thuận Bình",
    code: 28627,
    division_type: "xã",
    codename: "xa_tan_thuan_binh",
    district_code: 822,
  },
  {
    name: "Xã Song Bình",
    code: 28630,
    division_type: "xã",
    codename: "xa_song_binh",
    district_code: 822,
  },
  {
    name: "Xã Bình Phan",
    code: 28633,
    division_type: "xã",
    codename: "xa_binh_phan",
    district_code: 822,
  },
  {
    name: "Xã Long Bình Điền",
    code: 28636,
    division_type: "xã",
    codename: "xa_long_binh_dien",
    district_code: 822,
  },
  {
    name: "Xã An Thạnh Thủy",
    code: 28639,
    division_type: "xã",
    codename: "xa_an_thanh_thuy",
    district_code: 822,
  },
  {
    name: "Xã Xuân Đông",
    code: 28642,
    division_type: "xã",
    codename: "xa_xuan_dong",
    district_code: 822,
  },
  {
    name: "Xã Hòa Định",
    code: 28645,
    division_type: "xã",
    codename: "xa_hoa_dinh",
    district_code: 822,
  },
  {
    name: "Xã Bình Ninh",
    code: 28648,
    division_type: "xã",
    codename: "xa_binh_ninh",
    district_code: 822,
  },
  {
    name: "Thị trấn Vĩnh Bình",
    code: 28651,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_binh",
    district_code: 823,
  },
  {
    name: "Xã Đồng Sơn",
    code: 28654,
    division_type: "xã",
    codename: "xa_dong_son",
    district_code: 823,
  },
  {
    name: "Xã Bình Phú",
    code: 28657,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 823,
  },
  {
    name: "Xã Đồng Thạnh",
    code: 28660,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 823,
  },
  {
    name: "Xã Thành Công",
    code: 28663,
    division_type: "xã",
    codename: "xa_thanh_cong",
    district_code: 823,
  },
  {
    name: "Xã Bình Nhì",
    code: 28666,
    division_type: "xã",
    codename: "xa_binh_nhi",
    district_code: 823,
  },
  {
    name: "Xã Yên Luông",
    code: 28669,
    division_type: "xã",
    codename: "xa_yen_luong",
    district_code: 823,
  },
  {
    name: "Xã Thạnh Trị",
    code: 28672,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 823,
  },
  {
    name: "Xã Thạnh Nhựt",
    code: 28675,
    division_type: "xã",
    codename: "xa_thanh_nhut",
    district_code: 823,
  },
  {
    name: "Xã Long Vĩnh",
    code: 28678,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 823,
  },
  {
    name: "Xã Bình Tân",
    code: 28681,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 823,
  },
  {
    name: "Xã Vĩnh Hựu",
    code: 28684,
    division_type: "xã",
    codename: "xa_vinh_huu",
    district_code: 823,
  },
  {
    name: "Xã Long Bình",
    code: 28687,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 823,
  },
  {
    name: "Thị trấn Tân Hòa",
    code: 28702,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hoa",
    district_code: 824,
  },
  {
    name: "Xã Tăng Hoà",
    code: 28705,
    division_type: "xã",
    codename: "xa_tang_hoa",
    district_code: 824,
  },
  {
    name: "Xã Tân Phước",
    code: 28711,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 824,
  },
  {
    name: "Xã Gia Thuận",
    code: 28714,
    division_type: "xã",
    codename: "xa_gia_thuan",
    district_code: 824,
  },
  {
    name: "Thị trấn Vàm Láng",
    code: 28720,
    division_type: "thị trấn",
    codename: "thi_tran_vam_lang",
    district_code: 824,
  },
  {
    name: "Xã Tân Tây",
    code: 28723,
    division_type: "xã",
    codename: "xa_tan_tay",
    district_code: 824,
  },
  {
    name: "Xã Kiểng Phước",
    code: 28726,
    division_type: "xã",
    codename: "xa_kieng_phuoc",
    district_code: 824,
  },
  {
    name: "Xã Tân Đông",
    code: 28732,
    division_type: "xã",
    codename: "xa_tan_dong",
    district_code: 824,
  },
  {
    name: "Xã Bình Ân",
    code: 28735,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 824,
  },
  {
    name: "Xã Tân Điền",
    code: 28738,
    division_type: "xã",
    codename: "xa_tan_dien",
    district_code: 824,
  },
  {
    name: "Xã Bình Nghị",
    code: 28741,
    division_type: "xã",
    codename: "xa_binh_nghi",
    district_code: 824,
  },
  {
    name: "Xã Phước Trung",
    code: 28744,
    division_type: "xã",
    codename: "xa_phuoc_trung",
    district_code: 824,
  },
  {
    name: "Xã Tân Thành",
    code: 28747,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 824,
  },
  {
    name: "Xã Tân Thới",
    code: 28690,
    division_type: "xã",
    codename: "xa_tan_thoi",
    district_code: 825,
  },
  {
    name: "Xã Tân Phú",
    code: 28693,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 825,
  },
  {
    name: "Xã Phú Thạnh",
    code: 28696,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 825,
  },
  {
    name: "Xã Tân Thạnh",
    code: 28699,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 825,
  },
  {
    name: "Xã Phú Đông",
    code: 28750,
    division_type: "xã",
    codename: "xa_phu_dong",
    district_code: 825,
  },
  {
    name: "Xã Phú Tân",
    code: 28753,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 825,
  },
  {
    name: "Phường Phú Khương",
    code: 28756,
    division_type: "phường",
    codename: "phuong_phu_khuong",
    district_code: 829,
  },
  {
    name: "Phường Phú Tân",
    code: 28757,
    division_type: "phường",
    codename: "phuong_phu_tan",
    district_code: 829,
  },
  {
    name: "Phường 8",
    code: 28759,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 829,
  },
  {
    name: "Phường 6",
    code: 28762,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 829,
  },
  {
    name: "Phường 4",
    code: 28765,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 829,
  },
  {
    name: "Phường 5",
    code: 28768,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 829,
  },
  {
    name: "Phường An Hội",
    code: 28777,
    division_type: "phường",
    codename: "phuong_an_hoi",
    district_code: 829,
  },
  {
    name: "Phường 7",
    code: 28780,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 829,
  },
  {
    name: "Xã Sơn Đông",
    code: 28783,
    division_type: "xã",
    codename: "xa_son_dong",
    district_code: 829,
  },
  {
    name: "Xã Phú Hưng",
    code: 28786,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 829,
  },
  {
    name: "Xã Bình Phú",
    code: 28789,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 829,
  },
  {
    name: "Xã Mỹ Thạnh An",
    code: 28792,
    division_type: "xã",
    codename: "xa_my_thanh_an",
    district_code: 829,
  },
  {
    name: "Xã Nhơn Thạnh",
    code: 28795,
    division_type: "xã",
    codename: "xa_nhon_thanh",
    district_code: 829,
  },
  {
    name: "Xã Phú Nhuận",
    code: 28798,
    division_type: "xã",
    codename: "xa_phu_nhuan",
    district_code: 829,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 28801,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 831,
  },
  {
    name: "Xã Tân Thạch",
    code: 28804,
    division_type: "xã",
    codename: "xa_tan_thach",
    district_code: 831,
  },
  {
    name: "Xã Qưới Sơn",
    code: 28807,
    division_type: "xã",
    codename: "xa_quoi_son",
    district_code: 831,
  },
  {
    name: "Xã An Khánh",
    code: 28810,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 831,
  },
  {
    name: "Xã Giao Long",
    code: 28813,
    division_type: "xã",
    codename: "xa_giao_long",
    district_code: 831,
  },
  {
    name: "Xã Phú Túc",
    code: 28819,
    division_type: "xã",
    codename: "xa_phu_tuc",
    district_code: 831,
  },
  {
    name: "Xã Phú Đức",
    code: 28822,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 831,
  },
  {
    name: "Xã Phú An Hòa",
    code: 28825,
    division_type: "xã",
    codename: "xa_phu_an_hoa",
    district_code: 831,
  },
  {
    name: "Xã An Phước",
    code: 28828,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 831,
  },
  {
    name: "Xã Tam Phước",
    code: 28831,
    division_type: "xã",
    codename: "xa_tam_phuoc",
    district_code: 831,
  },
  {
    name: "Xã Thành Triệu",
    code: 28834,
    division_type: "xã",
    codename: "xa_thanh_trieu",
    district_code: 831,
  },
  {
    name: "Xã Tường Đa",
    code: 28837,
    division_type: "xã",
    codename: "xa_tuong_da",
    district_code: 831,
  },
  {
    name: "Xã Tân Phú",
    code: 28840,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 831,
  },
  {
    name: "Xã Quới Thành",
    code: 28843,
    division_type: "xã",
    codename: "xa_quoi_thanh",
    district_code: 831,
  },
  {
    name: "Xã Phước Thạnh",
    code: 28846,
    division_type: "xã",
    codename: "xa_phuoc_thanh",
    district_code: 831,
  },
  {
    name: "Xã An Hóa",
    code: 28849,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 831,
  },
  {
    name: "Xã Tiên Long",
    code: 28852,
    division_type: "xã",
    codename: "xa_tien_long",
    district_code: 831,
  },
  {
    name: "Xã An Hiệp",
    code: 28855,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 831,
  },
  {
    name: "Xã Hữu Định",
    code: 28858,
    division_type: "xã",
    codename: "xa_huu_dinh",
    district_code: 831,
  },
  {
    name: "Xã Tiên Thủy",
    code: 28861,
    division_type: "xã",
    codename: "xa_tien_thuy",
    district_code: 831,
  },
  {
    name: "Xã Sơn Hòa",
    code: 28864,
    division_type: "xã",
    codename: "xa_son_hoa",
    district_code: 831,
  },
  {
    name: "Thị trấn Chợ Lách",
    code: 28870,
    division_type: "thị trấn",
    codename: "thi_tran_cho_lach",
    district_code: 832,
  },
  {
    name: "Xã Phú Phụng",
    code: 28873,
    division_type: "xã",
    codename: "xa_phu_phung",
    district_code: 832,
  },
  {
    name: "Xã Sơn Định",
    code: 28876,
    division_type: "xã",
    codename: "xa_son_dinh",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 28879,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 832,
  },
  {
    name: "Xã Hòa Nghĩa",
    code: 28882,
    division_type: "xã",
    codename: "xa_hoa_nghia",
    district_code: 832,
  },
  {
    name: "Xã Long Thới",
    code: 28885,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 832,
  },
  {
    name: "Xã Phú Sơn",
    code: 28888,
    division_type: "xã",
    codename: "xa_phu_son",
    district_code: 832,
  },
  {
    name: "Xã Tân Thiềng",
    code: 28891,
    division_type: "xã",
    codename: "xa_tan_thieng",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 28894,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 832,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 28897,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 832,
  },
  {
    name: "Xã Hưng Khánh Trung B",
    code: 28900,
    division_type: "xã",
    codename: "xa_hung_khanh_trung_b",
    district_code: 832,
  },
  {
    name: "Thị trấn Mỏ Cày",
    code: 28903,
    division_type: "thị trấn",
    codename: "thi_tran_mo_cay",
    district_code: 833,
  },
  {
    name: "Xã Định Thủy",
    code: 28930,
    division_type: "xã",
    codename: "xa_dinh_thuy",
    district_code: 833,
  },
  {
    name: "Xã Đa Phước Hội",
    code: 28939,
    division_type: "xã",
    codename: "xa_da_phuoc_hoi",
    district_code: 833,
  },
  {
    name: "Xã Tân Hội",
    code: 28940,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 833,
  },
  {
    name: "Xã Phước Hiệp",
    code: 28942,
    division_type: "xã",
    codename: "xa_phuoc_hiep",
    district_code: 833,
  },
  {
    name: "Xã Bình Khánh",
    code: 28945,
    division_type: "xã",
    codename: "xa_binh_khanh",
    district_code: 833,
  },
  {
    name: "Xã An Thạnh",
    code: 28951,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 833,
  },
  {
    name: "Xã An Định",
    code: 28957,
    division_type: "xã",
    codename: "xa_an_dinh",
    district_code: 833,
  },
  {
    name: "Xã Thành Thới B",
    code: 28960,
    division_type: "xã",
    codename: "xa_thanh_thoi_b",
    district_code: 833,
  },
  {
    name: "Xã Tân Trung",
    code: 28963,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 833,
  },
  {
    name: "Xã An Thới",
    code: 28966,
    division_type: "xã",
    codename: "xa_an_thoi",
    district_code: 833,
  },
  {
    name: "Xã Thành Thới A",
    code: 28969,
    division_type: "xã",
    codename: "xa_thanh_thoi_a",
    district_code: 833,
  },
  {
    name: "Xã Minh Đức",
    code: 28972,
    division_type: "xã",
    codename: "xa_minh_duc",
    district_code: 833,
  },
  {
    name: "Xã Ngãi Đăng",
    code: 28975,
    division_type: "xã",
    codename: "xa_ngai_dang",
    district_code: 833,
  },
  {
    name: "Xã Cẩm Sơn",
    code: 28978,
    division_type: "xã",
    codename: "xa_cam_son",
    district_code: 833,
  },
  {
    name: "Xã Hương Mỹ",
    code: 28981,
    division_type: "xã",
    codename: "xa_huong_my",
    district_code: 833,
  },
  {
    name: "Thị trấn Giồng Trôm",
    code: 28984,
    division_type: "thị trấn",
    codename: "thi_tran_giong_trom",
    district_code: 834,
  },
  {
    name: "Xã Phong Nẫm",
    code: 28987,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 834,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 28993,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 834,
  },
  {
    name: "Xã Châu Hòa",
    code: 28996,
    division_type: "xã",
    codename: "xa_chau_hoa",
    district_code: 834,
  },
  {
    name: "Xã Lương Hòa",
    code: 28999,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 834,
  },
  {
    name: "Xã Lương Quới",
    code: 29002,
    division_type: "xã",
    codename: "xa_luong_quoi",
    district_code: 834,
  },
  {
    name: "Xã Lương Phú",
    code: 29005,
    division_type: "xã",
    codename: "xa_luong_phu",
    district_code: 834,
  },
  {
    name: "Xã Châu Bình",
    code: 29008,
    division_type: "xã",
    codename: "xa_chau_binh",
    district_code: 834,
  },
  {
    name: "Xã Thuận Điền",
    code: 29011,
    division_type: "xã",
    codename: "xa_thuan_dien",
    district_code: 834,
  },
  {
    name: "Xã Sơn Phú",
    code: 29014,
    division_type: "xã",
    codename: "xa_son_phu",
    district_code: 834,
  },
  {
    name: "Xã Bình Hoà",
    code: 29017,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 834,
  },
  {
    name: "Xã Phước Long",
    code: 29020,
    division_type: "xã",
    codename: "xa_phuoc_long",
    district_code: 834,
  },
  {
    name: "Xã Hưng Phong",
    code: 29023,
    division_type: "xã",
    codename: "xa_hung_phong",
    district_code: 834,
  },
  {
    name: "Xã Long Mỹ",
    code: 29026,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 834,
  },
  {
    name: "Xã Tân Hào",
    code: 29029,
    division_type: "xã",
    codename: "xa_tan_hao",
    district_code: 834,
  },
  {
    name: "Xã Bình Thành",
    code: 29032,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 834,
  },
  {
    name: "Xã Tân Thanh",
    code: 29035,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 834,
  },
  {
    name: "Xã Tân Lợi Thạnh",
    code: 29038,
    division_type: "xã",
    codename: "xa_tan_loi_thanh",
    district_code: 834,
  },
  {
    name: "Xã Thạnh Phú Đông",
    code: 29041,
    division_type: "xã",
    codename: "xa_thanh_phu_dong",
    district_code: 834,
  },
  {
    name: "Xã Hưng Nhượng",
    code: 29044,
    division_type: "xã",
    codename: "xa_hung_nhuong",
    district_code: 834,
  },
  {
    name: "Xã Hưng Lễ",
    code: 29047,
    division_type: "xã",
    codename: "xa_hung_le",
    district_code: 834,
  },
  {
    name: "Thị trấn Bình Đại",
    code: 29050,
    division_type: "thị trấn",
    codename: "thi_tran_binh_dai",
    district_code: 835,
  },
  {
    name: "Xã Tam Hiệp",
    code: 29053,
    division_type: "xã",
    codename: "xa_tam_hiep",
    district_code: 835,
  },
  {
    name: "Xã Long Định",
    code: 29056,
    division_type: "xã",
    codename: "xa_long_dinh",
    district_code: 835,
  },
  {
    name: "Xã Long Hòa",
    code: 29059,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 835,
  },
  {
    name: "Xã Phú Thuận",
    code: 29062,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 835,
  },
  {
    name: "Xã Vang Quới Tây",
    code: 29065,
    division_type: "xã",
    codename: "xa_vang_quoi_tay",
    district_code: 835,
  },
  {
    name: "Xã Vang Quới Đông",
    code: 29068,
    division_type: "xã",
    codename: "xa_vang_quoi_dong",
    district_code: 835,
  },
  {
    name: "Xã Châu Hưng",
    code: 29071,
    division_type: "xã",
    codename: "xa_chau_hung",
    district_code: 835,
  },
  {
    name: "Xã Phú Vang",
    code: 29074,
    division_type: "xã",
    codename: "xa_phu_vang",
    district_code: 835,
  },
  {
    name: "Xã Lộc Thuận",
    code: 29077,
    division_type: "xã",
    codename: "xa_loc_thuan",
    district_code: 835,
  },
  {
    name: "Xã Định Trung",
    code: 29080,
    division_type: "xã",
    codename: "xa_dinh_trung",
    district_code: 835,
  },
  {
    name: "Xã Thới Lai",
    code: 29083,
    division_type: "xã",
    codename: "xa_thoi_lai",
    district_code: 835,
  },
  {
    name: "Xã Bình Thới",
    code: 29086,
    division_type: "xã",
    codename: "xa_binh_thoi",
    district_code: 835,
  },
  {
    name: "Xã Phú Long",
    code: 29089,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 835,
  },
  {
    name: "Xã Bình Thắng",
    code: 29092,
    division_type: "xã",
    codename: "xa_binh_thang",
    district_code: 835,
  },
  {
    name: "Xã Thạnh Trị",
    code: 29095,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 835,
  },
  {
    name: "Xã Đại Hòa Lộc",
    code: 29098,
    division_type: "xã",
    codename: "xa_dai_hoa_loc",
    district_code: 835,
  },
  {
    name: "Xã Thừa Đức",
    code: 29101,
    division_type: "xã",
    codename: "xa_thua_duc",
    district_code: 835,
  },
  {
    name: "Xã Thạnh Phước",
    code: 29104,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 835,
  },
  {
    name: "Xã Thới Thuận",
    code: 29107,
    division_type: "xã",
    codename: "xa_thoi_thuan",
    district_code: 835,
  },
  {
    name: "Thị trấn Ba Tri",
    code: 29110,
    division_type: "thị trấn",
    codename: "thi_tran_ba_tri",
    district_code: 836,
  },
  {
    name: "Xã Tân Mỹ",
    code: 29113,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29116,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 836,
  },
  {
    name: "Xã Tân Xuân",
    code: 29119,
    division_type: "xã",
    codename: "xa_tan_xuan",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 29122,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 836,
  },
  {
    name: "Xã Bảo Thạnh",
    code: 29125,
    division_type: "xã",
    codename: "xa_bao_thanh",
    district_code: 836,
  },
  {
    name: "Xã An Phú Trung",
    code: 29128,
    division_type: "xã",
    codename: "xa_an_phu_trung",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Thạnh",
    code: 29131,
    division_type: "xã",
    codename: "xa_my_thanh",
    district_code: 836,
  },
  {
    name: "Xã Mỹ Nhơn",
    code: 29134,
    division_type: "xã",
    codename: "xa_my_nhon",
    district_code: 836,
  },
  {
    name: "Xã Phước Ngãi",
    code: 29137,
    division_type: "xã",
    codename: "xa_phuoc_ngai",
    district_code: 836,
  },
  {
    name: "Xã An Ngãi Trung",
    code: 29143,
    division_type: "xã",
    codename: "xa_an_ngai_trung",
    district_code: 836,
  },
  {
    name: "Xã Phú Lễ",
    code: 29146,
    division_type: "xã",
    codename: "xa_phu_le",
    district_code: 836,
  },
  {
    name: "Xã An Bình Tây",
    code: 29149,
    division_type: "xã",
    codename: "xa_an_binh_tay",
    district_code: 836,
  },
  {
    name: "Xã Bảo Thuận",
    code: 29152,
    division_type: "xã",
    codename: "xa_bao_thuan",
    district_code: 836,
  },
  {
    name: "Xã Tân Hưng",
    code: 29155,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 836,
  },
  {
    name: "Xã An Ngãi Tây",
    code: 29158,
    division_type: "xã",
    codename: "xa_an_ngai_tay",
    district_code: 836,
  },
  {
    name: "Xã An Hiệp",
    code: 29161,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 836,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 29164,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 836,
  },
  {
    name: "Xã Tân Thủy",
    code: 29167,
    division_type: "xã",
    codename: "xa_tan_thuy",
    district_code: 836,
  },
  {
    name: "Xã Vĩnh An",
    code: 29170,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 836,
  },
  {
    name: "Xã An Đức",
    code: 29173,
    division_type: "xã",
    codename: "xa_an_duc",
    district_code: 836,
  },
  {
    name: "Xã An Hòa Tây",
    code: 29176,
    division_type: "xã",
    codename: "xa_an_hoa_tay",
    district_code: 836,
  },
  {
    name: "Xã An Thủy",
    code: 29179,
    division_type: "xã",
    codename: "xa_an_thuy",
    district_code: 836,
  },
  {
    name: "Thị trấn Thạnh Phú",
    code: 29182,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_phu",
    district_code: 837,
  },
  {
    name: "Xã Phú Khánh",
    code: 29185,
    division_type: "xã",
    codename: "xa_phu_khanh",
    district_code: 837,
  },
  {
    name: "Xã Đại Điền",
    code: 29188,
    division_type: "xã",
    codename: "xa_dai_dien",
    district_code: 837,
  },
  {
    name: "Xã Quới Điền",
    code: 29191,
    division_type: "xã",
    codename: "xa_quoi_dien",
    district_code: 837,
  },
  {
    name: "Xã Tân Phong",
    code: 29194,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 837,
  },
  {
    name: "Xã Mỹ Hưng",
    code: 29197,
    division_type: "xã",
    codename: "xa_my_hung",
    district_code: 837,
  },
  {
    name: "Xã An Thạnh",
    code: 29200,
    division_type: "xã",
    codename: "xa_an_thanh",
    district_code: 837,
  },
  {
    name: "Xã Thới Thạnh",
    code: 29203,
    division_type: "xã",
    codename: "xa_thoi_thanh",
    district_code: 837,
  },
  {
    name: "Xã Hòa Lợi",
    code: 29206,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 837,
  },
  {
    name: "Xã An Điền",
    code: 29209,
    division_type: "xã",
    codename: "xa_an_dien",
    district_code: 837,
  },
  {
    name: "Xã Bình Thạnh",
    code: 29212,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 837,
  },
  {
    name: "Xã An Thuận",
    code: 29215,
    division_type: "xã",
    codename: "xa_an_thuan",
    district_code: 837,
  },
  {
    name: "Xã An Quy",
    code: 29218,
    division_type: "xã",
    codename: "xa_an_quy",
    district_code: 837,
  },
  {
    name: "Xã Thạnh Hải",
    code: 29221,
    division_type: "xã",
    codename: "xa_thanh_hai",
    district_code: 837,
  },
  {
    name: "Xã An Nhơn",
    code: 29224,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 837,
  },
  {
    name: "Xã Giao Thạnh",
    code: 29227,
    division_type: "xã",
    codename: "xa_giao_thanh",
    district_code: 837,
  },
  {
    name: "Xã Thạnh Phong",
    code: 29230,
    division_type: "xã",
    codename: "xa_thanh_phong",
    district_code: 837,
  },
  {
    name: "Xã Mỹ An",
    code: 29233,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 837,
  },
  {
    name: "Xã Phú Mỹ",
    code: 28889,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 838,
  },
  {
    name: "Xã Hưng Khánh Trung A",
    code: 28901,
    division_type: "xã",
    codename: "xa_hung_khanh_trung_a",
    district_code: 838,
  },
  {
    name: "Xã Thanh Tân",
    code: 28906,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 838,
  },
  {
    name: "Xã Thạnh Ngãi",
    code: 28909,
    division_type: "xã",
    codename: "xa_thanh_ngai",
    district_code: 838,
  },
  {
    name: "Xã Tân Phú Tây",
    code: 28912,
    division_type: "xã",
    codename: "xa_tan_phu_tay",
    district_code: 838,
  },
  {
    name: "Xã Phước Mỹ Trung",
    code: 28915,
    division_type: "xã",
    codename: "xa_phuoc_my_trung",
    district_code: 838,
  },
  {
    name: "Xã Tân Thành Bình",
    code: 28918,
    division_type: "xã",
    codename: "xa_tan_thanh_binh",
    district_code: 838,
  },
  {
    name: "Xã Thành An",
    code: 28921,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 838,
  },
  {
    name: "Xã Hòa Lộc",
    code: 28924,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 838,
  },
  {
    name: "Xã Tân Thanh Tây",
    code: 28927,
    division_type: "xã",
    codename: "xa_tan_thanh_tay",
    district_code: 838,
  },
  {
    name: "Xã Tân Bình",
    code: 28933,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 838,
  },
  {
    name: "Xã Nhuận Phú Tân",
    code: 28936,
    division_type: "xã",
    codename: "xa_nhuan_phu_tan",
    district_code: 838,
  },
  {
    name: "Xã Khánh Thạnh Tân",
    code: 28948,
    division_type: "xã",
    codename: "xa_khanh_thanh_tan",
    district_code: 838,
  },
  {
    name: "Phường 4",
    code: 29236,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 842,
  },
  {
    name: "Phường 1",
    code: 29239,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 842,
  },
  {
    name: "Phường 3",
    code: 29242,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 842,
  },
  {
    name: "Phường 2",
    code: 29245,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 842,
  },
  {
    name: "Phường 5",
    code: 29248,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 842,
  },
  {
    name: "Phường 6",
    code: 29251,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 842,
  },
  {
    name: "Phường 7",
    code: 29254,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 842,
  },
  {
    name: "Phường 8",
    code: 29257,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 842,
  },
  {
    name: "Phường 9",
    code: 29260,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 842,
  },
  {
    name: "Xã Long Đức",
    code: 29263,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 842,
  },
  {
    name: "Thị trấn Càng Long",
    code: 29266,
    division_type: "thị trấn",
    codename: "thi_tran_cang_long",
    district_code: 844,
  },
  {
    name: "Xã Mỹ Cẩm",
    code: 29269,
    division_type: "xã",
    codename: "xa_my_cam",
    district_code: 844,
  },
  {
    name: "Xã An Trường A",
    code: 29272,
    division_type: "xã",
    codename: "xa_an_truong_a",
    district_code: 844,
  },
  {
    name: "Xã An Trường",
    code: 29275,
    division_type: "xã",
    codename: "xa_an_truong",
    district_code: 844,
  },
  {
    name: "Xã Huyền Hội",
    code: 29278,
    division_type: "xã",
    codename: "xa_huyen_hoi",
    district_code: 844,
  },
  {
    name: "Xã Tân An",
    code: 29281,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 844,
  },
  {
    name: "Xã Tân Bình",
    code: 29284,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 844,
  },
  {
    name: "Xã Bình Phú",
    code: 29287,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 844,
  },
  {
    name: "Xã Phương Thạnh",
    code: 29290,
    division_type: "xã",
    codename: "xa_phuong_thanh",
    district_code: 844,
  },
  {
    name: "Xã Đại Phúc",
    code: 29293,
    division_type: "xã",
    codename: "xa_dai_phuc",
    district_code: 844,
  },
  {
    name: "Xã Đại Phước",
    code: 29296,
    division_type: "xã",
    codename: "xa_dai_phuoc",
    district_code: 844,
  },
  {
    name: "Xã Nhị Long Phú",
    code: 29299,
    division_type: "xã",
    codename: "xa_nhi_long_phu",
    district_code: 844,
  },
  {
    name: "Xã Nhị Long",
    code: 29302,
    division_type: "xã",
    codename: "xa_nhi_long",
    district_code: 844,
  },
  {
    name: "Xã Đức Mỹ",
    code: 29305,
    division_type: "xã",
    codename: "xa_duc_my",
    district_code: 844,
  },
  {
    name: "Thị trấn Cầu Kè",
    code: 29308,
    division_type: "thị trấn",
    codename: "thi_tran_cau_ke",
    district_code: 845,
  },
  {
    name: "Xã Hòa Ân",
    code: 29311,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 845,
  },
  {
    name: "Xã Châu Điền",
    code: 29314,
    division_type: "xã",
    codename: "xa_chau_dien",
    district_code: 845,
  },
  {
    name: "Xã An Phú Tân",
    code: 29317,
    division_type: "xã",
    codename: "xa_an_phu_tan",
    district_code: 845,
  },
  {
    name: "Xã Hoà Tân",
    code: 29320,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 845,
  },
  {
    name: "Xã Ninh Thới",
    code: 29323,
    division_type: "xã",
    codename: "xa_ninh_thoi",
    district_code: 845,
  },
  {
    name: "Xã Phong Phú",
    code: 29326,
    division_type: "xã",
    codename: "xa_phong_phu",
    district_code: 845,
  },
  {
    name: "Xã Phong Thạnh",
    code: 29329,
    division_type: "xã",
    codename: "xa_phong_thanh",
    district_code: 845,
  },
  {
    name: "Xã Tam Ngãi",
    code: 29332,
    division_type: "xã",
    codename: "xa_tam_ngai",
    district_code: 845,
  },
  {
    name: "Xã Thông Hòa",
    code: 29335,
    division_type: "xã",
    codename: "xa_thong_hoa",
    district_code: 845,
  },
  {
    name: "Xã Thạnh Phú",
    code: 29338,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 845,
  },
  {
    name: "Thị trấn Tiểu Cần",
    code: 29341,
    division_type: "thị trấn",
    codename: "thi_tran_tieu_can",
    district_code: 846,
  },
  {
    name: "Thị trấn Cầu Quan",
    code: 29344,
    division_type: "thị trấn",
    codename: "thi_tran_cau_quan",
    district_code: 846,
  },
  {
    name: "Xã Phú Cần",
    code: 29347,
    division_type: "xã",
    codename: "xa_phu_can",
    district_code: 846,
  },
  {
    name: "Xã Hiếu Tử",
    code: 29350,
    division_type: "xã",
    codename: "xa_hieu_tu",
    district_code: 846,
  },
  {
    name: "Xã Hiếu Trung",
    code: 29353,
    division_type: "xã",
    codename: "xa_hieu_trung",
    district_code: 846,
  },
  {
    name: "Xã Long Thới",
    code: 29356,
    division_type: "xã",
    codename: "xa_long_thoi",
    district_code: 846,
  },
  {
    name: "Xã Hùng Hòa",
    code: 29359,
    division_type: "xã",
    codename: "xa_hung_hoa",
    district_code: 846,
  },
  {
    name: "Xã Tân Hùng",
    code: 29362,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 846,
  },
  {
    name: "Xã Tập Ngãi",
    code: 29365,
    division_type: "xã",
    codename: "xa_tap_ngai",
    district_code: 846,
  },
  {
    name: "Xã Ngãi Hùng",
    code: 29368,
    division_type: "xã",
    codename: "xa_ngai_hung",
    district_code: 846,
  },
  {
    name: "Xã Tân Hòa",
    code: 29371,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 846,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 29374,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 847,
  },
  {
    name: "Xã Đa Lộc",
    code: 29377,
    division_type: "xã",
    codename: "xa_da_loc",
    district_code: 847,
  },
  {
    name: "Xã Mỹ Chánh",
    code: 29380,
    division_type: "xã",
    codename: "xa_my_chanh",
    district_code: 847,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 29383,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 847,
  },
  {
    name: "Xã Lương Hoà A",
    code: 29386,
    division_type: "xã",
    codename: "xa_luong_hoa_a",
    district_code: 847,
  },
  {
    name: "Xã Lương Hòa",
    code: 29389,
    division_type: "xã",
    codename: "xa_luong_hoa",
    district_code: 847,
  },
  {
    name: "Xã Song Lộc",
    code: 29392,
    division_type: "xã",
    codename: "xa_song_loc",
    district_code: 847,
  },
  {
    name: "Xã Nguyệt Hóa",
    code: 29395,
    division_type: "xã",
    codename: "xa_nguyet_hoa",
    district_code: 847,
  },
  {
    name: "Xã Hòa Thuận",
    code: 29398,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 847,
  },
  {
    name: "Xã Hòa Lợi",
    code: 29401,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 847,
  },
  {
    name: "Xã Phước Hảo",
    code: 29404,
    division_type: "xã",
    codename: "xa_phuoc_hao",
    district_code: 847,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 29407,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 847,
  },
  {
    name: "Xã Hòa Minh",
    code: 29410,
    division_type: "xã",
    codename: "xa_hoa_minh",
    district_code: 847,
  },
  {
    name: "Xã Long Hòa",
    code: 29413,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 847,
  },
  {
    name: "Thị trấn Cầu Ngang",
    code: 29416,
    division_type: "thị trấn",
    codename: "thi_tran_cau_ngang",
    district_code: 848,
  },
  {
    name: "Thị trấn Mỹ Long",
    code: 29419,
    division_type: "thị trấn",
    codename: "thi_tran_my_long",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Long Bắc",
    code: 29422,
    division_type: "xã",
    codename: "xa_my_long_bac",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Long Nam",
    code: 29425,
    division_type: "xã",
    codename: "xa_my_long_nam",
    district_code: 848,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29428,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 848,
  },
  {
    name: "Xã Vĩnh Kim",
    code: 29431,
    division_type: "xã",
    codename: "xa_vinh_kim",
    district_code: 848,
  },
  {
    name: "Xã Kim Hòa",
    code: 29434,
    division_type: "xã",
    codename: "xa_kim_hoa",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Hòa",
    code: 29437,
    division_type: "xã",
    codename: "xa_hiep_hoa",
    district_code: 848,
  },
  {
    name: "Xã Thuận Hòa",
    code: 29440,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 848,
  },
  {
    name: "Xã Long Sơn",
    code: 29443,
    division_type: "xã",
    codename: "xa_long_son",
    district_code: 848,
  },
  {
    name: "Xã Nhị Trường",
    code: 29446,
    division_type: "xã",
    codename: "xa_nhi_truong",
    district_code: 848,
  },
  {
    name: "Xã Trường Thọ",
    code: 29449,
    division_type: "xã",
    codename: "xa_truong_tho",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Mỹ Đông",
    code: 29452,
    division_type: "xã",
    codename: "xa_hiep_my_dong",
    district_code: 848,
  },
  {
    name: "Xã Hiệp Mỹ Tây",
    code: 29455,
    division_type: "xã",
    codename: "xa_hiep_my_tay",
    district_code: 848,
  },
  {
    name: "Xã Thạnh Hòa Sơn",
    code: 29458,
    division_type: "xã",
    codename: "xa_thanh_hoa_son",
    district_code: 848,
  },
  {
    name: "Thị trấn Trà Cú",
    code: 29461,
    division_type: "thị trấn",
    codename: "thi_tran_tra_cu",
    district_code: 849,
  },
  {
    name: "Thị trấn Định An",
    code: 29462,
    division_type: "thị trấn",
    codename: "thi_tran_dinh_an",
    district_code: 849,
  },
  {
    name: "Xã Phước Hưng",
    code: 29464,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 849,
  },
  {
    name: "Xã Tập Sơn",
    code: 29467,
    division_type: "xã",
    codename: "xa_tap_son",
    district_code: 849,
  },
  {
    name: "Xã Tân Sơn",
    code: 29470,
    division_type: "xã",
    codename: "xa_tan_son",
    district_code: 849,
  },
  {
    name: "Xã An Quảng Hữu",
    code: 29473,
    division_type: "xã",
    codename: "xa_an_quang_huu",
    district_code: 849,
  },
  {
    name: "Xã Lưu Nghiệp Anh",
    code: 29476,
    division_type: "xã",
    codename: "xa_luu_nghiep_anh",
    district_code: 849,
  },
  {
    name: "Xã Ngãi Xuyên",
    code: 29479,
    division_type: "xã",
    codename: "xa_ngai_xuyen",
    district_code: 849,
  },
  {
    name: "Xã Kim Sơn",
    code: 29482,
    division_type: "xã",
    codename: "xa_kim_son",
    district_code: 849,
  },
  {
    name: "Xã Thanh Sơn",
    code: 29485,
    division_type: "xã",
    codename: "xa_thanh_son",
    district_code: 849,
  },
  {
    name: "Xã Hàm Giang",
    code: 29488,
    division_type: "xã",
    codename: "xa_ham_giang",
    district_code: 849,
  },
  {
    name: "Xã Hàm Tân",
    code: 29489,
    division_type: "xã",
    codename: "xa_ham_tan",
    district_code: 849,
  },
  {
    name: "Xã Đại An",
    code: 29491,
    division_type: "xã",
    codename: "xa_dai_an",
    district_code: 849,
  },
  {
    name: "Xã Định An",
    code: 29494,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 849,
  },
  {
    name: "Xã Ngọc Biên",
    code: 29503,
    division_type: "xã",
    codename: "xa_ngoc_bien",
    district_code: 849,
  },
  {
    name: "Xã Long Hiệp",
    code: 29506,
    division_type: "xã",
    codename: "xa_long_hiep",
    district_code: 849,
  },
  {
    name: "Xã Tân Hiệp",
    code: 29509,
    division_type: "xã",
    codename: "xa_tan_hiep",
    district_code: 849,
  },
  {
    name: "Xã Đôn Xuân",
    code: 29497,
    division_type: "xã",
    codename: "xa_don_xuan",
    district_code: 850,
  },
  {
    name: "Xã Đôn Châu",
    code: 29500,
    division_type: "xã",
    codename: "xa_don_chau",
    district_code: 850,
  },
  {
    name: "Thị trấn Long Thành",
    code: 29513,
    division_type: "thị trấn",
    codename: "thi_tran_long_thanh",
    district_code: 850,
  },
  {
    name: "Xã Long Khánh",
    code: 29521,
    division_type: "xã",
    codename: "xa_long_khanh",
    district_code: 850,
  },
  {
    name: "Xã Ngũ Lạc",
    code: 29530,
    division_type: "xã",
    codename: "xa_ngu_lac",
    district_code: 850,
  },
  {
    name: "Xã Long Vĩnh",
    code: 29533,
    division_type: "xã",
    codename: "xa_long_vinh",
    district_code: 850,
  },
  {
    name: "Xã Đông Hải",
    code: 29536,
    division_type: "xã",
    codename: "xa_dong_hai",
    district_code: 850,
  },
  {
    name: "Phường 1",
    code: 29512,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 851,
  },
  {
    name: "Xã Long Toàn",
    code: 29515,
    division_type: "xã",
    codename: "xa_long_toan",
    district_code: 851,
  },
  {
    name: "Phường 2",
    code: 29516,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 851,
  },
  {
    name: "Xã Long Hữu",
    code: 29518,
    division_type: "xã",
    codename: "xa_long_huu",
    district_code: 851,
  },
  {
    name: "Xã Dân Thành",
    code: 29524,
    division_type: "xã",
    codename: "xa_dan_thanh",
    district_code: 851,
  },
  {
    name: "Xã Trường Long Hòa",
    code: 29527,
    division_type: "xã",
    codename: "xa_truong_long_hoa",
    district_code: 851,
  },
  {
    name: "Xã Hiệp Thạnh",
    code: 29539,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 851,
  },
  {
    name: "Phường 9",
    code: 29542,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 855,
  },
  {
    name: "Phường 5",
    code: 29545,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 855,
  },
  {
    name: "Phường 1",
    code: 29548,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 855,
  },
  {
    name: "Phường 2",
    code: 29551,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 855,
  },
  {
    name: "Phường 4",
    code: 29554,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 855,
  },
  {
    name: "Phường 3",
    code: 29557,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 855,
  },
  {
    name: "Phường 8",
    code: 29560,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 855,
  },
  {
    name: "Phường Tân Ngãi",
    code: 29563,
    division_type: "phường",
    codename: "phuong_tan_ngai",
    district_code: 855,
  },
  {
    name: "Phường Tân Hòa",
    code: 29566,
    division_type: "phường",
    codename: "phuong_tan_hoa",
    district_code: 855,
  },
  {
    name: "Phường Tân Hội",
    code: 29569,
    division_type: "phường",
    codename: "phuong_tan_hoi",
    district_code: 855,
  },
  {
    name: "Phường Trường An",
    code: 29572,
    division_type: "phường",
    codename: "phuong_truong_an",
    district_code: 855,
  },
  {
    name: "Thị trấn Long Hồ",
    code: 29575,
    division_type: "thị trấn",
    codename: "thi_tran_long_ho",
    district_code: 857,
  },
  {
    name: "Xã Đồng Phú",
    code: 29578,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 857,
  },
  {
    name: "Xã Bình Hòa Phước",
    code: 29581,
    division_type: "xã",
    codename: "xa_binh_hoa_phuoc",
    district_code: 857,
  },
  {
    name: "Xã Hòa Ninh",
    code: 29584,
    division_type: "xã",
    codename: "xa_hoa_ninh",
    district_code: 857,
  },
  {
    name: "Xã An Bình",
    code: 29587,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 857,
  },
  {
    name: "Xã Thanh Đức",
    code: 29590,
    division_type: "xã",
    codename: "xa_thanh_duc",
    district_code: 857,
  },
  {
    name: "Xã Tân Hạnh",
    code: 29593,
    division_type: "xã",
    codename: "xa_tan_hanh",
    district_code: 857,
  },
  {
    name: "Xã Phước Hậu",
    code: 29596,
    division_type: "xã",
    codename: "xa_phuoc_hau",
    district_code: 857,
  },
  {
    name: "Xã Long Phước",
    code: 29599,
    division_type: "xã",
    codename: "xa_long_phuoc",
    district_code: 857,
  },
  {
    name: "Xã Phú Đức",
    code: 29602,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 857,
  },
  {
    name: "Xã Lộc Hòa",
    code: 29605,
    division_type: "xã",
    codename: "xa_loc_hoa",
    district_code: 857,
  },
  {
    name: "Xã Long An",
    code: 29608,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 857,
  },
  {
    name: "Xã Phú Quới",
    code: 29611,
    division_type: "xã",
    codename: "xa_phu_quoi",
    district_code: 857,
  },
  {
    name: "Xã Thạnh Quới",
    code: 29614,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 857,
  },
  {
    name: "Xã Hòa Phú",
    code: 29617,
    division_type: "xã",
    codename: "xa_hoa_phu",
    district_code: 857,
  },
  {
    name: "Xã Mỹ An",
    code: 29623,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 858,
  },
  {
    name: "Xã Mỹ Phước",
    code: 29626,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 858,
  },
  {
    name: "Xã An Phước",
    code: 29629,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 858,
  },
  {
    name: "Xã Nhơn Phú",
    code: 29632,
    division_type: "xã",
    codename: "xa_nhon_phu",
    district_code: 858,
  },
  {
    name: "Xã Long Mỹ",
    code: 29635,
    division_type: "xã",
    codename: "xa_long_my",
    district_code: 858,
  },
  {
    name: "Xã Hòa Tịnh",
    code: 29638,
    division_type: "xã",
    codename: "xa_hoa_tinh",
    district_code: 858,
  },
  {
    name: "Thị trấn Cái Nhum",
    code: 29641,
    division_type: "thị trấn",
    codename: "thi_tran_cai_nhum",
    district_code: 858,
  },
  {
    name: "Xã Bình Phước",
    code: 29644,
    division_type: "xã",
    codename: "xa_binh_phuoc",
    district_code: 858,
  },
  {
    name: "Xã Chánh An",
    code: 29647,
    division_type: "xã",
    codename: "xa_chanh_an",
    district_code: 858,
  },
  {
    name: "Xã Tân An Hội",
    code: 29650,
    division_type: "xã",
    codename: "xa_tan_an_hoi",
    district_code: 858,
  },
  {
    name: "Xã Tân Long",
    code: 29653,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 858,
  },
  {
    name: "Xã Tân Long Hội",
    code: 29656,
    division_type: "xã",
    codename: "xa_tan_long_hoi",
    district_code: 858,
  },
  {
    name: "Thị trấn Vũng Liêm",
    code: 29659,
    division_type: "thị trấn",
    codename: "thi_tran_vung_liem",
    district_code: 859,
  },
  {
    name: "Xã Tân Quới Trung",
    code: 29662,
    division_type: "xã",
    codename: "xa_tan_quoi_trung",
    district_code: 859,
  },
  {
    name: "Xã Quới Thiện",
    code: 29665,
    division_type: "xã",
    codename: "xa_quoi_thien",
    district_code: 859,
  },
  {
    name: "Xã Quới An",
    code: 29668,
    division_type: "xã",
    codename: "xa_quoi_an",
    district_code: 859,
  },
  {
    name: "Xã Trung Chánh",
    code: 29671,
    division_type: "xã",
    codename: "xa_trung_chanh",
    district_code: 859,
  },
  {
    name: "Xã Tân An Luông",
    code: 29674,
    division_type: "xã",
    codename: "xa_tan_an_luong",
    district_code: 859,
  },
  {
    name: "Xã Thanh Bình",
    code: 29677,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành Tây",
    code: 29680,
    division_type: "xã",
    codename: "xa_trung_thanh_tay",
    district_code: 859,
  },
  {
    name: "Xã Trung Hiệp",
    code: 29683,
    division_type: "xã",
    codename: "xa_trung_hiep",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Phụng",
    code: 29686,
    division_type: "xã",
    codename: "xa_hieu_phung",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành Đông",
    code: 29689,
    division_type: "xã",
    codename: "xa_trung_thanh_dong",
    district_code: 859,
  },
  {
    name: "Xã Trung Thành",
    code: 29692,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 859,
  },
  {
    name: "Xã Trung Hiếu",
    code: 29695,
    division_type: "xã",
    codename: "xa_trung_hieu",
    district_code: 859,
  },
  {
    name: "Xã Trung Ngãi",
    code: 29698,
    division_type: "xã",
    codename: "xa_trung_ngai",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Thuận",
    code: 29701,
    division_type: "xã",
    codename: "xa_hieu_thuan",
    district_code: 859,
  },
  {
    name: "Xã Trung Nghĩa",
    code: 29704,
    division_type: "xã",
    codename: "xa_trung_nghia",
    district_code: 859,
  },
  {
    name: "Xã Trung An",
    code: 29707,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Nhơn",
    code: 29710,
    division_type: "xã",
    codename: "xa_hieu_nhon",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Thành",
    code: 29713,
    division_type: "xã",
    codename: "xa_hieu_thanh",
    district_code: 859,
  },
  {
    name: "Xã Hiếu Nghĩa",
    code: 29716,
    division_type: "xã",
    codename: "xa_hieu_nghia",
    district_code: 859,
  },
  {
    name: "Thị trấn Tam Bình",
    code: 29719,
    division_type: "thị trấn",
    codename: "thi_tran_tam_binh",
    district_code: 860,
  },
  {
    name: "Xã Tân Lộc",
    code: 29722,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 860,
  },
  {
    name: "Xã Phú Thịnh",
    code: 29725,
    division_type: "xã",
    codename: "xa_phu_thinh",
    district_code: 860,
  },
  {
    name: "Xã Hậu Lộc",
    code: 29728,
    division_type: "xã",
    codename: "xa_hau_loc",
    district_code: 860,
  },
  {
    name: "Xã Hòa Thạnh",
    code: 29731,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 860,
  },
  {
    name: "Xã Hoà Lộc",
    code: 29734,
    division_type: "xã",
    codename: "xa_hoa_loc",
    district_code: 860,
  },
  {
    name: "Xã Phú Lộc",
    code: 29737,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 860,
  },
  {
    name: "Xã Song Phú",
    code: 29740,
    division_type: "xã",
    codename: "xa_song_phu",
    district_code: 860,
  },
  {
    name: "Xã Hòa Hiệp",
    code: 29743,
    division_type: "xã",
    codename: "xa_hoa_hiep",
    district_code: 860,
  },
  {
    name: "Xã Mỹ Lộc",
    code: 29746,
    division_type: "xã",
    codename: "xa_my_loc",
    district_code: 860,
  },
  {
    name: "Xã Tân Phú",
    code: 29749,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 860,
  },
  {
    name: "Xã Long Phú",
    code: 29752,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 860,
  },
  {
    name: "Xã Mỹ Thạnh Trung",
    code: 29755,
    division_type: "xã",
    codename: "xa_my_thanh_trung",
    district_code: 860,
  },
  {
    name: "Xã Tường Lộc",
    code: 29758,
    division_type: "xã",
    codename: "xa_tuong_loc",
    district_code: 860,
  },
  {
    name: "Xã Loan Mỹ",
    code: 29761,
    division_type: "xã",
    codename: "xa_loan_my",
    district_code: 860,
  },
  {
    name: "Xã Ngãi Tứ",
    code: 29764,
    division_type: "xã",
    codename: "xa_ngai_tu",
    district_code: 860,
  },
  {
    name: "Xã Bình Ninh",
    code: 29767,
    division_type: "xã",
    codename: "xa_binh_ninh",
    district_code: 860,
  },
  {
    name: "Phường Cái Vồn",
    code: 29770,
    division_type: "phường",
    codename: "phuong_cai_von",
    district_code: 861,
  },
  {
    name: "Phường Thành Phước",
    code: 29771,
    division_type: "phường",
    codename: "phuong_thanh_phuoc",
    district_code: 861,
  },
  {
    name: "Xã Thuận An",
    code: 29806,
    division_type: "xã",
    codename: "xa_thuan_an",
    district_code: 861,
  },
  {
    name: "Xã Đông Thạnh",
    code: 29809,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 861,
  },
  {
    name: "Xã Đông Bình",
    code: 29812,
    division_type: "xã",
    codename: "xa_dong_binh",
    district_code: 861,
  },
  {
    name: "Phường Đông Thuận",
    code: 29813,
    division_type: "phường",
    codename: "phuong_dong_thuan",
    district_code: 861,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 29815,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 861,
  },
  {
    name: "Xã Đông Thành",
    code: 29818,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 861,
  },
  {
    name: "Thị trấn Trà Ôn",
    code: 29821,
    division_type: "thị trấn",
    codename: "thi_tran_tra_on",
    district_code: 862,
  },
  {
    name: "Xã Xuân Hiệp",
    code: 29824,
    division_type: "xã",
    codename: "xa_xuan_hiep",
    district_code: 862,
  },
  {
    name: "Xã Nhơn Bình",
    code: 29827,
    division_type: "xã",
    codename: "xa_nhon_binh",
    district_code: 862,
  },
  {
    name: "Xã Hòa Bình",
    code: 29830,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 862,
  },
  {
    name: "Xã Thới Hòa",
    code: 29833,
    division_type: "xã",
    codename: "xa_thoi_hoa",
    district_code: 862,
  },
  {
    name: "Xã Trà Côn",
    code: 29836,
    division_type: "xã",
    codename: "xa_tra_con",
    district_code: 862,
  },
  {
    name: "Xã Tân Mỹ",
    code: 29839,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 862,
  },
  {
    name: "Xã Hựu Thành",
    code: 29842,
    division_type: "xã",
    codename: "xa_huu_thanh",
    district_code: 862,
  },
  {
    name: "Xã Vĩnh Xuân",
    code: 29845,
    division_type: "xã",
    codename: "xa_vinh_xuan",
    district_code: 862,
  },
  {
    name: "Xã Thuận Thới",
    code: 29848,
    division_type: "xã",
    codename: "xa_thuan_thoi",
    district_code: 862,
  },
  {
    name: "Xã Phú Thành",
    code: 29851,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 862,
  },
  {
    name: "Xã Thiện Mỹ",
    code: 29854,
    division_type: "xã",
    codename: "xa_thien_my",
    district_code: 862,
  },
  {
    name: "Xã Lục Sỹ Thành",
    code: 29857,
    division_type: "xã",
    codename: "xa_luc_sy_thanh",
    district_code: 862,
  },
  {
    name: "Xã Tích Thiện",
    code: 29860,
    division_type: "xã",
    codename: "xa_tich_thien",
    district_code: 862,
  },
  {
    name: "Xã Tân Hưng",
    code: 29773,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 863,
  },
  {
    name: "Xã Tân Thành",
    code: 29776,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 863,
  },
  {
    name: "Xã Thành Trung",
    code: 29779,
    division_type: "xã",
    codename: "xa_thanh_trung",
    district_code: 863,
  },
  {
    name: "Xã Tân An Thạnh",
    code: 29782,
    division_type: "xã",
    codename: "xa_tan_an_thanh",
    district_code: 863,
  },
  {
    name: "Xã Tân Lược",
    code: 29785,
    division_type: "xã",
    codename: "xa_tan_luoc",
    district_code: 863,
  },
  {
    name: "Xã Nguyễn Văn Thảnh",
    code: 29788,
    division_type: "xã",
    codename: "xa_nguyen_van_thanh",
    district_code: 863,
  },
  {
    name: "Xã Thành Lợi",
    code: 29791,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 863,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 29794,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 863,
  },
  {
    name: "Xã Tân Bình",
    code: 29797,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 863,
  },
  {
    name: "Thị trấn Tân Quới",
    code: 29800,
    division_type: "thị trấn",
    codename: "thi_tran_tan_quoi",
    district_code: 863,
  },
  {
    name: "Phường 11",
    code: 29863,
    division_type: "phường",
    codename: "phuong_11",
    district_code: 866,
  },
  {
    name: "Phường 1",
    code: 29866,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 866,
  },
  {
    name: "Phường 2",
    code: 29869,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 866,
  },
  {
    name: "Phường 4",
    code: 29872,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 866,
  },
  {
    name: "Phường 3",
    code: 29875,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 866,
  },
  {
    name: "Phường 6",
    code: 29878,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Ngãi",
    code: 29881,
    division_type: "xã",
    codename: "xa_my_ngai",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Tân",
    code: 29884,
    division_type: "xã",
    codename: "xa_my_tan",
    district_code: 866,
  },
  {
    name: "Xã Mỹ Trà",
    code: 29887,
    division_type: "xã",
    codename: "xa_my_tra",
    district_code: 866,
  },
  {
    name: "Phường Mỹ Phú",
    code: 29888,
    division_type: "phường",
    codename: "phuong_my_phu",
    district_code: 866,
  },
  {
    name: "Xã Tân Thuận Tây",
    code: 29890,
    division_type: "xã",
    codename: "xa_tan_thuan_tay",
    district_code: 866,
  },
  {
    name: "Phường Hoà Thuận",
    code: 29892,
    division_type: "phường",
    codename: "phuong_hoa_thuan",
    district_code: 866,
  },
  {
    name: "Xã Hòa An",
    code: 29893,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 866,
  },
  {
    name: "Xã Tân Thuận Đông",
    code: 29896,
    division_type: "xã",
    codename: "xa_tan_thuan_dong",
    district_code: 866,
  },
  {
    name: "Xã Tịnh Thới",
    code: 29899,
    division_type: "xã",
    codename: "xa_tinh_thoi",
    district_code: 866,
  },
  {
    name: "Phường 3",
    code: 29902,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 867,
  },
  {
    name: "Phường 1",
    code: 29905,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 867,
  },
  {
    name: "Phường 4",
    code: 29908,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 867,
  },
  {
    name: "Phường 2",
    code: 29911,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 867,
  },
  {
    name: "Xã Tân Khánh Đông",
    code: 29914,
    division_type: "xã",
    codename: "xa_tan_khanh_dong",
    district_code: 867,
  },
  {
    name: "Phường Tân Quy Đông",
    code: 29917,
    division_type: "phường",
    codename: "phuong_tan_quy_dong",
    district_code: 867,
  },
  {
    name: "Phường An Hoà",
    code: 29919,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 867,
  },
  {
    name: "Xã Tân Quy Tây",
    code: 29920,
    division_type: "xã",
    codename: "xa_tan_quy_tay",
    district_code: 867,
  },
  {
    name: "Xã Tân Phú Đông",
    code: 29923,
    division_type: "xã",
    codename: "xa_tan_phu_dong",
    district_code: 867,
  },
  {
    name: "Phường An Lộc",
    code: 29954,
    division_type: "phường",
    codename: "phuong_an_loc",
    district_code: 868,
  },
  {
    name: "Phường An Thạnh",
    code: 29955,
    division_type: "phường",
    codename: "phuong_an_thanh",
    district_code: 868,
  },
  {
    name: "Xã Bình Thạnh",
    code: 29959,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 868,
  },
  {
    name: "Xã Tân Hội",
    code: 29965,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 868,
  },
  {
    name: "Phường An Lạc",
    code: 29978,
    division_type: "phường",
    codename: "phuong_an_lac",
    district_code: 868,
  },
  {
    name: "Phường An Bình B",
    code: 29986,
    division_type: "phường",
    codename: "phuong_an_binh_b",
    district_code: 868,
  },
  {
    name: "Phường An Bình A",
    code: 29989,
    division_type: "phường",
    codename: "phuong_an_binh_a",
    district_code: 868,
  },
  {
    name: "Thị trấn Sa Rài",
    code: 29926,
    division_type: "thị trấn",
    codename: "thi_tran_sa_rai",
    district_code: 869,
  },
  {
    name: "Xã Tân Hộ Cơ",
    code: 29929,
    division_type: "xã",
    codename: "xa_tan_ho_co",
    district_code: 869,
  },
  {
    name: "Xã Thông Bình",
    code: 29932,
    division_type: "xã",
    codename: "xa_thong_binh",
    district_code: 869,
  },
  {
    name: "Xã Bình Phú",
    code: 29935,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 869,
  },
  {
    name: "Xã Tân Thành A",
    code: 29938,
    division_type: "xã",
    codename: "xa_tan_thanh_a",
    district_code: 869,
  },
  {
    name: "Xã Tân Thành B",
    code: 29941,
    division_type: "xã",
    codename: "xa_tan_thanh_b",
    district_code: 869,
  },
  {
    name: "Xã Tân Phước",
    code: 29944,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 869,
  },
  {
    name: "Xã Tân Công Chí",
    code: 29947,
    division_type: "xã",
    codename: "xa_tan_cong_chi",
    district_code: 869,
  },
  {
    name: "Xã An Phước",
    code: 29950,
    division_type: "xã",
    codename: "xa_an_phuoc",
    district_code: 869,
  },
  {
    name: "Xã Thường Phước 1",
    code: 29956,
    division_type: "xã",
    codename: "xa_thuong_phuoc_1",
    district_code: 870,
  },
  {
    name: "Xã Thường Thới Hậu A",
    code: 29962,
    division_type: "xã",
    codename: "xa_thuong_thoi_hau_a",
    district_code: 870,
  },
  {
    name: "Thị trấn Thường Thới Tiền",
    code: 29971,
    division_type: "thị trấn",
    codename: "thi_tran_thuong_thoi_tien",
    district_code: 870,
  },
  {
    name: "Xã Thường Phước 2",
    code: 29974,
    division_type: "xã",
    codename: "xa_thuong_phuoc_2",
    district_code: 870,
  },
  {
    name: "Xã Thường Lạc",
    code: 29977,
    division_type: "xã",
    codename: "xa_thuong_lac",
    district_code: 870,
  },
  {
    name: "Xã Long Khánh A",
    code: 29980,
    division_type: "xã",
    codename: "xa_long_khanh_a",
    district_code: 870,
  },
  {
    name: "Xã Long Khánh B",
    code: 29983,
    division_type: "xã",
    codename: "xa_long_khanh_b",
    district_code: 870,
  },
  {
    name: "Xã Long Thuận",
    code: 29992,
    division_type: "xã",
    codename: "xa_long_thuan",
    district_code: 870,
  },
  {
    name: "Xã Phú Thuận B",
    code: 29995,
    division_type: "xã",
    codename: "xa_phu_thuan_b",
    district_code: 870,
  },
  {
    name: "Xã Phú Thuận A",
    code: 29998,
    division_type: "xã",
    codename: "xa_phu_thuan_a",
    district_code: 870,
  },
  {
    name: "Thị trấn Tràm Chim",
    code: 30001,
    division_type: "thị trấn",
    codename: "thi_tran_tram_chim",
    district_code: 871,
  },
  {
    name: "Xã Hoà Bình",
    code: 30004,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 871,
  },
  {
    name: "Xã Tân Công Sính",
    code: 30007,
    division_type: "xã",
    codename: "xa_tan_cong_sinh",
    district_code: 871,
  },
  {
    name: "Xã Phú Hiệp",
    code: 30010,
    division_type: "xã",
    codename: "xa_phu_hiep",
    district_code: 871,
  },
  {
    name: "Xã Phú Đức",
    code: 30013,
    division_type: "xã",
    codename: "xa_phu_duc",
    district_code: 871,
  },
  {
    name: "Xã Phú Thành B",
    code: 30016,
    division_type: "xã",
    codename: "xa_phu_thanh_b",
    district_code: 871,
  },
  {
    name: "Xã An Hòa",
    code: 30019,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 871,
  },
  {
    name: "Xã An Long",
    code: 30022,
    division_type: "xã",
    codename: "xa_an_long",
    district_code: 871,
  },
  {
    name: "Xã Phú Cường",
    code: 30025,
    division_type: "xã",
    codename: "xa_phu_cuong",
    district_code: 871,
  },
  {
    name: "Xã Phú Ninh",
    code: 30028,
    division_type: "xã",
    codename: "xa_phu_ninh",
    district_code: 871,
  },
  {
    name: "Xã Phú Thọ",
    code: 30031,
    division_type: "xã",
    codename: "xa_phu_tho",
    district_code: 871,
  },
  {
    name: "Xã Phú Thành A",
    code: 30034,
    division_type: "xã",
    codename: "xa_phu_thanh_a",
    district_code: 871,
  },
  {
    name: "Thị trấn Mỹ An",
    code: 30037,
    division_type: "thị trấn",
    codename: "thi_tran_my_an",
    district_code: 872,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 30040,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 872,
  },
  {
    name: "Xã Hưng Thạnh",
    code: 30043,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 872,
  },
  {
    name: "Xã Trường Xuân",
    code: 30046,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 872,
  },
  {
    name: "Xã Tân Kiều",
    code: 30049,
    division_type: "xã",
    codename: "xa_tan_kieu",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Hòa",
    code: 30052,
    division_type: "xã",
    codename: "xa_my_hoa",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Quý",
    code: 30055,
    division_type: "xã",
    codename: "xa_my_quy",
    district_code: 872,
  },
  {
    name: "Xã Mỹ Đông",
    code: 30058,
    division_type: "xã",
    codename: "xa_my_dong",
    district_code: 872,
  },
  {
    name: "Xã Đốc Binh Kiều",
    code: 30061,
    division_type: "xã",
    codename: "xa_doc_binh_kieu",
    district_code: 872,
  },
  {
    name: "Xã Mỹ An",
    code: 30064,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 872,
  },
  {
    name: "Xã Phú Điền",
    code: 30067,
    division_type: "xã",
    codename: "xa_phu_dien",
    district_code: 872,
  },
  {
    name: "Xã Láng Biển",
    code: 30070,
    division_type: "xã",
    codename: "xa_lang_bien",
    district_code: 872,
  },
  {
    name: "Xã Thanh Mỹ",
    code: 30073,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 872,
  },
  {
    name: "Thị trấn Mỹ Thọ",
    code: 30076,
    division_type: "thị trấn",
    codename: "thi_tran_my_tho",
    district_code: 873,
  },
  {
    name: "Xã Gáo Giồng",
    code: 30079,
    division_type: "xã",
    codename: "xa_gao_giong",
    district_code: 873,
  },
  {
    name: "Xã Phương Thịnh",
    code: 30082,
    division_type: "xã",
    codename: "xa_phuong_thinh",
    district_code: 873,
  },
  {
    name: "Xã Ba Sao",
    code: 30085,
    division_type: "xã",
    codename: "xa_ba_sao",
    district_code: 873,
  },
  {
    name: "Xã Phong Mỹ",
    code: 30088,
    division_type: "xã",
    codename: "xa_phong_my",
    district_code: 873,
  },
  {
    name: "Xã Tân Nghĩa",
    code: 30091,
    division_type: "xã",
    codename: "xa_tan_nghia",
    district_code: 873,
  },
  {
    name: "Xã Phương Trà",
    code: 30094,
    division_type: "xã",
    codename: "xa_phuong_tra",
    district_code: 873,
  },
  {
    name: "Xã Nhị Mỹ",
    code: 30097,
    division_type: "xã",
    codename: "xa_nhi_my",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Thọ",
    code: 30100,
    division_type: "xã",
    codename: "xa_my_tho",
    district_code: 873,
  },
  {
    name: "Xã Tân Hội Trung",
    code: 30103,
    division_type: "xã",
    codename: "xa_tan_hoi_trung",
    district_code: 873,
  },
  {
    name: "Xã An Bình",
    code: 30106,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Hội",
    code: 30109,
    division_type: "xã",
    codename: "xa_my_hoi",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 30112,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Long",
    code: 30115,
    division_type: "xã",
    codename: "xa_my_long",
    district_code: 873,
  },
  {
    name: "Xã Bình Hàng Trung",
    code: 30118,
    division_type: "xã",
    codename: "xa_binh_hang_trung",
    district_code: 873,
  },
  {
    name: "Xã Mỹ Xương",
    code: 30121,
    division_type: "xã",
    codename: "xa_my_xuong",
    district_code: 873,
  },
  {
    name: "Xã Bình Hàng Tây",
    code: 30124,
    division_type: "xã",
    codename: "xa_binh_hang_tay",
    district_code: 873,
  },
  {
    name: "Xã Bình Thạnh",
    code: 30127,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 873,
  },
  {
    name: "Thị trấn Thanh Bình",
    code: 30130,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_binh",
    district_code: 874,
  },
  {
    name: "Xã Tân Quới",
    code: 30133,
    division_type: "xã",
    codename: "xa_tan_quoi",
    district_code: 874,
  },
  {
    name: "Xã Tân Hòa",
    code: 30136,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 874,
  },
  {
    name: "Xã An Phong",
    code: 30139,
    division_type: "xã",
    codename: "xa_an_phong",
    district_code: 874,
  },
  {
    name: "Xã Phú Lợi",
    code: 30142,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 874,
  },
  {
    name: "Xã Tân Mỹ",
    code: 30145,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 874,
  },
  {
    name: "Xã Bình Tấn",
    code: 30148,
    division_type: "xã",
    codename: "xa_binh_tan",
    district_code: 874,
  },
  {
    name: "Xã Tân Huề",
    code: 30151,
    division_type: "xã",
    codename: "xa_tan_hue",
    district_code: 874,
  },
  {
    name: "Xã Tân Bình",
    code: 30154,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 874,
  },
  {
    name: "Xã Tân Thạnh",
    code: 30157,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 874,
  },
  {
    name: "Xã Tân Phú",
    code: 30160,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 874,
  },
  {
    name: "Xã Bình Thành",
    code: 30163,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 874,
  },
  {
    name: "Xã Tân Long",
    code: 30166,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 874,
  },
  {
    name: "Thị trấn Lấp Vò",
    code: 30169,
    division_type: "thị trấn",
    codename: "thi_tran_lap_vo",
    district_code: 875,
  },
  {
    name: "Xã Mỹ An Hưng A",
    code: 30172,
    division_type: "xã",
    codename: "xa_my_an_hung_a",
    district_code: 875,
  },
  {
    name: "Xã Tân Mỹ",
    code: 30175,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 875,
  },
  {
    name: "Xã Mỹ An Hưng B",
    code: 30178,
    division_type: "xã",
    codename: "xa_my_an_hung_b",
    district_code: 875,
  },
  {
    name: "Xã Tân Khánh Trung",
    code: 30181,
    division_type: "xã",
    codename: "xa_tan_khanh_trung",
    district_code: 875,
  },
  {
    name: "Xã Long Hưng A",
    code: 30184,
    division_type: "xã",
    codename: "xa_long_hung_a",
    district_code: 875,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 30187,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 875,
  },
  {
    name: "Xã Long Hưng B",
    code: 30190,
    division_type: "xã",
    codename: "xa_long_hung_b",
    district_code: 875,
  },
  {
    name: "Xã Bình Thành",
    code: 30193,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 875,
  },
  {
    name: "Xã Định An",
    code: 30196,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 875,
  },
  {
    name: "Xã Định Yên",
    code: 30199,
    division_type: "xã",
    codename: "xa_dinh_yen",
    district_code: 875,
  },
  {
    name: "Xã Hội An Đông",
    code: 30202,
    division_type: "xã",
    codename: "xa_hoi_an_dong",
    district_code: 875,
  },
  {
    name: "Xã Bình Thạnh Trung",
    code: 30205,
    division_type: "xã",
    codename: "xa_binh_thanh_trung",
    district_code: 875,
  },
  {
    name: "Thị trấn Lai Vung",
    code: 30208,
    division_type: "thị trấn",
    codename: "thi_tran_lai_vung",
    district_code: 876,
  },
  {
    name: "Xã Tân Dương",
    code: 30211,
    division_type: "xã",
    codename: "xa_tan_duong",
    district_code: 876,
  },
  {
    name: "Xã Hòa Thành",
    code: 30214,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 876,
  },
  {
    name: "Xã Long Hậu",
    code: 30217,
    division_type: "xã",
    codename: "xa_long_hau",
    district_code: 876,
  },
  {
    name: "Xã Tân Phước",
    code: 30220,
    division_type: "xã",
    codename: "xa_tan_phuoc",
    district_code: 876,
  },
  {
    name: "Xã Hòa Long",
    code: 30223,
    division_type: "xã",
    codename: "xa_hoa_long",
    district_code: 876,
  },
  {
    name: "Xã Tân Thành",
    code: 30226,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 876,
  },
  {
    name: "Xã Long Thắng",
    code: 30229,
    division_type: "xã",
    codename: "xa_long_thang",
    district_code: 876,
  },
  {
    name: "Xã Vĩnh Thới",
    code: 30232,
    division_type: "xã",
    codename: "xa_vinh_thoi",
    district_code: 876,
  },
  {
    name: "Xã Tân Hòa",
    code: 30235,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 876,
  },
  {
    name: "Xã Định Hòa",
    code: 30238,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 876,
  },
  {
    name: "Xã Phong Hòa",
    code: 30241,
    division_type: "xã",
    codename: "xa_phong_hoa",
    district_code: 876,
  },
  {
    name: "Thị trấn Cái Tàu Hạ",
    code: 30244,
    division_type: "thị trấn",
    codename: "thi_tran_cai_tau_ha",
    district_code: 877,
  },
  {
    name: "Xã An Hiệp",
    code: 30247,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 877,
  },
  {
    name: "Xã An Nhơn",
    code: 30250,
    division_type: "xã",
    codename: "xa_an_nhon",
    district_code: 877,
  },
  {
    name: "Xã Tân Nhuận Đông",
    code: 30253,
    division_type: "xã",
    codename: "xa_tan_nhuan_dong",
    district_code: 877,
  },
  {
    name: "Xã Tân Bình",
    code: 30256,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 877,
  },
  {
    name: "Xã Tân Phú Trung",
    code: 30259,
    division_type: "xã",
    codename: "xa_tan_phu_trung",
    district_code: 877,
  },
  {
    name: "Xã Phú Long",
    code: 30262,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 877,
  },
  {
    name: "Xã An Phú Thuận",
    code: 30265,
    division_type: "xã",
    codename: "xa_an_phu_thuan",
    district_code: 877,
  },
  {
    name: "Xã Phú Hựu",
    code: 30268,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 877,
  },
  {
    name: "Xã An Khánh",
    code: 30271,
    division_type: "xã",
    codename: "xa_an_khanh",
    district_code: 877,
  },
  {
    name: "Xã Tân Phú",
    code: 30274,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 877,
  },
  {
    name: "Xã Hòa Tân",
    code: 30277,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 877,
  },
  {
    name: "Phường Mỹ Bình",
    code: 30280,
    division_type: "phường",
    codename: "phuong_my_binh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Long",
    code: 30283,
    division_type: "phường",
    codename: "phuong_my_long",
    district_code: 883,
  },
  {
    name: "Phường Đông Xuyên",
    code: 30285,
    division_type: "phường",
    codename: "phuong_dong_xuyen",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Xuyên",
    code: 30286,
    division_type: "phường",
    codename: "phuong_my_xuyen",
    district_code: 883,
  },
  {
    name: "Phường Bình Đức",
    code: 30289,
    division_type: "phường",
    codename: "phuong_binh_duc",
    district_code: 883,
  },
  {
    name: "Phường Bình Khánh",
    code: 30292,
    division_type: "phường",
    codename: "phuong_binh_khanh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Phước",
    code: 30295,
    division_type: "phường",
    codename: "phuong_my_phuoc",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Quý",
    code: 30298,
    division_type: "phường",
    codename: "phuong_my_quy",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Thới",
    code: 30301,
    division_type: "phường",
    codename: "phuong_my_thoi",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Thạnh",
    code: 30304,
    division_type: "phường",
    codename: "phuong_my_thanh",
    district_code: 883,
  },
  {
    name: "Phường Mỹ Hòa",
    code: 30307,
    division_type: "phường",
    codename: "phuong_my_hoa",
    district_code: 883,
  },
  {
    name: "Xã Mỹ Khánh",
    code: 30310,
    division_type: "xã",
    codename: "xa_my_khanh",
    district_code: 883,
  },
  {
    name: "Xã Mỹ Hoà Hưng",
    code: 30313,
    division_type: "xã",
    codename: "xa_my_hoa_hung",
    district_code: 883,
  },
  {
    name: "Phường Châu Phú B",
    code: 30316,
    division_type: "phường",
    codename: "phuong_chau_phu_b",
    district_code: 884,
  },
  {
    name: "Phường Châu Phú A",
    code: 30319,
    division_type: "phường",
    codename: "phuong_chau_phu_a",
    district_code: 884,
  },
  {
    name: "Phường Vĩnh Mỹ",
    code: 30322,
    division_type: "phường",
    codename: "phuong_vinh_my",
    district_code: 884,
  },
  {
    name: "Phường Núi Sam",
    code: 30325,
    division_type: "phường",
    codename: "phuong_nui_sam",
    district_code: 884,
  },
  {
    name: "Phường Vĩnh Ngươn",
    code: 30328,
    division_type: "phường",
    codename: "phuong_vinh_nguon",
    district_code: 884,
  },
  {
    name: "Xã Vĩnh Tế",
    code: 30331,
    division_type: "xã",
    codename: "xa_vinh_te",
    district_code: 884,
  },
  {
    name: "Xã Vĩnh Châu",
    code: 30334,
    division_type: "xã",
    codename: "xa_vinh_chau",
    district_code: 884,
  },
  {
    name: "Thị trấn An Phú",
    code: 30337,
    division_type: "thị trấn",
    codename: "thi_tran_an_phu",
    district_code: 886,
  },
  {
    name: "Xã Khánh An",
    code: 30340,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 886,
  },
  {
    name: "Thị trấn Long Bình",
    code: 30341,
    division_type: "thị trấn",
    codename: "thi_tran_long_binh",
    district_code: 886,
  },
  {
    name: "Xã Khánh Bình",
    code: 30343,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 886,
  },
  {
    name: "Xã Quốc Thái",
    code: 30346,
    division_type: "xã",
    codename: "xa_quoc_thai",
    district_code: 886,
  },
  {
    name: "Xã Nhơn Hội",
    code: 30349,
    division_type: "xã",
    codename: "xa_nhon_hoi",
    district_code: 886,
  },
  {
    name: "Xã Phú Hữu",
    code: 30352,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 886,
  },
  {
    name: "Xã Phú Hội",
    code: 30355,
    division_type: "xã",
    codename: "xa_phu_hoi",
    district_code: 886,
  },
  {
    name: "Xã Phước Hưng",
    code: 30358,
    division_type: "xã",
    codename: "xa_phuoc_hung",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Lộc",
    code: 30361,
    division_type: "xã",
    codename: "xa_vinh_loc",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Hậu",
    code: 30364,
    division_type: "xã",
    codename: "xa_vinh_hau",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Trường",
    code: 30367,
    division_type: "xã",
    codename: "xa_vinh_truong",
    district_code: 886,
  },
  {
    name: "Xã Vĩnh Hội Đông",
    code: 30370,
    division_type: "xã",
    codename: "xa_vinh_hoi_dong",
    district_code: 886,
  },
  {
    name: "Xã Đa Phước",
    code: 30373,
    division_type: "xã",
    codename: "xa_da_phuoc",
    district_code: 886,
  },
  {
    name: "Phường Long Thạnh",
    code: 30376,
    division_type: "phường",
    codename: "phuong_long_thanh",
    district_code: 887,
  },
  {
    name: "Phường Long Hưng",
    code: 30377,
    division_type: "phường",
    codename: "phuong_long_hung",
    district_code: 887,
  },
  {
    name: "Phường Long Châu",
    code: 30378,
    division_type: "phường",
    codename: "phuong_long_chau",
    district_code: 887,
  },
  {
    name: "Xã Phú Lộc",
    code: 30379,
    division_type: "xã",
    codename: "xa_phu_loc",
    district_code: 887,
  },
  {
    name: "Xã Vĩnh Xương",
    code: 30382,
    division_type: "xã",
    codename: "xa_vinh_xuong",
    district_code: 887,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 30385,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 887,
  },
  {
    name: "Xã Tân Thạnh",
    code: 30387,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 887,
  },
  {
    name: "Xã Tân An",
    code: 30388,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 887,
  },
  {
    name: "Xã Long An",
    code: 30391,
    division_type: "xã",
    codename: "xa_long_an",
    district_code: 887,
  },
  {
    name: "Phường Long Phú",
    code: 30394,
    division_type: "phường",
    codename: "phuong_long_phu",
    district_code: 887,
  },
  {
    name: "Xã Châu Phong",
    code: 30397,
    division_type: "xã",
    codename: "xa_chau_phong",
    district_code: 887,
  },
  {
    name: "Xã Phú Vĩnh",
    code: 30400,
    division_type: "xã",
    codename: "xa_phu_vinh",
    district_code: 887,
  },
  {
    name: "Xã Lê Chánh",
    code: 30403,
    division_type: "xã",
    codename: "xa_le_chanh",
    district_code: 887,
  },
  {
    name: "Phường Long Sơn",
    code: 30412,
    division_type: "phường",
    codename: "phuong_long_son",
    district_code: 887,
  },
  {
    name: "Thị trấn Phú Mỹ",
    code: 30406,
    division_type: "thị trấn",
    codename: "thi_tran_phu_my",
    district_code: 888,
  },
  {
    name: "Thị trấn Chợ Vàm",
    code: 30409,
    division_type: "thị trấn",
    codename: "thi_tran_cho_vam",
    district_code: 888,
  },
  {
    name: "Xã Long Hoà",
    code: 30415,
    division_type: "xã",
    codename: "xa_long_hoa",
    district_code: 888,
  },
  {
    name: "Xã Phú Long",
    code: 30418,
    division_type: "xã",
    codename: "xa_phu_long",
    district_code: 888,
  },
  {
    name: "Xã Phú Lâm",
    code: 30421,
    division_type: "xã",
    codename: "xa_phu_lam",
    district_code: 888,
  },
  {
    name: "Xã Phú Hiệp",
    code: 30424,
    division_type: "xã",
    codename: "xa_phu_hiep",
    district_code: 888,
  },
  {
    name: "Xã Phú Thạnh",
    code: 30427,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 888,
  },
  {
    name: "Xã Hoà Lạc",
    code: 30430,
    division_type: "xã",
    codename: "xa_hoa_lac",
    district_code: 888,
  },
  {
    name: "Xã Phú Thành",
    code: 30433,
    division_type: "xã",
    codename: "xa_phu_thanh",
    district_code: 888,
  },
  {
    name: "Xã Phú An",
    code: 30436,
    division_type: "xã",
    codename: "xa_phu_an",
    district_code: 888,
  },
  {
    name: "Xã Phú Xuân",
    code: 30439,
    division_type: "xã",
    codename: "xa_phu_xuan",
    district_code: 888,
  },
  {
    name: "Xã Hiệp Xương",
    code: 30442,
    division_type: "xã",
    codename: "xa_hiep_xuong",
    district_code: 888,
  },
  {
    name: "Xã Phú Bình",
    code: 30445,
    division_type: "xã",
    codename: "xa_phu_binh",
    district_code: 888,
  },
  {
    name: "Xã Phú Thọ",
    code: 30448,
    division_type: "xã",
    codename: "xa_phu_tho",
    district_code: 888,
  },
  {
    name: "Xã Phú Hưng",
    code: 30451,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 888,
  },
  {
    name: "Xã Bình Thạnh Đông",
    code: 30454,
    division_type: "xã",
    codename: "xa_binh_thanh_dong",
    district_code: 888,
  },
  {
    name: "Xã Tân Hòa",
    code: 30457,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 888,
  },
  {
    name: "Xã Tân Trung",
    code: 30460,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 888,
  },
  {
    name: "Thị trấn Cái Dầu",
    code: 30463,
    division_type: "thị trấn",
    codename: "thi_tran_cai_dau",
    district_code: 889,
  },
  {
    name: "Xã Khánh Hòa",
    code: 30466,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 889,
  },
  {
    name: "Xã Mỹ Đức",
    code: 30469,
    division_type: "xã",
    codename: "xa_my_duc",
    district_code: 889,
  },
  {
    name: "Xã Mỹ Phú",
    code: 30472,
    division_type: "xã",
    codename: "xa_my_phu",
    district_code: 889,
  },
  {
    name: "Xã Ô Long Vỹ",
    code: 30475,
    division_type: "xã",
    codename: "xa_o_long_vy",
    district_code: 889,
  },
  {
    name: "Thị trấn Vĩnh Thạnh Trung",
    code: 30478,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh_trung",
    district_code: 889,
  },
  {
    name: "Xã Thạnh Mỹ Tây",
    code: 30481,
    division_type: "xã",
    codename: "xa_thanh_my_tay",
    district_code: 889,
  },
  {
    name: "Xã Bình Long",
    code: 30484,
    division_type: "xã",
    codename: "xa_binh_long",
    district_code: 889,
  },
  {
    name: "Xã Bình Mỹ",
    code: 30487,
    division_type: "xã",
    codename: "xa_binh_my",
    district_code: 889,
  },
  {
    name: "Xã Bình Thủy",
    code: 30490,
    division_type: "xã",
    codename: "xa_binh_thuy",
    district_code: 889,
  },
  {
    name: "Xã Đào Hữu Cảnh",
    code: 30493,
    division_type: "xã",
    codename: "xa_dao_huu_canh",
    district_code: 889,
  },
  {
    name: "Xã Bình Phú",
    code: 30496,
    division_type: "xã",
    codename: "xa_binh_phu",
    district_code: 889,
  },
  {
    name: "Xã Bình Chánh",
    code: 30499,
    division_type: "xã",
    codename: "xa_binh_chanh",
    district_code: 889,
  },
  {
    name: "Thị trấn Nhà Bàng",
    code: 30502,
    division_type: "thị trấn",
    codename: "thi_tran_nha_bang",
    district_code: 890,
  },
  {
    name: "Thị trấn Chi Lăng",
    code: 30505,
    division_type: "thị trấn",
    codename: "thi_tran_chi_lang",
    district_code: 890,
  },
  {
    name: "Xã Núi Voi",
    code: 30508,
    division_type: "xã",
    codename: "xa_nui_voi",
    district_code: 890,
  },
  {
    name: "Xã Nhơn Hưng",
    code: 30511,
    division_type: "xã",
    codename: "xa_nhon_hung",
    district_code: 890,
  },
  {
    name: "Xã An Phú",
    code: 30514,
    division_type: "xã",
    codename: "xa_an_phu",
    district_code: 890,
  },
  {
    name: "Xã Thới Sơn",
    code: 30517,
    division_type: "xã",
    codename: "xa_thoi_son",
    district_code: 890,
  },
  {
    name: "Thị trấn Tịnh Biên",
    code: 30520,
    division_type: "thị trấn",
    codename: "thi_tran_tinh_bien",
    district_code: 890,
  },
  {
    name: "Xã Văn Giáo",
    code: 30523,
    division_type: "xã",
    codename: "xa_van_giao",
    district_code: 890,
  },
  {
    name: "Xã An Cư",
    code: 30526,
    division_type: "xã",
    codename: "xa_an_cu",
    district_code: 890,
  },
  {
    name: "Xã An Nông",
    code: 30529,
    division_type: "xã",
    codename: "xa_an_nong",
    district_code: 890,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 30532,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 890,
  },
  {
    name: "Xã Tân Lợi",
    code: 30535,
    division_type: "xã",
    codename: "xa_tan_loi",
    district_code: 890,
  },
  {
    name: "Xã An Hảo",
    code: 30538,
    division_type: "xã",
    codename: "xa_an_hao",
    district_code: 890,
  },
  {
    name: "Xã Tân Lập",
    code: 30541,
    division_type: "xã",
    codename: "xa_tan_lap",
    district_code: 890,
  },
  {
    name: "Thị trấn Tri Tôn",
    code: 30544,
    division_type: "thị trấn",
    codename: "thi_tran_tri_ton",
    district_code: 891,
  },
  {
    name: "Thị trấn Ba Chúc",
    code: 30547,
    division_type: "thị trấn",
    codename: "thi_tran_ba_chuc",
    district_code: 891,
  },
  {
    name: "Xã Lạc Quới",
    code: 30550,
    division_type: "xã",
    codename: "xa_lac_quoi",
    district_code: 891,
  },
  {
    name: "Xã Lê Trì",
    code: 30553,
    division_type: "xã",
    codename: "xa_le_tri",
    district_code: 891,
  },
  {
    name: "Xã Vĩnh Gia",
    code: 30556,
    division_type: "xã",
    codename: "xa_vinh_gia",
    district_code: 891,
  },
  {
    name: "Xã Vĩnh Phước",
    code: 30559,
    division_type: "xã",
    codename: "xa_vinh_phuoc",
    district_code: 891,
  },
  {
    name: "Xã Châu Lăng",
    code: 30562,
    division_type: "xã",
    codename: "xa_chau_lang",
    district_code: 891,
  },
  {
    name: "Xã Lương Phi",
    code: 30565,
    division_type: "xã",
    codename: "xa_luong_phi",
    district_code: 891,
  },
  {
    name: "Xã Lương An Trà",
    code: 30568,
    division_type: "xã",
    codename: "xa_luong_an_tra",
    district_code: 891,
  },
  {
    name: "Xã Tà Đảnh",
    code: 30571,
    division_type: "xã",
    codename: "xa_ta_danh",
    district_code: 891,
  },
  {
    name: "Xã Núi Tô",
    code: 30574,
    division_type: "xã",
    codename: "xa_nui_to",
    district_code: 891,
  },
  {
    name: "Xã An Tức",
    code: 30577,
    division_type: "xã",
    codename: "xa_an_tuc",
    district_code: 891,
  },
  {
    name: "Thị trấn Cô Tô",
    code: 30580,
    division_type: "thị trấn",
    codename: "thi_tran_co_to",
    district_code: 891,
  },
  {
    name: "Xã Tân Tuyến",
    code: 30583,
    division_type: "xã",
    codename: "xa_tan_tuyen",
    district_code: 891,
  },
  {
    name: "Xã Ô Lâm",
    code: 30586,
    division_type: "xã",
    codename: "xa_o_lam",
    district_code: 891,
  },
  {
    name: "Thị trấn An Châu",
    code: 30589,
    division_type: "thị trấn",
    codename: "thi_tran_an_chau",
    district_code: 892,
  },
  {
    name: "Xã An Hòa",
    code: 30592,
    division_type: "xã",
    codename: "xa_an_hoa",
    district_code: 892,
  },
  {
    name: "Xã Cần Đăng",
    code: 30595,
    division_type: "xã",
    codename: "xa_can_dang",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Hanh",
    code: 30598,
    division_type: "xã",
    codename: "xa_vinh_hanh",
    district_code: 892,
  },
  {
    name: "Xã Bình Thạnh",
    code: 30601,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 892,
  },
  {
    name: "Thị trấn Vĩnh Bình",
    code: 30604,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_binh",
    district_code: 892,
  },
  {
    name: "Xã Bình Hòa",
    code: 30607,
    division_type: "xã",
    codename: "xa_binh_hoa",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh An",
    code: 30610,
    division_type: "xã",
    codename: "xa_vinh_an",
    district_code: 892,
  },
  {
    name: "Xã Hòa Bình Thạnh",
    code: 30613,
    division_type: "xã",
    codename: "xa_hoa_binh_thanh",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 30616,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Nhuận",
    code: 30619,
    division_type: "xã",
    codename: "xa_vinh_nhuan",
    district_code: 892,
  },
  {
    name: "Xã Tân Phú",
    code: 30622,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 892,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 30625,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 892,
  },
  {
    name: "Thị trấn Chợ Mới",
    code: 30628,
    division_type: "thị trấn",
    codename: "thi_tran_cho_moi",
    district_code: 893,
  },
  {
    name: "Thị trấn Mỹ Luông",
    code: 30631,
    division_type: "thị trấn",
    codename: "thi_tran_my_luong",
    district_code: 893,
  },
  {
    name: "Xã Kiến An",
    code: 30634,
    division_type: "xã",
    codename: "xa_kien_an",
    district_code: 893,
  },
  {
    name: "Xã Mỹ Hội Đông",
    code: 30637,
    division_type: "xã",
    codename: "xa_my_hoi_dong",
    district_code: 893,
  },
  {
    name: "Xã Long Điền A",
    code: 30640,
    division_type: "xã",
    codename: "xa_long_dien_a",
    district_code: 893,
  },
  {
    name: "Xã Tấn Mỹ",
    code: 30643,
    division_type: "xã",
    codename: "xa_tan_my",
    district_code: 893,
  },
  {
    name: "Xã Long Điền B",
    code: 30646,
    division_type: "xã",
    codename: "xa_long_dien_b",
    district_code: 893,
  },
  {
    name: "Xã Kiến Thành",
    code: 30649,
    division_type: "xã",
    codename: "xa_kien_thanh",
    district_code: 893,
  },
  {
    name: "Xã Mỹ Hiệp",
    code: 30652,
    division_type: "xã",
    codename: "xa_my_hiep",
    district_code: 893,
  },
  {
    name: "Xã Mỹ An",
    code: 30655,
    division_type: "xã",
    codename: "xa_my_an",
    district_code: 893,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 30658,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 893,
  },
  {
    name: "Xã Long Giang",
    code: 30661,
    division_type: "xã",
    codename: "xa_long_giang",
    district_code: 893,
  },
  {
    name: "Xã Long Kiến",
    code: 30664,
    division_type: "xã",
    codename: "xa_long_kien",
    district_code: 893,
  },
  {
    name: "Xã Bình Phước Xuân",
    code: 30667,
    division_type: "xã",
    codename: "xa_binh_phuoc_xuan",
    district_code: 893,
  },
  {
    name: "Xã An Thạnh Trung",
    code: 30670,
    division_type: "xã",
    codename: "xa_an_thanh_trung",
    district_code: 893,
  },
  {
    name: "Xã Hội An",
    code: 30673,
    division_type: "xã",
    codename: "xa_hoi_an",
    district_code: 893,
  },
  {
    name: "Xã Hòa Bình",
    code: 30676,
    division_type: "xã",
    codename: "xa_hoa_binh",
    district_code: 893,
  },
  {
    name: "Xã Hòa An",
    code: 30679,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 893,
  },
  {
    name: "Thị trấn Núi Sập",
    code: 30682,
    division_type: "thị trấn",
    codename: "thi_tran_nui_sap",
    district_code: 894,
  },
  {
    name: "Thị trấn Phú Hoà",
    code: 30685,
    division_type: "thị trấn",
    codename: "thi_tran_phu_hoa",
    district_code: 894,
  },
  {
    name: "Thị trấn Óc Eo",
    code: 30688,
    division_type: "thị trấn",
    codename: "thi_tran_oc_eo",
    district_code: 894,
  },
  {
    name: "Xã Tây Phú",
    code: 30691,
    division_type: "xã",
    codename: "xa_tay_phu",
    district_code: 894,
  },
  {
    name: "Xã An Bình",
    code: 30692,
    division_type: "xã",
    codename: "xa_an_binh",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30694,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Trạch",
    code: 30697,
    division_type: "xã",
    codename: "xa_vinh_trach",
    district_code: 894,
  },
  {
    name: "Xã Phú Thuận",
    code: 30700,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Chánh",
    code: 30703,
    division_type: "xã",
    codename: "xa_vinh_chanh",
    district_code: 894,
  },
  {
    name: "Xã Định Mỹ",
    code: 30706,
    division_type: "xã",
    codename: "xa_dinh_my",
    district_code: 894,
  },
  {
    name: "Xã Định Thành",
    code: 30709,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 894,
  },
  {
    name: "Xã Mỹ Phú Đông",
    code: 30712,
    division_type: "xã",
    codename: "xa_my_phu_dong",
    district_code: 894,
  },
  {
    name: "Xã Vọng Đông",
    code: 30715,
    division_type: "xã",
    codename: "xa_vong_dong",
    district_code: 894,
  },
  {
    name: "Xã Vĩnh Khánh",
    code: 30718,
    division_type: "xã",
    codename: "xa_vinh_khanh",
    district_code: 894,
  },
  {
    name: "Xã Thoại Giang",
    code: 30721,
    division_type: "xã",
    codename: "xa_thoai_giang",
    district_code: 894,
  },
  {
    name: "Xã Bình Thành",
    code: 30724,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 894,
  },
  {
    name: "Xã Vọng Thê",
    code: 30727,
    division_type: "xã",
    codename: "xa_vong_the",
    district_code: 894,
  },
  {
    name: "Phường Vĩnh Thanh Vân",
    code: 30730,
    division_type: "phường",
    codename: "phuong_vinh_thanh_van",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Thanh",
    code: 30733,
    division_type: "phường",
    codename: "phuong_vinh_thanh",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Quang",
    code: 30736,
    division_type: "phường",
    codename: "phuong_vinh_quang",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Hiệp",
    code: 30739,
    division_type: "phường",
    codename: "phuong_vinh_hiep",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Bảo",
    code: 30742,
    division_type: "phường",
    codename: "phuong_vinh_bao",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Lạc",
    code: 30745,
    division_type: "phường",
    codename: "phuong_vinh_lac",
    district_code: 899,
  },
  {
    name: "Phường An Hòa",
    code: 30748,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 899,
  },
  {
    name: "Phường An Bình",
    code: 30751,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 899,
  },
  {
    name: "Phường Rạch Sỏi",
    code: 30754,
    division_type: "phường",
    codename: "phuong_rach_soi",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Lợi",
    code: 30757,
    division_type: "phường",
    codename: "phuong_vinh_loi",
    district_code: 899,
  },
  {
    name: "Phường Vĩnh Thông",
    code: 30760,
    division_type: "phường",
    codename: "phuong_vinh_thong",
    district_code: 899,
  },
  {
    name: "Xã Phi Thông",
    code: 30763,
    division_type: "xã",
    codename: "xa_phi_thong",
    district_code: 899,
  },
  {
    name: "Phường Tô Châu",
    code: 30766,
    division_type: "phường",
    codename: "phuong_to_chau",
    district_code: 900,
  },
  {
    name: "Phường Đông Hồ",
    code: 30769,
    division_type: "phường",
    codename: "phuong_dong_ho",
    district_code: 900,
  },
  {
    name: "Phường Bình San",
    code: 30772,
    division_type: "phường",
    codename: "phuong_binh_san",
    district_code: 900,
  },
  {
    name: "Phường Pháo Đài",
    code: 30775,
    division_type: "phường",
    codename: "phuong_phao_dai",
    district_code: 900,
  },
  {
    name: "Phường Mỹ Đức",
    code: 30778,
    division_type: "phường",
    codename: "phuong_my_duc",
    district_code: 900,
  },
  {
    name: "Xã Tiên Hải",
    code: 30781,
    division_type: "xã",
    codename: "xa_tien_hai",
    district_code: 900,
  },
  {
    name: "Xã Thuận Yên",
    code: 30784,
    division_type: "xã",
    codename: "xa_thuan_yen",
    district_code: 900,
  },
  {
    name: "Thị trấn Kiên Lương",
    code: 30787,
    division_type: "thị trấn",
    codename: "thi_tran_kien_luong",
    district_code: 902,
  },
  {
    name: "Xã Kiên Bình",
    code: 30790,
    division_type: "xã",
    codename: "xa_kien_binh",
    district_code: 902,
  },
  {
    name: "Xã Hòa Điền",
    code: 30802,
    division_type: "xã",
    codename: "xa_hoa_dien",
    district_code: 902,
  },
  {
    name: "Xã Dương Hòa",
    code: 30805,
    division_type: "xã",
    codename: "xa_duong_hoa",
    district_code: 902,
  },
  {
    name: "Xã Bình An",
    code: 30808,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 902,
  },
  {
    name: "Xã Bình Trị",
    code: 30809,
    division_type: "xã",
    codename: "xa_binh_tri",
    district_code: 902,
  },
  {
    name: "Xã Sơn Hải",
    code: 30811,
    division_type: "xã",
    codename: "xa_son_hai",
    district_code: 902,
  },
  {
    name: "Xã Hòn Nghệ",
    code: 30814,
    division_type: "xã",
    codename: "xa_hon_nghe",
    district_code: 902,
  },
  {
    name: "Thị trấn Hòn Đất",
    code: 30817,
    division_type: "thị trấn",
    codename: "thi_tran_hon_dat",
    district_code: 903,
  },
  {
    name: "Thị trấn Sóc Sơn",
    code: 30820,
    division_type: "thị trấn",
    codename: "thi_tran_soc_son",
    district_code: 903,
  },
  {
    name: "Xã Bình Sơn",
    code: 30823,
    division_type: "xã",
    codename: "xa_binh_son",
    district_code: 903,
  },
  {
    name: "Xã Bình Giang",
    code: 30826,
    division_type: "xã",
    codename: "xa_binh_giang",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Thái",
    code: 30828,
    division_type: "xã",
    codename: "xa_my_thai",
    district_code: 903,
  },
  {
    name: "Xã Nam Thái Sơn",
    code: 30829,
    division_type: "xã",
    codename: "xa_nam_thai_son",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Hiệp Sơn",
    code: 30832,
    division_type: "xã",
    codename: "xa_my_hiep_son",
    district_code: 903,
  },
  {
    name: "Xã Sơn Kiên",
    code: 30835,
    division_type: "xã",
    codename: "xa_son_kien",
    district_code: 903,
  },
  {
    name: "Xã Sơn Bình",
    code: 30836,
    division_type: "xã",
    codename: "xa_son_binh",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 30838,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 903,
  },
  {
    name: "Xã Lình Huỳnh",
    code: 30840,
    division_type: "xã",
    codename: "xa_linh_huynh",
    district_code: 903,
  },
  {
    name: "Xã Thổ Sơn",
    code: 30841,
    division_type: "xã",
    codename: "xa_tho_son",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Lâm",
    code: 30844,
    division_type: "xã",
    codename: "xa_my_lam",
    district_code: 903,
  },
  {
    name: "Xã Mỹ Phước",
    code: 30847,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 903,
  },
  {
    name: "Thị trấn Tân Hiệp",
    code: 30850,
    division_type: "thị trấn",
    codename: "thi_tran_tan_hiep",
    district_code: 904,
  },
  {
    name: "Xã Tân Hội",
    code: 30853,
    division_type: "xã",
    codename: "xa_tan_hoi",
    district_code: 904,
  },
  {
    name: "Xã Tân Thành",
    code: 30856,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 904,
  },
  {
    name: "Xã Tân Hiệp B",
    code: 30859,
    division_type: "xã",
    codename: "xa_tan_hiep_b",
    district_code: 904,
  },
  {
    name: "Xã Tân Hoà",
    code: 30860,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông B",
    code: 30862,
    division_type: "xã",
    codename: "xa_thanh_dong_b",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông",
    code: 30865,
    division_type: "xã",
    codename: "xa_thanh_dong",
    district_code: 904,
  },
  {
    name: "Xã Tân Hiệp A",
    code: 30868,
    division_type: "xã",
    codename: "xa_tan_hiep_a",
    district_code: 904,
  },
  {
    name: "Xã Tân An",
    code: 30871,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Đông A",
    code: 30874,
    division_type: "xã",
    codename: "xa_thanh_dong_a",
    district_code: 904,
  },
  {
    name: "Xã Thạnh Trị",
    code: 30877,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 904,
  },
  {
    name: "Thị trấn Minh Lương",
    code: 30880,
    division_type: "thị trấn",
    codename: "thi_tran_minh_luong",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ A",
    code: 30883,
    division_type: "xã",
    codename: "xa_mong_tho_a",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ B",
    code: 30886,
    division_type: "xã",
    codename: "xa_mong_tho_b",
    district_code: 905,
  },
  {
    name: "Xã Mong Thọ",
    code: 30887,
    division_type: "xã",
    codename: "xa_mong_tho",
    district_code: 905,
  },
  {
    name: "Xã Giục Tượng",
    code: 30889,
    division_type: "xã",
    codename: "xa_giuc_tuong",
    district_code: 905,
  },
  {
    name: "Xã Vĩnh Hòa Hiệp",
    code: 30892,
    division_type: "xã",
    codename: "xa_vinh_hoa_hiep",
    district_code: 905,
  },
  {
    name: "Xã Vĩnh Hoà Phú",
    code: 30893,
    division_type: "xã",
    codename: "xa_vinh_hoa_phu",
    district_code: 905,
  },
  {
    name: "Xã Minh Hòa",
    code: 30895,
    division_type: "xã",
    codename: "xa_minh_hoa",
    district_code: 905,
  },
  {
    name: "Xã Bình An",
    code: 30898,
    division_type: "xã",
    codename: "xa_binh_an",
    district_code: 905,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 30901,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 905,
  },
  {
    name: "Thị trấn Giồng Riềng",
    code: 30904,
    division_type: "thị trấn",
    codename: "thi_tran_giong_rieng",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Hưng",
    code: 30907,
    division_type: "xã",
    codename: "xa_thanh_hung",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Phước",
    code: 30910,
    division_type: "xã",
    codename: "xa_thanh_phuoc",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 30913,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 30916,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 906,
  },
  {
    name: "Xã Thạnh Bình",
    code: 30917,
    division_type: "xã",
    codename: "xa_thanh_binh",
    district_code: 906,
  },
  {
    name: "Xã Bàn Thạch",
    code: 30919,
    division_type: "xã",
    codename: "xa_ban_thach",
    district_code: 906,
  },
  {
    name: "Xã Bàn Tân Định",
    code: 30922,
    division_type: "xã",
    codename: "xa_ban_tan_dinh",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Thành",
    code: 30925,
    division_type: "xã",
    codename: "xa_ngoc_thanh",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Chúc",
    code: 30928,
    division_type: "xã",
    codename: "xa_ngoc_chuc",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Thuận",
    code: 30931,
    division_type: "xã",
    codename: "xa_ngoc_thuan",
    district_code: 906,
  },
  {
    name: "Xã Hòa Hưng",
    code: 30934,
    division_type: "xã",
    codename: "xa_hoa_hung",
    district_code: 906,
  },
  {
    name: "Xã Hoà Lợi",
    code: 30937,
    division_type: "xã",
    codename: "xa_hoa_loi",
    district_code: 906,
  },
  {
    name: "Xã Hoà An",
    code: 30940,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 906,
  },
  {
    name: "Xã Long Thạnh",
    code: 30943,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 906,
  },
  {
    name: "Xã Vĩnh Thạnh",
    code: 30946,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 906,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30947,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 906,
  },
  {
    name: "Xã Hòa Thuận",
    code: 30949,
    division_type: "xã",
    codename: "xa_hoa_thuan",
    district_code: 906,
  },
  {
    name: "Xã Ngọc Hoà",
    code: 30950,
    division_type: "xã",
    codename: "xa_ngoc_hoa",
    district_code: 906,
  },
  {
    name: "Thị trấn Gò Quao",
    code: 30952,
    division_type: "thị trấn",
    codename: "thi_tran_go_quao",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Hòa Hưng Bắc",
    code: 30955,
    division_type: "xã",
    codename: "xa_vinh_hoa_hung_bac",
    district_code: 907,
  },
  {
    name: "Xã Định Hòa",
    code: 30958,
    division_type: "xã",
    codename: "xa_dinh_hoa",
    district_code: 907,
  },
  {
    name: "Xã Thới Quản",
    code: 30961,
    division_type: "xã",
    codename: "xa_thoi_quan",
    district_code: 907,
  },
  {
    name: "Xã Định An",
    code: 30964,
    division_type: "xã",
    codename: "xa_dinh_an",
    district_code: 907,
  },
  {
    name: "Xã Thủy Liễu",
    code: 30967,
    division_type: "xã",
    codename: "xa_thuy_lieu",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Hòa Hưng Nam",
    code: 30970,
    division_type: "xã",
    codename: "xa_vinh_hoa_hung_nam",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Phước A",
    code: 30973,
    division_type: "xã",
    codename: "xa_vinh_phuoc_a",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Phước B",
    code: 30976,
    division_type: "xã",
    codename: "xa_vinh_phuoc_b",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Tuy",
    code: 30979,
    division_type: "xã",
    codename: "xa_vinh_tuy",
    district_code: 907,
  },
  {
    name: "Xã Vĩnh Thắng",
    code: 30982,
    division_type: "xã",
    codename: "xa_vinh_thang",
    district_code: 907,
  },
  {
    name: "Thị trấn Thứ Ba",
    code: 30985,
    division_type: "thị trấn",
    codename: "thi_tran_thu_ba",
    district_code: 908,
  },
  {
    name: "Xã Tây Yên",
    code: 30988,
    division_type: "xã",
    codename: "xa_tay_yen",
    district_code: 908,
  },
  {
    name: "Xã Tây Yên A",
    code: 30991,
    division_type: "xã",
    codename: "xa_tay_yen_a",
    district_code: 908,
  },
  {
    name: "Xã Nam Yên",
    code: 30994,
    division_type: "xã",
    codename: "xa_nam_yen",
    district_code: 908,
  },
  {
    name: "Xã Hưng Yên",
    code: 30997,
    division_type: "xã",
    codename: "xa_hung_yen",
    district_code: 908,
  },
  {
    name: "Xã Nam Thái",
    code: 31000,
    division_type: "xã",
    codename: "xa_nam_thai",
    district_code: 908,
  },
  {
    name: "Xã Nam Thái A",
    code: 31003,
    division_type: "xã",
    codename: "xa_nam_thai_a",
    district_code: 908,
  },
  {
    name: "Xã Đông Thái",
    code: 31006,
    division_type: "xã",
    codename: "xa_dong_thai",
    district_code: 908,
  },
  {
    name: "Xã Đông Yên",
    code: 31009,
    division_type: "xã",
    codename: "xa_dong_yen",
    district_code: 908,
  },
  {
    name: "Thị trấn Thứ Mười Một",
    code: 31018,
    division_type: "thị trấn",
    codename: "thi_tran_thu_muoi_mot",
    district_code: 909,
  },
  {
    name: "Xã Thuận Hoà",
    code: 31021,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 909,
  },
  {
    name: "Xã Đông Hòa",
    code: 31024,
    division_type: "xã",
    codename: "xa_dong_hoa",
    district_code: 909,
  },
  {
    name: "Xã Đông Thạnh",
    code: 31030,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 909,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31031,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng",
    code: 31033,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng A",
    code: 31036,
    division_type: "xã",
    codename: "xa_dong_hung_a",
    district_code: 909,
  },
  {
    name: "Xã Đông Hưng B",
    code: 31039,
    division_type: "xã",
    codename: "xa_dong_hung_b",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh",
    code: 31042,
    division_type: "xã",
    codename: "xa_van_khanh",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh Đông",
    code: 31045,
    division_type: "xã",
    codename: "xa_van_khanh_dong",
    district_code: 909,
  },
  {
    name: "Xã Vân Khánh Tây",
    code: 31048,
    division_type: "xã",
    codename: "xa_van_khanh_tay",
    district_code: 909,
  },
  {
    name: "Thị trấn Vĩnh Thuận",
    code: 31051,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thuan",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Bình Bắc",
    code: 31060,
    division_type: "xã",
    codename: "xa_vinh_binh_bac",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Bình Nam",
    code: 31063,
    division_type: "xã",
    codename: "xa_vinh_binh_nam",
    district_code: 910,
  },
  {
    name: "Xã Bình Minh",
    code: 31064,
    division_type: "xã",
    codename: "xa_binh_minh",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Thuận",
    code: 31069,
    division_type: "xã",
    codename: "xa_vinh_thuan",
    district_code: 910,
  },
  {
    name: "Xã Tân Thuận",
    code: 31072,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 910,
  },
  {
    name: "Xã Phong Đông",
    code: 31074,
    division_type: "xã",
    codename: "xa_phong_dong",
    district_code: 910,
  },
  {
    name: "Xã Vĩnh Phong",
    code: 31075,
    division_type: "xã",
    codename: "xa_vinh_phong",
    district_code: 910,
  },
  {
    name: "Phường Dương Đông",
    code: 31078,
    division_type: "phường",
    codename: "phuong_duong_dong",
    district_code: 911,
  },
  {
    name: "Phường An Thới",
    code: 31081,
    division_type: "phường",
    codename: "phuong_an_thoi",
    district_code: 911,
  },
  {
    name: "Xã Cửa Cạn",
    code: 31084,
    division_type: "xã",
    codename: "xa_cua_can",
    district_code: 911,
  },
  {
    name: "Xã Gành Dầu",
    code: 31087,
    division_type: "xã",
    codename: "xa_ganh_dau",
    district_code: 911,
  },
  {
    name: "Xã Cửa Dương",
    code: 31090,
    division_type: "xã",
    codename: "xa_cua_duong",
    district_code: 911,
  },
  {
    name: "Xã Hàm Ninh",
    code: 31093,
    division_type: "xã",
    codename: "xa_ham_ninh",
    district_code: 911,
  },
  {
    name: "Xã Dương Tơ",
    code: 31096,
    division_type: "xã",
    codename: "xa_duong_to",
    district_code: 911,
  },
  {
    name: "Xã Bãi Thơm",
    code: 31102,
    division_type: "xã",
    codename: "xa_bai_thom",
    district_code: 911,
  },
  {
    name: "Xã Thổ Châu",
    code: 31105,
    division_type: "xã",
    codename: "xa_tho_chau",
    district_code: 911,
  },
  {
    name: "Xã Hòn Tre",
    code: 31108,
    division_type: "xã",
    codename: "xa_hon_tre",
    district_code: 912,
  },
  {
    name: "Xã Lại Sơn",
    code: 31111,
    division_type: "xã",
    codename: "xa_lai_son",
    district_code: 912,
  },
  {
    name: "Xã An Sơn",
    code: 31114,
    division_type: "xã",
    codename: "xa_an_son",
    district_code: 912,
  },
  {
    name: "Xã Nam Du",
    code: 31115,
    division_type: "xã",
    codename: "xa_nam_du",
    district_code: 912,
  },
  {
    name: "Xã Thạnh Yên",
    code: 31012,
    division_type: "xã",
    codename: "xa_thanh_yen",
    district_code: 913,
  },
  {
    name: "Xã Thạnh Yên A",
    code: 31015,
    division_type: "xã",
    codename: "xa_thanh_yen_a",
    district_code: 913,
  },
  {
    name: "Xã An Minh Bắc",
    code: 31027,
    division_type: "xã",
    codename: "xa_an_minh_bac",
    district_code: 913,
  },
  {
    name: "Xã Vĩnh Hòa",
    code: 31054,
    division_type: "xã",
    codename: "xa_vinh_hoa",
    district_code: 913,
  },
  {
    name: "Xã Hoà Chánh",
    code: 31057,
    division_type: "xã",
    codename: "xa_hoa_chanh",
    district_code: 913,
  },
  {
    name: "Xã Minh Thuận",
    code: 31066,
    division_type: "xã",
    codename: "xa_minh_thuan",
    district_code: 913,
  },
  {
    name: "Xã Vĩnh Phú",
    code: 30791,
    division_type: "xã",
    codename: "xa_vinh_phu",
    district_code: 914,
  },
  {
    name: "Xã Vĩnh Điều",
    code: 30793,
    division_type: "xã",
    codename: "xa_vinh_dieu",
    district_code: 914,
  },
  {
    name: "Xã Tân Khánh Hòa",
    code: 30796,
    division_type: "xã",
    codename: "xa_tan_khanh_hoa",
    district_code: 914,
  },
  {
    name: "Xã Phú Lợi",
    code: 30797,
    division_type: "xã",
    codename: "xa_phu_loi",
    district_code: 914,
  },
  {
    name: "Xã Phú Mỹ",
    code: 30799,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 914,
  },
  {
    name: "Phường Cái Khế",
    code: 31117,
    division_type: "phường",
    codename: "phuong_cai_khe",
    district_code: 916,
  },
  {
    name: "Phường An Hòa",
    code: 31120,
    division_type: "phường",
    codename: "phuong_an_hoa",
    district_code: 916,
  },
  {
    name: "Phường Thới Bình",
    code: 31123,
    division_type: "phường",
    codename: "phuong_thoi_binh",
    district_code: 916,
  },
  {
    name: "Phường An Nghiệp",
    code: 31126,
    division_type: "phường",
    codename: "phuong_an_nghiep",
    district_code: 916,
  },
  {
    name: "Phường An Cư",
    code: 31129,
    division_type: "phường",
    codename: "phuong_an_cu",
    district_code: 916,
  },
  {
    name: "Phường Tân An",
    code: 31135,
    division_type: "phường",
    codename: "phuong_tan_an",
    district_code: 916,
  },
  {
    name: "Phường An Phú",
    code: 31141,
    division_type: "phường",
    codename: "phuong_an_phu",
    district_code: 916,
  },
  {
    name: "Phường Xuân Khánh",
    code: 31144,
    division_type: "phường",
    codename: "phuong_xuan_khanh",
    district_code: 916,
  },
  {
    name: "Phường Hưng Lợi",
    code: 31147,
    division_type: "phường",
    codename: "phuong_hung_loi",
    district_code: 916,
  },
  {
    name: "Phường An Khánh",
    code: 31149,
    division_type: "phường",
    codename: "phuong_an_khanh",
    district_code: 916,
  },
  {
    name: "Phường An Bình",
    code: 31150,
    division_type: "phường",
    codename: "phuong_an_binh",
    district_code: 916,
  },
  {
    name: "Phường Châu Văn Liêm",
    code: 31153,
    division_type: "phường",
    codename: "phuong_chau_van_liem",
    district_code: 917,
  },
  {
    name: "Phường Thới Hòa",
    code: 31154,
    division_type: "phường",
    codename: "phuong_thoi_hoa",
    district_code: 917,
  },
  {
    name: "Phường Thới Long",
    code: 31156,
    division_type: "phường",
    codename: "phuong_thoi_long",
    district_code: 917,
  },
  {
    name: "Phường Long Hưng",
    code: 31157,
    division_type: "phường",
    codename: "phuong_long_hung",
    district_code: 917,
  },
  {
    name: "Phường Thới An",
    code: 31159,
    division_type: "phường",
    codename: "phuong_thoi_an",
    district_code: 917,
  },
  {
    name: "Phường Phước Thới",
    code: 31162,
    division_type: "phường",
    codename: "phuong_phuoc_thoi",
    district_code: 917,
  },
  {
    name: "Phường Trường Lạc",
    code: 31165,
    division_type: "phường",
    codename: "phuong_truong_lac",
    district_code: 917,
  },
  {
    name: "Phường Bình Thủy",
    code: 31168,
    division_type: "phường",
    codename: "phuong_binh_thuy",
    district_code: 918,
  },
  {
    name: "Phường Trà An",
    code: 31169,
    division_type: "phường",
    codename: "phuong_tra_an",
    district_code: 918,
  },
  {
    name: "Phường Trà Nóc",
    code: 31171,
    division_type: "phường",
    codename: "phuong_tra_noc",
    district_code: 918,
  },
  {
    name: "Phường Thới An Đông",
    code: 31174,
    division_type: "phường",
    codename: "phuong_thoi_an_dong",
    district_code: 918,
  },
  {
    name: "Phường An Thới",
    code: 31177,
    division_type: "phường",
    codename: "phuong_an_thoi",
    district_code: 918,
  },
  {
    name: "Phường Bùi Hữu Nghĩa",
    code: 31178,
    division_type: "phường",
    codename: "phuong_bui_huu_nghia",
    district_code: 918,
  },
  {
    name: "Phường Long Hòa",
    code: 31180,
    division_type: "phường",
    codename: "phuong_long_hoa",
    district_code: 918,
  },
  {
    name: "Phường Long Tuyền",
    code: 31183,
    division_type: "phường",
    codename: "phuong_long_tuyen",
    district_code: 918,
  },
  {
    name: "Phường Lê Bình",
    code: 31186,
    division_type: "phường",
    codename: "phuong_le_binh",
    district_code: 919,
  },
  {
    name: "Phường Hưng Phú",
    code: 31189,
    division_type: "phường",
    codename: "phuong_hung_phu",
    district_code: 919,
  },
  {
    name: "Phường Hưng Thạnh",
    code: 31192,
    division_type: "phường",
    codename: "phuong_hung_thanh",
    district_code: 919,
  },
  {
    name: "Phường Ba Láng",
    code: 31195,
    division_type: "phường",
    codename: "phuong_ba_lang",
    district_code: 919,
  },
  {
    name: "Phường Thường Thạnh",
    code: 31198,
    division_type: "phường",
    codename: "phuong_thuong_thanh",
    district_code: 919,
  },
  {
    name: "Phường Phú Thứ",
    code: 31201,
    division_type: "phường",
    codename: "phuong_phu_thu",
    district_code: 919,
  },
  {
    name: "Phường Tân Phú",
    code: 31204,
    division_type: "phường",
    codename: "phuong_tan_phu",
    district_code: 919,
  },
  {
    name: "Phường Thốt Nốt",
    code: 31207,
    division_type: "phường",
    codename: "phuong_thot_not",
    district_code: 923,
  },
  {
    name: "Phường Thới Thuận",
    code: 31210,
    division_type: "phường",
    codename: "phuong_thoi_thuan",
    district_code: 923,
  },
  {
    name: "Phường Thuận An",
    code: 31212,
    division_type: "phường",
    codename: "phuong_thuan_an",
    district_code: 923,
  },
  {
    name: "Phường Tân Lộc",
    code: 31213,
    division_type: "phường",
    codename: "phuong_tan_loc",
    district_code: 923,
  },
  {
    name: "Phường Trung Nhứt",
    code: 31216,
    division_type: "phường",
    codename: "phuong_trung_nhut",
    district_code: 923,
  },
  {
    name: "Phường Thạnh Hoà",
    code: 31217,
    division_type: "phường",
    codename: "phuong_thanh_hoa",
    district_code: 923,
  },
  {
    name: "Phường Trung Kiên",
    code: 31219,
    division_type: "phường",
    codename: "phuong_trung_kien",
    district_code: 923,
  },
  {
    name: "Phường Tân Hưng",
    code: 31227,
    division_type: "phường",
    codename: "phuong_tan_hung",
    district_code: 923,
  },
  {
    name: "Phường Thuận Hưng",
    code: 31228,
    division_type: "phường",
    codename: "phuong_thuan_hung",
    district_code: 923,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 31211,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 924,
  },
  {
    name: "Thị trấn Thanh An",
    code: 31231,
    division_type: "thị trấn",
    codename: "thi_tran_thanh_an",
    district_code: 924,
  },
  {
    name: "Thị trấn Vĩnh Thạnh",
    code: 31232,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_thanh",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Mỹ",
    code: 31234,
    division_type: "xã",
    codename: "xa_thanh_my",
    district_code: 924,
  },
  {
    name: "Xã Vĩnh Trinh",
    code: 31237,
    division_type: "xã",
    codename: "xa_vinh_trinh",
    district_code: 924,
  },
  {
    name: "Xã Thạnh An",
    code: 31240,
    division_type: "xã",
    codename: "xa_thanh_an",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Tiến",
    code: 31241,
    division_type: "xã",
    codename: "xa_thanh_tien",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Thắng",
    code: 31243,
    division_type: "xã",
    codename: "xa_thanh_thang",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Lợi",
    code: 31244,
    division_type: "xã",
    codename: "xa_thanh_loi",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Qưới",
    code: 31246,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 924,
  },
  {
    name: "Xã Thạnh Lộc",
    code: 31252,
    division_type: "xã",
    codename: "xa_thanh_loc",
    district_code: 924,
  },
  {
    name: "Xã Trung An",
    code: 31222,
    division_type: "xã",
    codename: "xa_trung_an",
    district_code: 925,
  },
  {
    name: "Xã Trung Thạnh",
    code: 31225,
    division_type: "xã",
    codename: "xa_trung_thanh",
    district_code: 925,
  },
  {
    name: "Xã Thạnh Phú",
    code: 31249,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 925,
  },
  {
    name: "Xã Trung Hưng",
    code: 31255,
    division_type: "xã",
    codename: "xa_trung_hung",
    district_code: 925,
  },
  {
    name: "Thị trấn Cờ Đỏ",
    code: 31261,
    division_type: "thị trấn",
    codename: "thi_tran_co_do",
    district_code: 925,
  },
  {
    name: "Xã Thới Hưng",
    code: 31264,
    division_type: "xã",
    codename: "xa_thoi_hung",
    district_code: 925,
  },
  {
    name: "Xã Đông Hiệp",
    code: 31273,
    division_type: "xã",
    codename: "xa_dong_hiep",
    district_code: 925,
  },
  {
    name: "Xã Đông Thắng",
    code: 31274,
    division_type: "xã",
    codename: "xa_dong_thang",
    district_code: 925,
  },
  {
    name: "Xã Thới Đông",
    code: 31276,
    division_type: "xã",
    codename: "xa_thoi_dong",
    district_code: 925,
  },
  {
    name: "Xã Thới Xuân",
    code: 31277,
    division_type: "xã",
    codename: "xa_thoi_xuan",
    district_code: 925,
  },
  {
    name: "Thị trấn Phong Điền",
    code: 31299,
    division_type: "thị trấn",
    codename: "thi_tran_phong_dien",
    district_code: 926,
  },
  {
    name: "Xã Nhơn Ái",
    code: 31300,
    division_type: "xã",
    codename: "xa_nhon_ai",
    district_code: 926,
  },
  {
    name: "Xã Giai Xuân",
    code: 31303,
    division_type: "xã",
    codename: "xa_giai_xuan",
    district_code: 926,
  },
  {
    name: "Xã Tân Thới",
    code: 31306,
    division_type: "xã",
    codename: "xa_tan_thoi",
    district_code: 926,
  },
  {
    name: "Xã Trường Long",
    code: 31309,
    division_type: "xã",
    codename: "xa_truong_long",
    district_code: 926,
  },
  {
    name: "Xã Mỹ Khánh",
    code: 31312,
    division_type: "xã",
    codename: "xa_my_khanh",
    district_code: 926,
  },
  {
    name: "Xã Nhơn Nghĩa",
    code: 31315,
    division_type: "xã",
    codename: "xa_nhon_nghia",
    district_code: 926,
  },
  {
    name: "Thị trấn Thới Lai",
    code: 31258,
    division_type: "thị trấn",
    codename: "thi_tran_thoi_lai",
    district_code: 927,
  },
  {
    name: "Xã Thới Thạnh",
    code: 31267,
    division_type: "xã",
    codename: "xa_thoi_thanh",
    district_code: 927,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31268,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 927,
  },
  {
    name: "Xã Xuân Thắng",
    code: 31270,
    division_type: "xã",
    codename: "xa_xuan_thang",
    district_code: 927,
  },
  {
    name: "Xã Đông Bình",
    code: 31279,
    division_type: "xã",
    codename: "xa_dong_binh",
    district_code: 927,
  },
  {
    name: "Xã Đông Thuận",
    code: 31282,
    division_type: "xã",
    codename: "xa_dong_thuan",
    district_code: 927,
  },
  {
    name: "Xã Thới Tân",
    code: 31285,
    division_type: "xã",
    codename: "xa_thoi_tan",
    district_code: 927,
  },
  {
    name: "Xã Trường Thắng",
    code: 31286,
    division_type: "xã",
    codename: "xa_truong_thang",
    district_code: 927,
  },
  {
    name: "Xã Định Môn",
    code: 31288,
    division_type: "xã",
    codename: "xa_dinh_mon",
    district_code: 927,
  },
  {
    name: "Xã Trường Thành",
    code: 31291,
    division_type: "xã",
    codename: "xa_truong_thanh",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân",
    code: 31294,
    division_type: "xã",
    codename: "xa_truong_xuan",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân A",
    code: 31297,
    division_type: "xã",
    codename: "xa_truong_xuan_a",
    district_code: 927,
  },
  {
    name: "Xã Trường Xuân B",
    code: 31298,
    division_type: "xã",
    codename: "xa_truong_xuan_b",
    district_code: 927,
  },
  {
    name: "Phường I",
    code: 31318,
    division_type: "phường",
    codename: "phuong_i",
    district_code: 930,
  },
  {
    name: "Phường III",
    code: 31321,
    division_type: "phường",
    codename: "phuong_iii",
    district_code: 930,
  },
  {
    name: "Phường IV",
    code: 31324,
    division_type: "phường",
    codename: "phuong_iv",
    district_code: 930,
  },
  {
    name: "Phường V",
    code: 31327,
    division_type: "phường",
    codename: "phuong_v",
    district_code: 930,
  },
  {
    name: "Phường VII",
    code: 31330,
    division_type: "phường",
    codename: "phuong_vii",
    district_code: 930,
  },
  {
    name: "Xã Vị Tân",
    code: 31333,
    division_type: "xã",
    codename: "xa_vi_tan",
    district_code: 930,
  },
  {
    name: "Xã Hoả Lựu",
    code: 31336,
    division_type: "xã",
    codename: "xa_hoa_luu",
    district_code: 930,
  },
  {
    name: "Xã Tân Tiến",
    code: 31338,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 930,
  },
  {
    name: "Xã Hoả Tiến",
    code: 31339,
    division_type: "xã",
    codename: "xa_hoa_tien",
    district_code: 930,
  },
  {
    name: "Phường Ngã Bảy",
    code: 31340,
    division_type: "phường",
    codename: "phuong_nga_bay",
    district_code: 931,
  },
  {
    name: "Phường Lái Hiếu",
    code: 31341,
    division_type: "phường",
    codename: "phuong_lai_hieu",
    district_code: 931,
  },
  {
    name: "Phường Hiệp Thành",
    code: 31343,
    division_type: "phường",
    codename: "phuong_hiep_thanh",
    district_code: 931,
  },
  {
    name: "Phường Hiệp Lợi",
    code: 31344,
    division_type: "phường",
    codename: "phuong_hiep_loi",
    district_code: 931,
  },
  {
    name: "Xã Đại Thành",
    code: 31411,
    division_type: "xã",
    codename: "xa_dai_thanh",
    district_code: 931,
  },
  {
    name: "Xã Tân Thành",
    code: 31414,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 931,
  },
  {
    name: "Thị trấn Một Ngàn",
    code: 31342,
    division_type: "thị trấn",
    codename: "thi_tran_mot_ngan",
    district_code: 932,
  },
  {
    name: "Xã Tân Hoà",
    code: 31345,
    division_type: "xã",
    codename: "xa_tan_hoa",
    district_code: 932,
  },
  {
    name: "Thị trấn Bảy Ngàn",
    code: 31346,
    division_type: "thị trấn",
    codename: "thi_tran_bay_ngan",
    district_code: 932,
  },
  {
    name: "Xã Trường Long Tây",
    code: 31348,
    division_type: "xã",
    codename: "xa_truong_long_tay",
    district_code: 932,
  },
  {
    name: "Xã Trường Long A",
    code: 31351,
    division_type: "xã",
    codename: "xa_truong_long_a",
    district_code: 932,
  },
  {
    name: "Xã Nhơn Nghĩa A",
    code: 31357,
    division_type: "xã",
    codename: "xa_nhon_nghia_a",
    district_code: 932,
  },
  {
    name: "Thị trấn Rạch Gòi",
    code: 31359,
    division_type: "thị trấn",
    codename: "thi_tran_rach_goi",
    district_code: 932,
  },
  {
    name: "Xã Thạnh Xuân",
    code: 31360,
    division_type: "xã",
    codename: "xa_thanh_xuan",
    district_code: 932,
  },
  {
    name: "Thị trấn Cái Tắc",
    code: 31362,
    division_type: "thị trấn",
    codename: "thi_tran_cai_tac",
    district_code: 932,
  },
  {
    name: "Xã Tân Phú Thạnh",
    code: 31363,
    division_type: "xã",
    codename: "xa_tan_phu_thanh",
    district_code: 932,
  },
  {
    name: "Thị trấn Ngã Sáu",
    code: 31366,
    division_type: "thị trấn",
    codename: "thi_tran_nga_sau",
    district_code: 933,
  },
  {
    name: "Xã Đông Thạnh",
    code: 31369,
    division_type: "xã",
    codename: "xa_dong_thanh",
    district_code: 933,
  },
  {
    name: "Xã Đông Phú",
    code: 31375,
    division_type: "xã",
    codename: "xa_dong_phu",
    district_code: 933,
  },
  {
    name: "Xã Phú Hữu",
    code: 31378,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 933,
  },
  {
    name: "Xã Phú Tân",
    code: 31379,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 933,
  },
  {
    name: "Thị trấn Mái Dầm",
    code: 31381,
    division_type: "thị trấn",
    codename: "thi_tran_mai_dam",
    district_code: 933,
  },
  {
    name: "Xã Đông Phước",
    code: 31384,
    division_type: "xã",
    codename: "xa_dong_phuoc",
    district_code: 933,
  },
  {
    name: "Xã Đông Phước A",
    code: 31387,
    division_type: "xã",
    codename: "xa_dong_phuoc_a",
    district_code: 933,
  },
  {
    name: "Thị trấn Kinh Cùng",
    code: 31393,
    division_type: "thị trấn",
    codename: "thi_tran_kinh_cung",
    district_code: 934,
  },
  {
    name: "Thị trấn Cây Dương",
    code: 31396,
    division_type: "thị trấn",
    codename: "thi_tran_cay_duong",
    district_code: 934,
  },
  {
    name: "Xã Tân Bình",
    code: 31399,
    division_type: "xã",
    codename: "xa_tan_binh",
    district_code: 934,
  },
  {
    name: "Xã Bình Thành",
    code: 31402,
    division_type: "xã",
    codename: "xa_binh_thanh",
    district_code: 934,
  },
  {
    name: "Xã Thạnh Hòa",
    code: 31405,
    division_type: "xã",
    codename: "xa_thanh_hoa",
    district_code: 934,
  },
  {
    name: "Xã Long Thạnh",
    code: 31408,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 934,
  },
  {
    name: "Xã Phụng Hiệp",
    code: 31417,
    division_type: "xã",
    codename: "xa_phung_hiep",
    district_code: 934,
  },
  {
    name: "Xã Hòa Mỹ",
    code: 31420,
    division_type: "xã",
    codename: "xa_hoa_my",
    district_code: 934,
  },
  {
    name: "Xã Hòa An",
    code: 31423,
    division_type: "xã",
    codename: "xa_hoa_an",
    district_code: 934,
  },
  {
    name: "Xã Phương Bình",
    code: 31426,
    division_type: "xã",
    codename: "xa_phuong_binh",
    district_code: 934,
  },
  {
    name: "Xã Hiệp Hưng",
    code: 31429,
    division_type: "xã",
    codename: "xa_hiep_hung",
    district_code: 934,
  },
  {
    name: "Xã Tân Phước Hưng",
    code: 31432,
    division_type: "xã",
    codename: "xa_tan_phuoc_hung",
    district_code: 934,
  },
  {
    name: "Thị trấn Búng Tàu",
    code: 31433,
    division_type: "thị trấn",
    codename: "thi_tran_bung_tau",
    district_code: 934,
  },
  {
    name: "Xã Phương Phú",
    code: 31435,
    division_type: "xã",
    codename: "xa_phuong_phu",
    district_code: 934,
  },
  {
    name: "Xã Tân Long",
    code: 31438,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 934,
  },
  {
    name: "Thị trấn Nàng Mau",
    code: 31441,
    division_type: "thị trấn",
    codename: "thi_tran_nang_mau",
    district_code: 935,
  },
  {
    name: "Xã Vị Trung",
    code: 31444,
    division_type: "xã",
    codename: "xa_vi_trung",
    district_code: 935,
  },
  {
    name: "Xã Vị Thuỷ",
    code: 31447,
    division_type: "xã",
    codename: "xa_vi_thuy",
    district_code: 935,
  },
  {
    name: "Xã Vị Thắng",
    code: 31450,
    division_type: "xã",
    codename: "xa_vi_thang",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Thuận Tây",
    code: 31453,
    division_type: "xã",
    codename: "xa_vinh_thuan_tay",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Trung",
    code: 31456,
    division_type: "xã",
    codename: "xa_vinh_trung",
    district_code: 935,
  },
  {
    name: "Xã Vĩnh Tường",
    code: 31459,
    division_type: "xã",
    codename: "xa_vinh_tuong",
    district_code: 935,
  },
  {
    name: "Xã Vị Đông",
    code: 31462,
    division_type: "xã",
    codename: "xa_vi_dong",
    district_code: 935,
  },
  {
    name: "Xã Vị Thanh",
    code: 31465,
    division_type: "xã",
    codename: "xa_vi_thanh",
    district_code: 935,
  },
  {
    name: "Xã Vị Bình",
    code: 31468,
    division_type: "xã",
    codename: "xa_vi_binh",
    district_code: 935,
  },
  {
    name: "Xã Thuận Hưng",
    code: 31483,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 936,
  },
  {
    name: "Xã Thuận Hòa",
    code: 31484,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 936,
  },
  {
    name: "Xã Vĩnh Thuận Đông",
    code: 31486,
    division_type: "xã",
    codename: "xa_vinh_thuan_dong",
    district_code: 936,
  },
  {
    name: "Thị trấn Vĩnh Viễn",
    code: 31489,
    division_type: "thị trấn",
    codename: "thi_tran_vinh_vien",
    district_code: 936,
  },
  {
    name: "Xã Vĩnh Viễn A",
    code: 31490,
    division_type: "xã",
    codename: "xa_vinh_vien_a",
    district_code: 936,
  },
  {
    name: "Xã Lương Tâm",
    code: 31492,
    division_type: "xã",
    codename: "xa_luong_tam",
    district_code: 936,
  },
  {
    name: "Xã Lương Nghĩa",
    code: 31493,
    division_type: "xã",
    codename: "xa_luong_nghia",
    district_code: 936,
  },
  {
    name: "Xã Xà Phiên",
    code: 31495,
    division_type: "xã",
    codename: "xa_xa_phien",
    district_code: 936,
  },
  {
    name: "Phường Thuận An",
    code: 31471,
    division_type: "phường",
    codename: "phuong_thuan_an",
    district_code: 937,
  },
  {
    name: "Phường Trà Lồng",
    code: 31472,
    division_type: "phường",
    codename: "phuong_tra_long",
    district_code: 937,
  },
  {
    name: "Phường Bình Thạnh",
    code: 31473,
    division_type: "phường",
    codename: "phuong_binh_thanh",
    district_code: 937,
  },
  {
    name: "Xã Long Bình",
    code: 31474,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 937,
  },
  {
    name: "Phường Vĩnh Tường",
    code: 31475,
    division_type: "phường",
    codename: "phuong_vinh_tuong",
    district_code: 937,
  },
  {
    name: "Xã Long Trị",
    code: 31477,
    division_type: "xã",
    codename: "xa_long_tri",
    district_code: 937,
  },
  {
    name: "Xã Long Trị A",
    code: 31478,
    division_type: "xã",
    codename: "xa_long_tri_a",
    district_code: 937,
  },
  {
    name: "Xã Long Phú",
    code: 31480,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 937,
  },
  {
    name: "Xã Tân Phú",
    code: 31481,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 937,
  },
  {
    name: "Phường 5",
    code: 31498,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 941,
  },
  {
    name: "Phường 7",
    code: 31501,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 941,
  },
  {
    name: "Phường 8",
    code: 31504,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 941,
  },
  {
    name: "Phường 6",
    code: 31507,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 941,
  },
  {
    name: "Phường 2",
    code: 31510,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 941,
  },
  {
    name: "Phường 1",
    code: 31513,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 941,
  },
  {
    name: "Phường 4",
    code: 31516,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 941,
  },
  {
    name: "Phường 3",
    code: 31519,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 941,
  },
  {
    name: "Phường 9",
    code: 31522,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 941,
  },
  {
    name: "Phường 10",
    code: 31525,
    division_type: "phường",
    codename: "phuong_10",
    district_code: 941,
  },
  {
    name: "Thị trấn Châu Thành",
    code: 31569,
    division_type: "thị trấn",
    codename: "thi_tran_chau_thanh",
    district_code: 942,
  },
  {
    name: "Xã Hồ Đắc Kiện",
    code: 31570,
    division_type: "xã",
    codename: "xa_ho_dac_kien",
    district_code: 942,
  },
  {
    name: "Xã Phú Tâm",
    code: 31573,
    division_type: "xã",
    codename: "xa_phu_tam",
    district_code: 942,
  },
  {
    name: "Xã Thuận Hòa",
    code: 31576,
    division_type: "xã",
    codename: "xa_thuan_hoa",
    district_code: 942,
  },
  {
    name: "Xã Phú Tân",
    code: 31582,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 942,
  },
  {
    name: "Xã Thiện Mỹ",
    code: 31585,
    division_type: "xã",
    codename: "xa_thien_my",
    district_code: 942,
  },
  {
    name: "Xã An Hiệp",
    code: 31594,
    division_type: "xã",
    codename: "xa_an_hiep",
    district_code: 942,
  },
  {
    name: "Xã An Ninh",
    code: 31600,
    division_type: "xã",
    codename: "xa_an_ninh",
    district_code: 942,
  },
  {
    name: "Thị trấn Kế Sách",
    code: 31528,
    division_type: "thị trấn",
    codename: "thi_tran_ke_sach",
    district_code: 943,
  },
  {
    name: "Thị trấn An Lạc Thôn",
    code: 31531,
    division_type: "thị trấn",
    codename: "thi_tran_an_lac_thon",
    district_code: 943,
  },
  {
    name: "Xã Xuân Hòa",
    code: 31534,
    division_type: "xã",
    codename: "xa_xuan_hoa",
    district_code: 943,
  },
  {
    name: "Xã Phong Nẫm",
    code: 31537,
    division_type: "xã",
    codename: "xa_phong_nam",
    district_code: 943,
  },
  {
    name: "Xã An Lạc Tây",
    code: 31540,
    division_type: "xã",
    codename: "xa_an_lac_tay",
    district_code: 943,
  },
  {
    name: "Xã Trinh Phú",
    code: 31543,
    division_type: "xã",
    codename: "xa_trinh_phu",
    district_code: 943,
  },
  {
    name: "Xã Ba Trinh",
    code: 31546,
    division_type: "xã",
    codename: "xa_ba_trinh",
    district_code: 943,
  },
  {
    name: "Xã Thới An Hội",
    code: 31549,
    division_type: "xã",
    codename: "xa_thoi_an_hoi",
    district_code: 943,
  },
  {
    name: "Xã Nhơn Mỹ",
    code: 31552,
    division_type: "xã",
    codename: "xa_nhon_my",
    district_code: 943,
  },
  {
    name: "Xã Kế Thành",
    code: 31555,
    division_type: "xã",
    codename: "xa_ke_thanh",
    district_code: 943,
  },
  {
    name: "Xã Kế An",
    code: 31558,
    division_type: "xã",
    codename: "xa_ke_an",
    district_code: 943,
  },
  {
    name: "Xã Đại Hải",
    code: 31561,
    division_type: "xã",
    codename: "xa_dai_hai",
    district_code: 943,
  },
  {
    name: "Xã An Mỹ",
    code: 31564,
    division_type: "xã",
    codename: "xa_an_my",
    district_code: 943,
  },
  {
    name: "Thị trấn Huỳnh Hữu Nghĩa",
    code: 31567,
    division_type: "thị trấn",
    codename: "thi_tran_huynh_huu_nghia",
    district_code: 944,
  },
  {
    name: "Xã Long Hưng",
    code: 31579,
    division_type: "xã",
    codename: "xa_long_hung",
    district_code: 944,
  },
  {
    name: "Xã Hưng Phú",
    code: 31588,
    division_type: "xã",
    codename: "xa_hung_phu",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Hương",
    code: 31591,
    division_type: "xã",
    codename: "xa_my_huong",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Tú",
    code: 31597,
    division_type: "xã",
    codename: "xa_my_tu",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Phước",
    code: 31603,
    division_type: "xã",
    codename: "xa_my_phuoc",
    district_code: 944,
  },
  {
    name: "Xã Thuận Hưng",
    code: 31606,
    division_type: "xã",
    codename: "xa_thuan_hung",
    district_code: 944,
  },
  {
    name: "Xã Mỹ Thuận",
    code: 31609,
    division_type: "xã",
    codename: "xa_my_thuan",
    district_code: 944,
  },
  {
    name: "Xã Phú Mỹ",
    code: 31612,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 944,
  },
  {
    name: "Thị trấn Cù Lao Dung",
    code: 31615,
    division_type: "thị trấn",
    codename: "thi_tran_cu_lao_dung",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 1",
    code: 31618,
    division_type: "xã",
    codename: "xa_an_thanh_1",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Tây",
    code: 31621,
    division_type: "xã",
    codename: "xa_an_thanh_tay",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Đông",
    code: 31624,
    division_type: "xã",
    codename: "xa_an_thanh_dong",
    district_code: 945,
  },
  {
    name: "Xã Đại Ân 1",
    code: 31627,
    division_type: "xã",
    codename: "xa_dai_an_1",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 2",
    code: 31630,
    division_type: "xã",
    codename: "xa_an_thanh_2",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh 3",
    code: 31633,
    division_type: "xã",
    codename: "xa_an_thanh_3",
    district_code: 945,
  },
  {
    name: "Xã An Thạnh Nam",
    code: 31636,
    division_type: "xã",
    codename: "xa_an_thanh_nam",
    district_code: 945,
  },
  {
    name: "Thị trấn Long Phú",
    code: 31639,
    division_type: "thị trấn",
    codename: "thi_tran_long_phu",
    district_code: 946,
  },
  {
    name: "Xã Song Phụng",
    code: 31642,
    division_type: "xã",
    codename: "xa_song_phung",
    district_code: 946,
  },
  {
    name: "Thị trấn Đại Ngãi",
    code: 31645,
    division_type: "thị trấn",
    codename: "thi_tran_dai_ngai",
    district_code: 946,
  },
  {
    name: "Xã Hậu Thạnh",
    code: 31648,
    division_type: "xã",
    codename: "xa_hau_thanh",
    district_code: 946,
  },
  {
    name: "Xã Long Đức",
    code: 31651,
    division_type: "xã",
    codename: "xa_long_duc",
    district_code: 946,
  },
  {
    name: "Xã Trường Khánh",
    code: 31654,
    division_type: "xã",
    codename: "xa_truong_khanh",
    district_code: 946,
  },
  {
    name: "Xã Phú Hữu",
    code: 31657,
    division_type: "xã",
    codename: "xa_phu_huu",
    district_code: 946,
  },
  {
    name: "Xã Tân Hưng",
    code: 31660,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 946,
  },
  {
    name: "Xã Châu Khánh",
    code: 31663,
    division_type: "xã",
    codename: "xa_chau_khanh",
    district_code: 946,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31666,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 946,
  },
  {
    name: "Xã Long Phú",
    code: 31669,
    division_type: "xã",
    codename: "xa_long_phu",
    district_code: 946,
  },
  {
    name: "Thị trấn Mỹ Xuyên",
    code: 31684,
    division_type: "thị trấn",
    codename: "thi_tran_my_xuyen",
    district_code: 947,
  },
  {
    name: "Xã Đại Tâm",
    code: 31690,
    division_type: "xã",
    codename: "xa_dai_tam",
    district_code: 947,
  },
  {
    name: "Xã Tham Đôn",
    code: 31693,
    division_type: "xã",
    codename: "xa_tham_don",
    district_code: 947,
  },
  {
    name: "Xã Thạnh Phú",
    code: 31708,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 947,
  },
  {
    name: "Xã Ngọc Đông",
    code: 31711,
    division_type: "xã",
    codename: "xa_ngoc_dong",
    district_code: 947,
  },
  {
    name: "Xã Thạnh Quới",
    code: 31714,
    division_type: "xã",
    codename: "xa_thanh_quoi",
    district_code: 947,
  },
  {
    name: "Xã Hòa Tú 1",
    code: 31717,
    division_type: "xã",
    codename: "xa_hoa_tu_1",
    district_code: 947,
  },
  {
    name: "Xã Gia Hòa 1",
    code: 31720,
    division_type: "xã",
    codename: "xa_gia_hoa_1",
    district_code: 947,
  },
  {
    name: "Xã Ngọc Tố",
    code: 31723,
    division_type: "xã",
    codename: "xa_ngoc_to",
    district_code: 947,
  },
  {
    name: "Xã Gia Hòa 2",
    code: 31726,
    division_type: "xã",
    codename: "xa_gia_hoa_2",
    district_code: 947,
  },
  {
    name: "Xã Hòa Tú II",
    code: 31729,
    division_type: "xã",
    codename: "xa_hoa_tu_ii",
    district_code: 947,
  },
  {
    name: "Phường 1",
    code: 31732,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 948,
  },
  {
    name: "Phường 2",
    code: 31735,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 948,
  },
  {
    name: "Xã Vĩnh Quới",
    code: 31738,
    division_type: "xã",
    codename: "xa_vinh_quoi",
    district_code: 948,
  },
  {
    name: "Xã Tân Long",
    code: 31741,
    division_type: "xã",
    codename: "xa_tan_long",
    district_code: 948,
  },
  {
    name: "Xã Long Bình",
    code: 31744,
    division_type: "xã",
    codename: "xa_long_binh",
    district_code: 948,
  },
  {
    name: "Phường 3",
    code: 31747,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 948,
  },
  {
    name: "Xã Mỹ Bình",
    code: 31750,
    division_type: "xã",
    codename: "xa_my_binh",
    district_code: 948,
  },
  {
    name: "Xã Mỹ Quới",
    code: 31753,
    division_type: "xã",
    codename: "xa_my_quoi",
    district_code: 948,
  },
  {
    name: "Thị trấn Phú Lộc",
    code: 31756,
    division_type: "thị trấn",
    codename: "thi_tran_phu_loc",
    district_code: 949,
  },
  {
    name: "Thị trấn Hưng Lợi",
    code: 31757,
    division_type: "thị trấn",
    codename: "thi_tran_hung_loi",
    district_code: 949,
  },
  {
    name: "Xã Lâm Tân",
    code: 31759,
    division_type: "xã",
    codename: "xa_lam_tan",
    district_code: 949,
  },
  {
    name: "Xã Thạnh Tân",
    code: 31762,
    division_type: "xã",
    codename: "xa_thanh_tan",
    district_code: 949,
  },
  {
    name: "Xã Lâm Kiết",
    code: 31765,
    division_type: "xã",
    codename: "xa_lam_kiet",
    district_code: 949,
  },
  {
    name: "Xã Tuân Tức",
    code: 31768,
    division_type: "xã",
    codename: "xa_tuan_tuc",
    district_code: 949,
  },
  {
    name: "Xã Vĩnh Thành",
    code: 31771,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 949,
  },
  {
    name: "Xã Thạnh Trị",
    code: 31774,
    division_type: "xã",
    codename: "xa_thanh_tri",
    district_code: 949,
  },
  {
    name: "Xã Vĩnh Lợi",
    code: 31777,
    division_type: "xã",
    codename: "xa_vinh_loi",
    district_code: 949,
  },
  {
    name: "Xã Châu Hưng",
    code: 31780,
    division_type: "xã",
    codename: "xa_chau_hung",
    district_code: 949,
  },
  {
    name: "Phường 1",
    code: 31783,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 950,
  },
  {
    name: "Xã Hòa Đông",
    code: 31786,
    division_type: "xã",
    codename: "xa_hoa_dong",
    district_code: 950,
  },
  {
    name: "Phường Khánh Hòa",
    code: 31789,
    division_type: "phường",
    codename: "phuong_khanh_hoa",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Hiệp",
    code: 31792,
    division_type: "xã",
    codename: "xa_vinh_hiep",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Hải",
    code: 31795,
    division_type: "xã",
    codename: "xa_vinh_hai",
    district_code: 950,
  },
  {
    name: "Xã Lạc Hòa",
    code: 31798,
    division_type: "xã",
    codename: "xa_lac_hoa",
    district_code: 950,
  },
  {
    name: "Phường 2",
    code: 31801,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 950,
  },
  {
    name: "Phường Vĩnh Phước",
    code: 31804,
    division_type: "phường",
    codename: "phuong_vinh_phuoc",
    district_code: 950,
  },
  {
    name: "Xã Vĩnh Tân",
    code: 31807,
    division_type: "xã",
    codename: "xa_vinh_tan",
    district_code: 950,
  },
  {
    name: "Xã Lai Hòa",
    code: 31810,
    division_type: "xã",
    codename: "xa_lai_hoa",
    district_code: 950,
  },
  {
    name: "Xã Đại Ân 2",
    code: 31672,
    division_type: "xã",
    codename: "xa_dai_an_2",
    district_code: 951,
  },
  {
    name: "Thị trấn Trần Đề",
    code: 31673,
    division_type: "thị trấn",
    codename: "thi_tran_tran_de",
    district_code: 951,
  },
  {
    name: "Xã Liêu Tú",
    code: 31675,
    division_type: "xã",
    codename: "xa_lieu_tu",
    district_code: 951,
  },
  {
    name: "Xã Lịch Hội Thượng",
    code: 31678,
    division_type: "xã",
    codename: "xa_lich_hoi_thuong",
    district_code: 951,
  },
  {
    name: "Thị trấn Lịch Hội Thượng",
    code: 31679,
    division_type: "thị trấn",
    codename: "thi_tran_lich_hoi_thuong",
    district_code: 951,
  },
  {
    name: "Xã Trung Bình",
    code: 31681,
    division_type: "xã",
    codename: "xa_trung_binh",
    district_code: 951,
  },
  {
    name: "Xã Tài Văn",
    code: 31687,
    division_type: "xã",
    codename: "xa_tai_van",
    district_code: 951,
  },
  {
    name: "Xã Viên An",
    code: 31696,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 951,
  },
  {
    name: "Xã Thạnh Thới An",
    code: 31699,
    division_type: "xã",
    codename: "xa_thanh_thoi_an",
    district_code: 951,
  },
  {
    name: "Xã Thạnh Thới Thuận",
    code: 31702,
    division_type: "xã",
    codename: "xa_thanh_thoi_thuan",
    district_code: 951,
  },
  {
    name: "Xã Viên Bình",
    code: 31705,
    division_type: "xã",
    codename: "xa_vien_binh",
    district_code: 951,
  },
  {
    name: "Phường 2",
    code: 31813,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 954,
  },
  {
    name: "Phường 3",
    code: 31816,
    division_type: "phường",
    codename: "phuong_3",
    district_code: 954,
  },
  {
    name: "Phường 5",
    code: 31819,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 954,
  },
  {
    name: "Phường 7",
    code: 31822,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 954,
  },
  {
    name: "Phường 1",
    code: 31825,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 954,
  },
  {
    name: "Phường 8",
    code: 31828,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 954,
  },
  {
    name: "Phường Nhà Mát",
    code: 31831,
    division_type: "phường",
    codename: "phuong_nha_mat",
    district_code: 954,
  },
  {
    name: "Xã Vĩnh Trạch",
    code: 31834,
    division_type: "xã",
    codename: "xa_vinh_trach",
    district_code: 954,
  },
  {
    name: "Xã Vĩnh Trạch Đông",
    code: 31837,
    division_type: "xã",
    codename: "xa_vinh_trach_dong",
    district_code: 954,
  },
  {
    name: "Xã Hiệp Thành",
    code: 31840,
    division_type: "xã",
    codename: "xa_hiep_thanh",
    district_code: 954,
  },
  {
    name: "Thị trấn Ngan Dừa",
    code: 31843,
    division_type: "thị trấn",
    codename: "thi_tran_ngan_dua",
    district_code: 956,
  },
  {
    name: "Xã Ninh Quới",
    code: 31846,
    division_type: "xã",
    codename: "xa_ninh_quoi",
    district_code: 956,
  },
  {
    name: "Xã Ninh Quới A",
    code: 31849,
    division_type: "xã",
    codename: "xa_ninh_quoi_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Hòa",
    code: 31852,
    division_type: "xã",
    codename: "xa_ninh_hoa",
    district_code: 956,
  },
  {
    name: "Xã Lộc Ninh",
    code: 31855,
    division_type: "xã",
    codename: "xa_loc_ninh",
    district_code: 956,
  },
  {
    name: "Xã Vĩnh Lộc",
    code: 31858,
    division_type: "xã",
    codename: "xa_vinh_loc",
    district_code: 956,
  },
  {
    name: "Xã Vĩnh Lộc A",
    code: 31861,
    division_type: "xã",
    codename: "xa_vinh_loc_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Thạnh Lợi A",
    code: 31863,
    division_type: "xã",
    codename: "xa_ninh_thanh_loi_a",
    district_code: 956,
  },
  {
    name: "Xã Ninh Thạnh Lợi",
    code: 31864,
    division_type: "xã",
    codename: "xa_ninh_thanh_loi",
    district_code: 956,
  },
  {
    name: "Thị trấn Phước Long",
    code: 31867,
    division_type: "thị trấn",
    codename: "thi_tran_phuoc_long",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Phú Đông",
    code: 31870,
    division_type: "xã",
    codename: "xa_vinh_phu_dong",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Phú Tây",
    code: 31873,
    division_type: "xã",
    codename: "xa_vinh_phu_tay",
    district_code: 957,
  },
  {
    name: "Xã Phước Long",
    code: 31876,
    division_type: "xã",
    codename: "xa_phuoc_long",
    district_code: 957,
  },
  {
    name: "Xã Hưng Phú",
    code: 31879,
    division_type: "xã",
    codename: "xa_hung_phu",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Thanh",
    code: 31882,
    division_type: "xã",
    codename: "xa_vinh_thanh",
    district_code: 957,
  },
  {
    name: "Xã Phong Thạnh Tây A",
    code: 31885,
    division_type: "xã",
    codename: "xa_phong_thanh_tay_a",
    district_code: 957,
  },
  {
    name: "Xã Phong Thạnh Tây B",
    code: 31888,
    division_type: "xã",
    codename: "xa_phong_thanh_tay_b",
    district_code: 957,
  },
  {
    name: "Xã Vĩnh Hưng",
    code: 31894,
    division_type: "xã",
    codename: "xa_vinh_hung",
    district_code: 958,
  },
  {
    name: "Xã Vĩnh Hưng A",
    code: 31897,
    division_type: "xã",
    codename: "xa_vinh_hung_a",
    district_code: 958,
  },
  {
    name: "Thị trấn Châu Hưng",
    code: 31900,
    division_type: "thị trấn",
    codename: "thi_tran_chau_hung",
    district_code: 958,
  },
  {
    name: "Xã Châu Hưng A",
    code: 31903,
    division_type: "xã",
    codename: "xa_chau_hung_a",
    district_code: 958,
  },
  {
    name: "Xã Hưng Thành",
    code: 31906,
    division_type: "xã",
    codename: "xa_hung_thanh",
    district_code: 958,
  },
  {
    name: "Xã Hưng Hội",
    code: 31909,
    division_type: "xã",
    codename: "xa_hung_hoi",
    district_code: 958,
  },
  {
    name: "Xã Châu Thới",
    code: 31912,
    division_type: "xã",
    codename: "xa_chau_thoi",
    district_code: 958,
  },
  {
    name: "Xã Long Thạnh",
    code: 31921,
    division_type: "xã",
    codename: "xa_long_thanh",
    district_code: 958,
  },
  {
    name: "Phường 1",
    code: 31942,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 959,
  },
  {
    name: "Phường Hộ Phòng",
    code: 31945,
    division_type: "phường",
    codename: "phuong_ho_phong",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh Đông",
    code: 31948,
    division_type: "xã",
    codename: "xa_phong_thanh_dong",
    district_code: 959,
  },
  {
    name: "Phường Láng Tròn",
    code: 31951,
    division_type: "phường",
    codename: "phuong_lang_tron",
    district_code: 959,
  },
  {
    name: "Xã Phong Tân",
    code: 31954,
    division_type: "xã",
    codename: "xa_phong_tan",
    district_code: 959,
  },
  {
    name: "Xã Tân Phong",
    code: 31957,
    division_type: "xã",
    codename: "xa_tan_phong",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh",
    code: 31960,
    division_type: "xã",
    codename: "xa_phong_thanh",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh A",
    code: 31963,
    division_type: "xã",
    codename: "xa_phong_thanh_a",
    district_code: 959,
  },
  {
    name: "Xã Phong Thạnh Tây",
    code: 31966,
    division_type: "xã",
    codename: "xa_phong_thanh_tay",
    district_code: 959,
  },
  {
    name: "Xã Tân Thạnh",
    code: 31969,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 959,
  },
  {
    name: "Thị trấn Gành Hào",
    code: 31972,
    division_type: "thị trấn",
    codename: "thi_tran_ganh_hao",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Đông",
    code: 31975,
    division_type: "xã",
    codename: "xa_long_dien_dong",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Đông A",
    code: 31978,
    division_type: "xã",
    codename: "xa_long_dien_dong_a",
    district_code: 960,
  },
  {
    name: "Xã Long Điền",
    code: 31981,
    division_type: "xã",
    codename: "xa_long_dien",
    district_code: 960,
  },
  {
    name: "Xã Long Điền Tây",
    code: 31984,
    division_type: "xã",
    codename: "xa_long_dien_tay",
    district_code: 960,
  },
  {
    name: "Xã Điền Hải",
    code: 31985,
    division_type: "xã",
    codename: "xa_dien_hai",
    district_code: 960,
  },
  {
    name: "Xã An Trạch",
    code: 31987,
    division_type: "xã",
    codename: "xa_an_trach",
    district_code: 960,
  },
  {
    name: "Xã An Trạch A",
    code: 31988,
    division_type: "xã",
    codename: "xa_an_trach_a",
    district_code: 960,
  },
  {
    name: "Xã An Phúc",
    code: 31990,
    division_type: "xã",
    codename: "xa_an_phuc",
    district_code: 960,
  },
  {
    name: "Xã Định Thành",
    code: 31993,
    division_type: "xã",
    codename: "xa_dinh_thanh",
    district_code: 960,
  },
  {
    name: "Xã Định Thành A",
    code: 31996,
    division_type: "xã",
    codename: "xa_dinh_thanh_a",
    district_code: 960,
  },
  {
    name: "Thị trấn Hòa Bình",
    code: 31891,
    division_type: "thị trấn",
    codename: "thi_tran_hoa_binh",
    district_code: 961,
  },
  {
    name: "Xã Minh Diệu",
    code: 31915,
    division_type: "xã",
    codename: "xa_minh_dieu",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Bình",
    code: 31918,
    division_type: "xã",
    codename: "xa_vinh_binh",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Mỹ B",
    code: 31924,
    division_type: "xã",
    codename: "xa_vinh_my_b",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Hậu",
    code: 31927,
    division_type: "xã",
    codename: "xa_vinh_hau",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Hậu A",
    code: 31930,
    division_type: "xã",
    codename: "xa_vinh_hau_a",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Mỹ A",
    code: 31933,
    division_type: "xã",
    codename: "xa_vinh_my_a",
    district_code: 961,
  },
  {
    name: "Xã Vĩnh Thịnh",
    code: 31936,
    division_type: "xã",
    codename: "xa_vinh_thinh",
    district_code: 961,
  },
  {
    name: "Phường 9",
    code: 31999,
    division_type: "phường",
    codename: "phuong_9",
    district_code: 964,
  },
  {
    name: "Phường 4",
    code: 32002,
    division_type: "phường",
    codename: "phuong_4",
    district_code: 964,
  },
  {
    name: "Phường 1",
    code: 32005,
    division_type: "phường",
    codename: "phuong_1",
    district_code: 964,
  },
  {
    name: "Phường 5",
    code: 32008,
    division_type: "phường",
    codename: "phuong_5",
    district_code: 964,
  },
  {
    name: "Phường 2",
    code: 32011,
    division_type: "phường",
    codename: "phuong_2",
    district_code: 964,
  },
  {
    name: "Phường 8",
    code: 32014,
    division_type: "phường",
    codename: "phuong_8",
    district_code: 964,
  },
  {
    name: "Phường 6",
    code: 32017,
    division_type: "phường",
    codename: "phuong_6",
    district_code: 964,
  },
  {
    name: "Phường 7",
    code: 32020,
    division_type: "phường",
    codename: "phuong_7",
    district_code: 964,
  },
  {
    name: "Phường Tân Xuyên",
    code: 32022,
    division_type: "phường",
    codename: "phuong_tan_xuyen",
    district_code: 964,
  },
  {
    name: "Xã An Xuyên",
    code: 32023,
    division_type: "xã",
    codename: "xa_an_xuyen",
    district_code: 964,
  },
  {
    name: "Phường Tân Thành",
    code: 32025,
    division_type: "phường",
    codename: "phuong_tan_thanh",
    district_code: 964,
  },
  {
    name: "Xã Tân Thành",
    code: 32026,
    division_type: "xã",
    codename: "xa_tan_thanh",
    district_code: 964,
  },
  {
    name: "Xã Tắc Vân",
    code: 32029,
    division_type: "xã",
    codename: "xa_tac_van",
    district_code: 964,
  },
  {
    name: "Xã Lý Văn Lâm",
    code: 32032,
    division_type: "xã",
    codename: "xa_ly_van_lam",
    district_code: 964,
  },
  {
    name: "Xã Định Bình",
    code: 32035,
    division_type: "xã",
    codename: "xa_dinh_binh",
    district_code: 964,
  },
  {
    name: "Xã Hòa Thành",
    code: 32038,
    division_type: "xã",
    codename: "xa_hoa_thanh",
    district_code: 964,
  },
  {
    name: "Xã Hòa Tân",
    code: 32041,
    division_type: "xã",
    codename: "xa_hoa_tan",
    district_code: 964,
  },
  {
    name: "Thị trấn U Minh",
    code: 32044,
    division_type: "thị trấn",
    codename: "thi_tran_u_minh",
    district_code: 966,
  },
  {
    name: "Xã Khánh Hòa",
    code: 32047,
    division_type: "xã",
    codename: "xa_khanh_hoa",
    district_code: 966,
  },
  {
    name: "Xã Khánh Thuận",
    code: 32048,
    division_type: "xã",
    codename: "xa_khanh_thuan",
    district_code: 966,
  },
  {
    name: "Xã Khánh Tiến",
    code: 32050,
    division_type: "xã",
    codename: "xa_khanh_tien",
    district_code: 966,
  },
  {
    name: "Xã Nguyễn Phích",
    code: 32053,
    division_type: "xã",
    codename: "xa_nguyen_phich",
    district_code: 966,
  },
  {
    name: "Xã Khánh Lâm",
    code: 32056,
    division_type: "xã",
    codename: "xa_khanh_lam",
    district_code: 966,
  },
  {
    name: "Xã Khánh An",
    code: 32059,
    division_type: "xã",
    codename: "xa_khanh_an",
    district_code: 966,
  },
  {
    name: "Xã Khánh Hội",
    code: 32062,
    division_type: "xã",
    codename: "xa_khanh_hoi",
    district_code: 966,
  },
  {
    name: "Thị trấn Thới Bình",
    code: 32065,
    division_type: "thị trấn",
    codename: "thi_tran_thoi_binh",
    district_code: 967,
  },
  {
    name: "Xã Biển Bạch",
    code: 32068,
    division_type: "xã",
    codename: "xa_bien_bach",
    district_code: 967,
  },
  {
    name: "Xã Tân Bằng",
    code: 32069,
    division_type: "xã",
    codename: "xa_tan_bang",
    district_code: 967,
  },
  {
    name: "Xã Trí Phải",
    code: 32071,
    division_type: "xã",
    codename: "xa_tri_phai",
    district_code: 967,
  },
  {
    name: "Xã Trí Lực",
    code: 32072,
    division_type: "xã",
    codename: "xa_tri_luc",
    district_code: 967,
  },
  {
    name: "Xã Biển Bạch Đông",
    code: 32074,
    division_type: "xã",
    codename: "xa_bien_bach_dong",
    district_code: 967,
  },
  {
    name: "Xã Thới Bình",
    code: 32077,
    division_type: "xã",
    codename: "xa_thoi_binh",
    district_code: 967,
  },
  {
    name: "Xã Tân Phú",
    code: 32080,
    division_type: "xã",
    codename: "xa_tan_phu",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc Bắc",
    code: 32083,
    division_type: "xã",
    codename: "xa_tan_loc_bac",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc",
    code: 32086,
    division_type: "xã",
    codename: "xa_tan_loc",
    district_code: 967,
  },
  {
    name: "Xã Tân Lộc Đông",
    code: 32089,
    division_type: "xã",
    codename: "xa_tan_loc_dong",
    district_code: 967,
  },
  {
    name: "Xã Hồ Thị Kỷ",
    code: 32092,
    division_type: "xã",
    codename: "xa_ho_thi_ky",
    district_code: 967,
  },
  {
    name: "Thị trấn Trần Văn Thời",
    code: 32095,
    division_type: "thị trấn",
    codename: "thi_tran_tran_van_thoi",
    district_code: 968,
  },
  {
    name: "Thị trấn Sông Đốc",
    code: 32098,
    division_type: "thị trấn",
    codename: "thi_tran_song_doc",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Tây Bắc",
    code: 32101,
    division_type: "xã",
    codename: "xa_khanh_binh_tay_bac",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Tây",
    code: 32104,
    division_type: "xã",
    codename: "xa_khanh_binh_tay",
    district_code: 968,
  },
  {
    name: "Xã Trần Hợi",
    code: 32107,
    division_type: "xã",
    codename: "xa_tran_hoi",
    district_code: 968,
  },
  {
    name: "Xã Khánh Lộc",
    code: 32108,
    division_type: "xã",
    codename: "xa_khanh_loc",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình",
    code: 32110,
    division_type: "xã",
    codename: "xa_khanh_binh",
    district_code: 968,
  },
  {
    name: "Xã Khánh Hưng",
    code: 32113,
    division_type: "xã",
    codename: "xa_khanh_hung",
    district_code: 968,
  },
  {
    name: "Xã Khánh Bình Đông",
    code: 32116,
    division_type: "xã",
    codename: "xa_khanh_binh_dong",
    district_code: 968,
  },
  {
    name: "Xã Khánh Hải",
    code: 32119,
    division_type: "xã",
    codename: "xa_khanh_hai",
    district_code: 968,
  },
  {
    name: "Xã Lợi An",
    code: 32122,
    division_type: "xã",
    codename: "xa_loi_an",
    district_code: 968,
  },
  {
    name: "Xã Phong Điền",
    code: 32124,
    division_type: "xã",
    codename: "xa_phong_dien",
    district_code: 968,
  },
  {
    name: "Xã Phong Lạc",
    code: 32125,
    division_type: "xã",
    codename: "xa_phong_lac",
    district_code: 968,
  },
  {
    name: "Thị trấn Cái Nước",
    code: 32128,
    division_type: "thị trấn",
    codename: "thi_tran_cai_nuoc",
    district_code: 969,
  },
  {
    name: "Xã Thạnh Phú",
    code: 32130,
    division_type: "xã",
    codename: "xa_thanh_phu",
    district_code: 969,
  },
  {
    name: "Xã Lương Thế Trân",
    code: 32131,
    division_type: "xã",
    codename: "xa_luong_the_tran",
    district_code: 969,
  },
  {
    name: "Xã Phú Hưng",
    code: 32134,
    division_type: "xã",
    codename: "xa_phu_hung",
    district_code: 969,
  },
  {
    name: "Xã Tân Hưng",
    code: 32137,
    division_type: "xã",
    codename: "xa_tan_hung",
    district_code: 969,
  },
  {
    name: "Xã Hưng Mỹ",
    code: 32140,
    division_type: "xã",
    codename: "xa_hung_my",
    district_code: 969,
  },
  {
    name: "Xã Hoà Mỹ",
    code: 32141,
    division_type: "xã",
    codename: "xa_hoa_my",
    district_code: 969,
  },
  {
    name: "Xã Đông Hưng",
    code: 32142,
    division_type: "xã",
    codename: "xa_dong_hung",
    district_code: 969,
  },
  {
    name: "Xã Đông Thới",
    code: 32143,
    division_type: "xã",
    codename: "xa_dong_thoi",
    district_code: 969,
  },
  {
    name: "Xã Tân Hưng Đông",
    code: 32146,
    division_type: "xã",
    codename: "xa_tan_hung_dong",
    district_code: 969,
  },
  {
    name: "Xã Trần Thới",
    code: 32149,
    division_type: "xã",
    codename: "xa_tran_thoi",
    district_code: 969,
  },
  {
    name: "Thị trấn Đầm Dơi",
    code: 32152,
    division_type: "thị trấn",
    codename: "thi_tran_dam_doi",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương",
    code: 32155,
    division_type: "xã",
    codename: "xa_ta_an_khuong",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương Đông",
    code: 32158,
    division_type: "xã",
    codename: "xa_ta_an_khuong_dong",
    district_code: 970,
  },
  {
    name: "Xã Trần Phán",
    code: 32161,
    division_type: "xã",
    codename: "xa_tran_phan",
    district_code: 970,
  },
  {
    name: "Xã Tân Trung",
    code: 32162,
    division_type: "xã",
    codename: "xa_tan_trung",
    district_code: 970,
  },
  {
    name: "Xã Tân Đức",
    code: 32164,
    division_type: "xã",
    codename: "xa_tan_duc",
    district_code: 970,
  },
  {
    name: "Xã Tân Thuận",
    code: 32167,
    division_type: "xã",
    codename: "xa_tan_thuan",
    district_code: 970,
  },
  {
    name: "Xã Tạ An Khương Nam",
    code: 32170,
    division_type: "xã",
    codename: "xa_ta_an_khuong_nam",
    district_code: 970,
  },
  {
    name: "Xã Tân Duyệt",
    code: 32173,
    division_type: "xã",
    codename: "xa_tan_duyet",
    district_code: 970,
  },
  {
    name: "Xã Tân Dân",
    code: 32174,
    division_type: "xã",
    codename: "xa_tan_dan",
    district_code: 970,
  },
  {
    name: "Xã Tân Tiến",
    code: 32176,
    division_type: "xã",
    codename: "xa_tan_tien",
    district_code: 970,
  },
  {
    name: "Xã Quách Phẩm Bắc",
    code: 32179,
    division_type: "xã",
    codename: "xa_quach_pham_bac",
    district_code: 970,
  },
  {
    name: "Xã Quách Phẩm",
    code: 32182,
    division_type: "xã",
    codename: "xa_quach_pham",
    district_code: 970,
  },
  {
    name: "Xã Thanh Tùng",
    code: 32185,
    division_type: "xã",
    codename: "xa_thanh_tung",
    district_code: 970,
  },
  {
    name: "Xã Ngọc Chánh",
    code: 32186,
    division_type: "xã",
    codename: "xa_ngoc_chanh",
    district_code: 970,
  },
  {
    name: "Xã Nguyễn Huân",
    code: 32188,
    division_type: "xã",
    codename: "xa_nguyen_huan",
    district_code: 970,
  },
  {
    name: "Thị trấn Năm Căn",
    code: 32191,
    division_type: "thị trấn",
    codename: "thi_tran_nam_can",
    district_code: 971,
  },
  {
    name: "Xã Hàm Rồng",
    code: 32194,
    division_type: "xã",
    codename: "xa_ham_rong",
    district_code: 971,
  },
  {
    name: "Xã Hiệp Tùng",
    code: 32197,
    division_type: "xã",
    codename: "xa_hiep_tung",
    district_code: 971,
  },
  {
    name: "Xã Đất Mới",
    code: 32200,
    division_type: "xã",
    codename: "xa_dat_moi",
    district_code: 971,
  },
  {
    name: "Xã Lâm Hải",
    code: 32201,
    division_type: "xã",
    codename: "xa_lam_hai",
    district_code: 971,
  },
  {
    name: "Xã Hàng Vịnh",
    code: 32203,
    division_type: "xã",
    codename: "xa_hang_vinh",
    district_code: 971,
  },
  {
    name: "Xã Tam Giang",
    code: 32206,
    division_type: "xã",
    codename: "xa_tam_giang",
    district_code: 971,
  },
  {
    name: "Xã Tam Giang Đông",
    code: 32209,
    division_type: "xã",
    codename: "xa_tam_giang_dong",
    district_code: 971,
  },
  {
    name: "Thị trấn Cái Đôi Vàm",
    code: 32212,
    division_type: "thị trấn",
    codename: "thi_tran_cai_doi_vam",
    district_code: 972,
  },
  {
    name: "Xã Phú Thuận",
    code: 32214,
    division_type: "xã",
    codename: "xa_phu_thuan",
    district_code: 972,
  },
  {
    name: "Xã Phú Mỹ",
    code: 32215,
    division_type: "xã",
    codename: "xa_phu_my",
    district_code: 972,
  },
  {
    name: "Xã Phú Tân",
    code: 32218,
    division_type: "xã",
    codename: "xa_phu_tan",
    district_code: 972,
  },
  {
    name: "Xã Tân Hải",
    code: 32221,
    division_type: "xã",
    codename: "xa_tan_hai",
    district_code: 972,
  },
  {
    name: "Xã Việt Thắng",
    code: 32224,
    division_type: "xã",
    codename: "xa_viet_thang",
    district_code: 972,
  },
  {
    name: "Xã Tân Hưng Tây",
    code: 32227,
    division_type: "xã",
    codename: "xa_tan_hung_tay",
    district_code: 972,
  },
  {
    name: "Xã Rạch Chèo",
    code: 32228,
    division_type: "xã",
    codename: "xa_rach_cheo",
    district_code: 972,
  },
  {
    name: "Xã Nguyễn Việt Khái",
    code: 32230,
    division_type: "xã",
    codename: "xa_nguyen_viet_khai",
    district_code: 972,
  },
  {
    name: "Xã Tam Giang Tây",
    code: 32233,
    division_type: "xã",
    codename: "xa_tam_giang_tay",
    district_code: 973,
  },
  {
    name: "Xã Tân Ân Tây",
    code: 32236,
    division_type: "xã",
    codename: "xa_tan_an_tay",
    district_code: 973,
  },
  {
    name: "Xã Viên An Đông",
    code: 32239,
    division_type: "xã",
    codename: "xa_vien_an_dong",
    district_code: 973,
  },
  {
    name: "Xã Viên An",
    code: 32242,
    division_type: "xã",
    codename: "xa_vien_an",
    district_code: 973,
  },
  {
    name: "Thị trấn Rạch Gốc",
    code: 32244,
    division_type: "thị trấn",
    codename: "thi_tran_rach_goc",
    district_code: 973,
  },
  {
    name: "Xã Tân Ân",
    code: 32245,
    division_type: "xã",
    codename: "xa_tan_an",
    district_code: 973,
  },
  {
    name: "Xã Đất Mũi",
    code: 32248,
    division_type: "xã",
    codename: "xa_dat_mui",
    district_code: 973,
  },
];
